/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { compose } from "recompose";
import { connect } from "react-redux";
import { withTableParams } from "@teselagen/ui";
import withQuery from "../../../src-shared/withQuery";

import { map, startCase, get } from "lodash";
import { withHandlers, withProps } from "recompose";
import LookupLibrary from "../../components/Library/LookupLibrary";

import aliquotContainerTypeFragment from "../../../../tg-iso-shared/src/fragments/aliquotContainerTypeFragment";
import { taskIoTypeFragment } from "../../graphql/fragments/taskIoTypeFragment.gql";
import ioItemTypeFragment from "../../graphql/fragments/ioItemTypeFragment";
import dataTableTypeFragment from "../../graphql/fragments/dataTableTypeFragment";
import qualityControlCheckFragment from "../../graphql/fragments/qualityControlCheckFragment";
import dataMapTypeFragment from "../../graphql/fragments/dataMapTypeFragment";
import taskParameterTypeFragment from "../../graphql/fragments/taskParameterTypeFragment";
import workflowTaskStatusTypeFragment from "../../graphql/fragments/workflowTaskStatusTypeFragment";
import workflowRunStatusTypeFragment from "../../graphql/fragments/workflowRunStatusTypeFragment";
import plateMapItemTypeFragment from "../../graphql/fragments/plateMapItemTypeFragment";
import j5ItemTypeFragment from "../../graphql/fragments/j5ItemTypeFragment";
import dataItemTypeFragment from "../../../src-shared/fragments/dataItemTypeFragment";
import inventoryItemTypeFragment from "../../../src-shared/fragments/inventoryItemTypeFragment";

import {
  withUnitGeneric,
  standardizeVolume
} from "../../../src-shared/utils/unitUtils";
import { showDialog } from "../../../src-shared/GlobalDialog";
import {
  additiveTypeSettingsFragment,
  reactionTypeSettingsFragment,
  requestTypeSettingsFragment,
  aliquotContainerTypeSettingsFragment,
  containerArrayTypeSettingsFragment,
  dataFileTypeSettingsFragment,
  containerFormatTypeSettingsFragment
} from "./typeSettingsFragments";
import UploadLocationSettingsDialog from "../../components/AppSettings/LocationSettings/UploadLocationSettingsDialog";
import UploadTubeTypesDialog from "./UploadTubeTypesDialog";
import UploadPlateTypesDialog from "./UploadPlateTypesDialog";
import UploadRackTypesDialog from "./UploadRackTypesDialog";
import { dateModifiedColumn } from "../../../src-shared/utils/libraryColumns";
import {
  isValidPositiveNumber,
  REQUIRED_ERROR
} from "../../../src-shared/utils/formUtils";
import UploadContainerFormatsDialog from "./UploadContainerFormatsDialog";
import isValidPositiveInteger from "../../../../tg-iso-shared/src/utils/isValidPositiveInteger";

export const modelToFragMap = {
  reactionType: reactionTypeSettingsFragment,
  requestType: requestTypeSettingsFragment,
  aliquotContainerType: aliquotContainerTypeSettingsFragment,
  containerArrayType: containerArrayTypeSettingsFragment,
  taskIoType: taskIoTypeFragment,
  ioItemType: ioItemTypeFragment,
  dataItemType: dataItemTypeFragment,
  dataTableType: dataTableTypeFragment,
  qualityControlCheck: qualityControlCheckFragment,
  dataMapType: dataMapTypeFragment,
  dataFileType: dataFileTypeSettingsFragment,
  inventoryItemType: inventoryItemTypeFragment,
  taskParameterType: taskParameterTypeFragment,
  workflowTaskStatusType: workflowTaskStatusTypeFragment,
  workflowRunStatusType: workflowRunStatusTypeFragment,
  plateMapItemType: plateMapItemTypeFragment,
  j5ItemType: j5ItemTypeFragment,
  additiveType: additiveTypeSettingsFragment,
  containerFormat: containerFormatTypeSettingsFragment,
  volumetricUnit: ["volumetricUnit", "code name description liters"],
  massUnit: ["massUnit", "code name description grams"],
  molarUnit: ["molarUnit", "code name description moles"],
  molarityUnit: ["molarityUnit", "code name description molesPerLiter"],
  concentrationUnit: [
    "concentrationUnit",
    "code name description gramsPerLiter"
  ]
};
export const ID_TYPE_MODELS = [
  "requestType",
  "containerArrayType",
  "qualityControlCheck"
];

const noDescription = ["containerFormat"];

const extraFields = {
  requestType: {
    instructions: "string"
  },
  volumetricUnit: {
    liters: "string"
  },
  massUnit: {
    grams: "string"
  },
  molarUnit: {
    moles: "string"
  },
  molarityUnit: {
    molesPerLiter: "string"
  },
  concentrationUnit: {
    gramsPerLiter: "string"
  },
  aliquotContainerType: {
    "Max Volume": record => {
      if (record.maxVolume && record.volumetricUnitCode) {
        return withUnitGeneric("maxVolume", "volumetricUnitCode")(record);
      } else {
        return "N/A";
      }
    },
    "Dead Volume": record => {
      if (record.deadVolume && record.deadVolumetricUnitCode) {
        return withUnitGeneric("deadVolume", "deadVolumetricUnitCode")(record);
      } else {
        return "N/A";
      }
    },
    // isTube: record => (record.isTube ? "Yes" : "No"),
    bottom: record => record.bottom
  },
  plateType: {
    format: record => get(record, "containerFormat.name"),
    "Well Type Name": record => get(record, "aliquotContainerType.name"),
    "Well Type Description": record =>
      get(record, "aliquotContainerType.description"),
    wellBottom: record => {
      return get(record, "aliquotContainerType.bottom");
    },
    "Max Well Volume": record =>
      withUnitGeneric(
        "aliquotContainerType.maxVolume",
        "aliquotContainerType.volumetricUnitCode"
      )(record),
    "Well Dead Volume": record =>
      withUnitGeneric(
        "aliquotContainerType.deadVolume",
        "aliquotContainerType.deadVolumetricUnitCode"
      )(record),
    "Is Column": record => (record.isColumn ? "Yes" : "No"),
    manufacturer: record => record.manufacturer,
    "Catalog Number": record => record.catalogNumber
  },
  containerFormat: {
    "Label Type": record => (record.is2DLabeled ? "Alphanumeric" : "Index"),
    Format: record => `${record.rowCount} x ${record.columnCount}`,
    "Quadrant Size": record => record.quadrantSize
  },
  tubeRackType: {
    format: record => get(record, "containerFormat.name"),
    tubeTypes: record => {
      return record.nestableTubeTypes
        .map(tube => tube.aliquotContainerType.name)
        .join(", ");
    },
    "Is Column": record => (record.isColumn ? "Yes" : "No"),
    manufacturer: record => record.manufacturer,
    "Catalog Number": record => record.catalogNumber
  }
};

const queryOptions = {
  isPlural: true,
  showLoading: true,
  inDialog: true
};
const wrapperMap = {
  containerArrayType: compose(
    withQuery(aliquotContainerTypeFragment, {
      ...queryOptions,
      variables: { filter: { isTube: true } }
    })
  ),
  taskIoType: withQuery(ioItemTypeFragment, queryOptions)
};

const validatorMap = {
  containerArrayType: (values, errors, props) => {
    const { aliquotContainerTypes = [] } = props;
    const { aliquotContainerTypeCode, isPlate } = values;
    if (values.aliquotContainerTypeCode) {
      const chosenType = aliquotContainerTypes.find(
        t => t.code === aliquotContainerTypeCode
      );
      if (chosenType && chosenType.isTube && isPlate) {
        errors.isPlate = "Cannot have a tube well type on a plate.";
      }
    }

    const maxVolume = get(values, "aliquotContainerType.maxVolume");
    const deadVolume = get(values, "aliquotContainerType.deadVolume");
    errors.aliquotContainerType = {};
    if (maxVolume && !isValidPositiveNumber(maxVolume)) {
      errors.aliquotContainerType.maxVolume = "Please enter a positive number.";
    }
    if (
      deadVolume &&
      !isValidPositiveNumber(deadVolume) &&
      Number(deadVolume) !== 0
    ) {
      errors.aliquotContainerType.deadVolume = "Please enter a valid number.";
    } else if (deadVolume && maxVolume) {
      const standardizedMaxVolume = standardizeVolume(
        maxVolume,
        get(values, "aliquotContainerType.volumetricUnitCode", "uL")
      );
      const standardizedDeadVolume = standardizeVolume(
        deadVolume,
        get(values, "aliquotContainerType.deadVolumetricUnitCode", "uL")
      );
      if (standardizedDeadVolume >= standardizedMaxVolume) {
        errors.aliquotContainerType.deadVolume =
          "Dead volume must be less than max volume.";
      }
    }
  },
  aliquotContainerType: (values, errors) => {
    const maxVolume = values.maxVolume;
    const deadVolume = values.deadVolume;
    if (maxVolume && !isValidPositiveNumber(maxVolume)) {
      errors.maxVolume = "Please enter a positive number.";
    }
    if (
      deadVolume &&
      !isValidPositiveNumber(deadVolume) &&
      Number(deadVolume) !== 0
    ) {
      errors.deadVolume = "Please enter a valid number.";
    } else if (deadVolume && maxVolume) {
      const standardizedMaxVolume = standardizeVolume(
        maxVolume,
        get(values, "volumetricUnitCode", "uL")
      );
      const standardizedDeadVolume = standardizeVolume(
        deadVolume,
        get(values, "deadVolumetricUnitCode", "uL")
      );
      if (standardizedDeadVolume >= standardizedMaxVolume) {
        errors.deadVolume = "Dead volume must be less than max volume.";
      }
    }
  },
  containerFormat: (values, errors) => {
    const numFields = ["rowCount", "columnCount"];
    numFields.forEach(field => {
      const val = values[field];
      if (val) {
        if (!isValidPositiveInteger(val)) {
          errors[field] = "Please enter a valid number";
        } else if (val > 48) {
          errors[field] = "Can not be greater than 48";
        }
      }
    });
    return errors;
  },
  volumetricUnit: (values, errors) => {
    if (!values.liters) {
      errors.liters = REQUIRED_ERROR;
    }
    return errors;
  },
  massUnit: (values, errors) => {
    if (!values.grams) {
      errors.grams = REQUIRED_ERROR;
    }
    return errors;
  },
  molarUnit: (values, errors) => {
    if (!values.moles) {
      errors.moles = REQUIRED_ERROR;
    }
    return errors;
  },
  molarityUnit: (values, errors) => {
    if (!values.molesPerLiter) {
      errors.molesPerLiter = REQUIRED_ERROR;
    }
    return errors;
  },
  concentrationUnit: (values, errors) => {
    if (!values.gramsPerLiter) {
      errors.gramsPerLiter = REQUIRED_ERROR;
    }
    return errors;
  }
};

const measurementModelToConversionName = {
  volumetricUnit: "liters",
  massUnit: "grams",
  concentrationUnit: "gramsPerLiter",
  molarityUnit: "molesPerLiter",
  molarUnit: "moles"
};

const simpleTypeUploads = [
  "dataFileType",
  "additiveType",
  "requestType",
  "reactionType"
];
const uploadDialogsMap = {
  aliquotContainerType: UploadTubeTypesDialog,
  plateType: UploadPlateTypesDialog,
  tubeRackType: UploadRackTypesDialog,
  containerFormat: UploadContainerFormatsDialog
};

const TypeSettingsContainer = ({ model, label, isMeasurement }) => {
  const lookupType = ID_TYPE_MODELS.indexOf(model) > -1 ? "id" : "code";

  const extraFieldsForModel = extraFields[label] || {};

  const schema = {
    model,
    fields: ["name"]
  };
  let defaultOrder = ["-modified"];
  if (lookupType === "id") {
    schema.fields.unshift({
      displayName: "ID",
      type: "string",
      path: "id",
      isHidden: true
    });
  }
  if (isMeasurement) {
    schema.fields.push("code");
    const conversionName = measurementModelToConversionName[model];
    schema.fields.push({
      isHidden: true,
      displayName: startCase(conversionName),
      type: "number",
      path: conversionName
    });
    defaultOrder = ["-" + conversionName, "name"];
  } else {
    schema.fields.push(dateModifiedColumn);
  }

  const fragment = modelToFragMap[model];
  const typeLibraryFilters = {
    aliquotContainerType: { isTube: true },
    plateType: { isPlate: true },
    tubeRackType: { isPlate: false }
  };
  if (!fragment) throw new Error("There must be a fragment for " + model);

  return compose(
    withHandlers({
      lookupNewItemFunction:
        () =>
        (show, refetch, _initialValues = {}, cb) => {
          let initialValues = _initialValues;
          if (
            model === "containerArrayType" &&
            initialValues.nestableTubeTypes
          ) {
            initialValues = {
              ...initialValues,
              initialNestableTubeTypes: initialValues.nestableTubeTypes,
              nestableTubeTypes: initialValues.nestableTubeTypes.map(
                tt => tt.aliquotContainerTypeCode
              )
            };
          }
          if (label === "plateType" || label === "tubeRackType") {
            initialValues = {
              ...initialValues,
              isPlate: label === "plateType"
            };
          }
          if (model === "containerFormat") {
            initialValues = {
              ...initialValues,
              is2DLabeled:
                !initialValues.code || initialValues.is2DLabeled
                  ? "alphanumeric"
                  : "index"
            };
          }
          const basicFields = ["name"];
          if (!noDescription.includes(model)) {
            basicFields.push("description");
          }
          showDialog({
            modalType: "LAUNCH_NEW_LOOKUP_VALUE_DIALOG",
            modalProps: {
              refetch,
              model,
              label,
              cb,
              validator: validatorMap[model],
              wrapper: wrapperMap[model],
              initialValues,
              lookupType,
              fields: basicFields.concat(
                Object.keys(extraFieldsForModel).filter(
                  key => typeof extraFieldsForModel[key] === "string"
                )
              )
            }
          });
        }
    }),
    connect(() => {
      const fields = [];
      if (!noDescription.includes(model)) {
        fields.push({
          path: "description",
          name: "Description",
          type: "string"
        });
      }
      return {
        tableLibraryName: label || model,
        lookupType,
        lookupEntityInfoFields: fields.concat(
          map(extraFieldsForModel, (typeOrRender, path) => ({
            path,
            name: startCase(path),
            ...(typeof typeOrRender === "string"
              ? { type: typeOrRender }
              : { render: typeOrRender })
          }))
        )
      };
    }),
    withTableParams({
      urlConnected: false,
      schema,
      defaults: {
        order: defaultOrder
      },
      additionalFilter: typeLibraryFilters[label],
      formName: "typeSettingsLibraryTable-" + label,
      withDisplayOptions: true,
      isCodeModel: lookupType === "code",
      withSelectedEntities: true
    }),
    withQuery(fragment, { isPlural: true }),
    withProps(props => {
      let showUploadFunction;
      if (simpleTypeUploads.includes(model) || uploadDialogsMap[label]) {
        showUploadFunction = () => {
          showDialog({
            ModalComponent:
              uploadDialogsMap[label] || UploadLocationSettingsDialog,
            modalProps: {
              model,
              refetch: props.tableParams.onRefresh
            }
          });
        };
      }
      return {
        showUploadFunction
      };
    })
  )(LookupLibrary);
};

export default TypeSettingsContainer;
