/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import GenericSelect from "../../../../src-shared/GenericSelect";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import platePreviewColumn from "../../../utils/platePreviewColumn";
import { plateLibraryFilter } from "../../../utils/plateUtils";

export const plateFragment = [
  "containerArray",
  "id name barcode { id barcodeString } containerArrayType { id name } updatedAt"
];

const containerArraysSchema = [
  {
    path: "name"
  },
  {
    displayName: "Barcode",
    path: "barcode.barcodeString"
  },
  dateModifiedColumn
];

const postSelectDTProps = {
  formName: "movePlateToolSelectPlates",
  schema: [
    platePreviewColumn(),
    "name",
    {
      displayName: "Barcode",
      path: "barcode.barcodeString"
    },
    dateModifiedColumn
  ]
};

const tableParamOptions = {
  additionalFilter: plateLibraryFilter
};

const MoveItems = ({
  toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
  stepFormProps: { initialValues }
}) => {
  const buttonProps = useMemo(
    () => ({
      loading: isLoadingMap.containerArrays,
      disabled: isDisabledMap.containerArrays
    }),
    [isDisabledMap.containerArrays, isLoadingMap.containerArrays]
  );

  return (
    <div>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select Plates"
          helper="Select one or more plates to move."
        />
        <div>
          <GenericSelect
            name="containerArrays"
            buttonProps={buttonProps}
            isMultiSelect
            isRequired
            schema={containerArraysSchema}
            tableParamOptions={tableParamOptions}
            fragment={plateFragment}
            postSelectDTProps={postSelectDTProps}
            defaultValue={initialValues.containerArrays}
          />
        </div>
      </div>
    </div>
  );
};

export default MoveItems;
