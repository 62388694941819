/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useEffect } from "react";
import { compose } from "recompose";
import { Classes } from "@blueprintjs/core";
import {
  CheckboxField,
  DataTable,
  DialogFooter,
  withSelectedEntities,
  withTableParams
} from "@teselagen/ui";
import gql from "graphql-tag";
import { wrapDialog, withSelectTableRecords } from "@teselagen/ui";
import appGlobals from "../appGlobals";
import withQuery from "../withQuery";
import { difference } from "lodash";
import { getUserProjects } from "../utils/projectUtils";
import { validateProjectUpdateForSubjects } from "../utils/assaySubjectUtils";
import { deleteWithQuery, safeUpsert } from "../apolloMethods";
import { formValues, reduxForm } from "redux-form";

const tableFormName = "changeProjectsDialogTable";

/**
 * This Function valdiation mapper will support
 * any model that needs project update validation.
 */
const PROJECT_UPDATE_FN_MAP = {
  assaySubject: validateProjectUpdateForSubjects
  // "sequence": validateProjectUpdateForSequence
  // "part": validateProjectUpdateForPart
};

async function validateProjectUpdate(props) {
  const { records, added, removed } = props;

  for (const record of records) {
    const validationFn = PROJECT_UPDATE_FN_MAP[record.__typename];
    if (validationFn) {
      const { isValid, validationMessage } = await validationFn({
        records,
        projects: { added, removed }
      });
      // If an invalid project tag update for a record is found
      // the whole attempt is considered invalid.
      // NOTE: the first invalid record found will break the loop and return invalid
      // we could validate all records, but the toastr message could get out of hand.
      if (!isValid) return { isValid, validationMessage };
    }
  }

  // If no record is invalid just return true.
  return { isValid: true };
}

function getOriginalProjects(records) {
  const selectedProjects = [];
  const userProjects = getUserProjects();
  const userProjectIds = userProjects.map(p => p.id);
  const keyedProjects = {};

  const recordProjectIdCounter = {};

  records.forEach(r => {
    const projectIdsOnRecord = [];
    r.projectItems.forEach(projectItem => {
      const projectId = projectItem.project.id;
      if (projectId && !projectIdsOnRecord.includes(projectId)) {
        projectIdsOnRecord.push(projectId);
        keyedProjects[projectId] = projectItem.project;
      }
    });
    projectIdsOnRecord.forEach(projectId => {
      if (
        userProjectIds.includes(projectId) ||
        projectId === window.frontEndConfig.allProjectsTagId
      ) {
        recordProjectIdCounter[projectId] =
          recordProjectIdCounter[projectId] || 0;
        recordProjectIdCounter[projectId]++;
      }
    });
  });
  Object.keys(recordProjectIdCounter).forEach(projectId => {
    const count = recordProjectIdCounter[projectId];
    if (count === records.length) {
      // if all of the records have this project then it is selected
      selectedProjects.push(keyedProjects[projectId]);
    }
  });
  return selectedProjects;
}

function ChangeProjectDialog(props) {
  const {
    hideModal,
    tableParams,
    [tableFormName + "SelectedEntities"]: selectedProjects = [],
    selectTableRecords,
    records,
    refetch,
    handleSubmit,
    submitting,
    allProjects,
    change
  } = props;

  useEffect(() => {
    const originalProjects = getOriginalProjects(records);
    if (
      originalProjects.length === 1 &&
      originalProjects[0].id === window.frontEndConfig.allProjectsTagId
    ) {
      change("allProjects", true);
    }
    selectTableRecords(originalProjects);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onSubmit(values) {
    try {
      const partsIds = [];

      if (values.allProjects) {
        records.forEach(r => {
          if (r.__typename === "sequence") {
            r.parts?.forEach(p => partsIds.push(p.id));
          }
        });
        // remove all project items for records
        await deleteWithQuery("projectItem", {
          [records[0].__typename + "Id"]: records.map(r => r.id)
        });
        await safeUpsert(
          "projectItem",
          records.map(r => {
            return {
              projectId: window.frontEndConfig.allProjectsTagId,
              [r.__typename + "Id"]: r.id
            };
          })
        );

        if (partsIds.length) {
          await deleteWithQuery("projectItem", { partId: partsIds });
          await safeUpsert(
            "projectItem",
            partsIds.map(pId => {
              return {
                projectId: window.frontEndConfig.allProjectsTagId,
                partId: pId
              };
            })
          );
        }
      } else {
        const originalProjects = getOriginalProjects(records);
        const originalProjectIds = originalProjects.map(p => p.id);
        const selectedIds = selectedProjects.map(p => p.id);
        const removed = difference(originalProjectIds, selectedIds);
        const added = difference(selectedIds, originalProjectIds);
        const newProjectItems = [];
        records.forEach(r => {
          if (r.__typename === "sequence") {
            r.parts?.forEach(p => partsIds.push(p.id));
          }
          added.forEach(projectId => {
            const alreadyHas = r.projectItems.some(
              projectItem => projectItem.project.id === projectId
            );
            if (!alreadyHas) {
              newProjectItems.push({
                [r.__typename + "Id"]: r.id,
                projectId
              });
              if (r.__typename === "sequence") {
                r.parts?.forEach(p =>
                  newProjectItems.push({ partId: p.id, projectId })
                );
              }
            }
          });
        });
        if (window.frontEndConfig.allProjectsTagId) {
          // remove from all projects
          await deleteWithQuery("projectItem", {
            [records[0].__typename + "Id"]: records.map(r => r.id),
            projectId: window.frontEndConfig.allProjectsTagId
          });
          if (partsIds.length) {
            await deleteWithQuery("projectItem", {
              partId: partsIds,
              projectId: window.frontEndConfig.allProjectsTagId
            });
          }
        }

        const { isValid, validationMessage } = await validateProjectUpdate({
          records,
          added,
          removed
        });

        if (!isValid) {
          // Show a validation message if provided.
          if (validationMessage) window.toastr.error(validationMessage);
          return;
        }

        if (removed.length) {
          await deleteWithQuery("projectItem", {
            [records[0].__typename + "Id"]: records.map(r => r.id),
            projectId: removed
          });
          if (partsIds.length) {
            await deleteWithQuery("projectItem", {
              partId: partsIds,
              projectId: removed
            });
          }
        }
        await safeUpsert("projectItem", newProjectItems);
      }
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error changing projects");
    }
  }

  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        {window.frontEndConfig.allProjectsTagId && (
          <CheckboxField
            name="allProjects"
            label="All Projects"
            tooltipInfo="Makes item show up across all projects"
          />
        )}
        {!allProjects && (
          <DataTable {...tableParams} withCheckboxes noPadding />
        )}
      </div>
      <DialogFooter
        loading={submitting}
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
      />
    </React.Fragment>
  );
}

const schema = {
  model: "project",
  fields: [
    {
      path: "name",
      type: "string",
      displayName: "Name"
    }
  ]
};

const changeProjectFragment = gql`
  fragment changeProjectFragment on project {
    id
    name
    color
  }
`;

export default compose(
  wrapDialog({
    title: "Change Project"
  }),
  reduxForm({
    form: "changeProjectDialog"
  }),
  formValues("allProjects"),
  withTableParams({
    schema,
    formName: tableFormName,
    urlConnected: false,
    additionalFilter: (_, qb) => {
      qb.whereAll({
        "projectRoles.userId": appGlobals.currentUser.id
      });
    }
  }),
  withQuery(changeProjectFragment, {
    isPlural: true
  }),
  withSelectTableRecords(tableFormName),
  withSelectedEntities(tableFormName)
)(ChangeProjectDialog);
