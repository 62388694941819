/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { Button, Intent, Classes } from "@blueprintjs/core";
import { compose } from "recompose";
import {
  withTableParams,
  DataTable,
  withSelectedEntities,
  DialogFooter,
  wrapDialog
} from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";
import classNames from "classnames";
import additiveMaterialInventoryFragment from "../../../graphql/fragments/additiveMaterialInventoryFragment";
import selectMaterialsFromSelectReagentsOrMaterialsDialogFragment from "../../../graphql/fragments/selectMaterialsFromSelectReagentsOrMaterialsDialogFragment";
import TagTableColumn from "../../../../src-shared/TagTableColumn";
import { tagColumn } from "../../../../src-shared/utils/tagColumn";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import { addTagFilterToQuery } from "../../../../src-shared/utils/tagUtils";
import { safeQuery } from "../../../../src-shared/apolloMethods";

class SelectReagentsOrMaterialsDialog extends Component {
  state = {
    type: null,
    loading: false
  };

  selectType = type => () => {
    this.setState({
      type
    });
  };

  selectItems = async maybeItems => {
    const { hideModal, materialFragment, reagentFragment, onSelect } =
      this.props;
    let selectedItems = maybeItems;
    const isMaterial = selectedItems[0].__typename === "material";
    const fragment = isMaterial ? materialFragment : reagentFragment;

    if (fragment) {
      selectedItems = await safeQuery(fragment, {
        variables: {
          filter: {
            id: selectedItems.map(item => item.id)
          }
        }
      });
    }

    onSelect(selectedItems);
    hideModal();
  };

  render() {
    const {
      hideModal,
      selectReagentsInDialogSelectedEntities = [],
      selectMaterialsInDialogSelectedEntities = []
    } = this.props;
    const { type, loading } = this.state;

    const selectedEntitiesToUse =
      type === "reagent"
        ? selectReagentsInDialogSelectedEntities
        : selectMaterialsInDialogSelectedEntities;

    let inner;
    if (type) {
      const TableComp = type === "reagent" ? ReagentTable : MaterialTable;
      inner = (
        <div>
          <TableComp />
          <DialogFooter
            disabled={!selectedEntitiesToUse.length}
            hideModal={hideModal}
            loading={loading}
            onClick={() => this.selectItems(selectedEntitiesToUse)}
          />
        </div>
      );
    } else {
      inner = (
        <div
          className={classNames(
            "tg-flex column align-center",
            Classes.DIALOG_BODY
          )}
        >
          <Button
            intent={Intent.PRIMARY}
            text="Select Reagents"
            onClick={this.selectType("reagent")}
          />
          <div style={{ margin: 15 }}>OR</div>
          <Button
            intent={Intent.PRIMARY}
            text="Select Materials"
            onClick={this.selectType("material")}
          />
        </div>
      );
    }
    return <div>{inner}</div>;
  }
}

export default compose(
  wrapDialog({
    title: "Select Materials or Reagents",
    canOutsideClickClose: false
  }),
  withSelectedEntities("selectReagentsInDialog", "selectMaterialsInDialog")
)(SelectReagentsOrMaterialsDialog);

const GenericTable = ({ tableParams }) => (
  <DataTable
    {...tableParams}
    withCheckboxes
    cellRenderer={{
      tags: (_, record, __, paramProps) => (
        <TagTableColumn record={record} paramProps={paramProps} />
      )
    }}
  />
);

const additiveMaterialSchema = {
  model: "additiveMaterial",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    {
      path: "additiveType.name",
      type: "string",
      displayName: "Reagent Type"
    },
    tagColumn,
    dateModifiedColumn
  ]
};

const ReagentTable = compose(
  withTableParams({
    urlConnected: true,
    schema: additiveMaterialSchema,
    formName: "selectReagentsInDialog",
    withDisplayOptions: true,
    additionalFilter: (_, qb, currentParams) => {
      addTagFilterToQuery(currentParams.tags, qb, "additiveMaterial");
    }
  }),
  withQuery(additiveMaterialInventoryFragment, {
    isPlural: true
  })
)(GenericTable);

const materialSchema = {
  model: "material",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    { path: "materialType.name", type: "string", displayName: "Type" },
    tagColumn,
    dateModifiedColumn
  ]
};

const MaterialTable = compose(
  withTableParams({
    urlConnected: true,
    schema: materialSchema,
    formName: "selectMaterialsInDialog",
    withDisplayOptions: true,
    additionalFilter: (_, qb, currentParams) => {
      addTagFilterToQuery(currentParams.tags, qb);
    }
  }),
  withQuery(selectMaterialsFromSelectReagentsOrMaterialsDialogFragment, {
    isPlural: true
  })
)(GenericTable);
