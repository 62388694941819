/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { formValues } from "redux-form";
import { ReactSelectField } from "@teselagen/ui";
import GenericSelect from "../../GenericSelect";
import { arrayToItemValuedOptions } from "../../utils/formUtils";

function LabelSettingsForm({ endpointData = {}, params }) {
  const { labelFormats: endpointLabelFormats = [] } = endpointData;

  const isContainerArray = params.itemType === "plate";

  let labelFormatSelect;

  if (endpointLabelFormats.length) {
    const defaultValue = endpointLabelFormats.find(m => m.isDefault);
    labelFormatSelect = (
      <ReactSelectField
        isRequired
        defaultValue={defaultValue}
        name="integrationLabelFormat"
        label="Label Format"
        options={arrayToItemValuedOptions(endpointLabelFormats)}
      />
    );
  } else {
    labelFormatSelect = (
      <GenericSelect
        name="labelFormat"
        asReactSelect
        isRequired
        label="Label Format"
        fragment={["labelFormat", "id name"]}
        additionalFilter={{
          isContainerArray
        }}
      />
    );
  }

  return <div>{labelFormatSelect}</div>;
}

export default formValues(
  "integrationLabelMedia",
  "labelMedia"
)(LabelSettingsForm);
