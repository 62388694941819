/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

/**
 * Corresponds to `Pcr_Block` in the perl code.
 *
 * A structure that contains a collection of information relevant to PCR thermocycler block.
 */
class PcrBlock {
  full: boolean;
  max_delta_temperature_adjacent_zones: number;
  n_zones: number;
  wells_per_zone: number;
  zone_temperature: number[];
  zone_reaction_list: any[][];

  constructor(
    {
      n_zones,
      wells_per_zone,
      max_delta_temperature_adjacent_zones
    }: {
      n_zones: number;
      wells_per_zone: number;
      max_delta_temperature_adjacent_zones: number;
    } = {
      n_zones: 0,
      wells_per_zone: 0,
      max_delta_temperature_adjacent_zones: 0
    }
  ) {
    // full => '$', # 0 or 1
    // max_delta_temperature_adjacent_zones => '$', # float
    // n_zones => '$', # integer
    // wells_per_zone => '$', # integer
    // zone_reaction_list => '@', # array of integer indexes
    // zone_temperature => '@', # array of floats
    this.full = false;
    this.max_delta_temperature_adjacent_zones =
      max_delta_temperature_adjacent_zones;
    this.n_zones = n_zones;
    this.wells_per_zone = wells_per_zone;
    this.zone_temperature = [];

    // This is an array of arrays of PCR Reactions.
    this.zone_reaction_list = [];
    for (let i = 0; i < this.n_zones; i++) {
      this.zone_reaction_list.push([]);
    }
  }

  /**
   * Empty the PCR reactions from the block
   */
  emptyPcrReactions = () => {
    for (let i = 0; i < this.n_zones; i++) {
      this.zone_reaction_list[i] = [];
    }
    this.full = false;
  };

  addPcrReactionToZone = (
    pcrReaction: {
      optimal_annealing_temperature: number;
    },
    zone: number
  ) => {
    // Check to make sure that we can add to the block and zone (that they aren't already full).
    if (
      this.full ||
      this.zone_reaction_list[zone].length > this.wells_per_zone
    ) {
      throw Error(`addPcrReactionToZone: zone ${zone} already full!`);
    }

    this.zone_reaction_list[zone].push(pcrReaction);

    this.checkIfFull();
  };

  checkIfFull = () => {
    // Assume block is full, before finding a zone that isn't full.
    this.full = true;

    for (let i = 0; i < this.n_zones; i++) {
      if (this.zone_reaction_list[i].length < this.wells_per_zone) {
        this.full = false;
        break;
      }
    }
  };

  copy = () => {
    const c = new PcrBlock({
      max_delta_temperature_adjacent_zones:
        this.max_delta_temperature_adjacent_zones,
      n_zones: this.n_zones,
      wells_per_zone: this.wells_per_zone
    });

    c.full = this.full;
    c.zone_temperature = [...this.zone_temperature];
    c.zone_reaction_list = this.zone_reaction_list.map(reactions => [
      ...reactions
    ]);

    return c;
  };

  /**
   * Helper function for debugging.
   */
  print = () => {
    let s = "";
    this.zone_reaction_list.forEach((reactions, i) => {
      s += `Zone Temperature: ${this.zone_temperature[i]}\n`;
      reactions.forEach(reaction => {
        s += `\tPCR Optimal Temp: ${reaction.optimal_annealing_temperature}\n`;
      });
    });
    // eslint-disable-next-line no-console
    console.log(s);
  };
}

export default PcrBlock;
