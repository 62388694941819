/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useCallback, useMemo } from "react";
import {
  SelectField,
  InputField,
  NumericInputField,
  CheckboxField,
  DataTable,
  RadioGroupField,
  BlueprintError
} from "@teselagen/ui";
import { Button, Intent } from "@blueprintjs/core";
import { get, forEach, map, keys, times, maxBy } from "lodash";
import { validateTransfers } from "../../../../utils";
import shortid from "shortid";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import { makeMaterialToAliquotContainerMap } from "../utils";
import {
  getAliquotTransferVolumeFromMass,
  getLocationHashMapGivenWellCountAndDirection
} from "../../../../utils/plateUtils";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import PlateMapView from "../../../PlateMapView";
import { arpPlateMapGroupFragment } from "../fragments";
import PlateUploadFields from "../../../PlateUploadFields";
import { standardizeVolume } from "../../../../../src-shared/utils/unitUtils";
import defaultValueConstants from "../../../../../../tg-iso-shared/src/defaultValueConstants";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { getPositionFromAlphanumericLocation } from "../../../../../../tg-iso-lims/src/utils/plateUtils";
import unitGlobals from "../../../../../../tg-iso-lims/src/unitGlobals";
import { change } from "redux-form";
import { useDispatch, useSelector } from "react-redux";

const genericSelectSchema = [
  "name",
  { displayName: "Plate Format", path: "containerFormat.name" },
  dateModifiedColumn
];

const genericSelectFragment = [
  "plateMapGroup",
  "id name containerFormatCode containerFormat { code name } updatedAt"
];

const DestinationPlateConfiguration = ({
  aliquotContainerTypes,
  Footer,
  footerProps,
  handleSubmit,
  loadingPlateMapGroup,
  nextStep,
  stepFormProps: { valid },
  toolIntegrationProps: { isDisabledMap = {} },
  toolSchema,
  toolSchema: { code }
}) => {
  const {
    aliquotContainers = [],
    aliquotContainerType,
    applyUniversalTransfer,
    assemblyMaterials = [],
    constructMap,
    constructReactionMap,
    containerArrays = [],
    containerArrayType,
    destinationDirection,
    destinationPlateBaseName,
    generateBarcodes,
    materialTransferInfo = {},
    universalTransferType,
    universalTransferUnitCode,
    universalTransferValue,
    validationPlateMapGroup
  } = useSelector(state => state.form?.[code]?.values);

  const dispatch = useDispatch();

  const generateWorklist = useCallback(() => {
    const worklist = {
      worklistTransfers: []
    };

    const constructIds = map(
      constructMap,
      construct => construct.sequence.polynucleotideMaterial.id
    );

    let constructWellsLocationList;
    const plateMapLocationToConstructMaterialId = {};

    if (validationPlateMapGroup) {
      const locationArray = [];
      validationPlateMapGroup.plateMaps.forEach(plateMap => {
        const plateMapArray = [];
        plateMap.plateMapItems.forEach(pmi =>
          plateMapArray.push(getAliquotContainerLocation(pmi))
        );
        locationArray.push(plateMapArray);
      });
      constructWellsLocationList = locationArray;
      validationPlateMapGroup.plateMaps.forEach((plateMap, index) => {
        plateMapLocationToConstructMaterialId[index] = {};
        plateMap.plateMapItems.forEach(plateMapItem => {
          plateMapLocationToConstructMaterialId[index][
            getAliquotContainerLocation(plateMapItem)
          ] = plateMapItem.inventoryItem.material.id;
        });
      });
    } else {
      constructWellsLocationList = getLocationHashMapGivenWellCountAndDirection(
        {
          containerFormat: containerArrayType.containerFormat,
          numWells: Object.keys(constructMap).length,
          startingPosition: "A1",
          direction: destinationDirection,
          multiplate: true
        }
      ).map(set => Object.keys(set));
    }
    const transfers = [];
    const constructMaterialIdToDestAC = {};
    const destPlateNameMap = {};
    const destinationPlates = constructWellsLocationList.map(
      (locations, index) => {
        const name = destinationPlateBaseName + " " + (index + 1);
        return {
          name,
          containerArrayTypeId: containerArrayType.id,
          containerArrayType,
          aliquotContainers: locations.map((location, i) => {
            const constructIdIndex =
              i + containerArrayType.containerFormat.quadrantSize * index;
            const aliquotContainerCid = shortid();
            const destACFields = {
              id: `&${aliquotContainerCid}`,
              location,
              aliquotContainerTypeCode: containerArrayType.isPlate
                ? containerArrayType.aliquotContainerTypeCode
                : aliquotContainerType.code,
              ...getPositionFromAlphanumericLocation(location)
            };
            if (validationPlateMapGroup) {
              constructMaterialIdToDestAC[
                plateMapLocationToConstructMaterialId[index][location]
              ] = {
                ...destACFields
              };
              destPlateNameMap[
                plateMapLocationToConstructMaterialId[index][location]
              ] = name;
            } else {
              const constructId = constructIds[constructIdIndex];
              constructMaterialIdToDestAC[constructId] = {
                ...destACFields
              };
              destPlateNameMap[constructId] = name;
            }
            return {
              ...getPositionFromAlphanumericLocation(location),
              cid: aliquotContainerCid,
              aliquotContainerTypeCode: containerArrayType.isPlate
                ? containerArrayType.aliquotContainerTypeCode
                : aliquotContainerType.code
            };
          })
        };
      }
    );
    const keyedReactions = {};
    const keyedInputMaterials = {};
    constructReactionMap.reactions.forEach(reaction => {
      keyedReactions[reaction.reactionOutputs[0].outputMaterialId] = reaction;
      reaction.inputMaterials.forEach(m => {
        keyedInputMaterials[m.id] = m;
      });
    });

    const remainingVolumeForAliquotContainers = {};

    const getAliquotContainerWithMostVolume = acList => {
      acList.forEach(ac => {
        if (!remainingVolumeForAliquotContainers[ac.id]) {
          const standardAliquotVolume = standardizeVolume(
            ac.aliquot.volume,
            ac.aliquot.volumetricUnitCode
          );
          remainingVolumeForAliquotContainers[ac.id] = standardAliquotVolume;
        }
      });
      const containerWithMostVolume = maxBy(
        acList,
        ac => remainingVolumeForAliquotContainers[ac.id]
      );

      return containerWithMostVolume;
    };

    const subtractTransferFromAliquotContainer = (
      ac,
      standardizedTransferVolume
    ) => {
      remainingVolumeForAliquotContainers[ac.id] =
        remainingVolumeForAliquotContainers[ac.id] - standardizedTransferVolume;
    };

    const assemblyPieceMaterialIdToSourceAliquotContainersMap =
      makeMaterialToAliquotContainerMap({
        containerArrays,
        aliquotContainers,
        assemblyMaterials
      });

    forEach(constructMap, construct => {
      const constructMaterialId = construct.sequence.polynucleotideMaterial.id;
      const reaction = keyedReactions[constructMaterialId];
      const destAc = constructMaterialIdToDestAC[constructMaterialId];
      const destinationPlateName = destPlateNameMap[constructMaterialId];
      reaction.reactionInputs.forEach(input => {
        const inputMaterialName =
          keyedInputMaterials[input.inputMaterialId].name;
        const inputMaterialId = input.inputMaterialId;
        const aliquotContainerList =
          assemblyPieceMaterialIdToSourceAliquotContainersMap[inputMaterialId];

        const sourceAliquotContainer =
          getAliquotContainerWithMostVolume(aliquotContainerList);

        const isPlate = sourceAliquotContainer.containerArray || false;

        // applyUniversalTransfer;
        const aliquot = sourceAliquotContainer.aliquot;
        let transferVolume, transferVolumetricUnitCode;

        if (applyUniversalTransfer) {
          if (universalTransferType === "volume") {
            transferVolume = universalTransferValue;
            transferVolumetricUnitCode = universalTransferUnitCode;
          } else {
            transferVolume = getAliquotTransferVolumeFromMass(
              aliquot,
              universalTransferValue,
              universalTransferUnitCode
            );
            transferVolumetricUnitCode = aliquot.volumetricUnitCode;
          }
        } else {
          const key = "id" + inputMaterialId;
          if (!materialTransferInfo[key]) {
            console.error("validation should have prevented this.");
          }

          const { quantityType, transferValue, transferUnit } =
            materialTransferInfo[key];
          if (quantityType === "volume") {
            transferVolume = transferValue;
            transferVolumetricUnitCode = transferUnit;
          } else {
            transferVolume = getAliquotTransferVolumeFromMass(
              aliquot,
              transferValue,
              transferUnit
            );
            transferVolumetricUnitCode = aliquot.volumetricUnitCode;
          }
        }

        const standardTransferVolume = standardizeVolume(
          transferVolume,
          transferVolumetricUnitCode
        );

        subtractTransferFromAliquotContainer(
          sourceAliquotContainer,
          standardTransferVolume
        );

        transfers.push({
          assemblyPieceName: inputMaterialName,
          constructName: construct.sequence.polynucleotideMaterial.name,
          volume: transferVolume,
          volumetricUnitCode: transferVolumetricUnitCode,
          sourcePlateName:
            isPlate && sourceAliquotContainer.containerArray.name,
          sourceAliquotContainerId: sourceAliquotContainer.id,
          sourceAliquotContainer,
          sourceAliquotPosition: getAliquotContainerLocation(
            sourceAliquotContainer
          ),
          destinationPlateName,
          destinationAliquotContainer: destAc,
          destinationAliquotContainerId: destAc.id,
          destinationAliquotContainerLocation: destAc.location
        });
      });
    });
    worklist.worklistTransfers = transfers;
    return { worklist, destinationPlates };
  }, [
    aliquotContainerType?.code,
    aliquotContainers,
    applyUniversalTransfer,
    assemblyMaterials,
    constructMap,
    constructReactionMap?.reactions,
    containerArrayType,
    containerArrays,
    destinationDirection,
    destinationPlateBaseName,
    materialTransferInfo,
    universalTransferType,
    universalTransferUnitCode,
    universalTransferValue,
    validationPlateMapGroup
  ]);

  const onStepSubmit = useCallback(() => {
    const { worklist, destinationPlates } = generateWorklist();
    dispatch(change(code, "worklist", worklist));
    dispatch(change(code, "destinationPlates", destinationPlates));
    nextStep();
  }, [code, dispatch, generateWorklist, nextStep]);

  const materialTransferSchema = useMemo(
    () => [
      "name",
      {
        displayName: "Quantity Type",
        filterDisabled: true,
        sortDisabled: true,
        render: (v, r) => {
          return (
            <SelectField
              name={`materialTransferInfo.id${r.id}.quantityType`}
              options={["volume", "mass"]}
              defaultValue="volume"
            />
          );
        }
      },
      {
        displayName: "Value",
        filterDisabled: true,
        sortDisabled: true,
        render: (v, r) => {
          return (
            <NumericInputField
              name={`materialTransferInfo.id${r.id}.transferValue`}
            />
          );
        }
      },
      {
        displayName: "Unit",
        filterDisabled: true,
        sortDisabled: true,
        render: (v, r) => {
          const quantityType = get(
            materialTransferInfo,
            `id${r.id}.quantityType`,
            "volume"
          );
          const defaultValue = quantityType === "volume" ? "uL" : "ng";
          return (
            <SelectField
              name={`materialTransferInfo.id${r.id}.transferUnit`}
              defaultValue={defaultValue}
              enableReinitialize
              options={unitGlobals.getOptionsForSelect(
                quantityType === "volume" ? "volumetricUnit" : "massUnit"
              )}
            />
          );
        }
      }
    ],
    [materialTransferInfo]
  );

  let additionalFilter;

  if (validationPlateMapGroup) {
    additionalFilter = {
      containerFormatCode: validationPlateMapGroup.containerFormatCode
    };
  }

  let worklistError;
  if (valid) {
    const hasTransferInfo = applyUniversalTransfer
      ? universalTransferUnitCode && universalTransferValue
      : true;
    const canValidate = containerArrayType && hasTransferInfo;

    if (canValidate) {
      const { worklist } = generateWorklist();
      worklistError = validateTransfers(worklist, aliquotContainerTypes);
    }
  }

  const numConstructs = keys(constructMap).length;
  const numWells = get(containerArrayType, "containerFormat.quadrantSize");
  const numDestinationPlates = Math.ceil(numConstructs / numWells);
  return (
    <div>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          width="100%"
          header="Destination Plate Layout"
          helper="Select a destination plate type and input a base plate
              name. The selected plate type and number of target constructs
              will determine how many destination plates will be required
              for the reaction. Or select a plate map of construct materials
              to dictate destination plate layout."
        />
        <GenericSelect
          name="validationPlateMapGroup"
          schema={genericSelectSchema}
          fragment={genericSelectFragment}
          nameOverride="Plate Map"
          additionalDataFragment={arpPlateMapGroupFragment}
          getButtonText={v =>
            v ? "Change Plate Map" : "Select Plate Map (Optional)"
          }
          buttonProps={{
            loading: loadingPlateMapGroup,
            disabled: isDisabledMap.plateMapGroup
          }}
        />
        {validationPlateMapGroup && (
          <PlateMapView noPadding plateMapGroup={validationPlateMapGroup} />
        )}
        <div
          className="tg-flex column"
          style={{ marginTop: 20, alignItems: "flex-end" }}
        >
          <div style={{ minWidth: "50%" }}>
            <PlateUploadFields
              inTool
              noFileUpload
              noNumTubes
              genericSelectOptions={{
                tableParamOptions: {
                  additionalFilter
                }
              }}
            />
            <InputField
              name="destinationPlateBaseName"
              label="Base Plate Name"
              isRequired
              placeholder="Enter base plate name..."
              generateDefaultValue={{
                ...defaultValueConstants.DESTINATION_CONTAINER_NAME,
                customParams: {
                  containerType: "Plate"
                }
              }}
            />
            <CheckboxField
              name="generateBarcodes"
              label="Generate Barcodes"
              defaultValue
            />
            <RadioGroupField
              inline
              label="Destination Plate Layout"
              name="destinationDirection"
              generateDefaultValue={
                defaultValueConstants.ASSEMBLY_REACTION_PLANNING_DESTINATION_WELL_ORDER
              }
              options={
                defaultValueConstants
                  .ASSEMBLY_REACTION_PLANNING_DESTINATION_WELL_ORDER.options
              }
            />
            {!generateBarcodes &&
              numDestinationPlates > 0 &&
              times(numDestinationPlates, index => {
                return (
                  <div key={index}>
                    <InputField
                      name={`destinationPlateBarcodes.${index}`}
                      label={
                        "Barcode for " +
                        destinationPlateBaseName +
                        " " +
                        (index + 1)
                      }
                      placeholder="Enter plate barcode..."
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="tg-step-form-section column">
        <div className="tg-flex justify-space-between">
          <HeaderWithHelper
            header="Material Transfer Information"
            helper="Enter desired mass or volume to transfer for each assembly piece or apply a universal transfer."
          />
        </div>
        <CheckboxField
          name="applyUniversalTransfer"
          label="Apply Universal Transfer"
        />
        {applyUniversalTransfer && (
          <div className="tg-flex">
            <div style={{ marginRight: 20 }}>
              <SelectField
                label="Quantity Type"
                name="universalTransferType"
                options={["volume", "mass"]}
                defaultValue="volume"
              />
            </div>
            <div className="input-with-unit-select">
              <NumericInputField
                name="universalTransferValue"
                label="Value"
                min={0}
              />
              {(function () {
                const defaultValue =
                  universalTransferType === "volume" ? "uL" : "ng";
                return (
                  <SelectField
                    className="tg-unit-select"
                    label="none"
                    defaultValue={defaultValue}
                    enableReinitialize
                    name="universalTransferUnitCode"
                    options={unitGlobals.getOptionsForSelect(
                      universalTransferType === "volume"
                        ? "volumetricUnit"
                        : "massUnit"
                    )}
                  />
                );
              })()}
            </div>
          </div>
        )}
        {worklistError && <BlueprintError error={worklistError} />}
        {!applyUniversalTransfer && (
          <DataTable
            isSimple
            entities={assemblyMaterials}
            noSelect
            keepDirtyOnReinitialize
            destroyOnUnmount={false}
            formName={toolSchema.code}
            schema={materialTransferSchema}
          />
        )}
      </div>
      <Footer
        {...footerProps}
        nextButton={
          <Button
            intent={Intent.PRIMARY}
            disabled={worklistError}
            onClick={handleSubmit(onStepSubmit)}
          >
            Next
          </Button>
        }
      />
    </div>
  );
};

export default DestinationPlateConfiguration;
