/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "redux";
import { showDialog } from "../GlobalDialog";
import AbstractLibrary from "../AbstractLibrary";
import gql from "graphql-tag";
import libraryEnhancer from "../libraryEnhancer";
import { withRouter } from "react-router-dom";
import CreateRNATypeDialog from "../components/Dialogs/CreateRNATypeDialog";
import { Tooltip, Icon } from "@blueprintjs/core";
import { safeDelete } from "../apolloMethods";
import { showConfirmationDialog } from "@teselagen/ui";

const schema = {
  model: "rnaType",
  fields: [
    {
      displayName: "Name",
      render: (v, r) => {
        return (
          <span>
            {r.isSystemType ? (
              <Tooltip content="Created by system">
                <Icon icon="cog" style={{ marginRight: 5 }} />
              </Tooltip>
            ) : null}
            {r.name}
          </span>
        );
      }
    },
    {
      path: "description",
      type: "string",
      displayName: "Description",
      isHidden: true
    }
  ]
};

class RNATypeLibrary extends Component {
  onNewItemClick = () => {
    showDialog({
      ModalComponent: CreateRNATypeDialog,
      modalProps: {
        refetchRnaTypes: this.props.refetch
      }
    });
  };

  onDoubleClick = rnaType => {
    const { refetch } = this.props;
    showDialog({
      ModalComponent: CreateRNATypeDialog,
      modalProps: {
        refetchRnaTypes: refetch,
        initialValues: {
          ...rnaType
        }
      }
    });
  };

  onDelete = async ({ records, refetch }) => {
    try {
      if (records.some(record => record.isSystemType)) {
        return window.toastr.error("Cannot delete system type.");
      } else {
        const confirm = await showConfirmationDialog({
          text: `Are you sure you want to delete this RNA Type? You cannot undo this action.`,
          intent: "danger",
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          canEscapeKeyCancel: true
        });
        if (confirm) {
          await safeDelete(
            "rnaType",
            records.map(r => r.id)
          );
          refetch();
        }
      }
    } catch (error) {
      console.error(error);
      window.toastr.error("Error deleting RNA type.");
    }
  };

  render() {
    const { readOnly } = this.props;
    return (
      <div className="tg-card">
        <AbstractLibrary
          {...this.props}
          noOpen
          noRename
          readOnly={readOnly}
          isLibraryTable
          onEdit={this.onDoubleClick}
          onDoubleClick={this.onDoubleClick}
          onNewItemClick={this.onNewItemClick}
          onDelete={this.onDelete}
        />
      </div>
    );
  }
}

const fragment = gql`
  fragment rnaTypeFragment on rnaType {
    id
    name
    description
    isSystemType
  }
`;

export default compose(
  withRouter,
  libraryEnhancer({
    fragment,
    schema,
    withSelectedEntities: true,
    noAddedBy: true
  })
)(RNATypeLibrary);
