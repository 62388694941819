/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import DownstreamAutomationParameters from "../../../distribute_pcr_reactions/structs/DownstreamAutomationParameters";
import { assignAliquotContainerPosition } from "../../../utils/plateUtils";
import { chunk } from "lodash";
import PcrReaction from "../../../distribute_pcr_reactions/structs/PcrReaction";
import DistributePcrReactions from "../../../distribute_pcr_reactions";
import { calculateNebTa } from "@teselagen/sequence-utils";
import { getSequence } from "../../../../../tg-iso-shared/src/utils/getSequence";
import PcrBlock from "../../../distribute_pcr_reactions/structs/PcrBlock";

/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export function isSequenceInInventory(sequence: {
  containerCount?: number;
  aliquots: { aliquotContainer: any }[];
}) {
  if (sequence?.containerCount) {
    return sequence.containerCount > 0;
  } else {
    return sequence.aliquots?.some(aliquot => aliquot.aliquotContainer);
  }
}

export function distributeIntoTemperatureBlocks({
  selectedPcrReactions,
  sequencesWithBps = {},
  containerFormat,
  distributeMethod,
  zonesPerPlate,
  temperatureZoneOrientation
}: {
  selectedPcrReactions: {
    id: number;
    oligoMeanTm: number;
    oligoDeltaTm: number;
    forwardPrimer: { sequenceId: number } | {};
    reversePrimer: { sequenceId: number } | {};
  }[];
  sequencesWithBps?: { [key: string]: any };
  containerFormat: { columnCount: number; rowCount: number };
  distributeMethod?: "disableZones" | "neb";
  zonesPerPlate: number;
  temperatureZoneOrientation: "vertical" | "horizontal";
}) {
  const columnCount = containerFormat.columnCount;
  const rowCount = containerFormat.rowCount;
  let plateMapItemGroups;
  let pcrBlocks: PcrBlock[] | undefined;
  if (distributeMethod === "disableZones") {
    plateMapItemGroups = chunk(
      selectedPcrReactions,
      columnCount * rowCount
    ).map(group => {
      return assignAliquotContainerPosition(
        group,
        { rowCount, columnCount },
        { columnFirst: true }
      );
    });
  } else {
    const zones_per_thermocycler_block =
      zonesPerPlate || (temperatureZoneOrientation === "vertical" ? 6 : 4);
    const wells_per_thermocycler_zone =
      (columnCount * rowCount) / zones_per_thermocycler_block;
    const downstreamAutomationParameters = new DownstreamAutomationParameters({
      max_delta_temperature_adjacent_zones: 5,
      max_delta_temperature_reaction_optimum_zone_acceptable: 5,
      max_mc_steps_per_zone: 1000,
      max_well_volume_multi_well_plate: 100,
      mc_temperature_initial: 0.1,
      mc_temperature_final: 0.0001,
      min_pipetting_volume: 5,
      trial_delta_temperature: 0.1,
      wells_per_thermocycler_zone,
      n_columns_multi_well_plate: columnCount,
      zones_per_thermocycler_block,
      n_rows_multi_well_plate: rowCount
    });

    const pcrReactionsInput = selectedPcrReactions.map(
      ({
        id,
        oligoMeanTm: primer_mean_tm,
        oligoDeltaTm: primer_delta_tm,
        forwardPrimer = {},
        reversePrimer = {}
      }: {
        id: number;
        oligoMeanTm: number;
        oligoDeltaTm: number;
        forwardPrimer: { sequenceId: number } | {};
        reversePrimer: { sequenceId: number } | {};
      }) => {
        const forwardPrimerSequence =
          "sequenceId" in forwardPrimer
            ? sequencesWithBps[forwardPrimer?.sequenceId]
            : false;
        const reversePrimerSequence =
          "sequenceId" in reversePrimer
            ? sequencesWithBps[reversePrimer?.sequenceId]
            : false;
        if (
          distributeMethod === "neb" &&
          forwardPrimerSequence &&
          reversePrimerSequence
        ) {
          const primerSeqs = [
            getSequence(forwardPrimerSequence),
            getSequence(reversePrimerSequence)
          ];
          // primer concentration in Q5 PCR protocol is 500 nM
          const primerConc = 0.0000005;
          // 50 mM KCl in Q5 PCR protocol
          const options = { monovalentCationConc: 0.05, polymerase: "Q5" };
          const annealingTemp = calculateNebTa(
            primerSeqs,
            primerConc,
            options
          ) as number;
          return new PcrReaction({
            id,
            optimal_annealing_temperature: annealingTemp
          });
        } else {
          return new PcrReaction({
            id,
            primer_mean_tm,
            primer_delta_tm
          });
        }
      }
    );

    pcrBlocks = DistributePcrReactions(
      downstreamAutomationParameters,
      pcrReactionsInput
    );

    const columnsPerZone = wells_per_thermocycler_zone / rowCount;
    const rowsPerZone = wells_per_thermocycler_zone / columnCount;

    const plateMapItemsOnBlocks = pcrBlocks.map(pcrBlock => {
      return pcrBlock.zone_reaction_list
        .map((reactions, zoneIndex) =>
          reactions.map((reaction, reactionIndex) => {
            let rowPosition, columnPosition;
            if (temperatureZoneOrientation === "vertical") {
              rowPosition = reactionIndex % rowCount;
              columnPosition =
                zoneIndex * columnsPerZone +
                Math.floor(reactionIndex / rowCount);
            } else {
              rowPosition =
                zoneIndex * rowsPerZone +
                Math.floor(reactionIndex / columnCount);
              columnPosition = reactionIndex % columnCount;
            }
            return {
              ...selectedPcrReactions.find(({ id }) => id === reaction.id),
              rowPosition,
              columnPosition
            };
          })
        )
        .reduce((a, b) => a.concat(b), []);
    });
    plateMapItemGroups = plateMapItemsOnBlocks;
  }

  return plateMapItemGroups.map(
    (
      plateMapItems: {
        id: string;
        rowPosition: number;
        columnPosition: number;
        containerArray: {};
        containerArrayType: {};
      }[],
      i
    ) => ({
      plateMapItems,
      temperatureZones: pcrBlocks && pcrBlocks[i].zone_temperature,
      temperatureZoneOrientation,
      name: `PCR Plate Map ${plateMapItemGroups.length === 1 ? "" : i + 1}`,
      type: "j5PcrReaction",
      plateMapTypeCode: "J5_PCR_REACTION"
    })
  );
}
