/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { round } from "lodash";

export function addToUniqArray(array, item) {
  if (item && !array.includes(item)) {
    array.push(item);
  }
}

/**
 *
 * @param {number} x
 * @param {number} precision
 * @returns {number}
 */
export function toDecimalPrecision(x, precision = 2) {
  const integralPart = x | 0;
  const fractionalPart = x - integralPart;
  if (integralPart >= 1) {
    return (
      integralPart + round(Number(fractionalPart.toPrecision(precision)), 2)
    );
  }
  return integralPart + Number(fractionalPart.toPrecision(precision));
}

/**
 * Creates a read-only enumeration object. Useful when there is no typescript.
 *
 * This function takes an existing enumeration object and returns a new object that is a
 * read-only version of the original enum. The returned object uses a Proxy to intercept
 * and manage property accesses. It prevents modification of the enum and ensures that
 * only existing enum keys can be accessed.
 *
 * @param {Object} baseEnum - The base enumeration object to be converted into a read-only enum.
 * @returns {Proxy} A Proxy-wrapped enumeration object that is read-only.
 * @throws {Error} Throws an error if an attempt is made to access a non-existent enum key.
 * @throws {Error} Throws an error if an attempt is made to modify the enum (add or change properties).
 *
 * @example
 * // Example usage
 * const Colors = Enum({ RED: '#FF0000', GREEN: '#00FF00', BLUE: '#0000FF' });
 * console.log(Colors.RED); // Outputs: #FF0000
 * Colors.NEW_COLOR = '#FFFFFF'; // Throws error: Cannot add a new value to the enum
 * console.log(Colors.PURPLE); // Throws error: "PURPLE" value does not exist in the enum
 */
export function Enum(baseEnum) {
  // eslint-disable-next-line no-undef
  return new Proxy(baseEnum, {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    get(_target, name) {
      if (name === "$$typeof") return typeof baseEnum;
      if (!baseEnum.hasOwnProperty(name)) {
        throw new Error(`"${name}" value does not exist in the enum`);
      }
      return baseEnum[name];
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    set(_target, _name, _value) {
      throw new Error("Cannot add a new value to the enum");
    }
  });
}
