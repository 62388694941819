/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback } from "react";
import { compose, withProps } from "recompose";
import { CollapsibleCard, DataTable, withTableParams } from "@teselagen/ui";
import routeDoubleClick from "../utils/routeDoubleClick";
import gql from "graphql-tag";
import { tagColumnWithRender } from "../utils/tagColumn";
import { addTagFilterToQuery } from "../utils/tagUtils";
import withQuery from "../withQuery";
import { taggedItemFragment } from "../../../tg-iso-shared/src/fragments/taggedItemFragment";

function FpusCard({ tableParams }) {
  return (
    <CollapsibleCard title="Functional Protein Units">
      <DataTable {...tableParams} noPadding onDoubleClick={routeDoubleClick} />
    </CollapsibleCard>
  );
}

const schema = {
  model: "functionalProteinUnit",
  fields: [
    {
      displayName: "Name",
      path: "name"
    },
    tagColumnWithRender
  ]
};

const fragment = gql`
  fragment aminoAcidRecordFpus on functionalProteinUnit {
    id
    name
    taggedItems {
      ...taggedItemFragment
    }
  }
  ${taggedItemFragment}
`;

export default compose(
  withProps(props => {
    // Added while #12943 is not ready
    const additionalFilter = useCallback(
      (_, qb, currentParams) => {
        addTagFilterToQuery(currentParams.tags, qb);
        qb.whereAll({
          "proteinSubUnits.aminoAcidSequenceId": props.aminoAcidSequenceId
        });
      },
      [props.aminoAcidSequenceId]
    );
    return { additionalFilter };
  }),
  withTableParams({
    formName: "aminoAcidFpuCard",
    urlConnected: false,
    schema
  }),
  withQuery(fragment, {
    isPlural: true
  })
)(FpusCard);
