/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { Callout } from "@blueprintjs/core";
import { endsWith } from "lodash";

export function createInputCallout(
  labelString: string,
  labelArray: string[],
  disabledByWorkflowInt: boolean
) {
  let innerString = `The ${labelString} `;
  if (labelArray.length === 1 && !endsWith(labelArray[0], "s")) {
    innerString += "has ";
  } else innerString += "have ";
  innerString += "been ";
  if (disabledByWorkflowInt) innerString += "disabled by workflow integration.";
  else innerString += "pre-selected through workflow integration.";

  return (
    <Callout style={{ marginTop: 15 }} intent="primary">
      {innerString}
    </Callout>
  );
}
