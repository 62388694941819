/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { flatMap, keyBy, map, startCase, upperCase } from "lodash";
import React from "react";
import { compose } from "recompose";
import {
  DialogFooter,
  showConfirmationDialog,
  wrapDialog
} from "@teselagen/ui";
import { Link } from "react-router-dom";
import {
  autoAnnotate,
  convertApELikeRegexToRegex
} from "@teselagen/sequence-utils";
import { hideDialog } from "../GlobalDialog";
import GenericSelect from "../GenericSelect";
import { reduxForm } from "redux-form";
import classNames from "classnames";
import { Classes } from "@blueprintjs/core";
import { safeQuery, safeUpsert } from "../apolloMethods";
import pluralize from "pluralize";
import sIfPlural from "../utils/sIfPlural";
import { getSequence } from "../../../tg-iso-shared/src/utils/getSequence";
import { registeredAnnotationGroupsAdditionalFragment } from "./registeredAnnotationGroupsAdditionalFragment.gql";

const warnIfMoreThan = 10;
const fragment = ["registeredAnnotationGroup", "id name updatedAt"];

const annotationTypeToModel = {
  OLIGO: "oligo", //todo-tnrUpdateOligoDM
  FEATURE: "sequenceFeature",
  PART: "part"
};

export const AutoAnnotateDialog = compose(
  wrapDialog({
    title: `Auto Annotate`
  }),
  reduxForm({ form: "AutoAnnotateDialog" })
)(({
  annotationType, //part|feature|oligo
  submitting,
  handleSubmit,
  justPassResultsCallback,
  refetch = () => {},
  doNotShowConfirmation,
  sequenceIds
}) => {
  const modelType = annotationTypeToModel[upperCase(annotationType)];

  const onSubmit = async ({ selectedAnnotationGroups }) => {
    //get all sequences
    const sequences = await safeQuery(
      getAutoAnnotateSequenceFragment(modelType),
      {
        variables: {
          filter: {
            id: sequenceIds
          }
        }
      }
    );

    //get all annotations in the group (including their sequences)
    //call autoAnnotate to get the annotations to add
    const seqsToAnnotateById = keyBy(
      map(sequences, sequence => ({
        id: sequence.id,
        name: sequence.name,
        circular: sequence.circular,
        annotations: sequence[pluralize(modelType)],
        sequence: getSequence(sequence)
      })),
      "id"
    );
    const annotationsToCheckById = keyBy(
      flatMap(selectedAnnotationGroups, g =>
        flatMap(g.registeredAnnotationToGroups, rg => {
          const ann = rg.registeredAnnotation;
          return {
            ...ann,
            sequence: ann.isRegex
              ? ann.sequence
              : convertApELikeRegexToRegex(ann.sequence)
          };
        })
      ),
      "id"
    );

    const { __more_than_warnings = {}, ...annotationsToAddBySeqId } =
      autoAnnotate({
        seqsToAnnotateById,
        annotationsToCheckById,
        warnIfMoreThan
      });
    let excludeExcessAnns;
    if (Object.values(__more_than_warnings).length) {
      excludeExcessAnns = await showConfirmationDialog({
        text: (
          <div>
            We detected that more than {warnIfMoreThan} of the same annotation
            will be created on the following sequence
            {Object.values(__more_than_warnings).length > 1 ? "s" : ""}:
            <br />
            <br />
            <div
              style={{
                display: "grid",
                columnGap: 20,
                rowGap: 10,
                gridTemplateColumns: "1fr 2fr",
                maxHeight: 200,
                overflow: "auto"
              }}
            >
              <div style={{ fontWeight: "bold" }}>Sequence:</div>
              <div style={{ fontWeight: "bold" }}>Annotations:</div>
              {map(__more_than_warnings, (annIds, seqId) => {
                return [
                  <div key={seqId}>{seqsToAnnotateById[seqId].name} </div>,
                  <div key={seqId + "anns"}>
                    {annIds
                      .map(id => annotationsToCheckById[id].name)
                      .join(" ")}
                  </div>
                ];
              })}
            </div>
            <br></br>
            Should we exclude these annotations from the process?
          </div>
        ),
        confirmButtonText: "Exclude",
        cancelButtonText: "Leave in"
      });
    }

    const annotationsToCreate = flatMap(
      annotationsToAddBySeqId,
      (anns, seqId) =>
        flatMap(anns, ({ start, end, strand, id }) => {
          if (excludeExcessAnns) {
            if ((__more_than_warnings[seqId] || []).includes(id)) {
              return [];
            }
          }
          const { name, type } = annotationsToCheckById[id];
          return {
            name,
            ...(modelType === "sequenceFeature" && {
              type: type || "misc_feature"
            }),
            start,
            end,
            strand,
            sequenceId: seqId,
            registeredAnnotationId: id
          };
        })
    );
    if (justPassResultsCallback) {
      return justPassResultsCallback(annotationsToCreate);
    }

    //pop up a dialog showing what will be added?
    const confirm = doNotShowConfirmation
      ? true
      : await showConfirmationDialog({
          text: `This will create ${
            flatMap(annotationsToCreate).length
          } new ${pluralize(annotationType)} on ${
            Object.keys(annotationsToAddBySeqId).length
          } sequences. Are you sure you want to do this?`,
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          canEscapeKeyCancel: true
        });
    if (confirm) {
      //create the new annotations!
      await safeUpsert(modelType, annotationsToCreate);

      await refetch();
      hideDialog();
    }
  };

  return (
    <>
      <div
        className={classNames(Classes.DIALOG_BODY, "tg-auto-annotate-dialog")}
      >
        <GenericSelect
          isRequired
          name="selectedAnnotationGroups"
          isMultiSelect
          asReactSelect
          fragment={fragment}
          reactSelectProps={{
            noResultsText: `No Groups Found. Add a ${startCase(
              annotationType
            )} Group First...`
          }}
          label={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>
                Select {startCase(annotationType)} Groups to Annotate With
              </span>{" "}
              <Link
                to={`/settings/${annotationType}-management/${annotationType}-groups`}
              >
                {" "}
                Add/Edit {startCase(annotationType)} Groups
              </Link>{" "}
            </div>
          }
          additionalDataFragment={registeredAnnotationGroupsAdditionalFragment}
          additionalFilter={(props, qb) => {
            qb.whereAll({
              registeredAnnotationTypeCode: upperCase(annotationType)
            });
          }}
        />
      </div>
      <DialogFooter
        text={`Annotate ${sequenceIds.length} Sequence${sIfPlural(
          sequenceIds
        )}`}
        hideModal={hideDialog}
        submitting={submitting}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
});

function getAutoAnnotateSequenceFragment(modelType) {
  return [
    "sequence",
    `
  id
  name
  circular
  sequenceFragments {
    id
    fragment
    index
  }
  ${pluralize(modelType)} {
    id
    name
    start
    end
    strand
  }
`
  ];
}
