/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback } from "react";
import shortid from "shortid";
import { forEach, isEmpty } from "lodash";
import { compose } from "recompose";
import { Callout, Classes } from "@blueprintjs/core";
import { get } from "lodash";
import mustache from "mustache";
import { reduxForm, change as _change } from "redux-form";
import {
  DialogFooter,
  InputField,
  TextareaField,
  wrapDialog,
  throwFormError
} from "@teselagen/ui";
import { asyncValidateStrainName } from "../CreateNewStrainDialog";
import { safeQuery, safeUpsert } from "../../../../src-shared/apolloMethods";
import MustacheTemplateField from "../../../../src-shared/MustacheTemplateField";
import materialRecordFragment from "../../../graphql/fragments/materialRecordFragment";
import { useDispatch } from "react-redux";

// tgreen: can't use redux form async validate until this issue is fixed
// there is an uncaught error if you open the promote to strain dialog and submit without changing the name
// (if a strain with that name already exists)
// https://github.com/redux-form/redux-form/issues/4026
const strainTemplateVariables = ["material_name", "strain_name", "genome_name"];
const cellLineTemplateVariables = ["cell_culture_name", "cell_line_name"];

const formName = "PromoteToStrain";

const PromoteToStrainDialog = ({
  hideModal,
  history,
  selectedMaterials = [],
  strainTypeCode,
  initialValues,
  handleSubmit,
  submitting,
  tooltipInfo
}) => {
  const dispatch = useDispatch();
  const change = useCallback(
    (field, value) => dispatch(_change(formName, field, value)),
    [dispatch]
  );

  const onSubmit = useCallback(
    async values => {
      try {
        await asyncValidateStrainName({ name: values.name }, null, {
          initialValues,
          strainTypeCode
        });
      } catch (error) {
        throwFormError(error);
      }
      try {
        const { materialIdToStrainMap, name, description, genotype } = values;
        const strainsToCreate = [];
        const strainCidToSelectionMethodIds = {};
        const strainCidToInductionMethodIds = {};
        const multipleMaterials = selectedMaterials.length > 1;
        const materialIds = selectedMaterials.map(m => m.id);
        const materials = await safeQuery(materialRecordFragment, {
          variables: { filter: { id: materialIds } }
        });
        const strainPlasmidsToCreate = [];

        materials.forEach(material => {
          const strainCid = shortid();
          const materialConditions = material.growthConditionOverwrite || {};
          const strainConditions =
            get(material, "strain.growthCondition") || {};
          let growthCondition = {};
          const growthFields = [
            "name",
            "description",
            "shakerSpeed",
            "shakerThrow",
            "lengthUnitCode",
            "temperature",
            "humidity",
            "growthMediaId",
            "gasCompositionId"
          ];
          growthFields.forEach(field => {
            const matField = get(materialConditions, field);
            const strainField = get(strainConditions, field);
            const valToUse = matField || strainField;
            if (valToUse) {
              growthCondition[field] = valToUse;
            }
          });

          const specieId = get(material, "strain.specie.id");
          const genomeId =
            get(material, "genome.id") || get(material, "strain.genome.id");
          const plasmidArray =
            material.microbialMaterialMicrobialMaterialPlasmids?.length > 0
              ? material.microbialMaterialMicrobialMaterialPlasmids
              : material.cellCultureCellCulturePlasmids;
          if (plasmidArray?.length > 0) {
            plasmidArray.forEach(mmp => {
              strainPlasmidsToCreate.push({
                strainId: `&${strainCid}`,
                polynucleotideMaterialId: mmp.polynucleotideMaterialId
              });
            });
          }
          let biosafetyLevelCode;
          if (get(material, "biosafetyLevelOverwrite.code")) {
            biosafetyLevelCode = material.biosafetyLevelOverwrite.code;
          } else {
            biosafetyLevelCode = get(material, "strain.biosafetyLevel.code");
          }
          let targetOrganismClassId;
          if (get(material, "targetOrganismClassOverwrite.id")) {
            targetOrganismClassId = material.targetOrganismClassOverwrite.id;
          } else {
            targetOrganismClassId = get(
              material,
              "strain.targetOrganismClass.id"
            );
          }
          strainCidToSelectionMethodIds[strainCid] = [];
          if (strainTypeCode === "MICROBIAL_STRAIN") {
            if (
              material.microbialMaterialMicrobialMaterialSelectionMethods
                ?.length > 0
            ) {
              material.microbialMaterialMicrobialMaterialSelectionMethods.forEach(
                mmsm => {
                  strainCidToSelectionMethodIds[strainCid].push(
                    mmsm.selectionMethod.id
                  );
                }
              );
            } else if (material.strain?.strainSelectionMethods?.length > 0) {
              material.strain.strainSelectionMethods.forEach(ssm => {
                strainCidToSelectionMethodIds[strainCid].push(
                  ssm.selectionMethod.id
                );
              });
            }
          } else {
            if (material.cellCultureCellCultureSelectionMethods?.length > 0) {
              material.cellCultureCellCultureSelectionMethods.forEach(ccsm => {
                strainCidToSelectionMethodIds[strainCid].push(
                  ccsm.selectionMethod.id
                );
              });
            } else if (material.strain?.strainSelectionMethods?.length > 0) {
              material.strain.strainSelectionMethods.forEach(ssm => {
                strainCidToSelectionMethodIds[strainCid].push(
                  ssm.selectionMethod.id
                );
              });
            }
          }
          if (material.strain?.inductionMethodStrains?.length) {
            strainCidToInductionMethodIds[strainCid] = [];
            get(material, "strain.inductionMethodStrains").forEach(ims => {
              strainCidToInductionMethodIds[strainCid].push(
                ims.inductionMethod.id
              );
            });
          }
          let sourceMicrobialMaterialId;
          let sourceCellCultureId;
          if (material.materialTypeCode === "MICROBIAL") {
            sourceMicrobialMaterialId = material.id;
          } else {
            sourceCellCultureId = material.id;
          }
          if (isEmpty(growthCondition)) {
            // ignore empty
            growthCondition = null;
          }
          strainsToCreate.push({
            cid: strainCid,
            name: name || materialIdToStrainMap[material.id],
            description,
            genotype,
            specieId,
            genomeId,
            biosafetyLevelCode,
            targetOrganismClassId,
            sourceMicrobialMaterialId,
            sourceCellCultureId,
            strainTypeCode,
            growthCondition
          });
        });

        const [{ id }] = await safeUpsert("strain", strainsToCreate);
        const strainSelectionMethodsToCreate = [];
        const inductionMethodStrainsToCreate = [];

        forEach(
          strainCidToSelectionMethodIds,
          (selectionMethodIds, strainCid) => {
            selectionMethodIds.forEach(selectionMethodId => {
              strainSelectionMethodsToCreate.push({
                strainId: `&${strainCid}`,
                selectionMethodId
              });
            });
          }
        );

        forEach(
          strainCidToInductionMethodIds,
          (inductionMethodIds, strainCid) => {
            inductionMethodIds.forEach(inductionMethodId => {
              inductionMethodStrainsToCreate.push({
                strainId: `&${strainCid}`,
                inductionMethodId
              });
            });
          }
        );

        await safeUpsert("strainPlasmid", strainPlasmidsToCreate);
        await safeUpsert(
          "strainSelectionMethod",
          strainSelectionMethodsToCreate
        );
        await safeUpsert(
          "inductionMethodStrain",
          inductionMethodStrainsToCreate
        );
        hideModal();
        if (strainTypeCode === "MICROBIAL_STRAIN") {
          if (multipleMaterials) {
            history.push(`/microbial-strains`);
          } else {
            history.push(`/microbial-strains/${id}`);
          }
        } else {
          if (multipleMaterials) {
            history.push(`/cell-lines`);
          } else {
            history.push(`/cell-lines/${id}`);
          }
        }
      } catch (error) {
        console.error("error:", error);
        window.toastr.error("Error promoting microbial material to strain.");
      }
    },
    [hideModal, history, initialValues, selectedMaterials, strainTypeCode]
  );

  const updateStrainNames = useCallback(
    ({ template }) => {
      if (template && selectedMaterials.length > 0) {
        const materialIdToStrainMap = {};
        selectedMaterials.forEach(material => {
          const val = mustache.render(template, {
            material_name: material.name,
            cell_culture_name: material.name,
            strain_name: material.strain?.name,
            cell_line_name: material.strain?.name,
            genome_name: material.strain?.genome?.name
          });
          materialIdToStrainMap[material.id] = val;
        });
        change("materialIdToStrainMap", materialIdToStrainMap);
      }
    },
    [change, selectedMaterials]
  );

  const onPromoteToStrainTemplateSubmit = useCallback(
    template => {
      updateStrainNames({
        template
      });
    },
    [updateStrainNames]
  );

  const defaultValue =
    strainTypeCode === "MICROBIAL_STRAIN"
      ? "{{{material_name}}}"
      : "{{{cell_culture_name}}}";

  const templateVariables =
    strainTypeCode === "MICROBIAL_STRAIN"
      ? strainTemplateVariables
      : cellLineTemplateVariables;

  const multipleMaterials = selectedMaterials.length > 1;
  const material = selectedMaterials[0];
  let materialType;
  let strainType;
  if (strainTypeCode === "MICROBIAL_STRAIN") {
    materialType = "material";
    strainType = "strain";
  } else {
    materialType = "cell culture";
    strainType = "cell line";
  }
  const callOutText = `Because you are promoting multiple ${materialType}s we
    have provided the template system below for naming the new ${strainType}s. Select
    variables below and/or enter text as you see fit.`;

  return (
    <div>
      <div className={Classes.DIALOG_BODY}>
        {multipleMaterials ? (
          <>
            <Callout intent="primary" style={{ marginBottom: 10 }}>
              {callOutText}
            </Callout>
            <MustacheTemplateField
              name="promoteToStrainTemplate"
              label="Strain Name Template"
              onFieldSubmit={onPromoteToStrainTemplateSubmit}
              defaultValue={defaultValue}
              templateVariables={templateVariables}
              tooltipInfo={tooltipInfo}
            />
          </>
        ) : (
          <>
            <InputField
              name="name"
              label="Name"
              isRequired
              defaultValue={material.name}
            />
            <InputField
              name="description"
              label="Description"
              defaultValue={get(material, "strain.description")}
            />
            <TextareaField
              name="genotype"
              label="Genotype"
              defaultValue={get(material, "strain.genotype")}
            />
          </>
        )}
      </div>
      <DialogFooter
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
        submitting={submitting}
      />
    </div>
  );
};

export default compose(
  wrapDialog({
    getDialogProps: ({ strainTypeCode }) => {
      return {
        title:
          strainTypeCode === "MICROBIAL_STRAIN"
            ? "Promote to Strain"
            : "Promote to Cell Line"
      };
    }
  }),
  reduxForm({
    form: formName
  })
)(PromoteToStrainDialog);
