/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import {
  addActiveProjectFilter,
  shouldShowProjects
} from "../utils/projectUtils";
import { addTagFilterToQuery } from "../utils/tagUtils";
import { getModelNameFromFragment } from "@teselagen/apollo-methods";
import { aliasModels } from "@teselagen/constants";
import {
  expirationDateModels,
  tagModels
} from "../../../tg-iso-shared/constants";
import { aliasColumn, dateModifiedColumn } from "../utils/libraryColumns";
import { tagColumnWithRender } from "../utils/tagColumn";
import { combineGqlFragments } from "../../../tg-iso-shared/utils/gqlUtils";
import type { DocumentNode } from "graphql";

const taggedItemFrag = `
  taggedItems {
    id
    tag {
      id
      name
      color
    }
    tagOption {
      id
      name
      color
    }
  }
`;

const aliasFrag = `
  aliases {
    id
    name
  }
`;

type Field = {
  key?: string;
  label?: string;
  type: string;
  width?: number;
  noEllipsis?: boolean;
  immovable?: boolean;
  render?: (v: any, record: any) => React.ReactNode;
};

type Schema =
  | {
      model: string;
      fields: Field[];
    }
  | Field[];

type Props = {
  additionalFilter?: {};
  asReactSelect?: boolean;
  dialogProps?: { style?: React.CSSProperties };
  fragment: DocumentNode | [string, string];
  name: string;
  schema?: { fields: {}[]; model: string };
  tableParamOptions?: { additionalFilter?: {} };
  useHasura?: boolean;
  postSelectDTProps?: { schema: Schema; isSingleSelect: boolean };
};

const useGenericSelectProps = ({
  additionalFilter: _additionalFilter,
  asReactSelect,
  dialogProps = {},
  fragment,
  name,
  schema: _schema,
  tableParamOptions = {},
  useHasura
}: Props) => {
  // Note: All of these include "something" variable will only work when passing a fragment, as it adds extraFragments to the query
  // When passing entities directly this is not done or has to be handled outside
  const { includeTags, includeAliases } = useMemo(() => {
    const model = getModelNameFromFragment(fragment);
    return {
      includeTags: !asReactSelect && tagModels.includes(model),
      includeAliases: !asReactSelect && aliasModels.includes(model)
    };
  }, [fragment, asReactSelect]);

  const { includeProjects, includeExpirationDate } = useMemo(() => {
    const model = getModelNameFromFragment(fragment);
    return {
      includeProjects: shouldShowProjects(model),
      includeExpirationDate: expirationDateModels.includes(model)
    };
  }, [fragment]);

  const additionalFilter = useMemo(() => {
    const passedAdditionalFilter =
      tableParamOptions.additionalFilter || _additionalFilter;
    const model = getModelNameFromFragment(fragment);
    const tagAndProjectFilter = (
      _: any,
      qb: any,
      currentParams: { tags: string[] }
    ) => {
      if (includeTags) {
        addTagFilterToQuery(currentParams?.tags, qb);
      }
      if (includeProjects) {
        addActiveProjectFilter(qb, {
          model
        });
      }
    };
    if (passedAdditionalFilter) {
      if (useHasura) {
        return passedAdditionalFilter;
      }
      return (
        ...args: [_: any, qb: any, currentParams: { tags: string[] }]
      ) => {
        tagAndProjectFilter(...args);
        const qb = args[1];
        if (typeof passedAdditionalFilter === "function") {
          const filter = passedAdditionalFilter(...args);
          qb.whereAll(filter);
        } else {
          qb.whereAll(passedAdditionalFilter);
        }
      };
    }
    return tagAndProjectFilter;
  }, [
    _additionalFilter,
    fragment,
    includeProjects,
    includeTags,
    tableParamOptions.additionalFilter,
    useHasura
  ]);

  const schema = useMemo(() => {
    let fieldsToUse: any[];
    if (_schema) {
      fieldsToUse = _schema.fields || _schema;
    } else if (asReactSelect) {
      fieldsToUse = ["name"];
    } else {
      fieldsToUse = ["name", dateModifiedColumn];
    }
    if (!fragment) return { model: _schema?.model, fields: fieldsToUse };
    const extraColumns = [];
    if (includeAliases) {
      extraColumns.push(aliasColumn);
    }
    if (includeTags) {
      extraColumns.push(tagColumnWithRender);
    }

    if (fieldsToUse.length === 1) {
      fieldsToUse = [fieldsToUse[0], ...extraColumns];
    } else {
      fieldsToUse = [
        ...fieldsToUse.slice(0, fieldsToUse.length - 1),
        ...extraColumns,
        ...fieldsToUse.slice(fieldsToUse.length - 1)
      ];
    }
    return {
      model: fragment ? getModelNameFromFragment(fragment) : _schema?.model,
      fields: fieldsToUse
    };
  }, [asReactSelect, fragment, includeAliases, includeTags, _schema]);

  const additionalFragment = useMemo(() => {
    if (!fragment) return;
    let _additionalFragment = "\nupdatedAt";
    if (includeExpirationDate) {
      _additionalFragment += "\nexpirationDate";
    }
    if (includeAliases) {
      _additionalFragment += aliasFrag;
    }
    if (includeTags) {
      _additionalFragment += taggedItemFrag;
    }
    return _additionalFragment;
  }, [fragment, includeAliases, includeExpirationDate, includeTags]);

  if (!fragment) {
    return {
      key: name,
      schema
    };
  }

  const fragmentToUse = combineGqlFragments([fragment, additionalFragment]);

  const propsToPass = {
    fragment: fragmentToUse,
    schema,
    dialogProps: {
      ...dialogProps,
      style: {
        ...dialogProps.style,
        width: 750
      }
    },
    tableParamOptions: {
      ...tableParamOptions,
      additionalFilter
    }
  };
  return { key: name, ...propsToPass };
};

export default (Comp: React.FC) => {
  return (props: Props & { [prop: string]: any }) => {
    const propsToPass = useGenericSelectProps(props);
    const allProps = { ...props, ...propsToPass };
    if ("key" in props && props.key !== undefined) {
      allProps.key = props.key;
    }

    return <Comp {...allProps} />;
  };
};
