/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import StepForm from "../../../../src-shared/StepForm";
import SelectQuery, { plateFragment } from "./SelectQuery";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import { safeQuery, safeUpsert } from "../../../../src-shared/apolloMethods";
import gql from "graphql-tag";
import { getUniqueFragmentKey } from "../../../../src-shared/utils/generalUtils";
import { parse } from "hjson";
import { getModelNameFromFragment } from "@teselagen/apollo-methods";
import { get, isObject } from "lodash";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";

const queryFragment = gql`
  fragment queryFragment on customQuery {
    id
    name
    description
    schema
    query
    idFieldName
  }
`;

class RunQueryTool extends React.Component {
  onSubmit = async values => {
    const { containerArrays, customQuery, dataTableName } = values;
    const {
      schema: unparsedSchema = "",
      query: unparsedQuery = "",
      idFieldName = "id"
    } = customQuery;
    const containerArrayIds = containerArrays.map(c => c.id);

    let fragment;
    let parsedSchema;

    try {
      fragment = unparsedQuery.replace(/fragment (.*) on/, (match, $1) => {
        return match.replace($1, $1 + getUniqueFragmentKey());
      });
      fragment = gql(fragment);
    } catch (error) {
      console.error("error parsing fragment:", error);
      window.toastr.error(
        `Error parsing the 'fragment' field of the selected custom query: ${error}`
      );
    }

    try {
      parsedSchema = parse(unparsedSchema);
    } catch (error) {
      console.error("error parsing schema:", error);
      window.toastr.error(
        `Error parsing the 'schema' field of the selected custom query: ${error}`
      );
    }

    try {
      const fields = parsedSchema.fields || parsedSchema;
      const schema = {
        model: getModelNameFromFragment(fragment),
        fields
      };

      schema.fields = schema.fields.map(field => {
        if (field.renderAliquotContainerLocation) {
          field.path = "rowPosition";
          field.displayName = "Location";
          field.render = (v, r) => {
            let aliquotContainer = r;
            if (field.pathToAliquotContainer)
              aliquotContainer = get(r, field.pathToAliquotContainer);
            return getAliquotContainerLocation(aliquotContainer);
          };
        } else if (field.renderAliquotCount) {
          field.displayName = "Aliquot Count";
          field.render = (v, r) => {
            let aliquotContainers = r;
            if (field.pathToAliquotContainers) {
              aliquotContainers = get(r, field.pathToAliquotContainers);
            }
            const aliquots = aliquotContainers.filter(ac => ac.aliquot);
            return aliquots.length;
          };
        }
        return field;
      });
      if (!fragment || !schema || !isObject(schema)) return [];

      const id = idFieldName || "id";
      const plates = await safeQuery(fragment, {
        variables: {
          filter: {
            [id]: containerArrayIds
          }
        }
      });

      const [dataTable] = await safeUpsert("dataTable", {
        name: dataTableName,
        dataTableTypeCode: "CUSTOM_QUERY_RESULTS",
        schema,
        entities: plates
      });

      return {
        dataTable,
        containerArrays: plates
      };
    } catch (error) {
      console.error("error:", error);
      window.toastr.error(`Error: ${error}`);
    }
  };

  render() {
    const {
      toolIntegrationProps,
      toolSchema,
      isToolIntegrated,
      initialValues
    } = this.props;

    const steps = [
      {
        title: "Select Query",
        Component: SelectQuery
      }
    ];

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={isToolIntegrated}
        steps={steps}
        toolSchema={toolSchema}
        onSubmit={this.onSubmit}
        validate={values => {
          const { customQuery } = values;
          const errors = {};
          if (customQuery && !customQuery.schema) {
            errors.error = "Selected custom query is missing a schema";
          } else if (customQuery && !customQuery.query) {
            errors.error = "Selected custom query is missing a query fragment";
          }
          return errors;
        }}
        initialValues={initialValues}
      />
    );
  }
}

export default compose(
  withWorkflowInputs(plateFragment),
  withWorkflowInputs(queryFragment, {
    singular: true
  })
)(RunQueryTool);
