/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { get, size, find, every, uniq, isNil, groupBy, keys } from "lodash";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import queryString from "query-string";
import {
  Button,
  Divider,
  Menu,
  MenuItem,
  Popover,
  Position
} from "@blueprintjs/core";
import classNames from "classnames";
import {
  CheckboxField,
  DialogFooter,
  ReactSelectField,
  wrapDialog
} from "@teselagen/ui";
import { showDialog } from "../../GlobalDialog";
import { getClassEntity } from ".";
import { TEST_LABELS, TEST_METADATA } from ".";
import { getRequestHeaderKeys } from "@teselagen/auth-utils";
import AddMetadataDialog from "../../components/Dialogs/AddMetadataDialog";
import { showStackedDialog } from "../../StackedDialog";
import { isAdmin } from "../generalUtils";
import { useFormValue } from "../../hooks/useFormValue";

const CLASS_WITH_UNITS = ["measurementType", "referenceDimension"];
const UNIT_CLASSES = ["unit", "d-unit"];

const HeaderView = ({ cell }) => (
  <div style={{ fontWeight: "bold", marginLeft: 5 }}>{cell.value}</div>
);

const HeaderEditor = ({ cell, onChange }) => {
  return (
    <div className="Spreadsheet__data-editor">
      <input
        type="text"
        style={{ fontWeight: "bold" }}
        autoFocus
        onChange={e => {
          onChange({ ...cell, value: e.target.value });
        }}
        value={cell.value}
      />
    </div>
  );
};

const ColumnHeaderClassMapperViewer = ({
  options,
  selectedOption,
  label,
  onSelect,
  helperText,
  withValidation
}) => {
  const classes = [
    "Button__header_class",
    ...(withValidation ? (selectedOption ? ["Complete"] : ["Incomplete"]) : [])
  ];

  return (
    <Popover
      className="Selector__header_class"
      position={Position.BOTTOM}
      content={
        <Menu
          className={classNames("Menu__header_class", {
            Menu__header_class_helper_text: !!helperText
          })}
        >
          {helperText && (
            <>
              <MenuItem
                key="menuitem-helper"
                text={helperText}
                disabled={true}
              />
              <Divider />
            </>
          )}
          {options.map((_option, idx) => (
            <MenuItem
              key={`menuitem-${idx}`}
              text={_option.label || _option.name}
              disabled={_option.disabled}
              onClick={() => onSelect(_option)}
            />
          ))}
        </Menu>
      }
    >
      <Button text={label} className={classNames(...classes)} />
    </Popover>
  );
};

const ColumnHeaderSubClassMapperViewer = props => {
  const classes = [
    "Selector__header_subclass",
    "Button__header_class",
    ...(props.withValidation
      ? props.selectedOption
        ? ["Complete"]
        : ["Incomplete"]
      : [])
  ];
  return (
    <Button
      text={props.buttonLabel}
      className={classNames(...classes)}
      onClick={() => {
        if (!props.selectedClass)
          return window.toastr.warning("Please select a class first.");
        showDialog({
          ModalComponent: SelectSubClassDialog,
          modalProps: props
        });
      }}
    />
  );
};

const selectSubClassForm = "selectSubClassForm";
const SelectSubClassDialog = compose(
  wrapDialog(props => ({ title: `Select ${props.label}` })),
  reduxForm({ form: selectSubClassForm })
)(props => {
  const isSingleColumnUnit = useFormValue(
    selectSubClassForm,
    "isSingleColumnUnit"
  );
  const subClassId = useFormValue(selectSubClassForm, "subClassId");
  const {
    hideModal,
    handleSubmit,
    selectedClass,
    options,
    onSelect,
    helperText,
    label,
    unitsByDimension,
    selectedOption,
    getUnits
  } = props;

  const unitsMap = {
    measurementType: "unit",
    referenceDimension: "d-unit"
  };
  const advanceMetadata = localStorage.getItem("advancedMetadata") === "true";

  const subClassNeedsUnits = keys(unitsMap).includes(selectedClass.name);

  const subClassSelected = !isNil(subClassId);

  const [unitOptions, setUnitOptions] = useState([]);

  // Units only apply to Measurements and Reference Dimension,
  // and we need to compute the valid units after the user selects a subClass,
  // given that each subclass has a different unitDimension, only units with the same
  // dimension should apply.
  useEffect(() => {
    if (subClassNeedsUnits) {
      const selectedSubClass = find(
        options,
        option => option.id === subClassId
      );
      const unitDimensionId = selectedSubClass?.unitDimension?.id;
      setUnitOptions(unitsByDimension?.[unitDimensionId] || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subClassId]);

  const onSubmit = async values => {
    const selectedSubClass = find(
      options,
      option => option.id === values.subClassId
    );
    const unit = find(unitOptions, option => option.id === values.selectedUnit);
    await onSelect({ ...selectedSubClass, unit });
    hideModal();
  };

  const _AddMetadataDialog = useCallback(
    () =>
      showStackedDialog({
        ModalComponent: AddMetadataDialog,
        modalProps: {
          modelName: selectedClass.name,
          onCreate: async subClass => {
            await onSelect(subClass);
            hideModal();
          }
        }
      }),
    [hideModal, onSelect, selectedClass.name]
  );

  return (
    <div style={{ padding: 20 }}>
      <>
        <Button
          style={{ marginBottom: 16 }}
          text={`New ${label}`}
          icon="add"
          intent="success"
          minimal
          onClick={_AddMetadataDialog}
        />
        <ReactSelectField
          isRequired
          name="subClassId"
          label={helperText || label}
          defaultValue={selectedOption?.id}
          options={options.map(option => ({
            value: option.id,
            label: option.name
          }))}
        />
      </>
      {subClassNeedsUnits && (
        <>
          <CheckboxField
            name="isSingleColumnUnit"
            label="Single Unit"
            disabled={!subClassSelected}
            defaultValue={!isNil(selectedOption?.unit)}
          />
          {isAdmin() && !advanceMetadata && (
            <Button
              style={{ marginBottom: 16 }}
              text="New Unit"
              icon="add"
              intent="success"
              minimal
              disabled={!isSingleColumnUnit || !subClassSelected}
              onClick={() =>
                showStackedDialog({
                  ModalComponent: AddMetadataDialog,
                  modalProps: {
                    modelName: unitsMap[selectedClass.name],
                    onCreate: async unit => {
                      await getUnits();
                      await onSelect({ ...selectedOption, unit });
                      hideModal();
                    }
                  }
                })
              }
            />
          )}
          <ReactSelectField
            name="selectedUnit"
            defaultValue={selectedOption?.unit?.id}
            label="Unit"
            disabled={!isSingleColumnUnit || !subClassSelected}
            options={unitOptions.map(option => ({
              value: option.id,
              label: option.name
            }))}
          />
        </>
      )}
      <DialogFooter hideModal={hideModal} onClick={handleSubmit(onSubmit)} />
    </div>
  );
});

function getClassSelectorProps(props) {
  const { headerName, headerClasses } = props;

  const selectedClass = headerClasses[headerName];
  const selectedClassLabel = selectedClass
    ? selectedClass.label
    : `Select ${TEST_LABELS.class}`;

  const classOptions = Object.keys(TEST_METADATA).map(
    _class => TEST_METADATA[_class]
  );

  return { classOptions, selectedClass, selectedClassLabel };
}

function getSubClassSelectorProps(props) {
  const { headerName, headerSubClasses, selectedClass, classSubClasses } =
    props;

  const className = selectedClass?.name;

  const isMeasUnit = className === "unit";
  const isRefDimUnit = className === "d-unit";
  const isUnit = isMeasUnit || isRefDimUnit;

  const selectedSubClass = headerSubClasses[headerName];
  const subClassUnitName = selectedSubClass?.unit?.name;

  const selectedSubclassLabel =
    className && selectedSubClass
      ? `${selectedSubClass.name} ${
          subClassUnitName ? `[${subClassUnitName}]` : ""
        }`
      : isUnit
        ? `Map to value column`
        : `Select ${TEST_LABELS.subClass}`;

  const subClassOptions = classSubClasses[getClassEntity(className)] || [];

  let subClassHelperText;
  if (isUnit)
    subClassHelperText = `Map to a ${
      isMeasUnit ? "Measurement" : "Reference Dimension"
    }`;

  return {
    subClassOptions,
    selectedSubClass,
    selectedSubclassLabel,
    subClassHelperText
  };
}

export function ColumnIndicator({
  headerName,
  headerClasses,
  headerSubClasses,
  classSubClasses,
  getUnits,
  handleHeaderClassSelection,
  handleHeaderSubClassSelection,
  unitsByDimension,
  withValidation
}) {
  const { classOptions, selectedClass, selectedClassLabel } = useMemo(
    () =>
      getClassSelectorProps({
        headerName,
        headerClasses: headerClasses || {}
      }),
    [headerClasses, headerName]
  );

  const {
    subClassOptions,
    selectedSubClass,
    selectedSubclassLabel,
    subClassHelperText
  } = useMemo(
    () =>
      getSubClassSelectorProps({
        headerName,
        headerSubClasses: headerSubClasses || {},
        selectedClass,
        classSubClasses: classSubClasses || {}
      }),
    [classSubClasses, headerName, headerSubClasses, selectedClass]
  );

  return (
    <th className="Datamap__header_class">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <ColumnHeaderClassMapperViewer
          options={classOptions}
          selectedOption={selectedClass}
          onSelect={headerClass =>
            handleHeaderClassSelection(headerClass, headerName)
          }
          label={selectedClassLabel}
          withValidation={withValidation}
        />
        <ColumnHeaderSubClassMapperViewer
          options={subClassOptions}
          unitsByDimension={unitsByDimension}
          selectedOption={selectedSubClass}
          getUnits={getUnits}
          onSelect={subClass =>
            handleHeaderSubClassSelection(subClass, headerName)
          }
          selectedClass={selectedClass}
          label={selectedClassLabel}
          buttonLabel={selectedSubclassLabel}
          helperText={subClassHelperText}
          withValidation={withValidation}
        />
      </div>
    </th>
  );
}

export const getMappingSuggestion = async (
  headerNames,
  classes = [],
  exact
) => {
  try {
    const queryParams = queryString.stringify({ exact });
    const results = await window.serverApi.request({
      method: "POST",
      headers: getRequestHeaderKeys(),
      withCredentials: true,
      url: `/test-routes/column-auto-mapper?${queryParams}`,
      data: {
        columnHeaders: headerNames.map((headerName, idx) => ({
          index: idx,
          name: headerName
        })),
        classes
      }
    });
    const autoMapperHeaders = results.data.map(columnHeader => {
      let mappedHeader = {
        name: columnHeader.name,
        hasSuggestion: false,
        accepted: false,
        tooltipMessage: "No suggestion found"
      };
      if (columnHeader.match) {
        mappedHeader = {
          ...mappedHeader,
          hasSuggestion: true,
          class: columnHeader.match.className,
          className: TEST_METADATA[columnHeader.match.className].label,
          subClass: columnHeader.match.subClassId,
          subClassId: columnHeader.match.subClassId,
          subClassName: columnHeader.match.subClassName,
          tooltipMessage: undefined
        };
      }
      return mappedHeader;
    });
    return autoMapperHeaders;
  } catch (error) {
    console.error(error);
  }
};

/**
 * This function validates the column mappings. The following must be satisfied:
 *
 * 1. There must be at least one assay subject class column.
 *
 * 2. There should not be duplicated types of measurements or reference dimensions.
 *
 * 3. Some metadata columns must be associated with column units, these are reference dimensions and measurement types.
 */
export const mappingValidator = async (...mappedHeaders) => {
  const [headerNames, headerClasses, headerSubClasses] = mappedHeaders;

  // Validation errors will be collected in this array.
  const validationErrors = [];

  try {
    // Checks that all columns are completely mapped (class and subclass).
    const mappingComplete = every(headerNames, headerName => {
      const columnMapComplete =
        get(headerClasses, headerName) && get(headerSubClasses, headerName);

      if (!columnMapComplete)
        validationErrors.push(
          "Mapping is incomplete. Every header must be mapped to a class and subclass."
        );

      return columnMapComplete;
    });
    if (!mappingComplete) return validationErrors;

    // This object will be used to track whether measurement and/or reference dimension columns have missing unit columns
    const mustHaveUnits = {};

    // 'uniqMeasAndRefDims' will be used to check for duplicated measurement or reference dimension columns.
    const uniqMeasAndRefDims = {
      measurementType: [],
      referenceDimension: []
    };
    let hasAssaySubject = false;

    headerNames.forEach(headerName => {
      const { name: className } = headerClasses[headerName];
      const { name: subClassName, unit: subClassUnit } =
        headerSubClasses[headerName];

      // If column is mapped as a measurementType or a referenceDimension
      // - collect it in the 'uniqMeasAndRefDims' object
      // - include it in the 'mustHaveUnits' object with 'hasValueColumn: true'
      if (CLASS_WITH_UNITS.includes(className)) {
        uniqMeasAndRefDims[className].push(subClassName);
        mustHaveUnits[subClassName] = {
          ...mustHaveUnits[subClassName],
          classLabel: TEST_METADATA[className].label,
          headerName,
          hasValueColumn: true,
          ...(subClassUnit && { hasUnitColumn: true })
        };
      }
      // If column is mapped as a d-unit or a unit
      // - include it in the 'mustHaveUnits' object with 'hasUnitColumn: true'
      else if (UNIT_CLASSES.includes(className)) {
        mustHaveUnits[subClassName] = {
          ...mustHaveUnits[subClassName],
          unitClassLabel: TEST_METADATA[className].label,
          headerName,
          hasUnitColumn: true
        };
      } else if (className === "assaySubjectClass") hasAssaySubject = true;
    });

    // Checks 1. is satisfied.
    if (!hasAssaySubject) {
      validationErrors.push(
        'You must have at least one "Assay Subject Class" column in your dataset.'
      );
    }

    // Checks 2. is satisfied.
    if (
      size(uniq(uniqMeasAndRefDims.measurementType)) <
      size(uniqMeasAndRefDims.measurementType)
    ) {
      validationErrors.push(
        "Your dataset can't have more than one column with the same type of Measurement."
      );
    }
    if (
      size(uniq(uniqMeasAndRefDims.referenceDimension)) <
      size(uniqMeasAndRefDims.referenceDimension)
    ) {
      validationErrors.push(
        "Your dataset can't have more than one column with the same type of Reference Dimension."
      );
    }

    // Checks 3. is satisfied.
    Object.keys(mustHaveUnits).forEach(col => {
      if (!mustHaveUnits[col].hasUnitColumn) {
        validationErrors.push(
          `${mustHaveUnits[col].classLabel} column "${mustHaveUnits[col].headerName}" requires a Unit Column. Click on "${mustHaveUnits[col].headerName}" and select a unit, or include a corresponding unit column`
        );
      } else if (!mustHaveUnits[col].hasValueColumn) {
        validationErrors.push(
          `${mustHaveUnits[col].unitClassLabel} column "${mustHaveUnits[col].headerName}" has no target column.`
        );
      }

      // // Check that unit column values exist in the database.
      // // This only checks the first few records. But at least works when all unit values are equal for the same unit column.
      // // TODO: evaluate getting ALL unit values for the column. This however may come with significant delays for large datasets.
      // const uniqueUnitValues = uniq(
      //   getParent(self).importFileSetAttachment.importFileSet.rows.map(
      //     row => row[col.unit || col["d-unit"]]
      //   )
      // );
      // let unitsNotInDatabase = differenceBy(
      //   uniqueUnitValues.map(unitValue => unitValue.toLowerCase()),
      //   databaseUnits.map(unitRecord => unitRecord.name.toLowerCase())
      // );
      // if (size(unitsNotInDatabase) > 0) {
      //   unitErrors += 1;
      //   return self.generateError(
      //     `Column '${col.unit}' has unit values not existant in TEST units metadata.
      //         Please create a new unit metadata record or fix the colum values to match an existant metadata unit record.`
      //   );
      // }
    });
  } catch (error) {
    console.error(error);
    validationErrors.push("Error validating dataset.");
  }
  return validationErrors;
};

export function buildGridDataRows(dataCells) {
  const dataRows = groupBy(dataCells, "rowPosition");
  return Object.keys(dataRows).map(rowNumber => {
    return dataRows[rowNumber].map(row => {
      return {
        value: row.value,
        readOnly: true
      };
    });
  });
}

export function buildGridHeaderRows(headerRow) {
  return headerRow.map(row => ({
    value: row.value,
    DataViewer: HeaderView,
    DataEditor: HeaderEditor,
    readOnly: true,
    className: "Datamap__header"
  }));
}

export function rowLabels(numberRows) {
  return ["", ...Array.from(Array(numberRows).keys()).slice(1)];
}
