/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback } from "react";
import { InputField, CheckboxField } from "@teselagen/ui";
import { get } from "lodash";
import { withUnitGeneric } from "../../../src-shared/utils/unitUtils";
import GenericSelect from "../../../src-shared/GenericSelect";
import defaultValueConstants from "../../../../tg-iso-shared/src/defaultValueConstants";
import containerArrayTypeFragment from "../../../../tg-iso-shared/src/fragments/containerArrayTypeFragment";
import gql from "graphql-tag";
import { useFormValue } from "../../../src-shared/hooks/useFormValue";

const schema = [
  "name",
  {
    render: (v, r) => {
      return `${r.name} (${
        r.aliquotContainerType.maxVolume +
        " " +
        r.aliquotContainerType.volumetricUnitCode
      })`;
    }
  }
];

const fragment = gql`
  fragment containerArrayTypeFragment on containerArrayType {
    id
    name
    isPlate
    aliquotContainerType {
      code
      maxVolume
      volumetricUnitCode
    }
  }
`;

const deadVolumeComponent = withUnitGeneric(
  "aliquotContainerType.deadVolume",
  "aliquotContainerType.deadVolumetricUnitCode"
);

function IntermediateContainerTypeFields({
  label = "Intermediate Container",
  noBarcodeInfo,
  error,
  formName
}) {
  const intermediateContainerType = useFormValue(
    formName,
    "intermediateContainerType"
  );
  const generateIntermediateBarcode = useFormValue(
    formName,
    "generateIntermediateBarcode"
  );
  const validate = useCallback(() => {
    return error;
  }, [error]);
  return (
    <>
      <InputField
        name="intermediateContainerName"
        isRequired
        label={`${label} Name`}
        generateDefaultValue={{
          ...defaultValueConstants.INTERMEDIATE_CONTAINER_NAME,
          customParams: {
            label: `${label}`
          }
        }}
      />
      <GenericSelect
        label={`${label} Type`}
        name="intermediateContainerType"
        asReactSelect
        isRequired
        placeholder="Select container type..."
        schema={schema}
        fragment={fragment}
        tableParamOptions={{
          additionalFilter: {
            isPlate: true
          }
        }}
        additionalDataFragment={containerArrayTypeFragment}
        validate={validate}
      />
      {!!get(intermediateContainerType, "aliquotContainerType.deadVolume") && (
        <div>
          This {label.toLowerCase()} type has a dead volume of{" "}
          {deadVolumeComponent(intermediateContainerType)}.
        </div>
      )}
      {!noBarcodeInfo && (
        <>
          <CheckboxField
            name="generateIntermediateBarcode"
            label="Generate Barcode"
            defaultValue
          />
          {!generateIntermediateBarcode && (
            <InputField name="intermediateBarcode" label="Barcode" />
          )}
        </>
      )}
    </>
  );
}

export default IntermediateContainerTypeFields;
