/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { map } from "lodash";
import { useTgQuery } from "../apolloMethods";
import { Link } from "react-router-dom";

export function ShowLinkedDesignsToPart({ partId }) {
  const {
    data: { part },
    ...rest
  } = useTgQuery(
    [
      "part",
      `id elements {
        id
        design {
          id name lockTypeCode
        }
      }`
    ],
    {
      variables: {
        id: partId
      }
    }
  );
  if (rest.error) return null;

  const designsById = {};
  map(part && part.elements, ({ design }) => {
    if (design && design.lockTypeCode !== "LOCKED_FOR_ASSEMBLY") {
      const { id, name } = design;
      designsById[id] = (
        <Link key={`${id}`} to={`/designs/${id}`}>
          {name}
        </Link>
      );
    }
  });

  const designNames = map(designsById);
  return (
    <div style={{ marginBottom: 10 }}>
      Linked Designs:{" "}
      {rest.loading ? "..." : designNames.length ? designNames : "None"}
    </div>
  );
}
