/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import PropTypes from "prop-types";
import useStyle from "substyle";
import { getSubstringIndex } from "./utils";

const defaultStyle = {
  cursor: "pointer"
};

const Suggestion = ({
  id,
  query,
  renderSuggestion,
  suggestion,
  index,
  focused,
  ignoreAccents,
  style: _style,
  ...rest
}) => {
  const style = useStyle(
    defaultStyle,
    { style: _style },
    { "&focused": focused }
  );

  const getDisplay = () => {
    if (suggestion instanceof String) {
      return suggestion;
    }
    const { id, display } = suggestion;
    if (id === undefined || !display) {
      return id;
    }
    return display;
  };

  const renderHighlightedDisplay = display => {
    const i = getSubstringIndex(display, query, ignoreAccents);

    if (i === -1) {
      return <span {...style("display")}>{display}</span>;
    }

    return (
      <span {...style("display")}>
        {display.substring(0, i)}
        <b {...style("highlight")}>{display.substring(i, i + query.length)}</b>
        {display.substring(i + query.length)}
      </span>
    );
  };

  const renderContent = () => {
    const display = getDisplay();
    const highlightedDisplay = renderHighlightedDisplay(display, query);

    if (renderSuggestion) {
      return renderSuggestion(
        suggestion,
        query,
        highlightedDisplay,
        index,
        focused
      );
    }

    return highlightedDisplay;
  };

  return (
    <li {...rest} {...style}>
      {renderContent()}
    </li>
  );
};

Suggestion.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  query: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  ignoreAccents: PropTypes.bool,
  suggestion: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      display: PropTypes.string
    })
  ]).isRequired,
  renderSuggestion: PropTypes.func,
  focused: PropTypes.bool
};

export default Suggestion;
