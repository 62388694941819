/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback } from "react";
import {
  CollapsibleCard,
  DataTable,
  DialogFooter,
  showConfirmationDialog,
  withSelectedEntities,
  withTableParams,
  wrapDialog
} from "@teselagen/ui";
import { withRouter } from "react-router-dom";
import withQuery from "../withQuery";
import { getReverseComplementSequenceString } from "@teselagen/sequence-utils";

import { compose, withProps } from "recompose";
import gql from "graphql-tag";
import routeDoubleClick from "../utils/routeDoubleClick";
import { Button, Callout, Classes, MenuItem } from "@blueprintjs/core";
import { safeQuery, safeUpsert, updateWithQuery } from "../apolloMethods";
import { showDialog } from "../GlobalDialog";
import { reduxForm } from "redux-form";
import { groupBy } from "lodash";

function OligoBindingSitesCard({ tableParams, sequenceId }) {
  return (
    <CollapsibleCard title="Oligo Binding Sites">
      <DataTable
        topLeftItems={
          <Button
            intent="primary"
            icon="search-template"
            onClick={() =>
              showDialog({
                ModalComponent: FindOligoBindingSitesDialog,
                modalProps: {
                  sequenceId,
                  refetchBindingSites: tableParams.onRefresh
                }
              })
            }
          >
            Find More Binding Sites
          </Button>
        }
        {...tableParams}
        contextMenu={function ({ selectedRecords }) {
          return [
            <MenuItem
              key="Delete"
              icon="delete"
              onClick={async () => {
                if (selectedRecords && selectedRecords.length) {
                  const confirm = await showConfirmationDialog({
                    text: `Are you sure you want to remove this linkage?`,
                    intent: "danger",
                    confirmButtonText: "Delete",
                    cancelButtonText: "Cancel",
                    canEscapeKeyCancel: true
                  });
                  if (confirm) {
                    await updateWithQuery(["sequenceFeature", "id name"], {
                      values: { oligoWithBindingSiteId: null }, //tnr: set the sequenceTypeCode here just to make sure any legacy j5 oligos get their type codes set properly
                      where: { id: selectedRecords.map(n => n.id) }
                    });
                  }
                }

                tableParams.onRefresh();
              }}
              text="Remove Linkage"
            />
          ];
        }}
        onDoubleClick={routeDoubleClick}
      />
    </CollapsibleCard>
  );
}

const fragment = gql`
  fragment oligoBindingSiteFeatures on sequenceFeature {
    id
    name
    start
    end
    strand
    primerBindsOn
    oligoWithBindingSite {
      id
      fullSequenceRaw
    }
    sequence {
      id
      name
      fullSequence
    }
  }
`;

const cellRenderer = {
  start: i => i + 1,
  end: i => i + 1
};

export default compose(
  withRouter,
  withProps(props => {
    // Added while #12943 is not ready
    const additionalFilter = useCallback(
      (_, qb) => {
        qb.whereAll({
          oligoWithBindingSiteId: props.sequenceId
        });
      },
      [props.sequenceId]
    );
    return { additionalFilter };
  }),
  withTableParams({
    urlConnected: false,
    formName: "sequenceRecordOligoBindingSites",
    tableParams: { cellRenderer },
    schema: {
      model: "sequenceFeature",
      fields: [
        "name",
        {
          displayName: "Sequence",
          path: "sequence.name"
        },
        "start",
        "end",
        "strand"
      ]
    }
  }),
  withQuery(fragment, { isPlural: true })
)(OligoBindingSitesCard);

function getIndicesOfStringMatch(sourceString, searchString, caseSensitive) {
  const searchStrLen = searchString.length;
  if (searchStrLen === 0) {
    return [];
  }
  let startIndex = 0;
  let index;
  const indices = [];
  if (!caseSensitive) {
    sourceString = sourceString.toLowerCase();
    searchString = searchString.toLowerCase();
  }
  while ((index = sourceString.indexOf(searchString, startIndex)) > -1) {
    indices.push(index);
    startIndex = index + searchStrLen;
  }
  return indices;
  // return [...sourceString.matchAll(new RegExp(searchString, "gi"))].map(
  //   a => a.index
  // );
}

let FindOligoBindingSitesDialog = ({
  sequence: oligo,
  refetchBindingSites,
  tableParams,
  hideModal,
  handleSubmit,
  submitting,
  findOligoBindingSitesTableSelectedEntities: selectedSequences = []
}) => {
  const oligoBps = oligo.fullSequence;
  async function onSubmit() {
    try {
      const existingFeatures = await safeQuery(
        [
          "sequenceFeature",
          /* GraphQL */ `
            {
              id
              start
              end
              type
              strand
              sequenceId
              oligoWithBindingSiteId
            }
          `
        ],
        {
          variables: {
            filter: {
              sequenceId: selectedSequences.map(s => s.id),
              type: "primer_bind"
            }
          }
        }
      );
      const groupedExistingFeatures = groupBy(existingFeatures, "sequenceId");
      const featureUpdates = [];
      const featureCreates = [];
      selectedSequences.forEach(seq => {
        getIndicesOfStringMatch(seq.fullSequence, oligoBps)
          .map(i => [i, true])
          .concat(
            getIndicesOfStringMatch(
              getReverseComplementSequenceString(seq.fullSequence),
              oligoBps
            ).map(i => [seq.fullSequence.length - i - oligoBps.length, false])
          )
          .forEach(([index, forward]) => {
            const start = index;
            const end = index + oligoBps.length - 1;
            const strand = forward ? 1 : -1;
            const feature = (groupedExistingFeatures[seq.id] || []).find(f => {
              return f.start === start && f.end === end && f.strand === strand;
            });
            if (feature) {
              if (!feature.oligoWithBindingSiteId) {
                featureUpdates.push({
                  id: feature.id,
                  oligoWithBindingSiteId: oligo.id
                });
              }
            } else {
              featureCreates.push({
                name: oligo.name,
                sequenceId: seq.id,
                start,
                end,
                strand,
                type: "primer_bind",
                oligoWithBindingSiteId: oligo.id
              });
            }
          });
      });
      await safeUpsert("sequenceFeature", featureUpdates);
      await safeUpsert("sequenceFeature", featureCreates);
      await refetchBindingSites();
      hideModal();
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error("Error creating new binding sites");
    }
  }

  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        <Callout intent="primary">
          Searching for additional binding sites with 100% sequence match to the
          oligo
        </Callout>
        <DataTable
          {...tableParams}
          withCheckboxes
          cellRenderer={{
            numSites: (v, r) => {
              const reg = new RegExp(oligoBps, "g");
              // count how many times this sequence contains the oligo
              const count =
                (r.fullSequence.match(reg) || []).length +
                (
                  getReverseComplementSequenceString(r.fullSequence).match(
                    reg
                  ) || []
                ).length;
              return count;
            }
          }}
        />
      </div>
      <DialogFooter
        disabled={!selectedSequences.length}
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
        text="Add Binding Sites"
        submitting={submitting}
      />
    </React.Fragment>
  );
};

FindOligoBindingSitesDialog = compose(
  wrapDialog({ title: "Find Binding Sites" }),
  reduxForm({
    form: "findOligoBindingSites"
  }),
  withQuery(["sequence", "id name fullSequence"], {
    showLoading: true,
    inDialog: true,
    options: ({ sequenceId }) => {
      return {
        variables: {
          id: sequenceId
        }
      };
    }
  }),
  withProps(props => {
    // Added while #12943 is not ready
    const additionalFilter = useCallback(
      (_, qb) => {
        const fs = props.sequence.fullSequence ?? "";
        qb.whereAny(
          {
            fullSequence: qb.containsExactly(fs)
          },
          {
            fullSequence: qb.containsExactly(
              getReverseComplementSequenceString(fs)
            )
          }
        );
        qb.whereAll({
          sequenceTypeCode: qb.notEquals("OLIGO")
        });
      },
      [props.sequence.fullSequence]
    );
    return { additionalFilter };
  }),
  withTableParams({
    isInfinite: true,
    formName: "findOligoBindingSitesTable",
    urlConnected: false,
    schema: {
      model: "sequence",
      fields: [
        "name",
        {
          displayName: "Number of Binding Sites",
          path: "numSites",
          filterDisabled: true,
          sortDisabled: true
        }
      ]
    }
  }),
  withQuery(["sequence", "id name fullSequence"], {
    doNotReturnTotalResults: true,
    isPlural: true
  }),
  withSelectedEntities("findOligoBindingSitesTable")
)(FindOligoBindingSitesDialog);
