/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { formValues, InjectedFormProps, reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import { DialogFooter, wrapDialog } from "@teselagen/ui";
import type { InjectedWrapDialogProps } from "@teselagen/ui";
import ZonesPerPlateField from "../../../PcrPlanningAndInventoryCheck/ZonesPerPlateField";
import TemperatureZoneOrientationField from "../../../PcrPlanningAndInventoryCheck/TemperatureZoneOrientationField";

type TemperatureZoneOrientations = "horizontal" | "vertical";

interface FormData {
  temperatureZoneOrientation: TemperatureZoneOrientations;
  zonesPerPlate: number;
}

interface Props {
  containerFormat: { rowCount: number; columnCount: number };
  handleDistribute: (values: {
    temperatureZoneOrientation: TemperatureZoneOrientations;
    zonesPerPlate: number;
  }) => void;
}

interface InjectedFormValues {
  temperatureZoneOrientation: string;
}

function ChooseDistributeIntoTemperatureBlocksOptions({
  hideModal,
  handleSubmit,
  submitting,
  containerFormat,
  temperatureZoneOrientation,
  handleDistribute
}: InjectedWrapDialogProps<Props> &
  InjectedFormProps<FormData, Props> &
  InjectedFormValues &
  Props) {
  async function onSubmit(values: FormData) {
    try {
      await handleDistribute(values);
      hideModal();
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error(error.message);
    }
  }

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        {/* <RadioGroupField
          name="distributeMethod"
          defaultValue="assembly"
          options={[
            {
              label: "Distribute using Assembly Report Tm",
              value: "assembly"
            },
            {
              label:
                "Distribute using NEB Tm calculated from SantaLucia (1998) & Owczarzy (2004)",
              value: "neb"
            }
          ]}
        /> */}
        {/* Maybe we need to include the defaultValue for updating */}
        <TemperatureZoneOrientationField />
        <ZonesPerPlateField
          containerFormat={containerFormat}
          temperatureZoneOrientation={temperatureZoneOrientation}
        />
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        text="Distribute"
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
}

export default compose<
  InjectedWrapDialogProps<Props> &
    InjectedFormProps<FormData, Props> &
    InjectedFormValues &
    Props,
  Props
>(
  wrapDialog({
    title: "Distribute Parameters"
  }),
  reduxForm({
    form: "distributeTempZonesParameters"
  }),
  formValues("temperatureZoneOrientation")
)(ChooseDistributeIntoTemperatureBlocksOptions);
