/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import {
  createOrEditPlateMapJ5ReportJ5PcrReactionsFragment,
  createOrEditPlateMapJ5ReportJ5AssemblyPiecesFragment,
  createOrEditPlateMapJ5Reportj5RunConstructFragment,
  createOrEditPlateMapJ5ReportprimaryTemplateFragment,
  createOrEditPlateMapJ5ReportprimerFragment,
  createOrEditPlateMapJ5ReportpcrProductSequenceFragment,
  createOrEditPlateMapJ5Reportj5InputSequenceFragment
} from "./j5EntityFragments.gql";

export const j5EntityToFragment = {
  j5PcrReaction: createOrEditPlateMapJ5ReportJ5PcrReactionsFragment,
  j5AssemblyPiece: createOrEditPlateMapJ5ReportJ5AssemblyPiecesFragment,
  j5RunConstruct: createOrEditPlateMapJ5Reportj5RunConstructFragment,
  primaryTemplate: createOrEditPlateMapJ5ReportprimaryTemplateFragment,
  primer: createOrEditPlateMapJ5ReportprimerFragment,
  pcrProductSequence: createOrEditPlateMapJ5ReportpcrProductSequenceFragment,
  j5InputSequence: createOrEditPlateMapJ5Reportj5InputSequenceFragment
} as const;
