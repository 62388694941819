/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";

import {
  DataTable,
  DialogFooter,
  withTableParams,
  wrapDialog
} from "@teselagen/ui";
import withQuery from "../../../withQuery";

import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { hideDialog } from "../../../GlobalDialog";
import { annotationSizeStartEndColumns } from "../../../utils/libraryColumns";

const schema = {
  model: "sequenceFeature",
  fields: [
    { path: "name", displayName: "Name" },
    ...annotationSizeStartEndColumns,
    { path: "type", displayName: "Type" },
    { path: "sequence.name", displayName: "Sequence" }
  ]
};

const cellRenderer = {
  start: i => i + 1,
  end: i => i + 1
};

class FeatureLibraryDialog extends React.Component {
  state = { submitting: false };

  onSubmit = async () => {
    const {
      onSubmit,
      hideModal,

      shouldNotHideModal,
      selectedEntities
    } = this.props;
    this.setState({ submitting: true });
    try {
      await onSubmit(selectedEntities);
    } catch (e) {
      console.error(e);
    }
    this.setState({ submitting: false });
    if (!shouldNotHideModal) hideModal();
  };

  render() {
    const { tableParams, additionalHeaderDiv } = this.props;
    const { submitting } = this.state;
    return (
      <React.Fragment>
        <div className="bp3-dialog-body">
          {additionalHeaderDiv}
          <DataTable
            maxHeight={400}
            {...tableParams}
            formName="featureLibraryDialog"
            withCheckboxes
          />
        </div>
        <DialogFooter
          hideModal={hideDialog}
          text="Add Annotations"
          loading={submitting}
          onClick={this.onSubmit}
        />
      </React.Fragment>
    );
  }
}

const selector = formValueSelector("featureLibraryDialog");

const mapStateToProps = state => ({
  selectedEntities: Object.values(
    selector(state, "reduxFormSelectedEntityIdMap") || {}
  ).map(v => v.entity)
});

export default compose(
  wrapDialog(p => ({
    title: p.title || "Features"
  })),
  withTableParams({
    // urlConnected: true,
    schema,
    formName: "featureLibraryDialog",
    tableParams: {
      cellRenderer
    },
    additionalFilter: (_, qb) => {
      qb.whereAny({
        "sequence.isInLibrary": true
      });
    }
  }),
  withQuery(
    [
      "sequenceFeature",
      `
    id
    name
    start
    end
    type
    sequence {
      id
      name
    }
  `
    ],
    {
      isPlural: true
    }
  ),
  connect(mapStateToProps)
)(FeatureLibraryDialog);
