/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export const userRoleFragment = gql`
  fragment userRoleFragment on userRole {
    id
    appRoleCode
    userId
    id
    user {
      id
      username
    }
  }
`;

export const registeredUserFragment = gql`
  fragment registeredUserFragment on user {
    id
    defaultLabId
    username
    firstName
    lastName
    email
    userRoles {
      ...userRoleFragment
    }
  }
  ${userRoleFragment}
`;
