/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export const duplicateWorkflowDefinitionFragment = gql`
  fragment duplicateWorkflowDefinitionFragment on workflowDefinition {
    id
    name
    description
    workflowToolDefinitions {
      id
      name
      index
      toolCode
      validateBarcodes
      wtdExtendedProperties {
        id
        extendedPropertyId
      }
      workflowToolInputDefinitions {
        id
        itemCode
        label
        isList
        isDisabled
        taskIoTypeCode
        wtInputDefToOutputDefs {
          id
          workflowToolInputDefinitionId
          workflowToolOutputDefinitionId
        }
      }
      workflowToolOutputDefinitions {
        id
        itemCode
        label
        isList
        taskIoTypeCode
      }
      workflowToolSettingDefinitions {
        id
        name
        isPresetValue
        value
        workflowToolSettingDefinitionTypeCode
        integration {
          id
          name
          integrationTypeCode
        }
        customQuery {
          id
          name
        }
      }
    }
  }
`;
