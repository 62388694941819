/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback } from "react";
import { capitalize } from "lodash";
import { reduxForm } from "redux-form";
import { useState } from "react";
import { compose } from "recompose";
import {
  BlueprintError,
  DialogFooter,
  InputField,
  tgFormValues,
  wrapDialog
} from "@teselagen/ui";
import HeaderWithHelper from "../../HeaderWithHelper";
import LabForUserForm from "../../auth/components/labForUserForm";
import { COMMON_LAB_ID } from "@teselagen/auth-utils";
import { Callout } from "@blueprintjs/core";
import { asyncValidateFieldDuplicate } from "../../utils/formUtils";

const placeholder = "SomeName";
const validateServiceAccountName = serviceAccountName => {
  if (serviceAccountName) {
    if (serviceAccountName.includes("serviceaccount")) {
      return "Please avoid using the word serviceaccount in the name";
    }
    if (serviceAccountName.includes(" ")) {
      return "Please avoid spaces";
    }
    if (serviceAccountName.includes("@")) {
      return "Please avoid the '@' character";
    }
  } else {
    return "Service Account Name is required";
  }
};
const serviceAccountEmailGenerator = serviceAccountName => {
  let host = window.location.hostname;
  if (window.location.hostname === "localhost") {
    host = "localhost.teselagen.com";
  }

  return `${serviceAccountName}-serviceaccount@${host}`.toLowerCase();
};

const CreateNewServiceAccountDialog = props => {
  const {
    handleSubmit,
    hideModal,
    refetch,
    error,
    asyncValidating,
    serviceAccountName
  } = props;
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = useCallback(
    async values => {
      setSubmitting(true);
      const labId = values.existingLab.id;
      try {
        await window.tgApi({
          method: "POST",
          url: "/users",
          data: {
            email: serviceAccountEmailGenerator(values.serviceAccountName),
            firstName: capitalize(values.serviceAccountName),
            lastName: "Service Account",
            userRoles: [
              { appRoleCode: "SERVICE" },
              ...(labId === COMMON_LAB_ID ? [{ appRoleCode: "PUBLISHER" }] : [])
            ],
            // NOTE: we might want to configure service accounts to have ADMIN lab roles in da future.
            ...(labId && { labRoles: [{ labId, roleCode: "MEMBER" }] })
          }
        });
        window.toastr.success("Service account created");
      } catch (error) {
        console.error(error);
        window.toastr.error("Error creating service account");
      }
      await refetch();
      hideModal();
      setSubmitting(false);
    },
    [hideModal, refetch]
  );

  return (
    <div style={{ padding: 20 }}>
      <InputField
        isRequired
        label="Service Account Name"
        name="serviceAccountName"
        placeholder={placeholder}
        validate={validateServiceAccountName}
      />
      <HeaderWithHelper
        helper={
          <span>
            <b>Service account email:</b>{" "}
            <span className={"tg-service-acct-email"}>
              {serviceAccountEmailGenerator(serviceAccountName || placeholder)}
            </span>{" "}
          </span>
        }
        width="100%"
      />
      {/* Service Accounts can only be created into an existing lab for now. */}
      <LabForUserForm
        tooltipInfo={
          "Note: In order to Publish using this service account, you must select the Common Lab"
        }
        onlyExistingLabs={true}
        allowCommonLab={true}
      />
      <Callout intent="primary">
        After you create a service account, you'll be able to generate a token
        for it. This token will allow the service account to perform actions in
        the chosen lab.
      </Callout>
      <br></br>
      {error && <BlueprintError error={error} />}
      <DialogFooter
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
        submitting={submitting || asyncValidating}
      />
    </div>
  );
};

export default compose(
  wrapDialog({
    title: "New Service Account"
  }),
  reduxForm({
    form: "newServiceAccount",
    asyncBlurFields: ["serviceAccountName"],
    asyncValidate: (values, dispatch, ownProps) =>
      asyncValidateFieldDuplicate({
        values: {
          email: serviceAccountEmailGenerator(values.serviceAccountName)
        },
        initialValues: {
          email: serviceAccountEmailGenerator(
            ownProps.initialValues?.serviceAccountName
          )
        },
        errorFieldName: "serviceAccountName",
        model: "user",
        field: "email"
      })
  }),
  tgFormValues("serviceAccountName")
)(CreateNewServiceAccountDialog);
