/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
import dataRowFragment from "./dataRowFragment";

export default gql`
  fragment dataTableRecordFragment on dataTable {
    id
    name
    dataSet {
      id
      name
      vendorOrders {
        id
        name
      }
    }
    dataRows {
      ...dataRowFragment
    }
    dataTableTypeCode
    dataTableType {
      code
      name
      rowSchema
    }
    schema
    entities
  }
  ${dataRowFragment}
`;
