/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import { tgFormValues } from "@teselagen/ui";
import { Link } from "react-router-dom";
import { DataTable, CheckboxField, BlueprintError } from "@teselagen/ui";
import {
  getAliquotContainerTableSchema,
  validateMaterialPlates
} from "../../../../utils/plateUtils";
import { getAlphnumericWellLocation } from "../../../../../src-shared/utils/getAlphnumericWellLocation";

import GenericSelect from "../../../../../src-shared/GenericSelect";
import { uniq, flatMap, isEmpty } from "lodash";
import { Button, Tooltip, Icon, Intent } from "@blueprintjs/core";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import toolConstants from "../constants.json";
import PlateMapView from "../../../../components/PlateMapView";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import platePreviewColumn from "../../../../utils/platePreviewColumn";
import { tuplingToolPlateFragment } from "../fragments";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";

class SelectSamples extends React.Component {
  state = {
    loading: false
  };

  componentDidUpdate(oldProps) {
    const {
      containerArrays: oldContainerArrays = [],
      stepFormProps: { change }
    } = oldProps;

    const { containerArrays: newContainerArrays = [] } = this.props;
    const oldIds = oldContainerArrays.map(c => c.id);
    const newIds = newContainerArrays.map(c => c.id);
    const equalContainerArrays =
      newIds.every(id => oldIds.includes(id)) &&
      newIds.length === oldIds.length;
    // No easy access to the GenericSelect's postSelectDataTable delete button function. Thus when deleting plates, tuplingData needs to be emptied.
    if (!equalContainerArrays && !newIds.length) {
      change("tuplingData", []);
    }
  }

  onSelectContainerArrays = containerArrays => {
    const {
      stepFormProps: { change }
    } = this.props;
    let newTuplingData = [];
    try {
      newTuplingData = containerArrays.map(containerArray => {
        if (containerArray) {
          const barcode = containerArray.barcode
            ? containerArray.barcode.barcodeString
            : "N/A";
          return containerArray.aliquotContainers
            .filter(aliquotContainer => aliquotContainer.aliquot)
            .map(aliquotContainer => {
              let warning;
              let error;
              let referenceSequence;
              let referenceAnnotations = [];
              let referenceParts = [];
              const referenceSequenceSnippet = "";
              let referenceId;
              let referenceName = "N/A";
              let circular = false;
              let featureTags = [];
              const position =
                aliquotContainer.rowPosition !== undefined ||
                aliquotContainer.columnPosition !== undefined
                  ? getAlphnumericWellLocation([
                      {
                        rowPosition: aliquotContainer.rowPosition,
                        columnPosition: aliquotContainer.columnPosition
                      }
                    ])
                  : "N/A";
              if (aliquotContainer.aliquot.sample.material) {
                if (
                  aliquotContainer.aliquot.sample.material
                    .polynucleotideMaterialSequence
                ) {
                  circular =
                    aliquotContainer.aliquot.sample.material
                      .polynucleotideMaterialSequence.circular;
                  referenceId =
                    aliquotContainer.aliquot.sample.material
                      .polynucleotideMaterialSequence.id;
                  referenceName =
                    aliquotContainer.aliquot.sample.material
                      .polynucleotideMaterialSequence.name;
                  referenceSequence =
                    aliquotContainer.aliquot.sample.material
                      .polynucleotideMaterialSequence.sequenceFragments;
                  referenceAnnotations =
                    aliquotContainer.aliquot.sample.material
                      .polynucleotideMaterialSequence.sequenceFeatures;
                  referenceParts =
                    aliquotContainer.aliquot.sample.material
                      .polynucleotideMaterialSequence.parts;
                  if (!referenceSequence || referenceSequence.length === 0) {
                    error = "No reference sequence was found for this sample.";
                  }
                }
                if (
                  !referenceAnnotations ||
                  referenceAnnotations.length === 0
                ) {
                  if (!referenceParts || referenceParts.length === 0) {
                    warning =
                      "No annotations found on this reference sequence.";
                  }
                }
                featureTags = referenceAnnotations.concat(referenceParts);
              } else {
                error = "No reference sequence was found for this sample.";
              }
              return {
                barcode,
                position,
                aliquotId: aliquotContainer.aliquot.id,
                sampleName: aliquotContainer.aliquot.sample.name,
                referenceId,
                referenceName,
                referenceSequenceSnippet,
                featureTags,
                circular,
                error: error,
                warning: warning,
                ignored: false
              };
            });
        }
        return {};
      });
    } catch (error) {
      console.error(error);
      change("warning", {
        check: true,
        message: "Error getting the plate(s) aliquots."
      });
    }
    const featureTags = flatMap(
      flatMap(newTuplingData).map(newTuplingRecord => {
        return newTuplingRecord.featureTags;
      })
    );
    const featureTags2 = uniq(
      flatMap(featureTags.map(feature => [feature.name, feature.type])).filter(
        val => val
      )
    );
    change("featureTags", featureTags2);
    change("tuplingData", flatMap(newTuplingData));
  };

  // Function that clears the current object "tuplingData" stepform value.
  clearTuplingData = () => {
    const {
      stepFormProps: { change }
    } = this.props;
    change("tuplingData", []);
    change("aliquotPlates", []); // Not working correctly together with the plate viewer.
    change("tuplingSchemaTable", null);
    change("tuplingUploadData", null);
    change("warning", { check: false, message: "" });
  };

  getTuplingSchema = () => {
    const schema = {
      fields: [
        {
          type: "action",
          width: 35,
          render: (_, record) => {
            if (record.error || record.warning) {
              return (
                <Tooltip content={record.error || record.warning}>
                  <Icon
                    intent={record.error ? "danger" : "warning"}
                    style={{ marginRight: 10 }}
                    icon="warning-sign"
                  />
                </Tooltip>
              );
            }
          }
        },
        { path: "barcode", displayName: "Barcode" },
        { path: "position", displayName: "Position" },
        { path: "sampleName", displayName: "Sample Name" },
        // { path: "referenceSequenceSnippet", displayName: "Reference Sequence" },
        {
          path: "referenceSequenceId",
          displayName: "Reference Sequence",
          render: (_, record) => {
            return record.referenceId ? (
              <Link
                key="dnaMaterialLink"
                to={`/dna-sequences/${record.referenceId}`}
                target="_blank"
              >
                {record.referenceName}
              </Link>
            ) : (
              <span>N/A</span>
            );
          }
        },
        { path: "referenceName", displayName: "Material" },
        { path: "circular", displayName: "Circular DNA" },
        { path: "ignore", displayName: "Ignore" }
      ]
    };
    return schema;
  };

  // This updates the formvalue everytime the user checks/unchecks the ignore checkboxes.
  updateIgnoredSamples = ignoredSample => {
    const {
      stepFormProps: { change },
      tuplingData
    } = this.props;
    const newTuplingData = tuplingData.map(sample => {
      if (sample.sampleName === ignoredSample.sampleName) {
        return { ...sample, ignored: !sample.ignored };
      } else {
        return { ...sample };
      }
    });

    change("tuplingData", newTuplingData);
  };

  render() {
    const {
      Footer,
      footerProps,
      tuplingData = [],
      containerArrays = [],
      toolSchema,
      isIntegratedMap = {},
      containerArraysSelectedEntities: selectedPlates = [],
      warning = {},
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} }
    } = this.props;
    const selectedPlate = selectedPlates[0];

    const plateErrors = validateMaterialPlates(containerArrays, {
      materialTypeCode: "DNA",
      enforceSequenceLinks: true,
      asObject: true
    });
    let containerArrayPreview;
    let plateViewer;
    // Builds a containerArrayPreview object whenever a plate is selected from the selected plates data table.
    if (selectedPlate) {
      containerArrayPreview = {
        ...selectedPlate,
        aliquotContainers: selectedPlate.aliquotContainers.map(container => ({
          ...container,
          location: getAliquotContainerLocation(container)
        }))
      };
      plateViewer = (
        <PlateMapView
          containerArrayType={containerArrayPreview.containerArrayType}
          containerArray={containerArrayPreview}
          tableSchema={getAliquotContainerTableSchema(
            containerArrayPreview.aliquotContainers
          )}
          noEntityTransform
          noPadding
        />
      );
    }

    // This table shows the results of the file uploaded on the "Select Data" section of Step 1 of the Tupling Tool.
    const table = (
      <React.Fragment>
        <div>
          <DataTable
            isSimple
            formName="tuplingSchemaDisplayTable"
            maxHeight={300}
            noSelect
            schema={this.getTuplingSchema()}
            cellRenderer={{
              ignore: (idx, record) => {
                return (
                  <div style={{ marginLeft: 10 }}>
                    <CheckboxField
                      name={record.sampleName}
                      onFieldSubmit={() => this.updateIgnoredSamples(record)}
                      defaultValue={record.ignored}
                    >
                      {" "}
                    </CheckboxField>
                  </div>
                );
              },
              circular: (_, record) => {
                if (record.circular) {
                  return "Yes";
                } else {
                  return "No";
                }
              }
            }}
            entities={tuplingData}
          />
        </div>
        {tuplingData.error && (
          <BlueprintError error="Please fix errors in Tupling Tool input data." />
        )}
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <div className="tg-step-form-section column">
          <div className="tg-flex justify-space-between">
            <HeaderWithHelper
              header="Select Plates"
              helper="Select plates containing samples to be multiplexed."
            />
            {tuplingData.length !== 0 &&
              containerArrays.length === 0 &&
              !isIntegratedMap.containerArrays && (
                <div
                  style={{
                    marginBottom: 10,
                    marginRight: 20,
                    alignSelf: "flex-end"
                  }}
                >
                  <Button
                    id={toolConstants.CLEAR_BUTTON_ID}
                    onClick={this.clearTuplingData}
                    intent="danger"
                    text="Clear"
                  />
                </div>
              )}
          </div>
          <div className="width100 column">
            <GenericSelect
              {...{
                name: "containerArrays", //the field name within the redux form Field
                isMultiSelect: true,
                isRequired: true,
                onSelect: this.onSelectContainerArrays,
                onClear: this.clearTuplingData,
                schema: ["name", dateModifiedColumn],
                fragment: [
                  "containerArray",
                  "id name barcode { id barcodeString } updatedAt"
                ],
                additionalDataFragment: tuplingToolPlateFragment,
                postSelectDTProps: {
                  formName: toolSchema.code,
                  plateErrors,
                  schema: [
                    platePreviewColumn({
                      plateErrors
                    }),
                    "name",
                    {
                      displayName: "Plate Type",
                      path: "containerArrayType.name"
                    },
                    {
                      displayName: "Barcode",
                      path: "barcode.barcodeString"
                    }
                  ],
                  buttonProps: {
                    loading: isLoadingMap.containerArrays,
                    disabled: isDisabledMap.containerArrays
                  }
                }
              }}
            />
          </div>
          {containerArrays.length !== 0 && plateViewer}
          <div style={{ marginTop: "24px" }}>
            {!(tuplingData.length === 0) && (
              <HeaderWithHelper
                header="Samples to be Multiplexed"
                helper="The following table shows a summary of the aliquots that are going to be submitted for multiplexing."
              />
            )}
          </div>
          {tuplingData.length !== 0 && table}
          {warning.check && (
            <div className="tg-flex justify-flex-end">
              <BlueprintError error={warning.message} />
            </div>
          )}
        </div>
        <Footer
          {...footerProps}
          errorMessage={!isEmpty(plateErrors) && "Review plate errors."}
          nextButton={
            <Button
              id={toolConstants.STEP_1_NEXT_BUTTON_ID}
              type="next"
              intent={Intent.PRIMARY}
              text="Next"
              disabled={
                !tuplingData.length ||
                !isEmpty(plateErrors) ||
                tuplingData
                  .filter(record => !record.ignored)
                  .some(record => record.error)
              }
            />
          }
        />
      </React.Fragment>
    );
  }
}

export default compose(
  tgFormValues("featureTags", "tuplingData", "containerArrays", "warning")
)(SelectSamples);
