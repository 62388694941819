/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import {
  BlueprintError,
  DialogFooter,
  FileUploadField,
  wrapDialog,
  throwFormError
} from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { omit } from "lodash";
import { unparse } from "papaparse";
import { safeUpsert, safeQuery } from "../../../../src-shared/apolloMethods";
import {
  allowedCsvFileTypes,
  parseCsvOrExcelFile
} from "@teselagen/file-utils";
import { download } from "../../../../src-shared/utils/downloadTest";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";

const BulkUpdatePlateAliquotsExtendedPropertyDialog = props => {
  const {
    aliquotContainers,
    containerArray,
    handleSubmit,
    submitting,
    hideModal,
    error,
    refetch
  } = props;

  const processCsvFile = async ([csvFile]) => {
    const parsedCsv = await parseCsvOrExcelFile(csvFile);
    if (parsedCsv.error) {
      return { error: parsedCsv.error };
    } else {
      const {
        errors,
        meta: { fields }
      } = parsedCsv;

      const errorPreface = `Error in file ${csvFile.name}: `;
      if (errors && errors.length) {
        return { error: errorPreface + errors.join("\n") };
      } else if (!fields.includes("id") || !fields.includes("Location")) {
        return {
          error: errorPreface + "Missing required header of id and/or Location."
        };
      }
    }
    const { data } = parsedCsv;
    return { data };
  };

  const createItemIdToPropertiesMap = fileData => {
    return fileData.reduce((acc, data) => {
      if (!acc[data.id]) acc[data.id] = {};
      acc[data.id] = { ...acc[data.id], ...omit(data, "id", "Location") };
      return acc;
    }, {});
  };

  const getPropertyMap = async idToProperties => {
    const propertyNamesMap = {};
    for (const properties of Object.values(idToProperties)) {
      Object.assign(propertyNamesMap, properties);
    }

    const propertyNames = Object.keys(propertyNamesMap);
    const queryResults = await safeQuery(
      [
        "extendedProperty",
        `id
        name
        extendedTypeCode
        extendedPropertyClassCode
        extendedCategoryClass {
          id
          extendedCategories {
            id
            name
          }
        }
        extendedMeasurementUnitClass {
          id
          measurementUnits {
            id
            abbreviation
          }
        }
    `
      ],
      {
        isPlural: true,
        variables: {
          filter: { name: propertyNames, modelTypeCode: "ALIQUOT" }
        }
      }
    );

    const propNameToProp = queryResults.reduce((acc, r) => {
      acc[r.name] = r;
      return acc;
    }, {});
    const existingPropNames = Object.keys(propNameToProp);
    const missingPropNames = Object.keys(propertyNamesMap).filter(
      name => !existingPropNames.includes(name)
    );
    if (missingPropNames.length) {
      throw new Error(
        "These properties do not exist: ",
        missingPropNames.join(", ")
      );
    }
    return propNameToProp;
  };

  /**
   * Assumes all of the extended properties have already been created in the database.
   */
  const updateWithNewExtendedValues = async (
    propertyMap,
    itemIdToProperties
  ) => {
    const aliquots = await safeQuery(
      [
        "aliquot",
        `
          id
          extendedValues {
            id
            extendedPropertyId
            value
          }
          extendedCategoryValues {
            id
            extendedCategoryId
            extendedPropertyId
          }
          extendedMeasurementValues {
            id
            value
            measurementUnitId
            extendedPropertyId
          }
        `
      ],
      {
        isPlural: true,
        variables: {
          filter: { id: Object.keys(itemIdToProperties) }
        }
      }
    );

    const vCreates = [];
    const vUpdates = [];
    const cCreates = [];
    const cUpdates = [];
    const mCreates = [];
    const mUpdates = [];
    for (const aliquot of aliquots) {
      const propertyToValue = itemIdToProperties[aliquot.id];
      // eslint-disable-next-line no-loop-func
      Object.keys(propertyToValue).forEach(propName => {
        const property = propertyMap[propName];
        const value =
          propertyToValue[propName] && propertyToValue[propName].trim();
        if (!value) return;
        if (property.extendedPropertyClassCode === "VALUE") {
          const existingValue = aliquot.extendedValues.find(
            v => v.extendedPropertyId === property.id
          );
          if (existingValue) {
            vUpdates.push({
              id: existingValue.id,
              value
            });
          } else {
            vCreates.push({
              value,
              extendedPropertyId: property.id,
              aliquotId: aliquot.id
            });
          }
        } else if (property.extendedPropertyClassCode === "MEASUREMENT") {
          const existingValue = aliquot.extendedMeasurementValues.find(
            v => v.extendedPropertyId === property.id
          );
          const [number, unit] = value.split(" ");
          const measurementUnit =
            property.extendedMeasurementUnitClass.measurementUnits.find(
              mUnit => mUnit.abbreviation === unit
            );
          if (!measurementUnit) {
            throw new Error(
              `Invalid unit provided on row with aliquot id ${aliquot.id} for property ${property.name}`
            );
          }
          if (existingValue) {
            mUpdates.push({
              id: existingValue.id,
              value: number,
              measurementUnitId: measurementUnit.id
            });
          } else {
            mCreates.push({
              extendedPropertyId: property.id,
              aliquotId: aliquot.id,
              value: number,
              measurementUnitId: measurementUnit.id
            });
          }
        } else if (property.extendedPropertyClassCode === "CATEGORY") {
          const existingValue = aliquot.extendedCategoryValues.find(
            v => v.extendedPropertyId === property.id
          );
          const category =
            property.extendedCategoryClass.extendedCategories.find(
              cat => cat.name === value
            );
          if (!category) {
            throw new Error(
              `Invalid category provided on row with aliquot id ${aliquot.id} for property ${property.name}`
            );
          }
          if (existingValue) {
            cUpdates.push({
              id: existingValue.id,
              extendedCategoryId: category.id
            });
          } else {
            cCreates.push({
              extendedPropertyId: property.id,
              aliquotId: aliquot.id,
              extendedCategoryId: category.id
            });
          }
        }
      });
    }
    await safeUpsert("extendedMeasurementValue", mCreates);
    await safeUpsert("extendedMeasurementValue", mUpdates);
    await safeUpsert("extendedCategoryValue", cCreates);
    await safeUpsert("extendedCategoryValue", cUpdates);
    await safeUpsert("extendedValue", vCreates);
    await safeUpsert("extendedValue", vUpdates);
  };

  const onSubmit = async ({ csvFile }) => {
    try {
      const { error, data } = await processCsvFile(csvFile);

      if (error) throw new Error(error);
      if (!data.length) {
        throw new Error("No valid csv files.");
      }

      const itemIdToProperties = createItemIdToPropertiesMap(data);
      const propNameToProp = await getPropertyMap(itemIdToProperties);

      await updateWithNewExtendedValues(propNameToProp, itemIdToProperties);

      if (refetch) await refetch();

      hideModal();
    } catch (e) {
      console.error(e);
      throwFormError(e.message || "Error updating fields");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={Classes.DIALOG_BODY}>
        <FileUploadField
          name="csvFile"
          fileLimit={1}
          isRequired
          accept={{
            type: allowedCsvFileTypes,
            exampleFile: () => {
              const rows = [];
              aliquotContainers.forEach((ac, i) => {
                if (ac.aliquot) {
                  rows.push({
                    id: ac.aliquot.id,
                    Location: getAliquotContainerLocation(ac),
                    "EXAMPLE FIELD 1": `example value ${i}`,
                    "EXAMPLE FIELD 2": `second example value ${i}`
                  });
                }
              });
              const csv = unparse(rows);
              download(
                csv,
                `${containerArray.name} Extended Properties.csv`,
                "text/plain"
              );
            }
          }}
        />
        <BlueprintError error={error} />
      </div>
      <DialogFooter
        error={error}
        submitting={submitting}
        hideModal={hideModal}
      />
    </form>
  );
};

export default compose(
  wrapDialog({ title: "Update Extended Properties" }),
  reduxForm({
    enableReinitialize: true,
    form: "updateExtendedPropertiesDialogForm"
  })
)(BulkUpdatePlateAliquotsExtendedPropertyDialog);
