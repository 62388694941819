/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { keyBy, get } from "lodash";
import {
  standardizeVolume,
  convertMolarity,
  convertConcentration,
  cleanUnit
} from "../../../../src-shared/utils/unitUtils";
import { getAliquotMolecularWeight } from "../../../../../tg-iso-lims/src/utils/aliquotUtils";
import {
  molarToNanoMolar,
  calculateMolarityFromConcentration,
  defaultMolarityUnitCode,
  calculateConcentrationFromMolarity,
  defaultConcentrationUnitCode,
  getCellCount
} from "../../../../../tg-iso-lims/src/utils/unitUtils";
import { throwFormError } from "../../../../src-shared/utils/formUtils";
import { safeUpsert, safeQuery } from "../../../../src-shared/apolloMethods";

import {
  isCsvOrExcelFile,
  extractZipFiles,
  parseCsvOrExcelFile
} from "../../../../../tg-iso-shared/src/utils/fileUtils";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import {
  getPositionFromAlphanumericLocation,
  wellInBounds
} from "../../../../../tg-iso-lims/src/utils/plateUtils";
import unitGlobals from "../../../../../tg-iso-lims/src/unitGlobals";
import { camelCase } from "lodash";
import {
  handleUpdateRackTubeBarcodes,
  uploadRackTubeBarcodesFragment
} from "../../UploadRackTubeBarcodes";
import isValidNonNegativeNumber from "../../../../../tg-iso-shared/src/utils/isValidNonNegativeNumber";
import gql from "graphql-tag";

const updatePlateToolTubeFragment = gql`
  fragment updatePlateToolTubeFragment on aliquotContainer {
    id
    rowPosition
    columnPosition
    aliquotContainerTypeCode
    barcode {
      id
      barcodeString
    }
    aliquot {
      id
      isDry
      volume
      volumetricUnitCode
      mass
      massUnitCode
      concentration
      concentrationUnitCode
      cellConcentration
      cellConcentrationUnitCode
      cellCount
      molarity
      molarityUnitCode
      sample {
        id
        material {
          id
          polynucleotideMaterialSequence {
            id
            molecularWeight
          }
          functionalProteinUnit {
            id
            molecularWeight
          }
        }
      }
    }
  }
`;

const updatePlateToolPlateFragment = gql`
  fragment updatePlateToolPlateFragment on containerArray {
    id
    name
    barcode {
      id
      barcodeString
    }
    containerArrayTypeId
    aliquotContainers {
      ...updatePlateToolTubeFragment
    }
  }
  ${updatePlateToolTubeFragment}
`;

const onSubmit = async values => {
  try {
    const {
      containerArrays = [],
      aliquotContainers = [],
      plateOrTube,
      updateType,
      updateFile = [],
      selectTable,
      dataTable,
      recalculateConcentration
    } = values;

    const isTubeUpdate = plateOrTube === "tube";

    const allFiles = await extractZipFiles(updateFile);

    let unitPrefix = updateType;
    if (updateType === "Volume") {
      unitPrefix = "Volumetric";
    }
    const unitHeader = unitPrefix + " Unit";

    let parsedCsv;
    const dataRowsToUse = [];
    if (selectTable && !isTubeUpdate) {
      const tableWithRows = await safeQuery(
        ["dataTable", "id dataRows { id rowValues }"],
        {
          variables: {
            id: dataTable.id
          }
        }
      );
      tableWithRows.dataRows.forEach(row => {
        const { plateName, plateBarcode, wellPosition } = row.rowValues;
        const ccUnitHeader = camelCase(unitHeader);
        dataRowsToUse.push({
          plateName,
          plateBarcode,
          unitValue: row.rowValues[camelCase(updateType)],
          unit: row.rowValues[ccUnitHeader],
          location: wellPosition
        });
      });
    } else {
      const unparsedCsvFiles = allFiles.filter(file => isCsvOrExcelFile(file));
      if (unparsedCsvFiles.length > 1) {
        throw new Error("Multiple CSV files found");
      } else if (unparsedCsvFiles.length < 1) {
        throw new Error("No CSV file found");
      }
      parsedCsv = await parseCsvOrExcelFile(unparsedCsvFiles[0]);

      if (updateType !== "Tube Barcodes") {
        for (const row of parsedCsv.data) {
          const {
            "Plate Name": plateName,
            "Plate Barcode": plateBarcode,
            "Tube Barcode": tubeBarcode,
            "Well Location": location
          } = row;
          const value = row[updateType];
          const unit = row[unitHeader];
          if (value) {
            dataRowsToUse.push({
              plateName,
              plateBarcode,
              tubeBarcode,
              unitValue: value,
              unit,
              ...((updateType === "Concentration" ||
                updateType === "Molarity" ||
                updateType === "Cell Concentration") && {
                volValue: row["Volume (optional)"],
                volUnit: row["Volumetric Unit (optional)"]
              }),
              location
            });
          }
        }
      }
    }

    if (updateType === "Tube Barcodes") {
      if (!isTubeUpdate) {
        const racksToUpdate = await safeQuery(uploadRackTubeBarcodesFragment, {
          variables: {
            filter: {
              id: containerArrays.map(c => c.id)
            }
          }
        });

        await handleUpdateRackTubeBarcodes({
          csvData: parsedCsv.data,
          racksToUse: racksToUpdate,
          hasHeaderRow: !selectTable
        });
      } else {
        const keyedTubes = keyBy(aliquotContainers, "barcode.barcodeString");
        const errors = [];
        const barcodeUpdates = [];
        for (const [index, row] of parsedCsv.data.entries()) {
          const { "Tube Barcode": tubeBarcode, "Updated Barcode": newBarcode } =
            row;
          if (tubeBarcode && newBarcode) {
            const aliquotContainer = keyedTubes[tubeBarcode];
            if (aliquotContainer) {
              barcodeUpdates.push({
                id: aliquotContainer.barcode.id,
                barcodeString: newBarcode
              });
            } else {
              errors.push(
                `Row ${index + 2}: Tube with barcode ${tubeBarcode} not found`
              );
            }
          } else {
            errors.push(
              `Row ${index + 2}: Missing tube barcode or new barcode`
            );
          }
        }
        if (errors.length) {
          throw new Error(errors.join("\n"));
        }
        await safeUpsert("barcode", barcodeUpdates);
      }
    } else {
      const platesToUpdate = containerArrays.length
        ? await safeQuery(updatePlateToolPlateFragment, {
            variables: {
              where: {
                id: {
                  _in: containerArrays.map(c => c.id)
                }
              }
            }
          })
        : [];
      const tubesToUpdate = aliquotContainers.length
        ? await safeQuery(updatePlateToolTubeFragment, {
            variables: {
              where: {
                id: {
                  _in: aliquotContainers.map(c => c.id)
                }
              }
            }
          })
        : [];
      const aliquotContainerTypeCodes = [];
      const containerArrayTypeIds = [];
      const plateToLocationMap = {};

      platesToUpdate.forEach(plate => {
        if (!containerArrayTypeIds.includes(plate.containerArrayTypeId)) {
          containerArrayTypeIds.push(plate.containerArrayTypeId);
        }
        plateToLocationMap[plate.id] = {};
        plate.aliquotContainers.forEach(ac => {
          plateToLocationMap[plate.id][getAliquotContainerLocation(ac)] = ac;
          if (
            !aliquotContainerTypeCodes.includes(ac.aliquotContainerTypeCode)
          ) {
            aliquotContainerTypeCodes.push(ac.aliquotContainerTypeCode);
          }
        });
      });
      tubesToUpdate.forEach(tube => {
        if (
          !aliquotContainerTypeCodes.includes(tube.aliquotContainerTypeCode)
        ) {
          aliquotContainerTypeCodes.push(tube.aliquotContainerTypeCode);
        }
      });
      const containerArrayTypes = containerArrayTypeIds.length
        ? await safeQuery(
            [
              "containerArrayType",
              /* GraphQL */ `
                {
                  id
                  isPlate
                  containerFormat {
                    code
                    rowCount
                    columnCount
                    quadrantSize
                  }
                }
              `
            ],
            {
              variables: {
                filter: {
                  id: containerArrayTypeIds
                }
              }
            }
          )
        : [];
      const keyedContainerArrayTypes = keyBy(containerArrayTypes, "id");
      const aliquotContainerTypes = aliquotContainerTypeCodes.length
        ? await safeQuery(
            [
              "aliquotContainerType",
              /* GraphQL */ `
                {
                  code
                  maxVolume
                  volumetricUnitCode
                }
              `
            ],
            {
              idAs: "code",
              variables: {
                filter: {
                  code: aliquotContainerTypeCodes
                }
              }
            }
          )
        : [];
      const keyedAliquotContainerTypes = keyBy(aliquotContainerTypes, "code");

      const errors = [];

      const getPlateMatch = (plateName, plateBarcode) => {
        return platesToUpdate.find(p => {
          const nameMatch =
            plateName && p.name.toLowerCase() === plateName.toLowerCase();
          const barcodeMatch =
            plateBarcode && get(p, "barcode.barcodeString") === plateBarcode;
          if (plateName && plateBarcode) {
            return nameMatch && barcodeMatch;
          } else if (plateName) {
            return nameMatch;
          } else {
            return barcodeMatch;
          }
        });
      };

      const keyedUnits = unitGlobals[camelCase(unitPrefix) + "Units"] || {};

      const aliquotUpdates = [];
      const keyedTubes = keyBy(tubesToUpdate, "barcode.barcodeString");

      for (const [index, row] of dataRowsToUse.entries()) {
        const { plateName, plateBarcode, tubeBarcode, location, unitValue } =
          row;
        const unit = cleanUnit(row.unit);
        const makeError = message => {
          const indexToUse = selectTable ? index + 1 : index + 2; // +2 because of the header row
          errors.push(`Row ${indexToUse}: ${message}`);
        };

        const makeAliquotError = message => {
          if (isTubeUpdate) {
            makeError(`The aliquot in the tube ${tubeBarcode} ${message}.`);
          } else {
            makeError(
              `The aliquot at this well position (${location}) ${message}.`
            );
          }
        };

        if (isTubeUpdate && !tubeBarcode) {
          makeError(`did not specify a tube barcode`);
          continue;
        }
        if (!isTubeUpdate && !plateName.trim() && !plateBarcode.trim()) {
          makeError(`did not specify a plate name or barcode`);
          continue;
        }

        let plateToUpdate;
        if (!isTubeUpdate) {
          plateToUpdate = getPlateMatch(plateName, plateBarcode);
          if (!plateToUpdate) {
            if (plateName && plateBarcode) {
              makeError(
                `No plate was selected with the name ${plateName} and barcode ${plateBarcode}`
              );
            } else if (plateName) {
              makeError(`No plate was selected with the name ${plateName}`);
            } else {
              makeError(
                `No plate was selected with the barcode ${plateBarcode}`
              );
            }
            continue;
          }
        }

        if (!row.unit) {
          makeError(`did not specify a ${unitHeader}`);
          continue;
        }

        if (!unit || !keyedUnits[unit]) {
          makeError(`specified the unit ${row.unit} which was not found.`);
          continue;
        }
        if (
          updateType === "Concentration" ||
          updateType === "Molarity" ||
          updateType === "Cell Concentration"
        ) {
          const volumeKeyedUnits = unitGlobals["volumetricUnits"] || {};
          if (row["volUnit"] && !volumeKeyedUnits[row["volUnit"]]) {
            makeError(
              `specified the volume unit ${row["volUnit"]} which was not found.`
            );
            continue;
          }
        }
        let aliquotContainer;
        let isPlate;
        if (isTubeUpdate) {
          aliquotContainer = keyedTubes[tubeBarcode];
          if (!aliquotContainer) {
            makeError(`No tube was found with barcode ${tubeBarcode}`);
            continue;
          }
        } else {
          const containerArrayType =
            keyedContainerArrayTypes[plateToUpdate.containerArrayTypeId];

          isPlate = containerArrayType.isPlate;
          const wellPosition = getPositionFromAlphanumericLocation(
            location,
            containerArrayType.containerFormat
          );

          if (isNaN(wellPosition.rowPosition)) {
            makeError(
              `specified the location ${location} which was not valid.`
            );
            continue;
          }

          if (!wellInBounds(location, containerArrayType.containerFormat)) {
            makeError(
              `specified the location ${location} which was not in the bounds of the plate.`
            );
            continue;
          }

          aliquotContainer =
            plateToLocationMap[plateToUpdate.id][
              getAliquotContainerLocation(wellPosition)
            ];
          if (!aliquotContainer || !aliquotContainer.aliquot) {
            makeError(
              `No aliquot was found at this well position ${location}.`
            );
            continue;
          }
        }
        if (
          tubeBarcode &&
          aliquotContainer.barcode?.barcodeString !== tubeBarcode
        ) {
          makeError(
            `Tube barcode did not match barcode in csv at well position ${location}.`
          );
          continue;
        }

        const aliquot = aliquotContainer.aliquot;
        const valAsNumber = Number(unitValue);
        if (isNaN(valAsNumber)) {
          makeError(`No ${updateType} was provided.`);
          continue;
        } else if (valAsNumber < 0) {
          makeError(`Provided a negative ${updateType}.`);
          continue;
        }

        if (
          [
            "Volume",
            "Concentration",
            "Molarity",
            "Cell Concentration"
          ].includes(updateType) &&
          aliquot.isDry
        ) {
          makeAliquotError(`is dry.`);
          continue;
        } else if (updateType === "Mass" && !aliquot.isDry) {
          makeAliquotError(`is not dry.`);
          continue;
        }
        const molecularWeight = getAliquotMolecularWeight(aliquot);
        const isValidVolumeUpdate = (volAsNumber, volUnit) => {
          const aliquotContainerType =
            keyedAliquotContainerTypes[
              aliquotContainer.aliquotContainerTypeCode
            ];
          if (!isValidNonNegativeNumber(volAsNumber)) {
            makeError(`Invalid volume specified`);
            return false;
          }
          const standardVolume = standardizeVolume(
            volAsNumber,
            volUnit,
            keyedUnits
          );
          const { maxVolume, volumetricUnitCode } = aliquotContainerType;
          const maxWellVolume = standardizeVolume(
            maxVolume,
            volumetricUnitCode,
            unitGlobals.volumetricUnits
          );
          if (standardVolume.gt(maxWellVolume)) {
            makeError(
              `The new volume (${volAsNumber} ${volUnit}) would be greater than this ${
                isPlate ? "well's" : "tube's"
              } max volume (${maxVolume} ${volumetricUnitCode}).`
            );
            return false;
          }
          return true;
        };

        if (updateType === "Volume") {
          if (!isValidVolumeUpdate(valAsNumber, unit)) {
            continue;
          }
          const standardVolume = standardizeVolume(
            valAsNumber,
            unit,
            keyedUnits
          );
          const update = {
            id: aliquot.id,
            volume: valAsNumber,
            volumetricUnitCode: unit
          };
          if (valAsNumber === 0) {
            update.concentration = null;
            update.molarity = null;
            update.cellCount = null;
            update.cellConcentration = null;
          } else if (
            recalculateConcentration &&
            aliquot.volume &&
            valAsNumber > 0
          ) {
            const oldVolume = standardizeVolume(
              aliquot.volume,
              aliquot.volumetricUnitCode
            );
            const newVolume = standardVolume;
            const ratio = newVolume / oldVolume;
            if (aliquot.concentration) {
              const newConcentration = aliquot.concentration / ratio;
              update.concentration = newConcentration;
              if (molecularWeight) {
                let molarity = calculateMolarityFromConcentration(
                  newConcentration,
                  aliquot.concentrationUnitCode,
                  molecularWeight
                );
                const molarityUnitCode =
                  aliquot.molarityUnitCode || defaultMolarityUnitCode;
                molarity = convertMolarity(molarity, "M", molarityUnitCode);
                update.molarity = molarity;
                update.molarityUnitCode = molarityUnitCode;
              }
            } else if (aliquot.molarity) {
              const newMolarity = aliquot.molarity / ratio;
              update.molarity = newMolarity;
              if (molecularWeight) {
                let concentration = calculateConcentrationFromMolarity(
                  newMolarity,
                  aliquot.molarityUnitCode,
                  molecularWeight
                );
                const concentrationUnitCode =
                  aliquot.concentrationUnitCode || defaultConcentrationUnitCode;
                concentration = convertConcentration(
                  concentration,
                  "g/L",
                  concentrationUnitCode
                );
                update.concentration = concentration;
                update.concentrationUnitCode = concentrationUnitCode;
              }
            }
            if (aliquot.cellConcentration) {
              update.cellConcentration = aliquot.cellConcentration / ratio;
            }
            if (update.cellConcentration) {
              update.cellCount = getCellCount({
                cellConcentration: update.cellConcentration,
                cellConcentrationUnitCode: aliquot.cellConcentrationUnitCode,
                volume: update.volume,
                volumetricUnitCode: update.volumetricUnitCode
              });
            }
          }
          aliquotUpdates.push(update);
        } else if (updateType === "Mass") {
          aliquotUpdates.push({
            id: aliquot.id,
            mass: valAsNumber,
            massUnitCode: unit
          });
        } else if (
          updateType === "Concentration" ||
          updateType === "Molarity"
        ) {
          const { volUnit, volValue } = row;
          const volValAsNumber = Number(volValue);
          const update = {};
          if (updateType === "Concentration") {
            update.concentration = valAsNumber;
            update.concentrationUnitCode = unit;
            if (volValAsNumber) {
              if (!isValidVolumeUpdate(volValAsNumber, volUnit)) {
                continue;
              }
              update.volume = volValAsNumber;
              update.volumetricUnitCode = volUnit;
            }
            if (molecularWeight) {
              update.molarity =
                calculateMolarityFromConcentration(
                  update.concentration,
                  update.concentrationUnitCode,
                  molecularWeight
                ) * molarToNanoMolar;
              update.molarityUnitCode =
                aliquot.molarityUnitCode || defaultMolarityUnitCode;
              if (update.molarityUnitCode !== defaultMolarityUnitCode) {
                update.molarity = convertMolarity(
                  update.molarity,
                  defaultMolarityUnitCode,
                  update.molarityUnitCode
                );
              }
            }
          } else {
            update.molarity = valAsNumber;
            update.molarityUnitCode = unit;
            if (volValAsNumber) {
              if (!isValidVolumeUpdate(volValAsNumber, volUnit)) {
                continue;
              }
              update.volume = volValAsNumber;
              update.volumetricUnitCode = volUnit;
            }
            if (molecularWeight) {
              update.concentration = calculateConcentrationFromMolarity(
                update.molarity,
                update.molarityUnitCode,
                molecularWeight
              );
              update.concentrationUnitCode =
                aliquot.concentrationUnitCode || defaultConcentrationUnitCode;
              if (update.concentrationUnitCode !== "g/L") {
                update.concentration = convertConcentration(
                  update.concentration,
                  "g/L",
                  update.concentrationUnitCode
                );
              }
            }
          }
          aliquotUpdates.push({
            id: aliquot.id,
            ...update
          });
        } else if (updateType === "Cell Concentration") {
          const { volUnit, volValue } = row;
          const volValAsNumber = Number(volValue);
          if (volValAsNumber) {
            if (!isValidVolumeUpdate(volValAsNumber, volUnit)) {
              continue;
            }
          }
          const cellCount = getCellCount({
            cellConcentration: valAsNumber,
            cellConcentrationUnitCode: unit,
            volume: aliquot.volume,
            volumetricUnitCode: aliquot.volumetricUnitCode,
            ...(volValAsNumber && {
              volume: volValAsNumber,
              volumetricUnitCode: volUnit
            })
          });
          aliquotUpdates.push({
            id: aliquot.id,
            cellCount: cellCount,
            cellConcentration: valAsNumber,
            cellConcentrationUnitCode: unit,
            ...(volValAsNumber && {
              volume: volValAsNumber,
              volumetricUnitCode: volUnit
            })
          });
        }
      }

      if (errors.length) {
        throw new Error(errors.join("\n"));
      }
      await safeUpsert("aliquot", aliquotUpdates);
    }

    await safeUpsert(
      ["containerArray", "id updatedAt"],
      containerArrays.map(p => ({ id: p.id, updatedAt: new Date() }))
    );
    await safeUpsert(
      ["aliquotContainer", "id updatedAt"],
      aliquotContainers.map(p => ({ id: p.id, updatedAt: new Date() }))
    );
    return {
      containerArrays,
      aliquotContainers
    };
  } catch (error) {
    console.error("error:", error);
    const message = error.message || "Error updating plates/tubes.";
    throwFormError(message);
  }
};

export { onSubmit };
