/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable local-eslint-plugin/no-direct-dialog */
/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useCallback } from "react";
import { compose } from "redux";

import { Dialog, Classes, Intent } from "@blueprintjs/core";
import {
  DataTable,
  withTableParams,
  showConfirmationDialog,
  DialogFooter
} from "@teselagen/ui";

import withQuery from "../../../../src-shared/withQuery";

import { generateFragmentWithFields } from "@teselagen/apollo-methods";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";

import defaultAsyncWrap from "../../../../src-shared/utils/defaultAsyncWrap";
import { safeDelete } from "../../../../src-shared/apolloMethods";
import { annotationSizeStartEndColumns } from "../../../../src-shared/utils/libraryColumns";
import { withProps } from "recompose";

class DeletePartsFromFeaturesDialog extends React.PureComponent {
  cellRenderer = {
    strand: strand => (strand < 0 ? "-" : "+")
  };

  onSubmit = defaultAsyncWrap(async () => {
    const { selectedEntities, hideModal, onRefresh } = this.props;

    const plural = selectedEntities.length > 1;
    const confirm = await showConfirmationDialog({
      text: `Are you sure you want to delete ${
        plural ? "these features" : "this feature"
      }?`,
      intent: Intent.DANGER, //applied to the right most confirm button
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      canEscapeKeyCancel: true //this is false by default
    });

    if (confirm) {
      await safeDelete(
        "sequenceFeature",
        selectedEntities.map(f => f.id)
      );
      await onRefresh();
      hideModal();
    }
  }, "Error creating parts from features.");

  render() {
    const { tableParams, hideModal, loading, selectedEntities, submitting } =
      this.props;
    return (
      <Dialog
        canOutsideClickClose={false}
        isOpen
        onClose={hideModal}
        title="Delete Features from Sequences"
        style={{ width: 850 }}
      >
        <div className={Classes.DIALOG_BODY}>
          Choose which features you would like to delete.
          <DataTable
            {...tableParams}
            doNotShowEmptyRows
            isLoading={loading}
            cellRenderer={this.cellRenderer}
            withCheckboxes
            showCount
          />
        </div>
        <DialogFooter
          hideModal={hideModal}
          text="Delete Features"
          onClick={this.onSubmit}
          disabled={!selectedEntities.length}
          loading={submitting}
        />
      </Dialog>
    );
  }
}

const selector = formValueSelector("DeletePartsFromFeaturesDialog");

const mapStateToProps = state => ({
  selectedEntities: Object.values(
    selector(state, "reduxFormSelectedEntityIdMap") || {}
  )
    .filter(val => val !== false)
    .map(v => v.entity)
});

export default compose(
  withProps(props => {
    // Added while #12943 is not ready
    const additionalFilter = useCallback(
      (_, qb) => {
        qb.whereAll({ "sequence.id": props.sequenceIds });
      },
      [props.sequenceIds]
    );
    return { additionalFilter };
  }),
  withTableParams({
    schema: {
      model: "sequenceFeature",
      fields: [
        {
          path: "id",
          type: "string",
          isHidden: true,
          searchDisabled: true
        },
        { path: "name", type: "string", displayName: "Name" },
        { path: "type", type: "string", displayName: "Type" },
        ...annotationSizeStartEndColumns,
        {
          path: "strand",
          type: "number",
          displayName: "Strand",
          searchDisabled: true
        },
        {
          path: "sequence.name",
          type: "string",
          displayName: "Sequence of Origin"
        }
      ]
    },
    formName: "DeletePartsFromFeaturesDialog"
  }),
  withQuery(
    generateFragmentWithFields(
      "sequenceFeature",
      "id name type start end strand sequence { id name size }"
    ),
    {
      isPlural: true
    }
  ),
  connect(mapStateToProps)
)(DeletePartsFromFeaturesDialog);
