/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Button, Classes, Colors, InputGroup } from "@blueprintjs/core";
import { map } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { compose } from "recompose";
import { formValueSelector, reduxForm } from "redux-form";
import {
  CheckboxField,
  DialogFooter,
  InfoHelper,
  RadioGroupField,
  ReactSelectField,
  SuggestField,
  Loading,
  TextareaField,
  AdvancedOptions
} from "@teselagen/ui";

import { useDebouncedCallback } from "use-debounce";
import { safeUpsert, useTgQuery } from "../apolloMethods";
import { wrapDialog } from "@teselagen/ui";
import defaultValueGeneratorFragment from "../../../tg-iso-shared/src/fragments/defaultValueGeneratorFragment";
import { getDefaultValueSharedParams } from "../defaultValueSharedParams";
import { useSelector } from "react-redux";
import { flatMap } from "lodash";
import { get } from "lodash";
import GenericSelect from "../GenericSelect";
import { getHelpArticleLink } from "../utils/generalUtils";
import CurrentUserContext from "../context/CurrentUserContext";

function AssignDefaultsModal({
  generateDefaultValue,
  submitting,
  handleSubmit,
  onFinish,
  change,
  hideModal
}) {
  const { currentUser = {} } = useContext(CurrentUserContext);
  const mode = useSelector(state =>
    formValueSelector("AssignDefaultsModal")(state, "mode")
  );
  const templateString = useSelector(state =>
    formValueSelector("AssignDefaultsModal")(state, "templateString")
  );
  const endpointUrlString = useSelector(state =>
    formValueSelector("AssignDefaultsModal")(state, "endpointUrlString")
  );
  const sharedParams = useMemo(() => getDefaultValueSharedParams(), []);
  const params = {
    ...sharedParams,
    ...generateDefaultValue.params,
    ...generateDefaultValue.staticParams,
    ...generateDefaultValue.customParams
  };

  const explicitlyInAdvancedMode = false;
  const [loadingExample, setLoadingExample] = useState(false);
  const [exampleOutput, setExampleOutput] = useState();
  const [exampleUrlOutput, setExampleUrlOutput] = useState();
  const [warningMessage, setWarningMessage] = useState();
  const [defaultValChangeCounter, setDefaultValChanged] = useState();

  const [possibleNodeRedUrls, setPossibleUrls] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await window.serverApi.request(
          `${window.frontEndConfig.nodeRedEditorUrl}/flows`
        );
        const httpInOptions = [];
        res.data.forEach(n => {
          if (n.type === "http in") {
            const val =
              "node-red://" + (n.url.startsWith("/") ? n.url.substr(1) : n.url);
            httpInOptions.push({
              __info: n,
              label: val,
              value: val
            });
          }
        });
        setPossibleUrls(httpInOptions);
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, []);

  const debounced = useDebouncedCallback(
    // function
    async ({ code, params, templateString, endpointUrlString, isUrl }) => {
      const { defaultValue: example, warningMessage } =
        await window.__triggerGetDefaultValueRequest(code, params, {
          isForExample: isUrl
            ? { __overrideUrlString: endpointUrlString }
            : {
                __overrideTemplateString:
                  templateString === ""
                    ? ""
                    : templateString ||
                      (dvg
                        ? dvg.templateString
                        : generateDefaultValue.defaultTemplateString) ||
                      ""
              }
        });
      setWarningMessage(warningMessage);

      if (isUrl) {
        setExampleUrlOutput(example);
      } else {
        setExampleOutput(example);
      }
      setLoadingExample(false);
    },
    // delay in ms
    1000
  );
  const getExample = (...args) => {
    setLoadingExample(true);
    debounced(...args);
  };
  const {
    data: { defaultValueGenerator: dvg },
    ...rest
  } = useTgQuery(defaultValueGeneratorFragment, {
    variables: { code: generateDefaultValue.code },
    idAs: "code"
  });

  useEffect(() => {
    getExample({
      code: generateDefaultValue.code,
      params,
      isUrl: mode === "endpoint",
      endpointUrlString,
      templateString
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValChangeCounter, dvg?.templateString]);

  if (useTgQuery.checkErrAndLoad(rest))
    return useTgQuery.handleErrAndLoad(rest);

  let simpleComponent = false;
  if (generateDefaultValue.fieldType === "radio") {
    simpleComponent = (
      <>
        {loadingExample ? (
          <Loading loading bounce />
        ) : (
          <RadioGroupField
            name="templateString"
            // label={"Options"}
            options={generateDefaultValue.options}
            defaultValue={
              exampleOutput || generateDefaultValue.defaultTemplateString
            }
          />
        )}
      </>
    );
  } else if (generateDefaultValue.fieldType === "select") {
    simpleComponent = (
      <>
        {loadingExample ? (
          <Loading loading bounce />
        ) : (
          <ReactSelectField
            name="templateString"
            // label={"Options"}
            options={generateDefaultValue.options}
            defaultValue={
              exampleOutput || generateDefaultValue.defaultTemplateString
            }
          />
        )}
      </>
    );
  } else if (generateDefaultValue.fieldType === "genericSelect") {
    simpleComponent = loadingExample ? (
      <Loading loading bounce />
    ) : (
      <GenericSelect
        {...generateDefaultValue}
        name="templateString"
        defaultValueByIdOverride={
          exampleOutput || {
            defaultValue: generateDefaultValue.defaultTemplateString
          } ||
          {}
        }
      />
    );
  }

  function insertAdvancedTemplateStatement(statement) {
    const ts =
      templateString || templateString === ""
        ? templateString
        : dvg
          ? dvg.templateString
          : generateDefaultValue.defaultTemplateString;
    // console.log(`templateString:`, templateString);
    const newVal = `${ts}${statement}`;
    change("templateString", newVal);
    getExample({
      code: generateDefaultValue.code,
      params,
      templateString: newVal
    });
  }
  const defaultInnerComp = (
    <>
      <RadioGroupField
        tooltipInfo="To determine the default value, you can either set a template string, or hit an endpoint of your choosing to get the value"
        name="mode"
        label="Mode"
        options={[
          { label: "Template", value: "template" },
          { label: "Endpoint", value: "endpoint" }
        ]}
        defaultValue={dvg && dvg.isUrl ? "endpoint" : "template"}
      />

      <table>
        <tbody>
          <tr>
            <td style={{ fontWeight: "bold" }}>
              Parameter:{" "}
              <InfoHelper
                size={12}
                // style={{marginTop: -5}}
                isInline
                content="These parameters can be used to fill in a template or will be sent in the request body (if you've chosen endpoint mode)"
              />{" "}
              &nbsp; &nbsp;
            </td>
            <td style={{ fontWeight: "bold" }}>Example Value:</td>
          </tr>
          {map(params, (val, key) => {
            const isMustacheTemplate = key && key.startsWith("'{{{");
            let keyDisplay = key;
            if (isMustacheTemplate) {
              keyDisplay = (
                <>
                  <InfoHelper
                    isInline
                    size={10}
                    content="This is a mustache template that will be filled in dynamically on the server."
                  />{" "}
                  &nbsp;
                  {keyDisplay}
                </>
              );
            }
            if (mode === "template") {
              keyDisplay = <a>{keyDisplay}</a>;
            }
            return (
              <tr
                style={mode === "template" ? { cursor: "pointer" } : {}}
                onClick={
                  mode === "template"
                    ? () => {
                        const ts = templateString || "";
                        const newVal = `${ts ? ts + "-" : ""}{{${key}}}`;
                        change("templateString", newVal);
                        getExample({
                          code: generateDefaultValue.code,
                          params,
                          templateString: newVal
                        });
                      }
                    : undefined
                }
                key={key}
              >
                <td className="tg-default-param-name">{keyDisplay} &nbsp;</td>
                <td>
                  <span style={{ fontSize: 10, fontStyle: "italic" }}>
                    {val}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
      {mode === "template" && (
        <>
          <AdvancedOptions style={{ marginTop: -10, marginBottom: 15 }}>
            <a
              onClick={() =>
                insertAdvancedTemplateStatement(`{% if userInitials === 'TR' %}
  Name 1
{% else %}
  Name 2
{% endif %}-{{workflowTaskCode}}`)
              }
            >
              If Statement
            </a>
            <br />
            <a
              onClick={() =>
                insertAdvancedTemplateStatement(`{{now()|date("YYYY MM DD")}}`)
              }
            >
              Current Date w/ Alternate Formatting
            </a>{" "}
            <br />
            <a
              onClick={() =>
                insertAdvancedTemplateStatement(
                  `{{ "foo" | replace("foo", "bar") | capitalize }}`
                )
              }
            >
              Replace/Capitalize
            </a>
            <br />
            <a onClick={() => insertAdvancedTemplateStatement(`{{ 2 + 3 }}`)}>
              Math
            </a>
            <br />
            <a
              onClick={() =>
                insertAdvancedTemplateStatement(
                  `{{__extendedProps__.project.someExtendedProp}}`
                )
              }
            >
              __extendedProps__.project.someExtendedProp
            </a>
            <br />
            <a
              onClick={() =>
                insertAdvancedTemplateStatement(
                  `{{__extendedProps__.workflowRun.someExtendedProp}}`
                )
              }
            >
              __extendedProps__.workflowRun.someExtendedProp
            </a>
            <br />
            <a
              onClick={() =>
                insertAdvancedTemplateStatement(
                  `{{__extendedProps__.workflowDefinition.someExtendedProp}}`
                )
              }
            >
              __extendedProps__.workflowDefinition.someExtendedProp
            </a>
          </AdvancedOptions>
          <div style={{ width: "100%", position: "relative" }}>
            <div style={{ position: "absolute", top: 25, right: 10 }}>
              <InfoHelper
                noMarginTop
                content="Reset To System Default"
                isButton
                onClick={() => {
                  change(
                    "templateString",
                    generateDefaultValue.defaultTemplateString
                  );
                  getExample({
                    code: generateDefaultValue.code,
                    params,
                    templateString: generateDefaultValue.defaultTemplateString
                  });
                }}
                minimal
                icon="reset"
              />
            </div>

            <TextareaField
              tooltipInfo={
                <div>
                  Use the list of parameters above to customize the default
                  value for this field. See the Help Docs linked above for more
                  info about using templates. Note: Extra white space will be
                  trimmed.
                </div>
              }
              style={{ resize: "vertical", minHeight: 30 }}
              placeholder={generateDefaultValue.defaultTemplateString}
              onChange={(e, val) => {
                getExample({
                  code: generateDefaultValue.code,
                  params,
                  templateString: val
                });
              }}
              label={
                generateDefaultValue.fieldType === "numericInput"
                  ? "Template"
                  : "Template String:"
              }
              name="templateString"
              defaultValue={
                dvg
                  ? dvg.templateString
                  : generateDefaultValue.defaultTemplateString
              }
            />
          </div>
        </>
      )}
      {mode === "endpoint" && (
        <>
          <SuggestField
            // validate={
            //   endpointConfig.isOptional ? validateUrlAllowEmpty : validateUrl
            // }
            options={flatMap(possibleNodeRedUrls || [], n =>
              get(n, "__info.method") === "post" ? n.value : []
            )}
            className="tg-no-form-group-margin"
            label="Endpoint URL"
            isRequired
            name="endpointUrlString"
            defaultValue={dvg ? dvg.endpointUrlString : ""}
            onDefaultValChanged={defaultVal => {
              setDefaultValChanged(defaultVal);
            }}
          />
          <br />
        </>
      )}
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          Example Output:{" "}
          {mode === "endpoint" && (
            <Button
              onClick={() => {
                if (!endpointUrlString) {
                  setExampleUrlOutput("");
                } else {
                  getExample({
                    code: generateDefaultValue.code,
                    params,
                    endpointUrlString,
                    isUrl: true
                  });
                }
              }}
              intent="success"
              small
            >
              Trigger Endpoint
            </Button>
          )}
        </div>
        <br />
        <InputGroup
          readOnly
          rightElement={loadingExample ? <Button loading minimal /> : null}
          value={
            mode === "endpoint" ? exampleUrlOutput || "" : exampleOutput || ""
          }
          className="tg-example-output-holder"
        />
      </div>
    </>
  );

  const innerComponent = explicitlyInAdvancedMode
    ? defaultInnerComp
    : simpleComponent || defaultInnerComp;

  return (
    <div className={Classes.DIALOG_BODY}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={getHelpArticleLink(
            "4714710-default-form-values-admin-configurable"
          )}
        >
          Read the help docs
        </a>
        {/* {simpleComponent &&
          //tnr: we don't want to allow "advanced mode for the time being" until more work has been done to improve it and make it usable
          generateDefaultValue.fieldType !== "radio" && (
            <React.Fragment>
              <a
                style={{ display: "flex" }}
                onClick={() => {
                  setExplicitlyInAdvancedMode(!explicitlyInAdvancedMode);
                }}
              >
                Toggle {explicitlyInAdvancedMode ? "off" : "on"} advanced mode{" "}
                &nbsp;
                <InfoHelper content="Only use this mode if you need to set up custom logic to determine the correct default value for your field"></InfoHelper>
              </a>
            </React.Fragment>
          )} */}
      </div>
      <br />
      <br />

      {innerComponent}
      {warningMessage && (
        <div style={{ color: Colors.GOLD3 }}> {warningMessage}</div>
      )}
      <CheckboxField
        tooltipInfo="Uncheck this if you want your users to ONLY be able to use the default value you're setting here."
        name="allowUserOverride"
        style={{ marginTop: 20 }}
        label="Allow User Override"
        defaultValue={dvg ? dvg.allowUserOverride !== false : true}
      />
      <DialogFooter
        hideModal={hideModal}
        submitting={submitting}
        onClick={handleSubmit(
          async ({
            templateString,
            allowUserOverride,
            endpointUrlString,
            mode
          }) => {
            await safeUpsert(
              "defaultValueGenerator",
              {
                code: generateDefaultValue.code,
                templateString:
                  generateDefaultValue.fieldType === "genericSelect"
                    ? templateString
                      ? templateString[generateDefaultValue.idAs || "id"]
                      : ""
                    : templateString || "",
                endpointUrlString: endpointUrlString || "",
                isUrl: mode === "endpoint",
                allowUserOverride,
                userId: currentUser.id
              },
              {
                idAs: "code",
                ...(dvg && dvg.code
                  ? {
                      forceUpdate: true
                    }
                  : {
                      forceCreate: true
                    })
                // excludeResults: true
              }
            );
            window.toastr.success("Default Value Set Successfully");
            onFinish && onFinish();
            hideModal();
          }
        )}
      />
    </div>
  );
}

export default compose(
  wrapDialog(),
  reduxForm({ form: "AssignDefaultsModal" })
)(AssignDefaultsModal);
