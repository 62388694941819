/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isFunction } from "lodash";
import { connect, useSelector } from "react-redux";
import { formValueSelector } from "redux-form";

export default function stepFormValues(..._fieldNames: string[]) {
  return connect((state, props: { toolSchema: { code: string } }) => {
    const { toolSchema } = props;
    if (!toolSchema)
      return console.error(
        "No toolSchema found in props. stepFormValues will not work"
      );

    const selector = formValueSelector(toolSchema.code);
    let fieldNames = _fieldNames;
    if (isFunction(fieldNames[0])) {
      fieldNames = fieldNames[0](props);
    }
    // if only selecting a single field you have to wrap in object
    if (fieldNames.length === 1) {
      return {
        // @ts-ignore
        [fieldNames[0]]: selector(state, fieldNames[0])
      };
    }
    // @ts-ignore
    return selector(state, ...fieldNames);
  });
}

export const useStepFormValues = (formName: string, fieldNames: string[]) => {
  const stepFormValues = useSelector(state =>
    // @ts-ignore
    formValueSelector(formName)(state, ...fieldNames)
  );

  return stepFormValues;
};
