/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useCallback } from "react";
import { Icon } from "@blueprintjs/core";
import classNames from "classnames";

const Step = props => {
  const { step, disabled, setActive, active, title, complete } = props;
  const setActiveStep = useCallback(() => {
    !disabled && !active && setActive(step);
  }, [disabled, active, setActive, step]);

  return (
    <div
      onClick={setActiveStep}
      className={classNames("step-group", { complete, active, disabled })}
    >
      <div className="step-mark">
        {complete ? <Icon icon="tick" /> : step + 1}
      </div>
      <div className="step-text">{title}</div>
    </div>
  );
};

export default Step;
