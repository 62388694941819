/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
import { distByAssemblyReportSequenceFragment } from "./distByAssemblyReportSequenceFragment.gql";

export const distByAssemblyReportMaterialFragment = gql`
  fragment distByAssemblyReportMaterialFragment on material {
    id
    polynucleotideMaterialSequence {
      ...distByAssemblyReportSequenceFragment
    }
  }
  ${distByAssemblyReportSequenceFragment}
`;
