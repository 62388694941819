/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { get, set, identity, startCase, isInteger } from "lodash";
import {
  validateCSVRequiredHeaders,
  validateCSVRow
} from "../../../tg-iso-shared/src/utils/fileUtils";
import caseInsensitiveFilter from "../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import { safeQuery } from "../apolloMethods";
import isValidPositiveNumber from "../../../tg-iso-shared/src/utils/isValidPositiveNumber";
import isValidNonNegativeNumber from "../../../tg-iso-shared/src/utils/isValidNonNegativeNumber";
import isValidPositiveInteger from "../../../tg-iso-shared/src/utils/isValidPositiveInteger";
import { shouldShowProjects } from "./projectUtils";
import { getActiveProjectId } from "@teselagen/auth-utils";
import modelNameToReadableName from "../../../tg-iso-shared/src/utils/modelNameToReadableName";
import { capitalize } from "lodash";
import { anOrA } from "./generalUtils";

export {
  validateCSVRequiredHeaders,
  isValidNonNegativeNumber,
  isValidPositiveNumber,
  validateCSVRow
};

export const REQUIRED_ERROR = "This field is required.";

type Options = { labelKey?: string; formatLabel?: (label: string) => string };

export const arrayToItemValuedOptions = (array = [], options: Options = {}) => {
  const { labelKey = "name", formatLabel = identity } = options;
  return array.map(a => {
    const label = get(a, labelKey);
    return {
      value: a,
      label: formatLabel(label)
    };
  });
};

export const arrayToIdOrCodeValuedOptions = (
  array: { [key: string]: any }[] = [],
  options: Options = {}
) => {
  const { labelKey = "name", formatLabel = identity } = options;
  return array.map(a => ({
    value: a.id ? a.id : a.code,
    label: formatLabel(a[labelKey])
  }));
};

export const valueEmpty = (fieldVal: any) => {
  return !fieldVal || (Array.isArray(fieldVal) && !fieldVal.length);
};

export const validateConditionallyRequiredFields = (
  fields: string[],
  values: object,
  errors: object
) => {
  const allEmpty = fields.every(field => valueEmpty(get(values, field)));
  if (allEmpty) {
    fields.forEach(field => {
      set(errors, field, REQUIRED_ERROR);
    });
  }
};

export const notLessThan =
  (value: number, { integer }: { integer?: boolean } = {}) =>
  (formValue: number | string) => {
    const parsedFormValue =
      typeof formValue === "string" ? parseFloat(formValue) : formValue;
    if (parsedFormValue < value) {
      return value;
    } else if (integer && !isNaN(parsedFormValue)) {
      return Math.round(parsedFormValue);
    } else {
      return formValue;
    }
  };

export const notMoreThan =
  (value: number, { integer }: { integer?: boolean } = {}) =>
  (formValue: number | string) => {
    const parsedFormValue =
      typeof formValue === "string" ? parseFloat(formValue) : formValue;
    if (parsedFormValue > value) {
      return value;
    } else if (integer && !isNaN(parsedFormValue)) {
      return Math.round(parsedFormValue);
    } else {
      return formValue;
    }
  };

export const inRange =
  ([min, max]: [number?, number?], { integer }: { integer?: boolean } = {}) =>
  (formValue: number | string) => {
    let parsedFormValue = formValue;
    if (typeof min === "number")
      parsedFormValue = notLessThan(min, { integer })(formValue);
    if (typeof max === "number")
      parsedFormValue = notMoreThan(max, { integer })(formValue);
    return parsedFormValue;
  };

export const getContainerFormatOptions = (
  containerFormats: { code: string; name: string }[]
) =>
  containerFormats
    .map(containerFormat => {
      return {
        label: startCase(containerFormat.code.toLowerCase()) + "s",
        value: containerFormat
      };
    })
    .sort((a, b) => {
      return a.value.name.localeCompare(b.value.name);
    });

export { throwFormError } from "@teselagen/ui";

export const removeDecimal = (s: string) =>
  s ? s.replace(/\./, "&decimal") : s;

export function validatePositiveNumber(num = "") {
  return isValidPositiveNumber(num)
    ? undefined
    : "Please enter a positive number";
}

export function validatePositiveInteger(num = "") {
  if (!isValidPositiveInteger(num)) {
    return "Please enter a positive integer";
  }
  return;
}

export function validateNonNegativeNumber(num = "") {
  return isValidNonNegativeNumber(num)
    ? undefined
    : "Please enter a number greater than or equal to 0";
}

export function validateNonNegativeInteger(num = "") {
  if (!isValidNonNegativeNumber(num) || !isInteger(num)) {
    return "Please enter an integer greater than or equal to 0";
  }
  return;
}

export async function asyncValidateFieldDuplicate({
  values = {},
  field,
  msg,
  idAs = "id",
  initialValues = {},
  model,
  errorFieldName
}: {
  values: { [key: string]: any };
  field: string;
  msg?: string;
  idAs?: string;
  initialValues: { [key: string]: any };
  model: string;
  errorFieldName?: string;
}) {
  msg = msg || `That ${field} is already in use.`;
  const fieldValue = values[field];
  if (fieldValue?.toLowerCase() === initialValues[field]?.toLowerCase())
    return Promise.resolve();
  let errorMsg;
  try {
    const hasProject = shouldShowProjects(model);
    let frag = idAs;
    if (hasProject) {
      frag += ` projectItems { id projectId project { id name } }`;
    }
    const res = (await safeQuery([model, frag], {
      variables: {
        pageSize: 1,
        filter: caseInsensitiveFilter(model, field, [fieldValue])
      }
    })) as unknown as [
      { projectItems: { projectId: string; project: { name: string } }[] }
    ];
    const activeProjectId = getActiveProjectId();
    if (hasProject) {
      const [item] = res;
      if (item) {
        errorMsg = msg;
        if (item.projectItems.length) {
          const inActiveProject =
            activeProjectId &&
            item.projectItems.some(pi => pi.projectId === activeProjectId);
          if (!inActiveProject) {
            const simpleName = modelNameToReadableName(model);
            errorMsg = `${capitalize(
              anOrA(simpleName)
            )} ${simpleName} with this ${field} already exists in the project ${
              item.projectItems[0].project.name
            }.`;
            if (activeProjectId) {
              errorMsg += ` An admin can add this ${simpleName} to this project or All Projects to proceed.`;
            } else {
              errorMsg += ` An admin must unassign this ${simpleName} from projects.`;
            }
          }
        }
      }
    } else if (res.length) {
      errorMsg = msg;
    }
  } catch (error) {
    console.error("error:", error);
    errorMsg = "Error checking for duplication";
  }
  if (errorMsg) {
    // eslint-disable-next-line no-throw-literal
    throw { [errorFieldName || field]: errorMsg };
  }
}

export async function asyncValidateBarcodeHandler({
  values,
  ownProps,
  model,
  errors = {}
}: {
  values: {
    generateBarcode?: boolean;
    barcode?: { barcodeString: string };
    userAddedBarcode?: string;
  };
  ownProps: { [key: string]: any };
  model: string;
  errors: { [key: string]: any };
}) {
  const { initialValues = {} } = ownProps;
  if (!values.generateBarcode) {
    let barcodeToCheck;
    const editBarcode = values.barcode?.barcodeString;
    if (editBarcode) {
      const initBarcode = initialValues.barcode?.barcodeString;
      const wasEdited = editBarcode !== initBarcode;
      if (wasEdited) {
        barcodeToCheck = editBarcode;
      }
    } else if (values.userAddedBarcode) {
      barcodeToCheck = values.userAddedBarcode;
    }
    if (barcodeToCheck) {
      const [dupBarcode] = (await safeQuery([model, "id"], {
        variables: {
          pageSize: 1,
          filter: {
            "barcode.barcodeString": barcodeToCheck
          }
        }
      })) as unknown as [{ id: string }];
      if (dupBarcode) {
        const error = `This barcode is already in use.`;
        if (editBarcode) {
          errors.barcode = {
            barcodeString: error
          };
        } else {
          errors.userAddedBarcode = error;
        }
      }
    }
  }
}

export async function asyncValidateNameHandler({
  values,
  ownProps,
  model,
  errors = {}
}: {
  values: { name: string };
  ownProps: { [key: string]: any };
  model: string;
  errors: { [key: string]: any };
}) {
  try {
    await asyncValidateFieldDuplicate({
      values,
      field: "name",
      initialValues: ownProps.initialValues,
      model
    });
  } catch (error) {
    errors.name = error.name;
  }
}

function asyncValidateNameFunction(
  values: {},
  dispatch: any,
  ownProps: { idAs: string; model: string; initialValues: {} }
) {
  return asyncValidateFieldDuplicate({
    values,
    initialValues: ownProps.initialValues,
    idAs: ownProps.idAs,
    model: ownProps.model,
    field: "name"
  });
}

export const asyncValidateName = {
  asyncBlurFields: ["name"],
  asyncValidate: asyncValidateNameFunction
};

export const isFormValueEmpty = (s: string) => {
  return !s || !s.trim || s.trim() === "";
};
