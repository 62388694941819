/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import { startCase } from "lodash";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { branch, compose, renderNothing, withProps } from "recompose";
import { CollapsibleCard, DataTable, withTableParams } from "@teselagen/ui";
import modelNameToLink from "../../../src-shared/utils/modelNameToLink";
import withQuery from "../../../src-shared/withQuery";

function SequenceJ5ItemTableCard({ tableParams }) {
  return (
    <CollapsibleCard title="Design Lineage">
      <DataTable {...tableParams} noPadding className="j5-items" />
    </CollapsibleCard>
  );
}

const schema = {
  model: "sequenceJ5ItemView",
  fields: [
    "name",
    {
      displayName: "DNA Assembly Report",
      path: "j5ReportName",
      render: (v, r) => (
        <Link to={modelNameToLink("j5Report", r.j5ReportId)}>{v}</Link>
      )
    },
    {
      displayName: "Type",
      path: "j5ItemType",
      render: v => startCase(v)
    }
  ]
};

const fragment = gql`
  fragment sequenceJ5ItemTableFragment on sequenceJ5ItemView {
    id
    name
    j5ReportId
    j5ReportName
    j5ItemType
  }
`;

export default compose(
  withQuery(fragment, {
    isPlural: true,
    options: ({ sequenceIds = [] }) => {
      return {
        variables: {
          pageSize: 1,
          filter: {
            sequenceId: sequenceIds
          }
        }
      };
    }
  }),
  branch(
    ({ sequenceJ5ItemViews }) =>
      !sequenceJ5ItemViews || sequenceJ5ItemViews.length === 0,
    renderNothing
  ),
  withProps(props => {
    // Added while #12943 is not ready
    const additionalFilter = useCallback(
      (_, qb) => {
        qb.whereAll({
          sequenceId: props.sequenceIds ?? []
        });
      },
      [props.sequenceIds]
    );
    return { additionalFilter };
  }),
  withTableParams({
    formName: "sequenceJ5ItemTableCard",
    urlConnected: false,

    schema: schema,
    defaults: {
      order: ["name"]
    }
  }),
  withQuery(fragment, {
    isPlural: true
  })
)(SequenceJ5ItemTableCard);
