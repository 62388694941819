/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { set } from "lodash";

import { safeQuery } from "../../src-shared/apolloMethods";

import { showDialog } from "../../src-shared/GlobalDialog";
import { download } from "../../src-shared/utils/downloadTest";

/**
 * Utility function to provide the handler for the "Reprint Label" button in the tube and
 * plate record views.
 *
 * If the label format has prompted values, open the enter prompted values dialog with with the
 * last used values used as the defaults. Otherwise just download the zpl file.
 *
 * @param {Object} arg
 * @param {string} arg.id The id of the item
 * @param {boolean} arg.isContainerArray
 * @param {function} arg.show Function to open dialog.
 * @param {function} arg.refetch
 */
export default async function handleReprint({ id, isContainerArray, refetch }) {
  try {
    const type = isContainerArray ? "containerArray" : "aliquotContainer";
    const item = await safeQuery(
      [
        type,
        "id labelFormatId storedLabelPrompts { id path value } labelFormat {id labelTemplatePrompts {id path name}}"
      ],
      {
        variables: { id }
      }
    );

    const initialValues = {};
    for (const { path, value } of item.storedLabelPrompts) {
      set(initialValues, path, value);
    }

    if (item.labelFormat.labelTemplatePrompts.length) {
      showDialog({
        modalType: "PROMPTED_LABEL_VALUES",
        modalProps: {
          isContainerArray,
          itemIds: [id],
          labelFormatId: item.labelFormatId,
          prompts: item.labelFormat.labelTemplatePrompts,
          refetch,
          initialValues
        }
      });
    } else {
      const { data } = await window.serverApi.request({
        method: "POST",
        url: "/createZpl",
        data: {
          isContainerArray,
          itemIds: [id],
          labelFormatId: item.labelFormatId,
          promptedValues: {}
        }
      });
      download(data, "label.zpl", "text/plain");
      await refetch();
    }
  } catch (e) {
    console.error(e);
    window.toastr.error("Error reprinting label.");
  }
}
