/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
/* eslint-disable no-throw-literal */

import React, { Component } from "react";
import { compose } from "recompose";
import { get } from "lodash";
import plateMapGroupRegistrationFragment from "../../../../graphql/fragments/plateMapGroupRegistrationFragment";
import { withSelectedEntities } from "@teselagen/ui";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import PlateMapView from "../../../../components/PlateMapView";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import { ItemTypes } from "../../CreateOrEditPlateMapTool/utils/itemTypes";
import { Link } from "react-router-dom";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import { throwFormError } from "../../../../../src-shared/utils/formUtils";
import containerArrayTypeFragment from "../../../../../../tg-iso-shared/src/fragments/containerArrayTypeFragment";

class SelectPlateMapGroups extends Component {
  savePlateMapsToForm = async values => {
    const { selectedPlateMapGroups = [] } = values;
    const {
      stepFormProps: { change },
      nextStep
    } = this.props;
    try {
      const plateMaps = [];
      const typeMissingMaps = [];

      const containerArrayTypes = await safeQuery(containerArrayTypeFragment, {
        isPlural: true,
        variables: {
          pageSize: 20000
        }
      });

      const containerFormats = containerArrayTypes.map(
        ca => ca.containerFormatCode
      );

      selectedPlateMapGroups.forEach(pmg => {
        if (!containerFormats.includes(pmg.containerFormat.code)) {
          typeMissingMaps.push(pmg.name);
        }
        pmg.plateMaps.forEach(plateMap => {
          plateMaps.push({
            ...plateMap,
            hasVolumeInfo: !!get(plateMap, "plateMapItems[0].volume"),
            plateMapGroup: {
              id: pmg.id,
              name: pmg.name,
              containerFormat: pmg.containerFormat
            }
          });
        });
      });

      if (typeMissingMaps.length) {
        throw {
          selectedPlateMapGroups: (
            <span>
              The plate type of the following plate maps does not exist:{" "}
              {typeMissingMaps.join(", ")}.{"\n"}
              To register plates they must have an existing{" "}
              <Link
                to={{
                  pathname: `/settings/plate-types`
                }}
                target="_blank"
              >
                Plate Type
              </Link>
            </span>
          ),
          _error: " "
        };
      }

      change("containerArrayTypes", containerArrayTypes);
      change("plateMaps", plateMaps);
      nextStep();
    } catch (error) {
      console.error(error);
      throwFormError(error);
    }
  };

  render() {
    const {
      Footer,
      footerProps,
      toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
      plateRegistrationPlateMapGroupsTableSelectedEntities:
        selectedPlateMapGroupsEntities = [],
      handleSubmit
    } = this.props;

    const selectedPlateMapGroup = selectedPlateMapGroupsEntities[0];

    return (
      <div>
        <div className="tg-step-form-section column">
          <div className="tg-flex justify-space-between column">
            <HeaderWithHelper
              header="Select and Preview Plate Maps"
              helper="Start by selecting a plate maps created using the Create Plate Map tool."
            />
            <div>
              <GenericSelect
                {...{
                  name: "selectedPlateMapGroups",
                  schema: [
                    "name",
                    { displayName: "Format", path: "containerFormat.name" },
                    dateModifiedColumn
                  ],
                  isMultiSelect: true,
                  buttonProps: {
                    disabled: isDisabledMap.plateMapGroups,
                    loading: isLoadingMap.plateMapGroups
                  },
                  fragment: [
                    "plateMapGroup",
                    "id name containerFormat { code name } updatedAt"
                  ],
                  tableParamOptions: {
                    additionalFilter: {
                      "plateMaps.type": Object.values(ItemTypes)
                    }
                  },
                  additionalDataFragment: plateMapGroupRegistrationFragment,
                  postSelectDTProps: {
                    formName: "plateRegistrationPlateMapGroupsTable",
                    isViewable: true,
                    isSingleSelect: true,
                    schema: [
                      "name",
                      {
                        displayName: "Plate Type",
                        path: "containerFormat.name"
                      }
                    ]
                  }
                }}
              />
            </div>
          </div>
          {selectedPlateMapGroup && (
            <PlateMapView plateMapGroup={selectedPlateMapGroup} noPadding />
          )}
        </div>
        <Footer
          {...footerProps}
          onNextClick={handleSubmit(this.savePlateMapsToForm)}
        />
      </div>
    );
  }
}

export default compose(
  withSelectedEntities("plateRegistrationPlateMapGroupsTable")
)(SelectPlateMapGroups);
