/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo, useState } from "react";
import { noop } from "lodash";
import {
  ButtonGroup,
  Button,
  Intent,
  Popover,
  Menu,
  MenuItem
} from "@blueprintjs/core";
import modelNameToReadableName from "../utils/modelNameToReadableName";
import { aliasModels } from "@teselagen/constants";
import {
  extendedPropertyModels,
  externalRecordIdentifierModels
} from "../../../tg-iso-shared/constants";
import { showDialog } from "../GlobalDialog";
import EditExternalIdentifiersDialog from "../ExternalIntegrations/EditExternalIdentifiersDialog";
import { isLabAdmin, popoverOverflowModifiers } from "../utils/generalUtils";
import { deleteRecordsHelper } from "../utils/deleteUtils";
import EditAliasesMenuItem from "../components/EditAliasesMenuItem";
import { safeDelete, safeUpsert } from "../apolloMethods";
import { isCommonLabLocked } from "../utils/labUtils";
import UpdateRecordExtendedPropertiesDialog from "../UpdateRecordExtendedPropertiesDialog";

function ExportButton({ record, onExport }) {
  const [loading, setLoading] = useState(false);
  return (
    <Button
      loading={loading}
      intent={Intent.SUCCESS}
      icon="export"
      disabled={!record}
      text="Export"
      onClick={async () => {
        setLoading(true);
        await onExport(record);
        setLoading(false);
      }}
    />
  );
}

const RecordButtonGroup = ({
  additionalButtons,
  additionalUpdateMenuItems = [],
  afterDelete,
  beforeDelete = noop,
  deleteDisabled,
  handleDelete: _handleDelete,
  history,
  noDelete,
  noDeleteAlert,
  onDelete,
  onExport,
  readOnly,
  record,
  refetch,
  updateShowFunction,
  updateNameOverwrite
}) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleDelete = async () => {
    setDeleteLoading(true);
    if (onDelete) {
      await onDelete({
        records: [record],
        deleteRecordsHelper: (_record, refetch) =>
          deleteRecordsHelper({
            records: [_record],
            handleDelete: _handleDelete,
            noDeleteAlert,
            beforeDelete,
            afterDelete,
            history,
            refetch
          }),
        history
      });
    } else {
      await deleteRecordsHelper({
        records: [record],
        handleDelete: _handleDelete,
        noDeleteAlert,
        beforeDelete,
        afterDelete,
        history
      });
    }
    setDeleteLoading(false);
  };
  const model = record?.__typename || undefined;

  const updateButton = useMemo(() => {
    const showUpdateExtendedProps = () => {
      showDialog({
        ModalComponent: UpdateRecordExtendedPropertiesDialog,
        modalProps: {
          model,
          recordId: record?.id || undefined
        }
      });
    };

    const withExtendedProperties = extendedPropertyModels.includes(model);
    const withAliases = aliasModels.includes(model);
    const withExternalReference =
      externalRecordIdentifierModels.includes(model);

    const showUpdateExternalReferences = () => {
      showDialog({
        ModalComponent: EditExternalIdentifiersDialog,
        modalProps: {
          initialValues: record
        }
      });
    };
    const updateButtonProps = {
      icon: "edit",
      disabled: !record,
      intent: Intent.WARNING,
      text: "Update"
    };

    const hasAdditionalUpdateOptions =
      withExtendedProperties ||
      additionalUpdateMenuItems.length ||
      withAliases ||
      withExternalReference;

    let updateButton;
    // if there is the single update function then just have the button be on click
    if (updateShowFunction && !hasAdditionalUpdateOptions) {
      updateButton = (
        <Button
          {...updateButtonProps}
          text={`Update ${modelNameToReadableName(
            updateNameOverwrite || model,
            {
              upperCase: true
            }
          )}`}
          onClick={updateShowFunction}
        />
      );
    } else if (updateShowFunction || hasAdditionalUpdateOptions) {
      // if there are multiple update options then have a dropdown menu
      let menuItems = [];
      if (updateShowFunction) {
        menuItems.push(
          <MenuItem
            key="update"
            text={`Update ${modelNameToReadableName(
              updateNameOverwrite || model,
              {
                upperCase: true
              }
            )}`}
            onClick={updateShowFunction}
          />
        );
      }
      if (withExtendedProperties) {
        menuItems.push(
          <MenuItem
            key="updateExtProp"
            text="Update Extended Properties"
            onClick={showUpdateExtendedProps}
          />
        );
      }
      if (withAliases) {
        menuItems.push(
          <EditAliasesMenuItem noIcon key="editAlias" record={record} />
        );
      }
      if (withExternalReference) {
        menuItems.push(
          <MenuItem
            key="updateExternal"
            text="Edit External Identifiers"
            onClick={showUpdateExternalReferences}
          />
        );
      }

      menuItems = menuItems.concat(additionalUpdateMenuItems);

      updateButton = (
        <Popover
          position="bottom-right"
          minimal
          modifiers={popoverOverflowModifiers}
          content={<Menu>{menuItems}</Menu>}
        >
          <Button {...updateButtonProps} />
        </Popover>
      );
    }
    return updateButton;
  }, [
    additionalUpdateMenuItems,
    model,
    record,
    updateNameOverwrite,
    updateShowFunction
  ]);

  const exportButton = <ExportButton record={record} onExport={onExport} />;

  const lockingButtons = isLabAdmin() && (
    <>
      {readOnly && record?.lockId && !isCommonLabLocked(record) && (
        <Button
          icon="unlock"
          text="Unlock"
          intent="primary"
          onClick={async () => {
            try {
              await safeDelete("lock", [record.lockId]);
              refetch();
            } catch (error) {
              console.error(`error:`, error);
              window.toastr.error("Error unlocking");
            }
          }}
        />
      )}
      {!readOnly &&
        !record?.lockId &&
        window.frontEndConfig.userRecordLocking &&
        !isCommonLabLocked(record) && (
          <Button
            icon="lock"
            text="Lock"
            intent="primary"
            onClick={async () => {
              try {
                const lockRecord = await safeUpsert("lock", {
                  isPermanent: false
                });
                await safeUpsert([model, "id lockId"], {
                  id: record.id,
                  lockId: lockRecord[0].id
                });
              } catch (error) {
                console.error(`error:`, error);
                window.toastr.error("Error unlocking");
              }
            }}
          />
        )}
    </>
  );

  return (
    <div style={{ maxHeight: 30 }}>
      <ButtonGroup minimal>
        {lockingButtons}
        {!readOnly && additionalButtons}
        {/* <HistoryButton disabled={!record} record={record} /> */}
        {!readOnly && updateButton}
        {!noDelete && !readOnly && (
          <Button
            intent={Intent.DANGER}
            icon="trash"
            disabled={!record || deleteDisabled}
            loading={deleteLoading}
            text="Delete"
            onClick={handleDelete}
          />
        )}
        {onExport && exportButton}
      </ButtonGroup>
    </div>
  );
};

export default RecordButtonGroup;
