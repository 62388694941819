/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { reduxForm } from "redux-form";
import { compose } from "recompose";
import {
  InputField,
  ReactSelectField,
  Loading,
  DialogFooter,
  wrapDialog
} from "@teselagen/ui";

import withQuery from "../../withQuery";

import { get } from "lodash";
import PropTypes from "prop-types";
import { Classes, Callout } from "@blueprintjs/core";
import { extendedPropertyFragment } from "../../fragments/extendedPropertyFragment.gql";

import { withProps } from "recompose";
import { arrayToIdOrCodeValuedOptions } from "../../utils/formUtils";
import { safeUpsert } from "../../apolloMethods";
import { getModelTypeOptions } from "../../utils/extendedPropertyUtils";

const EditValueExtendedProperty = ({
  refetch,
  hideModal,
  initialValues = {},
  isWorklistCounter,
  handleSubmit,
  extendedTypes = [],
  extendedTypesLoading,
  submitting,
  initialItemType
}) => {
  const onSubmit = async values => {
    try {
      await safeUpsert(extendedPropertyFragment, {
        id: get(initialValues, "id"),
        name: values.name.trim(),
        extendedTypeCode: isWorklistCounter
          ? "worklistCounter"
          : values.extendedTypeCode,
        modelTypeCode: values.modelTypeCode,
        extendedPropertyClassCode: "VALUE"
      });

      // Don't need to refetch if update because store will receive update.
      if (!initialValues.id) await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error(
        `Error ${initialValues.id ? "updating" : "creating"} extended property.`
      );
    }
  };

  return (
    <Loading inDialog loading={extendedTypesLoading}>
      <div className={Classes.DIALOG_BODY}>
        {!!initialValues.id && (
          <Callout style={{ marginBottom: 10 }} intent="warning">
            Cannot edit item type and data type when updating extended
            properties because they might be in use.
          </Callout>
        )}
        <ReactSelectField
          name="modelTypeCode"
          isRequired
          label="Item Type"
          disabled={!!initialValues.id}
          options={getModelTypeOptions(isWorklistCounter)}
          defaultValue={initialItemType}
        />

        <InputField name="name" label="Name" isRequired />
        {!isWorklistCounter && (
          <ReactSelectField
            name="extendedTypeCode"
            isRequired
            label="Type"
            disabled={!!initialValues.id}
            options={arrayToIdOrCodeValuedOptions(extendedTypes).filter(
              item => item.value !== "worklistCounter"
            )}
          />
        )}
      </div>
      <DialogFooter
        hideModal={hideModal}
        submitting={submitting}
        onClick={handleSubmit(onSubmit)}
      />
    </Loading>
  );
};

EditValueExtendedProperty.propTypes = {
  initialValues: PropTypes.object,
  refetch: PropTypes.func
};

export default compose(
  withProps(props => {
    const isWorklistCounter =
      props.isWorklistCounter ||
      get(props, "initialValues.extendedTypeCode") === "worklistCounter";
    return {
      isWorklistCounter
    };
  }),
  wrapDialog({
    getDialogProps: props => {
      let title;
      if (props.isWorklistCounter) {
        title = get(props, "initialValues.id")
          ? "Edit Worklist Counter Extended Property"
          : "Add Worklist Counter Extended Property";
      } else {
        title = get(props, "initialValues.id")
          ? "Edit Basic Extended Property"
          : "Add Basic Extended Property";
      }
      return {
        title
      };
    }
  }),
  withQuery(["extendedType", "code name"], {
    isPlural: true
  }),
  reduxForm({
    form: "EditValueExtendedProperty",
    enableReinitialize: true
  })
)(EditValueExtendedProperty);
