/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Loading } from "@teselagen/ui";
import { Button, Intent, Classes } from "@blueprintjs/core";
import { compose } from "redux";
import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isNumber } from "lodash";
import { withProps } from "recompose";
import { extendedPropertyFragment } from "../fragments/extendedPropertyFragment.gql";
import EditExtendedPropertyValue from "./EditExtendedPropertyValue";
import "./style.css";
import { modelTypeMap } from "../../../tg-iso-shared/src/utils/modelTypeMap";
import { REQUIRED_ERROR } from "../utils/formUtils";
import GenericSelect from "../GenericSelect";
import { submitAddExtendedProperty } from "./utils";
import AddExtendedPropertyDialog from "../AddExtendedPropertyDialog";
import { showStackedDialog } from "../StackedDialog";

const AddExtendedProperty = ({
  extendedProperty,
  extendedPropertyIdsToIgnore = [],
  extendedPropertyIdsToInclude = [],
  extendedPropertyLoading,
  fullValueFields,
  handleSubmit,
  model,
  noLinkProperties,
  onSubmitValue,
  recordId,
  recordIds,
  refetch,
  reset,
  submitting
}) => {
  const [genericSelectKey, setGenericSelectKey] = React.useState(0);
  return (
    <div className="record-add-extended-property">
      <GenericSelect
        name="extendedProperty"
        asReactSelect
        isRequired
        key={genericSelectKey}
        label="Extended Property"
        fragment={["extendedProperty", "id name"]}
        additionalDataFragment={extendedPropertyFragment}
        reactSelectProps={{
          creatable: true,
          noResultsText: "No results. Type to create a new property...",
          onCreateNewOption: option => {
            showStackedDialog({
              ModalComponent: AddExtendedPropertyDialog,
              modalProps: {
                stacked: true,
                refetch: () => setGenericSelectKey(genericSelectKey + 1),
                initialValues: {
                  name: option,
                  modelTypeCode: modelTypeMap[model]
                }
              }
            });
            return true;
          }
        }}
        additionalFilter={(props, qb) => {
          const filter = {
            modelTypeCode: modelTypeMap[model]
          };
          if (noLinkProperties) {
            filter.extendedPropertyClassCode = qb.notEquals("LINK");
          }
          if (extendedPropertyIdsToIgnore.length) {
            filter.id = qb.notInList(extendedPropertyIdsToIgnore);
          } else if (extendedPropertyIdsToInclude.length) {
            filter.id = qb.inList(extendedPropertyIdsToInclude);
          }
          qb.whereAll(filter);
        }}
      />
      {!!extendedProperty && (
        <Loading loading={extendedPropertyLoading} bounce>
          <EditExtendedPropertyValue
            extendedProperty={extendedProperty}
            fullValueFields={fullValueFields}
          />
        </Loading>
      )}
      <Button
        icon="add"
        text="Add Extended Property"
        intent={Intent.SUCCESS}
        className={Classes.MINIMAL}
        onClick={handleSubmit(async values => {
          return submitAddExtendedProperty(values, {
            refetch,
            model,
            recordId,
            recordIds,
            onSubmitValue,
            fullValueFields,
            reset
          });
        })}
        loading={submitting}
      />
    </div>
  );
};

AddExtendedProperty.propTypes = {
  /**
   * These custom fields will not appear in the select field. They should be
   * the custom fields that are already on the record.
   */
  extendedPropertyIdsToIgnore: PropTypes.arrayOf(PropTypes.string).isRequired,

  /**
   * The custom field options will be limited to this list of ids.
   */
  extendedPropertyIdsToInclude: PropTypes.arrayOf(PropTypes.string),

  /**
   * The name of the model for which we wish to find custom fields.
   */
  model: PropTypes.string.isRequired,

  /**
   * The id of the record that we want to render the extended properties
   * of.
   */
  recordId: PropTypes.string,

  /**
   * The id of the record that we want to render the extended properties
   * of.
   */
  recordIds: PropTypes.arrayOf(PropTypes.string),

  /**
   * Refetch the custom fields on the record.
   */
  refetch: PropTypes.func,

  /**
   * Callback for when new value submitted (overrides default function so value will not actually
   * be sent to the db. Used for embedding.)
   */
  onSubmitValue: PropTypes.func
};

export default compose(
  withProps(props => {
    return {
      form: props.formName || "AddExtendedProperty"
    };
  }),
  connect((state, { form }) => ({
    extendedProperty: formValueSelector(form)(state, "extendedProperty")
  })),
  reduxForm({
    enableReinitialize: true,
    validate
  })
)(AddExtendedProperty);

function validate(values) {
  const errors = {};
  const { extendedProperty } = values;
  if (extendedProperty) {
    validateValue({
      extendedProperty,
      values,
      errors
    });
  }
  return errors;
}

export function validateValue({ extendedProperty, values, errors }) {
  const { extendedPropertyClassCode, extendedTypeCode } = extendedProperty;

  switch (extendedPropertyClassCode) {
    case "VALUE":
      const numericExtendedTypeCodes = ["number", "worklistCounter"];
      if (
        numericExtendedTypeCodes.includes(extendedTypeCode) &&
        !isNumber(Number(values.value))
      ) {
        errors.value = REQUIRED_ERROR;
      } else if (extendedTypeCode !== "boolean" && !values.value) {
        errors.value = REQUIRED_ERROR;
      }
      break;

    case "CATEGORY":
      if (!values.extendedCategoryId)
        errors.extendedCategoryId = REQUIRED_ERROR;
      break;

    case "MEASUREMENT":
      if (!isNumber(values.value)) errors.value = REQUIRED_ERROR;
      if (!values.measurementUnitId) errors.measurementUnitId = REQUIRED_ERROR;
      break;

    default:
      break;
  }
}
