/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import { get, isEmpty } from "lodash";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import { additionalFilterForTubes } from "../../../../utils/plateUtils";
import { plateFragment, tubeFragment } from "../fragments";
import { Tooltip, Icon } from "@blueprintjs/core";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import platePreviewColumn from "../../../../utils/platePreviewColumn";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { useStepFormValues } from "../../../../../src-shared/stepFormValues";

const aliquotAppropriateMaterialError = aliquot => {
  const material = get(aliquot, "sample.material");
  if (aliquot && !aliquot.sample) {
    return "No sample on aliquot.";
  }
  if (material) {
    if (
      material.materialTypeCode !== "MICROBIAL" &&
      material.materialTypeCode !== "CELL_CULTURE"
    ) {
      return "Must be linked to a microbial material or cell culture.";
    }
    if (material.materialTypeCode === "MICROBIAL") {
      if (material.microbialMaterialMicrobialMaterialPlasmids.length > 1) {
        return "Material contains multiple plasmids. Should be linked to a single plasmid.";
      }
      if (!material.microbialMaterialMicrobialMaterialPlasmids.length) {
        return "This aliquot does not contain microbial material with a plasmid.";
      }
    } else if (material.materialTypeCode === "CELL_CULTURE") {
      if (material.cellCultureCellCulturePlasmids.length > 1) {
        return "Material contains multiple plasmids. Should be linked to a single plasmid.";
      }
      if (!material.cellCultureCellCulturePlasmids.length) {
        return "This aliquot does not contain a cell culture with a plasmid.";
      }
    }
  } else if (aliquot.sample.sampleTypeCode === "FORMULATED_SAMPLE") {
    if (!aliquot.sample.sampleFormulations.length) {
      return "No sample formulations found.";
    }
    for (const sf of aliquot.sample.sampleFormulations) {
      for (const mc of sf.materialCompositions) {
        if (
          mc.material.materialTypeCode !== "MICROBIAL" &&
          mc.material.materialTypeCode !== "CELL_CULTURE"
        ) {
          return "This pooled sample has non-microbial materials";
        } else if (
          mc.material.microbialMaterialMicrobialMaterialPlasmids.length > 1
        ) {
          return `Material ${mc.material.name} contains multiple plasmids. Should be linked to a single plasmid of DNA.`;
        } else if (
          !mc.material.microbialMaterialMicrobialMaterialPlasmids.length
        ) {
          return `Material ${mc.material.name} does not have plasmid DNA.`;
        } else if (mc.material.cellCultureCellCulturePlasmids.length > 1) {
          return `Material ${mc.material.name} contains multiple plasmids. Should be linked to a single plasmid of DNA.`;
        } else if (
          !mc.material.microbialMaterialMicrobialMaterialPlasmids.length
        ) {
          return `Material ${mc.material.name} does not have plasmid DNA.`;
        }
      }
    }
  } else if (!material) {
    return "Not linked to a material.";
  }
};

const containerArraysSchema = [
  "name",
  { displayName: "Barcode", path: "barcode.barcodeString" },
  dateModifiedColumn
];

const containerArraysFragment = [
  "containerArray",
  "id name barcode { id barcodeString } updatedAt"
];

const aliquotContainersSchema = [
  "name",
  { displayName: "Barcode", path: "barcode.barcodeString" },
  dateModifiedColumn
];

const aliquotContainerFragment = [
  "aliquotContainer",
  "id name barcode { id barcodeString } updatedAt"
];

const aliquotContainerTableParamOptions = {
  additionalFilter: additionalFilterForTubes
};

const SelectDonorCells = ({
  toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
  Footer,
  footerProps,
  toolSchema
}) => {
  const { containerArrays = [], aliquotContainers = [] } = useStepFormValues(
    toolSchema.code,
    ["containerArrays", "aliquotContainers"]
  );

  const aliquotContainerErrorMap = useMemo(() => {
    const aliquotContainerErrorMap = {};
    aliquotContainers.forEach(ac => {
      if (ac.aliquot) {
        const error = aliquotAppropriateMaterialError(ac.aliquot);
        if (error) {
          aliquotContainerErrorMap[ac.id] = error;
        }
      } else {
        aliquotContainerErrorMap[ac.id] = "No aliquot found in tube.";
      }
    });
    return aliquotContainerErrorMap;
  }, [aliquotContainers]);

  const containerArrayErrorMap = useMemo(() => {
    const _containerArrayErrorMap = {};

    containerArrays.forEach(containerArray => {
      let noAliquots = true;
      containerArray.aliquotContainers.forEach(ac => {
        if (ac.aliquot) {
          noAliquots = false;
          const error = aliquotAppropriateMaterialError(ac.aliquot);
          if (error) {
            if (!_containerArrayErrorMap[containerArray.id]) {
              _containerArrayErrorMap[containerArray.id] = {};
            }
            _containerArrayErrorMap[containerArray.id][
              getAliquotContainerLocation(ac, { force2D: true })
            ] = error;
          }
        }
      });

      if (noAliquots) {
        _containerArrayErrorMap[containerArray.id] = {
          _error: "No aliquots on plate."
        };
      }
    });
    return _containerArrayErrorMap;
  }, [containerArrays]);

  const invalidInputs =
    !isEmpty(aliquotContainerErrorMap) || !isEmpty(containerArrayErrorMap);

  const containerArraysButtonProps = useMemo(
    () => ({
      disabled: isDisabledMap.containerArrays,
      loading: isLoadingMap.containerArrays
    }),
    [isDisabledMap.containerArrays, isLoadingMap.containerArrays]
  );

  const containerArrayPostSelectDTProps = useMemo(
    () => ({
      formName: "bacterialConjugationSelectPlates",
      containerArrayErrorMap,
      schema: [
        platePreviewColumn({
          plateErrors: containerArrayErrorMap
        }),
        "name",
        {
          displayName: "Barcode",
          path: "barcode.barcodeString"
        }
      ]
    }),
    [containerArrayErrorMap]
  );

  const aliquotContainersButtonProps = useMemo(
    () => ({
      disabled: isDisabledMap.aliquotContainers,
      loading: isLoadingMap.aliquotContainers
    }),
    [isDisabledMap.aliquotContainers, isLoadingMap.aliquotContainers]
  );

  const aliquotConntainerPostSelectDTProps = useMemo(
    () => ({
      formName: "bacterialConjugationSelectTubes",
      aliquotContainerErrorMap,
      schema: [
        {
          type: "action",
          width: 35,
          render: (_, record) => {
            const error = aliquotContainerErrorMap[record.id];
            if (error) {
              return (
                <Tooltip content={error}>
                  <Icon
                    intent="danger"
                    style={{ marginRight: 10 }}
                    icon="warning-sign"
                  />
                </Tooltip>
              );
            }
          }
        },
        "name",
        {
          displayName: "Barcode",
          path: "barcode.barcodeString"
        }
      ]
    }),
    [aliquotContainerErrorMap]
  );

  return (
    <>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select Input Plates/Racks"
          helper="Select plates or racks of tubes of donor cells of microbial materials containing one plasmid DNA material."
        />
        <GenericSelect
          name="containerArrays"
          schema={containerArraysSchema}
          isMultiSelect
          buttonProps={containerArraysButtonProps}
          fragment={containerArraysFragment}
          additionalDataFragment={plateFragment}
          postSelectDTProps={containerArrayPostSelectDTProps}
        />
      </div>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select Tubes"
          helper="Select tubes of donor cells of microbial materials containing one plasmid DNA material."
        />
        <GenericSelect
          name="aliquotContainers"
          schema={aliquotContainersSchema}
          isMultiSelect
          buttonProps={aliquotContainersButtonProps}
          fragment={aliquotContainerFragment}
          additionalDataFragment={tubeFragment}
          tableParamOptions={aliquotContainerTableParamOptions}
          postSelectDTProps={aliquotConntainerPostSelectDTProps}
        />
      </div>
      <Footer
        nextDisabled={invalidInputs}
        {...footerProps}
        errorMessage={invalidInputs && "Please review form errors."}
      />
    </>
  );
};

export default SelectDonorCells;
