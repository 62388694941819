/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { MouseEvent } from "react";
import { Button, Classes, Intent } from "@blueprintjs/core";
import { FieldArrayFieldsProps } from "redux-form";

export default function RemoveButton({
  fields,
  index,
  remove: _remove,
  style
}: {
  fields: FieldArrayFieldsProps<any>;
  index: number;
  remove?: (event: MouseEvent) => void;
  style?: object;
}) {
  function remove() {
    fields.remove(index);
  }
  return (
    <Button
      style={{ maxHeight: 30, ...style }}
      intent={Intent.DANGER}
      className={Classes.MINIMAL}
      onClick={_remove || remove}
      icon="trash"
    />
  );
}
