/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { compose } from "redux";
import { Tab, Tabs } from "@blueprintjs/core";
import React, { useRef, useState } from "react";
import CustomCodonTable from "./CustomCodonTable";
import RemoteCodonOptimization from "./RemoteCodonOptimization";
import "./style.css";
import { hideDialog } from "../GlobalDialog";
import { BlueprintError, DialogFooter, wrapDialog } from "@teselagen/ui";

export const ReverseTranslationInner = ({
  aaSeqsToRevTrans = [],
  numToRevTrans,
  onRevTransFinished,
  additionalHeaderEl,
  submitText
}) => {
  const numToRev = numToRevTrans || aaSeqsToRevTrans.length;
  const [nextPage, setNextPage] = useState();
  const [error, setError] = useState();
  const { current: Footer } = useRef(({ onSubmit }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loading, setLoading] = useState();

    return (
      <DialogFooter
        loading={loading}
        text={
          submitText ||
          `Reverse Translate ${numToRev} Amino Acid${numToRev === 1 ? "" : "s"}`
        }
        onClick={async () => {
          setLoading(true);
          setError(false);
          try {
            const [returnVals, translationInfo] = await onSubmit();
            const { nextPage, doNotHideDialog } =
              (await onRevTransFinished(returnVals, {
                translationInfo,
                goBack: () => {
                  setNextPage();
                }
              })) || {};
            setLoading(false);
            if (nextPage) {
              setNextPage(nextPage);
            } else if (doNotHideDialog) {
              return;
            } else {
              hideDialog();
            }
          } catch (error) {
            setError(error.message);
            setLoading(false);
          }
        }}
      />
    );
  });
  if (nextPage) return nextPage;
  return (
    <div>
      {additionalHeaderEl}
      {error && <BlueprintError error={error} />}
      <Tabs renderActiveTabPanelOnly={true} id="reverseTranslationTabs">
        <Tab
          id="customCodonTable"
          title="Custom Codon Table"
          panel={<CustomCodonTable {...{ aaSeqsToRevTrans, Footer }} />}
        />
        <Tab
          id="api"
          title="Remote Codon Table"
          panel={<RemoteCodonOptimization {...{ aaSeqsToRevTrans, Footer }} />}
        />
      </Tabs>
    </div>
  );
};

/**
 * A dialog containing both the custom codon and api
 */

export const ReverseTranslationDialog = compose(
  wrapDialog({
    style: {
      width: 500
    }
  })
)(props => {
  return (
    <div className="bp3-dialog-body">
      <ReverseTranslationInner {...props} />
    </div>
  );
});
