/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useCallback } from "react";
import { DataTable, CollapsibleCard, withTableParams } from "@teselagen/ui";
import { get } from "lodash";
import routeDoubleClick from "../../../src-shared/utils/routeDoubleClick";
import { compose, withProps } from "recompose";
import withQuery from "../../../src-shared/withQuery";
import gql from "graphql-tag";

const schema = {
  model: "aliquot",
  fields: [
    {
      displayName: "ID",
      type: "number",
      path: "id",
      width: 80
    },
    {
      width: 120,
      displayName: "Aliquot Type",
      path: "aliquotType"
    },
    {
      width: 150,
      displayName: "Parent Aliquot",
      render: (v, r) =>
        get(r, "replicateParentAliquot.id")
          ? "Aliquot " + get(r, "replicateParentAliquot.id")
          : "N/A"
    },
    {
      displayName: "Location",
      path: "aliquotContainer.aliquotContainerPathView.fullPath",
      render: v => v || "Unassigned"
    }
  ]
};

const AliquotsTableCard = ({ tableParams, title = "Aliquots" }) => (
  <CollapsibleCard title={title} className="aliquots-table-card">
    <DataTable {...tableParams} noPadding onDoubleClick={routeDoubleClick} />
  </CollapsibleCard>
);

const fragment = gql`
  fragment aliquotTableCardFragment on aliquot {
    id
    aliquotType
    replicateParentAliquot {
      id
    }
    aliquotContainer {
      id
      aliquotContainerPathView {
        id
        fullPath
      }
    }
  }
`;

export default compose(
  withProps(props => {
    // Added while #12943 is not ready
    const additionalFilter = useCallback(
      () => props.additionalFilter,
      [props.additionalFilter]
    );
    return { additionalFilter };
  }),
  withTableParams({
    formName: "aliquotsTableCard",
    urlConnected: false,
    schema
  }),
  withQuery(fragment, {
    isPlural: true
  })
)(AliquotsTableCard);
