/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import { get, sortBy } from "lodash";
import Big from "big.js";
import { volumeRender } from "../../../../../src-shared/utils/unitUtils";
import ReviewWorklistSection from "../../../ReviewWorklistSection";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import {
  getAliquotContainersToNormalize,
  getSourceVolumetricUnit
} from "../utils";
import {
  generateEmptyWells,
  sortAliquotContainers
} from "../../../../../../tg-iso-lims/src/utils/plateUtils";
import { useFormValue } from "../../../../../src-shared/hooks/useFormValue";

const transferWorklistColumns = {
  model: "worklist",
  fields: [
    {
      displayName: "Source",
      path: "sourcePlateName"
    },
    {
      displayName: "Source Position",
      path: "sourceLocation"
    },
    {
      displayName: "Destination",
      path: "destinationContainerArrayName"
    },
    {
      displayName: "Destination Position",
      path: "destLocation"
    },
    {
      displayName: "Transfer Volume",
      path: "volume",
      render: volumeRender
    },
    {
      displayName: "Aliquot",
      path: "aliquotSample"
    }
  ]
};

function getNormalizationWorklistSchema(aliquotContainerTypeCode) {
  return {
    model: "worklist",
    fields: [
      {
        displayName: "Source",
        path: "sourcePlateName"
      },
      {
        displayName: "Source Position",
        path: "sourcePlateWell",
        isHidden: aliquotContainerTypeCode === "RESERVOIR"
      },
      {
        displayName: "Destination",
        path: "destinationContainerArrayName"
      },
      {
        displayName: "Destination Position",
        path: "destinationPlateWell"
      },
      {
        displayName: "Transfer Volume",
        path: "transferVolume",
        render: volumeRender
      },
      {
        displayName: "Aliquot",
        path: "aliquotSample"
      }
    ]
  };
}

const ReviewWorklists = ({ toolSchema }) => {
  const containerArrayToNormalize = useFormValue(
    toolSchema.code,
    "containerArrayToNormalize"
  );
  const containerArrayType = useFormValue(
    toolSchema.code,
    "containerArrayType"
  );
  const desiredTransferVolume = useFormValue(
    toolSchema.code,
    "desiredTransferVolume"
  );
  const desiredTransferVolumetricUnitCode = useFormValue(
    toolSchema.code,
    "desiredTransferVolumetricUnitCode"
  );
  const destinationContainerArrayName = useFormValue(
    toolSchema.code,
    "destinationContainerArrayName"
  );
  const destinationTransferOrder = useFormValue(
    toolSchema.code,
    "destinationTransferOrder"
  );
  const diluentAliquotContainers = useFormValue(
    toolSchema.code,
    "diluentAliquotContainers"
  );
  const intermediateContainerType = useFormValue(
    toolSchema.code,
    "intermediateContainerType"
  );
  const sourceTransferOrder = useFormValue(
    toolSchema.code,
    "sourceTransferOrder"
  );
  const transferSourcePlateToNormalizedPlate = useFormValue(
    toolSchema.code,
    "transferSourcePlateToNormalizedPlate"
  );
  const selectedWellsForPlates = useFormValue(
    toolSchema.code,
    "selectedWellsForPlates"
  );

  const containerArrayTransferWorklistTransfers = useMemo(() => {
    if (!transferSourcePlateToNormalizedPlate) return;
    const acsToUse = getAliquotContainersToNormalize({
      containerArrayToNormalize,
      selectedWellsForPlates
    });

    const emptyWells = generateEmptyWells(containerArrayType.containerFormat);
    const sortedSourceAcs = sortAliquotContainers(
      acsToUse,
      sourceTransferOrder
    );
    const sortedWells = sortAliquotContainers(
      emptyWells,
      destinationTransferOrder
    );

    const isDifferentFormat =
      containerArrayType.containerFormat.code !==
      containerArrayToNormalize.containerArrayType.containerFormat.code;

    return sortedSourceAcs.reduce((acc, aliquotContainer) => {
      if (!get(aliquotContainer, "aliquot")) return acc;
      const location = getAliquotContainerLocation(aliquotContainer);
      const transfer = {
        sourcePlateName: containerArrayToNormalize.name,
        sourceAliquotContainer: aliquotContainer,
        destinationContainerArrayName,
        sourceLocation: location,
        destLocation: isDifferentFormat
          ? getAliquotContainerLocation({
              ...sortedWells.shift(),
              containerArrayType
            })
          : location,
        aliquotSample: get(aliquotContainer, "aliquot.sample.name"),
        volume: desiredTransferVolume,
        volumetricUnitCode: desiredTransferVolumetricUnitCode
      };
      return acc.concat(transfer);
    }, []);
  }, [
    containerArrayToNormalize,
    containerArrayType,
    desiredTransferVolume,
    desiredTransferVolumetricUnitCode,
    destinationContainerArrayName,
    destinationTransferOrder,
    selectedWellsForPlates,
    sourceTransferOrder,
    transferSourcePlateToNormalizedPlate
  ]);

  const {
    volumetricUnit,
    volumetricUnitCode: volumetricUnitCodeForNormalization
  } = getSourceVolumetricUnit(containerArrayToNormalize);

  const normalizationWorklist = useMemo(
    () =>
      diluentAliquotContainers
        ? diluentAliquotContainers.reduce((acc, worklistTransfer) => {
            const location = getAliquotContainerLocation(worklistTransfer);
            const sortedTransfers = sortBy(worklistTransfer.transfers, [
              "aliquotContainer.columnPosition",
              "aliquotContainer.rowPosition"
            ]);
            const worklistItems = sortedTransfers.map(
              ({ transferVolume: _transferVolume, aliquotContainer }) => {
                const transferVolume = new Big(_transferVolume)
                  .div(new Big(volumetricUnit.liters))
                  .toString();
                return {
                  sourcePlateName: worklistTransfer.name,
                  sourcePlateWell: location,
                  sourceAliquotContainer: aliquotContainer,
                  destinationContainerArrayName:
                    transferSourcePlateToNormalizedPlate
                      ? destinationContainerArrayName
                      : containerArrayToNormalize.name,
                  destinationPlateWell:
                    getAliquotContainerLocation(aliquotContainer),
                  transferVolume: transferVolume.toString(),
                  volumetricUnitCode: volumetricUnitCodeForNormalization,
                  aliquotSample: get(aliquotContainer, "aliquot.sample.name")
                };
              }
            );
            return acc.concat(worklistItems);
          }, [])
        : [],
    [
      containerArrayToNormalize.name,
      destinationContainerArrayName,
      diluentAliquotContainers,
      transferSourcePlateToNormalizedPlate,
      volumetricUnit.liters,
      volumetricUnitCodeForNormalization
    ]
  );

  return (
    <div>
      {transferSourcePlateToNormalizedPlate && (
        <ReviewWorklistSection
          toolSchema={toolSchema}
          schema={transferWorklistColumns}
          worklistTransfers={containerArrayTransferWorklistTransfers}
          noSort
          header="Pre-Normalization Transfer Worklist"
          helper="Enter a worklist name and review the transfers below prior to normalization."
          fieldName="containerArrayTransferWorklistName"
          tableFormName="previewSourceRackNormalizationPlateWorklistTable"
        />
      )}
      <ReviewWorklistSection
        toolSchema={toolSchema}
        schema={getNormalizationWorklistSchema(
          intermediateContainerType.aliquotContainerTypeCode
        )}
        worklistTransfers={normalizationWorklist}
        noSort
        header="Normalization Worklist"
        helper="Enter a worklist name and review the normalization transfers below."
        fieldName="normalizationWorklistName"
        tableFormName="previewNormalizationWorklistTable"
      />
    </div>
  );
};

export default ReviewWorklists;
