/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { flatMap } from "lodash";
import { COMMON_LAB_ID, getActiveLabId } from "@teselagen/auth-utils";
import appGlobals from "../appGlobals";
import { isAdmin } from "./generalUtils";
import { commonLockedTooltip } from "../constants/tooltips";
import { CurrentUser } from "../context/CurrentUserContext";
import { safeQuery, safeUpsert } from "../apolloMethods";

export const lastSelectedLabKey = "lastSelectedLabId";
export const labStorageKey = "activeLabId";

type Lab = { id: string; labRoles: { id: string; roleCode: string }[] };
type Record = { id: string; labId?: string; lab?: Lab };

/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export const getLabAdmins = (lab: Lab) =>
  lab.labRoles.filter(({ roleCode }) => roleCode === "ADMIN");

export const getLabMembers = (lab: Lab) =>
  lab.labRoles.filter(({ roleCode }) => roleCode === "MEMBER");

export const setActiveLab = (labId: string | undefined) => {
  if (!isAdmin()) {
    return;
  } else {
    if (!labId) {
      labId = getActiveLabId();
    }
    if (!labId) {
      throw new Error("No lab selected. Cannot proceed.");
    }
  }
  if (!appGlobals.setActiveLabId) {
    console.error("No function for setting active lab. Shouldn't be here.");
  } else {
    appGlobals.setActiveLabId(labId);
  }
};

export function isCommonLabLocked(record: Record) {
  const locked =
    !isAdmin() &&
    (record?.labId === COMMON_LAB_ID || record?.lab?.id === COMMON_LAB_ID);
  if (locked) {
    return commonLockedTooltip;
  }
  return;
}

export function changeActiveLab(activeLabId: string) {
  localStorage.setItem(lastSelectedLabKey, activeLabId);
  sessionStorage.setItem(labStorageKey, activeLabId);
  window.allowLabChange = true;
  // do a full page refresh to stop weirdness from having mismatched labs.
  // The sessionStorage lab will be automatically set to this selected lab when the app is reloaded
  if (appGlobals.activeRecordId) {
    const newUrl = `/client`;
    // if there is an active record open then go to the home page so it does not auto switch lab on reload
    window.location.href = newUrl;
  } else {
    window.location.reload();
  }
}

export const getUserLabs = (currentUser: CurrentUser) => {
  const { labRoles = [] } = currentUser;
  return labRoles
    .map(({ lab }) => lab)
    .filter(l => !!l)
    .sort((a, b) => (a.name || "").localeCompare(b.name || ""));
};

/**
 * Used together with the lab group column, only works in multi lab mode
 */
export const addLabFilterToQuery = (labFilter: string, qb: any) => {
  if (labFilter) {
    if (labFilter === COMMON_LAB_ID) {
      qb.whereAll({
        labId: null
      });
    } else {
      qb.whereAll({
        labId: labFilter
      });
    }
  }
};

export const removeLabMembers = async (
  labs: Lab[],
  safeDelete: (model: string, ids: string[]) => Promise<void>
) => {
  const labRoleIdsToDelete = flatMap(
    labs.map(lab => {
      return lab.labRoles
        .filter(labRole => labRole.roleCode === "MEMBER")
        .map(labRole => labRole.id);
    })
  );
  await safeDelete("labRole", labRoleIdsToDelete);
};

export const removeDefaultLabIdIfNecessary = async ({
  originalLabBeingEdited,
  labGroupAdmins,
  labGroupMembers
}: {
  originalLabBeingEdited: { id?: string; labRoles?: { userId: string }[] };
  labGroupAdmins: { id: string }[];
  labGroupMembers: { id: string }[];
}) => {
  // **** Delete the defaultLabId for any users that had this lab as their default lab and are no longer in it ****
  const removedUserIds =
    originalLabBeingEdited?.labRoles
      ?.filter(
        role =>
          ![...labGroupAdmins, ...labGroupMembers].some(
            user => user.id === role.userId
          )
      )
      .map(role => role.userId) || [];
  if (removedUserIds?.length > 0) {
    const usersToUpdate = (await safeQuery(["user", "id"], {
      useHasura: true,
      variables: {
        where: {
          id: { _in: removedUserIds },
          defaultLabId: { _eq: originalLabBeingEdited.id }
        }
      }
    })) as { id: string }[];
    if (usersToUpdate.length > 0) {
      await safeUpsert(
        ["user", "id defaultLabId"],
        usersToUpdate.map(user => ({
          id: user.id,
          defaultLabId: null
        }))
      );
    }
  }
  /* ************ */
};

/**
 * This function should be called whenever we need to initialize the
 * session and/or local storage lab id info (e.g., on login, on window reload, on new tab, etc.)
 */
export const initActiveLabId = (userData: CurrentUser) => {
  const activeLabId = getActiveLabId()!;

  const userLabs = userData.labRoles.map(labRole => labRole.labId);

  const hasActiveLabId = userLabs.some(labId => labId === activeLabId);
  if (!hasActiveLabId) {
    localStorage.setItem(lastSelectedLabKey, userData.defaultLabId);
    sessionStorage.setItem(labStorageKey, userData.defaultLabId);
  }
  // Its possible that either the sessionStorage or the localStorage keys isn't set
  else {
    localStorage.setItem(lastSelectedLabKey, activeLabId);
    sessionStorage.setItem(labStorageKey, activeLabId);
  }
};
