/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export const sequencePartialFragment = gql`
  fragment sequencePartialFragment on sequence {
    id
    name
    circular
    description
    size
    hash
    molecularWeight
    sequenceTypeCode
    sequenceType {
      code
      name
    }
    polynucleotideMaterialId
    polynucleotideMaterial {
      id
      samples {
        id
      }
    }
    isJ5Sequence
    sequenceFragments {
      id
      index
      fragment
    }
    parts {
      id
      cid
      name
      start
      end
      strand
    }
    sequenceFeatures {
      id
      name
      start
      end
      strand
      type
      locations
      notes
    }
    translations {
      id
      start
      end
      strand
    }
  }
`;
