/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import { identity } from "lodash";
import { DragSource, DropTarget } from "react-dnd";
import { Tooltip, Colors } from "@blueprintjs/core";
import classNames from "classnames";
import { branch } from "recompose";
import { popoverOverflowModifiers } from "../../../src-shared/utils/generalUtils";

function Well(props) {
  // Well is getting used in CustomDragLayer so some of these props might not be defined
  const {
    sharedProps,
    clickHandlers,
    connectDragSource,
    connectDropTarget,
    isOver,
    isEditable,
    tooltip,
    is2DLabeled,
    location,
    fontSize,
    columnCount,
    isDisabled,
    aliquotContainer = {},
    previewMode,
    isDragLayer
  } = props;
  const { rowPosition, columnPosition } = aliquotContainer;

  const fn = isEditable
    ? compose(connectDragSource, connectDropTarget)
    : identity;

  let well;
  if (is2DLabeled) {
    well = fn(
      <circle
        {...sharedProps}
        {...clickHandlers}
        fill={isOver && !isDisabled ? Colors.GREEN3 : sharedProps.fill}
      />
    );
  } else {
    // handle box styling
    let wellRect, wellCircle, wellRectSelection;
    // if it has a tube
    const { x, y, width, height, fill, className } = sharedProps;

    const emptyBoxFill = Colors.GRAY2;

    const locationClassName = `well-location-${location}`;
    let rectClassName = "plate-well";
    let newClassName = className;
    if (!aliquotContainer.id) {
      // we want the location classname helper to live on the tube circle if there is one
      // otherwise we want it to live on the square well
      newClassName = className.replace(locationClassName, "");
      rectClassName += ` ${locationClassName}`;
    }
    const propsForRect = {
      ...sharedProps,
      ...clickHandlers,
      className: rectClassName,
      fill: isOver && !isDisabled ? Colors.GREEN3 : emptyBoxFill
    };

    if (!previewMode) {
      const selectionClassNames = [
        "is-secondary-selected-well",
        "is-selected-well",
        "is-in-selection"
      ];
      const hasSelection = selectionClassNames.some(className => {
        return sharedProps.className.includes(className);
      });
      if (hasSelection) {
        const activeSelectionClassNames = [];
        selectionClassNames.forEach(className => {
          if (newClassName.includes(className)) {
            activeSelectionClassNames.push(className);
            newClassName = newClassName.replace(className, "");
          }
        });
        wellRectSelection = (
          <rect
            {...{
              className: classNames(activeSelectionClassNames),
              fill: "none",
              x: x + 3,
              y: y + 3,
              width: width - 5,
              height: height - 5
            }}
          />
        );
      }
    }

    if (aliquotContainer && aliquotContainer.id) {
      wellCircle = fn(
        <circle
          {...sharedProps}
          {...clickHandlers}
          className={newClassName}
          fill={isOver && !isDisabled ? Colors.GREEN3 : fill}
          cx={x + width / 2}
          cy={y + width / 2}
          r={width * 0.4}
        />
      );
      wellRect = <rect {...propsForRect} />;
    } else {
      wellRect = fn(<rect {...propsForRect} />);
    }

    let text;
    if (location) {
      const pos = rowPosition * columnCount + columnPosition + 1;
      text = (
        <text
          className="no-select plate-well-label"
          x={x + width / 2}
          y={y + height / 2}
          fill="black"
          textAnchor="middle"
          alignmentBaseline="central"
          style={{
            fontSize
          }}
          pointerEvents="none"
        >
          {pos}
        </text>
      );
    }

    well = (
      <g>
        {!isDragLayer && wellRect}
        {!isDragLayer && wellRectSelection}
        {wellCircle}
        {text}
      </g>
    );
  }

  if (!tooltip) return well;
  else
    return (
      <Tooltip
        disabled={!!window.Cypress || isOver}
        modifiers={popoverOverflowModifiers}
        popoverClassName="preserve-newline"
        targetTagName="g"
        wrapperTagName="g"
        content={tooltip}
      >
        {well}
      </Tooltip>
    );
}

export default compose(
  branch(
    props => props.isEditable,
    compose(
      DragSource(
        "aliquot",
        {
          beginDrag(props) {
            const {
              location,
              rectWidth,
              rectHeight,
              r,
              isSelected,
              sharedProps,
              is2DLabeled
            } = props;
            const selectedItemsProps = isSelected
              ? props.selectedItemsProps
              : { [location]: sharedProps };
            let width, height;
            if (is2DLabeled) {
              width = rectWidth;
              height = rectHeight;
            } else {
              width = sharedProps.width;
              height = sharedProps.height;
            }
            return {
              location,
              width,
              height,
              selectedItemsProps,
              r
            };
          },
          canDrag(props) {
            if (props.canDrag) {
              return props.canDrag(props);
            }
            return true;
          }
        },
        (connect, monitor) => {
          return {
            connectDragSource: connect.dragSource(),
            isDragging: monitor.isDragging()
          };
        }
      ),
      DropTarget(
        ["source-material", "aliquot"],
        {
          drop: (
            { isDisabled, onDrop, location: _location, onSourceMaterialDrop },
            monitor
          ) => {
            if (isDisabled) return;
            const itemType = monitor.getItemType();
            if (itemType === "aliquot") {
              const { selectedItemsProps, location } = monitor.getItem();
              onDrop({
                sourceLocations: Object.keys(selectedItemsProps),
                draggedLocation: location,
                droppedLocation: _location
              });
            } else if (itemType === "source-material") {
              const { selectedSourceMaterials } = monitor.getItem();
              onSourceMaterialDrop({
                selectedSourceMaterials,
                droppedLocation: _location
              });
            }
          }
        },
        (connect, monitor) => {
          return {
            connectDropTarget: connect.dropTarget(),
            isOver: monitor.isOver()
          };
        }
      )
    )
  )
)(Well);
