/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import {
  InputField,
  DateInputField,
  TextareaField,
  DialogFooter,
  wrapDialog
} from "@teselagen/ui";
import { workflowRunBasicInfoWorkflowRunFragment } from "../../../graphql/fragments/workflowRunBasicInfoWorkflowRunFragment.gql";
import GenericSelect from "../../../../src-shared/GenericSelect";
import { get } from "lodash";
import { safeUpsert } from "../../../../src-shared/apolloMethods";

const prioritySchema = ["name"];
const priorityDefaultValue = {
  code: "NORMAL",
  name: "Normal"
};
const priorityFragment = ["priorityType", "code name"];

const UpdateWorkflowRunDialog = ({ hideModal, handleSubmit, submitting }) => {
  const onSubmit = async _values => {
    try {
      const values = { ..._values };
      values.priorityTypeCode = get(values, "priorityType.code");
      delete values.priorityType;
      await safeUpsert(workflowRunBasicInfoWorkflowRunFragment, values);
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error updating workflow run.");
    }
  };

  return (
    <div>
      <div className={Classes.DIALOG_BODY}>
        <InputField name="name" label="Name" />
        <GenericSelect
          name="priorityType"
          asReactSelect
          idAs="code"
          label="Priority"
          schema={prioritySchema}
          defaultValue={priorityDefaultValue}
          fragment={priorityFragment}
        />
        <DateInputField label="Due Date" name="dueDate" />
        <TextareaField name="notes" label="Notes" />
      </div>
      <DialogFooter
        onClick={handleSubmit(onSubmit)}
        submitting={submitting}
        hideModal={hideModal}
      />
    </div>
  );
};

export default compose(
  wrapDialog({
    title: "Update Workflow"
  }),
  reduxForm({
    form: "updateWorkflowRun"
  })
)(UpdateWorkflowRunDialog);
