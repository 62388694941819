/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { formValues, reduxForm } from "redux-form";
import { Callout, Classes } from "@blueprintjs/core";
import { DialogFooter, wrapDialog } from "@teselagen/ui";
import GenericSelect from "../../../../src-shared/GenericSelect";
import PlateMapView from "../../PlateMapView";

const DistributeWithPlateMap = ({
  defaultPlateMapGroup,
  handleSubmit,
  hideModal,
  onSelect,
  plateMapGroup,
  submitting
}) => {
  async function onSubmit(values) {
    const { plateMapGroup } = values;
    await onSelect(plateMapGroup);
  }

  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        <Callout style={{ marginBottom: 5 }} intent="primary">
          Select a plate map to dictate reaction distribution layout. This will
          place reactions into plate map locations which match reaction output
          materials.
        </Callout>
        <Callout style={{ marginBottom: 15 }} intent="warning">
          This will clear current plates.
        </Callout>
        <div
          style={{
            maxWidth: 250
          }}
        >
          <GenericSelect
            name="plateMapGroup"
            asReactSelect
            label="Select Material Plate Map"
            fragment={["plateMapGroup", "id name"]}
            tableParamOptions={{
              additionalFilter: {
                "plateMaps.type": ["material"]
              }
            }}
            defaultValue={defaultPlateMapGroup}
          />
        </div>
        {plateMapGroup && (
          <PlateMapView plateMapGroupId={plateMapGroup.id} plateOnly />
        )}
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
      />
    </React.Fragment>
  );
};

export default compose(
  wrapDialog({
    title: "Distribute with Plate Map",
    style: {
      width: 750
    }
  }),
  reduxForm({
    form: "distWithPMdialog"
  }),
  formValues("plateMapGroup")
)(DistributeWithPlateMap);
