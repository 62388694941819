/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useCallback, useEffect, useMemo } from "react";
import { Button, Intent } from "@blueprintjs/core";
import containerArrayNormalizationFragment from "../../../../graphql/fragments/containerArrayNormalizationFragment";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import { useTableEntities } from "@teselagen/ui";
import PlatePreview from "./PlatePreview";
import { useDispatch } from "react-redux";
import { change as _change } from "redux-form";
import { useFormValue } from "../../../../../src-shared/hooks/useFormValue";

export const selectPlatesTableFormName = "normalizationSelectPlatesTable";

const schema = [
  "name",
  { displayName: "Barcode", path: "barcode.barcodeString" },
  dateModifiedColumn
];

const fragment = [
  "containerArray",
  "id name barcode { id barcodeString } updatedAt"
];

const SelectPlates = ({
  Footer,
  footerProps,
  handleSubmit,
  nextStep,
  toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
  toolSchema: { code },
  stepFormProps: { initialValues }
}) => {
  const dispatch = useDispatch();
  const change = useCallback(
    (field, value) => dispatch(_change(code, field, value)),
    [code, dispatch]
  );
  const containerArrays = useFormValue(code, "containerArrays");
  const worklistMap = useFormValue(code, "worklistMap");
  const containerArrayToNormalize = useFormValue(
    code,
    "containerArrayToNormalize"
  );

  const { selectedEntities, selectTableEntities } = useTableEntities(
    selectPlatesTableFormName
  );

  const containerArray = useMemo(() => {
    return (
      containerArrayToNormalize ||
      Object.values(selectedEntities || {}).filter(val => val.rowIndex === 0)[0]
    );
  }, [containerArrayToNormalize, selectedEntities]);

  useEffect(() => {
    if (
      containerArrays &&
      ((containerArrays.length && !containerArray) ||
        (containerArrays.length &&
          containerArray &&
          !containerArrays.some(p => p.id === containerArray.id)))
    ) {
      const plate = containerArrays[0];
      selectTableEntities([plate]);
      change("containerArrayToNormalize", plate);
    } else if (
      (!containerArrays || !containerArrays.length) &&
      containerArray
    ) {
      selectTableEntities([]);
      change("containerArrayToNormalize", null);
    }
  }, [change, containerArray, containerArrays, selectTableEntities]);

  const previewContainerArraySchema = useMemo(
    () => [
      {
        path: "name",
        render: (v, record) => {
          return v + (worklistMap?.[record.id] ? " (Normalized)" : "");
        }
      }
    ],
    [worklistMap]
  );

  const saveContainerArrayToNormalize = () => {
    change("containerArrayToNormalize", containerArray);
    nextStep();
  };

  const onSingleRowSelect = useCallback(
    plate => {
      change("containerArrayToNormalize", plate || null);
    },
    [change]
  );

  const disabled = !containerArray || worklistMap?.[containerArray.id];

  const postSelectDTProps = useMemo(
    () => ({
      formName: selectPlatesTableFormName,
      isViewable: !containerArrays ? true : !(containerArrays.length <= 1),
      isSingleSelect: true,
      onDeselect: onSingleRowSelect,
      onSingleRowSelect: onSingleRowSelect,
      schema: previewContainerArraySchema
    }),
    [containerArrays, onSingleRowSelect, previewContainerArraySchema]
  );

  const buttonProps = useMemo(
    () => ({
      loading: isLoadingMap.containerArrays,
      disabled: isDisabledMap.containerArrays
    }),
    [isDisabledMap.containerArrays, isLoadingMap.containerArrays]
  );

  return (
    <>
      <div className="tg-step-form-section tg-flex column">
        <HeaderWithHelper
          header="Plates or Racks to Normalize"
          width="100%"
          helper="Select a plate or rack that you would like to normalize. This tool will rehydrate dry plates and racks."
        />
        <GenericSelect
          name="containerArrays"
          schema={schema}
          isMultiSelect
          fragment={fragment}
          additionalDataFragment={containerArrayNormalizationFragment}
          onClear={onSingleRowSelect}
          postSelectDTProps={postSelectDTProps}
          buttonProps={buttonProps}
          defaultValue={initialValues.containerArrays}
        />
        {containerArrays && !containerArray && (
          <h6 style={{ margin: 15 }}>Select a plate to begin normalization</h6>
        )}
        {containerArray && <PlatePreview containerArray={containerArray} />}
      </div>
      {!disabled && (
        <h4 style={{ textAlign: "right" }}>
          {" "}
          Ready to Normalize: {containerArray ? containerArray.name : ""}{" "}
        </h4>
      )}
      <Footer
        {...footerProps}
        nextButton={
          <Button
            className="normalization-button"
            intent={Intent.PRIMARY}
            disabled={disabled}
            onClick={handleSubmit(saveContainerArrayToNormalize)}
          >
            Next
          </Button>
        }
      />
    </>
  );
};

export default SelectPlates;
