/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useMemo } from "react";
import { compose } from "recompose";
import { BlueprintError, DialogFooter, FileUploadField } from "@teselagen/ui";
import { Callout, Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { uploadTagsCsv } from "../utils/tagUtils";
import { barcodeModels } from "../../../tg-iso-shared/constants";
import { wrapDialog } from "@teselagen/ui";
import modelNameToReadableName from "../utils/modelNameToReadableName";
import { getDownloadTemplateFileHelpers } from "../components/DownloadTemplateFileButton";
import { throwFormError } from "../utils/formUtils";

function UploadTagsCsvDialog({
  model,
  submitting,
  hideModal,
  handleSubmit,
  error,
  reset,
  sequenceType,
  rnaType,
  refetch
}) {
  const accept = useMemo(() => {
    const hasBarcode = barcodeModels.includes(model);
    const validateAgainstSchema = {
      requireExactlyOneOf: ["name", "ID", ...(hasBarcode ? ["barcode"] : [])],
      fields: []
    };
    validateAgainstSchema.fields.push({
      path: "ID",
      displayName: "ID",
      description: `The ID of the ${model} to be tagged. Used if a Name is NOT provided`
    });
    if (model !== "aliquot") {
      validateAgainstSchema.fields.push({
        path: "name",
        example: `${model}-01-name`,
        description: `The Name of the ${model} to be tagged. Used if an ID is NOT provided`
      });
    }
    if (hasBarcode) {
      validateAgainstSchema.fields.push({
        path: "barcode",
        // example: `${model}-01`,
        // isRequired: true,
        description: `The barcode of the ${model} to be tagged (name or barcode is required)`
      });
    }
    validateAgainstSchema.fields.push({
      path: "tags",
      example: `tag 1,tag 2,tag 3: option 2`,
      isRequired: true,
      description: `The tag list should be in the format: "tag 1,tag 2,tag 3: option 2" and the tags need to ALREADY exist in the app`
    });
    return getDownloadTemplateFileHelpers({
      fileName: "tag_upload",
      validateAgainstSchema
    });
  }, [model]);

  const onSubmit = async ({ tagsCsv }) => {
    try {
      await uploadTagsCsv({
        csvData: tagsCsv[0].parsedData,
        reset,
        model,
        sequenceType,
        rnaType
      });
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      throwFormError(error.message || "Error parsing and saving tags.");
    }
  };

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <Callout intent="primary">
          Upload a csv with a list of{" "}
          {modelNameToReadableName(model, { plural: true })} by name and the
          tags you would like to add to each.
        </Callout>
        <FileUploadField
          fileLimit={1}
          isRequired
          accept={accept}
          name="tagsCsv"
        />
        <BlueprintError error={error} />
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
}

export default compose(
  wrapDialog({ title: "Upload Tags CSV" }),
  reduxForm({
    form: "uploadTagsCsv"
  })
)(UploadTagsCsvDialog);
