/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component, useCallback } from "react";
import { compose } from "redux";
import { hideDialog, showDialog } from "../GlobalDialog";
import AbstractLibrary from "../AbstractLibrary";
import gql from "graphql-tag";
import libraryEnhancer from "../libraryEnhancer";
import { DropdownButton, wrapDialog } from "@teselagen/ui";
import { reduxForm } from "redux-form";
import {
  DataTable,
  DialogFooter,
  InputField,
  TextareaField,
  withSelectedEntities
} from "@teselagen/ui";
import { Callout, Classes, Menu, MenuItem } from "@blueprintjs/core";
import { capitalize, forEach } from "lodash";
import { deleteWithQuery, safeQuery, safeUpsert } from "../apolloMethods";
import LibraryInspector from "../AbstractLibrary/LibraryInspector";
import { asyncValidateName } from "../utils/formUtils";
import { withProps } from "recompose";
import pluralize from "pluralize";
import TableSelect from "../TableSelect";
import { download } from "../utils/downloadTest";
import { getFeatureToColorMap } from "@teselagen/sequence-utils";

function AnnotationGroupAnnotationsView({
  registeredAnnotationGroup,
  annotationGroupAnnotationsTableSelectedEntities = []
}) {
  const _selectedAnnotation =
    annotationGroupAnnotationsTableSelectedEntities[0];
  const annotations =
    registeredAnnotationGroup.registeredAnnotationToGroups.map(
      etg => etg.registeredAnnotation
    );
  // if the annotation was removed then the table will still have it in redux but we don't want to show it
  const selectedAnnotation =
    _selectedAnnotation &&
    annotations.find(e => e.id === _selectedAnnotation.id);
  return (
    <div>
      {selectedAnnotation && (
        <React.Fragment>
          <h6>{selectedAnnotation.name}</h6>
          <div style={{ marginTop: 15 }} />
        </React.Fragment>
      )}
      <DataTable
        isSimple
        withSearch
        maxHeight={300}
        isSingleSelect
        formName="annotationGroupAnnotationsTable"
        schema={[
          {
            displayName: "Annotations",
            path: "name"
          }
        ]}
        entities={annotations}
      />
    </div>
  );
}

const WrappedAnnotationGroupAnnotations = withSelectedEntities(
  "annotationGroupAnnotationsTable"
)(AnnotationGroupAnnotationsView);

const ViewerComponent = props => (
  <LibraryInspector
    {...props}
    additionalFields={[
      {
        path: "registeredAnnotationToGroups.registeredAnnotationGroup.registeredAnnotationToGroups",
        displayName: "Total",
        render: (a, rec) => {
          return rec.registeredAnnotationToGroups.length;
        }
      }
    ]}
    withDescription
    withSharingInformation={false}
    renderExtraItemsTop={group => {
      return (
        <React.Fragment>
          <DropdownButton
            menu={
              <Menu>
                <MenuItem
                  onClick={getExportHandler({ id: group.id, isCSV: true })}
                  text="Export CSV"
                ></MenuItem>
                <MenuItem
                  onClick={getExportHandler({ id: group.id, isAPE: true })}
                  text="Export APE File"
                ></MenuItem>
              </Menu>
            }
            icon="export"
          >
            Export
          </DropdownButton>
          <br></br>
          <br></br>
        </React.Fragment>
      );
    }}
    renderExtraItemsBottom={registeredAnnotationGroup => {
      return (
        <WrappedAnnotationGroupAnnotations
          registeredAnnotationGroup={registeredAnnotationGroup}
        />
      );
    }}
  />
);

const schema = {
  model: "registeredAnnotationGroup",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    {
      path: "description",
      type: "string",
      displayName: "Description",
      isHidden: true
    }
  ]
};

class AnnotationLibrary extends Component {
  libraryName = () => `${capitalize(this.props.annotationType)} Group`;

  onNewItemClick = () => {
    const { annotationType, refetch } = this.props;
    showDialog({
      ModalComponent: NewAnnotationGroupDialog,
      modalProps: {
        annotationType,
        refetch,
        libraryName: this.libraryName()
      }
    });
  };

  onDoubleClick = registeredAnnotationGroup => {
    const { refetch, annotationType } = this.props;
    showDialog({
      ModalComponent: NewAnnotationGroupDialog,
      modalProps: {
        refetch,
        annotationType,
        libraryName: this.libraryName(),
        initialValues: {
          ...registeredAnnotationGroup,
          annotations:
            registeredAnnotationGroup.registeredAnnotationToGroups.map(
              etg => etg.registeredAnnotation
            )
        }
      }
    });
  };

  render() {
    return (
      <AbstractLibrary
        {...this.props}
        noOpen
        libraryName={this.libraryName()}
        noRename
        alwaysShowInspector
        inspectorWidth={350}
        isLibraryTable
        onDoubleClick={this.onDoubleClick}
        onEdit={this.onDoubleClick}
        ViewerComponent={ViewerComponent}
        onNewItemClick={this.onNewItemClick}
      />
    );
  }
}

const fragment = gql`
  fragment registeredAnnotationGroupAnnotationGroupLibraryFragment on registeredAnnotationGroup {
    id
    name
    description
    registeredAnnotationTypeCode
    registeredAnnotationToGroups {
      id
      registeredAnnotation {
        id
        name
      }
    }
  }
`;

export default compose(
  withProps(props => {
    const additionalFilter = useCallback(
      (_, qb) => {
        qb.whereAll({
          registeredAnnotationTypeCode: props.annotationType.toUpperCase()
        });
      },
      [props.annotationType]
    );
    return { additionalFilter };
  }),
  libraryEnhancer({
    fragment,
    schema,
    withSelectedEntities: true,
    noAddedBy: true
  })
)(AnnotationLibrary);

const NewAnnotationGroupDialog = compose(
  wrapDialog(props => ({
    title: `${props.initialValues ? "Edit" : "New"} ${props.libraryName}`
  })),
  withProps(() => ({ model: "registeredAnnotationGroup" })), // needed for async validate
  reduxForm({ form: "newAnnotationGroup", ...asyncValidateName })
)(props => {
  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        <Callout intent="primary">
          Add registered {pluralize(props.annotationType)} to a group. You can
          then use the group to auto-annotate other sequences.
        </Callout>
        <br></br>
        <InputField label="Name" name="name" isRequired></InputField>
        <TextareaField label="Description" name="description"></TextareaField>
        <TableSelect
          {...{
            name: "annotations",
            isMultiSelect: true,
            noDialog: true,
            onCancel: props.hideModal,
            label: (
              <div>
                Select Registered {pluralize(capitalize(props.annotationType))}{" "}
                &nbsp;
                <span
                  style={{ fontSize: 10 }}
                >{`(Import more from the Registered ${pluralize(
                  capitalize(props.annotationType)
                )} tab)`}</span>
              </div>
            ),

            fragment: ["registeredAnnotation", "id name type sequence isRegex"],
            schema: ["name", "type", "sequence"],
            tableParamOptions: {
              additionalFilter: {
                registeredAnnotationTypeCode: props.annotationType.toUpperCase()
              }
            }
          }}
        />
      </div>

      <DialogFooter
        onClick={props.handleSubmit(async values => {
          try {
            const toUpsert = {
              id: values.id,
              name: values.name,
              registeredAnnotationTypeCode: props.annotationType.toUpperCase(),
              description: values.description
            };
            if (!values.id) {
              toUpsert.registeredAnnotationToGroups = (
                values.annotations || []
              ).map(e => ({
                registeredAnnotationId: e.id
              }));
            }
            await safeUpsert("registeredAnnotationGroup", toUpsert);
            if (values.id) {
              await deleteWithQuery("registeredAnnotationToGroup", {
                registeredAnnotationGroupId: values.id
              });
              await safeUpsert(
                "registeredAnnotationToGroup",
                (values.annotations || []).map(e => ({
                  registeredAnnotationId: e.id,
                  registeredAnnotationGroupId: values.id
                }))
              );
            }
            window.toastr.success(
              `Group ${values.name} ${
                props.initialValues ? "Edited" : "Created"
              } Successfully`
            );
            await props.refetch();
            props.hideModal();
          } catch (error) {
            console.error("error:", error);
            window.toastr.error(
              `Error ${values.id ? "editing" : "creating"} ${
                props.libraryName
              }.`
            );
          }
        })}
        hideModal={hideDialog}
        submitting={props.submitting}
      ></DialogFooter>
    </React.Fragment>
  );
});

const getExportHandler =
  ({ id, isCSV, isAPE }) =>
  async () => {
    const fullGroup = await safeQuery(
      [
        "registeredAnnotationGroup",
        `id name registeredAnnotationTypeCode
registeredAnnotationToGroups {
id
registeredAnnotation {
id
name
type
isRegex
description
sequence
}
}`
      ],
      {
        variables: {
          id
        }
      }
    );

    const toDownload = [];
    const featureColorsLower = {};
    forEach(
      getFeatureToColorMap({ includeHidden: true }),
      (color, type) => (featureColorsLower[type.toLowerCase()] = color)
    );
    if (
      !fullGroup.registeredAnnotationToGroups ||
      !fullGroup.registeredAnnotationToGroups.length
    ) {
      window.toastr.error("No annotations found on group!");
    }
    if (isCSV) {
      const hasType = fullGroup.registeredAnnotationTypeCode === "FEATURE";
      toDownload.push(
        `Name,Description,Sequence,${hasType ? "Type," : ""}IsRegex`
      );
      forEach(
        fullGroup.registeredAnnotationToGroups,
        ({
          registeredAnnotation: { name, description, type, sequence, isRegex }
        }) => {
          toDownload.push(
            `${name},${description || ""},${sequence},${
              hasType ? `${type},` : ""
            }${isRegex ? "true" : "false"}`
          );
        }
      );
      download(toDownload.join("\n"), fullGroup.name + ".csv");
    } else if (isAPE) {
      forEach(
        fullGroup.registeredAnnotationToGroups,
        ({ registeredAnnotation: { name, type, sequence } }) => {
          //T3	ATTAACCCTCACTAAAGGGA	primer_bind	cyan	green	0	0
          toDownload.push(
            `${name} ${sequence} ${type} ${
              featureColorsLower[(type || "").toLowerCase() || "misc_feature"]
            }`
          );
        }
      );
      download(toDownload.join("\n"), fullGroup.name + ".txt");
    } else {
      throw new Error("Type not yet supported");
    }
  };
