/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useEffect, useState } from "react";
import { Classes } from "@blueprintjs/core";
import { DialogFooter } from "@teselagen/ui";
import GenericSelect from "../GenericSelect";
import {
  generateTagMapFromQueryParams,
  getTagsUrlParamsForTagSelect
} from "../../../tg-iso-shared/src/tag-utils";
import "./style.css";
import { safeQuery } from "../apolloMethods";
import { tagFilterMenuFragment } from "./tagFilterMenuFragment.gql";
import { isEqual, keyBy } from "lodash";
import { TagFilterMenuFragment as _TagFilterMenuFragment } from "./tagFilterMenuFragment.gql.generated";
import { useDispatch } from "react-redux";
import { change } from "redux-form";

type TagFilterMenuFragment = Required<_TagFilterMenuFragment>;
type Value = TagFilterMenuFragment & { label: string };

type TagFilterParams = {
  page: number | undefined;
  tags: string[];
};
const TagFilterMenu = ({
  currentParams,
  setNewParams,
  formName
}: {
  currentParams: TagFilterParams;
  setNewParams: (params: TagFilterParams) => void;
  formName: string;
}) => {
  const [loadingDefault, setLoadingDefault] = useState(true);
  const [tags, setTags] = useState<Value[]>([]);
  const dispatch = useDispatch();

  const onSubmit = () => {
    const tagParams = getTagsUrlParamsForTagSelect(tags);
    if (
      !isEqual(tagParams.sort(), (currentParams.tags || []).sort()) &&
      !loadingDefault
    ) {
      setNewParams({
        ...currentParams,
        page: undefined,
        tags: tagParams
      });
    }
  };

  const handleFieldSubmit = (_tags = []) => {
    setTags(_tags);
  };

  useEffect(() => {
    async function fetchDefaultValue() {
      try {
        const tagMap = generateTagMapFromQueryParams(currentParams);
        const tagIds = Object.keys(tagMap);
        if (!tagIds.length) return setLoadingDefault(false);
        const tags = (await safeQuery<TagFilterMenuFragment>(
          tagFilterMenuFragment,
          {
            variables: {
              filter: {
                id: tagIds
              }
            }
          }
        )) as TagFilterMenuFragment[];
        const keyedTags = keyBy(tags, "id");
        let defaultValue: Value[] = [];
        Object.keys(tagMap).forEach(tagId => {
          const tag = keyedTags[tagId];
          if (tag) {
            defaultValue.push({
              ...tag,
              label: tag.name
            });
          }
        });
        defaultValue = defaultValue.sort((a, b) =>
          a.label.localeCompare(b.label)
        );
        setTags(defaultValue);
        dispatch(change(formName, "tagFilter", defaultValue));
      } catch (error) {
        console.error(`error:`, error);
      }
      setLoadingDefault(false);
    }
    fetchDefaultValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ padding: 10 }}>
      <div id="needtofindthis" style={{ maxWidth: 250, padding: 4 }}>
        <GenericSelect
          name="tagFilter"
          asReactSelect
          isMultiSelect
          reactSelectProps={{
            isTagSelect: true,
            isLoading: loadingDefault
          }}
          label="Tags"
          onFieldSubmit={handleFieldSubmit}
          enableReinitialize
          tableParamOptions={{
            defaults: {
              order: ["name"]
            }
          }}
          fragment={tagFilterMenuFragment}
        />
      </div>
      <DialogFooter
        text="OK"
        className={Classes.POPOVER_DISMISS}
        noCancel
        onClick={onSubmit}
      />
    </div>
  );
};

export default TagFilterMenu;
