/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import labelMediaFragment from "../../../../src-shared/graphql/fragments/labels/labelMediaFragment.gql";

export default gql`
  fragment labelFormatFragment on labelFormat {
    id
    name
    description
    fileExt
    isContainerArray
    startTemplate
    endTemplate
    labelTemplate
    labelFormatLabelMedias {
      id
      labelMedia {
        ...labelMediaFragment
      }
    }
    labelTemplatePrompts {
      id
      name
      description
      path
    }
  }
  ${labelMediaFragment}
`;
