/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { safeUpsert } from "../apolloMethods";
import { formatDate } from "./dateUtils";

export async function startImportCollection(importCollectionName: string) {
  const [{ id }] = await safeUpsert("importCollection", {
    name: importCollectionName + ` - ${formatDate(new Date())}`
  });
  sessionStorage.setItem("importCollectionId", id);
  return {
    importCollectionId: id,
    markCollectionAsFailed: async (failureReason: string) => {
      // we could use the import collection models list to verify if 1 or more models were created and simply delete the collection in the case that 0 were created
      try {
        return await safeUpsert("importCollection", {
          id,
          failed: true,
          failureReason
        });
      } catch (e) {
        console.error(`error trying to set importCollection as failed:`, e);
        return null;
      }
    }
  };
}

export async function stopImportCollection() {
  sessionStorage.removeItem("importCollectionId");
}
