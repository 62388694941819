/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import downloadjsFile from "downloadjs";

export function download(data: any, fileName: string, type: string) {
  if (window.Cypress && !window.Cypress.allowFileDownload) {
    window.Cypress.cypressTestFile = data;
    window.Cypress.cypressTestFileName = fileName;
    window.Cypress.cypressTestFileType = type;
    // eslint-disable-next-line no-console
    console.log(`file data:`, data);
    window.toastr.success(
      "file downloaded, use catchDownload((file,contents)=>{}) to assert on it"
    );
  }
  downloadjsFile(new Blob([data]), fileName, type);
}
