/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { get, keyBy, pick, range, set, noop, isEmpty } from "lodash";
import { showConfirmationDialog, onEnterHelper } from "@teselagen/ui";
import Big from "big.js";
import { minBigs } from "../../../../tg-iso-lims/src/utils/bigIntUtils";
import {
  withUnitGeneric,
  getMassFromVolumeAndConcentration,
  calculateConcentration,
  standardizeConcentration,
  standardizeVolume,
  convertVolume,
  convertConcentration,
  convertMass,
  convertMolarity
} from "../../../src-shared/utils/unitUtils";
import {
  calculateMolarityFromConcentration,
  standardizeMolarity,
  molarToNanoMolar,
  standardizeCellConcentration,
  sumVolumes
} from "../../../../tg-iso-lims/src/utils/unitUtils";
import { getAliquotMolecularWeight } from "../../../../tg-iso-lims/src/utils/aliquotUtils";
import {
  plateTo2dAliquotContainerArray,
  getBlockOf2dArray,
  blockToAliquotArray
} from "../../components/LimsTools/utils";
import { Link } from "react-router-dom";
import modelNameToLink from "../../../src-shared/utils/modelNameToLink";
import { safeUpsert, safeQuery } from "../../../src-shared/apolloMethods";
import { getAliquotContainerLocation } from "../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import unitGlobals from "../../../../tg-iso-lims/src/unitGlobals";
import { startCase } from "lodash";
import getAliquotNumberOfCells from "../../../../tg-iso-lims/src/utils/unitUtils/getAliquotNumberOfCells";
import { isNumber } from "lodash";
import { getMaterialPlasmidSequence } from "../materialUtils";

export function renderLocation(value, record) {
  return getAliquotContainerLocation(record);
}

export const sampleStatusColumn = {
  path: "aliquot",
  type: "string",
  displayName: "Sample Status",
  render: (v, r) => {
    if (get(r, "aliquot.sample.sampleStatus")) {
      return get(r, "aliquot.sample.sampleStatus.name");
    } else {
      return "N/A";
    }
  }
};

export function getPlateAliquotType(plateOrAliquotContainers) {
  const aliquotContainers = Array.isArray(plateOrAliquotContainers)
    ? plateOrAliquotContainers
    : plateOrAliquotContainers.aliquotContainers;
  let isDry = false,
    isWet = false;
  for (const aliquotContainer of aliquotContainers) {
    const aliquot = aliquotContainer.aliquot;
    if (aliquot) {
      if (aliquot.isDry) isDry = true;
      else isWet = true;
    }
  }
  let type = "mixed";
  if (isDry && !isWet) type = "dry";
  if (isWet && !isDry) type = "wet";
  return type;
}

export async function beforeRackDelete(maybeRecords) {
  const records = Array.isArray(maybeRecords) ? maybeRecords : [maybeRecords];
  const rackRecords = records.filter(record =>
    get(record, "containerArrayType.name").includes("Rack")
  );
  if (rackRecords.length) {
    const shouldDeleteTubes = await showConfirmationDialog({
      text: "Would you like to delete the tubes on these racks?",
      icon: "help",
      cancelButtonText: "No",
      confirmButtonText: "Yes"
    });
    if (!shouldDeleteTubes) {
      const aliquotContainerUpdates = [];

      const addToUpdates = aliquotContainers => {
        aliquotContainers.forEach(ac => {
          aliquotContainerUpdates.push({
            id: ac.id,
            containerArrayId: null
          });
        });
      };
      if (rackRecords[0].aliquotContainers) {
        rackRecords.forEach(rack => {
          addToUpdates(rack.aliquotContainers);
        });
      } else {
        const aliquotContainers = await safeQuery(["aliquotContainer", "id"], {
          variables: {
            filter: {
              containerArrayId: rackRecords.map(({ id }) => id)
            }
          }
        });
        addToUpdates(aliquotContainers);
      }
      if (aliquotContainerUpdates.length) {
        await safeUpsert("aliquotContainer", aliquotContainerUpdates);
      }
    }
  }
}

export function inPlateBounds(
  { rowPosition, columnPosition },
  { rowCount, columnCount }
) {
  return (
    isNumber(rowPosition) &&
    isNumber(columnPosition) &&
    rowPosition >= 0 &&
    rowPosition < rowCount &&
    columnPosition >= 0 &&
    columnPosition < columnCount
  );
}

export function createPlateLocationMap(aliquotContainers) {
  const map = {};
  aliquotContainers.forEach(ac => {
    map[getAliquotContainerLocation(ac)] = ac;
  });
  return map;
}

/**
 * This function constructs the initial values needed for the
 * CreatePlateLayerDialog
 * @param {array of <PlateLayers> from DB} plateLayers
 * @param {aliquot containers or plate map items} aliquotContainers
 */
export function getInitialPlateLayerValues(
  plateLayers = [],
  aliquotContainers
) {
  const keyedAliquotContainers = keyBy(aliquotContainers, "id");
  return plateLayers.map(layer => {
    const { id, plateLayerDefinition, plateZones } = layer;
    return {
      id,
      plateLayerDefinitionId: plateLayerDefinition.id,
      name: plateLayerDefinition.name,
      zones: plateZones.map(zone => {
        const { id, plateZoneDefinition, plateZoneWells } = zone;
        return {
          id,
          plateZoneDefinitionId: plateZoneDefinition.id,
          color: plateZoneDefinition.color,
          name: plateZoneDefinition.name,
          locations: plateZoneWells.reduce((acc, pzw) => {
            const ac =
              keyedAliquotContainers[
                pzw.aliquotContainerId || pzw.plateMapItemId
              ];
            if (ac) {
              acc.push(getAliquotContainerLocation(ac));
            }
            return acc;
          }, [])
        };
      })
    };
  });
}

export function findAliquotContainer(plate2d, rowPosition, columnPosition) {
  return plate2d[rowPosition][columnPosition];
}

/* gets rid of leading 0's in position number */
export function cleanPosition(egA01orD4 = "") {
  const [letter, ...number] = egA01orD4.split("");
  return letter + Number(number.join(""));
}

export const rowLetterToIndex = rowLetter => {
  return rowLetter.toLowerCase().charCodeAt(0) - 97;
};

export function assignAliquotContainerPosition(
  aliquotContainers,
  { rowCount, columnCount },
  { columnFirst = false } = {}
) {
  let row = 0,
    column = 0;
  if (!columnFirst) {
    return aliquotContainers.map(aliquotContainer => {
      if (column === columnCount) {
        column = 0;
        row++;
      }
      return {
        ...aliquotContainer,
        rowPosition: row,
        columnPosition: column++
      };
    });
  } else {
    return aliquotContainers.map(aliquotContainer => {
      if (row === rowCount) {
        row = 0;
        column++;
      }
      return {
        ...aliquotContainer,
        rowPosition: row++,
        columnPosition: column
      };
    });
  }
}

export function getAliquotContainerTableSchema(aliquotContainers) {
  let dry = false;
  let wet = false;
  let hasCellConcentration = false;
  aliquotContainers.forEach(c => {
    if (c.aliquot) {
      if (c.aliquot.isDry) dry = true;
      if (!c.aliquot.isDry) wet = true;
      if (c.aliquot.cellConcentration) hasCellConcentration = true;
    }
  });
  return {
    model: "aliquotContainer",
    fields: [
      {
        path: "location",
        type: "string",
        displayName: "Location",
        render: (v, r) => getAliquotContainerLocation(r)
      },
      {
        path: "aliquot.sample.name",
        type: "string",
        displayName: "Sample"
      },
      {
        path: "aliquot.volume",
        type: "string",
        isHidden: !wet,
        displayName: "Volume",
        render: withUnitGeneric("aliquot.volume", "aliquot.volumetricUnitCode")
      },
      {
        path: "aliquot.concentration",
        type: "string",
        displayName: "Concentration",
        isHidden: !wet,
        render: withUnitGeneric(
          "aliquot.concentration",
          "aliquot.concentrationUnitCode"
        )
      },
      {
        path: "aliquot.molarity",
        type: "number",
        displayName: "Molarity",
        isHidden: !wet,
        render: withUnitGeneric("aliquot.molarity", "aliquot.molarityUnitCode")
      },
      {
        path: "aliquot.mass",
        type: "string",
        displayName: "Mass",
        isHidden: !dry,
        render: withUnitGeneric("aliquot.mass", "aliquot.massUnitCode")
      },
      {
        path: "aliquot.cellConcentration",
        type: "string",
        displayName: "Cell Concentration",
        isHidden: !hasCellConcentration,
        render: withUnitGeneric(
          "aliquot.cellConcentration",
          "aliquot.cellConcentrationUnitCode"
        )
      }
    ]
  };
}

export const prepareDryAliquot = ({ aliquot, mass, massUnitCode }) => {
  let massInputs;
  if (mass !== undefined) {
    massInputs = {
      mass,
      massUnitCode
    };
  } else {
    massInputs = getMassFromVolumeAndConcentration({
      ...pick(aliquot, [
        "volume",
        "volumetricUnitCode",
        "concentration",
        "concentrationUnitCode"
      ]),
      big: true
    });
  }
  let cellConcentrationFields = {};
  if (aliquot.cellConcentration) {
    cellConcentrationFields = {
      cellCount: getAliquotNumberOfCells(aliquot),
      cellConcentration: null
    };
  }

  massInputs.mass = massInputs.mass.toString();
  return {
    id: aliquot.id,
    ...massInputs,
    isDry: true,
    volume: null,
    volumetricUnitCode: null,
    concentrationUnitCode: null,
    concentration: null,
    molarity: null,
    molarityUnitCode: null,
    ...cellConcentrationFields
  };
};

export const prepareWetAliquot = ({
  aliquot,
  volume,
  volumetricUnitCode,
  concentrationUnitCode,
  molarityUnitCode
}) => {
  const molecularWeight =
    get(
      aliquot,
      "sample.material.polynucleotideMaterialSequence.molecularWeight"
    ) || get(aliquot, "sample.material.functionalProteinUnit.molecularWeight");

  const concentration = calculateConcentration({
    volume,
    volumetricUnitCode,
    concentrationUnitCode,
    mass: aliquot.mass,
    massUnitCode: aliquot.massUnitCode,
    big: true
  }).toString();

  let molarity;
  if (molecularWeight && concentration) {
    molarity = Number(
      convertMolarity(
        calculateMolarityFromConcentration(
          concentration,
          concentrationUnitCode,
          molecularWeight
        ) * molarToNanoMolar,
        "nM",
        molarityUnitCode,
        true
      )
    ).toString();
  }

  let cellConcentrationFields = {};
  if (aliquot.cellCount) {
    cellConcentrationFields = {
      cellConcentration: aliquot.cellCount / volume,
      cellConcentrationUnitCode: `cells/${volumetricUnitCode}`
    };
  }

  return {
    id: aliquot.id,
    isDry: false,
    mass: null,
    massUnitCode: null,
    volume,
    volumetricUnitCode,
    concentrationUnitCode,
    molarityUnitCode:
      molecularWeight && concentration ? molarityUnitCode : null,
    molarity,
    concentration,
    ...cellConcentrationFields
  };
};

export const indexToColumnMajorAliquotContainer = (containerArray, index) => {
  const {
    containerArrayType: {
      containerFormat: { rowCount }
    }
  } = containerArray;

  const columnPosition = Math.floor(index / rowCount);
  const rowPosition = index % rowCount;

  return containerArray.aliquotContainers.find(
    ac => ac.columnPosition === columnPosition && ac.rowPosition === rowPosition
  );
};

export const getAliquotContainerByPosition = (
  aliquotContainers,
  rowPosition,
  columnPosition
) =>
  aliquotContainers.find(
    ac => ac.columnPosition === columnPosition && ac.rowPosition === rowPosition
  );

export const barcodeInputHelper = onEnterHelper(e => {
  e.preventDefault();
  const row = e.target.closest(".rt-tr-group");
  const nextRow = row.nextSibling;
  if (nextRow) {
    const inputField = nextRow.querySelector(".barcode-input-in-table");
    const input = (inputField || nextRow).querySelector("input");
    if (input) input.focus();
  }
});

export const noTransfersRequired = values => {
  const {
    containerArray,
    concentrationType,
    desiredConcentration,
    desiredConcentrationUnitCode,
    desiredCellConcentration,
    desiredCellConcentrationUnitCode,
    desiredMolarity,
    desiredMolarityUnitCode
  } = values;
  if (
    containerArray &&
    concentrationType === "concentration" &&
    desiredConcentration
  ) {
    const stDesiredConcentration = standardizeConcentration(
      desiredConcentration,
      desiredConcentrationUnitCode
    );
    return containerArray.aliquotContainers.every(aliquotContainer => {
      const aliquot = aliquotContainer.aliquot;
      if (aliquot) {
        if (aliquot.isDry) return false;
        const stConc = standardizeConcentration(
          aliquot.concentration,
          aliquot.concentrationUnitCode
        );
        return stConc === stDesiredConcentration;
      } else {
        return true;
      }
    });
  } else if (
    containerArray &&
    concentrationType === "molarity" &&
    desiredMolarity
  ) {
    const stDesiredMolarity = standardizeMolarity(
      desiredMolarity,
      desiredMolarityUnitCode
    );
    return containerArray.aliquotContainers.every(aliquotContainer => {
      const aliquot = aliquotContainer.aliquot;
      if (aliquot) {
        if (aliquot.isDry) return false;
        const molecularWeight = getAliquotMolecularWeight(aliquot);
        if (aliquot.molarity) {
          const stMolarity = standardizeMolarity(
            aliquot.molarity,
            aliquot.molarityUnitCode
          );
          return stMolarity === stDesiredMolarity;
        } else if (molecularWeight) {
          const stMolarity = standardizeMolarity(
            calculateMolarityFromConcentration(
              aliquot.concentration,
              aliquot.concentrationUnitCode,
              molecularWeight
            ),
            "M"
          );
          return stMolarity === stDesiredMolarity;
        } else {
          return true;
        }
      } else {
        return true;
      }
    });
  } else if (
    containerArray &&
    concentrationType === "cellConcentration" &&
    desiredCellConcentration
  ) {
    const stDesiredCellConcentration = standardizeCellConcentration(
      desiredCellConcentration,
      desiredCellConcentrationUnitCode
    );
    return containerArray.aliquotContainers.every(aliquotContainer => {
      const aliquot = aliquotContainer.aliquot;
      if (aliquot) {
        if (aliquot.isDry) return false;
        const stConc = standardizeCellConcentration(
          aliquot.cellConcentration,
          aliquot.cellConcentrationUnitCode
        );
        return stConc === stDesiredCellConcentration;
      } else {
        return true;
      }
    });
  } else {
    return false;
  }
};

export const minTransferVolume = values => {
  const {
    containerArray,
    containerArrays,
    aliquotContainer,
    aliquotContainers,
    desiredTransferVolume,
    desiredTransferVolumetricUnitCode,
    maxTransferVolume,
    additive
  } = values;

  const volumes = [];
  if (containerArray || containerArrays) {
    const platesToValidate = containerArrays || [containerArray];
    for (const plate of platesToValidate) {
      if (!get(plate, "aliquotContainers")) return;
    }
    platesToValidate.forEach(plate => {
      return plate.aliquotContainers.forEach(ac => {
        const { aliquot } = ac;
        if (!aliquot || aliquot.isDry) return;
        volumes.push(
          standardizeVolume(
            aliquot.volume || 0,
            aliquot.volumetricUnitCode || "uL",
            true
          )
        );
      });
    });
  }

  if (aliquotContainers) {
    aliquotContainers.forEach(ac => {
      if (!ac.aliquot || ac.aliquot.isDry) return;
      volumes.push(
        standardizeVolume(
          ac.aliquot.volume || 0,
          ac.aliquot.volumetricUnitCode,
          true
        )
      );
    });
  }

  if (additive) {
    volumes.push(
      standardizeVolume(
        maxTransferVolume,
        aliquotContainer.aliquotContainerType.volumetricUnitCode,
        true
      )
    );
  }
  const minVolume = minBigs(volumes) || new Big(0);
  const volumeUnit =
    unitGlobals.volumetricUnits[desiredTransferVolumetricUnitCode];
  if (!volumeUnit) return;
  const standardizedDesiredVolume = new Big(desiredTransferVolume || 0).times(
    volumeUnit.liters
  );
  if (!standardizedDesiredVolume) return "Please enter a value.";
  if (standardizedDesiredVolume.lt(new Big(0)))
    return "Please enter a positive number.";
  if (standardizedDesiredVolume.gt(minVolume)) {
    if (aliquotContainer && additive) {
      return `Desired volume must not cause contents to exceed max tube volume (${aliquotContainer.aliquotContainerType.maxVolume} ${aliquotContainer.aliquotContainerType.volumetricUnitCode}).`;
    }
    return `Desired volume must be lower than the lowest aliquot volume (${
      minVolume.div(new Big(volumeUnit.liters)).round(2).toString() +
      " " +
      volumeUnit.code
    }).`;
  }
};

export function validateNoDryPlatesObject(containerArrays) {
  return validateMaterialPlates(containerArrays, {
    asObject: true,
    validateAliquot: noop
  });
}

export function validateMaterialPlates(
  containerArrays,
  {
    asObject,
    materialTypeCode,
    enforceSequenceLinks = false,
    allowEmptyWithAdditives = false,
    allowDry = false,
    validateAliquot
  } = {}
) {
  if (containerArrays && containerArrays.length) {
    const errorObject = {};
    let fullError = "";
    const dryPlates = [];
    const missingAliquotPlates = [];
    const readableMaterialTypeCode = materialTypeCode
      ? materialTypeCode === "DNA"
        ? "DNA"
        : materialTypeCode.toLowerCase()
      : null;

    containerArrays.forEach(plate => {
      let hasDryAliquot = false;
      let hasNonEmptyWell = false;
      let hasOtherErrors = false;

      const addErrorFull = (errorMsg, ac, plate) => {
        if (asObject) {
          const location = getAliquotContainerLocation(ac, { force2D: true });
          if (!errorObject[plate.id]) {
            errorObject[plate.id] = {};
          }
          errorObject[plate.id][location] =
            errorObject[plate.id][location] || [];
          errorObject[plate.id][location].push(errorMsg);
        } else {
          const location = getAliquotContainerLocation({
            ...ac,
            containerArray: plate
          });
          const plateNameAndLocation = `${plate.name} (${location})`;
          const error = `${plateNameAndLocation}: ${errorMsg}`;
          if (fullError) fullError += "\n";
          fullError += error;
        }
      };
      plate.aliquotContainers?.forEach(ac => {
        const addErrorBound = (msg, basicError) => {
          addErrorFull(msg, ac, plate);
          if (!basicError) hasOtherErrors = true;
        };
        if (!allowDry && ac.aliquot && ac.aliquot.isDry) {
          hasDryAliquot = true;
          addErrorBound("This aliquot is dry", true);
        }

        let hasAdditives = false;
        if (allowEmptyWithAdditives && ac.additives && ac.additives.length) {
          hasAdditives = true;
        }
        if (ac.aliquot || hasAdditives) {
          hasNonEmptyWell = true;
        }
        if (ac.aliquot) {
          if (validateAliquot) {
            const msg = validateAliquot(ac.aliquot);
            if (msg) {
              addErrorBound(msg);
            }
          } else {
            const sample = get(ac, "aliquot.sample");
            const material = get(sample, "material");
            const sampleTypeCode = get(sample, "sampleTypeCode");
            const addMaterialMessage = material => {
              let materialMsg = "";
              if (material && material.name) {
                materialMsg = `Material ${material.name} `;
              }
              if (
                materialTypeCode &&
                get(material, "materialTypeCode") !== materialTypeCode
              ) {
                addErrorBound(
                  materialMsg + `is not a ${readableMaterialTypeCode} material`
                );
              } else if (
                enforceSequenceLinks &&
                !get(material, "polynucleotideMaterialSequence.id")
              ) {
                addErrorBound(materialMsg + " is not linked to a sequence");
              }
            };
            if (material) {
              addMaterialMessage(material);
            } else if (
              sampleTypeCode === "FORMULATED_SAMPLE" &&
              sample.sampleFormulations
            ) {
              sample.sampleFormulations.forEach(sf => {
                sf.materialCompositions.forEach(mc => {
                  if (mc.material) {
                    addMaterialMessage(mc.material);
                  }
                });
              });
            } else if (materialTypeCode) {
              addErrorBound(
                `is not linked to a ${readableMaterialTypeCode} material`
              );
            }
          }
        }
      });

      let errorForPlate = "";
      if (!hasNonEmptyWell) {
        missingAliquotPlates.push(plate.name);
        errorForPlate += `This plate does not have any aliquots.\n`;
      }
      if (hasDryAliquot) {
        dryPlates.push(plate.name);
        // the dry error message will show with the other messages in the wells
        if (!hasOtherErrors) {
          // we want to go to default message
          errorForPlate += `This plate has dry aliquots.\n`;
        }
      }

      if (errorForPlate && hasOtherErrors) {
        errorForPlate += `Preview plate to view other errors.\n`;
      }

      if (asObject && errorForPlate) {
        set(errorObject, `${plate.id}._error`, errorForPlate);
      }
    });

    const addGlobalError = msg => {
      if (asObject) {
        if (!errorObject._error) errorObject._error = "";
        errorObject._error += msg;
      } else {
        fullError = msg + fullError;
      }
    };

    if (dryPlates.length) {
      const dryError = `These plates have dry aliquots: ${dryPlates.join(
        ", "
      )}.\n\n`;
      addGlobalError(dryError);
    }

    if (missingAliquotPlates.length) {
      const missingErr = `These plates do not have any aliquots: ${missingAliquotPlates.join(
        ", "
      )}.\n\n`;
      addGlobalError(missingErr);
    }

    if (asObject) {
      return errorObject;
    } else {
      return fullError;
    }
  }
}

export const validateMicrobialMaterial = material => {
  // update this to include check for whether plasmid is linked to DNA material
  const microbialMaterialPlasmids =
    material.microbialMaterialMicrobialMaterialPlasmids;

  const microbeMissingPlasmid =
    microbialMaterialPlasmids.length < 1 ||
    microbialMaterialPlasmids.some(mmp => !getMaterialPlasmidSequence(mmp));

  const microbeMissingPlasmidMaterial =
    microbialMaterialPlasmids.length > 1 ||
    microbialMaterialPlasmids.some(mmp => !mmp.polynucleotideMaterial);

  const microbialPlasmidMissingFpus = microbialMaterialPlasmids.some(
    mmp =>
      !mmp.polynucleotideMaterial?.polynucleotideMaterialMaterialFpus?.length
  );

  const microbialFpusMissingMaterials = microbialMaterialPlasmids.some(mmp => {
    if (mmp.polynucleotideMaterial?.polynucleotideMaterialMaterialFpus) {
      return mmp.polynucleotideMaterial?.polynucleotideMaterialMaterialFpus.some(
        materialFpu => !materialFpu.functionalProteinUnit.proteinMaterial
      );
    }
    return false;
  });

  return {
    missingPlasmid: microbeMissingPlasmid,
    missingPlasmidMaterial: microbeMissingPlasmidMaterial,
    plasmidMissingFpus: microbialPlasmidMissingFpus,
    fpusMissingMaterials: microbialFpusMissingMaterials
  };
};

const validateDNAMaterial = material => {
  const sequence = material.polynucleotideMaterialSequence;
  let noSequence = false;
  let noFpus = false;
  let noProteinMaterials = false;
  if (!sequence) {
    noSequence = true;
  } else if (!material.polynucleotideMaterialMaterialFpus.length) {
    noFpus = true;
  } else if (
    material.polynucleotideMaterialMaterialFpus.some(
      materialFpu => !materialFpu.functionalProteinUnit.proteinMaterial
    )
  ) {
    noProteinMaterials = true;
  }
  return {
    noSequence,
    noFpus,
    noProteinMaterials
  };
};

export const validateDNAOrMicrobialMaterial = material => {
  if (material.materialTypeCode === "MICROBIAL") {
    return validateMicrobialMaterial(material);
  } else {
    return validateDNAMaterial(material);
  }
};

export const validateContainerArrays = (
  values,
  { materialTypeCode = "DNA", errors = {} } = {}
) => {
  const { containerArrays } = values;
  if (!containerArrays || !containerArrays.length) {
    errors.containerArrays = "Please select an input plate or rack.";
  } else {
    const readableTypeCode = materialTypeCode === "DNA" ? "DNA" : "microbial";
    errors.containerArrays = [];
    containerArrays.forEach(containerArray => {
      containerArray.aliquotContainers.some(aliquotContainer => {
        if (!aliquotContainer.aliquot) return false;
        const aliquotSample = get(aliquotContainer, "aliquot.sample", {});
        const genericError = insertError =>
          `${containerArray.name} ${insertError}.`;
        let error;
        if (isEmpty(aliquotSample)) {
          error = genericError("is missing a sample");
        } else if (!aliquotSample.material) {
          error = genericError("is missing a material");
        } else if (
          aliquotSample.material.materialTypeCode !== materialTypeCode
        ) {
          error = genericError(
            `must contain only ${readableTypeCode} materials`
          );
        } else if (
          aliquotSample.material.materialTypeCode !== materialTypeCode
        ) {
          error = genericError(`does not hold ${readableTypeCode} materials`);
        } else if (
          materialTypeCode === "DNA" &&
          !aliquotSample.material.polynucleotideMaterialSequence
        ) {
          error = genericError(`does not have DNA sequences attached`);
        }

        if (error) {
          return errors.containerArrays.push(error);
        } else return false;
      });
    });
    if (!errors.containerArrays.length) delete errors.containerArrays;
  }
  return errors;
};

export const additionalFilterForTubes = (props, qb) => {
  qb.whereAll({
    "aliquotContainerType.isTube": true,
    containerArrayId: qb.isNull()
  });
};

export const attachedColumnPlateFilter = (props, qb) => {
  qb.whereAll({
    collectionPlateId: qb.notNull(),
    "containerArrayType.isColumn": true
  });
};

const getPotentialMass = aliquotOrAdditive => {
  const {
    volume = 0,
    volumetricUnitCode,
    concentration = 0,
    concentrationUnitCode
  } = aliquotOrAdditive;
  const volumeInLiters = convertVolume(volume, volumetricUnitCode, "L", true);
  const concentrationInGL = convertConcentration(
    concentration,
    concentrationUnitCode,
    "g/L",
    true
  );
  return Number(volumeInLiters.times(concentrationInGL).toString());
};

export const getAliquotTransferVolumeFromMass = (
  aliquotOrAdditive,
  mass,
  massUnitCode
) => {
  const { volume = 0 } = aliquotOrAdditive;
  const massInGrams = getPotentialMass(aliquotOrAdditive);
  const desiredTransferMassInGrams = convertMass(mass, massUnitCode, "g", true);
  return Number(
    desiredTransferMassInGrams.div(massInGrams).times(volume).toString()
  );
};

export function getPlateTubeLabel(r) {
  let label = "";
  if (r.name) {
    label += r.name;
  }
  if (r.barcode && r.barcode.barcodeString) {
    if (label) label += " ";
    label += `(${r.barcode.barcodeString})`;
  }
  return label;
}

export const taggedItems = `taggedItems {
  id
  tagId
  tagOptionId
}`;

export function getActiveLocationsForQuadrant({
  containerFormat,
  aliquotContainers,
  quadrant,
  breakdownPattern
}) {
  const activeLocationsArray = [];
  const { rowCount, columnCount } = containerFormat;

  const aliquotContainer2dArray = plateTo2dAliquotContainerArray({
    containerArrayType: { containerFormat: containerFormat },
    aliquotContainers
  });

  const blockRowCount = 2;
  const blockColCount = 2;
  range(rowCount / 2).forEach(repRowPos => {
    range(columnCount / 2).forEach(repColPos => {
      const block = getBlockOf2dArray(
        aliquotContainer2dArray,
        blockRowCount,
        blockColCount,
        repColPos,
        repRowPos,
        true,
        true
      );
      blockToAliquotArray(block, breakdownPattern).forEach(
        (aliquotContainer, quadrantIndex) => {
          if (quadrantIndex === quadrant) {
            activeLocationsArray.push(
              getAliquotContainerLocation(aliquotContainer)
            );
          }
        }
      );
    });
  });
  return activeLocationsArray;
}

export const aliquotVolIsLessThanDeadTooltip =
  "Aliquot volume is less than dead volume of container.";

export const aliquotHasVolumeToTransfer = aliquot => {
  const aliquotContainerType =
    get(aliquot, "aliquotContainer.aliquotContainerType") || {};
  const standardizedDeadVolume = standardizeVolume(
    aliquotContainerType.deadVolume || 0,
    aliquotContainerType.deadVolumetricUnitCode || "uL"
  );
  const standardizedAliquotVolume = standardizeVolume(
    aliquot.volume || 0,
    aliquot.volumetricUnitCode || "uL"
  );
  // if aliquot has more than 1 nanoliter
  return standardizedAliquotVolume - standardizedDeadVolume > 1e-9;
};

export const additivesHaveVolumeToTransfer = ({
  aliquotContainerType,
  additives = []
}) => {
  const standardizedDeadVolume = standardizeVolume(
    aliquotContainerType.deadVolume || 0,
    aliquotContainerType.deadVolumetricUnitCode || "uL"
  );
  const allAdditiveVolume = Number(sumVolumes(additives, "L").toString());

  // if additives has more than 1 nanoliter
  return allAdditiveVolume - standardizedDeadVolume > 1e-9;
};

export const plateLibraryFilter = (_, qb) => {
  qb.whereAll({
    displayFilter: qb.isNull()
  });
};

export function getAdditives(aliquotContainer = {}) {
  return (
    get(aliquotContainer, "aliquot.additives") ||
    aliquotContainer.additives ||
    []
  );
}

export function getAliquotContainerAdditiveList(aliquotContainer = {}) {
  const additives = getAdditives(aliquotContainer);
  return additives.map(additive => {
    const additiveMaterialName =
      get(additive, "additiveMaterial.name") ||
      get(additive, "lot.additiveMaterial.name") ||
      "";
    let lotName = get(additive, "lot.name", "");
    if (lotName) lotName = ` (${lotName})`;
    return additiveMaterialName + lotName;
  });
}

export function getAliquotContainerAdditiveString(aliquotContainer = {}) {
  return getAliquotContainerAdditiveList(aliquotContainer).join(", ");
}

export function getAliquotMaterialString(aliquot) {
  return getAliquotMaterialList(aliquot)
    .map(m => m.name)
    .join(", ");
}

export function getWellContents(ac) {
  const sampleName = get(ac, "aliquot.sample.name");
  const materialNames = getAliquotMaterialString(ac.aliquot);
  const additiveString = getAliquotContainerAdditiveString(ac);
  return materialNames || sampleName || additiveString;
}

export const getIsFormulatedSample = sample => {
  if (!sample) return false;
  return (
    sample.sampleTypeCode === "FORMULATED_SAMPLE" ||
    sample.sampleTypeCode === "FERMENTED_BATCH"
  );
};

export function getSampleMaterialList(sample, { returnIds } = {}) {
  const materialList = [];
  const isFormulatedSample = getIsFormulatedSample(sample);
  if (sample && !sample.material && isFormulatedSample) {
    const matIds = [];
    sample.sampleFormulations.forEach(sf => {
      sf.materialCompositions.forEach(mc => {
        const mat = mc.material;
        if (mat && !matIds.includes(mat.id)) {
          matIds.push(mat.id);
          materialList.push(returnIds ? mat.id : mat);
        }
      });
    });
  } else {
    const material = get(sample, "material");
    if (material) {
      materialList.push(returnIds ? material.id : material);
    }
  }
  return materialList;
}

export function getAliquotMaterialList(aliquot) {
  return getSampleMaterialList(get(aliquot, "sample"));
}

export const renderMaterialsField = (aliquot, inNewTab = false) => {
  const materials = getAliquotMaterialList(aliquot);
  return materials.map((mat, i) => {
    return (
      <React.Fragment key={mat.id}>
        <Link
          to={modelNameToLink(mat.__typename, mat.id)}
          {...(inNewTab
            ? { target: "_blank", rel: "nofollow noreferrer noopener" }
            : {})}
        >
          {mat.name}
        </Link>
        {i !== materials.length - 1 ? ", " : ""}
      </React.Fragment>
    );
  });
};

export const aliquotContainerBottoms = [
  "Round",
  "Flat",
  "Pyramidal",
  "Conical"
];
export const bottomField = {
  path: "bottom",
  type: "dropdown",
  values: aliquotContainerBottoms,
  description: `Optional bottom type - one of (${aliquotContainerBottoms.join(
    ", "
  )})`,
  example: "Round"
};

export function cleanPlateForIntegrationRequest(plate) {
  const format = plate.containerArrayType.containerFormat;
  return {
    id: plate.id,
    name: plate.name,
    barcode: get(plate, "barcode.barcodeString"),
    format: {
      name: format.name,
      rowCount: format.rowCount,
      columnCount: format.columnCount
    },
    contents: plate.aliquotContainers.map(ac => {
      const aliquot = ac.aliquot || {};
      return {
        position: getAliquotContainerLocation({ ...ac, containerArray: plate }),
        volume: aliquot.volume,
        "volume unit": aliquot.volumetricUnitCode,
        mass: aliquot.mass,
        "mass unit": aliquot.massUnitCode,
        concentration: aliquot.concentration,
        "concentration unit": aliquot.concentrationUnitCode,
        molarity: aliquot.molarity,
        "molarity unit": aliquot.molarityUnitCode,
        "aliquot id": aliquot.id,
        "sample id": aliquot.sample?.id,
        sample: aliquot.sample?.name,
        "sample type": startCase(aliquot.sample?.sampleTypeCode || ""),
        "sample status": startCase(aliquot.sample?.sampleStatusCode || ""),
        materials: getAliquotMaterialList(aliquot),
        additives: getAliquotContainerAdditiveList(ac)
      };
    })
  };
}
