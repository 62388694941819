/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Link } from "react-router-dom";
import { useIntegrationQuery } from "../useIntegrationQuery";
import integrationTypeSettingsMap from "../../../../tg-iso-shared/src/utils/integrationTypeSettingsMap";
import useTgQuery from "../../apolloUseTgQuery";
import { DialogFooter, InputField, Loading, wrapDialog } from "@teselagen/ui";
import { getFormInputsUi } from "../getFormInputsUi";
import { compose } from "recompose";
import { Callout, Classes } from "@blueprintjs/core";
import { getFormValues, reduxForm } from "redux-form";
import withQuery from "../../withQuery";
import LabelSettingsForm from "./LabelSettingsForm";
import { safeQuery } from "../../apolloMethods";
import labelMediaFragment from "../../graphql/fragments/labels/labelMediaFragment.gql";
import { get } from "lodash";
import labelFormatFragment from "../../../src-build/graphql/fragments/labels/labelFormatFragment";

const {
  CUSTOM_LABEL_PRINTING: {
    endpoints: {
      CUSTOM_LABEL__PRINT_SETTINGS_FORM: { method: methodPrintSettings },
      CUSTOM_LABEL__LABEL_SETTINGS_PRESET: { method: methodLabelSettings },
      CUSTOM_LABEL__PRINT_LABEL: { method: methodPrint }
    }
  }
} = integrationTypeSettingsMap;

function getPrintSettingsEndpoint(integration) {
  return integration.integrationEndpoints.find(
    ep => ep.endpointTypeCode === "CUSTOM_LABEL__PRINT_SETTINGS_FORM" && ep.url
  );
}
function getSettingsEndpoint(integration) {
  return integration.integrationEndpoints.find(
    ep =>
      ep.endpointTypeCode === "CUSTOM_LABEL__LABEL_SETTINGS_PRESET" && ep.url
  );
}

function getPrintEndpoint(integration) {
  return integration.integrationEndpoints.find(
    ep => ep.endpointTypeCode === "CUSTOM_LABEL__PRINT_LABEL" && ep.url
  );
}

const form = "customLabelDialog";

function getCurrentValues() {
  return getFormValues(form)(window.teGlobalStore.getState());
}

async function filterValid(items) {
  let warningMessage = "";
  let filteredItems = [];
  items.forEach(m => {
    if (m.id) {
      filteredItems.push(m);
    } else {
      warningMessage += `\nLabel Format ${
        m.name || "unknown"
      } was returned from endpoint without an ID.`;
    }
  });
  const labelMediasDb = await safeQuery(["labelFormat", "id name"], {
    variables: {
      filter: {
        id: filteredItems.map(m => m.id)
      }
    }
  });
  const idsFromDb = labelMediasDb.map(m => m.id);
  filteredItems = filteredItems.filter(flm => {
    if (idsFromDb.includes(flm.id)) {
      return true;
    } else {
      warningMessage += `\nnLabel Format ${
        flm.name || "unknown"
      } was not found in database.`;
      return false;
    }
  });
  return {
    warningMessage,
    filteredItems
  };
}

function PrintCustomExternalLabel(props) {
  const {
    containerArrays,
    aliquotContainers,
    integrationId,
    handleSubmit,
    hideModal,
    submitting
  } = props;
  const { integration, ...queryRest } = useIntegrationQuery({ integrationId });
  const [settingsForm, setSettingsForm] = useState(false);
  const [successState, setSuccessState] = useState();
  const [missingSettingsWarning, setMessingSettingsWarning] = useState("");
  const printSettingsValues = useRef();
  const labelSettingsValues = useRef();

  const params = useMemo(() => {
    const itemsToMap = containerArrays || aliquotContainers;
    const items = itemsToMap.map(c => ({
      id: c.id,
      name: c.name,
      barcode: c.barcode?.barcodeString
    }));
    return {
      itemType: containerArrays ? "plate" : "tube",
      itemsToPrint: items
    };
  }, [containerArrays, aliquotContainers]);

  const triggerLabelSettings = useCallback(async () => {
    const curValues = getCurrentValues();
    printSettingsValues.current = curValues;

    const labelSettingsEndpoint = getSettingsEndpoint(integration);
    let data;
    if (labelSettingsEndpoint) {
      const res = await window.triggerIntegrationRequest({
        method: methodLabelSettings,
        endpointId: labelSettingsEndpoint.id,
        params: {
          ...params,
          ...curValues
        }
      });
      data = res.data;

      const { labelFormats = [] } = data;
      const labelData = {};
      let warningMessages = "";

      if (labelFormats.length) {
        const { filteredItems, warningMessage } =
          await filterValid(labelFormats);
        labelData.labelFormats = filteredItems;
        warningMessages += warningMessage;
      }
      if (warningMessages[0] === "\n") {
        warningMessages = warningMessages.slice(1);
      }
      setMessingSettingsWarning(warningMessages);
      setSettingsForm({
        ...labelData,
        endpointTypeCode: labelSettingsEndpoint.endpointTypeCode,
        endpointId: labelSettingsEndpoint.id
      });
    } else {
      setSettingsForm({
        endpointTypeCode: "CUSTOM_LABEL__LABEL_SETTINGS_PRESET"
      });
    }
  }, [integration, params]);

  useEffect(() => {
    (async () => {
      if (!integration) return;
      setSettingsForm(null);
      try {
        const printSettingsEndpoint = getPrintSettingsEndpoint(integration);

        if (printSettingsEndpoint) {
          const res = await window.triggerIntegrationRequest({
            method: methodPrintSettings,
            endpointId: printSettingsEndpoint.id,
            params
          });
          setSettingsForm({
            ...res.data,
            endpointTypeCode: printSettingsEndpoint.endpointTypeCode,
            endpointId: printSettingsEndpoint.id
          });
        } else {
          await triggerLabelSettings();
        }
      } catch (error) {
        window.toastr.error(
          "There was an error getting the form input endpoint response. See console for more details"
        );
        console.error(
          `23r82h Error - There was an error getting the form inputs. The integration endpoint has not been set up properly. Contact an admin for help. Error details:`,
          error
        );
        setSettingsForm({
          error: "Error with form response"
        });
      }
    })();
  }, [integration, params, triggerLabelSettings]);

  if (useTgQuery.checkErrAndLoad(queryRest))
    return useTgQuery.handleErrAndLoad({ ...queryRest, inDialog: true });

  let inner;
  if (successState) {
    inner = (
      <div>
        <Callout intent={successState.success ? "success" : "danger"}>
          {successState.success
            ? "Labels were printed successfully."
            : "There was an error printing labels. Make sure all steps in your integration are working as expected."}
        </Callout>
      </div>
    );
  } else if (settingsForm) {
    if (settingsForm.error) {
      inner = settingsForm.error;
    } else if (
      settingsForm.endpointTypeCode === "CUSTOM_LABEL__LABEL_SETTINGS_PRESET"
    ) {
      inner = <LabelSettingsForm endpointData={settingsForm} params={params} />;
    } else if (settingsForm.labelTemplatePrompts) {
      inner = (
        <div>
          <h6>Prompted Values</h6>
          {settingsForm.labelTemplatePrompts.map(p => (
            <InputField
              key={p.path}
              label={p.name}
              name={`promptedValues.${p.path}`}
            />
          ))}
        </div>
      );
    } else {
      inner = getFormInputsUi(settingsForm.formInputs, {
        endpointId: settingsForm.endpointId,
        formName: settingsForm.endpointTypeCode
      });
    }
  } else {
    inner = <Loading inDialog />;
  }
  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        {inner}
        {missingSettingsWarning && (
          <Callout
            intent="warning"
            className="preserve-newline"
            style={{ marginTop: 10 }}
          >
            There were problems with the label settings returned from the
            integration:
            <br />
            <br />
            {missingSettingsWarning}
            <br />
            <br />
            <Link to="/settings/label-media">Label Media Settings</Link>
            <br />
            <Link to="/settings/label-format">Label Format Settings</Link>
          </Callout>
        )}
      </div>
      {!successState && (
        <DialogFooter
          disabled={settingsForm?.error}
          text={
            settingsForm?.endpointTypeCode ===
            "CUSTOM_LABEL__PRINT_SETTINGS_FORM"
              ? "Next"
              : undefined
          }
          hideModal={hideModal}
          submitting={submitting}
          onClick={handleSubmit(async () => {
            async function triggerPrint() {
              try {
                const curValues = getCurrentValues();
                const promptedValues = curValues.promptedValues;
                const labelMediaId = get(
                  labelSettingsValues.current,
                  `labelFormat.labelFormatLabelMedias[0].labelMedia.id`
                );
                if (!labelMediaId) {
                  window.toastr.error(
                    "Label Format does not have a Label Media associated with it."
                  );
                  throw new Error(
                    "Label Format does not have a Label Media associated with it."
                  );
                }
                const { data } = await window.serverApi.request({
                  method: "POST",
                  url: "/createZpl",
                  data: {
                    asString: true,
                    isContainerArray: params.itemType === "plate",
                    itemIds: params.itemsToPrint.map(item => item.id),
                    labelFormatId: labelSettingsValues.current.labelFormat.id,
                    promptedValues
                  }
                });
                const _fullLabelMedia = await safeQuery(labelMediaFragment, {
                  variables: {
                    id: labelMediaId
                  }
                });
                const fullLabelMedia = { ..._fullLabelMedia };
                delete fullLabelMedia.isDotUnit;
                const labelContents = data;
                const printEndpoint = getPrintEndpoint(integration);
                const res = await window.triggerIntegrationRequest({
                  method: methodPrint,
                  endpointId: printEndpoint.id,
                  data: {
                    // we want all values from both forms to be available to the print endpoint
                    ...params,
                    ...printSettingsValues.current,
                    ...labelSettingsValues.current,
                    labelMedia: fullLabelMedia,
                    labelContents,
                    promptedValues
                  }
                });
                const success = res.data.success;
                setSuccessState({
                  success
                });
              } catch (error) {
                console.error(`error:`, error);
                window.toastr.error("Error calling print endpoint");
              }
            }
            if (
              settingsForm.endpointTypeCode ===
              "CUSTOM_LABEL__PRINT_SETTINGS_FORM"
            ) {
              try {
                await triggerLabelSettings();
              } catch (error) {
                console.error(`error:`, error);
                console.error(
                  `23r82h Error - There was an error getting label settings form inputs. The integration endpoint has not been set up properly. Contact an admin for help. Error details:`,
                  error
                );
              }
            } else if (
              settingsForm.endpointTypeCode ===
              "CUSTOM_LABEL__LABEL_SETTINGS_PRESET"
            ) {
              const { labelFormats: endpointLabelFormats = [] } =
                settingsForm || {};
              const { integrationLabelFormat, labelFormat } =
                getCurrentValues();
              let chosenLabelFormat;
              if (endpointLabelFormats.length) {
                chosenLabelFormat = integrationLabelFormat;
              } else {
                chosenLabelFormat = labelFormat;
              }

              const labelFormatFull = await safeQuery(labelFormatFragment, {
                variables: { id: chosenLabelFormat.id }
              });
              if (!labelFormatFull) {
                return window.toastr.error("Invalid label format.");
              }

              if (
                !get(labelFormatFull, `labelFormatLabelMedias[0].labelMedia.id`)
              ) {
                return window.toastr.error(
                  "Label Format does not have a Label Media associated with it."
                );
              }

              labelSettingsValues.current = {
                labelFormat: labelFormatFull
              };

              if (labelFormatFull.labelTemplatePrompts.length) {
                setSettingsForm({
                  labelTemplatePrompts: labelFormatFull.labelTemplatePrompts
                });
              } else {
                await triggerPrint();
              }
            } else if (settingsForm.labelTemplatePrompts) {
              await triggerPrint();
            }
          })}
        />
      )}
    </React.Fragment>
  );
}

export default compose(
  wrapDialog({
    title: "Print Custom Label"
  }),
  withQuery(["containerArray", "id name barcode { id barcodeString }"], {
    isPlural: true,
    showLoading: true,
    inDialog: true,
    skip: props => props.records[0].__typename !== "containerArray",
    options: props => {
      return {
        variables: {
          filter: {
            id: props.records.map(r => r.id)
          }
        }
      };
    }
  }),
  withQuery(["aliquotContainer", "id name barcode { id barcodeString }"], {
    isPlural: true,
    showLoading: true,
    inDialog: true,
    skip: props => props.records[0].__typename !== "aliquotContainer",
    options: props => {
      return {
        variables: {
          filter: {
            id: props.records.map(r => r.id)
          }
        }
      };
    }
  }),
  reduxForm({
    form
  })
)(PrintCustomExternalLabel);
