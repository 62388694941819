/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  NumericInputField,
  InputField,
  DateInputField,
  ReactSelectField,
  SelectField,
  RadioGroupField
} from "@teselagen/ui";

import {
  arrayToItemValuedOptions,
  arrayToIdOrCodeValuedOptions
} from "../../utils/formUtils";
import GenericSelect from "../../GenericSelect";
import modelNameToReadableName from "../../utils/modelNameToReadableName";

import "./style.css";
import pluralize from "pluralize";
import { capitalize } from "lodash";
import { FormName } from "redux-form";
import { useSelector } from "react-redux";

const BooleanField = ({ form, ...props }) => {
  const defaultValue = useSelector(state => {
    const value = state.form[form]?.initial?.[props.name];
    return value === undefined ? true : value;
  });
  return (
    <RadioGroupField
      {...props}
      inline
      options={[
        { label: "True", value: true },
        { label: "False", value: false }
      ]}
      defaultValue={defaultValue}
    />
  );
};

/**
 * Helper map for value extended properties.
 */
const extendedTypeCodeToField = {
  boolean: BooleanField,
  number: NumericInputField,
  worklistCounter: NumericInputField,
  string: InputField,
  timestamp: DateInputField
};

/**
 * Given an extended property, render the fields we need to edit a value for that extended property.
 */
const EditExtendedPropertyValue = ({
  fullValueFields,
  extendedProperty,
  extendedProperty: {
    extendedTypeCode,
    extendedCategoryClass = {},
    extendedMeasurementUnitClass = {},
    extendedPropertyClassCode
  }
}) => {
  const arrayTransform = useMemo(() => {
    if (fullValueFields) {
      return arrayToItemValuedOptions;
    } else {
      return arrayToIdOrCodeValuedOptions;
    }
  }, [fullValueFields]);

  /**
   * Render the fields we need if the extended property is a value property.
   */
  const renderValueFields = () => {
    const Field = extendedTypeCodeToField[extendedTypeCode];
    if (!Field)
      throw new Error(`Invalid extendedTypeCode ${extendedTypeCode}.`);

    return (
      <FormName>
        {({ form }) => <Field name="value" label="Value" form={form} />}
      </FormName>
    );
  };

  const maybeAddId = name => (fullValueFields ? name : name + "Id");

  /**
   * Render the fields we need if the extended property is a category property.
   */
  const renderCategoryFields = () => {
    const { extendedCategories } = extendedCategoryClass;
    return (
      <ReactSelectField
        name={maybeAddId("extendedCategory")}
        label="Value"
        options={arrayTransform(extendedCategories)}
      />
    );
  };

  /**
   * Render the fields we need if the extended property is a measurement property.
   */
  const renderMeasurementFields = () => {
    const { measurementUnits } = extendedMeasurementUnitClass;
    return (
      <div className="record-extended-property-measurement-fields">
        <NumericInputField
          className="record-extended-property-measurement-value"
          name="measurementValue"
          label="Value"
        />
        <SelectField
          containerStyle={{
            marginLeft: 10
          }}
          className="record-extended-property-measurement-unit"
          name={maybeAddId("measurementUnit")}
          label="Unit"
          options={arrayTransform(measurementUnits, {
            labelKey: "abbreviation"
          })}
          defaultValue={
            fullValueFields ? measurementUnits[0] : measurementUnits[0].id
          }
        />
      </div>
    );
  };

  const renderLinkFields = () => {
    const targetModel = extendedProperty.targetModel;
    return (
      <div className="record-extended-property-link-fields">
        {"Target " +
          capitalize(pluralize(modelNameToReadableName(targetModel)))}
        <GenericSelect
          name="targetEntities"
          isMultiSelect
          asReactSelect
          isRequired
          schema={[
            {
              render: (_, r) => {
                return (
                  r.name ||
                  modelNameToReadableName(r.__typename, {
                    upperCase: true
                  }) +
                    " " +
                    r.id
                );
              }
            }
          ]}
          fragment={[`${targetModel}`, "id name"]}
        />
      </div>
    );
  };

  if (extendedPropertyClassCode === "VALUE") {
    return renderValueFields();
  } else if (extendedPropertyClassCode === "CATEGORY") {
    return renderCategoryFields();
  } else if (extendedPropertyClassCode === "MEASUREMENT") {
    return renderMeasurementFields();
  } else if (extendedPropertyClassCode === "LINK") {
    return renderLinkFields();
  } else {
    throw new Error(
      `Invalid extendedPropertyClassCode ${extendedPropertyClassCode}.`
    );
  }
};

EditExtendedPropertyValue.propTypes = {
  /**
   * The extended property we wish to edit. Should have the same fields as `extendedPropertyFragment`.
   */
  extendedProperty: PropTypes.object.isRequired
};

export default EditExtendedPropertyValue;
