/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import Color from "color";
import React from "react";
import { Popover } from "@blueprintjs/core";
import classNames from "classnames";
import "./style.css";
import getAvatarIconPath from "../utils/getAvatarIconPath";
import { toLength } from "lodash";
import { CurrentUser } from "../context/CurrentUserContext";

const AvatarIcon = ({
  user,
  size = 40,
  style = {},
  noHover,
  menu,
  ...rest
}: {
  user: CurrentUser;
  size?: number;
  style?: React.CSSProperties;
  noHover?: boolean;
  menu?: React.ReactNode;
}) => {
  const { avatarFile, id, firstName = "", lastName = "" } = user || {};
  const { path } = avatarFile || {};
  const trimmedPath = getAvatarIconPath(user);
  const styleToPass = {
    width: size,
    height: size,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ...style
  };

  const avatarIcon = (
    <div
      {...rest}
      className={classNames("tg-avatar-button", {
        "has-hover": !!menu
      })}
    >
      {path ? (
        <img
          crossOrigin="anonymous"
          style={{ ...styleToPass }}
          alt="avatar"
          src={trimmedPath}
        />
      ) : (
        <div
          style={{
            ...styleToPass,
            fontSize: size / 2.7,
            backgroundColor: genColor(id),
            color: "white"
          }}
        >
          {firstName && lastName && firstName[0] + lastName[0]}
        </div>
      )}
    </div>
  );
  if (menu) {
    return (
      // @ts-ignore
      <Popover position="bottom-right" content={menu}>
        {avatarIcon}
      </Popover>
    );
  } else {
    return avatarIcon;
  }
};

export default AvatarIcon;

function genColor(seed: string | number = 1) {
  // const colors = ["#2965CC", "#29A634", "#D99E0B", "#D13913", "#8F398F", "#00B3A4", "#DB2C6F", "#9BBF30", "#96622D", "#7157D9"];
  // return colors[seed % colors.length];

  if (!Number.isInteger(seed)) {
    seed = toLength(seed);
  }

  const colorNumber = Math.floor(
    Math.abs(Math.sin(seed as number) * 16777215) % 16777215
  );
  let colorString = colorNumber.toString(16);
  // pad any colors shorter than 6 characters with leading 0s
  while (colorString.length < 6) {
    colorString = "0" + colorString;
  }
  //NOTE: SD use these "Qualitative colors" instead (from blueprint)??
  // ["#2965CC", "#29A634", "#D99E0B", "#D13913", "#8F398F", "#00B3A4", "#DB2C6F", "#9BBF30", "#96622D", "#7157D9"]
  colorString = "#" + colorString;
  let color = Color(colorString);
  if (color.isLight()) {
    color = color.negate();
  }

  return color.hsl().string();
}
