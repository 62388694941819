/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { isEmpty } from "lodash";
import { unparse } from "papaparse";
import pluralize from "pluralize";
import { download } from "../../utils/downloadTest";
import { AnchorButton, Button, ButtonGroup } from "@blueprintjs/core";
import { useDialog } from "@teselagen/ui";
import {
  modelTypeMap,
  modelTypeToModelMap
} from "../../../../tg-iso-shared/src/utils/modelTypeMap";
import { camelCase } from "lodash";
import { Link } from "react-router-dom";
import { safeQuery } from "../../apolloMethods";
import { extendedPropertyFragment } from "../../fragments/extendedPropertyFragment.gql";
import queryBuilder from "tg-client-query-builder";
import { isFunction } from "lodash";
import type { ValidateAgainstSchemaType, Field } from "./types";
import {
  DownloadTemplateFileButtonDialog,
  Props as DownloadTemplateFileButtonDialogProps
} from "./DownloadTemplateFileButtonDialog";
import { classFilter } from "./classFilter";
import { getFieldFromExtProp } from "./getFieldFromExtProp";

export const getDownloadTemplateFileHelpers = async ({
  headers: _headers = [],
  validateAgainstSchema,
  requiredHeaders = [],
  headerMessages: _headerMessages = {},
  conditionalHeaderMessages = {},
  extendedPropTypes = [], // types of extended properties that this upload accepts
  fileName,
  helper,
  fileContents,
  type,
  tableWideValidation,
  additionalFileTypes
}: {
  headers?: string[];
  validateAgainstSchema?: ValidateAgainstSchemaType;
  requiredHeaders?: string[];
  headerMessages?: any;
  conditionalHeaderMessages?: any;
  extendedPropTypes?: string[];
  fileName: string;
  helper?: string;
  fileContents?: any;
  type?: string | string[];
  tableWideValidation?: any;
  additionalFileTypes?: any[];
}) => {
  let headerMessages = _headerMessages;
  let headers = _headers.length ? _headers : Object.keys(headerMessages);
  let helperToUse = helper;
  const fields = [...(validateAgainstSchema?.fields || [])];
  const exampleDownloadFields: Field[] = [];
  if (extendedPropTypes.length) {
    headerMessages = { ...headerMessages };
    const extHeaders: string[] = [];
    if (validateAgainstSchema) {
      const qb = new queryBuilder("extendedProperty");
      const extendedProps = (await safeQuery(extendedPropertyFragment, {
        isPlural: true,
        variables: {
          pageSize: 1000,
          filter: classFilter(qb, {
            modelTypeCode: extendedPropTypes.map(
              t => modelTypeMap[camelCase(t) as keyof typeof modelTypeMap] || ""
            )
          }).toJSON()
        }
      })) as {
        name: string;
        modelTypeCode: keyof typeof modelTypeToModelMap;
        extendedPropertyClassCode:
          | "CATEGORY"
          | "MEASUREMENT"
          | "BOOLEAN"
          | "NUMBER";
        extendedTypeCode: "string" | "boolean" | "number";
        extendedCategoryClass?: {
          extendedCategories: Array<{ name: string }>;
        };
      }[];
      extendedProps.forEach(p => {
        const newField = getFieldFromExtProp(p);
        extHeaders.push(newField.path);
        const paths = fields.map(f => f.path);

        if (!paths.includes(newField.path)) {
          exampleDownloadFields.push(newField);
        }
      });
    } else {
      extendedPropTypes.forEach(pType => {
        const header = `ext-${pType}-`;
        extHeaders.push(header);
        headerMessages[header] =
          `This upload accepts extended properties for the ${pluralize(
            pType
          )}. Add a property name to this header (ex. '${header}Date of Purchase') and values to the rows. To add additional ${type} properties just duplicate the column header.`;
      });
      headers = [...headers, ...extHeaders];
      const helperMessage = validateAgainstSchema
        ? ""
        : `Please download the template file for help with adding extended properties. All extended properties are set in 'Settings > Extended Properties' before this upload.`;
      helperToUse = helper ? helper + "\n" + helperMessage : helperMessage;
    }

    headers = [...headers, ...extHeaders];
  }

  const toRet = {
    ...(type
      ? { type }
      : {
          type: [".csv", ".xlsx"] //by default allow csv and xlsx
        }),
    callout: helperToUse || undefined,
    description: helperToUse || undefined,
    ...(validateAgainstSchema
      ? {
          validateAgainstSchema: {
            exampleDownloadFields,
            ...(extendedPropTypes?.length && {
              coerceUserSchema: ({
                userSchema,
                officialSchema
              }: {
                userSchema: { fields: { path: string }[] };
                officialSchema: {
                  fields: {
                    path: string;
                    displayName: string;
                    hasMatch: boolean;
                    matches: { item: { displayName: string; path: string } }[];
                  }[];
                };
              }) => {
                //first remove any extended properties that may have been added to the officialSchema from a previous upload
                officialSchema.fields = officialSchema.fields.filter(
                  ({ path }) => {
                    return !path.startsWith("ext-");
                  }
                );
                //if userSchema comes in with extended properties, we need to add them to the officialSchema
                userSchema.fields.forEach(f => {
                  if (
                    f.path.startsWith("ext-") &&
                    !officialSchema.fields.some(({ path }) => {
                      return path === f.path;
                    })
                  ) {
                    officialSchema.fields.push({
                      path: f.path,
                      displayName: f.path,
                      hasMatch: true,
                      matches: [
                        {
                          item: {
                            displayName: f.path,
                            path: f.path
                          }
                        }
                      ]
                    });
                  }
                });
              },
              HeaderComp: ({
                validateAgainstSchema
                // props from TRC upload component
              }: {
                validateAgainstSchema: ValidateAgainstSchemaType;
              }) => {
                const { showDialogPromise, Comp } =
                  useDialog<DownloadTemplateFileButtonDialogProps>({
                    ModalComponent: DownloadTemplateFileButtonDialog
                  });
                return (
                  <>
                    <Comp
                      extendedPropTypes={extendedPropTypes}
                      validateAgainstSchema={validateAgainstSchema}
                    />

                    <ButtonGroup style={{ marginBottom: 5 }}>
                      {validateAgainstSchema.additionalHeaderBtn?.({
                        validateAgainstSchema
                      })}
                      <Button
                        icon="add"
                        intent="primary"
                        onClick={async () => {
                          showDialogPromise();
                        }}
                      >
                        Add Extended Property Column
                      </Button>
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        to="/settings/extended-properties"
                      >
                        <AnchorButton
                          data-tip="Extended Properties Settings"
                          icon="cog"
                        />
                      </Link>
                    </ButtonGroup>
                  </>
                );
              }
            }),
            ...tableWideValidation,
            ...validateAgainstSchema,
            ...(fileName && { fileName }),
            fields
          }
        }
      : {
          exampleFile: () => {
            let contents;
            if (fileContents) {
              if (isFunction(fileContents)) {
                contents = fileContents(headers, headerMessages);
              } else {
                contents = fileContents;
              }
            } else {
              const csvData: {
                fields: string[];
                data: any[];
              } = {
                fields: headers,
                data: []
              };

              if (
                requiredHeaders.length ||
                !isEmpty(conditionalHeaderMessages) ||
                !isEmpty(headerMessages)
              ) {
                const requiredRow = Array(headers.length);
                requiredHeaders.forEach(header => {
                  requiredRow[headers.indexOf(header)] = "required";
                });
                Object.keys(conditionalHeaderMessages).forEach(header => {
                  requiredRow[headers.indexOf(header)] =
                    conditionalHeaderMessages[header];
                });
                Object.keys(headerMessages).forEach(header => {
                  requiredRow[headers.indexOf(header)] = headerMessages[header];
                });
                csvData.data.push(requiredRow);
              }
              const csvString = unparse(csvData);
              contents = csvString;
            }
            download(
              contents,
              fileName.includes(".") ? fileName : fileName + ".csv",
              "text/plain"
            );
          }
        })
  };
  if (additionalFileTypes) {
    return [toRet, ...additionalFileTypes];
  }
  return toRet;
};
