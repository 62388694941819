/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { MenuItem } from "@blueprintjs/core";
import { showDialog } from "../../GlobalDialog";

const TagMenuItem = ({ records, refetch, afterTag }) => {
  const onClick = () => {
    showDialog({
      modalType: "TAG_ITEM",
      modalProps: {
        records,
        afterTag,
        refetch
      }
    });
  };

  return <MenuItem onClick={onClick} icon="tag" text="Tag" />;
};

export default TagMenuItem;
