/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { trim, get } from "lodash";
import {
  TextareaField,
  DialogFooter,
  DataTable,
  wrapDialog,
  throwFormError
} from "@teselagen/ui";

import appGlobals from "../../../../src-shared/appGlobals";
import { safeQuery } from "../../../../src-shared/apolloMethods";
import { COMMON_LAB_ID } from "@teselagen/auth-utils";

const barcodeFragment = [
  "barcode",
  "id barcodeString aliquotContainer { id labId assignedPosition { id } } containerArray { id labId assignedPosition { id } }"
];

class AddItemsByBarcodeDialog extends Component {
  state = {
    missingBarcodes: []
  };

  onSubmit = async ({ barcodes }) => {
    const {
      onSubmit,
      hideModal
      // Deprecating these fields because the logic is too complex
      // to have these passed in
      // tubeFragment = defaultTubeFragment,
      // plateFragment = defaultPlateFragment
    } = this.props;
    const barcodeArray = barcodes
      .split(/\n|\t/g)
      .map(trim)
      .filter(b => b);
    if (!barcodeArray.length) {
      throwFormError({
        barcodes: "Please enter proper barcodes."
      });
    }
    try {
      //// There's a bug with the query builder. We have to filter server side
      // const qb = new QueryBuilder("barcode");

      // let filter = qb
      //   .whereAny({
      //     "containerArray.labId": [null, 2]
      //   })
      //   .orWhereAny({
      //     "aliquotContainer.labId": [null, 2]
      //   })
      //   .andWhereAll({
      //     barcodeString: qb.inList(barcodeArray)
      //   })
      //   .toJSON();

      const results = await safeQuery(barcodeFragment, {
        variables: {
          // filter
          filter: {
            barcodeString: barcodeArray
          }
        }
      });

      let barcodedItems = [];

      let userLabIds = [];

      if (appGlobals.currentUser && appGlobals.currentUser.labRoles.length) {
        userLabIds = appGlobals.currentUser.labRoles.map(l => l.labId);
      }

      // Filter to only have items that are part of a lab that
      // the user is a member of
      if (results.length) {
        barcodedItems = results.reduce((acc, val) => {
          let labId = -1;
          if (val.aliquotContainer) {
            labId = val.aliquotContainer.labId;
          } else if (val.containerArray) {
            labId = val.containerArray.labId;
          }
          if (labId === COMMON_LAB_ID) {
            acc.push(val);
          }
          if (userLabIds.indexOf(labId) > -1) {
            acc.push(val);
          }
          return acc;
        }, []);
      }

      if (!barcodedItems.length) {
        return window.toastr.error("No items found with these barcodes");
      }

      const allItems = barcodedItems.map(bi => {
        const { id, barcodeString, containerArray, aliquotContainer } = bi;

        if (containerArray) {
          return {
            ...containerArray,
            barcode: {
              id,
              barcodeString
            }
          };
        } else if (aliquotContainer) {
          return {
            ...aliquotContainer,
            barcode: {
              id,
              barcodeString
            }
          };
        } else {
          console.error(`Unknown barcoded item!`, bi);
          window.toastr.error(`Unknown barcoded item!`);
          throw new Error(`Unknown barcoded item!`);
        }
      });

      await onSubmit(allItems);
      const foundBarcodes = [];
      allItems.forEach(item => {
        const barcode = get(item, "barcode.barcodeString");
        barcode && foundBarcodes.push(barcode);
      });

      const missingBarcodes = barcodeArray.filter(
        barcode => !foundBarcodes.includes(barcode)
      );
      if (missingBarcodes.length) {
        this.setState({
          missingBarcodes
        });
      } else {
        hideModal();
      }
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error finding items");
    }
  };

  render() {
    const { missingBarcodes } = this.state;
    const { handleSubmit, submitting, hideModal } = this.props;
    if (missingBarcodes.length) {
      return (
        <>
          <div className={Classes.DIALOG_BODY}>
            <h6>No items were found with these barcodes:</h6>
            <DataTable
              formName="missingBarcodesInAddItemsByBarcode"
              hideColumnHeader
              isSimple
              schema={["barcode"]}
              entities={missingBarcodes.map((barcode, i) => {
                return {
                  id: i,
                  barcode
                };
              })}
            />
          </div>
          <DialogFooter
            submitting={submitting}
            onClick={hideModal}
            text="OK"
            noCancel
            hideModal={hideModal}
          />
        </>
      );
    }
    return (
      <>
        <div className={Classes.DIALOG_BODY}>
          <TextareaField
            style={{
              height: 300
            }}
            isRequired
            name="barcodes"
            label="Enter Barcodes"
            secondaryLabel="(on separate lines)"
          />
        </div>
        <DialogFooter
          submitting={submitting}
          onClick={handleSubmit(this.onSubmit)}
          hideModal={hideModal}
        />
      </>
    );
  }
}

export default compose(
  wrapDialog({
    title: "Add Items By Barcodes"
  }),
  reduxForm({
    form: "addItemsByBarcode"
  })
)(AddItemsByBarcodeDialog);
