/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useContext, useEffect, useMemo } from "react";
import { isEqual } from "lodash";
import { Button } from "@blueprintjs/core";
import { DataTable, TableFormTrackerContext } from "@teselagen/ui";
import type { TableSchema, TableSchemaField } from "@teselagen/ui";
import usePrevious from "../usePrevious";
import { useTgQuery } from "../apolloMethods";
import { DocumentNode } from "graphql";

type Schema =
  | {
      model: string;
      fields: TableSchemaField[];
    }
  | TableSchemaField[];

type Props = {
  additionalDataFragment: DocumentNode | [string, string];
  idAs: string;
};

type InjectedProps = {
  withSelectedTitle: boolean;
  buttonProps?: { disabled: boolean };
  changeGenericSelectValue: any;
  clearTempValue: any;
  genericSelectValue: any;
  isMultiSelect: boolean;
  initialEntities: any;
  loading: boolean;
  noRemoveButton: boolean;
  readableName: string;
  postSelectFormName: string;
  postSelectDTProps: {
    schema: Schema;
    isSingleSelect: boolean;
    noRemoveButton?: boolean;
  };
  removeSelection: () => void;
  removeEntityFromSelection: (record: any) => void;
};

const PostSelectTable = ({
  additionalDataFragment,
  buttonProps,
  changeGenericSelectValue,
  clearTempValue,
  genericSelectValue,
  idAs,
  isMultiSelect,
  initialEntities,
  loading: receivedLoading,
  noRemoveButton,
  readableName,
  postSelectFormName,
  postSelectDTProps,
  removeSelection,
  removeEntityFromSelection,
  withSelectedTitle
}: Props & InjectedProps) => {
  const filter = useMemo(
    () => ({
      [idAs]: isMultiSelect
        ? initialEntities.map((e: { [id in string]: string }) => e[idAs])
        : initialEntities[0][idAs]
    }),
    [idAs, initialEntities, isMultiSelect]
  );

  const { entities: queryEntities = [], loading: queryLoading } = useTgQuery(
    additionalDataFragment,
    {
      isPlural: true,
      variables: {
        pageSize: initialEntities.length,
        filter
      },
      skip: !additionalDataFragment
    }
  );

  const { entities, loading } = useMemo(() => {
    if (additionalDataFragment) {
      return { entities: queryEntities, loading: queryLoading };
    }
    return { loading: receivedLoading };
  }, [additionalDataFragment, queryEntities, queryLoading, receivedLoading]);

  const prevEntities = usePrevious(entities);

  useEffect(() => {
    if (!entities) return;
    const hasValue = isMultiSelect
      ? genericSelectValue && genericSelectValue.length
      : genericSelectValue;

    const prevEntitiesEqual = isEqual(prevEntities, entities);
    if (
      (!prevEntitiesEqual || !hasValue) &&
      entities.length &&
      initialEntities.length
    ) {
      setTimeout(() => {
        // we need to clear the tempValue only after the generic select redux form value has been set, otherwise there is a race condition between tempValue and input.value
        clearTempValue();
      }, 0);
      const toSelect = isMultiSelect ? entities : entities[0];
      changeGenericSelectValue(toSelect);
    }
  }, [
    entities,
    isMultiSelect,
    genericSelectValue,
    initialEntities.length,
    changeGenericSelectValue,
    clearTempValue,
    prevEntities
  ]);

  const removeColumn = useMemo(() => {
    const removeRecord = (record: any) => {
      if (isMultiSelect) {
        removeEntityFromSelection(record);
      } else {
        removeSelection();
      }
    };

    return {
      width: 50,
      noEllipsis: true,
      immovable: true,
      type: "action",
      render: (v: any, record: any) => {
        return (
          <Button
            small
            minimal
            onClick={e => {
              e.stopPropagation();
              removeRecord(record);
            }}
            icon="trash"
            intent="danger"
          />
        );
      }
    };
  }, [isMultiSelect, removeEntityFromSelection, removeSelection]);

  const schemaToUse = useMemo(() => {
    const schemaToUse = postSelectDTProps.schema || [];
    if (
      !noRemoveButton &&
      !buttonProps?.disabled &&
      !postSelectDTProps?.noRemoveButton
    ) {
      if (Array.isArray(schemaToUse)) {
        return [...schemaToUse, removeColumn];
      } else {
        return {
          ...schemaToUse,
          fields: [...schemaToUse.fields, removeColumn]
        };
      }
    }
    return schemaToUse as TableSchema;
  }, [
    buttonProps?.disabled,
    noRemoveButton,
    postSelectDTProps?.noRemoveButton,
    postSelectDTProps?.schema,
    removeColumn
  ]);

  const { isActive } = useContext(TableFormTrackerContext);

  return (
    <div className="postSelectDataTable" style={{ paddingTop: 10 }}>
      {withSelectedTitle && <h6>Selected {readableName}:</h6>}
      <DataTable
        formName={postSelectFormName}
        doNotShowEmptyRows
        maxHeight={400}
        destroyOnUnmount={!isActive}
        keepDirtyOnReinitialize
        enableReinitialize
        isSimple
        noSelect={!postSelectDTProps.isSingleSelect}
        {...postSelectDTProps}
        schema={schemaToUse as any}
        isLoading={loading}
        entities={entities || initialEntities}
      />
    </div>
  );
};

export { PostSelectTable };
