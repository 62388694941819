/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { get } from "lodash";
import Big from "big.js";
import {
  minTransferVolume,
  noTransfersRequired
} from "../../../utils/plateUtils";

import {
  calculateConcentrationFromMolarity,
  standardizeMolarity,
  calculateMolarityFromConcentration,
  defaultConcentrationUnitCode,
  defaultMolarityUnitCode,
  standardizeCellConcentration,
  defaultCellConcentrationUnitCode
} from "../../../../../tg-iso-lims/src/utils/unitUtils";
import { getAliquotMolecularWeight } from "../../../../../tg-iso-lims/src/utils/aliquotUtils";
import {
  standardizeVolume,
  standardizeConcentration,
  standardizeMass
} from "../../../../src-shared/utils/unitUtils";
import { minBigs } from "../../../../../tg-iso-lims/src/utils/bigIntUtils";
import { isValidPositiveNumber } from "../../../../src-shared/utils/formUtils";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { getAliquotContainersToNormalize } from "./utils";
import unitGlobals from "../../../../../tg-iso-lims/src/unitGlobals";
import { camelCase } from "lodash";
import { lowerCase } from "lodash";
import { sortToLocationStrings } from "../../../../../tg-iso-lims/src/utils/plateUtils";

export default function validate(values) {
  const {
    minNumContainerArrays,
    containerArrayToNormalize,
    normalizationType,
    transferSourcePlateToNormalizedPlate,
    desiredTransferVolume,
    desiredTransferVolumetricUnitCode,
    containerArrayType,
    aliquotContainerType
  } = values;
  const errors = {};
  if (values.numberOfDiluentContainers < minNumContainerArrays) {
    errors.numberOfDiluentContainers = `Number of Diluent Containers must be greater than ${minNumContainerArrays} (not enough diluent).`;
  }

  const acsToUse = getAliquotContainersToNormalize(values);
  if (
    values.transferSourcePlateToNormalizedPlate &&
    containerArrayToNormalize &&
    isContainerArrayDry(containerArrayToNormalize)
  ) {
    errors.transferSourcePlateToNormalizedPlate =
      "Cannot transfer dry aliquots, please rehydrate plate.";
  }

  const nameOfFieldValue = camelCase("desired_" + normalizationType);
  const desiredValue = values[nameOfFieldValue];

  if (
    containerArrayToNormalize &&
    desiredValue &&
    noTransfersRequired(values)
  ) {
    errors[nameOfFieldValue] = `Aliquots already have desired ${lowerCase(
      normalizationType
    )}.`;
  }
  if (containerArrayToNormalize && normalizationType === "molarity") {
    const invalidLocations = [];
    acsToUse.forEach(ac => {
      if (ac.aliquot && !getAliquotMolecularWeight(ac.aliquot)) {
        invalidLocations.push(getAliquotContainerLocation(ac));
      }
    });
    if (invalidLocations.length) {
      errors.desiredMolarity = `The aliquots at the following locations do not have molecular weight: ${invalidLocations.join(
        ", "
      )}.`;
    }
  }

  if (!isValidPositiveNumber(desiredTransferVolume)) {
    errors.desiredTransferVolume = "Please provide a valid transfer volume.";
  }

  let hasConcentration = true;
  let hasCellConcentration = true;
  let hasMolarity = true;
  let isDry = true;
  let hasDryAliquot = false;
  const acsWithAliquots = [];
  if (containerArrayToNormalize) {
    acsToUse.forEach(ac => {
      const { aliquot } = ac;
      if (
        aliquot &&
        !aliquot.concentration &&
        !canComputeConcentration(aliquot) &&
        !aliquot.isDry
      ) {
        hasConcentration = false;
      }
      if (aliquot && !aliquot.cellConcentration) {
        hasCellConcentration = false;
      }
      if (aliquot && !aliquot.molarity) {
        hasMolarity = false;
      }
      if (aliquot?.isDry) {
        hasDryAliquot = true;
      }
      if (aliquot && !aliquot.isDry) {
        isDry = false;
      }
      if (aliquot) {
        acsWithAliquots.push(ac);
      }
    });
    const isEmptyPlate = acsToUse.every(ac => ac.aliquot === null);
    if (isEmptyPlate)
      errors.containerArrays =
        "Please select a plate that contains an aliquot.";
  }
  if (!hasMolarity && !hasConcentration && !hasCellConcentration) {
    errors.containerArrays =
      "Aliquots are missing concentration data, nothing to normalize.";
  }
  if (hasDryAliquot) {
    errors.desiredCellConcentration =
      "Cannot normalize cell concentration on dry aliquots.";
  }

  if (transferSourcePlateToNormalizedPlate) {
    if (containerArrayType) {
      const canValidate = containerArrayType.isPlate
        ? true
        : aliquotContainerType;
      if (canValidate) {
        let maxVolume, volumetricUnitCode, errorMsg;
        if (containerArrayType.isPlate) {
          maxVolume = containerArrayType.aliquotContainerType.maxVolume;
          volumetricUnitCode =
            containerArrayType.aliquotContainerType.volumetricUnitCode;
          errorMsg =
            "Transfer volume exceeds max well volume of selected destination plate type.";
        } else {
          maxVolume = aliquotContainerType.maxVolume;
          volumetricUnitCode = aliquotContainerType.volumetricUnitCode;
          errorMsg =
            "Transfer volume exceeds max well volume of selected tube type.";
        }
        if (
          !errors.desiredTransferVolume &&
          standardizeVolume(maxVolume, volumetricUnitCode) <
            standardizeVolume(
              desiredTransferVolume,
              desiredTransferVolumetricUnitCode
            )
        ) {
          errors.desiredTransferVolume = errorMsg;
        }
      }

      if (containerArrayType) {
        const sourceType = containerArrayToNormalize.containerArrayType;
        const destType = containerArrayType;
        if (sourceType.containerFormat.code !== destType.containerFormat.code) {
          // if they are transferring to a different format we need to make sure there is enough space
          if (
            sourceType.containerFormat.quadrantSize >
            destType.containerFormat.quadrantSize
          ) {
            if (
              acsWithAliquots.length > destType.containerFormat.quadrantSize
            ) {
              errors.containerArrayType =
                "This plate type does not have enough space for all transfers.";
            }
          }
        }
      }
    }
  }

  const hasError = errors[camelCase("desired_" + normalizationType)];
  if (!errors.normalizationType) {
    if (normalizationType) {
      const getIsMissingMolecularWeight = () =>
        acsToUse.some(container => {
          const { aliquot } = container;
          if (!aliquot || aliquot.isDry) return false;
          return !getAliquotMolecularWeight(aliquot);
        });
      const hasConcentrationAndMolecularWeight =
        hasConcentration && !getIsMissingMolecularWeight();

      const cantNormalizeMolarity =
        !hasMolarity &&
        normalizationType === "molarity" &&
        !hasConcentrationAndMolecularWeight;
      if (
        (!hasCellConcentration && normalizationType === "cellConcentration") ||
        (!hasConcentration && normalizationType === "concentration") ||
        cantNormalizeMolarity
      ) {
        errors.normalizationType = `Please select a plate with a defined aliquot ${lowerCase(
          normalizationType
        )}.`;
      } else if (!hasError && !isDry) {
        const errorForMolarityOrConcentration =
          maxStandardizedDesiredConcentration(values);
        errors.desiredConcentration = errorForMolarityOrConcentration;
        errors.desiredMolarity = errorForMolarityOrConcentration;
        errors.desiredCellConcentration = errorForMolarityOrConcentration;
      }
    }
  }

  const validSoFar =
    !hasError &&
    desiredValue &&
    containerArrayToNormalize &&
    !errors.normalizationType;

  if (validSoFar) {
    if (!isValidPositiveNumber(desiredValue)) {
      errors[nameOfFieldValue] = "Please enter a positive number.";
    }
    if (!errors[nameOfFieldValue]) {
      const invalidWellVolumes = exceedsMaxWellVolume(values);

      if (invalidWellVolumes.length > 0) {
        errors[nameOfFieldValue] =
          `The diluent volume required to reach desired ${lowerCase(
            normalizationType
          )} exceeds max well volume at the following locations: ${invalidWellVolumes.join(
            ", "
          )}.`;
      }
    }
  }
  const minTransferError =
    !errors.desiredTransferVolume &&
    minTransferVolume({
      containerArray: containerArrayToNormalize,
      desiredTransferVolume,
      desiredTransferVolumetricUnitCode
    });
  let deadVolumeError;
  if (!minTransferError) {
    // make sure that this would not cause the source plates to go under its dead volume
    if (containerArrayToNormalize && desiredTransferVolume) {
      const standardizedDesiredTransferVolume = standardizeVolume(
        desiredTransferVolume,
        desiredTransferVolumetricUnitCode
      );
      const deadVolumeLocations = [];
      acsToUse.forEach(ac => {
        const deadVolume = get(ac, "aliquotContainerType.deadVolume");
        if (ac.aliquot && !ac.aliquot.isDry && deadVolume) {
          const standardDeadVolume = standardizeVolume(
            deadVolume,
            ac.aliquotContainerType.deadVolumetricUnitCode || "uL"
          );
          const standardAliquotVolume = standardizeVolume(
            ac.aliquot.volume || 0,
            ac.aliquot.volumetricUnitCode || "uL"
          );
          if (
            standardAliquotVolume - standardizedDesiredTransferVolume <
            standardDeadVolume
          ) {
            deadVolumeLocations.push(
              getAliquotContainerLocation({
                ...ac,
                containerArray: containerArrayToNormalize
              })
            );
          }
        }
      });
      if (deadVolumeLocations.length) {
        const isPlate = get(
          containerArrayToNormalize,
          "containerArrayType.isPlate"
        );
        deadVolumeError = `Aliquots at the following locations would go below the ${
          isPlate ? "well's" : "tube's"
        } dead volume: ${deadVolumeLocations.join(", ")}.`;
      }
    }
  }
  errors.desiredTransferVolume =
    errors.desiredTransferVolume || minTransferError || deadVolumeError;
  return errors;
}

function canComputeConcentration(aliquot) {
  return aliquot.molarity && getAliquotMolecularWeight(aliquot);
}

const exceedsMaxWellVolume = values => {
  const {
    containerArrayToNormalize,
    desiredConcentration,
    desiredConcentrationUnitCode,
    desiredMolarity,
    desiredMolarityUnitCode,
    desiredCellConcentration,
    desiredCellConcentrationUnitCode,
    normalizationType,
    containerArrayType,
    aliquotContainerType,
    transferSourcePlateToNormalizedPlate,
    desiredTransferVolume,
    desiredTransferVolumetricUnitCode
  } = values;

  const acsToUse = getAliquotContainersToNormalize(values);

  const invalidWells = [];
  let standardizedMaxWellVolume;

  if (transferSourcePlateToNormalizedPlate) {
    if (
      !isValidPositiveNumber(desiredTransferVolume) ||
      !desiredTransferVolumetricUnitCode ||
      !containerArrayType ||
      (!containerArrayType.isPlate && !aliquotContainerType)
    ) {
      return false;
    }
  }

  // if we are transferring to a new plate before normalization, the max volume is taken from the selected destination plate type
  if (transferSourcePlateToNormalizedPlate) {
    if (containerArrayType.isPlate) {
      // if the selected type is a plate, we access the max volume from the plate type's aliquot container type
      standardizedMaxWellVolume = standardizeVolume(
        containerArrayType.aliquotContainerType.maxVolume,
        containerArrayType.aliquotContainerType.volumetricUnitCode
      );
      // if the selected type is a rack/box, we access the max volume from the selected aliquot container type
    } else {
      standardizedMaxWellVolume = standardizeVolume(
        aliquotContainerType.maxVolume,
        aliquotContainerType.volumetricUnitCode
      );
    }
    // if we are normalizing the source plate, the max volume is taken from the source plate's aliquot container type
  } else {
    if (containerArrayToNormalize.containerArrayType.isPlate) {
      standardizedMaxWellVolume = standardizeVolume(
        containerArrayToNormalize.containerArrayType.aliquotContainerType
          .maxVolume,
        containerArrayToNormalize.containerArrayType.aliquotContainerType
          .volumetricUnitCode
      );
      // if the source plate is a rack, the max volume is the max volume of the smallest tube on that rack
    } else {
      const tubesWithAliquots = acsToUse.filter(ac => ac.aliquot);
      let smallestTubeVolume = standardizeVolume(
        tubesWithAliquots[0].aliquotContainerType.maxVolume,
        tubesWithAliquots[0].aliquotContainerType.volumetricUnitCode
      );
      tubesWithAliquots.forEach(ac => {
        const currentTubeVolume = standardizeVolume(
          ac.aliquotContainerType.maxVolume,
          ac.aliquotContainerType.volumetricUnitCode
        );
        if (currentTubeVolume < smallestTubeVolume) {
          smallestTubeVolume = currentTubeVolume;
        }
      });
      standardizedMaxWellVolume = smallestTubeVolume;
    }
  }
  const getInitialVolume = aliquot => {
    let initialVolume, initialVolumetricUnitCode;
    if (transferSourcePlateToNormalizedPlate) {
      initialVolume = desiredTransferVolume;
      initialVolumetricUnitCode = desiredTransferVolumetricUnitCode;
    } else {
      initialVolume = aliquot.volume;
      initialVolumetricUnitCode = aliquot.volumetricUnitCode;
    }
    return {
      initialVolume,
      initialVolumetricUnitCode
    };
  };
  const willItGoOver = (ac, ratio) => {
    const { initialVolume, initialVolumetricUnitCode } = getInitialVolume(
      ac.aliquot
    );
    const standardizedExpectedVolume = standardizeVolume(
      initialVolume * ratio,
      initialVolumetricUnitCode
    );
    if (standardizedExpectedVolume > standardizedMaxWellVolume) {
      invalidWells.push({
        ...ac,
        containerArray: containerArrayToNormalize
      });
    }
  };
  if (normalizationType === "concentration") {
    const standardizedDesiredConcentration = standardizeConcentration(
      desiredConcentration,
      desiredConcentrationUnitCode
    );

    acsToUse.forEach(container => {
      const aliquot = container.aliquot;
      if (aliquot) {
        if (!aliquot.isDry) {
          let initialStdConcentration;
          if (aliquot.concentration) {
            const initialConcentrationUnitCode = aliquot.concentrationUnitCode;
            initialStdConcentration = standardizeConcentration(
              aliquot.concentration,
              initialConcentrationUnitCode
            );
          } else {
            // calculate concentration from molarity
            initialStdConcentration = calculateConcentrationFromMolarity(
              aliquot.molarity,
              aliquot.molarityUnitCode,
              getAliquotMolecularWeight(aliquot)
            );
          }
          willItGoOver(
            container,
            initialStdConcentration / standardizedDesiredConcentration
          );
        } else {
          const standardizedMass = standardizeMass(
            aliquot.mass,
            aliquot.massUnitCode
          );
          const standardizedExpectedVolume =
            standardizedMass / standardizedDesiredConcentration;
          if (standardizedExpectedVolume > standardizedMaxWellVolume) {
            invalidWells.push({
              ...container,
              containerArray: containerArrayToNormalize
            });
          }
        }
      }
    });
  } else if (normalizationType === "cellConcentration") {
    const standardizedDesiredCellConcentration = standardizeCellConcentration(
      desiredCellConcentration,
      desiredCellConcentrationUnitCode
    );

    acsToUse.forEach(container => {
      const aliquot = container.aliquot;
      if (aliquot) {
        if (!aliquot.isDry) {
          let initialStdCellConcentration;
          if (aliquot.cellConcentration) {
            const initialCellConcentrationUnitCode =
              aliquot.cellConcentrationUnitCode;
            initialStdCellConcentration = standardizeCellConcentration(
              aliquot.cellConcentration,
              initialCellConcentrationUnitCode
            );
            willItGoOver(
              container,
              initialStdCellConcentration / standardizedDesiredCellConcentration
            );
          }
        }
      }
    });
  } else if (normalizationType === "molarity") {
    const standardizedDesiredMolarity = standardizeMolarity(
      desiredMolarity,
      desiredMolarityUnitCode
    );

    acsToUse.forEach(container => {
      const aliquot = container.aliquot;
      if (aliquot) {
        if (!aliquot.isDry) {
          let initialStdMolarity;
          if (aliquot.concentration) {
            initialStdMolarity = calculateMolarityFromConcentration(
              aliquot.concentration,
              aliquot.concentrationUnitCode,
              getAliquotMolecularWeight(aliquot)
            );
          } else {
            initialStdMolarity = standardizeMolarity(
              aliquot.molarity,
              aliquot.molarityUnitCode
            );
          }
          willItGoOver(
            container,
            initialStdMolarity / standardizedDesiredMolarity
          );
        } else {
          const standardizedMass = standardizeMass(
            aliquot.mass,
            aliquot.massUnitCode
          );

          // step 1: convert desired molarity to concentration using aliquot's molecular weight
          // divide aliquot mass by converted desired concentration to get volume

          const desiredConcentrationForAliquot =
            calculateConcentrationFromMolarity(
              desiredMolarity,
              desiredMolarityUnitCode,
              getAliquotMolecularWeight(aliquot)
            );

          const standardizedExpectedVolume =
            standardizedMass / desiredConcentrationForAliquot;

          if (standardizedExpectedVolume > standardizedMaxWellVolume) {
            invalidWells.push({
              ...container,
              containerArray: containerArrayToNormalize
            });
          }
        }
      }
    });
  }
  return sortToLocationStrings(invalidWells);
};

const isContainerArrayDry = containerArray => {
  let isDry = false;
  get(containerArray, "aliquotContainers").forEach(container => {
    if (get(container, "aliquot.isDry")) {
      isDry = true;
    }
  });
  return isDry;
};

export function maxStandardizedDesiredConcentration({
  containerArrayToNormalize,
  desiredConcentration,
  desiredConcentrationUnitCode,
  desiredMolarity,
  desiredMolarityUnitCode,
  desiredCellConcentration,
  desiredCellConcentrationUnitCode,
  forWarning,
  normalizationType,
  selectedWellsForPlates
}) {
  if (!get(containerArrayToNormalize, "aliquotContainers")) return;

  const acsToUse = getAliquotContainersToNormalize({
    containerArrayToNormalize,
    selectedWellsForPlates
  });

  const allDry = acsToUse.every(ac => {
    return !ac.aliquot || ac.aliquot.isDry;
  });

  const cleanedUpAllAliquots = [];
  const molarities = [];
  const concentrations = [];
  const cellConcentrations = [];
  acsToUse.forEach(container => {
    const { aliquot } = container;
    if (!aliquot) return;

    // get molarity
    let molarity;
    if (aliquot.molarity) {
      molarity = standardizeMolarity(
        aliquot.molarity || 0,
        aliquot.molarityUnitCode || defaultMolarityUnitCode,
        true
      );
    } else {
      if (getAliquotMolecularWeight(aliquot)) {
        molarity = new Big(
          calculateMolarityFromConcentration(
            aliquot.concentration || 0,
            aliquot.concentrationUnitCode || defaultConcentrationUnitCode,
            getAliquotMolecularWeight(aliquot)
          )
        );
      }
    }
    if (molarity) molarities.push(molarity);
    let concentration;
    if (aliquot && !aliquot.isDry) {
      concentration = standardizeConcentration(
        aliquot.concentration || 0,
        aliquot.concentrationUnitCode || defaultConcentrationUnitCode,
        true
      );
    }
    if (concentration) concentrations.push(concentration);
    let cellConcentration;
    if (aliquot?.cellConcentration) {
      cellConcentration = standardizeCellConcentration(
        aliquot.cellConcentration,
        aliquot.cellConcentrationUnitCode || defaultCellConcentrationUnitCode,
        true
      );
    }
    if (cellConcentration) concentrations.push(cellConcentration);
    cleanedUpAllAliquots.push({
      id: aliquot.id,
      location: getAliquotContainerLocation(container),
      plateName: containerArrayToNormalize.name,
      concentration,
      cellConcentration,
      molarity
    });
  });

  if (normalizationType === "molarity") {
    // make sure each aliquot has molecular weight
    const missingMolecularWeight = acsToUse.some(container => {
      const { aliquot } = container;
      if (!aliquot || aliquot.isDry) return false;
      return !getAliquotMolecularWeight(aliquot);
    });
    if (missingMolecularWeight) {
      return "All materials must have molecular weight to calculate molarity.";
    }
    const minMolarity = minBigs(molarities) || 0;
    const molarityUnit = unitGlobals.molarityUnits[desiredMolarityUnitCode];
    if (!molarityUnit) return;
    if (
      desiredMolarity &&
      desiredMolarity.toString().indexOf(".") !==
        desiredMolarity.toString().lastIndexOf(".")
    )
      return "Please enter a valid number.";
    const standardizedDesiredMolarity = new Big(
      isNaN(Number(desiredMolarity)) ? 0 : desiredMolarity || 0
    ).times(molarityUnit.molesPerLiter);
    if (!standardizedDesiredMolarity) return "Please enter a value.";
    if (standardizedDesiredMolarity.lte(0))
      return "Please enter a positive number.";
    if (!allDry && standardizedDesiredMolarity.gt(minMolarity)) {
      let aliquotMessages = "";
      let allDiluted = true;
      cleanedUpAllAliquots.forEach(aliquot => {
        const { location, plateName, molarity } = aliquot;
        if (molarity && molarity.lte(standardizedDesiredMolarity)) {
          aliquotMessages += `Aliquot at position ${location} on ${plateName}.\n`;
        } else {
          allDiluted = false;
        }
      });

      if (forWarning) {
        return {
          warning: `Aliquots with a lower molarity will not be diluted:\n${aliquotMessages}`
        };
      } else if (allDiluted) {
        return "All aliquots are already at a lower molarity.";
      }
    }
  } else if (normalizationType === "concentration") {
    const minConcentration = minBigs(concentrations) || 0;
    const concentrationUnit =
      unitGlobals.concentrationUnits[desiredConcentrationUnitCode];
    if (!concentrationUnit) return;
    if (
      desiredConcentration &&
      desiredConcentration.toString().indexOf(".") !==
        desiredConcentration.toString().lastIndexOf(".")
    )
      return "Please enter a valid number.";
    const standardizedDesiredConcentration = new Big(
      isNaN(Number(desiredConcentration)) ? 0 : desiredConcentration || 0
    ).times(concentrationUnit.gramsPerLiter);
    if (!standardizedDesiredConcentration) return "Please enter a value.";
    if (standardizedDesiredConcentration.lte(0))
      return "Please enter a positive number.";
    if (!allDry && standardizedDesiredConcentration.gte(minConcentration)) {
      const dilutedMessages = [];
      const alreadyMessages = [];
      let alreadyAtConcentration = true;
      let allDiluted = true;
      let warning = "";
      cleanedUpAllAliquots.forEach(aliquot => {
        const { location, plateName, concentration } = aliquot;
        if (
          concentration &&
          concentration.eq(standardizedDesiredConcentration)
        ) {
          alreadyMessages.push(
            `Aliquot at position ${location} on ${plateName}.\n`
          );
        } else {
          alreadyAtConcentration = false;
        }
        if (
          concentration &&
          concentration.lt(standardizedDesiredConcentration)
        ) {
          dilutedMessages.push(
            `Aliquot at position ${location} on ${plateName}.\n`
          );
        } else {
          allDiluted = false;
        }
      });
      if (forWarning) {
        if (alreadyMessages.length) {
          warning += `Aliquots already at desired concentration will not be diluted:\n${alreadyMessages.join(
            " "
          )}\n`;
        }
        if (dilutedMessages.length) {
          warning += `Aliquots with a lower concentration will not be diluted:\n${dilutedMessages.join(
            " "
          )}\n`;
        }
        return { warning };
      } else if (allDiluted) {
        return "All aliquots are already at a lower concentration.";
      } else if (alreadyAtConcentration) {
        return "All aliquots are already at desired concentration";
      }
    }
  } else if (normalizationType === "cellConcentration") {
    const minCellConcentration = minBigs(cellConcentrations) || 0;
    const cellConcentrationUnit =
      unitGlobals.cellConcentrationUnits[desiredCellConcentrationUnitCode];
    if (!cellConcentrationUnit) return;
    if (
      desiredCellConcentration &&
      desiredCellConcentration.toString().indexOf(".") !==
        desiredCellConcentration.toString().lastIndexOf(".")
    )
      return "Please enter a valid number.";
    const standardizedDesiredCellConcentration = new Big(
      isNaN(Number(desiredCellConcentration))
        ? 0
        : desiredCellConcentration || 0
    ).times(cellConcentrationUnit.cellsPerLiter);
    if (!standardizedDesiredCellConcentration) return "Please enter a value.";
    if (standardizedDesiredCellConcentration.lte(0))
      return "Please enter a positive number.";
    if (
      !allDry &&
      standardizedDesiredCellConcentration.gte(minCellConcentration)
    ) {
      const dilutedMessages = [];
      const alreadyMessages = [];
      let alreadyAtConcentration = true;
      let allDiluted = true;
      let warning = "";
      cleanedUpAllAliquots.forEach(aliquot => {
        const { location, plateName, cellConcentration } = aliquot;
        if (
          cellConcentration &&
          cellConcentration.eq(standardizedDesiredCellConcentration)
        ) {
          alreadyMessages.push(
            `Aliquot at position ${location} on ${plateName}.\n`
          );
        } else {
          alreadyAtConcentration = false;
        }
        if (
          cellConcentration &&
          cellConcentration.lt(standardizedDesiredCellConcentration)
        ) {
          dilutedMessages.push(
            `Aliquot at position ${location} on ${plateName}.\n`
          );
        } else {
          allDiluted = false;
        }
      });
      if (forWarning) {
        if (alreadyMessages.length) {
          warning += `Aliquots already at desired cell concentration will not be diluted:\n${alreadyMessages.join(
            " "
          )}\n`;
        }
        if (dilutedMessages.length) {
          warning += `Aliquots with a lower cell concentration will not be diluted:\n${dilutedMessages.join(
            " "
          )}\n`;
        }
        return { warning };
      } else if (allDiluted) {
        return "All aliquots are already at a lower cell concentration.";
      } else if (alreadyAtConcentration) {
        return "All aliquots are already at desired cell concentration";
      }
    }
  }
}
