/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get } from "lodash-es";

/**
 *
 * @param {[string, string] | DocumentNode} fragment
 * @returns {string}
 */
export default fragment => {
  return Array.isArray(fragment)
    ? fragment[0]
    : get(fragment, "definitions[0].typeCondition.name.value");
};
