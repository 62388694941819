/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useMemo, useState } from "react";
import { DataTable, useTableParams } from "@teselagen/ui";
import {
  unitColumns,
  dateModifiedColumn
} from "../../../../src-shared/utils/libraryColumns";
import { addTagFilterToQuery } from "../../../../src-shared/utils/tagUtils";
import { tagColumnWithRender } from "../../../../src-shared/utils/tagColumn";
import { addActiveProjectFilter } from "../../../../src-shared/utils/projectUtils";
import { aliquotUnassignedViewFragment } from "./aliquotUnassignedViewFragment.gql";
import useTgQuery from "../../../../src-shared/apolloUseTgQuery";
import { Checkbox } from "@blueprintjs/core";

const schema = {
  model: "aliquotUnassignedView",
  fields: [
    ...unitColumns,
    { path: "aliquot.sample.name", type: "string", displayName: "Sample" },
    {
      path: "fullPath",
      displayName: "Location"
    },
    tagColumnWithRender,
    dateModifiedColumn
  ]
};

const defaults = {
  order: ["-modified"]
};

const AliquotsTable = ({ multi, onDoubleClick }) => {
  const [showAssigned, setShowAssigned] = useState(false);

  const additionalFilter = useCallback(
    (_, qb, currentParams) => {
      qb.whereAll({
        isUnassigned: !showAssigned
      });
      addTagFilterToQuery(currentParams.tags, qb, {
        pathToTaggedItems: "aliquot.taggedItems"
      });
      addActiveProjectFilter(qb, {
        pathToProjectId: "aliquot.projectItems.projectId",
        model: "aliquot",
        isUsingView: true
      });
    },
    [showAssigned]
  );

  const { tableParams: _tableParams, variables } = useTableParams({
    urlConnected: false,
    formName: "aliquotsDisplayTable",
    schema,
    defaults,
    additionalFilter
  });

  const { entities, loading, refetch } = useTgQuery(
    aliquotUnassignedViewFragment,
    { isPlural: true, variables }
  );

  const tableParams = useMemo(() => {
    if (!loading && entities && entities.length) {
      return {
        ..._tableParams,
        isLoading: loading,
        entities: entities.map(e => {
          return { ...e, ...e.aliquot };
        }),
        entityCount: entities.length,
        fragment: aliquotUnassignedViewFragment,
        onRefresh: refetch,
        variables
      };
    }
    return _tableParams;
  }, [_tableParams, entities, loading, refetch, variables]);

  const showAssignedAliquots = useCallback(() => {
    setShowAssigned(prev => {
      tableParams.setNewParams({
        ...tableParams.currentParams,
        showAssigned: !prev
      });
      return !prev;
    });
  }, [tableParams]);

  return (
    <>
      <Checkbox
        checked={showAssigned}
        label="Show Assigned Aliquots"
        onChange={showAssignedAliquots}
      />
      <DataTable
        {...tableParams}
        maxHeight={450}
        onDoubleClick={onDoubleClick}
        noPadding
        withCheckboxes={multi}
        isSingleSelect={!multi}
      />
    </>
  );
};

export default AliquotsTable;
