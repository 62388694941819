/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback } from "react";
import { get } from "lodash";
import AbstractLibrary from "../../../../src-shared/AbstractLibrary";
import libraryEnhancer from "../../../../src-shared/libraryEnhancer";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import SampleRecordView from "../../Record/SampleRecordView";
import { sampleLibraryFragment } from "./sampleLibraryFragment.gql";

const Library = props => {
  const onNewItemClick = useCallback(
    ({ history }) => {
      showDialog({
        modalType: "NEW_SAMPLE_ALIQUOT",
        modalProps: { history, refetch: props.refetch }
      });
    },
    [props.refetch]
  );

  return (
    <AbstractLibrary
      {...props}
      withExport
      RecordViewInspector={SampleRecordView}
      isLibraryTable
      model="sample"
      onNewItemClick={onNewItemClick}
    />
  );
};

const schema = {
  model: "sample",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    { path: "sampleStatus.name", type: "string", displayName: "Status" },
    { path: "sampleType.name", type: "string", displayName: "Sample Type" },
    {
      type: "string",
      displayName: "Materials",
      render: (v, r) => {
        if (r.sampleTypeCode === "FORMULATED_SAMPLE") {
          const materialNames = [];
          const materialIds = [];
          r.sampleFormulations.forEach(sf => {
            sf.materialCompositions &&
              sf.materialCompositions.forEach(mc => {
                if (!materialIds.includes(get(mc, "material.id"))) {
                  materialIds.push(get(mc, "material.id"));
                  materialNames.push(get(mc, "material.name"));
                }
              });
          });
          return materialNames.join(", ");
        } else {
          return get(r, "material.name");
        }
      }
    }
  ]
};

export default libraryEnhancer({
  schema,
  fragment: sampleLibraryFragment,
  additionalFilter: (_, qb, currentParams) => {
    if (currentParams.j5SequenceIdFilter) {
      qb.whereAll({
        "material.polynucleotideMaterialSequence.id":
          currentParams.j5SequenceIdFilter
      });
    }
  }
})(Library);
