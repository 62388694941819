/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get, find, size } from "lodash";
import { useEffect, useMemo, useState } from "react";
import React from "react";
import { Callout, Classes } from "@blueprintjs/core";
import { getFormInputsUi } from "./getFormInputsUi";
import { DialogFooter, Loading } from "@teselagen/ui";
import { useTgQuery } from "../apolloMethods";
import { cloneDeep } from "lodash";
import { modelToExportFragment } from "../../../tg-iso-shared/src/crudHandlers/modelToExportFragment-conversionFn";

export const SendRecordsPage = ({
  integration,
  records,
  submitting,
  handleSubmit,
  methodFormat,
  form,
  onSend,
  subtype,
  endpointTypeCode: _endpointTypeCode
}) => {
  const [loading, setLoading] = useState(true);
  // const [formFields, setFormFields] = useState(true);
  const [pages, setPages] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [formInputUI, setFormInputUI] = useState([]);

  const {
    convertDbModelToUserFacing,
    // A custom sort for the entities queried.
    // TODO: It could actually be any pre-processing needed
    //   that cant be done by the conversion function, like sorting,
    //   deleting/adding array elements, etc.
    postQueryFn,
    intermediateUpdate,
    fragment,
    queryOptions
  } = useMemo(
    () =>
      modelToExportFragment({
        subtype
      }),
    [subtype]
  );

  const {
    entities: _entities,
    error,
    ...rest
  } = useTgQuery(fragment, {
    variables: {
      pageSize: records.length,
      filter: {
        id: records.map(({ id }) => id)
      },
      ...queryOptions
    }
  });
  // apollo doesn't let us mutate entities which will happen in conversion function below
  const entities = useMemo(() => cloneDeep(_entities), [_entities]);
  useEffect(() => {
    async function fetchData() {
      if (useTgQuery.checkErrAndLoad(rest)) return;
      setLoading(true);
      // You can await here
      try {
        const endpoint = find(
          integration.integrationEndpoints,
          ({ endpointTypeCode }) => endpointTypeCode === _endpointTypeCode
        );
        const headers = {};
        if (endpoint) {
          const { integrationEndpointHeaders } = endpoint;
          if (integrationEndpointHeaders.length > 0) {
            integrationEndpointHeaders.forEach(h => {
              headers[h?.name] = h?.value;
            });
          }
        }

        // The format url of the Update hook is optional
        // When no format url is set use the integration name as the page title.
        let res;
        if (get(endpoint, "url")) {
          res = await window.triggerIntegrationRequest({
            endpointId: endpoint.id,
            data: {
              records: postQueryFn(entities).map(r =>
                convertDbModelToUserFacing(r)
              )
            },
            method: methodFormat,
            headers
          });
        }
        setPages(
          get(res, "data.pages") || [
            get(res, "data") || { title: integration.name }
          ]
        );
      } catch (e) {
        window.toastr.error(
          "An error occurred fetching the data. Please verify that you've correctly set up the endpoint"
        );
        console.error(e);
      } finally {
        setLoading(false);
      }
      // ...
    }
    if (size(entities)) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integration, entities, methodFormat, _endpointTypeCode]);

  useEffect(() => {
    const _formInputUI = [];
    try {
      if (pages && pages.length) {
        const page = pages[pageNumber];
        if (page.title) {
          _formInputUI.push(
            <h3 key="title">
              {page.title} ({pageNumber + 1}/{pages.length}){" "}
            </h3>
          );
        }

        if (!page.formInputs || !page.formInputs.length) {
          handleSubmit(additionalFields =>
            onSend(additionalFields, {
              convertDbModelToUserFacing,
              intermediateUpdate,
              entities: postQueryFn(entities)
            })
          )();

          _formInputUI.push(
            <div key="no-additional-info">
              <Callout style={{ marginBottom: 10 }} intent="success">
                Sending Over Record Info...
              </Callout>
            </div>
          );
        } else {
          _formInputUI.push(
            ...getFormInputsUi(page.formInputs, {
              endpointId: find(
                integration.integrationEndpoints,
                ({ endpointTypeCode }) => endpointTypeCode === _endpointTypeCode
              ).id,
              formName: form
            })
          );
        }
      }
    } catch (error) {
      console.error(`integration error:`, error);
      window.toastr.error(
        "Something went wrong trying to set up the integration"
      );
    } finally {
      setFormInputUI(_formInputUI);
    }
  }, [
    pages,
    pageNumber,
    integration,
    handleSubmit,
    onSend,
    convertDbModelToUserFacing,
    intermediateUpdate,
    postQueryFn,
    entities,
    form,
    _endpointTypeCode
  ]);

  if (useTgQuery.checkErrAndLoad(rest))
    return useTgQuery.handleErrAndLoad(rest);

  const completed = pageNumber === pages.length - 1;
  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        {loading && <Loading bounce></Loading>}
        {formInputUI}
      </div>
      <DialogFooter
        disabled={loading || rest.loading}
        onBackClick={
          pageNumber === 0 ? undefined : () => setPageNumber(pageNumber - 1)
        }
        text={completed ? "Submit" : "Next"}
        submitting={submitting}
        onClick={
          completed
            ? handleSubmit(additionalFields =>
                onSend(additionalFields, {
                  convertDbModelToUserFacing,
                  intermediateUpdate,
                  entities: postQueryFn(entities)
                })
              )
            : handleSubmit(() => {
                setPageNumber(pageNumber + 1);
              })
        }
      />
    </React.Fragment>
  );
};
