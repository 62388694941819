/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { getSortedTaggedItems } from "../../../tg-iso-shared/src/tag-utils";
import TagsRenderingWrapper from "../TagsRenderingWrapper";
import TagWithSetParams from "../TagWithSetParams";

type TableProps = { currentParams: any; setNewParams: (params: any) => void };

export default function TagTableColumn({
  record,
  paramProps: { currentParams, setNewParams }
}: {
  record: { taggedItems?: { id: string; tag: { id: string; name: string } }[] };
  paramProps: TableProps;
}) {
  const sortedTaggedItems = getSortedTaggedItems(record);

  return (
    <TagsRenderingWrapper>
      {sortedTaggedItems.map(({ id, tag, tagOption }) => (
        <TagWithSetParams
          key={id}
          tag={tag}
          tagOption={tagOption}
          currentParams={currentParams}
          setNewParams={setNewParams}
        />
      ))}
    </TagsRenderingWrapper>
  );
}
