/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { cloneDeep } from "lodash";
import SchematicEditView from "../components/SchematicEditView";
import schematicFragment from "../graphql/fragments/schematicFragment";
import recordViewEnhancer from "../../src-shared/recordViewEnhancer";
import { useMemo } from "react";

export default compose(
  recordViewEnhancer(schematicFragment),
  Component => props => {
    const {
      data: { schematic: __schematic }
    } = props;
    const schematic = useMemo(() => {
      if (!__schematic) return;
      const _schematic = cloneDeep(__schematic);
      _schematic.schematicSets.sort((a, b) => a.index - b.index);
      _schematic.schematicSets.forEach(s =>
        s.schematicElements.sort((a, b) => a.index - b.index)
      );
      return { ..._schematic };
    }, [__schematic]);
    return <Component {...props} schematic={schematic} />;
  }
)(SchematicEditView);
