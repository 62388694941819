/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { createRoot } from "react-dom/client";

export default function renderOnDoc(fn) {
  const elemDiv = document.createElement("div");
  elemDiv.style.cssText =
    "position:absolute;width:100%;height:100%;top:0px;opacity:0.3;z-index:100;";
  document.body.appendChild(elemDiv);
  const root = createRoot(elemDiv);

  const handleClose = () => {
    setTimeout(() => {
      root.unmount();
      document.body.removeChild(elemDiv);
    });
  };
  return root.render(fn(handleClose));
}
