/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { get, find, size } from "lodash";
import { isoContext } from "@teselagen/utils";
import { isHdeDesignJson } from "../utils/isHdeDesignJson";
import caseInsensitiveFilter from "../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import shortid from "shortid";
import { isBrowser } from "browser-or-node";

const DEFAULT_ASSEMBLY_METHOD = "Mock Assembly";
const DEFAULT_ASSEMBLY_METHOD_CID = "mock";

export const isSimpleDesignJson = json => "columns" in json;

// A pretty rough heuristic.
export const isOldHdeDesignJson = json =>
  [
    "cards",
    "sets",
    "icons",
    "elements",
    "operations",
    "customJ5Parameters"
  ].every(field => json[field]);

export const isDeprecatedHdeDesignJson = json =>
  !!get(json, "hdeDesignExportVersion", 0) && !isHdeDesignJson(json);

export const queryForDuplicateTags = async (
  tags = [],
  key = "id",
  ctx = isoContext
) => {
  if (!tags.length) return {};
  const tagNames = tags.map(t => t.name);
  const existingTags = await ctx.safeQuery(["tag", "id name"], {
    variables: {
      filter: { name: tagNames }
    }
  });

  const map = {};
  existingTags.forEach(t => {
    map[get(t, key)] = t.id;
  });
  return map;
};

export const getValidAssemblyMethods = async (designData, { safeQuery }) => {
  const validatedAssemblyMethods = [];

  const assemblyMethodRefs = [];

  const designsData = Array.isArray(designData) ? designData : [designData];

  /**
   * Validate assembly methods for each incoming design.
   * If no assembly method is found, the Mock Assembly method will be selected.
   */
  for (const designData of designsData) {
    if (isSimpleDesignJson(designData)) {
      designData.assembly_method =
        designData.assembly_method || DEFAULT_ASSEMBLY_METHOD;
      assemblyMethodRefs.push({ name: designData.assembly_method });
    } else if (isHdeDesignJson(designData)) {
      if (!size(designData.design.assemblyMethod)) {
        designData.design.assemblyMethod = {
          [shortid()]: { name: "Mock Assembly" }
        };
      }
      assemblyMethodRefs.push(
        ...Object.values(designData.design.assemblyMethod).map(am => ({
          name: am.name
        }))
      );
    } else if (
      Array.isArray(designData) &&
      find(designData, designEntity => designEntity.entity === "card")
    ) {
      const designCards = find(
        designData,
        designEntity => designEntity.entity === "card"
      );
      const designCardsInputs = Array.isArray(designCards.inputs)
        ? designCards.inputs
        : [designCards.inputs];
      designCardsInputs.forEach(card => {
        card.outputReaction.assemblyMethodId =
          card.outputReaction.assemblyMethodId ||
          `&${DEFAULT_ASSEMBLY_METHOD_CID}`;
        const assemblyMethodId = card.outputReaction.assemblyMethodId;
        assemblyMethodRefs.push({
          ...(assemblyMethodId.startsWith("&")
            ? { cid: assemblyMethodId }
            : { id: assemblyMethodId })
        });
      });
    } else {
      console.error(`Erroring designData:`, designData);
      throw new Error("Design format not supported");
    }
    if (!assemblyMethodRefs.length) {
      throw new Error("No assembly method found.");
    }

    for (const assemblyMethodRef of assemblyMethodRefs) {
      const filter = {
        ...(assemblyMethodRef.name &&
          caseInsensitiveFilter("assemblyMethod", "name", [
            assemblyMethodRef.name
          ])),
        ...(assemblyMethodRef.id && { id: assemblyMethodRef.id }),
        ...(assemblyMethodRef.cid && { cid: assemblyMethodRef.cid })
      };
      const [assemblyMethod] = await safeQuery(["assemblyMethod", "id name"], {
        variables: { filter }
      });

      if (!assemblyMethod) {
        throw new Error(
          `No assembly method found: ${
            assemblyMethodRef.name ||
            assemblyMethodRef.id ||
            assemblyMethodRef.cid
          }.`
        );
      }

      validatedAssemblyMethods.push(assemblyMethod);
    }
  }

  return validatedAssemblyMethods;
};

/**
 * This function patches old design exports
 */

export function patchOldDesigns(input) {
  console.info("Patching old designs");
  if (input.design && input.design.restrictionEnzyme) {
    console.info("Patching restrictionEnzyme");
    let updatedEnzymeTypeCode = false;
    Object.keys(input.design.restrictionEnzyme).forEach(key => {
      const rse = input.design.restrictionEnzyme[key];
      if (typeof rse.enzymeTypeCode === "undefined") {
        rse.enzymeTypeCode = "TYPE_IIS";
        updatedEnzymeTypeCode = true;
      }
    });
    if (updatedEnzymeTypeCode && isBrowser) {
      window.toastr.warning(
        "Legacy Design File Detected! Defaulting all enzymes to Type IIS"
      );
    }
  }
  if (input.design?.design) {
    console.info("Patching design metadata");
    Object.values(input.design.design).forEach(design => {
      delete design.isRecordLocked;
    });
  }

  return input;
}
