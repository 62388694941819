/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { withTableParams } from "@teselagen/ui";
import withQuery from "../../src-shared/withQuery";
import { generateFragmentWithFields } from "@teselagen/apollo-methods";
// import axios from 'axios'
import { compose } from "redux";
import { connect } from "react-redux";
import { withProps } from "recompose";
import actions from "../../src-shared/redux/actions";
import CreateDigestPartsFromSequenceDialog from "../components/Dialogs/CreateDigestPartsFromSequenceDialog";
import {
  designMaterialAvailabilityColumn,
  dateModifiedColumn,
  userColumns
} from "../../src-shared/utils/libraryColumns";
import { tagColumnWithRender } from "../../src-shared/utils/tagColumn";
import gql from "graphql-tag";
import { addTagFilterToQuery } from "../../src-shared/utils/tagUtils";
import { taggedItemFragment } from "../../../tg-iso-shared/src/fragments/taggedItemFragment";

const schema = {
  model: "sequence",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    { path: "description", type: "string", displayName: "Description" },
    { path: "size", type: "number", displayName: "Size" },
    {
      path: "sequenceFeatures.name",
      type: "string",
      displayName: "Features"
    },
    {
      path: "parts.name",
      type: "string",
      displayName: "Parts"
    },
    designMaterialAvailabilityColumn,
    tagColumnWithRender,
    userColumns,
    dateModifiedColumn
  ]
};

const mapDispatchToProps = {
  changeFas: actions.design.changeFas
};

const fragment = gql`
  fragment insertPartsFromSequenceDigestFragment on sequence {
    id
    name
    description
    size
    circular
    parts {
      id
      name
    }
    designMaterialAvailabilityView {
      id
      isAvailable
    }
    sequenceFeatures {
      id
      name
    }
    user {
      id
      username
      firstName
      lastName
    }
    isJ5Sequence
    isInLibrary
    lab {
      id
      name
    }
    taggedItems {
      ...taggedItemFragment
    }
    createdAt
    updatedAt
  }
  ${taggedItemFragment}
`;

const InsertPartsFromSequenceDigestDialogContainer = compose(
  connect(null, mapDispatchToProps),
  withTableParams({
    urlConnected: true,
    schema,
    formName: "sequenceLibraryForm",
    tableParams: {
      cellRenderer: {
        "sequenceFeatures.name": (items, record) => {
          return record.sequenceFeatures.map(item => item.name).join(", ");
        },
        "parts.name": (items, record) =>
          record.parts.map(item => item.name).join(", ")
      }
    },
    withDisplayOptions: true,
    additionalFilter: (_, qb, currentParams) => {
      addTagFilterToQuery(currentParams.tags, qb);
      qb.whereAny({
        isInLibrary: true
      });
    }
  }),
  withProps(({ selectedCellPaths, createElements, selectedBinIds }) => {
    return {
      selectedCellPaths,
      createElements,
      selectedBinIds
    };
  }),
  withQuery(fragment, {
    isPlural: true
  }),
  withQuery(
    generateFragmentWithFields(
      "restrictionEnzyme",
      `
      id
      name
      enzymeTypeCode
      `
    ),
    {
      isPlural: true,
      options: () => {
        return {
          variables: {
            filter: { enzymeTypeCode: "TYPE_IIS" },
            pageNumber: 1,
            pageSize: 1000000
          }
        };
      }
    }
  )
)(CreateDigestPartsFromSequenceDialog);

export default InsertPartsFromSequenceDigestDialogContainer;
