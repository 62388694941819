/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { get } from "lodash";
import {
  NumericInputField,
  SelectField,
  InputField,
  ReactSelectField,
  CheckboxField
} from "@teselagen/ui";

import GenericSelect from "../../../../src-shared/GenericSelect";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import { getPlatePrepKey } from "./utils";
import { platePrepPlateFragment } from "./fragments";
import defaultValueConstants from "../../../../../tg-iso-shared/src/defaultValueConstants";
import { arrayToItemValuedOptions } from "../../../../src-shared/utils/formUtils";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import unitGlobals from "../../../../../tg-iso-lims/src/unitGlobals";

function PrepFields({
  aliquotContainer,
  lot,
  platesToPrep,
  toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} } = {},
  addPlateFilter,
  selectedWellsForPlate,
  universalTransfers,
  isTube,
  isLast,
  renderWells,
  containerArrayTypes,
  generateBarcode
}) {
  let materialName;
  let title;
  const platePrepKey = getPlatePrepKey(lot || aliquotContainer);
  if (lot) {
    materialName = get(lot, "additiveMaterial.name");
    title = `${materialName}: ${lot.name}`;
  } else {
    materialName = get(aliquotContainer, "aliquot.sample.material.name");
    if (isTube) {
      title = materialName;
    } else {
      title = `Well ${getAliquotContainerLocation(
        aliquotContainer
      )}: ${materialName}`;
    }
  }
  const platePrepEntities = platesToPrep[platePrepKey] || [];
  return (
    <div className="tg-flex column">
      <div
        className="tg-flex justify-space-between align-flex-start"
        style={{ marginBottom: 20 }}
      >
        <div
          className="tg-flex column"
          style={{
            width: "65%",
            overflow: "auto",
            marginRight: 15
          }}
        >
          <div className="tg-flex justify-space-between align-center">
            <GenericSelect
              label={title}
              name={`platesToPrep.${platePrepKey}`}
              schema={["name", dateModifiedColumn]}
              isMultiSelect
              preserveValue
              getButtonText={v =>
                v?.length ? "Change Plates" : "Prep Existing Plates"
              }
              buttonProps={{
                disabled: isDisabledMap.containerArrays,
                loading: isLoadingMap.containerArrays
              }}
              fragment={["containerArray", "id name updatedAt"]}
              additionalDataFragment={platePrepPlateFragment}
              tableParamOptions={{
                additionalFilter: (_, qb) => {
                  addPlateFilter(qb);
                }
              }}
              postSelectDTProps={{
                style: {
                  minWidth: 600
                },
                formName: `selectedPlatesToPrep.${platePrepKey}`,
                entities: platePrepEntities,
                schema: platePrepSchema,
                selectedWellsForPlate,
                cellRenderer: {
                  wells: renderWells(platePrepKey)
                }
              }}
            />
          </div>
        </div>
        <div className="tg-flex column" style={{ width: "30%" }}>
          {!!lot && (
            <>
              <InputField
                name={`intermediateContainers.${platePrepKey}.name`}
                label="Intermediate Container Name"
                generateDefaultValue={{
                  ...defaultValueConstants.INTERMEDIATE_CONTAINER_NAME,
                  customParams: {
                    label: "Intermediate Container"
                  }
                }}
              />
              <ReactSelectField
                name={`intermediateContainers.${platePrepKey}.type`}
                label="Intermediate Container Type"
                options={arrayToItemValuedOptions(containerArrayTypes)}
                defaultValue={
                  containerArrayTypes[containerArrayTypes.length - 1]
                }
              />
              <CheckboxField
                name={`generateBarcode.${platePrepKey}`}
                style={{ marginBottom: -10 }}
                label="Generate Barcode"
                defaultValue
              />
              {!generateBarcode[platePrepKey] && (
                <InputField
                  name={`intermediateContainers.${platePrepKey}.barcode`}
                  label="Container Barcode"
                />
              )}
              <hr className="tg-section-break" />
            </>
          )}
          <div className="input-with-unit-select" style={{ marginTop: 15 }}>
            <NumericInputField
              label="Transfer Volume"
              name={`transferVolume.${platePrepKey}`}
              min={0}
              disabled={universalTransfers}
            />
            <SelectField
              name={`transferVolumetricUnitCode.${platePrepKey}`}
              defaultValue="uL"
              disabled={universalTransfers}
              label="none"
              className="tg-unit-select"
              options={unitGlobals.getOptionsForSelect("volumetricUnit")}
            />
          </div>
          <CheckboxField
            name={`prepEmptyWells.${platePrepKey}`}
            label="Prep Empty Wells?"
            defaultValue
          />
        </div>
      </div>
      {isLast ? null : <hr className="tg-section-break" />}
    </div>
  );
}

export default PrepFields;

const platePrepSchema = {
  model: "containerArray",
  fields: [
    {
      displayName: "Plate Name",
      path: "name"
    },
    {
      displayName: "Plate Barcode",
      path: "barcode.barcodeString"
    },
    {
      displayName: "Plate Type",
      path: "containerArrayType.name"
    },
    {
      displayName: "Choose Wells to Prep",
      width: 200,
      path: "wells"
    }
  ]
};
