/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { times, find } from "lodash";
import AbstractRecord from "../AbstractRecord";
import recordViewEnhancer from "../recordViewEnhancer";
import gql from "graphql-tag";

const fragment = gql`
  fragment designRecordViewFragment on design {
    id
    name
    layoutType
    numRows
    reactions: designReactions {
      id
      designId
    }
    description
    elements {
      id

      part {
        id
        name
      }
      binId
      index
    }
    bins {
      id
      direction
      icon {
        id
        name
        path
      }
    }
  }
`;

export default recordViewEnhancer(fragment)(props => {
  const { design } = props;
  const info = [
    ["Name", design.name],
    ["Description", design.description]
  ];
  return (
    <AbstractRecord {...props} recordInfo={info} recordName="design">
      <DesignPreview design={design}></DesignPreview>
    </AbstractRecord>
  );
});

function DesignPreview({ design }) {
  const { numRows, bins, elements, reactions } = design;
  if (reactions.length !== 1) return "No Preview Available";
  const elementsByBinId = {};
  elements.forEach(el => {
    if (!elementsByBinId[el.binId]) {
      elementsByBinId[el.binId] = [];
    }
    elementsByBinId[el.binId].push(el);
  });
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: 500
      }}
      className="tg-simple-design-preview"
    >
      <div
        key="bin-row"
        style={{ display: "flex", minHeight: 30, marginBottom: 10 }}
        className="tg-row-preview"
      >
        {bins.map(({ icon, direction }, i) => {
          const { path } = icon;
          const iconTransform = !direction ? "rotate(180deg)" : "";
          return (
            <div
              key={i}
              style={{
                transform: iconTransform,
                border: "1px solid #e0e3e6",
                padding: 5,
                flex: "1 1 0px",
                minWidth: 0,
                flexShrink: 1,
                overflow: "hidden",
                wordBreak: "break-all",
                maxWidth: 155,
                textOverflow: "ellipsis",
                height: 60
              }}
              className="tg-bin-preview"
            >
              <svg style={{ marginTop: -30 }} height={100} width={100}>
                <path d={path} className="icon-image"></path>
              </svg>
            </div>
          );
        })}
      </div>

      {times(numRows).map((row, rowIndex) => {
        return (
          <div
            key={rowIndex}
            style={{ display: "flex", minHeight: 30 }}
            className="tg-row-preview"
          >
            {bins.map((bin, i) => {
              const elsInBin = elementsByBinId[bin.id];
              const elForBin = find(elsInBin, el => el.index === rowIndex);

              return (
                <div
                  key={i}
                  style={{
                    border: "1px solid #e0e3e6",
                    padding: 5,
                    flex: "1 1 0px",
                    minWidth: 0,
                    flexShrink: 1,
                    overflow: "hidden",
                    wordBreak: "break-all",
                    maxWidth: 155,
                    textOverflow: "ellipsis",
                    maxHeight: 30,
                    whiteSpace: "nowrap"
                  }}
                  className="tg-bin-preview"
                >
                  {elForBin?.part?.name || ""}
                  {/* Part {i} */}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
