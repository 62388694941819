/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { NumericInputField, SelectField } from "@teselagen/ui";
import { notLessThan } from "../../../src-shared/utils/formUtils";
import unitGlobals from "../../../../tg-iso-lims/src/unitGlobals";
import { useSelector } from "react-redux";
import { FormName } from "redux-form";

const UnitInputField = ({
  name,
  label,
  unitName,
  isRequired,
  unitDefault,
  unitType,
  filteredUnits,
  style,
  disabled,
  enableReinitialize,
  form,
  ...rest
}) => {
  const defaultValue = useSelector(state => {
    return state.form[form]?.initial?.[unitName] || unitDefault;
  });
  const options = unitGlobals.getOptionsForSelect(unitType, filteredUnits);

  return (
    <div className="input-with-unit-select" style={style}>
      <div style={{ maxWidth: 300 }}>
        <NumericInputField
          name={name}
          isRequired={isRequired}
          disabled={disabled}
          label={label}
          enableReinitialize={enableReinitialize}
          min={0}
          normalize={notLessThan(0)}
          {...rest}
        />
      </div>
      <SelectField
        name={unitName}
        disabled={disabled}
        defaultValue={defaultValue}
        enableReinitialize={enableReinitialize}
        label={label ? "none" : undefined}
        className="tg-unit-select"
        options={options}
      />
    </div>
  );
};

export default props => (
  <FormName>{({ form }) => <UnitInputField form={form} {...props} />}</FormName>
);
