/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import * as yup from "yup";

export default {
  description: "Setup endpoints for Twist DNA Ordering tool",
  bundles: [
    {
      id: "dna_ordering_twist",
      name: "Twist DNA Ordering",
      integrationHookFileUrl: "twistDnaOrderingIntegrationHook.json",
      nodeRedFileUrl: "twistNodeRed.json"
    }
  ],
  endpoints: {
    VENDOR__VECTORS: {
      name: "Get Vendor Vectors (Optional)",
      method: "GET",
      description: `Get Vectors is an optional endpoint to get the vectors into which the DNA being ordered can be cloned.
        The Vector selected will be passed to the subsequent SCORING endpoint`,
      exampleRequest: "",
      exampleResponse: {
        __validate__: () => {
          return yup.object({
            options: yup.array(
              yup.lazy(v => {
                if (Array.isArray(v.options)) {
                  return yup.object({
                    label: yup.string().required(),
                    options: yup.array(yup.object())
                  });
                }
                return yup.object({
                  id: yup.string().rquired(),
                  value: yup.object().required(),
                  label: yup.string().required()
                });
              })
            )
          });
        },
        // TODO cannot do an array of data directly because the subtype gets hoisted up
        // to be on the example response directly.
        options: [
          {
            label: "Top Level Opt1",
            options: [
              {
                label: "Child Opt1",
                value: { your: "custom", property: "here" }
              }
            ]
          },
          {
            label: "Top Level Opt2",
            options: [
              {
                label: "Child Opt1",
                value: { your: "custom", property: "here" }
              },
              {
                label: "Child Opt2",
                value: { your: "custom", property: "here" }
              }
            ]
          }
        ]
      }
    },
    VENDOR__SCORING: {
      name: "Score DNA",
      method: "POST",
      description: "Score DNA",
      exampleRequest: {
        __validate__: () => {
          return yup.object({
            sequence: yup
              .object({
                id: yup.string().required(),
                name: yup.string().required(),
                sequence: yup.string().required(),
                circular: yup.boolean().required(),
                vector: yup.mixed()
              })
              .required()
          });
        },
        sequence: {
          id: "382",
          name: "pj5001",
          sequence: "AAABBCC",
          circular: false,
          vector: {
            your: "custom",
            property: "here"
          }
        }
        // {
        //   id: "383",
        //   name: "pj5002",
        //   sequence: "AAABBCCCCCC",
        //   vector: "N/A"
        // }
      },
      exampleResponse: {
        __validate__: () => {
          return yup.object({
            scoringId: yup.string(),
            error: yup.string()
          });
        },
        scoringId: "2ac01b36-35ba-4b9c-b6f5-c4bc2f9ded7f"
      }
    },
    VENDOR__SCORING__RESULT: {
      name: "Get Scoring Results",
      method: "GET",
      description: "Get Scoring Results",
      exampleRequest: "?scoringId=2ac01b36-35ba-4b9c-b6f5-c4bc2f9ded7f",
      exampleResponse: {
        __validate__: () => {
          return yup.object({
            error: yup.string(),
            info: yup.string(),
            scored: yup.boolean().required(),
            buildable: yup.boolean()
          });
        },
        buildable: true,
        scored: true,
        info: "STANDARD"
      }
    },
    VENDOR__PRICEBOOK: {
      // this will get the full pricebook
      name: "Pricebook",
      method: "GET",
      description: "Get Pricebook for different types of sequences",
      exampleRequest: "",
      exampleResponse: {
        __validate__: () => {
          return yup.object({
            pricebook: yup.array(
              yup.object({
                product_code: yup.string(),
                unit_price: yup.string()
              })
            )
          });
        },
        pricebook: [
          {
            product_code: "GS00008_2",
            unit_price: "250.00"
          }
        ]
      }
    },
    VENDOR__QUOTE: {
      name: "Quote",
      description: "Get the price of DNA sequence",
      method: "POST",
      // this will get the price for a single construct
      exampleRequest: {
        __validate__: () => {
          return yup.object({
            pricebook: yup.array(
              yup.object({
                product_code: yup.string(),
                unit_price: yup.string()
              })
            ),
            sequence: yup
              .object({
                id: yup.string().required(),
                name: yup.string().required(),
                sequence: yup.string().required(),
                circular: yup.boolean().required(),
                vector: yup.mixed()
              })
              .required()
          });
        },
        pricebook: [
          {
            product_code: "GS00008_2",
            unit_price: "250.00"
          }
        ],
        sequence: {
          id: "382",
          name: "pj5001",
          sequence: "AAABBCC",
          circular: false,
          vector: {
            your: "custom",
            property: "here"
          }
        }
      },
      exampleResponse: {
        __validate__: () => {
          return yup.object({
            id: yup.string().required(),
            price: yup.number().required()
          });
        },
        id: "382",
        price: 30.0
      }
    }
  }
};
