/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";

export type CurrentUser = {
  avatarFile?: { path: string };
  defaultLabId: string;
  firstName?: string;
  email: string;
  id: string;
  labRoles: {
    labId: string;
    lab: { id: string; name: string };
    roleCode: string;
  }[];
  lastName?: string;
  projectRoles: { project: { name: string; id: string } }[];
  defaultProjectId: string;

  username: String;
};

const CurrentUserContext = React.createContext<{
  currentUser?: CurrentUser;
  refetchCurrentUser?: () => void;
}>({});

export default CurrentUserContext;
