/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import shortid from "shortid";
import { isoContext } from "@teselagen/utils";
import { forEach } from "lodash";
import { sequenceJSONtoGraphQLInput } from "../../../tg-iso-shared/src/sequence-import-utils/utils";
import {
  checkBarcodesAndFormat,
  maxWellVolumeError
} from "../utils/plateUtils";
import { sequenceWithTagsAndAliasesAndExtendedProperitesFragment } from "./helperFragments";
import calculateConcentration from "../utils/unitUtils/calculateConcentration";
import {
  calculateConcentrationFromMolarity,
  calculateMolarityFromConcentration,
  defaultConcentrationUnitCode
} from "../utils/unitUtils";
import {
  convertConcentration,
  convertMolarity
} from "../utils/unitUtils/convertUnits";
import parsePlateCsvAndSequenceFiles from "./parsePlateCsvAndSequenceFiles";
import isValidPositiveNumber from "../../../tg-iso-shared/src/utils/isValidPositiveNumber";

export const requiredHeaders = [
  "Plate Name",
  "Well Position",
  "Sequence Name",
  "Sequence"
];

export const allHeaders = requiredHeaders.concat([
  "ug",
  "Final Volume uL",
  "Plate Barcode",
  "Tube Barcode",
  "Molarity nM"
]);

export default async function handleIdtPlateImport(values, ctx = isoContext) {
  const {
    sequenceTypeCode = "OLIGO",
    dry,
    generateTubeBarcodes,
    generateBarcode
  } = values;

  const {
    finishPlateCreate,
    containerArrayType,
    aliquotContainerType,
    filename,
    csvData,
    getCsvRowExtProps
  } = await parsePlateCsvAndSequenceFiles(
    values,
    {
      barcodeHeader: "PLATE_BARCODE",
      nameHeader: "PLATE_NAME",
      hasSequences: true,
      hasExtendedProperties: true,
      requiredFields: requiredHeaders,
      sequenceFragment: sequenceWithTagsAndAliasesAndExtendedProperitesFragment,
      getSequenceForRow: ({ row }) => {
        if (row.SEQUENCE) {
          const sequenceName = row["SEQUENCE_NAME"];
          return sequenceJSONtoGraphQLInput({
            name: sequenceName,
            sequence: row.SEQUENCE.replace(/\s/g, ""),
            sequenceTypeCode
          });
        }
      }
    },
    ctx
  );

  const isRack = !containerArrayType.isPlate;
  const newAliquots = [];
  const mapPlates = {};
  const addedPropsForSequence = {};
  const existingSequenceToTag = [];
  const existingMaterialToTag = [];
  // collect all sequence hashes
  const data = [];

  for (const row of csvData) {
    const newRow = {};
    Object.keys(row).forEach(key => {
      if (key.match(/FINAL_VOLUME.*L/i)) {
        newRow["FINAL_VOLUME_UL"] = row[key];
      } else if (key.match(/.*g/i) && key.length === 2) {
        newRow["UG"] = row[key];
      } else {
        newRow[key] = row[key];
      }
    });
    data.push(newRow);
  }

  const continueUpload = await checkBarcodesAndFormat({
    data,
    generateTubeBarcodes,
    filename,
    containerArrayType,
    tubeBarcodeKey: "TUBE_BARCODE",
    barcodeKey: "PLATE_BARCODE",
    wellPositionHeader: "WELL_POSITION"
  });

  if (!continueUpload) return;

  for (const [index, row] of data.entries()) {
    // "Plate Name","Plate Barcode","Well Position","Sequence Name","Sequence","ug","Final Volume uL"
    const {
      SEQUENCE_NAME: sequenceName,
      sequenceId,
      sequence,
      materialId,
      molecularWeight,
      PLATE_NAME: plateName,
      rowPosition,
      columnPosition,
      WELL_POSITION: well,
      UG: mass,
      FINAL_VOLUME_UL: volume = "",
      MOLARITY_NM: molarity = "",
      TUBE_BARCODE: tubeBarcode,
      PLATE_BARCODE: plateBarcode,
      rowKey
    } = row;

    if (!sequenceId) {
      throw new Error(
        `Row with well position ${well} did not specify a sequence.`
      );
    }

    if (dry && volume.trim() !== "" && Number(volume) !== 0) {
      throw new Error(
        `Row with well position ${well} has a volume. Dry plates should not specify volume.`
      );
    } else if (!dry && (!volume || Number(volume) === 0)) {
      throw new Error(`Row with well position ${well} does not have a volume.`);
    }
    if (molarity && !isValidPositiveNumber(Number(molarity))) {
      throw new Error(
        `Row with well position ${well} provided an invalid molarity.`
      );
    }
    if (!mapPlates[rowKey]) {
      const plateCid = shortid();
      mapPlates[rowKey] = {
        cid: plateCid,
        name: plateName,
        barcode: plateBarcode,
        aliquotContainers: []
      };
      getCsvRowExtProps({
        row,
        recordId: `&${plateCid}`,
        modelTypeCode: "CONTAINER_ARRAY",
        typeFilter: ["plate", "rack"]
      });
    }

    if (!addedPropsForSequence[sequenceId]) {
      addedPropsForSequence[sequenceId] = true;
      getCsvRowExtProps({
        row,
        modelTypeCode: "DNA_SEQUENCE",
        typeFilter: "sequence",
        recordId: sequenceId,
        record: sequence
      });
    }

    const aliquotCid = shortid();
    const aliquotInfo = {};
    aliquotInfo.isDry = dry;
    if (dry) {
      aliquotInfo.mass = mass;
      aliquotInfo.massUnitCode = "ug";
    } else {
      aliquotInfo.volume = volume;
      aliquotInfo.volumetricUnitCode = "uL";
      aliquotInfo.concentrationUnitCode = "ng/uL";
      if (volume) {
        const maxVolumeError = maxWellVolumeError({
          volume,
          unit: aliquotInfo.volumetricUnitCode,
          containerArrayType,
          aliquotContainerType,
          index
        });
        if (maxVolumeError) {
          throw new Error(maxVolumeError);
        }
      }
      if (mass) {
        aliquotInfo.concentration = calculateConcentration({
          volume,
          volumetricUnitCode: "uL",
          mass,
          massUnitCode: "ug",
          concentrationUnitCode: "ng/uL"
        });

        const calculatedMolarity = calculateMolarityFromConcentration(
          aliquotInfo.concentration,
          aliquotInfo.concentrationUnitCode,
          molecularWeight
        );
        const convertedMolarity = Number(
          convertMolarity(calculatedMolarity, "M", "nM", true)
        ).toString();
        aliquotInfo.molarity = convertedMolarity;
        aliquotInfo.molarityUnitCode = "nM";
      } else if (molarity) {
        aliquotInfo.molarity = molarity;
        aliquotInfo.molarityUnitCode = "nM";
        aliquotInfo.concentration = convertConcentration(
          calculateConcentrationFromMolarity(molarity, "nM", molecularWeight),
          "g/L",
          defaultConcentrationUnitCode
        );
      }
    }
    const sampleCid = shortid();
    const aliquot = {
      cid: aliquotCid,
      ...aliquotInfo,
      aliquotType: "sample-aliquot",
      sample: {
        cid: sampleCid,
        name: sequenceName,
        sampleTypeCode: "REGISTERED_SAMPLE",
        materialId
      }
    };
    getCsvRowExtProps({
      row,
      modelTypeCode: "ALIQUOT",
      typeFilter: "aliquot",
      recordId: `&${aliquotCid}`
    });

    getCsvRowExtProps({
      row,
      modelTypeCode: "SAMPLE",
      typeFilter: "sample",
      recordId: `&${sampleCid}`
    });
    newAliquots.push(aliquot);

    const tubeCid = shortid();
    if (isRack) {
      getCsvRowExtProps({
        row,
        modelTypeCode: "ALIQUOT_CONTAINER",
        typeFilter: "tube",
        recordId: `&${tubeCid}`
      });
    }

    mapPlates[rowKey].aliquotContainers.push({
      cid: tubeCid,
      aliquotId: `&${aliquotCid}`,
      rowPosition,
      columnPosition,
      tubeBarcode
    });
  }

  const platesToCreate = [];

  forEach(mapPlates, ({ cid, aliquotContainers, name, barcode }) => {
    const newAliquotContainers = aliquotContainers.map(
      ({ tubeBarcode, ...rest }) => {
        const barcodeField = {};
        if (!generateTubeBarcodes) {
          barcodeField.barcode = {
            barcodeString: tubeBarcode
          };
        }
        return {
          aliquotContainerTypeCode: isRack
            ? aliquotContainerType.code
            : containerArrayType.aliquotContainerType.code,
          ...barcodeField,
          ...rest
        };
      }
    );

    platesToCreate.push({
      cid,
      name,
      ...(name !== barcode &&
        !generateBarcode && {
          barcode: {
            barcodeString: barcode
          }
        }),
      containerArrayTypeId: containerArrayType.id,
      aliquotContainers: newAliquotContainers
    });
  });

  const createdPlates = await finishPlateCreate(
    {
      newPlates: platesToCreate,
      newAliquots,
      existingMaterials: existingMaterialToTag,
      existingSequences: existingSequenceToTag
    },
    ctx
  );

  if (!createdPlates.length) {
    throw new Error("No plates to created.");
  }

  return createdPlates;
}
