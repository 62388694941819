/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useContext } from "react";
import { Callout, MenuItem, Switch } from "@blueprintjs/core";
import AbstractLibrary from "../AbstractLibrary";
import { showDialog } from "../GlobalDialog";
import { compose } from "recompose";
import gql from "graphql-tag";
import AddProjectDialog from "./AddProjectDialog";
import { isAdmin } from "../utils/generalUtils";
import appGlobals from "../appGlobals";
import { ALL_PROJECTS_CID } from "@teselagen/auth-utils";
import useDisabledProjects from "../utils/useDisabledProjects";
import libraryEnhancer from "../libraryEnhancer";
import CurrentUserContext from "../context/CurrentUserContext";

const getProjectUsers = (project, code) => {
  return project.projectRoles
    .filter(role => role.roleCode === code)
    .map(r => r.user);
};

const getProjectAdmins = project => {
  return getProjectUsers(project, "ADMIN");
};

const getProjectMembers = project => {
  return getProjectUsers(project, "MEMBER");
};

const ProjectManagementLibrary = props => {
  const { currentUser } = useContext(CurrentUserContext);
  const {
    data: { refetch }
  } = props;
  const [projectsDisabled, setProjectsDisabled] = useDisabledProjects();

  const onNewItemClick = () => {
    showDialog({
      ModalComponent: AddProjectDialog,
      modalProps: {
        refetch
      }
    });
  };

  const editProject = record => {
    showDialog({
      ModalComponent: AddProjectDialog,
      modalProps: {
        refetch,
        initialValues: {
          ...record,
          projectMembers: getProjectMembers(record),
          projectAdmins: getProjectAdmins(record)
        }
      }
    });
  };

  const contextMenu = ({
    deleteMenuItem,
    selectedRecords,
    editExtendedPropertiesMenuItem
  }) => {
    const editMenuItem = (
      <MenuItem
        icon="edit"
        key="edit"
        onClick={() => editProject(selectedRecords[0])}
        text="Edit"
      />
    );
    const keyedProjectRoles = {};
    currentUser.projectRoles.forEach(role => {
      if (role.project) {
        keyedProjectRoles[role.project.id] = role.roleCode;
      }
    });
    const isAdminOfAllSelected =
      isAdmin() ||
      selectedRecords.every(
        project => keyedProjectRoles[project.id] === "ADMIN"
      );

    if (!isAdminOfAllSelected) return;

    const menuItems = [];
    if (selectedRecords.length === 1) {
      menuItems.push(editMenuItem);
    }
    if (editExtendedPropertiesMenuItem) {
      menuItems.push(editExtendedPropertiesMenuItem);
    }
    menuItems.push(deleteMenuItem);

    return menuItems;
  };

  return (
    <div className="tg-card">
      {isAdmin() && (
        <Callout intent="primary" style={{ marginBottom: 10 }}>
          As an admin you can disable project filters across the app. This will
          let you see all items across projects. This will not affect other
          users.
          <Switch
            name="disableProjects"
            label="Disable Projects"
            checked={projectsDisabled}
            onChange={e => setProjectsDisabled(e.target.checked)}
          />
        </Callout>
      )}
      <AbstractLibrary
        {...props}
        compact
        libraryName="project"
        contextMenu={contextMenu}
        onNewItemClick={onNewItemClick}
        onDoubleClick={editProject}
      />
    </div>
  );
};

const projectManagementFragment = gql`
  fragment projectManagementFragment on project {
    id
    name
    description
    color
    lab {
      id
      name
    }
    projectRoles {
      id
      userId
      roleCode
      user {
        id
        username
      }
    }
  }
`;

const schema = {
  model: "project",
  fields: [
    {
      path: "name",
      type: "string",
      displayName: "Name"
    },
    {
      path: "projectAdmins",
      type: "array",
      filterDisabled: true,
      sortDisabled: true,
      displayName: "Project Admins",
      render: (v, record) => {
        try {
          return getProjectAdmins(record)
            .map(user => user.username)
            .join(", ");
        } catch (e) {
          console.error(e);
          return "Unknown";
        }
      }
    },
    {
      path: "projectRoles.user.username",
      displayName: "Project Members",
      render: (v, record) => {
        try {
          return getProjectMembers(record)
            .map(user => user.username)
            .join(", ");
        } catch (e) {
          console.error(e);
          return "Unknown";
        }
      }
    },
    {
      path: "color",
      type: "string",
      sortDisabled: true,
      filterDisabled: true,
      render: v => {
        return (
          v && (
            <div
              style={{
                height: 20,
                width: 40,
                background: v,
                border: "1px solid #182026",
                borderRadius: 5
              }}
            />
          )
        );
      }
    }
  ]
};

export default compose(
  libraryEnhancer({
    schema,
    includeLabColumnIfAdmin: true,
    noAddedBy: true,
    fragment: projectManagementFragment,
    additionalFilter: (_, qb) => {
      qb.whereAny({ cid: qb.notEquals(ALL_PROJECTS_CID) }, { cid: null });

      if (!isAdmin(appGlobals.currentUser)) {
        qb.whereAll({
          "projectRoles.userId": appGlobals.currentUser.id
        });
      }
    }
  })
)(ProjectManagementLibrary);
