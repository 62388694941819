/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

// The `array2d` parameter is assumed to be row major.
export const getBlockOf2dArray = <T,>(
  array2d: T[][],
  blkRowCount: number,
  blkColCount: number,
  blkXIndex: number,
  blkYIndex: number,
  everyOtherColumn: boolean,
  everyOtherRow: boolean
): T[][] => {
  if (everyOtherRow && everyOtherColumn) {
    return array2d
      .slice(blkYIndex * blkRowCount, (blkYIndex + 1) * blkRowCount)
      .map(row =>
        row.slice(blkXIndex * blkColCount, (blkXIndex + 1) * blkColCount)
      );
  } else if (everyOtherRow) {
    const row1 = array2d[blkYIndex * blkRowCount];
    const row2 = array2d[blkYIndex * blkRowCount + 1];
    return [
      [row1[blkXIndex], row1.slice(row1.length / 2)[blkXIndex]],
      [row2[blkXIndex], row2.slice(row2.length / 2)[blkXIndex]]
    ];
  } else if (everyOtherColumn) {
    const col1: any[] = [];
    const col2: any[] = [];
    array2d.forEach(row => {
      col1.push(row[blkXIndex * blkColCount]);
      col2.push(row[blkXIndex * blkColCount + 1]);
    });
    return [
      [col1[blkYIndex], col2[blkYIndex]],
      [
        col1.slice(col1.length / 2)[blkYIndex],
        col2.slice(col2.length / 2)[blkYIndex]
      ]
    ];
  } else {
    const row1 = array2d[blkYIndex];
    const row2 = array2d[array2d.length / 2 + blkYIndex];
    return [
      [row1[blkXIndex], row1.slice(row1.length / 2)[blkXIndex]],
      [row2[blkXIndex], row2.slice(row2.length / 2)[blkXIndex]]
    ];
  }
};
