/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useContext, useMemo } from "react";
import { compose } from "recompose";
import { showConfirmationDialog, withTableParams } from "@teselagen/ui";
import { Icon, Colors } from "@blueprintjs/core";
import Helmet from "react-helmet";
import { get } from "lodash";
import { MenuItem, Callout } from "@blueprintjs/core";
import AbstractLibrary from "../AbstractLibrary";
import { safeUpsert } from "../apolloMethods";
import { userManagementFragment } from "./userManagementFragment.gql";
import { isAdmin } from "../utils/generalUtils";
import { showDialog } from "../GlobalDialog";
import { Link } from "react-router-dom";
import dataTableSelectFilterMenu from "../dataTableSelectFilterMenu";
import { userFirstNameWithIconColumn } from "../utils/libraryColumns";
import AdminCallout from "../SharedAppSettings/AdminCallout";
import CurrentUserContext from "../context/CurrentUserContext";
import withQuery from "../withQuery";
import { RegisterFormDialog } from "../auth/components/RegisterUserForm";

const ActiveUsersFilterMenu = dataTableSelectFilterMenu({
  options: [
    {
      value: "active",
      label: "Show Only Active Users"
    },
    {
      value: "inactive",
      label: "Show Only Inactive Users"
    }
  ],
  paramKey: "activeFilter",
  label: "Filter On Active Status"
});

const UserManagementPanel = props => {
  const { selectTableRecords, refetchUsers } = props;
  const { currentUser } = useContext(CurrentUserContext);
  const isLocalAuth = useMemo(
    () => !!get(window, "frontEndConfig.localLogin"),
    []
  );

  const callOut = useMemo(() => {
    let callOut = (
      <Callout intent="primary" title="You are not an admin">
        Only an admin can add, delete and edit users from this screen. To edit
        your own profile go to <Link to="/settings/profile">Profile</Link>
      </Callout>
    );
    if (isAdmin()) {
      if (isLocalAuth) {
        callOut = (
          <AdminCallout>
            As an admin you can add, delete and edit users. You cannot remove
            your own admin status, only other admins can do that.
          </AdminCallout>
        );
      } else {
        callOut = (
          <AdminCallout>
            As an admin you can delete and edit some user settings. You cannot
            remove your own admin status, only other admins can do that. To add
            users please use your single sign-on admin console.
          </AdminCallout>
        );
      }
    }
    return callOut;
  }, [isLocalAuth]);

  const afterEdit = user => {
    selectTableRecords([user.id]);
  };
  const showCreateUser = () => {
    if (isAdmin() && isLocalAuth) {
      showDialog({
        ModalComponent: RegisterFormDialog,
        modalProps: {
          refetch: refetchUsers,
          adminAddUser: true
        }
      });
    } else {
      return null; //don't show the new user button if the user isn't an admin
    }
  };

  const contextMenuItems = ({ selectedRecords }) => {
    if (isAdmin()) {
      const hasSelf = selectedRecords.some(r => r.id === currentUser.id);
      if (hasSelf) return;
      const menuItems = [];
      if (selectedRecords.length === 1) {
        const userToEdit = selectedRecords[0];
        menuItems.push(
          <MenuItem
            key="editUser"
            icon="edit"
            text="Edit User"
            onClick={() => {
              showDialog({
                ModalComponent: RegisterFormDialog,
                modalProps: {
                  adminEditUser: true,
                  afterEdit: afterEdit,
                  dialogProps: {
                    title: "Edit User"
                  },
                  originalUserBeingEdited: userToEdit,
                  initialValues: {
                    userRole: userToEdit.userRoles.some(
                      ({ appRoleCode = "" }) => {
                        return appRoleCode === "ADMIN";
                      }
                    )
                      ? "ADMIN"
                      : null,
                    email: userToEdit.email,
                    firstName: userToEdit.firstName,
                    id: userToEdit.id,
                    lastName: userToEdit.lastName,
                    phoneNumber: userToEdit.phoneNumber,
                    username: userToEdit.username
                  }
                }
              });
            }}
          />
        );
        const { id, firstName, lastName, isDeactivated } = selectedRecords[0];
        const fullName = firstName + " " + lastName;
        menuItems.push(
          <MenuItem
            key="deactivate"
            onClick={async () => {
              const confirm = await showConfirmationDialog({
                text: `Are you sure you want to ${
                  isDeactivated ? "activate" : "deactivate"
                } user "${fullName}"?`,
                intent: isDeactivated ? "primary" : "danger",
                confirmButtonText: isDeactivated ? "Activate" : "Deactivate",
                cancelButtonText: "Cancel",
                canEscapeKeyCancel: true
              });

              if (confirm) {
                await safeUpsert(["user", "id isDeactivated"], {
                  id,
                  isDeactivated: !isDeactivated
                });
                window.toastr.success(
                  `${isDeactivated ? "Activated" : "Deactivated"} "${fullName}"`
                );
              }
            }}
            text={isDeactivated ? "Activate" : "Deactivate"}
          />
        );
      }
      return menuItems;
    }
  };

  return (
    <div className="tg-card">
      <Helmet title="Users" />
      {callOut}
      <AbstractLibrary
        {...props}
        isLibraryTable
        noOpen
        extraTableParams={{
          currentUser
        }}
        noRoute
        contextMenu={contextMenuItems}
        libraryName="user"
        noNewItem={!isAdmin() || !isLocalAuth}
        onNewItemClick={showCreateUser}
      />
    </div>
  );
};

const schema = {
  model: "user",
  fields: [
    // these columns are needed for tests until we can have a multifilter column
    userFirstNameWithIconColumn,
    {
      type: "string",
      displayName: "Last Name",
      path: "lastName"
    },
    { path: "email", type: "string", displayName: "Email" },
    { path: "createdAt", type: "timestamp", displayName: "Sign Up Date" },
    {
      path: "userRoles.appRole.name",
      displayName: "User Roles",
      render: (v, { userRoles }) => {
        if (!userRoles.length) return "No Role Assigned";
        return userRoles.map(({ appRole }) => appRole.name).join(", ");
      }
    },
    {
      path: "isDeactivated",
      type: "boolean",
      displayName: "Active?",
      FilterMenu: ActiveUsersFilterMenu,
      filterKey: "activeFilter",
      filterIsActive: currentParams => currentParams.activeFilter,
      render: isDeactivated => (
        <Icon
          icon={isDeactivated ? "cross" : "tick"}
          style={{
            color: isDeactivated ? Colors.RED3 : Colors.GREEN3,
            opacity: isDeactivated ? 0.7 : 1
          }}
        />
      )
    }
  ]
};

export default compose(
  withTableParams({
    urlConnected: false,
    schema,
    formName: "UserManagementPanelTable",
    withDisplayOptions: true,
    additionalFilter: (_, qb, currentParams) => {
      // Always filter out SERVICE account users.
      qb.whereAll({
        id: qb
          .notRelated("userRole.userId", true)
          .whereAll({ appRoleCode: "SERVICE" })
      });

      if (currentParams.activeFilter) {
        if (currentParams.activeFilter === "active") {
          qb.whereAny(
            {
              isDeactivated: false
            },
            {
              isDeactivated: null
            }
          );
        } else if (currentParams.activeFilter === "inactive") {
          qb.whereAll({
            isDeactivated: true
          });
        }
      }
    }
  }),
  withQuery(userManagementFragment, { isPlural: true })
)(UserManagementPanel);
