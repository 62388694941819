/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import type QueryBuilder from "tg-client-query-builder";

export const classFilter = (
  qb: QueryBuilder,
  additionalFilter: { [field: string]: string[] | string }
) => {
  return qb.whereAll({
    ...additionalFilter,
    extendedPropertyClassCode: qb.notInList(["LINK", "WORKLIST_COUNTER"])
  });
};
