/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import * as yup from "yup";
import { exampleRecord } from "../exampleRecords";
import { exampleRecordValidation } from "../validationFns";

export default {
  description: "Hit an endpoint to export a plate in a custom format",
  endpoints: {
    EXPORT_CUSTOM_PLATE: {
      name: "Export Custom Plate",
      method: "POST",
      description: "Export a plate in a custom format",
      exampleRequest: {
        __validate__: () =>
          yup.object({
            plates: yup.array(
              exampleRecordValidation({
                isExportCall: true,
                subtype: "PLATE",
                doNotRequireExternalFields: true
              })
            )
          }),
        plates: [exampleRecord({ alwaysIds: true }).__subtype__.PLATE]
      },
      exampleResponse: {
        __validate__: () => {
          return yup.object({
            data: yup.array(
              yup.object({
                filename: yup.string(),
                contents: yup.string()
              })
            )
          });
        },
        data: [
          {
            filename: "plate.txt",
            contents: `custom plate format for Plate 1
              A1,1,uL,PCR_0001,4623
              A11,3,uL,,reagent 3 (lot 3)`
          }
        ]
      }
    }
  }
};
