/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get, isObject } from "lodash";
import Big from "big.js";
import React from "react";
import { Tooltip } from "@blueprintjs/core";
import getUnit from "../../../tg-iso-lims/src/utils/unitUtils/getUnit";
import cleanUnit from "../../../tg-iso-lims/src/utils/unitUtils/cleanUnit";
import {
  standardizeConcentration,
  standardizeVolume,
  standardizeMass,
  standardizeMolarity
} from "../../../tg-iso-lims/src/utils/unitUtils/standardizeUnits";
import {
  convertVolume,
  convertConcentration,
  convertMass,
  convertMolarity
} from "../../../tg-iso-lims/src/utils/unitUtils/convertUnits";
import calculateConcentration from "../../../tg-iso-lims/src/utils/unitUtils/calculateConcentration";
import { toDecimalPrecision } from "../../../tg-iso-shared/src/utils/generalUtils";
import unitGlobals from "../../../tg-iso-lims/src/unitGlobals";

export {
  standardizeConcentration,
  standardizeVolume,
  standardizeMass,
  standardizeMolarity,
  calculateConcentration,
  convertVolume,
  convertConcentration,
  convertMass,
  convertMolarity,
  toDecimalPrecision,
  cleanUnit
};

export const getMassFromVolumeAndConcentration = <T extends boolean>({
  volume,
  volumetricUnitCode,
  concentration,
  concentrationUnitCode,
  big
}: {
  volume: T extends true ? Big : number;
  volumetricUnitCode: string;
  concentration: T extends true ? Big : number;
  concentrationUnitCode: string;
  big?: T;
}): {
  mass: T extends true ? Big : number;
  massUnitCode: string;
} => {
  const desiredUnits = concentrationUnitCode.split("/");
  const desiredMassUnit = getUnit(unitGlobals.massUnits, desiredUnits[0]);
  const desiredVolumetricUnit = getUnit(
    unitGlobals.volumetricUnits,
    desiredUnits[1]
  );
  const standardizedVolume = standardizeVolume(volume, volumetricUnitCode, big);
  let newVolume: Big | number;
  let mass: Big | number;
  if (big) {
    newVolume = (standardizedVolume as Big).div(desiredVolumetricUnit.liters);
    mass = newVolume.times(concentration);
  } else {
    newVolume =
      (standardizedVolume as number) / (desiredVolumetricUnit.liters as number);
    mass = newVolume * (concentration as number);
  }
  return {
    mass: mass as T extends true ? Big : number,
    massUnitCode: desiredMassUnit.code
  };
};

type withUnitGenericOpts = { noJsx?: boolean };

//noJsx is used when we're exporting data and want to export a string, not a JSX object
export const withUnitGeneric =
  (path: string, unitPath: string) =>
  (
    value: any,
    record?: any,
    { noJsx }: withUnitGenericOpts = { noJsx: false }
  ): string | React.JSX.Element => {
    let valueToUse = value;
    let recordToUse = record;
    if (isObject(value)) {
      // then value is the record
      recordToUse = value;
      valueToUse = get(recordToUse, path);
    }
    if (!valueToUse) valueToUse = get(recordToUse, path);
    const unit = get(recordToUse, unitPath) || "";
    const numericValue = parseFloat(valueToUse);

    if (!isNaN(numericValue)) {
      const roundedValue = toDecimalPrecision(numericValue);
      const unroundedValue = numericValue;

      if (
        roundedValue === unroundedValue ||
        Math.abs(unroundedValue - roundedValue) < 1e-6 ||
        noJsx
      ) {
        return roundedValue + ` ${unit}`;
      }
      return (
        // @ts-ignore
        <Tooltip content={unroundedValue + ` ${unit}`}>
          {roundedValue + ` ${unit}`}
        </Tooltip>
      );
    }
    return "N/A";
  };

export const volumeRender = withUnitGeneric("volume", "volumetricUnitCode");
export const lengthRender = withUnitGeneric("length", "lengthUnitCode");
export const concentrationRender = withUnitGeneric(
  "concentration",
  "concentrationUnitCode"
);
export const massRender = withUnitGeneric("mass", "massUnitCode");
export const molarityRender = withUnitGeneric("molarity", "molarityUnitCode");
export const cellConcentrationRender = withUnitGeneric(
  "cellConcentration",
  "cellConcentrationUnitCode"
);
export const materialConcentrationRender = withUnitGeneric(
  "materialConcentration",
  "materialConcentrationUnitCode"
);

export const molecularWeightRender = (
  molecularWeight: number,
  unused: any,
  opts: withUnitGenericOpts
) =>
  withUnitGeneric("molecularWeight", "molecularWeightUnitCode")(
    { molecularWeight, molecularWeightUnitCode: "g/mol" },
    opts
  );
