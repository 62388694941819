/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { range } from "lodash";

export type AliquotContainerType = {
  rowPosition: number;
  columnPosition: number;
};

export const plateTo2dAliquotContainerArray = (plate: {
  containerArrayType: {
    containerFormat: {
      rowCount: number;
    };
  };
  aliquotContainers: AliquotContainerType[];
}) => {
  const { rowCount } = plate.containerArrayType.containerFormat;
  const array2d: AliquotContainerType[][] = range(rowCount).map(() => []);
  plate.aliquotContainers.forEach(aliquotContainer => {
    const { rowPosition, columnPosition } = aliquotContainer;
    array2d[rowPosition][columnPosition] = aliquotContainer;
  });
  return array2d;
};
