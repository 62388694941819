/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
import { distByAssemblyReportMaterialFragment } from "./distByAssemblyReportMaterialFragment.gql";

export const distByAssemblyReportSampleFragment = gql`
  fragment distByAssemblyReportSampleFragment on sample {
    id
    material {
      ...distByAssemblyReportMaterialFragment
    }
  }
  ${distByAssemblyReportMaterialFragment}
`;
