/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import {
  FileUploadField,
  DialogFooter,
  ReactSelectField,
  wrapDialog,
  CheckboxField,
  BlueprintError,
  throwFormError
} from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";
import { Classes } from "@blueprintjs/core";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { readAsText } from "promise-file-reader";
import locationPartialFragment from "../../../graphql/fragments/locationPartialFragment";
import { arrayToIdOrCodeValuedOptions } from "../../../../src-shared/utils/formUtils";
import {
  getExt,
  extractZipFiles
} from "../../../../../tg-iso-shared/src/utils/fileUtils";
import exampleUpload from "./example-upload.json";
import { getDownloadTemplateFileHelpers } from "../../../../src-shared/components/DownloadTemplateFileButton";
import { handleEquipmentUpload } from "../../../../../tg-iso-lims/src/utils/equipmentUtils";

const EquipmentUploadDialog = props => {
  const { refetch, hideModal, handleSubmit, submitting, locations, error } =
    props;
  const onSubmit = async values => {
    const { equipmentFiles = [], locationId, generateBarcode } = values;

    const parsedEquipmentItems = [];
    try {
      const equipmentJsonFiles = (await extractZipFiles(equipmentFiles)).filter(
        file => getExt(file) === "json"
      );
      if (!equipmentJsonFiles.length) {
        return window.toastr.error("No JSON files found.");
      }
      for (const jsonFile of equipmentJsonFiles) {
        const jsonContent = await readAsText(jsonFile.originFileObj);
        const equipment = JSON.parse(jsonContent);
        parsedEquipmentItems.push(equipment);
      }
    } catch (error) {
      console.error("error:", error);
      return window.toastr.error("Error parsing files");
    }

    try {
      await handleEquipmentUpload({
        equipmentJsons: parsedEquipmentItems,
        locationId,
        files: equipmentFiles,
        promptBarcodeOverwrite: true,
        generateBarcode,
        refetch
      });
      await refetch();
      hideModal();
    } catch (error) {
      console.error(`error:`, error);
      throwFormError(`Error uploading equipment: ${error.message}`);
    }
  };

  const accept = useMemo(
    () =>
      getDownloadTemplateFileHelpers({
        type: [".json", ".zip"],
        fileName: "example-equipment.json",
        fileContents: JSON.stringify(exampleUpload, null, 2)
      }),
    []
  );

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <ReactSelectField
          name="locationId"
          label="Location"
          isRequired
          placeholder="Select a location"
          options={arrayToIdOrCodeValuedOptions(locations)}
        />
        <CheckboxField
          name="generateBarcode"
          label="Generate Barcodes"
          defaultValue
          tooltipInfo="Will generate barcodes for equipment and nested containers"
        />
        <FileUploadField accept={accept} name="equipmentFiles" isRequired />
        <BlueprintError error={error} />
      </div>
      <DialogFooter
        onClick={handleSubmit(onSubmit)}
        submitting={submitting}
        hideModal={hideModal}
      />
    </>
  );
};

export default compose(
  wrapDialog({ title: "Upload Equipment" }),
  withQuery(locationPartialFragment, {
    isPlural: true,
    showLoading: true,
    inDialog: true
  }),
  reduxForm({
    form: "UploadEquipment"
  })
)(EquipmentUploadDialog);
