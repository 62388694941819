/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Callout } from "@blueprintjs/core";
import React, { useState } from "react";
import { DataTable, Loading } from "@teselagen/ui";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import type { modelNameToAdditionalFragment } from "./PlateMapSettings";

import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import { useSelector } from "react-redux";
import { formValueSelector } from "redux-form";
import { useTableEntities } from "@teselagen/ui";
import { SampleAdditionalFragment } from "../utils/additionalFragments.gql.generated";

type RowValues = { sampleId: string };
type DataRow = { id: string; rowValues?: RowValues };

type Props = {
  modelNameToFragment: typeof modelNameToAdditionalFragment;
  change: (field: string, value: any) => void;
  formName: string;
};

type FormData = {
  inventoryLists: { id: string; name: string }[];
  samplesFromInventoryList: any;
};

interface ReduxState {
  form?: {
    [formName: string]: {
      values?: FormData;
    };
  };
}

const InventoryListSection = ({
  modelNameToFragment,
  change,
  formName
}: Props) => {
  const { selectTableEntities } = useTableEntities(
    "createPlateMapSampleInventoryListTable"
  );

  const { inventoryLists, samplesFromInventoryList = [] } = useSelector<
    ReduxState,
    FormData
  >(state =>
    formValueSelector(formName)(
      state,
      "inventoryLists",
      "samplesFromInventoryList"
    )
  );

  const [loadingList, setLoadingList] = useState(false);
  const loadSamplesFromInventoryList = async (dataTables: { id: string }[]) => {
    setLoadingList(true);
    try {
      const dataRows = (await safeQuery<DataRow>(["dataRow", "id rowValues"], {
        variables: {
          filter: {
            dataTableId: dataTables.map(d => d.id)
          }
        }
      })) as DataRow[];
      const sampleIds: string[] = [];
      const rowValuesKeyed: { [sampleId: string]: { sampleId: string } } = {};
      dataRows.forEach(row => {
        const sampleId = row.rowValues?.sampleId;
        if (typeof sampleId === "string" && row.rowValues) {
          sampleIds.push(sampleId);
          rowValuesKeyed[sampleId] = row.rowValues;
        }
      });
      if (sampleIds.length) {
        const samples = (await safeQuery<SampleAdditionalFragment>(
          modelNameToFragment.sample,
          {
            variables: {
              filter: {
                id: sampleIds
              }
            }
          }
        )) as SampleAdditionalFragment[];
        const fullSamples = samples.map(s => {
          return {
            ...s.material, // this will get sequence info from material which allows size sorting
            ...s,
            ...rowValuesKeyed[s.id]
          };
        });
        change("samplesFromInventoryList", fullSamples);
        selectTableEntities(fullSamples);
      }
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error loading samples from list.");
    }
    setLoadingList(false);
  };

  let sampleContent;
  if (!loadingList) {
    if (!samplesFromInventoryList.length) {
      sampleContent = (
        <Callout intent="warning">No samples found on inventory list</Callout>
      );
    } else {
      sampleContent = (
        <DataTable
          formName="createPlateMapSampleInventoryListTable"
          entities={samplesFromInventoryList}
          schema={[
            "name",
            {
              path: "sampleType.name",
              displayName: "Type"
            },
            {
              displayName: "Plate Name",
              path: "plateName"
            },
            {
              displayName: "Plate Barcode",
              path: "plateBarcode"
            },
            {
              displayName: "Position",
              path: "well"
            }
          ]}
          isSimple
          withCheckboxes
        />
      );
    }
  }

  return (
    <div className="tg-step-form-section column">
      <HeaderWithHelper
        width="100%"
        header="Select Inventory List"
        helper="Select list of samples outputted by the Sample Quality Control tool"
      />
      <GenericSelect
        name="inventoryLists"
        schema={["name", dateModifiedColumn]}
        isMultiSelect
        fragment={["dataTable", "id name updatedAt"]}
        // additionalDataFragment: modelNameToAdditionalFragment[itemType],
        tableParamOptions={{
          additionalFilter: () => ({
            dataTableTypeCode: [
              "VALID_SAMPLE_QC_INVENTORY_LIST",
              "INVALID_SAMPLE_QC_INVENTORY_LIST"
            ]
          })
        }}
        onSelect={loadSamplesFromInventoryList}
        nameOverride={"Inventory List"}
      />
      {inventoryLists && (
        <>
          <h6>{inventoryLists.map(l => l.name).join(", ")}</h6>
          {loadingList && <Loading inDialog />}
          {sampleContent}
        </>
      )}
    </div>
  );
};

export default InventoryListSection;
