/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import { compose } from "redux";
import { DataTable, BlueprintError, AdvancedOptions } from "@teselagen/ui";
import withQuery from "../../../../../src-shared/withQuery";

import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import { validateTransfers } from "../../../../utils";

import stepFormValues from "../../../../../src-shared/stepFormValues";
import AddExtendedProperty from "../../../../../src-shared/UpdateRecordExtendedPropertiesDialog/AddExtendedProperty";
import RecordExtendedPropertiesTable from "../../../../../src-shared/UpdateRecordExtendedPropertiesDialog/RecordExtendedPropertiesTable";
import aliquotContainerTypeFragment from "../../../../../../tg-iso-shared/src/fragments/aliquotContainerTypeFragment";
import { volumeRender } from "../../../../../src-shared/utils/unitUtils";
import ReviewWorklistSection from "../../../ReviewWorklistSection";
import { transformExtendedValues } from "../../../../../src-shared/utils/extendedPropertyUtils";
import { isEmpty } from "lodash";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";

const worklistTransferSchema = {
  model: "worklist",
  fields: [
    {
      displayName: "Source Plate",
      path: "sourcePlateName",
      render: value => (!value ? "Tube is Unassigned" : value)
    },
    {
      displayName: "Source Position",
      path: "sourceAliquotContainer",
      render: value => getAliquotContainerLocation(value)
    },
    {
      displayName: "Destination Plate",
      path: "destinationPlateName"
    },
    {
      displayName: "Destination Position",
      path: "destinationAliquotContainer",
      render: value => getAliquotContainerLocation(value)
    },
    {
      displayName: "Transfer Volume",
      path: "volume",
      render: volumeRender
    }
  ]
};

const ReviewWorklists = props => {
  const {
    aliquotContainerTypes = [],
    destinationAliquotExtendedProperties = [],
    destinationContainerArrayExtendedProperties = [],
    destinationContainerArrayExtendedValues = [],
    destinationAliquotExtendedValues = [],
    Footer,
    footerProps,
    stepFormProps: { change },
    worklists = []
  } = props;

  const saveExtendedPropertyToForm = (val, model) => {
    const fieldName = `${model}ExtendedValues`;
    const existingValues = props[fieldName] || [];
    change(fieldName, [...existingValues, val]);
  };

  const removeExtendedProperty = (record, model) => {
    const fieldName = `${model}ExtendedValues`;
    const existingValues = props[fieldName] || [];
    change(
      fieldName,
      existingValues.filter(
        val => val.extendedProperty.id !== record.extendedProperty.id
      )
    );
  };

  const sortWorklistCb = (sortedWorklist, index) => {
    const newWorklists = [...worklists];
    newWorklists[index] = sortedWorklist;
    change("worklists", newWorklists);
  };

  const { errors, valid } = useMemo(() => {
    const errors = {};
    worklists.forEach((worklist, i) => {
      const error = validateTransfers(worklist, aliquotContainerTypes);
      if (error) {
        errors[i] = error;
      }
    });
    const valid = isEmpty(errors);
    return { errors, valid };
  }, [aliquotContainerTypes, worklists]);

  const containerArrayExtendedValuesEntities = useMemo(
    () => transformExtendedValues(destinationContainerArrayExtendedValues),
    [destinationContainerArrayExtendedValues]
  );

  const aliquotExtendedValuesEntities = useMemo(
    () => transformExtendedValues(destinationAliquotExtendedValues),
    [destinationAliquotExtendedValues]
  );

  const extendedPropertes = (
    <AdvancedOptions>
      {(!!destinationAliquotExtendedProperties.length ||
        !!destinationContainerArrayExtendedProperties.length) && (
        <div className="tg-step-form-section column">
          <>
            <HeaderWithHelper
              header="Remove Existing Extended Properties from Destination Aliquots and Plates"
              helper="The selected destination plates and/or aliquots already have the
                  extended properties displayed below. Check the corresponding
                  box to delete any extended property upon executing the worklist."
              width="100%"
            />
            <div
              className="tg-flex justify-space-between"
              style={{ marginBottom: 20 }}
            >
              {!!destinationContainerArrayExtendedProperties.length && (
                <div style={{ width: "45%" }}>
                  <h6>Plates</h6>
                  <DataTable
                    entities={destinationContainerArrayExtendedProperties}
                    schema={["name"]}
                    maxHeight={300}
                    destroyOnUnmount={false}
                    isSimple
                    withCheckboxes
                    compact
                    formName="destinationContainerArrayExtendedPropertiesToRemove"
                  />
                </div>
              )}
              <div className="tg-flex-separator" />
              {!!destinationAliquotExtendedProperties.length && (
                <div style={{ width: "45%" }}>
                  <h6>Aliquots</h6>
                  <DataTable
                    entities={destinationAliquotExtendedProperties}
                    schema={["name"]}
                    maxHeight={300}
                    destroyOnUnmount={false}
                    isSimple
                    withCheckboxes
                    compact
                    formName="destinationAliquotExtendedPropertiesToRemove"
                  />
                </div>
              )}
            </div>
          </>
        </div>
      )}
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Add Extended Properties"
          helper="Add extended properties to selected plates and
              aliquots below."
          width="100%"
        />
        <div
          className="tg-flex justify-space-between"
          style={{ marginBottom: 20 }}
        >
          <div style={{ width: "45%", height: "100%" }}>
            <h6>Plates</h6>
            <AddExtendedProperty
              model="containerArray"
              fullValueFields
              noLinkProperties
              formName="addExtendedPropertiesPlatePrepToolContainerArray"
              extendedPropertyIdsToIgnore={destinationContainerArrayExtendedValues.map(
                val => val.extendedProperty.id
              )}
              onSubmitValue={val =>
                saveExtendedPropertyToForm(val, "destinationContainerArray")
              }
            />
            {!!containerArrayExtendedValuesEntities.length && (
              <div style={{ minHeight: 200, marginTop: 20 }}>
                <RecordExtendedPropertiesTable
                  isSimple
                  compact
                  noEdit
                  onDelete={record =>
                    removeExtendedProperty(record, "destinationContainerArray")
                  }
                  entities={containerArrayExtendedValuesEntities}
                />
              </div>
            )}
          </div>
          <div className="tg-flex-separator" />
          <div style={{ width: "45%", height: "100%" }}>
            <h6>Aliquots</h6>
            <AddExtendedProperty
              model="aliquot"
              fullValueFields
              noLinkProperties
              formName="addExtendedPropertiesPlatePrepToolAliquot"
              extendedPropertyIdsToIgnore={destinationAliquotExtendedValues.map(
                val => val.extendedProperty.id
              )}
              onSubmitValue={val =>
                saveExtendedPropertyToForm(val, "destinationAliquot")
              }
            />
            {!!aliquotExtendedValuesEntities.length && (
              <div style={{ minHeight: 200, marginTop: 20 }}>
                <RecordExtendedPropertiesTable
                  isSimple
                  compact
                  noEdit
                  onDelete={record =>
                    removeExtendedProperty(record, "destinationAliquot")
                  }
                  entities={aliquotExtendedValuesEntities}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </AdvancedOptions>
  );

  return (
    <>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Review Worklists"
          helper="Enter a worklist name and review the transfers below."
        />
        {worklists.map((worklist, i) => {
          return (
            <div key={i}>
              <ReviewWorklistSection
                noHeader
                worklist={worklist}
                fieldName={`worklistNames.${i}`}
                tableFormName={`platePrepReviewWorklists${i}`}
                onSort={(fieldName, sortedWorklist) =>
                  sortWorklistCb(sortedWorklist, i)
                }
                schema={worklistTransferSchema}
              />
              <BlueprintError error={errors[i]} />
            </div>
          );
        })}
        {extendedPropertes}
      </div>
      <Footer
        {...footerProps}
        text="Generate Worklist"
        disabled={!valid}
        loading={footerProps.submitting}
      />
    </>
  );
};

export default compose(
  withQuery(aliquotContainerTypeFragment, {
    isPlural: true,
    showLoading: true,
    inDialog: true,
    options: {
      variables: {
        pageSize: 20000
      }
    }
  }),
  stepFormValues(
    "worklists",
    "destinationAliquotExtendedProperties",
    "destinationContainerArrayExtendedProperties",
    "destinationContainerArrayExtendedValues",
    "destinationAliquotExtendedValues"
  )
)(ReviewWorklists);
