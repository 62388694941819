/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState, useMemo } from "react";
import { Router } from "react-router-dom";
import { useHotkeys } from "@blueprintjs/core";
import { AssignDefaultsModeContext } from "@teselagen/ui";
import AssignDefaultsModeUi from "../../AssignDefaultsModeUi";
import { getOpenDialogType, hideDialog, showDialog } from "../../GlobalDialog";
import { isAdmin } from "../../utils/generalUtils";
import { createBrowserHistory } from "history";
import { createNotification } from "../../utils/createNotification";
import { AppContentManager } from "../AppContentManager";
import { appHotkeySets } from "../../appHotKeys";

const hist =
  window.browserHistory || createBrowserHistory({ basename: "client" });
if (process.env.NODE_ENV === "development" && !window.browserHistory) {
  window.browserHistory = hist;
}

if (window.Cypress) {
  window.Cypress.tgHistory = hist;
  window.Cypress.tg_cy_createNotification = createNotification;
}

export const AppInner = ({
  Alert,
  Helper,
  mainStore,
  modalComponents,
  mobXModalComponents
}) => {
  const [inAssignDefaultsMode, setAssignDefaultsMode] = useState(false);

  // important: hotkeys array must be memoized to avoid infinitely re-binding hotkeys
  const hotkeys = useMemo(
    () => [
      {
        combo: appHotkeySets.General.showKeyboardShortcuts,
        label: "Show Keyboard Shortcuts",
        global: true,

        onKeyDown: () => {
          if (getOpenDialogType() === "HotkeysDialog") {
            return hideDialog();
          }
          return showDialog({
            modalType: "HotkeysDialog",
            modalProps: {
              hotkeySets: appHotkeySets,
              isOpen: true,
              onClose: hideDialog
            }
          });
        }
      },
      {
        global: true,
        combo: "shift+d",
        label: "Toggle Assign Defaults Mode",
        onKeyDown: e => {
          e.preventDefault();
          e.stopPropagation();
          if (!isAdmin()) return;
          setAssignDefaultsMode(prev => !prev);
        }
      }
    ],
    []
  );

  useHotkeys(hotkeys);

  return (
    <AssignDefaultsModeContext.Provider
      value={{
        inAssignDefaultsMode,
        setAssignDefaultsMode
      }}
    >
      <Router history={hist}>
        <div tabIndex={0} style={{ width: "100vw", height: "100vh" }}>
          {inAssignDefaultsMode && (
            <AssignDefaultsModeUi
              inAssignDefaultsMode={inAssignDefaultsMode}
              setAssignDefaultsMode={setAssignDefaultsMode}
            />
          )}
          <AppContentManager
            Alert={Alert}
            Helper={Helper}
            mainStore={mainStore}
            modalComponents={modalComponents}
            mobXModalComponents={mobXModalComponents}
          />
        </div>
      </Router>
    </AssignDefaultsModeContext.Provider>
  );
};
