/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { reduxForm } from "redux-form";
import React, { useMemo } from "react";
import {
  InputField,
  DateInputField,
  TextareaField,
  DialogFooter,
  wrapDialog
} from "@teselagen/ui";
import classNames from "classnames";
import { Classes } from "@blueprintjs/core";
import GenericSelect from "../../../../src-shared/GenericSelect";
import { onSubmitCreate, onSubmitEdit } from "./submit";
import { compose } from "recompose";
import UserSelect from "../../../../src-shared/UserSelect";
import defaultValueConstants from "../../../../../tg-iso-shared/src/defaultValueConstants";
import "./style.css";

const priorityDefaultValue = {
  code: "NORMAL",
  name: "Normal"
};

const LaunchNewJobDialog = ({
  initialValues = {},
  hideModal,
  history,
  refetchCustomerRequest,
  returnJob,
  refetch,
  currentUser,
  handleSubmit,
  customerRequest = {},
  submitting
}) => {
  const onSubmit = formData => {
    const submitFunction = initialValues.id ? onSubmitEdit : onSubmitCreate;
    return submitFunction(formData, {
      hideModal,
      history,
      refetchCustomerRequest,
      returnJob,
      initialValues,
      refetch
    });
  };

  const defaultTeamMembers = useMemo(
    () => (customerRequest.customers || []).map(c => c.user),
    [customerRequest.customers]
  );

  return (
    <div>
      <div className={classNames(Classes.DIALOG_BODY, "tg-flex auto")}>
        <div>
          <InputField
            label="Name"
            name="name"
            isRequired
            generateDefaultValue={{
              ...defaultValueConstants.JOB_NAME,
              customParams: {
                userRequestName: customerRequest.name
                  ? customerRequest.name
                  : "Untitled Job"
              }
            }}
            placeholder="My job"
          />
          <DateInputField
            defaultValue={new Date()}
            label="Start Date"
            name="startDate"
            minDate={initialValues ? undefined : new Date()}
          />
          <GenericSelect
            name={"customerRequests"}
            isMultiSelect
            asReactSelect
            label="User Requests"
            fragment={["customerRequest", "id name"]}
          />
          <GenericSelect
            name="priorityType"
            asReactSelect
            idAs="code"
            label="Priority"
            schema={["name"]}
            defaultValue={priorityDefaultValue}
            fragment={["priorityType", "code name"]}
          />
        </div>
        <div className="tg-flex-separator" />
        <div>
          <UserSelect
            isRequired
            name="jobOwner"
            label="Owner"
            defaultValue={currentUser}
          />
          <DateInputField
            defaultValue={new Date()}
            label="Due Date"
            name="dueDate"
            minDate={initialValues ? undefined : new Date()}
          />
          <UserSelect
            name="jobUsers"
            label="Team Members"
            defaultValue={defaultTeamMembers}
            isMultiSelect
          />
          <TextareaField
            name="description"
            label="Description"
            generateDefaultValue={{
              ...defaultValueConstants.JOB_DESCRIPTION,
              customParams: {
                userRequestDescription: customerRequest.outputDescription || ""
              }
            }}
          />
        </div>
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
      />
    </div>
  );
};

const style = {
  width: "fit-content"
};

export default compose(
  wrapDialog(({ initialValues }) => {
    if (initialValues && initialValues.id) {
      return {
        title: "Update Job",
        style
      };
    } else {
      return {
        title: "New Job",
        style
      };
    }
  }),
  reduxForm({
    enableReinitialize: true,
    form: "launchNewJobForm",
    validate
  })
)(LaunchNewJobDialog);

function validate(values) {
  const errors = {};
  if (new Date(values.startDate) > new Date(values.dueDate)) {
    errors.startDate = "Start date can not be later than due date.";
  }
  if (new Date(values.dueDate) < new Date(values.startDate)) {
    errors.dueDate = "Due date must be after start date.";
  }
  return errors;
}
