/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import { taggedItemFragment } from "./taggedItemFragment.gql";

export const additiveMaterialFragment = gql`
  fragment additiveMaterialFragment on additiveMaterial {
    id
    name
    additiveType {
      code
      name
    }
    updatedAt
    taggedItems {
      ...taggedItemFragment
    }
  }
  ${taggedItemFragment}
`;

export const containerArrayFragment = gql`
  fragment containerArrayFragment on containerArray {
    id
    name
    barcode {
      id
      barcodeString
    }
    updatedAt
    taggedItems {
      ...taggedItemFragment
    }
  }
  ${taggedItemFragment}
`;

export const j5AssemblyPiecePlateMapFragment = gql`
  fragment j5AssemblyPiecePlateMapFragment on j5AssemblyPiece {
    id
    name
    sequence {
      id
      polynucleotideMaterial {
        id
        name
      }
    }
    j5Report {
      id
      name
    }
    updatedAt
  }
`;

export const j5InputSequenceFragment = gql`
  fragment j5InputSequenceFragment on j5InputSequence {
    id
    sequence {
      id
      name
      polynucleotideMaterial {
        id
        name
      }
    }
    j5Report {
      id
      name
    }
    updatedAt
  }
  ${taggedItemFragment}
`;

export const j5MaterialFragment = gql`
  fragment j5MaterialFragment on material {
    id
    name
    materialTypeCode
    materialType {
      code
      name
    }
    updatedAt
    taggedItems {
      ...taggedItemFragment
    }
  }
  ${taggedItemFragment}
`;

export const j5PcrReactionFragment = gql`
  fragment j5PcrReactionFragment on j5PcrReaction {
    id
    name
    primaryTemplate {
      id
      name
    }
    forwardPrimer {
      id
      name
    }
    reversePrimer {
      id
      name
    }
    pcrProductSequence {
      id
      name
      size
    }
    j5Report {
      id
      name
    }
    updatedAt
  }
`;

export const j5ReportFragment = gql`
  fragment j5ReportFragment on j5Report {
    id
    name
    isHierarchical
    outputCardName
    assemblyBatchId
    idFromOriginalAssemblyBatch
    treePosition
    updatedAt
    taggedItems {
      ...taggedItemFragment
    }
  }
  ${taggedItemFragment}
`;

export const j5RunConstructFragment = gql`
  fragment j5RunConstructFragment on j5RunConstruct {
    id
    name
    sequence {
      id
      polynucleotideMaterial {
        id
        name
      }
    }
    j5Report {
      id
      name
    }
    updatedAt
  }
`;

export const lotFragment = gql`
  fragment lotFragment on lot {
    id
    name
    additiveMaterial {
      id
      name
    }
    updatedAt
    taggedItems {
      ...taggedItemFragment
    }
  }
  ${taggedItemFragment}
`;

export const materialFragment = gql`
  fragment materialFragment on material {
    id
    name
    materialTypeCode
    materialType {
      code
      name
    }
    updatedAt
    taggedItems {
      ...taggedItemFragment
    }
  }
  ${taggedItemFragment}
`;

export const pcrProductSequenceFragment = gql`
  fragment pcrProductSequenceFragment on sequence {
    id
    name
    polynucleotideMaterial {
      id
      name
    }
    j5PcrReactionsPCRProductSequences {
      id
      j5Report {
        id
        name
      }
    }
    updatedAt
    taggedItems {
      ...taggedItemFragment
    }
  }
  ${taggedItemFragment}
`;

export const plateMapFragment = gql`
  fragment plateMapFragment on plateMap {
    id
    name
    type
    plateMapGroup {
      id
      name
      containerFormat {
        code
        name
        rowCount
        columnCount
      }
    }
    updatedAt
  }
`;

export const primaryTemplateFragment = gql`
  fragment primaryTemplateFragment on sequence {
    id
    name
    polynucleotideMaterial {
      id
      name
    }
    j5PcrReactionsPrimaryTemplates {
      id
      j5Report {
        id
        name
      }
    }
    updatedAt
    taggedItems {
      ...taggedItemFragment
    }
  }
  ${taggedItemFragment}
`;

export const primerFragment = gql`
  fragment primerFragment on j5Oligo {
    id
    name
    sequence {
      id
      polynucleotideMaterial {
        id
        name
      }
    }
    j5PcrReactionsForwardPrimers {
      id
      j5Report {
        id
        name
      }
    }
    j5PcrReactionsReversePrimers {
      id
      j5Report {
        id
        name
      }
    }
    updatedAt
  }
`;

export const reactionMapFragment = gql`
  fragment reactionMapFragment on reactionMap {
    id
    name
    reactionTypeCode
    reactionType {
      code
      name
    }
    updatedAt
    taggedItems {
      ...taggedItemFragment
    }
  }
  ${taggedItemFragment}
`;

export const sampleFragment = gql`
  fragment sampleFragment on sample {
    id
    name
    sampleType {
      code
      name
    }
    updatedAt
    taggedItems {
      ...taggedItemFragment
    }
  }
  ${taggedItemFragment}
`;
