/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import { CheckboxField, InputField, RadioGroupField } from "@teselagen/ui";
import SortableListField from "../../../SortableListField";
import DisplayField from "../../../DisplayField";
import { useSelector } from "react-redux";
import { formValueSelector } from "redux-form";

export const minimalConcatenateWorklistFragment = [
  "worklist",
  "id name updatedAt"
];

const SelectWorklists = ({
  toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
  toolSchema: { code }
}) => {
  const { worklists, consolidate } = useSelector(state =>
    formValueSelector(code)(state, "worklists", "consolidate")
  );
  return (
    <div>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select Worklists"
          helper="Select worklists you would like to concatenate."
        />
        <GenericSelect
          name="worklists" //the field name within the redux form Field
          schema={["name", dateModifiedColumn]}
          buttonProps={{
            loading: isLoadingMap.worklists,
            disabled: isDisabledMap.worklists
          }}
          isMultiSelect
          isRequired
          fragment={minimalConcatenateWorklistFragment}
        />
      </div>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Name and Order Worklists"
          helper="Choose a name for the new worklist and choose an order for the concatenation."
        />
        <div style={{ maxWidth: 350 }}>
          <InputField
            isRequired
            label="Concatenated Worklist Name"
            name="worklistName"
          />
        </div>
        <div>
          <CheckboxField
            label="Consolidate duplicate transfers"
            name="consolidate"
            tooltipInfo="Checking this will remove all duplicate transfers from the worklist (Transfers with the same source and destination)"
          />
          <RadioGroupField
            inline
            disabled={!consolidate}
            label="Consolidate Method"
            name="consolidateMethod"
            defaultValue="combineVolume"
            options={[
              {
                label: "Combine volumes of duplicate transfers",
                value: "combineVolume"
              },
              {
                label: "Only keep first transfer volume",
                value: "keepVolume"
              }
            ]}
          />
        </div>
        <div>
          <SortableListField
            name="worklists"
            worklists={worklists}
            ListItemComp={ListItemComp}
            noRemove
          />
        </div>
      </div>
    </div>
  );
};

function ListItemComp({ field, index }) {
  return (
    <>
      <div style={{ marginLeft: 15 }}>{index + 1}.</div>
      <h6 style={{ marginBottom: 0, marginLeft: 5, padding: 15 }}>
        <DisplayField name={`${field}.name`} />
      </h6>
    </>
  );
}

export default SelectWorklists;
