/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { get } from "lodash";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import {
  DialogFooter,
  InputField,
  TextareaField,
  wrapDialog
} from "@teselagen/ui";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { compose } from "recompose";

// The props `ruleSet` and `refetchRuleSet` will be passed if and only if we are editing an
// existing rule set.
const CreateDesignRuleSetDialog = ({
  handleSubmit,
  hideModal,
  history,
  ruleSet,
  refetchRuleSet
}) => {
  const onSubmit = async values => {
    try {
      const [{ id }] = await safeUpsert("ruleSet", {
        name: values.name,
        description: values.description,
        ...(ruleSet ? { id: ruleSet.id } : {})
      });
      hideModal();
      if (ruleSet) {
        await refetchRuleSet();
      } else {
        history.push("/design-rulesets/" + id);
      }
    } catch (e) {
      console.error(e);
      window.toastr.error("Error creating design rule set.");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={Classes.DIALOG_BODY}>
        <InputField
          name="name"
          label="Design Ruleset Name"
          defaultValue={get(ruleSet, "name")}
        />
        <TextareaField
          name="description"
          label="Description"
          defaultValue={get(ruleSet, "description")}
        />
      </div>
      <DialogFooter hideModal={hideModal} text="OK" />
    </form>
  );
};

const validate = ({ name }) => ({
  name: name ? null : "Required"
});

export default compose(
  wrapDialog(({ ruleSet }) => {
    return {
      title: ruleSet ? "Edit Design Ruleset" : "Create New Design Ruleset",
      style: {
        width: 400
      }
    };
  }),
  reduxForm({
    form: "createNewDesignRuleSetDialogForm", // a unique name for this form
    enableReinitialize: true,
    validate
  })
)(CreateDesignRuleSetDialog);
