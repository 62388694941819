/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

export type StateType = {
  ui: {
    records: {
      containerArray: {
        selectedAliquotContainerLocations: any;
      };
    };
  };
};

export const containerArraySelectedAliquotContainerLocationsSelector = (
  state: StateType
) => state.ui.records.containerArray.selectedAliquotContainerLocations || [];
