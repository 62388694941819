/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState, useEffect, useCallback } from "react";
import { get, size, groupBy } from "lodash";
import {
  Button,
  ButtonGroup,
  Intent,
  Icon,
  Position,
  Popover,
  Menu,
  MenuItem,
  Divider
} from "@blueprintjs/core";
import { Loading } from "@teselagen/ui";
import Spreadsheet from "react-spreadsheet";
import { showDialog } from "../../../GlobalDialog";
import NewMappingPresetDialog from "../components/newMappingPresetDialog";
import UseMappingPresetDialog from "../components/useMappingPresetDialog";
import SmartMapperDialog from "../../../components/Dialogs/smartMapperDialog";
import HeaderWithHelper from "../../../HeaderWithHelper";
import {
  getClassEntity,
  getSubClasses,
  getMappingPresets
} from "../../../utils/experimentData";
import {
  buildGridHeaderRows,
  buildGridDataRows,
  ColumnIndicator,
  rowLabels,
  getMappingSuggestion,
  mappingValidator
} from "../../../utils/experimentData/dataMappingUtils";
import { useFormValue } from "../../../hooks/useFormValue";
import { change as _change } from "redux-form";
import { useDispatch } from "react-redux";

const CLASSES = [
  "assaySubjectClass",
  "descriptorType",
  "measurementType",
  "referenceDimension"
  // "computedValueType"
];

const MAX_ROWS_FOR_DATA_MAPING_TABLE = 25;

const DataMapping = ({
  Footer,
  footerProps,
  toolSchema: { code },
  setActiveStep
}) => {
  const dispatch = useDispatch();
  const change = useCallback(
    (field, value) => dispatch(_change(code, field, value)),
    [code, dispatch]
  );

  const headerNames = useFormValue(code, "headerNames");
  const headerClasses = useFormValue(code, "headerClasses");
  const headerSubClasses = useFormValue(code, "headerSubClasses");
  const subjectLinkingIssues = useFormValue(code, "subjectLinkingIssues");
  const selectedDataGrid = useFormValue(code, "selectedDataGrid");
  const mappingPresetName = useFormValue(code, "mappingPresetName");
  const submitting = useFormValue(code, "submitting");

  // data grid spreadsheet views states.
  const [gridDataViews, setGridDataViews] = useState();

  // Header states.
  const headerStates = [headerNames, headerClasses, headerSubClasses];

  // Mapper states
  const [mappingPresets, setMappingPresets] = useState([]);
  const [isMappingValid, setIsMappingValid] = useState(undefined);
  const [mappingValidated, setMappingValidated] = useState(undefined);
  const [suggestingMapping, setSuggestingMapping] = useState(false);
  // const [showSubjectMapping, setShowSubjectMapping] = useState(false);
  const [validationErrorMessages, setValidationErrorMessages] = useState([]);
  const [classSubClasses, setClassSubClasses] = useState([]);
  const [unitsByDimension, setUnitsByDimension] = useState([]);
  const [validating, setValidating] = useState(false);
  const [loading, setLoading] = useState(true);

  const getClassSubclasses = async () => {
    const classToSubClassesMap = {};
    for (let index = 0; index < CLASSES.length; index++) {
      const _class = CLASSES[index];
      const response = await getSubClasses(getClassEntity(_class), {
        expanded: true
      });
      const subClasses = response.data;
      classToSubClassesMap[_class] = subClasses;
    }
    setClassSubClasses(classToSubClassesMap);
    setLoading(false);
  };

  // NOTE: cldrojas, we'll probably need to call this function when creating new units on-the-fly.
  const getUnits = async () => {
    const response = await getSubClasses("unit", { expanded: true });
    const units = response.data;
    const unitsByDimension = groupBy(units, "unitScale.unitDimension.id");
    setUnitsByDimension(unitsByDimension);
  };

  const applyMapper = useCallback(
    selectedMapper => {
      const headerClasses = {};
      const headerSubClasses = {};
      selectedMapper.header.forEach(header => {
        const name = header.name;
        const _class = {
          name: header.class,
          label: header.className,
          subClasses: classSubClasses[getClassEntity(header.class)]
        };
        const _subClass = {
          id: header.subClassId || header.subClass,
          name: header.subClassName
        };

        headerClasses[name] = _class;
        headerSubClasses[name] = _subClass;
      });

      change("headerClasses", currentValue => ({
        ...currentValue,
        ...headerClasses
      }));
      change("headerSubClasses", currentValue => ({
        ...currentValue,
        ...headerSubClasses
      }));
      change("mappingPresetName", selectedMapper.name);
    },
    [change, classSubClasses]
  );

  // NOTE: Spreadsheet doesnt work with React memoization hooks...
  const handleHeaderClassSelection = useCallback(
    (_class, headerName) => {
      // When updating a column's class, its subClass must be restarted.
      change("headerSubClasses", currentValue => ({
        ...currentValue,
        [headerName]: undefined
      }));
      change("headerClasses", currentValue => ({
        ...currentValue,
        [headerName]: _class
      }));
      change("mappingPresetName", undefined);
    },
    [change]
  );

  const handleHeaderSubClassSelection = useCallback(
    (_subClass, headerName) => {
      getClassSubclasses();
      change("headerSubClasses", currentValue => ({
        ...currentValue,
        [headerName]: _subClass
      }));
      change("mappingPresetName", undefined);
    },
    [change]
  );

  const clearValidation = useCallback(() => {
    setMappingValidated(false);
    setValidationErrorMessages([]);
    setIsMappingValid(false);
  }, []);

  // NOTE: on component mount, query for the different subclasses.
  useEffect(() => {
    getClassSubclasses();
    getUnits();
  }, []);

  useEffect(() => {
    // Every time any of the mapping select headers is updated, we should reset the validation states.
    clearValidation();

    const headerRow = get(selectedDataGrid, "dataCells").filter(
      cell => cell.rowPosition === 0
    );

    const headerViews = buildGridHeaderRows(headerRow);

    const cellViews = buildGridDataRows(
      get(selectedDataGrid, "dataCells").filter(
        cell =>
          cell.rowPosition > 0 &&
          cell.rowPosition <= MAX_ROWS_FOR_DATA_MAPING_TABLE
      )
    );

    const dataGridViews = [headerViews, ...cellViews];

    setGridDataViews(dataGridViews);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...headerStates]);

  return (
    <>
      {gridDataViews && (
        <>
          <div className="tg-step-form-section column">
            <div className="tg-flex align-flex-start column">
              <HeaderWithHelper
                header="Map data columns"
                helper="Please map each column with the appropriate TEST metadata class and subclass. You can also choose one of your own saved mappers or use TeselaGen's Smart Mapper suggestions."
              />
              {loading ? (
                <div style={{ padding: 50, alignSelf: "center" }}>
                  <Loading />
                </div>
              ) : (
                <>
                  <ButtonGroup minimal={true} className="Datamap__button_bar">
                    <Button
                      icon="floppy-disk"
                      onClick={() => {
                        showDialog({
                          ModalComponent: NewMappingPresetDialog,
                          modalProps: {
                            headerStates
                          }
                        });
                      }}
                    >
                      Save mapper
                    </Button>
                    <Divider />
                    <Popover
                      onOpening={async () => {
                        const mappingPresets = await getMappingPresets();
                        // TODO: Only mappingPresets with header prop can be used to suggest mappings for now
                        // https://github.com/TeselaGen/lims/issues/11687
                        setMappingPresets(
                          mappingPresets.filter(preset => preset.header)
                        );
                      }}
                      content={
                        <Menu>
                          {size(mappingPresets) > 0 ? (
                            mappingPresets.map(preset => (
                              <MenuItem
                                key={preset.id}
                                // icon={reformattingPreset.id === preset.id && "small-tick"}
                                text={preset.name}
                                // disabled={reformattingPreset.id === preset.id}
                                onClick={async () => {
                                  showDialog({
                                    ModalComponent: UseMappingPresetDialog,
                                    modalProps: {
                                      headers: headerStates[0],
                                      preset,
                                      submitMappings: applyMapper
                                    }
                                  });
                                }}
                              />
                            ))
                          ) : (
                            <MenuItem
                              text="No mappers found..."
                              disabled={true}
                            />
                          )}
                        </Menu>
                      }
                      position={Position.BOTTOM}
                    >
                      <Button
                        icon={mappingPresetName ? "tick-circle" : "property"}
                        intent={mappingPresetName && Intent.SUCCESS}
                        rightIcon="caret-down"
                      >
                        {mappingPresetName || "Mappers"}
                      </Button>
                    </Popover>
                    <Divider />
                    <Button
                      // TODO: this icon looked good for the "smart" mapper,
                      // we could eventually change it for another one.
                      icon="clean"
                      loading={suggestingMapping}
                      onClick={async () => {
                        setSuggestingMapping(true);
                        const mappings =
                          await getMappingSuggestion(headerNames);
                        setSuggestingMapping(false);
                        showDialog({
                          ModalComponent: SmartMapperDialog,
                          modalProps: {
                            mappings: mappings.map(el => ({
                              ...el,
                              disabled: !el.hasSuggestion
                            })),
                            submitMappings: mappings =>
                              applyMapper({
                                header: mappings.filter(
                                  mapping => mapping.accepted
                                )
                              })
                          }
                        });
                      }}
                    >
                      Use smart mapper
                    </Button>
                    <Divider />
                    <Button
                      // TODO: this icon looked good for the "smart" mapper,
                      // we could eventually change it for another one.
                      icon="eraser"
                      onClick={() => {
                        change("headerClasses", undefined);
                        change("headerSubClasses", undefined);
                        change("mappingPresetName", undefined);
                      }}
                    >
                      Reset mapping
                    </Button>
                  </ButtonGroup>

                  <div className="Datamap__container">
                    <Spreadsheet
                      data={gridDataViews}
                      rowLabels={rowLabels(gridDataViews.length)}
                      // we are going to leverage react-spreadsheet column indicators
                      // for our column mapping selectors.
                      ColumnIndicator={({ column }) => {
                        const headerName = headerNames[column]?.trim();
                        // This shouldnt happen, but just in case.
                        if (!headerName) return null;
                        return (
                          <ColumnIndicator
                            column={column}
                            unitsByDimension={unitsByDimension}
                            headerName={headerName}
                            headerClasses={headerClasses}
                            headerSubClasses={headerSubClasses}
                            classSubClasses={classSubClasses}
                            getUnits={getUnits}
                            handleHeaderClassSelection={
                              handleHeaderClassSelection
                            }
                            handleHeaderSubClassSelection={
                              handleHeaderSubClassSelection
                            }
                            withValidation={mappingValidated}
                          />
                        );
                      }}
                    />
                  </div>
                  <HeaderWithHelper
                    width="100%"
                    helper={
                      <span>
                        Only the first few rows are shown as a reference. All
                        rows can be reviewed in the
                        <a href="#" onClick={() => setActiveStep(0)}>
                          {" "}
                          Data Selection Step.
                        </a>
                      </span>
                    }
                  />
                </>
              )}
              <>
                <Button
                  className="Datamap__validate_mapping_button"
                  intent={Intent.PRIMARY}
                  text="Validate"
                  disabled={mappingValidated}
                  onClick={async () => {
                    setValidating(true);
                    const validationErrors = await mappingValidator(
                      headerNames,
                      headerClasses,
                      headerSubClasses,
                      gridDataViews,
                      get(selectedDataGrid, "dataCells")
                    );
                    setValidating(false);
                    setValidationErrorMessages(validationErrors);
                    if (size(validationErrors) === 0) setIsMappingValid(true);
                    else setIsMappingValid(false);
                    setMappingValidated(true);
                  }}
                  loading={validating}
                />
                {mappingValidated && (
                  <div style={{ alignSelf: "flex-end" }}>
                    {!isMappingValid ? (
                      validationErrorMessages.map((validationMessage, idx) => (
                        <div key={`validation-error-message-${idx}`}>
                          <br />
                          <span
                            id="EvolveConfig.cypressTags.STEP_1_DATA_VALIDATION_WARNING_ID"
                            style={{ fontSize: "12px", color: "red" }}
                          >
                            {" "}
                            <Icon iconSize={16} icon="warning-sign" />{" "}
                            {validationMessage}{" "}
                          </span>
                        </div>
                      ))
                    ) : (
                      <div key="validation-success-message">
                        <p />
                        <br />
                        <span style={{ fontSize: "12px" }}>
                          {" "}
                          <Icon
                            intent={Intent.SUCCESS}
                            iconSize={16}
                            icon="tick-circle"
                          />{" "}
                          {"Validated. Ready for submission."}{" "}
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </>
            </div>
          </div>
          {/* {showSubjectMapping ? (
            <div className="tg-step-form-section column">
              {renderSubjectMapping}
            </div>
          ) : null} */}
        </>
      )}
      <Footer
        {...footerProps}
        nextButton={
          <Button
            type="submit"
            intent={Intent.SUCCESS}
            text="Submit"
            loading={submitting}
            disabled={
              !isMappingValid || size(subjectLinkingIssues)
              // (showSubjectMapping && size(subjectLinkingIssues))
            }
          />
        }
      />
    </>
  );
};

export default DataMapping;
