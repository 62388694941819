/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useCallback, useEffect, useMemo } from "react";
import { DataTable, useTableEntities, useTableParams } from "@teselagen/ui";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import { constructSelectionConstructFragment } from "../../../../graphql/fragments/constructSelectionJ5ReportFragment";
import { constructsTableName } from "../constants";
import { addInInventoryFilter, getBuildableConstructIds } from "../utils";
import InventoryCheckButton from "../../../InventoryCheckButton";
import InInventorySelectFilter from "../InInventorySelectFilter";
import { safeQuery, useTgQuery } from "../../../../../src-shared/apolloMethods";
import { useFormValue } from "../../../../../src-shared/hooks/useFormValue";

const schema = {
  model: "j5RunConstructView",
  fields: [
    {
      displayName: "Name",
      path: "name"
    },
    {
      type: "number",
      displayName: "Size",
      path: "size"
    },
    {
      path: "containerCount",
      width: 130,
      displayName: "Inventory",
      filterDisabled: true,
      noEllipsis: true,
      immovable: true,
      render: (v, r) => {
        if (v <= 0) return "Not in Inventory";
        return (
          <InventoryCheckButton
            record={{
              id: r.id,
              __typename: "j5RunConstruct"
            }}
          />
        );
      }
    }
  ]
};

const Constructs = ({ toolSchema }) => {
  const j5Reports = useFormValue(toolSchema.code, "j5Reports");
  const finalizedAPIds = useFormValue(toolSchema.code, "finalizedAPIds");

  const buildableConstructIds = useMemo(
    () =>
      getBuildableConstructIds({
        assemblyPieceIds: finalizedAPIds || [],
        j5Reports
      }) || [],
    [finalizedAPIds, j5Reports]
  );

  const additionalFilter = useCallback(
    (_, qb, currentParams) => {
      const filter = {
        id: buildableConstructIds
      };
      addInInventoryFilter(qb, currentParams);
      qb.whereAll(filter);
    },
    [buildableConstructIds]
  );

  const { tableParams: _tableParams, variables } = useTableParams({
    formName: constructsTableName,
    urlConnected: false,
    schema,
    additionalFilter
  });

  const { entities, loading, refetch } = useTgQuery(
    constructSelectionConstructFragment,
    {
      isPlural: false,
      variables
    }
  );

  const tableParams = useMemo(() => {
    if (!loading && entities && entities.length) {
      return {
        ..._tableParams,
        isLoading: loading,
        entities,
        entityCount: entities.length,
        onRefresh: refetch,
        variables,
        fragment: constructSelectionConstructFragment
      };
    }
    return _tableParams;
  }, [_tableParams, entities, loading, refetch, variables]);

  const { selectedEntities: selectedConstructs, selectTableEntities } =
    useTableEntities(constructsTableName);

  useEffect(() => {
    const ids = Object.keys(selectedConstructs || {});
    const buildableSelectedConstructs = ids.filter(id =>
      buildableConstructIds.includes(id)
    );
    if (ids.length !== buildableSelectedConstructs.length) {
      selectTableEntities(buildableSelectedConstructs);
    }
  }, [selectedConstructs, buildableConstructIds, selectTableEntities]);

  return (
    <div>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          width="100%"
          header="Buildable Constructs"
          helper="The following constructs are buildable based on the selection of assembly pieces above. Select the constructs you would like to build."
        />
        <DataTable
          {...tableParams}
          className="tg-buildable-constructs-table"
          maxHeight={400}
          keepDirtyOnReinitialize
          withSelectAll
          safeQuery={safeQuery}
          withCheckboxes
        >
          <InInventorySelectFilter
            name="constructInventoryFilter"
            setNewParams={tableParams.setNewParams}
            selectTableRecords={selectTableEntities}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default Constructs;
