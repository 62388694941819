/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { useMemo } from "react";
import { extendedPropertyLinkableModels } from "../../../tg-iso-shared/constants";
import { capitalizeModel } from "@teselagen/utils";
import useTgQuery from "../apolloUseTgQuery";

/**
 * For a given componenet with `model` and `recordId` in the props, query for
 * the extended property values for that record
 */

const extendedValuesFragment = `
  id
  value
  extendedPropertyId
  extendedProperty {
    id
    name
    extendedTypeCode
    extendedPropertyClassCode
  }
`;

const extendedCategoryValuesFragment = `
  id
  extendedCategoryId
  extendedCategory {
    id
    name
  }
  extendedPropertyId
  extendedProperty {
    id
    name
    extendedTypeCode
    extendedPropertyClassCode
    extendedCategoryClass {
      id
      name
      extendedCategories {
        id
        name
      }
    }
  }
`;

const extendedMeasurementValuesFragment = `
  id
  value
  measurementUnitId
  measurementUnit {
    id
    abbreviation
  }
  extendedPropertyId
  extendedProperty {
    id
    name
    extendedTypeCode
    extendedPropertyClassCode
    extendedMeasurementUnitClass {
      id
      name
      baseMeasurementUnitId
      baseMeasurementUnit {
        id
        name
        abbreviation
        conversionFactor
      }
      measurementUnits {
        id
        name
        abbreviation
        conversionFactor
      }
    }
  }
`;

export let extendedLinkValueString = "";
extendedPropertyLinkableModels.forEach(model => {
  const capM = capitalizeModel(model);
  extendedLinkValueString += `\n${model}Elvs {
    id
    source${capM} {
      id
      ${model !== "aliquot" ? "name" : ""}
    }
  }\n`;
});

export const extendedValuesLibraryFragment = `
extendedValues {
  id
  extendedPropertyId
  value
}
extendedCategoryValues {
  id
  extendedCategoryId
  extendedPropertyId
}
extendedMeasurementValues {
  id
  value
  measurementUnitId
  extendedPropertyId
}
extendedLinkValues {
  id
  ${extendedLinkValueString}
  extendedPropertyId
}
`;

export const extendedLinkValuesFragment = `
  id
  ${extendedLinkValueString}
  extendedPropertyId
  extendedProperty {
    id
    name
    extendedPropertyClassCode
    targetModel
  }
`;

export const getWithRecordExtendedPropertyFragment: (
  model: string
) => [string, string] = (model: string) => {
  return [
    model,
    `
      id
      extendedValues {
        ${extendedValuesFragment}
      }
      extendedCategoryValues {
        ${extendedCategoryValuesFragment}
      }
      extendedMeasurementValues {
        ${extendedMeasurementValuesFragment}
      }
      extendedLinkValues {
        ${extendedLinkValuesFragment}
      }
    `
  ];
};

export type RecordWithExtendedProperties = {
  extendedValues: any[];
  extendedCategoryValues: any[];
  extendedMeasurementValues: any[];
  extendedLinkValues: any[];
};

export const useRecordExtendedProperties = ({
  model,
  recordIds,
  recordId
}: {
  model: string;
  recordIds?: string[];
  recordId?: string;
}) => {
  const variables = useMemo(
    () => ({
      ...(recordIds
        ? {
            filter: {
              id: recordIds
            }
          }
        : {
            id: recordId
          })
    }),
    [recordId, recordIds]
  );
  const fragment = useMemo(
    () => getWithRecordExtendedPropertyFragment(model),
    [model]
  );

  const { entities, refetch } = useTgQuery(fragment, {
    variables,
    isPlural: !!recordIds?.length
  });

  const recordsWithExtendedProperties:
    | {
        recordsWithExtendedProperties: RecordWithExtendedProperties[];
      }
    | RecordWithExtendedProperties
    | undefined = useMemo(() => {
    if (recordIds?.length) {
      return {
        recordsWithExtendedProperties: entities
      };
    } else {
      const recordWithExtPropValues = entities;
      if (recordWithExtPropValues) {
        const {
          extendedValues,
          extendedCategoryValues,
          extendedMeasurementValues,
          extendedLinkValues
        } = recordWithExtPropValues;
        return {
          extendedValues,
          extendedCategoryValues,
          extendedMeasurementValues,
          extendedLinkValues
        };
      }
    }
    return;
  }, [entities, recordIds?.length]);

  return {
    ...recordsWithExtendedProperties,
    refetchExtendedProperties: refetch
  };
};
