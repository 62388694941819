/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { flatten } from "lodash";
import { transpose } from "./transpose";

export type BreakdownPatterns =
  | "Z"
  | "Z-Reverse"
  | "N"
  | "N-Reverse"
  | "Clockwise"
  | "Counter-Clockwise";

// Assumes `block` is row-major.
// This function might mutate `block`.
export const blockToAliquotArray = <T,>(
  block: T[][],
  pattern: BreakdownPatterns
): T[] => {
  switch (pattern) {
    case "Z":
      return flatten(block);
    case "Z-Reverse":
      return flatten(block.map(row => row.reverse()));
    case "N":
      return flatten(transpose(block).map(row => row.reverse()));
    case "N-Reverse":
      return flatten(transpose(block));
    case "Clockwise":
      return [...block[0], ...block[1].reverse()];
    case "Counter-Clockwise":
      return [block[0][0], block[1][0], block[1][1], block[0][1]];
    default:
      throw new Error("Unrecognized breakdown pattern: " + pattern);
  }
};
