/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useEffect, useMemo } from "react";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import { getAssemblyPieceIds } from "./AssemblyPieces";
import { useTableEntities } from "@teselagen/ui";
import constructSelectionJ5ReportFragment from "../../../../graphql/fragments/constructSelectionJ5ReportFragment";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import { assemblyPieceTableName } from "../constants";
import LinkAllJ5MaterialsButton from "../../../LinkAllJ5MaterialsButton";
import { j5ReportLinkedToMaterials } from "../utils";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import { j5ReportAssemblyHierarchicalColumns } from "../../../../../src-shared/utils/libraryColumns";
import { change } from "redux-form";
import { useDispatch } from "react-redux";
import { useFormValue } from "../../../../../src-shared/hooks/useFormValue";

const shortSchema = {
  model: "j5Report",
  fields: [
    {
      displayName: "Name",
      path: "name"
    },
    {
      displayName: "Assembly Method",
      path: "assemblyMethod"
    },
    {
      displayName: "Assembly Type",
      path: "assemblyType"
    },
    ...j5ReportAssemblyHierarchicalColumns
  ]
};

const j5ReportFragment = [
  "j5Report",
  `id
      name
      assemblyMethod
      assemblyType
      isHierarchical
      outputCardName
      assemblyBatchId
      treePosition
      updatedAt`
];

type Props = {
  selectTableRecords: (records: any[]) => void;
  toolSchema: { code: string };
  toolIntegrationProps: {
    isDisabledMap: { [key: string]: boolean };
    isLoadingMap: { [key: string]: boolean };
  };
};

const SelectJ5Report = ({
  toolSchema: { code },
  toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} }
}: Props) => {
  const dispatch = useDispatch();
  const j5Reports = useFormValue<{ id: string }[]>(code, "j5Reports");

  const { selectTableEntities } = useTableEntities(assemblyPieceTableName);

  useEffect(() => {
    const assemblyPieceIds = getAssemblyPieceIds((j5Reports || []).sort());
    selectTableEntities(assemblyPieceIds.map(id => ({ id })));
    change(code, "finalizedAPIds", []);
  }, [code, j5Reports, selectTableEntities]);

  const fullSchema = useMemo(
    () => ({
      ...shortSchema,
      fields: [
        ...shortSchema.fields,
        {
          displayName: "Link Materials",
          render: (v: any, j5Report: any) => {
            if (j5ReportLinkedToMaterials(j5Report)) {
              return "Fully Linked";
            } else {
              const afterLinking = async () => {
                const fullReport = await safeQuery(
                  constructSelectionJ5ReportFragment,
                  {
                    variables: {
                      id: j5Report.id
                    }
                  }
                );
                const indexOfReport = (j5Reports || []).findIndex(
                  r => r.id === j5Report.id
                );
                change(code, "j5Reports", [
                  ...(j5Reports || []).slice(0, indexOfReport),
                  fullReport,
                  ...(j5Reports || []).slice(indexOfReport + 1)
                ]);
              };

              return (
                <LinkAllJ5MaterialsButton
                  j5Report={j5Report}
                  text="Link to Materials"
                  afterlinking={afterLinking}
                />
              );
            }
          }
        }
      ]
    }),
    [code, j5Reports]
  );

  const postSelectDTProps = useMemo(
    () => ({
      formName: "constructSelectionReportsTable",
      schema: fullSchema,
      j5Reports,
      change: (...args: [name: string, value: any]) =>
        dispatch(change(code, ...args))
    }),
    [code, dispatch, fullSchema, j5Reports]
  );

  const buttonProps = useMemo(
    () => ({
      disabled: isDisabledMap.j5Reports,
      loading: isLoadingMap.j5Reports
    }),
    [isDisabledMap.j5Reports, isLoadingMap.j5Reports]
  );

  return (
    <div className="tg-step-form-section column">
      <HeaderWithHelper
        width="100%"
        header="Select DNA Assembly Reports"
        helper="Select one or more DNA assembly reports to compile a list of assembly pieces and constructs."
      />
      <div style={{ marginBottom: 20 }}>
        <GenericSelect
          additionalDataFragment={constructSelectionJ5ReportFragment}
          name="j5Reports"
          isMultiSelect
          buttonProps={buttonProps}
          schema={shortSchema}
          fragment={j5ReportFragment}
          postSelectDTProps={postSelectDTProps}
        />
      </div>
    </div>
  );
};

export default SelectJ5Report;
