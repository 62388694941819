/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import {
  BlueprintError,
  DialogFooter,
  SelectField,
  wrapDialog,
  throwFormError
} from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import { camelCase } from "lodash";
import SequenceExportFormatsRadio from "../../../../src-shared/SequenceExportFormatsRadio";
import { exportPlatesOrTubes } from "../../../utils/exportUtils";

class ExportPlatesOrTubesDialog extends Component {
  onSubmit = async values => {
    const { plateIds, tubeIds, hideModal } = this.props;
    try {
      const { sorting, sequenceFileType } = values;
      if (plateIds) {
        await exportPlatesOrTubes(plateIds, {
          sorting: camelCase(sorting),
          sequenceFileType
        });
      }
      if (tubeIds) {
        await exportPlatesOrTubes(tubeIds, {
          sequenceFileType,
          isTube: true
        });
      }
      hideModal({ completed: true });
    } catch (error) {
      console.error(`error:`, error);
      throwFormError(
        error.message || `Error exporting ${plateIds ? "plates." : "tubes."}`
      );
    }
  };

  render() {
    const { handleSubmit, hideModal, submitting, plateIds, error } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          {plateIds && (
            <SelectField
              name="sorting"
              label="Sorting"
              defaultValue="Row First"
              options={["Row First", "Column First"]}
            />
          )}
          <SequenceExportFormatsRadio
            name="sequenceFileType"
            label="Sequence File Type"
          />
          <BlueprintError error={error} />
        </div>
        <DialogFooter hideModal={hideModal} submitting={submitting} />
      </form>
    );
  }
}

export default compose(
  wrapDialog(props => ({
    title: `Export ${props.plateIds ? "Plates" : "Tubes"}`
  })),
  reduxForm({
    form: "plateExport"
  })
)(ExportPlatesOrTubesDialog);
