/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { FC, useEffect } from "react";
import { camelCase, get, isEqual, upperFirst } from "lodash";
import { Loading } from "@teselagen/ui";
import { QueryResult, WatchQueryFetchPolicy } from "@apollo/client";
import usePrevious from "../usePrevious";

export type GenericVariables = {
  id?: string;
  filter?: { [name: string]: any };
  pageNumber?: number;
  pageSize?: number;
  sort?: string[];
  offset?: number;
  limit?: number;
};

/** Props required in the wrapped component or passed from other HOCs */
export type BaseTProps<TEntity = any> = {
  variables?: any;
  getIdFromParams?: boolean;
  recordIdOverride?: string;
  fetchPolicy?: WatchQueryFetchPolicy;
  pollInterval?: number;
  notifyOnNetworkStatusChange?: boolean;
  additionalFilter?: (...args: any[]) => any;
  tableParams?: {
    entities: TEntity[];
    isLoading: boolean;
  };
};

export type WithQueryOptionsOptions =
  // Function that receives props and returns options
  | ((props: any) => {
      variables: GenericVariables;
      context?: any;
      [key: string]: any;
    })
  | { variables: GenericVariables; context?: any; [key: string]: any };

export type WithQueryOptions = {
  isPlural?: boolean;
  idAs?: string;
  queryName?: string;
  asFunction?: boolean;
  /** Grab the id variable off the match.params object being passed in */
  getIdFromParams?: boolean;
  showLoading?: boolean;
  showError?: boolean;
  useHasura?: boolean;
  isSingular?: boolean;
  nameOverride?: string;
  inDialog?: boolean;
  withLoadingHoc?: any;
  /** Options to pass to the query, can be a function that accepts the props from the component or an object directly */
  options?: WithQueryOptionsOptions;
  /** Yet another way to pass variables to the query */
  variables?: GenericVariables;
  /** Function that receives results and ownProps and returns new props for the component, similar to how a withProps HOC works */
  props?: (results: { ownProps: any } & QueryResult) => {
    [key: string]: any;
  };
  /** Function that receives props and returns whether to skip the query or not  */
  skip?: (props: any) => boolean | boolean;
  /** No idea what these 2 are needed for */
  client?: any;
  context?: any;
  pollInterval?: number;
};

/**
 *
 * @param {string[]} columns columns to sortBy in format ['col1', '-col2']
 * @returns {{[colName: string]: 'asc' | 'desc'}[]} orderBy
 */
export const convertInputToOutput = (colNames: string[]) => {
  const orderBy: { [colName: string]: "asc" | "desc" }[] = [];
  colNames.forEach(colName => {
    const orderItem: { [colName: string]: "asc" | "desc" } = {};
    let direction: "asc" | "desc" = "asc";

    if (colName.startsWith("-")) {
      direction = "desc";
      colName = colName.substring(1);
    }

    orderItem[colName] = direction;
    orderBy.push(orderItem);
  });

  return orderBy;
};

/**
 * Based on the props of the wrapped component and the options passed to the HOC, obtains the variables for the query
 */
export const getVariables = <TProps extends BaseTProps>(
  wrappedComponentProps: TProps,
  withQueryOptions: {
    queryOptions: WithQueryOptions["options"];
    queryNameToUse: string;
    getIdFromParams?: boolean;
    variables?: GenericVariables;
  }
) => {
  const {
    variables: propVariables,
    getIdFromParams: _getIdFromParamsFromProps,
    recordIdOverride
  } = wrappedComponentProps;
  const {
    queryOptions,
    getIdFromParams: withQueryOptionsGetIdFromParams,
    queryNameToUse,
    variables: withQueryVariables
  } = withQueryOptions;
  let id;
  const getIdFromParams =
    _getIdFromParamsFromProps || withQueryOptionsGetIdFromParams;
  if (getIdFromParams && !recordIdOverride) {
    id = get(wrappedComponentProps, "match.params.id");
    if (!id) {
      console.error(
        "There needs to be an id passed here to ",
        queryNameToUse,
        "but none was found"
      );
      debugger; // eslint-disable-line
      // to prevent crash
      id = -1;
    }
  } else if (recordIdOverride) {
    id = recordIdOverride;
  }

  let extraOptions = queryOptions ?? { variables: undefined };
  if (typeof extraOptions === "function") {
    extraOptions = extraOptions(wrappedComponentProps) || {
      variables: undefined
    };
  }

  const { variables: extraOptionVariables } = extraOptions;
  const variablesToUse: GenericVariables = {
    ...((getIdFromParams || recordIdOverride) && { id }),
    ...withQueryVariables,
    ...propVariables,
    ...(extraOptionVariables && extraOptionVariables)
  };

  return variablesToUse;
};

export const WithLoadingHoc =
  (options: {
    showError?: boolean;
    showLoading?: boolean;
    queryNameToUse?: string;
    inDialog?: boolean;
  }) =>
  (WrappedComponent: FC<any>) => {
    const WithLoadingComp: React.FC<{
      data?: QueryResult;
      inDialog?: boolean;
      loggedIn?: boolean;
    }> = props => {
      const {
        showLoading,
        inDialog: _inDialog1,
        showError,
        queryNameToUse
      } = options;
      const {
        data = { loading: false, error: undefined },
        inDialog: _inDialog2,
        loggedIn
      } = props;
      const { loading, error } = data;
      const prevError = usePrevious(error);

      useEffect(() => {
        if (showError && error && !isEqual(error, prevError)) {
          if (loggedIn) {
            console.error("error:", error);
            window.toastr.error(
              `Error loading ${queryNameToUse}: ${error.toString()}`
            );
          } else {
            console.warn("Error supressed, not logged in");
          }
        }
      });

      const inDialog = _inDialog1 || _inDialog2;
      if (loading && showLoading) {
        return <Loading inDialog={inDialog} bounce={inDialog} />;
      }
      return <WrappedComponent {...props} />;
    };

    return WithLoadingComp;
  };

/** Helper function that takes the results from apollo query to a named version of the results */
export const transformQueryDataToNamedData = <
  TResultName extends string,
  TResultData = any
>({
  queryResultsWithoutData,
  nameToUse,
  results,
  totalResults
}: {
  queryResultsWithoutData: Omit<QueryResult<TResultData>, "data">;
  nameToUse: TResultName;
  results: TResultData;
  totalResults: number | undefined;
}) => {
  const newData = {
    ...queryResultsWithoutData,
    // ...data, // {[nameToUse]: results},
    totalResults,
    entities: results,
    entityCount: totalResults,
    ["error" + upperFirst(nameToUse)]: queryResultsWithoutData.error,
    ["loading" + upperFirst(nameToUse)]: queryResultsWithoutData.loading,
    [nameToUse]: results
  };

  return newData;
};

/** Helper function that maps the results into a named version of the results */
export const transformDataToNamedResults = <
  TResultName extends string,
  TResultData = any
>({
  nameToUse,
  queryNameToUse,
  data
}: {
  nameToUse: TResultName;
  queryNameToUse: string;
  data: ReturnType<
    typeof transformQueryDataToNamedData<TResultName, TResultData>
  >;
}) => {
  return {
    [nameToUse]: data.entities,
    [nameToUse + "Error"]: data.error,
    [nameToUse + "Loading"]: data.loading,
    [nameToUse + "Count"]: data.totalResults,
    [camelCase("refetch_" + nameToUse)]: data.refetch,
    [queryNameToUse]: data
  };
};
