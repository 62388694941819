/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

export function canDistributeTemperatureBlocks({
  reactionEntityType,
  itemType,
  reactionMaps = []
}: {
  reactionEntityType: string;
  itemType: string;
  reactionMaps: { reactionTypeCode: string }[];
}) {
  return (
    itemType === "reactionMap" &&
    reactionEntityType === "Output Materials" &&
    reactionMaps.every(rm => rm.reactionTypeCode === "PCR_REACTION")
  );
}
