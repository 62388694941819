/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { SelectField } from "@teselagen/ui";

function ZonesPerPlateField({
  containerFormat,
  temperatureZoneOrientation
}: {
  containerFormat: { rowCount: number; columnCount: number };
  temperatureZoneOrientation: string;
}) {
  let zonesPerPlateDefaultVal;
  const zonesPerPlateOptions = [];
  const { rowCount, columnCount } = containerFormat;
  if (temperatureZoneOrientation === "vertical") {
    zonesPerPlateDefaultVal = 6;
    for (let i = 1; i <= columnCount; i++) {
      if (columnCount % i === 0) {
        zonesPerPlateOptions.push(i);
      }
    }
  } else {
    zonesPerPlateDefaultVal = 4;
    for (let i = 1; i <= rowCount; i++) {
      if (rowCount % i === 0) {
        zonesPerPlateOptions.push(i);
      }
    }
  }

  return (
    <SelectField
      label="Zones per Plate"
      name="zonesPerPlate"
      enableReinitialize
      defaultValue={zonesPerPlateDefaultVal}
      options={zonesPerPlateOptions}
    />
  );
}

export default ZonesPerPlateField;
