/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Button, Menu, Popover, Position } from "@blueprintjs/core";

const ThreeDotMenuButton = ({ menuItems }: { menuItems: React.ReactNode }) => (
  //@ts-ignore
  <Popover
    minimal
    content={<Menu>{menuItems}</Menu>}
    position={Position.BOTTOM_RIGHT}
  >
    <Button minimal icon="more" />
  </Popover>
);

export default ThreeDotMenuButton;
