/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import shortid from "shortid";
import { isoContext } from "@teselagen/utils";
import {
  getPositionFromAlphanumericLocation,
  checkBarcodesAndFormat
} from "../utils/plateUtils";
import { stripTwistAdapters } from "./utils";
import {
  getParsedSequenceMatch,
  sequenceJSONtoGraphQLInput
} from "../../../tg-iso-shared/src/sequence-import-utils/utils";
import { sequenceWithTagsAndAliasesAndExtendedProperitesFragment } from "./helperFragments";
import isValidPositiveNumber from "../../../tg-iso-shared/src/utils/isValidPositiveNumber";
import parsePlateCsvAndSequenceFiles from "./parsePlateCsvAndSequenceFiles";
import { bottle } from "../dependencyContainer";

export const requiredHeaders = ["Name", "Yield (ng)"];

export default async function handleTwistPlateImport(values, ctx = isoContext) {
  const { plateName, generateTubeBarcodes, isTubeUpload, stripAdapters } =
    values;
  const { safeQuery } = ctx;

  const requiredHeadersToUse = isTubeUpload
    ? requiredHeaders.concat("TUBE_BARCODE")
    : requiredHeaders.concat("WELL_LOCATION");

  const {
    finishPlateCreate,
    finishTubeCreate,
    containerArrayType,
    aliquotContainerType,
    filename,
    csvData,
    getCsvRowExtProps,
    sequenceFiles
  } = await parsePlateCsvAndSequenceFiles(
    values,
    {
      barcodeHeader: "PLATE_ID",
      hasSequences: true,
      stripAdapters,
      hasExtendedProperties: true,
      requiredFields: requiredHeadersToUse,
      sequenceFragment: sequenceWithTagsAndAliasesAndExtendedProperitesFragment,
      getSequenceForRow: ({
        row,
        allParsedSequences,
        sequenceNameMap,
        sequenceFileMap
      }) => {
        const {
          NAME: sequenceName,
          INSERT_SEQUENCE: insertSequence,
          CONSTRUCT_SEQUENCE: constructSequence
        } = row;
        if (allParsedSequences.length) {
          return getParsedSequenceMatch(
            sequenceNameMap,
            sequenceFileMap,
            sequenceName
          );
        } else {
          // grab sequence from csv
          let sequenceString = constructSequence || insertSequence;
          if (!constructSequence && stripAdapters) {
            sequenceString = stripTwistAdapters(sequenceString);
          }

          if (sequenceString) {
            return sequenceJSONtoGraphQLInput({
              name: sequenceName,
              sequence: sequenceString,
              circular: !!constructSequence
            });
          }
        }
      }
    },
    ctx
  );

  const isRack = !isTubeUpload && !containerArrayType.isPlate;
  let containerFormat;
  if (containerArrayType) {
    containerFormat = containerArrayType.containerFormat;
  }
  const tubesToCreate = [];
  const mapPlates = {};
  const addedPropsForSequence = {};
  const aliquotsToCreate = [];
  const plateKeyToCid = {};

  csvData.forEach(row => {
    const { PLATE_ID: plateBarcode } = row;
    row.rowKey = (plateBarcode || "") + ":" + (plateName || "");
  });

  const continueUpload = await checkBarcodesAndFormat({
    data: csvData,
    generateTubeBarcodes,
    isTubeUpload,
    filename,
    containerArrayType,
    barcodeKey: "PLATE_ID",
    tubeBarcodeKey: "TUBE_BARCODE",
    wellPositionHeader: "WELL_LOCATION"
  });
  if (!continueUpload) return;

  for (const [index, row] of csvData.entries()) {
    const {
      sequence,
      materialId,
      sequenceId,
      NAME: sequenceName,
      WELL_LOCATION: location,
      PLATE_ID: plateBarcode,
      TUBE_BARCODE: tubeBarcode,
      YIELD_NG: mass,
      rowKey
    } = row;

    if (!sequence) {
      // check to see if this validation is still correct
      if (sequenceFiles && sequenceFiles.length) {
        throw new Error(
          `Row ${index + 1} specifies the sequence ${sequenceName}` +
            ` but the corresponding genbank file is missing.`
        );
      } else {
        throw new Error(
          `Row ${index + 1} specifies the sequence ${sequenceName}` +
            ` which had an error.`
        );
      }
    }
    if (!materialId) {
      throw new Error(
        `Row ${
          index + 1
        } was not linked to a material properly, something went wrong.`
      );
    }
    if (!plateName && !plateBarcode && !isTubeUpload) {
      throw new Error("Plate name is required");
    }
    if (!isValidPositiveNumber(mass)) {
      throw new Error(`Row ${index + 1} does not provide a mass.`);
    }

    const aliquotCid = shortid();
    if (sequenceId && !addedPropsForSequence[sequenceId]) {
      addedPropsForSequence[sequenceId] = true;

      getCsvRowExtProps({
        row,
        modelTypeCode: "DNA_SEQUENCE",
        typeFilter: "sequence",
        recordId: sequenceId,
        record: sequence
      });
    }
    const sampleCid = shortid();
    const aliquot = {
      cid: aliquotCid,
      mass,
      massUnitCode: "ng",
      aliquotType: "sample-aliquot",
      isDry: true,
      sample: {
        cid: sampleCid,
        name: sequenceName,
        sampleTypeCode: "REGISTERED_SAMPLE",
        materialId
      }
    };
    getCsvRowExtProps({
      row,
      modelTypeCode: "ALIQUOT",
      typeFilter: "aliquot",
      recordId: `&${aliquotCid}`
    });
    getCsvRowExtProps({
      row,
      modelTypeCode: "SAMPLE",
      typeFilter: "sample",
      recordId: `&${sampleCid}`
    });
    aliquotsToCreate.push(aliquot);
    if (!isTubeUpload && location && rowKey && mass) {
      const { rowPosition, columnPosition } =
        getPositionFromAlphanumericLocation(location, containerFormat);

      if (!mapPlates[rowKey]) {
        mapPlates[rowKey] = {
          aliquotContainers: [],
          name: (plateName || "").trim() || plateBarcode,
          barcode: plateBarcode
        };
        plateKeyToCid[rowKey] = shortid();
        // handle plate extended properties
        getCsvRowExtProps({
          row,
          modelTypeCode: "CONTAINER_ARRAY",
          typeFilter: ["plate", "rack"],
          recordId: `&${plateKeyToCid[rowKey]}`
        });
      }

      const tubeCid = shortid();
      if (isRack) {
        getCsvRowExtProps({
          row,
          modelTypeCode: "ALIQUOT_CONTAINER",
          typeFilter: "tube",
          recordId: `&${tubeCid}`
        });
      }
      mapPlates[rowKey].aliquotContainers.push({
        cid: tubeCid,
        rowPosition,
        columnPosition,
        mass,
        tubeBarcode,
        aliquotId: `&${aliquotCid}`
      });
    } else if (tubeBarcode || isTubeUpload) {
      const tubeCid = shortid();
      getCsvRowExtProps({
        row,
        modelTypeCode: "ALIQUOT_CONTAINER",
        typeFilter: "tube",
        recordId: `&${tubeCid}`
      });
      tubesToCreate.push({
        cid: tubeCid,
        name: tubeBarcode,
        barcode: {
          barcodeString: tubeBarcode
        },
        aliquotContainerTypeCode: aliquotContainerType.code,
        aliquotId: `&${aliquotCid}`
      });
    }
  }

  const platesToCreate = [];
  if (!isTubeUpload) {
    let needsPlateIndex = false;
    if (Object.keys(mapPlates).length > 1) needsPlateIndex = true;
    Object.keys(mapPlates).forEach((plateKey, i) => {
      const { aliquotContainers, barcode, name } = mapPlates[plateKey];
      const newAliquotContainers = aliquotContainers.map(
        ({ cid, rowPosition, columnPosition, aliquotId, tubeBarcode }) => {
          const barcodeField = {};
          if (!generateTubeBarcodes) {
            barcodeField.barcode = {
              barcodeString: tubeBarcode
            };
          }
          return {
            cid,
            aliquotContainerTypeCode: isRack
              ? aliquotContainerType.code
              : containerArrayType.aliquotContainerType.code,
            rowPosition,
            columnPosition,
            ...barcodeField,
            aliquotId
          };
        }
      );

      let nameToUse = name || barcode;
      if (needsPlateIndex && name === plateName) {
        nameToUse = `${name} ${i + 1}`;
      }
      platesToCreate.push({
        cid: plateKeyToCid[plateKey],
        name: nameToUse,
        barcode: {
          barcodeString: barcode
        },
        containerArrayTypeId: containerArrayType.id,
        aliquotContainers: newAliquotContainers
      });
    });
  }

  const extraFn = async ({ sequenceIds }) => {
    const ordersToBeUpdated = await safeQuery(
      ["vendorOrder", "id name lineItems { sequenceId }"],
      {
        variables: {
          filter: {
            orderStatusCode: ["DRAFT_STATUS", "SUBMITTED_STATUS"],
            "lineItems.sequenceId": sequenceIds
          },
          pageSize: 1
        }
      }
    );
    // only want to show the dialog if there are any matching orders
    if (ordersToBeUpdated.length) {
      await bottle.container.updateVendorOrderDialogAsPromise({
        ordersToBeUpdated,
        sequenceIds
      });
    }
  };

  if (!isTubeUpload) {
    return await finishPlateCreate({
      newAliquots: aliquotsToCreate,
      newPlates: platesToCreate,
      extraFn
    });
  }
  if (tubesToCreate.length) {
    if (!isTubeUpload) {
      console.error(
        "Not uploading twist tubes but still tubes to create. Bad input file."
      );
    }

    return await finishTubeCreate({
      newTubes: tubesToCreate,
      newAliquots: aliquotsToCreate,
      extraFn
    });
  }
}
