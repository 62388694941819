/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import joinUrl from "url-join";
import { get } from "lodash";
import { customAlphabet } from "nanoid";
import appGlobals from "../appGlobals";
import { getActiveLabId } from "@teselagen/auth-utils";
import { lastSelectedLabKey } from "./labUtils";

export function fullUrl(url: string) {
  return joinUrl(window.location.origin, "client", url);
}

export function getHelpArticleLink(article: string) {
  return `/html_files/articles/${article}.html`;
}
export function openInNewTab(url: string) {
  const activeLabId = getActiveLabId();
  if (activeLabId && localStorage.getItem(lastSelectedLabKey) !== activeLabId) {
    // will make new tab's lab match current tabs
    localStorage.setItem(lastSelectedLabKey, activeLabId);
  }
  const toRet = window.open(fullUrl(url), "_blank");
  toRet?.focus();
  return toRet;
}

export const includesMultiple = (
  array: string[],
  items: string[],
  { caseInsensitive }: { caseInsensitive?: boolean } = {}
) => {
  const arrayToSearch = caseInsensitive
    ? array.map(s => s.toLowerCase())
    : array;
  return items.every(item => arrayToSearch.includes(item));
};

export function isAdmin() {
  if (
    process.env.NODE_ENV !== "production" &&
    window.localStorage.getItem("__dev_only_tgIsAdmin") !== null
  ) {
    if (window.localStorage.getItem("__dev_only_tgIsAdmin") === "true") {
      return true;
    } else {
      return false;
    }
  }
  const isAdmin = get(appGlobals.currentUser, "userRoles", []).some(
    ({ appRoleCode }) => appRoleCode === "ADMIN"
  );
  return isAdmin;
}
export const isTeselagenUser = () => {
  return appGlobals.currentUser?.email?.endsWith("@teselagen.com");
};

export function isLabAdmin() {
  const activeLabId = getActiveLabId();
  return (
    activeLabId &&
    (
      get(appGlobals.currentUser, "labRoles", []).find(
        lr => lr.labId === activeLabId
      ) || {}
    ).roleCode === "ADMIN"
  );
}

export function isLabMember() {
  const activeLabId = getActiveLabId();
  const userLabRole = get(appGlobals.currentUser, "labRoles", []).find(
    lr => lr.labId === activeLabId
  ) || { roleCode: "NONE" };
  return activeLabId && ["ADMIN", "MEMBER"].includes(userLabRole.roleCode);
}

export const popoverOverflowModifiers = {
  preventOverflow: { enabled: false },
  hide: {
    enabled: false
  },
  flip: {
    boundariesElement: "viewport"
  }
};

export const anOrA = (word: string) => {
  return /^[aeiou]/i.test(word) ? "an" : "a";
};

// Use this to sort strings in ascending order.
export function strcmp(a: string, b: string) {
  return a < b ? -1 : a > b ? 1 : 0;
}

export const isMac = navigator.userAgent.includes("Mac OS X");

export const disabledToolkits =
  (window.frontEndConfig && window.frontEndConfig.disabledToolkits) || {};
export const hiddenFeaturesConfig =
  (window.frontEndConfig && window.frontEndConfig.hiddenFeaturesConfig) || {};

// disable design templates by default for now. they are not in a great working state.
if (hiddenFeaturesConfig.designTemplates !== false) {
  hiddenFeaturesConfig.designTemplates = true;
}

// Unless explicitly enabled, disable ELN
if (hiddenFeaturesConfig.eln !== false) {
  hiddenFeaturesConfig.eln = true;
}

// Enable ELN for Cypress tests
if (window.Cypress) {
  hiddenFeaturesConfig.eln = false;
}

const customNanoId = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 10);
export const getUniqueFragmentKey = () => customNanoId();

export function inSSO() {
  return !get(window, "frontEndConfig.localLogin");
}
