/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import appGlobals from "./appGlobals";
import shortid from "shortid";
import { set } from "lodash";
import { getAuthToken, setAuthToken } from "./auth/utils";
import { getRequestHeaderKeys } from "@teselagen/auth-utils";

function addAuthTokenToRequest(request) {
  if (getAuthToken()) {
    request.headers.Authorization = "Bearer " + getAuthToken();
  }

  return request;
}

function addRequestHeaders(request) {
  request.headers = {
    ...getRequestHeaderKeys(),
    ...request.headers
  };

  return request;
}

function invalidTokenHandler(error) {
  const response = error.response;
  if (
    response &&
    response.data &&
    !response.data.success &&
    response.data.isAuthorization
  ) {
    if (appGlobals.logout) {
      console.error(
        "Logging out because token was invalid, please check the server for more information."
      );
      return appGlobals.logout();
    } else {
      console.warn("appGlobals.logout not defined, cant execute logout");
    }
  }

  return Promise.reject(error);
}

function addRequestInterceptorsToAxios(axiosInstance) {
  axiosInstance.interceptors.request.use(config => {
    //console.log(`[AXIOS] Request interceptor being called`);
    const key = shortid();
    set(config, "meta.requestTrackingId", key);
    return addAuthTokenToRequest(config);
  });

  axiosInstance.interceptors.request.use(addRequestHeaders);
}

function addResponseInterceptorsToAxios(axiosInstance) {
  axiosInstance.interceptors.response.use(
    response => {
      if (response.headers["auth-token"]) {
        console.info("Setting auth token");
        setAuthToken(response.headers["auth-token"]);
      }
      return response;
    },
    error => {
      if (error?.response?.data) {
        console.error(`Error from response data:`, error.response.data);
      }
      return invalidTokenHandler(error);
    }
  );
}

export { addRequestInterceptorsToAxios, addResponseInterceptorsToAxios };
