/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { keyBy } from "lodash";
import { standardizeVolume } from "../../../../tg-iso-lims/src/utils/unitUtils/standardizeUnits";
import concatWarningStrs from "../../../utils/concatWarningStrs";
import { isoContext } from "@teselagen/utils";
import handleUpdateMutations from "./handleUpdates";
import { handleNestedRecords, validateUnits } from "./utils";

export const TUBE = async (
  { recordsToImport, upsertHandlers, ...rest },
  ctx = isoContext
) => {
  const { safeQuery } = ctx;
  recordsToImport.forEach((r, i) => {
    r.ref = i;
  });

  const allTubeTypes = await safeQuery([
    "aliquotContainerType",
    "code name maxVolume volumetricUnitCode"
  ]);

  const keyedTubeTypes = keyBy(allTubeTypes, t => t.name.toLowerCase());
  let recordsToContinueUpserting = recordsToImport.filter(r => {
    let validTubeType;
    if (r.tubeType) {
      validTubeType = keyedTubeTypes[r.tubeType.toLowerCase()];
      if (!validTubeType) {
        r.__importFailed = concatWarningStrs(
          r.__importFailed,
          "Invalid tube type"
        );
      }
    } else if (!r.id) {
      r.__importFailed = concatWarningStrs(
        r.__importFailed,
        "Must provide a tube type."
      );
    } else {
      validTubeType = true;
    }
    if (!validTubeType) {
      return false;
    }

    let aliquotContainerType;
    if (r.id) {
      aliquotContainerType = allTubeTypes.find(
        t => t.code === r.__newRecord.aliquotContainerTypeCode
      );
    } else {
      aliquotContainerType = keyedTubeTypes[r.tubeType.toLowerCase()];
    }
    if (aliquotContainerType && r.aliquot && r.aliquot.volume) {
      const unitError = validateUnits(r.aliquot);
      if (unitError) {
        r.__importFailed = concatWarningStrs(r.__importFailed, unitError);
        return false;
      }
      const aliquotVolume = standardizeVolume(
        r.aliquot.volume,
        r.aliquot.volumetricUnitCode || ""
      );
      const maxVolume = standardizeVolume(
        aliquotContainerType.maxVolume,
        aliquotContainerType.volumetricUnitCode
      );
      if (aliquotVolume > maxVolume) {
        r.__importFailed = concatWarningStrs(
          r.__importFailed,
          "Aliquot volume is larger than tube capacity."
        );
        return false;
      }
    }

    return true;
  });

  recordsToContinueUpserting = await handleNestedRecords(
    recordsToContinueUpserting,
    "aliquot",
    async aliquots => {
      await upsertHandlers.ALIQUOT(
        {
          ...rest,
          model: "aliquot",
          recordsToImport: aliquots,
          upsertHandlers
        },
        ctx
      );
    }
  );
  if (!recordsToContinueUpserting.length) {
    return;
  }
  const newRecords = await handleUpdateMutations(
    {
      recordsToImport: recordsToContinueUpserting,
      convertUserFacingToDbModel: r => {
        if (r.aliquot) {
          r.aliquotId = r.aliquot.id;
        }
        delete r.aliquot;
        if (r.tubeType) {
          r.aliquotContainerTypeCode =
            keyedTubeTypes[r.tubeType.toLowerCase()].code;
        }
        delete r.tubeType;
        delete r.additives;

        if (r.barcode) {
          r.barcode = {
            barcodeString: r.barcode
          };
        }
        return r;
      },
      model: "aliquotContainer"
    },
    ctx
  );

  const newIds = (
    await ctx.safeUpsert(
      ["aliquotContainer", "id"],
      newRecords.map(nr => {
        const recordToUpsert = { ...nr };
        delete recordToUpsert.ref;
        return recordToUpsert;
      })
    )
  ).map(nr => nr.id);

  newRecords.forEach((nr, i) => {
    const record = recordsToImport[nr.ref];
    record.id = newIds[i];
    delete record.ref;
    delete nr.ref;
  });
};
