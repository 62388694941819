/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useMemo, useState } from "react";
import {
  CheckboxField,
  FileUploadField,
  RadioGroupField,
  ReactSelectField,
  SelectField,
  useTableEntities
} from "@teselagen/ui";
import { Callout, Tooltip, Icon, Position, Button } from "@blueprintjs/core";
import {
  keyBy,
  get,
  set,
  isEmpty,
  chunk,
  times,
  sortBy,
  range,
  uniqBy
} from "lodash";
import shortid from "shortid";
import { useDispatch, useSelector } from "react-redux";
import {
  stopSubmit,
  change,
  InjectedFormProps,
  formValueSelector
} from "redux-form";
import QueryBuilder from "tg-client-query-builder";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import {
  cleanPosition,
  quadrantToDefaultStartingPosition
} from "../../../../utils/plateUtils";
import modelNameToReadableName from "../../../../../src-shared/utils/modelNameToReadableName";
import {
  plateTo2dAliquotContainerArray,
  blockToAliquotArray,
  getBlockOf2dArray
} from "../../utils";
import InventoryListSection from "./InventoryListSection";
import { arrayToIdOrCodeValuedOptions } from "../../../../../src-shared/utils/formUtils";
import isValidPositiveNumber from "../../../../../../tg-iso-shared/src/utils/isValidPositiveNumber";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import platePreviewColumn from "../../../../utils/platePreviewColumn";
import defaultValueConstants from "../../../../../../tg-iso-shared/src/defaultValueConstants";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import {
  generateContainerArray,
  generateEmptyWells,
  wellInBounds
} from "../../../../../../tg-iso-lims/src/utils/plateUtils";
import unitGlobals from "../../../../../../tg-iso-lims/src/unitGlobals";
import SelectJ5MaterialsOrPcrReactions from "../../../SelectJ5MaterialsOrPcrReactions";
import { getDownloadTemplateFileHelpers } from "../../../../../src-shared/components/DownloadTemplateFileButton";
import { breakdownPatterns } from "../../PlateReformatTool/utils";
import SelectReactionMapEntities, {
  getItemTypeAndFilterForReactionMaps
} from "../../../../../src-shared/SelectReactionMapEntities";
import {
  additiveMaterialFragment,
  containerArrayAdditionalFragment,
  containerArrayFragment,
  FieldConstants,
  getSelectedItems,
  itemTypeOptions,
  j5AssemblyPiecePlateMapFragment,
  j5EntityToFragment,
  j5InputSequenceFragment,
  j5MaterialFragment,
  j5PcrReactionFragment,
  j5ReportFragment,
  j5RunConstructFragment,
  lotFragment,
  materialAdditionalFragment,
  materialFragment,
  pcrProductSequenceFragment,
  plateMapAdditionalFragment,
  plateMapFragment,
  PlateMapItemTypes,
  PlateWellContentTypes,
  primerFragment,
  primaryTemplateFragment,
  quadrantFormatMap,
  reactionMapFragment,
  sampleAdditionalFragment,
  sampleFragment,
  modelNameToSchema,
  ItemTypes,
  pluralizeItemType
} from "../utils";
import { Item } from "../utils/types/Item";
import type FilterExpression from "tg-client-query-builder/query-builder/filter-expression";
import type { J5EntityType } from "../utils/types/J5EntityType";
import type {
  RecursivePartial,
  RecursiveRequired
} from "../../../../../src-shared/typescriptHelpers";
import type { PlateToCreateType } from "../utils/generatePlateMapGroup";
import type { BreakdownPatterns } from "../../utils/blockToAliquotArray";
import type { PlateMap as PlateMapFragment } from "../utils/types/PlateMap";
import type { FileUploadResult } from "@teselagen/ui";
import type {
  ContainerArrayAdditionalFragment,
  MaterialAdditionalFragment,
  SampleAdditionalFragment,
  PlateMapAdditionalFragment
} from "../utils/additionalFragments.gql.generated";
import type {
  AdditiveMaterialFragment,
  J5MaterialFragment,
  J5PcrReactionFragment,
  LotFragment,
  J5InputSequenceFragment,
  J5AssemblyPiecePlateMapFragment,
  J5ReportFragment,
  J5RunConstructFragment,
  PcrProductSequenceFragment,
  PrimaryTemplateFragment,
  PrimerFragment,
  ReactionMapFragment
} from "../utils/fragments.gql.generated";

import type {
  CreateOrEditPlateMapJ5ReportJ5AssemblyPiecesFragment,
  CreateOrEditPlateMapJ5Reportj5InputSequenceFragment,
  CreateOrEditPlateMapJ5ReportJ5PcrReactionsFragment,
  CreateOrEditPlateMapJ5Reportj5RunConstructFragment,
  CreateOrEditPlateMapJ5ReportpcrProductSequenceFragment,
  CreateOrEditPlateMapJ5ReportprimaryTemplateFragment,
  CreateOrEditPlateMapJ5ReportprimerFragment
} from "../utils/j5EntityFragments.gql.generated";

type modelNameToFragmentQuery =
  | AdditiveMaterialFragment
  | ContainerArrayAdditionalFragment
  | J5AssemblyPiecePlateMapFragment
  | J5InputSequenceFragment
  | J5MaterialFragment
  | J5PcrReactionFragment
  | J5ReportFragment
  | J5RunConstructFragment
  | LotFragment
  | MaterialAdditionalFragment
  | PcrProductSequenceFragment
  | PlateMapAdditionalFragment
  | PrimaryTemplateFragment
  | PrimerFragment
  | ReactionMapFragment
  | SampleAdditionalFragment;

export const modelNameToFragment = {
  additiveMaterial: additiveMaterialFragment,
  containerArray: containerArrayFragment,
  j5AssemblyPiece: j5AssemblyPiecePlateMapFragment,
  j5InputSequence: j5InputSequenceFragment,
  j5Material: j5MaterialFragment,
  j5PcrReaction: j5PcrReactionFragment,
  j5Report: j5ReportFragment,
  j5RunConstruct: j5RunConstructFragment,
  lot: lotFragment,
  material: materialFragment,
  pcrProductSequence: pcrProductSequenceFragment,
  plateMap: plateMapFragment,
  primaryTemplate: primaryTemplateFragment,
  primer: primerFragment,
  reactionMap: reactionMapFragment,
  sample: sampleFragment
} as const;

export const modelNameToAdditionalFragment = {
  containerArray: containerArrayAdditionalFragment,
  material: materialAdditionalFragment,
  plateMap: plateMapAdditionalFragment,
  sample: sampleAdditionalFragment
} as const;

type FieldType = {
  path?: string;
  isRequired?: boolean;
  description?: string;
};

const pluralizeplateWellContentType = {
  [PlateWellContentTypes.Material]: "materials",
  [PlateWellContentTypes.Sample]: "samples"
};

const fields = [
  "uploadingVolumeInfo",
  "uploadMaterialPlateMap",
  "selectedContainerFormat",
  "itemType",
  "breakIntoQuadrants",
  "j5EntityType",
  "plateMapType",
  "plateWellContentType",
  "j5Reports",
  "reactionMaps",
  "plateMaps",
  "destinationPlateMapQuadrants",
  "j5EntityRadio",
  "reactionEntityType",
  FieldConstants.selectAllReactionEntities,
  ...Object.values(ItemTypes).map(model => pluralizeItemType[model])
];

const generateDefaultValue = {
  ...defaultValueConstants.CREATE_PLATE_MAP_PLATE_FORMAT
};

type Props = {
  toolSchema: { code: string };
  nextStep: () => void;
  Footer: React.FC<any>;
  footerProps: {};
};

type ValueOf<T> = T[keyof T];

type ContainerFormat = {
  quadrantSize: number;
  rowCount: number;
  columnCount: number;
  code: keyof typeof quadrantFormatMap;
};

type ReactionMap = {
  id: string;
  reactionTypeCode: string;
};

type ContainerArray = RecursiveRequired<ContainerArrayAdditionalFragment>;

type PlateMap = RecursivePartial<PlateMapAdditionalFragment>;

type PlateMapItem = NonNullable<NonNullable<PlateMap["plateMapItems"]>[number]>;
type InventoryItem = NonNullable<PlateMapItem["inventoryItem"]>;
type PlateItem =
  | PlateMapItem["j5Item"]
  | InventoryItem["material" | "sample" | "additiveMaterial" | "lot"];

type J5ReportType = { id: string; name: string };

type FormData = {
  additiveMaterials: RecursiveRequired<AdditiveMaterialFragment[]>;
  breakdownPattern: BreakdownPatterns;
  breakIntoQuadrants: boolean;
  containerArrays: ContainerArray[];
  currentPlateMapIndex: number;
  destinationPlateMapQuadrants: {
    quadrant1: string;
    quadrant2: string;
    quadrant3: string;
    quadrant4: string;
  }[];
  extendedPropertyUsedForDist?: { name: string };
  fixedQuadrants: string;
  itemType: ValueOf<typeof ItemTypes>;
  j5EntityRadio: "material" | "j5PcrReaction";
  j5EntityType: ValueOf<typeof J5EntityType>;
  j5Materials: RecursiveRequired<J5MaterialFragment[]>;
  j5PcrReactions: RecursiveRequired<J5PcrReactionFragment[]>;
  j5Reports: J5ReportType[];
  keyedExtendedValuesUsedForDist: { [id: string]: { value: any } };
  lots: RecursiveRequired<LotFragment[]>;
  materials: RecursiveRequired<MaterialAdditionalFragment[]>;
  plateMapGroupName: string;
  plateMaps: PlateMapFragment[];
  plateMapType: ValueOf<typeof PlateMapItemTypes>;
  platesToCreate: RecursiveRequired<PlateToCreateType[]>;
  plateWellContentType: ValueOf<typeof PlateWellContentTypes>;
  quadrant: keyof typeof quadrantToDefaultStartingPosition;
  reactionEntityType: string;
  reactionMaps: ReactionMap[];
  samples: RecursiveRequired<SampleAdditionalFragment[]>;
  selectAllReactionEntities: boolean;
  selectedContainerFormat?: ContainerFormat;
  uploadMaterialPlateMap: boolean;
  uploadingVolumeInfo: boolean;
};

interface ReduxState {
  form?: {
    [formName: string]: {
      values?: FormData;
    };
  };
}

type J5PcrReactionType =
  RecursiveRequired<CreateOrEditPlateMapJ5ReportJ5PcrReactionsFragment>;
type J5AssemblyPieceType =
  RecursiveRequired<CreateOrEditPlateMapJ5ReportJ5AssemblyPiecesFragment>;
type J5RunConstructType =
  RecursiveRequired<CreateOrEditPlateMapJ5Reportj5RunConstructFragment>;
type PrimaryTemplateType =
  RecursiveRequired<CreateOrEditPlateMapJ5ReportprimaryTemplateFragment>;
type PrimerType = RecursiveRequired<CreateOrEditPlateMapJ5ReportprimerFragment>;
type PcrProductSequenceType =
  RecursiveRequired<CreateOrEditPlateMapJ5ReportpcrProductSequenceFragment>;
type J5InputSequenceType =
  RecursiveRequired<CreateOrEditPlateMapJ5Reportj5InputSequenceFragment>;

type j5EntityQueryResult =
  | J5PcrReactionType
  | J5AssemblyPieceType
  | J5RunConstructType
  | PrimaryTemplateType
  | PrimerType
  | PcrProductSequenceType
  | J5InputSequenceType;

const PlateMapSettingsStep = ({
  toolSchema,
  nextStep,
  Footer,
  footerProps,
  handleSubmit
}: Props & InjectedFormProps<FormData, Props>) => {
  const { selectedEntities } = useTableEntities<
    RecursiveRequired<SampleAdditionalFragment>
  >("createPlateMapSampleInventoryListTable");

  const {
    uploadingVolumeInfo,
    uploadMaterialPlateMap,
    selectedContainerFormat,
    itemType,
    breakIntoQuadrants,
    j5EntityType,
    plateMapType,
    plateWellContentType,
    j5Reports = [],
    reactionMaps = [],
    plateMaps = [],
    destinationPlateMapQuadrants = [],
    j5EntityRadio,
    reactionEntityType,
    materials,
    additiveMaterials,
    lots,
    samples,
    containerArrays = [],
    j5PcrReactions,
    j5Materials
  } = useSelector<ReduxState, FormData>(state =>
    formValueSelector(toolSchema.code)(state, ...fields)
  );

  const dispatch = useDispatch();
  const [activeDestinationPlateMapIndex, setActiveDestinationPlateMapIndex] =
    useState(0);
  const [numDestinationPlateMaps, setNumDestinationPlateMaps] = useState(1);

  const getCsvNameFieldHeader = () =>
    `${modelNameToReadableName(itemType, {
      upperCase: true
    })} Name`;

  const getCsvHeaders = () => {
    return ["Plate Key", getCsvNameFieldHeader(), "Well"];
  };

  const beforeUpload = async (
    fileList: any[],
    onChange: (files: FileUploadResult[]) => void
  ) => {
    if (!selectedContainerFormat?.code) {
      return window.toastr.error("Please select a plate format.");
    }

    const file = fileList[0];
    const newFile = {
      ...file,
      loading: false
    };

    const stopLoading = () => {
      onChange([newFile]);
    };

    const makeError = (error: string) => {
      stopLoading();
      dispatch(
        stopSubmit(toolSchema.code, {
          plateMapCsv: error
        })
      );
    };

    try {
      const readableName = modelNameToReadableName(itemType);
      const { parsedData } = file;

      const itemNames: string[] = [];
      const rowKey = getCsvNameFieldHeader();

      let uploadingVolumeInfo = false;
      for (const [index, row] of parsedData.entries()) {
        if (!row[rowKey].trim()) {
          return makeError(
            `Row ${
              index + 1
            } is missing a ${readableName} name, please provide one.`
          );
        }
        if (row["Volume (optional)"]) {
          uploadingVolumeInfo = true;
        }

        const materialName = row[rowKey].trim();
        if (materialName && !itemNames.includes(materialName)) {
          itemNames.push(materialName);
        }
      }
      const filterArray: { [key in string]: FilterExpression }[] = [];
      const qb = new QueryBuilder(itemType);
      itemNames.forEach(name =>
        filterArray.push({ name: qb.lowerCase(name.toLowerCase()) })
      );
      const filter = qb.whereAny(...filterArray).toJSON();

      let fragToUse:
        | ValueOf<typeof modelNameToFragment>
        | ValueOf<typeof modelNameToAdditionalFragment>;
      if (itemType in modelNameToAdditionalFragment) {
        fragToUse =
          modelNameToAdditionalFragment[
            itemType as keyof typeof modelNameToAdditionalFragment
          ];
      } else {
        fragToUse =
          modelNameToFragment[itemType as keyof typeof modelNameToFragment];
      }
      const items = (await safeQuery<modelNameToFragmentQuery>(fragToUse, {
        variables: {
          filter
        }
      })) as ReturnType<typeof getSelectedItems>[number][];

      const selectedItems = getSelectedItems({
        itemType,
        breakIntoQuadrants,
        j5EntityRadio,
        j5PcrReactions,
        j5Materials,
        samples,
        reactionEntityType,
        materials,
        additiveMaterials,
        plateWellContentType,
        lots,
        containerArrays,
        plateMaps
      });
      const keyedItems = keyBy(items, m => m.name.toLowerCase().trim());
      let keyedAlreadySelectedItems: {
        [id: string]: ReturnType<typeof getSelectedItems>[number];
      } = {};
      let newSelectedItems: ReturnType<typeof getSelectedItems>[number][] = [];
      if (!uploadingVolumeInfo) {
        keyedAlreadySelectedItems = keyBy<
          ReturnType<typeof getSelectedItems>[number]
        >(selectedItems, "id");
        newSelectedItems = [...selectedItems];
      }
      items.forEach(m => {
        if (!keyedAlreadySelectedItems[m.id]) {
          newSelectedItems.push(m);
          keyedAlreadySelectedItems[m.id] = m;
        }
      });

      const plateMapKeys = [];
      const platesToCreate: PlateToCreateType[] = [];
      const plateKeyToIndex: { [key in string]: number } = {};

      const missingItems: Item[] = [];
      parsedData.forEach((row: any) => {
        const itemName = row[rowKey];
        const existingItem = keyedItems[itemName.toLowerCase().trim()];

        if (!existingItem && !missingItems.includes(itemName)) {
          missingItems.push(itemName);
        }
      });
      if (missingItems.length) {
        return makeError(
          `These ${readableName} were not found in the ${readableName} library: ${missingItems.join(
            ", "
          )}.`
        );
      }

      // because we only one thing per well we need to make sure there are not two rows of items going into the same well
      const plateLocationTracker: { [plateKey in string]: string[] } = {};
      for (const [index, row] of parsedData.entries()) {
        const {
          "Plate Key": plateKey,
          Well: _location,
          "Volume (optional)": volume,
          "Volumetric Unit (optional)": volumetricUnitCode
        } = row;
        if (uploadingVolumeInfo) {
          if (!volume) {
            return makeError(
              `Row ${
                index + 1
              } does not have a volume. If providing volume info it needs to be on each row.`
            );
          } else if (!isValidPositiveNumber(volume)) {
            return makeError(`Row ${index + 1} does specify a valid volume.`);
          }
          if (!volumetricUnitCode) {
            return makeError(
              `Row ${index + 1} does not specify a valid volumetric unit.`
            );
          }
          if (
            volumetricUnitCode &&
            !unitGlobals.volumetricUnits[volumetricUnitCode]
          ) {
            return makeError(
              `Row ${
                index + 1
              } specifies the volumetric unit ${volumetricUnitCode} which does not exist.`
            );
          }
        }

        const itemName = row[rowKey];
        const location = cleanPosition(_location);
        const existingItem = keyedItems[itemName.toLowerCase().trim()];

        if (!location) {
          return makeError(
            `Row ${index + 1} is missing a well location, please provide one.`
          );
        }
        if (!plateKey) {
          return makeError(
            `Row ${index + 1} is missing a plate key, please provide one.`
          );
        }
        let plateIndex = plateKeyToIndex[plateKey];
        if (plateIndex === undefined) {
          plateIndex = plateMapKeys.push(plateKey) - 1;
          plateKeyToIndex[plateKey] = plateIndex;
        }
        if (!platesToCreate[plateIndex]) {
          platesToCreate[plateIndex] = {};
        }
        if (!wellInBounds(location, selectedContainerFormat)) {
          return makeError(
            `The well ${location} will not fit onto the selected plate format.`
          );
        }
        plateLocationTracker[plateKey] = plateLocationTracker[plateKey] || [];
        if (plateLocationTracker[plateKey].includes(location)) {
          return makeError(
            `Multiple rows pointed to the same location ${location} on plate ${plateKey}. Please only specify one row per well.`
          );
        }
        plateLocationTracker[plateKey].push(location);
        platesToCreate[plateIndex][location] = {
          item: existingItem,
          volume,
          volumetricUnitCode
        };
      }
      dispatch(
        change(toolSchema.code, "uploadingVolumeInfo", uploadingVolumeInfo)
      );
      dispatch(change(toolSchema.code, "platesToCreate", platesToCreate));
      dispatch(
        change(toolSchema.code, pluralizeItemType[itemType], newSelectedItems)
      );
      stopLoading();
    } catch (error) {
      console.error("error:", error);
      makeError(error.message || "Unable to upload plate map csv.");
    }
  };

  const clearPlates = useCallback(() => {
    dispatch(change(toolSchema.code, "platesToCreate", []));
    dispatch(change(toolSchema.code, "plateMaps", []));
  }, [dispatch, toolSchema.code]);

  const removeDestinationPlateMap = () => {
    const newDestinationPlateMapQuadrants = destinationPlateMapQuadrants.filter(
      (d, i) => i !== activeDestinationPlateMapIndex
    );
    setNumDestinationPlateMaps(prev => prev - 1);
    setActiveDestinationPlateMapIndex(prev => (prev - 1 < 0 ? 0 : prev - 1));
    dispatch(
      change(
        toolSchema.code,
        "destinationPlateMapQuadrants",
        newDestinationPlateMapQuadrants
      )
    );
  };

  const onSelectMainEntity = useCallback(
    (mainEntities: ReactionMap[] = []) => {
      setActiveDestinationPlateMapIndex(0);
      const selectedPlateMapOrJ5Reports =
        itemType === "plateMap" || itemType === "j5Report";
      if (selectedPlateMapOrJ5Reports && breakIntoQuadrants) {
        const numDestinationPlateMaps = Math.ceil(mainEntities.length / 4);
        setNumDestinationPlateMaps(numDestinationPlateMaps);
        const destinationPlateMapQuadrants: { [quadrant in string]: string }[] =
          [];
        times(numDestinationPlateMaps, i => {
          const startIndex = i * 4;
          destinationPlateMapQuadrants[i] = {};
          times(4, j => {
            const plateMapIndex = startIndex + j;
            if (mainEntities[plateMapIndex]) {
              destinationPlateMapQuadrants[i][`quadrant${j + 1}`] =
                mainEntities[plateMapIndex].id;
            }
          });
        });
        dispatch(
          change(
            toolSchema.code,
            "destinationPlateMapQuadrants",
            destinationPlateMapQuadrants
          )
        );
      }
      if (itemType === "reactionMap") {
        const changed = reactionMaps !== mainEntities;
        if (changed) {
          // clear these so that the table won't be populated
          dispatch(change(toolSchema.code, "materials", []));
          dispatch(change(toolSchema.code, "additiveMaterials", []));
        }
      }
    },
    [breakIntoQuadrants, dispatch, itemType, reactionMaps, toolSchema.code]
  );

  const beforeNextStep = async (values: FormData) => {
    const {
      plateMaps = [],
      containerArrays = [],
      j5Reports = [],
      itemType,
      j5EntityType,
      plateMapType,
      plateWellContentType,
      breakIntoQuadrants,
      breakdownPattern,
      uploadMaterialPlateMap,
      destinationPlateMapQuadrants: _destinationPlateMapQuadrants,
      selectedContainerFormat,
      [FieldConstants.selectAllReactionEntities]: selectAllReactionEntities,
      [FieldConstants.reactionEntityType]: reactionEntityType,
      reactionMaps
    } = values;

    const fixedQuadrants =
      (itemType === "plateMap" || itemType === "j5Report") &&
      breakIntoQuadrants;

    try {
      if (selectAllReactionEntities) {
        const { additionalFilter, itemType } =
          getItemTypeAndFilterForReactionMaps({
            reactionEntityType,
            reactionMaps
          });
        let queryToUse;
        switch (itemType) {
          case "material":
            queryToUse = materialAdditionalFragment;
            break;
          case "additiveMaterial":
            queryToUse = additiveMaterialFragment;
            break;
        }
        const items = await safeQuery(queryToUse, {
          variables: {
            filter: additionalFilter
          }
        });
        if (!items.length) {
          return window.toastr.error(
            `Could not find any ${reactionEntityType}`
          );
        }
        dispatch(change(toolSchema.code, pluralizeItemType[itemType], items));
      }
      if (fixedQuadrants) {
        let keyedPlateMaps: {
          [key: string]: PlateMap;
        } = {};
        const platesToCreate: PlateItem[] = [];

        let destinationPlateMapQuadrants = _destinationPlateMapQuadrants;

        if (itemType === "j5Report") {
          const j5ReportsWithEntities = (await safeQuery<j5EntityQueryResult>(
            j5EntityToFragment[j5EntityType],
            {
              variables: {
                filter: { id: j5Reports.map(j5Report => j5Report.id) }
              }
            }
          )) as j5EntityQueryResult[];
          const j5ReportIdToEntities: {
            [id: string]: {
              id: string;
              name: string;
            }[];
          } = {};

          j5ReportsWithEntities.forEach(j5Report => {
            j5ReportIdToEntities[j5Report.id] = [];
            if (j5EntityType === "j5PcrReaction") {
              j5ReportIdToEntities[j5Report.id] = (
                j5Report as J5PcrReactionType
              ).j5PcrReactions;
            } else if (j5EntityType === "primaryTemplate") {
              (j5Report as PrimaryTemplateType).j5PcrReactions.forEach(
                pcrReaction => {
                  const material = get(
                    pcrReaction,
                    "primaryTemplate.polynucleotideMaterial"
                  );
                  if (!material)
                    throw {
                      validationMsg: `Assembly report ${j5Report.name} not linked to materials.`
                    };
                  j5ReportIdToEntities[j5Report.id].push(material);
                }
              );
            } else if (j5EntityType === "pcrProductSequence") {
              (j5Report as PcrProductSequenceType).j5PcrReactions.forEach(
                pcrReaction => {
                  const material = get(
                    pcrReaction,
                    "pcrProductSequence.polynucleotideMaterial"
                  );
                  if (!material)
                    throw {
                      validationMsg: `Assembly report ${j5Report.name} not linked to materials.`
                    };
                  j5ReportIdToEntities[j5Report.id].push(material);
                }
              );
            } else if (j5EntityType === "primer") {
              (j5Report as PrimerType).j5PcrReactions.forEach(pcrReaction => {
                const forwardPrimerMaterial = get(
                  pcrReaction,
                  "forwardPrimer.sequence.polynucleotideMaterial"
                );
                const reversePrimerMaterial = get(
                  pcrReaction,
                  "reversePrimer.sequence.polynucleotideMaterial"
                );
                if (!forwardPrimerMaterial || !reversePrimerMaterial)
                  throw {
                    validationMsg: `Assembly report ${j5Report.name} not linked to materials.`
                  };
                j5ReportIdToEntities[j5Report.id].push(forwardPrimerMaterial);
                j5ReportIdToEntities[j5Report.id].push(reversePrimerMaterial);
              });
            } else if (j5EntityType === "j5AssemblyPiece") {
              (j5Report as J5AssemblyPieceType).j5AssemblyPieces.forEach(
                assemblyPiece => {
                  const material = get(
                    assemblyPiece,
                    "sequence.polynucleotideMaterial"
                  );
                  if (!material)
                    throw {
                      validationMsg: `Assembly report ${j5Report.name} not linked to materials.`
                    };
                  j5ReportIdToEntities[j5Report.id].push(material);
                }
              );
            } else if (j5EntityType === "j5InputSequence") {
              (j5Report as J5InputSequenceType).j5InputSequences.forEach(
                inputSequence => {
                  const material = get(
                    inputSequence,
                    "sequence.polynucleotideMaterial"
                  );
                  if (!material)
                    throw {
                      validationMsg: `Assembly report ${j5Report.name} not linked to materials.`
                    };
                  j5ReportIdToEntities[j5Report.id].push(material);
                }
              );
            } else if (j5EntityType === "j5RunConstruct") {
              (j5Report as J5RunConstructType).j5RunConstructs.forEach(
                construct => {
                  const material = get(
                    construct,
                    "sequence.polynucleotideMaterial"
                  );
                  if (!material)
                    throw {
                      validationMsg: `Assembly report ${j5Report.name} not linked to materials.`
                    };
                  j5ReportIdToEntities[j5Report.id].push(material);
                }
              );
            }
          });
          destinationPlateMapQuadrants = [];
          const { quadrant1, quadrant2, quadrant3, quadrant4 } =
            _destinationPlateMapQuadrants[0];
          const allReportEntities = [
            j5ReportIdToEntities[quadrant1],
            j5ReportIdToEntities[quadrant2],
            j5ReportIdToEntities[quadrant3],
            j5ReportIdToEntities[quadrant4]
          ];
          times(4, plateMapIndex => {
            const reportEntitiesForIndex =
              allReportEntities[plateMapIndex] || [];
            chunk(
              reportEntitiesForIndex,
              selectedContainerFormat!.quadrantSize / 4
            ).forEach((chunkOfEntities, i) => {
              const plateMapKey = shortid();
              keyedPlateMaps[plateMapKey] = {
                plateMapItems: (
                  generateEmptyWells(selectedContainerFormat!) as {
                    columnPosition: number;
                    rowPosition: number;
                    j5Item?: (typeof chunkOfEntities)[0];
                  }[]
                ).map((well, i) => {
                  const j5Item = chunkOfEntities[i];
                  well.j5Item = j5Item;
                  return well;
                }),
                plateMapGroup: {
                  containerFormat: selectedContainerFormat
                }
              };
              set(
                destinationPlateMapQuadrants,
                `[${i}].quadrant${plateMapIndex + 1}`,
                plateMapKey
              );
            });
          });
        } else {
          keyedPlateMaps = keyBy<PlateMap>(plateMaps, "id");
        }
        destinationPlateMapQuadrants.forEach(quadrants => {
          const plateToCreate: {
            [location: string]: {
              item: NonNullable<PlateItem>;
            };
          } = {};
          const { quadrant1, quadrant2, quadrant3, quadrant4 } = quadrants;
          const plateMapFor1 = keyedPlateMaps[quadrant1];
          const plateMapFor2 = keyedPlateMaps[quadrant2];
          const plateMapFor3 = keyedPlateMaps[quadrant3];
          const plateMapFor4 = keyedPlateMaps[quadrant4];

          const sortedSourcePlateAcs: PlateMap["plateMapItems"][] = [];
          const sourcePlateMaps = [
            plateMapFor1,
            plateMapFor2,
            plateMapFor3,
            plateMapFor4
          ];

          sourcePlateMaps.forEach(plateMap => {
            if (plateMap) {
              const plateMapItemsFilled: PlateMap["plateMapItems"] =
                generateContainerArray(
                  plateMap.plateMapItems,
                  plateMap.plateMapGroup!.containerFormat as ContainerFormat
                );
              sortedSourcePlateAcs.push(
                sortBy(plateMapItemsFilled, ["rowPosition", "columnPosition"])
              );
            } else {
              sortedSourcePlateAcs.push([]);
            }
          });
          const { rowCount: destRowCount, columnCount: destColCount } =
            selectedContainerFormat!;
          const srcRowCount = destRowCount / 2;
          const srcColCount = destColCount / 2;
          const destinationPlate: {
            columnPosition: number;
            rowPosition: number;
          }[] = generateContainerArray([], selectedContainerFormat!);
          const aliquotContainer2dArray = plateTo2dAliquotContainerArray({
            aliquotContainers: destinationPlate,
            containerArrayType: {
              containerFormat: selectedContainerFormat!
            }
          });
          const blockRowCount = destRowCount / srcRowCount;
          const blockColCount = destColCount / srcColCount;
          range(srcRowCount).forEach(destRowPos => {
            range(srcColCount).forEach(destColPos => {
              const block = getBlockOf2dArray(
                aliquotContainer2dArray,
                blockRowCount,
                blockColCount,
                destColPos,
                destRowPos,
                true,
                true
              );
              const inputAliquotContainers = times(
                4,
                i => sortedSourcePlateAcs[i] && sortedSourcePlateAcs[i].shift()
              );
              blockToAliquotArray(block, breakdownPattern).forEach(
                (aliquotContainer, plateIndex) => {
                  const sourceAc = inputAliquotContainers[plateIndex]!;
                  let item;
                  if (
                    sourceAc &&
                    "inventoryItem" in sourceAc &&
                    sourceAc.inventoryItem &&
                    plateMapType in sourceAc.inventoryItem
                  ) {
                    item = sourceAc.inventoryItem[plateMapType];
                  } else if (
                    sourceAc &&
                    "j5Item" in sourceAc &&
                    sourceAc.j5Item
                  ) {
                    item = sourceAc.j5Item;
                  }
                  if (item) {
                    plateToCreate[
                      getAliquotContainerLocation(aliquotContainer)
                    ] = {
                      item
                    };
                  }
                }
              );
            });
          });

          platesToCreate.push(plateToCreate);
        });

        dispatch(change(toolSchema.code, "platesToCreate", platesToCreate));
      }
      if (!uploadMaterialPlateMap && !fixedQuadrants) {
        dispatch(change(toolSchema.code, "platesToCreate", []));
      }
      if (itemType === "containerArray") {
        type PlateEntityType =
          | RecursiveRequired<MaterialAdditionalFragment[]>
          | RecursiveRequired<SampleAdditionalFragment[]>;
        const plateEntities: PlateEntityType = [];
        const addPlateEnt = (
          plate: ContainerArrayAdditionalFragment,
          ac: {
            rowPosition: number;
            columnPosition: number;
            containerArray?: object;
            containerArrayType?: object;
          },
          path: string
        ) => {
          const ent = get(ac, path);
          if (!ent) return;
          const plateBarcode = plate.barcode?.barcodeString;
          plateEntities.push({
            ...ent,
            well: getAliquotContainerLocation(ac, {
              containerFormat: plate.containerArrayType?.containerFormat
            }),
            plateName: plate.name,
            plateBarcode
          });
        };
        let path, fieldName;
        if (plateWellContentType === "sample") {
          path = "aliquot.sample";
          fieldName = "samples";
        } else {
          path = "aliquot.sample.material";
          fieldName = "materials";
        }
        containerArrays.forEach(p =>
          p.aliquotContainers.forEach(ac => addPlateEnt(p, ac, path))
        );
        dispatch(
          change(
            toolSchema.code,
            fieldName,
            uniqBy<PlateEntityType[number]>(plateEntities, "id")
          )
        );
      }
      if (itemType === "Inventory List") {
        dispatch(
          change(
            toolSchema.code,
            "samples",
            Object.values(selectedEntities || {}).map(e => e.entity)
          )
        );
      }
    } catch (error) {
      console.error("error:", error);
      return window.toastr.error(
        error.validationMsg || "Error generating plate maps"
      );
    }
    dispatch(change(toolSchema.code, "fixedQuadrants", fixedQuadrants));
    nextStep();
  };

  const renderJ5ReportOptions = () => {
    const selectedItemsNames: string[] = [];
    const duplicateItemNames = [];
    const selectedItems = getSelectedItems({
      itemType,
      breakIntoQuadrants,
      j5EntityRadio,
      j5PcrReactions,
      j5Materials,
      samples,
      reactionEntityType,
      materials,
      additiveMaterials,
      plateWellContentType,
      lots,
      containerArrays,
      plateMaps
    });

    if (selectedItems.length > 0) {
      selectedItems.forEach(material => {
        if (selectedItemsNames.includes(material.name)) {
          duplicateItemNames.push(material.name);
        } else {
          selectedItemsNames.push(material.name);
        }
      });
    }

    if (j5Reports.length && itemType === "j5Report") {
      if (breakIntoQuadrants) {
        return (
          <div className="tg-flex column" style={{ maxWidth: 250 }}>
            <ReactSelectField
              name="j5EntityType"
              options={[
                {
                  label: "Input Sequences",
                  value: "j5InputSequence"
                },
                { label: "PCR Reactions", value: "j5PcrReaction" },
                {
                  label: "Primary Templates",
                  value: "primaryTemplate"
                },
                { label: "Primers", value: "primer" },
                {
                  label: "PCR Products",
                  value: "pcrProductSequence"
                },
                {
                  label: "Assembly Pieces",
                  value: "j5AssemblyPiece"
                },
                { label: "Constructs", value: "j5RunConstruct" }
              ]}
              label="Assembly Report Entity"
            />
          </div>
        );
      } else {
        return (
          <div className="tg-flex column">
            <RadioGroupField
              inline
              options={[
                {
                  label: "Report Materials",
                  value: "material"
                },
                {
                  label: "PCR Reactions",
                  value: "j5PcrReaction"
                }
              ]}
              defaultValue="material"
              name="j5EntityRadio"
              label=""
            />
            {j5EntityRadio && (
              <SelectJ5MaterialsOrPcrReactions
                entityType={j5EntityRadio}
                j5Reports={j5Reports}
                modelNameToSchema={modelNameToSchema}
                modelNameToFragment={modelNameToFragment}
                modelNameToAdditionalFragment={modelNameToAdditionalFragment}
              />
            )}
          </div>
        );
      }
    }
    return;
  };

  const renderReactionMapOptions = () => {
    if (itemType !== "reactionMap") return;

    return (
      <SelectReactionMapEntities
        toolSchema={toolSchema}
        change={(...args) => dispatch(change(toolSchema.code, ...args))}
        modelNameToSchema={modelNameToSchema}
        fieldConstants={FieldConstants}
        reactionMaps={reactionMaps}
        modelNameToAdditionalFragment={modelNameToAdditionalFragment}
        modelNameToFragment={modelNameToFragment}
        onlyMaterials={false}
      />
    );
  };

  let quadrantEntities: J5ReportType[] | PlateMapFragment[];
  if (itemType === "j5Report") {
    quadrantEntities = j5Reports;
  } else if (itemType === "plateMap") {
    quadrantEntities = plateMaps;
  }

  const selectedItemsNames: string[] = [];
  const duplicateItemNames: string[] = [];
  const csvHeaders = getCsvHeaders();
  const selectedItems = getSelectedItems({
    itemType,
    breakIntoQuadrants,
    j5EntityRadio,
    j5PcrReactions,
    j5Materials,
    samples,
    reactionEntityType,
    materials,
    additiveMaterials,
    plateWellContentType,
    lots,
    containerArrays,
    plateMaps
  });
  if (selectedItems.length > 0) {
    selectedItems.forEach(item => {
      if (selectedItemsNames.includes(item.name)) {
        duplicateItemNames.push(item.name);
      } else {
        selectedItemsNames.push(item.name);
      }
    });
  }

  const plateMapFilter = useMemo(() => {
    if (breakIntoQuadrants) {
      return {
        type: plateMapType,
        "plateMapGroup.containerFormatCode":
          quadrantFormatMap[
            selectedContainerFormat?.code as keyof typeof quadrantFormatMap
          ]
      };
    } else {
      return {
        type: plateMapType,
        "plateMapGroup.containerFormatCode": selectedContainerFormat?.code
      };
    }
  }, [breakIntoQuadrants, plateMapType, selectedContainerFormat?.code]);

  const quadrantEntityOptions = arrayToIdOrCodeValuedOptions(
    quadrantEntities! || []
  );

  const normalFlow =
    !(
      itemType === "j5Report" ||
      itemType === "plateMap" ||
      itemType === "reactionMap" ||
      itemType === "containerArray"
    ) && !breakIntoQuadrants;

  const errorsForPlates = useMemo(() => {
    const _errorsForPlates: { [id: string]: string } = {};
    const hasEntity = (
      ac: ContainerArray["aliquotContainers"][number],
      plateMapType: string
    ) => {
      if (plateMapType === "sample") {
        return get(ac, "aliquot.sample");
      } else {
        return get(ac, "aliquot.sample.material");
      }
    };

    containerArrays.forEach(containerArray => {
      if (
        !containerArray.aliquotContainers.some(ac =>
          hasEntity(ac, plateWellContentType)
        )
      ) {
        _errorsForPlates[containerArray.id] =
          `This plate does not have any ${pluralizeplateWellContentType[plateWellContentType]}.`;
      }
    });
    return _errorsForPlates;
  }, [containerArrays, plateWellContentType]);

  const postSelectTableSchema = useMemo(() => {
    if (itemType === "containerArray") {
      return [
        {
          type: "action",
          width: 35,
          render: (_: any, record: { id: string }) => {
            const error = errorsForPlates[record.id];
            if (error) {
              return (
                // @ts-ignore
                <Tooltip content={error}>
                  <Icon
                    intent="danger"
                    style={{ marginRight: 10 }}
                    icon="warning-sign"
                  />
                </Tooltip>
              );
            }
            return;
          }
        },
        platePreviewColumn(),
        {
          path: "name"
        },
        {
          path: "barcode.barcodeString",
          displayName: "Barcode"
        }
      ];
    }

    return modelNameToSchema[itemType as keyof typeof modelNameToSchema];
  }, [errorsForPlates, itemType]);

  const readyToSelect = useMemo(() => {
    if (itemType === "plateMap") {
      return !!plateMapType;
    } else if (itemType === "containerArray") {
      return !!plateWellContentType;
    }
    return true;
  }, [itemType, plateMapType, plateWellContentType]);

  return (
    <div>
      <div className="tg-step-form-section">
        <HeaderWithHelper
          header="Select Plate Format"
          helper="Specify a plate format for the output plate map. If you would like to break the plate map into quadrants, check the box and specify a breakdown pattern."
        />
        <div className="tg-flex column" style={{ width: "30%" }}>
          <GenericSelect
            generateDefaultValue={generateDefaultValue}
            {...defaultValueConstants.CREATE_PLATE_MAP_PLATE_FORMAT}
            label="Plate Format"
            name="selectedContainerFormat"
            onFieldSubmit={clearPlates}
          />
          <div className="tg-flex column">
            <div className="tg-flex">
              <CheckboxField
                name="breakIntoQuadrants"
                label="Break Into Quadrants"
              />
              <div style={{ marginTop: 7, marginLeft: 10 }}>
                {/* @ts-ignore */}
                <Tooltip
                  content="Checking this box allows placement of entities onto quadrants of the output plate map."
                  position="auto"
                  // boundary="preventOverflow"
                >
                  <Icon icon="help" />
                </Tooltip>
              </div>
            </div>
            {breakIntoQuadrants && (
              <div style={{ width: 150, marginTop: 10 }}>
                <SelectField
                  name="breakdownPattern"
                  label="Breakdown Pattern"
                  defaultValue="Z"
                  options={breakdownPatterns(true)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="tg-step-form-section">
        <HeaderWithHelper
          header="Choose Item Type"
          helper="Which type of items will be used to populate these plate maps."
        />
        <div style={{ width: "30%" }}>
          <ReactSelectField
            name={FieldConstants.itemType}
            options={itemTypeOptions}
            label="Item Type"
            onFieldSubmit={() => {
              dispatch(change(toolSchema.code, "materials", []));
              dispatch(change(toolSchema.code, "additiveMaterials", []));
            }}
          />
          {itemType === "plateMap" && (
            <ReactSelectField
              name="plateMapType"
              options={Object.values(PlateMapItemTypes).map(model => ({
                label: modelNameToReadableName(model, { upperCase: true }),
                value: model
              }))}
              onFieldSubmit={() =>
                dispatch(change(toolSchema.code, "plateMaps", []))
              }
              label="Plate Map Type"
            />
          )}
          {itemType === "containerArray" && (
            <ReactSelectField
              name="plateWellContentType"
              options={Object.values(PlateWellContentTypes).map(model => ({
                label: modelNameToReadableName(model, { upperCase: true }),
                value: model
              }))}
              onFieldSubmit={() =>
                dispatch(change(toolSchema.code, "plateMaps", []))
              }
              label="Plate Well Contents"
            />
          )}
        </div>
      </div>
      {!!selectedContainerFormat &&
        !!itemType &&
        itemType !== "Inventory List" &&
        readyToSelect && (
          <div>
            <div className="tg-step-form-section column">
              <div className="tg-flex justify-space-between">
                <HeaderWithHelper
                  width="100%"
                  header={`Select ${modelNameToReadableName(itemType, {
                    plural: true,
                    upperCase: true
                  })}`}
                  helper={`Choose which ${modelNameToReadableName(itemType, {
                    plural: true
                  })} you would like to apply to the output plate map.`}
                />
                {normalFlow && (
                  <div
                    className="tg-flex justify-space-between"
                    style={{ width: 250 }}
                  >
                    <CheckboxField
                      name="uploadMaterialPlateMap"
                      label="Populate Plate Map with CSV"
                    />
                    <div style={{ marginTop: 5 }}>
                      {/* @ts-ignore */}
                      <Tooltip
                        content={`Check this box to provide a CSV of ${
                          pluralizeItemType[itemType]
                        } instead of selecting manually.`}
                        position={Position.BOTTOM_RIGHT}
                        // boundary="preventOverflow"
                      >
                        <Icon icon="help" />
                      </Tooltip>
                    </div>
                  </div>
                )}
              </div>
              <div>
                {normalFlow && uploadMaterialPlateMap && (
                  <div style={{ marginBottom: 20, maxWidth: 300 }}>
                    <FileUploadField
                      beforeUpload={beforeUpload}
                      accept={getDownloadTemplateFileHelpers({
                        fileName: "create_plate_map.csv",
                        validateAgainstSchema: {
                          fields: csvHeaders
                            .map(
                              h =>
                                ({
                                  path: h,
                                  isRequired: true,
                                  description:
                                    h === "Plate Key"
                                      ? "Required. Used to specify items across multiple plates."
                                      : undefined
                                }) as FieldType
                            )
                            .concat([
                              { path: "Volume (optional)" },
                              { path: "Volumetric Unit (optional)" }
                            ])
                        }
                      })}
                      fileLimit={1}
                      name="plateMapCsv"
                    />
                  </div>
                )}
                {!uploadingVolumeInfo && (
                  <GenericSelect
                    key={itemType}
                    name={pluralizeItemType[itemType]}
                    isMultiSelect
                    schema={
                      modelNameToSchema[
                        itemType as keyof typeof modelNameToSchema
                      ]
                    }
                    fragment={modelNameToFragment[itemType]}
                    additionalDataFragment={
                      modelNameToAdditionalFragment[
                        itemType as keyof typeof modelNameToAdditionalFragment
                      ]
                    }
                    tableParamOptions={{
                      additionalFilter:
                        itemType === "plateMap" ? plateMapFilter : undefined
                    }}
                    onSelect={onSelectMainEntity}
                    postSelectDTProps={{
                      formName: "plateMapEntitiesTable",
                      schema: postSelectTableSchema,
                      errorsForPlates
                    }}
                  />
                )}
                {renderJ5ReportOptions()}
                {renderReactionMapOptions()}

                {duplicateItemNames.length > 0 && (
                  <Callout intent="warning" icon="error">
                    {`The following selected ${modelNameToReadableName(
                      itemType,
                      {
                        plural: true
                      }
                    )} have duplicated names: ${duplicateItemNames.join(
                      ", "
                    )}.`}
                  </Callout>
                )}
              </div>
            </div>
            {breakIntoQuadrants &&
              ((j5Reports &&
                itemType === "j5Report" &&
                j5Reports.length > 0 &&
                j5EntityType) ||
                (plateMaps &&
                  itemType === "plateMap" &&
                  plateMaps.length > 0)) && (
                <div className="tg-step-form-section">
                  <HeaderWithHelper
                    header="Assign to Quadrant"
                    helper={`Assign each selected ${modelNameToReadableName(
                      itemType
                    )} to a quadrant.`}
                  />

                  <div className="tg-flex column">
                    {itemType === "plateMap" && (
                      <div
                        className="tg-flex align-center"
                        style={{ marginBottom: 10 }}
                      >
                        <Button
                          minimal
                          icon="arrow-left"
                          disabled={activeDestinationPlateMapIndex <= 0}
                          onClick={() => {
                            setActiveDestinationPlateMapIndex(prev => prev - 1);
                          }}
                        />
                        <div>
                          Destination Plate Map{" "}
                          {activeDestinationPlateMapIndex + 1} of{" "}
                          {numDestinationPlateMaps}
                        </div>
                        <Button
                          minimal
                          icon="arrow-right"
                          disabled={
                            activeDestinationPlateMapIndex >=
                            numDestinationPlateMaps - 1
                          }
                          onClick={() => {
                            setActiveDestinationPlateMapIndex(prev => prev + 1);
                          }}
                        />
                        <Button
                          icon="trash"
                          intent="danger"
                          minimal
                          style={{ marginLeft: 10 }}
                          disabled={numDestinationPlateMaps <= 1}
                          onClick={removeDestinationPlateMap}
                        />
                        <Button
                          icon="add"
                          intent="success"
                          minimal
                          style={{ marginLeft: 10 }}
                          onClick={() => {
                            setNumDestinationPlateMaps(prev => prev + 1);
                          }}
                        />
                      </div>
                    )}
                    {times(4, i => {
                      return (
                        <div key={i}>
                          <ReactSelectField
                            name={`destinationPlateMapQuadrants.${activeDestinationPlateMapIndex}.quadrant${
                              i + 1
                            }`}
                            options={quadrantEntityOptions}
                            label={`Quadrant ${i + 1}`}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
          </div>
        )}
      {itemType === "Inventory List" && (
        <InventoryListSection
          formName={toolSchema.code}
          modelNameToFragment={modelNameToAdditionalFragment}
          change={(...args) => dispatch(change(toolSchema.code, ...args))}
        />
      )}
      <Footer
        {...footerProps}
        nextDisabled={
          (itemType === "Inventory List" && isEmpty(selectedEntities || {})) ||
          !isEmpty(errorsForPlates)
        }
        onNextClick={handleSubmit(beforeNextStep)}
      />
    </div>
  );
};

export default PlateMapSettingsStep;
