/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useState } from "react";
import { get, capitalize, isEmpty, toString, startCase } from "lodash";
import { Button, Classes } from "@blueprintjs/core";
import { CheckboxField, BlueprintError, DialogFooter } from "@teselagen/ui";
import modelNameToReadableName from "../utils/modelNameToReadableName";

import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { unparse } from "papaparse";
import { safeQuery } from "../apolloMethods";
import { wrapDialog } from "@teselagen/ui";
import { download } from "../utils/downloadTest";

import { throwFormError } from "../utils/formUtils";
import { formatDateTime } from "../utils/dateUtils";
import { showDialog } from "../GlobalDialog";

/*
future implementation: look at getSelectTable.js on how to generate a fragment from a table schema
then we will send that schema to generate a fragment on the backend which will start running a fetch
for all the rows in the table. Then it will compile the csv on backend process and store file temporarily
on server and pass a link to download that file to the frontend user.

current implementation: export only the current rows shown in the table.
*/
const typeConvertMap = {
  timestamp: value => (value ? formatDateTime(value) : "")
};

export function getStringValueForField({
  field,
  record,
  index,
  cellRenderer,
  idToPath,
  props = {}
}) {
  let value;
  if (field.path) {
    if (field.path.includes("projectItems")) {
      value = (get(record, field.path.replace(".project.name", "")) || []).map(
        item => item.project.name
      );
    } else {
      value = get(record, field.path);
    }
  }

  const argsForRenderFn = [value, record, index, props];

  const valPriorityList = [];
  if (field.getClipboardData) {
    const val = field.getClipboardData(...argsForRenderFn);
    valPriorityList.push(val);
  }
  if (field.getValueToFilterOn) {
    const val = field.getValueToFilterOn(record);
    valPriorityList.push(val);
  }
  if (cellRenderer[field.path]) {
    const val = cellRenderer[field.path](...argsForRenderFn);
    valPriorityList.push(val);
  }
  if (field.render) {
    const val = field.render(...argsForRenderFn);
    valPriorityList.push(val);
  }
  if (typeConvertMap[field.type]) {
    const val = typeConvertMap[field.type](value);
    valPriorityList.push(val);
  }

  // location display
  if (field.displayName === "Location" && !isEmpty(idToPath)) {
    const pathArray = idToPath[get(record, "assignedPosition.id")] || [];
    const val = pathArray.join(" / ");
    valPriorityList.unshift(val);
  }
  valPriorityList.push(value);
  let valueToRender = "";
  for (const val of valPriorityList) {
    if (toString(val) === "[object Object]") {
      continue;
    } else {
      valueToRender = val;
      break;
    }
  }
  return valueToRender;
}

function sanitizeFieldName(name) {
  return name.replace(/\[/, "(").replace(/\]/, ")");
}

const getDisplayName = field => {
  return field.displayName || startCase(field.path);
};

const ExportAsCsvSelectColumns = props => {
  const {
    cellRenderer = {},
    entities: passedEntities,
    error,
    ExtraFields,
    filename,
    handleSubmit,
    hideModal,
    idToPath = {},
    noId,
    schema: passedSchema,
    submitting,
    tableParams: {
      schema: schemaTP,
      entities: entitiesTP,
      fragment,
      entityCount,
      variables = {},
      transformEntities: transformEntitiesTP,
      updateableModel
    } = {},
    transformEntities: passedTransformEntities
  } = props;

  const [checkboxSelected, setCheckboxSelected] = useState(true);

  const onSubmit = async values => {
    const schema = schemaTP || passedSchema;
    const transformEntities = transformEntitiesTP || passedTransformEntities;
    let recordsToExport = entitiesTP || passedEntities;
    const schemaFields = Array.isArray(schema) ? schema : schema.fields;

    const fields = schemaFields.reduce((acc, field) => {
      const displayName = getDisplayName(field);

      // if noId is provided then skip ID column
      if (noId && displayName?.toLowerCase() === "id") return acc;

      // only export the columns they choose to export
      if (!values[displayName] && !values[sanitizeFieldName(displayName)])
        return acc;

      // split these fields into a column of value and unit
      if (
        ["aliquot.volume", "aliquot.mass", "aliquot.concentration"].includes(
          field.path
        )
      ) {
        const typeToUnit = type => {
          if (type === "volume") return "volumetricUnitCode";
          else return `${type}UnitCode`;
        };
        const type = field.path.replace("aliquot.", "");
        acc.push({
          path: field.path,
          displayName: capitalize(type)
        });
        acc.push({
          path: `aliquot.${typeToUnit(type)}`,
          displayName: `${capitalize(type)} Unit`
        });
      } else {
        acc.push(field);
      }
      return acc;
    }, []);

    if (!fields.length) {
      throwFormError("Please select at least one column.");
    }
    try {
      if (fragment) {
        if (entityCount > 100) {
          window.toastr.info(`Exporting ${entityCount} records...`);
        }
        recordsToExport = await safeQuery(fragment, {
          variables: {
            filter: variables.filter,
            sort: variables.sort
          }
        });
      }
      if (transformEntities) {
        recordsToExport = await transformEntities(
          recordsToExport,
          fields,
          values
        );
      }
      if (!recordsToExport.length) {
        return window.toastr.warning("No records to export.");
      }
      const csvData = recordsToExport.map((entity, i) => {
        const row = [];
        fields.forEach(field => {
          const val = getStringValueForField({
            field,
            record: entity,
            idToPath,
            index: i,
            cellRenderer,
            props: props
          });
          row.push(val);
        });
        return row;
      });

      let csvFields = [];
      csvFields = csvFields.concat(fields.map(getDisplayName));
      const csv = unparse({
        data: csvData,
        fields: values.noHeader ? null : csvFields
      });
      const readableName = modelNameToReadableName(
        updateableModel || schema.model || recordsToExport[0].__typename,
        {
          upperCase: true,
          plural: true
        }
      );
      hideModal({ completed: true });
      download(csv, `${filename || readableName}.csv`, "text/csv");
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error exporting library.");
    }
  };

  const schema = schemaTP || passedSchema;
  const allfields = Array.isArray(schema) ? schema : schema.fields;
  const fields = allfields.filter(field => field.type !== "action"); // get rid of the locked field if its present
  return (
    <div>
      <div className={Classes.DIALOG_BODY}>
        {ExtraFields && <ExtraFields />}
        <CheckboxField
          label="No Header"
          name="noHeader"
          tooltipInfo="Will remove header row from exported CSV."
        />
        <div className="tg-flex justify-space-between">
          <h6>Choose Columns to Export</h6>
          <div>
            <Button
              text={checkboxSelected ? "Deselect All" : "Select All"}
              onClick={() => {
                setCheckboxSelected(prev => !prev);
              }}
            />
          </div>
        </div>
        {fields.map(field => {
          const name = getDisplayName(field);
          return (
            <CheckboxField
              className="tg-no-form-group-margin"
              key={name}
              name={sanitizeFieldName(name)}
              label={name}
              enableReinitialize
              defaultValue={checkboxSelected}
            />
          );
        })}
        {error && <BlueprintError error={error} />}
      </div>
      <DialogFooter
        hideModal={hideModal}
        submitting={submitting}
        onClick={handleSubmit(onSubmit)}
      />
    </div>
  );
};

export const ExportTableAsCsvDialog = compose(
  reduxForm({
    form: "exportTableAsCsv"
  }),
  wrapDialog({ title: "Select Columns To Export" })
)(ExportAsCsvSelectColumns);

function ExportTableAsCsvButton(props) {
  return (
    <Button
      className="tg-export-data-table"
      minimal
      icon="export"
      text={props.text}
      onClick={() => {
        showDialog({
          ModalComponent: ExportTableAsCsvDialog,
          modalProps: props
        });
      }}
    />
  );
}

export default ExportTableAsCsvButton;
