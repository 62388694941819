/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useCallback, useMemo } from "react";
import { Button } from "@blueprintjs/core";
import AbstractRecord from "../../../../src-shared/AbstractRecord";
import PlateMapView from "../../../components/PlateMapView";
import RecordInfoDisplay from "../../../../src-shared/RecordInfoDisplay";
import plateMapGroupRecordFragment from "../../../graphql/fragments/plateMapGroupRecordFragment";
import { plateLayerFragment } from "../../../graphql/fragments/plateLayerFragment.gql";
import { getInitialPlateLayerValues } from "../../../utils/plateUtils";
import recordViewEnhancer from "../../../../src-shared/recordViewEnhancer";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import "./style.css";
import { groupBy, camelCase } from "lodash";
import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";
import { generateContainerArray } from "../../../../../tg-iso-lims/src/utils/plateUtils";
import UpdatePlateMapGroupDialog from "./UpdatePlateMapGroupDialog";
import useTgQuery from "../../../../src-shared/apolloUseTgQuery";

const PlateMapGroupRecordView = props => {
  const { history, plateMapGroup, readOnly } = props;

  const plateMapIds = useMemo(() => {
    if (!plateMapGroup) return [];
    return plateMapGroup.plateMaps.map(pm => pm.id);
  }, [plateMapGroup]);

  const { entities: plateLayers = [], refetch: refetchPlateLayers } =
    useTgQuery(plateLayerFragment, {
      skip: !plateMapIds.length,
      variables: {
        filter: {
          plateMapId: plateMapIds
        }
      }
    });

  const onRowDoubleClick = useCallback(
    record => {
      if (record.inventoryItem) {
        const inventoryRecord =
          record.inventoryItem[
            camelCase(record.inventoryItem.inventoryItemTypeCode)
          ];
        if (inventoryRecord) {
          history.push(modelNameToLink(inventoryRecord));
        }
      }
    },
    [history]
  );

  const wrapPlateMapPlate = useCallback(
    (plateMapPlate, { plateMap }) => {
      const { plateLayers = [] } = plateMap;
      let dialogProps;
      if (plateMapGroup.containerFormat.rowCount >= 16) {
        dialogProps = {
          style: {
            width: 1000
          }
        };
      }
      return (
        <div>
          {!readOnly && (
            <Button
              text="Update Plate Layers"
              intent="warning"
              style={{ marginBottom: 7 }}
              onClick={() => {
                showDialog({
                  modalType: "CREATE_PLATE_LAYER",
                  modalProps: {
                    containerFormat: plateMapGroup.containerFormat,
                    record: {
                      ...plateMap,
                      plateMapItems: generateContainerArray(
                        plateMap.plateMapItems,
                        plateMapGroup.containerFormat
                      )
                    },
                    refetch: refetchPlateLayers,
                    dialogProps,
                    initialValues: {
                      plateLayers: getInitialPlateLayerValues(
                        plateLayers,
                        plateMap.plateMapItems
                      )
                    }
                  }
                });
              }}
            />
          )}
          {plateMapPlate}
        </div>
      );
    },
    [plateMapGroup.containerFormat, readOnly, refetchPlateLayers]
  );

  const plateMapGroupToUse = useMemo(() => {
    if (plateLayers.length) {
      const layersById = groupBy(plateLayers, "plateMapId");

      return {
        ...plateMapGroup,
        plateMaps: plateMapGroup.plateMaps.map(pm => {
          if (layersById[pm.id]) {
            return {
              ...pm,
              plateLayers: layersById[pm.id]
            };
          } else return pm;
        })
      };
    }
    return plateMapGroup;
  }, [plateLayers, plateMapGroup]);

  const recordInfo = useMemo(
    () => [
      ["Type", plateMapGroup.plateMaps[0]?.type],
      ["Plate Format", plateMapGroup.containerFormat?.name]
    ],
    [plateMapGroup]
  );

  return (
    <AbstractRecord
      {...props}
      recordName="plateMapGroup"
      updateShowFunction={() => {
        showDialog({
          ModalComponent: UpdatePlateMapGroupDialog,
          modalProps: {
            initialValues: plateMapGroup
          }
        });
      }}
    >
      <div className="record-header-info-section">
        <RecordInfoDisplay
          recordInfo={recordInfo}
          readOnly={readOnly}
          record={plateMapGroup}
        />
      </div>
      <PlateMapView
        noPadding
        noTitle
        stacked={plateMapGroupToUse.containerFormat?.rowCount >= 16}
        plateMapGroup={plateMapGroupToUse}
        wrapPlateMapPlate={wrapPlateMapPlate}
        aliquotContainerDoubleClick={onRowDoubleClick}
        onTableRowDoubleClick={onRowDoubleClick}
      />
    </AbstractRecord>
  );
};

export default recordViewEnhancer(plateMapGroupRecordFragment)(
  PlateMapGroupRecordView
);
