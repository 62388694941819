/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose, branch } from "recompose";
import { useDispatch } from "react-redux";
import { change } from "redux-form";
import { InputField, DataTable } from "@teselagen/ui";
import HeaderWithHelper from "../../../src-shared/HeaderWithHelper";
import { volumeRender } from "../../../src-shared/utils/unitUtils";
import stepFormValues from "../../../src-shared/stepFormValues";
import { get } from "lodash";
import SortWorklistField from "./SortWorklistField";
import defaultValueConstants from "../../../../tg-iso-shared/src/defaultValueConstants";
import { getAliquotContainerLocation } from "../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { getToolOutputItems } from "../../utils";

export const sourcePositionColumn = {
  displayName: "Source Position",
  path: "sourceAliquotContainer",
  render: value => getAliquotContainerLocation(value),
  sortFn: [
    r => get(r, "sourceAliquotContainer.rowPosition"),
    r => get(r, "sourceAliquotContainer.columnPosition")
  ]
};

export const destinationPositionColumn = {
  displayName: "Destination Position",
  path: "destinationAliquotContainer",
  render: value => getAliquotContainerLocation(value),
  sortFn: [
    r => r.destinationAliquotContainer.rowPosition,
    r => r.destinationAliquotContainer.columnPosition
  ]
};

export const worklistTransferSchema = {
  model: "worklist",
  fields: [
    {
      displayName: "Source",
      path: "sourceAliquotContainer.containerArray.name",
      render: (v, r) => {
        if (v) return v;
        else return get(r, "sourceAliquotContainer.name");
      }
    },
    {
      displayName: "Source Plate Barcode",
      path: "sourceAliquotContainer.containerArray.barcode.barcodeString"
    },
    sourcePositionColumn,
    {
      displayName: "Destination Plate",
      path: "destinationPlateName",
      render: (v, r) => {
        if (v) return v;
        else
          return (
            get(r, "destinationAliquotContainer.containerArray.name") ||
            get(r, "destinationAliquotContainer.name")
          );
      }
    },
    {
      displayName: "Destination Plate Barcode",
      path: "destinationAliquotContainer.containerArray.barcode.barcodeString"
    },
    destinationPositionColumn,
    {
      displayName: "Transfer Volume",
      path: "volume",
      render: volumeRender
    }
  ]
};

export const worklistTubeTransferSchema = {
  model: "worklist",
  fields: [
    {
      displayName: "Source Rack",
      path: "sourceAliquotContainer.containerArray.name"
    },
    {
      displayName: "Source Rack Barcode",
      path: "sourceAliquotContainer.containerArray.barcode.barcodeString"
    },
    sourcePositionColumn,
    {
      displayName: "Destination Rack",
      path: "destinationRackName",
      render: (v, r) => {
        if (v) return v;
        else return get(r, "destinationContainerArray.name");
      }
    },
    {
      displayName: "Destination Rack Barcode",
      path: "destinationContainerArray.barcode.barcodeString"
    },
    {
      displayName: "Destination Position",
      path: "destinationAliquotContainer",
      render: (_, { destinationColumnPosition, destinationRowPosition }) =>
        getAliquotContainerLocation({
          rowPosition: destinationRowPosition,
          columnPosition: destinationColumnPosition
        }),
      sortFn: [r => r.destinationRowPosition, r => r.destinationColumnPosition]
    }
  ]
};

const ReviewWorklistSection = ({
  onSort,
  worklist = {},
  worklistTransfers,
  noHeader,
  noSection,
  fieldName = "worklistName",
  schema,
  noSort,
  header = "Review Worklist",
  helper = "Enter a worklist name and review the transfers below.",
  tableFormName = "reviewWorklistTable",
  sectionFooter,
  toolSchema
}) => {
  const dispatch = useDispatch();
  const onSortChange = (...args) => {
    if (onSort) {
      onSort(...args);
    } else {
      dispatch(change(toolSchema.code, ...args));
    }
  };

  const canSort =
    !noSort &&
    (!schema ||
      get(worklist, "worklistTransfers[0].destinationAliquotContainer"));

  let outputType;
  if (toolSchema) {
    const outputItems = getToolOutputItems(toolSchema);
    const worklistOutputItem = outputItems.find(
      item => item.name === "worklist" || item.name === "worklists"
    );
    if (worklistOutputItem) {
      outputType = worklistOutputItem.defaultValue;
    }
  }
  const isTubeTransfer = !!worklist.tubeTransfers?.length;
  let transfers = worklistTransfers;
  if (!transfers && isTubeTransfer) {
    transfers = worklist.tubeTransfers;
  } else if (!transfers) {
    transfers = worklist.worklistTransfers;
  }
  if (!transfers) {
    transfers = [];
  }
  return (
    <div className={noSection ? "" : "tg-step-form-section column"}>
      <div className="tg-flex justify-space-between">
        {!noHeader && <HeaderWithHelper header={header} helper={helper} />}
        <div style={{ width: "40%" }}>
          <InputField
            name={fieldName}
            label="Name"
            placeholder="Enter worklist name..."
            isRequired
            generateDefaultValue={{
              ...defaultValueConstants.WORKLIST_NAME,
              customParams: {
                outputType
              }
            }}
          />
        </div>
      </div>
      {canSort && (
        <SortWorklistField change={onSortChange} worklist={worklist} />
      )}
      <DataTable
        schema={
          schema ||
          (isTubeTransfer ? worklistTubeTransferSchema : worklistTransferSchema)
        }
        entities={transfers}
        formName={tableFormName}
        withDisplayOptions
        isSimple
        compact
        destroyOnUnmount={false}
        hideSelectedCount
        withPaging
        defaults={{
          pageSize: 100
        }}
      />
      {sectionFooter}
    </div>
  );
};

export default compose(
  branch(({ worklist }) => !worklist, stepFormValues("worklist"))
)(ReviewWorklistSection);
