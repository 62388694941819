/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import {
  generateEmptyWells,
  sortAliquotContainers
} from "../../../../../../../../tg-iso-lims/src/utils/plateUtils";
import { chunk, reverse, flatten } from "lodash";
import { Directions } from "../../../../../../utils/plateUtils/getWellPositionsGivenWellCountAndDirection";

export const getSortedWellsForPlate = (
  containerFormat: { columnCount: number; rowCount: number },
  fillDirection: Directions = "right"
): {
  columnPosition: number;
  rowPosition: number;
}[] => {
  const aliquotContainers = generateEmptyWells(containerFormat);
  switch (fillDirection) {
    case "right":
      return sortAliquotContainers(aliquotContainers, "rowFirst");
    case "left":
      const sortedWells = sortAliquotContainers(aliquotContainers, "rowFirst");
      return flatten(
        chunk(sortedWells, containerFormat.columnCount).map(reverse)
      );
    case "down":
      return sortAliquotContainers(aliquotContainers, "columnFirst");
    case "up":
      const sortedDownWells = sortAliquotContainers(
        aliquotContainers,
        "columnFirst"
      );
      return flatten(
        chunk(sortedDownWells, containerFormat.rowCount).map(reverse)
      );
    default:
      throw new Error(`Invalid fill direction: ${fillDirection}`);
  }
};
