/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useMemo } from "react";
import { Classes } from "@blueprintjs/core";
import { DataTable, DialogFooter, useTableEntities } from "@teselagen/ui";
import { safeUpsert } from "../../../apolloMethods";
import asPromiseDialog from "../../../utils/asPromiseDialog";
import { isEmpty } from "lodash";

const formName = "updateVendorOrderTable";

const UpdateVendorOrderDialog = ({
  resolve,
  sequenceIds,
  ordersToBeUpdated
}) => {
  const { selectedEntities } = useTableEntities(formName);

  const onCancel = () => {
    resolve();
  };

  const onConfirm = useCallback(async () => {
    try {
      if (!isEmpty(selectedEntities)) {
        await safeUpsert(
          "vendorOrder",
          Object.values(selectedEntities).map(({ entity }) => ({
            id: entity.id,
            orderStatusCode: "COMPLETE_STATUS"
          }))
        );
      }
    } catch (error) {
      window.toastr.error("Error updating orders. Please update manually.");
      console.error("error:", error);
    }
    resolve();
  }, [resolve, selectedEntities]);

  const schema = useMemo(
    () => ({
      model: "vendorOrder",
      fields: [
        "name",
        {
          displayName: "Number of Uploaded Sequences",
          filterDisabled: true,
          sortDisabled: true,
          render: (v, record) => {
            const matching = record.lineItems.filter(li =>
              sequenceIds.includes(li.sequenceId)
            );
            return `${matching.length} / ${record.lineItems.length}`;
          }
        }
      ]
    }),
    [sequenceIds]
  );

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <div style={{ marginBottom: 15 }}>
          Please select an vendor orders you would like to mark as complete.
        </div>
        <DataTable
          formName={formName}
          urlConnected={false}
          schema={schema}
          noPadding
          entities={ordersToBeUpdated}
          withCheckboxes
        />
      </div>
      <DialogFooter secondaryAction={onCancel} onClick={onConfirm} />
    </>
  );
};

export default asPromiseDialog(UpdateVendorOrderDialog);
