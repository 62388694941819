/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useCallback } from "react";
import { Menu } from "@blueprintjs/core";
import AbstractLibrary from "../../AbstractLibrary";
import { showDialog } from "../../GlobalDialog";
import {
  MultiLineSearchMenuItem,
  sequenceSearchMenu
} from "../../components/SequenceSearch";
import { disableOligosInSeqLibHelper } from "../../utils/disableOligosInSeqLibHelper";
import DNASequenceRecordView from "../../DNASequenceRecordView";
import UploadDNASequenceDialog from "../../UploadDNASequenceDialog";
import routeDoubleClick from "../../utils/routeDoubleClick";
import { contextMenu } from "../../TgSequenceEditor/ContextMenu";

const GenericSequenceLibrary = props => {
  const { isRNA, history, refetchSequences } = props;

  const showSequenceUpload = () => {
    showDialog({
      ModalComponent: UploadDNASequenceDialog,
      modalProps: {
        isRNA,
        refetch: refetchSequences
      }
    });
  };

  const additionalContextMenu = selectedRecords => {
    return contextMenu({
      selectedRecords,
      ...props
    });
  };

  const onNewItemClick = () => {
    history.push(isRNA ? "/rna-sequences/new" : "/dna-sequences/new");
  };

  const onDoubleClick = useCallback(
    (record, ...args) => {
      let routeOverride;
      if (isRNA) {
        if (record.rnaType?.name === "gRNA") {
          routeOverride = `/guide-rna-sequences/${record.id}`;
        } else {
          routeOverride = `/rna-sequences/${record.id}`;
        }
      }
      routeDoubleClick({ ...record, routeOverride }, ...args);
    },
    [isRNA]
  );

  return (
    <AbstractLibrary
      {...props}
      generateExtraContextMenuItems={additionalContextMenu}
      onDoubleClick={onDoubleClick}
      libraryTitle={isRNA ? "RNA Sequences" : "DNA Sequences"}
      onNewItemClick={onNewItemClick}
      model="sequence"
      sequenceType={isRNA ? "RNA" : undefined}
      libraryName={isRNA ? "rnaSequence" : "dnaSequence"}
      showUploadFunction={showSequenceUpload}
      isLibraryTable
      RecordViewInspector={DNASequenceRecordView}
      withExport
      {...disableOligosInSeqLibHelper}
      searchMenu={
        <Menu>
          {sequenceSearchMenu({
            setSearchTerm: props.tableParams.setSearchTerm
          })}
          <MultiLineSearchMenuItem {...props}></MultiLineSearchMenuItem>
        </Menu>
      }
    />
  );
};

export default GenericSequenceLibrary;
