/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import {
  FormGroup,
  Intent,
  Switch,
  Position,
  Tooltip,
  Icon
} from "@blueprintjs/core";
// Apparently Tooltip is going to be deprecated in blueprintjs v4. More info at: https://blueprintjs.com/docs/#popover2-package/tooltip2
// import { ToolTip2 as Tooltip } from "@blueprintjs/popover";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { size } from "lodash";
import { EvolveConfig } from "../../src-evolve/configs/config";

type AppModes = {
  [key: string]: {
    label: string;
    default: boolean;
    hide: boolean;
    checked?: boolean;
    tooltip?: string;
  };
};

const enableAppMode = (mode: string) => {
  localStorage.setItem(mode, "true");
};

const disableAppMode = (mode: string) => {
  localStorage.setItem(mode, "false");
};

const DEFAULT_APP_MODES: AppModes = {
  advancedMode: {
    label: "Enable advanced features",
    default: false,
    hide: true
  },
  experimentalMode: {
    label: "Enable experimental features",
    default: false,
    hide: true
  }
};

/* Taken and refactored from TEST advanced features settings. It currently stores switch info in locaStorage but it could do so using react states.
   It receives as prop an object with different "modes" or experimental features which are totally up to the developer.
   Then throughout the app, this can be retrieved from localstorage and turn on/off certain views accordingly.

   Each mode in the appModes prop object should come with the following properties:
   - "label": This is the text that will appear as the label next to switch.
   - "tooltip": This is an extra text shown as toolip in the label.
   - "default": This is the initial value for the switch value (true/false or ON/OFF).
   - "hide": This is used if the developer wants to hide the switch.
*/
const AdvancedFeatures = () => {
  const [appModes, setAppModes] = useState<AppModes>({});

  useEffect(() => {
    const _appModes: AppModes = EvolveConfig.appModes || DEFAULT_APP_MODES;
    const _shownAppModes: AppModes = {};
    Object.keys(_appModes).forEach(mode => {
      if (!_appModes[mode].hide) {
        const checked = localStorage.getItem(mode);
        _shownAppModes[mode] = {
          ..._appModes[mode],
          checked: checked ? JSON.parse(checked) : _appModes[mode].default
        };
      }
    });
    setAppModes(_shownAppModes);
  }, []); // eslint-disable-line

  useEffect(() => {
    Object.keys(appModes).forEach(mode => {
      if (appModes[mode].checked) enableAppMode(mode);
      else disableAppMode(mode);
    });
  }, [appModes]);

  const onToggleAppMode = (mode: string) => {
    setAppModes(_appModes => ({
      ..._appModes,
      [mode]: { ..._appModes[mode], checked: !_appModes[mode].checked }
    }));
  };

  return (
    <div className="tg-card">
      <Helmet title="App Settings" />
      <h3 className="settings-form-header">Predictive Model Settings</h3>
      <div className="settings-body">
        {/* @ts-ignore */}
        <FormGroup
          helperText="enable/disable extra app features"
          intent={Intent.PRIMARY}
          label="App Modes"
          labelFor="text-input"
          labelInfo="(optional)"
        >
          {size(Object.keys(appModes)) ? (
            Object.keys(appModes).map(mode => (
              <Switch
                id={mode}
                key={mode}
                // @ts-ignore
                label={
                  <React.Fragment>
                    {appModes[mode].label}
                    {/* @ts-ignore */}
                    <Tooltip
                      className="pt-icon"
                      content={<span>{appModes[mode].tooltip}</span>}
                      position={Position.RIGHT}
                      usePortal={true}
                    >
                      <Icon
                        icon="info-sign"
                        iconSize={12}
                        style={{ marginLeft: 5, paddingBottom: 1 }}
                        intent={Intent.PRIMARY}
                      />
                    </Tooltip>
                  </React.Fragment>
                }
                onChange={() => onToggleAppMode(mode)}
                checked={appModes[mode].checked}
              />
            ))
          ) : (
            <div style={{ marginTop: 10 }}>
              {" "}
              <span>No advanced features available.</span>
            </div>
          )}
        </FormGroup>
      </div>
    </div>
  );
};

export default AdvancedFeatures;
