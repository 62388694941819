/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Loading } from "@teselagen/ui";
import withQuery from "../withQuery";
import { Tooltip, Button } from "@blueprintjs/core";
import { showDialog } from "../GlobalDialog";
import { projectItemFragment } from "../fragments/projectItemFragment.gql";
import { labModels } from "../../../tg-iso-shared/constants";
import { RenderProjectItems } from "../utils/libraryColumns";
import ChangeProjectDialog from "../ChangeProjectDialog";

class RecordTags extends React.Component {
  getRecordWithItems = () => {
    const { model, projectItems, recordId } = this.props;
    const fakeRecord = {
      id: recordId,
      projectItems,
      __typename: model
    };
    return fakeRecord;
  };
  showUpdate = () => {
    const { refetchProjectItems } = this.props;
    showDialog({
      ModalComponent: ChangeProjectDialog,
      modalProps: {
        records: [this.getRecordWithItems()],
        refetch: refetchProjectItems
      }
    });
  };

  render() {
    const { model, projectItemsLoading, readOnly } = this.props;

    if (!labModels.includes(model)) {
      // doesn't have projects
      return null;
    }

    if (projectItemsLoading) return "...";

    return (
      <Loading loading={projectItemsLoading} bounce>
        <div className="tg-flex align-center">
          {!readOnly && (
            <Tooltip content="Update Projects" disabled={window.Cypress}>
              <Button
                icon="edit"
                className="tg-project-record-button"
                minimal
                intent="warning"
                onClick={this.showUpdate}
              />
            </Tooltip>
          )}
          <div style={{ marginTop: 8, marginBottom: 10 }}>
            {RenderProjectItems(this.getRecordWithItems())}
          </div>
        </div>
      </Loading>
    );
  }
}

const queryOptions = ({ recordId, model }) => {
  return {
    variables: {
      pageSize: 400,
      filter: {
        [model + "Id"]: recordId
      }
    }
  };
};

export default withQuery(projectItemFragment, {
  isPlural: true,
  options: queryOptions,
  skip: props => !labModels.includes(props.model)
})(RecordTags);
