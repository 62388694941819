/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import { useTgQuery } from "../apolloMethods";

const useIntegrationFragment = gql`
  fragment useIntegrationFragment on integration {
    id
    integrationTypeCode
    name
    subtype
    integrationHeaders {
      id
      value
      name
    }
    integrationEndpoints {
      id
      externalRecordType {
        id
        urlTemplate
        identifierTypeCode
        externalSourceSystem {
          id
          url
        }
      }
      endpointTypeCode
      url
      integrationEndpointHeaders {
        id
        value
        name
      }
    }
  }
`;

export function useIntegrationQuery({ integrationId }) {
  const {
    data: { integration },
    ...rest
  } = useTgQuery(useIntegrationFragment, {
    variables: {
      id: integrationId
    }
  });

  return { integration, ...rest };
}
