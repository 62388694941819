/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { PlateToCreateType } from "../../../utils/generatePlateMapGroup";

export const isPlateObjectFull = (
  plate: PlateToCreateType,
  containerFormat: { rowCount: number; columnCount: number },
  quadrantPositionMap?: { [position: string]: boolean }
) => {
  if (quadrantPositionMap) {
    const quadrantPositions = Object.keys(quadrantPositionMap);
    return quadrantPositions.every(pos => plate[pos]);
  } else {
    return (
      Object.keys(plate).length >=
      containerFormat.rowCount * containerFormat.columnCount
    );
  }
};
