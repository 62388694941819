/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { map } from "lodash";
import { getAliquotContainerLocation } from "../../../tg-iso-lims/src/utils/getAliquotContainerLocation";

export type Position = {
  rowPosition: number;
  columnPosition: number;
  containerArray?: object;
  containerArrayType?: object;
};

export function getAlphnumericWellLocation(
  positionArray: (Position | null)[] = []
) {
  return map(positionArray, (position = null) =>
    getAliquotContainerLocation(position, {
      force2D: true
    })
  );
}
