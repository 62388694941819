/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { compose, withProps } from "recompose";
import { Button, Intent } from "@blueprintjs/core";
import { SubmissionError } from "redux-form";
import StepForm from "../../../../src-shared/StepForm/index";
import SelectMaterials, {
  modelNameToAdditionalFragment,
  modelNameToFragment
} from "./Steps/SelectMaterials";
import SetParameters from "./Steps/SetParameters";
import { safeUpsert, safeQuery } from "../../../../src-shared/apolloMethods";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import pluralize from "pluralize";
import { getRequestHeaderKeys } from "@teselagen/auth-utils";
import { createNotification } from "../../../../src-shared/utils/createNotification";
import { itemTypes } from "./utils";
import fieldConstants from "./fieldConstants";

class GenerateOligosForSynthonsTool extends Component {
  onSubmit = async values => {
    const {
      materials = [],
      designType,
      designName,
      estimatedIdentity = 0.95,
      minZScoreCutoff = -4.0,
      tempForDeltaGCalc = 60.0,
      optimizeOverlapTms,
      minSize = 30,
      targetSize = 40,
      maxSize = 50,
      minOverlap = 15,
      maxOverlap = 25,
      targetPrimerSize = 20
    } = values;
    const { currentUser } = this.props;

    const designTypeToInputFormatMap = {
      maximizeRecycling: "maximize_recycling",
      minimizeComplexity: "minimize_complexity",
      gappedDesign: "gapped_design"
    };

    try {
      const sequenceIds = materials.map(
        material => material?.polynucleotideMaterialSequence?.id
      );
      const sequenceResults = await safeQuery(
        [
          "sequence",
          `
          id
          name
          sequenceFragments {
            id
            fragment
          }
          `
        ],
        {
          variables: {
            filter: {
              id: sequenceIds
            }
          }
        }
      );
      const sequences = sequenceResults.map(seq => {
        const { name, sequenceFragments } = seq;
        const sequenceString = sequenceFragments.map(f => f.fragment).join("");
        return {
          sequence_name: name,
          sequence: sequenceString,
          primer_length: targetPrimerSize
        };
      });

      const oligoDesignParameters = {
        design_type: designTypeToInputFormatMap[designType],
        minimum_length: minSize,
        target_length: targetSize,
        maximum_length: maxSize,
        minimum_overlap: minOverlap,
        maximum_overlap: maxOverlap
      };
      const primerDesignParameters = {
        target_primer_length: targetPrimerSize
      };
      const optionalParameters = {
        design_name: designName
      };
      if (designType === "maximizeRecycling") {
        optionalParameters["partition_identity"] = estimatedIdentity;
      } else if (designType === "minimizeComplexity") {
        optionalParameters["min_zscore_cutoff"] = minZScoreCutoff;
        optionalParameters["temp"] = tempForDeltaGCalc;
        optionalParameters["optimize_overlap_tm"] = optimizeOverlapTms;
      } else if (designType === "gappedDesign") {
        optionalParameters["optimize_overlap_tm"] = optimizeOverlapTms;
      }

      const homologyPathInput = {
        api: "oligo_design",
        sequences,
        required_parameters: {
          sequences,
          oligo_design_parameters: oligoDesignParameters,
          primer_design_parameters: primerDesignParameters
        },
        optional_parameters: optionalParameters
      };
      const [reactionDesign] = await safeUpsert("reactionDesign", {
        name: designName
      });
      const reactionMapsToUpsert = [
        {
          name: `${designName} - Synthon Assembly by Oligos`,
          reactionTypeCode: "ANNEALED_OLIGOS"
        },
        {
          name: `${designName} - Synthon Amplification`,
          reactionTypeCode: "PCR_REACTION"
        }
      ];
      const reactionMaps = await safeUpsert(
        ["reactionMap", "id name reactionTypeCode"],
        reactionMapsToUpsert
      );
      const reactionDesignId = reactionDesign.id;
      let pcaReactionMap;
      let pcrReactionMap;
      reactionMaps.forEach(rm => {
        if (rm.reactionTypeCode === "ANNEALED_OLIGOS") {
          pcaReactionMap = rm;
        } else if (rm.reactionTypeCode === "PCR_REACTION") {
          pcrReactionMap = rm;
        }
      });
      const pcaReactionMapId = pcaReactionMap.id;
      const pcrReactionMapId = pcrReactionMap.id;
      const options = {
        reactionDesignId,
        pcaReactionMapId,
        pcrReactionMapId
      };
      const microserviceInput = { homologyPathInput, options };

      return await window.serverApi
        .request({
          method: "POST",
          url: "/createTaskToGenerateOligosForSynthons",
          data: { microserviceInput },
          headers: getRequestHeaderKeys()
        })
        .then(async output => {
          const { taskId } = output.data;
          const microserviceQueueId = taskId;
          window.serverApi.request({
            method: "POST",
            url: "/generateOligosForSynthons",
            data: {
              taskId,
              reactionDesignId,
              pcaReactionMapId,
              pcrReactionMapId,
              designName
            },
            headers: getRequestHeaderKeys()
          });

          await createNotification({
            message: `Task to generate oligos for synthons submitted - ${designName}`,
            userId: currentUser.id,
            notificationIntent: "primary",
            link: `/microservice-tasks`,
            notificationTypeCode: "GENERATE_OLIGOS_FOR_SYNTHONS_STARTED"
          });

          return {
            reactionDesign: {
              id: reactionDesignId,
              microserviceQueueId,
              __typename: "reactionDesign"
            },
            pcaReactionMap: {
              id: pcaReactionMapId,
              microserviceQueueId,
              __typename: "reactionMap"
            },
            pcrReactionMap: {
              id: pcrReactionMapId,
              microserviceQueueId,
              __typename: "reactionMap"
            }
          };
        })
        .catch(error => {
          console.error("error", error);
          return {};
        });
    } catch (error) {
      console.error("error:", error);
      throw new SubmissionError({
        _error:
          "Error submitting materials as synthons for which to generate oligos"
      });
    }
  };

  render() {
    const {
      toolSchema,
      toolIntegrationProps,
      isToolIntegrated,
      initialValues
    } = this.props;
    const steps = [
      {
        Component: SelectMaterials,
        title: "Select Materials",
        withCustomFooter: true
      },
      {
        Component: SetParameters,
        title: "Set Parameters",
        nextButton: (
          <Button
            type="submit"
            text="Create Reaction Maps"
            intent={Intent.SUCCESS}
          />
        )
      }
    ];

    return (
      <StepForm
        toolSchema={toolSchema}
        initialValues={initialValues}
        enableReinitialize={isToolIntegrated}
        toolIntegrationProps={toolIntegrationProps}
        onSubmit={this.onSubmit}
        validate={validate}
        steps={steps}
      />
    );
  }
}

const validate = values => {
  const {
    itemType,
    materials = [],
    [fieldConstants.selectAllReactionEntities]: selectAllReactionEntities
  } = values;
  const errors = {};
  if (!itemType) errors.itemType = "Please select an item type.";
  const selectingAll = itemType === "reactionMap" && selectAllReactionEntities;
  if (itemType && (!materials || !materials.length) && !selectingAll) {
    errors._error = `Please select at least one material.`;
  }
  return errors;
};

export default compose(
  ...itemTypes.map(type => {
    const fragment =
      modelNameToAdditionalFragment[type] || modelNameToFragment[type];
    return withWorkflowInputs(fragment);
  }),
  withProps(props => {
    if (props.initialValues) {
      const typeToInitializeTo = itemTypes.find(
        type => props.initialValues[pluralize(type)]?.length
      );
      if (typeToInitializeTo) {
        return {
          initialValues: {
            ...props.initialValues,
            [fieldConstants.itemType]: typeToInitializeTo
          }
        };
      }
    }
  })
)(GenerateOligosForSynthonsTool);
