/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback } from "react";
import { compose } from "recompose";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import {
  CheckboxField,
  DialogFooter,
  BlueprintError,
  wrapDialog
} from "@teselagen/ui";
import classNames from "classnames";
import TagField from "../../../../src-shared/TagField";

import PlateUploadFields from "../../PlateUploadFields";
import handleReagentPlateImport from "../../../../../tg-iso-lims/src/plateImports/handleReagentPlateImport";
import { throwFormError } from "../../../../src-shared/utils/formUtils";
import { wellLocationField } from "../../../../../tg-iso-shared/src/utils/unitCodeFields";
import { defaultReagentFields } from "../../../../../tg-iso-lims/src/utils/getReagentsFromCsv";
import { reagentHandlingLogic } from "../../../utils/reagentHandlingLogic";
import { useFormValue } from "../../../../src-shared/hooks/useFormValue";
import { get } from "lodash";

export async function submitReagentPlate(values, options) {
  const { makeError } = options;
  try {
    return await handleReagentPlateImport({ ...values, ...options });
  } catch (error) {
    console.error("error:", error);
    makeError(error.errors || error.message || "Error uploading plate.");
  }
}

const templateFileOptions = {
  fileName: "Reagent Plate",
  validateAgainstSchema: {
    ...reagentHandlingLogic,
    fields: [
      {
        path: "Plate Name",
        isRequired: true,
        description: "The name of the plate.",
        example: "Plate123"
      },
      {
        path: "Plate Barcode",
        description: "The barcode of the plate.",
        example: "Barcode456"
      },
      wellLocationField,
      {
        path: "Tube Barcode",
        description: "The barcode of the tube.",
        example: "Tube123"
      },
      ...defaultReagentFields
    ]
  },
  extendedPropTypes: ["plate", "tube"]
};

const form = "uploadReagentPlateForm";

const UploadReagentPlateUnwrapped = ({
  noDialog,
  submitting,
  handleSubmit,
  hideModal,
  fieldPrefix = "",
  error,
  refetch
}) => {
  const containerArrayType = useFormValue(form, "containerArrayType");

  const onSubmit = useCallback(
    async values => {
      await submitReagentPlate(values, {
        makeError: throwFormError,
        refetch
      });
    },
    [refetch]
  );

  const isRack = get(containerArrayType, "isPlate") === false;

  return (
    <>
      <div className={classNames({ [Classes.DIALOG_BODY]: !noDialog })}>
        <PlateUploadFields
          fieldPrefix={fieldPrefix}
          inTool={noDialog}
          templateFileOptions={templateFileOptions}
        />

        <CheckboxField
          name={fieldPrefix + "generateBarcode"}
          label={`Generate ${isRack ? "Rack" : "Plate"} Barcode`}
        />

        <TagField name={fieldPrefix + "tags"} />
        <BlueprintError error={error} />
      </div>
      {!noDialog && (
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          onClick={handleSubmit(onSubmit)}
        />
      )}
    </>
  );
};

export const UploadReagentPlateDialog = UploadReagentPlateUnwrapped;

export default compose(
  wrapDialog({
    title: "Upload Reagent Plate"
  }),
  reduxForm({ form })
)(UploadReagentPlateDialog);
