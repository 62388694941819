/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useContext } from "react";
import AvatarIcon from "../AvatarIcon";
import { Button, Icon, Menu } from "@blueprintjs/core";
import { createMenu } from "@teselagen/ui";
import { isAdmin, openInNewTab } from "../utils/generalUtils";
import { Link } from "react-router-dom";
import { AssignDefaultsModeContext } from "@teselagen/ui";
import { handleIntegrationClick } from "./handleIntegrationClick";
import "./style.css";
import CurrentUserContext from "../context/CurrentUserContext";
import { isTeselagenUser } from "../utils/generalUtils";

const UserAccountButton = ({ logout, extraItems }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { setAssignDefaultsMode } = useContext(AssignDefaultsModeContext);
  let conditionalItems = [];
  if (
    !window.frontEndConfig.disabledToolkits.integrations &&
    window.frontEndConfig.nodeRedEditorUrl &&
    isAdmin()
  ) {
    conditionalItems = [
      { divider: "" },
      {
        text: "Integration Server",
        icon: "send-to-graph",
        onClick: handleIntegrationClick
      }
    ];
  }
  if (
    process.env.NODE_ENV !== "production" ||
    (isAdmin() && isTeselagenUser())
  ) {
    conditionalItems.push({
      text: "GraphiQL",
      icon: "locate",
      onClick: () => {
        window.open(`/client/graphiql`, "_blank");
      }
    });
    conditionalItems.push({
      divider: ""
    });
    conditionalItems.push({
      text: "(Dev-only) Server Logs",
      onClick: () => {
        window.open(
          `https://console.cloud.google.com/kubernetes/deployment/us-central1-a/dev-cluster/${
            window.location.hostname?.split(".")?.[0]
          }/tg-app/logs?project=tg-cluster-dev`,
          "_blank"
        );
      }
    });
    conditionalItems.push({
      text: `(Dev-Only) Override isAdmin to ${isAdmin() ? "false" : "true"}`,
      onClick: () => {
        if (isAdmin()) {
          window.localStorage.setItem("__dev_only_tgIsAdmin", "false");
        } else {
          window.localStorage.setItem("__dev_only_tgIsAdmin", "true");
        }
        window.location.reload();
      }
    });
    if (
      process.env.NODE_ENV !== "production" &&
      window.localStorage.getItem("__dev_only_tgIsAdmin") !== null
    ) {
      conditionalItems.push({
        text: "(Dev-Only) Remove isAdmin Override",
        onClick: () => {
          window.localStorage.removeItem("__dev_only_tgIsAdmin");
          window.location.reload();
        }
      });
    }
    conditionalItems.push({
      divider: ""
    });
  }
  conditionalItems.push({
    text: "API Docs",
    icon: "layout-auto",
    onClick: () => {
      openInNewTab("/api-docs/");
    }
  });

  const menu = [
    {
      divider: (
        <div
          style={{
            marginLeft: 10
          }}
        >
          <span>
            {currentUser.firstName + " " + currentUser.lastName}
            {isAdmin() && (
              <span style={{ color: "#ffd567", fontSize: 12 }}>
                - Admin{" "}
                <Icon
                  style={{ position: "relative", top: -2 }}
                  icon="star"
                  size={11}
                  color="#ffd567"
                ></Icon>
              </span>
            )}
          </span>
          {/* say if they're an admin */}

          <div style={{ color: "grey", fontSize: 12 }}>{currentUser.email}</div>
        </div>
      )
      // tooltip: currentUser.email
    },
    { divider: "" },
    ...(extraItems || []),
    ...(isAdmin()
      ? [
          {
            text: <DefaultMode />,
            icon: "code",
            onClick: e => {
              const element = document.querySelector(
                ".tg-jump-to-settings-btn"
              );
              if (e.target !== element && !element.contains(e.target)) {
                setAssignDefaultsMode(prev => !prev);
              }
            }
          }
        ]
      : []),
    ...conditionalItems,
    { divider: "" },
    { text: "Sign out", icon: "log-out", onClick: logout }
  ];

  return (
    <div className="user-account-button">
      <AvatarIcon
        size={29}
        style={{ border: "2px solid white" }}
        user={currentUser}
        withAdminStar={isAdmin()}
        menu={<Menu>{createMenu(menu)}</Menu>}
      />
    </div>
  );
};

export default UserAccountButton;

const DefaultMode = () => {
  const { inAssignDefaultsMode } = useContext(AssignDefaultsModeContext);
  return (
    <>
      {inAssignDefaultsMode ? "Leave" : ""} Assign Default Mode{" "}
      <Link to="/settings/default-values">
        <Button
          className="tg-jump-to-settings-btn"
          // onClick={() => {
          //   history.push("/settings/default-values");
          //   // e.stopPropagation();
          // }}
          small
          minimal
          icon="settings"
        />
      </Link>
    </>
  );
};
