/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
import { sequencePartialFragment } from "../../../../tg-iso-shared/src/fragments/sequencePartialFragment.gql";

export default gql`
  fragment oligoFragment on j5Oligo {
    id
    name
    sequence {
      ...sequencePartialFragment
    }
  }
  ${sequencePartialFragment}
`;
