/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { isInteger } from "lodash";
import isValidPositiveNumber from "./isValidPositiveNumber";

/**
 * Check if a number is a positive integer
 * @param {number | string} num - The number to check
 * @returns {boolean} - True if the number is a positive integer, false otherwise
 * @example
 * isValidPositiveInteger(1); // true
 * isValidPositiveInteger(1.1); // false
 * isValidPositiveInteger(-1); // false
 * isValidPositiveInteger(0); // false
 **/
export default function isValidPositiveInteger(num = "") {
  return isValidPositiveNumber(num) && isInteger(num);
}
