/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import GenericSelect from "../../../src-shared/GenericSelect";
import containerArrayTypeFragment from "../../../../tg-iso-shared/src/fragments/containerArrayTypeFragment";

const schema = ["name"];
const fragment = ["containerArrayTypeWithQuadrantSizeView", "id name"];
const queryOptions = {
  variables: {
    sort: ["-isPlate", "quadrantSize", "name", "id"]
  }
};

function ContainerArrayTypeSelect({
  additionalDataFragment = containerArrayTypeFragment,
  ...rest
}) {
  return (
    <GenericSelect
      asReactSelect
      isRequired
      name="containerArrayType"
      label="Plate Type"
      placeholder="Select plate type..."
      schema={schema}
      fragment={fragment}
      additionalDataFragment={additionalDataFragment}
      queryOptions={queryOptions}
      {...rest}
    />
  );
}

export default ContainerArrayTypeSelect;
