/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import {
  dateModifiedColumn,
  j5ReportAssemblyHierarchicalColumns
} from "../../../../../src-shared/utils/libraryColumns";

type J5ReportType = {
  id: string;
  name: string;
};

export const modelNameToSchema = {
  material: [
    { path: "name" },
    { path: "materialType.name", displayName: "Type" },
    dateModifiedColumn
  ],
  j5Material: [
    { path: "name" },
    { path: "materialType.name", displayName: "Type" },
    dateModifiedColumn
  ],
  additiveMaterial: [
    { path: "name" },
    { path: "additiveType.name", displayName: "Type", type: "string" }
  ],
  lot: [
    { path: "name" },
    { path: "additiveMaterial.name", displayName: "Reagent", type: "string" }
  ],
  sample: [
    { path: "name" },
    { path: "sampleType.name", displayName: "Type" },
    dateModifiedColumn
  ],
  j5Report: [
    { path: "name" },
    ...j5ReportAssemblyHierarchicalColumns,
    dateModifiedColumn
  ],
  plateMap: [
    { path: "name" },
    { path: "plateMapGroup.containerFormat.name", displayName: "Format" },
    dateModifiedColumn
  ],
  j5PcrReaction: [
    { path: "name" },
    { path: "j5Report.name", displayName: "Assembly Report" },
    { path: "primaryTemplate.name", displayName: "Primary Template" },
    { path: "forwardPrimer.name", displayName: "Forward Primer" },
    { path: "reversePrimer.name", displayName: "Reverse Primer" },
    { path: "pcrProductSequence.name", displayName: "Product Sequence" }
  ],
  primaryTemplate: [
    { path: "name" },
    {
      displayName: "Assembly Report",
      render: (
        v: any,
        r: { j5PcrReactionsPrimaryTemplates: { j5Report: J5ReportType }[] }
      ) => {
        const j5Reports: J5ReportType[] = [];
        const j5ReportIds: string[] = [];
        r.j5PcrReactionsPrimaryTemplates.forEach(primaryTemplate => {
          if (!j5ReportIds.includes(primaryTemplate.j5Report.id)) {
            j5ReportIds.push(primaryTemplate.j5Report.id);
            j5Reports.push(primaryTemplate.j5Report);
          }
        });
        return j5Reports.map(j5Report => j5Report.name).join(", ");
      }
    },
    dateModifiedColumn
  ],
  pcrProductSequence: [
    { path: "name" },
    {
      displayName: "Assembly Report",
      render: (
        v: any,
        r: {
          j5PcrReactionsPCRProductSequences: { j5Report: { name: string } }[];
        }
      ) => {
        return (
          r.j5PcrReactionsPCRProductSequences.length > 0 &&
          r.j5PcrReactionsPCRProductSequences
            .map(productSequence => productSequence?.j5Report?.name)
            .join(", ")
        );
      }
    },
    dateModifiedColumn
  ],
  primer: [
    { path: "name" },
    {
      displayName: "Assembly Report",
      render: (
        v: any,
        r: {
          j5PcrReactionsForwardPrimers: { j5Report: J5ReportType }[];
          j5PcrReactionsReversePrimers: { j5Report: J5ReportType }[];
        }
      ) => {
        const j5Reports: string[] = [];
        r.j5PcrReactionsForwardPrimers.forEach(forwardPrimer =>
          j5Reports.push(forwardPrimer.j5Report.name)
        );
        r.j5PcrReactionsReversePrimers.forEach(reversePrimer =>
          j5Reports.push(reversePrimer.j5Report.name)
        );
        return j5Reports.join(", ");
      }
    },
    dateModifiedColumn
  ],
  j5AssemblyPiece: [
    { path: "name" },
    { displayName: "Assembly Report", path: "j5Report.name" },
    dateModifiedColumn
  ],
  j5InputSequence: [
    { displayName: "Name", path: "sequence.name" },
    { displayName: "Assembly Report", path: "j5Report.name" },
    dateModifiedColumn
  ],
  j5RunConstruct: [
    { path: "name" },
    { displayName: "Assembly Report", path: "j5Report.name" },
    dateModifiedColumn
  ],
  reactionMap: [
    "name",
    { displayName: "Reaction Type", path: "reactionType.name" },
    dateModifiedColumn
  ]
};
