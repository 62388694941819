/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Button, Classes, Menu, MenuItem, Popover } from "@blueprintjs/core";
import { TgSelect } from "@teselagen/ui";
import React from "react";
import tgUseLocalStorageState from "tg-use-local-storage-state";
import appGlobals from "../appGlobals";
import isMobile from "is-mobile";
import { openInNewTab } from "../utils/generalUtils";

export function useNavJumpExtraSearch() {
  const [includeTools, setIncludeTools] = tgUseLocalStorageState(
    "tgNavJumpIncludeTools",
    {
      defaultValue: false
    }
  );
  const [includeSettings, setIncludeSettings] = tgUseLocalStorageState(
    "tgNavJumpIncludeSettings",
    {
      defaultValue: false
    }
  );
  return {
    includeTools,
    setIncludeTools,
    includeSettings,
    setIncludeSettings
  };
}

function NavJump({ navSuggestItems, onSelect }) {
  const { includeTools, setIncludeTools, includeSettings, setIncludeSettings } =
    useNavJumpExtraSearch();

  return (
    <TgSelect
      autoFocus={!isMobile()}
      popoverProps={{
        usePortal: false
      }}
      noToggle
      additionalRightEl={
        <Popover
          minimal
          position="bottom-left"
          content={
            <Menu>
              <MenuItem
                icon={includeTools ? "tick" : "blank"}
                text="Include Tools in Search"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIncludeTools(!includeTools);
                }}
              />
              <MenuItem
                icon={includeSettings ? "tick" : "blank"}
                text="Include Settings in Search"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIncludeSettings(!includeSettings);
                }}
              />
            </Menu>
          }
        >
          <Button minimal icon="settings" />
        </Popover>
      }
      mustHaveQueryToOpen
      placeholder="Jump to..."
      className="tg-nav-jump-search"
      onChange={(value, e) => {
        if (value) {
          if (e?.metaKey) {
            openInNewTab(value.value);
            return;
          }
          appGlobals.history.push(value.value);
          onSelect(value);
        }
      }}
      options={navSuggestItems.map(item => {
        return {
          label: (
            <div>
              {item.label}
              <div style={{ fontSize: 9 }} className={Classes.TEXT_MUTED}>
                {item.path}
              </div>
            </div>
          ),
          value: item.navTo
        };
      })}
    />
  );
}

export default NavJump;
