/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import addSequenceTypeOrDefault from "../addSequenceTypeOrDefault";
import { aaSequenceJSONtoGraphQLInput } from "./aaSequenceJSONtoGraphQLInput";
import { sequenceJSONtoGraphQLInput } from "./sequenceJSONtoGraphQLInput";
import { tidyUpSequenceData } from "@teselagen/sequence-utils";
import { anyToJson } from "@teselagen/bio-parsers";

/**
 * parses sequence text into graphql input. From a single string input it can return multiple sequences (when passing fasta or gembank)
 * @param {object | string} sequenceText - The text of the sequence (or many sequences in case of genbank or fasta)
 * @param {string=} sequenceName - acts as an override for all sequence names when passed
 * @param {boolean=} options.isProtein - whether it is an amino acid
 * @param {boolean=} options.defaultToCircular
 * @param {string=} options.sequenceTypeCode
 */
export const parseSequenceText = async (
  sequenceText,
  sequenceName = undefined,
  options = {}
) => {
  const { isProtein, defaultToCircular, sequenceTypeCode } = options;
  const toReturn = { sequences: [], messages: [] };
  const isOligo = sequenceTypeCode === "OLIGO";
  const isRNA = sequenceTypeCode === "RNA";
  const jsonToGraphql = isProtein
    ? aaSequenceJSONtoGraphQLInput
    : sequenceJSONtoGraphQLInput;

  // aaSequenceJSONtoGraphQLInput() tnw - here for ease of finding
  // sequenceJSONtoGraphQLInput() tnw - here for ease of finding
  const addResult = result => {
    // loop through each parsing result
    let sequenceResult = result.parsedSequence;

    // If a sequenceName is provided, it will override any existing previous sequence names
    if (sequenceName) {
      sequenceResult = { ...sequenceResult, name: sequenceName };
    }
    if (isOligo && sequenceResult.circular) {
      // eslint-disable-next-line no-throw-literal
      throw {
        mismatchError: `Sequence ${result.parsedSequence.name} cannot be an oligo because the pasted sequence file is circular.`
      };
    }
    if (isRNA && sequenceResult.circular) {
      // eslint-disable-next-line no-throw-literal
      throw {
        mismatchError: `Sequence ${result.parsedSequence.name} cannot be a RNA because the pasted sequence file is circular.`
      };
    }
    if (!isProtein) {
      addSequenceTypeOrDefault(sequenceResult, sequenceTypeCode);
    }
    const sequenceInput = jsonToGraphql(sequenceResult);
    if (result.messages) toReturn.messages.push(...result.messages);
    toReturn.sequences.push(sequenceInput);
  };

  // sequenceText can either be a string (genbank, fasta, single sequence string) or a tesleagen json like object
  try {
    const results =
      sequenceText && sequenceText.sequence //just return the teselagen json
        ? [{ parsedSequence: tidyUpSequenceData(sequenceText) }]
        : await anyToJson(sequenceText, {
            parseFastaAsCircular: defaultToCircular,
            acceptParts: true,
            isProtein: isProtein,
            isOligo,
            isRNA,
            primersAsFeatures: true
          });

    results.forEach(addResult);
  } catch (error) {
    console.error("err:", error);
    throw new Error(error.mismatchError || error || "Error parsing sequence");
  }
  return toReturn;
};
