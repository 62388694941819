/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useEffect, useState } from "react";
import {
  getDataGrid,
  parseDataForDataGridTable
} from "../../../utils/experimentData/dataGridUtils";
import withQuery from "../../../withQuery";
import { compose } from "recompose";
import HeaderWithHelper from "../../../HeaderWithHelper";
import {
  DataTable,
  ReactSelectField,
  RadioGroupField,
  SelectField,
  NumericInputField,
  BlueprintError
} from "@teselagen/ui";
import { Button, Intent } from "@blueprintjs/core";
import { get, isEmpty } from "lodash";
import stepFormValues from "../../../stepFormValues";
import { safeQuery } from "../../../apolloMethods";
import { useDispatch } from "react-redux";
import { change as _change } from "redux-form";

const DataSelectionStep = ({
  dataGrid,
  dataGrids = [],
  Footer,
  footerProps,
  errors,
  filterTarget,
  filterType,
  filterTypes,
  filterValue,
  submitting,
  toolSchema: { code }
}) => {
  const dispatch = useDispatch();
  const change = useCallback(
    (field, value) => dispatch(_change(code, field, value)),
    [code, dispatch]
  );
  const [dataSchema, setDataSchema] = useState([]);
  const [dataRows, setDataRows] = useState([]);
  const [measurements, setMeasurements] = useState([]);

  const clearValidation = useCallback(() => {
    change("filterTarget", null);
    change("filterValue", null);
    setDataSchema([]);
    setDataRows([]);
    setMeasurements([]);
  }, [change]);

  useEffect(() => {
    const getData = async () => {
      const dataGridId = get(dataGrid, "id");
      const _dataGrid = await getDataGrid(dataGridId);
      const { dataCells, dataCellHeaders } = _dataGrid;
      const { entities: dataGridEntities, schema: dataGridSchema } =
        parseDataForDataGridTable(dataCells, {
          dataCellHeaders,
          withAliquotData: true
        });

      const _measurements = dataGridSchema
        .filter(header => !header.path.includes("aliquot"))
        .map(header => {
          const name = header.displayName.split("[")[0].trim();
          const unit = header.displayName.match(/\[(.*?)\]/)[1];
          return { name, unit };
        });

      setMeasurements(_measurements);
      setDataSchema(dataGridSchema);
      setDataRows(dataGridEntities);
      change("dataGridEntites", dataGridEntities);
    };
    clearValidation();
    if (dataGrid) {
      getData();
    }
  }, [change, clearValidation, dataGrid]);

  return (
    <>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select a Data Grid"
          helper="A Data Grid coming from Data Association Tool will be used as the experimental context where data points were stored"
        />
        <ReactSelectField
          isRequired
          name="dataGrid"
          label="Data Grid"
          placeholder="Select a data grid..."
          options={dataGrids.map(option => ({
            value: option,
            label: option.name
          }))}
        />
      </div>
      {dataGrid && (
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Aliquots Data"
            helper="These are your aliquots linked to data rows."
          />
          <DataTable
            withPaging
            defaults={{
              pageSize: 10
            }}
            formName="selectSamplesForm"
            safeQuery={safeQuery}
            isSimple
            entities={dataRows}
            schema={dataSchema}
          />
          {errors.dataGridEntities && (
            <BlueprintError error={errors.dataGridEntities} />
          )}
        </div>
      )}
      {dataGrid && !errors.dataGridEntities && (
        <div className="tg-step-form-section column">
          <HeaderWithHelper
            header="Selection Criteria"
            helper="Select data rows by choosing measurement and a value to compare."
          />
          <div className="tg-flex justify-space-between">
            <SelectField
              name="filterTarget"
              label="Measurement"
              defaultValue={measurements[0]}
              options={measurements.map(option => ({
                value: option,
                label: option.name
              }))}
            />
            <div className="row">
              <RadioGroupField
                inline
                defaultValue="over"
                options={Object.keys(filterTypes).map(opt => ({
                  value: opt,
                  label: opt
                }))}
                name="filterType"
              />
              <div className="tg-flex align-center justify-space-between">
                <NumericInputField
                  label={`${filterTarget?.name} (${filterTarget?.unit})`}
                  name="filterValue"
                  min={0}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer
        {...footerProps}
        nextButton={
          <Button
            type="submit"
            intent={Intent.SUCCESS}
            text="Submit"
            disabled={
              !dataGrid ||
              !filterTarget ||
              !filterType ||
              !filterValue > 0 ||
              !isEmpty(errors)
            }
            loading={submitting}
          />
        }
      />
    </>
  );
};

export default compose(
  withQuery(["dataGrid", "id name"], {
    isPlural: true,
    variables: { filter: { dataGridStateCode: "EDITABLE" } }
  }),
  stepFormValues(
    "dataGrid",
    "filterTarget",
    "filterType",
    "filterValue",
    "dataGridEntites"
  )
)(DataSelectionStep);
