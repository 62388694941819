/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useMemo } from "react";
import { DataTable, useTableEntities, useTableParams } from "@teselagen/ui";
import { assemblyPieceTableName } from "../../constants";
import { assemblyPieceViewFragment } from "../../../../../graphql/fragments/constructSelectionJ5ReportFragment";
import schema from "./schema";
import { addInInventoryFilter } from "../../utils";
import InInventorySelectFilter from "../../InInventorySelectFilter";
import {
  safeQuery,
  useTgQuery
} from "../../../../../../src-shared/apolloMethods";
import { useFormValue } from "../../../../../../src-shared/hooks/useFormValue";

const divStyle = { display: "flex", alignItems: "center" };
const dataTableStyle = { height: 400 };

const AssemblyPieceInitialTable = ({ toolSchema: { code } }) => {
  const j5Reports = useFormValue(code, "j5Reports");
  const finalizedAPIds = useFormValue(code, "finalizedAPIds");

  const additionalFilter = useCallback(
    (_, qb, currentParams) => {
      const j5ReportIds = j5Reports?.map(j => j.id) || [];
      const filter = {
        j5ReportId: j5ReportIds,
        id: qb.notInList(finalizedAPIds || [])
      };
      addInInventoryFilter(qb, currentParams);
      qb.whereAll(filter);
    },
    [finalizedAPIds, j5Reports]
  );

  const { tableParams: _tableParams, variables } = useTableParams({
    formName: assemblyPieceTableName,
    urlConnected: false,
    schema,
    additionalFilter
  });

  const { entities, loading, refetch } = useTgQuery(assemblyPieceViewFragment, {
    isPlural: true,
    variables
  });

  const tableParams = useMemo(() => {
    if (!loading && entities && entities.length) {
      return {
        ..._tableParams,
        isLoading: loading,
        entities,
        entityCount: entities.length,
        fragment: assemblyPieceViewFragment,
        onRefresh: refetch,
        variables
      };
    }
    return _tableParams;
  }, [_tableParams, entities, loading, refetch, variables]);

  const { selectTableEntities } = useTableEntities(assemblyPieceTableName);

  return (
    <DataTable
      {...tableParams}
      className="tg-assembly-pieces-initial"
      maxHeight={400}
      style={dataTableStyle}
      compact
      keepDirtyOnReinitialize
      withSelectAll
      safeQuery={safeQuery}
      withCheckboxes
    >
      <div style={divStyle}>
        <InInventorySelectFilter
          name="assemblyPieceInventoryFilter"
          setNewParams={tableParams.setNewParams}
          selectTableRecords={selectTableEntities}
        />
      </div>
    </DataTable>
  );
};

export default AssemblyPieceInitialTable;
