/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import TagFilterMenu from "../TagFilterMenu";
import { exportTagsColumn } from "./tagUtils";
import TagTableColumn from "../TagTableColumn";
import { get } from "lodash";

type Record = {
  taggedItems?: { id: string; tag: { id: string; name: string } }[];
};
type NestedRecord = Record | { [key: string]: NestedRecord } | undefined;

export const tagColumn = {
  path: "tags",
  type: "string",
  displayName: "Tags",
  searchDisabled: true,
  sortDisabled: true,
  FilterMenu: TagFilterMenu,
  getClipboardData: exportTagsColumn,
  filterIsActive: (currentParams: { tags: string[] }) => currentParams.tags
};

type TableProps = { currentParams: any; setNewParams: (params: any) => void };

export const tagColumnWithRender = {
  ...tagColumn,
  render: (
    val: any,
    record: Record,
    row: any,
    { currentParams, setNewParams }: TableProps
  ) => (
    <TagTableColumn
      record={record}
      paramProps={{ currentParams, setNewParams }}
    />
  )
};

export const tagColumnWithRenderNested = (path: string) => {
  return {
    ...tagColumnWithRender,
    render: (val: any, record: NestedRecord, row: any, props: TableProps) =>
      tagColumnWithRender.render(val, get(record, path) || {}, row, props)
  };
};
