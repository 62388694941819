/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { generateField } from "@teselagen/ui";
import modelNameToReadableName from "../utils/modelNameToReadableName";

import { safeQuery } from "../apolloMethods";
import { Button, Intent, Tooltip } from "@blueprintjs/core";
import { noop, isEmpty, cloneDeep } from "lodash";
import { branch, withProps, compose, withHandlers } from "recompose";
import { reduxForm, autofill, change } from "redux-form";
import { useDispatch } from "react-redux";
import GenericSelectInner from "./InnerComp";
import genericSelectWrapper from "./genericSelectWrapper";
import { PostSelectTable } from "./PostSelectTable";
import usePrevious from "../usePrevious";
import { useFormValue } from "../hooks/useFormValue";

// useage example:
// <GenericSelect {...{
//   name: "selectedWorklists", //the field name within the redux form Field
//   isMultiSelect: true,
//   schema: ["name", "lastModified"],
//   fragment: [model, "id name"],
//   additionalDataFragment: somefragment,
// }}/>

//options:
// name - the field name of the redux form Field!
// schema - the schema for the data table
// getButtonText(selectedEntities) - function to override the button text if necessary
// isMultiSelect=false - do you want users to be able to select multiple entities or just one
// noDialog=false - set to true to not have the selector show up in a dialog
// fragment - the fragment powering the lookup/datatable
// dialogProps - any dialog overrides you might want to make
// dialogFooterProps - any dialogFooter overrides you might want to make
// additionalDataFragment - optional fragment for fetching more data based on the initially selected data
// postSelectDTProps - props passed to the DataTable shown after select. If none are passed the DataTable isn't shown
// onSelect - optional callback for doing things with the selected data
// onFieldSubmit - additional callback for doing things when selecting options. Receives all the currently selected options. When GenericSelect is created from SelectGenericItemsDialog its always defined

// ################################   asReactSelect   ################################
// idAs="id" - use this to get the TgSelect to use some other property as the "value" aka idAs="code" for code based selects
// asReactSelect - optionally make the generic select a simple TgSelect component instead of the default datatables
// reactSelectProps - optionally pass additional props to the TgSelect
// ...rest - all additional props will be passed to the TgSelect
// ** preventing unselect if you don't want a certain option to be unselected ever, you can pass initialValues with a property called clearableValue  entity.clearableValue,

// if you want initialValues, simply pass them to the reduxForm wrapped component like:
// {
//   initialValues: {
//     selectedWorklists: [{id: 1, name: "worklist1"}]
//   }
// }

function preventBubble(e) {
  e.stopPropagation();
}

const GenericSelect = compose(
  branch(
    props => props.noForm, // In case the GenericSelect is not used within a form, add an artificial one for all events to work correctly
    reduxForm({
      form: "genericSelect",
      asyncBlurFields: [] //hacky fix for weird redux form asyncValidate error https://github.com/erikras/redux-form/issues/1675
    })
  ),
  withProps(
    ({
      asReactSelect,
      idAs: _idAs,
      isCodeModel: _isCodeModel,
      isMultiSelect,
      name,
      nameOverride,
      schema
    }) => {
      // custom inner component should be treated the same as asReactSelect in terms of props passed down
      let idAs = _idAs;
      if (!idAs) {
        idAs = _isCodeModel ? "code" : "id";
      }

      const readableName =
        nameOverride ||
        modelNameToReadableName(schema.model, {
          plural: isMultiSelect,
          upperCase: true
        });

      return {
        modelName: schema.model,
        idAs,
        passedName: name,
        readableName,
        schema,
        asReactSelect
      };
    }
  ),
  withHandlers({
    massageDefaultIdValue:
      props =>
      async ({ defaultValueById }) => {
        if (!defaultValueById) return {};
        const res = await safeQuery(
          props.additionalDataFragment || props.fragment,
          {
            idAs: props.idAs,
            variables:
              props.idAs === "code"
                ? { code: defaultValueById }
                : { id: defaultValueById }
          }
        );

        return {
          defaultValue: res,
          preventUserOverrideFromBeingDisabled: !res
        };
      }
  }),
  generateField
)(({
  additionalDataFragment,
  additionalFilter,
  additionalTableProps,
  asReactSelect,
  autoOpen,
  buttonProps,
  code,
  containerClassName,
  dataTest,
  defaultTemplateString,
  defaultValue,
  defaultValueByIdOverride,
  destinationPlateFormat,
  dialogInfoMessage,
  dialogProps: _dialogProps,
  disabled,
  doNotGenerateField,
  enableReinitialize,
  entities,
  fragment,
  fieldType,
  firstItemsToShow,
  getButton,
  getButtonText,
  handlersObj,
  handleOpenChange,
  hideModal,
  idAs,
  input: { onChange, onBlur, name },
  inputTracker,
  isLocalCall,
  isMultiSelect,
  isRequired,
  key,
  label,
  meta: { form },
  minSelected,
  mustSelect,
  nameOverride,
  noDialog: _noDialog,
  noFill,
  noForm,
  noMarginBottom,
  noRemoveButton,
  noResultsText,
  onClear = noop,
  onFieldSubmit = noop,
  onSelect,
  params,
  passedName,
  placeholder,
  postSelectDTProps,
  preserveValue,
  queryOptions,
  reactSelectProps,
  reactSelectQueryString: _reactSelectQueryString,
  reagent,
  readableName,
  schema: _schema,
  secondaryLabel,
  selectedPlateIds, // TODO: Remove in favor of a generic prop,
  style,
  tableParamOptions,
  tooltipInfo,
  validate,
  withSelectAll = true,
  withSelectedTitle
}) => {
  const dispatch = useDispatch();
  const [fetchingData, setFetchingData] = useState(false);
  const [tempValue, setTempValue] = useState(null);
  const [reactSelectQueryString, setReactSelectQueryString] = useState("");
  const value = useFormValue(form, name);
  const noDialog = asReactSelect || !!_noDialog;
  const postSelectFormName = postSelectDTProps
    ? passedName + "PostSelect"
    : null;

  const oldValue = usePrevious(value);

  const dialogProps = useMemo(
    () => ({
      title: "Select " + readableName,
      ..._dialogProps
    }),
    [_dialogProps, readableName]
  );

  // handles value pre-selection from parent
  useEffect(() => {
    if (onSelect) {
      const hasValue = Array.isArray(value) ? value.length : value;
      if (hasValue) {
        const arrValue = Array.isArray(value) ? value : [value];
        const arrOldValue = oldValue
          ? Array.isArray(oldValue)
            ? oldValue
            : [oldValue]
          : [];
        const newIds = arrValue.map(r => r.id || r.code);
        const oldIds = arrOldValue.map(r => r.id || r.code);
        if (
          newIds.length !== oldIds.length ||
          newIds.every(id => !oldIds.includes(id))
        ) {
          // instead of doing a deep comparison check just check if ids of selected items changed
          onSelect(value, {
            reactSelectQueryString: _reactSelectQueryString
          });
        }
      }
    }
  }, [_reactSelectQueryString, value, oldValue, onSelect]);

  const resetPostSelectSelection = useCallback(() => {
    const postSelectDTFormName =
      postSelectDTProps?.formName || postSelectFormName;
    if (postSelectDTFormName) {
      dispatch(
        change(postSelectDTFormName, "reduxFormSelectedEntityIdMap", {})
      );
    }
  }, [dispatch, postSelectDTProps?.formName, postSelectFormName]);

  // Here we can add props of the genericSelect if needed. The DataTable will
  // not be receiving extraProps anymore to prevent unnecessary rerenders and
  // have a better separation of data and presentation
  const schema = useMemo(() => {
    const tmpSchema = cloneDeep(_schema);
    if (tmpSchema.fields) {
      tmpSchema.fields = tmpSchema.fields.map(field => {
        if (field.addQueryString) {
          return {
            ...field,
            render: field.render({ reactSelectQueryString })
          };
        }
        return field;
      });
    }

    return tmpSchema;
  }, [_schema, reactSelectQueryString]);

  const removeSelection = useCallback(() => {
    const newVal = isMultiSelect ? [] : undefined;
    dispatch(autofill(form, name, newVal));
    onClear();
    onBlur();

    setTempValue(null);
    resetPostSelectSelection();
    onFieldSubmit(undefined, true);
  }, [
    dispatch,
    form,
    isMultiSelect,
    name,
    onBlur,
    onClear,
    onFieldSubmit,
    resetPostSelectSelection
  ]);

  // for post select table
  const removeEntityFromSelection = useCallback(
    record => {
      // TODO: sometimes (kind of randomly) the value is not an array
      const newValue = value ? value.filter(r => r[idAs] !== record[idAs]) : [];
      if (newValue.length) {
        onChange(newValue);
        setTempValue(null);
        resetPostSelectSelection();
      } else {
        removeSelection();
      }
    },
    [idAs, onChange, removeSelection, resetPostSelectSelection, value]
  );

  const handleOnChange = useCallback(
    newValue => {
      const _value = value || [];
      let toSelect = newValue;
      if (isMultiSelect && _value.length && preserveValue) {
        const newIds = newValue.map(r => r[idAs]);
        toSelect = _value
          .filter(r => !newIds.includes(r[idAs]))
          .concat(newValue);
      }
      onChange && onChange(toSelect);
      onBlur && onBlur();
      onFieldSubmit(toSelect, true);
    },
    [value, isMultiSelect, preserveValue, onChange, onBlur, onFieldSubmit, idAs]
  );

  const handleSelection = useCallback(
    async records => {
      const toSelect = isMultiSelect ? records : records[0];
      resetPostSelectSelection();
      if (asReactSelect && !records.length) {
        return removeSelection();
      }
      if (!additionalDataFragment) {
        handleOnChange(toSelect || null);
        return;
      }
      setFetchingData(true);
      const queryVariables = {
        filter: {
          [idAs]: isMultiSelect ? records.map(r => r[idAs]) : records[0][idAs]
        }
      };
      if (isEmpty(postSelectDTProps)) {
        try {
          const records = await safeQuery(additionalDataFragment, {
            variables: queryVariables
          });

          const toSelect = isMultiSelect ? records : records[0];
          handleOnChange(toSelect);
        } catch (error) {
          console.error("err:", error);
          window.toastr.error("Error fetching " + readableName);
        }
      } else if (!additionalDataFragment) {
        handleOnChange(toSelect);
      } else {
        // this is necessary because sometimes we are relying on the field to have
        // the full data
        setTempValue(toSelect);
      }
      setFetchingData(false);
    },
    [
      additionalDataFragment,
      asReactSelect,
      handleOnChange,
      idAs,
      isMultiSelect,
      postSelectDTProps,
      readableName,
      removeSelection,
      resetPostSelectSelection
    ]
  );

  // bad practice!!
  if (handlersObj) {
    handlersObj.removeSelection = removeSelection;
  }

  const postSelectDataTableValue = useMemo(() => {
    let _postSelectDataTableValue = tempValue || value;
    if (
      _postSelectDataTableValue &&
      !Array.isArray(_postSelectDataTableValue)
    ) {
      _postSelectDataTableValue = [_postSelectDataTableValue];
    }
    return _postSelectDataTableValue;
  }, [value, tempValue]);

  const propsToPass = {
    additionalDataFragment,
    additionalFilter,
    additionalTableProps,
    asReactSelect,
    autoOpen,
    buttonProps,
    code,
    currentValue: value,
    dataTest,
    defaultTemplateString,
    defaultValue,
    defaultValueByIdOverride,
    destinationPlateFormat,
    dialogInfoMessage,
    dialogProps,
    disabled,
    doNotGenerateField,
    enableReinitialize,
    entities,
    fragment,
    fieldType,
    firstItemsToShow,
    getButton,
    getButtonText,
    handleOpenChange,
    handlersObj,
    handleSelection,
    hideModal,
    idAs,
    isLocalCall,
    isMultiSelect,
    isRequired,
    inputTracker,
    onClear,
    key,
    label,
    minSelected,
    mustSelect,
    nameOverride,
    noDialog,
    noFill,
    noForm,
    noMarginBottom,
    noRemoveButton,
    noResultsText,
    onFieldSubmit,
    onSelect,
    params,
    passedName,
    placeholder,
    postSelectDTProps,
    postSelectFormName,
    preserveValue,
    queryOptions,
    reactSelectProps,
    reactSelectQueryString,
    setReactSelectQueryString,
    reagent,
    readableName,
    schema,
    secondaryLabel,
    selectedPlateIds,
    style,
    tableParamOptions,
    tooltipInfo,
    validate,
    withSelectAll,
    withSelectedTitle
  };

  let hasValue = !!value;
  // need to account for case where value = [] which is empty
  if (Array.isArray(value) && !value.length) hasValue = false;

  let buttonText = buttonProps?.text;
  if (!buttonText) {
    buttonText = getButtonText
      ? getButtonText(value)
      : hasValue
        ? "Change " + readableName
        : `Select ${readableName}`;
  }

  const classNameToUse =
    "tg-generic-select-container " + (containerClassName || "");

  return noDialog ? (
    <div className={classNameToUse} onClick={preventBubble}>
      <GenericSelectInner {...propsToPass} />
    </div>
  ) : (
    <div className={classNameToUse}>
      <div
        onClick={preventBubble}
        style={{ paddingTop: 10, paddingBottom: 10 }}
      >
        <div style={{ display: "flex" }}>
          <GenericSelectInner {...propsToPass} buttonText={buttonText}>
            {getButton ? (
              getButton(value, propsToPass, {
                fetchingData,
                tempValue,
                reactSelectQueryString
              })
            ) : (
              <Button
                intent={hasValue ? Intent.NONE : Intent.PRIMARY}
                {...buttonProps}
                text={buttonText}
                loading={fetchingData || buttonProps?.loading}
              />
            )}
          </GenericSelectInner>
          {hasValue && !noRemoveButton && !noForm && (
            <Tooltip
              disabled={buttonProps?.disabled}
              content={"Clear " + readableName}
            >
              <Button
                minimal
                style={{ marginLeft: 4 }}
                intent={Intent.DANGER}
                disabled={buttonProps?.disabled}
                onClick={removeSelection}
                className="tg-clear-generic-select"
                icon="trash"
              />
            </Tooltip>
          )}
        </div>
        {!isEmpty(postSelectDTProps) && !!postSelectDataTableValue?.length && (
          <PostSelectTable
            additionalDataFragment={additionalDataFragment}
            buttonProps={buttonProps}
            changeGenericSelectValue={handleOnChange}
            clearTempValue={() => {
              setTempValue(null);
            }}
            genericSelectValue={value}
            idAs={idAs}
            initialEntities={postSelectDataTableValue}
            isMultiSelect={isMultiSelect}
            onSelect={onSelect}
            postSelectDTProps={postSelectDTProps}
            postSelectFormName={postSelectFormName}
            readableName={readableName}
            removeEntityFromSelection={removeEntityFromSelection}
            removeSelection={removeSelection}
            resetSelection={resetPostSelectSelection}
            withSelectedTitle={withSelectedTitle}
          />
        )}
      </div>
    </div>
  );
});

export default genericSelectWrapper(GenericSelect);
