/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { Callout, Classes } from "@blueprintjs/core";
import { DialogFooter } from "@teselagen/ui";
import { wrapDialog } from "@teselagen/ui";
import GenericSelect from "../GenericSelect";
import { Link } from "react-router-dom";

const PublishSequencesDialog = props => {
  const {
    hideModal,
    sequenceIds,
    isProtein,
    refetchSequences,
    submitting,
    handleSubmit
  } = props;

  const onSubmit = async values => {
    try {
      if (!values.publicationTarget) {
        window.toastr.error("Please select a publication target.");
        return;
      }
      const {
        data: { success, imported, duplicateSequences, err }
      } = await window.serverApi.request({
        method: "POST",
        url: "/publishSequences",
        data: {
          ...values,
          isProtein,
          sequenceIds
        }
      });
      if (!success) {
        console.error(err);
        throw new Error(err);
      } else {
        let dupMsg = "";
        if (duplicateSequences?.length > 0) {
          dupMsg = `\nThere were ${duplicateSequences?.length} duplicated sequences: ${duplicateSequences?.map(x => x.name).join(", ")}.`;
        }
        window.toastr.success(
          `Successfully published ${imported?.length} sequences of ${sequenceIds?.length} submitted. ${dupMsg}`,
          { timeout: 2000 * (duplicateSequences?.length + 1) }
        );
      }
      await refetchSequences();

      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error publishing sequence(s).");
      hideModal();
    }
  };

  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        <Callout intent="primary">
          Publishing the selected sequences will create a common, read-only
          version of the sequence(s) at the selected URL.
          <div
            // smaller font size
            style={{
              marginTop: 10,
              fontSize: "0.8em"
            }}
          >
            Publication targets can be created in{" "}
            <Link to="/settings/publication-management">
              Publication Management Settings
            </Link>
          </div>
        </Callout>
        <br></br>
        <GenericSelect
          {...{
            noResultsText: (
              <div>
                No publication targets found. Please create one in{" "}
                <Link to="/settings/publication-management">
                  Publication Management Settings
                </Link>
              </div>
            ),
            name: "publicationTarget",
            schema: {
              fields: ["name", "baseUrl"]
            },
            isRequired: true,
            asReactSelect: true,
            label: "Publication Target",
            fragment: ["publicationTarget", "id name authKey baseUrl"]
          }}
        />
      </div>
      <DialogFooter
        hideModal={hideModal}
        submitting={submitting}
        text="Publish"
        onClick={handleSubmit(onSubmit)}
      />
    </React.Fragment>
  );
};

export default compose(
  wrapDialog({
    title: "Publish Options",
    style: {
      width: 450
    }
  }),
  reduxForm({
    form: "publishSequencesForm", // a unique name for this form
    enableReinitialize: true
  })
)(PublishSequencesDialog);
