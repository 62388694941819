/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { getPositionFromAlphanumericLocation } from "../../../../../../tg-iso-lims/src/utils/plateUtils";
import { inventoryItemTypeMap } from "../../../../../../tg-iso-shared/src/utils/inventoryUtils";
import type { Item } from "./types/Item";

type __TmpInfoContent = {
  temperatureZoneOrientation: "vertical" | "horizontal";
  temperatureZones?: number[];
};

export const isTmpInfoContent = (
  key: string,
  tmp: any
): tmp is __TmpInfoContent => {
  return key === "__tempInfo";
};

type __TmpInfo = {
  __tempInfo: __TmpInfoContent;
};

type WellInfo = {
  [wellString: string]: {
    item: Item;
    volume?: number;
    volumetricUnitCode?: string;
  };
};

export type PlateMapItem = {
  columnPosition: number;
  id?: number | string;
  inventoryItem?: {
    inventoryItemTypeCode: string;
    [typeNameId: string]: string;
  };
  j5Item?: { j5ItemTypeCode: string; [typeNameId: string]: string };
  name?: string;
  plateMapItemTypeCode?: string;
  rowPosition: number;
  taggedItems?: any;
  volume?: number;
  volumetricUnitCode?: string;
  location?: string;
};

export type PlateToCreateType = { __tempInfo?: __TmpInfoContent } & WellInfo;

export const generatePlateMapGroup = ({
  itemType,
  plateWellContentType,
  plateMapType,
  j5EntityRadio,
  plateMapGroupName = "Plate Map",
  platesToCreate: _platesToCreate,
  selectedContainerFormat,
  addFakeIds,
  uploadingVolumeInfo,
  reactionEntityType
}: {
  itemType: string;
  plateWellContentType: string;
  plateMapType: string;
  j5EntityRadio?: "j5PcrReaction" | string;
  plateMapGroupName?: string;
  platesToCreate: PlateToCreateType[];
  selectedContainerFormat: { code: string };
  addFakeIds: boolean;
  uploadingVolumeInfo: boolean;
  reactionEntityType: string;
}) => {
  let id = 1;

  const platesToCreate: {
    name: string;
    type: string;
    metadata?: __TmpInfoContent;
    plateMapItems: PlateMapItem[];
  }[] = _platesToCreate.map((plate, i) => {
    //this plate comes in looking like: {"A4": someMaterial, B5: someOtherMaterial}
    let type;
    if (itemType === "j5Report") {
      if (j5EntityRadio === "j5PcrReaction") {
        type = "j5PcrReaction";
      } else {
        type = "material";
      }
    } else if (itemType === "plateMap") {
      type = plateMapType;
    } else if (itemType === "reactionMap") {
      if (reactionEntityType.includes("Material")) {
        type = "material";
      } else {
        type = "additiveMaterial";
      }
    } else if (itemType === "Inventory List") {
      type = "sample";
    } else if (itemType === "containerArray") {
      type = plateWellContentType;
    } else {
      type = itemType;
    }
    let metadata: { metadata?: __TmpInfoContent } = {};
    if ("__tempInfo" in plate && plate.__tempInfo !== undefined) {
      metadata = { metadata: plate.__tempInfo };
    }

    return {
      name: plateMapGroupName + ` ${i + 1}`,
      type,
      ...metadata,
      plateMapItems: Object.entries(plate)
        .map(([wellString, plateMapItemInfo]) => {
          if (isTmpInfoContent(wellString, plateMapItemInfo)) {
            return null;
          }
          const { item, volume, volumetricUnitCode } = plateMapItemInfo;
          const { rowPosition, columnPosition } =
            getPositionFromAlphanumericLocation(wellString);

          const itemTypeField: {
            j5Item?: { j5ItemTypeCode: string; [typeNameId: string]: string };
            inventoryItem?: {
              inventoryItemTypeCode: string;
              [typeNameId: string]: string;
            };
          } = {};
          if (item.__typename === "j5PcrReaction") {
            itemTypeField.j5Item = {
              j5ItemTypeCode: "J5_PCR_REACTION",
              [item.__typename + "Id"]: item.id
            };
          } else {
            itemTypeField.inventoryItem = {
              inventoryItemTypeCode:
                inventoryItemTypeMap[
                  item.__typename as keyof typeof inventoryItemTypeMap
                ],
              [item.__typename + "Id"]: item.id
            };
          }

          return {
            // the ids and tags are needed for the preview
            ...(addFakeIds && {
              id: id++,
              taggedItems: "taggedItems" in item ? item.taggedItems : undefined
            }),
            ...(uploadingVolumeInfo && {
              volume,
              volumetricUnitCode
            }),
            rowPosition,
            name: item.name,
            columnPosition,
            plateMapItemTypeCode: "INVENTORY_ITEM",
            ...itemTypeField
          };
        })
        .filter(x => x) as PlateMapItem[]
    };
  });

  return {
    name: plateMapGroupName,
    containerFormatCode: selectedContainerFormat.code,
    plateMaps: platesToCreate
  };
};
