/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback } from "react";
import { compose, withProps } from "recompose";
import { withTableParams, DataTable } from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";

import {
  volumeColumn,
  concentrationColumn,
  dateModifiedColumn
} from "../../../../src-shared/utils/libraryColumns";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";

function Page1(props) {
  const { tableParams, onDoubleClick } = props;
  return (
    <div>
      <h6 style={{ marginBottom: 15 }}>
        Select an aliquot of the same material:
      </h6>
      <DataTable
        {...tableParams}
        onDoubleClick={onDoubleClick}
        isSingleSelect
      />
    </div>
  );
}

export default compose(
  withProps(props => {
    // Added while #12943 is not ready
    const additionalFilter = useCallback(
      (_, qb) => {
        qb.whereAll({
          id: qb.notEquals(props.destinationAliquot.id),
          isDry: false,
          "sample.materialId": props.destinationAliquot.sample.materialId
        });
        qb.orWhereAll({
          id: qb.notEquals(props.destinationAliquot.id),
          isDry: null,
          "sample.materialId": props.destinationAliquot.sample.materialId
        });
      },
      [props.destinationAliquot.id, props.destinationAliquot.sample.materialId]
    );
    return { additionalFilter };
  }),
  withTableParams({
    formName: "aliquotsForReplenish",
    schema: {
      model: "aliquot",
      fields: [
        {
          path: "id",
          type: "number",
          displayName: "ID",
          render: val => {
            return `Aliquot ${val}`;
          }
        },
        {
          displayName: "Location",
          path: "aliquotContainer.containerArray.name",
          render: (val, record) => {
            if (val) {
              const location = getAliquotContainerLocation(
                record.aliquotContainer
              );
              return `${val} ${location}`;
            }
          }
        },
        volumeColumn,
        concentrationColumn,
        dateModifiedColumn
      ]
    }
  }),
  withQuery(
    [
      "aliquot",
      `
      id
      volume
      volumetricUnitCode
      concentration
      concentrationUnitCode
      sample {
        id
        materialId
      }
      aliquotContainer {
        id
        rowPosition
        columnPosition
        containerArray {
          id
          name
        }
      }
      updatedAt
    `
    ],
    {
      isPlural: true
    }
  )
)(Page1);
