/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export const createOrEditPlateMapJ5ReportJ5AssemblyPiecesFragment = gql`
  fragment createOrEditPlateMapJ5ReportJ5AssemblyPiecesFragment on j5Report {
    id
    name
    j5AssemblyPieces {
      id
      name
      sequence {
        id
        polynucleotideMaterial {
          id
          name
        }
      }
    }
  }
`;

export const createOrEditPlateMapJ5Reportj5InputSequenceFragment = gql`
  fragment createOrEditPlateMapJ5Reportj5InputSequenceFragment on j5Report {
    id
    name
    j5InputSequences {
      id
      sequence {
        id
        polynucleotideMaterial {
          id
          name
        }
      }
    }
  }
`;

export const createOrEditPlateMapJ5ReportJ5PcrReactionsFragment = gql`
  fragment createOrEditPlateMapJ5ReportJ5PcrReactionsFragment on j5Report {
    id
    name
    j5PcrReactions {
      id
      name
    }
  }
`;

export const createOrEditPlateMapJ5Reportj5RunConstructFragment = gql`
  fragment createOrEditPlateMapJ5Reportj5RunConstructFragment on j5Report {
    id
    name
    j5RunConstructs {
      id
      name
      sequence {
        id
        polynucleotideMaterial {
          id
          name
        }
      }
    }
  }
`;

export const createOrEditPlateMapJ5ReportpcrProductSequenceFragment = gql`
  fragment createOrEditPlateMapJ5ReportpcrProductSequenceFragment on j5Report {
    id
    name
    j5PcrReactions {
      id
      pcrProductSequence {
        id
        polynucleotideMaterial {
          id
          name
        }
      }
    }
  }
`;

export const createOrEditPlateMapJ5ReportprimaryTemplateFragment = gql`
  fragment createOrEditPlateMapJ5ReportprimaryTemplateFragment on j5Report {
    id
    name
    j5PcrReactions {
      id
      primaryTemplate {
        id
        polynucleotideMaterial {
          id
          name
        }
      }
    }
  }
`;

export const createOrEditPlateMapJ5ReportprimerFragment = gql`
  fragment createOrEditPlateMapJ5ReportprimerFragment on j5Report {
    id
    name
    j5PcrReactions {
      id
      forwardPrimer {
        id
        sequence {
          id
          polynucleotideMaterial {
            id
            name
          }
        }
      }
      reversePrimer {
        id
        sequence {
          id
          polynucleotideMaterial {
            id
            name
          }
        }
      }
    }
  }
`;
