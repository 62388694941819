/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useMemo } from "react";
import {
  Directions,
  quadrantToDefaultStartingPosition
} from "../../../../../utils/plateUtils/getWellPositionsGivenWellCountAndDirection";
import {
  generateEmptyWells,
  getPositionFromAlphanumericLocation
} from "../../../../../../../tg-iso-lims/src/utils/plateUtils";
import {
  isTmpInfoContent,
  PlateToCreateType
} from "../../utils/generatePlateMapGroup";
import { rowIndexToLetter } from "../../../../../../../tg-iso-lims/src/utils/rowIndexToLetter";
import { useDispatch, useSelector } from "react-redux";
import { change, formValueSelector } from "redux-form";
import {
  canDistributeTemperatureBlocks,
  getSelectedItems,
  ItemTypes,
  PlateWellContentTypes,
  pluralizeItemType
} from "../../utils";
import type { RecursiveRequired } from "../../../../../../src-shared/typescriptHelpers";
import type { J5EntityType } from "../../utils/types/J5EntityType";
import type {
  J5MaterialFragment,
  J5PcrReactionFragment,
  LotFragment,
  AdditiveMaterialFragment
} from "../../utils/fragments.gql.generated";
import type {
  ContainerArrayAdditionalFragment,
  MaterialAdditionalFragment,
  SampleAdditionalFragment
} from "../../utils/additionalFragments.gql.generated";
import type { PlateMap } from "../../utils/types/PlateMap";
import { Item, Items } from "../../utils/types/Item";
import actions from "../../../../../../src-shared/redux/actions";
import {
  Button,
  Intent,
  Menu,
  MenuItem,
  Classes,
  Tooltip,
  Icon,
  Colors
} from "@blueprintjs/core";
import {
  containerArraySelectedAliquotContainerLocationsSelector,
  StateType
} from "../../../../../../src-shared/redux/selectors";
import {
  getSortedWellsForPlate,
  isPlateObjectFull,
  reactionsWithTempInfoFragment,
  makeItemToWellMapForPlateMap
} from "./utils";
import {
  getLocationHashMapGivenWellCountAndDirection,
  getActiveLocationsForQuadrant
} from "../../../../../utils/plateUtils";
import ChooseSimpleDistributeOptions, {
  FormData
} from "./ChooseSimpleDistributeOptions";
import {
  DataTable,
  DropdownButton,
  SelectField,
  useTableEntities
} from "@teselagen/ui";
import {
  blockToAliquotArray,
  getBlockOf2dArray,
  plateTo2dAliquotContainerArray
} from "../../../utils";
import { chunk, keyBy, min, range, times } from "lodash";
import { getAliquotContainerLocation } from "../../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import type { BreakdownPatterns } from "../../../utils/blockToAliquotArray";
import { modelTypeMap } from "../../../../../../../tg-iso-shared/src/utils/modelTypeMap";
import { showDialog } from "../../../../../../src-shared/GlobalDialog";
import ChooseDistributeMaterialsByLengthOptions from "./ChooseDistributeMaterialsByLengthOptions";
import ChooseDistributeByExtendedPropertyOptions from "./ChooseDistributeByExtendedPropertyOptions";
import { safeQuery } from "../../../../../../src-shared/apolloMethods";
import DistributeByAssemblyReportDialog from "./DistributeByAssemblyReportDialog";
import ChooseDistributeIntoTemperatureBlocksOptions from "./ChooseDistributeIntoTemperatureBlocksOptions";
import { distributeIntoTemperatureBlocks } from "../../../PcrPlanningAndInventoryCheck/utils";
import HeaderWithHelper from "../../../../../../src-shared/HeaderWithHelper";
import DraggableMaterialHandle from "./DraggableMaterialHandle";
import { isMac } from "../../../../../../src-shared/utils/generalUtils";
import { PlateMapPlateNoContext } from "../../../../PlateMapPlate";
import CustomMaterialDragLayer from "./CustomMaterialDragLayer";
import type { ReactionsWithTempInfoFragment } from "./utils/reactionsWithTempInfoFragment.gql.generated";
const reduxValues = {
  BreakIntoQuadrants: "breakIntoQuadrants",
  BreakdownPattern: "breakdownPattern",
  ItemType: "itemType",
  J5EntityType: "j5EntityType",
  J5EntityRadio: "j5EntityRadio",
  J5Materials: "j5Materials",
  J5PcrReactions: "j5PcrReactions",
  SelectedContainerFormat: "selectedContainerFormat",
  CurrentPlateMapIndex: "currentPlateMapIndex",
  PlatesToCreate: "platesToCreate",
  PlateWellContentType: "plateWellContentType",
  Quadrant: "quadrant",
  ReactionEntityType: "reactionEntityType",
  ExtendedPropertyUsedForDist: "extendedPropertyUsedForDist",
  KeyedExtendedValuesUsedForDist: "keyedExtendedValuesUsedForDist"
} as const;

type Props = {
  fillDirection: Directions;
  formName: string;
  readableItemType: ({
    upperCase,
    plural
  }: {
    upperCase?: boolean;
    plural?: boolean;
  }) => string;
};

type ValueOf<T> = T[keyof T];

type useSelectorResponse = {
  additiveMaterials: RecursiveRequired<AdditiveMaterialFragment[]>;
  breakdownPattern: BreakdownPatterns;
  breakIntoQuadrants: boolean;
  containerArrays: RecursiveRequired<ContainerArrayAdditionalFragment[]>;
  currentPlateMapIndex: number;
  extendedPropertyUsedForDist?: { name: string };
  itemType: ValueOf<typeof ItemTypes>;
  j5EntityRadio: "material" | "j5PcrReaction";
  j5EntityType: ValueOf<typeof J5EntityType>;
  j5Materials: RecursiveRequired<J5MaterialFragment[]>;
  j5PcrReactions: RecursiveRequired<J5PcrReactionFragment[]>;
  j5Reports: { id: string }[];
  keyedExtendedValuesUsedForDist: { [id: string]: { value: any } };
  lots: RecursiveRequired<LotFragment[]>;
  materials: RecursiveRequired<MaterialAdditionalFragment[]>;
  plateMaps: RecursiveRequired<PlateMap[]>;
  platesToCreate: PlateToCreateType[];
  plateWellContentType: ValueOf<typeof PlateWellContentTypes>;
  quadrant: keyof typeof quadrantToDefaultStartingPosition;
  reactionEntityType: string;
  reactionMaps: { id: string; reactionTypeCode: string }[];
  samples: RecursiveRequired<SampleAdditionalFragment[]>;
  selectedContainerFormat: {
    quadrantSize: number;
    rowCount: number;
    columnCount: number;
  };
};

interface ReduxState {
  form?: {
    [formName: string]: {
      values?: useSelectorResponse;
    };
  };
}

const valueHasSequenceSize = (
  value: Item
): value is RecursiveRequired<PlateMap | J5PcrReactionFragment> => {
  if (
    ("polynucleotideMaterialSequence" in value &&
      value.polynucleotideMaterialSequence) ||
    ("pcrProductSequence" in value && value.pcrProductSequence)
  ) {
    return true;
  }
  return false;
};

type ItemWithWellPositionsType = { wellPositions: string[] } & Item;

const extPropTypes = ["lot", "material", "additiveMaterial", "sample"];

export const PlateConfiguration = ({
  fillDirection: _fillDirection,
  formName,
  readableItemType
}: Props) => {
  const {
    additiveMaterials,
    breakIntoQuadrants,
    breakdownPattern,
    containerArrays,
    currentPlateMapIndex,
    extendedPropertyUsedForDist,
    itemType,
    j5EntityType,
    j5EntityRadio,
    j5Materials,
    j5PcrReactions,
    j5Reports,
    keyedExtendedValuesUsedForDist,
    lots,
    materials,
    plateMaps,
    platesToCreate = [],
    plateWellContentType,
    quadrant,
    reactionEntityType,
    reactionMaps,
    samples,
    selectedContainerFormat
  } = useSelector<ReduxState, useSelectorResponse>(state =>
    formValueSelector(formName)(
      state,
      ...Object.values(ItemTypes).map(model => pluralizeItemType[model]),
      ...Object.values(reduxValues)
    )
  );

  const { selectTableEntities, allOrderedEntities, selectedEntities } =
    useTableEntities<Item>("selectedSourceMaterialsTable");

  const dispatch = useDispatch();

  const getPlateOnInput = useCallback(
    (index: number) => {
      return platesToCreate[index] || {};
    },
    [platesToCreate]
  );

  const currentPlate = useMemo(() => {
    return getPlateOnInput(currentPlateMapIndex);
  }, [currentPlateMapIndex, getPlateOnInput]);

  const itemsToPlace = useMemo(() => {
    // if (usableItemType === "plateMap") {
    //   return plateMapSelectedItems.current;
    // } else {
    // Improve types here
    return getSelectedItems({
      itemType,
      breakIntoQuadrants,
      j5EntityRadio,
      j5PcrReactions,
      j5Materials,
      samples,
      reactionEntityType,
      materials,
      additiveMaterials,
      plateWellContentType,
      lots,
      containerArrays,
      plateMaps
    });
    // }
  }, [
    itemType,
    breakIntoQuadrants,
    j5EntityRadio,
    j5PcrReactions,
    j5Materials,
    samples,
    reactionEntityType,
    materials,
    additiveMaterials,
    plateWellContentType,
    lots,
    containerArrays,
    plateMaps
  ]);

  const setSelectedAliquotContainerLocation = useCallback(
    (value: any) => {
      dispatch(
        actions.ui.records.containerArray.setSelectedAliquotContainerLocation(
          value
        )
      );
    },
    [dispatch]
  );

  const clearAllPlates = useCallback(() => {
    const platesToUpdate = [...platesToCreate].map(() => ({}));
    dispatch(change(formName, "platesToCreate", platesToUpdate));
    selectTableEntities([]);
    setSelectedAliquotContainerLocation([]);
  }, [
    dispatch,
    formName,
    platesToCreate,
    selectTableEntities,
    setSelectedAliquotContainerLocation
  ]);

  const clearPlate = useCallback(() => {
    const platesToUpdate = [...platesToCreate];
    platesToUpdate[currentPlateMapIndex] = {};
    dispatch(change(formName, "platesToCreate", platesToUpdate));
    selectTableEntities([]);
    setSelectedAliquotContainerLocation([]);
  }, [
    currentPlateMapIndex,
    dispatch,
    formName,
    platesToCreate,
    selectTableEntities,
    setSelectedAliquotContainerLocation
  ]);

  const valueHasDNASequence = (value: Item) => {
    if ("polynucleotideMaterialSequence" in value) {
      return !!value.polynucleotideMaterialSequence;
    }
    return false;
  };

  const anItemHasDNASequence = useCallback(() => {
    const items = itemsToPlace;
    return items.some(valueHasDNASequence);
  }, [itemsToPlace]);

  const itemPlacedMap = useMemo(() => {
    const _itemPlacedMap: { [id: string]: true } = {};
    platesToCreate.forEach(plateMap => {
      Object.entries(plateMap).forEach(([well, plateMapItemInfo]) => {
        if (isTmpInfoContent(well, plateMapItemInfo)) return;
        const item = plateMapItemInfo.item;
        _itemPlacedMap[item.id] = true;
      });
    });
    return _itemPlacedMap;
  }, [platesToCreate]);

  const hasBarcodeAndWell = (value: Items) => {
    return value.some(item => {
      if (
        "plateBarcode" in item &&
        item.plateBarcode &&
        "well" in item &&
        item.well
      ) {
        return true;
      }
      return false;
    });
  };

  const datatableSchema = useMemo(() => {
    const basicSchema: {
      path?: string;
      displayName?: string;
      sortFn?: string[];
      render?: (value: any, row: any) => any;
      width?: number;
      type?: string;
    }[] = [{ path: "name" }];
    if (
      (j5EntityType || itemType === "j5Report") &&
      j5EntityRadio !== "material"
    ) {
      basicSchema.push({
        path: "j5Report.name",
        displayName: "DNA Assembly Report"
      });
    }
    if (anItemHasDNASequence()) {
      basicSchema.push({
        displayName: "Sequence Size",
        path: "polynucleotideMaterialSequence.size"
      });
    }
    if (hasBarcodeAndWell(itemsToPlace)) {
      basicSchema.push({
        path: "plateBarcode",
        sortFn: ["plateBarcode", "well"],
        displayName: "Current Location",
        render: (
          _,
          r: { plateBarcode: string; plateName: string; well: string }
        ) => `${r.plateBarcode || r.plateName} (${r.well})`
      });
    }
    basicSchema.push({
      path: "wellPositions",
      width: 250,
      displayName: "Well Positions for Current Plate",
      render: val => {
        return val ? val.join(", ") : "";
      }
    });
    if (itemType === "j5Report" && j5EntityRadio === "j5PcrReaction") {
      basicSchema.push({
        path: "pcrProductSequence.size",
        displayName: "Amplicon Size",
        type: "number"
      });
    }
    if (extendedPropertyUsedForDist) {
      basicSchema.push({
        displayName: extendedPropertyUsedForDist.name,
        render: (v, r) => {
          return keyedExtendedValuesUsedForDist[r.id]?.value;
        }
      });
    }
    basicSchema.push({
      path: "hasBeenPlaced",
      type: "action",
      width: 40,
      render: (v, r) => {
        if (itemPlacedMap?.[r.id]) {
          return (
            // @ts-ignore
            <Tooltip
              content={`${readableItemType({
                upperCase: true
              })} has been placed.`}
            >
              <Icon intent="success" icon="tick" />
            </Tooltip>
          );
        }
        return <></>;
      }
    });
    return basicSchema;
  }, [
    anItemHasDNASequence,
    extendedPropertyUsedForDist,
    itemPlacedMap,
    itemType,
    itemsToPlace,
    j5EntityRadio,
    j5EntityType,
    keyedExtendedValuesUsedForDist,
    readableItemType
  ]);

  const anItemHasSequenceSize = useCallback(() => {
    const items = itemsToPlace;
    return items.some(valueHasSequenceSize);
  }, [itemsToPlace]);

  const activeFilledWells = useMemo(() => {
    const alreadyFilledWells = platesToCreate[currentPlateMapIndex];
    return alreadyFilledWells || {};
  }, [currentPlateMapIndex, platesToCreate]);

  const setSelectedAliquotContainerLocations = useCallback(
    (value: any) => {
      dispatch(
        actions.ui.records.containerArray.setSelectedAliquotContainerLocations(
          value
        )
      );
    },
    [dispatch]
  );

  const addPlateMapGroup = useCallback(() => {
    const newPlates = [...platesToCreate, {}];
    setSelectedAliquotContainerLocations({
      locations: []
    });
    dispatch(change(formName, "platesToCreate", newPlates));
    dispatch(change(formName, "currentPlateMapIndex", newPlates.length - 1));
  }, [
    dispatch,
    formName,
    platesToCreate,
    setSelectedAliquotContainerLocations
  ]);

  const deletePlateMapGroup = useCallback(() => {
    const newPlates = [...platesToCreate].filter(
      (__, i) => i !== currentPlateMapIndex
    );
    change(formName, "platesToCreate", newPlates);
    if (currentPlateMapIndex >= newPlates.length) {
      change(formName, "currentPlateMapIndex", newPlates.length - 1);
    }
  }, [currentPlateMapIndex, formName, platesToCreate]);

  const aliquotContextMenu = useCallback(() => {
    const selectedLocations =
      containerArraySelectedAliquotContainerLocationsSelector(
        window.teGlobalStore.getState()
      );

    const hasAliquotsSelected = selectedLocations.some(
      (l: string) => currentPlate[l]
    );

    return (
      <Menu>
        <MenuItem
          icon="trash"
          text="Delete"
          disabled={!hasAliquotsSelected}
          onClick={() => {
            const newPlate = { ...currentPlate };
            for (const l of selectedLocations) delete newPlate[l];
            const newPlates = [...platesToCreate];
            newPlates[currentPlateMapIndex] = newPlate;
            dispatch(change(formName, "platesToCreate", newPlates));
          }}
        />
      </Menu>
    );
  }, [currentPlate, platesToCreate, currentPlateMapIndex, dispatch, formName]);

  const handleWellOnWellDrop = useCallback(
    ({
      sourceLocations,
      draggedLocation,
      droppedLocation
    }: {
      sourceLocations: string[];
      draggedLocation: string;
      droppedLocation: string;
    }) => {
      const { columnPosition: dragCol, rowPosition: dragRow } =
        getPositionFromAlphanumericLocation(draggedLocation);
      const { columnPosition: dropCol, rowPosition: dropRow } =
        getPositionFromAlphanumericLocation(droppedLocation);

      const deltaCol = dropCol - dragCol;
      const deltaRow = dropRow - dragRow;

      const newPlate = { ...currentPlate };

      const dstLocations = sourceLocations.map((srcLoc: string) => {
        delete newPlate[srcLoc];

        const { columnPosition: srcCol, rowPosition: srcRow } =
          getPositionFromAlphanumericLocation(srcLoc);

        const dstCol =
          (srcCol + deltaCol + selectedContainerFormat.columnCount) %
          selectedContainerFormat.columnCount;
        const dstRow =
          (srcRow + deltaRow + selectedContainerFormat.rowCount) %
          selectedContainerFormat.rowCount;

        const dstLoc = rowIndexToLetter(dstRow) + (dstCol + 1);
        return dstLoc;
      });

      const equal = sourceLocations.every(
        (srcLoc: string, i: number) => dstLocations[i] === srcLoc
      );
      // if they dropped into the same spot then there are no changes to do
      if (equal) {
        return;
      }
      sourceLocations.forEach((srcLoc: string, i: number) => {
        const dstLoc = dstLocations[i];
        if (currentPlate[srcLoc]) newPlate[dstLoc] = currentPlate[srcLoc];
        else delete newPlate[dstLoc];
      });

      setSelectedAliquotContainerLocations({ locations: dstLocations });
      const newPlates = [...platesToCreate];
      newPlates[currentPlateMapIndex] = newPlate;
      dispatch(change(formName, "platesToCreate", newPlates));
    },
    [
      currentPlate,
      currentPlateMapIndex,
      dispatch,
      formName,
      platesToCreate,
      selectedContainerFormat.columnCount,
      selectedContainerFormat.rowCount,
      setSelectedAliquotContainerLocations
    ]
  );

  const selectedAliquotContainerLocations = useSelector<StateType, string[]>(
    state =>
      containerArraySelectedAliquotContainerLocationsSelector(state) || []
  );

  const handleSourceMaterialDrop = useCallback(
    ({
      selectedSourceMaterials,
      droppedLocation
    }: {
      selectedSourceMaterials: any[];
      droppedLocation: string;
    }) => {
      const newPlates = [...platesToCreate];

      const fillDirection = _fillDirection.toLowerCase() as Directions;
      if (
        selectedAliquotContainerLocations &&
        selectedAliquotContainerLocations.length >= 2 &&
        selectedAliquotContainerLocations.includes(droppedLocation)
      ) {
        if (
          selectedSourceMaterials.length >
          selectedAliquotContainerLocations.length
        ) {
          return window.toastr.warning(
            `Cannot fit ${
              selectedSourceMaterials.length
            } ${readableItemType({ plural: true })} into ${
              selectedAliquotContainerLocations.length
            } locations.`
          );
        }
        const dstWellsMap = getLocationHashMapGivenWellCountAndDirection({
          containerFormat: selectedContainerFormat,
          numWells: selectedContainerFormat.quadrantSize,
          startingPosition: droppedLocation,
          direction: fillDirection,
          multiplate: false
        });
        const dstWells = Object.keys(dstWellsMap);
        const sortedSelection = [...selectedAliquotContainerLocations].sort(
          (a, b) => dstWells.indexOf(a) - dstWells.indexOf(b)
        );
        const newPlate = { ...currentPlate };
        sortedSelection.forEach((loc, i) => {
          const material =
            selectedSourceMaterials[i % selectedSourceMaterials.length];
          if (material) {
            newPlate[loc] = { ...newPlate[loc], item: material };
          }
        });
        newPlates[currentPlateMapIndex] = newPlate;
      } else {
        const dstWellsMaps = getLocationHashMapGivenWellCountAndDirection({
          containerFormat: selectedContainerFormat,
          numWells: selectedSourceMaterials.length,
          startingPosition: droppedLocation,
          direction: breakIntoQuadrants ? "right" : fillDirection, // left, right, up, down
          multiplate: true,
          quadrant: breakIntoQuadrants ? quadrant : undefined,
          breakdownPattern: breakIntoQuadrants ? breakdownPattern : undefined
        }) as { [position: string]: true }[];
        let capacity = selectedContainerFormat.quadrantSize;
        if (breakIntoQuadrants) {
          capacity = capacity / 4;
        }

        dstWellsMaps.forEach((dstWellsMap, i) => {
          const materialsOnPlates = selectedSourceMaterials.slice(
            i * capacity,
            (i + 1) * capacity
          );

          // Although the keys returned technically have no order guarantee, they
          // tend to be the same order as they were put in.
          const dstWells = Object.keys(dstWellsMap);

          const newPlate = { ...getPlateOnInput(currentPlateMapIndex + i) };

          dstWells.forEach((loc, j) => {
            newPlate[loc] = { ...newPlate[loc], item: materialsOnPlates[j] };
          });

          newPlates[currentPlateMapIndex + i] = newPlate;
        });

        dispatch(
          change(
            formName,
            "currentPlateMapIndex",
            currentPlateMapIndex + dstWellsMaps.length - 1
          )
        );

        setSelectedAliquotContainerLocations({
          locations: Object.keys(dstWellsMaps[dstWellsMaps.length - 1])
        });
      }
      dispatch(change(formName, "platesToCreate", newPlates));
    },
    [
      _fillDirection,
      breakIntoQuadrants,
      breakdownPattern,
      currentPlate,
      currentPlateMapIndex,
      dispatch,
      formName,
      getPlateOnInput,
      platesToCreate,
      quadrant,
      readableItemType,
      selectedAliquotContainerLocations,
      selectedContainerFormat,
      setSelectedAliquotContainerLocations
    ]
  );

  const distributeAllItems = useCallback(
    async (vals?: FormData) => {
      const { fillDirection, distributionPattern } = vals ?? {
        fillDirection: undefined,
        distributionPattern: undefined
      };

      const allItemsInOrder = allOrderedEntities ? [...allOrderedEntities] : [];

      if (distributionPattern) {
        const aliquotContainers = generateEmptyWells(selectedContainerFormat);
        const aliquotContainer2dArray = plateTo2dAliquotContainerArray({
          aliquotContainers: aliquotContainers,
          containerArrayType: {
            containerFormat: selectedContainerFormat
          }
        });
        const newPlatesToCreate: PlateToCreateType[] = [];
        let plateIndex = 0;
        const distributeItemGroup = () => {
          range(selectedContainerFormat.rowCount / 2).forEach(repRowPos => {
            range(selectedContainerFormat.columnCount / 2).forEach(
              repColPos => {
                const block = getBlockOf2dArray(
                  aliquotContainer2dArray,
                  2,
                  2,
                  repColPos,
                  repRowPos,
                  true,
                  true
                );
                blockToAliquotArray(block, distributionPattern).forEach(ac => {
                  newPlatesToCreate[plateIndex] =
                    newPlatesToCreate[plateIndex] || {};
                  const plate = newPlatesToCreate[plateIndex];
                  const location = getAliquotContainerLocation(ac);

                  const item = allItemsInOrder.shift();
                  if (ac && item) {
                    plate[location] = { item };
                  }
                });
              }
            );
          });
        };
        while (allItemsInOrder.length > 0) {
          distributeItemGroup();
          plateIndex++;
        }
        dispatch(change(formName, "platesToCreate", newPlatesToCreate));
      } else {
        let sortedWellList:
          | string[]
          | {
              columnPosition: number;
              rowPosition: number;
            }[]
          | undefined;

        let quadrantPositionMap: { [position: string]: true } | undefined;
        if (breakIntoQuadrants) {
          quadrantPositionMap = getLocationHashMapGivenWellCountAndDirection({
            containerFormat: selectedContainerFormat,
            numWells:
              selectedContainerFormat.rowCount *
              selectedContainerFormat.columnCount,
            startingPosition: "A1",
            direction: "right",
            quadrant,
            breakdownPattern,
            multiplate: false
          }) as { [position: string]: true };
          sortedWellList = Object.keys(quadrantPositionMap);
        } else {
          sortedWellList = getSortedWellsForPlate(
            selectedContainerFormat,
            fillDirection
          );
        }

        const newPlatesToCreate: PlateToCreateType[] = [];
        while (allItemsInOrder.length) {
          let unfilledPlate = newPlatesToCreate.find(
            p =>
              !isPlateObjectFull(
                p,
                selectedContainerFormat,
                quadrantPositionMap
              )
          );
          if (!unfilledPlate) {
            unfilledPlate = {};
            newPlatesToCreate.push(unfilledPlate);
          }
          for (const well of sortedWellList) {
            const location =
              typeof well === "string"
                ? well
                : getAliquotContainerLocation(well);
            if (!unfilledPlate[location]) {
              const itemToPlace = allItemsInOrder.shift();
              if (!itemToPlace) break;
              unfilledPlate[location] = { item: itemToPlace };
            }
          }
        }

        dispatch(change(formName, "platesToCreate", newPlatesToCreate));
      }
    },
    [
      allOrderedEntities,
      selectedContainerFormat,
      dispatch,
      formName,
      breakIntoQuadrants,
      quadrant,
      breakdownPattern
    ]
  );

  const aliquotContainers = useMemo(
    () => generateEmptyWells(selectedContainerFormat),
    [selectedContainerFormat]
  );

  const isWellDisabled = useMemo(() => {
    if (breakIntoQuadrants) {
      const activeLocationsArray = getActiveLocationsForQuadrant({
        containerFormat: selectedContainerFormat,
        aliquotContainers,
        breakdownPattern: breakdownPattern || "Z",
        quadrant: quadrant || 0
      });
      return (_: any, location: string) => {
        return !activeLocationsArray.includes(location);
      };
    }
    return;
  }, [
    aliquotContainers,
    breakIntoQuadrants,
    breakdownPattern,
    quadrant,
    selectedContainerFormat
  ]);

  const itemsWithWellPositions = useMemo(() => {
    const itemToWellMap = makeItemToWellMapForPlateMap(currentPlate);
    return itemsToPlace.map(item => {
      return {
        ...item,
        wellPositions: itemToWellMap[item.id] || []
      };
    });
  }, [currentPlate, itemsToPlace]);

  const itemName = readableItemType({ plural: true });

  const numberOfItemsPlaced = Object.keys(itemPlacedMap).length;

  // @ts-ignore
  const itemModel: keyof typeof modelTypeMap = itemsToPlace[0]?.__typename;
  const menuItems = useMemo(() => {
    const _menuItems = [
      <MenuItem
        key="distributeByTableOrder"
        onClick={() => {
          if (breakIntoQuadrants) {
            distributeAllItems();
          } else {
            showDialog({
              ModalComponent: ChooseSimpleDistributeOptions,
              modalProps: {
                containerFormat: selectedContainerFormat,
                handleDistribute: values => {
                  distributeAllItems(values);
                }
              }
            });
          }
          selectTableEntities(itemsToPlace);
        }}
        text="Table Order"
      />,
      anItemHasSequenceSize() && (
        <MenuItem
          key="distributeBySequenceLength"
          onClick={() =>
            showDialog({
              ModalComponent: ChooseDistributeMaterialsByLengthOptions,
              modalProps: {
                handleDistribute: ({
                  ranges,
                  fillDirection
                }: {
                  ranges: { min: number; max: number }[];
                  fillDirection: Directions;
                }) => {
                  window.localStorage.setItem(
                    "distributeCreatePlateMapRanges",
                    JSON.stringify(ranges)
                  );

                  const sortedWells = getSortedWellsForPlate(
                    selectedContainerFormat,
                    fillDirection
                  );
                  const newPlatesToCreate: PlateToCreateType[] = [];
                  const itemGroups: Item[][] = ranges.map(() => []);
                  const getItemSize = (item: Item): number => {
                    if ("polynucleotideMaterialSequence" in item)
                      return (
                        item.polynucleotideMaterialSequence as { size: number }
                      ).size;
                    else if ("pcrProductSequence" in item)
                      return (item.pcrProductSequence as { size: number }).size;
                    throw new Error("Item does not have a sequence size.");
                  };
                  itemsToPlace.forEach(item => {
                    const size = getItemSize(item);
                    if (size) {
                      const groupIndex = ranges.findIndex(r => {
                        return size >= r.min && size <= r.max;
                      });
                      if (groupIndex > -1) {
                        itemGroups[groupIndex].push(item);
                      }
                    }
                  });
                  if (itemGroups.every(g => !g.length)) {
                    return window.toastr.error("No items fit into ranges.");
                  }
                  itemGroups.forEach(group => {
                    // because there could be too many for a single plate we need to split
                    const groupsOfGroup = chunk(
                      group.sort(
                        (a: any, b: any) => getItemSize(a) - getItemSize(b)
                      ),
                      selectedContainerFormat.rowCount *
                        selectedContainerFormat.columnCount
                    );
                    groupsOfGroup.forEach(group => {
                      const newPlate: { [location: string]: any } = {};
                      newPlatesToCreate.push(newPlate);
                      group.forEach((item, i) => {
                        const location = getAliquotContainerLocation(
                          sortedWells[i]
                        );
                        newPlate[location] = { item };
                      });
                    });
                  });
                  dispatch(
                    change(formName, "platesToCreate", newPlatesToCreate)
                  );
                }
              }
            })
          }
          text="Sequence Length"
        />
      ),
      itemModel && extPropTypes.includes(itemModel) && (
        <MenuItem
          key="distributeByExtendedProperty"
          onClick={() => {
            showDialog({
              ModalComponent: ChooseDistributeByExtendedPropertyOptions,
              modalProps: {
                model: itemModel,
                handleDistribute: async ({
                  extendedProperty,
                  fillDirection
                }) => {
                  const sortedWells = getSortedWellsForPlate(
                    selectedContainerFormat,
                    fillDirection
                  );
                  const newPlatesToCreate: PlateToCreateType[] = [];
                  type extProp = {
                    id: string;
                    value: any;
                    [itemModelId: string]: string;
                  };
                  const extStringVals = (await safeQuery<extProp>(
                    ["extendedStringValueView", `id value ${itemModel}Id`],
                    {
                      variables: {
                        filter: {
                          extendedPropertyId: extendedProperty.id,
                          [`${itemModel}Id`]: allOrderedEntities?.map(i => i.id)
                        }
                      }
                    }
                  )) as extProp[];

                  const keyedVals: {
                    [itemModelId: string]: extProp;
                  } = keyBy(extStringVals, `${itemModel}Id`);

                  // keyed by value
                  const itemGroups: { [val: string]: any[] } = {};
                  allOrderedEntities?.forEach(item => {
                    const val = keyedVals[item.id]?.value;
                    if (val) {
                      itemGroups[val] = itemGroups[val] || [];
                      itemGroups[val].push(item);
                    } else {
                      itemGroups["no val"] = itemGroups["no val"] || [];
                      itemGroups["no val"].push(item);
                    }
                  });
                  Object.keys(itemGroups)
                    .filter(key => key !== "no val")
                    .concat("no val") // no val should be last
                    .forEach(key => {
                      const groupsOfGroup = chunk(
                        itemGroups[key],
                        selectedContainerFormat.rowCount *
                          selectedContainerFormat.columnCount
                      );
                      groupsOfGroup.forEach(group => {
                        const newPlate: { [location: string]: any } = {};
                        newPlatesToCreate.push(newPlate);
                        group.forEach((item, i) => {
                          const location = getAliquotContainerLocation(
                            sortedWells[i]
                          );
                          newPlate[location] = { item };
                        });
                      });
                    });
                  dispatch(
                    change(formName, "platesToCreate", newPlatesToCreate)
                  );
                  dispatch(
                    change(
                      formName,
                      "extendedPropertyUsedForDist",
                      extendedProperty
                    )
                  );
                  dispatch(
                    change(
                      formName,
                      "keyedExtendedValuesUsedForDist",
                      keyedVals
                    )
                  );
                }
              }
            });
          }}
          text="Extended Property"
        />
      ),
      (itemModel === "sample" || itemModel === "material") && (
        <MenuItem
          key="distributeByAssemblyReport"
          onClick={() =>
            showDialog({
              ModalComponent: DistributeByAssemblyReportDialog,
              modalProps: {
                items: itemsToPlace,
                selectedJ5Reports:
                  itemType === "j5Report" ? j5Reports : undefined,
                handleDistribute: async ({
                  fillDirection,
                  reportOrder,
                  itemIdToReports
                }) => {
                  const sortedWells = getSortedWellsForPlate(
                    selectedContainerFormat,
                    fillDirection
                  );
                  const newPlatesToCreate: PlateToCreateType[] = [];
                  const items = allOrderedEntities!;
                  const reportToIndex: { [id: string]: number } = {};
                  reportOrder.forEach((id, i) => {
                    reportToIndex[id] = i;
                  });
                  const itemGroups: any[][] = [];
                  items.forEach(item => {
                    const reports = itemIdToReports[item.id];
                    if (reports) {
                      const indexToUse = min(
                        reports.map(r => reportToIndex[r.id])
                      ) as number;
                      itemGroups[indexToUse] = itemGroups[indexToUse] || [];
                      itemGroups[indexToUse].push(item);
                    }
                  });
                  itemGroups.forEach(group => {
                    // because there could be too many for a single plate we need to split
                    const groupsOfGroup = chunk(
                      group,
                      selectedContainerFormat.rowCount *
                        selectedContainerFormat.columnCount
                    );
                    groupsOfGroup.forEach(group => {
                      const newPlate: { [location: string]: any } = {};
                      newPlatesToCreate.push(newPlate);
                      group.forEach((item, i) => {
                        const location = getAliquotContainerLocation(
                          sortedWells[i]
                        );
                        newPlate[location] = { item };
                      });
                    });
                  });
                  dispatch(
                    change(formName, "platesToCreate", newPlatesToCreate)
                  );
                }
              }
            })
          }
          text="Assembly Report"
        />
      )
    ];

    if (
      canDistributeTemperatureBlocks({
        reactionEntityType,
        itemType,
        reactionMaps
      })
    ) {
      _menuItems.push(
        <MenuItem
          key="distributeIntoTemperatureBlocks"
          onClick={() =>
            showDialog({
              ModalComponent: ChooseDistributeIntoTemperatureBlocksOptions,
              modalProps: {
                containerFormat: selectedContainerFormat,
                handleDistribute: async ({
                  // distributeMethod,
                  temperatureZoneOrientation,
                  zonesPerPlate
                }) => {
                  const reactionsWithInfo =
                    (await safeQuery<ReactionsWithTempInfoFragment>(
                      reactionsWithTempInfoFragment,
                      {
                        variables: {
                          filter: {
                            reactionMapId: reactionMaps.map(r => r.id),
                            "reactionOutputs.outputMaterialId": materials.map(
                              m => m.id
                            )
                          }
                        }
                      }
                    )) as RecursiveRequired<ReactionsWithTempInfoFragment>[];
                  const reactionIdToMaterialId: {
                    [reactionId: string]: string;
                  } = {};
                  const mockPcrReactions: {
                    id: number;
                    oligoMeanTm: number;
                    oligoDeltaTm: number;
                    forwardPrimer: { sequenceId: number } | {};
                    reversePrimer: { sequenceId: number } | {};
                  }[] = [];
                  const keyedMaterials = keyBy(materials, "id");
                  reactionsWithInfo.forEach(r => {
                    reactionIdToMaterialId[r.id] =
                      r.reactionOutputs[0].outputMaterialId;
                    if (
                      !r.reactionDetails?.oligoMeanTm ||
                      !r.reactionDetails?.oligoDeltaTm
                    ) {
                      throw new Error(
                        "All reactions must have reaction detail info."
                      );
                    }
                    mockPcrReactions.push({
                      id: r.id,
                      ...r.reactionDetails
                    });
                  });
                  const plateMaps = distributeIntoTemperatureBlocks({
                    selectedPcrReactions: mockPcrReactions,
                    containerFormat: selectedContainerFormat,
                    zonesPerPlate,
                    temperatureZoneOrientation
                  });
                  const platesToCreate: PlateToCreateType[] = [];
                  let itemFound = false;
                  plateMaps.forEach(pm => {
                    const newPlate: {
                      [location: string]: { item: any };
                    } & {
                      __tempInfo?: {
                        temperatureZoneOrientation: "vertical" | "horizontal";
                        temperatureZones?: number[];
                      };
                    } = {};
                    pm.plateMapItems.forEach(pmi => {
                      const location = getAliquotContainerLocation(pmi);
                      const item =
                        keyedMaterials[reactionIdToMaterialId[pmi.id]];
                      if (item) {
                        itemFound = true;
                        newPlate[location] = { item };
                      }
                    });
                    newPlate.__tempInfo = {
                      temperatureZoneOrientation: pm.temperatureZoneOrientation,
                      temperatureZones: pm.temperatureZones
                    };
                    platesToCreate.push(newPlate);
                  });
                  if (!itemFound) {
                    throw new Error(
                      "No valid materials found for distribution."
                    );
                  }
                  dispatch(change(formName, "platesToCreate", platesToCreate));
                  dispatch(change(formName, "currentPlateMapIndex", 0));
                }
              }
            })
          }
          text="Distribute Into Temperature Blocks"
        />
      );
    }
    return _menuItems;
  }, [
    allOrderedEntities,
    anItemHasSequenceSize,
    breakIntoQuadrants,
    dispatch,
    distributeAllItems,
    formName,
    itemModel,
    itemType,
    itemsToPlace,
    j5Reports,
    materials,
    reactionEntityType,
    reactionMaps,
    selectTableEntities,
    selectedContainerFormat
  ]);

  const onSelectRows = useCallback(
    (selectedEntities: ItemWithWellPositionsType[]) => {
      const selectedWelPositions = selectedEntities.reduce(
        (acc: string[], entity: any) => [...acc, ...entity.wellPositions],
        []
      );
      if (
        selectedWelPositions.length &&
        selectedWelPositions.some(
          row => !selectedAliquotContainerLocations.includes(row)
        )
      ) {
        setSelectedAliquotContainerLocations({
          locations: selectedWelPositions
        });
      }
    },
    [selectedAliquotContainerLocations, setSelectedAliquotContainerLocations]
  );

  return (
    <>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header={`Distribute ${readableItemType({
            upperCase: true,
            plural: true
          })}`}
          helper={
            <span>
              Below is a list of {itemName} selected in the previous step. Drag
              the {itemName} onto the plate to the right and organize them as
              you see fit. You can shift click to select multiple {itemName} to
              drag at once.
              <br />
              <br />
              You can select multiple wells using {isMac ? "cmd" : "ctrl"} /
              shift click and drop {itemName} into them to create a custom
              format.
            </span>
          }
          width="100%"
        />
        <br />
        <div className="tg-flex justify-space-between">
          <div style={{ width: "50%", display: "table" }}>
            <DropdownButton
              intent="primary"
              text="Distribute By"
              menu={<Menu>{menuItems}</Menu>}
            />
            <DataTable
              style={{ maxHeight: 500 }}
              formName="selectedSourceMaterialsTable"
              entities={itemsWithWellPositions}
              schema={datatableSchema}
              withDisplayOptions
              onDeselect={() => onSelectRows([])}
              onSingleRowSelect={(selectedEntity: ItemWithWellPositionsType) =>
                onSelectRows([selectedEntity])
              }
              onMultiRowSelect={(
                selectedEntities: ItemWithWellPositionsType[]
              ) => onSelectRows(selectedEntities)}
              cellRenderer={{
                name: (name: string, material: { id: string }) => (
                  <DraggableMaterialHandle
                    name={name}
                    selectedSourceMaterials={Object.values(
                      selectedEntities || {}
                    )
                      .sort((a, b) => a.rowIndex - b.rowIndex)
                      .map(e => e.entity)}
                    material={material}
                  />
                )
              }}
            >
              {numberOfItemsPlaced > 0 && (
                <div
                  style={{
                    color:
                      numberOfItemsPlaced === itemsToPlace.length
                        ? Colors.GREEN3
                        : Colors.GOLD3
                  }}
                >
                  {`${numberOfItemsPlaced} of ${itemsToPlace.length} ${itemName} have
                  been placed.`}
                </div>
              )}
            </DataTable>
          </div>
          <div>
            <div
              className="tg-flex align-flex-end justify-flex-end"
              style={{ marginRight: 20, marginBottom: 15 }}
            >
              {breakIntoQuadrants && (
                <div style={{ marginRight: 10 }}>
                  <SelectField
                    className="tg-no-form-group-margin"
                    name="quadrant"
                    label="Quadrant"
                    defaultValue={0}
                    options={[
                      { label: "One", value: 0 },
                      { label: "Two", value: 1 },
                      { label: "Three", value: 2 },
                      { label: "Four", value: 3 }
                    ]}
                  />
                </div>
              )}
              <div style={{ display: "flex", marginRight: 5 }}>
                <Button onClick={clearPlate} intent={Intent.WARNING}>
                  Clear Plate
                </Button>
                <Button
                  style={{ marginLeft: 5 }}
                  onClick={clearAllPlates}
                  intent={Intent.DANGER}
                >
                  Clear All Plates
                </Button>
              </div>
              <SelectField
                className="tg-no-form-group-margin tg-no-form-label"
                name="currentPlateMapIndex"
                options={times(platesToCreate.length || 1, i => ({
                  label: "Plate Map " + (i + 1),
                  value: i
                }))}
                style={{ minWidth: 200 }}
                defaultValue={0}
              />
              <div className={Classes.BUTTON_GROUP} style={{ marginLeft: 15 }}>
                {/* @ts-ignore */}
                <Tooltip content="Add plate map">
                  <Button
                    icon="add"
                    intent={Intent.SUCCESS}
                    onClick={addPlateMapGroup}
                  />
                </Tooltip>
                {/* @ts-ignore */}
                <Tooltip
                  content="Remove active plate map"
                  disabled={platesToCreate.length <= 1}
                >
                  <Button
                    icon="trash"
                    intent={Intent.DANGER}
                    disabled={platesToCreate.length <= 1}
                    onClick={deletePlateMapGroup}
                  />
                </Tooltip>
              </div>
            </div>
            <PlateMapPlateNoContext
              isEditable
              {...currentPlate?.__tempInfo}
              isWellDisabled={isWellDisabled}
              activeFilledWells={activeFilledWells}
              selectedAliquotContainerLocations={
                selectedAliquotContainerLocations
              }
              setSelectedAliquotContainerLocation={
                setSelectedAliquotContainerLocation
              }
              aliquotContainers={aliquotContainers}
              containerFormat={selectedContainerFormat}
              onDrop={handleWellOnWellDrop}
              onSourceMaterialDrop={handleSourceMaterialDrop}
              aliquotContextMenu={aliquotContextMenu}
            />
          </div>
        </div>
      </div>
      <CustomMaterialDragLayer />
    </>
  );
};
