/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import {
  DialogFooter,
  wrapDialog,
  BlueprintError,
  FileUploadField
} from "@teselagen/ui";
import TagField from "../../../../src-shared/TagField";
import { getDownloadTemplateFileHelpers } from "../../../../src-shared/components/DownloadTemplateFileButton";
import { throwFormError } from "../../../../src-shared/utils/formUtils";
import withQuery from "../../../../src-shared/withQuery";
import { hideDialog } from "../../../../src-shared/GlobalDialog";
import handleUserRequestImport from "../../../../../tg-iso-lims/src/handleUserRequestImport";

function UploadUserRequests(props) {
  const {
    hideModal,
    handleSubmit,
    customerRequestStatusTypes,
    requestTypes,
    submitting,
    error,
    refetch
  } = props;
  async function onSubmit(values) {
    try {
      await handleUserRequestImport({
        userRequestFile: values.userRequestFile,
        tags: values.tags
      });
      await refetch();
      hideDialog();
    } catch (error) {
      console.error(`error:`, error);
      throwFormError(error.message);
    }
  }

  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        <FileUploadField
          accept={getDownloadTemplateFileHelpers({
            fileName: "user_requests",
            validateAgainstSchema: {
              fields: [
                {
                  path: "NAME",
                  description: "The name of the user request",
                  isRequired: true,
                  example: "User Request A"
                },
                {
                  path: "REQUEST_TYPE",
                  description: "The type of the user request",
                  isRequired: true,
                  type: "dropdown",
                  values: requestTypes.map(({ name }) => name)
                },
                {
                  path: "DESCRIPTION",
                  description: "The description of the user request",
                  example: "Design a fermentation run"
                },
                {
                  path: "DUE_DATE",
                  description: "Date the user request is due",
                  isRequired: true,
                  example: "1/1/2027"
                },
                {
                  path: "STATUS",
                  description: "Status of request",
                  isRequired: true,
                  type: "dropdown",
                  values: customerRequestStatusTypes.map(({ name }) => name)
                },
                {
                  path: "PRIMARY_USER",
                  isRequired: true,
                  description:
                    "The primary user associated with the user request"
                },
                {
                  path: "TEAM_MEMBERS",
                  description:
                    "The team members associated with the user request"
                },
                {
                  path: "STRAINS",
                  description: "Strains to associate with user request"
                },
                {
                  path: "SEQUENCES",
                  description: "Sequences to associate with user request"
                }
              ]
            }
          })}
          fileLimit={1}
          isRequired
          name="userRequestFile"
          label="Upload User Requests"
        />
        <TagField />
        <BlueprintError error={error} />
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
      />
    </React.Fragment>
  );
}

export default compose(
  wrapDialog({
    title: "Upload User Requests"
  }),
  reduxForm({
    form: "uploadUserRequests"
  }),
  withQuery(["customerRequestStatusType", "code name"], {
    isPlural: true,
    showLoading: true,
    inDialog: true
  }),
  withQuery(["requestType", "id name"], {
    variables: {
      pageSize: 10000
    },
    isPlural: true,
    showLoading: true,
    inDialog: true
  })
)(UploadUserRequests);
