/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import {
  DialogFooter,
  FileUploadField,
  BlueprintError,
  wrapDialog
} from "@teselagen/ui";

import shortid from "shortid";
import { throwFormError } from "../../../src-shared/utils/formUtils";
import { safeUpsert } from "../../../src-shared/apolloMethods";
import isValidPositiveInteger from "../../../../tg-iso-shared/src/utils/isValidPositiveInteger";
import { getDownloadTemplateFileHelpers } from "../../../src-shared/components/DownloadTemplateFileButton";
import { validateNameCsvTableUniqueAsync } from "../../components/AppSettings/validateNameCsvTableUniqueAsync";

const UploadContainerFormats = ({
  refetch,
  hideModal,
  handleSubmit,
  submitting,
  error
}) => {
  const onSubmit = async values => {
    try {
      const newContainerFormats = [];
      for (const row of values.containerFormatsFile[0].parsedData) {
        const { name, "label type": labelType } = row;
        let { rows, columns } = row;
        rows = Number(rows);
        columns = Number(columns);

        newContainerFormats.push({
          code: shortid(),
          name,
          rowCount: rows,
          columnCount: columns,
          quadrantSize: rows * columns,
          is2DLabeled: labelType === "alphanumeric"
        });
      }
      await safeUpsert("containerFormat", newContainerFormats, {
        idAs: "code",
        forceCreate: true
      });
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      throwFormError(error.message || `Error uploading container formats.`);
    }
  };

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <FileUploadField
          isRequired
          fileLimit={1}
          accept={getDownloadTemplateFileHelpers({
            fileName: "containerFormats",
            validateAgainstSchema: {
              ...validateNameCsvTableUniqueAsync({
                model: "containerFormat",
                isCodeModel: true
              }),
              fields: [
                {
                  path: "name",
                  description: "Required. Name of the plate",
                  example: "Plate_ABC",
                  isUnique: true,
                  isRequired: true
                },
                {
                  path: "rows",
                  type: "number",
                  description: "Required. Number of rows in the plate",
                  example: "8",
                  isRequired: true,
                  validate: num => {
                    if (!isValidPositiveInteger(num)) {
                      return "Row count must be a positive integer.";
                    }
                    if (num > 48) {
                      return "Row count must be less than 48.";
                    }
                  }
                },
                {
                  path: "columns",
                  type: "number",
                  description: "Required. Number of columns in the plate",
                  example: "12",
                  isRequired: true,
                  validate: num => {
                    if (!isValidPositiveInteger(num)) {
                      return "Column count must be a positive integer.";
                    }
                    if (num > 48) {
                      return "Column count must be less than 48.";
                    }
                  }
                },
                {
                  path: "label type",
                  description:
                    "Required. Type of label for each well. Choose one: alphanumeric or index",
                  type: "dropdown",
                  values: ["alphanumeric", "index"],
                  example: "alphanumeric",
                  isRequired: true
                }
              ]
            }
          })}
          name="containerFormatsFile"
        />
        <BlueprintError error={error} />
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
};

export default compose(
  wrapDialog({
    title: "Upload Container Formats"
  }),
  reduxForm({
    form: "uploadContainerFormats"
  })
)(UploadContainerFormats);
