/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useMemo } from "react";
import { DataTable, useTableParams } from "@teselagen/ui";
import { assemblyPieceViewFragment } from "../../../../../graphql/fragments/constructSelectionJ5ReportFragment";
import { Button } from "@blueprintjs/core";
import { pull } from "lodash";
import useTgQuery from "../../../../../../src-shared/apolloUseTgQuery";
import { useFormValue } from "../../../../../../src-shared/hooks/useFormValue";

const schema = {
  model: "assemblyPieceView",
  fields: [
    {
      width: 40,
      resizeable: false,
      type: "action",
      path: "trash"
    },
    "name"
  ]
};

const SelectedAssemblypieceTable = ({ change, toolSchema: { code } }) => {
  const finalizedAPIds = useFormValue(code, "finalizedAPIds");
  const additionalFilter = useCallback(
    (_, qb) => {
      const filter = {
        id: finalizedAPIds || []
      };
      qb.whereAll(filter);
    },
    [finalizedAPIds]
  );

  const { tableParams: _tableParams, variables } = useTableParams({
    formName: "selectedAssemblyPieceTable",
    urlConnected: false,
    schema,
    additionalFilter
  });

  const { entities, loading, refetch } = useTgQuery(assemblyPieceViewFragment, {
    isPlural: true,
    variables
  });

  const tableParams = useMemo(() => {
    if (!loading && entities && entities.length) {
      return {
        ..._tableParams,
        isLoading: loading,
        entities,
        entityCount: entities.length,
        fragment: assemblyPieceViewFragment,
        onRefresh: refetch,
        variables
      };
    }
    return _tableParams;
  }, [_tableParams, entities, loading, refetch, variables]);

  const clearPiece = useCallback(
    r => {
      change("finalizedAPIds", pull(finalizedAPIds || [], r.id));
    },
    [change, finalizedAPIds]
  );

  return (
    <DataTable
      {...tableParams}
      noSelect
      compact
      cellRenderer={{
        trash: (v, r) => {
          return (
            <Button
              minimal
              small
              intent="danger"
              icon="trash"
              onClick={() => clearPiece(r)}
            />
          );
        }
      }}
      className="tg-assembly-pieces-selected"
      maxHeight={400}
      style={{
        height: 400
      }}
      keepDirtyOnReinitialize
    />
  );
};

export default SelectedAssemblypieceTable;
