/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Button, Icon } from "@blueprintjs/core";
import React from "react";
import { withRouter } from "react-router-dom";
import { createPortal } from "react-dom";
import AssignDefaultsModal from "./AssignDefaultsModal";
import { showStackedDialog } from "../StackedDialog";

const AssignDefaultsModeUi = ({
  history,
  inAssignDefaultsMode,
  setAssignDefaultsMode
}) => {
  if (!inAssignDefaultsMode) return null;

  return createPortal(
    <>
      <div
        className="tg-assign-defaults-mode"
        style={{
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 100000,
          position: "absolute",
          border: "3px solid yellow",
          pointerEvents: "none"
        }}
      />
      <div
        style={{
          bottom: 0,
          right: 0,

          zIndex: 100000,
          position: "absolute"
        }}
      >
        <Button
          onClick={() => {
            history.push("/settings/default-values");
            setAssignDefaultsMode(false);
          }}
          style={{
            color: "black",
            background: "yellow",
            border: "0px solid yellow",
            borderRadius: "0px",
            boxShadow: "-25px 0 0 -23px grey, 0 25px 0 -23px grey",
            marginRight: 3
          }}
          rightIcon={<Icon icon="settings" color="black" />}
        />

        <Button
          onClick={() => setAssignDefaultsMode(false)}
          style={{
            color: "black",
            background: "yellow",
            border: "0px solid yellow",
            borderRadius: "0px",
            boxShadow: "-25px 0 0 -23px grey, 0 25px 0 -23px grey"
          }}
          rightIcon={<Icon icon="cross" color="black" />}
        >
          Leave Assign Defaults Mode
        </Button>
      </div>
    </>,
    document.body
  );
};

export default withRouter(AssignDefaultsModeUi);

window.__showAssignDefaultValueModal = props => {
  showStackedDialog({
    modalProps: {
      ...props,
      doNotRemoveOtherDialogs: true,
      dialogProps: {
        title: `Assign Default ${
          props.generateDefaultValue.label || props.generateDefaultValue.name
        }`
      }
    },
    ModalComponent: AssignDefaultsModal
  });
};
