/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useCallback } from "react";
import { DataTable, CollapsibleCard, withTableParams } from "@teselagen/ui";
import routeDoubleClick from "../../../../src-shared/utils/routeDoubleClick";
import { compose, withProps } from "recompose";
import withQuery from "../../../../src-shared/withQuery";
import gql from "graphql-tag";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";

function RegisteredSamplesCard({ tableParams }) {
  return (
    <CollapsibleCard title="Samples">
      <DataTable {...tableParams} noPadding onDoubleClick={routeDoubleClick} />
    </CollapsibleCard>
  );
}
const schema = {
  model: "sample",
  fields: [
    {
      displayName: "Name",
      path: "name"
    },
    {
      displayName: "Sample Type",
      path: "sampleType.name"
    },
    dateModifiedColumn
  ]
};

const fragment = gql`
  fragment materialRecordSamplesFragment on sample {
    id
    name
    sampleType {
      code
      name
    }
    updatedAt
  }
`;

export default compose(
  withProps(({ materialId }) => {
    // Added while #12943 is not ready
    const additionalFilter = useCallback(
      (_, qb) => {
        qb.whereAll({
          materialId
        });
      },
      [materialId]
    );
    return { additionalFilter };
  }),
  withTableParams({
    urlConnected: false,
    formName: "materialRecordSamplesCard",
    schema
  }),
  withQuery(fragment, {
    isPlural: true
  })
)(RegisteredSamplesCard);
