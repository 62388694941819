/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { showConfirmationDialog, showProgressToast } from "@teselagen/ui";
import { inDevelopment } from "./utils/inDevelopment";
import { CurrentUser } from "./context/CurrentUserContext";
import { AxiosResponse } from "axios";

/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
interface AppGlobals {
  currentUser?: CurrentUser;
  // refetch current app user
  refetchCurrentUser?: () => void;
  tgApolloClient?: Object;
  logout?: () => void;
  // The id of the active record view that is open
  activeRecordId?: String;
  // react router history
  history?: { push: (path: string) => void };
  setActiveLabId?: (labId: string) => void;
}

export type UndefinedOrDisabledToolkitInfo =
  | undefined
  | {
      toolkitName: string;
      disabledToolkitUrl: string;
    };

interface triggerIntegrationRequestObj {
  endpointId: number;
  data: any;
  params: any;
  method: string;
}

type WindowApi = {
  request: <T = any>(options: any) => Promise<AxiosResponse<T>>;
  post: <T = any>(url: string, data?: any) => Promise<AxiosResponse<T>>;
  delete: <T = any>(url: string, data?: any) => Promise<AxiosResponse<T>>;
  (options: any): Promise<any>;
};

interface tgApiRequestObj {
  method: "POST" | "PUT" | "GET" | "DELETE";
  url: string;
  data?: object;
  headers?: object;
}

declare global {
  interface Window {
    __appGlobals: any;
    showConfirmationDialog: (options: any) => Promise<boolean>;
    showProgressToast: (
      message: string,
      value: number,
      key: string,
      opts: { [key: string]: string | number }
    ) => void;
    simulateLatencyOverride?: string;
    teGlobalStore: {
      getState: () => any;
      dispatch: (action: any) => void;
    };
    toastr: {
      error: (message: any, opts?: { timeout?: number }) => void;
      success: (message: any, opts?: { timeout?: number }) => void;
      warning: (message: any, opts?: { timeout?: number }) => void;
    };

    /**
     * Custom wrapper around axios request that adds appropriate headers for hitting our backend
     *
     * @example
     * await window.serverApi.request({
        method: "POST",
        url: "/execute-worklist",
        data: {
          worklistId
        }
      });
     */
    serverApi: WindowApi;

    /**
     * Request to teselagen's API. Adds appropriate API headers.
     *
     * @example
     * await window.tgApi.request({
        method: "POST",
        url: "/execute-worklist",
        data: {
          worklistId
        }
      });
     */
    tgApi<T = any>(
      options: tgApiRequestObj
    ): Promise<{
      error: any;
      data?: T;
    }>;
    /**
     * This triggers the integration's request to be run on the server.
     * The server hits the url set up for that endpoint
     * The server needs to run the actual request to avoid any CORS issues
     *
     * @example
     * const res = await window.triggerIntegrationRequest({
          endpointId: endpoint.id
          //for a POST/DELETE/PUT
          data: {
            myData: "here"
          },
          //for a GET request
          params: {
            id: 5
          } ,
          method: methodSearch,
        });

        defined in src-shared/api.js
     */
    triggerIntegrationRequest(
      triggerIntegrationRequestObj: triggerIntegrationRequestObj
    ): Promise<any>;
    /**
     * @example
     * window.frontEndConfig.atLanzaTech
     * @example
     * window.frontEndConfig.appSettings.ALLOW_REGULAR_USERS_TO_CREATE_LABS
     */
    frontEndConfig: {
      allProjectsTagId: string;
      appColorOverride: string;
      /**
       * @example
       * window.frontEndConfig.appSettings.ALLOW_REGULAR_USERS_TO_CREATE_LABS
       *
       * you can use this together with upsertAppSetting like so:
       * await upsertAppSetting({
       *   code: "ALLOW_REGULAR_USERS_TO_CREATE_LABS",
       *   value: !checked
       * });
       * this.forceUpdate();
       */
      appSettings: {
        ALLOW_REGULAR_USERS_TO_CREATE_LABS: boolean;
        MIN_AUTO_ANNOTATED_FEATURE_LENGTH: number;
        MIN_AUTO_ANNOTATED_PART_LENGTH: number;
        MIN_AUTO_ANNOTATED_OLIGO_LENGTH: number;
        DEFAULT_PHONE_FORMAT_CODE: string;
      };
      disabledToolkits: {
        advancedMolecularBiology: UndefinedOrDisabledToolkitInfo;
        materialsAndInventoryManagement: UndefinedOrDisabledToolkitInfo;
        workflowManagement: UndefinedOrDisabledToolkitInfo;
        labAutomation: UndefinedOrDisabledToolkitInfo;
        dataAnalysis: UndefinedOrDisabledToolkitInfo;
        freezerEquipmentManagement: UndefinedOrDisabledToolkitInfo;
        discover: UndefinedOrDisabledToolkitInfo;
        integrations: UndefinedOrDisabledToolkitInfo;
      };
      localDocs: boolean;
      atLanzatech: boolean;
      azureADLogin: boolean;
      buildUrl: string;
      consentAcceptance: string;
      consentRequest: string;
      designUrl: string;
      debugEln: boolean;
      enabledModules: any;
      enabledTools: string[];
      enableGraphiqlInspector: boolean;
      enableNonSequenceDnaMaterials: boolean;
      evolveUrl: string;
      federatedSso: boolean;
      googleLogin: boolean;
      hiddenNotificationSettings: object;
      hideTaskMenu: boolean;
      iubenda: {
        version: number;
      };
      ldapLogin: boolean;
      localSignup: boolean;
      logTrackedRequests: boolean;
      logoutRedirect?: string;
      nodeRedEditorUrl: string;
      projectTags: boolean;
      reactions: boolean;
      recoverPassword: boolean;
      samlLogin: boolean;
      showDocumentation: boolean;
      segmentAnalyticsKey?: string;
      singleUserLabs: boolean;
      testUrl: string;
      trackUserNavigation: boolean;
      userRecordLocking: boolean;
      hasuraEnabled: boolean;
      hiddenFeaturesConfig: {
        designTemplates: boolean;
        eln: boolean;
      };
    };

    Cypress?: {
      tgApolloClient?: ApolloClient<NormalizedCacheObject> & {
        removeFromCache?: (records: Object[]) => void;
      };
      allowFileDownload?: boolean;
      cypressTestFile?: any;
      hadToChangeLab?: boolean;
      cypressTestFileName?: string;
      cypressTestFileType?: string;
    };
    _iub: {
      cons_instructions: any[];
    };
    analytics: {
      identify: (
        userId?: string,
        traits?: Object,
        options?: Object,
        callback?: Function
      ) => void;
      track: (
        event: string,
        properties?: Object,
        options?: Object,
        callback?: Function
      ) => void;
    };
    allowLabChange?: boolean;
  }
}

window.showConfirmationDialog = showConfirmationDialog;
window.showProgressToast = showProgressToast;

const appGlobals: AppGlobals = {};
export default appGlobals;

if (inDevelopment) {
  window.__appGlobals = appGlobals;
}
