/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import gql from "graphql-tag";
import AbstractLibrary from "../../../../src-shared/AbstractLibrary";
import libraryEnhancer from "../../../../src-shared/libraryEnhancer";
import "./style.css";
import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";
import { stringify } from "qs";
import { Checkbox, MenuItem, Tooltip } from "@blueprintjs/core";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import { startCase, lowerCase } from "lodash";
import ReassignTasksDialog from "../../NewWorkflowRun/WorkflowTaskInfo/ReassignTasksDialog";
import appGlobals from "../../../../src-shared/appGlobals";

function WorkQueueLibrary(props) {
  return (
    <AbstractLibrary
      {...props}
      getRoute={record => {
        if (record.workflowTool && record.workflowTool.workflowRun) {
          const hash = stringify({
            task: record.workflowTool.workflowToolDefinition.index + 1
          });
          return {
            pathname: modelNameToLink(record.workflowTool.workflowRun),
            hash
          };
        }
      }}
      contextMenu={({ openMenuItem, selectedRecords, extraContextItems }) => {
        const reassignMenuItem = (
          <MenuItem
            key="reassign"
            text="Reassign Tasks"
            icon="move"
            onClick={() => {
              showDialog({
                ModalComponent: ReassignTasksDialog,
                modalProps: {
                  tasks: selectedRecords.map(r => ({
                    workflowTool: r.workflowTool,
                    jobId: r.jobUser.jobId
                  })),
                  refetch: props.tableParams.onRefresh
                }
              });
            }}
          />
        );

        return [openMenuItem, reassignMenuItem, ...extraContextItems];
      }}
      withExport
      noNewItem
      libraryTitle="Work Queues"
      additionalHeaderItems={
        <Checkbox
          style={{ marginBottom: 0, marginLeft: 12 }}
          label="Only show my work queue items"
          name="filterMyWorkQueueItems"
          checked={props.tableParams.currentParams.showAll !== "true"}
          onChange={e => {
            const newParams = {
              ...props.tableParams.currentParams,
              showAll: !e.target.checked
            };
            if (!e.target.checked) {
              newParams.showAll = true;
            } else {
              delete newParams.showAll;
            }
            props.tableParams.setNewParams(newParams);
          }}
        />
      }
    ></AbstractLibrary>
  );
}

export const workQueueStatusColumn = {
  displayName: "Task Status",
  path: "workflowTool.workflowToolStatus.name",
  getClipboardData: v => v,
  render: (v, r) => {
    if (v) {
      return (
        <Tooltip content={r.workflowTool.workflowToolStatus.description}>
          {v}
        </Tooltip>
      );
    }
  }
};

const schema = {
  model: "workQueueItem",
  fields: [
    {
      displayName: "Task Name",
      path: "workflowTool.workflowToolDefinition.name"
    },
    workQueueStatusColumn,
    {
      displayName: "Assignee",
      path: "jobUser.user.username"
    },
    {
      displayName: "Job",
      path: "jobUser.job.name"
    },
    {
      path: "jobUser.job.priorityTypeCode",
      type: "string",
      displayName: "Job Priority",
      render: v => startCase(lowerCase(v))
    },
    {
      displayName: "Workflow Run",
      path: "workflowTool.workflowRun.name"
    },
    {
      displayName: "Workflow Status",
      path: "workflowTool.workflowRun.workflowRunStatusType.name"
    }
  ]
};

const fragment = gql`
  fragment workQueueLibraryFragment on workQueueItem {
    id
    reassigned
    createdAt
    updatedAt
    workflowTool {
      id
      workflowToolStatus {
        code
        name
        description
      }
      workflowRun {
        id
        name
        priorityType {
          code
          name
        }
        workflowRunStatusType {
          code
          name
        }
      }
      workflowToolDefinition {
        id
        name
        index
      }
    }
    jobUser {
      id
      jobId
      job {
        id
        name
        priorityTypeCode
      }
      user {
        id
        username
      }
    }
  }
`;

export default libraryEnhancer({
  schema,
  fragment,
  noAddedBy: true,
  additionalFilter: (_, qb, currentParams) => {
    const filter = {
      reassigned: false
    };
    if (currentParams.showAll !== "true") {
      filter["jobUser.userId"] = appGlobals.currentUser.id;
    }
    qb.whereAll(filter);
  }
})(WorkQueueLibrary);
