/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get, isEqual } from "lodash";
import concatWarningStrs from "../../../utils/concatWarningStrs";
import { isoContext } from "@teselagen/utils";

import handleUpdateMutations from "./handleUpdates";
import { handleNestedRecords, validateUnits } from "./utils";

export const ALIQUOT = async (
  { recordsToImport, upsertHandlers, ...rest },
  ctx = isoContext
) => {
  recordsToImport.forEach((r, i) => {
    r.ref = i;
  });
  const filteredRecords = recordsToImport.filter(r => {
    if (!r.id && !r.microbialMaterials?.length && !r.dnaMaterials?.length) {
      r.__importFailed = concatWarningStrs(
        r.__importFailed,
        "Provide a DNA or microbial material."
      );
    } else if (!r.volume && !r.mass) {
      r.__importFailed = concatWarningStrs(
        r.__importFailed,
        "Provide a volume or mass."
      );
    }

    if (r.id) {
      if (r.__oldRecord) {
        const oldDnaMaterialIds = get(r.__oldRecord, "dnaMaterials", []).map(
          m => m.id
        );
        const newDnaMaterialIds = get(r, "dnaMaterials", []).map(m => m.id);
        const oldMicrobialMaterialIds = get(
          r.__oldRecord,
          "microbialMaterials",
          []
        ).map(m => m.id);
        const newMicrobialMaterialIds = get(r, "microbialMaterials", []).map(
          m => m.id
        );

        if (
          !isEqual(oldDnaMaterialIds.sort(), newDnaMaterialIds.sort()) ||
          !isEqual(
            oldMicrobialMaterialIds.sort(),
            newMicrobialMaterialIds.sort()
          )
        ) {
          r.__importFailed = concatWarningStrs(
            r.__importFailed,
            "Cannot change material on an existing aliquot."
          );
        }
      }
    }

    if (r.__importFailed) return false;

    const unitError = validateUnits(r);
    if (unitError) {
      r.__importFailed = concatWarningStrs(r.__importFailed, unitError);
    }

    return !r.__importFailed;
  });

  let recordsToContinueUpserting = await handleNestedRecords(
    filteredRecords,
    "dnaMaterials",
    async dnaMaterials => {
      await upsertHandlers.DNA_MATERIAL(
        {
          ...rest,
          model: "material",
          recordsToImport: dnaMaterials,
          upsertHandlers
        },
        ctx
      );
    }
  );
  recordsToContinueUpserting = await handleNestedRecords(
    recordsToContinueUpserting,
    "microbialMaterials",
    async microbialMaterials => {
      await upsertHandlers.MICROBIAL_MATERIAL(
        {
          ...rest,
          model: "material",
          recordsToImport: microbialMaterials,
          upsertHandlers
        },
        ctx
      );
    }
  );

  const newRecords = await handleUpdateMutations(
    {
      recordsToImport: recordsToContinueUpserting,
      convertUserFacingToDbModel: r => {
        if (!r.id) {
          const materials = [
            ...(r.dnaMaterials || []),
            ...(r.microbialMaterials || [])
          ];
          if (materials.length === 1) {
            r.sample = {
              sampleTypeCode: "REGISTERED_SAMPLE",
              name: materials[0].name,
              materialId: materials[0].id
            };
          } else if (materials.length > 1) {
            r.sample = {
              sampleTypeCode: "FORMULATED_SAMPLE",
              name: `${materials.map(m => m.name).join(" - ")}`,
              sampleFormulations: {
                materialCompositions: materials.map(m => ({
                  materialId: m.id
                }))
              }
            };
          } else {
            r.sample = {
              sampleTypeCode: "REGISTERED_SAMPLE",
              name: "Empty Sample"
            };
          }
        }

        r.aliquotType = "registered-aliquot";
        if (r.mass && !r.volume) {
          r.isDry = true;
        }

        delete r.additives;
        delete r.dnaMaterials;
        delete r.microbialMaterials;

        return r;
      },
      model: "aliquot"
    },
    ctx
  );

  const newIds = (
    await ctx.safeUpsert(
      ["aliquot", "id"],
      newRecords.map(nr => {
        const recordToUpsert = { ...nr };
        delete recordToUpsert.ref;
        return recordToUpsert;
      })
    )
  ).map(nr => nr.id);

  newRecords.forEach((nr, i) => {
    const record = recordsToImport[nr.ref];
    record.id = newIds[i];

    delete record.ref;
    delete nr.ref;
  });
};
