/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Component } from "react";
import { forEach } from "lodash";
import { compose } from "recompose";
import { withSelectedEntities } from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";

import StepForm from "../../../../src-shared/StepForm";
import { extendedPropertyFragment } from "../../../../src-shared/fragments/extendedPropertyFragment.gql";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import SelectPlates, {
  containerArrayFragmentWithExtendedProperties
} from "./SelectPlates";
import UpdateExtendedProperties from "./UpdateExtendedProperties";
import {
  safeUpsert,
  safeQuery,
  safeDelete
} from "../../../../src-shared/apolloMethods";

import { addAndRemoveExtendedProperties } from "../../../../src-shared/utils/extendedPropertyUtils";

class SpinDownTool extends Component {
  onSubmit = async values => {
    const {
      aliquotExtendedPropertiesToRemoveSelectedEntities = [],
      plateExtendedPropertiesToRemoveSelectedEntities = []
    } = this.props;
    const platePropIdsToRemove =
      plateExtendedPropertiesToRemoveSelectedEntities.map(p => p.id);
    const aliquotPropIdsToRemove =
      aliquotExtendedPropertiesToRemoveSelectedEntities.map(p => p.id);
    const {
      containerArrays = [],
      aliquotExtendedValues = [],
      containerArrayExtendedValues = []
    } = values;

    const extendedValuesToDelete = {
      extendedValue: [],
      extendedCategoryValue: [],
      extendedMeasurementValue: []
    };

    const extendedValuesToCreate = {
      extendedValue: [],
      extendedCategoryValue: [],
      extendedMeasurementValue: []
    };

    containerArrays.forEach(containerArray => {
      addAndRemoveExtendedProperties(containerArray, {
        extendedValues: containerArrayExtendedValues,
        extendedValuesToDelete,
        extendedValuesToCreate,
        propIdsToRemove: platePropIdsToRemove
      });
      containerArray.aliquotContainers.forEach(ac => {
        if (ac.aliquot) {
          addAndRemoveExtendedProperties(ac.aliquot, {
            extendedValues: aliquotExtendedValues,
            extendedValuesToDelete,
            extendedValuesToCreate,
            propIdsToRemove: aliquotPropIdsToRemove
          });
        }
      });
    });

    try {
      forEach(extendedValuesToDelete, async (items, model) => {
        await safeDelete(model, items);
      });
      forEach(extendedValuesToCreate, async (items, model) => {
        await safeUpsert(model, items);
      });
      // to update cache
      await safeQuery(containerArrayFragmentWithExtendedProperties, {
        variables: {
          filter: {
            id: containerArrays.map(c => c.id)
          }
        }
      });
      return {
        containerArrays
      };
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error creating new properties");
    }
  };

  render() {
    const {
      toolIntegrationProps,
      toolSchema,
      initialValues,
      pelletProperty,
      aspiratedProperty
    } = this.props;

    const steps = [
      {
        title: "Select Plates",
        Component: SelectPlates,
        withCustomFooter: true,
        props: {
          pelletProperty,
          aspiratedProperty
        }
      },
      {
        title: "Update Extended Properties",
        Component: UpdateExtendedProperties
      }
    ];

    return (
      <StepForm
        toolIntegrationProps={toolIntegrationProps}
        enableReinitialize={true}
        toolSchema={toolSchema}
        initialValues={initialValues}
        steps={steps}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default compose(
  withQuery(extendedPropertyFragment, {
    isPlural: true,
    showLoading: true,
    options: {
      variables: {
        filter: {
          name: "Aspirated",
          extendedTypeCode: "boolean",
          modelTypeCode: "CONTAINER_ARRAY"
        }
      }
    },
    props: props => {
      if (props.extendedProperties) {
        return {
          aspiratedProperty: props.extendedProperties[0]
        };
      }
    }
  }),
  withQuery(extendedPropertyFragment, {
    isPlural: true,
    showLoading: true,
    options: {
      variables: {
        filter: {
          name: "Pellet",
          extendedTypeCode: "boolean",
          modelTypeCode: "ALIQUOT"
        }
      }
    },
    props: props => {
      if (props.extendedProperties) {
        return {
          pelletProperty: props.extendedProperties[0]
        };
      }
    }
  }),
  withWorkflowInputs(containerArrayFragmentWithExtendedProperties),
  withSelectedEntities(
    "aliquotExtendedPropertiesToRemove",
    "plateExtendedPropertiesToRemove"
  )
)(SpinDownTool);
