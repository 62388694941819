/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import shortid from "shortid";
import {
  extractZipFiles,
  isCsvOrExcelFile,
  parseCsvOrExcelFile,
  validateCSVRow,
  validateCSVRequiredHeaders,
  getExt
} from "../../../tg-iso-shared/src/utils/fileUtils";
import getReagentsFromCsv from "../utils/getReagentsFromCsv";
import {
  sequenceFileTypes,
  parseSequenceFiles,
  stripAssemblyGaps,
  calculateDNAMolecularWeight
} from "../../../tg-iso-shared/src/sequence-import-utils/utils";
import { uniqBy, noop } from "lodash";
import {
  sequenceWithTagsAndAliasesFragment,
  aliquotContainerTypeFragment,
  containerArrayTypeFragment
} from "./helperFragments";
import { showDuplicateInputSequencesConfirmation } from "./utils";
import caseInsensitiveFilter from "../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import finishPlateOrTubeCreate from "../utils/finishPlateOrTubeCreate";
import { checkDuplicateSequencesExtended } from "../../../tg-iso-shared/src/sequence-import-utils/checkDuplicateSequences";
import { getBoundExtendedPropertyUploadHelpers } from "../../../tg-iso-shared/src/utils/extendedPropertiesUtils";
import { getSequence } from "../../../tg-iso-shared/src/utils/getSequence";
import { normalizeCsvHeader } from "../../../tg-iso-shared/src/utils/fileUtils";

export async function extractPlateCsvFiles(uploadFiles) {
  const allFiles = await extractZipFiles(uploadFiles);
  const plateUnparsedCsvFiles = allFiles.filter(file => isCsvOrExcelFile(file));
  const otherFiles = allFiles.filter(file => !isCsvOrExcelFile(file));

  if (!plateUnparsedCsvFiles.length) {
    throw new Error("No csv file found.");
  }

  return { otherFiles, csvFiles: plateUnparsedCsvFiles };
}

export async function handlePlateCsv(
  plateUnparsedCsvFiles,
  params = {},
  options = {},
  ctx
) {
  const {
    aliquotContainerType,
    containerArrayType,
    allParsedSequences,
    allParsedSequencesFilenames,
    filteredSequences,
    sequenceFiles,
    sequenceNameMap,
    sequenceFileMap
  } = params;

  const {
    requiredFields: _requiredFields,
    getSequenceForRow, // this is required if we want this function to do sequence checking
    hasReagents,
    allowDryReagents,
    headerMappings = {},
    barcodeHeader,
    nameHeader
  } = options;

  let allCsvData = [];
  // this will keep track of the csv headers across all the plate files
  const allCsvHeaders = [];

  const addHeader = header => {
    if (!allCsvHeaders.includes(header)) {
      allCsvHeaders.push(header);
    }
  };

  for (const unparsedCsvFile of plateUnparsedCsvFiles) {
    const parsedCsv = await parseCsvOrExcelFile(unparsedCsvFile, {
      csvParserOptions: {
        header: false // header is false to allow for duplicate headers which is needed for reagents
      }
    });

    // trim header row
    const cleanedHeaders = parsedCsv.data[0]
      .map((header = "") => {
        const trimmedHeader = header.trim();
        if (headerMappings[trimmedHeader]) {
          return headerMappings[trimmedHeader];
        } else {
          return trimmedHeader;
        }
      })
      .map(normalizeCsvHeader);
    cleanedHeaders.forEach(addHeader);
    const csvData = parsedCsv.data.slice(1);
    const filename = parsedCsv.originalFile.name;
    let cleanedCsvData = [];
    const requiredFields = _requiredFields.map(normalizeCsvHeader);
    if (requiredFields) {
      const requiredFieldError = validateCSVRequiredHeaders(
        cleanedHeaders,
        requiredFields,
        filename
      );
      if (requiredFieldError) throw new Error(requiredFieldError);
    }
    if (hasReagents) {
      try {
        cleanedCsvData = await getReagentsFromCsv(
          csvData,
          cleanedHeaders,
          {
            aliquotContainerType,
            containerArrayType,
            allowDry: allowDryReagents
          },
          ctx
        );
      } catch (error) {
        console.error("error:", error);
        throw new Error(`Error in CSV file ${filename} ${error.message}`);
      }
    } else {
      cleanedCsvData = csvData.map(dataRow => {
        const cleanedRow = {};
        cleanedHeaders.forEach((header, i) => {
          cleanedRow[header] = dataRow[i];
        });
        return cleanedRow;
      });
    }
    for (const [index, row] of cleanedCsvData.entries()) {
      if (requiredFields) {
        const requiredError = validateCSVRow(row, requiredFields, index);
        if (requiredError) {
          throw new Error(`Error in CSV file ${filename} ${requiredError}`);
        }
      }
      if (barcodeHeader || nameHeader) {
        const rowBarcode = barcodeHeader && row[barcodeHeader];
        const rowName = nameHeader && row[nameHeader];
        row.rowKey = (rowBarcode || "") + ":" + (rowName || "");
      }
      // after running through this if statement we should have a list of sequences to check for duplicate
      // and each row in the csv will now have a sequence hash
      if (getSequenceForRow) {
        // this function might throw an error. Keep it so that it can run validation for each row
        const sequence = await getSequenceForRow({
          row,
          index,
          allParsedSequences,
          allParsedSequencesFilenames,
          allSequenceFiles: sequenceFiles,
          sequenceNameMap,
          sequenceFileMap
        });

        // allow for multiple
        if (Array.isArray(sequence)) {
          filteredSequences.push(...sequence);
          row.sequenceHashes = [];
          row.molecularWeights = [];
          // eslint-disable-next-line no-loop-func
          sequence.forEach(seq => {
            row.sequenceHashes.push(seq.hash);
            // this is a little backwards but easier to add molecular weight
            const sequenceBps = getSequence(seq);
            const molecularWeight = calculateDNAMolecularWeight(
              sequenceBps,
              seq.sequenceTypeCode
            );
            row.molecularWeights.push(molecularWeight);
          });
          row.sequenceHashes = sequence.map(s => s.hash);
        } else if (sequence) {
          row.sequenceHash = sequence.hash;
          filteredSequences.push(sequence);
          const sequenceBps = getSequence(sequence);
          row.molecularWeight = calculateDNAMolecularWeight(
            sequenceBps,
            sequence.sequenceTypeCode
          );
        }
      }
    }
    allCsvData = allCsvData.concat(cleanedCsvData);
  }

  return { csvData: allCsvData, csvHeaders: allCsvHeaders };
}

export async function handlePlateTypeInfo(values, options = {}, ctx) {
  const {
    // because the api sends the plate and tube types as strings then we can handle that here
    plateType: stringPlateType,
    tubeType: stringTubeType,
    containerArrayType: maybePlateType,
    aliquotContainerType: maybeTubeType,
    isTubeUpload: isTubeUploadValue
  } = values;
  const { isTubeUpload: isTubeUploadOption } = options;
  const { safeQuery } = ctx;

  const isTubeUpload = isTubeUploadValue || isTubeUploadOption;

  let containerArrayType = maybePlateType;
  let aliquotContainerType = maybeTubeType;

  if (!isTubeUpload && !containerArrayType && !stringPlateType) {
    throw new Error("Please select a plate type.");
  } else if (isTubeUpload && !aliquotContainerType && !stringTubeType) {
    throw new Error("please select a tube type.");
  }

  if (!containerArrayType && stringPlateType) {
    // then we are calling from the api
    const [matchedPlateType] = await safeQuery(
      ["containerArrayType", containerArrayTypeFragment],
      {
        variables: {
          filter: caseInsensitiveFilter("containerArrayType", "name", [
            stringPlateType
          ])
        }
      }
    );
    if (!matchedPlateType) {
      throw new Error(`No plate type found with name ${stringPlateType}`);
    }
    containerArrayType = matchedPlateType;

    if (containerArrayType.isPlate && stringTubeType) {
      throw new Error(
        `The plate type ${stringPlateType} does not have tubes but the tube type ${stringTubeType} was passed.`
      );
    }

    if (!stringTubeType && containerArrayType.nestableTubeTypes.length === 1) {
      aliquotContainerType =
        containerArrayType.nestableTubeTypes[0].aliquotContainerType;
    } else if (stringTubeType) {
      const matchingTubeType = containerArrayType.nestableTubeTypes.find(
        type =>
          type.aliquotContainerType.name.toLowerCase() ===
          stringTubeType.toLowerCase()
      );
      if (!matchingTubeType) {
        throw new Error(
          `There was no tube type found with the name ${stringTubeType} for the rack type ${matchedPlateType.name}`
        );
      } else {
        aliquotContainerType = matchingTubeType.aliquotContainerType;
      }
    }
  }

  if (isTubeUpload && !aliquotContainerType && stringTubeType) {
    const [matchedTubeType] = await safeQuery(
      ["aliquotContainerType", aliquotContainerTypeFragment],
      {
        variables: {
          filter: caseInsensitiveFilter("aliquotContainerType", "name", [
            stringTubeType
          ])
        }
      }
    );
    if (!matchedTubeType) {
      throw new Error(`No tube type found with name ${stringTubeType}`);
    }
    aliquotContainerType = matchedTubeType;
  }

  if (
    containerArrayType &&
    !containerArrayType.isPlate &&
    !aliquotContainerType
  ) {
    throw new Error(`Please select a tube type for ${containerArrayType.name}`);
  }

  return {
    containerArrayType,
    aliquotContainerType
  };
}

export default async function parsePlateCsvAndSequenceFiles(
  values,
  options = {},
  ctx
) {
  const {
    plateFiles = [],
    tubeFiles = [],
    // because the api sends the plate and tube types as strings then we can handle that here
    refetch = noop,
    isTubeUpload: isTubeUploadValue
  } = values;
  const {
    hasSequences,
    sequenceFragment = sequenceWithTagsAndAliasesFragment,
    hasExtendedProperties,
    isTubeUpload: isTubeUploadOption,
    isRNAUpload
  } = options;
  const isTubeUpload = isTubeUploadValue || isTubeUploadOption;

  let uploadFiles;
  if (isTubeUpload) {
    uploadFiles = tubeFiles;
  } else {
    uploadFiles = plateFiles;
  }

  if (!uploadFiles || !uploadFiles.length) {
    throw new Error(
      `No files passed to ${
        isTubeUpload ? "tube" : "plate"
      } upload file parser.`
    );
  }

  const { csvFiles: plateUnparsedCsvFiles, otherFiles } =
    await extractPlateCsvFiles(uploadFiles);
  const filename = plateUnparsedCsvFiles[0].name;

  const { containerArrayType, aliquotContainerType } =
    await handlePlateTypeInfo(values, options, ctx);

  let allParsedSequences = [];
  let allParsedSequencesFilenames = [];
  let sequenceNameMap = {};
  let sequenceFileMap = {};
  let filteredSequences = [];

  let sequenceFiles = [];
  if (hasSequences) {
    sequenceFiles = otherFiles.filter(f =>
      sequenceFileTypes.includes(getExt(f))
    );
    if (sequenceFiles.length) {
      const {
        sequences,
        filenames,
        sequenceNameMap: _sequenceNameMap,
        sequenceFileMap: _sequenceFileMap
      } = await parseSequenceFiles(sequenceFiles, {
        isRNA: isRNAUpload
      });
      sequenceNameMap = _sequenceNameMap;
      sequenceFileMap = _sequenceFileMap;
      allParsedSequences = await stripAssemblyGaps(sequences);
      allParsedSequencesFilenames = filenames;
    }
  }

  let newSequences = [];
  let newMaterials = [];
  let sequenceUpdates = [];
  let existingSequences = [];
  const existingMaterials = [];

  const { csvData: allCsvData, csvHeaders: allCsvHeaders } =
    await handlePlateCsv(
      plateUnparsedCsvFiles,
      {
        aliquotContainerType,
        containerArrayType,
        allParsedSequences,
        allParsedSequencesFilenames,
        sequenceFiles,
        filteredSequences,
        sequenceNameMap,
        sequenceFileMap
      },
      options,
      ctx
    );

  if (hasSequences) {
    // we will check these sequences for duplicates in the database
    // first just make a list of the sequences unique by name + hash

    // we want to use name + hash because we want to warn the users if they
    // are providing multiple sequences that are the same
    filteredSequences = uniqBy(filteredSequences, s => `${s.name}-${s.hash}`);

    let sequenceFragmentToUse = sequenceFragment + " name";
    // we need to always check for existing aliases and materials
    // so add it to the fragment if it is not on the one passed in
    if (sequenceFragmentToUse.indexOf("aliases") === -1) {
      sequenceFragmentToUse += " aliases { id name }";
    }
    if (sequenceFragmentToUse.indexOf("polynucleotideMaterialId") === -1) {
      sequenceFragmentToUse += " polynucleotideMaterialId";
    }

    const {
      duplicatesOfInputSequences,
      uniqueInputSequences, // make new materials and sequences for these
      allInputSequencesWithAttachedDuplicates, // use this to create a map from the input sequence hash to the sequence it should be mapped to
      duplicateSequencesFound // this is an array of all the existing sequences. return them
    } = await checkDuplicateSequencesExtended(
      filteredSequences,
      {
        skipAssemblyGapCheck: true,
        fragment: sequenceFragmentToUse
      },
      ctx
    );

    existingSequences = duplicateSequencesFound;

    if (sequenceFiles.length && duplicatesOfInputSequences.length) {
      const continueUpload = await showDuplicateInputSequencesConfirmation(
        duplicatesOfInputSequences
      );
      if (!continueUpload) {
        throw new Error("Import cancelled.");
      }
    }

    newSequences = uniqueInputSequences;
    newMaterials = [];
    sequenceUpdates = [];

    const inputSequenceHashMap = {};
    const sequenceIdToMaterialId = {};

    allInputSequencesWithAttachedDuplicates.forEach(sequence => {
      const duplicateFound = sequence.duplicateFound;
      const mappedSequence = duplicateFound || sequence;
      const sequenceId = mappedSequence.id || `&${mappedSequence.cid}`;

      if (!inputSequenceHashMap[sequence.hash]) {
        inputSequenceHashMap[sequence.hash] = {
          sequenceId,
          sequence: mappedSequence
        };
      }

      if (mappedSequence.polynucleotideMaterialId) {
        sequenceIdToMaterialId[sequenceId] =
          mappedSequence.polynucleotideMaterialId;
      } else if (!sequenceIdToMaterialId[sequenceId]) {
        // if we have not created a dna material for this sequence
        const materialCid = shortid();
        // the new material name should match the sequence that we are mapping to
        const newMaterial = {
          cid: materialCid,
          materialTypeCode: isRNAUpload ? "RNA" : "DNA",
          name: mappedSequence.name,
          aliases: (mappedSequence.aliases || []).map(a => ({ name: a.name }))
        };
        newMaterials.push(newMaterial);
        const materialId = `&${materialCid}`;
        sequenceUpdates.push({
          id: sequenceId,
          polynucleotideMaterialId: materialId
        });
        sequenceIdToMaterialId[sequenceId] = materialId;
      }

      // add the material id to the map
      inputSequenceHashMap[sequence.hash].materialId =
        sequenceIdToMaterialId[sequenceId];
    });

    for (const row of allCsvData) {
      if (row.sequenceHash) {
        const { sequenceId, sequence, materialId } =
          inputSequenceHashMap[row.sequenceHash];
        row.sequenceId = sequenceId;
        row.sequence = sequence;
        row.materialId = materialId;
      } else if (row.sequenceHashes) {
        row.sequenceIds = [];
        row.sequences = [];
        row.materialIds = [];
        row.sequenceHashes.forEach(hash => {
          const { sequenceId, sequence, materialId } =
            inputSequenceHashMap[hash];
          row.sequenceIds.push(sequenceId);
          row.sequences.push(sequence);
          row.materialIds.push(materialId);
        });
      }
    }
  }

  let getCsvRowExtProps, createUploadProperties;
  if (hasExtendedProperties) {
    const boundHelpers = await getBoundExtendedPropertyUploadHelpers(
      allCsvHeaders,
      ctx
    );
    getCsvRowExtProps = boundHelpers.getCsvRowExtProps;
    createUploadProperties = boundHelpers.createUploadProperties;
  }

  const csvData = allCsvData;

  existingSequences.forEach(seq => {
    if (seq.polynucleotideMaterial) {
      existingMaterials.push(seq.polynucleotideMaterial);
    }
  });

  const boundFinishPlateOrTubeCreate = options => {
    const paramsToPass = {
      ...values,
      createUploadProperties,
      aliquotContainerType,
      containerArrayType,
      filename,
      ...options,
      isTubeUpload,
      refetch,
      newSequences,
      newMaterials,
      sequenceUpdates,
      existingSequences,
      existingMaterials
    };
    // some uploads (proteinPlate) pass their own materials
    const toMerge = [
      "newSequences",
      "newMaterials",
      "sequenceUpdates",
      "existingSequences",
      "existingMaterials"
    ];
    toMerge.forEach(key => {
      if (options[key] && options[key] !== paramsToPass[key]) {
        paramsToPass[key] = paramsToPass[key].concat(options[key]);
      }
    });
    return finishPlateOrTubeCreate(paramsToPass, ctx);
  };

  return {
    finishPlateCreate: boundFinishPlateOrTubeCreate,
    finishTubeCreate: boundFinishPlateOrTubeCreate,
    containerArrayType,
    aliquotContainerType,
    filename,
    allCsvHeaders,
    csvData,
    getCsvRowExtProps,
    newSequences,
    newMaterials,
    sequenceUpdates,
    existingSequences,
    existingMaterials,
    sequenceFiles
  };
}
