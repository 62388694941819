/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useEffect, useState } from "react";
import { safeQuery } from "../../src-shared/apolloMethods";
import { keyBy } from "lodash";
import { SwitchField } from "@teselagen/ui";

export default function useShowReactionsSampleStatus({ reactions }) {
  const [showSampleStatus, setShowStampleStatus] = useState(false);
  const [loadingSampleStatus, setLoadingSampleStatus] = useState(false);
  const [sampleStatusInfo, setSampleStatusInfo] = useState({});

  async function loadSampleInfo(reactions) {
    if (!reactions?.length) return;
    setLoadingSampleStatus(true);
    try {
      const materialIds = [];
      reactions.forEach(r => {
        r.reactionInputs.forEach(input => {
          if (input.inputMaterial) {
            materialIds.push(input.inputMaterial.id);
          }
        });
        r.reactionOutputs.forEach(output => {
          if (output.outputMaterial) {
            materialIds.push(output.outputMaterial.id);
          }
        });
      });
      const alreadyLoaded = Object.keys(sampleStatusInfo);
      let newInfo = { ...sampleStatusInfo };
      const needed = materialIds.filter(id => !alreadyLoaded.includes(id));
      if (needed.length) {
        const newSampleInfo = await safeQuery(
          [
            "materialSampleStatusView",
            "id totalInvalid totalValid totalUnvalidated"
          ],
          {
            variables: {
              filter: {
                id: needed
              }
            }
          }
        );
        newInfo = {
          ...newInfo,
          ...keyBy(newSampleInfo, "id")
        };
        // fill out empty ones so that it will cache
        needed.forEach(id => {
          if (!newInfo[id]) {
            newInfo[id] = undefined;
          }
        });
      }
      setSampleStatusInfo(newInfo);
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error("Error loading sample status info");
    }
    setLoadingSampleStatus(false);
  }

  useEffect(() => {
    if (!showSampleStatus || loadingSampleStatus) return;

    loadSampleInfo(reactions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSampleStatus, reactions]);

  const switchField = (
    <SwitchField
      name="showSampleStatus"
      label={loadingSampleStatus ? "Loading statuses..." : "Show Sample Status"}
      onFieldSubmit={val => {
        setShowStampleStatus(!!val);
      }}
    />
  );

  return {
    switchField,
    showSampleStatus,
    sampleStatusInfo:
      loadingSampleStatus || !showSampleStatus ? null : sampleStatusInfo
  };
}
