/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import {
  generateEmptyWells,
  getPositionFromAlphanumericLocation
} from "../../../../tg-iso-lims/src/utils/plateUtils";
import { willItFit } from "./willItFit";
import { times, range } from "lodash";
import {
  plateTo2dAliquotContainerArray,
  getBlockOf2dArray,
  blockToAliquotArray
} from "../../components/LimsTools/utils";
import { BreakdownPatterns } from "../../components/LimsTools/utils/blockToAliquotArray";
import { AliquotContainerType } from "../../components/LimsTools/utils/plateTo2dAliquotContainerArray";

export const quadrantToDefaultStartingPosition = {
  0: "A1",
  1: "A2",
  2: "B1",
  3: "B2"
};

type WithQuadrantAndBreakdown = {
  quadrant: keyof typeof quadrantToDefaultStartingPosition; // in create plate map tool, if user is placing materials into breakdown quadrants (1 material every four wells), quadrant is a number between 0 and 3
  breakdownPattern: BreakdownPatterns; // for splitting plate map into quadrants in create plate map tool
};

export type Directions = "left" | "right" | "up" | "down";

export type getWellPositionsGivenWellCountAndDirectionParams = {
  containerFormat: {
    rowCount: number;
    columnCount: number;
  };
  numWells: number;
  startingPosition: string | { rowPosition: number; columnPosition: number }; //a1 or {rowPosition, columnPosition}
  direction: Directions;
  multiplate: boolean;
} & (WithQuadrantAndBreakdown | {});

function hasQuadrantAndBreakdown(
  params: any
): params is WithQuadrantAndBreakdown {
  return (
    "quadrant" in params &&
    params.quadrant !== undefined &&
    "breakdownPattern" in params &&
    params.breakdownPattern !== undefined
  );
}

export function getWellPositionsGivenWellCountAndDirection(
  params: getWellPositionsGivenWellCountAndDirectionParams
): AliquotContainerType[] | AliquotContainerType[][] {
  const {
    containerFormat,
    numWells,
    startingPosition: _startingPosition,
    direction: _direction = "right",
    multiplate
  } = params;
  if (!_startingPosition) {
    return [];
  }
  const startingPosition =
    typeof _startingPosition === "string"
      ? getPositionFromAlphanumericLocation(_startingPosition)
      : _startingPosition;
  const direction = _direction.toLowerCase() as Directions;
  const { rowCount, columnCount } = containerFormat;
  let { rowPosition: row, columnPosition: column } = startingPosition;
  if (!multiplate && !willItFit(numWells, containerFormat)) {
    throw new Error("Not enough space in plate");
  }
  let capacity = containerFormat.rowCount * containerFormat.columnCount;
  let defaultStartingPosition = "A1";
  if (hasQuadrantAndBreakdown(params)) {
    const { quadrant } = params;
    capacity = (containerFormat.rowCount * containerFormat.columnCount) / 4;
    defaultStartingPosition = quadrantToDefaultStartingPosition[quadrant];
  }
  const numPlates = Math.ceil(numWells / capacity);
  if (multiplate) {
    let allNumWellsToAssign = numWells;
    return times(numPlates, i => {
      const newStartPos = i === 0 ? _startingPosition : defaultStartingPosition;
      let newNumWells = capacity;
      if (allNumWellsToAssign - newNumWells < 0) {
        newNumWells = allNumWellsToAssign;
      }
      allNumWellsToAssign -= newNumWells;
      return getWellPositionsGivenWellCountAndDirection({
        ...params,
        numWells: newNumWells,
        startingPosition: newStartPos,
        direction,
        multiplate: false
      }) as AliquotContainerType[];
    });
  } else {
    if (hasQuadrantAndBreakdown(params)) {
      const { quadrant, breakdownPattern } = params;
      const locationsArray = [];
      const destinationAliquotContainerArray: AliquotContainerType[] = [];
      const aliquotContainers = generateEmptyWells(containerFormat);
      const { rowCount, columnCount } = containerFormat;

      const aliquotContainer2dArray = plateTo2dAliquotContainerArray({
        containerArrayType: { containerFormat: containerFormat },
        aliquotContainers
      });
      const blockRowCount = 2;
      const blockColCount = 2;
      range(rowCount / 2).forEach(repRowPos => {
        range(columnCount / 2).forEach(repColPos => {
          const block = getBlockOf2dArray(
            aliquotContainer2dArray,
            blockRowCount,
            blockColCount,
            repColPos,
            repRowPos,
            true,
            true
          );
          blockToAliquotArray(block, breakdownPattern).forEach(
            (aliquotContainer, quadrantIndex) => {
              if (quadrantIndex === quadrant) {
                destinationAliquotContainerArray.push(aliquotContainer);
              }
            }
          );
        });
      });
      const startingIndex = destinationAliquotContainerArray.findIndex(
        aliquotContainer =>
          aliquotContainer.rowPosition === row &&
          aliquotContainer.columnPosition === column
      );
      const destinationAliquotContainerArrayShifted = [
        ...destinationAliquotContainerArray.slice(startingIndex),
        ...destinationAliquotContainerArray.slice(0, startingIndex)
      ];
      for (const ac of destinationAliquotContainerArrayShifted) {
        if (locationsArray.length === numWells) break;
        locationsArray.push(ac);
      }
      return locationsArray;
    } else if (direction === "right") {
      return times(numWells, () => {
        if (column !== 0 && column % columnCount === 0) row++;
        if (row % rowCount === 0) row = 0;
        return {
          columnPosition: column++ % columnCount,
          rowPosition: row
          // ...otherColumns
        };
      });
    } else if (direction === "left") {
      return times(numWells, () => {
        if (column === -1) {
          row++;
          column = columnCount - 1;
        }
        if (row % rowCount === 0) row = 0;

        return {
          columnPosition: column-- % columnCount,
          rowPosition: row
          // ...otherColumns
        };
      });
    } else if (direction === "up") {
      return times(numWells, () => {
        if (row === -1) {
          column++;
          row = rowCount - 1;
        }
        if (column % columnCount === 0) column = 0;

        return {
          rowPosition: row-- % rowCount,
          columnPosition: column
          // ...otherColumns
        };
      });
    } else if (direction === "down") {
      return times(numWells, () => {
        if (row !== 0 && row % rowCount === 0) column++;
        if (column % columnCount === 0) column = 0;
        return {
          rowPosition: row++ % rowCount,
          columnPosition: column
          // ...otherColumns
        };
      });
    } else {
      throw new Error("Invalid direction");
    }
    // return rowIndexToLetter(rowPosition) + (columnPosition + 1);
  }
}
