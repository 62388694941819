/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { isEmpty, noop } from "lodash";
import {
  popoverOverflowModifiers,
  showConfirmationDialog,
  showProgressToast
} from "@teselagen/ui";
import { download } from "../../src-shared/utils/downloadTest";
import formatReactionMapsForExport from "../../../tg-iso-lims/src/utils/formatReactionMapsForExport";
import {
  getKeyMaterialsFromAliquotContainer,
  getKeyReagentsFromAliquotContainer
} from "../../../tg-iso-lims/src/utils/plateUtils";
import { reactionIsMatch } from "../../../tg-iso-lims/src/ReactionMap";
import getSampleStatusMessage from "../../src-shared/utils/getSampleStatusMessage";
import { Icon, Tooltip } from "@blueprintjs/core";
import { get } from "lodash";
import { safeQuery, safeUpsert } from "../../src-shared/apolloMethods";
import { handleLinkAllReportMaterials } from "../components/LinkAllJ5MaterialsButton/utils";
import gql from "graphql-tag";
import getReactionInputsAndOutputs from "../../../tg-iso-shared/src/utils/getReactionInputsAndOutputs";
import { handleZipFiles } from "../../../tg-iso-shared/src/utils/fileUtils";
import { Link } from "react-router-dom";
import modelNameToLink from "../../src-shared/utils/modelNameToLink";

export function getReactionsForAliquotContainer({
  aliquotContainer = {},
  reactionMaps = [],
  onMatch = noop
}) {
  const keyedMaterials = getKeyMaterialsFromAliquotContainer(aliquotContainer);
  const keyedReagents = getKeyReagentsFromAliquotContainer(aliquotContainer);
  const matchingReactions = [];
  if (!isEmpty(keyedMaterials) || !isEmpty(keyedReagents)) {
    reactionMaps.forEach(rm => {
      return rm.reactions.forEach(reaction => {
        const isMatch = reactionIsMatch(
          reaction,
          keyedMaterials,
          keyedReagents
        );
        if (isMatch) {
          onMatch(reaction);
          matchingReactions.push(reaction);
        }
      });
    });
  }
  return matchingReactions;
}

const makeLink = (record, text) => {
  return (
    <Link to={modelNameToLink(record, record.id)}>{text || record.name}</Link>
  );
};

export function getReactionMapSchema(
  reactions = [],
  { returnFullSchema, isReactionMapRecordView, sampleStatusInfo } = {}
) {
  const schema = {
    model: "reaction",
    fields: []
  };
  if (isReactionMapRecordView) {
    schema.fields.push({
      type: "action",
      width: 30,
      resizable: false,
      render: () => {
        return <Icon icon="eye-open" />;
      }
    });
  }
  const initBool = returnFullSchema || false;
  let hasReactionName = initBool;
  let hasInputMaterial = initBool;
  let hasInputAdditiveMaterial = initBool;
  let hasOutputMaterial = initBool;
  let hasOutputAdditiveMaterial = initBool;
  let hasConserved = initBool;
  let hasExecutedDate = initBool;

  reactions?.forEach(r => {
    hasReactionName = hasReactionName || r.name;
    hasExecutedDate = hasExecutedDate || r.executedAt;
    const hasIM =
      hasInputMaterial || r.reactionInputs.some(input => input.inputMaterial);
    const hasIA =
      hasInputAdditiveMaterial ||
      r.reactionInputs.some(input => input.inputAdditiveMaterial);
    const hasCon =
      hasConserved || r.reactionInputs.some(input => input.conserved);
    hasInputMaterial = hasInputMaterial || hasIM;
    hasInputAdditiveMaterial = hasInputAdditiveMaterial || hasIA;
    hasConserved = hasConserved || hasCon;

    const hasOM =
      hasOutputMaterial ||
      r.reactionOutputs.some(output => output.outputMaterial);
    const hasOA =
      hasOutputAdditiveMaterial ||
      r.reactionOutputs.some(output => output.outputAdditiveMaterial);
    hasOutputMaterial = hasOutputMaterial || hasOM;
    hasOutputAdditiveMaterial = hasOutputAdditiveMaterial || hasOA;
  });

  if (hasReactionName) {
    schema.fields.push("name");
  }

  const withSampleStatus = (mats, { sampleStatusInfo }) => {
    if (sampleStatusInfo) {
      return (
        <>
          {mats.map((mat, i) => {
            const info = sampleStatusInfo[mat.id];
            const msg = getSampleStatusMessage(info);
            const valid = msg === "All Valid" || msg === "At Least One Valid";
            return (
              <span
                key={i}
                style={{ marginRight: 7 }}
                className="mat-with-status"
              >
                {makeLink(mat)}{" "}
                <Tooltip
                  content={"Sample Status: " + msg}
                  modifiers={popoverOverflowModifiers}
                  position="top"
                >
                  <Icon
                    intent={valid ? "success" : "warning"}
                    icon={valid ? "tick-circle" : "warning-sign"}
                  />
                </Tooltip>
                {i === mats.length - 1 ? "" : ", "}
              </span>
            );
          })}
        </>
      );
    } else {
      return mats.map((m, i) => {
        return (
          <span key={m.id}>
            {makeLink(m)}
            {i === mats.length - 1 ? "" : ", "}
          </span>
        );
      });
    }
  };

  if (hasInputMaterial) {
    schema.fields.push({
      displayName: "Input Materials",
      path: "reactionInputs.inputMaterial.name",
      render: (v, r) => {
        return withSampleStatus(
          r.reactionInputs
            .filter(i => i.inputMaterial)
            .map(i => i.inputMaterial),
          { sampleStatusInfo }
        );
      }
    });
  }
  if (hasInputAdditiveMaterial) {
    schema.fields.push({
      displayName: "Input Reagents",
      path: "reactionInputs.inputAdditiveMaterial.name",
      render: (v, r) => {
        const items = r.reactionInputs.filter(i => i.inputAdditiveMaterial);
        return items.map(({ conserved, inputAdditiveMaterial }, i) => {
          return (
            <span key={i}>
              {makeLink(
                inputAdditiveMaterial,
                inputAdditiveMaterial.name + (conserved ? "*" : "")
              )}
              {i === items.length - 1 ? "" : ", "}
            </span>
          );
        });
      }
    });
  }
  if (hasOutputMaterial) {
    schema.fields.push({
      displayName: "Output Materials",
      path: "reactionOutputs.outputMaterial.name",
      render: (v, r) => {
        return withSampleStatus(
          r.reactionOutputs
            .filter(i => i.outputMaterial)
            .map(i => i.outputMaterial),
          { sampleStatusInfo }
        );
      }
    });
  }
  if (hasOutputAdditiveMaterial) {
    schema.fields.push({
      displayName: "Output Reagents",
      path: "reactionOutputs.outputAdditiveMaterial.name",
      render: (v, r) => {
        const items = r.reactionOutputs.filter(i => i.outputAdditiveMaterial);
        return items.map(({ outputAdditiveMaterial }, i) => (
          <span key={i}>
            {makeLink(outputAdditiveMaterial, outputAdditiveMaterial.name)}
            {i === items.length - 1 ? "" : ", "}
          </span>
        ));
      }
    });
  }
  schema.hasConserved = hasConserved;
  if (hasExecutedDate) {
    schema.fields.push({
      displayName: "Last Executed",
      path: "executedAt",
      type: "timestamp"
    });
  }
  return schema;
}

export async function exportReactionMaps(reactionMapIds) {
  const clearProgressToast = showProgressToast(`Exporting reaction maps...`);
  try {
    const reactionMaps = await formatReactionMapsForExport(reactionMapIds);
    const filesToZip = [];
    reactionMaps.forEach(rm => {
      filesToZip.push({
        name: rm.name + ".csv",
        data: rm.contents
      });
    });
    download(await handleZipFiles(filesToZip), "reactionMaps.zip");
  } catch (error) {
    console.error(`error:`, error);
    window.toastr.error("Error exporting reaction maps.");
  }
  clearProgressToast();
}
export const reactionMapCsvFields = [
  {
    path: "REACTION_NAME",
    description:
      "Name of the reaction. Optional: If left empty will default to Reaction 1, Reaction 2, etc.",
    example: "DNA Amplification"
  },
  {
    path: "INPUT_MATERIALS",
    description:
      "Materials used as inputs for the reaction. Conditional: Required if not providing input reagents.",
    example: "DNA template, primers, nucleotides"
  },
  {
    path: "INPUT_REAGENTS",
    description:
      "Reagents used as inputs for the reaction. Conditional: Required if not providing input materials.",
    example: "Polymerase enzyme, buffer solution"
  },
  {
    path: "INPUT_REAGENTS_CONSERVED",
    description:
      "Reagents that are conserved during the reaction. Optional: These reagents will remain in the destination well after the reaction.",
    example: "Water, dNTPs"
  },
  {
    path: "OUTPUT_MATERIALS",
    description:
      "Materials produced as outputs from the reaction. Conditional: Required if not providing an output reagent.",
    example: "Amplified DNA fragments"
  },
  {
    path: "OUTPUT_REAGENTS",
    description:
      "Reagents produced as outputs from the reaction. Conditional: Required if not providing an output material.",
    example: "Unused primers, residual buffer"
  }
];

const j5ReportFragmentForReactionMap = gql`
  fragment j5ReportFragmentForReactionMap on j5Report {
    id
    name
    j5PcrReactions {
      id
      # for metadata
      oligoMeanTm
      oligoDeltaTm
      oligoMeanTm3Prime
      oligoDeltaTm3Prime
      #input
      primaryTemplate {
        id
        polynucleotideMaterialId
        polynucleotideMaterial {
          id
          name
        }
      }
      forwardPrimer {
        id
        sequence {
          id
          polynucleotideMaterialId
          polynucleotideMaterial {
            id
            name
          }
        }
      }
      reversePrimer {
        id
        sequence {
          id
          polynucleotideMaterialId
          polynucleotideMaterial {
            id
            name
          }
        }
      }
      #output
      pcrProductSequence {
        id
        polynucleotideMaterialId
      }
    }
    #reaction map 2
    j5RunConstructs {
      id
      #input
      j5ConstructAssemblyPieces {
        id
        assemblyPiece {
          id
          sequence {
            id
            polynucleotideMaterialId
            polynucleotideMaterial {
              id
              name
            }
          }
        }
      }
      #output
      sequence {
        id
        polynucleotideMaterialId
      }
    }
  }
`;

export async function createAssemblyReportReactionMaps({
  j5ReportId,
  pcrName,
  assemblyName,
  userTriggered = false
}) {
  let reportWithMaterials = await safeQuery(j5ReportFragmentForReactionMap, {
    variables: {
      id: j5ReportId
    }
  });

  const missingAssemblyReactions = reportWithMaterials.j5RunConstructs.every(
    j5RunConstruct => j5RunConstruct.j5ConstructAssemblyPieces.length === 0
  );
  const missingPCRReactions = reportWithMaterials.j5PcrReactions.length === 0;
  let missingMaterial = reportWithMaterials.j5PcrReactions.some(pcrReaction => {
    return (
      !get(pcrReaction, "primaryTemplate.polynucleotideMaterialId") ||
      !get(pcrReaction, "forwardPrimer.sequence.polynucleotideMaterialId") ||
      !get(pcrReaction, "reversePrimer.sequence.polynucleotideMaterialId") ||
      !get(pcrReaction, "pcrProductSequence.polynucleotideMaterialId")
    );
  });
  missingMaterial =
    missingMaterial ||
    reportWithMaterials.j5RunConstructs.some(j5RunConstruct => {
      return (
        !get(j5RunConstruct, "sequence.polynucleotideMaterialId") ||
        j5RunConstruct.j5ConstructAssemblyPieces.some(
          j5ConstructAssemblyPiece => {
            return !get(
              j5ConstructAssemblyPiece,
              "assemblyPiece.sequence.polynucleotideMaterialId"
            );
          }
        )
      );
    });
  if (missingMaterial) {
    if (!userTriggered) return;
    const shouldLink = await showConfirmationDialog({
      text: "This report is not linked to materials. Would you like to link now?",
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    });
    if (!shouldLink) return;
    await handleLinkAllReportMaterials(j5ReportId);
    // requery for updated data
    reportWithMaterials = await safeQuery(j5ReportFragmentForReactionMap, {
      variables: {
        id: j5ReportId
      }
    });
  }
  const reactionMapForPcr = {
    name: pcrName || reportWithMaterials.name + " PCR Reaction Map",
    reactionTypeCode: "PCR_REACTION",
    reactions: []
  };
  reportWithMaterials.j5PcrReactions.forEach((pcrReaction, i) => {
    reactionMapForPcr.reactions.push({
      name: "reaction " + (i + 1),
      ...getReactionInputsAndOutputs({
        inputMaterials: [
          {
            id: pcrReaction.primaryTemplate.polynucleotideMaterial.id,
            reactionRoleType: "PRIMARY_TEMPLATE"
          },
          {
            id: pcrReaction.forwardPrimer.sequence.polynucleotideMaterial.id,
            reactionRoleType: "FORWARD_PRIMER"
          },
          {
            id: pcrReaction.reversePrimer.sequence.polynucleotideMaterial.id,
            reactionRoleType: "REVERSE_PRIMER"
          }
        ],
        outputMaterialId:
          pcrReaction.pcrProductSequence.polynucleotideMaterialId
      }),
      reactionDetails: {
        oligoMeanTm: pcrReaction.oligoMeanTm,
        oligoDeltaTm: pcrReaction.oligoDeltaTm,
        oligoMeanTm3Prime: pcrReaction.oligoMeanTm3Prime,
        oligoDeltaTm3Prime: pcrReaction.oligoDeltaTm3Prime
      }
    });
  });
  const reactionMapForAssembly = {
    name: assemblyName || reportWithMaterials.name + " Assembly Reaction Map",
    reactionTypeCode: "ASSEMBLY_REACTION",
    reactions: []
  };
  reportWithMaterials.j5RunConstructs.forEach((j5RunConstruct, i) => {
    reactionMapForAssembly.reactions.push({
      name: "reaction " + (i + 1),
      ...getReactionInputsAndOutputs({
        outputMaterialId: j5RunConstruct.sequence.polynucleotideMaterialId,
        inputMaterials: j5RunConstruct.j5ConstructAssemblyPieces.map(piece =>
          get(piece, "assemblyPiece.sequence.polynucleotideMaterial")
        )
      })
    });
  });
  const reactionMapsToCreate = [];
  const toastIfUserTriggered = userTriggered ? window.toastr : undefined;
  if (missingPCRReactions) {
    toastIfUserTriggered?.warning(
      "No PCR reactions found on DNA assembly report."
    );
  } else {
    reactionMapsToCreate.push(reactionMapForPcr);
    toastIfUserTriggered?.success("Created PCR reaction map");
  }
  if (missingAssemblyReactions) {
    toastIfUserTriggered?.warning(
      "No assembly reactions found on DNA assembly report."
    );
  } else {
    reactionMapsToCreate.push(reactionMapForAssembly);
    toastIfUserTriggered?.success("Created assembly reaction map");
  }
  if (!missingPCRReactions || !missingAssemblyReactions) {
    await safeUpsert("reactionMap", [
      reactionMapForAssembly,
      reactionMapForPcr
    ]);
  }
}
