/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import {
  importCollectionModels,
  workflowCollectionModels,
  labModels,
  externalRecordIdentifierModels,
  externalReferenceKeys,
  isDeprecatedModels,
  barcodeModels,
  lockableModels,
  expirationDateModels
} from "../../../tg-iso-shared/constants";

function identity<T>(x: T) {
  return x;
}

export const getGeneralFragmentFields = (
  model: string,
  options: {
    updateableModel?: string;
    wrapWithNestModel?: (model: string) => string;
    isUsingView?: boolean;
    noAddedBy?: boolean;
    noUser?: boolean;
  } = {}
) => {
  const {
    updateableModel,
    wrapWithNestModel = identity,
    isUsingView
  } = options;

  const modelToUse = updateableModel || model;
  const hasLab = labModels.includes(modelToUse);
  const withImportCollection = importCollectionModels.includes(modelToUse);
  const withWorkflowCollection = workflowCollectionModels.includes(modelToUse);
  const withRecordLocking =
    window.frontEndConfig.userRecordLocking &&
    lockableModels.includes(modelToUse);
  const withExternalReference =
    externalRecordIdentifierModels.includes(modelToUse);
  const withBarcodes = barcodeModels.includes(modelToUse);
  const withDeprecation = isDeprecatedModels.includes(modelToUse);
  let fragment = "";
  if (withImportCollection) {
    fragment += `
      importCollection {
        id
        name
      }
    `;
  }

  if (withExternalReference) {
    fragment += wrapWithNestModel(`
    ${externalReferenceKeys.join("\n")}
  `);
  }

  if (expirationDateModels.includes(model)) {
    fragment += `
    expirationDate
    `;
  }
  if (withBarcodes) {
    if (isUsingView) {
      fragment += `
        barcodeString
      `;
    }
    fragment += wrapWithNestModel(`
      barcode {
        id
        barcodeString
      }
    `);
  }

  if (withDeprecation) {
    fragment += `
      isDeprecated
    `;
  }
  if (withRecordLocking) {
    fragment += wrapWithNestModel(`
      lockId
      childLockId
    `);
  }
  if (withWorkflowCollection) {
    fragment += `
      workflowCollection {
        id
        workflowRun {
          id
          name
        }
      }
    `;
  }
  if (hasLab) {
    fragment += `
      lab {
        id
        name
      }
    `;
  }
  if (!options.noAddedBy && !options.noUser) {
    fragment += `
    user {
      id
      username
      email
    }
    `;
    if (!isUsingView) {
      fragment += `
      updatedByUser {
        id
        username
        email
      }`;
    }
  }

  fragment += `
      createdAt
      updatedAt
    `;
  return fragment;
};
