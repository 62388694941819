/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Link } from "react-router-dom";
import { Classes, AnchorButton } from "@blueprintjs/core";
import { extendedPropertyFragment } from "../../fragments/extendedPropertyFragment.gql";
import { DialogFooter, SelectField, wrapDialog } from "@teselagen/ui";
import type { InjectedWrapDialogProps } from "@teselagen/ui";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "recompose";
import { reduxForm, change, InjectedFormProps } from "redux-form";
import GenericSelect from "../../GenericSelect";
import { extPropTypeFilterAdditionalItems } from "../../../../tg-iso-shared/src/utils/extendedPropertiesUtils";
import { camelCase, some } from "lodash";
import { classFilter } from "./classFilter";
import { modelTypeMap } from "../../../../tg-iso-shared/src/utils/modelTypeMap";
import { getFieldFromExtProp } from "./getFieldFromExtProp";
import { ValidateAgainstSchemaType } from "./types";

const form = "addExtendedPropertyCsvWizard";

export type Props = {
  extendedPropTypes: string[];
  validateAgainstSchema: ValidateAgainstSchemaType;
};

type FormData = {
  extendedProperty: any;
};

const _DownloadTemplateFileButtonDialog = ({
  hideModal,
  handleSubmit,
  extendedPropTypes,
  validateAgainstSchema
}: InjectedWrapDialogProps & InjectedFormProps<FormData> & Props) => {
  const dispatch = useDispatch();
  const propType = useSelector<
    {
      form?: {
        [form: string]: {
          values: {
            propType: string;
          };
        };
      };
    },
    string | undefined
  >(state => state.form?.[form]?.values?.propType);
  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          to="/settings/extended-properties"
        >
          <AnchorButton
            style={{ marginBottom: 10 }}
            data-tip="Extended Properties Settings"
            icon="cog"
          >
            Extended Properties Settings
          </AnchorButton>
        </Link>

        <SelectField
          label="Extended Property Type"
          defaultValue={extendedPropTypes[0]}
          name="propType"
          options={extendedPropTypes}
          onFieldSubmit={() => {
            dispatch(change(form, "extendedProperty", null));
          }}
        />
        <GenericSelect
          name="extendedProperty"
          isRequired
          asReactSelect
          label="Extended Property"
          fragment={extendedPropertyFragment}
          additionalFilter={(props, qb) => {
            const modelToUse =
              extPropTypeFilterAdditionalItems[
                propType as keyof typeof extPropTypeFilterAdditionalItems
              ] || camelCase(propType);
            classFilter(qb, {
              modelTypeCode:
                modelTypeMap[modelToUse as keyof typeof modelTypeMap]
            });
          }}
        />
      </div>
      <DialogFooter
        onClick={handleSubmit(({ extendedProperty }) => {
          const newField = getFieldFromExtProp(extendedProperty);
          if (
            some(validateAgainstSchema.fields, {
              path: newField.path
            })
          )
            return window.toastr.warning(
              "This extended property column has already been added"
            );
          validateAgainstSchema.fields.push(newField);
          window.toastr.success("Extended Property Column Added");
          hideModal();
        })}
      />
    </>
  );
};

export const DownloadTemplateFileButtonDialog = compose<
  InjectedWrapDialogProps & InjectedFormProps<FormData> & Props,
  Props
>(
  wrapDialog({ title: "Add Extended Property" }),
  reduxForm({ form })
)(_DownloadTemplateFileButtonDialog);
