/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useMemo } from "react";
import { compose } from "recompose";
import { DialogFooter, FileUploadField } from "@teselagen/ui";
import { Callout, Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { wrapDialog } from "@teselagen/ui";

import modelNameToReadableName from "../utils/modelNameToReadableName";
import { groupBy } from "lodash";
import { safeQuery } from "../apolloMethods";
import caseInsensitiveFilter from "../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import upsertUniqueAliases from "../../../tg-iso-shared/src/sequence-import-utils/upsertUniqueAliases";
import { getDownloadTemplateFileHelpers } from "../components/DownloadTemplateFileButton";
import { noop } from "lodash";

const fields = [
  {
    path: "name",
    description: "The name of the descriptor",
    example: "Descriptor A",
    isRequired: true
  },
  {
    path: "alias",
    description: "The alias of the descriptor",
    example: "Desc A"
  }
];

function UploadAliasesCsvDialog(props) {
  const {
    model,
    submitting,
    hideModal,
    handleSubmit,
    additionalFilter = noop
  } = props;

  async function onSubmit({ aliasCsv }) {
    const { model, reset, refetch, hideModal } = props;
    try {
      const namesToAlias = {};
      for (const [index, row] of aliasCsv[0].parsedData.entries()) {
        let { alias = "", name = "" } = row;
        name = name.trim();
        alias = alias.trim();
        if (!name) {
          return window.toastr.error(
            `Row ${index + 1} did not provide a valid name`
          );
        }
        if (alias) {
          if (!namesToAlias[name]) {
            namesToAlias[name] = [];
          }
          namesToAlias[name].push(alias);
        }
      }
      const qb = caseInsensitiveFilter(
        model,
        "name",
        Object.keys(namesToAlias),
        {
          returnQb: true
        }
      );
      additionalFilter(qb);
      const records = await safeQuery([model, "id name aliases { id name }"], {
        variables: {
          filter: qb.toJSON()
        }
      });
      const groupedRecords = groupBy(records, r => r.name.toLowerCase());
      const aliasesToCreate = [];
      for (const name of Object.keys(namesToAlias)) {
        const matchingRecords = groupedRecords[name.toLowerCase()];
        if (!matchingRecords) {
          return window.toastr.error(
            `No ${modelNameToReadableName(model)} found with the name ${name}`
          );
        }
        if (matchingRecords.length > 1) {
          return window.toastr.error(
            `Multiple ${modelNameToReadableName(model, {
              plural: true
            })} found with the name ${name}`
          );
        }
        const record = matchingRecords[0];
        const newAliases = namesToAlias[name];
        newAliases.forEach(alias => {
          if (
            // don't duplicate
            !record.aliases.some(
              a => a.name.toLowerCase().trim() === alias.toLowerCase().trim()
            )
          ) {
            aliasesToCreate.push({
              name: alias,
              [model + "Id"]: record.id
            });
          }
        });
      }

      await upsertUniqueAliases(aliasesToCreate);
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error(error.message || "Error parsing and saving tags.");
      reset();
    }
  }

  const accept = useMemo(
    () =>
      getDownloadTemplateFileHelpers({
        fileName: "alias_upload",
        validateAgainstSchema: {
          fields
        }
      }),
    []
  );

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <Callout intent="primary">
          Upload a csv with a list of{" "}
          {modelNameToReadableName(model, { plural: true })} by name and an
          alias.
        </Callout>
        <FileUploadField
          fileLimit={1}
          isRequired
          accept={accept}
          name="aliasCsv"
        />
      </div>
      <DialogFooter
        {...{ submitting, hideModal, onClick: handleSubmit(onSubmit) }}
      />
    </>
  );
}

export default compose(
  wrapDialog({ title: "Upload Aliases CSV" }),
  reduxForm({
    form: "uploadAliasesCsv"
  })
)(UploadAliasesCsvDialog);
