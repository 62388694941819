/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback } from "react";
import { compose } from "recompose";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import {
  DialogFooter,
  BlueprintError,
  InputField,
  wrapDialog
} from "@teselagen/ui";
import classNames from "classnames";
import TagField from "../../../../src-shared/TagField";

import PlateUploadFields from "../../PlateUploadFields";
import handleTwistPlateImport from "../../../../../tg-iso-lims/src/plateImports/handleTwistPlateImport";
import { throwFormError } from "../../../../src-shared/utils/formUtils";
import defaultValueConstants from "../../../../../tg-iso-shared/src/defaultValueConstants";
import { wellLocationField } from "../../../../../tg-iso-shared/src/utils/unitCodeFields";

export async function submitTwistPlate(values, options) {
  const { makeError } = options;
  try {
    return await handleTwistPlateImport({ ...values, ...options });
  } catch (error) {
    console.error("error:", error);
    makeError(error.message || error.errors || "Error uploading plate.");
  }
}

const templateFileOptions = {
  fileName: "Twist_Plate",
  isTwistUpload: true,
  validateAgainstSchema: {
    fields: [
      {
        path: "Name",
        isRequired: true,
        description: "The name.",
        example: "Name123"
      },
      {
        path: "Insertion point name",
        description: "The name of the insertion point.",
        example: "InsertionPoint456"
      },
      {
        path: "Vector name",
        description: "The name of the vector.",
        example: "Vector789"
      },
      {
        path: "Insert length",
        description: "The length of the insert.",
        example: "100",
        type: "number"
      },
      {
        path: "Construct length",
        description: "The length of the construct.",
        example: "200",
        type: "number"
      },
      {
        path: "Insert sequence",
        description: "The sequence of the insert.",
        example: "ATCG"
      },
      {
        path: "Construct sequence",
        description: "The sequence of the construct.",
        example: "GCTA"
      },
      { ...wellLocationField, isRequired: false },
      {
        path: "NGS",
        description: "The NGS value.",
        example: "NGS123"
      },
      {
        path: "Yield (ng)",
        isRequired: true,
        description: "The yield in nanograms.",
        example: "150",
        type: "number"
      },
      {
        path: "Product type",
        description: "The type of the product.",
        example: "ProductType456"
      },
      {
        path: "Plate ID",
        description: "The ID of the plate.",
        example: "Plate789"
      },
      {
        path: "Tube barcode",
        description: "The barcode of the tube.",
        example: "Tube123"
      }
    ]
  },
  extendedPropTypes: ["sequence", "aliquot", "sample", "tube", "plate"],
  helper: "To attach sequences upload genbank files along with the csv"
};

const generateDefaultValue = {
  ...defaultValueConstants.PLATE_NAME_PREFIX
};

const UploadTwistPlateUnwrapped = ({
  refetch,
  noDialog,
  submitting,
  handleSubmit,
  hideModal,
  fieldPrefix = "",
  error
}) => {
  const onSubmit = useCallback(
    async values => {
      const makeError = error => {
        throwFormError({
          _error: error
        });
      };
      await submitTwistPlate(values, {
        makeError,
        refetch
      });
    },
    [refetch]
  );

  return (
    <>
      <div className={classNames({ [Classes.DIALOG_BODY]: !noDialog })}>
        <InputField
          name={fieldPrefix + "plateName"}
          label="Plate Name Prefix"
          placeholder="Enter plate name prefix..."
          generateDefaultValue={generateDefaultValue}
        />
        <PlateUploadFields
          fieldPrefix={fieldPrefix}
          inTool={noDialog}
          stripAdaptersOption={true}
          uploadHelperText="Upload a .zip including a CSV of one or more plates received from Twist and genbank files for any associated sequences. This prevents duplication of sequence data."
          templateFileOptions={templateFileOptions}
        />
        <TagField name={fieldPrefix + "tags"} />
        <BlueprintError error={error} />
      </div>
      {!noDialog && (
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          onClick={handleSubmit(onSubmit)}
        />
      )}
    </>
  );
};

// the inner logic and upload is getting used elsewhere (CsvPlateRegistrationTool)
export const UploadTwistPlateDialog = UploadTwistPlateUnwrapped;

export default compose(
  wrapDialog({
    title: "Upload Twist CSV"
  }),
  reduxForm({
    form: "uploadTwistForm",
    onSubmitFail: (...args) => {
      console.error("Error hit in upload twist plate onSubmit:", ...args);
    }
  })
)(UploadTwistPlateDialog);
