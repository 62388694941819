/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import {
  includeTagModels as buildTagModels,
  includeWorkflowCollectionModels as buildIncludeWorkflowCollectionModels,
  includeExternalRecordIdentifierModels as buildIncludeExternalRecordIdentifierModels,
  includeIsDeprecatedModels as buildIncludeIsDeprecatedModels,
  extendedPropertyLinkableLimsModels,
  includeExtendedPropertyLimsModels,
  includeImportCollectionModels
} from "@teselagen/constants";
import buildBarcodeModels from "../tg-iso-lims/src/utils/barcodeModels";
import { lockableModels as _lockableModels } from "./src/lockableModels";
import buildExpirationDateModels from "../tg-iso-lims/src/utils/includeExpirationDateModels";
import { makeMaterialSearchObject } from "./utils/makeMaterialSearchObject";

export const tagModels = buildTagModels;
export const expirationDateModels = buildExpirationDateModels;
export const importCollectionModels = includeImportCollectionModels;
// aliquots are not included in the workflow collection models yet because they are not a
// direct output from a workflow tool
export const workflowCollectionModels =
  buildIncludeWorkflowCollectionModels.filter(m => m !== "aliquot");
export const externalRecordIdentifierModels =
  buildIncludeExternalRecordIdentifierModels;
export const externalRecordIdentifierModelsNoUpdate = [
  "reactionMap",
  "reactionDesign",
  "equipmentItem",
  "customerRequest"
];
export const isDeprecatedModels = buildIncludeIsDeprecatedModels;
export const extendedPropertyLinkableModels =
  extendedPropertyLinkableLimsModels;
export const lockableModels = _lockableModels;
export const barcodeModels = buildBarcodeModels;
export const labModels = [
  "additiveMaterial",
  "project",
  "alignment",
  "aliquot",
  "aliquotContainer",
  "aminoAcidSequence",
  "batch",
  "containerArray",
  "customerRequest",
  "customQuery",
  "dataSet",
  "dataTable",
  "functionalProteinUnit",
  "genome",
  "j5Report",
  "job",
  "lot",
  "material",
  "plateMapGroup",
  "reactionMap",
  "sample",
  "sequence",
  "sequencingQualityControlReport",
  "strain",
  "vendorOrder",
  "workflowDefinition",
  "workflowRun",
  "worklist",
  "regionAnnotation",
  "sequenceFeature",
  "dataGrid",
  "assaySubject",
  "experiment",
  "assay",
  "importFileSet",
  "evolveModel",
  "codonMap",
  "design",
  "part",
  "partset",
  "schematic",
  "enzyme",
  "entry"
];
export const labModelsNoEdit = ["j5Report"];
export const batchModels = [
  "material",
  "sample",
  "aliquot",
  "aliquotContainer",
  "dataSet",
  "dataTable",
  "containerArray",
  "vendorOrder",
  // "lot", TODO add to batches to datamodel
  "j5Report",
  "plateMapGroup",
  "reactionMap",
  "worklist"
];

export const extendedPropertyModels = includeExtendedPropertyLimsModels;

export const externalReferenceKeys = [
  "externalReferenceSystem",
  "externalReferenceId",
  "externalReferenceType",
  "externalReferenceUrl"
];

export const universalSearchModels = {
  models: [
    "design",
    "schematic",
    "part",
    "partset",
    "ruleSet",
    "alignment",
    "aminoAcidPart",
    "codonMap",
    "additiveMaterial",
    "aliquot",
    "aliquotContainer",
    "aminoAcidSequence",
    "container",
    "containerArray",
    "customerRequest",
    "dataSet",
    "dataTable",
    "equipmentItem",
    "functionalProteinUnit",
    "j5Report",
    "job",
    "location",
    "lot",
    "plateMapGroup",
    "labProtocol",
    "reactionMap",
    "reactionDesign",
    "sample",
    "strain",
    "workflowRun",
    "worklist",
    "restrictionEnzyme",
    {
      model: "sequence",
      displayName: "Sequence",
      additionalFilter: qb => {
        return qb.whereAll({
          sequenceTypeCode: qb.notEquals("OLIGO")
        });
      },
      route: `/sequences`
    },
    {
      model: "sequence",
      displayName: "Oligo",
      additionalFilter: qb => {
        return qb.whereAll({
          sequenceTypeCode: "OLIGO"
        });
      },
      route: `/oligos`
    },
    makeMaterialSearchObject("DNA"),
    makeMaterialSearchObject("PROTEIN"),
    makeMaterialSearchObject("MICROBIAL")
  ],
  noNameModels: ["aliquot"]
};
