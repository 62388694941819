/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { TgSelect, TextareaField, DialogFooter } from "@teselagen/ui";
import { flatMap, get } from "lodash";
import defaultAsyncWrap from "../../../src-shared/utils/defaultAsyncWrap";
import { safeUpsert, useTgQuery } from "../../../src-shared/apolloMethods";
import { useState } from "react";
import { upsertDesignApprovalNotification } from "../../utils";
import { getActiveLabId } from "@teselagen/auth-utils";
import { wrapDialog } from "@teselagen/ui";
import appGlobals from "../../../src-shared/appGlobals";

function AddDesignReviewersDialog(props) {
  const { hideModal, submitting, handleSubmit, refetch, designId } = props;
  const [newReviewers, setNewReviewers] = useState([]);

  const {
    data: { design },
    ...rest
  } = useTgQuery(
    [
      "design",
      `name id userId activeSubmissionId activeSubmission {
        id stageId
        submissionStageApprovers {
          id
          submissionStageApproverStatusCode
          stageApprover {
            id
            user {
              id
              username
            }
            userId
          }
        }
        stage {
          id name
          stageApprovers {
            id userId user {
              id username labRoles { id lab { id } }
            }
          }
    }}
    `
    ],
    {
      variables: {
        id: designId
      }
    }
  );
  if (!designId) return window.toastr.warning("No designId found");

  if (useTgQuery.checkErrAndLoad(rest))
    return useTgQuery.handleErrAndLoad(rest);

  if (!design) return window.toastr.warning("No design found");

  const onSubmit = defaultAsyncWrap(async ({ comment }) => {
    await safeUpsert(
      "submissionStageApprover",
      newReviewers.map(({ fullVal: { id } }) => ({
        stageApproverId: id,
        submissionId: design.activeSubmissionId,
        submissionStageApproverStatusCode: "READY"
      }))
    );
    const [newComment] = await safeUpsert("comment", {
      headerMsg: `Added ${newReviewers
        .map(({ fullVal }) => fullVal.user.username)
        .join(" and ")} as reviewer${newReviewers.length > 1 ? "s" : ""}`,
      message: comment,
      submissionId: design.activeSubmissionId,
      userId: localStorage.getItem("userId")
    });

    for (const reviewer of newReviewers) {
      await upsertDesignApprovalNotification({
        userId: reviewer.value,
        stageName: design.activeSubmission.stage.name,
        designName: design.name,
        commentId: newComment.id,
        extraMessage: comment,
        designId: design.id,
        activeSubmissionId: design.activeSubmissionId
      });
    }
    window.refetchNotifications && window.refetchNotifications();
    window.__tgReloadComments && window.__tgReloadComments();
    refetch && (await refetch());
    hideModal();
  }, "Error submitting design for approval.");
  const existingReviewers = (
    design.activeSubmission.submissionStageApprovers || []
  ).map(({ stageApprover } = {}) => ({
    fullVal: stageApprover.user,
    value: stageApprover.userId,
    label: stageApprover.user.username,
    disabled: true
  }));
  const possibleNewReviewers =
    flatMap(design.activeSubmission.stage.stageApprovers, s => {
      const labRoles = get(s, "user.labRoles") || [];
      const activeLabId = getActiveLabId();
      if (
        //if they're not in the lab the design is in don't let them be a reviewer
        activeLabId &&
        !labRoles.find(({ lab }) => lab.id === activeLabId)
      ) {
        return [];
      }
      if (s.userId === design.userId) {
        //don't let the creator be a reviewer
        return [];
      }
      if (s.userId === appGlobals.currentUser.id) {
        //don't let the person editing the list be a reviewer
        return [];
      }
      return {
        label: s.user.username,
        value: s.userId,
        fullVal: s,
        isNewReviewer: true
      };
    }) || [];
  const options = [...existingReviewers, ...possibleNewReviewers];

  return (
    <React.Fragment>
      <div style={{ padding: 20 }}>
        <h5>Add Additional Reviewers</h5>
        <div style={{ margin: "10px 0px", fontSize: 10 }}>
          Note: You can't add yourself or the design creator as a reviewer
        </div>
        <TgSelect
          className="addDesignReviewersSelect"
          isRequired
          multi
          options={options}
          onChange={reviewers => {
            setNewReviewers(reviewers.filter(s => s.isNewReviewer));
          }}
          value={[...existingReviewers, ...newReviewers]}
          name="reviewers"
        />
        <br></br>
        <br></br>
        <TextareaField
          name="comment"
          label="Comment"
          defaultValue="Please review this design."
        />
      </div>
      <DialogFooter
        hideModal={hideModal}
        disabled={!newReviewers.length}
        onClick={handleSubmit(onSubmit)}
        loading={submitting}
      />
    </React.Fragment>
  );
}

export default compose(
  wrapDialog({
    title: "Add Additional Reviewers",
    style: { width: 350 }
  }),
  reduxForm({
    form: "AddDesignReviewersDialog", // a unique name for this form
    enableReinitialize: true
    // validate
  })
)(AddDesignReviewersDialog);
