/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import { get, identity } from "lodash";
import { unparse } from "papaparse";
import { safeQuery } from "../../../../src-shared/apolloMethods";
import { cleanCsvExport } from "../../../../../tg-iso-shared/src/utils/fileUtils";
import { sequenceFragment } from "../../../../../tg-iso-shared/src/fragments/sequenceFragment.gql";
import { exportExtendedPropertyCsvHelper } from "../../../../src-shared/utils/extendedPropertyUtils";
import { download } from "../../../../src-shared/utils/downloadTest";
import { getSequenceGenbank } from "../../../../../tg-iso-shared/src/sequence-import-utils/utils";
import { handleZipFiles } from "../../../../../tg-iso-shared/src/utils/fileUtils";

/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const exportStrainFragment = gql`
  fragment exportStrainFragment on strain {
    id
    name
    aliases {
      id
      name
    }
    biosafetyLevel {
      code
      name
    }
    genome {
      id
      name
    }
    genotype
    description
    targetOrganismClass {
      id
      name
    }
    strainSelectionMethods {
      id
      selectionMethod {
        id
        name
      }
    }
    specie {
      id
      name
      genus {
        id
        name
      }
    }
    strainPlasmids {
      id
      polynucleotideMaterial {
        id
        polynucleotideMaterialSequence {
          id
          name
        }
      }
    }
    growthCondition {
      id
      name
      description
      shakerSpeed
      shakerThrow
      lengthUnitCode
      temperature
      growthMedia {
        id
        name
      }
    }
    extendedValues {
      id
      value
      extendedPropertyId
      extendedProperty {
        id
        name
      }
    }
    extendedCategoryValues {
      id
      extendedCategory {
        id
        name
      }
      extendedPropertyId
      extendedProperty {
        id
        name
      }
    }
    extendedMeasurementValues {
      id
      value
      measurementUnit {
        id
        abbreviation
      }
      extendedPropertyId
      extendedProperty {
        id
        name
      }
    }
  }
`;

export async function handleStrainsExport(strainIds) {
  try {
    const strains = await safeQuery(exportStrainFragment, {
      variables: {
        pageSize: strainIds.length,
        filter: {
          id: strainIds
        }
      }
    });

    const sequenceIdsToInclude = [];
    const csvRows = [];

    strains.forEach(strain => {
      const growthCondition = strain.growthCondition || {};
      const row = {
        NAME: strain.name,
        ALIAS: strain.aliases.map(a => a.name).join(", "),
        BIOSAFETY_LEVEL: get(strain, "biosafetyLevel.name"),
        GENUS: get(strain, "specie.genus.name"),
        SPECIES: get(strain, "specie.name"),
        GENOTYPE: strain.genotype,
        DESCRIPTION: strain.description,
        GB_FILE: strain.strainPlasmids
          .map(
            p => p.polynucleotideMaterial.polynucleotideMaterialSequence?.name
          )
          .filter(identity)
          .join(", "),
        GENOME_NAME: get(strain, "genome.name"),
        SELECTION_METHOD: strain.strainSelectionMethods
          .map(ssm => ssm.selectionMethod.name)
          .join(", "),
        TARGET_ORGANISM_GROUP: get(strain, "targetOrganismClass.name"),
        GROWTH_CONDITION_NAME: growthCondition.name,
        GROWTH_CONDITION_DESCRIPTION: growthCondition.description,
        GROWTH_MEDIUM: get(growthCondition, "growthMedia.name"),
        TEMPERATURE: growthCondition.temperature,
        SHAKER_SPEED: growthCondition.shakerSpeed,
        SHAKER_THROW: growthCondition.shakerThrow,
        LENGTH_UNIT_CODE: growthCondition.lengthUnitCode
      };
      strain.strainPlasmids.forEach(p => {
        if (p.polynucleotideMaterial?.polynucleotideMaterialSequence) {
          sequenceIdsToInclude.push(
            p.polynucleotideMaterial.polynucleotideMaterialSequence.id
          );
        }
      });
      exportExtendedPropertyCsvHelper({ row, record: strain, type: "strain" });
      csvRows.push(row);
    });
    const csv = unparse(cleanCsvExport(csvRows));
    if (sequenceIdsToInclude.length) {
      const sequences = await safeQuery(sequenceFragment, {
        variables: {
          filter: {
            id: sequenceIdsToInclude
          }
        }
      });
      const files = [
        {
          name: "strains.csv",
          data: csv
        }
      ];
      sequences.forEach(sequence => {
        const sequenceFileContents = getSequenceGenbank(sequence);
        files.push({
          name: `${sequence.name}.gb`,
          data: sequenceFileContents
        });
      });

      download(await handleZipFiles(files), "strains.zip", "application/zip");
    } else {
      download(csv, "strains.csv", "text/csv");
    }
  } catch (error) {
    console.error("error:", error);
    window.toastr.error("Error exporting strains");
  }
}
