/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Icon, Menu, MenuItem, Tooltip } from "@blueprintjs/core";
import PlatePreviewDialog from "../components/Dialogs/PlatePreviewDialog";
import { showDialog } from "../../src-shared/GlobalDialog";
import { openInNewTab } from "../../src-shared/utils/generalUtils";
import modelNameToLink from "../../src-shared/utils/modelNameToLink";

const aliquotContextMenu = (aliquotContainer: {
  aliquot: { id: string; __typename: string };
}) => {
  if (aliquotContainer.aliquot) {
    return (
      <Menu>
        <MenuItem
          text="View Aliquot"
          onClick={() => {
            openInNewTab(modelNameToLink(aliquotContainer.aliquot));
          }}
        />
      </Menu>
    );
  }
  return null;
};

const platePreviewColumn = function (
  options: {
    plateErrors?: { [id: string]: { _error: string } };
    plateWarnings?: { [id: string]: string };
  } & { [otherOptions: string]: string } = {}
) {
  const renderPlatePreview = (
    val: any,
    plate: { id: string; name: string; __typename: string }
  ) => {
    const { plateErrors = {}, plateWarnings = {}, ...optionsToPass } = options;
    const errorsForPlate = plateErrors[plate.id];
    const warningsForPlate = plateWarnings[plate.id];

    let errorIcon;
    if (errorsForPlate || warningsForPlate) {
      let error;
      const errorsOrWarnings = errorsForPlate || warningsForPlate;
      if (errorsOrWarnings._error) {
        error = errorsOrWarnings._error;
      } else {
        error =
          "There were some problems with this plate. Preview plate for more information.";
      }
      errorIcon = (
        // @ts-ignore
        <Tooltip content={error}>
          <Icon
            intent={errorsForPlate ? "danger" : "warning"}
            style={{ marginRight: 12 }}
            icon="warning-sign"
          />
        </Tooltip>
      );
    }

    const previewIcon = (
      <Icon
        style={{
          // to keep icons aligned if some have errors and some do not
          float: "right",
          marginRight: 8
        }}
        icon="eye-open"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          const modalProps: {
            dialogProps: { title: string };
            wellErrors: { _error: string };
            wellWarnings: string;
            aliquotContextMenu: typeof aliquotContextMenu;
            [key: string]: any;
          } = {
            dialogProps: { title: `Preview ${plate.name}` },
            wellErrors: errorsForPlate,
            wellWarnings: warningsForPlate,
            aliquotContextMenu,
            ...optionsToPass
          };
          modalProps[`${plate.__typename}Id`] = plate.id;
          showDialog({
            ModalComponent: PlatePreviewDialog,
            modalProps
          });
        }}
      />
    );
    return (
      <span>
        {errorIcon}
        {previewIcon}
      </span>
    );
  };
  return {
    width: 60,
    noEllipsis: true,
    immovable: true,
    type: "action",
    render: renderPlatePreview
  };
};

Object.assign(platePreviewColumn, platePreviewColumn());

export default platePreviewColumn;
