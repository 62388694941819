/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose, withProps } from "recompose";
import { DataTable, generateField } from "@teselagen/ui";
import genericSelectTableWrapper from "../GenericSelect/genericSelectTableWrapper";
import genericSelectWrapper from "../GenericSelect/genericSelectWrapper";

const TableSelect = ({
  additionalTableProps,
  additionalDataFragment,
  defaultValue,
  idAs = "id",
  initialValues,
  input,
  isMultiSelect,
  name,
  tableParams
}) => {
  if (additionalDataFragment) {
    throw new Error("Do not pass additionalDataFragment to TableSelect");
  }
  if (defaultValue) {
    throw new Error("Do not pass defaultValue to TableSelect");
  }

  let enhancedChildren;
  // the enhanced children will get overwritten if passing children to additionalTableProps
  if (additionalTableProps && additionalTableProps.enhancedChildren) {
    enhancedChildren = additionalTableProps.enhancedChildren({ tableParams });
  }

  let arrayValue = [];
  if (Array.isArray(input.value)) {
    arrayValue = input.value;
  } else if (input.value) {
    arrayValue = [input.value];
  }

  return (
    <DataTable
      key={name}
      noPadding
      withSearch
      withPaging
      doNotShowEmptyRows
      withCheckboxes={isMultiSelect}
      isSingleSelect={!isMultiSelect}
      maxHeight={400}
      onRowSelect={records => {
        if (!isMultiSelect) {
          input.onChange(records[0]);
        } else {
          input.onChange(records);
        }
      }}
      {...tableParams}
      {...additionalTableProps}
      initialValues={{
        ...initialValues,
        reduxFormSelectedEntityIdMap: arrayValue.reduce((acc, entity) => {
          acc[entity[idAs]] = { entity };
          return acc;
        }, {})
      }}
    >
      {enhancedChildren}
    </DataTable>
  );
};

export default compose(
  withProps(({ name }) => {
    return {
      passedName: name
    };
  }),
  genericSelectWrapper,
  genericSelectTableWrapper,
  generateField
)(TableSelect);
