/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { compose } from "redux";
import React, { Component } from "react";
import { MenuItem, MenuDivider, Menu } from "@blueprintjs/core";
import designToJson from "../../src-shared/redux/sagas/exportDesign/designToJson";
import { showDialog } from "../../src-shared/GlobalDialog";
import AbstractLibrary from "../../src-shared/AbstractLibrary";
import libraryEnhancer from "../../src-shared/libraryEnhancer";
import gql from "graphql-tag";
import NameExampleDesignDialog from "../components/NameExampleDesignDialog";
import modelNameToLink from "../../src-shared/utils/modelNameToLink";
import UploadDesignTemplates from "../components/UploadDesignTemplates";
// import DesignRecordView from "../../src-shared/DesignRecordView";

const schema = {
  model: "design",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    {
      path: "numPlaceholders",
      type: "number",
      displayName: "Number of Placeholders"
    }
  ]
};

class DesignTemplateLibrary extends Component {
  handleSubmitForReviewClick = selectedRecords => {
    const { refetchDesigns } = this.props;
    showDialog({
      modalType: "SubmitDesignForReviewDialog",
      modalProps: {
        design: selectedRecords[0],
        refetch: refetchDesigns
      }
    });
  };
  handleDuplicateClick = selectedRecords => {
    const { refetchDesigns } = this.props;
    showDialog({
      modalType: "DUPLICATE_DESIGN",
      modalProps: {
        designs: selectedRecords,
        refetch: refetchDesigns
      }
    });
  };

  additionalContextMenu = selectedRecords => {
    const menuItems = [];
    if (selectedRecords.length === 1) {
      const duplicateMenuItem = (
        <MenuItem
          key="duplicate"
          text="Duplicate"
          onClick={() => this.handleDuplicateClick(selectedRecords)}
        />
      );
      menuItems.push(duplicateMenuItem);
    }
    menuItems.push(
      <MenuDivider key="md2" />,
      <MenuItem
        key="export"
        text="Export"
        onClick={() => {
          window.toastr.success("Download is starting");
          designToJson(
            selectedRecords.length > 1 ? selectedRecords : selectedRecords[0]
          );
        }}
      />
    );
    return menuItems;
  };

  render() {
    const exampleDesignTemplates = [
      "Combinatorial Gibson (with placeholder backbone)",
      "Combinatorial Golden Gate (with placeholder gene)"
    ];
    // This is replaced by webpack, passed in by means of an env var
    if (window.frontEndConfig.atLanzatech) {
      // eslint-disable-line no-undef
      exampleDesignTemplates.push("Cell Free");
    }
    return (
      <AbstractLibrary
        {...this.props}
        // RecordViewInspector={DesignRecordView} //tnr enable this at some point
        generateExtraContextMenuItems={this.additionalContextMenu}
        uploadMenuItems={[
          <MenuItem
            key="uploadDesignTemplates"
            text="Upload Design Templates"
            onClick={() => {
              showDialog({
                ModalComponent: UploadDesignTemplates,
                modalProps: {
                  refetch: this.props.refetch
                }
              });
            }}
          />
        ]}
        onDoubleClick={r => {
          this.props.history.push(
            `${modelNameToLink("designTemplate")}/${r.id}`
          );
        }}
        newItemMenu={
          <Menu>
            <MenuItem
              text="New Design Template"
              onClick={() => {
                this.props.history.push(
                  `${modelNameToLink("designTemplate")}/new`
                );
              }}
            />
            <MenuItem text="Load Example Design Template">
              {exampleDesignTemplates.map(templateName => {
                return (
                  <MenuItem
                    text={templateName}
                    key={templateName}
                    onClick={() => {
                      showDialog({
                        ModalComponent: NameExampleDesignDialog,
                        modalProps: {
                          templateName
                        }
                      });
                    }}
                  ></MenuItem>
                );
              })}
            </MenuItem>
          </Menu>
        }
        withExport
        isLibraryTable
        model="design"
        libraryName="designTemplate"
      />
    );
  }
}

const fragment = gql`
  fragment designTemplateLibraryFragment on design {
    id
    name
    type
    isLocked
    description
    numPlaceholders
  }
`;

export default compose(
  libraryEnhancer({
    schema,
    fragment,
    withSelectedEntities: true,
    additionalFilter: (_, qb) => {
      qb.whereAll({
        type: "design-template"
      });
    }
  })
)(DesignTemplateLibrary);
