/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, {
  Children,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {
  applyChangeToValue,
  countSuggestions,
  escapeRegex,
  findStartOfMentionInPlainText,
  getEndOfLastMention,
  getMentions,
  getPlainText,
  getSubstringIndex,
  makeMentionsMarkup,
  mapPlainTextIndex,
  readConfigFromChildren,
  spliceString
} from "./utils";
import Highlighter from "./Highlighter";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";
import SuggestionsOverlay from "./SuggestionsOverlay";
import useStyle from "substyle";
import isEqual from "lodash/isEqual";
import isNumber from "lodash/isNumber";
import values from "lodash/values";
import { cloneDeep } from "lodash";
import classNames from "classnames";

export const makeTriggerRegex = (trigger, { allowSpaceInQuery } = {}) => {
  if (trigger instanceof RegExp) {
    return trigger;
  } else {
    const escapedTriggerChar = escapeRegex(trigger);

    // first capture group is the part to be replaced on completion
    // second capture group is for extracting the search query
    return new RegExp(
      `(?:^|\\s)(${escapedTriggerChar}([^${
        allowSpaceInQuery ? "" : "\\s"
      }${escapedTriggerChar}]*))$`
    );
  }
};

const getDataProvider = (data, ignoreAccents) => {
  if (data instanceof Array) {
    // if data is an array, create a function to query that
    return function (query) {
      const results = [];
      for (let i = 0, l = data.length; i < l; ++i) {
        const display = data[i].display || data[i].id;
        if (getSubstringIndex(display, query, ignoreAccents) >= 0) {
          results.push(data[i]);
        }
      }
      return results;
    };
  } else {
    // expect data to be a query function
    return data;
  }
};

const KEY = { TAB: 9, RETURN: 13, ESC: 27, UP: 38, DOWN: 40 };

const isMobileSafari =
  typeof navigator !== "undefined" &&
  /iPhone|iPad|iPod/i.test(navigator.userAgent);

const defaultStyle = {
  position: "relative",
  overflowY: "visible",

  input: {
    display: "block",
    position: "absolute",
    top: 0,
    left: 0,
    boxSizing: "border-box",
    backgroundColor: "transparent",
    width: "inherit",
    fontFamily: "inherit",
    fontSize: "inherit",
    letterSpacing: "inherit"
  },

  "&multiLine": {
    input: {
      width: "100%",
      height: "100%",
      bottom: 0,
      overflow: "hidden",
      resize: "none",

      // fix weird textarea padding in mobile Safari (see: http://stackoverflow.com/questions/6890149/remove-3-pixels-in-ios-webkit-textarea)
      ...(isMobileSafari
        ? {
            marginTop: 1,
            marginLeft: -3
          }
        : null)
    }
  }
};

const propTypes = {
  /**
   * If set to `true` a regular text input element will be rendered
   * instead of a textarea
   */
  singleLine: PropTypes.bool,
  allowSpaceInQuery: PropTypes.bool,
  EXPERIMENTAL_cutCopyPaste: PropTypes.bool,
  allowSuggestionsAboveCursor: PropTypes.bool,
  ignoreAccents: PropTypes.bool,
  value: PropTypes.string,
  onKeyDown: PropTypes.func,
  onSelect: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  suggestionsPortalHost:
    typeof Element === "undefined"
      ? PropTypes.any
      : PropTypes.PropTypes.instanceOf(Element),
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current:
        typeof Element === "undefined"
          ? PropTypes.any
          : PropTypes.instanceOf(Element)
    })
  ]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
};

const MentionsInput = ({
  allowSpaceInQuery,
  allowSuggestionsAboveCursor = false,
  children,
  className,
  classNames: _classNames,
  disabled,
  EXPERIMENTAL_cutCopyPaste,
  ignoreAccents = false,
  inputRef: _inputRef,
  onBlur = () => null,
  onChange,
  onKeyDown = () => null,
  onSelect = () => null,
  readOnly,
  singleLine = false,
  style: _style,
  suggestionsPortalHost,
  value = ""
}) => {
  const style = useStyle(
    defaultStyle,
    { style: _style },
    {
      "&singleLine": singleLine,
      "&multiLine": !singleLine
    }
  );
  const _queryId = useRef(0);
  const isComposing = useRef(false);
  const _suggestionsMouseDown = useRef(false);
  const suggestionsRef = useRef(null);
  const highlighterRef = useRef(null);
  const containerRef = useRef(null);
  const inputRef = useRef(null);
  const [focusIndex, setFocusIndex] = useState(0);
  const [selectionStart, setSelectionStart] = useState(null);
  const [selectionEnd, setSelectionEnd] = useState(null);
  const [suggestions, setSuggestions] = useState({});
  const [caretPosition, setCaretPosition] = useState(null);
  const [suggestionsPosition, setSuggestionsPosition] = useState(null);
  const [scrollFocusedIntoView, setScrollFocusedIntoView] = useState(false);

  const saveSelectionToClipboard = useCallback(
    event => {
      const config = readConfigFromChildren(children);

      const markupStartIndex = mapPlainTextIndex(
        value,
        config,
        selectionStart,
        "START"
      );
      const markupEndIndex = mapPlainTextIndex(
        value,
        config,
        selectionEnd,
        "END"
      );

      event.clipboardData.setData(
        "text/plain",
        event.target.value.slice(selectionStart, selectionEnd)
      );
      event.clipboardData.setData(
        "text/react-mentions",
        value.slice(markupStartIndex, markupEndIndex)
      );
    },
    [children, selectionEnd, selectionStart, value]
  );

  const handleCopy = useCallback(
    event => {
      if (event.target !== inputRef.current) {
        return;
      }
      if (!supportsClipboardActions(event)) {
        return;
      }

      event.preventDefault();

      saveSelectionToClipboard(event);
    },
    [saveSelectionToClipboard]
  );

  const executeOnChange = useCallback(
    (event, ...args) => {
      if (onChange) {
        return onChange(event, ...args);
      }
    },
    [onChange]
  );

  const handleCut = useCallback(
    event => {
      if (event.target !== inputRef.current) {
        return;
      }
      if (!supportsClipboardActions(event)) {
        return;
      }

      event.preventDefault();

      saveSelectionToClipboard(event);

      const config = readConfigFromChildren(children);

      const markupStartIndex = mapPlainTextIndex(
        value,
        config,
        selectionStart,
        "START"
      );
      const markupEndIndex = mapPlainTextIndex(
        value,
        config,
        selectionEnd,
        "END"
      );

      const newValue = [
        value.slice(0, markupStartIndex),
        value.slice(markupEndIndex)
      ].join("");
      const newPlainTextValue = getPlainText(newValue, config);

      const eventMock = {
        target: { ...event.target, value: newPlainTextValue }
      };

      executeOnChange(
        eventMock,
        newValue,
        newPlainTextValue,
        getMentions(value, config)
      );
    },
    [
      children,
      executeOnChange,
      saveSelectionToClipboard,
      selectionEnd,
      selectionStart,
      value
    ]
  );

  const handlePaste = useCallback(
    event => {
      if (event.target !== inputRef.current) {
        return;
      }
      if (!supportsClipboardActions(event)) {
        return;
      }

      event.preventDefault();

      const config = readConfigFromChildren(children);

      const markupStartIndex = mapPlainTextIndex(
        value,
        config,
        selectionStart,
        "START"
      );
      const markupEndIndex = mapPlainTextIndex(
        value,
        config,
        selectionEnd,
        "END"
      );

      const pastedMentions = event.clipboardData.getData("text/react-mentions");
      const pastedData = event.clipboardData.getData("text/plain");

      const newValue = spliceString(
        value,
        markupStartIndex,
        markupEndIndex,
        pastedMentions || pastedData
      ).replace(/\r/g, "");

      const newPlainTextValue = getPlainText(newValue, config);

      const eventMock = { target: { ...event.target, value: newValue } };

      executeOnChange(
        eventMock,
        newValue,
        newPlainTextValue,
        getMentions(newValue, config)
      );
    },
    [children, executeOnChange, selectionEnd, selectionStart, value]
  );

  useEffect(() => {
    if (EXPERIMENTAL_cutCopyPaste) {
      document.addEventListener("copy", handleCopy);
      document.addEventListener("cut", handleCut);
      document.addEventListener("paste", handlePaste);
      return () => {
        document.removeEventListener("copy", handleCopy);
        document.removeEventListener("cut", handleCut);
        document.removeEventListener("paste", handlePaste);
      };
    }
  }, [EXPERIMENTAL_cutCopyPaste, handleCopy, handleCut, handlePaste]);

  useEffect(() => {
    if (!caretPosition || !suggestionsRef.current) {
      return;
    }

    const suggestions = suggestionsRef.current;
    const highlighter = highlighterRef.current;
    // first get viewport-relative position (highlighter is offsetParent of caret):
    const caretOffsetParentRect = highlighter.getBoundingClientRect();
    const caretHeight = getComputedStyleLengthProp(highlighter, "font-size");
    const viewportRelative = {
      left: caretOffsetParentRect.left + caretPosition.left,
      top: caretOffsetParentRect.top + caretPosition.top + caretHeight
    };
    const viewportHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );

    if (!suggestions) {
      return;
    }

    const position = {};

    // if suggestions menu is in a portal, update position to be releative to its portal node
    if (suggestionsPortalHost) {
      position.position = "fixed";
      let left = viewportRelative.left;
      let top = viewportRelative.top;
      // absolute/fixed positioned elements are positioned according to their entire box including margins; so we remove margins here:
      left -= getComputedStyleLengthProp(suggestions, "margin-left");
      top -= getComputedStyleLengthProp(suggestions, "margin-top");
      // take into account highlighter/textinput scrolling:
      left -= highlighter.scrollLeft;
      top -= highlighter.scrollTop;
      // guard for mentions suggestions list clipped by right edge of window
      const viewportWidth = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      );
      if (left + suggestions.offsetWidth > viewportWidth) {
        position.left = Math.max(0, viewportWidth - suggestions.offsetWidth);
      } else {
        position.left = left;
      }
      // guard for mentions suggestions list clipped by bottom edge of window if allowSuggestionsAboveCursor set to true.
      // Move the list up above the caret if it's getting cut off by the bottom of the window, provided that the list height
      // is small enough to NOT cover up the caret
      if (
        allowSuggestionsAboveCursor &&
        top + suggestions.offsetHeight > viewportHeight &&
        suggestions.offsetHeight < top - caretHeight
      ) {
        position.top = Math.max(
          0,
          top - suggestions.offsetHeight - caretHeight
        );
      } else {
        position.top = top;
      }
    } else {
      const left = caretPosition.left - highlighter.scrollLeft;
      const top = caretPosition.top - highlighter.scrollTop;
      // guard for mentions suggestions list clipped by right edge of window
      if (left + suggestions.offsetWidth > containerRef.current.offsetWidth) {
        position.right = 0;
      } else {
        position.left = left;
      }
      // guard for mentions suggestions list clipped by bottom edge of window if allowSuggestionsAboveCursor set to true.
      // move the list up above the caret if it's getting cut off by the bottom of the window, provided that the list height
      // is small enough to NOT cover up the caret
      if (
        allowSuggestionsAboveCursor &&
        viewportRelative.top -
          highlighter.scrollTop +
          suggestions.offsetHeight >
          viewportHeight &&
        suggestions.offsetHeight <
          caretOffsetParentRect.top - caretHeight - highlighter.scrollTop
      ) {
        position.top = top - suggestions.offsetHeight - caretHeight;
      } else {
        position.top = top;
      }
    }

    if (isEqual(position, suggestionsPosition)) {
      return;
    }

    setSuggestionsPosition(position);
  }, [
    allowSuggestionsAboveCursor,
    caretPosition,
    suggestionsPortalHost,
    suggestionsPosition
  ]);

  useEffect(() => {
    if (selectionStart === null || selectionEnd === null || !inputRef.current)
      return;

    const el = inputRef.current;
    if (el.setSelectionRange) {
      el.setSelectionRange(selectionStart, selectionEnd);
    } else if (el.createTextRange) {
      const range = el.createTextRange();
      range.collapse(true);
      range.moveEnd("character", selectionEnd);
      range.moveStart("character", selectionStart);
      range.select();
    }
    // This one needs to also depend on value
  }, [selectionEnd, selectionStart, value]);

  // Handle input element's change event
  const handleChange = useCallback(
    ev => {
      ev.preventDefault();
      // if we are inside iframe, we need to find activeElement within its contentDocument
      const currentDocument =
        (document.activeElement && document.activeElement.contentDocument) ||
        document;
      if (currentDocument.activeElement !== ev.target) {
        // fix an IE bug (blur from empty input element with placeholder attribute trigger "input" event)
        return;
      }

      const config = readConfigFromChildren(children);

      let newPlainTextValue = ev.target.value;
      // let newPlainTextValue = "aaa";

      // Derive the new value to set by applying the local change in the textarea's plain text
      const newValue = applyChangeToValue(
        value,
        newPlainTextValue,
        {
          selectionStartBefore: selectionStart,
          selectionEndBefore: selectionEnd,
          selectionEndAfter: ev.target.selectionEnd
        },
        config
      );

      // In case a mention is deleted, also adjust the new plain text value
      newPlainTextValue = getPlainText(newValue, config);

      // Save current selection after change to be able to restore caret position after rerendering
      let _selectionStart = ev.target.selectionStart;
      let _selectionEnd = ev.target.selectionEnd;

      // Adjust selection range in case a mention will be deleted by the characters outside of the
      // selection range that are automatically deleted
      const startOfMention = findStartOfMentionInPlainText(
        value,
        config,
        _selectionStart
      );

      if (startOfMention !== undefined && selectionEnd > startOfMention) {
        // only if a deletion has taken place
        _selectionStart = startOfMention;
        _selectionEnd = selectionStart;
      }

      const mentions = getMentions(newValue, config);

      const eventMock = { target: { value: newValue } };

      executeOnChange(eventMock, newValue, newPlainTextValue, mentions);
      setSelectionStart(_selectionStart);
      setSelectionEnd(_selectionEnd);
    },
    [children, executeOnChange, selectionEnd, selectionStart, value]
  );

  // Returns the text to set as the value of the textarea with all markups removed
  const _getPlainText = useCallback(() => {
    return getPlainText(value || "", readConfigFromChildren(children));
  }, [children, value]);

  const updateSuggestions = useCallback(
    (
      queryId,
      childIndex,
      query,
      querySequenceStart,
      querySequenceEnd,
      plainTextValue,
      results
    ) => {
      // neglect async results from previous queries
      if (queryId !== _queryId.current) return;

      const newSuggestions = {
        ...cloneDeep(suggestions),
        [childIndex]: {
          queryInfo: {
            childIndex,
            query,
            querySequenceStart,
            querySequenceEnd,
            plainTextValue
          },
          results
        }
      };

      const suggestionsCount = countSuggestions(newSuggestions);
      setSuggestions(newSuggestions);
      setFocusIndex(prev =>
        prev >= suggestionsCount ? Math.max(suggestionsCount - 1, 0) : prev
      );
    },
    [suggestions]
  );

  const queryData = useCallback(
    (
      query,
      childIndex,
      querySequenceStart,
      querySequenceEnd,
      plainTextValue
    ) => {
      const mentionChild = Children.toArray(children)[childIndex];
      const provideData = getDataProvider(
        mentionChild.props.data,
        ignoreAccents
      );
      const syncResult = provideData(
        query,
        updateSuggestions.bind(
          null,
          _queryId.current,
          childIndex,
          query,
          querySequenceStart,
          querySequenceEnd,
          plainTextValue
        )
      );
      if (syncResult instanceof Array) {
        updateSuggestions(
          _queryId.current,
          childIndex,
          query,
          querySequenceStart,
          querySequenceEnd,
          plainTextValue,
          syncResult
        );
      }
    },
    [children, ignoreAccents, updateSuggestions]
  );

  const updateMentionsQueries = useCallback(
    (plainTextValue, caretPosition) => {
      // Invalidate previous queries. Async results for previous queries will be neglected.
      _queryId.current++;
      setSuggestions({});
      const config = readConfigFromChildren(children);

      const positionInValue = mapPlainTextIndex(
        value,
        config,
        caretPosition,
        "NULL"
      );

      // If caret is inside of mention, do not query
      if (positionInValue === null) {
        return;
      }

      // Extract substring in between the end of the previous mention and the caret
      const substringStartIndex = getEndOfLastMention(
        value.substring(0, positionInValue),
        config
      );
      const substring = plainTextValue.substring(
        substringStartIndex,
        caretPosition
      );

      // Check if suggestions have to be shown:
      // Match the trigger patterns of all Mention children on the extracted substring
      React.Children.forEach(children, (child, childIndex) => {
        if (!child) {
          return;
        }

        const regex = makeTriggerRegex(child.props.trigger, {
          allowSpaceInQuery
        });
        const match = substring.match(regex);
        if (match) {
          const querySequenceStart =
            substringStartIndex + substring.indexOf(match[1], match.index);
          queryData(
            match[2],
            childIndex,
            querySequenceStart,
            querySequenceStart + match[1].length,
            plainTextValue
          );
        }
      });
    },
    [allowSpaceInQuery, children, queryData, value]
  );

  const updateHighlighterScroll = useCallback(() => {
    if (!inputRef.current || !highlighterRef.current) {
      // since the invocation of this function is deferred,
      // the whole component may have been unmounted in the meanwhile
      return;
    }
    const input = inputRef.current;
    const highlighter = highlighterRef.current;
    highlighter.scrollLeft = input.scrollLeft;
    highlighter.scrollTop = input.scrollTop;
    highlighter.height = input.height;
  }, []);

  const clearSuggestions = useCallback(() => {
    // Invalidate previous queries. Async results for previous queries will be neglected.
    _queryId.current++;
    setSuggestions({});
    setFocusIndex(0);
  }, []);

  // Handle input element's select event
  const handleSelect = useCallback(
    ev => {
      ev.preventDefault();
      if (!inputRef.current) return;
      // keep track of selection range / caret position
      setSelectionStart(ev.target.selectionStart);
      setSelectionEnd(ev.target.selectionEnd);

      // do nothing while a IME composition session is active
      if (isComposing.current) return;

      // refresh suggestions queries
      const el = inputRef.current;
      if (ev.target.selectionStart === ev.target.selectionEnd) {
        updateMentionsQueries(el.value, ev.target.selectionStart);
      } else {
        clearSuggestions();
      }

      // sync highlighters scroll position
      updateHighlighterScroll();

      onSelect(ev);
    },
    [clearSuggestions, onSelect, updateHighlighterScroll, updateMentionsQueries]
  );

  const shiftFocus = useCallback(
    delta => {
      const suggestionsCount = countSuggestions(suggestions);
      setFocusIndex(
        prev => (suggestionsCount + prev + delta) % suggestionsCount
      );
      setScrollFocusedIntoView(true);
    },
    [suggestions]
  );

  const addMention = useCallback(
    (
      { id, display },
      { childIndex, querySequenceStart, querySequenceEnd, plainTextValue }
    ) => {
      // Insert mention in the marked up value at the correct position
      const config = readConfigFromChildren(children);
      const mentionsChild = Children.toArray(children)[childIndex];
      const { markup, displayTransform, appendSpaceOnAdd, onAdd } =
        mentionsChild.props;

      const start = mapPlainTextIndex(
        value,
        config,
        querySequenceStart,
        "START"
      );
      const end = start + querySequenceEnd - querySequenceStart;
      let insert = makeMentionsMarkup(markup, id, display);
      if (appendSpaceOnAdd) {
        insert += " ";
      }
      const newValue = spliceString(value, start, end, insert);

      // Refocus input and set caret position to end of mention
      inputRef.current.focus();

      let displayValue = displayTransform(id, display);
      if (appendSpaceOnAdd) {
        displayValue += " ";
      }
      const newCaretPosition = querySequenceStart + displayValue.length;
      setSelectionStart(newCaretPosition);
      setSelectionEnd(newCaretPosition);

      // Propagate change
      const eventMock = { target: { value: newValue } };
      const mentions = getMentions(newValue, config);
      const newPlainTextValue = spliceString(
        plainTextValue,
        querySequenceStart,
        querySequenceEnd,
        displayValue
      );

      executeOnChange(eventMock, newValue, newPlainTextValue, mentions);

      if (onAdd) {
        onAdd(id, display);
      }

      // Make sure the suggestions overlay is closed
      clearSuggestions();
    },
    [children, clearSuggestions, executeOnChange, value]
  );

  const selectFocused = useCallback(() => {
    const { result, queryInfo } = Object.values(suggestions).reduce(
      (acc, { results, queryInfo }) => [
        ...acc,
        ...results.map(result => ({ result, queryInfo }))
      ],
      []
    )[focusIndex];

    addMention(result, queryInfo);

    setFocusIndex(0);
  }, [addMention, focusIndex, suggestions]);

  const handleKeyDown = useCallback(
    ev => {
      // do not intercept key events if the suggestions overlay is not shown
      const suggestionsCount = countSuggestions(suggestions);

      const suggestionsComp = suggestionsRef.current;
      if (suggestionsCount === 0 || !suggestionsComp) {
        onKeyDown(ev);
        return;
      }

      if (values(KEY).indexOf(ev.keyCode) >= 0) {
        ev.preventDefault();
      }

      switch (ev.keyCode) {
        case KEY.ESC: {
          clearSuggestions();
          return;
        }
        case KEY.DOWN: {
          shiftFocus(+1);
          return;
        }
        case KEY.UP: {
          shiftFocus(-1);
          return;
        }
        case KEY.RETURN: {
          selectFocused();
          return;
        }
        case KEY.TAB: {
          selectFocused();
          return;
        }
        default: {
          return;
        }
      }
    },
    [clearSuggestions, onKeyDown, selectFocused, shiftFocus, suggestions]
  );

  const handleBlur = useCallback(
    ev => {
      const clickedSuggestion = _suggestionsMouseDown.current;
      _suggestionsMouseDown.current = false;

      // only reset selection if the mousedown happened on an element
      // other than the suggestions overlay
      if (!clickedSuggestion) {
        setSelectionStart(null);
        setSelectionEnd(null);
      }

      window.setTimeout(() => {
        updateHighlighterScroll();
      }, 1);

      onBlur(ev, clickedSuggestion);
    },
    [onBlur, updateHighlighterScroll]
  );

  const inputProps = useMemo(() => {
    // pass all props that we don't use through to the input control
    return {
      readOnly,
      disabled,
      className,
      classNames: classNames(_classNames),
      ...style("input"),
      value: _getPlainText(),
      ...(!readOnly &&
        !disabled && {
          onChange: handleChange,
          onSelect: handleSelect,
          onKeyDown: handleKeyDown,
          onBlur: handleBlur,
          onCompositionStart: () => {
            isComposing.current = true;
          },
          onCompositionEnd: () => {
            isComposing.current = false;
          },
          onScroll: updateHighlighterScroll
        })
    };
  }, [
    _classNames,
    _getPlainText,
    className,
    disabled,
    handleBlur,
    handleChange,
    handleKeyDown,
    handleSelect,
    readOnly,
    style,
    updateHighlighterScroll
  ]);

  const setInputRef = useCallback(
    el => {
      inputRef.current = el;
      if (typeof _inputRef === "function") {
        _inputRef(el);
      } else if (_inputRef) {
        _inputRef.current = el;
      }
    },
    [_inputRef]
  );

  const isLoading = useMemo(() => {
    let _isLoading = false;
    React.Children.forEach(children, function (child) {
      _isLoading = _isLoading || (child && child.props.isLoading);
    });
    return _isLoading;
  }, [children]);

  const SuggestionsOverlayComp = useMemo(() => {
    if (!isNumber(selectionStart)) {
      // do not show suggestions when the input does not have the focus
      return null;
    }

    const suggestionsNode = (
      <SuggestionsOverlay
        focusIndex={focusIndex}
        ignoreAccents={ignoreAccents}
        isLoading={isLoading}
        position={suggestionsPosition}
        scrollFocusedIntoView={scrollFocusedIntoView}
        ref={suggestionsRef}
        style={style("suggestions")}
        suggestions={suggestions}
        onSelect={addMention}
        onMouseDown={() => {
          _suggestionsMouseDown.current = true;
        }}
        onMouseEnter={focusIndex => {
          setFocusIndex(focusIndex);
          setScrollFocusedIntoView(false);
        }}
      >
        {children}
      </SuggestionsOverlay>
    );
    if (suggestionsPortalHost) {
      return createPortal(suggestionsNode, suggestionsPortalHost);
    } else {
      return suggestionsNode;
    }
  }, [
    addMention,
    children,
    focusIndex,
    ignoreAccents,
    isLoading,
    scrollFocusedIntoView,
    selectionStart,
    style,
    suggestions,
    suggestionsPortalHost,
    suggestionsPosition
  ]);

  const selection = useMemo(
    () => ({
      start: selectionStart,
      end: selectionEnd
    }),
    [selectionEnd, selectionStart]
  );

  return (
    <div ref={containerRef} {...style}>
      <div {...style("control")}>
        <Highlighter
          ref={highlighterRef}
          style={style("highlighter")}
          inputStyle={inputProps.style}
          value={value}
          singleLine={singleLine}
          selection={selection}
          onCaretPositionChange={position => setCaretPosition(position)}
        >
          {children}
        </Highlighter>
        {singleLine ? (
          <input type="text" ref={setInputRef} {...inputProps} />
        ) : (
          <textarea ref={setInputRef} {...inputProps} />
        )}
      </div>
      {SuggestionsOverlayComp}
    </div>
  );
};

/**
 * Returns the computed length property value for the provided element.
 * Note: According to spec and testing, can count on length values coming back in pixels. See https://developer.mozilla.org/en-US/docs/Web/CSS/used_value#Difference_from_computed_value
 */
const getComputedStyleLengthProp = (forElement, propertyName) => {
  const length = parseFloat(
    window.getComputedStyle(forElement, null).getPropertyValue(propertyName)
  );
  return isFinite(length) ? length : 0;
};

MentionsInput.propTypes = propTypes;

const supportsClipboardActions = event => {
  return !!event.clipboardData;
};

export default MentionsInput;
