/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import {
  DialogFooter,
  FileUploadField,
  BlueprintError,
  wrapDialog,
  throwFormError
} from "@teselagen/ui";
import shortid from "shortid";
import modelNameToReadableName from "../../../../src-shared/utils/modelNameToReadableName";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { getDownloadTemplateFileHelpers } from "../../../../src-shared/components/DownloadTemplateFileButton";
import { allowedCsvFileTypes } from "../../../../../tg-iso-shared/src/utils/fileUtils";
import { validateNameCsvTableUniqueAsync } from "../validateNameCsvTableUniqueAsync";

const extraFields = {
  requestType: ["instructions"]
};

const idModels = ["requestType"];

const UploadLocationSettings = ({
  error,
  handleSubmit,
  hideModal,
  model,
  refetch,
  submitting
}) => {
  const onSubmit = async values => {
    const readableName = modelNameToReadableName(model, { plural: true });
    try {
      const { parsedData } = values.locationSettingsFile[0];
      const codeModel = !idModels.includes(model);

      const newItems = [];
      parsedData.forEach(row => {
        const { name, description } = row;

        const newItem = {
          name,
          description
        };
        const extraFieldsForModel = extraFields[model] || [];
        extraFieldsForModel.forEach(field => {
          newItem[field] = row[field];
        });
        if (codeModel) {
          newItem.code = shortid();
        }
        newItems.push(newItem);
      });
      await safeUpsert(model, newItems, {
        ...(codeModel && { idAs: "code" }),
        forceCreate: true
      });
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      throwFormError(error.message || `Error uploading ${readableName}.`);
    }
  };

  const accept = useMemo(
    () =>
      getDownloadTemplateFileHelpers({
        type: allowedCsvFileTypes,
        fileName: modelNameToReadableName(model, { plural: true }),
        validateAgainstSchema: {
          ...validateNameCsvTableUniqueAsync({
            model,
            isCodeModel: !idModels.includes(model)
          }),
          fields: [
            {
              path: "name",
              description: "Name of the location",
              isRequired: true,
              isUnique: true,
              example: "Location 1"
            },
            {
              path: "description",
              description: "Description of the location",
              example: "First location"
            },
            ...(extraFields[model] || []).map(field => ({
              path: field
            }))
          ]
        }
      }),
    [model]
  );

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <FileUploadField
          isRequired
          fileLimit={1}
          accept={accept}
          name="locationSettingsFile"
        />
        <BlueprintError error={error} />
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
};

export default compose(
  wrapDialog(({ model }) => ({
    title: `Upload ${modelNameToReadableName(model, {
      upperCase: true,
      plural: true
    })}`
  })),
  reduxForm({
    form: "uploadLocationSettings"
  })
)(UploadLocationSettings);
