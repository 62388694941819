/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import AbstractLibrary from "../../../../src-shared/AbstractLibrary";
import libraryEnhancer from "../../../../src-shared/libraryEnhancer";
import dataTablePartialFragment from "../../../graphql/fragments/dataTablePartialFragment";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import { MenuItem } from "@blueprintjs/core";
import { kebabCase } from "lodash";
import Papa from "papaparse";
import { download } from "../../../../src-shared/utils/downloadTest";
import { safeQuery } from "../../../../src-shared/apolloMethods";
import dataTableRecordFragment from "../../../graphql/fragments/dataTableRecordFragment";
import { getTableDataToDownload } from "../../../../src-shared/utils/dataTableUtils";

const schema = {
  model: "dataTable",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    {
      path: "dataTableType.name",
      type: "string",
      displayName: "Type"
    },
    {
      path: "dataSet.name",
      type: "string",
      displayName: "Data Set"
    }
  ]
};

const handleDataTableExport = async selectedRecords => {
  const ids = selectedRecords.map(record => record.id);
  const dataTables = await safeQuery(dataTableRecordFragment, {
    variables: {
      filter: {
        id: ids
      }
    }
  });
  dataTables.forEach(dataTable => {
    const {
      name,
      dataTableType: { code, rowSchema },
      schema,
      entities
    } = dataTable;
    const fields = rowSchema || schema?.fields;

    const data = getTableDataToDownload({ fields, entities });

    const csvData = Papa.unparse(data);
    download(
      csvData,
      `${name}-${kebabCase(code.toLowerCase()).replace(/^j-5/, "j5")}.csv`,
      "text/csv"
    );
  });
};

const contextMenu = selectedRecords => {
  const exportMenuItem = (
    <MenuItem
      key="export"
      icon="export"
      text="Export"
      onClick={() => handleDataTableExport(selectedRecords)}
    />
  );
  return [exportMenuItem];
};

class DataTablesInventory extends Component {
  onDoubleClick = record => {
    const { history } = this.props;
    history.push(`/data-tables/${record.id}`);
  };

  showUpload = () => {
    const { refetchDataTables } = this.props;
    showDialog({
      modalType: "UPLOAD_DATA_TABLE",
      modalProps: {
        refetch: refetchDataTables
      }
    });
  };

  render() {
    return (
      <AbstractLibrary
        {...this.props}
        showUploadFunction={this.showUpload}
        isLibraryTable
        model="dataTable"
        withExport
        noNewItem
        onDoubleClick={this.onDoubleClick}
        generateExtraContextMenuItems={contextMenu}
      />
    );
  }
}

export default libraryEnhancer({
  schema,
  fragment: dataTablePartialFragment
})(DataTablesInventory);
