/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

/**
 *
 * @param {string} model
 * @returns {string}
 */
function capitalizeModel(model) {
  return model[0].toUpperCase() + model.slice(1);
}

export { capitalizeModel };
