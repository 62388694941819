/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import GenericSelect from "../../../../src-shared/GenericSelect";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import {
  worklistPlanningDestinationPlateFragment,
  worklistPlanningReactionMapFragment
} from "./fragments";
import {
  CheckboxField,
  DataTable,
  InputField,
  NumericInputField,
  RadioGroupField,
  ReactSelectField,
  useTableEntities
} from "@teselagen/ui";
import { chunk, get, keyBy, isEmpty, forEach, flatten, isEqual } from "lodash";
import { Button, ButtonGroup, Icon, Menu, MenuItem } from "@blueprintjs/core";
import { PlateMapPlateNoContext } from "../../PlateMapPlate";
import { useDispatch, useSelector } from "react-redux";

import { containerArraySelectedAliquotContainerLocationsSelector } from "../../../../src-shared/redux/selectors";
import {
  getLocationHashMapGivenWellCountAndDirection,
  plateLibraryFilter
} from "../../../utils/plateUtils";
import DraggableMaterialHandle from "../CreateOrEditPlateMapTool/Steps/PlateMapConfiguration/DraggableMaterialHandle";
import CustomMaterialDragLayer from "../CreateOrEditPlateMapTool/Steps/PlateMapConfiguration/CustomMaterialDragLayer";
import shortid from "shortid";
import gql from "graphql-tag";
import { isValidNumOfReactions } from "./utils";
import actions from "../../../../src-shared/redux/actions";
import { rowIndexToLetter } from "../../../../../tg-iso-lims/src/utils/rowIndexToLetter";
import {
  generateEmptyWells,
  getPositionFromAlphanumericLocation
} from "../../../../../tg-iso-lims/src/utils/plateUtils";
import PlateUploadFields from "../../PlateUploadFields";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import FillDirectionSelect from "../../FillDirectionSelect";
import { hideDialog, showDialog } from "../../../../src-shared/GlobalDialog";
import DistributeWithPlateMapDialog from "./DistributeWithPlateMapDialog";
import { safeQuery } from "../../../../src-shared/apolloMethods";
import fieldConstants from "./fieldConstants";
import containerArrayTypeFragment from "../../../../../tg-iso-shared/src/fragments/containerArrayTypeFragment";
import defaultValueConstants from "../../../../../tg-iso-shared/src/defaultValueConstants";
import { change } from "redux-form";
import { useStepFormValues } from "../../../../src-shared/stepFormValues";

// store a map for each plate for location to reaction
// when you drop a reaction add it to the map
// change preview so that it shows which wells have reactions
// allow dragging reactions around on plate

const newPlateTypeFilter = (p, qb) => {
  qb.whereAll({
    quadrantSize: qb.greaterThan(2)
  });
};

export const worklistPlanningContainerArrayTypeFragment = gql`
  fragment worklistPlanningContainerArrayTypeFragment on containerArrayType {
    id
    name
    aliquotContainerTypeCode
    isPlate
    isColumn
    containerFormatCode
    nestableTubeTypes {
      id
      aliquotContainerTypeCode
      aliquotContainerType {
        code
        name
      }
    }
    containerFormat {
      code
      rowCount
      columnCount
      quadrantSize
      is2DLabeled
    }
  }
`;

const reactionInputOutputColumns = [
  {
    path: "reactionInputs.inputMaterial.name",
    displayName: "Inputs",
    getValueToFilterOn: getReactionInputs,
    render: (v, reaction) => getReactionInputs(reaction)
  },
  {
    path: "reactionOutputs.outputMaterial.name",
    displayName: "Outputs",
    getValueToFilterOn: getReactionOutputs,
    render: (v, reaction) => getReactionOutputs(reaction)
  }
];

const existingPlatesSchema = [
  "name",
  {
    displayName: "Barcode",
    path: "barcode.barcodeString"
  },
  dateModifiedColumn
];

const existingPlatesFragment = [
  "containerArray",
  "id name barcode { id barcodeString } updatedAt"
];

const existingPlatesTableParamOptions = {
  additionalFilter: plateLibraryFilter
};

const PlateUploadFieldsTableParamOptions = {
  additionalFilter: newPlateTypeFilter
};

const reactionMapsSchema = [
  "name",
  { displayName: "Type", path: "reactionType.name" },
  dateModifiedColumn
];

const reactionMapsFragment = [
  "reactionMap",
  "id name reactionType { code name } updatedAt"
];

const getActiveFilledWells = (activePlate, plateToReactionMap) => {
  let _activeFilledWells = {};
  if (activePlate) {
    _activeFilledWells =
      (activePlate && plateToReactionMap[activePlate.id]) || {};
  }
  return _activeFilledWells;
};

const radioGroupOptions = ["New Plates", "Existing Plates"];

const SelectPlates = ({
  defaultDestinationPlates,
  defaultPlateMapGroup,
  defaultPlateType,
  defaultReactionMaps,
  Footer,
  footerProps,
  handleSubmit,
  nextStep,
  staticTracker,
  toolIntegrationProps: {
    isDisabledMap = {},
    isLoadingMap = {},
    isIntegratedMap = {}
  },
  toolSchema: { code: formName }
}) => {
  const dispatch = useDispatch();

  const {
    selectTableEntities,
    allOrderedEntities: allOrderedReactions,
    selectedEntities: selectedReactionsDictionary
  } = useTableEntities("worklistPlanningReactionMapTable");

  const {
    destinationType,
    destinationPlates,
    reactionMaps,
    activePlate,
    newPlateType,
    plateToReactionMap,
    fillDirection,
    plateBaseName,
    enableMultipleReactions,
    numberOfReactionsPerWell,
    plateMapGroup
  } = useStepFormValues(formName, [
    "destinationType",
    "destinationPlates",
    "reactionMaps",
    "activePlate",
    "newPlateType",
    "plateToReactionMap",
    "fillDirection",
    fieldConstants.plateBaseName,
    fieldConstants.enableMultipleReactions,
    fieldConstants.numberOfReactionsPerWell,
    "plateMapGroup"
  ]);

  const selectedAliquotContainerLocations = useSelector(
    state =>
      containerArraySelectedAliquotContainerLocationsSelector(state) || []
  );

  const setSelectedAliquotContainerLocation = useCallback(
    value => {
      dispatch(
        actions.ui.records.containerArray.setSelectedAliquotContainerLocation(
          value
        )
      );
    },
    [dispatch]
  );
  const setSelectedAliquotContainerLocations = useCallback(
    value => {
      dispatch(
        actions.ui.records.containerArray.setSelectedAliquotContainerLocations(
          value
        )
      );
    },
    [dispatch]
  );

  const [distributingWithPlateMap, setDistributingWithPlateMap] =
    useState(false);

  const generateNewPlate = useCallback(
    (containerArrayType, index = 1) => {
      const acs = generateEmptyWells(containerArrayType.containerFormat, {
        aliquotContainerTypeCode:
          containerArrayType.aliquotContainerTypeCode ||
          containerArrayType.nestableTubeTypes[0]?.aliquotContainerTypeCode
      });
      if (!containerArrayType.isPlate) {
        acs.forEach(ac => {
          // this will force the plate to render "empty tubes" instead of completely empty
          ac.id = shortid();
          ac.aliquot = null;
        });
      }
      return {
        id: "__" + shortid(),
        name: (plateBaseName || "Destination Plate") + ` ${index}`,
        containerArrayType,
        aliquotContainers: acs
      };
    },
    [plateBaseName]
  );

  const distributeUsingPlateMap = useCallback(
    async (plateMapGroup, reactionMaps) => {
      const handler = async () => {
        try {
          const fullPlateMapGroup = await safeQuery(
            [
              "plateMapGroup",
              /* GraphQL */ `
                {
                  id
                  containerFormat {
                    code
                    rowCount
                    columnCount
                  }
                  plateMaps {
                    id
                    plateMapItems {
                      id
                      rowPosition
                      columnPosition
                      inventoryItem {
                        id
                        materialId
                      }
                    }
                  }
                }
              `
            ],
            {
              variables: {
                id: plateMapGroup.id
              }
            }
          );
          const [containerArrayTypeWithSameFormat] = await safeQuery(
            containerArrayTypeFragment,
            {
              variables: {
                pageSize: 1,
                filter: {
                  "containerFormat.rowCount":
                    fullPlateMapGroup.containerFormat.rowCount,
                  "containerFormat.columnCount":
                    fullPlateMapGroup.containerFormat.columnCount
                }
              }
            }
          );
          if (!containerArrayTypeWithSameFormat) {
            return window.toastr.error(
              "No plate type found to match plate map type"
            );
          }

          const outputMaterialIdToReactionId = {};
          reactionMaps?.forEach(rm => {
            rm.reactions.forEach(reaction => {
              if (reaction.reactionOutputs.length === 1) {
                reaction.reactionOutputs.forEach(o => {
                  const matId = o.outputMaterial.id;
                  if (matId) {
                    outputMaterialIdToReactionId[matId] = reaction.id;
                  }
                });
              }
            });
          });
          if (isEmpty(outputMaterialIdToReactionId)) {
            return window.toastr.error(
              "No reactions selected that output a single material."
            );
          }

          const plateToReactionMap = {};
          const destinationPlates = [];
          fullPlateMapGroup.plateMaps.forEach((pm, i) => {
            const newPlate = generateNewPlate(
              containerArrayTypeWithSameFormat,
              i + 1
            );
            destinationPlates.push(newPlate);
            pm.plateMapItems.forEach(pmi => {
              const matId = pmi.inventoryItem?.materialId;
              const reactionId = matId && outputMaterialIdToReactionId[matId];
              if (matId && reactionId) {
                const loc = getAliquotContainerLocation(pmi, { force2D: true });
                if (!plateToReactionMap[newPlate.id]) {
                  plateToReactionMap[newPlate.id] = {};
                }
                plateToReactionMap[newPlate.id][loc] = [reactionId];
              }
            });
          });
          if (isEmpty(plateToReactionMap)) {
            return window.toastr.warning(
              "No reactions with output materials in plate map."
            );
          }

          dispatch(
            change(formName, "newPlateType", containerArrayTypeWithSameFormat)
          );
          dispatch(change(formName, "destinationPlates", destinationPlates));
          dispatch(change(formName, "plateToReactionMap", plateToReactionMap));
          dispatch(change(formName, "activePlate", undefined));
        } catch (error) {
          console.error(`error:`, error);
          window.toastr.error(
            "Error placing reactions according to plate map."
          );
        }
      };

      setDistributingWithPlateMap(true);
      await handler();
      setDistributingWithPlateMap(false);
    },
    [dispatch, formName, generateNewPlate]
  );

  useEffect(() => {
    // Checks when changing from existing plates to new plates
    if (
      newPlateType &&
      destinationType === "New Plates" &&
      !destinationPlates?.length
    ) {
      const newPlate = generateNewPlate(newPlateType);
      dispatch(change(formName, "destinationPlates", [newPlate]));
      dispatch(change(formName, "activePlate", newPlate));
    }
  }, [
    destinationPlates?.length,
    destinationType,
    dispatch,
    formName,
    generateNewPlate,
    newPlateType
  ]);

  useEffect(() => {
    // if the reaction maps are pre-selected and they have a plate map integrated then try to
    // distribute using it.
    // but only do this once!
    if (isIntegratedMap.plateMapGroup && !isIntegratedMap.reactionMaps) {
      staticTracker.current.showedIntegrationWarning = true;
      window.toastr.warning(
        "Can not use pre-selected plate map if reaction maps are not pre-selected."
      );
    } else if (
      isIntegratedMap.plateMapGroup &&
      isIntegratedMap.reactionMaps &&
      defaultReactionMaps?.length &&
      defaultPlateMapGroup &&
      !staticTracker.current.usedPreselectedPlateMapGroup
    ) {
      staticTracker.current.usedPreselectedPlateMapGroup = true;
      window.toastr.info("Distributing using integrated plate map...");
      distributeUsingPlateMap(defaultPlateMapGroup, defaultReactionMaps);
    }
  }, [
    defaultPlateMapGroup,
    defaultReactionMaps,
    distributeUsingPlateMap,
    isIntegratedMap.plateMapGroup,
    isIntegratedMap.reactionMaps,
    staticTracker
  ]);

  const handleSourceMaterialDrop = useCallback(
    ({
      selectedSourceMaterials,
      droppedLocation,
      skipFilledWells,
      multiplate
    }) => {
      if (!activePlate) return;

      const newPlateToReactionMap = { ...plateToReactionMap };
      if (newPlateToReactionMap[activePlate.id]) {
        newPlateToReactionMap[activePlate.id] = {
          ...newPlateToReactionMap[activePlate.id]
        };
      } else {
        newPlateToReactionMap[activePlate.id] = {};
      }

      let reactionGroups = selectedSourceMaterials;
      if (
        enableMultipleReactions &&
        isValidNumOfReactions(numberOfReactionsPerWell)
      ) {
        reactionGroups = chunk(
          reactionGroups,
          Math.floor(numberOfReactionsPerWell)
        );
      }

      const containerFormat = activePlate.containerArrayType.containerFormat;

      let numberOfWellsToDropInto = reactionGroups.length;

      let numberOfAlreadyFilledWells = 0;
      forEach(newPlateToReactionMap, locationMap => {
        numberOfAlreadyFilledWells += Object.keys(locationMap).length;
      });

      if (skipFilledWells) {
        // add the number of already filled wells so the getLocationHashMapGivenWellCountAndDirection
        // will get enough destination locations to be able to skip all filled wells
        numberOfWellsToDropInto =
          numberOfWellsToDropInto + numberOfAlreadyFilledWells;
      }

      const reactionList = [...reactionGroups];

      const dstWellsMaps = getLocationHashMapGivenWellCountAndDirection({
        containerFormat: containerFormat,
        numWells: numberOfWellsToDropInto,
        startingPosition: droppedLocation,
        direction: fillDirection || "right", // left, right, up, down
        multiplate: true // allow multiplate if they drag extra but ignore the extra reactions
      });

      // we want to place overflow of reactions onto plates which come after active plate
      const plateList =
        destinationPlates?.slice(destinationPlates.indexOf(activePlate)) || [];

      const newDestinationPlates = [...(destinationPlates || [])];

      dstWellsMaps.forEach((dstWellsMap, i) => {
        if (i > 0 && !multiplate) return;
        let plate = plateList[i];
        if (!plate) {
          if (destinationType === "New Plates") {
            plate = generateNewPlate(
              newPlateType,
              newDestinationPlates.length + 1
            );
            newDestinationPlates.push(plate);
          } else {
            return;
          }
        }
        // Although the keys returned technically have no order guarantee, they
        // tend to be the same order as they were put in.
        const dstWells = Object.keys(dstWellsMap);
        if (!newPlateToReactionMap[plate.id]) {
          newPlateToReactionMap[plate.id] = {};
        }
        dstWells.forEach(loc => {
          if (skipFilledWells && newPlateToReactionMap[plate.id][loc]) return;
          const reactionsToPutHere = reactionList.shift();
          if (reactionsToPutHere) {
            let reactionsForLocation;
            if (Array.isArray(reactionsToPutHere)) {
              reactionsForLocation = reactionsToPutHere.map(r => r.id);
            } else {
              reactionsForLocation = [reactionsToPutHere.id];
            }
            newPlateToReactionMap[plate.id][loc] = reactionsForLocation;
          }
        });
      });

      const dstWells = Object.keys(dstWellsMaps[0]);
      setSelectedAliquotContainerLocations({
        locations: Object.keys(dstWells)
      });
      dispatch(change(formName, "destinationPlates", newDestinationPlates));
      dispatch(change(formName, "plateToReactionMap", newPlateToReactionMap));
    },
    [
      activePlate,
      destinationPlates,
      destinationType,
      dispatch,
      enableMultipleReactions,
      fillDirection,
      formName,
      generateNewPlate,
      newPlateType,
      numberOfReactionsPerWell,
      plateToReactionMap,
      setSelectedAliquotContainerLocations
    ]
  );

  const onDistributeReactionsClick = useCallback(async () => {
    // always starts from a1, skip wells which already have a reaction
    // count up all of the already filled wells and add that to the number of reactions
    // first do dry run and see where they will be placed. count up how many of those
    // destinations have reactions and then do a run with that added to the number of source
    //
    // dont make extra plates automatically, but jump to it if there is one
    const allReactions = flatten((reactionMaps || []).map(rm => rm.reactions));
    selectTableEntities(allReactions);

    handleSourceMaterialDrop({
      droppedLocation: "A1",
      selectedSourceMaterials: allOrderedReactions || [],
      skipFilledWells: true,
      multiplate: true
    });
  }, [
    allOrderedReactions,
    handleSourceMaterialDrop,
    reactionMaps,
    selectTableEntities
  ]);

  const activeFilledWells = useMemo(() => {
    return getActiveFilledWells(activePlate, plateToReactionMap);
  }, [activePlate, plateToReactionMap]);

  const handleWellOnWellDrop = useCallback(
    ({ sourceLocations, draggedLocation, droppedLocation }) => {
      const { rowCount, columnCount: colCount } =
        activePlate.containerArrayType.containerFormat;
      // move all dropped reactions
      const { columnPosition: dragCol, rowPosition: dragRow } =
        getPositionFromAlphanumericLocation(draggedLocation);
      const { columnPosition: dropCol, rowPosition: dropRow } =
        getPositionFromAlphanumericLocation(droppedLocation);
      const newPlateActives = { ...activeFilledWells };

      const deltaCol = dropCol - dragCol;
      const deltaRow = dropRow - dragRow;
      const dstLocations = sourceLocations.map(srcLoc => {
        const { columnPosition: srcCol, rowPosition: srcRow } =
          getPositionFromAlphanumericLocation(srcLoc);

        const dstCol = (srcCol + deltaCol + colCount) % colCount;
        const dstRow = (srcRow + deltaRow + rowCount) % rowCount;

        const dstLoc = rowIndexToLetter(dstRow) + (dstCol + 1);
        return dstLoc;
      });

      sourceLocations.forEach((srcLoc, i) => {
        const dstLoc = dstLocations[i];
        if (srcLoc === dstLoc) return;
        if (newPlateActives[srcLoc])
          newPlateActives[dstLoc] = newPlateActives[srcLoc];
        else delete newPlateActives[dstLoc];

        // remove from source
        delete newPlateActives[srcLoc];
      });

      setSelectedAliquotContainerLocations({ locations: dstLocations });
      dispatch(
        change(formName, "plateToReactionMap", prev => {
          return {
            ...prev,
            [activePlate.id]: newPlateActives
          };
        })
      );
    },
    [
      activeFilledWells,
      activePlate?.containerArrayType?.containerFormat,
      activePlate?.id,
      dispatch,
      formName,
      setSelectedAliquotContainerLocations
    ]
  );

  const canDrag = useCallback(
    // only allow dragging if the well has a reaction
    wellProps => !!activeFilledWells[wellProps.location],
    [activeFilledWells]
  );

  const clearPlate = useCallback(() => {
    dispatch(
      change(formName, "plateToReactionMap", (prev, { activePlate }) => ({
        ...prev,
        [activePlate.id]: {}
      }))
    );
  }, [dispatch, formName]);

  const clearAllPlate = useCallback(() => {
    dispatch(change(formName, "plateToReactionMap", {}));
  }, [dispatch, formName]);

  const selectedLocations = useMemo(
    () =>
      containerArraySelectedAliquotContainerLocationsSelector(
        window.teGlobalStore.getState()
      ),
    []
  );

  const hasReactionSelected = selectedLocations.some(l => activeFilledWells[l]);

  const aliquotContextMenu = useCallback(
    () => (
      <Menu>
        <MenuItem
          icon="trash"
          text="Remove Reaction"
          disabled={!hasReactionSelected}
          onClick={() => {
            dispatch(
              change(
                formName,
                "plateToReactionMap",
                (prev, { activePlate, plateToReactionMap }) => {
                  const activeFilledWells = getActiveFilledWells(
                    activePlate,
                    plateToReactionMap
                  );
                  const newActiveForPlate = {
                    ...activeFilledWells
                  };
                  selectedLocations.forEach(l => {
                    delete newActiveForPlate[l];
                  });
                  return {
                    ...prev,
                    [activePlate.id]: newActiveForPlate
                  };
                }
              )
            );
          }}
        />
      </Menu>
    ),
    [dispatch, formName, hasReactionSelected, selectedLocations]
  );

  const beforeNextStep = useCallback(
    async ({
      destinationPlates,
      plateToReactionMap,
      shouldFillRack,
      aliquotContainerType
    }) => {
      try {
        if (destinationPlates[0].id.includes("__")) {
          const fullDest = destinationPlates.map(destP => {
            destP.aliquotContainers = destP.aliquotContainers.filter(ac => {
              if (!ac.id) {
                ac.cid = shortid();
              }
              if (!destP.containerArrayType.isPlate) {
                const loc = getAliquotContainerLocation(ac, { force2D: true });
                const isMatch = plateToReactionMap[destP.id]?.[loc];
                if (aliquotContainerType) {
                  ac.aliquotContainerTypeCode = aliquotContainerType.code;
                }
                // if not filling the rack then we only want to keep the destinations that we are using
                if (shouldFillRack) return true;
                return isMatch;
              }
              return true;
            });
            return destP;
          });
          dispatch(change(formName, "destinationPlates", fullDest));
        }
        nextStep();
      } catch (error) {
        console.error("error:", error);
        window.toastr.error("Error loading info.");
      }
    },
    [dispatch, formName, nextStep]
  );

  const keyedReactions = useMemo(() => {
    let _keyedReactions = {};
    reactionMaps?.forEach(
      rm =>
        (_keyedReactions = {
          ..._keyedReactions,
          ...keyBy(get(rm, "reactions"), "id")
        })
    );
    return _keyedReactions;
  }, [reactionMaps]);

  const allReactions = useMemo(
    () => flatten((reactionMaps || []).map(rm => rm.reactions)),
    [reactionMaps]
  );

  const selectedLocation = selectedAliquotContainerLocations[0];

  const activeReactionsForWell = useMemo(() => {
    let _activeReactionsForWell = [];
    if (selectedLocation && activeFilledWells[selectedLocation]) {
      _activeReactionsForWell = activeFilledWells[selectedLocation];
    }
    return _activeReactionsForWell.map(
      reactionId => keyedReactions[reactionId]
    );
  }, [activeFilledWells, keyedReactions, selectedLocation]);

  const existingPlatesButtonProps = useMemo(
    () => ({
      loading: isLoadingMap.containerArrays,
      disabled: isDisabledMap.containerArrays
    }),
    [isDisabledMap.containerArrays, isLoadingMap.containerArrays]
  );

  const exitingPlatesOnClear = useCallback(() => {
    dispatch(change(formName, "activePlate", null));
    dispatch(change(formName, "plateToReactionMap", {}));
  }, [dispatch, formName]);

  const existingPlateOnSelect = useCallback(
    (newPlates = []) => {
      const newPlateIds = newPlates.map(p => p.id);
      dispatch(
        change(formName, "activePlate", prev => {
          if (prev && !newPlateIds.includes(prev.id)) {
            return newPlates[0];
          }
          return prev;
        })
      );
      dispatch(
        change(formName, "plateToReactionMap", prev => {
          const newPlateToReactionMap = {
            ...prev
          };
          Object.keys(newPlateToReactionMap).forEach(plateId => {
            if (!newPlateIds.includes(plateId)) {
              delete newPlateToReactionMap[plateId];
            }
          });
          return newPlateToReactionMap;
        })
      );
    },
    [dispatch, formName]
  );

  const plateUploadFieldsOnFieldSubmit = useCallback(
    containerArrayType => {
      if (isEqual(containerArrayType, newPlateType)) return;
      const newPlate = generateNewPlate(containerArrayType || defaultPlateType);
      dispatch(change(formName, "destinationPlates", [newPlate]));
      dispatch(change(formName, "activePlate", newPlate));
      dispatch(change(formName, "plateToReactionMap", {}));
    },
    [defaultPlateType, dispatch, formName, generateNewPlate, newPlateType]
  );

  const plateUploadFieldGenericSelectOptions = useMemo(
    () => ({
      name: "newPlateType",
      label: "New Plate Type",
      additionalDataFragment: worklistPlanningContainerArrayTypeFragment,
      tableParamOptions: PlateUploadFieldsTableParamOptions,
      onFieldSubmit: plateUploadFieldsOnFieldSubmit,
      defaultValue: newPlateType || defaultPlateType
    }),
    [defaultPlateType, newPlateType, plateUploadFieldsOnFieldSubmit]
  );

  const doNotSubmit = useRef(true);
  const radioGroupOnFieldSubmit = useCallback(() => {
    if (doNotSubmit.current) {
      doNotSubmit.current = false;
      return;
    }
    dispatch(change(formName, "destinationPlates", []));
    dispatch(change(formName, "activePlate", null));
  }, [dispatch, formName]);

  const plateBaseNameOnFieldSubmit = useCallback(
    baseName => {
      if (baseName) {
        dispatch(
          change(
            formName,
            "destinationPlates",
            prev =>
              prev?.map((destP, i) => {
                if (destP.id.startsWith("__")) {
                  return {
                    ...destP,
                    name: baseName + ` ${i + 1}`
                  };
                }
                return destP;
              }) || []
          )
        );
      }
    },
    [dispatch, formName]
  );

  const worklistPlanningReactionMapTableSchema = useMemo(() => {
    const allPlacedReactionIds = [];
    Object.keys(plateToReactionMap || {}).forEach(plateId => {
      const reactionIds = Object.values(plateToReactionMap[plateId]);
      reactionIds.forEach(arrayOfReactionIds => {
        arrayOfReactionIds.forEach(rId => {
          if (!allPlacedReactionIds.includes(rId)) {
            allPlacedReactionIds.push(rId);
          }
        });
      });
    });

    const selectedReactions = Object.values(selectedReactionsDictionary || {})
      .sort((a, b) => a.rowIndex - b.rowIndex)
      .map(i => i.entity);

    return [
      {
        type: "action",
        width: 40,
        resizable: false,
        render: (_, reaction) => {
          return (
            <DraggableMaterialHandle
              selectedSourceMaterials={selectedReactions}
              material={reaction}
            />
          );
        }
      },
      ...reactionInputOutputColumns,
      {
        type: "action",
        resizable: false,
        width: 40,
        render: (_, reaction) => {
          if (allPlacedReactionIds.includes(reaction.id)) {
            return <Icon icon="tick-circle" intent="success" />;
          }
        }
      }
    ];
  }, [plateToReactionMap, selectedReactionsDictionary]);

  const aliquotTooltip = useCallback(
    (ac, location) => {
      const reactionIds = activeFilledWells[location];
      if (reactionIds) {
        if (reactionIds.length === 1 && keyedReactions[reactionIds[0]]) {
          const reaction = keyedReactions[reactionIds[0]];
          return `Input: ${getReactionInputs(
            reaction
          )}\nOutput: ${getReactionOutputs(reaction)}`;
        } else {
          return `${reactionIds.length} reactions. Select to view.`;
        }
      }
    },
    [activeFilledWells, keyedReactions]
  );

  const destinationPlatesOptions = useMemo(
    () =>
      destinationPlates?.map(p => ({
        label: p.name,
        value: p
      })),
    [destinationPlates]
  );

  const addNewPlate = useCallback(() => {
    const id = "__" + shortid();
    const genNewPlate = (destP, npt, pbn) => ({
      id,
      name: (pbn || "Destination Plate") + ` ${destP.length + 1}`,
      containerArrayType: npt,
      aliquotContainers: generateEmptyWells(npt.containerFormat, {
        aliquotContainerTypeCode: npt.aliquotContainerTypeCode
      })
    });
    dispatch(
      change(
        formName,
        "destinationPlates",
        (prev, { newPlateType, plateBaseName }) => [
          ...prev,
          genNewPlate(prev, newPlateType, plateBaseName)
        ]
      )
    );
    dispatch(
      change(
        formName,
        "activePlate",
        (_, { destinationPlates, newPlateType, plateBaseName }) =>
          genNewPlate(destinationPlates, newPlateType, plateBaseName)
      )
    );
  }, [dispatch, formName]);

  const deletePlate = useCallback(() => {
    const genNewDestinationPlate = (dp, ap, pbn) => {
      const newDest = dp?.filter(p => p.id !== ap.id) || [];
      return newDest.map((p, i) => {
        return {
          ...p,
          name: (pbn || "Destination Plate") + ` ${i + 1}`
        };
      });
    };
    dispatch(
      change(
        formName,
        "destinationPlates",
        (destinationPlates, { activePlate, plateBaseName }) =>
          genNewDestinationPlate(destinationPlates, activePlate, plateBaseName)
      )
    );
    dispatch(
      change(
        formName,
        "activePlate",
        (destinationPlates, { activePlate, plateBaseName }) =>
          genNewDestinationPlate(
            destinationPlates,
            activePlate,
            plateBaseName
          )[0]
      )
    );
  }, [dispatch, formName]);

  const reactionMapsButtonProps = useMemo(
    () => ({
      loading: isLoadingMap.reactionMaps,
      disabled: isDisabledMap.reactionMaps
    }),
    [isDisabledMap.reactionMaps, isLoadingMap.reactionMaps]
  );

  return (
    <div>
      <div className="tg-step-form-section column">
        <div className="tg-flex justify-space-between">
          <div
            style={{
              width: "50%",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <HeaderWithHelper
              header="Select Reaction"
              helper="Select a reaction map to plan worklist."
            />
            <GenericSelect
              name="reactionMaps"
              isRequired
              isMultiSelect
              schema={reactionMapsSchema}
              fragment={reactionMapsFragment}
              additionalDataFragment={worklistPlanningReactionMapFragment}
              buttonProps={reactionMapsButtonProps}
              defaultValue={reactionMaps || defaultReactionMaps}
            />
            {!!reactionMaps?.length && (
              <>
                <h6>{reactionMaps.map(rm => rm.name).join(", ")}</h6>
                <CheckboxField
                  name={fieldConstants.enableMultipleReactions}
                  label="Enable Multiple Reactions per Well"
                  tooltipInfo="If checked you can drag multiple reactions into a single plate well on the right."
                />
                {enableMultipleReactions && (
                  <NumericInputField
                    name={fieldConstants.numberOfReactionsPerWell}
                    label="Number of Reactions per Well"
                    defaultValue={numberOfReactionsPerWell ?? 2}
                  />
                )}
                <div style={{ display: "flex", alignItems: "end" }}>
                  <Button
                    intent="primary"
                    text="Distribute Reactions"
                    disabled={
                      enableMultipleReactions
                        ? !isValidNumOfReactions(numberOfReactionsPerWell)
                        : false
                    }
                    onClick={onDistributeReactionsClick}
                  />
                  <div style={{ width: 150, marginLeft: 5 }}>
                    <FillDirectionSelect />
                  </div>
                  {destinationType === "New Plates" && (
                    <Button
                      intent="primary"
                      disabled={isDisabledMap.plateMapGroup}
                      style={{ marginLeft: 5 }}
                      text="Distribute with Plate Map"
                      loading={distributingWithPlateMap}
                      onClick={() => {
                        showDialog({
                          ModalComponent: DistributeWithPlateMapDialog,
                          modalProps: {
                            onSelect: async plateMapGroup => {
                              distributeUsingPlateMap(
                                plateMapGroup,
                                reactionMaps
                              );
                              hideDialog();
                            },
                            defaultPlateMapGroup:
                              plateMapGroup || defaultPlateMapGroup
                          }
                        });
                      }}
                    />
                  )}
                </div>
                <DataTable
                  isSimple
                  doNotShowEmptyRows
                  entities={allReactions}
                  formName="worklistPlanningReactionMapTable"
                  withSearch
                  schema={worklistPlanningReactionMapTableSchema}
                />
              </>
            )}
          </div>
          <div style={{ width: 50 }} />
          <div style={{ width: "50%" }}>
            <HeaderWithHelper
              width="100%"
              header="Destination Plates"
              helper="Create new destination plates or choose from inventory"
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <RadioGroupField
                name={fieldConstants.destinationType}
                options={radioGroupOptions}
                defaultValue={destinationType || "New Plates"}
                onFieldSubmit={radioGroupOnFieldSubmit}
              />
              {destinationType === "Existing Plates" && (
                <GenericSelect
                  isRequired
                  noFill
                  name="destinationPlates"
                  schema={existingPlatesSchema}
                  isMultiSelect
                  fragment={existingPlatesFragment}
                  tableParamOptions={existingPlatesTableParamOptions}
                  additionalDataFragment={
                    worklistPlanningDestinationPlateFragment
                  }
                  buttonProps={existingPlatesButtonProps}
                  onClear={exitingPlatesOnClear}
                  onSelect={existingPlateOnSelect}
                  defaultValue={destinationPlates || defaultDestinationPlates}
                />
              )}
              {destinationType === "New Plates" && (
                <div style={{ maxWidth: 350, flex: 1 }}>
                  <PlateUploadFields
                    inTool
                    noFileUpload
                    noNumTubes
                    noTubeBarcodeOption
                    genericSelectOptions={plateUploadFieldGenericSelectOptions}
                  />
                  <InputField
                    name={fieldConstants.plateBaseName}
                    label="Plate Base Name"
                    generateDefaultValue={defaultValueConstants.PLATE_BASE_NAME}
                    onFieldSubmit={plateBaseNameOnFieldSubmit}
                  />
                </div>
              )}
            </div>
            {!!destinationPlates?.length && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 15,
                  justifyContent: "flex-end"
                }}
              >
                <div style={{ maxWidth: 250 }}>
                  <ReactSelectField
                    className="tg-no-form-group-margin"
                    name="activePlate"
                    defaultValue={activePlate || destinationPlates[0]}
                    enableReinitialize
                    options={destinationPlatesOptions}
                  />
                </div>
                {destinationType === "New Plates" && (
                  <ButtonGroup style={{ marginLeft: 8 }}>
                    <Button
                      icon="add"
                      intent="success"
                      disabled={!newPlateType}
                      onClick={addNewPlate}
                    />
                    <Button
                      icon="trash"
                      intent="danger"
                      disabled={!destinationPlates?.length || !activePlate}
                      onClick={deletePlate}
                    />
                  </ButtonGroup>
                )}
              </div>
            )}
            {activePlate && !!destinationPlates?.length && (
              <>
                <div
                  style={{
                    display: "flex",
                    marginBottom: 10,
                    marginLeft: 15
                  }}
                >
                  <Button onClick={clearPlate} intent="warning">
                    Clear Plate
                  </Button>
                  <Button
                    style={{ marginLeft: 5 }}
                    onClick={clearAllPlate}
                    intent="danger"
                  >
                    Clear All Plates
                  </Button>
                </div>
                <PlateMapPlateNoContext
                  activeFilledWells={activeFilledWells}
                  aliquotContainers={activePlate.aliquotContainers}
                  aliquotContextMenu={aliquotContextMenu}
                  aliquotTooltip={aliquotTooltip}
                  canDrag={canDrag}
                  containerArrayType={activePlate.containerArrayType}
                  isEditable
                  onDrop={handleWellOnWellDrop}
                  onSourceMaterialDrop={handleSourceMaterialDrop}
                  selectedAliquotContainerLocations={
                    selectedAliquotContainerLocations
                  }
                  setSelectedAliquotContainerLocation={
                    setSelectedAliquotContainerLocation
                  }
                />

                {!!activeReactionsForWell.length && (
                  <div style={{ marginTop: 15 }}>
                    <DataTable
                      isSimple
                      className="tg-active-reactions-table"
                      formName="activeReactionsForSelectedWell"
                      entities={activeReactionsForWell}
                      schema={reactionInputOutputColumns}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Footer {...footerProps} onNextClick={handleSubmit(beforeNextStep)} />
      <CustomMaterialDragLayer />
    </div>
  );
};

export default SelectPlates;

function getReactionInputs(reaction) {
  const inputs = reaction.reactionInputs
    .map(input => {
      const name =
        get(input, "inputMaterial.name") ||
        get(input, "inputAdditiveMaterial.name");
      return name;
    })
    .join(", ");
  return inputs;
}

function getReactionOutputs(reaction) {
  const outputs = reaction.reactionOutputs
    .map(output => {
      const name =
        get(output, "outputMaterial.name") ||
        get(output, "outputAdditiveMaterial.name");
      return name;
    })
    .join(", ");
  return outputs;
}
