/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import {
  BlueprintError,
  CheckboxField,
  InputField,
  NumericInputField
} from "@teselagen/ui";
import { cloneDeep } from "lodash";
import { Button } from "@blueprintjs/core";
import {
  AddButton,
  RemoveButton
} from "../../../../src-shared/FieldArrayButtons";
import PlateUploadFields from "../../PlateUploadFields";
import "./style.css";
import defaultValueConstants from "../../../../../tg-iso-shared/src/defaultValueConstants";

const AddPlateFields = ({
  fields,
  meta: { submitFailed, error },
  isEditing,
  generateBarcodes,
  numPlates,
  handleSubmit
}) => {
  const addPlates = ({
    numPlates,
    containerArrayType,
    aliquotContainerType,
    platePrefix,
    shouldFillRack,
    numTubesToFillRack,
    generateTubeBarcodes
  }) => {
    const newPlates = [];
    for (let i = 0; i < numPlates; i++) {
      newPlates.push(
        cloneDeep({
          name: `${platePrefix} ${i + 1}`,
          containerArrayType,
          shouldFillRack,
          numTubesToFillRack,
          generateTubeBarcodes,
          ...(!containerArrayType.isPlate && {
            aliquotContainerType
          })
        })
      );
    }
    newPlates.forEach(plate => fields.push(plate));
  };

  return (
    <>
      <CheckboxField
        name="generateBarcodes"
        label="Generate Barcodes For Destination Plates"
        defaultValue
      />
      {fields.map((field, index) => (
        <div key={`plate.${index}`} className="tg-flex column">
          <hr className="tg-section-break" />
          <InputField
            name={`${field}.name`}
            label="Plate Name"
            placeholder="Enter destination plate name..."
            isRequired
          />
          {!generateBarcodes && (
            <InputField name={`${field}.barcode`} label="Plate Barcode" />
          )}
          <PlateUploadFields inTool noFileUpload fieldPrefix={field + "."} />
          {!isEditing && <RemoveButton fields={fields} index={index} />}
        </div>
      ))}
      {!isEditing &&
        (fields.length > 0 ? (
          <AddButton fields={fields} label="Add Plate" />
        ) : (
          <div>
            <InputField
              name="platePrefix"
              label="Plate Name Prefix"
              isRequired
              generateDefaultValue={{
                ...defaultValueConstants.PLATE_NAME_PREFIX
              }}
            />
            <NumericInputField
              name="numPlates"
              label="Number of plates to add"
              defaultValue={1}
              min={1}
            />
            <PlateUploadFields inTool noFileUpload />
            <Button
              icon="add"
              intent="primary"
              text={numPlates > 1 ? "Add Plates" : "Add Plate"}
              onClick={handleSubmit(addPlates)}
            />
          </div>
        ))}
      {submitFailed && <BlueprintError error={error} />}
    </>
  );
};

export default AddPlateFields;
