/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SelectField } from "@teselagen/ui";
import HeaderWithHelper from "../../HeaderWithHelper";
import { Button, Intent } from "@blueprintjs/core";
import { isCsvFile } from "../../../../tg-iso-shared/src/utils/fileUtils";
import { get, size } from "lodash";
import { ValidationMsgComponent } from "../../../src-shared/ValidationComponent";
import ExperimentalDataFileUpload from "../../components/ExperimentalDataFileUpload";
import { useFormValue } from "../../hooks/useFormValue";
import useTgQuery, { HandleErrAndLoad } from "../../apolloUseTgQuery";
import integrationFragment from "../../fragments/integrationFragment";
import { useDispatch } from "react-redux";
import { change as _change } from "redux-form";

const headers = [
  "Assay Subject Name",
  "Descriptor 1",
  "Descriptor 2",
  "Metabolite Concentration",
  "Metabolite Concentration Unit"
];

const TEMPLATE_FILE_NAME = "Assay Results";

const validators = {
  options: {
    // Allows rows to have empty cells.
    row: { allowEmpty: true }
  }
};

const UploadDataFile = ({
  Footer,
  footerProps,
  integrations,
  stepFormProps: { destroy },
  toolSchema: { code }
}) => {
  const dispatch = useDispatch();
  const change = useCallback(
    (field, value) => dispatch(_change(code, field, value)),
    [dispatch, code]
  );
  const parserValidationErrors = useFormValue(code, "parserValidationErrors");
  const submitLoading = useFormValue(code, "submitLoading");
  const submitted = useFormValue(code, "submitted");
  const uploadedFiles = useFormValue(code, "uploadedFiles");
  const dataParserId = useFormValue(code, "dataParserId");
  const [allFilesAreCSV, setAllFilesAreCsv] = useState(false);
  const [needsParser, setNeedsParser] = useState();
  const [validationErrors, setValidationErrors] = useState([]);

  const clearSubmissionState = useCallback(() => {
    change("submitted", false);
    change("submitLoading", false);
    change("parserValidationErrors", []);
  }, [change]);

  useEffect(() => {
    clearSubmissionState();
  }, [clearSubmissionState, dataParserId]);

  const validateFiles = (files, validation) => {
    if (files.length === 0) return false;

    let filesAreValid = true;
    let filesAreCSV = true;

    // Loop through each file and check if it is a CSV file
    files.forEach((file, file_index) => {
      // If no file is found return false
      if (!file) return false;

      if (!isCsvFile(file)) {
        filesAreCSV = false;
      }

      // if some file is invalid, set filesAreValid to false
      const { isValid, validationErrors } = validation[file_index];
      if (!isValid) {
        filesAreValid = false;
        setValidationErrors(validationErrors);
      }
    });

    // When some CSV file is invalid, we just set the needsParser to true
    if (filesAreCSV) {
      setNeedsParser(!filesAreValid);
      setAllFilesAreCsv(true);
    } else {
      setNeedsParser(true);
      setAllFilesAreCsv(false);
    }
    return true;
  };

  const parserNeededButNotSelected = needsParser && !dataParserId;
  const submitDisabled = !size(uploadedFiles) || parserNeededButNotSelected;

  const parserOptions = useMemo(
    () =>
      integrations.map(integration => ({
        value: get(integration, "integrationEndpoints.0.id"),
        label: integration.name
      })),
    [integrations]
  );

  return (
    <>
      <div className="tg-step-form-section column">
        <ExperimentalDataFileUpload
          onRemove={(files, validation) => {
            if (!size(files)) {
              clearSubmissionState();
              destroy();
              return;
            } else {
              validateFiles(files, validation);
            }
          }}
          validators={validators}
          withTags={true}
          templateFileName={TEMPLATE_FILE_NAME}
          template={{ headers }}
          beforeUpload={validateFiles}
        />
      </div>
      {!!size(uploadedFiles) && (
        <div className="tg-step-form-section column">
          <div className="tg-flex align-flex-start column">
            <HeaderWithHelper
              header="Select data file parser"
              helper={
                <span>
                  If needed, choose a data file parser integration. These are
                  configured at TeselaGen Settings {">"} Integrations.
                </span>
              }
            />
            <SelectField
              tooltipInfo={
                <span>
                  Only CSVs that are properly tabulated can proceed without a
                  parser.
                </span>
              }
              placeholder="Select a parser"
              options={parserOptions}
              name="dataParserId"
              label="Select Data Parser"
            />
            {allFilesAreCSV && parserNeededButNotSelected
              ? ValidationMsgComponent({
                  validationMessage: `${validationErrors[0].message}. Either fix your CSV file or select an appropriate Data Parser.`,
                  intent: Intent.WARNING
                })
              : null}
            {submitted && parserValidationErrors && size(parserValidationErrors)
              ? parserValidationErrors.map((validationError, idx) => {
                  const { code, name, validationErrors } = validationError;

                  let validationMessage = `[${name}]: ${validationErrors[0].message}.`;
                  if (code !== "yup-validation") {
                    validationMessage +=
                      " Make sure the selected Data Parser is correct and matches you Data File.";
                  }
                  validationMessage = validationMessage.replace("..", ".");
                  return (
                    <React.Fragment key={`parserValidationError-${idx}`}>
                      <ValidationMsgComponent
                        validationMessage={validationMessage}
                        intent={Intent.DANGER}
                      />
                    </React.Fragment>
                  );
                })
              : null}
          </div>
        </div>
      )}
      {/**
       * TODO: Submitting can take quite a while, Maybe implement a sort of loading view with steps like:
       * "Parsing file" ---- "Generating CSVs" ---- "Uploading CSVs" ---- "Generating DataGrids"
       */}
      <Footer
        {...footerProps}
        nextButton={
          <Button
            id="generate-data-grids-button"
            type="submit"
            intent={Intent.SUCCESS}
            text="Generate Data Grids"
            disabled={submitDisabled}
            loading={submitLoading}
          />
        }
      />
    </>
  );
};

export default props => {
  const {
    entities: integrations,
    loading,
    error
  } = useTgQuery(integrationFragment, {
    isPlural: true,
    variables: {
      filter: {
        integrationTypeCode: "FILE_TO_DATAGRID"
      }
    }
  });
  if (loading || error)
    return <HandleErrAndLoad loading={loading} error={error} />;
  return <UploadDataFile {...props} integrations={integrations} />;
};
