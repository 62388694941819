/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
const reqError = "This field is required";

export const validateEmail = (email: string) =>
  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
    ? "Invalid email address"
    : undefined;

const noop = () => {};

export default (mode: string) =>
  (
    values: { [key: string]: string },
    {
      loginField = "email",
      loginFieldValidator = "email"
    }: { loginField: string; loginFieldValidator: "email" | Function }
  ) => {
    const isSignup = mode === "signup";
    const isLogin = mode === "login";
    const isResetRequest = mode === "resetRequest";
    const isReset = mode === "reset";

    const errors: { [key: string]: any } = {};
    const validators = {
      email: validateEmail
    };
    const lfValidator =
      typeof loginFieldValidator === "function"
        ? loginFieldValidator
        : validators[loginFieldValidator] || noop;

    if (!isReset) {
      if (isLogin && loginFieldValidator !== "email") {
        if (!values[loginField]) {
          errors[loginField] = reqError;
        } else {
          errors[loginField] = lfValidator(values[loginField]);
        }
      } else {
        if (!values.email) {
          errors.email = reqError;
        } else {
          errors.email = validateEmail(values.email);
        }
        // TODO
        if (isLogin && values.email && values.email.match(/^U[a-z0-9-]+$/i)) {
          delete errors.email;
        }
      }
    }

    if (isSignup) {
      if (!values.firstName) {
        errors.firstName = reqError;
      }
      if (!values.lastName) {
        errors.lastName = reqError;
      }
    }

    if (!isResetRequest) {
      if (!values.password) {
        errors.password = reqError;
      }
    }

    if (isSignup || isReset) {
      if (!values.passwordConfirm) {
        errors.passwordConfirm = reqError;
      } else if (values.password !== values.passwordConfirm) {
        errors.passwordConfirm = "Passwords do not match";
      }
    }

    return errors;
  };
