/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
export const registeredAnnotationGroupsAdditionalFragment = gql`
  fragment registeredAnnotationGroupsAdditionalFragment on registeredAnnotationGroup {
    id
    name
    updatedAt
    registeredAnnotationToGroups {
      id
      registeredAnnotation {
        id
        registeredAnnotationTypeCode
        name
        type
        isRegex
        sequence
      }
    }
  }
`;
