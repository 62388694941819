/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { MenuItem } from "@blueprintjs/core";
import AbstractLibrary from "../../../../src-shared/AbstractLibrary";
import additiveMaterialInventoryFragment from "../../../graphql/fragments/additiveMaterialInventoryFragment";
import libraryEnhancer from "../../../../src-shared/libraryEnhancer";
import { showDialog } from "../../../../src-shared/GlobalDialog";

const additiveTypeCode = "REAGENT";

const schema = {
  model: "additiveMaterial",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    {
      path: "additiveType.name",
      type: "string",
      displayName: "Reagent Type"
    }
  ]
};

const onNewItemClick = ({ history, refetch }) => {
  showDialog({
    modalType: "CREATE_NEW_ADDITIVE_MATERIAL",
    modalProps: {
      refetch,
      history,
      initialValues: {
        additiveTypeCode
      }
    }
  });
};

const contextMenuItems = selectedRecords => {
  if (selectedRecords.length === 1) {
    const reagent = selectedRecords[0];
    return [
      <MenuItem
        key="createTube"
        icon="add"
        text="Create Tube"
        onClick={() => {
          showDialog({
            modalType: "CREATE_TUBE_FROM_REAGENT",
            modalProps: {
              reagentId: reagent.id,
              dialogProps: {
                title: "Create Tube from " + reagent.name
              }
            }
          });
        }}
      />
    ];
  }
};

const AdditiveMaterialLibrary = props => {
  const { refetchAdditiveMaterials, history } = props;

  const onDoubleClick = record => history.push(`/reagents/${record.id}`);

  const showUploadFunction = () => {
    showDialog({
      modalType: "UPLOAD_ADDITIVE_MATERIAL",
      modalProps: {
        refetch: refetchAdditiveMaterials
      }
    });
  };

  return (
    <AbstractLibrary
      {...props}
      generateExtraContextMenuItems={contextMenuItems}
      libraryName="reagent"
      libraryTitle="Reagents"
      model="additiveMaterial"
      isLibraryTable
      withExport
      showUploadFunction={showUploadFunction}
      onDoubleClick={onDoubleClick}
      onNewItemClick={onNewItemClick}
    />
  );
};

export default libraryEnhancer({
  schema,
  fragment: additiveMaterialInventoryFragment
})(AdditiveMaterialLibrary);
