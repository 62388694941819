/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useMemo, useState } from "react";
import { Classes, Button, Callout } from "@blueprintjs/core";
import { union, without, set, isEmpty } from "lodash";
import { reduxForm, FieldArray, change as _change } from "redux-form";
import {
  DialogFooter,
  InputField,
  ReactColorField,
  BlueprintError,
  wrapDialog,
  throwFormError
} from "@teselagen/ui";
import PlateMapPlate from "../../PlateMapPlate";
import SimplePaging from "../../SimplePaging";
import AddButton from "../../../../src-shared/FieldArrayButtons/AddButton";
import RemoveButton from "../../../../src-shared/FieldArrayButtons/RemoveButton";
import { createPlateLocationMap } from "../../../utils/plateUtils";
import { REQUIRED_ERROR } from "../../../../src-shared/utils/formUtils";
import { safeUpsert, safeDelete } from "../../../../src-shared/apolloMethods";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { compose } from "recompose";
import { useDispatch } from "react-redux";
import { useFormValue } from "../../../../src-shared/hooks/useFormValue";

const form = "CreatePlateLayers";

const CreatePlateLayerDialog = ({
  record,
  noEmptyWells,
  hideModal,
  refetch,
  containerFormat,
  handleSubmit,
  submitting,
  invalid,
  submitFailed
}) => {
  const plateLayers = useFormValue(form, "plateLayers");
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [page, setPage] = useState(0);
  const { aliquotContainers, plateMapItems, containerArrayType } = record;

  const plateLocationMap = useMemo(
    () => createPlateLocationMap(aliquotContainers || plateMapItems),
    [aliquotContainers, plateMapItems]
  );

  const updateLocations = locations => {
    if (noEmptyWells) {
      locations = locations.filter(lo => {
        const ac = plateLocationMap[lo];
        return ac && ac.id;
      });
    }
    setSelectedLocations(locations);
  };

  const dispatch = useDispatch();
  const change = useCallback(
    (field, value) => dispatch(_change(form, field, value)),
    [dispatch]
  );

  const addSelectedWellsToZone = (plateLayerName, zoneIndex) => {
    if (!selectedLocations.length) return;
    if (!plateLayers?.[page]) return;
    const plateLayer = plateLayers[page];
    const newZones = plateLayer.zones.map((zone, i) => {
      const { locations = [] } = zone;
      let newLocations;
      if (i === zoneIndex) {
        newLocations = union(locations, selectedLocations);
      } else {
        newLocations = without(locations, ...selectedLocations);
      }
      if (newLocations.length !== locations.length) {
        return {
          ...zone,
          locations: newLocations
        };
      } else {
        return zone;
      }
    });
    change(`${plateLayerName}.zones`, newZones);
  };

  const onSubmit = async values => {
    const { plateLayers } = values;

    const plateLayerDefIds = [];
    const errors = {};
    plateLayers.forEach((pl, i) => {
      pl.zones.forEach((zone, j) => {
        const fieldName = `plateLayers[${i}].zones[${j}]`;
        if (!zone.locations?.length) {
          set(errors, `${fieldName}.name`, "No wells assigned to zone.");
        }
      });
    });
    if (!isEmpty(errors)) {
      return throwFormError(errors);
    }
    try {
      plateLayers.forEach(pl => {
        if (pl.id) {
          plateLayerDefIds.push(pl.plateLayerDefinitionId);
        }
      });

      await safeDelete("plateLayerDefinition", plateLayerDefIds);
      const newPlateLayerDefinitions = plateLayers.map(plateLayer => {
        return {
          name: plateLayer.name
        };
      });

      const plateLayerDefinitionIds = (
        await safeUpsert("plateLayerDefinition", newPlateLayerDefinitions)
      ).map(({ id }) => id);

      const newPlateLayers = plateLayers.map((plateLayer, index) => ({
        [record.__typename + "Id"]: record.id,
        plateLayerDefinitionId: plateLayerDefinitionIds[index],
        plateZones: plateLayer.zones.map(zone => {
          return {
            plateZoneDefinition: {
              plateLayerDefinitionId: plateLayerDefinitionIds[index],
              name: zone.name,
              color: zone.color
            },
            plateZoneWells: (zone.locations || []).reduce((acc, location) => {
              const ac = plateLocationMap[location];
              if (ac && ac.__typename) {
                acc.push({
                  [ac.__typename + "Id"]: ac.id
                });
              }
              return acc;
            }, [])
          };
        })
      }));

      await safeUpsert("plateLayer", newPlateLayers);
      await refetch?.();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error creating plate layers");
    }
  };

  const plateLayer = plateLayers?.[page];
  const aliquotContainersToShow = useMemo(() => {
    const acs = aliquotContainers || plateMapItems;
    const plateZones = plateLayer?.zones;
    if (plateZones?.length) {
      return acs.map(ac => {
        let acToReturn = ac;
        plateZones.some(zone => {
          const { locations = [], color } = zone;
          if (
            color &&
            locations.length &&
            locations.includes(getAliquotContainerLocation(ac))
          ) {
            acToReturn = {
              ...ac,
              color
            };
            return true;
          }
          return false;
        });
        return acToReturn;
      });
    }
    return acs;
  }, [aliquotContainers, plateLayer?.zones, plateMapItems]);

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        {noEmptyWells && (
          <Callout style={{ marginBottom: 15 }} intent="primary">
            Plate layers cannot be applied to empty wells.
          </Callout>
        )}
        <div className="tg-flex justify-space-between">
          <div>
            <FieldArray
              page={page}
              setPage={setPage}
              name="plateLayers"
              addSelectedWellsToZone={addSelectedWellsToZone}
              component={renderPlateLayers}
            />
          </div>
          <div>
            {plateLayer && (
              <h6 style={{ textAlign: "center", marginBottom: 15 }}>
                {plateLayer.name}
              </h6>
            )}
            <PlateMapPlate
              noPadding
              containerArrayType={containerArrayType}
              containerFormat={containerFormat}
              withDragSelect
              onWellsSelected={updateLocations}
              selectedAliquotContainerLocations={selectedLocations}
              aliquotContainers={aliquotContainersToShow}
            />
          </div>
        </div>
        {submitFailed && invalid && (
          <div className="tg-flex justify-flex-end" style={{ marginTop: 10 }}>
            <BlueprintError error="A plate layer has errors. Please check all plate layers for errors." />
          </div>
        )}
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
};

export default compose(
  wrapDialog({ title: "Create Plate Layers", style: { width: 800 } }),
  reduxForm({
    form,
    validate
  })
)(CreatePlateLayerDialog);

function validate(values) {
  const { plateLayers = [] } = values;
  const errors = {};
  const seenLayerNames = [];
  plateLayers.forEach((plateLayer, i) => {
    if (!plateLayer.name) {
      set(errors, `plateLayers[${i}].name`, REQUIRED_ERROR);
    } else if (seenLayerNames.includes(plateLayer.name)) {
      set(errors, `plateLayers[${i}].name`, "Another layer has the same name.");
    } else {
      seenLayerNames.push(plateLayer.name);
    }
    const zones = plateLayer.zones || [];
    const seenZoneNames = [];
    if (!zones.length) {
      set(
        errors,
        `plateLayers[${i}].zones._error`,
        "Please add at least one zone."
      );
    }
    zones.forEach((zone, j) => {
      const fieldName = `plateLayers[${i}].zones[${j}]`;
      if (!zone.name) {
        set(errors, `${fieldName}.name`, REQUIRED_ERROR);
      } else if (seenZoneNames.includes(zone.name)) {
        set(errors, `${fieldName}.name`, "Another zone has the same name.");
      } else {
        seenZoneNames.push(zone.name);
      }
      if (!zone.color) {
        set(errors, `${fieldName}.color`, REQUIRED_ERROR);
      }
    });
  });
  return errors;
}

function renderPlateLayers({ fields, page, setPage, addSelectedWellsToZone }) {
  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <AddButton
          noMargin
          text="Add Plate Layer"
          add={() => {
            const newLength = fields.length + 1;
            fields.push({});
            setPage(newLength - 1);
          }}
        />
        {fields.length > 1 && (
          <SimplePaging
            zeroIndexed
            numPages={fields.length}
            page={page}
            setPage={setPage}
          />
        )}
      </div>
      {fields.map((plateLayer, i) => {
        if (page !== i) return null;

        return (
          <div key={i}>
            <div className="tg-flex">
              <RemoveButton
                remove={() => {
                  const newLength = fields.length - 1;
                  fields.remove(i);
                  if (page === fields.length - 1) {
                    setPage(newLength - 1);
                  }
                }}
              />
              <InputField label="Name" name={`${plateLayer}.name`} />
            </div>
            <FieldArray
              plateLayer={plateLayer}
              name={`${plateLayer}.zones`}
              component={renderZones}
              addSelectedWellsToZone={addSelectedWellsToZone}
            />
          </div>
        );
      })}
    </div>
  );
}

function renderZones({
  fields,
  meta: { error },
  plateLayer,
  addSelectedWellsToZone
}) {
  return (
    <div>
      <div className="tg-flex align-center" style={{ marginBottom: 10 }}>
        <h6 style={{ marginBottom: 0 }}>Zones</h6>
        <AddButton noMargin fields={fields} text="Add Zone" />
      </div>
      {error && <BlueprintError error={error} />}
      {fields.map((zone, i) => {
        return (
          <div key={i}>
            <div className="tg-flex align-center">
              <RemoveButton fields={fields} index={i} />
              <InputField label="Name" name={`${zone}.name`} />
              <div className="tg-flex-separator" />
              <ReactColorField label="Color" name={`${zone}.color`} />
            </div>
            <Button
              small
              text="Add Selected Wells to Zone"
              onClick={() => addSelectedWellsToZone(plateLayer, i)}
            />
            {i !== fields.length - 1 && <hr className="tg-section-break" />}
          </div>
        );
      })}
    </div>
  );
}
