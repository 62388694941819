/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback } from "react";
import { CollapsibleCard, DataTable, withTableParams } from "@teselagen/ui";
import { withRouter } from "react-router-dom";
import withQuery from "../withQuery";

import { compose, withProps } from "recompose";
import { addTagFilterToQuery } from "../utils/tagUtils";
import { tagColumnWithRender } from "../utils/tagColumn";
import gql from "graphql-tag";
import { taggedItemFragment } from "../../../tg-iso-shared/src/fragments/taggedItemFragment";

function MicrobialMaterialCard({ tableParams, history }) {
  return (
    <CollapsibleCard title="Microbial Materials">
      <DataTable
        {...tableParams}
        onDoubleClick={r => history.push(`/microbial-materials/${r.id}`)}
      />
    </CollapsibleCard>
  );
}

const fragment = gql`
  fragment microbialMaterialCardFragment on material {
    id
    name
    strain {
      id
      name
    }
    taggedItems {
      ...taggedItemFragment
    }
  }
  ${taggedItemFragment}
`;

export default compose(
  withRouter,
  withProps(props => {
    // Added while #12943 is not ready
    const additionalFilter = useCallback(
      (_, qb, currentParams) => {
        qb.whereAll({
          "microbialMaterialMicrobialMaterialPlasmids.polynucleotideMaterial.polynucleotideMaterialSequence.id":
            props.sequenceId
        });
        addTagFilterToQuery(currentParams.tags, qb);
      },
      [props.sequenceId]
    );
    return { additionalFilter };
  }),
  withTableParams({
    urlConnected: false,
    formName: "sequenceRecordMicrobialMaterials",
    schema: {
      model: "material",
      fields: [
        "name",
        {
          displayName: "Strain",
          path: "strain.name"
        },
        tagColumnWithRender
      ]
    }
  }),
  withQuery(fragment, { isPlural: true })
)(MicrobialMaterialCard);
