/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useMemo } from "react";
import { MenuItem } from "@blueprintjs/core";
import { round } from "lodash";
import { materialMenuItems } from "../../../utils";
import AbstractLibrary from "../../../../src-shared/AbstractLibrary";
import libraryEnhancer from "../../../../src-shared/libraryEnhancer";
import dataTableSelectFilterMenu from "../../../../src-shared/dataTableSelectFilterMenu";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import gql from "graphql-tag";
import MaterialRecordView from "../../Record/MaterialRecordView";
import UploadDNASequenceDialog from "../../../../src-shared/UploadDNASequenceDialog";
import { buildMaterialAvailabilityColumns } from "../../../../src-shared/utils/libraryColumns";
import CreateNewMaterialDialog from "../../Dialogs/CreateNewMaterialDialog";

const materialTypeCode = "DNA";

const SequenceTypeFilterMenu = dataTableSelectFilterMenu({
  options: [
    {
      value: "CIRCULAR_DNA",
      label: "Circular DNA"
    },
    {
      value: "LINEAR_DNA",
      label: "Linear DNA"
    },
    {
      value: "OLIGO",
      label: "Oligo"
    }
  ],
  multi: true,
  paramKey: "sequenceType",
  label: "Sequence Type"
});

export const sequenceTypeColumn = {
  path: "polynucleotideMaterialSequence.sequenceType.name",
  type: "string",
  displayName: "Sequence Type",
  FilterMenu: SequenceTypeFilterMenu,
  filterKey: "sequenceType",
  filterIsActive: currentParams => currentParams.sequenceType
};

const schema = {
  model: "material",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    sequenceTypeColumn,
    {
      path: "polynucleotideMaterialSequence.size",
      type: "number",
      displayName: "Size"
    },
    ...buildMaterialAvailabilityColumns,
    {
      path: "polynucleotideMaterialSequence.molecularWeight",
      type: "number",
      displayName: "Molecular Weight",
      render: v => {
        if (v) {
          return round(v, 2) + " g/mol";
        } else {
          return "N/A";
        }
      }
    },
    {
      displayName: "Associated Aliquots",
      filterDisabled: true,
      sortDisabled: true,
      render: (v, r) => {
        const aliquotIdArray = [];
        r.samples.forEach(sample =>
          sample.aliquots.forEach(aliquot => aliquotIdArray.push(aliquot.id))
        );
        return aliquotIdArray.length;
      }
    },
    {
      displayName: "Replication Origin",
      render: (v, r) =>
        r.polynucleotideMaterialSequence &&
        r.polynucleotideMaterialSequence.sequenceFeatures.length > 0 &&
        r.polynucleotideMaterialSequence.sequenceFeatures
          .filter(feature => feature.type === "rep_origin")
          .map(feature => feature.name)
          .join(", ")
    }
  ]
};

const onNewItemClick = ({ history }) => {
  showDialog({
    ModalComponent: CreateNewMaterialDialog,
    modalProps: {
      history,
      initialValues: {
        materialTypeCode
      }
    }
  });
};

const MaterialLibrary = props => {
  const { refetchMaterials, history } = props;

  const onDoubleClick = useCallback(
    record => {
      history.push(`/dna-materials/${record.id}`);
    },
    [history]
  );

  const uploadMenuItems = useMemo(
    () => [
      <MenuItem
        key="uploadSequences"
        text="Upload DNA Materials"
        onClick={() => {
          showDialog({
            ModalComponent: UploadDNASequenceDialog,
            modalProps: {
              refetch: refetchMaterials,
              isMaterial: true
            }
          });
        }}
      />
    ],
    [refetchMaterials]
  );

  return (
    <AbstractLibrary
      {...props}
      libraryName="dnaMaterial"
      model="material"
      RecordViewInspector={MaterialRecordView}
      libraryTitle="DNA Materials"
      uploadMenuItems={uploadMenuItems}
      generateExtraContextMenuItems={materialMenuItems}
      isLibraryTable
      withExport
      onDoubleClick={onDoubleClick}
      onNewItemClick={onNewItemClick}
    />
  );
};

const fragment = gql`
  fragment dnaMaterialLibraryFragment on material {
    id
    name
    materialTypeCode
    internalAvailability
    externalAvailability
    polynucleotideMaterialSequence {
      id
      name
      molecularWeight
      sequenceTypeCode
      sequenceType {
        code
        name
      }
      sequenceFeatures {
        id
        name
        type
      }
      size
    }
    samples {
      id
      aliquots {
        id
      }
    }
  }
`;

export default libraryEnhancer({
  formName: "dnaMaterialLibrary",
  fragment,
  schema,
  integrationSubtype: "DNA_MATERIAL",
  additionalFilter: (_, qb, currentParams) => {
    qb.andWhereAll({
      materialTypeCode,
      ...(currentParams.sequenceType && {
        "polynucleotideMaterialSequence.sequenceTypeCode":
          currentParams.sequenceType
      })
    });
  }
})(MaterialLibrary);
