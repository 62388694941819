/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Intent, Callout, Tooltip, Icon } from "@blueprintjs/core";
import {
  DataTable,
  Loading,
  BlueprintError,
  CheckboxField,
  FileUploadField,
  IntentText,
  useDeepEqualMemo
} from "@teselagen/ui";
import { cloneDeep, get, uniqBy } from "lodash";
import dataTableFragment from "../../../../graphql/fragments/dataTableFragment";
import {
  arpDataRowFragment,
  arpJ5ConstructAssemblyPieceFragment,
  arpContainerArrayFragment
} from "../fragments";
import { computeDataTableValues, getMaterialsFromDataTable } from "../utils";
import { validateNoDryPlatesObject } from "../../../../utils/plateUtils";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import { volumeRender } from "../../../../../src-shared/utils/unitUtils";
import platePreviewColumn from "../../../../utils/platePreviewColumn";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import { getCaseInsensitiveKeyedItems } from "../../../../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import { getDownloadTemplateFileHelpers } from "../../../../../src-shared/components/DownloadTemplateFileButton";
import { useSelector } from "react-redux";
import { formValueSelector } from "redux-form";

const fields = [
  {
    path: "Assembly Piece Material",
    description: "Material used for assembly piece",
    example: "PCR-amplified DNA fragments"
  },
  {
    path: "Construct Material",
    description: "Material used for construct",
    example: "Plasmid backbone"
  }
];

const additionalFilterForTubes = (props, qb) => {
  qb.whereAll({
    containerArrayId: qb.isNull()
  });
};

const validateAssemblyCsv = (fileList = []) => {
  const csvFile = fileList[0];
  if (csvFile && csvFile.error) {
    return csvFile.error;
  }
};

const SelectJ5ConstructList = ({
  Footer,
  footerProps,
  handleSubmit,
  nextStep,
  stepFormProps: { change },
  toolIntegrationProps: {
    isDisabledMap: {
      constructLists: disableConstructLists,
      containerArrays: disableContainerArrays
    } = {},
    isLoadingMap: {
      constructLists: loadingConstructLists,
      containerArrays: loadingContainerArrays
    } = {}
  },
  toolSchema
}) => {
  const selector = formValueSelector(toolSchema.code);
  const {
    aliquotContainers: _aliquotContainers = [],
    assemblyMaterials: _assemblyMaterials = [],
    constructReactionMap: _constructReactionMap,
    constructLists: _constructLists = [],
    containerArrays: _containerArrays = [],
    lastLoadingConstructListIds: _lastLoadingConstructListIds = [],
    partialConstructLists: _partialConstructLists = [],
    selectedDataTables: _selectedDataTables = [],
    uploadAdditionalAssemblyPieces: _uploadAdditionalAssemblyPieces,
    assemblyPieceCsv: _assemblyPieceCsv = []
  } = useSelector(state =>
    selector(
      state,
      "aliquotContainers",
      "assemblyMaterials",
      "constructReactionMap",
      "constructLists",
      "containerArrays",
      "lastLoadingConstructListIds",
      "partialConstructLists",
      "selectedDataTables",
      "uploadAdditionalAssemblyPieces",
      "assemblyPieceCsv"
    )
  );

  const aliquotContainers = useDeepEqualMemo(_aliquotContainers);
  const assemblyMaterials = useDeepEqualMemo(_assemblyMaterials);
  const assemblyPieceCsv = useDeepEqualMemo(_assemblyPieceCsv);
  const constructReactionMap = useDeepEqualMemo(_constructReactionMap);
  const constructLists = useDeepEqualMemo(_constructLists);
  const containerArrays = useDeepEqualMemo(_containerArrays);
  const lastLoadingConstructListIds = useDeepEqualMemo(
    _lastLoadingConstructListIds
  );
  const partialConstructLists = useDeepEqualMemo(_partialConstructLists);
  const selectedDataTables = useDeepEqualMemo(_selectedDataTables);
  const uploadAdditionalAssemblyPieces = useDeepEqualMemo(
    _uploadAdditionalAssemblyPieces
  );

  const [loadingFullConstructLists, setLoadingFullConstructLists] =
    useState(false);

  useEffect(() => {
    const loadConstructReactionMap = async () => {
      if (assemblyPieceCsv.length || !constructReactionMap) return;
      // removed upload. need to clear out uploaded pieces
      const newAssemblyMaterials = assemblyMaterials.filter(m => !m.csvUpload);
      change("assemblyMaterials", newAssemblyMaterials);
      // make new object for reactions so that table will re-render
      const newConstructReactionMap = cloneDeep(constructReactionMap);
      newConstructReactionMap.reactions.forEach(reaction => {
        const keepMaterialIds = [];
        const filteredInputs = reaction.reactionInputs.filter(input => {
          const keep = !input.csvUpload;
          if (keep) {
            keepMaterialIds.push(input.inputMaterialId);
          }
          return keep;
        });
        if (filteredInputs.length !== reaction.reactionInputs.length) {
          reaction.reactionInputs = filteredInputs;
          reaction.inputMaterials = reaction.inputMaterials.filter(m =>
            keepMaterialIds.includes(m.id)
          );
          reaction.inputs = {
            names: reaction.inputMaterials.map(m => m.name).join(", ")
          };
        }
      });
      change("constructReactionMap", newConstructReactionMap);
    };
    loadConstructReactionMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assemblyMaterials, assemblyPieceCsv.length, constructReactionMap]);

  useEffect(() => {
    const load = async () => {
      const newIds = partialConstructLists.map(w => w.id);
      const wasAlreadyLoaded = newIds.every(id =>
        lastLoadingConstructListIds.includes(id)
      );
      // reset
      if (!partialConstructLists.length && constructLists.length) {
        change("constructLists", []);
        change("lastLoadingConstructListIds", []);
      }

      if (!wasAlreadyLoaded && partialConstructLists.length) {
        change("lastLoadingConstructListIds", newIds);
        setLoadingFullConstructLists(true);

        try {
          const constructLists = [];
          for (const partialConstructList of partialConstructLists) {
            let dataRows = await safeQuery(arpDataRowFragment, {
              variables: {
                filter: {
                  dataTableId: partialConstructList.id
                }
              }
            });
            const j5ConstructIds = dataRows.reduce((acc, row) => {
              row.dataRowJ5Items.forEach(drJ5Item => {
                const j5ConstructId = get(drJ5Item, "j5Item.j5RunConstruct.id");
                if (j5ConstructId) {
                  acc.push(j5ConstructId);
                }
              });
              return acc;
            }, []);
            if (j5ConstructIds) {
              const constructAPs = await safeQuery(
                arpJ5ConstructAssemblyPieceFragment,
                {
                  variables: {
                    filter: {
                      j5RunConstructId: j5ConstructIds
                    }
                  }
                }
              );
              if (constructAPs) {
                const groupedConstructAps = constructAPs.reduce((acc, ap) => {
                  if (!acc[ap.j5RunConstructId]) acc[ap.j5RunConstructId] = [];
                  acc[ap.j5RunConstructId].push(ap);
                  return acc;
                }, {});
                dataRows = dataRows.map(dataRow => {
                  return {
                    ...dataRow,
                    dataRowJ5Items: dataRow.dataRowJ5Items.map(drJ5Item => {
                      const j5RunConstruct = get(
                        drJ5Item,
                        "j5Item.j5RunConstruct"
                      );
                      const j5ConstructAssemblyPieces =
                        (j5RunConstruct &&
                          groupedConstructAps[j5RunConstruct.id]) ||
                        [];
                      if (j5RunConstruct) {
                        return {
                          ...drJ5Item,
                          j5Item: {
                            ...drJ5Item.j5Item,
                            j5RunConstruct: {
                              ...j5RunConstruct,
                              j5ConstructAssemblyPieces
                            }
                          }
                        };
                      } else {
                        return drJ5Item;
                      }
                    })
                  };
                });
              }
            }
            constructLists.push({
              ...partialConstructList,
              dataRows
            });
          }

          const {
            constructDataTablesName,
            constructMap,
            constructReactionMap
          } = computeDataTableValues(constructLists);

          change("constructDataTablesName", constructDataTablesName);
          change("constructMap", constructMap);
          change("constructReactionMap", constructReactionMap);
          const selectedDataSetIds = constructLists.map(
            dataTable => dataTable.dataSet && dataTable.dataSet.id
          );
          const dataTables = await safeQuery(dataTableFragment, {
            variables: {
              filter: {
                dataSetId: selectedDataSetIds,
                dataTableTypeCode: "J5_ASSEMBLY_PIECE_LIST"
              }
            }
          });
          change("selectedDataTables", dataTables);
          const assemblyMaterials = getMaterialsFromDataTable(dataTables);
          change("assemblyMaterials", uniqBy(assemblyMaterials, "id"));
        } catch (error) {
          console.error("error:", error);
          window.toastr.error("Error loading full construct lists.");
        }

        setLoadingFullConstructLists(false);
      }
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    constructLists.length,
    lastLoadingConstructListIds,
    partialConstructLists
  ]);

  const materialMap = useMemo(() => {
    const sourceMaterialMap = {};

    containerArrays.forEach(plate => {
      plate.aliquotContainers.forEach(container => {
        const materialId = get(container, "aliquot.sample.material.id");
        if (materialId) {
          sourceMaterialMap[materialId] = true;
        }
      });
    });
    aliquotContainers.forEach(tube => {
      const materialId = get(tube, "aliquot.sample.material.id");
      if (materialId) {
        sourceMaterialMap[materialId] = true;
      }
    });
    return sourceMaterialMap;
  }, [aliquotContainers, containerArrays]);

  const removeConstructSelection = () => {
    change("constructReactionMap", null);
    change("lastLoadingConstructListIds", []);
    change("constructDataTablesName", null);
    change("constructMap", null);
    change("selectedDataTables", []);
  };

  const beforeNextStep = values => {
    const { containerArrays = [] } = values;
    // add nested containerArray to aliquot containers
    const fullContainerArrays = containerArrays.map(c => {
      return {
        ...c,
        aliquotContainers: c.aliquotContainers.map(ac => {
          return {
            ...ac,
            containerArray: {
              id: c.id,
              name: c.name
            }
          };
        })
      };
    });
    change("containerArrays", fullContainerArrays);
    nextStep();
  };

  const handleAssemblyInfoCsv = useCallback(
    async (fileList, onChange) => {
      const csvFile = fileList[0];
      try {
        const { parsedData } = csvFile;
        const materialNames = [];
        const keyedReactions = {};
        // make new object for reactions so that table will re-render
        const newConstructReactionMap = cloneDeep(constructReactionMap);
        newConstructReactionMap.reactions.forEach(reaction => {
          const { output } = reaction;
          keyedReactions[output.name] = reaction;
        });
        for (const [index, row] of parsedData.entries()) {
          const {
            "Assembly Piece Material": assemblyPieceMaterialName,
            "Construct Material": constructMaterialName
          } = row;
          if (!keyedReactions[constructMaterialName]) {
            throw new Error(
              `Row ${
                index + 1
              } specifies the construct material ${constructMaterialName} which was not found on the selected construct list.`
            );
          }
          materialNames.push(assemblyPieceMaterialName.trim());
        }
        if (!materialNames.length) {
          throw new Error("No materials specified in CSV");
        }
        const keyedMaterials = await getCaseInsensitiveKeyedItems(
          "material",
          "name",
          materialNames
        );
        const csvAssemblyMaterials = [];
        const csvReactions = {};
        for (const [index, row] of parsedData.entries()) {
          const {
            "Assembly Piece Material": assemblyPieceMaterialName,
            "Construct Material": constructMaterialName
          } = row;
          const assemblyPieceMaterial =
            keyedMaterials[assemblyPieceMaterialName.toLowerCase()];
          if (!assemblyPieceMaterial) {
            throw new Error(
              `Row ${
                index + 1
              } specifies the assembly piece material ${assemblyPieceMaterialName} which was not found in inventory.`
            );
          }
          csvAssemblyMaterials.push(assemblyPieceMaterial);
          if (!csvReactions[constructMaterialName]) {
            csvReactions[constructMaterialName] = [];
          }
          csvReactions[constructMaterialName].push(assemblyPieceMaterial);
        }
        const newFile = {
          ...csvFile,
          loading: false
        };
        // if they haven't cleared the data table while this was loading
        if (selectedDataTables.length) {
          const existingAssemblyMaterialIds = assemblyMaterials.map(m => m.id);
          const newAssemblyMaterials = [...assemblyMaterials];
          csvAssemblyMaterials.forEach(m => {
            if (!existingAssemblyMaterialIds.includes(m.id)) {
              newAssemblyMaterials.push({ ...m, csvUpload: true });
            }
          });
          Object.keys(csvReactions).forEach(constructMaterialName => {
            const assemblyMaterials = csvReactions[constructMaterialName];
            const existingReaction = keyedReactions[constructMaterialName];
            const materialsToAddToReaction = [];
            assemblyMaterials.forEach(m => {
              const alreadyHasMaterial = existingReaction.reactionInputs.some(
                ri => {
                  return ri.inputMaterialId === m.id;
                }
              );
              if (!alreadyHasMaterial) {
                materialsToAddToReaction.push(m);
              }
            });
            if (materialsToAddToReaction.length) {
              existingReaction.inputMaterials.push(...materialsToAddToReaction);
              materialsToAddToReaction.forEach(m => {
                existingReaction.reactionInputs.push({
                  inputMaterialId: m.id,
                  csvUpload: true
                });
              });
              existingReaction.inputs = {
                names: existingReaction.inputMaterials
                  .map(m => m.name)
                  .join(", ")
              };
            }
          });
          change("constructReactionMap", newConstructReactionMap);
          change("assemblyMaterials", uniqBy(newAssemblyMaterials, "id"));
          onChange([newFile]);
        } else {
          onChange([]);
        }
      } catch (error) {
        onChange([
          {
            ...csvFile,
            loading: false,
            error: error.message || "Error parsing csv file."
          }
        ]);
        console.error("error:", error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assemblyMaterials, constructReactionMap, selectedDataTables?.length]
  );

  const anyInitialValuesLoading =
    loadingContainerArrays || loadingConstructLists;

  const unMappedReactionErrors = [];
  let validConstructSelection = true;
  constructReactionMap &&
    constructReactionMap.reactions.forEach(reaction => {
      if (reaction.inputs.names === ", " || !reaction.output.name) {
        unMappedReactionErrors.push(reaction.name);
        validConstructSelection = false;
      }
    });

  const reactions = constructReactionMap ? constructReactionMap.reactions : [];
  let reactionErrors;
  if (unMappedReactionErrors.length > 1)
    reactionErrors =
      "The selected data tables have unlinked assembly pieces or output constructs in the following reactions: " +
      unMappedReactionErrors.join(", ") +
      ".";

  const plateErrors = validateNoDryPlatesObject(containerArrays);

  const missingMaterials = [];
  if (
    constructReactionMap &&
    validConstructSelection &&
    !loadingFullConstructLists &&
    (containerArrays.length || aliquotContainers.length)
  ) {
    assemblyMaterials.forEach(mat => {
      if (!materialMap[mat.id]) {
        missingMaterials.push(mat.name);
      }
    });
  }

  const aReactionIsBroken = reactions.some(r => !r.inputMaterials.length);

  const assemblyPlanningReactionMapReviewDataTableSchema = useMemo(
    () => [
      {
        type: "action",
        width: 30,
        render: (v, r) => {
          if (!containerArrays.length && !aliquotContainers.length) {
            return (
              <Tooltip content="Pending selection of input plates/tubes">
                <Icon intent="warning" icon="warning-sign" />
              </Tooltip>
            );
          } else if (!r.inputMaterials.length) {
            return (
              <Tooltip content="Reaction does not have any inputs">
                <Icon intent="error" icon="warning-sign" />
              </Tooltip>
            );
          } else {
            const missingInputMaterials = [];
            r.inputMaterials.forEach(mat => {
              if (!materialMap[mat.id]) {
                missingInputMaterials.push(mat.name);
              }
            });
            if (missingInputMaterials.length) {
              return (
                <Tooltip
                  content={`Reaction is missing these input materials: ${missingInputMaterials.join(
                    ", "
                  )}`}
                >
                  <Icon intent="danger" icon="error" />
                </Tooltip>
              );
            } else {
              return (
                <Tooltip content="All materials for this reaction have been selected">
                  <Icon intent="success" icon="tick-circle" />
                </Tooltip>
              );
            }
          }
        }
      },
      { path: "name", displayName: "Name" },
      {
        path: "inputs.names",
        displayName: "Inputs",
        render: (v, r) =>
          r.inputMaterials.map((material, i) => {
            if (!containerArrays.length && !aliquotContainers.length) {
              return (
                <div style={{ marginTop: 5 }} key={material.id}>
                  {material.name}
                </div>
              );
            }
            return (
              <IntentText
                key={i}
                intent={materialMap[material.id] ? "success" : "danger"}
              >
                {material.name}
              </IntentText>
            );
          })
      },
      { path: "output.name", displayName: "Output" }
    ],
    [aliquotContainers.length, containerArrays.length, materialMap]
  );

  const accept = useMemo(
    () =>
      getDownloadTemplateFileHelpers({
        fileName: "assembly_pieces",
        validateAgainstSchema: {
          fields
        }
      }),
    []
  );

  return (
    <div>
      <div className="tg-step-form-section column">
        <div className="tg-flex justify-space-between">
          <HeaderWithHelper
            header="Select Construct Lists"
            helper="Select one or more lists
              of constructs."
          />
          <div>
            <GenericSelect
              name="partialConstructLists"
              isRequired
              schema={[
                "name",
                {
                  displayName: "Data Set",
                  path: "dataSet.name"
                },
                dateModifiedColumn
              ]}
              onClear={removeConstructSelection}
              isMultiSelect
              nameOverride="Construct Lists"
              buttonProps={{
                disabled: disableConstructLists,
                loading: loadingConstructLists
              }}
              fragment={["dataTable", "id name dataSet { id name } updatedAt"]}
              tableParamOptions={{
                additionalFilter: {
                  dataTableTypeCode: "J5_CONSTRUCT_SELECTION"
                }
              }}
            />
          </div>
        </div>
        {loadingFullConstructLists && <Loading inDialog />}
        {constructReactionMap &&
          validConstructSelection &&
          !loadingFullConstructLists && (
            <>
              <DataTable
                formName="assemblyPlanningReactionMapReviewDataTable"
                className="assembly-reaction-reactions-table"
                schema={assemblyPlanningReactionMapReviewDataTableSchema}
                destroyOnUnmount={false}
                entities={reactions}
                style={{ marginTop: 15 }}
                isSimple
                withPaging
                defaults={{
                  pageSize: 100
                }}
                noSelect
              />
              <CheckboxField
                label="Upload Additional Assembly Pieces"
                name="uploadAdditionalAssemblyPieces"
              />
              {uploadAdditionalAssemblyPieces && (
                <div style={{ maxWidth: 450 }}>
                  <FileUploadField
                    label="Upload a CSV with extra assembly pieces not included in the j5 report."
                    beforeUpload={handleAssemblyInfoCsv}
                    validate={validateAssemblyCsv}
                    accept={accept}
                    fileLimit={1}
                    name="assemblyPieceCsv"
                  />
                </div>
              )}
            </>
          )}
        {!validConstructSelection && selectedDataTables.length && (
          <Callout intent={Intent.DANGER} icon="info-sign">
            {reactionErrors}
          </Callout>
        )}
      </div>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          width="100%"
          header="Select Source Plates"
          helper="Select one or more source plates of assembly pieces.
              Make sure that all of the necessary input materials are present."
        />
        <div className="width100 column">
          <GenericSelect
            name="containerArrays"
            schema={[
              "name",
              { displayName: "Barcode", path: "barcode.barcodeString" },
              dateModifiedColumn
            ]}
            isMultiSelect
            fragment={[
              "containerArray",
              "id name containerArrayType { id name } barcode { id barcodeString } updatedAt"
            ]}
            additionalDataFragment={arpContainerArrayFragment}
            postSelectDTProps={{
              formName: "assemblyReactionPlates",
              isSingleSelect: true,
              plateErrors,
              schema: [
                platePreviewColumn({
                  plateErrors
                }),
                "name",
                { displayName: "Barcode", path: "barcode.barcodeString" },
                {
                  displayName: "Plate Type",
                  path: "containerArrayType.name"
                }
              ]
            }}
            buttonProps={{
              loading: loadingContainerArrays,
              disabled: disableContainerArrays
            }}
          />
        </div>
      </div>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          width="100%"
          header="Select Source Tubes"
          helper="Select one or more source tubes of assembly pieces.
              Make sure that all of the necessary input materials are present."
        />
        <div className="width100 column">
          <GenericSelect
            name="aliquotContainers"
            schema={[
              "name",
              { displayName: "Barcode", path: "barcode.barcodeString" },
              dateModifiedColumn
            ]}
            isMultiSelect
            fragment={[
              "aliquotContainer",
              "id name barcode { id barcodeString } updatedAt"
            ]}
            additionalDataFragment={[
              "aliquotContainer",
              "id name barcode { id barcodeString } aliquotContainerType { code name } aliquot { id isDry volume volumetricUnitCode mass massUnitCode sample { id name material { id name } } }"
            ]}
            tableParamOptions={{
              additionalFilter: additionalFilterForTubes
            }}
            postSelectDTProps={{
              formName: "assemblyReactionTubes",
              schema: [
                "name",
                { displayName: "Barcode", path: "barcode.barcodeString" },
                {
                  displayName: "Material",
                  path: "aliquot.sample.material.name"
                },
                {
                  displayName: "Volume",
                  path: "aliquot",
                  render: volumeRender
                },
                {
                  displayName: "Tube Type",
                  path: "aliquotContainerType.name"
                }
              ]
            }}
            buttonProps={{
              loading: loadingContainerArrays,
              disabled: disableContainerArrays
            }}
          />
        </div>
      </div>
      <Footer
        {...footerProps}
        errorMessage={
          missingMaterials.length
            ? "Please check construct table for missing reaction materials."
            : ""
        }
        nextButton={
          <Button
            intent={Intent.PRIMARY}
            onClick={handleSubmit(beforeNextStep)}
            disabled={
              aReactionIsBroken ||
              missingMaterials.length ||
              !selectedDataTables.length ||
              !validConstructSelection
            }
            loading={anyInitialValuesLoading}
          >
            Next
          </Button>
        }
      />
      {constructReactionMap && !selectedDataTables.length && (
        <div className="tg-flex justify-flex-end">
          <BlueprintError error="No assembly lists found." />
        </div>
      )}
    </div>
  );
};

export default SelectJ5ConstructList;
