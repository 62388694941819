/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import { DialogFooter, wrapDialog } from "@teselagen/ui";

import { get, noop } from "lodash";
import {
  workQueueItemAssignedNotification,
  workQueueItemReadyNotification,
  isToolReady
} from "../utils";
import gql from "graphql-tag";
import { workQueueItemsOnWorkflowToolsFragment } from "../fragments/newWorkflowRunFragment.gql";
import { createNotification } from "../../../../src-shared/utils/createNotification";
import UserSelect from "../../../../src-shared/UserSelect";
import { safeUpsert, safeQuery } from "../../../../src-shared/apolloMethods";
import { getJobUserForUser } from "../../../../../tg-iso-lims/src/utils/workflowUtils";

export const assignUserToTaskWorkflowToolFragment = gql`
  fragment assignUserToTaskWorkflowToolFragment on workflowTool {
    id
    workflowToolStatusCode
    workflowRun {
      id
      name
    }
    workflowToolDefinition {
      id
      name
      index
    }
    ...workQueueItemsOnWorkflowToolsFragment
  }
  ${workQueueItemsOnWorkflowToolsFragment}
`;

export async function assignUserToTask({ workflowTool, taskAssignee, jobId }) {
  const currentWorkQueueItems = await safeQuery(
    [
      "workQueueItem",
      /* GraphQL */ `
        {
          id
          reassigned
          jobUser {
            id
            userId
          }
        }
      `
    ],
    {
      variables: {
        filter: {
          workflowToolId: workflowTool.id
        }
      }
    }
  );

  const workQueueItemForUser = currentWorkQueueItems.find(
    item => get(item, "jobUser.userId") === taskAssignee.id
  );

  const previouslyAssignedWorkQueueItems = currentWorkQueueItems.filter(
    item => item.reassigned === false && item !== workQueueItemForUser
  );
  const updates = [];
  if (previouslyAssignedWorkQueueItems.length) {
    previouslyAssignedWorkQueueItems.forEach(
      previouslyAssignedWorkQueueItem => {
        updates.push({
          id: previouslyAssignedWorkQueueItem.id,
          reassigned: true
        });
      }
    );
  }

  if (workQueueItemForUser) {
    if (!workQueueItemForUser.reassigned) {
      // the chosen assignee is already assigned to this task
      return window.toastr.warning(
        "This user is already assigned to this task."
      );
    } else {
      updates.push({ id: workQueueItemForUser.id, reassigned: false });
    }
  } else {
    const jobUser = await getJobUserForUser(taskAssignee, jobId);
    await safeUpsert("workQueueItem", {
      reassigned: false,
      workflowToolId: workflowTool.id,
      jobUserId: jobUser.id
    });
  }

  await safeUpsert("workQueueItem", updates);

  // update cache after update
  const fullWorkflowTool = await safeQuery(
    assignUserToTaskWorkflowToolFragment,
    {
      variables: {
        id: workflowTool.id
      }
    }
  );

  const newNotifications = [
    workQueueItemAssignedNotification(taskAssignee.id, fullWorkflowTool)
  ];
  if (isToolReady(fullWorkflowTool)) {
    newNotifications.push(
      workQueueItemReadyNotification(taskAssignee.id, fullWorkflowTool)
    );
  }
  await createNotification(newNotifications);
}

const AssignUserToTaskDialog = ({
  workflowTool,
  jobId,
  hideModal,
  afterUpdate = noop,
  handleSubmit,
  submitting
}) => {
  const onSubmit = async values => {
    try {
      const { taskAssignee } = values;
      await assignUserToTask({
        workflowTool,
        taskAssignee,
        jobId
      });
      await afterUpdate();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error updating task assignee.");
    }
  };

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <UserSelect name="taskAssignee" label="Task Assignee" isRequired />
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
};

export default compose(
  wrapDialog({
    title: "Assign User to Task"
  }),
  reduxForm({
    form: "assignUserToTaskForm"
  })
)(AssignUserToTaskDialog);
