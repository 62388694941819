/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { reduxForm } from "redux-form";
import { compose } from "redux";
import { Card } from "@blueprintjs/core";
import { InputField, TextareaField, ReactSelectField } from "@teselagen/ui";
import { DesignImport } from "../../utils/designImportProcess";
import defaultValueConstants from "../../../../tg-iso-shared/src/defaultValueConstants";
import CustomHelpDocWidget from "../../../src-shared/CustomHelpDocsManagementPanel/CustomHelpDocWidget";
import customHelpDocConstants from "../../../src-shared/CustomHelpDocsManagementPanel/customHelpDocConstants";
import FormFooter from "../../../src-shared/components/FormFooter";
import "./style.css";

//Below is the code for create new design dialog
const EmptyDesign = ({ handleSubmit, submitting, history }) => {
  const beforeSubmit = async values => {
    try {
      const designImport = new DesignImport(history, false);
      await designImport.processImport(values);
    } catch (err) {
      console.error(err);
      window.toastr.error(err.message || "Error uploading design.");
    }
  };

  return (
    <Card className="form-layout">
      <form onSubmit={handleSubmit(beforeSubmit)}>
        <h2 className="edit-view-title">Create New Design</h2>
        <CustomHelpDocWidget {...customHelpDocConstants.NEW_DESIGN} />
        <InputField
          isRequired
          generateDefaultValue={{
            ...defaultValueConstants.DESIGN_NAME,
            customParams: {
              // lastDesignId: 189128
              //every field will always have userName, userId, timestamp, labId passed to it
            }
          }}
          name="name"
          label="Name"
          secondaryLabel="(required)"
        />
        <TextareaField name="description" label="Description" />
        <ReactSelectField
          name="layoutType"
          label="Design Layout Type"
          defaultValue="combinatorial"
          options={defaultValueConstants.DESIGN_LAYOUT_TYPE.options}
          disallowClear
          generateDefaultValue={defaultValueConstants.DESIGN_LAYOUT_TYPE}
        />
        <FormFooter submitting={submitting} history={history} />
      </form>
    </Card>
  );
};

export default compose(
  reduxForm({
    form: "emptyDesignForm"
  })
)(EmptyDesign);
