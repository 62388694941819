/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import Helmet from "react-helmet";
import { reduxForm } from "redux-form";
import { ReactSelectField } from "@teselagen/ui";
import { Button, Callout } from "@blueprintjs/core";
import { safeUpsert } from "../apolloMethods";
import useTgQuery from "../apolloUseTgQuery";
import { getActiveLabId } from "@teselagen/auth-utils";

const DefaultLabProject = ({ handleSubmit, submitting }) => {
  const activeLabId = getActiveLabId();
  const {
    data: { lab },
    ...rest
  } = useTgQuery(["lab", "id defaultProjectId"], {
    skip: !activeLabId,
    variables: {
      id: activeLabId
    }
  });
  const {
    data: { projects },
    ...rest2
  } = useTgQuery(["project", "id name"], {
    skip: !lab,
    variables: {
      filter: {
        labId: lab?.id
      }
    }
  });
  if (useTgQuery.checkErrAndLoad(rest)) {
    return useTgQuery.handleErrAndLoad(rest);
  }
  if (useTgQuery.checkErrAndLoad(rest2)) {
    return useTgQuery.handleErrAndLoad(rest2);
  }
  let innerContent;
  if (!lab) {
    innerContent = (
      <Callout intent="warning">
        No lab is active. Please choose a lab to set a default project for.
      </Callout>
    );
  } else {
    innerContent = (
      <>
        <h3>Default Lab Project</h3>
        <Callout style={{ marginBottom: 20 }} intent="primary">
          New users in this lab will be automatically added to this project on
          login and it will be selected for them.
        </Callout>
        <div style={{ maxWidth: 250 }}>
          <ReactSelectField
            label="Default Lab Project"
            name="defaultProjectId"
            isRequired
            defaultValue={lab.defaultProjectId}
            options={[
              {
                label: "None",
                value: "none"
              }
            ].concat(
              projects.map(project => ({
                label: project.name,
                value: project.id
              }))
            )}
          />
        </div>
        <div>
          <Button
            intent="primary"
            onClick={handleSubmit(async values => {
              try {
                await safeUpsert("lab", {
                  id: lab.id,
                  defaultProjectId:
                    values.defaultProjectId === "none"
                      ? null
                      : values.defaultProjectId
                });
                window.toastr.success("Default project updated");
              } catch (error) {
                console.error(`error:`, error);
                window.toastr.error("Error updating default project");
              }
            })}
            loading={submitting}
            text="Update Default Project"
          />
        </div>
      </>
    );
  }

  return (
    <div>
      <Helmet title="Default Lab Project" />
      <div className="tg-card">{innerContent}</div>
    </div>
  );
};

export default reduxForm({
  form: "DefaultLabProject"
})(DefaultLabProject);
