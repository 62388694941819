/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

export default ({ isRNA }, qb) => {
  if (isRNA) {
    qb.whereAll({ "sequence.sequenceTypeCode": "RNA" });
  } else {
    qb.whereAll({ "sequence.sequenceTypeCode": qb.notEquals("RNA") });
  }

  qb.whereAny({
    "sequence.isInLibrary": true
  });
};
