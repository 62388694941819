/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback } from "react";
import { CollapsibleCard, DataTable, withTableParams } from "@teselagen/ui";
import {
  speciesColumn,
  genusAndSpeciesColumn,
  targetOrganismGroupColumn
} from "../../../../src-shared/utils/libraryColumns";
import routeDoubleClick from "../../../../src-shared/utils/routeDoubleClick";
import { compose, withProps } from "recompose";
import withQuery from "../../../../src-shared/withQuery";
import gql from "graphql-tag";
import { addTagFilterToQuery } from "../../../../src-shared/utils/tagUtils";
import { tagColumnWithRender } from "../../../../src-shared/utils/tagColumn";
import { taggedItemFragment } from "../../../../../tg-iso-shared/src/fragments/taggedItemFragment";

function DerivedStrainCard({ tableParams, strain, openTitleElements }) {
  return (
    <CollapsibleCard
      title={`Derived ${
        strain.strainTypeCode === "MICROBIAL_STRAIN" ? "Strains" : "Cell Lines"
      }`}
      openTitleElements={openTitleElements}
    >
      <DataTable {...tableParams} noPadding onDoubleClick={routeDoubleClick} />
    </CollapsibleCard>
  );
}

const schema = {
  model: "strain",
  fields: [
    {
      displayName: "Name",
      path: "name"
    },
    speciesColumn,
    genusAndSpeciesColumn,
    targetOrganismGroupColumn,
    {
      displayName: "Biosafety Level",
      path: "biosafetyLevel.name"
    },
    tagColumnWithRender
  ]
};

const fragment = gql`
  fragment strainDerivedStrainFragment on strain {
    id
    name
    specie {
      id
      name
      abbreviatedName
      genus {
        id
        name
      }
    }
    targetOrganismClass {
      id
      name
    }
    biosafetyLevel {
      code
      name
    }
    taggedItems {
      ...taggedItemFragment
    }
  }
  ${taggedItemFragment}
`;

export default compose(
  withProps(props => {
    // Added while #12943 is not ready
    const additionalFilter = useCallback(
      (_, qb, currentParams) => {
        qb.whereAll({
          "sourceMicrobialMaterial.strainId": props.strain.id
        });
        addTagFilterToQuery(currentParams.tags, qb);
      },
      [props.strain.id]
    );
    return { additionalFilter };
  }),
  withTableParams({
    formName: "strainRecordDerivedStrains",
    urlConnected: false,
    schema
  }),
  withQuery(fragment, {
    isPlural: true
  })
)(DerivedStrainCard);
