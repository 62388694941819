/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { compose } from "redux";
import withQuery from "../../src-shared/withQuery";
import { get } from "lodash";
import RuleSet from "../components/RuleSet";
import ruleSetFragment from "../../../tg-iso-design/graphql/fragments/ruleSetFragment";
import recordViewEnhancer from "../../src-shared/recordViewEnhancer";

export default compose(
  recordViewEnhancer(ruleSetFragment, { noUser: true }),
  // TODO: Move this to the `handleDeleteClick` function. I would
  // put it there, but filtering appears to not work.
  withQuery(["binRuleSet", "id ruleSetId"], {
    isPlural: true,
    options: props => ({
      variables: {
        filter: {
          ruleSetId: get(props, "ruleSet.id")
        }
      }
    }),
    props: ({ data }) => ({
      associatedBinRuleSetIds: (get(data, "binRuleSets") || []).map(
        ({ id }) => id
      )
    })
  })
)(RuleSet);
