/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export default gql`
  fragment containerArrayLibraryFragment on containerArrayPathView {
    id
    createdAt
    updatedAt
    fullPath
    assignedPositionId
    equipmentId
    containerArrayType {
      id
      name
      isPlate
      containerFormatCode
      aliquotContainerType {
        code
        maxVolume
        volumetricUnitCode
      }
    }
    containerArray {
      id
      name # adding in name here so we can test some stuff
      assignedPosition {
        id
      }
    }
  }
`;
