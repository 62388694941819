/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get, groupBy } from "lodash";
import {
  getMaterialPlasmid,
  getMaterialPlasmidSequence
} from "./materialUtils";
import { safeQuery } from "../../src-shared/apolloMethods";

export const START_CODON = "atg";

export const isGenbankFile = (file: File) => {
  return (
    file.name && ["gb", "gbk"].includes(file.name.split(".").pop() as string)
  );
};

type Genome = {
  genomeGenomicRegions: {
    genomicRegion: { sequence: string };
  }[];
  __typename: "genome";
};

type Strain = {
  genome: Genome;
  strainPlasmids: {
    plasmid: { sequence: string };
    polynucleotideMaterial: any;
  }[];
  __typename: "strain";
};

type Material = {
  materialTypeCode: string;
  genome: Genome;
  strain: Strain;
  microbialMaterialMicrobialMaterialPlasmids: {
    id: string;
    copyNumber: number;
    polynucleotideMaterial: any;
  }[];
  cellCultureCellCulturePlasmids: {
    id: string;
    copyNumber: number;
    polynucleotideMaterial: any;
  }[];
  polynucleotideMaterialSequences?: string[];
  polynucleotideMaterialSequence?: string;
};

function isStrain(obj: any): obj is Strain {
  return obj && typeof obj === "object" && obj.__typename === "strain";
}

function isGenome(obj: any): obj is Genome {
  return obj && typeof obj === "object" && obj.__typename === "genome";
}

export const getSequencesToUse = (props: {
  aliquot: Material;
  material: Material;
  sample: Material;
  strain: Strain;
  genome: Genome;
  isGenomicRegion: boolean;
}) => {
  function getSeqsFromProps() {
    const { aliquot, material, sample, strain, genome, isGenomicRegion } =
      props;
    let entityWithSequences: Material | Strain | Genome;
    if (strain) {
      entityWithSequences = strain;
    } else if (genome) {
      entityWithSequences = genome;
    } else {
      entityWithSequences =
        material || get(aliquot, "sample.material") || get(sample, "material");
    }
    if (!entityWithSequences) return [];
    if (isStrain(entityWithSequences)) {
      if (isGenomicRegion) {
        return (
          entityWithSequences.genome &&
          entityWithSequences.genome.genomeGenomicRegions.map(
            ggr => ggr.genomicRegion
          )
        );
      } else {
        return entityWithSequences.strainPlasmids.map(strainPlasmid => ({
          ...getMaterialPlasmid(strainPlasmid),
          polynucleotideMaterialSequence:
            getMaterialPlasmidSequence(strainPlasmid)
        }));
      }
    } else if (isGenome(entityWithSequences)) {
      return entityWithSequences.genomeGenomicRegions.map(
        ggr => ggr.genomicRegion
      );
    } else if (entityWithSequences.materialTypeCode === "MICROBIAL") {
      if (isGenomicRegion) {
        if (entityWithSequences.genome) {
          return entityWithSequences.genome.genomeGenomicRegions.map(
            ggr => ggr.genomicRegion
          );
        } else if (get(entityWithSequences, "strain.genome")) {
          return entityWithSequences.strain.genome.genomeGenomicRegions.map(
            ggr => ggr.genomicRegion
          );
        }
      } else {
        return entityWithSequences.microbialMaterialMicrobialMaterialPlasmids.map(
          mmp => ({
            ...getMaterialPlasmid(mmp),
            polynucleotideMaterialSequence: getMaterialPlasmidSequence(mmp),
            microbialMaterialPlasmidId: mmp.id,
            copyNumber: mmp.copyNumber
          })
        );
      }
    } else if (entityWithSequences.materialTypeCode === "CELL_CULTURE") {
      return entityWithSequences.cellCultureCellCulturePlasmids.map(ccp => ({
        ...getMaterialPlasmid(ccp),
        polynucleotideMaterialSequence: getMaterialPlasmidSequence(ccp),
        cellCulturePlasmidId: ccp.id,
        copyNumber: ccp.copyNumber
      }));
    } else if (
      entityWithSequences.polynucleotideMaterialSequences &&
      !isGenomicRegion
    ) {
      return entityWithSequences.polynucleotideMaterialSequences;
    } else if (
      entityWithSequences.polynucleotideMaterialSequence &&
      !isGenomicRegion
    ) {
      return [entityWithSequences.polynucleotideMaterialSequence];
    }
    return [];
  }
  return getSeqsFromProps() || [];
};

export async function filterSequenceArrayToSequencesWithDnaMaterials(
  sequences: {
    id: string;
    hash: string;
    polynucleotideMaterialId: string;
  }[] = []
) {
  let selectedSequences = sequences;
  const sequenceLinkToMaterialUpdates: {
    id: string;
    polynucleotideMaterialId: string;
  }[] = [];
  if (sequences.length > 0) {
    const allSequencesWithSelectedHashes = await safeQuery(
      ["sequence", "id hash polynucleotideMaterialId"],
      {
        variables: {
          filter: {
            hash: sequences.map(s => s.hash)
          }
        }
      }
    );
    const groupedSequences = groupBy(allSequencesWithSelectedHashes, "hash");
    selectedSequences = selectedSequences.map(seq => {
      const foundSeq = groupedSequences[seq.hash].find(
        s => s.polynucleotideMaterialId
      );
      if (!seq.polynucleotideMaterialId && foundSeq) {
        sequenceLinkToMaterialUpdates.push({
          id: seq.id,
          polynucleotideMaterialId: foundSeq.polynucleotideMaterialId
        });
      }
      return foundSeq || seq;
    });
  }
  return { sequences: selectedSequences, sequenceLinkToMaterialUpdates };
}

export function assemblyReportEntityFilter({
  j5Reports,
  entityFilter,
  qb,
  onMaterial
}: {
  j5Reports: { id: string }[];
  entityFilter: string;
  qb: any;
  onMaterial: boolean;
}) {
  const j5ReportIds = j5Reports.map(j5Report => j5Report.id);

  const anyFilters: { [filterName: string]: typeof j5ReportIds }[] = [];

  const xtra = onMaterial ? "polynucleotideMaterialSequence." : "";

  const allFilters = {
    // pcr reactions
    [`${xtra}j5PcrReactionsPCRProductSequences.j5ReportId`]: j5ReportIds,
    // primary templates
    [`${xtra}j5PcrReactionsPrimaryTemplates.j5ReportId`]: j5ReportIds,
    // j5 input sequence
    [`${xtra}j5InputSequence.j5ReportId`]: j5ReportIds,
    // assembly pieces
    [`${xtra}j5AssemblyPiece.j5ReportId`]: j5ReportIds,
    // constructs
    [`${xtra}j5RunConstruct.j5ReportId`]: j5ReportIds,
    // oligos
    [`${xtra}j5Oligo.j5PcrReactionsForwardPrimers.j5ReportId`]: j5ReportIds,
    [`${xtra}j5Oligo.j5PcrReactionsReversePrimers.j5ReportId`]: j5ReportIds
  };
  Object.keys(allFilters).forEach(key => {
    if (entityFilter && !key.toLowerCase().includes(entityFilter.toLowerCase()))
      return;
    anyFilters.push({
      [key]: allFilters[key]
    });
  });
  qb.whereAny(...anyFilters);
}
