/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import useStyles from "substyle";

const defaultStyle = {};

function LoadingIndicator() {
  const style = useStyles(defaultStyle);
  const spinnerStyle = style("spinner");
  return (
    <div {...style}>
      <div {...spinnerStyle}>
        <div {...spinnerStyle(["element", "element1"])} />
        <div {...spinnerStyle(["element", "element2"])} />
        <div {...spinnerStyle(["element", "element3"])} />
        <div {...spinnerStyle(["element", "element4"])} />
        <div {...spinnerStyle(["element", "element5"])} />
      </div>
    </div>
  );
}

export default LoadingIndicator;
