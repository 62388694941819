/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useMemo } from "react";
import { ReactSelectField } from "@teselagen/ui";
import GenericSelect from "../../src-shared/GenericSelect";
import { dateModifiedColumn } from "../../src-shared/utils/libraryColumns";
import { assemblyReportEntityFilter } from "../utils";
import QueryBuilder from "tg-client-query-builder";

const materialFragment = [
  "material",
  "id name materialTypeCode materialType { code name }"
];

const materialSchema = [
  {
    path: "name"
  },
  {
    path: "materialType.name",
    displayName: "Type"
  },
  dateModifiedColumn
];

const entityFilterOptions = [
  { label: "Input Sequences", value: "j5InputSequence" },
  { label: "Primary Templates", value: "primaryTemplate" },
  { label: "Primers", value: "primer" },
  { label: "PCR Products", value: "pcrProductSequence" },
  { label: "Assembly Pieces", value: "j5AssemblyPiece" },
  { label: "Constructs", value: "j5RunConstruct" }
];

// entityType
function SelectJ5MaterialsOrPcrReactions({
  j5Reports = [],
  entityType = "material",
  modelNameToSchema = {},
  modelNameToFragment = {},
  modelNameToAdditionalFragment = {}
}: {
  j5Reports: { id: string }[];
  entityType: "material" | "j5PcrReaction";
  modelNameToSchema: any;
  modelNameToFragment: any;
  modelNameToAdditionalFragment: any;
}) {
  const j5MaterialFilter = useCallback(
    (
      _props: any,
      qb: QueryBuilder,
      currentParams: { entityFilter: string }
    ) => {
      assemblyReportEntityFilter({
        j5Reports,
        entityFilter: currentParams.entityFilter,
        onMaterial: true,
        qb
      });
    },
    [j5Reports]
  );
  const j5PcrReactionFilter = useCallback(
    (_props: any, qb: QueryBuilder) => {
      qb.whereAll({
        j5ReportId: j5Reports.map(j5Report => j5Report.id)
      });
    },
    [j5Reports]
  );

  const modelNameToFilter = useMemo(
    () => ({
      // separate filter
      j5PcrReaction: j5PcrReactionFilter,
      material: j5MaterialFilter
    }),
    [j5MaterialFilter, j5PcrReactionFilter]
  );

  const tableSchema = modelNameToSchema[entityType];
  return (
    <GenericSelect
      key={entityType}
      isRequired
      name={entityType === "material" ? "j5Materials" : "j5PcrReactions"}
      isMultiSelect
      nameOverride={entityType === "material" ? "Materials" : "Reactions"}
      schema={modelNameToSchema[entityType] || materialSchema}
      fragment={modelNameToFragment[entityType] || materialFragment}
      additionalDataFragment={modelNameToAdditionalFragment[entityType]}
      tableParamOptions={{ additionalFilter: modelNameToFilter[entityType] }}
      getButtonText={(val: string) => {
        let actionPrefix = "Select";
        if (val?.length) {
          actionPrefix = "Change";
        }
        if (entityType === "material") {
          return `${actionPrefix} DNA Assembly Materials`;
        } else {
          return `${actionPrefix} DNA Assembly PCR Reactions`;
        }
      }}
      postSelectDTProps={
        tableSchema && {
          formName: "j5EntitiesTable",
          schema: tableSchema
        }
      }
      additionalTableProps={
        entityType === "material"
          ? {
              enhancedChildren: ({
                tableParams
              }: {
                tableParams: {
                  currentParams: any;
                  setNewParams: (newParams: any) => void;
                };
              }) => {
                const { currentParams, setNewParams } = tableParams;
                return (
                  <ReactSelectField
                    name="entityFilter"
                    label="Entity Type"
                    onFieldSubmit={(val: any) => {
                      setNewParams({
                        ...currentParams,
                        // clear page so we don't get into a weird non-existing page state
                        page: undefined,
                        entityFilter: val
                      });
                    }}
                    options={entityFilterOptions}
                  />
                );
              }
            }
          : undefined
      }
    />
  );
}

export default SelectJ5MaterialsOrPcrReactions;
