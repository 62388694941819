/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export const workflowRunBasicInfoWorkflowRunFragment = gql`
  fragment workflowRunBasicInfoWorkflowRunFragment on workflowRun {
    id
    dueDate
    name
    notes
    workflowDefinition {
      id
      name
    }
    job {
      id
      name
    }
    workflowRunStatusType {
      code
      name
      description
    }
    priorityType {
      code
      color
      level
      name
    }
    lab {
      id
      name
    }
    user {
      id
      username
    }
    createdAt
    updatedAt
  }
`;
