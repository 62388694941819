/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useMemo } from "react";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { compose } from "recompose";
import { Classes, Button } from "@blueprintjs/core";
import dayjs from "dayjs";
import {
  InputField,
  DateInputField,
  TextareaField,
  DialogFooter,
  CheckboxField,
  InfoHelper,
  wrapDialog
} from "@teselagen/ui";
import "./style.css";
import GenericSelect from "../../../../src-shared/GenericSelect";
import withQuery from "../../../../src-shared/withQuery";
import { get } from "lodash";
import UserSelect from "../../../../src-shared/UserSelect";
import { createWorkflowRun } from "../../../../../tg-iso-lims/src/utils/workflowUtils";
import { onSubmitCreate as createNewJob } from "../LaunchNewJobDialog/submit";

const priorityDefaultValue = {
  code: "NORMAL",
  name: "Normal"
};
const priorityFragment = ["priorityType", "code name"];
const prioritySchema = ["name"];

const workflowFragment = ["workflowDefinition", "id name"];
const workflowSchema = [{ path: "name" }];

const LaunchNewWorkflowDialogInner = ({
  hideModal,
  history,
  jobId: _maybeJobId,
  refetch,
  currentUser,
  job,
  submitting,
  handleSubmit,
  isAdHoc,
  includeInactive,
  createJob,
  jobId
}) => {
  const onSubmit = useCallback(
    async (formData, shouldSave) => {
      try {
        let jobId = _maybeJobId;
        const { createJob, jobName, ...workflowValues } = formData;
        if (!jobId && createJob) {
          const newJob = await createNewJob(
            {
              name: jobName,
              priorityTypeCode: "NORMAL",
              jobOwner: currentUser
            },
            { returnJob: true }
          );
          if (!newJob) return;
          jobId = newJob.id;
        }
        const newWorkflowRun = await createWorkflowRun({
          ...workflowValues,
          jobId,
          workflowDefinitionId: get(formData, "workflowDefinition.id")
        });
        if (shouldSave && refetch) {
          await refetch();
        } else {
          history.push("/workflow-runs/" + newWorkflowRun.id);
        }
        hideModal();
      } catch (error) {
        console.error("error:", error);
        window.toastr.error("Error creating workflow run");
      }
    },
    [_maybeJobId, currentUser, hideModal, history, refetch]
  );

  const onLaunchClick = values => onSubmit(values, false);

  const additionalButtons = useMemo(() => {
    const onSaveClick = values => onSubmit(values, true);
    // let users save instead of launch workflow if in job record view
    if (job) {
      return [
        <Button
          key="save"
          text="Save"
          loading={submitting}
          intent="success"
          onClick={handleSubmit(onSaveClick)}
        />
      ];
    }
  }, [handleSubmit, job, onSubmit, submitting]);

  const newJobFields = useMemo(() => {
    if (!jobId) {
      return (
        <>
          <CheckboxField
            name="createJob"
            defaultValue
            label={
              <span>
                Create Job{" "}
                <InfoHelper
                  isInline
                  content="A job will be created for this workflow. Team members can be assigned to tasks."
                />
              </span>
            }
          />
          {createJob && (
            <InputField isRequired name="jobName" label="Job Name" />
          )}
        </>
      );
    }
  }, [createJob, jobId]);

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <InputField
          label="Name"
          name="name"
          isRequired
          defaultValue="Untitled Workflow Run"
          placeholder="My workflow"
        />
        {newJobFields}
        <CheckboxField
          name="isAdHoc"
          label={
            <span>
              Ad Hoc{" "}
              <InfoHelper
                isInline
                content="Ad hoc workflows are built in place without a workflow definition"
              />
            </span>
          }
        />
        {!isAdHoc && (
          <>
            <CheckboxField
              name="includeInactive"
              label={
                <span>
                  Include Unpublished Workflows{" "}
                  <InfoHelper
                    isInline
                    content="This will allow you to select workflow drafts"
                  />
                </span>
              }
            />
            <GenericSelect
              asReactSelect
              fragment={workflowFragment}
              isRequired
              label="Workflow"
              name="workflowDefinition" //the field name within the redux form Field
              schema={workflowSchema}
              tableParamOptions={{
                additionalFilter: {
                  isAdHoc: false,
                  isDeprecated: false,
                  ...(!includeInactive && { isActive: true })
                }
              }}
            />
          </>
        )}
        <GenericSelect
          asReactSelect
          defaultValue={priorityDefaultValue}
          fragment={priorityFragment}
          idAs="code"
          label="Priority"
          name="priorityType"
          schema={prioritySchema}
        />
        <DateInputField
          defaultValue={new Date()}
          label="Due Date"
          name="dueDate"
          minDate={new Date()}
          maxDate={dayjs().add(3, "years").toDate()}
        />
        <TextareaField name="notes" label="Notes" rows={8} />
        {!!job && (
          <UserSelect
            name="manager"
            label="Workflow Manager"
            defaultValue={job.jobOwner}
            isRequired
          />
        )}
      </div>
      <DialogFooter
        hideModal={hideModal}
        loading={submitting}
        additionalButtons={additionalButtons}
        onClick={handleSubmit(onLaunchClick)}
        text="Launch Workflow"
      />
    </>
  );
};

export default compose(
  wrapDialog({
    title: "New Workflow Run"
  }),
  reduxForm({
    form: "launchNewWorkflowRunForm"
  }),
  tgFormValues("isAdHoc", "createJob", "includeInactive"),
  withQuery(["job", `id jobOwner { id username }`], {
    skip: ({ jobId }) => !jobId,
    showLoading: true,
    inDialog: true,
    options: ({ jobId }) => ({
      variables: {
        id: jobId
      }
    })
  })
)(LaunchNewWorkflowDialogInner);
