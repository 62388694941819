/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { flatMap, pull, trimEnd } from "lodash";
import { getTaggedItemModelKey } from "./utils";

const tagOptionUrlSeparator = "_";

export function getInitialTaggedItems(records) {
  const typename = records[0].__typename;
  const taggedItemModelKey = getTaggedItemModelKey(typename);

  const mapTaggedItems = (taggedItems, extraFields = {}) => {
    return taggedItems.map(({ id, tag, tagOption }) => ({
      id,
      tagId: tag.id,
      tagOptionId: tagOption ? tagOption.id : null,
      ...extraFields
    }));
  };
  return flatMap(records, record => {
    if (record.taggedItems) {
      return mapTaggedItems(record.taggedItems, {
        [taggedItemModelKey]: record.id
      });
    } else {
      console.error("tags broken: could not find taggedItems");
    }
  });
}

export function addTagFilterToQuery(tags, qb, options = {}) {
  if (tags) {
    const {
      pathToTaggedItems = "taggedItems",
      not,
      atLeastOneOf,
      model
    } = options;
    const tagIds = Array.isArray(tags) ? tags : [tags];
    const filter = tagIds.map(tagIdWithMaybeOption => {
      let tagId = tagIdWithMaybeOption;
      let tagOptionId;
      if (tagIdWithMaybeOption.indexOf(tagOptionUrlSeparator) > -1) {
        [tagId, tagOptionId] = tagIdWithMaybeOption.split(
          tagOptionUrlSeparator
        );
      }
      const id = tagOptionId || tagId;
      const filterKey = `${tagOptionId ? "tagOptionId" : "tagId"}`;
      if (not) {
        // this will filter for items which do not have this tag
        const pathToTaggedItemsNoS = trimEnd(pathToTaggedItems, "s");
        return {
          id: qb.notRelated(`${pathToTaggedItemsNoS}.${model}Id`).whereAll({
            [filterKey]: id
          })
        };
      } else {
        return {
          [`${pathToTaggedItems}.${filterKey}`]: not ? qb.notInList([id]) : id
        };
      }
    });
    if (not) {
      qb.whereAll(...filter);
    } else if (atLeastOneOf) {
      qb.whereAny(...filter);
    } else {
      qb.whereAll(...filter);
    }
  }
}

export function getTagKey({ tagId, tagOptionId }) {
  return tagOptionId ? `${tagId}${tagOptionUrlSeparator}${tagOptionId}` : tagId;
}

function splitTagUrlKey(tagKey) {
  const [tagId, tagOptionId] = tagKey.split(tagOptionUrlSeparator);
  return { tagId, tagOptionId };
}

/**
 *
 * @param {{tags: string[] | string}} currentParams
 * @returns {{[tagId: string]: boolean}}
 */
export function generateTagMapFromQueryParams(currentParams) {
  if (!currentParams.tags) return {};

  const tagIds = Array.isArray(currentParams.tags)
    ? currentParams.tags
    : [currentParams.tags];
  if (!tagIds) return {};
  return tagIds.reduce((acc, tagIdWithMaybeOption) => {
    const { tagId, tagOptionId } = splitTagUrlKey(tagIdWithMaybeOption);
    if (tagOptionId) {
      acc[`${tagId}:${tagOptionId}`] = true;
    } else {
      acc[tagId] = true;
    }
    return acc;
  }, {});
}

export function addTagToUrl({
  tagId,
  tagOptionId,
  currentParams,
  setNewParams
}) {
  const tagKey = getTagKey({ tagId, tagOptionId });
  let tags = currentParams.tags || [];
  if (!Array.isArray(tags)) tags = [tags];
  tags = [...tags];
  if (!tags.includes(tagKey)) {
    tags.push(tagKey);
  }
  setNewParams({
    ...currentParams,
    page: undefined,
    tags
  });
}

export function removeTagFromUrl({
  tagId,
  tagOptionId,
  currentParams,
  setNewParams
}) {
  let tags = currentParams.tags || [];
  if (!Array.isArray(tags)) tags = [tags];
  tags = [...tags];
  const tagKey = getTagKey({ tagId, tagOptionId });
  tags = pull(tags, tagKey);
  delete currentParams.page;
  setNewParams({
    ...currentParams,
    page: undefined,
    tags
  });
}

export function getTagsUrlParamsForTagSelect(tags = []) {
  const tagParams = [];

  tags.forEach(tag => {
    const [tagId, tagOptionId] = tag.id.split(":");
    const tagKey = getTagKey({ tagId, tagOptionId });
    tagParams.push(tagKey);
  });
  return tagParams;
}
