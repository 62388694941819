/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { camelCase, get, flatMap } from "lodash";
import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";
import { Spinner, Tooltip, Icon } from "@blueprintjs/core";
import { openInNewTab } from "../../../../src-shared/utils/generalUtils";
import { createNotification } from "../../../../src-shared/utils/createNotification";
import appGlobals from "../../../../src-shared/appGlobals";
import { safeUpsert, safeQuery } from "../../../../src-shared/apolloMethods";
import { workflowToolStatuses } from "../../../../../tg-iso-lims/src/utils/workflowUtils";
import {
  dataItemTypeCodeToModel,
  inventoryItemTypeCodeToModel
} from "../../../../../tg-iso-shared/src/utils/inventoryUtils";
import { keyedToolSchemas } from "../../LimsTools/toolSchemas";
import { ValueOf } from "../../../../src-shared/typescriptHelpers";
import { IoItem } from "../types/IoItem";
import { Task } from "../types/SelectedTask";
import { IoList, isIoListItem } from "../types/IoList";
import { WorkflowRun } from "../types/WorkflowRun";
import { workflowRunNotifyJobUsersFragment } from "./workflowRunNotifyJobUsersFragment.gql";
import { CurrentUser } from "../../../../src-shared/context/CurrentUserContext";
import type { WorkflowRunNotifyJobUsersFragment } from "./workflowRunNotifyJobUsersFragment.gql.generated";
const workflowStatuses = {
  notStarted: "NOT_STARTED",
  inProgress: "IN_PROGRESS",
  completed: "COMPLETED"
};

type ToolCode = keyof typeof keyedToolSchemas;

export function getToolSchemaInputItem(toolCode: ToolCode, itemCode: string) {
  const currentToolSchema = keyedToolSchemas[toolCode];
  let toRet;
  if (currentToolSchema) {
    toRet = currentToolSchema.input?.ioItems?.[itemCode];
  }
  return toRet || {};
}

export function getToolSchemaOutputItem(toolCode: ToolCode, itemCode: string) {
  const currentToolSchema = keyedToolSchemas[toolCode];
  let toRet;
  if (currentToolSchema) {
    toRet = currentToolSchema.output?.ioItems?.[itemCode];
  }
  return toRet || {};
}

export const isToolReady = (workflowTool: {
  workflowToolStatusCode: ValueOf<typeof workflowToolStatuses>;
}) => workflowTool?.workflowToolStatusCode === workflowToolStatuses.ready;

export const isToolSkipped = (
  workflowTool: { workflowToolStatusCode?: string } | false | undefined
) =>
  workflowTool &&
  workflowTool.workflowToolStatusCode === workflowToolStatuses.skipped;

export const isToolBlocked = (workflowTool: {
  workflowToolStatusCode?: string;
}) => workflowTool?.workflowToolStatusCode === workflowToolStatuses.blocked;

export const isToolCompleted = (workflowTool: {
  workflowToolStatusCode?: string;
}) => workflowTool?.workflowToolStatusCode === workflowToolStatuses.completed;

export const isToolCompletedOrSkipped = (workflowTool: {
  workflowToolStatusCode?: string;
}) => isToolCompleted(workflowTool) || isToolSkipped(workflowTool);

export function getUpstreamOutputsForTool(tool: Task) {
  const { workflowToolInputs = [] } = tool;
  const upstreamOutputs: (IoItem | IoList)[] = [];
  workflowToolInputs.forEach(input => {
    if (input.workflowToolInputDefinition.wtInputDefToOutputDefs.length) {
      input.workflowToolInputIoItems.forEach(({ ioItem }) => {
        const inputTool = ioItem?.workflowToolOutput?.workflowTool;
        if (inputTool) {
          upstreamOutputs.push(ioItem);
        }
      });
    }
  });
  return upstreamOutputs;
}

export function getUpstreamToolsForTool(tool: Task) {
  const { workflowToolInputs = [] } = tool;
  const upstreamTools: {
    workflowToolStatusCode?: string;
  }[] = [];
  workflowToolInputs.forEach(input => {
    if (input.workflowToolInputDefinition.wtInputDefToOutputDefs.length) {
      input.workflowToolInputIoItems.forEach(({ ioItem }) => {
        const inputTool = ioItem?.workflowToolOutput?.workflowTool;
        if (inputTool) {
          upstreamTools.push(inputTool);
        }
      });
    }
  });
  return upstreamTools;
}

function areUpstreamToolsCompletedOrSkipped(tool: Task) {
  const upstreamTools = getUpstreamToolsForTool(tool);
  return !upstreamTools.length || upstreamTools.every(isToolCompletedOrSkipped);
}

export async function updatedToolStatuses(workflowRun: WorkflowRun) {
  const updates: {
    id: string;
    workflowToolStatusCode: ValueOf<typeof workflowToolStatuses>;
  }[] = [];
  const unblockedTools: Task[] = [];
  workflowRun.workflowTools.forEach(tool => {
    if (isToolBlocked(tool) && areUpstreamToolsCompletedOrSkipped(tool)) {
      updates.push({
        id: tool.id,
        workflowToolStatusCode: workflowToolStatuses.ready
      });
      unblockedTools.push(tool);
    } else if (!areUpstreamToolsCompletedOrSkipped(tool) && isToolReady(tool)) {
      updates.push({
        id: tool.id,
        workflowToolStatusCode: workflowToolStatuses.blocked
      });
    }
  });
  await safeUpsert(["workflowTool", "id workflowToolStatusCode"], updates);
  try {
    if (unblockedTools.length) {
      const unblockedNotifications: ReturnType<
        typeof workQueueItemReadyNotification
      >[] = [];
      unblockedTools.forEach(tool => {
        const activeWorkQueueItem = tool.workQueueItems.find(
          wqi => wqi.reassigned === false
        );
        if (activeWorkQueueItem) {
          unblockedNotifications.push(
            workQueueItemReadyNotification(
              activeWorkQueueItem.jobUser.user.id,
              { ...tool, workflowRun }
            )
          );
        }
      });
      await createNotification(unblockedNotifications);
    }
  } catch (error) {
    console.error("error sending unblocked notifications:", error);
  }
}

export const getAllItemIdsFromIoItems = (ioItemOrIoItems: IoItem | IoList) => {
  const ioItems = Array.isArray(ioItemOrIoItems)
    ? ioItemOrIoItems
    : [ioItemOrIoItems];
  return flatMap(ioItems, ioItem => getItemIdOrIdsFromIoItem(ioItem)).filter(
    id => id
  );
};

export const generateLink = (
  ioItemOrIoItems: IoItem | IoList,
  label: string = "Untitled Item",
  options: {
    loading?: boolean;
    error?: boolean;
    tooltip?: any;
    success?: boolean;
    returnUrlObject?: boolean;
  } = {}
) => {
  const { loading, error, tooltip, success, returnUrlObject } = options;
  const itemIds = getAllItemIdsFromIoItems(ioItemOrIoItems);
  const ioItems = Array.isArray(ioItemOrIoItems)
    ? ioItemOrIoItems
    : [ioItemOrIoItems];

  let linkComp;
  if (itemIds.length > 0) {
    let link;
    const modelName = camelCase(getTypeCodeFromIoItem(ioItems[0]));
    let linkToCheckifLinkable = modelNameToLink(modelName);
    if (itemIds.length > 1) {
      // TODO: cant do this because then a circular dependency problem occurs
      // if (!privateRoutes[linkToCheckifLinkable] || !itemIdOrIds) return label;
      link =
        linkToCheckifLinkable + "?filters=id__inList__" + itemIds.join(";");
    } else {
      //just a single item
      linkToCheckifLinkable += "/:id";
      // TODO: cant do this because then a circular dependency problem occures
      // if (!privateRoutes[linkToCheckifLinkable] || !itemIdOrIds) return label;
      link = linkToCheckifLinkable.replace(":id", itemIds[0]!);
    }
    if (returnUrlObject) {
      return { link, model: modelName, recordIds: itemIds };
    }
    linkComp = (
      <a
        onClick={() =>
          (window.Cypress ? appGlobals.history?.push! : openInNewTab)(link)
        }
      >
        {label}
      </a>
    );
  } else {
    linkComp = label;
  }

  // if we only want the url and we are at this point then there is no valid url
  if (returnUrlObject) {
    return null;
  }

  let icon;
  if (loading) {
    icon = <Spinner size={15} />;
  } else if (!loading && error) {
    icon = <Icon intent="danger" icon="error" />;
  } else if (!loading && success) {
    icon = <Icon intent="success" icon="tick-circle" />;
  }
  if (icon) {
    linkComp = (
      <div className="tg-flex align-center" style={{ marginBottom: 5 }}>
        <div style={{ marginRight: 8 }}>{icon}</div>
        {linkComp}
      </div>
    );
  }
  if (tooltip) {
    linkComp = (
      // @ts-ignore
      <Tooltip content={tooltip} disabled={!tooltip}>
        {linkComp}
      </Tooltip>
    );
  }
  return linkComp;
};

export const getItemIdOrIdsFromIoItem = (
  ioItem: IoItem | IoList
): string | string[] | undefined => {
  if (ioItem) {
    if (isIoListItem(ioItem)) {
      //return either undefined (if no ids are found), an id if just 1 id is found, or a list of ids if multiple are found
      const toReturn = flatMap(ioItem.ioListItems, getItemIdOrIdsFromIoItem) as
        | string
        | string[];
      return toReturn.length === 0
        ? undefined
        : toReturn.length === 1
          ? toReturn[0]
          : toReturn;
    }
    if (ioItem && (ioItem.dataItemId || ioItem.inventoryItemId)) {
      const typeCode = getTypeCodeFromIoItem(ioItem)!;
      const dataItemKey =
        dataItemTypeCodeToModel[
          typeCode as keyof typeof dataItemTypeCodeToModel
        ] + "Id";
      const inventoryItemId =
        inventoryItemTypeCodeToModel[
          typeCode as keyof typeof inventoryItemTypeCodeToModel
        ] + "Id";
      const itemId =
        ioItem.dataItem?.[dataItemKey] ||
        ioItem.inventoryItem?.[inventoryItemId];
      return itemId;
    }
  }
  return;
};

export const getTypeCodeFromIoItem = (ioItem: IoItem) => {
  if ((ioItem && ioItem.dataItemId) || ioItem.inventoryItemId) {
    const typeCode =
      ioItem?.dataItem?.dataItemTypeCode ||
      ioItem?.inventoryItem?.inventoryItemTypeCode;
    return typeCode;
  }
  return;
};

export const getWorkflowProgress = (
  workflow: WorkflowRun | null,
  maybeTools?: Task[]
) => {
  let numCompletedTools = 0;
  const tools = (maybeTools || workflow?.workflowTools)!;
  tools.forEach(tool => {
    if (isToolCompleted(tool) || isToolSkipped(tool)) {
      numCompletedTools++;
    }
  });
  return numCompletedTools / tools.length;
};

/**
 * when a workflow is started or completed notify all job users about the status change
 */
async function notifyWorkflowStatusChange(
  workflow: WorkflowRun,
  newStatusCode: ValueOf<typeof workflowStatuses>
) {
  try {
    const oldStatus = get(workflow, "workflowRunStatusType.code");
    const workflowRunWasStarted =
      oldStatus === workflowStatuses.notStarted &&
      newStatusCode === workflowStatuses.inProgress;
    const workflowRunWasCompleted =
      newStatusCode === workflowStatuses.completed;
    if (workflowRunWasStarted || workflowRunWasCompleted) {
      const workflowWithJobUsers = await safeQuery<
        WorkflowRunNotifyJobUsersFragment,
        false
      >(workflowRunNotifyJobUsersFragment, {
        variables: {
          id: workflow.id
        }
      });
      if (
        workflowWithJobUsers.job &&
        workflowWithJobUsers.job.jobUsers?.length
      ) {
        const newNotifications: {
          userId: string;
          message: string;
          notificationIntent: "primary";
          link: string;
          notificationTypeCode: string;
        }[] = [];
        workflowWithJobUsers.job.jobUsers.forEach(jobUser => {
          newNotifications.push({
            userId: jobUser.userId!,
            message: `The workflow run ${workflow.name} was ${
              workflowRunWasStarted ? "started" : "completed"
            }.`,
            notificationIntent: "primary",
            link: `/workflow-runs/${workflow.id}`,
            notificationTypeCode: workflowRunWasStarted
              ? "WORKFLOW_RUN_STARTED"
              : "WORKFLOW_RUN_FINISHED"
          });
        });
        await createNotification(newNotifications);
      }
    }
  } catch (error) {
    console.error("error:", error);
  }
}

export const finalizeStatusUpdate = async (
  workflow: WorkflowRun,
  newStatusCode: ValueOf<typeof workflowStatuses>
) => {
  try {
    await safeUpsert(
      [
        "workflowRun",
        `
          id
          workflowRunStatusTypeCode
          workflowRunStatusType {
            code
            name
            description
          }`
      ],
      {
        id: workflow.id,
        workflowRunStatusTypeCode: newStatusCode
      }
    );
    await notifyWorkflowStatusChange(workflow, newStatusCode);
  } catch (error) {
    console.error("error:", error);
  }
};

/**
 * This will update the workflow status when a tool has been updated
 */
export const updateWorkflowStatus = async (
  toolUpdatedId: string | string[],
  statusOfTool: 0 | 1,
  workflow: WorkflowRun
) => {
  const { workflowTools } = workflow;
  const toolUpdatedIds = Array.isArray(toolUpdatedId)
    ? toolUpdatedId
    : [toolUpdatedId];
  const toolsNotUpdated = workflowTools.filter(
    tool => !toolUpdatedIds.includes(tool.id)
  );
  let progressOfOtherTools;
  if (toolsNotUpdated.length) {
    progressOfOtherTools = getWorkflowProgress(null, toolsNotUpdated);
  }

  let newStatusCode: ValueOf<typeof workflowStatuses>;
  // if all tools are finished (or just a single tool that is finished)
  if (
    statusOfTool === 1 &&
    (progressOfOtherTools === undefined || progressOfOtherTools === 1)
  ) {
    newStatusCode = workflowStatuses.completed;
  } else if (statusOfTool === 0 && !progressOfOtherTools) {
    newStatusCode = workflowStatuses.notStarted;
  } else {
    newStatusCode = workflowStatuses.inProgress;
  }
  await finalizeStatusUpdate(workflow, newStatusCode);
};

/**
 * This will calculate the initial status of the workflow incase items
 * have been removed since the last time the workflow was opened
 * @param {Object} workflow - the workflow to add a status to
 */
export const calculateInitialStatus = async (workflow: WorkflowRun) => {
  const progress = getWorkflowProgress(workflow);
  let newStatusCode: ValueOf<typeof workflowStatuses>;
  if (progress === 1) {
    newStatusCode = workflowStatuses.completed;
  } else if (progress === 0) {
    newStatusCode = workflowStatuses.notStarted;
  } else {
    newStatusCode = workflowStatuses.inProgress;
  }
  if (workflow?.workflowRunStatusType?.code !== newStatusCode) {
    await finalizeStatusUpdate(workflow, newStatusCode);
  }
};

export const getIdFromIoItem = (ioItem: IoItem) => {
  const { dataItem, inventoryItem } = ioItem;
  if (dataItem) {
    const { dataItemTypeCode } = dataItem;
    const model =
      dataItemTypeCodeToModel[
        dataItemTypeCode as keyof typeof dataItemTypeCodeToModel
      ];
    return dataItem[model + "Id"];
  } else if (inventoryItem) {
    const { inventoryItemTypeCode } = inventoryItem;
    const model =
      inventoryItemTypeCodeToModel[
        inventoryItemTypeCode as keyof typeof inventoryItemTypeCodeToModel
      ];
    return inventoryItem[model + "Id"];
  } else {
    /* eslint-disable no-debugger*/
    debugger; //we shouldn't be here
    /* eslint-enable no-debugger*/
  }
  return;
};

export const getTaskAssignee = (workflowTool: {
  workQueueItems: { jobUser: { user: CurrentUser } }[];
}) => workflowTool?.workQueueItems?.[0]?.jobUser?.user;

export const workQueueItemAssignedNotification = (
  userId: string,
  tool: {
    workflowRun: { id: string; name: string };
    workflowToolDefinition: { index: number; name: string };
  }
) => ({
  userId,
  message: `You have been assigned to the task ${tool.workflowToolDefinition.name} on the workflow run ${tool.workflowRun.name}`,
  notificationIntent: "primary",
  notificationTypeCode: "ASSIGNED_WORKQUEUE_ITEM",
  link: `/workflow-runs/${tool.workflowRun.id}#task=${
    tool.workflowToolDefinition.index + 1
  }`
});

export const workQueueItemReadyNotification = (
  userId: string,
  tool: {
    workflowRun: { id: string; name: string };
    workflowToolDefinition: { index: number; name: string };
  }
) => ({
  userId,
  message: `The task ${tool.workflowToolDefinition.name} on the workflow run ${tool.workflowRun.name} is ready to be started`,
  notificationIntent: "primary",
  notificationTypeCode: "WORKQUEUE_ITEM_READY",
  link: `/workflow-runs/${tool.workflowRun.id}#task=${
    tool.workflowToolDefinition.index + 1
  }`
});

export async function setToolStartDate(toolId: string) {
  try {
    await safeUpsert(["workflowTool", "id startDate"], {
      id: toolId,
      startDate: new Date()
    });
  } catch (error) {
    console.error(`error:`, error);
  }
}

export async function setToolEndDate(toolId: string) {
  try {
    await safeUpsert(["workflowTool", "id endDate"], {
      id: toolId,
      endDate: new Date()
    });
  } catch (error) {
    console.error(`error:`, error);
  }
}

export function getNamedOutputs(
  input: {
    workflowToolInputIoItems: {
      ioItem: {
        workflowToolOutput: { workflowToolOutputDefinitionId: string };
      };
    }[];
    workflowToolInputDefinition: {
      wtInputDefToOutputDefs: {
        workflowToolOutputDefinition: {
          id: string;
          label: string;
          workflowToolDefinition: { name: string; index: number };
        };
      }[];
    };
  },
  options: { noLabel?: boolean } = {}
) {
  const { noLabel } = options;
  const skippedMap: { [id: string]: boolean | undefined } = {};
  input.workflowToolInputIoItems.forEach(({ ioItem }) => {
    const skipped = isToolSkipped(
      get(ioItem, "workflowToolOutput.workflowTool")
    );
    const defId = ioItem?.workflowToolOutput?.workflowToolOutputDefinitionId;
    if (defId) {
      skippedMap[defId] = skipped;
    }
  });
  return input.workflowToolInputDefinition.wtInputDefToOutputDefs.map(
    ({
      workflowToolOutputDefinition: {
        id,
        label,
        workflowToolDefinition: { name, index }
      }
    }) => ({
      label:
        (noLabel ? "" : label) +
        ` (${index + 1}. ${name}${skippedMap[id] ? " - skipped" : ""})`,
      workflowToolOutputDefinitionId: id
    })
  );
}
