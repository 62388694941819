/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { get } from "lodash";
import {
  DataTable,
  DialogFooter,
  InputField,
  TextareaField,
  withTableParams,
  wrapDialog
} from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";
import { reduxForm, formValueSelector, change } from "redux-form";
import { tagColumnWithRender } from "../../../../src-shared/utils/tagColumn";
import "./style.css";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { addTagFilterToQuery } from "../../../../src-shared/utils/tagUtils";
import gql from "graphql-tag";
import { safeDelete } from "../../../../src-shared/apolloMethods";
import { libraryExtendedStringValues } from "../../../../src-shared/libraryEnhancer";
import withLibraryExtendedPropertyColumns from "../../../../src-shared/enhancers/withLibraryExtendedPropertyColumns";
import { annotationSizeStartEndColumns } from "../../../../src-shared/utils/libraryColumns";
import { combineGqlFragments } from "../../../../../tg-iso-shared/utils/gqlUtils";

const schema = {
  model: "part",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    tagColumnWithRender,
    ...annotationSizeStartEndColumns,
    {
      path: "sequence.name",
      type: "string",
      displayName: "Source"
    }
  ]
};

let fragment = gql`
  fragment updatePartSetPartFragment on part {
    id
    name
    taggedItems {
      id
      tag {
        id
        name
        color
      }
      tagOption {
        id
        name
        color
      }
    }
    start
    end
    sequence {
      id
      size
      name
    }
  }
`;

fragment = combineGqlFragments([fragment, libraryExtendedStringValues]);

class UpdatePartSetDialog extends React.Component {
  /**
   * See if we are editing an existing group or creating a new one.
   */
  isEditing() {
    return !!this.props.initialValues;
  }

  onSubmit = async values => {
    const {
      selectedEntities: parts,
      refetchPartsets,
      initialValues,
      partSetId,
      hideModal
    } = this.props;
    try {
      if (partSetId) {
        await safeUpsert("partset", {
          id: partSetId,
          name: values.name,
          description: values.description,
          numParts: parts.length
        });
        await safeDelete(
          "partsetPart",
          initialValues.partsetParts.map(partsetPart => {
            return partsetPart.id;
          })
        );
        await safeUpsert(
          "partsetPart",
          parts.map(part => {
            return { partsetId: partSetId, partId: get(part, "id") };
          })
        );
        await refetchPartsets();
        hideModal();
        window.toastr.success("Part set updated");
      } else {
        await safeUpsert("partset", {
          name: values.name,
          description: values.description,
          partsetParts: parts.map(part => {
            return { partId: get(part, "id") };
          }),
          numParts: parts.length
        });
        await refetchPartsets();
        window.toastr.success("Part set created");
        hideModal();
      }
    } catch (e) {
      console.error(e);
      window.toastr.error(
        `Error ${partSetId ? "updating" : "creating"} DNA part set.`
      );
    }
  };

  getParts() {
    const { initialValues } = this.props;
    if (get(initialValues, "partsetParts")) {
      const partList = {};
      initialValues.partsetParts.forEach(part => {
        if (get(part, "part.id")) {
          return (partList[get(part, "part.id")] = {
            entity: {
              id: part.part.id,
              name: part.part.name,
              start: part.part.start,
              sequenceId: part.id
            }
          });
        }
      });
      return partList;
    }
  }

  render() {
    const { tableParams, submitting, handleSubmit, hideModal } = this.props;
    const data = { reduxFormSelectedEntityIdMap: this.getParts() };
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className="bp3-dialog-body">
          <InputField name="name" label="Name" />
          <TextareaField name="description" label="Description" />
          <DataTable
            {...tableParams}
            className="no-padding"
            withCheckboxes
            compact
            maxHeight={290}
            showCount
            initialValues={data}
            doNotShowEmptyRows
          >
            Parts
          </DataTable>
        </div>
        <DialogFooter
          text="Save"
          submitting={submitting}
          hideModal={hideModal}
        />
      </form>
    );
  }
}

const selector = formValueSelector("UpdatePartSetTable"); // <-- same as form name
UpdatePartSetDialog = connect(state => {
  const { tableData, reduxFormSelectedEntityIdMap } = selector(
    state,
    "reduxFormSelectedEntityIdMap",
    "UpdatePartSetTable"
  );
  return {
    tableData,
    reduxFormSelectedEntityIdMap
  };
})(UpdatePartSetDialog);

const validate = values => {
  const errors = {};
  if (!values.name) errors.name = "Required";
  return errors;
};

export default compose(
  wrapDialog(props => ({
    title: props.initialValues ? "Edit Part Set" : "Create Part Set",
    className: "update-group-dialog",
    style: { width: 750 }
  })),
  withLibraryExtendedPropertyColumns({
    schema,
    model: "part",
    showLoading: true,
    inDialog: true
  }),
  withTableParams({
    urlConnected: false,
    schema,
    formName: "UpdatePartSetTable",
    withSelectedEntities: true,
    tableParams: {
      cellRenderer: {
        start: index => index + 1,
        end: index => index + 1
      }
    },
    additionalFilter: (_, qb, currentParams) => {
      addTagFilterToQuery(currentParams.tags, qb);
      qb.whereAll({
        "sequence.isInLibrary": true,
        "sequence.sequenceTypeCode": qb.notEquals("RNA")
      });
    }
  }),
  withQuery(fragment, {
    isPlural: true
  }),
  reduxForm({ form: "UpdatePartsetDialog", validate }),
  connect(null, dispatch => {
    return {
      changeFieldValue: (...args) => dispatch(change(...args))
    };
  })
)(UpdatePartSetDialog);
