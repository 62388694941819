/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback } from "react";
import { MenuItem } from "@blueprintjs/core";
import AbstractLibrary from "../../../../src-shared/AbstractLibrary";
import libraryEnhancer from "../../../../src-shared/libraryEnhancer";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import gql from "graphql-tag";
import {
  speciesColumn,
  genusAndSpeciesColumn,
  targetOrganismGroupColumn
} from "../../../../src-shared/utils/libraryColumns";
import { handleStrainsExport } from "./utils";
import StrainRecordView from "../../Record/StrainRecordView";
import CreateNewStrainDialog from "../../Dialogs/CreateNewStrainDialog";
import UploadStrainsDialog from "../../Dialogs/UploadStrainsDialog";

const additionalContextMenu = selectedRecords => {
  const strainIds = selectedRecords.map(s => s.id);
  const exportStrainMenuItem = (
    <MenuItem
      key="export"
      icon="export"
      text="Export"
      onClick={() => handleStrainsExport(strainIds)}
    />
  );
  const menuItems = [];
  menuItems.push(exportStrainMenuItem);
  return menuItems;
};

const strainTypeCode = "MICROBIAL_STRAIN";

const schema = {
  model: "strain",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    speciesColumn,
    genusAndSpeciesColumn,
    { path: "genotype", type: "string", displayName: "Genotype" },
    {
      path: "biosafetyLevel.name",
      type: "string",
      displayName: "BioSafety Level"
    },
    targetOrganismGroupColumn,
    {
      displayName: "Induction Methods",
      render: (v, r) =>
        r.inductionMethodStrains.map(sim => sim.inductionMethod.name).join(", ")
    }
  ]
};

const StrainLibrary = props => {
  const { history, refetchStrains } = props;
  const onNewItemClick = useCallback(() => {
    showDialog({
      ModalComponent: CreateNewStrainDialog,
      modalProps: {
        history,
        strainTypeCode
      }
    });
  }, [history]);

  const onDoubleClick = useCallback(
    record => {
      history.push(`/microbial-strains/${record.id}`);
    },
    [history]
  );

  const showUploadFunction = useCallback(() => {
    showDialog({
      ModalComponent: UploadStrainsDialog,
      modalProps: {
        refetch: refetchStrains
      }
    });
  }, [refetchStrains]);

  return (
    <AbstractLibrary
      {...props}
      RecordViewInspector={StrainRecordView}
      onDoubleClick={onDoubleClick}
      showUploadFunction={showUploadFunction}
      isLibraryTable
      libraryTitle="Microbial Strains"
      withExport
      onNewItemClick={onNewItemClick}
      generateExtraContextMenuItems={additionalContextMenu}
    />
  );
};

const fragment = gql`
  fragment strainLibraryFragment on strain {
    id
    name
    strainTypeCode
    specie {
      id
      abbreviatedName
      name
      genus {
        id
        name
      }
    }
    genotype
    biosafetyLevel {
      code
      name
    }
    inductionMethodStrains {
      id
      inductionMethod {
        id
        name
      }
    }
    targetOrganismClass {
      id
      name
    }
  }
`;

export default libraryEnhancer({
  schema,
  fragment,
  additionalFilter: (_, qb) => {
    qb.andWhereAll({
      strainTypeCode
    });
  }
})(StrainLibrary);
