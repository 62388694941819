/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { InjectedFormProps, reduxForm } from "redux-form";
import { Callout, Classes, Icon } from "@blueprintjs/core";
import { DataTable, DialogFooter, wrapDialog } from "@teselagen/ui";
import type { InjectedWrapDialogProps } from "@teselagen/ui";
import FillDirectionSelect from "../../../../FillDirectionSelect";
import withQuery from "../../../../../../src-shared/withQuery";
import type { InjectedWithQueryProps } from "../../../../../../src-shared/withQuery/types";
import { arrayMove } from "@dnd-kit/sortable";
import { addActiveProjectFilter } from "../../../../../../src-shared/utils/projectUtils";
import queryBuilder from "tg-client-query-builder";
import { keyBy } from "lodash";
import SortableList from "./../../../../../../src-shared/SortableList";
import {
  distByAssemblyReportMaterialFragment,
  distByAssemblyReportSampleFragment
} from "./utils";
import { DistByAssemblyReportSampleFragment as _DistByAssemblyReportSampleFragment } from "./utils/distByAssemblyReportSampleFragment.gql.generated";
import { DistByAssemblyReportMaterialFragment as _DistByAssemblyReportMaterialFragment } from "./utils/distByAssemblyReportMaterialFragment.gql.generated";
import { RecursiveRequired } from "../../../../../../src-shared/typescriptHelpers";
import { Directions } from "../../../../../utils/plateUtils/getWellPositionsGivenWellCountAndDirection";
import { Items } from "../../utils/types/Item";

const SortableItem = ({ item: report }: { item: { name: string } }) => (
  <div
    className="tg-flex align-center justify-space-between"
    style={{ marginBottom: 5, width: "100%" }}
  >
    <div
      className="tg-flex align-center "
      style={{
        overflow: "hidden",
        padding: "10px",
        width: "100%",
        margin: 1,
        borderRadius: "10px",
        height: "30px",
        maxHeight: "30px",
        display: "flex",
        alignItems: "center"
      }}
    >
      <Icon icon="drag-handle-vertical" />
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          marginLeft: 4,
          userSelect: "none"
        }}
      >
        {report.name}
      </div>
    </div>
  </div>
);

type Props = {
  items: Items;
  selectedJ5Reports?: { id: string }[];
  handleDistribute: (values: {
    fillDirection: Directions;
    reportOrder: string[];
    itemIdToReports: { [key: string]: { id: string; name: string }[] };
  }) => Promise<void>;
};

type FormData = {
  fillDirection: Directions;
};

type DistByAssemblyReportSampleFragment =
  RecursiveRequired<_DistByAssemblyReportSampleFragment>;

type DistByAssemblyReportMaterialFragment =
  RecursiveRequired<_DistByAssemblyReportMaterialFragment>;

type AllProps = InjectedWrapDialogProps<Props> &
  InjectedFormProps<FormData, Props> &
  InjectedWithQueryProps<
    Props,
    DistByAssemblyReportSampleFragment,
    {},
    true,
    "samples"
  > &
  InjectedWithQueryProps<
    Props,
    DistByAssemblyReportMaterialFragment,
    {},
    true,
    "materials"
  > &
  InjectedWithQueryProps<
    Props,
    { __typename: "j5Report"; id: string; updatedAt: string },
    {},
    true,
    "j5Reports"
  > &
  Props;

type Report = {
  id: string;
  name: string;
};

function DistributeByAssemblyReportDialog({
  hideModal,
  handleSubmit,
  submitting,
  handleDistribute,
  items,
  samples,
  materials,
  selectedJ5Reports,
  j5Reports
}: AllProps) {
  const [reports, setReports] = useState<Report[]>([]);
  const [itemIdToReports, setItemIdToReports] = useState<{
    [id: string]: Report[];
  }>({});
  useEffect(() => {
    let selectedReportIds: string[] | undefined;
    if (selectedJ5Reports) {
      selectedReportIds = selectedJ5Reports.map(report => report.id);
    }
    const keyedJ5ReportsInProject = keyBy(j5Reports || [], "id");
    const ids: string[] = [];
    const allReports: Report[] = [];
    const newItemIdsToReports: { [id: string]: Report[] } = {};
    const getReportsFromViews = (
      item:
        | DistByAssemblyReportSampleFragment
        | DistByAssemblyReportMaterialFragment
    ) => {
      const reportsForItem: Report[] = [];
      const reportIds: string[] = [];
      const { polynucleotideMaterialSequence } =
        "material" in item ? item.material : item;
      if (polynucleotideMaterialSequence) {
        const { sequenceJ5ItemViews } = polynucleotideMaterialSequence;
        if (!sequenceJ5ItemViews) return;
        sequenceJ5ItemViews.forEach(item => {
          if (selectedReportIds) {
            if (!selectedReportIds.includes(item.j5ReportId as string)) return;
          } else {
            if (!keyedJ5ReportsInProject[item.j5ReportId as string]) return;
          }

          const report = {
            id: item.j5ReportId,
            name: item.j5ReportName
          };
          if (!reportIds.includes(report.id)) {
            reportIds.push(report.id);
            reportsForItem.push(report);
          }
          if (!ids.includes(report.id)) {
            ids.push(report.id);
            allReports.push(report);
          }
        });
      }
      newItemIdsToReports[item.id] = reportsForItem;
    };
    (materials || samples || []).forEach(getReportsFromViews);
    setReports(
      allReports.sort((a, b) =>
        keyedJ5ReportsInProject[a.id]?.updatedAt <
        keyedJ5ReportsInProject[b.id]?.updatedAt
          ? 1
          : -1
      )
    );
    setItemIdToReports(newItemIdsToReports);
  }, [samples, materials, selectedJ5Reports, j5Reports]);

  async function onSubmit(values: FormData) {
    try {
      await handleDistribute({
        ...values,
        reportOrder: reports.map(report => report.id),
        itemIdToReports
      });
      hideModal();
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error(error.message || "Error Distributing");
    }
  }
  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <Callout intent="warning" style={{ marginBottom: 15 }}>
          This will clear current placement and replace all items.
        </Callout>
        <Callout intent="primary" style={{ marginBottom: 15 }}>
          {`You can prioritize assembly reports by reordering them
          on the right side. If a material or sample belongs to multiple
          reports, they will only be distributed to the
          report with the higher priority.`}
        </Callout>
        <div style={{ maxWidth: 250, marginBottom: 10 }}>
          <FillDirectionSelect />
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 2 }}>
            <DataTable
              isSimple
              entities={items}
              itemIdToReports={itemIdToReports}
              schema={[
                "name",
                {
                  displayName: "Assembly Reports",
                  render: (v: any, item: { id: string }) =>
                    (itemIdToReports[item.id] || [])
                      .map(report => report.name)
                      .join(", ")
                }
              ]}
              formName="items"
            />
          </div>
          <div style={{ flex: 1, overflow: "hidden" }}>
            <h6 style={{ textAlign: "center", marginBottom: 10 }}>
              Report Priority
            </h6>
            <SortableList
              items={reports}
              onDragEnd={({ oldIndex, newIndex }) =>
                setReports(arrayMove(reports, oldIndex, newIndex))
              }
              DisplayComponent={SortableItem}
            />
          </div>
        </div>
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        text="Distribute"
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
}

const getJ5ReportIds = (
  items: (
    | DistByAssemblyReportSampleFragment
    | DistByAssemblyReportMaterialFragment
  )[] = []
) => {
  const ids: string[] = [];
  items.forEach(item => {
    const { polynucleotideMaterialSequence } =
      "material" in item ? item.material : item;
    if (polynucleotideMaterialSequence) {
      const { sequenceJ5ItemViews } = polynucleotideMaterialSequence;
      if (!sequenceJ5ItemViews) return;
      sequenceJ5ItemViews.forEach(item => {
        if (item.j5ReportId && !ids.includes(item.j5ReportId)) {
          ids.push(item.j5ReportId);
        }
      });
    }
  });
  return ids;
};

export default compose<AllProps, Props>(
  wrapDialog({
    title: "Distribute by Assembly Report",
    style: {
      width: 950
    }
  }),
  reduxForm({
    form: "distByAssemblyReport"
  }),
  withQuery(distByAssemblyReportSampleFragment, {
    showLoading: true,
    inDialog: true,
    isPlural: true,
    skip: props => props.items[0].__typename !== "sample",
    options: ({ items }: { items: { id: string }[] }) => {
      return {
        variables: {
          pageSize: items.length,
          filter: {
            id: items.map(item => item.id)
          }
        }
      };
    }
  }),
  withQuery(distByAssemblyReportMaterialFragment, {
    showLoading: true,
    inDialog: true,
    isPlural: true,
    skip: ({ items }) => items[0].__typename !== "material",
    options: ({ items }: Props) => {
      return {
        variables: {
          pageSize: items.length,
          filter: {
            id: items.map(item => item.id)
          }
        }
      };
    }
  }),
  // this will be used to see check if the j5 report is in the active project which will be used as a filter
  withQuery(["j5Report", "id updatedAt"], {
    showLoading: true,
    inDialog: true,
    isPlural: true,
    skip: props => !getJ5ReportIds(props.materials || props.samples).length,
    options: props => {
      const ids = getJ5ReportIds(props.materials || props.samples);
      const qb = new queryBuilder("j5Report");
      qb.whereAll({
        id: ids
      });
      addActiveProjectFilter(qb, {
        model: "j5Report"
      });
      const filter = qb.toJSON();
      return {
        variables: {
          pageSize: ids.length,
          filter: filter
        }
      };
    }
  })
)(DistributeByAssemblyReportDialog);
