/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useMemo } from "react";
import { reduxForm } from "redux-form";
import { compose } from "recompose";
import { Classes } from "@blueprintjs/core";
import {
  DialogFooter,
  FileUploadField,
  wrapDialog,
  getIdOrCodeOrIndex,
  BlueprintError
} from "@teselagen/ui";
import TagField from "../../../../src-shared/TagField";
import { startImportCollection } from "../../../../src-shared/utils/importCollection";
import { addTaggedItemsBeforeCreate } from "../../../../../tg-iso-shared/src/tag-utils";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { cleanCommaSeparatedCell } from "../../../../../tg-iso-shared/src/utils/fileUtils";
import { getBoundExtendedPropertyUploadHelpers } from "../../../../../tg-iso-shared/src/utils/extendedPropertiesUtils";
import uploadReagentHelper from "../../../../../tg-iso-shared/src/utils/uploadReagentHelper";
import { getDownloadTemplateFileHelpers } from "../../../../src-shared/components/DownloadTemplateFileButton";
import { throwFormError } from "../../../../src-shared/utils/formUtils";

const UploadAdditiveMaterialDialog = props => {
  const { hideModal, refetch, submitting, handleSubmit, error } = props;

  const accept = useMemo(
    () =>
      getDownloadTemplateFileHelpers({
        fileName: "reagents",
        validateAgainstSchema: {
          tableWideValidation: ({ entities }) => {
            const toRet = {};
            entities.forEach(entity => {
              if (entity.REAGENT_TYPE?.toLowerCase() !== "growth media") {
                if (entity.SELECTION_METHOD) {
                  toRet[`${getIdOrCodeOrIndex(entity)}:SELECTION_METHOD`] =
                    "Only applies to type 'Growth Media'";
                }
                if (entity.TARGET_ORGANISM_GROUP) {
                  toRet[`${getIdOrCodeOrIndex(entity)}:TARGET_ORGANISM_GROUP`] =
                    "Only applies to type 'Growth Media'";
                }
              }
            });
            return toRet;
          },
          fields: [
            {
              path: "NAME",
              description: "The name of the additive material",
              isRequired: true,
              example: "Additive A"
            },
            {
              path: "DESCRIPTION",
              description: "The description of the additive material",
              example: "Powdered growth supplement"
            },
            {
              path: "REAGENT_TYPE",
              description: "The type of the additive material",
              isRequired: true,
              example: "Growth Media"
            },
            {
              path: "IS_DRY?",
              description: "Whether the additive material is dry",
              example: "Yes"
            },
            {
              path: "SELECTION_METHOD",
              description:
                "Only applies to Reagent Type of 'Growth Media'. The selection method of the additive material",
              example: "Growth promotion"
            },
            {
              path: "TARGET_ORGANISM_GROUP",
              description:
                "Only applies to Reagent Type of 'Growth Media'. The target organism group of the additive material",
              example: "Bacteria"
            }
          ]
        },
        extendedPropTypes: ["reagent"]
      }),
    []
  );

  const onSubmit = useCallback(
    async values => {
      try {
        const { name: filename } = values.additiveMaterialFile[0];

        const { getCsvRowExtProps, createUploadProperties } =
          await getBoundExtendedPropertyUploadHelpers(
            values.additiveMaterialFile[0].meta.fields
          );

        const cleanedReagents = [];
        for (const row of values.additiveMaterialFile[0].parsedData) {
          cleanedReagents.push({
            name: row.NAME,
            reagentType: row.REAGENT_TYPE,
            description: row.DESCRIPTION,
            isDry: row["IS_DRY?"] === "true" || row["IS_DRY?"] === "yes",
            selectionMethods: cleanCommaSeparatedCell(row.SELECTION_METHOD),
            targetOrganismGroup: row.TARGET_ORGANISM_GROUP
          });
        }

        const newAdditiveMaterials = await uploadReagentHelper(
          cleanedReagents,
          {
            getCsvRowExtProps
          }
        );
        if (newAdditiveMaterials.length) {
          await startImportCollection(filename || "Reagent Upload");
          await safeUpsert(
            "additiveMaterial",
            addTaggedItemsBeforeCreate(newAdditiveMaterials, values.tags),
            {
              excludeResults: true
            }
          );
          await createUploadProperties();
        }
        await refetch();
        hideModal();
      } catch (error) {
        console.error("error:", error);
        throwFormError(error.message || "Error registering new reagents.");
        // window.toastr.error(error.message || "Error registering new reagents.");
      }
    },
    [hideModal, refetch]
  );
  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <FileUploadField
          accept={accept}
          fileLimit={1}
          isRequired
          name="additiveMaterialFile"
          label="Upload Reagents"
        />
        <TagField />
        <BlueprintError error={error} />
      </div>
      <DialogFooter
        hideModal={hideModal}
        submitting={submitting}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
};

export default compose(
  wrapDialog({
    title: "Upload Reagents"
  }),
  reduxForm({
    form: "uploadAdditiveMaterial"
  })
)(UploadAdditiveMaterialDialog);
