/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Tree, Menu, MenuItem, ContextMenu } from "@blueprintjs/core";
import { get, snakeCase } from "lodash";
import QueryBuilder from "tg-client-query-builder";
import { reduxForm } from "redux-form";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { parse, stringify } from "qs";
import { Loading } from "@teselagen/ui";
import shortid from "shortid";
import scrollIntoView from "dom-scroll-into-view";
import {
  getPositionRecordByIdAndType,
  moveContainer,
  resetAssignedPositionFields,
  buildTreeNodes,
  deleteContainerAndContents,
  getNodeLabel,
  showDeleteAlert
} from "../../../utils";
import PlateMapView from "../../../components/PlateMapView";
import GenericSelect from "../../../../src-shared/GenericSelect";
import getPathsFrom from "../../../../src-shared/utils/getPathsFrom";

import appGlobals from "../../../../src-shared/appGlobals";
import QueryLoader from "../../QueryLoader";
import { loadFreezerView } from "./utils";
import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";

import FreezerView from "../../FreezerSetup/FreezerView";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import { openInNewTab } from "../../../../src-shared/utils/generalUtils";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import loadChildItemsForTreeNode from "./loadChildItemsForTreeNode";
import gql from "graphql-tag";
import {
  safeUpsert,
  safeQuery,
  safeDelete
} from "../../../../src-shared/apolloMethods";
import { pathToPrimaryIds } from "@teselagen/path-utils";
import { getActiveLabId } from "@teselagen/auth-utils";
import DehydrateRehydrateAliquotMenuItem from "../ContainerArrayRecordView/DehydrateRehydrateAliquotMenuItem";

const equipmentTreePlateAdditiveFragment = gql`
  fragment equipmentTreePlateAdditiveFragment on additive {
    id
    additiveMaterial {
      id
      name
    }
    lot {
      id
      name
      additiveMaterial {
        id
        name
      }
    }
  }
`;

const equipmentTreePlatePreviewAliquotContainerFragment = gql`
  fragment equipmentTreePlatePreviewAliquotContainerFragment on aliquotContainer {
    id
    barcode {
      id
      barcodeString
    }
    aliquotContainerTypeCode
    columnPosition
    rowPosition
    additives {
      ...equipmentTreePlateAdditiveFragment
    }
    aliquot {
      id
      labId
      isDry
      volume
      volumetricUnitCode
      concentration
      concentrationUnitCode
      molarity
      molarityUnitCode
      mass
      massUnitCode
      additives {
        ...equipmentTreePlateAdditiveFragment
      }
      importCollection {
        id
        name
      }
      sample {
        id
        name
        material {
          id
          name
        }
      }
    }
  }
  ${equipmentTreePlateAdditiveFragment}
`;

const equipmentTreePlatePreviewFragment = gql`
  fragment equipmentTreePlatePreviewFragment on containerArray {
    id
    name
    labId
    containerArrayType {
      id
      isPlate
      containerFormatCode
      containerFormat {
        code
        rowCount
        columnCount
        quadrantSize
        is2DLabeled
      }
    }
    aliquotContainers {
      ...equipmentTreePlatePreviewAliquotContainerFragment
    }
  }
  ${equipmentTreePlatePreviewAliquotContainerFragment}
`;

// async function loadPageOfEquipment(page) {
//   try {
//     return await safeQuery(["equipmentItem", "id name label"], {
//       variables: {
//         pageSize: 10,
//         page,
//         sort: ["-name"]
//       }
//     });
//   } catch (error) {
//     console.error("error:", error);
//     window.toastr.error("Error loading equipment");
//   }
// }

const platesAndTubesFragment = gql`
  fragment platesAndTubesFragment on assignedPosition {
    id
    containerArray {
      id
      name
      barcode {
        id
        barcodeString
      }
    }
    aliquotContainer {
      id
      name
      barcode {
        id
        barcodeString
      }
    }
  }
`;

const platesAndTubesSchema = [
  {
    render: (v, r) => {
      return get(r, "containerArray.name") || get(r, "aliquotContainer.name");
    }
  },
  {
    render: (v, r) => {
      return (
        get(r, "containerArray.barcode.barcodeString") ||
        get(r, "aliquotContainer.barcode.barcodeString")
      );
    }
  },
  {
    addQueryString: true,
    render:
      ({ reactSelectQueryString = "" }) =>
      (_, r) => {
        if (
          reactSelectQueryString &&
          !matchedOnNameOrBarcode(r, reactSelectQueryString)
        ) {
          return "(Matches tube barcode in rack/box)";
        }
      }
  }
];

class EquipmentTree extends React.Component {
  state = {
    initialLoading: false,
    shelvesForFreezerView: undefined,
    treeNodes: [],
    selectionMap: {},
    freezerSelection: {
      shelf: [],
      drawer: [],
      rack: []
    },
    searchKey: shortid()
  };

  // this will keep track of node parents
  // nodeParentMap[node.id] = nodeParent
  nodeParentMap = {};

  clearSelection = () => {
    const { selectionMap } = this.state;
    Object.keys(selectionMap).forEach(key => {
      const nodeData = selectionMap[key];
      nodeData.isSelected = false;
    });
    this.setState({
      selectionMap: {},
      treeNodes: this.state.treeNodes
    });
  };

  async componentDidMount() {
    const { selectionMap } = this.state;
    this.setState({
      initialLoading: true
    });

    const { equipmentId, containerId, location } = this.props;
    try {
      this.loadFreezerView();
      let equipment, container, equipmentPositions;
      if (equipmentId) {
        equipment = await safeQuery(["equipmentItem", "id name label"], {
          variables: {
            id: equipmentId
          }
        });
        equipmentPositions = await safeQuery(
          ["equipmentPosition", "id name "],
          {
            variables: {
              filter: {
                equipmentId
              }
            }
          }
        );
      } else if (containerId) {
        container = await safeQuery(
          ["container", "id name label path equipmentId equipmentPositionId"],
          {
            variables: {
              id: containerId
            }
          }
        );
      }
      const treeNodes = buildTreeNodes({
        records: [equipment || container],
        selectionMap
      });
      this.setState(
        {
          equipmentPositions,
          rootContainer: container,
          treeNodes
        },
        async () => {
          await this.loadNode(treeNodes[0]);
          const { assignedPositionId, containerPositionId, tubeBarcode } =
            this.getHashInfoFromLocation(location);

          if (assignedPositionId || containerPositionId) {
            let locationRecord;
            if (assignedPositionId) {
              locationRecord = {
                id: assignedPositionId,
                __typename: "assignedPosition"
              };
            } else {
              locationRecord = {
                id: containerPositionId,
                __typename: "containerPosition"
              };
            }
            this.loadNodesToPosition(locationRecord, {
              forceSelect: true,
              tubeBarcode
            });
          }
        }
      );
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error loading equipment");
    }

    this.setState({
      initialLoading: false
    });
  }

  loadFreezerView = async () => {
    const { equipmentId } = this.props;
    if (!equipmentId) return;
    try {
      this.setState({
        loadingFreezerView: true
      });
      const shelves = await loadFreezerView(equipmentId);
      this.setState({
        shelvesForFreezerView: shelves
      });
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error loading freezer overview.");
    }
    this.setState({
      loadingFreezerView: false
    });
  };

  getHashInfoFromLocation = location => {
    return parse(location.hash.replace(/^#/, ""));
  };

  reloadContainerNode = async node => {
    // this function is called after a container is edited. It will reload the container name and label but keep
    // all children and open state
    const container = await await safeQuery(
      ["container", "id name label barcode {id barcodeString}"],
      {
        variables: {
          id: node.record.id
        }
      }
    );
    node.label = getNodeLabel(container);
    node.record = {
      ...node.record,
      name: container.name,
      label: container.label
    };

    this.setState({
      treeNodes: this.state.treeNodes
    });
  };

  resortContainerChildren = async node => {
    if (node.childNodes) {
      const containerNodes = [...node.childNodes].filter(n => {
        return get(n, "record.__typename") === "container";
      });
      if (containerNodes.length) {
        const sortedContainerNodes = [...containerNodes].sort((a, b) => {
          return (a.record.label || a.record.name || "").localeCompare(
            b.record.label || b.record.name || ""
          );
        });
        const wasReordered = containerNodes.some(node => {
          return (
            containerNodes.indexOf(node) !== sortedContainerNodes.indexOf(node)
          );
        });
        if (!wasReordered) return;
        node.childNodes = node.childNodes
          .filter(n => {
            return get(n, "record.__typename") !== "container";
          })
          .concat(sortedContainerNodes);
        this.setState({
          treeNodes: this.state.treeNodes
        });
        await this.loadFreezerView();
      }
    }
  };

  loadNode = async (node, forceLoad) => {
    if (node.loaded === true && !forceLoad) {
      node.icon = "folder-open";
      node.isExpanded = true;
      return this.setState({
        treeNodes: this.state.treeNodes
      });
    }
    if (node.icon === "refresh") return;
    node.icon = "refresh";

    try {
      this.setState({
        treeNodes: this.state.treeNodes
      });

      const items = await loadChildItemsForTreeNode(node);
      node.icon = "folder-open";
      node.isExpanded = true;
      node.loaded = true;
      const itemNodes = buildTreeNodes({
        records: items,
        displayPath: node.record.displayPath || "",
        selectionMap: this.state.selectionMap
      });
      node.childNodes = itemNodes;
      itemNodes.forEach(itemNode => {
        this.nodeParentMap[itemNode.id] = node;
      });
      return new Promise(resolve => {
        this.setState(
          {
            treeNodes: this.state.treeNodes
          },
          () => {
            resolve();
          }
        );
      });
    } catch (error) {
      console.error(`error:`, error);
    }
  };

  handleNodeExpand = async node => this.loadNode(node);

  handleNodeCollapse = nodeData => {
    nodeData.icon = "folder-close";
    nodeData.isExpanded = false;
    this.setState({
      treeNodes: this.state.treeNodes
    });
  };

  onTreeNodeSelect = (nodeData, nodePath, options = {}) => {
    const { treeNodes, selectionMap } = this.state;
    const { equipmentId } = this.props;
    const { tubeBarcode, forceSelect, manualClick } = options;
    const record = nodeData.record;
    if (record.__typename === "assignedPosition") {
      if (record.containerArray) {
        this.setState({
          aliquotContainerId: null,
          containerArrayId: record.containerArray.id,
          labId: record.containerArray.labId,
          tubeBarcode
        });
      } else if (record.aliquotContainer) {
        this.setState({
          containerArrayId: null,
          aliquotContainerId: record.aliquotContainer.id
        });
      }
    }

    if (selectionMap[nodeData.id] && !forceSelect) {
      this.clearSelection();
    } else {
      Object.values(selectionMap).forEach(node => {
        node.isSelected = false;
      });
      nodeData.isSelected = true;
      this.setState(
        {
          treeNodes,
          selectionMap: {
            [nodeData.id]: nodeData
          }
        },
        () => {
          if (manualClick) return;
          setTimeout(() => {
            const treeListContainer = document.getElementsByClassName(
              "tg-equipment-tree-list-container"
            )[0];
            const selectedTreeNode = document.getElementsByClassName(
              "bp3-tree-node-selected"
            )[0];
            if (treeListContainer && selectedTreeNode) {
              scrollIntoView(selectedTreeNode, treeListContainer, {
                alignWithTop: true
              });
            }
          }, 100);
        }
      );
    }

    if (equipmentId && nodePath) {
      // highlight the item in the freezer overview
      const pathOfInterest = nodePath.slice(1, 5);
      // the path of interest will hold the shelf, rack, and drawer (maybe position)
      const recordsOfPath = [];
      const nodesOfPath = [];
      let currentNode = treeNodes[0];
      pathOfInterest.forEach(nodeIndex => {
        currentNode = currentNode.childNodes[nodeIndex];
        nodesOfPath.push(currentNode);
        recordsOfPath.push(currentNode.record);
      });

      const containersOfPath = [];
      for (const record of recordsOfPath) {
        if (record.__typename === "container") {
          containersOfPath.push(record);
        } else {
          break;
        }
      }
      // we only care about the containers in the path
      // due to arbitrary nesting they could have something weird,
      // we just want to highlight up to the level we care about
      if (containersOfPath.length) {
        const lastContainer = containersOfPath[containersOfPath.length - 1];
        let containerPosition;
        if (lastContainer) {
          const lastContainerIndex = recordsOfPath.indexOf(lastContainer);
          const recordAfterLastContainer =
            recordsOfPath[lastContainerIndex + 1];
          if (
            recordAfterLastContainer &&
            recordAfterLastContainer.__typename === "containerPosition"
          ) {
            containerPosition = recordAfterLastContainer;
          }
        }
        this.selectContainerInFreezerView(containersOfPath, containerPosition);
      }
    }
  };

  handleNodeClick = (nodeData, nodePath) => {
    this.onTreeNodeSelect(nodeData, nodePath, { manualClick: true });
  };

  showAddByBarcodesDialog = nodeData => {
    // const fragFields =
    //   "id barcode { id barcodeString } assignedPosition { id }";
    showDialog({
      modalType: "ADD_ITEMS_BY_BARCODE",
      modalProps: {
        // tubeFragment: ["aliquotContainer", fragFields],
        // plateFragment: ["containerArray", fragFields],
        onSubmit: items => this.finalizeAddItems(items, nodeData)
      }
    });
  };

  finalizeAddItems = async (items = [], nodeData) => {
    if (!nodeData || !items.length) return;
    const record = nodeData.record;
    const modelName =
      record.__typename === "equipmentItem" ? "equipment" : record.__typename;
    const positionTypeCode = snakeCase(modelName).toUpperCase();
    if (items[0].__typename === "container") {
      const fullRecord = await getPositionRecordByIdAndType(record);
      for (const containerToMove of items) {
        const currentParentNode =
          this.nodeParentMap["container:" + containerToMove.id];
        // if the container is already in the equipment tree at some other place
        // we need to filter it out of the children because we are moving it
        // we don't want to refetch the whole tree because that is slow
        if (currentParentNode && currentParentNode !== nodeData) {
          currentParentNode.childNodes = currentParentNode.childNodes.filter(
            // eslint-disable-next-line no-loop-func
            childNode => {
              return !(
                childNode.record.id === containerToMove.id &&
                childNode.record.__typename === "container"
              );
            }
          );
        }
        await moveContainer(containerToMove, fullRecord, positionTypeCode);
      }
      await this.loadNode(nodeData, true);
      await this.loadFreezerView();
    } else {
      const assignedPositionFields = {
        ...resetAssignedPositionFields,
        positionTypeCode,
        [modelName + "Id"]: record.id
      };
      const assignedPositionUpdates = [];
      const newAssignedPositions = [];
      items.forEach(item => {
        if (get(item, "assignedPosition.id")) {
          const assignedPositionId = item.assignedPosition.id;
          assignedPositionUpdates.push({
            ...assignedPositionFields,
            id: assignedPositionId
          });
          const currentParentNode =
            this.nodeParentMap["assignedPosition:" + assignedPositionId];
          // if the plate is already in the equipment tree at some other place
          // we need to filter it out of the children because we are moving it
          // we don't want to refetch the whole tree because that is slow
          if (currentParentNode && currentParentNode !== nodeData) {
            currentParentNode.childNodes = currentParentNode.childNodes.filter(
              childNode => {
                return !(
                  childNode.record.id === assignedPositionId &&
                  childNode.record.__typename === "assignedPosition"
                );
              }
            );
          }
        } else {
          newAssignedPositions.push({
            ...assignedPositionFields,
            [item.__typename + "Id"]: item.id
          });
        }
      });
      await safeUpsert("assignedPosition", assignedPositionUpdates);
      await safeUpsert("assignedPosition", newAssignedPositions);
      await this.loadNode(nodeData, true);
      this.setState({
        searchKey: shortid()
      });
    }
  };

  showAddItemsDialog = async (nodeData, itemType) => {
    let fragment, tableParamOptions;
    const basicFields = "id name labId updatedAt";
    const barcodePositionFields =
      basicFields + " assignedPosition { id } barcode { id barcodeString }";

    const barcodeColumn = {
      displayName: "Barcode",
      path: "barcode.barcodeString"
    };
    if (itemType === "containers") {
      const fullRecord = nodeData.record;
      if (
        fullRecord.__typename === "container" ||
        fullRecord.__typename === "containerPosition"
      ) {
        let pathToExclude = fullRecord.container
          ? fullRecord.container.path
          : fullRecord.path || "";

        if (fullRecord.__typename === "container")
          pathToExclude += "/" + fullRecord.id;
        else pathToExclude += "/" + fullRecord.container.id;

        const containerIdsInPath = pathToPrimaryIds(pathToExclude);
        tableParamOptions = {
          additionalFilter: (props, qb) => {
            qb.whereAll({
              id: qb.notInList(containerIdsInPath)
            });
          }
        };
      }

      fragment = [
        "container",
        basicFields + " barcode { id barcodeString } path"
      ];
    } else if (itemType === "plates") {
      fragment = ["containerArray", barcodePositionFields];
    } else if (itemType === "aliquotContainer") {
      fragment = ["aliquotContainer", barcodePositionFields];
      tableParamOptions = {
        additionalFilter: (props, qb) => {
          qb.whereAll({
            containerArrayId: qb.isNull()
          });
        }
      };
    }

    const schema = {
      model: fragment[0],
      fields: ["name", barcodeColumn, dateModifiedColumn]
    };

    showDialog({
      modalType: "SELECT_GENERIC_ITEMS",
      modalProps: {
        isMultiSelect: true,
        onSelect: items => this.finalizeAddItems(items, nodeData),
        tableParamOptions,
        dialogProps: {
          style: {
            width: 700
          }
        },
        fragment,
        schema
      }
    });
  };

  showEditPositions = nodeData => {
    showDialog({
      modalType: "EDIT_POSITIONS",
      modalProps: {
        record: nodeData.record,
        refetch: async () => {
          await this.loadNode(nodeData, true);
          await this.loadFreezerView();
        }
      }
    });
  };

  showCreateContainer = nodeData => {
    showDialog({
      modalType: "ADD_CONTAINER",
      modalProps: {
        forcedPosition: true,
        refetch: async () => {
          await this.loadNode(nodeData, true);
          await this.loadFreezerView();
        },
        partialParentRecord: nodeData.record
      }
    });
  };

  handleNodeContextMenu = (nodeData, nodePath, e) => {
    e.preventDefault();
    const { history } = this.props;

    const menuItems = [];

    const model = nodeData.record.__typename;
    const recordId = nodeData.record.id;
    const canPlaceItems = [
      "container",
      "containerPosition",
      "equipmentItem",
      "equipmentPosition"
    ].includes(model);
    const canCreateContainer = [
      "container",
      "equipmentItem",
      "equipmentPosition"
    ].includes(model);
    const hasPositions = ["container"].includes(model);

    if (model === "assignedPosition") {
      const plateOrTube =
        nodeData.record.containerArray || nodeData.record.aliquotContainer;
      const link = modelNameToLink(plateOrTube.__typename, plateOrTube.id);
      menuItems.push(
        <MenuItem
          key="openItem"
          text="Open"
          onClick={() => {
            history.push(link);
          }}
        />
      );
      menuItems.push(
        <MenuItem
          key="openItemInNewTab"
          text="Open in New Tab"
          onClick={() => {
            openInNewTab(link);
          }}
        />
      );
    }

    if (canPlaceItems) {
      const addMenuItems = [
        <MenuItem
          key="barcode"
          text="Add by Barcodes"
          onClick={() => this.showAddByBarcodesDialog(nodeData)}
        />,
        <MenuItem
          key="plate"
          text="Add Plates"
          onClick={() => this.showAddItemsDialog(nodeData, "plates")}
        />,
        <MenuItem
          key="tube"
          text="Add Tubes"
          onClick={() => this.showAddItemsDialog(nodeData, "aliquotContainer")}
        />
      ];
      if (model !== "containerPosition") {
        addMenuItems.push(
          <MenuItem
            key="container"
            text="Add Containers"
            onClick={() => this.showAddItemsDialog(nodeData, "containers")}
          />
        );
      }
      const addMenu = (
        <MenuItem key="addItems" text="Add Items">
          {addMenuItems}
        </MenuItem>
      );
      menuItems.push(addMenu);
    }
    if (canCreateContainer) {
      menuItems.push(
        <MenuItem
          key="createContainer"
          text="Create Container"
          onClick={() => this.showCreateContainer(nodeData)}
        />
      );
    }
    if (model === "container") {
      menuItems.push(
        <MenuItem
          key="editContainer"
          text="Edit Container"
          onClick={() => {
            showDialog({
              modalType: "ADD_CONTAINER",
              modalProps: {
                refetch: async () => {
                  await this.reloadContainerNode(nodeData);
                  const parentNode = this.nodeParentMap[nodeData.id];
                  if (parentNode) {
                    this.resortContainerChildren(parentNode);
                  }
                },
                containerId: recordId
              }
            });
          }}
        />,
        <MenuItem
          key="deleteContainer"
          text="Delete Container"
          onClick={async () => {
            await deleteContainerAndContents([recordId]);
            const parentNode = this.nodeParentMap[nodeData.id];
            await this.loadNode(parentNode, true);
            await this.loadFreezerView();
          }}
        />
      );
    }
    if (hasPositions) {
      menuItems.push(
        <MenuItem
          key="editPositions"
          text="Edit Positions"
          onClick={() => this.showEditPositions(nodeData)}
        />
      );
    }
    if (menuItems.length) {
      const menu = <Menu>{menuItems}</Menu>;
      ContextMenu.show(menu, { left: e.clientX, top: e.clientY });
    }
  };

  onItemChosen = (assignedPosition, { reactSelectQueryString = "" } = {}) => {
    const { history, location } = this.props;
    let tubeBarcode;
    if (!matchedOnNameOrBarcode(assignedPosition, reactSelectQueryString)) {
      tubeBarcode = reactSelectQueryString;
    }
    this.loadNodesToPosition(assignedPosition, { tubeBarcode });
    history.replace(
      `${location.pathname}#${stringify({
        assignedPositionId: assignedPosition.id,
        tubeBarcode
      })}`
    );
  };

  onFreezerViewClick = record => {
    if (record && record.__typename) {
      this.loadNodesToPosition(record);
    }
  };

  loadNodesToPosition = async (position, options = {}) => {
    try {
      this.setState({
        loadingItemPath: true
      });
      const { equipmentId } = this.props;
      const { rootContainer } = this.state;
      const pathForPositions = await getPathsFrom(
        position.__typename,
        [position.id],
        safeQuery,
        {
          includeSelfInPath: position.__typename !== "assignedPosition",
          objectsAsPath: true
        }
      );
      const pathToPosition = pathForPositions[0];
      const indexOfCurrentItem = pathToPosition.findIndex(path => {
        if (rootContainer) {
          return (
            path.id === rootContainer.id &&
            path.__typename === rootContainer.__typename
          );
        } else {
          return path.__typename === "equipmentItem";
        }
      });
      const pathAfterCurrentItem = pathToPosition.slice(indexOfCurrentItem + 1);

      let currentNode = this.state.treeNodes[0];
      const getChildForPath = pathItem =>
        (currentNode.childNodes || []).find(child => {
          return (
            child.record.id === pathItem.id &&
            child.record.__typename === pathItem.__typename
          );
        });
      for (const pathItem of pathAfterCurrentItem) {
        const childForPath = getChildForPath(pathItem);

        if (childForPath) {
          currentNode.isExpanded = true;
          currentNode = childForPath;
        } else {
          await this.loadNode(currentNode, true);
          const childForPath = getChildForPath(pathItem);
          if (!childForPath) {
            throw new Error(
              "The child should be here because we loaded it. Something is broken."
            );
          }
          currentNode = childForPath;
        }
      }

      let loadedItem = getChildForPath(position);
      if (!loadedItem) {
        await this.loadNode(currentNode, true);
      } else {
        currentNode.isExpanded = true;
      }

      loadedItem = getChildForPath(position);

      if (!loadedItem) {
        const currentNodeIsMatch =
          get(currentNode, "record.id") === position.id &&
          currentNode.record.__typename === position.__typename;
        if (currentNodeIsMatch) {
          loadedItem = currentNode;
        }
      }
      this.setState({
        loadingItemPath: false
      });
      if (loadedItem) {
        this.onTreeNodeSelect(loadedItem, undefined, options);
      }

      // if we are in an equipment record view we can highlight the location of the item
      // on the freezer view
      if (equipmentId) {
        const containers = pathAfterCurrentItem.slice(0, 3);
        const allContainers = containers.every(
          c => c.__typename === "container"
        );
        if (allContainers && containers.length) {
          const recordAfterLastContainer =
            pathAfterCurrentItem[containers.length];
          let containerPosition;
          if (
            recordAfterLastContainer &&
            recordAfterLastContainer.__typename === "containerPosition"
          ) {
            containerPosition = recordAfterLastContainer;
          }
          this.selectContainerInFreezerView(containers, containerPosition);
        }
      }
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error loading equipment path.");
      this.setState({
        loadingItemPath: false
      });
    }
  };

  selectContainerInFreezerView = (containers, containerPosition = {}) => {
    // for the freezer view level 1 is shelf, level 2 is rack, level 3 is drawer
    const lastContainerId = containers[containers.length - 1].id;
    let key;
    if (containers.length === 3) {
      // its a drawer
      key = "drawer";
    } else if (containers.length === 2) {
      // its a rack
      key = "rack";
    } else if (containers.length === 1) {
      // its a shelf
      key = "shelf";
    }

    if (lastContainerId && key) {
      this.setState({
        freezerSelection: {
          [key]: [lastContainerId],
          [key + "PositionIndex"]: containerPosition.index
        }
      });
    }
  };

  searchFilter = (props, qb, { searchTerm }) => {
    const { equipmentPositions = [], rootContainer } = this.state;
    const { equipmentId } = this.props;
    const filters = [];
    // because plates and tubes can either be
    // 1) directly in an equipment
    // 2) in a equipment position
    // 3) in a container
    // 4) in a container position
    // we need to filter for items in any of those places
    // inside this piece of equipment
    const addFilter = tempFilter => {
      if (searchTerm) {
        filters.push({
          ...tempFilter,
          "containerArray.name": qb.contains(searchTerm)
        });
        filters.push({
          ...tempFilter,
          "containerArray.barcode.barcodeString": qb.contains(searchTerm)
        });
        // this will match a tube barcode in a rack or box
        filters.push({
          ...tempFilter,
          "containerArray.aliquotContainers.barcode.barcodeString":
            qb.contains(searchTerm)
        });
        filters.push({
          ...tempFilter,
          "aliquotContainer.name": qb.contains(searchTerm)
        });
        filters.push({
          ...tempFilter,
          "aliquotContainer.barcode.barcodeString": qb.contains(searchTerm)
        });
      } else {
        filters.push(tempFilter);
      }
    };
    if (equipmentId) {
      addFilter({
        equipmentId
      });
      addFilter({
        "container.equipmentId": equipmentId
      });
      addFilter({
        "containerPosition.container.equipmentId": equipmentId
      });
      if (equipmentPositions.length) {
        equipmentPositions.forEach(pos => {
          addFilter({
            equipmentPositionId: pos.id
          });
          addFilter({
            "container.equipmentPositionId": pos.id
          });
          addFilter({
            "containerPosition.container.equipmentPositionId": pos.id
          });
        });
      }
    }

    if (rootContainer) {
      const qb = new QueryBuilder("assignedPosition");
      const containerPathFilter = qb.startsWith(
        (rootContainer.path || "") + `/${rootContainer.id}`
      );
      // either directly placed in container
      addFilter({
        containerId: rootContainer.id
      });
      addFilter({
        "containerPosition.containerId": rootContainer.id
      });
      // or in a nested container inside root container
      addFilter({
        "container.path": containerPathFilter
      });
      addFilter({
        "containerPosition.container.path": containerPathFilter
      });
    }

    filters.forEach(filter => {
      qb.orWhereAll(filter);
    });
  };

  renderFreezerView() {
    const {
      loadingFreezerView,
      initialLoading,
      shelvesForFreezerView = [],
      freezerSelection
    } = this.state;
    if (loadingFreezerView && !initialLoading) return <Loading inDialog />;

    if (shelvesForFreezerView.length) {
      return (
        <FreezerView
          shelves={shelvesForFreezerView}
          onDrawerClick={this.onFreezerViewClick}
          onShelfClick={this.onFreezerViewClick}
          onPositionClick={this.onFreezerViewClick}
          selection={freezerSelection}
        />
      );
    }
  }

  render() {
    const { history } = this.props;
    const {
      treeNodes,
      initialLoading,
      containerArrayId,
      labId,
      loadingItemPath,
      searchKey,
      tubeBarcode
    } = this.state;

    if (initialLoading) {
      return <Loading inDialog />;
    }

    let preview = false;

    if (containerArrayId) {
      let userLabIds = [];

      if (appGlobals.currentUser && appGlobals.currentUser.labRoles.length) {
        userLabIds = appGlobals.currentUser.labRoles.map(l => l.labId);
      }

      if (!labId || userLabIds.indexOf(labId) > -1) {
        preview = true;
      }
    }

    return (
      <div className="tg-equipment-tree-wrapper">
        <div style={{ maxWidth: 300 }}>
          <GenericSelect
            key={searchKey}
            name="platesAndTubes"
            asReactSelect
            reactSelectProps={{
              disabled: loadingItemPath,
              placeholder: "Search contents..."
            }}
            onSelect={this.onItemChosen}
            tableParamOptions={{
              additionalFilter: this.searchFilter
            }}
            schema={platesAndTubesSchema}
            fragment={platesAndTubesFragment}
          />
        </div>
        <div
          className="tg-flex justify-space-between"
          style={{
            flexWrap: "wrap"
          }}
        >
          <div
            className="tg-equipment-tree-list-container"
            style={{
              minWidth: 300,
              maxHeight: 500,
              overflow: "auto",
              padding: 10,
              marginBottom: 15,
              marginRight: 25
            }}
          >
            {loadingItemPath ? (
              <Loading inDialog />
            ) : (
              <Tree
                contents={treeNodes}
                onNodeClick={this.handleNodeClick}
                onNodeCollapse={this.handleNodeCollapse}
                onNodeExpand={this.handleNodeExpand}
                onNodeContextMenu={this.handleNodeContextMenu}
              />
            )}
          </div>

          {this.renderFreezerView()}
        </div>
        {preview && (
          <>
            <QueryLoader
              inDialog
              showLoading
              fragment={equipmentTreePlatePreviewFragment}
              options={{
                context: {
                  headers: {
                    "tg-active-lab-id": getActiveLabId()
                  }
                },
                fetchPolicy: "cache-first",
                variables: {
                  id: containerArrayId
                }
              }}
            >
              {results => {
                const { containerArray } = results;

                const isRack =
                  containerArray && !containerArray.containerArrayType.isPlate;

                const onRowDoubleClick = record => {
                  if (!record.id) return;
                  if (record.aliquot) {
                    history.push(modelNameToLink(record.aliquot));
                  } else if (isRack) {
                    history.push(modelNameToLink(record));
                  }
                };

                const getContextMenuItems = ({ selectedRecords = [] }) => {
                  const record = selectedRecords[0] || {};
                  if (!record.id) return;
                  const viewRackMenuItem = (
                    <MenuItem
                      key="openRack"
                      icon="eye-open"
                      text="View Tube"
                      onClick={e => {
                        if (e.metaKey) {
                          openInNewTab(modelNameToLink(record));
                        } else {
                          history.push(modelNameToLink(record));
                        }
                      }}
                    />
                  );
                  if (record.aliquot) {
                    const items = [
                      <MenuItem
                        key="openAliquot"
                        icon="eye-open"
                        text="View Aliquot"
                        onClick={e => {
                          if (e.metaKey) {
                            openInNewTab(modelNameToLink(record.aliquot));
                          } else {
                            history.push(modelNameToLink(record.aliquot));
                          }
                        }}
                      />
                    ];
                    if (record.aliquot.labId === getActiveLabId()) {
                      items.push(
                        <DehydrateRehydrateAliquotMenuItem
                          aliquot={record.aliquot}
                        />,
                        <MenuItem
                          key="deleteAliquot"
                          icon="trash"
                          text="Delete Aliquot"
                          onClick={async () => {
                            try {
                              const continueDelete = await showDeleteAlert({
                                model: "aliquot"
                              });
                              if (continueDelete) {
                                await safeDelete("aliquot", [
                                  record.aliquot.id
                                ]);
                                await safeQuery(
                                  equipmentTreePlatePreviewAliquotContainerFragment,
                                  {
                                    variables: {
                                      filter: {
                                        id: record.id
                                      }
                                    }
                                  }
                                );
                              }
                            } catch (err) {
                              console.error("err:", err);
                              window.toastr.error("Error deleting aliquots");
                            }
                          }}
                        />
                      );
                    }
                    if (isRack) {
                      items.push(viewRackMenuItem);
                    }
                    return items;
                  } else if (isRack) {
                    return [viewRackMenuItem];
                  }
                };

                return (
                  <>
                    <PlateMapView
                      nameAsTitle
                      nameAsLink
                      noPadding
                      containerArray={containerArray}
                      selectedTubeBarcode={tubeBarcode}
                      aliquotContainerDoubleClick={onRowDoubleClick}
                      getContextMenuItems={getContextMenuItems}
                      plateOnly
                    />
                    <div>
                      <PlateMapView
                        noPadding
                        onTableRowDoubleClick={onRowDoubleClick}
                        containerArray={containerArray}
                        getContextMenuItems={getContextMenuItems}
                        tableOnly
                        withDisplayOptions
                      />
                    </div>
                  </>
                );
              }}
            </QueryLoader>
          </>
        )}
      </div>
    );
  }
}

export default compose(
  withRouter,
  reduxForm({
    form: "equipmentTree"
  })
)(EquipmentTree);

function matchedOnNameOrBarcode(r, reactSelectQueryString = "") {
  const name = get(r, "containerArray.name") || get(r, "aliquotContainer.name");
  const barcode =
    get(r, "containerArray.barcode.barcodeString") ||
    get(r, "aliquotContainer.barcode.barcodeString");
  const nameMatch =
    name && name.toLowerCase().includes(reactSelectQueryString.toLowerCase());
  const barcodeMatch =
    barcode &&
    barcode.toLowerCase().includes(reactSelectQueryString.toLowerCase());
  return nameMatch || barcodeMatch;
}
