/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import Promise from "bluebird";
import {
  extractZipFiles,
  getExt,
  isCsvOrExcelFile,
  parseCsvOrExcelFile
} from "../../utils/fileUtils";
import { sequenceFileTypes } from "./sequenceFileTypes";
import { parseSequenceFiles } from "./parseSequenceFiles";

export async function parseCsvAndSequenceFiles(
  allFilesMaybeZipped = [],
  { allowMultipleCsvFiles } = {}
) {
  const allFiles = await extractZipFiles(allFilesMaybeZipped);
  const csvFile = allFiles.find(isCsvOrExcelFile);
  const csvFiles = allFiles.filter(isCsvOrExcelFile);
  if (!csvFile) {
    throw new Error("No CSV file found.");
  }
  const nonCsvFiles = allFiles.filter(file => !isCsvOrExcelFile(file));
  let parsedCsv,
    parsedCsvs,
    allParsedSequences = [],
    allParsedSequencesFilenames = [];
  try {
    if (allowMultipleCsvFiles) {
      parsedCsvs = await Promise.all(csvFiles.map(parseCsvOrExcelFile));
    } else {
      parsedCsv = await parseCsvOrExcelFile(csvFile);
    }
  } catch (error) {
    console.error("error:", error);
    throw new Error("Error parsing CSV file.");
  }
  const sequenceFiles = nonCsvFiles
    ? nonCsvFiles.filter(f => sequenceFileTypes.includes(getExt(f)))
    : [];
  if (sequenceFiles.length) {
    const { sequences, filenames } = await parseSequenceFiles(sequenceFiles);
    allParsedSequences = sequences;
    allParsedSequencesFilenames = filenames;
  }
  return {
    parsedCsv,
    parsedCsvs,
    allParsedSequences,
    allParsedSequencesFilenames
  };
}
