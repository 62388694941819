/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { capitalize } from "lodash";
import HeaderWithHelper from "../../HeaderWithHelper";
import { CheckboxField, DataTable, RadioGroupField } from "@teselagen/ui";
import pluralize from "pluralize";
import fieldConstants from "./fieldConstants";
import UnitInputField from "../../UnitInputField";
import {
  defaultMassUnitCode,
  defaultVolumetricUnitCode
} from "../../../../../tg-iso-lims/src/utils/unitUtils";

const getTransferColumn = (type = "volume", prefix) => {
  let unitType, unitDefault;
  if (type === "volume") {
    unitType = "volumetricUnit";
    unitDefault = defaultVolumetricUnitCode;
  } else {
    unitType = "massUnit";
    unitDefault = defaultMassUnitCode;
  }
  return {
    displayName: `Transfer ${capitalize(type)}`,
    filterDisabled: true,
    sortDisabled: true,
    render: (v, r) => {
      return (
        <UnitInputField
          style={{ display: "flex", alignItems: "center" }}
          name={`${prefix}.transferInfo.${r.id}.${type}`}
          unitName={`${prefix}.transferInfo.${r.id}.${unitType}Code`}
          unitType={unitType}
          unitDefault={unitDefault}
        />
      );
    }
  };
};

export const TransferInfoSection = ({
  type,
  neededInputMaterials,
  materialTransferSettings,
  neededInputReagents,
  reagentTransferSettings,
  allReagentsMissing,
  formName
}) => {
  const capitalType = capitalize(type);
  let neededInputs;
  let transferSettings;
  if (type === "material") {
    neededInputs = neededInputMaterials;
    transferSettings = materialTransferSettings || {};
  } else if (type === "reagent") {
    neededInputs = neededInputReagents;
    transferSettings = reagentTransferSettings || {};
  }
  if (!neededInputs?.length) return null;
  const prefix = type + "TransferSettings";
  if (type === "reagent" && allReagentsMissing) return;
  return (
    <div className="tg-step-form-section column">
      <HeaderWithHelper
        header={`${capitalType} Transfer Volume`}
        helper={`Specify a transfer volume for source ${pluralize(type)}.`}
      />
      <CheckboxField
        defaultValue={transferSettings?.applyUniversalTransfer ?? true}
        name={
          type === "material"
            ? fieldConstants.applyUniversalTransfersMaterial
            : fieldConstants.applyUniversalTransfersReagent
        }
        label="Apply Universal Transfer"
      />
      <RadioGroupField
        defaultValue={transferSettings?.transferType ?? "volume"}
        inline
        options={[
          {
            label: "Volume",
            value: "volume"
          },
          {
            label: "Mass",
            value: "mass"
          }
        ]}
        label="Transfer Type"
        name={
          type === "material"
            ? fieldConstants.transferTypeMaterial
            : fieldConstants.transferTypeReagent
        }
      />
      {transferSettings.applyUniversalTransfer ? (
        <div style={{ maxWidth: 350 }}>
          {transferSettings.transferType === "volume" ? (
            <UnitInputField
              label="Transfer Volume"
              name={
                type === "material"
                  ? fieldConstants.universalTransferVolumeMaterial
                  : fieldConstants.universalTransferVolumeReagent
              }
              unitName={
                type === "material"
                  ? fieldConstants.universalTransferVolumetricUnitMaterial
                  : fieldConstants.universalTransferVolumetricUnitReagent
              }
              unitType="volumetricUnit"
              unitDefault={defaultVolumetricUnitCode}
            />
          ) : (
            <UnitInputField
              label="Transfer Mass"
              name={
                type === "material"
                  ? fieldConstants.universalTransferMassMaterial
                  : fieldConstants.universalTransferMassReagent
              }
              unitName={
                type === "material"
                  ? fieldConstants.universalTransferMassUnitMaterial
                  : fieldConstants.universalTransferMassUnitReagent
              }
              unitType="massUnit"
              unitDefault={defaultMassUnitCode}
            />
          )}
        </div>
      ) : (
        <>
          {!!neededInputMaterials.length && (
            <div style={{ marginBottom: 15 }}>
              <h6>Needed Input {capitalType}s</h6>
              <DataTable
                isSimple
                entities={neededInputs}
                noSelect
                keepDirtyOnReinitialize
                destroyOnUnmount={false}
                formName={formName} // toolSchema.code
                schema={[
                  "name",
                  getTransferColumn(transferSettings.transferType, prefix)
                ]}
                transferInfo={transferSettings.transferInfo}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
