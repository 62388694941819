/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

// That's what she said.
export function willItFit(
  numWells: number,
  containerFormat: { rowCount: number; columnCount: number }
) {
  const { rowCount, columnCount } = containerFormat;
  return numWells <= rowCount * columnCount;
}
