/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

/**
 * Get the sequence from a sequence object
 * @param {Object} sequence - The sequence object
 * @param {Object} options - The options object
 * @param {string} options.fragmentsKey - The key to use to get the sequence fragments
 * @param {boolean} options.upperCase - Whether to return the sequence in upper case
 * @returns {string | undefined} - The sequence or undefined if there is no sequence
 * @example
 * getSequence({ sequence: "ATGC" }); // "ATGC"
 * getSequence({ fullSequenceRaw: "atgc" }); // "atgc"
 * getSequence({ sequenceFragments: [{ index: 0, fragment: "AT" }, { index: 2, fragment: "GC" }] }); // "ATGC"
 * getSequence({ fullSequence: "atgc" }); // "atgc"
 * getSequence({ noSequence: true }); // undefined
 * getSequence({}); // undefined
 * getSequence({ proteinSequence: "ATGC" }); // "ATGC"
 * getSequence({ aminoAcidSequenceFragments: [{ index: 0, fragment: "AT" }, { index: 2, fragment: "GC" }] }); // "ATGC"
 * getSequence({ sequence: "atgc" }, { upperCase: true }); // "ATGC"
 * getSequence({ fullSequenceRaw: "atgc" }, { upperCase: true }); // "ATGC"
 **/
export const getSequence = (
  sequence,
  { fragmentsKey = "sequenceFragments", upperCase } = {}
) => {
  if (sequence.noSequence) return undefined;
  let toRet;
  if (sequence.sequence) {
    toRet = sequence.sequence;
  } else if (sequence.fullSequenceRaw) {
    toRet = sequence.fullSequenceRaw;
  } else if (sequence.proteinSequence) {
    toRet = sequence.proteinSequence;
  } else {
    const fragments =
      sequence.sequenceFragments ||
      sequence.aminoAcidSequenceFragments ||
      sequence[fragmentsKey];
    if (fragments) {
      toRet = [...fragments]
        .sort((frag1, frag2) => frag1.index - frag2.index)
        .map(frag => {
          return frag.fragment;
        })
        .join("");
    } else if (sequence.fullSequence) {
      return sequence.fullSequence; //tnr: this will always be lower case so we prioritize fullSequenceRaw and using sequenceFragments over it
    } else {
      return undefined;
    }
  }
  if (upperCase) {
    return toRet.toUpperCase();
  }
  return toRet;
};
