/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { get, isEqual, pick } from "lodash";
import { DataTable, CollapsibleCard } from "@teselagen/ui";
import routeDoubleClick from "../../../../src-shared/utils/routeDoubleClick";

import { getSequencesToUse } from "../../../utils";
import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";

import AbstractRecord from "../../../../src-shared/AbstractRecord";
import VeCard from "../MaterialRecordView/VeCard";
import sampleRecordFragment from "../../../graphql/fragments/sampleRecordFragment";
import RecordInfoDisplay from "../../../../src-shared/RecordInfoDisplay";
import recordViewEnhancer from "../../../../src-shared/recordViewEnhancer";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import AliquotsTableCard from "../../AliquotsTableCard";
import SampleFormulationsCard from "./SampleFormulationsCard";
import AminoAcidSequenceCard from "../ProteinRecordView/AminoAcidSequenceCard";
import SampleQcChecksTableCard from "../../SampleQCChecksTableCard";
import {
  getIsFormulatedSample,
  renderMaterialsField
} from "../../../utils/plateUtils";
import { formatDateTime } from "../../../../src-shared/utils/dateUtils";
import SequenceJ5ItemTableCard from "../../SequenceJ5ItemTableCard";
import { updateSequenceFromProps } from "../../../../src-shared/utils/sequenceUtils";

const proteinPatternSchema = {
  model: "proteinPattern",
  fields: [
    {
      displayName: "Pattern",
      path: "proteinPattern.pattern"
    }
  ]
};

class SampleRecordView extends Component {
  state = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    const newSequences = getSequencesToUse(nextProps);
    const { oldSequences } = prevState;
    if (!isEqual(newSequences, oldSequences)) {
      updateSequenceFromProps(newSequences);
      return {
        oldSequences: newSequences
      };
    } else {
      return null;
    }
  }

  renderLinkOrNotFound(item, route) {
    let itemName;
    if (item && item.__typename === "aliquot") {
      itemName = `Aliquot ${item.id}`;
    } else {
      itemName = item && item.name;
    }
    return item ? (
      <Link to={`/${route}/${item.id}`}>{itemName}</Link>
    ) : (
      "Not Found"
    );
  }

  renderMaterialsField = sample => {
    if (sample.sampleTypeCode === "FORMULATED_SAMPLE" && !sample.material) {
      const allMats = [];
      const matIds = [];
      sample.sampleFormulations.forEach(sf => {
        sf.materialCompositions.forEach(mc => {
          if (mc.material && !matIds.includes(mc.material.id)) {
            matIds.push(mc.material.id);
            allMats.push(mc.material);
          }
        });
      });
      return allMats.map((mat, i) => {
        return (
          <React.Fragment key={mat.id}>
            <Link to={modelNameToLink(mat)}>{mat.name}</Link>
            {i !== allMats.length - 1 ? ", " : ""}
          </React.Fragment>
        );
      });
    } else {
      return this.renderLinkOrNotFound(get(sample, "material"), "materials");
    }
  };

  renderSeqQCReportsField = reports => {
    return reports.map((report, i) => {
      return (
        <React.Fragment key={report.id}>
          {this.renderLinkOrNotFound(
            report,
            "dna-sequencing-quality-control-reports"
          )}
          {i !== reports.length - 1 ? ", " : ""}
        </React.Fragment>
      );
    });
  };

  renderContent(sample) {
    if (this.props.data.loading) return;
    if (sample) {
      const isolationEvent = get(sample, "sampleIsolation");
      const recordInfo = [
        ["Name", sample.name],
        ["Materials", renderMaterialsField({ sample })],
        ["Status", get(sample, "sampleStatus.name")],
        ["Sample Type", get(sample, "sampleType.name")]
      ];

      // if a sample has been sequenced,
      // the 'actual' sequence becomes the material
      // and the 'ideal' sequence becomes the target material
      if (sample.targetMaterial) {
        const targetMaterialLink = [
          "Target Material",
          this.renderLinkOrNotFound(sample.targetMaterial, "materials")
        ];
        recordInfo.splice(2, 0, targetMaterialLink);
      }
      if (sample.sampleAliquot) {
        const targetMaterialLink = [
          "Sample Aliquot",
          this.renderLinkOrNotFound(sample.sampleAliquot, "aliquots")
        ];
        recordInfo.splice(3, 0, targetMaterialLink);
      }
      if (sample.sequencingQualityControlReports) {
        const seqQCReportLink = [
          "Sequencing QC Report",
          this.renderSeqQCReportsField(sample.sequencingQualityControlReports)
        ];
        recordInfo.splice(4, 0, seqQCReportLink);
      }

      const isolationEventInfo = [
        [
          "Source Sample",
          this.renderLinkOrNotFound(
            get(sample, "sampleIsolation.sourceSample"),
            "samples"
          )
        ],
        ["X-Coordinate", get(isolationEvent, "xCoordinate")],
        ["Y-Coordinate", get(isolationEvent, "yCoordinate")],
        ["Diameter", get(isolationEvent, "diameter")],
        ["Date", formatDateTime(get(isolationEvent, "date"))]
      ];
      return (
        <div className="record-info-container">
          <div className="tg-flex justify-flex-start">
            <RecordInfoDisplay
              noFill
              recordInfo={recordInfo}
              readOnly={this.props.readOnly}
              record={sample}
            />
            {isolationEvent && (
              <React.Fragment>
                <div className="tg-flex-separator divider" />
                <div>
                  <h6 style={{ marginBottom: 20 }}>Isolation Event Info</h6>
                  <RecordInfoDisplay
                    withExtendedProperties
                    record={isolationEvent}
                    recordInfo={isolationEventInfo}
                  />
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      );
    }
  }

  render() {
    const {
      sample = {},
      refetchSample,
      hasExtendedProperties,
      extendedPropertyFields,
      showLeftSlideOutDrawer
    } = this.props;
    const material = get(sample, "material");
    const isFormulatedSample = getIsFormulatedSample(sample);
    const materialTypeCode = get(material, "materialTypeCode");
    const sequencesToUse = getSequencesToUse(this.props);
    if (!sample) return null;

    const dnaMaterialCompEntities = [];
    const strainMaterialCompEntities = [];
    const materialIdMap = {};
    if (isFormulatedSample) {
      sample.sampleFormulations.forEach(sf => {
        sf.materialCompositions.forEach(mc => {
          const material = mc.material;
          if (
            material.materialTypeCode === "DNA" &&
            !materialIdMap[material.id]
          ) {
            materialIdMap[material.id] = true;
            dnaMaterialCompEntities.push({
              name: material.name,
              size: get(material, "polynucleotideMaterialSequence.size"),
              sequenceType: get(
                material,
                "polynucleotideMaterialSequence.sequenceType.name"
              )
            });
          } else if (!materialIdMap[material.id]) {
            materialIdMap[material.id] = true;
            strainMaterialCompEntities.push({
              name: material.name,
              strain: get(material, "strain.name"),
              species: get(material, "strain.specie.abbreviatedName")
            });
          }
        });
      });
    }
    const extendedPropsCard = hasExtendedProperties && (
      <CollapsibleCard key="extendedPropsCard" title="Extended Properties">
        {extendedPropertyFields.map(field => {
          return (
            <div
              className="break-word"
              key={field.path}
              style={{ marginLeft: 20, marginTop: 5 }}
            >
              {field.displayName}: {field.render(null, sample)}
            </div>
          );
        })}
      </CollapsibleCard>
    );

    const materialCompositionsCard = isFormulatedSample && (
      <CollapsibleCard key="materialCompCard" title="Material Composition">
        <div style={{ paddingTop: 30 }}>
          <h6>DNA Materials</h6>
          <DataTable
            schema={[
              "name",
              { displayName: "Size", path: "size" },
              "sequenceType"
            ]}
            style={{ marginBottom: 20 }}
            formName="dnaMaterialsTable"
            entities={dnaMaterialCompEntities}
            isSimple
            onDoubleClick={routeDoubleClick}
          />
          <h6>Microbial Materials</h6>
          <DataTable
            schema={[
              "name",
              "strain",
              {
                displayName: "Species",
                path: "species",
                render: v => <i>{v}</i>
              }
            ]}
            formName="strainMaterialsTable"
            entities={strainMaterialCompEntities}
            isSimple
            onDoubleClick={routeDoubleClick}
          />
        </div>
      </CollapsibleCard>
    );

    const hasProteinPatterns = sample.sampleProteinPatterns.length > 0;
    const proteinPatternCard = hasProteinPatterns && (
      <CollapsibleCard key="proteinPatternCard" title="Protein Patterns">
        <DataTable
          schema={proteinPatternSchema}
          formName="proteinPatternTable"
          entities={sample.sampleProteinPatterns || []}
          isSimple
        />
      </CollapsibleCard>
    );
    const aliquotsCard = (
      <AliquotsTableCard
        key="sampleAliquotsCard"
        additionalFilter={(props, qb) => {
          qb.whereAll({
            sampleId: sample.id
          });
        }}
      />
    );
    const sampleQCChecksCard = sample.sampleQualityControlChecks.length > 0 && (
      <SampleQcChecksTableCard
        key="sampleQCChecksCard"
        additionalFilter={(props, qb) => {
          qb.whereAll({
            sampleId: sample.id
          });
        }}
      />
    );

    const materialHasSequences =
      materialTypeCode === "MICROBIAL" || materialTypeCode === "DNA";
    const veCard = materialHasSequences && (
      <VeCard
        key="veCard"
        {...{
          showLeftSlideOutDrawer,
          isMicrobialMaterial: materialTypeCode === "MICROBIAL",
          hasSequences: sequencesToUse.length,
          sequences: sequencesToUse,
          material,
          readOnly: true
        }}
      />
    );
    const aminoAcidCard = materialTypeCode === "PROTEIN" &&
      material.functionalProteinUnit && (
        <AminoAcidSequenceCard
          key="aas"
          functionalProteinUnit={material.functionalProteinUnit}
        />
      );
    return (
      <AbstractRecord
        {...this.props}
        recordName="sample"
        updateShowFunction={() =>
          showDialog({
            modalType: "UPDATE_SAMPLE",
            modalProps: {
              refetch: refetchSample,
              initialValues: pick(sample, ["id", "name", "sampleStatus"])
            }
          })
        }
        additionalCards={[
          extendedPropsCard,
          veCard,
          aminoAcidCard,
          aliquotsCard,
          isFormulatedSample && (
            <hr className="tg-section-break" key="sectionBreak1" />
          ),
          materialCompositionsCard,
          isFormulatedSample && (
            <React.Fragment key="sampleFormulations">
              <hr className="tg-section-break" />
              <SampleFormulationsCard sampleId={sample.id} />
            </React.Fragment>
          ),
          sampleQCChecksCard,
          proteinPatternCard,
          <SequenceJ5ItemTableCard
            key="sequenceJ5Items"
            sequenceIds={sequencesToUse.map(s => s.id)}
          />
          // this.props.assaySubjectCard
        ]}
      >
        {this.renderContent(sample)}
      </AbstractRecord>
    );
  }
}

export default recordViewEnhancer(sampleRecordFragment)(SampleRecordView);

// TODO: get back to deciding whether experimental analysis data,
// (e.g., assay subjects and assays) should be displayed in the Sample
// record view, for now it is shown in the Aliquot Record view.
// import {
//   withExperimentalDataRecords
// } from "../../../../src-shared/utils/assaySubjectUtils";
// export default compose(
//   ...withExperimentalDataRecords()
// )(recordViewEnhancer(sampleRecordFragment, {})(SampleRecordView));
