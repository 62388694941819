/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get } from "lodash";
import { Loading } from "@teselagen/ui";
import React from "react";
import { DocumentNode, QueryResult, useQuery } from "@apollo/client";
import client from "./apolloClient";
import { fragmentToQuery } from "@teselagen/apollo-methods";
import type { QueryOptions } from "./queryOptions";
import { gql } from "@apollo/client";

const EMPTY_QUERY = gql`
  query EmptyQuery {
    __typename
  }
`;

/**
 * Helper function to unify results formats from Hasura and Apollo
 *
 * Hasura results are always plural, and come in the form of { [nameToUse].nodes: [] }
 * Apollo can be singular or plural, and come in the form of { [nameToUse]: [] } or { [nameToUse].results: [] }
 *
 * Returns { [nameToUse]: realData }
 */
const unifyHasuraAndApolloResults = <
  TResultName extends string,
  TResultData = any
>(
  res: QueryResult,
  nameToUse: TResultName,
  isPlural: boolean,
  useHasura: boolean
) => {
  const path =
    nameToUse + (isPlural ? (useHasura ? ".nodes" : ".results") : "");
  const results = get(res.data, path);

  const totalResults = isPlural
    ? (get(res.data, nameToUse + ".totalResults", 0) as number | undefined)
    : ((results && 1) as number | undefined);

  return {
    data: {
      [nameToUse]: results
    } as { [key in TResultName]: TResultData | undefined },
    totalResults
  };
};

const useTgQuery = <TResultName extends string, TResultData = any>(
  inputFragment: [string, string] | DocumentNode,
  opts: QueryOptions = { skip: false }
) => {
  // Split by custom options and useQuery options
  const { useHasura, isPlural: _isPlural, ...useQueryOpts } = opts;
  const isSingle = opts.variables && (opts.variables.id || opts.variables.code);
  opts.isPlural = _isPlural || !isSingle;
  if (opts.useHasura) opts.isPlural = true;
  const { gqlQuery, nameToUse, queryNameToUse, isPlural } = opts.skip
    ? {
        gqlQuery: EMPTY_QUERY,
        nameToUse: "emptyQuery",
        queryNameToUse: "emptyQuery",
        isPlural: false
      }
    : fragmentToQuery(inputFragment, opts);

  const res = useQuery(gqlQuery as DocumentNode, {
    client,
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    ...useQueryOpts
  });

  const { data, totalResults } = unifyHasuraAndApolloResults<
    TResultName,
    TResultData
  >(res, nameToUse as TResultName, isPlural, !!opts.useHasura);

  const { data: _data, ...resWithoutData } = res;

  return {
    ...resWithoutData,
    data, // overwriting data with unified results for all cases
    entities: data[nameToUse as TResultName] as TResultData, // Also returning the results in entities
    totalResults,
    nameToUse,
    queryNameToUse
  };
};

useTgQuery.checkErrAndLoad = ({
  loading,
  error
}: {
  loading: boolean;
  error: Error;
}) => loading || error;

export const HandleErrAndLoad = ({
  loading,
  error,
  inDialog
}: {
  loading: boolean;
  error: Error;
  inDialog: boolean;
}) => {
  if (loading) return <Loading bounce inDialog={inDialog} />;
  if (error) {
    const stringErr = JSON.stringify(error);
    window.toastr.error(stringErr, { timeout: 30000 });
  }
  return null;
};

useTgQuery.handleErrAndLoad = HandleErrAndLoad;

export default useTgQuery;
