/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { InjectedFormProps, reduxForm } from "redux-form";
import { Callout, Classes } from "@blueprintjs/core";
import { DialogFooter, wrapDialog } from "@teselagen/ui";
import type { InjectedWrapDialogProps } from "@teselagen/ui";
import FillDirectionSelect from "../../../../FillDirectionSelect";
import DistributionAdvancedOptions from "./DistributionAdvancedOptions";
import type { Directions } from "../../../../../utils/plateUtils/getWellPositionsGivenWellCountAndDirection";

type DistributionPattern =
  | "Z"
  | "Z-Reverse"
  | "N"
  | "N-Reverse"
  | "Clockwise"
  | "Counter-Clockwise";

export interface FormData {
  fillDirection: Directions;
  distributionPattern: DistributionPattern;
}

interface Props {
  containerFormat: {
    rowCount: number;
    columnCount: number;
  };
  handleDistribute: (values: FormData) => void;
}

function ChooseSimpleDistributeOptions({
  hideModal,
  handleSubmit,
  submitting,
  containerFormat,
  handleDistribute
}: InjectedFormProps<FormData, Props> & InjectedWrapDialogProps & Props) {
  const onSubmit = (values: FormData) => {
    try {
      handleDistribute(values);
      hideModal();
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error(error.message || "Error Distributing");
    }
  };

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <Callout intent="warning" style={{ marginBottom: 15 }}>
          This will clear current placement and replace all items.
        </Callout>
        <div style={{ maxWidth: 250 }}>
          <FillDirectionSelect />
        </div>

        <DistributionAdvancedOptions containerFormat={containerFormat} />
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        text="Distribute"
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
}

export default compose<
  InjectedFormProps<FormData, Props> & InjectedWrapDialogProps & Props,
  Props
>(
  wrapDialog({
    title: "Distribute Options"
  }),
  reduxForm({
    form: "chooseDistSimpleForm"
  })
)(ChooseSimpleDistributeOptions);
