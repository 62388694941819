/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback } from "react";
import { reduxForm } from "redux-form";
import { compose } from "redux";
import { difference, keyBy } from "lodash";
import { Classes } from "@blueprintjs/core";
import {
  InputField,
  TextareaField,
  DialogFooter,
  RadioGroupField,
  ReactSelectField,
  wrapDialog,
  Loading
} from "@teselagen/ui";
import { arrayToIdOrCodeValuedOptions } from "../../../../src-shared/utils/formUtils";
import {
  safeUpsert,
  safeDelete,
  useTgQuery
} from "../../../../src-shared/apolloMethods";
import { withRouter } from "react-router-dom";
import { useFormValue } from "../../../../src-shared/hooks/useFormValue";
import gql from "graphql-tag";

const form = "PlacementStrategyConfigurationDialog";

const destinationContainerTypeOptions = [
  { label: "Box/Rack Placement", value: "box" },
  { label: "Container Placement", value: "container" }
];

const tubePlacementOptions = [
  {
    label:
      "Place in existing boxes/racks (this will generate new boxes if more space is needed)",
    value: "mixed"
  },
  { label: "Only place in new boxes/racks", value: "newBoxes" }
];

const strategyTypeOptions = [
  { label: "Plate Placement", value: "plate" },
  { label: "Tube Placement", value: "tube" }
];

const placementStrategyFragment = gql`
  fragment PlacementStrategyFragment on placementStrategy {
    id
    placementSections {
      id
    }
  }
`;

const PlacementStrategyConfigurationDialog = ({
  aliquotContainerTypes = [],
  containerArrayTypes = [],
  handleSubmit,
  hideModal,
  history,
  initialValues: {
    id: updateId,
    containerArrayTypeIds: initialContainerArrayTypeIds,
    aliquotContainerTypeCodes: initialAliquotContainerTypeCodes,
    placementContainerTypes: initialPlacementContainerTypes,
    isContainerArrayStrategy,
    containerArrayTypeIds,
    aliquotContainerTypeCodes
  } = {},
  placementContainerTypes = [],
  refetch,
  submitting
}) => {
  const strategyType = useFormValue(form, "strategyType");
  const destinationContainerType = useFormValue(
    form,
    "destinationContainerType"
  );

  const onSubmit = useCallback(
    async values => {
      const {
        id: updateId,
        name,
        description,
        strategyType,
        containerArrayTypeIds = [],
        aliquotContainerTypeCodes = [],
        destinationContainerType,
        tubePlacementOption,
        destinationContainerArrayTypeId
      } = values;
      const isContainerArrayStrategy = strategyType === "plate";
      const isDestinationContainerArray = destinationContainerType === "box";
      try {
        if (updateId) {
          const updateContainerType = [];
          if (containerArrayTypeIds.length) {
            containerArrayTypeIds.forEach(containerArrayTypeId => {
              updateContainerType.push({
                placementStrategyId: updateId,
                containerArrayTypeId
              });
            });
          } else {
            aliquotContainerTypeCodes.forEach(aliquotContainerTypeCode => {
              updateContainerType.push({
                placementStrategyId: updateId,
                aliquotContainerTypeCode
              });
            });
          }
          await safeDelete(
            "placementContainerType",
            initialPlacementContainerTypes.map(pct => pct.id)
          );
          await safeUpsert(["placementStrategy", "id name description"], {
            id: updateId,
            name,
            description
          });
          await safeUpsert("placementContainerType", updateContainerType);
          await refetch?.();
          hideModal();
        } else {
          const [
            {
              id,
              placementSections: [{ id: defaultPlacementSectionId }]
            }
          ] = await safeUpsert(placementStrategyFragment, {
            name,
            description,
            isContainerArrayStrategy,
            isDestinationContainerArray,
            destinationContainerArrayTypeId,
            generateBoxes: tubePlacementOption === "newBoxes",
            placementSections: [
              {
                name: "Default Placement Section",
                index: 1,
                prioritySubSections: [
                  {
                    name: "Default Priority Sub-Section",
                    rank: 1
                  }
                ]
              }
            ]
          });
          await safeUpsert("placementStrategy", {
            id,
            defaultPlacementSectionId
          });
          let newContainerArrayTypeIds = containerArrayTypeIds;
          let newAliquotContainerTypeCodes = aliquotContainerTypeCodes;

          if (initialContainerArrayTypeIds) {
            newContainerArrayTypeIds = difference(
              containerArrayTypeIds,
              initialContainerArrayTypeIds
            );
          }
          if (initialAliquotContainerTypeCodes) {
            newAliquotContainerTypeCodes = difference(
              aliquotContainerTypeCodes,
              initialAliquotContainerTypeCodes
            );
          }
          const removedContainerArrayTypeIds = difference(
            initialContainerArrayTypeIds,
            containerArrayTypeIds
          );
          const removedAliquotContainerTypeCodes = difference(
            initialAliquotContainerTypeCodes,
            aliquotContainerTypeCodes
          );

          const placementContainerTypesToDelete =
            placementContainerTypes.reduce((acc, placementContainerType) => {
              if (
                removedContainerArrayTypeIds.includes(
                  placementContainerType.containerArrayType.id
                )
              ) {
                acc.push(placementContainerType.id);
              }
              if (
                removedAliquotContainerTypeCodes.includes(
                  placementContainerType.aliquotContainerType.code
                )
              ) {
                acc.push(placementContainerType.id);
              }
              return acc;
            }, []);
          const containerTypes = [];
          if (isContainerArrayStrategy) {
            newContainerArrayTypeIds.forEach(containerArrayTypeId => {
              containerTypes.push({
                placementStrategyId: id,
                containerArrayTypeId
              });
            });
          } else {
            newAliquotContainerTypeCodes.forEach(aliquotContainerTypeCode => {
              containerTypes.push({
                placementStrategyId: id,
                aliquotContainerTypeCode
              });
            });
          }

          await safeDelete(
            "placementContainerType",
            placementContainerTypesToDelete
          );

          await safeUpsert("placementContainerType", containerTypes);
          await hideModal();
          await refetch?.();
          history.push(`/placement-strategies/${id}`);
        }
      } catch (e) {
        console.error(e);
        window.toastr.error(`Error placement strategy.`);
      }
    },
    [
      hideModal,
      history,
      initialAliquotContainerTypeCodes,
      initialContainerArrayTypeIds,
      initialPlacementContainerTypes,
      placementContainerTypes,
      refetch
    ]
  );

  const boxAndRackTypes = containerArrayTypes.filter(
    containerArrayType => !containerArrayType.isPlate
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={Classes.DIALOG_BODY}>
        <InputField name="name" label="Name" isRequired />
        <TextareaField name="description" label="Description" />
        {!updateId && (
          <RadioGroupField
            name="strategyType"
            label="Type"
            defaultValue="plate"
            options={strategyTypeOptions}
          />
        )}
        {!updateId && strategyType === "tube" && (
          <RadioGroupField
            name="destinationContainerType"
            label="Destination Type"
            defaultValue="box"
            options={destinationContainerTypeOptions}
          />
        )}
        {!updateId &&
          strategyType === "tube" &&
          destinationContainerType === "box" && (
            <RadioGroupField
              name="tubePlacementOption"
              label="Placement Option"
              defaultValue="mixed"
              options={tubePlacementOptions}
            />
          )}
        {strategyType === "plate" || isContainerArrayStrategy ? (
          <ReactSelectField
            name="containerArrayTypeIds"
            multi
            label="Plate Types"
            defaultValue={containerArrayTypeIds}
            options={arrayToIdOrCodeValuedOptions(containerArrayTypes)}
          />
        ) : (
          <ReactSelectField
            name="aliquotContainerTypeCodes"
            multi
            label="Tube Types"
            defaultValue={aliquotContainerTypeCodes}
            options={arrayToIdOrCodeValuedOptions(aliquotContainerTypes)}
          />
        )}
        {strategyType === "tube" && destinationContainerType === "box" && (
          <ReactSelectField
            name="destinationContainerArrayTypeId"
            label="Destination Box/Rack Type"
            options={arrayToIdOrCodeValuedOptions(boxAndRackTypes)}
          />
        )}
      </div>
      <DialogFooter {...{ hideModal, submitting }} />
    </form>
  );
};

const aliquotContainerTypeFragment = [
  "aliquotContainerType",
  "code name isTube"
];
const aliquotContainerTypeOptions = { variables: { filter: { isTube: true } } };
const containerArrayTypeFragment = [
  "containerArrayType",
  "id name isPlate nestableTubeTypes { id aliquotContainerTypeCode }"
];
const placementStrategyConfigurationDialogWrapper = Component => props => {
  const { entities: entitiesACT, loading: loadingACT } = useTgQuery(
    aliquotContainerTypeFragment,
    aliquotContainerTypeOptions
  );
  const { entities: entitiesCAT, loading: loadingCAT } = useTgQuery(
    containerArrayTypeFragment
  );
  if (loadingCAT || loadingACT) return <Loading inDialog bounce />;
  return (
    <Component
      {...props}
      containerArrayTypes={entitiesCAT}
      aliquotContainerTypes={entitiesACT}
    />
  );
};

export default compose(
  wrapDialog({ title: "Configure Placement Strategy" }),
  placementStrategyConfigurationDialogWrapper,
  reduxForm({
    enableReinitialize: true,
    form,
    validate,
    touchOnChange: true,
    touchOnBlur: true
  }),
  withRouter
)(PlacementStrategyConfigurationDialog);

function validate(values, props) {
  const {
    aliquotContainerTypeCodes = [],
    containerArrayTypeIds = [],
    destinationContainerArrayTypeId
  } = values;
  const { containerArrayTypes = [], aliquotContainerTypes = [] } = props;
  const errors = {};
  if (aliquotContainerTypeCodes && aliquotContainerTypeCodes.length < 1)
    errors.aliquotContainerTypeCodes =
      "Please select at least one aliquot container type.";
  if (containerArrayTypeIds && containerArrayTypeIds.length < 1)
    errors.containerArrayTypeIds = "Please select at least one plate type.";

  if (
    !Array.isArray(destinationContainerArrayTypeId) &&
    destinationContainerArrayTypeId &&
    aliquotContainerTypeCodes.length
  ) {
    const keyedAliquotContainerTypes = keyBy(aliquotContainerTypes, "code");
    const containerArrayType = containerArrayTypes.find(
      c => c.id === destinationContainerArrayTypeId
    );
    const acceptedTubeTypeCodes = containerArrayType.nestableTubeTypes.map(
      ntt => ntt.aliquotContainerTypeCode
    );
    const unnacceptedTubeTypeCodes = aliquotContainerTypeCodes.filter(
      c => !acceptedTubeTypeCodes.includes(c)
    );
    if (unnacceptedTubeTypeCodes.length) {
      const unnacceptedTypeNames = unnacceptedTubeTypeCodes.map(
        code => keyedAliquotContainerTypes[code].name
      );
      errors.destinationContainerArrayTypeId = `This box/rack type does not accept these tube types: ${unnacceptedTypeNames.join(
        ", "
      )}.`;
    }
  }
  return errors;
}
