/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState } from "react";
import { pick } from "lodash";
import { Button, Intent, Classes } from "@blueprintjs/core";
import PlateMapPlate from "../../PlateMapPlate";
import { DialogFooter, wrapDialog } from "@teselagen/ui";

const SelectPlateWellsDialog = ({
  locations: initialLocations = [],
  onSelect: _onSelect,
  containerArray,
  hideModal
}) => {
  const [locations, setLocations] = useState(initialLocations);

  const onSelect = () => {
    _onSelect(locations, containerArray);
    hideModal();
  };

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <Button
          text="Clear Selection"
          disabled={!locations.length}
          onClick={() => setLocations([])}
          intent={Intent.DANGER}
        />
        <div
          className="tg-flex align-center justify-center"
          style={{ padding: 20 }}
        >
          <PlateMapPlate
            {...pick(containerArray, [
              "aliquotContainers",
              "containerArrayType"
            ])}
            withDragSelect
            onWellsSelected={loc => setLocations(loc)}
            selectedAliquotContainerLocations={locations}
          />
        </div>
      </div>
      <DialogFooter hideModal={hideModal} onClick={onSelect} />
    </>
  );
};

export default wrapDialog({
  title: "Select Plate Wells",
  style: {
    display: "table"
  }
})(SelectPlateWellsDialog);
