/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { Callout, Classes, MenuItem } from "@blueprintjs/core";
import { startsWith } from "lodash";
import QueryString from "qs";
import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import {
  CheckboxField,
  DialogFooter,
  SelectField,
  TextareaField,
  wrapDialog
} from "@teselagen/ui";
import CustomTableSearchDialog from "../CustomTableSearchDialog";
import { hideDialog, showDialog } from "../GlobalDialog";
import { normalizeBasePairs } from "../utils/normalizeValues";
import {
  buildSequenceFilter,
  SEARCH_TYPE_KEYS,
  NO_REVERSE_PREFIX
} from "../../../tg-iso-shared/src/sequence-search";

export const sequenceSearchMenu = ({ setSearchTerm }) => (
  <MenuItem
    onClick={() => {
      showDialog({
        ModalComponent: compose(
          wrapDialog({ title: "Search by Sequence" }),
          withRouter,
          reduxForm({ form: "searchBySeqDialog" })
        )(function SearchBySeqDialog({ handleSubmit }) {
          return (
            <div className={Classes.DIALOG_BODY}>
              <Callout intent="primary">
                Please input at least 4 bases to search your sequence library
              </Callout>
              <br></br>
              <TextareaField
                tooltipError
                autoFocus
                isRequired
                validate={min4Bps}
                normalize={normalizeBasePairs}
                label="Base Pairs"
                name="sequence"
              ></TextareaField>

              <SelectField
                label="Match Type"
                name="matchType"
                defaultValue={Object.keys(matchTypes)[0]}
                options={Object.keys(matchTypes)}
              ></SelectField>
              <CheckboxField
                defaultValue={true}
                name="includeReverseStrand"
                label="Include Reverse Strand"
              ></CheckboxField>
              <DialogFooter
                text="Search"
                hideModal={hideDialog}
                onClick={handleSubmit(
                  ({ sequence, matchType, includeReverseStrand }) => {
                    const match = matchTypes[matchType];

                    setSearchTerm(
                      `${match}${
                        includeReverseStrand ? "" : NO_REVERSE_PREFIX
                      }${sequence}`
                    );
                    hideDialog();
                  }
                )}
              ></DialogFooter>
            </div>
          );
        })
      });
    }}
    text="Search By Sequence"
  ></MenuItem>
);

const matchTypes = {
  Contains: "bp_in_",
  "Matches Entire Sequence": "bp_eq_",
  "Starts With": "bp_sw_",
  "Ends With": "bp_ew_"
};

export const seqSearchAdditionalOrFilter = (search = "", qb) => {
  let { searchTerm } = QueryString.parse(search, { ignoreQueryPrefix: true });
  if (searchTerm) {
    searchTerm = searchTerm.toLowerCase();

    const searchTypeKey = SEARCH_TYPE_KEYS.filter(key =>
      startsWith(searchTerm, key)
    )[0];
    if (searchTypeKey) {
      buildSequenceFilter({
        searchSequence: searchTerm,
        searchTypeKey,
        qb
      });
      return {
        ignoreSearchTerm: true
      };
    }
  }
};

export function MultiLineSearchMenuItem(props) {
  return (
    <MenuItem
      text="Multi-line Search"
      onClick={() => {
        const { tableParams } = props;
        showDialog({
          ModalComponent: CustomTableSearchDialog,
          modalProps: {
            tableParams
          }
        });
      }}
    ></MenuItem>
  );
}

function min4Bps(r) {
  if (r && r.length >= 4) return "";
  return "Please input at least 4 bases";
}
