/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { modelTypeToModelMap } from "../../../../tg-iso-shared/src/utils/modelTypeMap";

export const getFieldFromExtProp = (p: {
  name: string;
  modelTypeCode: keyof typeof modelTypeToModelMap;
  extendedPropertyClassCode: "CATEGORY" | "MEASUREMENT" | "BOOLEAN" | "NUMBER";
  extendedTypeCode: "string" | "boolean" | "number";
  extendedCategoryClass?: {
    extendedCategories: Array<{ name: string }>;
  };
}) => {
  const header = `ext-${modelTypeToModelMap[p.modelTypeCode]}-${p.name}`;
  const type =
    p.extendedPropertyClassCode === "CATEGORY"
      ? "dropdown"
      : p.extendedTypeCode;
  return {
    path: header,
    displayName: header,
    type,
    ...(p.extendedPropertyClassCode === "CATEGORY" && {
      values: p.extendedCategoryClass?.extendedCategories.map(c => c.name)
    }),
    description: `${header}  (${type})`,
    example:
      p.extendedPropertyClassCode === "CATEGORY"
        ? p.extendedCategoryClass?.extendedCategories[0].name
        : p.extendedPropertyClassCode === "MEASUREMENT"
          ? "23 stones"
          : p.extendedTypeCode === "boolean"
            ? true
            : p.extendedTypeCode === "number"
              ? 3
              : "example value"
  };
};
