/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export const workflowDefTaskIoTypeFragment = gql`
  fragment workflowDefTaskIoTypeFragment on taskIoType {
    code
    dataItemTypeCode
    inventoryItemTypeCode
    ioItemTypeCode
  }
`;

export const wtdExtendedPropertiesFragment = gql`
  fragment wtdExtendedPropertiesFragment on wtdExtendedProperty {
    id
    extendedPropertyId
    extendedProperty {
      id
      name
      modelTypeCode
    }
  }
`;

export const wdWorkflowToolInputDefinitionFragment = gql`
  fragment wdWorkflowToolInputDefinitionFragment on workflowToolInputDefinition {
    id
    itemCode
    label
    isList
    isDisabled
    taskIoTypeCode
    taskIoType {
      ...workflowDefTaskIoTypeFragment
    }
    wtInputDefToOutputDefs {
      id
      workflowToolInputDefinitionId
      workflowToolOutputDefinitionId
    }
  }
  ${workflowDefTaskIoTypeFragment}
`;

export const wdWorkflowToolOutputDefinitionFragment = gql`
  fragment wdWorkflowToolOutputDefinitionFragment on workflowToolOutputDefinition {
    id
    itemCode
    label
    isList
    taskIoTypeCode
    taskIoType {
      ...workflowDefTaskIoTypeFragment
    }
  }
  ${workflowDefTaskIoTypeFragment}
`;

export const workflowToolDefinitionFragment = gql`
  fragment workflowToolDefinitionFragment on workflowToolDefinition {
    id
    name
    toolCode
    index
    validateBarcodes
    wtdExtendedProperties {
      ...wtdExtendedPropertiesFragment
    }
    workflowToolInputDefinitions {
      ...wdWorkflowToolInputDefinitionFragment
    }
    workflowToolOutputDefinitions {
      ...wdWorkflowToolOutputDefinitionFragment
    }
    workflowToolSettingDefinitions {
      id
      name
      isPresetValue
      value
      workflowToolSettingDefinitionTypeCode
      integration {
        id
        name
      }
      customQuery {
        id
        name
      }
    }
  }
  ${wtdExtendedPropertiesFragment}
  ${wdWorkflowToolInputDefinitionFragment}
  ${wdWorkflowToolOutputDefinitionFragment}
`;

export default gql`
  fragment workflowDefinitionFragment on workflowDefinition {
    id
    name
    description
    isActive
    workflowToolDefinitions {
      ...workflowToolDefinitionFragment
    }
    workflowRuns(pageSize: 1) {
      id
    }
  }
  ${workflowToolDefinitionFragment}
`;
