/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import StepForm from "../../../../src-shared/StepForm";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import MoveItems, { plateFragment } from "./MoveItems";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import AssignPlatePlacementDialog from "../../Dialogs/AssignPlatePlacementDialog";

const steps = [
  {
    title: "Move Items",
    Component: MoveItems
  }
];

const onSubmit = async ({ containerArrays }) => {
  try {
    const { dataTableId } = await new Promise((resolve, reject) => {
      const placementCb = (options = {}) => {
        const { success, dataTableId } = options;
        if (!success) {
          reject("user cancelled");
        } else {
          resolve({ dataTableId });
        }
      };

      showDialog({
        ModalComponent: AssignPlatePlacementDialog,
        onClose: () => placementCb({ success: false }),
        modalProps: {
          placementCb,
          plateIds: containerArrays.map(p => p.id),
          containerArrayTypes: containerArrays.map(p => p.containerArrayType)
        }
      });
    });
    await safeUpsert(
      ["containerArray", "id updatedAt"],
      containerArrays.map(p => ({ id: p.id, updatedAt: new Date() }))
    );
    return {
      containerArrays,
      dataTable: dataTableId && { id: dataTableId, __typename: "dataTable" }
    };
  } catch (error) {
    console.error("error:", error);
  }
};

const MoveTool = ({
  toolIntegrationProps,
  toolSchema,
  isToolIntegrated,
  initialValues
}) => (
  <StepForm
    toolIntegrationProps={toolIntegrationProps}
    enableReinitialize={isToolIntegrated}
    steps={steps}
    toolSchema={toolSchema}
    onSubmit={onSubmit}
    initialValues={initialValues}
  />
);

export default withWorkflowInputs(plateFragment)(MoveTool);
