/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useEffect, useMemo, useRef } from "react";
import { reduxForm } from "redux-form";
import { Button, Intent, Classes } from "@blueprintjs/core";
import classNames from "classnames";
import { compose } from "redux";
import { TextareaField } from "@teselagen/ui";
import scrollIntoView from "dom-scroll-into-view";
import qs from "qs";
import AvatarIcon from "../../AvatarIcon";
import { getLocationHashPathName } from "../utils";
import MentionField from "../MentionField";
import commentFragment from "../commentFragment";
import "./style.css";
import { noop } from "lodash";
import { createNotification } from "../../utils/createNotification";
import { safeUpsert } from "../../apolloMethods";

export const AddComment = ({
  isReplyForm,
  reset,
  record: { id, __typename } = {},
  commentId,
  cancelReply,
  currentUser,
  afterCreate,
  withMentions = true,
  userIdToReplyTo,
  handleSubmit,
  label,
  buttonOverride,
  placeholder,
  submitting,
  handleMentionsSubmit
}) => {
  const formEl = useRef(null);
  useEffect(() => {
    if (isReplyForm) {
      // scroll so the form is showing fully
      const cancelButton = document.getElementById(
        "reply-to-comment-cancel-button"
      );
      const container = document.getElementById("tg-comment-list");
      if (cancelButton) {
        scrollIntoView(cancelButton, container, {
          onlyScrollIfNeeded: true
        });
      }
    }
  }, [isReplyForm]);

  const onSubmit =
    (opts = {}) =>
    async formData => {
      const { messagePrefix = "", commentProps, beforeFinish = noop } = opts;

      if (!formData.message) return false;
      formEl.current.querySelector("textarea").setAttribute("disabled", true);

      const newStringValue = withMentions
        ? formData.message.stringValue
        : formData.message;

      const data = { message: messagePrefix + newStringValue, ...commentProps };

      data.userId = currentUser.id;

      if (commentId) {
        data.commentReply = {
          commentId
        };
      } else {
        data[__typename + "Id"] = id;
      }

      try {
        const [newComment] = await safeUpsert(commentFragment, data);
        if (isReplyForm) {
          const { hash, pathName } = getLocationHashPathName();

          const hashObj = qs.parse(hash.replace(/^#/, ""));
          hashObj.commentId = newComment.id;
          hashObj.parentCommentId = commentId;

          const urlHash = qs.stringify(hashObj);

          if (currentUser.id !== userIdToReplyTo) {
            await createNotification({
              message: `${currentUser.username} replied to your comment`,
              userId: userIdToReplyTo,
              notificationIntent: "primary",
              link: `${pathName}#${urlHash}`,
              notificationTypeCode: "COMMENT_REPLY"
            });
          }
        }
        if (withMentions) {
          await handleMentionsSubmit(newComment.id, newStringValue);
        }

        await beforeFinish();
        await afterCreate(newComment);
        reset();
        cancelReply && cancelReply();
      } catch (error) {
        console.error(error);
        window.toastr.error("Error creating comment");
      } finally {
        formEl.current.querySelector("textarea").removeAttribute("disabled");
      }
    };

  const textAreaToUse = useMemo(() => {
    const shared = {
      name: "message",
      disabled: submitting,
      autoFocus: isReplyForm,
      placeholder: placeholder,
      "data-test": `tg-${isReplyForm ? "reply" : "comment"}-message`,
      label: label
    };
    if (withMentions) {
      return <MentionField {...shared} isRequired />;
    } else {
      return <TextareaField {...shared} submitting={submitting} isRequired />;
    }
  }, [isReplyForm, label, placeholder, submitting, withMentions]);

  return (
    <form
      ref={formEl}
      className={classNames("add-comment-form", { reply: isReplyForm })}
    >
      <div className="tg-flex width100">
        {isReplyForm && (
          <div className="comment-avatar">
            <AvatarIcon
              size={28}
              style={{ border: "2px solid white" }}
              user={currentUser}
            />
          </div>
        )}
        {textAreaToUse}
      </div>
      <div className="tg-flex justify-flex-end">
        {isReplyForm && (
          <Button
            style={{ marginRight: 10 }}
            id="reply-to-comment-cancel-button"
            className={Classes.SMALL + " " + Classes.MINIMAL}
            onClick={cancelReply}
            intent={Intent.DANGER}
            text="Cancel"
          />
        )}
        {buttonOverride ? (
          buttonOverride({
            handleSubmit,
            submitting,
            onSubmit
          })
        ) : (
          <Button
            onClick={handleSubmit(onSubmit())}
            data-test={`tg-submit-${isReplyForm ? "reply" : "comment"}`}
            style={{ marginRight: isReplyForm ? 15 : 0 }}
            className={Classes.SMALL + " " + Classes.MINIMAL}
            intent={Intent.SUCCESS}
            loading={submitting}
            text="Submit"
            type="submit"
          />
        )}
      </div>
    </form>
  );
};

export default compose(
  reduxForm({
    form: "AddCommentForm"
  })
)(AddComment);
