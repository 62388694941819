/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback } from "react";
import { pick, keyBy, uniqBy } from "lodash";
import withQuery from "../../../../src-shared/withQuery";

import shortid from "shortid";
import { SubmissionError } from "redux-form";
import { compose } from "recompose";
import StepForm from "../../../../src-shared/StepForm";
import { addAliquotsToAliquotContainers } from "../../../utils";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import dataTableFragment from "../../../graphql/fragments/dataTableFragment";
import DestinationContainerConfiguration from "./Steps/DestinationContainerConfiguration";
import UploadFeedbackData from "./Steps/UploadFeedbackData";

import {
  safeUpsert,
  safeQuery,
  safeDelete
} from "../../../../src-shared/apolloMethods";
import { addBarcodesToRecords } from "../../../../../tg-iso-lims/src/utils/barcodeUtils";
import {
  getPositionFromAlphanumericLocation,
  getUploadAliquotContainers,
  wellInBounds
} from "../../../../../tg-iso-lims/src/utils/plateUtils";
import containerArrayTypeFragment from "../../../../../tg-iso-shared/src/fragments/containerArrayTypeFragment";
import { getBoundExtendedPropertyUploadHelpers } from "../../../../../tg-iso-shared/src/utils/extendedPropertiesUtils";
import { inPlateBounds } from "../../../utils/plateUtils";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";

const steps = [
  {
    title: "Select Feedback Data",
    Component: UploadFeedbackData,
    withCustomFooter: true
  },
  {
    title: "Destination Container Configuration",
    Component: DestinationContainerConfiguration
  }
];

const validate = values => {
  const errors = {};

  const { feedbackDataFile } = values;

  if (feedbackDataFile && feedbackDataFile.length) {
    let error = "";
    feedbackDataFile.forEach(file => {
      if (file.error) {
        error += `\n\n${file.error}`;
      }
    });
    if (error) {
      errors.feedbackDataFile = error;
    }
  }

  if (
    !errors.containerArrayType &&
    values.containerArrayType &&
    values.feedbackData
  ) {
    let destinationContainerTypeError = "";
    values.feedbackData.some(row => {
      if (
        !row.destinationAliquotContainer &&
        !wellInBounds(
          row.destinationWell,
          values.containerArrayType.containerFormat
        )
      ) {
        destinationContainerTypeError += `Well ${row.destinationWell} from feedback data will not fit into this format.`;
        return true;
      }
      return false;
    });
    if (destinationContainerTypeError) {
      errors.containerArrayType = destinationContainerTypeError;
    }
  }

  return errors;
};

const ColonyPickingFeedback = ({
  containerArrayTypes = [],
  toolIntegrationProps,
  toolSchema,
  isToolIntegrated,
  initialValues
}) => {
  const onSubmit = useCallback(
    async values => {
      const {
        feedbackData,
        destinationContainerName,
        containerArrayType,
        aliquotContainerType,
        shouldFillRack,
        generateTubeBarcodes,
        hasExtProp,
        allCsvFields
      } = values;

      try {
        const keyedContainerArrayTypes = keyBy(containerArrayTypes, "id");
        let destinationAliquotContainerType;
        if (containerArrayType) {
          destinationAliquotContainerType = containerArrayType.isPlate
            ? containerArrayType.aliquotContainerType
            : aliquotContainerType;
        }

        const newPlates = {};
        const updatedPlates = [];
        const getDestinationPlate = dataRow => {
          const barcode = dataRow.destinationPlateBarcode;
          if (!newPlates[barcode]) {
            newPlates[barcode] = {
              name:
                destinationContainerName +
                ` ${Object.keys(newPlates).length + 1}`,
              barcode: {
                barcodeString: barcode
              },
              containerArrayTypeId: containerArrayType.id,
              aliquotContainers: []
            };
          }
          return newPlates[barcode];
        };
        const aliquotContainerUpdates = [];
        const aliquotsToCreate = [];
        let getCsvRowExtProps, createUploadProperties;
        if (hasExtProp) {
          const helpers =
            await getBoundExtendedPropertyUploadHelpers(allCsvFields);
          getCsvRowExtProps = helpers.getCsvRowExtProps;
          createUploadProperties = helpers.createUploadProperties;
        }
        const destinationPlatePlacedMap = {};
        feedbackData.forEach((dataRow, i) => {
          // source sample should have sample formulation data
          const sourceSample = dataRow.sourceAliquotContainer.aliquot.sample;
          const sampleIsolationCid = shortid();
          const sampleIsolation = {
            cid: sampleIsolationCid,
            ...pick(dataRow, ["xCoordinate", "yCoordinate", "diameter"]),
            date: dataRow.cleanedDate,
            sourceSampleId: sourceSample.id,
            sourcePlateWellId: dataRow.sourceAliquotContainer.id
          };
          if (getCsvRowExtProps) {
            getCsvRowExtProps({
              row: dataRow,
              recordId: `&${sampleIsolationCid}`,
              modelTypeCode: "ISOLATION_EVENT"
            });
          }
          const newSampleName = `${sourceSample.name || "Untitled Sample"}-Isolate${i + 1}`;
          const newAliquot = {
            cid: shortid(),
            volume: 0,
            aliquotType: "sample-aliquot",
            volumetricUnitCode: "uL",
            isDry: false,
            sample: {
              name: `${sourceSample.name || "Untitled Sample"}-Isolate${i + 1}`,
              sampleIsolation: sampleIsolation,
              sampleProteinPatterns: sourceSample.sampleProteinPatterns.map(
                spp => ({
                  proteinPatternId: spp.proteinPatternId
                })
              ),
              sampleStatusCode: "UNVALIDATED",
              sampleTypeCode: "ISOLATED_SAMPLE"
            }
          };
          if (sourceSample.materialId) {
            newAliquot.sample.materialId = sourceSample.materialId;
          } else {
            newAliquot.sample.material = {
              name: newSampleName,
              materialTypeCode:
                sourceSample.sampleFormulations[0].materialCompositions[0]
                  .material.materialTypeCode
            };
          }
          aliquotsToCreate.push(newAliquot);
          if (dataRow.destinationAliquotContainer) {
            updatedPlates.push(dataRow.destinationPlate);
            aliquotContainerUpdates.push({
              id: dataRow.destinationAliquotContainer.id,
              aliquotCid: newAliquot.cid
            });
          } else {
            const destinationPlate = getDestinationPlate(dataRow);
            const pos = getPositionFromAlphanumericLocation(
              dataRow.destinationWell,
              containerArrayType.containerFormat
            );
            const location2d = getAliquotContainerLocation(pos, {
              force2D: true
            });
            const barcode = dataRow.destinationPlateBarcode;
            if (!destinationPlatePlacedMap[barcode]) {
              destinationPlatePlacedMap[barcode] = {};
            }
            if (destinationPlatePlacedMap[barcode][location2d]) {
              throw new Error(
                `Multiple rows from feedback data were pointed at destination well ${dataRow.destinationWell} on destination plate ${barcode}.`
              );
            }
            destinationPlatePlacedMap[barcode][location2d] = true;
            if (!inPlateBounds(pos, containerArrayType.containerFormat)) {
              throw new Error(
                `Well ${dataRow.destinationWell} from feedback data will not fit into this format.`
              );
            }
            destinationPlate.aliquotContainers.push({
              aliquotId: `&${newAliquot.cid}`,
              aliquotContainerTypeCode: destinationAliquotContainerType.code,
              ...pos
            });
          }
        });

        const platesToCreate = Object.values(newPlates).map(plate => {
          const containerArrayType =
            keyedContainerArrayTypes[plate.containerArrayTypeId];
          plate.aliquotContainers = getUploadAliquotContainers({
            newAliquotContainers: plate.aliquotContainers || [],
            containerArrayType,
            shouldFillRack,
            aliquotContainerType
          });
          return plate;
        });

        const newAliquots = await safeUpsert(
          ["aliquot", "id cid sampleId"],
          aliquotsToCreate
        );

        if (createUploadProperties) {
          await createUploadProperties();
        }

        const sampleUpdates = [];
        newAliquots.forEach(aliquot =>
          sampleUpdates.push({
            id: aliquot.sampleId,
            sampleAliquotId: aliquot.id
          })
        );
        await safeUpsert("sample", sampleUpdates);

        const keyedAliquots = keyBy(newAliquots, "cid");

        if (aliquotContainerUpdates.length) {
          const aliquotsToAdd = [];
          const sortedAliquotContainers = [];

          aliquotContainerUpdates.forEach(update => {
            aliquotsToAdd.push(keyedAliquots[update.aliquotCid]);
            sortedAliquotContainers.push(update);
          });
          try {
            // use a write buffer so that we can backtrack if some wells do not have enough space for additives
            await addAliquotsToAliquotContainers(
              sortedAliquotContainers,
              aliquotsToAdd
            );
          } catch (error) {
            window.toastr.error(
              error.message || "Error adding aliquots to destination wells."
            );
            try {
              await safeDelete(
                "aliquot",
                newAliquots.map(a => a.id)
              );
            } catch (error) {
              console.error("error:", error);
            }
            console.error("error:", error);
            throw new Error("failed");
          }
        }

        const createdPlates = await safeUpsert(
          "containerArray",
          platesToCreate
        );
        if (generateTubeBarcodes && !containerArrayType.isPlate) {
          const tubes = await safeQuery(["aliquotContainer", "id"], {
            variables: {
              filter: {
                containerArrayId: createdPlates.map(p => p.id)
              }
            }
          });
          await addBarcodesToRecords(tubes);
        }
        return {
          containerArrays: uniqBy(updatedPlates.concat(createdPlates), "id")
        };
      } catch (error) {
        console.error("err:", error);
        throw new SubmissionError({
          _error: error.message || "Error creating isolated samples."
        });
      }
    },
    [containerArrayTypes]
  );

  return (
    <StepForm
      toolIntegrationProps={toolIntegrationProps}
      enableReinitialize={isToolIntegrated}
      steps={steps}
      validate={validate}
      toolSchema={toolSchema}
      onSubmit={onSubmit}
      initialValues={initialValues}
    />
  );
};

export default compose(
  withWorkflowInputs(dataTableFragment, {
    initialValueName: "feedbackDataTable"
  }),
  withQuery(containerArrayTypeFragment, {
    showLoading: true,
    isPlural: true
  })
)(ColonyPickingFeedback);
