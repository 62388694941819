/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { InfoHelper } from "@teselagen/ui";
import "./index.css";
// TODO: Check if it can be moved to common src-shared components.
const LabelWithTooltip = ({
  label,
  tooltip,
  icon,
  intent,
  onClick,
  tooltipMargin = 5
}) => (
  <div className="info-sign-container" disabled={!onClick}>
    {label}
    {tooltip && (
      <InfoHelper
        style={{ marginLeft: tooltipMargin }}
        icon={icon}
        intent={intent}
        // ref={} // This can probably be useful for hiding the tooltip with clicked.
        content={
          <div>
            <span>{tooltip}</span>
            {onClick && (
              <>
                <br />
                <br />
                <span className="small">Click icon for more information.</span>
              </>
            )}
          </div>
        }
        size={12}
        onClick={onClick}
      />
    )}
  </div>
);

export default LabelWithTooltip;
