/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { connect } from "react-redux";
import { get, omit, set, sortBy, find } from "lodash";
import { reduxForm, formValueSelector, change } from "redux-form";
import { withRouter } from "react-router-dom";
import store from "../../../../../src-shared/redux/store";
import { compose } from "redux";
import {
  getCustomParametersOfReaction,
  getItemOfType,
  getJ5OutputNamingTemplatesMap,
  getLevelOfCard,
  getOutputCardIdOfReaction,
  isCardRoot,
  isDesignVisualReport,
  treeLayout
} from "../../../../../../tg-iso-design/selectors/designStateSelectors";
import { isDesignLocked } from "../../../../../src-shared/utils/designUtils/isDesignLocked";

import EditNamingTemplateDialog from "../../../Dialogs/EditNamingTemplateDialog";
import SelectRestrictionEnzymesDialog from "../../../../../src-shared/components/Dialogs/SelectRestrictionEnzymesDialog";

import { isReactionErdam } from "../../../../../src-shared/selectors/erdamSelectors";
import getNumberOfCombinationsInReaction from "../../../../../../tg-iso-design/selectors/getNumberOfCombinationsInReaction";
import { DIGEST_ASSEMBLY_METHODS } from "../../../../../../tg-iso-design/constants/assemblyMethods";
import reactionTemplateFragment from "../../../../graphql/fragments/reactionTemplateFragment";
import "./style.css";
import { hideDialog, showDialog } from "../../../../../src-shared/GlobalDialog";
import EditJ5Parameters from "../../../Dialogs/EditJ5Parameters";

import {
  InputField,
  InfoHelper,
  ReactSelectField,
  DialogFooter,
  AsyncValidateFieldSpinner,
  showConfirmationDialog,
  DropdownButton,
  wrapDialog
} from "@teselagen/ui";
import "./style.css";
import {
  MenuItem,
  Menu,
  ButtonGroup,
  Classes,
  Icon,
  Button
} from "@blueprintjs/core";

import { generateFragmentWithFields } from "@teselagen/apollo-methods";
import { getSelectedReactionId } from "../../../../../src-shared/selectors/designViewSelectors";
import actions from "../../../../../src-shared/redux/actions";
import withQuery from "../../../../../src-shared/withQuery";
import AddSplitsDialog from "../../../Dialogs/AddSplitsDialog";
import defaultJ5OutputNamingTemplateMap from "../../../../../../tg-iso-design/constants/defaultJ5OutputNamingTemplateMap";
import {
  safeDelete,
  safeQuery,
  safeUpsert,
  useTgQuery
} from "../../../../../src-shared/apolloMethods";
import { asyncValidateName } from "../../../../../src-shared/utils/formUtils";
import LabelWithTooltip from "../../../../../src-shared/LabelWithTooltip";
import { getDefaultParamsAsCustomJ5ParamName } from "../../../../../../tg-iso-shared/redux/sagas/submitDesignForAssembly/createParameters";
import { disabledAssemblyMethods } from "../../../../../src-shared/utils/disabledAssemblyMethods";

const validate = values => {
  const errors = {};
  const requiredProperties = [
    "outputNamingTemplates.assembledConstructs",
    "outputNamingTemplates.oligoSynthesis",
    "outputNamingTemplates.dnaSynthesis",
    "outputNamingTemplates.pcrReactions"
  ];

  requiredProperties.forEach(property => {
    if (!get(values, property)) set(errors, property, "Required");
  });

  return errors;
};

// These have their own custom logic for Assembly Pieces.
// In order to support AP naming template additional work and refactor
// will have to be done for these methods.
// NOTE: refer to file: `server/src/j5/j5-assembly-methods/index.js` and `processJ5ResponseUSHER.js`
const NOT_SUPPORTED_ASSEMBLY_METHODS_FOR_AP_NAMING_TEMPLATE = [
  "synthesis-and-custom-cloning",
  "USER",
  "Mock"
];

const TYPE_IIS = "TYPE_IIS";

class ReactionPanel extends Component {
  state = {
    selectedRuleSet: null
  };

  enterEditParametersView = () => {
    const {
      assemblyMethod,
      restrictionEnzymes,
      reaction: { id: reactionId, restrictionEnzymeId },
      customJ5Parameter,
      updateReaction,
      readOnly
    } = this.props;

    const restrictionEnzyme = find(
      restrictionEnzymes,
      rEnzyme => rEnzyme.id === restrictionEnzymeId
    );

    showDialog({
      ModalComponent: EditJ5Parameters,
      modalProps: {
        assemblyMethod,
        readOnly,
        restrictionEnzyme,
        customJ5Parameter,
        onSubmit: newJ5Parameters =>
          updateReaction({
            id: reactionId,
            customJ5Parameter: {
              ...newJ5Parameters,
              // Make sure both the original 'id' and 'isLocalToThisDesignId' fields remain the same
              // We are only updating the parameters of the already existing customJ5Parameter record
              // of this reaction.
              isLocalToThisDesignId: customJ5Parameter.isLocalToThisDesignId,
              id: customJ5Parameter.id
            }
          })
      }
    });
  };

  renderNamingTemplate(outputTarget, label) {
    const { outputNamingTemplates, readOnly, hasMultipleReactions, cardLevel } =
      this.props;

    const handleClick = e => {
      if (readOnly) return;
      e.stopPropagation();
      showDialog({
        ModalComponent: EditNamingTemplateDialog,
        modalProps: {
          onSubmit: ({ newTemplate }) => {
            store.dispatch(
              change(
                "reactionPanelForm",
                "outputNamingTemplates." + outputTarget + ".template",
                newTemplate
              )
            );
            this.handleFieldSubmit(
              "outputNamingTemplates." + outputTarget + ".template"
            )(newTemplate);
            hideDialog();
          },
          startingVal: hasMultipleReactions
            ? `${cardLevel} ${outputNamingTemplates[outputTarget].template}`
            : outputNamingTemplates[outputTarget].template
        }
      });
    };
    return (
      <div>
        <InputField
          // containerStyle={{
          //   marginBottom: 3
          // }}
          onKeyDown={handleClick}
          onClick={handleClick}
          name={`outputNamingTemplates.${outputTarget}.template`}
          label={label}
          aria-label="press enter to edit"
          readOnly={true}
          // rightElement={
          //   <Button
          //     minimal
          //     onClick={handleClick}
          //     icon="edit"
          //   ></Button>
          // }
          // onFocus={this.handleTemplateFocus(outputTarget)}
          // onClick={this.handleTemplateCursor(outputTarget)}
          // onKeyDown={this.handleTemplateCursor(outputTarget)}
          // onFieldSubmit={this.handleFieldSubmit(
          //   `outputNamingTemplates.${outputTarget}.template`
          // )}
        />
      </div>
    );
  }

  handleFieldSubmit = fieldName => newValue => {
    const { updateReaction, reaction } = this.props;
    const values = { id: reaction.id };
    set(values, fieldName, newValue);
    updateReaction(values);
  };

  handleAssemblyMethodSubmit = newValue => {
    const {
      updateReaction,
      updateReactionRestrictionEnzyme,
      reaction,
      assemblyMethod,
      formName = "reactionPanelForm"
    } = this.props;
    if (DIGEST_ASSEMBLY_METHODS.includes(newValue?.name)) {
      showDialog({
        ModalComponent: SelectRestrictionEnzymesDialog,
        modalProps: {
          onlyTypeIIS: true,
          onSubmit: restrictionEnzyme => {
            store.dispatch(
              change(formName, "restrictionEnzymeId", restrictionEnzyme.id)
            );
            updateReaction({
              id: reaction.id,
              assemblyMethod: newValue
            });
            updateReactionRestrictionEnzyme({
              id: reaction.id,
              restrictionEnzyme
            });
          },
          onCancel: () => {
            store.dispatch(
              change(formName, "assemblyMethodId", assemblyMethod.id)
            );
          }
        }
      });
    } else {
      this.handleFieldSubmit("assemblyMethod")(newValue);
    }
  };

  render() {
    const {
      isOutputCircular,
      assemblyMethods = [],
      restrictionEnzymes = [],
      outputCardId,
      customJ5Parameter,
      // Props from redux form selector.
      assemblyMethod,
      // Specific props you need to pass:
      reaction, // Can either be an reaction or reaction template
      numCombos,
      readOnly,
      treeLayout,
      designId,
      handleSubmit,
      cardLevel,
      hasMultipleReactions,
      isCardRoot,
      updateReaction,
      initialValues,
      updateReactionRestrictionEnzyme
    } = this.props;

    // const assemblyMethod = assemblyMethods.find(t => t.id === assemblyMethodId);
    const canHavePcr = !isOutputCircular;

    const selectableAssemblyMethods = assemblyMethods.filter(({ name, id }) => {
      if (name === "Contiguous Express Digest") {
        if (
          !this.hasThrownDigestWarning &&
          initialValues?.assemblyMethodId === id
        ) {
          window.toastr.warning(
            "The Contiguous Express Digest assembly method has been deprecated. Please choose a different assembly method. Also note that you can use a Digest Forced Assembly Strategy to achieve a similar functionality."
          );
          this.hasThrownDigestWarning = true;
        }
        return false;
      }
      return (
        name !== "SOE" &&
        name !== "Simple Flanking Homology" &&
        (canHavePcr || name !== "PCR") &&
        (isCardRoot || name !== "Simple Flanking Homology")
      );
    });

    const isDigestAssembly = DIGEST_ASSEMBLY_METHODS.includes(
      get(assemblyMethod, "name")
    );

    const assemblyParamsTooltip = !assemblyMethod
      ? "First select an assembly method"
      : isDigestAssembly && !reaction?.restrictionEnzymeId
        ? "First select a restriction enzyme"
        : "Click to view/edit your assembly parameters. You can also apply any parameter presets you might have.";

    if (!reaction)
      return (
        <div className="reaction-inspector-panel">
          <i>Click on an assembly reaction card to see more details.</i>
        </div>
      );
    return (
      <div className="reaction-inspector-panel">
        <h5 className="inspector-panel-header">
          Assembly Reaction Details{" "}
          {cardLevel && hasMultipleReactions ? (
            <span style={{ fontSize: 13 }}>({cardLevel})</span>
          ) : null}
        </h5>
        <div style={{ marginTop: 10, marginBottom: 15, display: "flex" }}>
          <LabelWithTooltip
            label="Possible Combinations"
            tooltip={`This ignores any filtering of the constructs such as Eugene rules.
              Thus this is an upper bound on the number of constructs.`}
          />{" "}
          :&nbsp;&nbsp;{numCombos}
        </div>

        <form>
          <div>
            <ButtonGroup>
              <DropdownButton
                disabled={readOnly}
                className="preset-dropdown-button"
                menu={
                  <GetPresets
                    updateForm={(key, val) => {
                      store.dispatch(change("reactionPanelForm", key, val));
                    }}
                    currentCustomJ5Parameter={customJ5Parameter}
                    isCardRoot={isCardRoot}
                    reactionId={reaction && reaction.id}
                    updateReaction={updateReaction}
                    updateReactionRestrictionEnzyme={
                      updateReactionRestrictionEnzyme
                    }
                  ></GetPresets>
                }
                text={
                  <LabelWithTooltip
                    label="Load Preset"
                    tooltip="Load the form below with a preset of your choosing."
                    tooltipMargin={10}
                  />
                }
              ></DropdownButton>
              <InfoHelper
                className="tg-save-reaction-preset-btn"
                onClick={handleSubmit(
                  async ({
                    assemblyMethodId,
                    outputNamingTemplates,
                    restrictionEnzymeId
                  }) => {
                    showDialog({
                      ModalComponent: J5SaveReactionPresetDialog,
                      modalProps: {
                        assemblyMethodId,
                        outputNamingTemplates,
                        restrictionEnzymeId,
                        customJ5Parameter: {
                          //get full current j5 parameter set and save it as a new custom j5 parameter set here
                          ...customJ5Parameter,
                          id: undefined,
                          cid: undefined,
                          isLocalToThisDesignId: designId
                        },
                        // customJ5ParameterId: customJ5Parameter.id,
                        model: "reactionTemplate"
                      }
                    });
                  }
                )}
                minimal
                disabled={readOnly}
                content="Save Current Values as Preset"
                isButton
                icon="plus"
              ></InfoHelper>
            </ButtonGroup>
            <br></br>
            <br></br>
            <ReactSelectField
              name="assemblyMethodId"
              disallowClear
              label="Assembly Method"
              options={selectableAssemblyMethods.map(assemblyMethod => ({
                label: assemblyMethod.name,
                value: assemblyMethod.id,
                disabled: assemblyMethod.disabled
              }))}
              disabled={readOnly}
              // disabled={readOnly || isReactionDigest}
              onFieldSubmit={id => {
                this.handleAssemblyMethodSubmit(
                  selectableAssemblyMethods.find(t => t.id === id)
                );
              }}
            />
            {isDigestAssembly && (
              <ReactSelectField
                name="restrictionEnzymeId"
                label="Restriction Enzyme"
                options={restrictionEnzymes.map(restrictionEnzyme => ({
                  label: restrictionEnzyme.name,
                  value: restrictionEnzyme.id
                }))}
                disabled={readOnly}
                onChange={(event, id) => {
                  if (reaction) {
                    updateReactionRestrictionEnzyme({
                      id: reaction.id,
                      restrictionEnzyme: restrictionEnzymes.find(
                        t => t.id === id
                      )
                    });
                  }
                }}
              />
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <Button
                onClick={this.enterEditParametersView}
                style={{ marginBottom: 20 }}
                disabled={
                  // Needs to wait for assemblyMethod and enzyme data to load.
                  !assemblyMethod ||
                  (isDigestAssembly && !reaction?.restrictionEnzymeId)
                }
                text={
                  <LabelWithTooltip
                    label="Assembly Parameters"
                    tooltip={assemblyParamsTooltip}
                    tooltipMargin={10}
                  />
                }
              />
            </div>

            {treeLayout === "classic" ? null : (
              <Button
                onClick={() => {
                  showDialog({
                    ModalComponent: AddSplitsDialog,
                    modalProps: {
                      cardId: outputCardId
                    }
                  });
                }}
              >
                Change Input Grouping
              </Button>
            )}
            <div>
              <br></br>
              <div style={{ paddingBottom: 5 }}>
                <b>Naming Templates </b>
                <InfoHelper
                  noMarginTop
                  isInline
                  content="Use the fields below to customize the naming of the output constructs/pcr reactions/etc."
                ></InfoHelper>{" "}
              </div>

              {this.renderNamingTemplate(
                "assembledConstructs",
                "Assembled Constructs"
              )}
              {this.renderNamingTemplate("oligoSynthesis", "Oligo Synthesis")}
              {this.renderNamingTemplate("dnaSynthesis", "DNA Synthesis")}
              {this.renderNamingTemplate("pcrReactions", "PCR Reactions")}
              {/*
                TODO: Naming templates for assembly pieces is only supported for some
                assembly methods. Mainly those that run by calling j5 in the j5 worker.
              */}
              {!NOT_SUPPORTED_ASSEMBLY_METHODS_FOR_AP_NAMING_TEMPLATE.includes(
                get(assemblyMethod, "name")
              )
                ? this.renderNamingTemplate("assemblyPieces", "Assembly Pieces")
                : null}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const selector = formValueSelector("reactionPanelForm");

export default compose(
  // TODO: Could we instead take the logic of the two connect HOCs into a simplified single one?
  connect(
    state => {
      const reactionId = getSelectedReactionId(state);

      const isVisualReport = isDesignVisualReport(state);
      const isLocked = isDesignLocked(state);
      const readOnly = isVisualReport || isLocked;
      const injected = {
        readOnly,
        treeLayout: treeLayout(state)
      };

      if (reactionId) {
        const reaction = getItemOfType(state, "reaction", reactionId);
        const outputCardId = getOutputCardIdOfReaction(state, reaction.id);
        const cardLevel = getLevelOfCard(state, outputCardId);
        const j5OutputNamingTemplates = getJ5OutputNamingTemplatesMap(
          state,
          reactionId
        );
        const customJ5Parameter = getCustomParametersOfReaction(
          state,
          reactionId
        );
        Object.assign(injected, {
          hasMultipleReactions:
            Object.values(state.design.reaction || {}).length > 1,
          cardLevel, //use cardLevel to get the naming prefix!
          reaction,
          designId: Object.keys(state.design.design)[0],
          outputCardId,
          j5OutputNamingTemplates,
          isReactionDigest: isReactionErdam(state, reactionId),
          numCombos: getNumberOfCombinationsInReaction(state, reactionId),
          isCardRoot: isCardRoot(state, outputCardId),
          customJ5Parameter
        });
      }

      return injected;
    },
    {
      updateReaction: actions.design.updateReaction,
      updateReactionRestrictionEnzyme:
        actions.design.updateReactionRestrictionEnzyme,
      updateViewOptions: actions.ui.designEditor.general.updateViewOptions
    }
  ),
  withRouter,
  withQuery(["assemblyMethod", "cid id  name  description"], {
    isPlural: true
  }),
  withQuery(
    generateFragmentWithFields(
      "restrictionEnzyme",
      `
      id
      name
      sequence
      enzymeTypeCode
      recognitionRegex
      forwardSnipPosition
      reverseSnipPosition
      reverseRecognitionRegex
    `
    ),
    {
      isPlural: true,
      options: () => {
        return {
          variables: {
            filter: {
              enzymeTypeCode: TYPE_IIS,
              // We currently do not support submitting to j5 with TYPE-II restriction enzymes
              // with degenerated recognition sites. So filter them out until we do.
              isRecognitionSiteDegenerate: false
            },
            pageSize: 10000,
            sort: ["name"]
          }
        };
      }
    }
  ),
  connect((state, props) => {
    const {
      reaction = {},
      assemblyMethods = [],
      j5OutputNamingTemplates,
      cardLevel,
      hasMultipleReactions
    } = props;
    // If the design has multiple reaction, this adds the card level to the default template
    // as a default differentiation prefix.
    const getDefaultTemplate = template => {
      return hasMultipleReactions ? `${cardLevel} ${template}` : template;
    };

    const initialValues = {
      assemblyMethodId: reaction.assemblyMethodId,
      restrictionEnzymeId: reaction.restrictionEnzymeId,
      outputNamingTemplates: {
        assembledConstructs: {
          template: get(
            j5OutputNamingTemplates,
            "assembledConstructs.template",
            getDefaultTemplate(
              defaultJ5OutputNamingTemplateMap.assembledConstructs.template
            )
          )
        },
        oligoSynthesis: {
          template: get(
            j5OutputNamingTemplates,
            "oligoSynthesis.template",
            getDefaultTemplate(
              defaultJ5OutputNamingTemplateMap.oligoSynthesis.template
            )
          )
        },
        dnaSynthesis: {
          template: get(
            j5OutputNamingTemplates,
            "dnaSynthesis.template",
            getDefaultTemplate(
              defaultJ5OutputNamingTemplateMap.dnaSynthesis.template
            )
          )
        },
        pcrReactions: {
          template: get(
            j5OutputNamingTemplates,
            "pcrReactions.template",
            getDefaultTemplate(
              defaultJ5OutputNamingTemplateMap.pcrReactions.template
            )
          )
        },
        assemblyPieces: {
          template: get(
            j5OutputNamingTemplates,
            "assemblyPieces.template",
            getDefaultTemplate(
              defaultJ5OutputNamingTemplateMap.assemblyPieces.template
            )
          )
        }
      }
    };

    const toRet = {
      assemblyMethod: assemblyMethods.find(
        t => t.id === selector(state, "assemblyMethodId")
      ),
      outputNamingTemplates: selector(state, "outputNamingTemplates"),
      initialValues,
      assemblyMethods: assemblyMethods.map(assemblyMethod => ({
        ...assemblyMethod,
        disabled: disabledAssemblyMethods.includes(assemblyMethod.name)
      }))
    };
    return toRet;
  }),
  reduxForm({
    form: "reactionPanelForm", // a unique name for this form
    validate,
    enableReinitialize: true
    // keepDirty: true
  })
)(ReactionPanel);

function GetPresets({
  currentCustomJ5Parameter,
  reactionId,
  updateReaction,
  updateReactionRestrictionEnzyme,
  isCardRoot
}) {
  const {
    data: { reactionTemplates: _reactionTemplates },
    ...rest
  } = useTgQuery(["reactionTemplate", "id name assemblyMethod {id name}"], {
    variables: { pageSize: 1000000 }
  });
  if (useTgQuery.checkErrAndLoad(rest))
    return useTgQuery.handleErrAndLoad(rest);

  const reactionTemplates = sortBy(
    _reactionTemplates.filter(
      t => isCardRoot || t.assemblyMethod.name !== "Simple Flanking Homology"
    ),
    "name"
  );

  return (
    <Menu>
      {reactionTemplates.length ? (
        reactionTemplates.map(({ name, id }, i) => {
          return (
            <MenuItem
              onClick={async () => {
                //get the full reaction template, and populate all the fields with it
                const { ...reactionTemplate } = await safeQuery(
                  //destructure reactionTemplate so we can mutate it later without changing the apollo cache
                  reactionTemplateFragment,
                  { variables: { id } }
                );

                if (!reactionTemplate.customJ5Parameter) {
                  window.toastr.warning(
                    "The Assembly Parameter Set associated with this Preset was not found. applying default parameters instead."
                  );
                  //the custom j5 parameter set associated with this reaction template was probably deleted, we'll use the default parameter set instead
                  reactionTemplate.customJ5Parameter =
                    getDefaultParamsAsCustomJ5ParamName();
                }
                reactionTemplate.customJ5Parameter = {
                  ...reactionTemplate.customJ5Parameter,
                  isLocalToThisDesignId:
                    currentCustomJ5Parameter.isLocalToThisDesignId,
                  id: currentCustomJ5Parameter.id
                };
                reactionTemplate.outputNamingTemplates = {};

                [
                  "assembledConstructs",
                  "oligoSynthesis",
                  "dnaSynthesis",
                  "pcrReactions",
                  "assemblyPieces"
                ].forEach(type => {
                  const newTemplate = get(
                    reactionTemplate.reactionTemplateJ5OutputNamingTemplates.find(
                      t => t.j5OutputNamingTemplate.outputTarget === type
                    ),
                    "j5OutputNamingTemplate",
                    defaultJ5OutputNamingTemplateMap[type]
                  );
                  reactionTemplate.outputNamingTemplates[type] = omit(
                    newTemplate,
                    "id"
                  );
                });

                updateReaction({
                  ...omit(
                    reactionTemplate,
                    "reactionTemplateJ5OutputNamingTemplates"
                  ),
                  id: reactionId
                });

                updateReactionRestrictionEnzyme({
                  id: reactionId,
                  restrictionEnzyme: reactionTemplate.restrictionEnzyme
                });

                window.toastr.success(`Preset ${name} Successfully Applied!`);
              }}
              key={i}
              text={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  {name}{" "}
                  <Icon
                    iconSize={12}
                    onClick={async e => {
                      e.stopPropagation();
                      const confirm = await showConfirmationDialog({
                        text: `Are you sure you want to delete the ${name} preset? You cannot undo this action.`,
                        intent: "danger",
                        confirmButtonText: "Delete",
                        cancelButtonText: "Cancel",
                        canEscapeKeyCancel: true
                      });
                      if (confirm) {
                        await safeDelete("reactionTemplate", id);
                        rest.refetch();
                      }
                    }}
                    intent="danger"
                    icon="trash"
                  ></Icon>
                </div>
              }
            />
          );
        })
      ) : (
        <MenuItem disabled text="No Presets Saved Yet..." />
      )}
    </Menu>
  );
}

const J5SaveReactionPresetDialog = compose(
  wrapDialog({ title: "Save New Preset" }),
  reduxForm({
    ...asyncValidateName,
    form: "newReactionPresetName"
  })
)(props => {
  const {
    handleSubmit,
    submitting,
    asyncValidating,
    assemblyMethodId,
    outputNamingTemplates,
    customJ5Parameter,
    restrictionEnzymeId
  } = props;
  return (
    <div>
      <div className={Classes.DIALOG_BODY}>
        <InputField
          rightElement={
            <AsyncValidateFieldSpinner validating={asyncValidating} />
          }
          defaultValue="Untitled Preset"
          name="name"
        ></InputField>
      </div>
      <DialogFooter
        hideModal={hideDialog}
        submitting={submitting}
        onClick={handleSubmit(async ({ name }) => {
          try {
            await safeUpsert("reactionTemplate", {
              name,
              assemblyMethodId: assemblyMethodId,
              customJ5Parameter,
              restrictionEnzymeId: restrictionEnzymeId || null,
              reactionTemplateJ5OutputNamingTemplates: Object.keys(
                outputNamingTemplates
              ).map(outputTarget => {
                return {
                  j5OutputNamingTemplate: {
                    outputTarget,
                    template: outputNamingTemplates[outputTarget].template
                  }
                };
              })
            });

            window.toastr.success("Assembly reaction template saved.");
            hideDialog();
          } catch (e) {
            console.error(e);
            window.toastr.error("Error saving as template.");
            hideDialog();
          }
        })}
      />
    </div>
  );
});
