/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { InputField } from "@teselagen/ui";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import {
  casEnzymeFragment,
  genomeFragment,
  genomeGenomicRegionFragment,
  guideRnaFragment
} from "./fragments.gql";
import type {
  CasEnzymeFragment,
  GenomeFragment,
  GenomeGenomicRegionFragment,
  GuideRnaFragment
} from "./fragments.gql.generated";
import type { RecursiveRequired } from "../../../../../src-shared/typescriptHelpers";
import type QueryBuilder from "tg-client-query-builder";
const fieldsStyle = { minWidth: 450, marginRight: 20 };

const SelectLocationInner = ({
  genome,
  genomeGenomicRegion,
  guideRna
}: {
  genome?: RecursiveRequired<GenomeFragment>;
  genomeGenomicRegion?: RecursiveRequired<GenomeGenomicRegionFragment>;
  guideRna?: RecursiveRequired<GuideRnaFragment>;
}) => (
  <>
    <div className="tg-step-form-section column">
      <div className="tg-flex justify-space-between">
        <HeaderWithHelper
          header="Select name"
          helper="Enter the name of the resulting CRISPR Design"
        />
        <InputField label="Name" name="name" isRequired />
      </div>
    </div>
    <div className="tg-step-form-section column">
      <HeaderWithHelper
        header="Select genome"
        helper="Select the genome you want to perform a knock-in"
      />
      <GenericSelect
        name="genome"
        schema={["name", dateModifiedColumn]}
        fragment={genomeFragment}
        isRequired
        style={fieldsStyle}
        postSelectDTProps={{
          formName: "selectedGenome",
          schema: ["name", dateModifiedColumn]
        }}
        tableParamOptions={{
          additionalFilter: (_props: any, qb: QueryBuilder) => {
            qb.whereAll({
              "microbialMaterials.id": qb.notNull()
            });
          }
        }}
      />
    </div>
    {genome && (
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select Genomic Region"
          helper="Select the genomic region where you want to perform a knock-in"
        />
        <GenericSelect
          nameOverride="Genomic Region"
          name="genomeGenomicRegion"
          schema={[
            {
              displayName: "Name",
              path: "genomicRegion.name"
            },
            {
              displayName: "Size",
              path: "genomicRegion.size"
            },
            dateModifiedColumn
          ]}
          fragment={genomeGenomicRegionFragment}
          tableParamOptions={{
            additionalFilter: (_props: any, qb: QueryBuilder) => {
              qb.whereAll({
                genomeId: genome.id
              });
            }
          }}
          isRequired
          style={fieldsStyle}
          postSelectDTProps={{
            formName: "selectedGenomeGenomicRegion",
            schema: [
              {
                displayName: "Name",
                path: "genomicRegion.name"
              },
              {
                displayName: "Size",
                path: "genomicRegion.size"
              },
              dateModifiedColumn
            ]
          }}
        />
      </div>
    )}
    {genome && genomeGenomicRegion && (
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select guide RNA"
          helper="Select the guide RNA to use. This guide RNA must be associated to the selected genomic region via a guide RNA Score, with an appropiate cut site"
        />
        <GenericSelect
          nameOverride="Guide RNA"
          name="guideRna"
          schema={[
            "name",
            {
              path: "rnaSequenceGuideRnaScores[0].cutSite",
              displayName: "Cut site",
              render: (v: number) => v + 1
            },
            dateModifiedColumn
          ]}
          fragment={guideRnaFragment}
          tableParamOptions={{
            additionalFilter: (_props: any, qb: QueryBuilder) => {
              qb.whereAll({
                sequenceTypeCode: "RNA",
                "rnaType.name": "gRNA",
                "rnaSequenceGuideRnaScores.guideRnaRun.genomicRegionId":
                  genomeGenomicRegion.genomicRegion.id,
                "rnaSequenceGuideRnaScores.guideRnaRun.genomeId": genome.id
              });
            }
          }}
          isRequired
          style={fieldsStyle}
          postSelectDTProps={{
            formName: "selectedGuideRna",
            schema: [
              "name",
              {
                path: "rnaSequenceGuideRnaScores[0].cutSite",
                displayName: "Cut site",
                render: (v: number) => v + 1
              },
              dateModifiedColumn
            ]
          }}
        />
      </div>
    )}
    {guideRna && (
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select cas enzyme"
          helper="Select the cas enzyme to use, it has to be associated to the selected guide RNA"
        />
        <GenericSelect
          nameOverride="Cas Enzyme"
          name="casEnzyme"
          schema={[
            "name",
            {
              path: "scaffoldSequence.sequence",
              displayName: "Scaffold Sequence"
            },
            {
              displayName: "PAM Sequence",
              path: "casEnzymePamSites.pamSite.sequence",
              render: (_v: any, r: CasEnzymeFragment) =>
                r.casEnzymePamSites
                  ?.map(f => {
                    return f.pamSite!.sequence;
                  })
                  .join(", ")
            },
            {
              displayName: "PAM Strand",
              path: "casEnzymePamSites.pamSite.strand",
              render: (_v: any, r: CasEnzymeFragment) =>
                r.casEnzymePamSites
                  ?.map(f => {
                    return f.pamSite!.strand === 1 ? "Positive" : "Negative";
                  })
                  .join(", ")
            },
            dateModifiedColumn
          ]}
          isRequired
          fragment={casEnzymeFragment}
          tableParamOptions={{
            additionalFilter: (_props: any, qb: QueryBuilder) => {
              qb.whereAll({
                reagentEnzymeTypeCode: "CAS",
                "scaffoldSequence.rnaSequences.id": guideRna.id
              });
            }
          }}
          style={fieldsStyle}
          postSelectDTProps={{
            formName: "selectedCasEnzyme",
            schema: [
              "name",
              {
                path: "scaffoldSequence.sequence",
                displayName: "Scaffold Sequence"
              },
              {
                displayName: "PAM Site",
                path: "casEnzymePamSites.pamSite.sequence",
                render: (_v: any, r: CasEnzymeFragment) =>
                  r.casEnzymePamSites
                    ?.map(f => {
                      return f.pamSite!.sequence;
                    })
                    .join(", ")
              },
              {
                displayName: "PAM Strand",
                path: "casEnzymePamSites.pamSite.strand",
                render: (_v: any, r: CasEnzymeFragment) =>
                  r.casEnzymePamSites
                    ?.map(f => {
                      return f.pamSite!.strand === 1 ? "Positive" : "Negative";
                    })
                    .join(", ")
              },
              dateModifiedColumn
            ]
          }}
        />
      </div>
    )}
  </>
);

const SelectLocation = stepFormValues(
  "name",
  "genome",
  "genomeGenomicRegion",
  "casEnzyme",
  "guideRna"
)(SelectLocationInner);

export { SelectLocation };
