/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export const tagFilterMenuFragment = gql`
  fragment tagFilterMenuFragment on tagWithOptionView {
    id
    name
    color
  }
`;
