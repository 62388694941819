/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import AbstractLibrary from "../../../../src-shared/AbstractLibrary";
import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";

import libraryEnhancer from "../../../../src-shared/libraryEnhancer";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import gql from "graphql-tag";
import {
  j5ReportAssemblyHierarchicalColumns,
  linkedWorkflowsColumn
} from "../../../../src-shared/utils/libraryColumns";
import J5ReportRecordView from "../../Record/J5ReportRecordView";
import UploadJ5Report from "./UploadJ5Report";
import { reportLinks } from "../../../../src-shared/SharedJ5ReportRecordView/utils";
import { safeQuery } from "../../../../src-shared/apolloMethods";
import { Button, Icon, Tooltip } from "@blueprintjs/core";

const msgToColor = {
  saving: "#FF8F02",
  Error: "#f54242",
  Completed: "#2ECC71",
  "Completed with warnings": "#ffd56e"
};

const statusColumn = {
  path: "j5ReportStatusMessageView.statusMsg",
  type: "string",
  displayName: "Status",
  render(msg) {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Icon
          style={{ color: msgToColor[msg], marginRight: 5 }}
          icon="full-circle"
          iconSize={10}
        />
        {msg}
      </div>
    );
  }
};

async function showJ5ErrorLogs(report) {
  try {
    const j5LogMessages = await safeQuery(
      [
        "j5LogMessage",
        "id message j5LogMessageTypeCode j5LogMessagePriorityCode j5LogMessageJoins { id specificMsg }"
      ],
      {
        variables: {
          filter: {
            j5ReportId: report.id
          }
        }
      }
    );
    showDialog({
      modalType: "J5_LOGS",
      modalProps: {
        j5LogMessages
      }
    });
  } catch (error) {
    console.error("error:", error);
    window.toastr.error("Error loading report.");
  }
}

function onDoubleClick(record, row, history) {
  history.push(modelNameToLink(record.__typename, record.id));
}

const Library = props => {
  const showUploadFunction = () => {
    showDialog({
      ModalComponent: UploadJ5Report,
      modalProps: {
        uploadCompleted: () => props.data.refetch()
      }
    });
  };

  return (
    <AbstractLibrary
      {...props}
      noNewItem
      onDoubleClick={onDoubleClick}
      withExport
      RecordViewInspector={J5ReportRecordView}
      showUploadFunction={showUploadFunction}
    />
  );
};

const schema = {
  model: "j5Report",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    { path: "assemblyType", type: "string", displayName: "Assembly Type" },
    { path: "assemblyMethod", type: "string", displayName: "Assembly Method" },
    {
      path: "originalDesign.name",
      type: "string",
      displayName: "Design",
      withLink: true
    },
    statusColumn,
    {
      path: "j5ReportStatusMessageView.logCount",
      displayName: "Warnings/Errors",
      type: "number",
      searchDisabled: true,
      getTitleAttr: () => {},
      render(count, j5Report) {
        return (
          <Tooltip
            className="bp3-minimal"
            content="Click to view assembly warnings."
          >
            <Button
              icon="eye-open"
              className="bp3-minimal"
              onClick={async e => {
                e.stopPropagation();
                showJ5ErrorLogs(j5Report);
              }}
            >
              {count}
            </Button>
          </Tooltip>
        );
      }
    },
    ...j5ReportAssemblyHierarchicalColumns,
    linkedWorkflowsColumn("j5ReportWorkflowView"),
    {
      displayName: "Parent Reports",
      filterDisabled: true,
      sortDisabled: true,
      render: (v, r) => {
        const parentReports = r.j5ReportSubsetJ5ReportSubsetJoins.map(
          j => j.parentReport
        );
        return reportLinks(parentReports);
      }
    }
  ]
};

const fragment = gql`
  fragment j5ReportLibraryFragment on j5Report {
    id
    name
    assemblyType
    assemblyMethod
    isHierarchical
    outputCardName
    assemblyBatchId
    idFromOriginalAssemblyBatch
    treePosition
    j5ReportStatusMessageView {
      id
      statusMsg
      logCount
    }
    j5ReportSubsetJ5ReportSubsetJoins {
      id
      parentReport {
        id
        name
      }
    }
    design {
      id
      name
    }
    originalDesign {
      id
      name
    }
    j5LogMessages {
      id
    }
    status
    j5ReportWorkflowView {
      id
      workflowIds
      workflowNames
    }
  }
`;

export default libraryEnhancer({
  schema,
  fragment
})(Library);
