/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useMemo } from "react";
import { Tooltip } from "@blueprintjs/core";
import { CollapsibleCard, DataTable } from "@teselagen/ui";
import { get, pick } from "lodash";
import Linkify from "react-linkify";

import AbstractRecord from "../../../../src-shared/AbstractRecord";
import PriorityView from "../../PriorityView";
import recordViewEnhancer from "../../../../src-shared/recordViewEnhancer";
import jobFragment from "../../../graphql/fragments/jobFragment";
import WorkflowRunJobs from "./WorkflowRunJobs";
import "./style.css";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import CommentCard from "../../../../src-shared/CommentCard";
import WorkQueueJobs from "./WorkQueueJobs";
import { cascadeCustomerRequestTagsToJobsAndWorkflow } from "../CustomerRequestRecordView/utils";
import { formatDate } from "../../../../src-shared/utils/dateUtils";
import MediaQuery from "react-responsive";

const jobUsersSchema = {
  fields: [
    {
      displayName: "Name",
      path: "username",
      render: (username, record) => {
        return <Tooltip content={record.email}>{username}</Tooltip>;
      }
    },
    {
      displayName: "Email",
      path: "email"
    }
  ]
};

const customerRequestColumns = {
  fields: [
    {
      displayName: "Name",
      path: "name"
    },
    {
      displayName: "Request Type",
      path: "requestType"
    },
    {
      displayName: "Due Date",
      type: "timestamp",
      path: "dueDate"
    }
  ]
};

const JobRecordView = props => {
  const { history, job, currentUser, refetchJob, readOnly } = props;
  const onCustomerRequestDoubleClick = useCallback(
    (record /*, rowIndex, event*/) => {
      const route = "/user-requests/" + record.id;
      history.push(route);
    },
    [history]
  );

  const updateShowFunction = useCallback(() => {
    const initialValues = {
      ...pick(job, [
        "id",
        "name",
        "startDate",
        "priorityTypeCode",
        "dueDate",
        "description"
      ]),
      jobUsers: job.jobUsers.map(ju => ju.user),
      customerRequests: job.customerRequestJobs.map(r => r.customerRequest),
      jobOwner: job.jobOwner,
      initialCustomerRequestJobs: job.customerRequestJobs,
      initialJobUsers: job.jobUsers
    };

    showDialog({
      modalType: "LaunchNewJobDialog",
      modalProps: {
        initialValues,
        currentUser,
        refetch: refetchJob
      }
    });
  }, [currentUser, job, refetchJob]);

  const afterTag = async ({ selectedTags }) => {
    await cascadeCustomerRequestTagsToJobsAndWorkflow({
      jobIds: [job.id],
      selectedTags
    });
  };

  const jobUsersData = useMemo(
    () =>
      job.jobUsers.map(jobUser => ({
        id: jobUser.userId,
        username: jobUser.user.username,
        email: jobUser.user.email
      })),
    [job.jobUsers]
  );

  const customerRequests = useMemo(
    () =>
      job.customerRequestJobs.map(customerRequestJob => {
        return customerRequestJob.customerRequest;
      }),
    [job.customerRequestJobs]
  );

  const customerRequestData = useMemo(
    () =>
      job.customerRequestJobs.reduce((acc, customerRequestJob) => {
        if (!customerRequestJob.customerRequest) return acc;
        return acc.concat({
          id: customerRequestJob.customerRequestId,
          name: customerRequestJob.customerRequest.name,
          requestType: customerRequestJob.customerRequest.requestType.name,
          dueDate: customerRequestJob.customerRequest.dueDate
        });
      }, []),
    [job.customerRequestJobs]
  );

  const additionalCards = useMemo(() => {
    const workflowCard = (
      <WorkflowRunJobs key="workflowRuns" jobId={job.id} readOnly={readOnly} />
    );
    const workQueueCard = <WorkQueueJobs key="workQueues" jobId={job.id} />;

    const extraCards = (
      <MediaQuery maxWidth={600}>
        {onMobile => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: onMobile ? "column" : undefined
              }}
              key="extraCards"
            >
              <div style={{ flex: 1 }}>
                <CollapsibleCard
                  title="Team Members"
                  className="job-team-members-section"
                  icon="people"
                >
                  {job.jobUsers.length ? (
                    <DataTable
                      formName="jobUsersTable"
                      isSimple
                      compact
                      style={{ marginBottom: 20 }}
                      schema={jobUsersSchema}
                      entities={jobUsersData}
                    />
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      No assigned team members.
                    </div>
                  )}
                </CollapsibleCard>
                <CollapsibleCard title="User Requests" icon="projects">
                  {customerRequests.length ? (
                    <DataTable
                      formName="customerRequestJobsTable"
                      isSimple
                      compact
                      style={{ marginBottom: 20 }}
                      schema={customerRequestColumns}
                      entities={customerRequestData}
                      onDoubleClick={onCustomerRequestDoubleClick}
                    />
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      Not assigned to any user requests.
                    </div>
                  )}
                </CollapsibleCard>
              </div>
              {!onMobile && <div style={{ width: 15 }} />}
              <div style={{ flex: 1 }}>
                <CommentCard currentUser={currentUser} record={job} />
              </div>
            </div>
          );
        }}
      </MediaQuery>
    );
    return [workflowCard, workQueueCard, extraCards];
  }, [
    currentUser,
    customerRequestData,
    customerRequests.length,
    job,
    jobUsersData,
    onCustomerRequestDoubleClick,
    readOnly
  ]);

  const recordInfo = useMemo(
    () => [
      ["Description", <Linkify key="description">{job.description}</Linkify>],
      ["Job Owner", get(job, "jobOwner.username")],
      ["Priority", <PriorityView key="priority" priority={job.priorityType} />],
      ["Start Date", formatDate(job.startDate)],
      ["Due Date", formatDate(job.dueDate)]
    ],
    [job]
  );

  return (
    <div className="record-container-with-cards">
      <AbstractRecord
        {...props}
        afterTag={afterTag}
        inCard
        recordName="job"
        updateShowFunction={updateShowFunction}
        recordInfo={recordInfo}
        additionalCards={additionalCards}
      />
    </div>
  );
};

export default recordViewEnhancer(jobFragment)(JobRecordView);
