/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Icon } from "@blueprintjs/core";
import { get, identity } from "lodash";
import { getAliquotContainerLocation } from "../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { formatDateTime } from "./dateUtils";

export const recordPreview = props => {
  const { onClick } = props;
  const previewRender = (_, record) => {
    const previewIcon = (
      <Icon
        style={{
          // to keep icons aligned if some have errors and some do not
          float: "right",
          marginRight: 8,
          marginLeft: 8
        }}
        icon="eye-open"
        onClick={() => onClick(record)}
      />
    );
    return <span>{previewIcon}</span>;
  };

  return {
    width: 60,
    noEllipsis: true,
    immovable: true,
    type: "action",
    render: previewRender
  };
};

export const renderJ5RunConstructs = (j5ConstructAssemblyPieces = []) =>
  j5ConstructAssemblyPieces.map(j => get(j, "j5RunConstruct.name")).join(", ");

const processValueForCsv = path =>
  ({
    "dataRowJ5Items[0].j5Item.j5AssemblyPiece.j5ConstructAssemblyPieces":
      renderJ5RunConstructs,
    "dataRowJ5Items[0].j5Item.j5AssemblyPiece.sequence.polynucleotideMaterial.name":
      value => (value ? value : "Not Linked")
  })[path] || identity;

export function getAliquotInfo({ val, row, field }) {
  let aliquotContainerOrContainers = row;
  if (field.renderAliquotContainerLocation && field.pathToAliquotContainer) {
    aliquotContainerOrContainers = get(row, field.pathToAliquotContainer);
    return getAliquotContainerLocation(aliquotContainerOrContainers);
  } else if (field.renderAliquotCount && field.pathToAliquotContainers) {
    aliquotContainerOrContainers = get(row, field.pathToAliquotContainers);
    const aliquots = aliquotContainerOrContainers.filter(ac => ac.aliquot);
    return aliquots.length;
  } else {
    return val;
  }
}

const rowToCsvData = (row, fields) => {
  return fields.map(field => {
    const { path } = field;
    let val;
    if (!path) {
      if (field.renderAliquotCount) {
        val = getAliquotInfo({ row, field });
      }
      return val;
    } else {
      val = processValueForCsv(path)(get(row, path));
      if (path === "createdAt" || path === "updatedAt") {
        val = formatDateTime(val);
      } else if (field.renderAliquotContainerLocation) {
        val = getAliquotInfo({ row, field });
      }
      return val;
    }
  });
};

export function getTableDataToDownload({ fields, entities }) {
  const fieldsToUse = fields.map(field => {
    if (field.renderAliquotContainerLocation) {
      return {
        ...field,
        path: "rowPosition",
        displayName: "Location",
        render: (v, r) => {
          let aliquotContainer = r;
          if (field.pathToAliquotContainer)
            aliquotContainer = get(r, field.pathToAliquotContainer);
          return getAliquotContainerLocation(aliquotContainer);
        }
      };
    } else if (field.renderAliquotCount) {
      return {
        ...field,
        displayName: "Aliquot Count",
        render: (v, r) => {
          let aliquotContainers = r;
          if (field.pathToAliquotContainers) {
            aliquotContainers = get(r, field.pathToAliquotContainers);
          }
          const aliquots = aliquotContainers.filter(ac => ac.aliquot);
          return aliquots.length;
        }
      };
    } else {
      return field;
    }
  });

  const data = [
    fieldsToUse.map(({ displayName }) => displayName),
    ...(entities || []).map(row => rowToCsvData(row, fieldsToUse))
  ];

  return data;
}
