/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { compose, withProps } from "recompose";
import type { TableSchemaField } from "@teselagen/ui";
import { useTableEntities, useTableParams } from "@teselagen/ui";
import { generateFragmentWithFields } from "@teselagen/apollo-methods";
import React, { useMemo } from "react";
import { aliasModels } from "@teselagen/constants";
import {
  tagModels,
  importCollectionModels,
  workflowCollectionModels,
  extendedPropertyModels,
  labModels,
  externalRecordIdentifierModels,
  isDeprecatedModels,
  barcodeModels,
  expirationDateModels
} from "../../tg-iso-shared/constants";
import { useWithLibraryExtendedPropertyColumns } from "./enhancers/withLibraryExtendedPropertyColumns";
import buildAliasFragment from "./fragments/buildAliasFragment";
import {
  generalLibraryColumns,
  importCollectionColumn,
  aliasColumn,
  dateColumns,
  workflowCollectionColumn,
  lockedToCommonColumn,
  nameWithDeprecateColumn,
  projectsColumn,
  barcodeColumn,
  barcodeViewColumn,
  labGroupColumn
} from "./utils/libraryColumns";
import { addTagFilterToQuery } from "./utils/tagUtils";
import { tagColumn } from "./utils/tagColumn";
import { getModelNameFromFragment } from "@teselagen/apollo-methods";
import modelNameToLink from "./utils/modelNameToLink";
import { get } from "lodash";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { combineGqlFragments } from "../../tg-iso-shared/utils/gqlUtils";
import {
  addActiveProjectFilter,
  shouldShowProjects
} from "./utils/projectUtils";
import { projectItemFragment } from "./fragments/projectItemFragment.gql";
import { addLabFilterToQuery } from "./utils/labUtils";
import {
  hasExternalImportIntegration,
  hasIntegration
} from "../../tg-iso-shared/src/utils/integrationTypeSettingsMap/importExportSubtypes";
import { integrationOptionsFragment } from "./fragments/integrationOptionsFragment.gql";
import type { IntegrationOptionsFragment } from "./fragments/integrationOptionsFragment.gql.generated";
import { isAdmin } from "./utils/generalUtils";
import { taggedItemFragment } from "../../tg-iso-shared/src/fragments/taggedItemFragment";
import getFragmentNameFromFragment from "../../tg-iso-shared/src/utils/getFragmentNameFromFragment";
import renderExpirationDate from "./utils/renderExpirationDate";
import { getExternalReferenceColumnsWithRender } from "../../tg-iso-shared/utils/getExternalReferenceColumnsWithRender";
import { getGeneralFragmentFields } from "./utils/getGeneralFragmentFields";
import { DocumentNode } from "graphql";
import {
  transformDataToNamedResults,
  transformQueryDataToNamedData
} from "./withQuery/utils";
import QueryBuilder from "tg-client-query-builder";
import useTgQuery from "./apolloUseTgQuery";
import { QueryOptions } from "./queryOptions";

export const libraryExtendedStringValues = `
  extendedStringValueViews {
    id
    value
    type
    extendedPropertyId
    targetModel
    linkIds
  }
`;

type Schema =
  | {
      model: string;
      fields: TableSchemaField[];
    }
  | TableSchemaField[];

type WithLibraryEnhancerOptions = {
  schema?: Schema;
  formName?: string;
  urlConnected?: boolean; // default true
  withDisplayOptions?: boolean; // default true
  includeLabColumnIfAdmin?: boolean; // default false
  fragment: DocumentNode | string | [string | string];
  withQueryOptions?: QueryOptions;
  updateableModel?: string;
  transformEntities?: (entities: any[]) => any[];
  withSelectedEntities?: boolean; // default true
  integrationSubtype?: string;
  isInfinite?: boolean; // default false
  noAddedBy?: boolean; // default false
  hideCreatedFields?: boolean; // default false
  showIdColumnByDefault?: boolean; // default false
  isCodeModel?: boolean; // default false
  /** Additional filters passed via options will have priority over the ones that come in the props, but these have no access to the props */
  additionalFilter?: (
    /** @deprecated */
    libraryEnhancerOptions: any,
    qb: QueryBuilder,
    currentParams: any
  ) => void;
  /** Additional or filters passed via options will have priority over the ones that come in the props, but these have no access to the props */
  additionalOrFilter?: (
    /** @deprecated */
    libraryEnhancerOptions: any,
    qb: QueryBuilder,
    currentParams: any
  ) => void;
};

type UseLibraryEnhancerOptions = WithLibraryEnhancerOptions & {
  history: RouteComponentProps<any>["history"];
  schema: Schema;
};

export const useLibraryEnhancer = <
  TResultName extends string,
  TResultData = any
>(
  options: UseLibraryEnhancerOptions
) => {
  const {
    schema: partialSchema, // Doesn't include extended properties
    formName,
    additionalFilter,
    additionalOrFilter,
    urlConnected,
    withDisplayOptions,
    fragment,
    withQueryOptions,
    updateableModel,
    model,
    withExtendedProperties,
    transformEntities,
    withSelectedEntities,
    isInfinite,
    withIntegration,
    withExternalImportIntegration
  } = useMemo(
    () =>
      getLibraryData({
        schema: options.schema,
        formName: options.formName,
        additionalFilter: options.additionalFilter,
        additionalOrFilter: options.additionalOrFilter,
        urlConnected: options.urlConnected,
        withDisplayOptions: options.withDisplayOptions,
        includeLabColumnIfAdmin: options.includeLabColumnIfAdmin,
        fragment: options.fragment,
        withQueryOptions: options.withQueryOptions,
        updateableModel: options.updateableModel,
        transformEntities: options.transformEntities,
        withSelectedEntities: options.withSelectedEntities,
        integrationSubtype: options.integrationSubtype,
        isInfinite: options.isInfinite,
        noAddedBy: options.noAddedBy,
        hideCreatedFields: options.hideCreatedFields,
        showIdColumnByDefault: options.showIdColumnByDefault
      }),
    [
      options.schema,
      options.formName,
      options.additionalFilter,
      options.additionalOrFilter,
      options.urlConnected,
      options.withDisplayOptions,
      options.includeLabColumnIfAdmin,
      options.fragment,
      options.withQueryOptions,
      options.updateableModel,
      options.transformEntities,
      options.withSelectedEntities,
      options.integrationSubtype,
      options.isInfinite,
      options.noAddedBy,
      options.hideCreatedFields,
      options.showIdColumnByDefault
    ]
  );

  const { selectTableEntities } = useTableEntities(formName);

  // Extended properties, if needed it modifies the schema
  const {
    schema,
    hasExtendedProperties,
    loading: extendedPropertiesLoading,
    error: extendedPropertiesError
  } = useWithLibraryExtendedPropertyColumns({
    schema: partialSchema,
    model,
    updateableModel,
    skip: !withExtendedProperties
  });

  const withLibraryExtendedPropertiesPropsToPass = useMemo(() => {
    if (withExtendedProperties) {
      return {
        hasExtendedProperties,
        extendedPropertiesLoading,
        extendedPropertiesError
      };
    }
    return {};
  }, [
    extendedPropertiesError,
    extendedPropertiesLoading,
    hasExtendedProperties,
    withExtendedProperties
  ]);

  // External integrations
  const {
    nameToUse: integrationsNameToUse,
    queryNameToUse: integrationsQueryNameToUse,
    totalResults: integrationsTotalResults,
    data: integrationsData,
    ...integrationsQueryResult
  } = useTgQuery<"integrations", IntegrationOptionsFragment[]>(
    integrationOptionsFragment,
    {
      isPlural: true,
      skip: !withIntegration,
      variables: {
        filter: {
          integrationTypeCode: ["CUSTOM_INFO", "UPDATE", "EXPORT"],
          subtype: withIntegration?.key
        }
      }
    }
  );

  const withIntegrationPropsToPass = useMemo(() => {
    if (withIntegration) {
      const integrationsWithQueryLikeResultsData =
        transformQueryDataToNamedData({
          queryResultsWithoutData: integrationsQueryResult,
          nameToUse: integrationsNameToUse,
          results: integrationsData?.integrations,
          totalResults: integrationsTotalResults
        });

      return transformDataToNamedResults({
        nameToUse: integrationsNameToUse,
        queryNameToUse: integrationsQueryNameToUse,
        data: integrationsWithQueryLikeResultsData
      });
    }
    return {};
  }, [
    integrationsData?.integrations,
    integrationsNameToUse,
    integrationsQueryNameToUse,
    integrationsQueryResult,
    integrationsTotalResults,
    withIntegration
  ]);

  const {
    tableParams: _tableParams,
    variables,
    ...useTableParamsRest
  } = useTableParams({
    history: options.history,
    schema,
    urlConnected,
    formName,
    withDisplayOptions,
    defaults: {
      order: ["-modified"]
    },
    withSelectedEntities,
    additionalFilter,
    additionalOrFilter,
    isCodeModel: options.isCodeModel
  });
  // Main query, we want the data object in the props to include these results
  const {
    nameToUse,
    queryNameToUse,
    totalResults,
    data,
    ...queryResultWithoutData
  } = useTgQuery<TResultName, TResultData>(fragment, {
    isPlural: true,
    variables,
    ...withQueryOptions,
    ...(isInfinite ? { pageSize: 999999 } : {})
  });

  const withQueryLikeResultsData = useMemo(
    () =>
      transformQueryDataToNamedData({
        queryResultsWithoutData: queryResultWithoutData,
        nameToUse,
        results: data[nameToUse as TResultName],
        totalResults
      }),
    [data, nameToUse, queryResultWithoutData, totalResults]
  );

  const tableParams = useMemo(() => {
    let tableParams = {
      ..._tableParams,
      isLoading:
        queryResultWithoutData.loading ||
        extendedPropertiesLoading ||
        integrationsQueryResult.loading,
      entities: queryResultWithoutData.entities,
      entityCount: totalResults,
      onRefresh: queryResultWithoutData.refetch,
      variables,
      fragment,
      transformEntities,
      updateableModel,
      isInfinite
    };
    if (transformEntities && tableParams.entities) {
      tableParams = {
        ...tableParams,
        entities: transformEntities(tableParams.entities)
      };
    }

    return tableParams;
  }, [
    _tableParams,
    extendedPropertiesLoading,
    fragment,
    integrationsQueryResult.loading,
    isInfinite,
    queryResultWithoutData.entities,
    queryResultWithoutData.loading,
    queryResultWithoutData.refetch,
    totalResults,
    transformEntities,
    updateableModel,
    variables
  ]);

  const propsToPass = useMemo(() => {
    const propsToPass = {
      model: updateableModel,
      isLibraryTable: true,
      withIntegration,
      withExternalImportIntegration,
      libraryFragment: fragment,
      integrationSubtype: withIntegration && withIntegration.key,
      selectTableRecords: (ids: string[]) =>
        selectTableEntities(ids.map(id => ({ id }))),
      fragment,
      refetch: tableParams.onRefresh,
      tableParams,
      variables,
      ...useTableParamsRest,
      // All the results from the queries
      data: withQueryLikeResultsData,
      [nameToUse]: withQueryLikeResultsData,
      ...transformDataToNamedResults({
        nameToUse,
        queryNameToUse,
        data: withQueryLikeResultsData
      }),
      ...withLibraryExtendedPropertiesPropsToPass,
      ...withIntegrationPropsToPass,
      schema
    };

    return propsToPass;
  }, [
    fragment,
    nameToUse,
    queryNameToUse,
    schema,
    selectTableEntities,
    tableParams,
    updateableModel,
    useTableParamsRest,
    variables,
    withExternalImportIntegration,
    withIntegration,
    withIntegrationPropsToPass,
    withLibraryExtendedPropertiesPropsToPass,
    withQueryLikeResultsData
  ]);

  return propsToPass;
};

function getLibraryData(
  options: Pick<
    UseLibraryEnhancerOptions,
    | "schema"
    | "formName"
    | "additionalFilter"
    | "additionalOrFilter"
    | "urlConnected"
    | "withDisplayOptions"
    | "includeLabColumnIfAdmin"
    | "fragment"
    | "withQueryOptions"
    | "updateableModel"
    | "transformEntities"
    | "withSelectedEntities"
    | "integrationSubtype"
    | "isInfinite"
    | "noAddedBy"
    | "hideCreatedFields"
    | "showIdColumnByDefault"
  >
) {
  const {
    schema: partialSchema,
    formName: _formName,
    additionalFilter: passedAdditionalFilter,
    additionalOrFilter,
    urlConnected = true,
    withDisplayOptions = true,
    includeLabColumnIfAdmin,
    fragment: _fragment,
    withQueryOptions,
    updateableModel: _updateableModel,
    transformEntities: _passedTransformEntities,
    withSelectedEntities = true,
    integrationSubtype,
    isInfinite
  } = options;

  if (!partialSchema) {
    console.error("schema not passed to libraryEnhancer.");
  }

  let fragment = _fragment;
  if (Array.isArray(fragment)) {
    fragment = generateFragmentWithFields(...fragment);
  }
  const model = getModelNameFromFragment(fragment);
  const updateableModel = _updateableModel || model;
  const withTags = tagModels.includes(updateableModel);
  const withIntegration = hasIntegration(
    updateableModel,
    integrationSubtype
  ) as any;
  const withExternalImportIntegration = hasExternalImportIntegration(
    updateableModel,
    integrationSubtype
  );
  const withAliases = aliasModels.includes(updateableModel);
  const withExtendedProperties =
    extendedPropertyModels.includes(updateableModel);
  const withProjects = shouldShowProjects(updateableModel);
  let transformEntities = _passedTransformEntities;
  if (_updateableModel && !_passedTransformEntities) {
    transformEntities = (entities = []) => {
      return entities.map(e => {
        const actualRecord = e[_updateableModel];
        if (!actualRecord) {
          // tgreen: weird test failure here
          console.error(
            "array library entity was missing updateable model:",
            e
          );
        }
        const transformed = {
          ...e,
          ...actualRecord
        };
        return transformed;
      });
    };
  }

  const isUsingView = !!updateableModel && updateableModel !== model;

  const wrapWithNestModel = (str: string) => {
    if (isUsingView) {
      return ` ${updateableModel} { id ${str} } `;
    } else {
      return str;
    }
  };

  let additionalFragment = getGeneralFragmentFields(model, {
    ...options,
    isUsingView,
    wrapWithNestModel
  });

  if (withExtendedProperties) {
    additionalFragment += wrapWithNestModel(libraryExtendedStringValues);
  }

  const allFragments = [fragment];
  if (withTags) {
    additionalFragment += wrapWithNestModel(`
      taggedItems {
        ...taggedItemFragment
      }
    `);
    allFragments.push(taggedItemFragment);
  }
  if (withProjects) {
    additionalFragment += wrapWithNestModel(`
      projectItems {
        ...projectItemFragment
      }
    `);
    allFragments.push(projectItemFragment);
  }
  if (withAliases) {
    const aliasFragmentToUse = buildAliasFragment;
    additionalFragment += wrapWithNestModel(`
      aliases {
        ...${getFragmentNameFromFragment(aliasFragmentToUse)}
      }
    `);
    allFragments.push(aliasFragmentToUse);
  }

  allFragments.push(additionalFragment);
  fragment = combineGqlFragments(allFragments);

  const schema = getSchema(partialSchema, model, {
    noAddedBy: !!options.noAddedBy,
    hideCreatedFields: !!options.hideCreatedFields,
    showIdColumnByDefault: !!options.showIdColumnByDefault,
    isUsingView,
    updateableModel,
    includeLabColumnIfAdmin: !!includeLabColumnIfAdmin
  });

  const additionalFilter = (
    ...args: [props: any, qb: QueryBuilder, currentParams: any]
  ) => {
    // TODO-additionalFilter remove props
    const [props, qb, currentParams] = args;

    if (withTags) {
      let tagOptions;
      if (updateableModel !== model) {
        tagOptions = {
          pathToTaggedItems: `${updateableModel}.taggedItems`
        };
      }
      addTagFilterToQuery(currentParams.tags, qb, tagOptions);
    }

    if (withProjects) {
      let pathToProjectId;
      if (updateableModel !== model) {
        pathToProjectId = `${updateableModel}.projectItems.projectId`;
      }
      addActiveProjectFilter(qb, {
        pathToProjectId,
        model: updateableModel,
        isUsingView
      });
    }

    addLabFilterToQuery(currentParams.labFilter, qb);

    if (isDeprecatedModels.includes(model)) {
      if (!currentParams.showDeprecated) {
        qb.whereAll({
          isDeprecated: false
        });
      }
    }
    if (passedAdditionalFilter) {
      passedAdditionalFilter(...args);
    }

    // TODO-additionalFilter remove props
    if (props.embeddedLibraryFilter) {
      props.embeddedLibraryFilter({ model, updateableModel }, ...args);
    }
  };

  const formName = _formName || `${updateableModel}LibraryTable`;

  return {
    schema,
    formName,
    additionalFilter,
    additionalOrFilter,
    urlConnected,
    withDisplayOptions,
    includeLabColumnIfAdmin,
    fragment,
    withQueryOptions,
    model,
    updateableModel,
    withExtendedProperties,
    transformEntities,
    withSelectedEntities,
    integrationSubtype,
    isInfinite,
    noAddedBy: !!options.noAddedBy,
    hideCreatedFields: !!options.hideCreatedFields,
    showIdColumnByDefault: !!options.showIdColumnByDefault,
    withIntegration,
    withExternalImportIntegration
  };
}

function getSchema(
  partialSchema: Schema,
  model: string,
  options: {
    noAddedBy: boolean;
    hideCreatedFields: boolean;
    updateableModel: string;
    showIdColumnByDefault: boolean;
    isUsingView: boolean;
    includeLabColumnIfAdmin: boolean;
  }
) {
  const {
    noAddedBy,
    hideCreatedFields,
    updateableModel,
    showIdColumnByDefault = false,
    isUsingView,
    includeLabColumnIfAdmin
  } = options;
  let schema = partialSchema;
  let fields;
  if (Array.isArray(schema)) {
    fields = [...schema];
    schema = { model: updateableModel, fields };
  } else {
    schema = {
      ...schema,
      fields: [...schema.fields]
    };
    fields = schema.fields;
  }

  if (barcodeModels.includes(updateableModel)) {
    const col = isUsingView ? barcodeViewColumn : barcodeColumn;
    fields.splice(1, 0, col);
  }
  // should go after name column
  if (aliasModels.includes(updateableModel)) {
    fields.splice(1, 0, aliasColumn);
  }

  if (isDeprecatedModels.includes(updateableModel)) {
    fields.unshift(nameWithDeprecateColumn);
  }

  fields.unshift({
    displayName: "ID",
    type: "string",
    path: "id",
    isHidden: !showIdColumnByDefault
  });

  if (labModels.includes(updateableModel)) {
    fields.unshift(lockedToCommonColumn);
  }

  if (expirationDateModels.includes(model)) {
    fields.push({
      path: "expirationDate",
      type: "timestamp",
      displayName: "Expiration",
      render: v => {
        return renderExpirationDate(v);
      }
    });
  }

  if (tagModels.includes(updateableModel)) {
    fields.push(tagColumn);
  }

  if (shouldShowProjects(updateableModel)) {
    let projectsColumnToUse = projectsColumn;
    if (model !== updateableModel) {
      projectsColumnToUse = {
        ...projectsColumnToUse,
        path: `${updateableModel}.${projectsColumnToUse.path}`
      };
    }
    fields.push(projectsColumnToUse);
  }

  if (importCollectionModels.includes(updateableModel)) {
    fields.push(importCollectionColumn);
  }
  if (workflowCollectionModels.includes(updateableModel)) {
    fields.push(workflowCollectionColumn);
  }
  // do not show the normal lab group column in single lab mode
  if (labModels.includes(updateableModel)) {
    fields.push({
      ...labGroupColumn,
      isHidden: true
    });
  }

  if (externalRecordIdentifierModels.includes(updateableModel)) {
    fields.push(
      ...getExternalReferenceColumnsWithRender({
        prefix: isUsingView ? updateableModel : ""
      })
    );
  }

  if (noAddedBy) {
    fields.push(...dateColumns);
  } else {
    if (isUsingView) {
      fields.push(
        ...generalLibraryColumns.filter(col => col.displayName !== "Updated By")
      );
    } else {
      fields.push(...generalLibraryColumns);
    }
  }
  fields = fields.map(f => {
    // simple string fields will be handled in @teselagen/ui
    if (typeof f === "string") return f;
    const toRet = { ...f };
    if (hideCreatedFields) {
      if (toRet.path === `createdAt` || toRet.path === "updatedAt") {
        toRet.isHidden = true;
      }
    }
    if (toRet.withLink) {
      toRet.getClipboardData = v => v;
      toRet.render = (v, r) => {
        if (v) {
          return (
            <Link
              to={modelNameToLink(get(r, toRet.path!.replace(".name", "")))}
            >
              {v}
            </Link>
          );
        }
        return;
      };
    }
    return toRet;
  });
  schema.fields = fields;

  if (includeLabColumnIfAdmin && isAdmin()) {
    const labColumn = schema.fields.find(col => col.path === "lab.name");
    if (labColumn) {
      labColumn.isHidden = false;
    }
  }

  return schema;
}

const libraryEnhancer = <TResultName extends string, TResultData = any>(
  topLevelOptions: WithLibraryEnhancerOptions
) =>
  compose(
    withRouter,
    withProps((props: any) => {
      const passedSchema = topLevelOptions.schema ?? props.schema;
      /** Take into account filters passed via the props, which can have access to the props */
      const additionalFilter =
        topLevelOptions.additionalFilter ?? props.additionalFilter;
      const additionalOrFilter =
        topLevelOptions.additionalOrFilter ?? props.additionalOrFilter;

      const propsToPass = useLibraryEnhancer<TResultName, TResultData>({
        ...props,
        ...topLevelOptions,
        schema: passedSchema,
        history: props.history,
        additionalFilter,
        additionalOrFilter
      });

      return propsToPass;
    })
  );

export default libraryEnhancer;
