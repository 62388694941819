/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export const reactionsWithTempInfoFragment = gql`
  fragment reactionsWithTempInfoFragment on reaction {
    id
    reactionOutputs {
      id
      outputMaterialId
    }
    reactionDetails
  }
`;
