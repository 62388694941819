/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import { compose } from "recompose";
import { DataTable, InfoHelper, withSelectedEntities } from "@teselagen/ui";
import Helmet from "react-helmet";
import { MenuItem, Callout } from "@blueprintjs/core";
import AbstractLibrary from "../AbstractLibrary";
import { isAdmin } from "../utils/generalUtils";
import { showDialog } from "../GlobalDialog";
import AdminCallout from "../SharedAppSettings/AdminCallout";
import gql from "graphql-tag";
import { deleteRecordsHelper } from "../utils/deleteUtils";
import libraryEnhancer from "../libraryEnhancer";
import useTgQuery from "../apolloUseTgQuery";
import { formatDate } from "../utils/dateUtils";
import { Link } from "react-router-dom";
import modelNameToLink from "../utils/modelNameToLink";
import modelNameToReadableName from "../utils/modelNameToReadableName";

const PublicationManagementPanel = props => {
  const {
    selectedEntities = [],
    refetchPublicationTargets,
    selectTableRecords,
    readOnly
  } = props;

  const refetch = async () => {
    await refetchPublicationTargets();
    if (selectedEntities.length) {
      selectTableRecords([]);
      selectTableRecords([selectedEntities[0].id]);
    }
  };

  const openEditWindow = record => {
    showDialog({
      modalType: "CREATE_PUBLICATION_TARGET",
      modalProps: {
        refetch,
        initialValues: record
      }
    });
  };

  const showCreatePublicationTarget = () => {
    if (isAdmin()) {
      showDialog({
        modalType: "CREATE_PUBLICATION_TARGET",
        modalProps: { refetch }
      });
    } else {
      return null;
    }
  };

  const contextMenu = ({ selectedRecords }) => {
    if (readOnly) return;
    const menuItems = [];
    const deleteRecords = () =>
      deleteRecordsHelper({
        records: selectedRecords,
        refetch
      });

    const deleteMenuItem = (
      <MenuItem
        key="delete"
        icon="trash"
        onClick={deleteRecords}
        text="Delete"
      />
    );
    if (selectedRecords.length === 1) {
      const record = selectedRecords[0];
      menuItems.push(
        <MenuItem
          key="edit"
          icon="edit"
          onClick={() => openEditWindow(record)}
          text="Edit"
        />
      );
    }
    menuItems.push(deleteMenuItem);
    return menuItems;
  };

  const callOut = useMemo(() => {
    if (isAdmin()) {
      return (
        <AdminCallout>
          As an admin you can create, delete and edit publication targets.
        </AdminCallout>
      );
    }
    return (
      <Callout intent="primary" title="You are not an admin">
        Only an admin can add, delete and edit publication targets from this
        screen.
      </Callout>
    );
  }, []);

  return (
    <div className="tg-card">
      <Helmet title="Publication Targets" />
      {callOut}
      <AbstractLibrary
        {...props}
        alwaysShowInspector
        ViewerComponent={ViewerComponent}
        noRoute
        contextMenu={contextMenu}
        libraryName="publicationTarget"
        noNewItem={!isAdmin()}
        onNewItemClick={showCreatePublicationTarget}
      />
    </div>
  );
};

const viewerFragment = gql`
  fragment publicationManagementViewerFragment on publicationTarget {
    id
    publicationEvents {
      id
      publicationAt
      publisher {
        id
        username
      }
      publishedEntities {
        id
        design {
          id
          name
        }
        sequence {
          id
          name
        }
      }
    }
  }
`;

const viewerComponentPublicationEntitiesTableSchema = [
  {
    displayName: "Name",
    render: (v, r) => <Link to={modelNameToLink(r)}>{r.name}</Link>
  }
];

const publicationsEventsSchema = [
  {
    displayName: "Publisher",
    path: "publisher.username"
  },
  {
    displayName: "Published At",
    render: (_, { publicationAt }) => formatDate(publicationAt)
  }
];

const ViewerComponent = withSelectedEntities("publicationEvents")(({
  record,
  publicationEventsSelectedEntities = []
}) => {
  const {
    data: { publicationTarget },
    ...rest
  } = useTgQuery(viewerFragment, {
    variables: {
      id: record.id
    }
  });
  if (useTgQuery.checkErrAndLoad(rest)) {
    return useTgQuery.handleErrAndLoad(rest);
  }
  const selectedPublicationEvent = publicationEventsSelectedEntities[0];

  const makePublicationEntitiesTable = model => {
    if (!selectedPublicationEvent) return null;
    return (
      <>
        <h6 style={{ marginTop: 10 }}>
          {modelNameToReadableName(model, { upperCase: true, plural: true })}
        </h6>
        <DataTable
          className="tg-no-min-height-table"
          isSimple
          doNotShowEmptyRows
          noForm
          schema={viewerComponentPublicationEntitiesTableSchema}
          entities={selectedPublicationEvent.publishedEntities
            .filter(e => e[model])
            .map(e => e[model])}
        />
      </>
    );
  };

  return (
    <div style={{ padding: 10 }}>
      <h6>
        <InfoHelper
          style={{ marginRight: 5 }}
          content={`Click an event to view its published sequences and designs.`}
          isInline
        />
        Publication Events
      </h6>

      <DataTable
        className="tg-no-min-height-table"
        isSimple
        doNotShowEmptyRows
        isSingleSelect
        formName="publicationEvents"
        schema={publicationsEventsSchema}
        entities={publicationTarget.publicationEvents}
      />
      {makePublicationEntitiesTable("sequence")}
      {makePublicationEntitiesTable("design")}
    </div>
  );
});

const schema = {
  model: "publicationTarget",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    { path: "authKey", type: "string", displayName: "Service Account" } // TODO: we need to generate and use AUTH KEY's rather than the actual account
  ]
};

const fragment = gql`
  fragment publicationTargetFragment on publicationTarget {
    id
    name
    baseUrl
    authKey
    authToken
    createdAt
    updatedAt
  }
`;

export default compose(
  libraryEnhancer({
    fragment,
    schema,
    withSelectedEntities: true,
    noAddedBy: true
  })
)(PublicationManagementPanel);
