/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import StepForm from "../../../../src-shared/StepForm";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import UpdatePlates, {
  plateFragment,
  tableFragment,
  tubeFragment
} from "./UploadCsv";
import { withProps } from "recompose";
import { onSubmit } from "./onSubmit";

const UpdatePlateTool = ({
  toolIntegrationProps,
  toolSchema,
  isToolIntegrated,
  initialValues
}) => (
  <StepForm
    toolIntegrationProps={toolIntegrationProps}
    enableReinitialize={isToolIntegrated}
    steps={[
      {
        title: "Update Plates",
        Component: UpdatePlates,
        withCustomFooter: true
      }
    ]}
    validate={values => {
      const errors = {};
      if (
        values.dataTable &&
        values.dataTable.dataTableTypeCode !== "UPDATE_PLATES_TOOL"
      ) {
        errors.dataTable = "Incorrect table type.";
      }
      if (
        values.updateType === "Tube Barcodes" &&
        values.containerArrays?.some(c => c.containerArrayType.isPlate)
      ) {
        errors.updateType = "Cannot update tube barcodes on plates.";
      }
      if (values.aliquotContainers?.length) {
        const uniqBarcodes = [];
        const invalidBarcode = values.aliquotContainers.some(c => {
          const barcode = c.barcode?.barcodeString;
          if (uniqBarcodes.includes(barcode)) {
            return true;
          }
          if (!barcode) {
            return true;
          }
          uniqBarcodes.push(barcode);
          return false;
        });
        if (invalidBarcode) {
          errors.aliquotContainers =
            "All selected tubes must have a unique barcode.";
        }
      }
      return errors;
    }}
    toolSchema={toolSchema}
    onSubmit={onSubmit}
    initialValues={initialValues}
  />
);

export default compose(
  withWorkflowInputs(plateFragment),
  withWorkflowInputs(tubeFragment),
  withWorkflowInputs(tableFragment, {
    singular: true
  }),
  withProps(({ initialValues: _initialValues }) => {
    let initialValues = _initialValues;
    if (initialValues?.aliquotContainers) {
      initialValues = {
        ...initialValues,
        plateOrTube: "tube"
      };
    }
    if (initialValues && initialValues.dataTable) {
      initialValues = {
        ...initialValues,
        selectTable: true
      };
    }
    return {
      initialValues
    };
  })
)(UpdatePlateTool);
