/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo, useRef } from "react";
import { SubmissionError } from "redux-form";
import StepForm from "../../../../src-shared/StepForm";
import SourceMaterials from "./SourceMaterials";
import SelectPlates, {
  worklistPlanningContainerArrayTypeFragment
} from "./SelectInputs";
import ReviewWorklist from "../AliquotRearrayTool/Steps/ReviewWorklist";
import { every, forEach, set, isEmpty } from "lodash";
import {
  worklistPlanningReactionMapFragment,
  worklistPlanningPlateFragment,
  worklistPlanningTubeFragment,
  worklistPlanningDataTableFragment
} from "./fragments";
import shortid from "shortid";
import { isValidPositiveNumber } from "../../../../src-shared/utils/formUtils";
import {
  safeQuery,
  safeUpsert,
  useTgQuery
} from "../../../../src-shared/apolloMethods";
import { isValidNumOfReactions } from "./utils";
import { generateEmptyWells } from "../../../../../tg-iso-lims/src/utils/plateUtils";
import { addBarcodesToRecords } from "../../../../../tg-iso-lims/src/utils/barcodeUtils";
import { Loading } from "@teselagen/ui";

const onSubmit = async values => {
  const {
    destinationPlates,
    worklistName,
    worklist,
    usedReactionMapIds = []
  } = values;
  try {
    if (destinationPlates[0].id.includes("__")) {
      const isRack = !destinationPlates[0].containerArrayType.isPlate;
      const newPlates = await safeUpsert(
        "containerArray",
        destinationPlates.map(p => {
          const cleanedPlate = { ...p };
          delete cleanedPlate.id;
          cleanedPlate.aliquotContainers = cleanedPlate.aliquotContainers.map(
            ac => {
              const cleanedAc = { ...ac };
              delete ac.id;
              return cleanedAc;
            }
          );
          cleanedPlate.containerArrayTypeId = p.containerArrayType.id;
          delete cleanedPlate.containerArrayType;
          return cleanedPlate;
        })
      );
      await addBarcodesToRecords(newPlates);
      if (isRack) {
        const newTubes = await safeQuery(["aliquotContainer", "id"], {
          variables: {
            filter: {
              containerArrayId: newPlates.map(p => p.id)
            }
          }
        });
        await addBarcodesToRecords(newTubes);
      }
    }
    const worklistToUpsert = {
      name: worklistName,
      worklistTransfers: worklist.worklistTransfers.map(t => {
        const cleanedTransfer = { ...t };
        delete cleanedTransfer.sourceAliquotContainer;
        delete cleanedTransfer.destinationAliquotContainer;
        delete cleanedTransfer.destinationPlateName;
        return cleanedTransfer;
      })
    };

    // link to reaction map if completed at least one reaction
    if (usedReactionMapIds.length) {
      worklistToUpsert.worklistReactionMaps = usedReactionMapIds.map(id => {
        return {
          reactionMapId: id
        };
      });
    }
    const [newWorklist] = await safeUpsert("worklist", worklistToUpsert);
    return {
      worklist: newWorklist
    };
  } catch (error) {
    console.error("error:", error);
    throw new SubmissionError({
      _error: error.message || "Error generating worklist."
    });
  }
};

const validate = values => {
  const {
    destinationType,
    sourceTubes = [],
    sourcePlates = [],
    sourceDataTables = [],
    destinationPlates = [],
    plateToReactionMap = {},
    reagentTransferSettings = {},
    materialTransferSettings = {},
    numberOfReactionsPerWell,
    enableMultipleReactions
  } = values;
  const errors = {};

  if (!sourceTubes.length && !sourcePlates.length && !sourceDataTables.length) {
    errors.sourcePlates =
      errors.sourceTubes =
      errors.sourceDataTables =
        "Please select source plates, tubes, or data tables.";
  }
  const sourcePlateIds = sourcePlates.map(c => c.id);
  if (
    destinationType === "Existing Plates" &&
    destinationPlates.some(p => sourcePlateIds.includes(p.id))
  ) {
    errors.sourcePlates = "Source plate cannot be a destination plate.";
  }

  if (!isValidPositiveNumber(reagentTransferSettings.transferVolume)) {
    set(
      errors,
      "reagentTransferSettings.transferVolume",
      "Please enter a valid transfer volume."
    );
  }
  if (!isValidPositiveNumber(reagentTransferSettings.transferMass)) {
    set(
      errors,
      "reagentTransferSettings.transferMass",
      "Please enter a valid transfer mass."
    );
  }
  if (!isValidPositiveNumber(materialTransferSettings.transferVolume)) {
    set(
      errors,
      "materialTransferSettings.transferVolume",
      "Please enter a valid transfer volume."
    );
  }
  if (!isValidPositiveNumber(materialTransferSettings.transferMass)) {
    set(
      errors,
      "materialTransferSettings.transferMass",
      "Please enter a valid transfer mass."
    );
  }
  if (!materialTransferSettings.applyUniversalTransfer) {
    forEach(materialTransferSettings.transferInfo, (value = {}, key) => {
      if (!isValidPositiveNumber(value.volume)) {
        set(
          errors,
          `materialTransferSettings.transferInfo[${key}].volume`,
          "Please enter a valid transfer volume."
        );
      }
      if (!isValidPositiveNumber(value.mass)) {
        set(
          errors,
          `materialTransferSettings.transferInfo[${key}].mass`,
          "Please enter a valid transfer mass."
        );
      }
    });
  }
  if (!reagentTransferSettings.applyUniversalTransfer) {
    forEach(reagentTransferSettings.transferInfo, (value = {}, key) => {
      if (!isValidPositiveNumber(value.volume)) {
        set(
          errors,
          `reagentTransferSettings.transferInfo[${key}].volume`,
          "Please enter a valid transfer volume."
        );
      }
      if (!isValidPositiveNumber(value.mass)) {
        set(
          errors,
          `reagentTransferSettings.transferInfo[${key}].mass`,
          "Please enter a valid transfer mass."
        );
      }
    });
  }
  if (destinationPlates.length && every(plateToReactionMap, isEmpty)) {
    errors.reactionMaps =
      "Please drag at least one reaction from the table to the plate wells.";
  }
  if (enableMultipleReactions) {
    if (!isValidNumOfReactions(numberOfReactionsPerWell)) {
      errors.numberOfReactionsPerWell =
        "Please enter a positive integer greater than or equal to 2.";
    }
  }
  return errors;
};

const plateMapGroupFragment = ["plateMapGroup", "id name"];

const WorklistPlanningTool = ({
  containerArrayTypes,
  defaultPlateMapGroup,
  defaultReactionMaps,
  defaultSourceTubes,
  defaultSourcePlates,
  defaultSourceDataTables,
  initialValues: _initialValues,
  isToolIntegrated,
  toolIntegrationProps,
  toolSchema
}) => {
  const staticTracker = useRef({});
  const containerArrayType = containerArrayTypes?.[0];

  const defaultDestinationPlates = useMemo(() => {
    if (!containerArrayType) return;
    return [
      {
        id: "__" + shortid(),
        name: "Destination Plate 1",
        containerArrayType,
        aliquotContainers: generateEmptyWells(
          containerArrayType.containerFormat,
          {
            aliquotContainerTypeCode:
              containerArrayType.aliquotContainerTypeCode
          }
        )
      }
    ];
  }, [containerArrayType]);

  const selectPlatesDefaultValues = useMemo(() => {
    if (!containerArrayType) return {};
    return {
      defaultReactionMaps,
      defaultPlateMapGroup,
      defaultDestinationPlates
    };
  }, [
    containerArrayType,
    defaultDestinationPlates,
    defaultPlateMapGroup,
    defaultReactionMaps
  ]);

  const sourceMaterialsDefaultValues = useMemo(() => {
    if (!containerArrayType) return;
    return {
      defaultSourceDataTables,
      defaultSourcePlates,
      defaultSourceTubes
    };
  }, [
    containerArrayType,
    defaultSourceDataTables,
    defaultSourcePlates,
    defaultSourceTubes
  ]);

  const steps = useMemo(
    () => [
      {
        title: "Plan Reactions",
        Component: SelectPlates,
        withCustomFooter: true,
        props: {
          staticTracker: staticTracker,
          defaultPlateType: containerArrayTypes[0],
          ...selectPlatesDefaultValues
        }
      },
      {
        title: "Select Input Materials",
        Component: SourceMaterials,
        withCustomFooter: true,
        props: sourceMaterialsDefaultValues
      },
      {
        title: "Review Worklist",
        Component: ReviewWorklist,
        withCustomFooter: true
      }
    ],
    [
      containerArrayTypes,
      selectPlatesDefaultValues,
      sourceMaterialsDefaultValues
    ]
  );

  return (
    <StepForm
      toolIntegrationProps={toolIntegrationProps}
      enableReinitialize={isToolIntegrated}
      steps={steps}
      validate={validate}
      toolSchema={toolSchema}
      onSubmit={onSubmit}
    />
  );
};

const containerArrayTypesOptions = {
  isPlural: true,
  variables: {
    pageSize: 1,
    filter: {
      cid: "sys-generic-96-well-plate"
    }
  }
};

const WorklistPlanningToolWrapper = props => {
  const inputs = props.toolIntegrationProps?.inputs;
  const isIntegratedMap = props.toolIntegrationProps?.isIntegratedMap;

  const reactionMapVariables = useMemo(
    () => ({
      variables: { filter: { id: inputs?.reactionMaps } },
      skip: !isIntegratedMap?.reactionMaps
    }),
    [inputs?.reactionMaps, isIntegratedMap?.reactionMaps]
  );
  const { entities: defaultReactionMaps, loading: loadingReactionMaps } =
    useTgQuery(worklistPlanningReactionMapFragment, reactionMapVariables);

  const sourceDataOptions = useMemo(
    () => ({
      variables: { filter: { id: inputs?.dataTables } },
      skip: !isIntegratedMap?.dataTables
    }),
    [inputs?.dataTables, isIntegratedMap?.dataTables]
  );
  const { entities: defaultSourceDataTables, loading: loadingDataTables } =
    useTgQuery(worklistPlanningDataTableFragment, sourceDataOptions);

  const sourcePlatesOptions = useMemo(
    () => ({
      variables: { filter: { id: inputs?.containerArrays } },
      skip: !isIntegratedMap?.containerArrays
    }),
    [inputs?.containerArrays, isIntegratedMap?.containerArrays]
  );
  const { entities: defaultSourcePlates, loading: loadingSourcePlates } =
    useTgQuery(worklistPlanningPlateFragment, sourcePlatesOptions);

  const sourceTubesVariables = useMemo(
    () => ({
      variables: { filter: { id: inputs?.aliquotContainers } },
      skip: !isIntegratedMap?.aliquotContainers
    }),
    [inputs?.aliquotContainers, isIntegratedMap?.aliquotContainers]
  );
  const { entities: defaultSourceTubes, loading: loadingSourceTubes } =
    useTgQuery(worklistPlanningTubeFragment, sourceTubesVariables);

  const plateMapGroupVariables = useMemo(
    () => ({
      variables: { filter: { id: inputs?.plateMapGroup } },
      skip: !isIntegratedMap?.plateMapGroup
    }),
    [inputs?.plateMapGroup, isIntegratedMap?.plateMapGroup]
  );
  const { entities: defaultPlateMapGroup, loading: loadingPlateMapGroup } =
    useTgQuery(plateMapGroupFragment, plateMapGroupVariables);

  const { entities: containerArrayTypes, loading: loadingContainerArrayTypes } =
    useTgQuery(
      worklistPlanningContainerArrayTypeFragment,
      containerArrayTypesOptions
    );

  const loading =
    loadingContainerArrayTypes ||
    loadingPlateMapGroup ||
    loadingSourceTubes ||
    loadingSourcePlates ||
    loadingDataTables ||
    loadingReactionMaps;
  if (loading) return <Loading inDialog bounce />;
  return (
    <WorklistPlanningTool
      {...props}
      containerArrayTypes={containerArrayTypes}
      defaultPlateMapGroup={
        defaultPlateMapGroup?.length ? defaultPlateMapGroup[0] : null
      }
      defaultSourceTubes={defaultSourceTubes}
      defaultSourcePlates={defaultSourcePlates}
      defaultSourceDataTables={defaultSourceDataTables}
      defaultReactionMaps={defaultReactionMaps}
    />
  );
};

export default WorklistPlanningToolWrapper;
