/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { ComponentType, useEffect } from "react";
import { ComponentEnhancer, compose, withProps } from "recompose";
import { upperFirst } from "lodash";
import {
  generateFragmentWithFields,
  getModelNameFromFragment
} from "@teselagen/apollo-methods";
import { useNotFound } from "./withNotFound";
import withQuery from "./withQuery";
import { getGeneralFragmentFields } from "./utils/getGeneralFragmentFields";
import { isCommonLabLocked } from "./utils/labUtils";
import modelNameToReadableName from "./utils/modelNameToReadableName";
import { Helmet } from "react-helmet";
import { combineGqlFragments } from "../../tg-iso-shared/utils/gqlUtils";
import { setActiveRecord } from "./activeRecordStore";
import { DocumentNode } from "graphql";
import { InjectedWithQueryProps } from "./withQuery/types";
import { RouteComponentProps } from "react-router-dom";
import { WithQueryOptions } from "./withQuery/utils";

/** The props that are injected by the HOC
 * @example InjectedRecordViewEnhancerProps<{showModal: boolean}, {name: string, description: number}, "genome">
 * The first parameter refers to the props that are required in the component  and not injected
 * The second parameter represents the structure of the data of the query being performed, ideally should be auto generated from a fragment
 * The third parameter represents the name object received. Originally, gql adds all the data inside of the data property, here as an extension of InjectedWithQueryProps
 * In the example, the result of the query will be in props.data and props.genome
 */
export type InjectedRecordViewEnhancerProps<
  TProps = any,
  TData = any,
  TQueryNameToUse = string
> = {
  isInsideSidePanel: boolean;
  getIdFromParams: boolean;
  inDialog: boolean;
  model: string;
  record: TData;
  fragment: string | DocumentNode;
  refetchRecord: InjectedWithQueryProps<
    TProps,
    TData,
    {},
    false,
    TQueryNameToUse extends string ? TQueryNameToUse : never
  >["data"]["refetch"];
  readOnly: boolean;
} & InjectedWithQueryProps<
  TProps,
  TData,
  {},
  false,
  TQueryNameToUse extends string ? TQueryNameToUse : never
> &
  RouteComponentProps &
  TProps;

type Record = { __typename: string; id: string; name?: string };

const useRecordHelmet = (record: Record) => {
  if (!record) return <></>;
  const modelType = modelNameToReadableName(record.__typename, {
    upperCase: true
  });
  const name = record.name || `${modelType} ${record.id}`;

  return <Helmet title={`${name} - ${modelType}`} />;
};

export function getRecordViewFragment(
  _fragment: DocumentNode | [string, string],
  options: {
    updateableModel?: string;
    wrapWithNestModel?: (model: string) => string;
    isUsingView?: boolean;
    noAddedBy?: boolean;
    noUser?: boolean;
  } = {}
) {
  let fragment = _fragment;

  if (Array.isArray(fragment)) {
    fragment = generateFragmentWithFields(...fragment) as DocumentNode;
  }
  const model = getModelNameFromFragment(fragment);
  const additionalFragment = getGeneralFragmentFields(model, options);

  return combineGqlFragments([fragment, additionalFragment]);
}

type WithRecordViewEnhancerComponentProps = {
  record: Record;
  readOnly: boolean;
  fragment: DocumentNode | string;
  refetchRecord: () => void;
};

interface recordViewEnhancerOptions {
  withQueryOptions?: WithQueryOptions;
  noAddedBy?: boolean;
  noUser?: boolean;
  updateableModel?: string;
  wrapWithNestModel?: (model: string) => string;
  isUsingView?: boolean;
}

const recordViewEnhancer: <TProps = any, TData = any, TQueryNameToUse = string>(
  _fragment: DocumentNode | [string, string],
  options?: recordViewEnhancerOptions
) => ComponentEnhancer<
  InjectedRecordViewEnhancerProps<TProps, TData, TQueryNameToUse>, // injected props in the component
  TProps // Required props in the component
> = (_fragment, options = {}) => {
  const { withQueryOptions } = options;
  const fragment = getRecordViewFragment(_fragment, options);
  const model = getModelNameFromFragment(fragment);

  const withRecordViewEnhancer = (
    Component: ComponentType<WithRecordViewEnhancerComponentProps>
  ) => {
    const RecordView = (
      props: { [model: string]: any } & { readOnly?: boolean }
    ) => {
      const record = props[model];
      const readOnly =
        props.readOnly || isCommonLabLocked(record) || record.lockId;

      useEffect(() => {
        if (record?.name) {
          setActiveRecord({
            name: record.name
          });
        }
      }, [record.name]);

      const helmet = useRecordHelmet(record);

      return (
        <>
          {helmet}
          <Component
            {...props}
            record={record}
            readOnly={readOnly}
            fragment={fragment}
            refetchRecord={props.data.refetch}
          />
        </>
      );
    };

    const NotFoundWrapper = (
      props: { data: any } & { [model: string]: any }
    ) => {
      const { data } = props;
      const isLoading =
        `loading${upperFirst(model)}` in props &&
        props[`loading${upperFirst(model)}`];
      const hasData = !!(model in props && props[model]);
      const notFound = useNotFound({
        isLoading,
        data,
        fragment,
        model,
        hasData
      });
      if (notFound) {
        return notFound;
      }
      return <RecordView {...props} />;
    };

    return NotFoundWrapper;
  };

  const toCompose = [
    withProps(({ recordIdOverride }: { recordIdOverride?: string }) => ({
      isInsideSidePanel: !!recordIdOverride,
      getIdFromParams: !recordIdOverride,
      inDialog: !!recordIdOverride, //make the loader act as if in a dialog
      model
    })),
    withQuery(fragment, {
      showLoading: true,
      ...withQueryOptions
    }),
    withRecordViewEnhancer
  ];

  return compose(...toCompose);
};

export default recordViewEnhancer;
