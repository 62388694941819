/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export const workflowRunNotifyJobUsersFragment = gql`
  fragment workflowRunNotifyJobUsersFragment on workflowRun {
    id
    job {
      id
      jobUsers {
        id
        userId
      }
    }
  }
`;
