/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { SimpleCircularOrLinearView } from "@teselagen/ove";
import sequenceFullFragment from "../../../tg-iso-shared/src/fragments/sequenceFullFragment";
import { sequenceToVeInput } from "../../../tg-iso-shared/src/sequence-import-utils/utils";
import useTgQuery from "../apolloUseTgQuery";

function SequencePreview(props) {
  const { circular, fullSequence, parts, cutsites, id } = props;
  const {
    data: { sequence }
  } = useTgQuery(sequenceFullFragment, { variables: { id } });
  const oveSeq = sequenceToVeInput(sequence);

  return (
    <SimpleCircularOrLinearView
      withChoosePreviewType
      minimalPreviewTypeBtns
      // withDownload
      withCaretEnabled
      withFullscreen
      width={500}
      withVisibilityOptions
      sequenceData={{
        sequence: fullSequence,
        circular,
        parts,
        cutsites,
        features: oveSeq?.features
      }}
      annotationVisibility={{
        features: false
      }}
      annotationLabelVisibility={{
        parts: false,
        features: false,
        cutsites: true,
        primers: false
      }}
    ></SimpleCircularOrLinearView>
  );
}

export default SequencePreview;
