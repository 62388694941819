/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import {
  getAlphnumericWellLocation,
  Position
} from "../../../src-shared/utils/getAlphnumericWellLocation";
import {
  getWellPositionsGivenWellCountAndDirection,
  getWellPositionsGivenWellCountAndDirectionParams
} from "./getWellPositionsGivenWellCountAndDirection";
import { AliquotContainerType } from "../../components/LimsTools/utils/plateTo2dAliquotContainerArray";

const positionsToLocationsMap = (positions: Position[]) =>
  getAlphnumericWellLocation(positions).reduce(
    (acc: { [position: string]: true }, location) => {
      acc[location] = true;
      return acc;
    },
    {}
  );

/**
 * Will return a set of locations if `multiplate` is false, otherwise
 * will return an array of sets of locations.
 * @param {*} args
 */
export function getLocationHashMapGivenWellCountAndDirection(
  options: getWellPositionsGivenWellCountAndDirectionParams
) {
  const positions = getWellPositionsGivenWellCountAndDirection(options);
  return options.multiplate
    ? (positions as AliquotContainerType[][]).map(positionsToLocationsMap)
    : positionsToLocationsMap(positions as AliquotContainerType[]);
}
