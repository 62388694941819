/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import GenericSelect from "../../../../src-shared/GenericSelect";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import platePreviewColumn from "../../../utils/platePreviewColumn";
import { addReagentsContainerArrayFragment } from "./fragments";

const schema = [
  "name",
  { displayName: "Barcode", path: "barcode.barcodeString" },
  dateModifiedColumn
];

const fragment = [
  "containerArray",
  "id name barcode { id barcodeString } updatedAt"
];

const postSelectDTProps = {
  formName: "addReagentsSelectPlatesTable",
  schema: [
    platePreviewColumn(),
    "name",
    { displayName: "Barcode", path: "barcode.barcodeString" },
    {
      displayName: "Plate Type",
      path: "containerArrayType.name"
    },
    dateModifiedColumn
  ]
};

const SelectPlates = ({
  toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} }
}) => (
  <div>
    <div className="tg-step-form-section column">
      <HeaderWithHelper
        header="Select Plates from Inventory"
        helper="Select one or more plates to add reagents to."
      />
      <GenericSelect
        name="containerArrays"
        isRequired
        schema={schema}
        isMultiSelect
        fragment={fragment}
        additionalDataFragment={addReagentsContainerArrayFragment}
        postSelectDTProps={postSelectDTProps}
        buttonProps={{
          loading: isLoadingMap.containerArrays,
          disabled: isDisabledMap.containerArrays
        }}
      />
    </div>
  </div>
);

export default SelectPlates;
