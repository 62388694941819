/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { IoItem } from "./IoItem";

export type IoList = {
  id?: string;
  microserviceQueueId: string;
  isList: boolean;
  workflowToolOutput?: {
    workflowTool: { workflowToolStatusCode: string };
  };
  ioListItems: IoItem[];
};

export const isIoListItem = (item: IoItem | IoList): item is IoList =>
  "isList" in item && !!item.isList;
