/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

export {
  createTaggedItems,
  uploadTagsCsv,
  getInitialTaggedItems,
  addTagFilterToQuery,
  addTagToUrl,
  removeTagFromUrl,
  tagNameWithOption,
  getSortedTaggedItems,
  exportTagsColumn
} from "../../../tg-iso-shared/src/tag-utils";

export function getInitiallySelectedTags(records) {
  const partiallySelectedTags = {};
  const selectedTagsForEachRecord = records.map(record => {
    return record.taggedItems.reduce((acc, taggedItem) => {
      const { tag, tagOption } = taggedItem;
      let tagId = tag.id,
        name = tag.name,
        color = tag.color;
      if (tagOption) {
        tagId += `:${tagOption.id}`;
        name += `: ${tagOption.name}`;
        color = tagOption.color;
      }
      acc[tagId] = {
        id: tagId,
        name,
        color
      };
      partiallySelectedTags[tagId] = acc[tagId];
      return acc;
    }, {});
  });
  const selectedTags = selectedTagsForEachRecord[0] || {};
  Object.keys(selectedTags).forEach(key => {
    const everyRecordHasSameTag = selectedTagsForEachRecord.every(
      tags => tags[key]
    );
    if (everyRecordHasSameTag) {
      delete partiallySelectedTags[key];
    } else {
      delete selectedTags[key];
    }
  });
  return Object.values(selectedTags).map(t => ({
    ...t,
    label: t.name
  }));
}
