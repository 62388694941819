/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useContext } from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import {
  InputField,
  ReactColorField,
  InfoHelper,
  DialogFooter
} from "@teselagen/ui";
import { wrapDialog } from "@teselagen/ui";
import {
  deleteWithQuery,
  safeDelete,
  safeQuery,
  safeUpsert
} from "../apolloMethods";
import UserSelect from "../UserSelect";
import { nanoid } from "nanoid";
import withQuery from "../withQuery";
import {
  getLabAdmins,
  getLabMembers,
  removeDefaultLabIdIfNecessary
} from "../utils/labUtils";
import CurrentUserContext from "../context/CurrentUserContext";
import { difference } from "lodash";
import { labFragment } from "./labFragment.gql";

const CreateOrEditNewLabGroup = ({
  refetchLabs,
  hideModal,
  originalLabBeingEdited = {},
  handleSubmit,
  submitting
}) => {
  const { currentUser, refetchCurrentUser } = useContext(CurrentUserContext);

  const addMembersToLab = useCallback(
    async (
      labId,
      existingLabGroupAdmins = [],
      existingLabGroupMembers = [],
      finalLabGroupAdmins = [],
      finalLabGroupMembers = []
    ) => {
      const existingLabGroupAdminsIds = existingLabGroupAdmins.map(
        admin => admin.id
      );
      const existingLabGroupMembersIds = existingLabGroupMembers.map(
        member => member.id
      );
      const finalLabGroupAdminsIds = finalLabGroupAdmins.map(admin => admin.id);
      const finalLabGroupMembersIds = finalLabGroupMembers.map(
        member => member.id
      );

      const existingLabUsers = new Set(
        existingLabGroupAdminsIds.concat(existingLabGroupMembersIds)
      );
      const finalLabUsers = new Set(
        finalLabGroupAdminsIds.concat(finalLabGroupMembersIds)
      );

      // Something in our versions don't seem to suport set native difference
      const newUsersInLab = difference(
        Array.from(finalLabUsers),
        Array.from(existingLabUsers)
      );

      const newLabAdmins = finalLabGroupAdmins.map(admin => ({
        labId,
        userId: admin.id,
        roleCode: "ADMIN"
      }));

      const newLabMembers = finalLabGroupMembers
        .filter(member => !finalLabGroupAdminsIds.includes(member.id))
        .map(member => ({
          labId,
          userId: member.id,
          roleCode: "MEMBER"
        }));

      await safeUpsert("labRole", newLabAdmins.concat(newLabMembers), {
        context: {
          headers: {
            "tg-active-lab-id": labId
          }
        }
      });

      //delete any old labRoles if they exist because we may have made duplicates in
      //the previous upsert
      if (originalLabBeingEdited.labRoles) {
        await safeDelete(
          "labRole",
          originalLabBeingEdited.labRoles.map(r => r.id),
          {
            context: {
              headers: {
                "tg-active-lab-id": labId
              }
            }
          }
        );
      }

      const postLabRegistrationActions = newUsersInLab.map(userId =>
        window.tgApi({
          url: `users/${userId}/post-register-actions`,
          method: "POST",
          data: {
            labId: labId
          },
          withCredentials: true
        })
      );

      await Promise.all(postLabRegistrationActions);
    },
    [originalLabBeingEdited.labRoles]
  );

  const onCreateLab = useCallback(
    async data => {
      const { labGroupAdmins = [], labGroupMembers = [] } = data;

      const input = {
        name: data.labGroupName,
        emailDomain: data.allowedEmailDomain,
        color: data.color
      };
      const temporaryAdminCid = nanoid();
      input.labRoles = [
        {
          userId: currentUser.id,
          roleCode: "ADMIN",
          cid: temporaryAdminCid
        }
      ];
      //create/edit the existing lab
      const [{ id: labId }] = await safeUpsert("lab", input, {
        context: {
          headers: {
            "tg-active-lab-id": "all"
          }
        }
      });

      await addMembersToLab(
        labId,
        undefined,
        undefined,
        labGroupAdmins,
        labGroupMembers
      );

      // delete the temporary lab admin role that let us add labRoles
      await deleteWithQuery(
        "labRole",
        { cid: [temporaryAdminCid] },
        {
          context: {
            headers: {
              "tg-active-lab-id": input.id
            }
          }
        }
      );
    },
    [addMembersToLab, currentUser.id]
  );

  const onEditLab = useCallback(
    async data => {
      const { labGroupAdmins = [], labGroupMembers = [] } = data;
      const input = {
        id: originalLabBeingEdited.id,
        name: data.labGroupName,
        emailDomain: data.allowedEmailDomain,
        color: data.color
      };

      //create/edit the existing lab
      await safeUpsert("lab", input, {
        context: {
          headers: {
            "tg-active-lab-id": "all"
          }
        }
      });
      await addMembersToLab(
        originalLabBeingEdited.id,
        originalLabBeingEdited.labGroupAdmins,
        originalLabBeingEdited.labGroupMembers,
        labGroupAdmins,
        labGroupMembers
      );
    },
    [
      addMembersToLab,
      originalLabBeingEdited.id,
      originalLabBeingEdited.labGroupAdmins,
      originalLabBeingEdited.labGroupMembers
    ]
  );

  const onSubmit = useCallback(
    async data => {
      const { labGroupAdmins = [], labGroupMembers = [] } = data;
      try {
        if (originalLabBeingEdited.id) {
          await onEditLab(data);
        } else {
          await onCreateLab(data);
        }

        removeDefaultLabIdIfNecessary({
          originalLabBeingEdited,
          labGroupAdmins,
          labGroupMembers
        });

        await refetchLabs();
        refetchCurrentUser();
        hideModal();
      } catch (error) {
        console.error("error:", error);
        window.toastr.error(
          `Error ${originalLabBeingEdited.id ? "updating" : "creating"} lab.`
        );
      }
    },
    [
      hideModal,
      onCreateLab,
      onEditLab,
      originalLabBeingEdited,
      refetchCurrentUser,
      refetchLabs
    ]
  );

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <InputField
          name="labGroupName"
          isRequired
          label="Lab Group Name"
          placeholder="New Lab Group"
        />
        <div className="tg-flex">
          <ReactColorField
            name="color"
            label="Color"
            secondaryLabel="(optional)"
          />
          <InfoHelper
            style={{ marginLeft: 10 }}
            content="Color in app header while lab is active."
          />
        </div>
        <InputField
          name="allowedEmailDomain"
          label="Allowed Email Domain"
          placeholder="@mymail.com"
        />
        <UserSelect
          name="labGroupAdmins"
          isRequired
          label="Lab Admins"
          isMultiSelect
          showEmail
        />
        <UserSelect
          name="labGroupMembers"
          label="Lab Members"
          isMultiSelect
          showEmail
        />
      </div>
      <DialogFooter
        hideModal={hideModal}
        submitting={submitting}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
};

const validate = values => {
  const errors = {};
  if (
    values.allowedEmailDomain &&
    // eslint-disable-next-line no-useless-escape
    !/^@[\S\.]+\.\S+$/i.test(values.allowedEmailDomain)
  ) {
    errors.allowedEmailDomain = "Invalid email domain";
  }

  return errors;
};

function asyncValidate(
  { allowedEmailDomain },
  dispatch,
  { initialValues = {} }
) {
  if (!allowedEmailDomain) return Promise.resolve();
  if (allowedEmailDomain === initialValues.allowedEmailDomain)
    return Promise.resolve();
  return safeQuery(["lab", "emailDomain"], {
    variables: {
      filter: {
        emailDomain: allowedEmailDomain
      }
    }
  }).then(res => {
    if (res.length) {
      const error = {
        allowedEmailDomain: "That email domain is already in use."
      };
      throw error;
    }
  });
}

export function getInitialValuesForLabGroup(lab) {
  return {
    id: lab.id,
    labGroupName: lab.name,
    allowedEmailDomain: lab.emailDomain,
    color: lab.color,
    labGroupAdmins: getLabAdmins(lab).map(({ id, user, userId }) => ({
      labRoleId: id,
      id: userId,
      username: user && user.username,
      email: user && user.email
    })),
    labGroupMembers: getLabMembers(lab).map(({ id, user, userId }) => ({
      labRoleId: id,
      id: userId,
      username: user && user.username,
      email: user && user.email
    })),
    labRoles: lab.labRoles
  };
}

export default compose(
  wrapDialog({
    title: "Create New Lab Group"
  }),
  withQuery(labFragment, {
    showLoading: true,
    inDialog: true,
    skip: props => !props.recordId,
    options: props => {
      return {
        variables: {
          id: props.recordId
        }
      };
    },
    props: ({ lab }) => {
      if (lab) {
        const initialValues = getInitialValuesForLabGroup(lab);
        return {
          originalLabBeingEdited: initialValues,
          initialValues
        };
      }
    }
  }),
  reduxForm({
    form: "CreateOrEditNewLabGroupForm",
    enableReinitialize: true,
    validate,
    asyncValidate
  })
)(CreateOrEditNewLabGroup);
