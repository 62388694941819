/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { MenuItem } from "@blueprintjs/core";
import { useTgQuery } from "../apolloMethods";
import { showDialog } from "../GlobalDialog";
import ImportFromExternalDbDialog from "./ImportFromExternalDbDialog";
import { integrationOptionsFragment } from "../fragments/integrationOptionsFragment.gql";

export default function ImportFromExternalDbMenuOptions({
  subtype,
  refetch,
  afterRecordUpsert
}) {
  const {
    data: { integrations },
    ...rest
  } = useTgQuery(integrationOptionsFragment, {
    variables: {
      filter: {
        integrationTypeCode: "IMPORT",
        subtype
      }
    }
  });
  if (rest.loading) {
    return <MenuItem text="Loading..." disabled />;
  }
  if (useTgQuery.checkErrAndLoad(rest))
    return useTgQuery.handleErrAndLoad(rest);

  if (!integrations.length) {
    return <MenuItem text="No integrations have been setup." disabled />;
  }
  return integrations.map(integration => {
    return (
      <MenuItem
        text={integration.name}
        key={integration.id}
        onClick={() => {
          showDialog({
            ModalComponent: ImportFromExternalDbDialog,
            modalProps: {
              importSubtype: integration.subtype,
              integrationId: integration.id,
              afterRecordUpsert,
              refetch
            }
          });
        }}
      />
    );
  });
}
