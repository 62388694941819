/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useMemo } from "react";
import {
  ReactSelectField,
  CheckboxField,
  FileUploadField,
  fieldRequired,
  NumericInputField
} from "@teselagen/ui";
import { has, get, upperFirst } from "lodash";
import { Field, formValues } from "redux-form";

import ContainerArrayTypeSelect from "../ContainerArrayTypeSelect";
import {
  arrayToItemValuedOptions,
  isValidPositiveNumber
} from "../../../src-shared/utils/formUtils";

import { allowedCsvFileTypes } from "../../../../tg-iso-shared/src/utils/fileUtils";
import { Icon, Tooltip } from "@blueprintjs/core";
import { getTypeOfContainerArray } from "../../../../tg-iso-lims/src/utils/plateUtils";
import AssignToLocationCheckbox from "../AssignToLocationCheckbox";
import { getDownloadTemplateFileHelpers } from "../../../src-shared/components/DownloadTemplateFileButton";

const ShouldFillRack = formValues(props => {
  return { shouldFillRack: `${props.fieldPrefix}shouldFillRack` };
})(({ input, containerArrayType, fieldPrefix, noNumTubes, shouldFillRack }) => {
  const quadrantSize = containerArrayType.containerFormat.quadrantSize;
  const type = getTypeOfContainerArray(containerArrayType);
  const validateNumTubes = useCallback(
    val => {
      if (!isValidPositiveNumber(val)) {
        return "Please enter a positive number";
      } else if (val > quadrantSize) {
        return `This ${type} type can only fit ${quadrantSize} tubes.`;
      }
    },
    [quadrantSize, type]
  );

  return (
    <>
      <CheckboxField
        name={fieldPrefix + "shouldFillRack"}
        // With react 18, if the field  reinstantiates it will change the value to
        // the default value, so we need to explcitely tell it to use the value if
        // it is not set.
        defaultValue={shouldFillRack === undefined ? true : shouldFillRack}
        label={`Fill ${upperFirst(type)} with Tubes`}
      />
      {input.value && !noNumTubes && (
        <NumericInputField
          label="Number of Tubes"
          name="numTubesToFillRack"
          validate={validateNumTubes}
          min={0}
          max={quadrantSize}
          enableReinitialize
          defaultValue={quadrantSize}
        />
      )}
    </>
  );
});

function ChooseTubeOptions({
  input,
  fieldPrefix = "",
  noNumTubes,
  noFillOption,
  noTubeBarcodeOption
}) {
  const containerArrayType = input.value;
  const isRack =
    has(containerArrayType, "isPlate") && !containerArrayType.isPlate;
  const nestableTubeTypes = get(containerArrayType, "nestableTubeTypes");
  if (isRack) {
    return (
      <>
        <ReactSelectField
          name={fieldPrefix + "aliquotContainerType"}
          label="Tube Type"
          placeholder="Select Tube Type"
          isRequired
          enableReinitialize
          options={arrayToItemValuedOptions(
            nestableTubeTypes &&
              nestableTubeTypes.map(tt => tt.aliquotContainerType)
          )}
          defaultValue={
            nestableTubeTypes.length === 1
              ? nestableTubeTypes[0].aliquotContainerType
              : undefined
          }
        />
        {!noFillOption && (
          <Field
            name={fieldPrefix + "shouldFillRack"}
            containerArrayType={containerArrayType}
            fieldPrefix={fieldPrefix}
            component={ShouldFillRack}
            noNumTubes={noNumTubes}
          />
        )}
        {!noTubeBarcodeOption && (
          <CheckboxField
            name={fieldPrefix + "generateTubeBarcodes"}
            label="Generate Tube Barcodes"
            defaultValue
          />
        )}
      </>
    );
  }
  return null;
}

const PlateUploadFields = ({
  fieldPrefix = "",
  inTool,
  templateFileOptions,
  noFileUpload,
  stripAdaptersOption,
  noNumTubes,
  uploadHelperText,
  genericSelectOptions,
  noFillOption,
  noTubeBarcodeOption,
  emptyPlates // special empty plate upload (no plate type select)
}) => {
  const accept = useMemo(() => {
    if (!noFileUpload)
      return getDownloadTemplateFileHelpers({
        type: allowedCsvFileTypes.concat([".zip"]),
        ...templateFileOptions,
        additionalFileTypes:
          templateFileOptions.validateAgainstSchema?.fields?.find(
            f => f.path === "SEQUENCE_FILE" || f.path === "GB_FILE"
          ) || templateFileOptions.isTwistUpload
            ? [".gb"]
            : []
      });
  }, [noFileUpload, templateFileOptions]);

  return (
    <>
      {!emptyPlates && (
        <>
          <ContainerArrayTypeSelect
            name={fieldPrefix + "containerArrayType"}
            {...genericSelectOptions}
          />
          {!inTool && stripAdaptersOption && (
            <div className="tg-flex">
              <CheckboxField
                name={fieldPrefix + "stripAdapters"}
                label="Strip Adapters"
                defaultValue
              />
              <div style={{ marginTop: 7, marginLeft: 10 }}>
                <Tooltip
                  content="Checking this box will remove any adapters added to linear DNA sequences by Twist."
                  position="right"
                  boundary="preventOverflow"
                >
                  <Icon icon="help" />
                </Tooltip>
              </div>
            </div>
          )}
          <Field
            name={
              genericSelectOptions?.name || fieldPrefix + "containerArrayType"
            }
            fieldPrefix={fieldPrefix}
            // keep the fieldRequired
            validate={fieldRequired}
            noNumTubes={noNumTubes}
            noFillOption={noFillOption}
            noTubeBarcodeOption={noTubeBarcodeOption}
            component={ChooseTubeOptions}
          />
        </>
      )}
      {!noFileUpload && (
        <>
          {uploadHelperText && (
            <div style={{ fontStyle: "italic", marginBottom: 10 }}>
              {uploadHelperText}
            </div>
          )}
          <FileUploadField
            isRequired
            accept={accept}
            name={fieldPrefix + "plateFiles"}
          />
        </>
      )}
      {!inTool && (
        <div className="tg-flex">
          <AssignToLocationCheckbox fieldPrefix={fieldPrefix} />
          <div style={{ marginTop: 7, marginLeft: 10 }}>
            <Tooltip
              content="Checking this box will guide you through plate placement after plate/tube upload."
              position="right"
              boundary="preventOverflow"
            >
              <Icon icon="help" />
            </Tooltip>
          </div>
        </div>
      )}
    </>
  );
};

export default PlateUploadFields;
