/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import {
  CheckboxField,
  InputField,
  RadioGroupField,
  NumericInputField
} from "@teselagen/ui";
import { compose } from "recompose";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import defaultValueConstants from "../../../../../../tg-iso-shared/src/defaultValueConstants";

function SetParameters(props) {
  const { designType } = props;

  return (
    <React.Fragment>
      <div
        style={{
          textAlign: "right",
          fontStyle: "italic",
          fontSize: 12,
          marginBottom: 15
        }}
      >
        Powered by NinthBio's Homology Path
      </div>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Set Parameters"
          helper="Set parameters for the design of oligos to create synthons."
        />
        <div className="tg-flex column" style={{ maxWidth: 300 }}>
          <InputField
            name="designName"
            isRequired
            label="Design Name"
            generateDefaultValue={{
              ...defaultValueConstants.GENERATE_OLIGOS_FOR_SYNTHONS_DESIGN_NAME
            }}
          />
          <RadioGroupField
            name="designType"
            label="Design Type"
            generateDefaultValue={
              defaultValueConstants.GENERATE_OLIGOS_FOR_SYNTHONS_DESIGN_TYPE
            }
            options={
              defaultValueConstants.GENERATE_OLIGOS_FOR_SYNTHONS_DESIGN_TYPE
                .options
            }
          />
          {designType === "maximizeRecycling" && (
            <div>
              <NumericInputField
                name="estimatedIdentity"
                label="Estimated Identity [0-1.0]"
                placeholder="0.95"
                min={0}
                max={1}
                stepSize={0.01}
                minorStepSize={0.01}
                generateDefaultValue={defaultValueConstants.ESTIMATED_IDENTITY}
              />
            </div>
          )}
          {designType === "minimizeComplexity" && (
            <div>
              <NumericInputField
                name="minZScoreCutoff"
                label="Min Z-Score Cutoff"
                placeholder="-4.0"
                stepSize={0.1}
                minorStepSize={0.1}
                generateDefaultValue={defaultValueConstants.MIN_Z_SCORE_CUTOFF}
              />
              <NumericInputField
                name="tempForDeltaGCalc"
                label="Temp for ΔG Calculations (°C)"
                placeholder="60.0"
                min={0}
                stepSize={0.1}
                minorStepSize={0.1}
                generateDefaultValue={
                  defaultValueConstants.TEMP_FOR_DELTAG_CALC
                }
              />
            </div>
          )}
          {(designType === "minimizeComplexity" ||
            designType === "gappedDesign") && (
            <div>
              <CheckboxField
                label="Optimize Overlap TMs"
                name="optimizeOverlapTms"
                defaultValue={true}
              />
            </div>
          )}
        </div>
        <div className="tg-flex column" style={{ maxWidth: 300 }}>
          <NumericInputField
            name="minSize"
            label="Min Size (bp)"
            placeholder="30"
            min={1}
            generateDefaultValue={defaultValueConstants.MIN_SIZE}
          />
          <NumericInputField
            name="targetSize"
            label="Target Size (bp)"
            placeholder="40"
            min={1}
            generateDefaultValue={defaultValueConstants.TARGET_SIZE}
          />
          <NumericInputField
            name="maxSize"
            label="Max Size (bp)"
            placeholder="50"
            min={1}
            generateDefaultValue={defaultValueConstants.MAX_SIZE}
          />
          <NumericInputField
            name="minOverlap"
            label="Min Overlap (bp)"
            placeholder="15"
            min={1}
            generateDefaultValue={defaultValueConstants.MIN_OVERLAP}
          />
          <NumericInputField
            name="maxOverlap"
            label="Max Overlap (bp)"
            placeholder="25"
            min={1}
            generateDefaultValue={defaultValueConstants.MAX_OVERLAP}
          />
        </div>
        <div className="tg-flex column" style={{ maxWidth: 300 }}>
          <NumericInputField
            name="targetPrimerSize"
            label="Target Primer Size (bp)"
            placeholder="20"
            min={1}
            generateDefaultValue={defaultValueConstants.TARGET_PRIMER_SIZE}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default compose(
  stepFormValues(
    "designType",
    "estimatedIdentity",
    "minZScoreCutoff",
    "tempForDeltaGCalc",
    "optimizeOverlapTms",
    "designName",
    "minSize",
    "targetSize",
    "maxSize",
    "minOverlap",
    "maxOverlap",
    "targetPrimerSize"
  )
)(SetParameters);
