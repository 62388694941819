/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback } from "react";
import { compose } from "recompose";
import {
  FileUploadField,
  DialogFooter,
  BlueprintError,
  wrapDialog,
  useTableEntities
} from "@teselagen/ui";
import HeaderWithHelper from "../../../../../../src-shared/HeaderWithHelper";
import AssemblyPieceInitialTable from "./AssemblyPieceInitialTable";
import { reduxForm, change as _change } from "redux-form";
import { keyBy, groupBy, uniq } from "lodash";
import { Button, Callout, Classes, MenuItem } from "@blueprintjs/core";

import { showDialog } from "../../../../../../src-shared/GlobalDialog";

import { assemblyPieceTableName } from "../../constants";
import SelectedAssemblyPieceTable from "./SelectedAssemblyPieceTable";
import SelectAssemblyPiecesButton from "./SelectAssemblyPiecesButton";

import { throwFormError } from "../../../../../../src-shared/utils/formUtils";
import { safeQuery } from "../../../../../../src-shared/apolloMethods";
import {
  allowedCsvFileTypes,
  parseCsvOrExcelFile
} from "../../../../../../../tg-iso-shared/src/utils/fileUtils";

import caseInsensitiveFilter from "../../../../../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import { getDownloadTemplateFileHelpers } from "../../../../../../src-shared/components/DownloadTemplateFileButton";
import { useDispatch } from "react-redux";
import { useFormValue } from "../../../../../../src-shared/hooks/useFormValue";

export function getAssemblyPieceIds(j5Reports = []) {
  const assemblyPieceIds = [];
  j5Reports.forEach(report => {
    report.j5RunConstructs.forEach(construct => {
      construct.j5ConstructAssemblyPieces.forEach(({ assemblyPieceId }) => {
        if (!assemblyPieceIds.includes(assemblyPieceId)) {
          assemblyPieceIds.push(assemblyPieceId);
        }
      });
    });
  });
  return assemblyPieceIds;
}

const _SelectViaCsvDialog = ({
  error,
  submitting,
  hideModal,
  handleSubmit,
  onFileSubmit
}) => {
  const onSubmit = async ({ assemblySelectionCsv }) => {
    try {
      const csvFile = assemblySelectionCsv[0];
      await onFileSubmit(csvFile);
      hideModal();
    } catch (error) {
      console.error("error:", error);
      throwFormError(error.message || "Error parsing csv.");
    }
  };

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <Callout intent="primary" style={{ marginBottom: 10 }}>
          Upload a CSV with 2 columns, "assembly piece name" and "assembly piece
          id" to select from the table below.{" "}
        </Callout>
        <FileUploadField
          isRequired
          accept={getDownloadTemplateFileHelpers({
            type: allowedCsvFileTypes,
            fileName: "assemblyPieceSelection",
            headers: ["assembly piece name", "assembly piece id"]
          })}
          fileLimit={1}
          name="assemblySelectionCsv"
        />
        <BlueprintError error={error} />
      </div>
      <DialogFooter
        hideModal={hideModal}
        submitting={submitting}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
};

const SelectViaCsvDialog = compose(
  wrapDialog({ title: "Upload Assembly Pieces CSV" }),
  reduxForm({
    form: "CsvDialog"
  })
)(_SelectViaCsvDialog);

const AssemblyPieces = ({ toolSchema }) => {
  const dispatch = useDispatch();
  const change = useCallback(
    (field, value) => dispatch(_change(toolSchema.code, field, value)),
    [dispatch, toolSchema.code]
  );
  const j5Reports = useFormValue(toolSchema.code, "j5Reports");
  const finalizedAPIds = useFormValue(toolSchema.code, "finalizedAPIds");

  const { selectTableEntities } = useTableEntities(assemblyPieceTableName);

  const parseSelectionFile = useCallback(
    async csvFile => {
      const { data } = await parseCsvOrExcelFile(csvFile);
      if (!data.length) {
        throw new Error("No data found in csv.");
      }
      const j5ReportIds = j5Reports?.map(r => r.id) || [];
      const names = [];
      const assemblyPieceIds = [];
      const finalizedAssemblyPieces = [];
      for (const [index, row] of data.entries()) {
        const {
          "assembly piece name": _name = "",
          "assembly piece id": _assemblyPieceId = ""
        } = row;
        const name = _name.trim();
        const assemblyPieceId = _assemblyPieceId.trim().replace(/\D/g, "");

        if (!name && !assemblyPieceId) {
          throw new Error(
            `Row ${index + 1} did not provide a assembly piece name or id.`
          );
        }
        if (name) {
          names.push(name);
        }
        if (assemblyPieceId) {
          assemblyPieceIds.push(assemblyPieceId);
        }
      }
      let assemblyPieces = [];
      if (names.length) {
        const filter = caseInsensitiveFilter("j5AssemblyPiece", "name", names, {
          returnQb: true
        })
          .whereAll({
            j5ReportId: j5ReportIds
          })
          .toJSON();
        assemblyPieces = assemblyPieces.concat(
          await safeQuery(["j5AssemblyPiece", "id name"], {
            variables: {
              filter
            }
          })
        );
      }
      if (assemblyPieceIds.length) {
        assemblyPieces = assemblyPieces.concat(
          await safeQuery(["j5AssemblyPiece", "id name"], {
            variables: {
              filter: {
                id: assemblyPieceIds,
                j5ReportId: j5ReportIds
              }
            }
          })
        );
      }
      const keyedById = keyBy(assemblyPieces, "id");
      const groupedByName = groupBy(assemblyPieces, "name");
      for (const [index, row] of data.entries()) {
        const {
          "assembly piece name": _name,
          "assembly piece id": _assemblyPieceId
        } = row;
        const name = _name.trim();
        const assemblyPieceId = _assemblyPieceId.trim().replace(/\D/g, "");

        if (name && !groupedByName[name]) {
          throw new Error(
            `Row ${
              index + 1
            } specifies the name ${_name} which did not appear on these reports.`
          );
        }
        if (assemblyPieceId && !keyedById[assemblyPieceId]) {
          throw new Error(
            `Row ${
              index + 1
            } specifies the id ${assemblyPieceId} which did not appear on these reports.`
          );
        }
        if (name && !assemblyPieceId && groupedByName[name].length > 1) {
          // show dup error
          throw new Error(
            `Row ${
              index + 1
            } specifies the name ${name} but there were duplicate pieces found with this name.\
              Please specify an id to narrow selection.`
          );
        }
        if (name && assemblyPieceId) {
          const byName = groupedByName[name][0];
          const byId = keyedById[assemblyPieceId];
          if (byName.id !== byId.id) {
            throw new Error(
              `Row ${
                index + 1
              } specifies the id ${assemblyPieceId} and name ${name} but the assembly piece \
                found did not have both of these properties match.
              `
            );
          }
        }
        const record = name
          ? groupedByName[name][0]
          : keyedById[assemblyPieceId];
        finalizedAssemblyPieces.push(record);
      }
      selectTableEntities(finalizedAssemblyPieces);
      const newIds = finalizedAssemblyPieces.map(p => p.id);
      change("finalizedAPIds", uniq(newIds.concat(finalizedAPIds || [])));
    },
    [change, finalizedAPIds, j5Reports, selectTableEntities]
  );

  const clearAll = useCallback(() => {
    change("finalizedAPIds", []);
  }, [change]);

  if (!j5Reports?.length) return null;

  return (
    <div
      className="tg-step-form-section column"
      style={{ overflowX: "auto", overflowY: "hidden" }}
    >
      <HeaderWithHelper
        menuItems={
          <MenuItem
            text="Select assembly pieces via CSV"
            onClick={() => {
              showDialog({
                ModalComponent: SelectViaCsvDialog,
                modalProps: {
                  onFileSubmit: parseSelectionFile
                }
              });
            }}
          />
        }
        header="Assembly Pieces"
        helper="Below is a list of available assembly pieces associated with the selected assembly reports. Select the pieces to be used during assembly, this will affect the list of buildable constructs."
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "40%" }}>
          <div>
            <AssemblyPieceInitialTable toolSchema={toolSchema} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 15px"
          }}
        >
          <SelectAssemblyPiecesButton change={change} toolSchema={toolSchema} />
          <Button
            style={{ marginTop: 12, width: "100%" }}
            intent="danger"
            text="Clear Selection"
            disabled={!finalizedAPIds?.length}
            onClick={clearAll}
          />
        </div>
        <div style={{ width: "40%" }}>
          <div>
            <SelectedAssemblyPieceTable
              change={change}
              toolSchema={toolSchema}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssemblyPieces;
