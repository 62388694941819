/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import GenericSelect from "./GenericSelect";
import pluralize from "pluralize";
import { CheckboxField, ReactSelectField } from "@teselagen/ui";
import { useSelector } from "react-redux";
import { formValueSelector } from "redux-form";
import { DocumentNode } from "graphql";

function SelectReactionMapEntities({
  fieldConstants,
  reactionMaps = [],
  modelNameToSchema,
  modelNameToFragment,
  modelNameToAdditionalFragment,
  change,
  onlyMaterials,
  getFieldName,
  extraFilter,
  toolSchema
}: {
  fieldConstants: {
    reactionEntityType: string;
    selectAllReactionEntities: string;
  };
  reactionMaps: { id: string }[];
  modelNameToSchema: {
    [key: string]: (
      | { path?: string; displayName?: string; type?: string }
      | string
    )[];
  };
  modelNameToFragment: { [key: string]: DocumentNode };
  modelNameToAdditionalFragment: { [key: string]: DocumentNode };
  change: (field: string, value: any) => void;
  onlyMaterials: boolean;
  getFieldName?: (itemType: string) => string;
  extraFilter?: {};
  toolSchema: { code: string };
}) {
  const {
    // @ts-ignore
    [fieldConstants.reactionEntityType]: reactionEntityType,
    // @ts-ignore
    [fieldConstants.selectAllReactionEntities]: selectAllReactionEntities
  } = useSelector<any>(state =>
    formValueSelector(toolSchema.code)(
      state,
      fieldConstants.reactionEntityType,
      fieldConstants.selectAllReactionEntities
    )
  );

  let select;
  if (!reactionMaps.length) return null;
  if (reactionEntityType && !selectAllReactionEntities) {
    const { additionalFilter, itemType } = getItemTypeAndFilterForReactionMaps({
      reactionEntityType,
      reactionMaps,
      extraFilter
    });
    select = (
      <GenericSelect
        isRequired
        name={getFieldName?.(itemType) || pluralize(itemType)}
        isMultiSelect
        schema={modelNameToSchema[itemType]}
        fragment={modelNameToFragment[itemType]}
        additionalDataFragment={modelNameToAdditionalFragment?.[itemType]}
        postSelectDTProps={{
          formName: "reactionMapPlateMapEntitiesTable",
          schema: modelNameToSchema[itemType]
        }}
        additionalFilter={additionalFilter}
      />
    );
  }
  return (
    <div>
      <div style={{ maxWidth: 250 }}>
        <ReactSelectField
          label="Reaction Entity Type"
          name={fieldConstants.reactionEntityType}
          onFieldSubmit={() => {
            change("materials", []);
            change("additiveMaterials", []);
          }}
          isRequired
          options={getReactionEntityTypeOptions({ onlyMaterials })}
        />
      </div>
      {reactionEntityType && (
        <CheckboxField
          name={fieldConstants.selectAllReactionEntities}
          label={`Select All ${reactionEntityType}`}
        />
      )}
      {select}
    </div>
  );
}

export function getItemTypeAndFilterForReactionMaps({
  reactionMaps,
  reactionEntityType,
  extraFilter
}: {
  reactionMaps: { id: string }[];
  reactionEntityType: string;
  extraFilter?: {};
}) {
  let itemType;
  let additionalFilter;
  const reactionMapIds = reactionMaps.map(r => r.id);
  if (reactionEntityType.includes("Material")) {
    itemType = "material";
  } else {
    itemType = "additiveMaterial";
  }
  if (reactionEntityType.includes("Input")) {
    additionalFilter = {
      "reactionInputs.reaction.reactionMapId": reactionMapIds
    };
  } else if (reactionEntityType.includes("Conserved")) {
    additionalFilter = {
      "reactionInputs.reaction.reactionMapId": reactionMapIds,
      "reactionInputs.conserved": true
    };
  } else {
    additionalFilter = {
      "reactionOutputs.reaction.reactionMapId": reactionMapIds
    };
  }
  additionalFilter = {
    ...additionalFilter,
    ...extraFilter
  };
  return {
    additionalFilter,
    itemType: itemType as "material" | "additiveMaterial"
  };
}

export const getReactionEntityTypeOptions = ({
  onlyMaterials
}: { onlyMaterials?: boolean } = {}) =>
  [
    "Input Materials",
    "Output Materials",
    "Input Reagents",
    "Output Reagents",
    "Conserved Reagents"
  ]
    .filter(op => {
      if (onlyMaterials) {
        return op.includes("Material");
      } else {
        return true;
      }
    })
    .map(type => {
      return {
        label: type,
        value: type
      };
    });

export default SelectReactionMapEntities;
