/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useMemo } from "react";
import { Button, Intent } from "@blueprintjs/core";
import { get, pick } from "lodash";
import { CollapsibleCard, DataTable } from "@teselagen/ui";
import Linkify from "react-linkify";
import RecordInfoDisplay from "../../../../src-shared/RecordInfoDisplay";
import AbstractRecord from "../../../../src-shared/AbstractRecord";
import recordViewEnhancer from "../../../../src-shared/recordViewEnhancer";

// import CustomerRequestOutputContainer from "../../../containers/CustomerRequestOutputContainer";
import AssociatedFilesSection from "../AssociatedFilesSection";
import customerRequestFragment from "../../../graphql/fragments/customerRequestFragment";
import "./style.css";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import CommentCard from "../../../../src-shared/CommentCard";
import { cascadeCustomerRequestTagsToJobsAndWorkflow } from "./utils";
import { formatDate } from "../../../../src-shared/utils/dateUtils";
import LaunchNewJobDialog from "../../Dialogs/LaunchNewJobDialog";
import { Link } from "react-router-dom";

const customerColumns = [
  {
    displayName: "Name",
    path: "name"
  },
  {
    displayName: "Email",
    path: "email"
  }
];

const jobColumns = [
  {
    displayName: "Name",
    path: "name"
  },
  {
    displayName: "Priority",
    path: "priorityTypeCode"
  },
  {
    displayName: "Due Date",
    path: "dueDate"
  }
];

const CustomerRequestRecordView = props => {
  const {
    customerRequest,
    refetchCustomerRequest,
    history,
    currentUser,
    readOnly
  } = props;
  const newJobButton = useCallback(() => {
    return (
      <Button
        intent={Intent.SUCCESS}
        minimal
        icon="add"
        text="New Job"
        style={{ marginTop: -3 }}
        onClick={() => {
          showDialog({
            ModalComponent: LaunchNewJobDialog,
            modalProps: {
              customerRequest,
              refetchCustomerRequest,
              initialValues: {
                customerRequests: [
                  {
                    id: customerRequest.id,
                    name: customerRequest.name,
                    __typename: "customerRequest"
                  }
                ]
              }
            }
          });
        }}
      />
    );
  }, [customerRequest, refetchCustomerRequest]);

  const onRowDoubleClick = useCallback(
    (datum /*, rowIndex, event*/) => {
      // This is the corresponding id.
      const recordId = datum["key"];
      const route = "/jobs/" + recordId;
      history
        ? history.push(route)
        : console.warn("react router history not passed to this component");
    },
    [history]
  );

  const getInitialValuesForEdit = () => {
    return {
      ...pick(customerRequest, [
        "id",
        "customerRequestStatusTypeCode",
        "name",
        "outputDescription",
        "dueDate"
      ]),
      strains: customerRequest.customerRequestStrains.map(s => s.strain),
      sequences: customerRequest.customerRequestSequences.map(s => s.sequence),
      primaryCustomer: get(customerRequest, "primaryCustomer.user"),
      customers: customerRequest.customers.map(c => c.user)
    };
  };

  const updateShowFunction = () => {
    showDialog({
      modalType: "LaunchNewCustomerRequestDialog",
      modalProps: {
        initialValues: getInitialValuesForEdit(),
        requestType: customerRequest.requestType,
        initialCustomers: customerRequest.customers,
        initialPrimaryCustomer: customerRequest.primaryCustomer,
        currentUser: currentUser,
        refetch: refetchCustomerRequest
      }
    });
  };

  const afterTag = async ({ selectedTags }) => {
    await cascadeCustomerRequestTagsToJobsAndWorkflow({
      customerRequestIds: [customerRequest.id],
      selectedTags
    });
  };

  const customers = useMemo(
    () => customerRequest.customers || [],
    [customerRequest.customers]
  );
  const customerRequestJobs = useMemo(
    () => customerRequest.customerRequestJobs || [],
    [customerRequest.customerRequestJobs]
  );

  const jobs = useMemo(
    () =>
      customerRequestJobs.map(customerRequestJob => {
        return customerRequestJob.job;
      }),
    [customerRequestJobs]
  );

  const customersTableData = useMemo(
    () =>
      customers.map(customer => {
        return {
          key: customer.user.id,
          name: customer.user.username,
          email: customer.user.email
        };
      }),
    [customers]
  );

  const jobsTableData = useMemo(
    () =>
      jobs.map(job => ({
        key: job.id,
        name: job.name,
        priorityTypeCode: job.priorityTypeCode,
        dueDate: formatDate(job.dueDate)
      })),
    [jobs]
  );

  const additionalCards = useMemo(
    () => (
      <div
        key="customer-request-cards"
        style={{
          display: "flex",
          flexWrap: "wrap"
        }}
      >
        <div style={{ flex: 1, padding: 10 }}>
          <CollapsibleCard title="Users" icon="people">
            {customers.length ? (
              <DataTable
                compact
                isSimple
                formName="jobsCustomersTable"
                schema={customerColumns}
                entities={customersTableData}
              />
            ) : (
              <div style={{ textAlign: "center" }}>No assigned customers.</div>
            )}
          </CollapsibleCard>
          <CollapsibleCard
            title="Jobs"
            className="customer-request-jobs-section"
            icon="build"
            openTitleElements={!readOnly && newJobButton()}
          >
            {jobs.length ? (
              <DataTable
                compact
                isSimple
                formName="jobsCustomerRequest"
                schema={jobColumns}
                entities={jobsTableData}
                onDoubleClick={onRowDoubleClick}
              />
            ) : (
              <div style={{ textAlign: "center" }}>No assigned jobs.</div>
            )}
          </CollapsibleCard>
          <CollapsibleCard
            title="Request Type Details"
            className="request-type-info-section"
            icon="info-sign"
          >
            <RecordInfoDisplay
              recordInfo={[
                ["Request Type", customerRequest.requestType.name],
                ["Description", customerRequest.requestType.description],
                ["Instructions", customerRequest.requestType.instructions]
              ]}
            />
          </CollapsibleCard>
          {!!customerRequest.customerRequestStrains.length && (
            <CollapsibleCard title="Relevant Strains">
              <DataTable
                isSimple
                formName="relevantStrains"
                schema={[
                  {
                    displayName: "Name",
                    path: "name",
                    render: (name, r) => {
                      return <Link to={`/strains/${r.id}`}>{name}</Link>;
                    }
                  },
                  {
                    displayName: "Type",
                    path: "strainType.name"
                  }
                ]}
                entities={customerRequest.customerRequestStrains.map(
                  s => s.strain
                )}
              />
            </CollapsibleCard>
          )}
          {!!customerRequest.customerRequestSequences.length && (
            <CollapsibleCard title="Relevant Sequences">
              <DataTable
                isSimple
                formName="relevantSequences"
                schema={[
                  {
                    displayName: "Name",
                    path: "name",
                    render: (name, r) => {
                      return <Link to={`/sequences/${r.id}`}>{name}</Link>;
                    }
                  },
                  {
                    displayName: "Type",
                    path: "sequenceType.name"
                  }
                ]}
                entities={customerRequest.customerRequestSequences.map(
                  s => s.sequence
                )}
              />
            </CollapsibleCard>
          )}
        </div>
        <div style={{ flex: 1, padding: 10 }}>
          <CollapsibleCard
            title="Associated Files"
            className="customer-request-inputs-section"
            icon="document"
          >
            <AssociatedFilesSection
              noHeader
              files={customerRequest.customerRequestInputFiles}
              refetch={refetchCustomerRequest}
              fileTableName="customerRequestInputFile"
              record={customerRequest}
            />
          </CollapsibleCard>

          <CommentCard currentUser={currentUser} record={customerRequest} />
        </div>
      </div>
    ),
    [
      currentUser,
      customerRequest,
      customers.length,
      customersTableData,
      jobs.length,
      jobsTableData,
      newJobButton,
      onRowDoubleClick,
      readOnly,
      refetchCustomerRequest
    ]
  );

  const recordInfo = useMemo(
    () => [
      [
        "Feedback",
        <Linkify key="linked">{customerRequest.outputDescription}</Linkify>
      ],
      ["Primary User", get(customerRequest, "primaryCustomer.user.username")],
      ["Status", customerRequest.customerRequestStatusType.name],
      [
        "Due Date",
        customerRequest.dueDate && formatDate(customerRequest.dueDate)
      ]
    ],
    [customerRequest]
  );

  return (
    <div className="record-container-with-cards">
      <AbstractRecord
        {...props}
        inCard
        recordInfo={recordInfo}
        recordName="customerRequest"
        afterTag={afterTag}
        updateShowFunction={updateShowFunction}
        additionalCards={additionalCards}
      />
    </div>
  );
};

export default recordViewEnhancer(customerRequestFragment)(
  CustomerRequestRecordView
);
