/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import { branch, compose, withProps } from "recompose";
import { withTableParams } from "@teselagen/ui";
import withQuery from "../withQuery";
import { reduxForm } from "redux-form";
import type { InjectedWithQueryProps } from "../withQuery/types";
import type { WithQueryOptionsOptions } from "../withQuery/utils";
import type { InjectedFormProps } from "redux-form";
import type { DocumentNode } from "graphql";
import type { InjectedWithTableParamsProps } from "@teselagen/ui";

type TableParamOptions = {};

type Props = {
  passedName: string;
  tableParamOptions: TableParamOptions;
  asReactSelect: boolean;
  fragment?: DocumentNode | [string, string];
  isLocalCall?: boolean;
  entities: any[];
  queryOptions: WithQueryOptionsOptions;
  useHasura?: boolean;
};

type withPropsInjected = {
  form: string;
  formName: string;
} & TableParamOptions;

type AllProps = Props & withPropsInjected & InjectedFormProps<any, Props>;

const WithQueryWrapper = (InnerComp: React.FC) => {
  return function WithQueryWrapper(props: AllProps) {
    const { isLocalCall = !props.fragment } = props;
    const Inner = useMemo(() => {
      const addTableParams = withTableParams<AllProps>({
        withSelectedEntities: true,
        noOrderError: true,
        doNotCoercePageSize: true,
        defaults: {
          order: ["-modified"]
        },
        entities: props.entities,
        isLocalCall
      });

      // Only perform query if fragment is present
      if (props.fragment) {
        return compose<
          InjectedWithTableParamsProps & AllProps & InjectedWithQueryProps,
          AllProps
        >(
          addTableParams,
          withQuery(props.fragment, {
            isPlural: true,
            options: props.queryOptions,
            useHasura: props.useHasura
          })
        )(InnerComp);
      }

      return addTableParams(InnerComp);
    }, [
      isLocalCall,
      props.entities,
      props.fragment,
      props.queryOptions,
      props.useHasura
    ]);

    return <Inner {...props} />;
  };
};

export default compose(
  withProps<withPropsInjected, Props>(props => {
    const { passedName, tableParamOptions } = props;
    return {
      form: passedName + "DataTable",
      formName: passedName + "DataTable",
      ...tableParamOptions
    };
  }),
  branch<Props>(props => props.asReactSelect, reduxForm({})),
  WithQueryWrapper
);
