/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";

export const labFragment = gql`
  fragment labFragment on lab {
    id
    name
    color
    labRoles {
      id
      roleCode
      userId
      user {
        id
        username
      }
    }
    emailDomain
  }
`;
