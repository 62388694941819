/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

interface BasePcrReaction {
  id: number;
  template_name?: string;
  alt_template_name?: string;
  forward_primer?: string;
  reverse_primer?: string;
  note?: string;
  primer_mean_tm_full_length?: number;
  primer_delta_tm_full_length?: number;
  // seq_obj?: SeqObj; // Uncomment and type this if SeqObj is defined
}

interface OptimalAnnealingTemperatureCase extends BasePcrReaction {
  optimal_annealing_temperature: number;
  primer_mean_tm?: undefined;
  primer_delta_tm?: undefined;
}

interface PrimerTmCase extends BasePcrReaction {
  optimal_annealing_temperature?: undefined;
  primer_mean_tm: number;
  primer_delta_tm: number;
}

type PcrReactionType = OptimalAnnealingTemperatureCase | PrimerTmCase;

class PcrReaction {
  id: number;
  template_name?: string;
  alt_template_name?: string;
  forward_primer?: string;
  reverse_primer?: string;
  note?: string;
  primer_mean_tm_full_length?: number;
  primer_delta_tm_full_length?: number;
  primer_mean_tm?: number;
  primer_delta_tm?: number;
  optimal_annealing_temperature: number;
  // seq_obj: SeqObj;
  constructor({
    id,
    template_name,
    alt_template_name,
    forward_primer,
    reverse_primer,
    note,
    primer_mean_tm_full_length,
    primer_delta_tm_full_length,
    primer_mean_tm,
    primer_delta_tm,
    optimal_annealing_temperature
    // seq_obj
  }: PcrReactionType) {
    // I'm not sure if all these fields are necessary.
    this.id = id;
    this.template_name = template_name;
    this.alt_template_name = alt_template_name;
    this.forward_primer = forward_primer;
    this.reverse_primer = reverse_primer;
    this.note = note;
    this.primer_mean_tm_full_length = primer_mean_tm_full_length;
    this.primer_delta_tm_full_length = primer_delta_tm_full_length;
    this.primer_mean_tm = primer_mean_tm;
    this.primer_delta_tm = primer_delta_tm;

    if (optimal_annealing_temperature) {
      this.optimal_annealing_temperature = optimal_annealing_temperature;
    } else if (primer_mean_tm && primer_delta_tm) {
      this.optimal_annealing_temperature =
        primer_mean_tm - 0.5 * primer_delta_tm + 2.0;
    }
  }

  /**
   * Sort an array of PCR reactions in ascending order of their optimal annealing temperature.
   */
  static sortByOptimumAnnealingTemperature = (pcrReactions: PcrReaction[]) => {
    // Make a shallow copy so as not to mutate the original array.
    pcrReactions = [...pcrReactions];

    // Sort ascending.
    pcrReactions.sort(
      (pcr1, pcr2) =>
        pcr1.optimal_annealing_temperature - pcr2.optimal_annealing_temperature
    );

    return pcrReactions;
  };
}

export default PcrReaction;
