/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Button, Classes, Intent } from "@blueprintjs/core";
import { inject, observer } from "mobx-react";
import { loop } from "lodash";
export default inject("mainStore")(
  observer(
    ({
      mainStore: {
        modal: {
          hideModal,
          noCancel,
          intent,
          secondaryIntent,
          secondaryClassName,
          secondaryText,
          text,
          className,
          submitting
        }
      },
      disabled,
      loading,
      onClick = loop,
      secondaryAction = loop,
      additionalButtons = ""
    }) => (
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {!noCancel && (
            <Button
              intent={Intent[secondaryIntent]}
              className={Classes.MINIMAL + " " + secondaryClassName}
              text={secondaryText}
              onClick={hideModal}
            />
          )}
          {additionalButtons}
          <Button
            text={text}
            intent={Intent[intent]}
            type="submit"
            className={className}
            onClick={secondaryAction || onClick}
            disabled={disabled}
            loading={loading || submitting}
          />
        </div>
      </div>
    )
  )
);
