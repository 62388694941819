/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";

interface SelectionLayerProps {
  /**
   * One of the corners of the selection region. It doesn't have to be
   * any particular corner. The x and y values are relative to the plate
   * background.
   */
  corner1: { x: number | undefined; y: number | undefined };
  /**
   * The corner opposite of `corner1` in the selection region.
   */
  corner2: { x: number | undefined; y: number | undefined };
  onMouseMove: (e: React.MouseEvent) => void;
  onMouseUp: (e: React.MouseEvent) => void;
}

const SelectionLayer = ({
  corner1: c1,
  corner2: c2,
  onMouseMove,
  onMouseUp
}: SelectionLayerProps) => {
  const [parentEl, setParentEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setParentEl(document.getElementById("root"));
  }, []);

  if (
    !parentEl ||
    c1.x === undefined ||
    c1.y === undefined ||
    c2.x === undefined ||
    c2.y === undefined
  )
    return null;

  return createPortal(
    <svg
      className="above-dialog"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: window.innerWidth,
        height: window.innerHeight
      }}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
    >
      <rect
        x={Math.min(c1.x, c2.x)}
        y={Math.min(c1.y, c2.y)}
        width={Math.abs(c1.x - c2.x)}
        height={Math.abs(c1.y - c2.y)}
        fill="#AD99FF"
        fillOpacity={0.25}
        stroke="#634DBF"
        strokeWidth={1}
        rx={5}
        ry={5}
        style={{
          pointerEvents: "none"
        }}
      />
    </svg>,
    parentEl
  );
};

export default SelectionLayer;
