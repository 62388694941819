/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { WorkflowRun } from "../types/WorkflowRun";

export function addWorkflowToolOutputs(workflowRun: WorkflowRun) {
  const outputToolIoItemHelperMap: {
    [id: string]: {
      id: string;
      workflowToolOutputDefinitionId: string;
      workflowTool: { id: string; workflowToolStatusCode: string };
    };
  } = {};
  workflowRun.workflowTools.forEach(tool => {
    tool.workflowToolOutputs.forEach(output => {
      if (output.ioItemId) {
        outputToolIoItemHelperMap[output.ioItemId] = {
          id: output.id,
          workflowToolOutputDefinitionId:
            output.workflowToolOutputDefinition.id,
          workflowTool: {
            id: tool.id,
            workflowToolStatusCode: tool.workflowToolStatusCode
          }
        };
      }
    });
  });
  return {
    ...workflowRun,
    workflowTools: workflowRun.workflowTools.map(tool => {
      return {
        ...tool,
        workflowToolInputs: tool.workflowToolInputs.map(input => {
          return {
            ...input,
            workflowToolInputIoItems: input.workflowToolInputIoItems.map(
              inputIoItem => {
                if (inputIoItem.ioItem) {
                  let ioItem = inputIoItem.ioItem;
                  const workflowToolOutput =
                    outputToolIoItemHelperMap[ioItem.id!];
                  if (workflowToolOutput) {
                    ioItem = {
                      ...ioItem,
                      workflowToolOutput
                    };
                  }
                  return {
                    ...inputIoItem,
                    ioItem
                  };
                }
                return inputIoItem;
              }
            )
          };
        })
      };
    })
  };
}
