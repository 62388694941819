/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState, useReducer, useMemo } from "react";
import {
  Classes,
  Checkbox,
  Intent,
  Icon,
  Tooltip,
  Position
} from "@blueprintjs/core";
import { find, get, identity } from "lodash";
import classNames from "classnames";
import { DataTable, DialogFooter, wrapDialog } from "@teselagen/ui";
import { TEST_LABELS } from "../../utils/experimentData";

const ACCEPT_MAPPING = "ACCEPT_MAPPING";
const REJECT_MAPPING = "REJECT_MAPPING";
const ACCEPT_ALL_MAPPING = "ACCEPT_ALL_MAPPING";
const REJECT_ALL_MAPPING = "REJECT_ALL_MAPPING";

const mappingReducer = (state, action) => {
  switch (action.type) {
    case ACCEPT_ALL_MAPPING:
      return state.map(mapping => ({
        ...mapping,
        accepted: mapping.hasSuggestion && !mapping.disabled
      }));
    case REJECT_ALL_MAPPING:
      return state.map(mapping => ({
        ...mapping,
        accepted: false
      }));
    case ACCEPT_MAPPING:
      return state.map(mapping => ({
        ...mapping,
        accepted:
          mapping.name === action.payload.name
            ? !mapping.accepted
            : mapping.accepted
      }));
    case REJECT_MAPPING:
      return state.map(mapping => ({
        ...mapping,
        accepted:
          mapping.name === action.payload.name
            ? !mapping.accepted
            : mapping.accepted
      }));
    default:
      return state;
  }
};

const SmartMapperDialog = ({
  hideModal,
  mappings,
  submitMappings,
  customizeSchema = identity
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [acceptAll, setAcceptAll] = useState(false);
  const [reviewedMappings, reviewedMappingsDispatch] = useReducer(
    mappingReducer,
    mappings
  );

  const mappingSuggestions = reviewedMappings.map(mapping => ({
    ...mapping,
    name: get(mapping, "name"),
    className: get(mapping, "className"),
    subClassName: get(mapping, "subClassName")
  }));

  async function handleSubmit() {
    setSubmitting(true);
    await submitMappings(reviewedMappings);
    hideModal();
    setSubmitting(false);
  }

  const schema = useMemo(() => {
    const renderAcceptCheckBox = suggestedHeader => {
      return (
        <Checkbox
          style={{ textAlignLast: "center" }}
          disabled={!suggestedHeader.hasSuggestion || suggestedHeader.disabled}
          checked={suggestedHeader.hasSuggestion && suggestedHeader.accepted}
          onChange={() => {
            const mapping = find(
              reviewedMappings,
              mapping => mapping.name === suggestedHeader.name
            );
            if (mapping) {
              reviewedMappingsDispatch({
                type: mapping.accepted ? REJECT_MAPPING : ACCEPT_MAPPING,
                payload: { name: mapping.name }
              });
            }
          }}
        />
      );
    };

    const renderColumnHeaderCell = ({ name, disabled, tooltipMessage }) => (
      <p style={{ marginTop: "5px" }}>
        {tooltipMessage ? (
          <Tooltip
            position={Position.TOP}
            className={Classes.TOOLTIP_INDICATOR}
            content={tooltipMessage}
          >
            {name}
          </Tooltip>
        ) : (
          name
        )}
        <Icon
          key="icon-enabled"
          icon={disabled ? "cross" : "small-tick"}
          style={{ marginLeft: 5 }}
          intent={disabled ? Intent.DANGER : Intent.SUCCESS}
        />
      </p>
    );

    const renderCell = value => (
      <div>
        {value ? (
          <p style={{ marginTop: "5px" }}>{value}</p>
        ) : (
          <div>
            <Icon icon="info-sign" iconSize={15} />
            <span style={{ fontSize: 12, marginLeft: 5 }}>No match found</span>
          </div>
        )}
      </div>
    );

    return customizeSchema([
      {
        path: "acceptSuggestion",
        displayName: "Accept",
        width: 60,
        render: (_, row) => renderAcceptCheckBox(row)
      },
      {
        path: "name",
        displayName: "Column",
        render: (_, row) => renderColumnHeaderCell(row)
      },
      {
        path: "className",
        displayName: TEST_LABELS.class,
        render: (_, row) => renderCell(row.className)
      },
      {
        path: "subClassName",
        displayName: TEST_LABELS.subClass,
        render: (_, row) => renderCell(row.subClassName)
      }
    ]);
  }, [customizeSchema, reviewedMappings]);

  return (
    <>
      <div className={classNames(Classes.DIALOG_BODY)}>
        <div>
          <div style={{ margin: "20px 20px 0 20px" }}>
            <p>These are the mapping suggestions. Please inspect them.</p>
            <br />
            You can either accept them all or one by one.
            <br />
            <div>
              <Checkbox
                style={{
                  margin: "20px 0 -40px 0",
                  // This prevents the DataTable to cut on top the CheckBox
                  // TODO: Implement the CheckBox as a custom header of the DataTable
                  zIndex: 10
                }}
                label="Accept all"
                checked={acceptAll}
                onChange={() => {
                  setAcceptAll(!acceptAll);
                  reviewedMappingsDispatch({
                    type: acceptAll ? REJECT_ALL_MAPPING : ACCEPT_ALL_MAPPING
                  });
                }}
              />
              <DataTable
                formName="smartMapperSuggestions"
                schema={schema}
                entities={mappingSuggestions}
                withSearch={false}
                isInfinite
                noFooter
              />
            </div>
          </div>
        </div>
      </div>
      <DialogFooter
        submitting={submitting}
        text="Submit"
        disabled={reviewedMappings.filter(el => el.accepted).length === 0}
        hideModal={hideModal}
        onClick={handleSubmit}
      />
    </>
  );
};

export default wrapDialog({ style: { width: 800 } })(SmartMapperDialog);
