/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";
import { taggedItemFragment } from "./taggedItemFragment.gql";

export const containerArrayAdditionalFragment = gql`
  fragment containerArrayAdditionalFragment on containerArray {
    id
    name
    barcode {
      id
      barcodeString
    }
    containerArrayType {
      id
      containerFormat {
        code
        columnCount
        rowCount
        is2DLabeled
      }
    }
    aliquotContainers {
      id
      rowPosition
      columnPosition
      aliquot {
        id
        sample {
          id
          name
          taggedItems {
            ...taggedItemFragment
          }
          material {
            id
            name
            polynucleotideMaterialSequence {
              id
              size
            }
            taggedItems {
              ...taggedItemFragment
            }
          }
        }
      }
    }
  }
  ${taggedItemFragment}
`;

export const materialAdditionalFragment = gql`
  fragment materialAdditionalFragment on material {
    id
    name
    materialTypeCode
    materialType {
      code
      name
    }
    polynucleotideMaterialSequence {
      id
      size
    }
    updatedAt
  }
`;

export const plateMapAdditionalFragment = gql`
  fragment plateMapAdditionalFragment on plateMap {
    id
    name
    type
    plateMapGroup {
      id
      name
      containerFormat {
        code
        name
        rowCount
        columnCount
      }
    }
    plateMapItems {
      id
      rowPosition
      columnPosition
      plateMapItemTypeCode
      inventoryItem {
        id
        material {
          id
          name
          taggedItems {
            ...taggedItemFragment
          }
        }
        lot {
          id
          name
          taggedItems {
            ...taggedItemFragment
          }
        }
        sample {
          id
          name
          taggedItems {
            ...taggedItemFragment
          }
        }
        additiveMaterial {
          id
          name
          taggedItems {
            ...taggedItemFragment
          }
        }
      }
      j5Item {
        id
        j5PcrReaction {
          id
          name
        }
      }
    }
  }
  ${taggedItemFragment}
`;

export const sampleAdditionalFragment = gql`
  fragment sampleAdditionalFragment on sample {
    id
    name
    sampleType {
      code
      name
    }
    material {
      id
      polynucleotideMaterialSequence {
        id
        size
      }
    }
  }
`;
