/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import {
  COMMON_LAB_ID,
  labStorageKey,
  lastSelectedLabKey
} from "./constants.js";

/**
 * First, it looks for an active lab id in the browser's sessionStorage
 * which is preserved for tab duplication and tab reloading but not for new tabs.
 *
 * Then it looks for the lastSelectedLabId but now in the local storage, which
 * is preserved for the whole browser window.
 *
 */
/**
 *
 * @returns { string | undefined}
 */
export const getActiveLabId = () => {
  const activeLabId =
    sessionStorage.getItem(labStorageKey) ||
    localStorage.getItem(lastSelectedLabKey);

  // NOTE: Active Lab ID cannot be the Common Lab, and there must be an active lab set
  if (activeLabId && activeLabId !== COMMON_LAB_ID) {
    return activeLabId;
  }

  // We shouldn't get here, but in this case the app will logout the current user
  // Caugth in labUtils
  return undefined;
};
