/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback } from "react";
import { get, times, flatten, isEmpty } from "lodash";
import { showConfirmationDialog } from "@teselagen/ui";
import { Button, Intent } from "@blueprintjs/core";
import { change, reset, SubmissionError } from "redux-form";
import Big from "big.js";
import StepForm from "../../../../src-shared/StepForm";
import validate, { maxStandardizedDesiredConcentration } from "./validate";
import {
  SelectPlates,
  NormalizationParameters,
  DiluentContainer,
  ReviewWorklists
} from "./Steps";
import {
  getSourceVolumetricUnit,
  getAliquotContainersToNormalize
} from "./utils";
import {
  generateEmptyWells,
  getUploadAliquotContainers,
  sortAliquotContainers
} from "../../../../../tg-iso-lims/src/utils/plateUtils";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { addBarcodesToRecords } from "../../../../../tg-iso-lims/src/utils/barcodeUtils";
import unitGlobals from "../../../../../tg-iso-lims/src/unitGlobals";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import containerArrayNormalizationFragment from "../../../graphql/fragments/containerArrayNormalizationFragment";
import { useDispatch } from "react-redux";
import "./style.css";

const steps = [
  {
    title: "Select Inputs",
    Component: SelectPlates,
    withCustomFooter: true
  },
  {
    title: "Normalization Parameters",
    Component: NormalizationParameters
  },
  {
    title: "Diluent Container",
    Component: DiluentContainer
  },
  {
    title: "Review Worklists",
    Component: ReviewWorklists,
    nextButton: (
      <Button type="submit" text="Save Worklists" intent={Intent.SUCCESS} />
    )
  }
];

const NormalizationTool = ({
  toolIntegrationProps,
  initialValues,
  toolSchema
}) => {
  const dispatch = useDispatch();
  const changeFieldValue = useCallback(
    (fieldName, value) => dispatch(change(toolSchema.code, fieldName, value)),
    [dispatch, toolSchema.code]
  );

  const resetForm = useCallback(
    () => dispatch(reset(toolSchema.code)),
    [dispatch, toolSchema.code]
  );

  const onSubmit = useCallback(
    async values => {
      const {
        containerArrays,
        containerArrayToNormalize: sourceContainerArray,
        transferSourcePlateToNormalizedPlate,
        destinationContainerArrayName,
        destinationContainerArrayBarcode,
        containerArrayTransferWorklistName,
        normalizationWorklistName,
        desiredTransferVolume,
        desiredTransferVolumetricUnitCode,
        intermediateContainerName,
        intermediateContainerType,
        numberOfDiluentContainers,
        diluentAliquotContainers,
        uniformDiluentAliquotContainerVolume,
        generateBarcodes,
        containerArrayType: destinationContainerArrayType,
        aliquotContainerType: destinationAliquotContainerType,
        generateTubeBarcodes,
        shouldFillRack,
        numTubesToFillRack,
        worklistMap = {},
        sourceTransferOrder,
        destinationTransferOrder
      } = values;
      const acsToUse = getAliquotContainersToNormalize(values);
      let aliquotContainers;
      let sortedSourceAcs = acsToUse;
      const isDifferentFormat =
        destinationContainerArrayType &&
        destinationContainerArrayType.containerFormat.code !==
          sourceContainerArray.containerArrayType.containerFormat.code;
      if (isDifferentFormat) {
        sortedSourceAcs = sortAliquotContainers(acsToUse, sourceTransferOrder);
      }
      if (transferSourcePlateToNormalizedPlate) {
        const newAliquotContainers = [];
        const emptyWells = generateEmptyWells(
          destinationContainerArrayType.containerFormat
        );
        const sortedDestWells = sortAliquotContainers(
          emptyWells,
          destinationTransferOrder
        );
        sortedSourceAcs.forEach(ac => {
          if (ac.aliquot) {
            const destWell = sortedDestWells.shift();
            // if they are not a different format we just want the same positions as the source
            // if they are different then use the sorted well positions
            const positionEnt = isDifferentFormat ? destWell : ac;
            const newAc = {
              rowPosition: positionEnt.rowPosition,
              columnPosition: positionEnt.columnPosition,
              aliquotContainerTypeCode: destinationContainerArrayType.isPlate
                ? destinationContainerArrayType.aliquotContainerType.code
                : destinationAliquotContainerType.code
            };
            newAliquotContainers.push(newAc);
          }
        });
        aliquotContainers = getUploadAliquotContainers({
          newAliquotContainers,
          containerArrayType: destinationContainerArrayType,
          aliquotContainerType: destinationAliquotContainerType,
          shouldFillRack,
          numTubesToFillRack
        });
      }
      const sourcePlateToNormalizedPlate = {};
      const createdWorklists = [];
      try {
        let destinationContainerArray;
        if (transferSourcePlateToNormalizedPlate) {
          const destinationContainerArrays = await safeUpsert(
            [
              "containerArray",
              `id
          aliquotContainers {
            id
            rowPosition
            columnPosition
            aliquotContainerType {
              code
              volumetricUnitCode
            }
          }`
            ],
            {
              containerArrayTypeId: destinationContainerArrayType.id,
              name: destinationContainerArrayName,
              barcode: destinationContainerArrayBarcode
                ? { barcodeString: destinationContainerArrayBarcode }
                : null,
              aliquotContainers
            }
          );
          if (generateBarcodes) {
            await addBarcodesToRecords(destinationContainerArrays);
          }
          if (generateTubeBarcodes) {
            const createdAliquotContainers = [];
            destinationContainerArrays.forEach(plate => {
              plate.aliquotContainers.forEach(ac =>
                createdAliquotContainers.push(ac)
              );
            });
            await addBarcodesToRecords(createdAliquotContainers);
          }
          destinationContainerArray = destinationContainerArrays[0];
        }
        let transferWorklist;
        if (destinationContainerArray) {
          let sortedDestinationWells;
          if (isDifferentFormat) {
            sortedDestinationWells = sortAliquotContainers(
              [...destinationContainerArray.aliquotContainers],
              destinationTransferOrder
            );
          }

          transferWorklist = await safeUpsert("worklist", {
            name: containerArrayTransferWorklistName,
            worklistTransfers: sortedSourceAcs.reduce(
              (acc, aliquotContainer) => {
                if (!get(aliquotContainer, "aliquot")) return acc;
                let destinationAliquotContainer;
                if (isDifferentFormat) {
                  destinationAliquotContainer = sortedDestinationWells.shift();
                } else {
                  destinationAliquotContainer =
                    destinationContainerArray.aliquotContainers.find(ac => {
                      return (
                        ac.rowPosition === aliquotContainer.rowPosition &&
                        ac.columnPosition === aliquotContainer.columnPosition
                      );
                    });
                }
                sourcePlateToNormalizedPlate[aliquotContainer.id] =
                  destinationAliquotContainer;
                const transfer = {
                  volume: desiredTransferVolume,
                  volumetricUnitCode: desiredTransferVolumetricUnitCode,
                  sourceAliquotContainerId: aliquotContainer.id,
                  destinationAliquotContainerId: destinationAliquotContainer.id
                };
                return acc.concat(transfer);
              },
              []
            )
          });
        }
        if (transferWorklist) {
          createdWorklists.push({
            ...transferWorklist[0],
            name: containerArrayTransferWorklistName
          });
        }
        const diluentContainerUnitCode =
          intermediateContainerType.aliquotContainerType.volumetricUnitCode;
        const diluentContainerUnit =
          unitGlobals.volumetricUnits[diluentContainerUnitCode];

        const diluentVolumeInUnit = new Big(
          uniformDiluentAliquotContainerVolume
        )
          .div(new Big(diluentContainerUnit.liters))
          .toString();
        const normalizationAliquotContainers = generateEmptyWells(
          get(intermediateContainerType, "containerFormat"),
          {
            aliquotContainerTypeCode:
              intermediateContainerType.aliquotContainerTypeCode,
            aliquot: {
              aliquotType: "additive",
              volume: diluentVolumeInUnit,
              volumetricUnitCode: diluentContainerUnitCode,
              sample: {
                name: "diluent",
                sampleTypeCode: "ADDITIVE_SAMPLE"
              }
            }
          }
        );
        const diluentContainersToCreate = times(
          numberOfDiluentContainers,
          i => {
            return {
              name: intermediateContainerName + ` ${i + 1}`,
              containerArrayTypeId: intermediateContainerType.id,
              displayFilter: "DILUENT",
              aliquotContainers: normalizationAliquotContainers
            };
          }
        );
        const createdDiluentContainers = await safeUpsert(
          [
            "containerArray",
            `id
          aliquotContainers {
            id
          }`
          ],
          diluentContainersToCreate
        );
        const createdAliquotContainers = createdDiluentContainers.reduce(
          (acc, diluentContainer) => {
            return acc.concat(diluentContainer.aliquotContainers);
          },
          []
        );

        const { volumetricUnit, volumetricUnitCode } =
          getSourceVolumetricUnit(sourceContainerArray);

        const worklistTransfers = [];
        diluentAliquotContainers.forEach((diluentContainer, i) => {
          diluentContainer.transfers.forEach(transfer => {
            const aliquotContainerId = get(transfer, "aliquotContainer.id");
            const worklistTransfer = {
              volume: new Big(transfer.transferVolume)
                .div(new Big(volumetricUnit.liters))
                .toString(),
              volumetricUnitCode,
              sourceAliquotContainerId: get(
                createdAliquotContainers,
                `[${i}].id`
              ),
              destinationAliquotContainerId: isEmpty(
                sourcePlateToNormalizedPlate
              )
                ? aliquotContainerId
                : sourcePlateToNormalizedPlate[aliquotContainerId].id
            };
            worklistTransfers.push(worklistTransfer);
          });
        });

        const normalizationWorklist = await safeUpsert("worklist", {
          name: normalizationWorklistName,
          worklistTransfers
        });
        createdWorklists.push({
          ...normalizationWorklist[0],
          name: normalizationWorklistName
        });
        window.toastr.success("Successfully created worklists.");
        const newWorklistMap = {
          ...worklistMap,
          [sourceContainerArray.id]: createdWorklists.map(({ id }) => ({ id }))
        };
        const incomplete = containerArrays.some(
          ({ id }) => !newWorklistMap[id]
        );
        const notNormalized = [];
        containerArrays.forEach(containerArray => {
          if (!newWorklistMap[containerArray.id])
            notNormalized.push(containerArray.name);
        });
        const allWorklists = flatten(Object.values(newWorklistMap));
        const toolOutput = {
          worklists: allWorklists
        };
        if (incomplete) {
          const continueNormalization = await showConfirmationDialog({
            text: `There are ${notNormalized.length} plate${
              notNormalized.length === 1 ? "" : "s"
            } (${notNormalized.join(
              ", "
            )}) that have not been normalized. Would you like to continue normalization?`,
            cancelButtonText: "Stop Now",
            confirmButtonText: "Continue Normalization"
          });
          if (continueNormalization) {
            resetForm();
            changeFieldValue("worklistMap", newWorklistMap);
            changeFieldValue("containerArrays", containerArrays);
            return false;
          } else {
            return toolOutput;
          }
        } else {
          return toolOutput;
        }
      } catch (error) {
        console.error("error:", error);
        throw new SubmissionError({ _error: "Error creating worklists." });
      }
    },
    [changeFieldValue, resetForm]
  );

  const warn = useCallback(values => {
    const errorForMolarityOrConcentration = maxStandardizedDesiredConcentration(
      {
        ...values,
        forWarning: true
      }
    );
    const warnings = {};
    if (
      errorForMolarityOrConcentration &&
      errorForMolarityOrConcentration.warning
    ) {
      warnings.desiredConcentration = errorForMolarityOrConcentration.warning;
      warnings.desiredMolarity = errorForMolarityOrConcentration.warning;
      warnings.desiredCellConcentration =
        errorForMolarityOrConcentration.warning;
    }
    return warnings;
  }, []);

  return (
    <StepForm
      steps={steps}
      onSubmit={onSubmit}
      validate={validate}
      warn={warn}
      enableReinitialize
      toolSchema={toolSchema}
      toolIntegrationProps={toolIntegrationProps}
      initialValues={initialValues}
    />
  );
};

export default withWorkflowInputs(containerArrayNormalizationFragment)(
  NormalizationTool
);
