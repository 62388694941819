/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { InjectedFormProps, reduxForm } from "redux-form";
import { Callout, Classes } from "@blueprintjs/core";
import { DialogFooter, wrapDialog } from "@teselagen/ui";
import type { InjectedWrapDialogProps } from "@teselagen/ui";
import FillDirectionSelect from "../../../../FillDirectionSelect";
import GenericSelect from "../../../../../../src-shared/GenericSelect";
import { modelTypeMap } from "../../../../../../../tg-iso-shared/src/utils/modelTypeMap";
import modelNameToReadableName from "../../../../../../../tg-iso-shared/src/utils/modelNameToReadableName";
import type { Directions } from "../../../../../utils/plateUtils/getWellPositionsGivenWellCountAndDirection";

interface FormData {
  extendedProperty: { id: string; name: string };
  fillDirection: Directions;
}

interface Props {
  model: keyof typeof modelTypeMap;
  handleDistribute: (values: FormData) => void;
}

const ChooseDistributeByExtendedPropertyOptions = ({
  hideModal,
  handleSubmit,
  submitting,
  model,
  handleDistribute
}: InjectedWrapDialogProps<Props> &
  InjectedFormProps<FormData, Props> &
  Props) => {
  const onSubmit = async (values: {
    extendedProperty: { id: string; name: string };
    fillDirection: Directions;
  }) => {
    try {
      await handleDistribute(values);
      hideModal();
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error(error.message || "Error Distributing");
    }
  };

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <Callout intent="primary" style={{ marginBottom: 10 }}>
          Please choose an extended property for distribution.{" "}
          {modelNameToReadableName(model, { upperCase: true, plural: true })}{" "}
          will be grouped onto plate maps by their extended property values.
        </Callout>
        <Callout intent="warning" style={{ marginBottom: 10 }}>
          This will override current plate maps.
        </Callout>
        <GenericSelect
          asReactSelect
          isRequired
          label="Extended Property"
          name="extendedProperty"
          fragment={["extendedProperty", "id name"]}
          additionalFilter={{
            modelTypeCode: modelTypeMap[model]
          }}
        />
        <div style={{ marginTop: 15, maxWidth: 250 }}>
          <FillDirectionSelect />
        </div>
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        text="Distribute"
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
};

export default compose<
  InjectedWrapDialogProps<Props> & InjectedFormProps<FormData, Props> & Props,
  Props
>(
  wrapDialog({
    title: "Distribute Options"
  }),
  reduxForm<FormData>({
    form: "chooseDistExtPropForm"
  })
)(ChooseDistributeByExtendedPropertyOptions);
