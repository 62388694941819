/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export const aliquotUnassignedViewFragment = gql`
  fragment aliquotUnassignedViewFragment on aliquotUnassignedView {
    id
    isUnassigned
    fullPath
    aliquot {
      id
      mass
      massUnitCode
      volume
      volumetricUnitCode
      concentration
      concentrationUnitCode
      sample {
        id
        name
      }
      taggedItems {
        id
        tag {
          id
          name
          color
        }
        tagOption {
          id
          name
          color
        }
      }
    }
    updatedAt
  }
`;
