/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

/**
 * Check if a number is a positive number
 * @param {number | string} num - The number to check
 * @returns {boolean} - True if the number is a positive number, false otherwise
 * @example
 * isValidPositiveNumber(1); // true
 * isValidPositiveNumber(1.1); // true
 * isValidPositiveNumber(-1); // false
 * isValidPositiveNumber(0); // false
 **/
export default function isValidPositiveNumber(num = "") {
  return num && Number(num) > 0 && !isNaN(Number(num));
}
