/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useMemo, useRef } from "react";
import { Field, change, formValueSelector } from "redux-form";
import { RadioGroupField } from "@teselagen/ui";
import UnitInputField from "../UnitInputField";

import {
  calculateConcentrationFromMolarity,
  calculateMolarityFromConcentration,
  defaultConcentrationUnitCode,
  defaultMolarityUnitCode,
  defaultMaterialConcentrationUnitCode
} from "../../../../tg-iso-lims/src/utils/unitUtils";
import {
  convertConcentration,
  convertMolarity
} from "../../../src-shared/utils/unitUtils";
import { useDispatch } from "react-redux";

function ConcentrationAndMolarity({
  namePrefix,
  disabled,
  input,
  meta,
  molecularWeight,
  concentrationTypes = [],
  isRequired,
  initialValues = {}
}) {
  // this will let us track the concentrationType radio group value without needing to use form values
  // (which doesn't work in tools). So no need to pass down any props from parent component
  const molarityName = namePrefix + "molarity";
  const concentrationName = namePrefix + "concentration";
  const materialConcentrationName = namePrefix + "materialConcentration";
  const molarityUnitName = namePrefix + "molarityUnitCode";
  const concentrationUnitName = namePrefix + "concentrationUnitCode";
  const materialConcentrationUnitName =
    namePrefix + "materialConcentrationUnitCode";

  const dispatch = useDispatch();

  const unitOptions = useMemo(() => {
    const _unitOptions = [];
    if (concentrationTypes.includes("concentration")) {
      _unitOptions.push({ label: "Concentration", value: "concentration" });
    }
    if (concentrationTypes.includes("molarity")) {
      _unitOptions.push({ label: "Molarity", value: "molarity" });
    }
    if (concentrationTypes.includes("materialConcentration")) {
      _unitOptions.push({
        label: "Material Concentration",
        value: "materialConcentration"
      });
    }
    return _unitOptions;
  }, [concentrationTypes]);

  const initialized = useRef(false);

  const inputField = useMemo(() => {
    if (unitOptions.length) {
      return input.value === "materialConcentration" ? (
        <UnitInputField
          disabled={disabled}
          label="Material Concentration"
          isRequired={isRequired}
          name={materialConcentrationName}
          unitName={materialConcentrationUnitName}
          unitDefault={defaultMaterialConcentrationUnitCode}
          unitType="materialConcentrationUnit"
        />
      ) : input.value === "molarity" ? (
        <UnitInputField
          disabled={disabled}
          label="Molarity"
          isRequired={isRequired}
          name={molarityName}
          unitName={molarityUnitName}
          unitDefault={defaultMolarityUnitCode}
          unitType="molarityUnit"
        />
      ) : (
        <UnitInputField
          disabled={disabled}
          label="Concentration"
          isRequired={isRequired}
          name={concentrationName}
          unitName={concentrationUnitName}
          unitDefault={defaultConcentrationUnitCode}
          unitType="concentrationUnit"
        />
      );
    }
  }, [
    concentrationName,
    concentrationUnitName,
    disabled,
    input.value,
    isRequired,
    materialConcentrationName,
    materialConcentrationUnitName,
    molarityName,
    molarityUnitName,
    unitOptions.length
  ]);

  const onFieldSubmit = useCallback(
    concentrationType => {
      if (!initialized.current) {
        initialized.current = true;
        return;
      }
      const currentState = window.teGlobalStore.getState();
      if (molecularWeight) {
        const selector = formValueSelector(meta.form);
        const molarity = selector(currentState, molarityName);
        const molarityUnit = selector(currentState, molarityUnitName);
        const concentration = selector(currentState, concentrationName);
        const concentrationUnit = selector(currentState, concentrationUnitName);
        if (concentrationType === "molarity" && concentration) {
          const molarity = calculateMolarityFromConcentration(
            concentration,
            concentrationUnit,
            molecularWeight
          );
          const molarityInProperUnit = convertMolarity(
            molarity,
            "M",
            molarityUnit || defaultMolarityUnitCode
          );
          dispatch(change(meta.form, molarityName, molarityInProperUnit));
        } else if (concentrationType === "concentration" && molarity) {
          const concentration = calculateConcentrationFromMolarity(
            molarity,
            molarityUnit,
            molecularWeight
          );
          const concentrationInProperUnit = convertConcentration(
            concentration,
            "g/L",
            concentrationUnit || defaultConcentrationUnitCode
          );
          dispatch(
            change(meta.form, concentrationName, concentrationInProperUnit)
          );
        }
      }
    },
    [
      concentrationName,
      concentrationUnitName,
      dispatch,
      meta.form,
      molarityName,
      molarityUnitName,
      molecularWeight
    ]
  );

  const defaultValue = useMemo(
    () =>
      initialValues.concentration
        ? "concentration"
        : initialValues.molarity
          ? "molarity"
          : concentrationTypes.includes("materialConcentration") &&
              initialValues.materialConcentration
            ? "materialConcentration"
            : "concentration",
    [
      concentrationTypes,
      initialValues.concentration,
      initialValues.materialConcentration,
      initialValues.molarity
    ]
  );

  return (
    <>
      {unitOptions.length > 1 && (
        <RadioGroupField
          disabled={disabled}
          defaultValue={defaultValue}
          className="tg-no-form-group-margin"
          name={namePrefix + "concentrationType"}
          inline
          options={unitOptions}
          onFieldSubmit={onFieldSubmit}
        />
      )}
      {inputField}
      {concentrationTypes.includes("cellConcentration") && (
        <UnitInputField
          disabled={disabled}
          label="Cell Concentration"
          name={namePrefix + "cellConcentration"}
          unitName={namePrefix + "cellConcentrationUnitCode"}
          unitDefault="cells/uL"
          unitType="cellConcentrationUnit"
        />
      )}
    </>
  );
}

const defaultConcentrationTypes = ["concentration", "molarity"];

function UnitFields({
  isDry,
  disabled,
  namePrefix = "",
  initialValues,
  showAllFields = false,
  concentrationTypes = defaultConcentrationTypes,
  inline,
  volumeRequired,
  massRequired,
  concentrationRequired,
  molecularWeight
}) {
  const wetFields = (
    <>
      <UnitInputField
        disabled={disabled}
        style={inline && { marginRight: 15 }}
        label="Volume"
        name={namePrefix + "volume"}
        unitName={namePrefix + "volumetricUnitCode"}
        unitDefault="uL"
        unitType="volumetricUnit"
        isRequired={volumeRequired}
      />
      {!!concentrationTypes.length && (
        <Field
          name={namePrefix + "concentrationType"}
          component={ConcentrationAndMolarity}
          isRequired={concentrationRequired}
          initialValues={initialValues}
          concentrationTypes={concentrationTypes}
          namePrefix={namePrefix}
          disabled={disabled}
          molecularWeight={molecularWeight}
        />
      )}
    </>
  );

  const dryFields = (
    <UnitInputField
      disabled={disabled}
      name={namePrefix + "mass"}
      unitName={namePrefix + "massUnitCode"}
      label="Mass"
      unitDefault="ng"
      unitType="massUnit"
      isRequired={massRequired}
    />
  );
  let toReturn;
  if (showAllFields) {
    toReturn = (
      <>
        {wetFields}
        {dryFields}
      </>
    );
  } else {
    toReturn = isDry ? dryFields : wetFields;
  }
  if (inline) {
    return <div className="tg-flex align-center">{toReturn}</div>;
  }
  return toReturn;
}

export default UnitFields;
