/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Button } from "@blueprintjs/core";
import { uniq } from "lodash";
import React from "react";
import { useTableEntities } from "@teselagen/ui";
import { assemblyPieceTableName } from "../../constants";
import { useFormValue } from "../../../../../../src-shared/hooks/useFormValue";

function SelectAssemblyPiecesButton({ change, toolSchema: { code } }) {
  const { selectTableEntities, selectedEntities } = useTableEntities(
    assemblyPieceTableName
  );
  const finalizedAPIds = useFormValue(code, "finalizedAPIds");
  return (
    <Button
      style={{ width: "100%" }}
      text={`Select ${Object.values(selectedEntities || {}).length} Pieces`}
      disabled={!Object.values(selectedEntities || {}).length}
      intent="primary"
      rightIcon="double-chevron-right"
      onClick={() => {
        const newIds = Object.values(selectedEntities || {}).map(
          p => p.entity.id
        );
        change("finalizedAPIds", uniq(newIds.concat(finalizedAPIds)));
        selectTableEntities([]);
      }}
    />
  );
}

export default SelectAssemblyPiecesButton;
