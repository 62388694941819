/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import { camelCase, get } from "lodash";
import { extendedPropertyFragment } from "../fragments/extendedPropertyFragment.gql";
import { modelTypeMap } from "../../../tg-iso-shared/src/utils/modelTypeMap";
import modelNameToLink from "../utils/modelNameToLink";
import { Link } from "react-router-dom";
import { formatDate } from "../utils/dateUtils";
import { compose, withProps } from "recompose";
import useTgQuery from "../apolloUseTgQuery";
import { ExtendedPropertyFragment } from "../fragments/extendedPropertyFragment.gql.generated";
import { WithLoadingHoc } from "../withQuery/utils";
import type { TableSchemaField } from "@teselagen/ui";
import QueryBuilder from "tg-client-query-builder";

type Schema = {
  model: string;
  fields: TableSchemaField[];
};

type UseLibraryExtendedPropertyColumnsReturnProps = {
  loading: boolean;
  error: any;
  hasExtendedProperties: boolean;
  schema: Schema | undefined;
  extendedPropertyFields?: TableSchemaField[] | undefined;
  refetchExtendedProperties: () => void;
};

const withLibraryExtendedPropertyColumns = ({
  schema,
  model,
  updateableModel,
  recordPath,
  modelTypeCode,
  showLoading = true,
  inDialog,
  isLocalTable
}: {
  model: string;
  updateableModel?: string;
  modelTypeCode?: string;
  isLocalTable?: boolean;
  recordPath?: string;
  inDialog?: boolean;
  schema?: any;
  showLoading?: boolean;
}) => {
  return compose(
    withProps(() => {
      const propsToPass = useWithLibraryExtendedPropertyColumns({
        schema,
        model,
        updateableModel,
        recordPath,
        modelTypeCode,
        isLocalTable
      });

      return propsToPass;
    }),
    WithLoadingHoc({ showLoading, inDialog })
  );
};

export const useWithLibraryExtendedPropertyColumns = ({
  schema,
  model: _model,
  updateableModel,
  recordPath,
  modelTypeCode,
  isLocalTable,
  skip
}: {
  model: string;
  updateableModel?: string;
  modelTypeCode?: string;
  isLocalTable?: boolean;
  recordPath?: string;
  schema?: Schema;
  skip?: boolean;
}): UseLibraryExtendedPropertyColumnsReturnProps => {
  const model = updateableModel || _model;
  const isView = !!updateableModel && updateableModel !== _model;
  const {
    data: { extendedProperties },
    refetch: refetchExtendedProperties,
    loading,
    error
  } = useTgQuery<"extendedProperties", ExtendedPropertyFragment[]>(
    extendedPropertyFragment,
    {
      skip,
      variables: {
        filter: {
          modelTypeCode:
            modelTypeCode || modelTypeMap[model as keyof typeof modelTypeMap]
        }
      }
    }
  );
  // only calculate the table fields if needed
  const displayNameHelper = useMemo(() => {
    const displayNameHelper: { [key: string]: number } = {};

    if (schema) {
      schema.fields.forEach(field => {
        if (field.displayName) {
          displayNameHelper[camelCase(field.displayName)] = 1;
        }
      });
    }
    return displayNameHelper;
  }, [schema]);

  const additionalFields = useMemo(() => {
    // these fields are to be used in a table schema
    const additionalFields: TableSchemaField[] | undefined =
      extendedProperties?.map(prop => {
        const getExtendedPropValue = (_record: any, withLinks?: boolean) => {
          const record: { extendedStringValueViews: any[] } = recordPath
            ? get(_record, recordPath, {})
            : _record;
          if (!record) return null;

          const { extendedStringValueViews = [] } = record;
          const valueForProp = extendedStringValueViews.find(
            v => v.extendedPropertyId === prop.id
          );
          if (valueForProp) {
            let display = valueForProp.value;
            if (withLinks && valueForProp.targetModel) {
              const names = (valueForProp.value || "").split(", ");
              const ids = (valueForProp.linkIds || "").split(", ");
              if (ids.length === names.length) {
                display = names.map((name: string, index: number) => {
                  const id = ids[index];
                  if (id) {
                    return (
                      <React.Fragment key={id}>
                        <Link
                          to={modelNameToLink(valueForProp.targetModel, id)}
                        >
                          {name}
                        </Link>
                        {index !== names.length - 1 && ", "}
                      </React.Fragment>
                    );
                  } else return null;
                });
              }
            }
            if (prop.extendedType?.code === "timestamp" && display) {
              display = formatDate(display);
            }
            return display;
          }
        };

        let displayName = prop.name;
        const camelD = camelCase(displayName);
        if (displayNameHelper[camelD]) {
          displayName = `${displayName} ${displayNameHelper[camelD]++}`;
        } else {
          displayNameHelper[camelD] = 1;
        }

        const withVal = (v: any, r: any) => getExtendedPropValue(r);

        let pathToView = "extendedStringValueViews";
        if (isView) {
          pathToView = updateableModel + "." + pathToView;
        } else if (recordPath) {
          pathToView = recordPath + "." + pathToView;
        }
        // this will also be used for the j5 table card because it is nested in the source part field
        let viewUpdateableModel: string;
        if (pathToView.includes(".")) {
          viewUpdateableModel = pathToView.split(".")[0];
        }
        return {
          displayName,
          path: pathToView + ".value",
          placementPath: `extProp${prop.id}`,
          additionalColumnFilter: (qb: QueryBuilder, subFilter: any) => {
            const inner = qb
              .related(`extendedStringValueView.${model}Id`)
              .whereAll({
                value: subFilter,
                extendedPropertyId: prop.id
              });
            if (viewUpdateableModel) {
              return {
                id: qb.related(`${viewUpdateableModel}.id`, false).whereAll({
                  id: inner
                })
              };
            } else {
              return {
                id: inner
              };
            }
          },
          fieldGroup: "Extended Properties",
          type: "string",
          getClipboardData: withVal,
          ...(isLocalTable && {
            getValueToFilterOn: (r: any) => getExtendedPropValue(r)
          }),
          render: (v: any, r: any) => getExtendedPropValue(r, true)
        };
      });

    return additionalFields;
  }, [
    displayNameHelper,
    extendedProperties,
    isLocalTable,
    isView,
    model,
    recordPath,
    updateableModel
  ]);

  const toReturn = useMemo(() => {
    const toReturn: UseLibraryExtendedPropertyColumnsReturnProps = {
      loading,
      error,
      refetchExtendedProperties,
      hasExtendedProperties: !!extendedProperties?.length,
      schema
    };

    // if a schema was passed then add the fields onto it
    // otherwise just return them flat
    if (schema) {
      toReturn.schema = {
        ...schema,
        fields: [...schema.fields, ...(additionalFields ?? [])]
      };
    } else {
      toReturn.extendedPropertyFields = additionalFields ?? [];
    }

    return toReturn;
  }, [
    additionalFields,
    error,
    extendedProperties?.length,
    loading,
    refetchExtendedProperties,
    schema
  ]);

  return toReturn;
};

export default withLibraryExtendedPropertyColumns;
