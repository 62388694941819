/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback } from "react";
import { compose, withProps } from "recompose";
import { withTableParams, DataTable } from "@teselagen/ui";

import withQuery from "../../../../src-shared/withQuery";

import { generateFragmentWithFields } from "@teselagen/apollo-methods";
import { additivesTableSchema } from "../../Record/AliquotRecordView/AdditivesTableCard";
import { allConcentrationTypeFields } from "../../../../../tg-iso-lims/src/utils/unitUtils";

function AdditivesTable({ tableParams }) {
  return (
    <DataTable {...tableParams} withSearch={false} noSelect>
      Additives
    </DataTable>
  );
}

const fragment = generateFragmentWithFields(
  "additive",
  `
    id
    volume
    volumetricUnitCode
    mass
    massUnitCode
    ${allConcentrationTypeFields.join(" ")}
    additiveMaterial {
      id
      name
      additiveType {
        code
        name
      }
    }
    lot {
      id
      name
      additiveMaterial {
        id
        name
        additiveType {
          code
          name
        }
      }
    }
    `
);

export default compose(
  withProps(props => {
    // Added while #12943 is not ready
    const additionalFilter = useCallback(
      (_, qb) => {
        if (props.aliquotId) {
          qb.whereAll({
            aliquotId: props.aliquotId
          });
        } else if (props.aliquotContainerId) {
          qb.whereAll({
            aliquotContainerId: props.aliquotContainerId
          });
        }
      },
      [props.aliquotContainerId, props.aliquotId]
    );
    return { additionalFilter };
  }),
  withTableParams({
    formName: "additiveTableForInspectWell",
    urlConnected: false,
    schema: additivesTableSchema
  }),
  withQuery(fragment, {
    isPlural: true
  })
)(AdditivesTable);
