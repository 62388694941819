/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import withQuery from "../../../src-shared/withQuery";
import { get } from "lodash";
import pluralize from "pluralize";

export default function withWorkflowInputs(
  fragment,
  { inputName: passedInputName, initialValueName, singular } = {}
) {
  const modelName = Array.isArray(fragment)
    ? fragment[0]
    : get(fragment, "definitions[0].typeCondition.name.value");
  const pluralModelName = pluralize(modelName);
  const inputName = passedInputName || (singular ? modelName : pluralModelName);
  return withQuery(fragment, {
    isPlural: true,
    skip: props => {
      const inputs = get(props, `toolIntegrationProps.inputs.${inputName}`);
      const isIntegrated = get(
        props,
        `toolIntegrationProps.isIntegratedMap.${inputName}`
      );
      return !isIntegrated || !inputs || !inputs.length;
    },
    options: props => {
      const inputs = get(props, `toolIntegrationProps.inputs.${inputName}`);
      return {
        variables: {
          filter: {
            id: inputs
          }
        }
      };
    },
    props: ({
      data,
      ownProps: { toolIntegrationProps = {}, initialValues }
    }) => {
      let inputs = get(data, `${pluralModelName}${singular ? "[0]" : ""}`);
      if (!singular && inputs && !inputs.length) inputs = undefined;
      let newInitialValues = initialValues;
      if (inputs) {
        newInitialValues = {
          ...newInitialValues,
          [initialValueName || inputName]: inputs
        };
      }
      return {
        [initialValueName || inputName]: inputs,
        initialValues: newInitialValues,
        toolIntegrationProps: {
          ...toolIntegrationProps,
          isLoadingMap: {
            ...toolIntegrationProps.isLoadingMap,
            [inputName]: data.loading
          }
        }
      };
    }
  });
}
