/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { CollapsibleCard, DataTable } from "@teselagen/ui";
import AbstractRecord from "../AbstractRecord";

import RecordInfoDisplay from "../RecordInfoDisplay";
import recordViewEnhancer from "../recordViewEnhancer";
import TgSequenceEditor from "../TgSequenceEditor";
import TagTableColumn from "../TagTableColumn";
import { tagColumn } from "../utils/tagColumn";

import { showDialog } from "../GlobalDialog";
import { molecularWeightRender } from "../utils/unitUtils";
import FpusCard from "./FpusCard";
import { SimpleCircularOrLinearView } from "@teselagen/ove";
import { sequenceToVeInput } from "../../../tg-iso-shared/src/sequence-import-utils/utils";
import aminoAcidRecordFragment from "../../../tg-iso-shared/src/fragments/aminoAcidRecordFragment";

const CDSSchema = {
  model: "sequence",
  fields: [
    {
      displayName: "Fragment Id",
      path: "name"
    },
    {
      displayName: "Size",
      path: "size",
      render: record => record + " bp"
    },
    {
      ...tagColumn,
      displayName: "Tags",
      render: (val, record, row, { currentParams, setNewParams }) => {
        return (
          <TagTableColumn
            record={record}
            paramProps={{ currentParams, setNewParams }}
          />
        );
      }
    }
  ]
};

class AminoAcidRecordView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderContent(aminoAcidSequence) {
    const { extinctionCoefficient, molecularWeight } = aminoAcidSequence;
    if (this.props.data.loading) return;
    if (aminoAcidSequence) {
      const recordInfo = [
        ["Name", aminoAcidSequence.name],
        ["Description", aminoAcidSequence.description],
        ["Extinction Coefficient", extinctionCoefficient],
        aminoAcidSequence.hisTagLoc && [
          "His-tag Location",
          aminoAcidSequence.hisTagLoc
        ],
        aminoAcidSequence.uniprotId && [
          "Uniprot ID",
          aminoAcidSequence.uniprotId
        ],
        aminoAcidSequence.isoPoint && [
          "Isolectric Point",
          aminoAcidSequence.isoPoint
        ],
        molecularWeight && [
          "Molecular Weight",
          molecularWeightRender(molecularWeight)
        ],
        aminoAcidSequence.length && ["Size", aminoAcidSequence.length]
      ];
      return (
        <div className="record-info-container" style={{ marginBottom: 15 }}>
          <RecordInfoDisplay
            readOnly={this.props.readOnly}
            recordInfo={recordInfo}
            record={aminoAcidSequence}
          />
        </div>
      );
    }
  }

  onCdsDoubleClick = record => {
    this.props.history.push(`/dna-sequences/${record.id}`);
  };

  showUpdateDialog = () => {
    const { aminoAcidSequence = {}, refetchAminoAcidSequence } = this.props;
    showDialog({
      modalType: "CREATE_AA_SEQUENCE",
      modalProps: {
        dialogProps: {
          title: "Update Amino Acid Sequence"
        },
        initialValues: {
          ...aminoAcidSequence
        },
        refetch: refetchAminoAcidSequence
      }
    });
  };

  render() {
    const { aminoAcidSequence = {} } = this.props;
    const aminoAcidCard = (
      <CollapsibleCard title="Amino Acid Sequence" key="aaSequence">
        <div style={{ wordBreak: "break-all" }}>
          {aminoAcidSequence.proteinSequence}
        </div>
      </CollapsibleCard>
    );
    const codingDNASequences = (
      <CollapsibleCard title="Coding Sequences" key="CDS">
        <DataTable
          entities={aminoAcidSequence.codingDnaSequences}
          isSimple
          schema={CDSSchema}
          formName="aminoAcidForm"
          onDoubleClick={this.onCdsDoubleClick}
        />
      </CollapsibleCard>
    );
    const fpusCard = (
      <FpusCard key="fpu" aminoAcidSequenceId={aminoAcidSequence.id} />
    );
    return (
      <AbstractRecord
        {...this.props}
        recordName="aminoAcidSequence"
        withExtendedProperties
        updateShowFunction={this.showUpdateDialog}
        additionalCards={[aminoAcidCard, codingDNASequences, fpusCard]}
      >
        {this.renderContent(aminoAcidSequence)}
        <TgSequenceEditor
          {...this.props}
          isProtein
          sequence={{ ...aminoAcidSequence, isProtein: true }}
        />
      </AbstractRecord>
    );
  }
}

export default recordViewEnhancer(aminoAcidRecordFragment)(AminoAcidRecordView);

export const SimpleAAView = recordViewEnhancer(aminoAcidRecordFragment)(({
  aminoAcidSequence,
  ...props
}) => {
  return (
    <SimpleCircularOrLinearView
      isProtein
      height="unset"
      sequenceData={sequenceToVeInput({
        ...aminoAcidSequence,
        isProtein: true
      })}
      {...props}
    />
  );
});
