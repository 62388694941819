/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import {
  DialogFooter,
  FileUploadField,
  CheckboxField,
  InputField,
  wrapDialog
} from "@teselagen/ui";
import { dataTableHeaderMap } from "../../../utils";
import GenericSelect from "../../../../src-shared/GenericSelect";

import { startImportCollection } from "../../../../src-shared/utils/importCollection";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { allowedCsvFileTypes } from "../../../../../tg-iso-shared/src/utils/fileUtils";
import {
  dataTableTypeCodesWithCustomPaths,
  parseDataTableImportData
} from "../../../../../tg-iso-lims/src/utils/dataTableUtils";
import { getDownloadTemplateFileHelpers } from "../../../../src-shared/components/DownloadTemplateFileButton";

const additionalDataFragment = ["dataTableType", "code name rowSchema"];
const fragment = ["dataTableType", "code name"];
const tableParamOptions = {
  additionalFilter: (props, qb) => {
    qb.whereAll({
      code: qb.notInList(dataTableTypeCodesWithCustomPaths)
    });
  }
};

// Add a checkbox field for grouping data tables inside a data set
// Change logic to parse multiple csv files
const UploadDataTableDialog = props => {
  const {
    refetch,
    hideModal,
    handleSubmit,
    submitting,
    groupInDataSet,
    dataTableType
  } = props;

  const onSubmit = async values => {
    try {
      const { dataTableType, dataTableFiles, dataSetName, groupInDataSet } =
        values;

      const { error, dataTables, dataSet } = await parseDataTableImportData({
        csvFiles: dataTableFiles,
        dataTableType,
        dataSetName: groupInDataSet ? dataSetName : undefined
      });
      if (error) {
        return window.toastr.error(error);
      }
      await startImportCollection("Data Table Upload");
      if (dataSet) {
        await safeUpsert("dataSet", dataSet);
      }
      await safeUpsert("dataTable", dataTables);
      await refetch();
      hideModal();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error uploading data table");
    }
  };

  const accept = useMemo(() => {
    let requiredHeaders;
    const _dataTableType = dataTableType || {};
    if (_dataTableType && _dataTableType.rowSchema) {
      requiredHeaders = _dataTableType.rowSchema.map(r => r.displayName);
    }
    return getDownloadTemplateFileHelpers({
      type: allowedCsvFileTypes,
      fileName: `${_dataTableType.name} - Template`,
      headers: requiredHeaders,
      headerMessages: dataTableHeaderMap[_dataTableType.code] || {}
    });
  }, [dataTableType]);

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <GenericSelect
          name="dataTableType"
          isRequired
          label="Type"
          idAs="code"
          asReactSelect
          tableParamOptions={tableParamOptions}
          fragment={fragment}
          additionalDataFragment={additionalDataFragment}
        />
        <CheckboxField
          name="groupInDataSet"
          label="Group Tables in Data Set"
          defaultValue
        />
        {groupInDataSet && (
          <InputField isRequired name="dataSetName" label="Data Set Name" />
        )}
        <FileUploadField accept={accept} name="dataTableFiles" isRequired />
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
};

export default compose(
  wrapDialog({
    title: "Upload Table"
  }),
  reduxForm({
    form: "uploadDataTable"
  }),
  tgFormValues("dataTableType", "groupInDataSet")
)(UploadDataTableDialog);
