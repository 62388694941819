/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { withTableParams } from "@teselagen/ui";
import withQuery from "../../src-shared/withQuery";

import { compose } from "redux";
import { insertSequenceDialogFragment } from "../graphql/fragments/insertSequenceDialogFragment";
import InsertSequenceDialog from "../components/Dialogs/InsertSequenceDialog";
import {
  designMaterialAvailabilityColumn,
  dateModifiedColumn,
  userColumns
} from "../../src-shared/utils/libraryColumns";

const schema = {
  model: "sequence",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    { path: "size", type: "number", displayName: "Size" },
    designMaterialAvailabilityColumn,
    userColumns,
    dateModifiedColumn
  ]
};

const InsertSequenceDialogContainer = compose(
  withTableParams({
    urlConnected: true,
    schema,
    formName: "insertSequenceDialog",
    withDisplayOptions: true,
    additionalFilter: (_, qb) => {
      qb.whereAny({
        isInLibrary: true
      });
    }
  }),
  withQuery(insertSequenceDialogFragment, {
    isPlural: true
  })
)(InsertSequenceDialog);

export default InsertSequenceDialogContainer;
