/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback } from "react";
import { compose } from "recompose";
import { Button } from "@blueprintjs/core";
import shortid from "shortid";
import Big from "big.js";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import MicrobialMaterialSelectSection from "../../MicrobialTransformationTool/MicrobialMaterialSelectSection";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import {
  standardizeVolume,
  convertVolume
} from "../../../../../src-shared/utils/unitUtils";
import { get } from "lodash";
import IntermediateContainerTypeFields from "../../../IntermediateContainerTypeFields";
import { generateEmptyWells } from "../../../../../../tg-iso-lims/src/utils/plateUtils";
import UnitInputField from "../../../UnitInputField";
import { useDispatch } from "react-redux";
import { change as _change } from "redux-form";

const SelectRecipientCells = ({
  Footer,
  footerProps,
  handleSubmit,
  microbialMaterial,
  toolSchema,
  nextStep
}) => {
  const dispatch = useDispatch();
  const change = useCallback(
    (...args) => dispatch(_change(toolSchema.code, ...args)),
    [dispatch, toolSchema.code]
  );
  const generateWorklist = values => {
    const {
      strain,
      microbialTransferVolume,
      microbialTransferVolumeUnitCode,
      microbialMaterial,
      destinationPlateTypes = [],
      intermediateContainerName,
      intermediateContainerType,
      generateIntermediateBarcode,
      intermediateBarcode,
      containerArrays = [],
      aliquotContainers = []
    } = values;

    const microbialTransformationWorklist = {
      worklistTransfers: []
    };

    const microbialMaterialCid = shortid();

    let toBeCreatedDestinationPlates;

    const aliquotContainersToPrep = [...aliquotContainers];
    containerArrays.forEach(plate => {
      plate.aliquotContainers.forEach(ac => {
        if (ac.aliquot) {
          aliquotContainersToPrep.push(ac);
        }
      });
    });
    const standardizedMaxWellVolume = standardizeVolume(
      intermediateContainerType.aliquotContainerType.maxVolume,
      intermediateContainerType.aliquotContainerType.volumetricUnitCode,
      true
    );
    const deadVolume =
      get(intermediateContainerType, "aliquotContainerType.deadVolume") || 0;
    const standardizedDeadVolume = standardizeVolume(
      deadVolume,
      intermediateContainerType.aliquotContainerType.deadVolumetricUnitCode ||
        "uL",
      true
    );
    const standardizedMicrobialTransferVolume = standardizeVolume(
      microbialTransferVolume,
      microbialTransferVolumeUnitCode,
      true
    );
    const totalRequiredVolume = standardizedMicrobialTransferVolume.times(
      aliquotContainersToPrep.length
    );
    const intermediateAliquotContainers = generateEmptyWells(
      intermediateContainerType.containerFormat,
      {
        aliquotContainerTypeCode:
          intermediateContainerType.aliquotContainerTypeCode
      }
    );
    let volumeNeeded = totalRequiredVolume;
    let maxWellVolumeAdjusted = standardizedMaxWellVolume;

    if (
      !standardizedMaxWellVolume.mod(standardizedMicrobialTransferVolume).eq(0)
    ) {
      maxWellVolumeAdjusted = standardizedMaxWellVolume.minus(
        standardizedMaxWellVolume.mod(standardizedMicrobialTransferVolume)
      );
    }

    intermediateAliquotContainers.forEach(ac => {
      let volume = maxWellVolumeAdjusted.plus(standardizedDeadVolume);
      if (!volumeNeeded.eq(0)) {
        if (volumeNeeded.minus(maxWellVolumeAdjusted).lt(0)) {
          volume = volumeNeeded.plus(standardizedDeadVolume);
          volumeNeeded = new Big(0);
        } else {
          volumeNeeded = volumeNeeded.minus(maxWellVolumeAdjusted);
        }
        ac.containerArray = {
          name: intermediateContainerName
        };
        const aliquotContainerCid = shortid();
        ac.cid = aliquotContainerCid;
        ac.aliquot = {
          aliquotType: "sample-aliquot",
          volume: Number(
            convertVolume(
              volume,
              "L",
              microbialTransferVolumeUnitCode
            ).toString()
          ),
          volumetricUnitCode: microbialTransferVolumeUnitCode,
          sample: {
            name: !microbialMaterial ? strain.name : microbialMaterial.name,
            sampleTypeCode: "REGISTERED_SAMPLE",
            materialId: !microbialMaterial
              ? `&${microbialMaterialCid}`
              : microbialMaterial.id
          }
        };
      }
    });
    const cid = shortid();
    const intermediateContainer = {
      cid,
      name: intermediateContainerName,
      containerArrayTypeId: intermediateContainerType.id,
      aliquotContainers: intermediateAliquotContainers,
      ...(!generateIntermediateBarcode && {
        barcode: {
          barcodeString: intermediateBarcode
        }
      })
    };
    let intermediateAcIndex = 0;
    const getCurrentIntermediateContainerVolume = i => {
      return standardizeVolume(
        intermediateContainer.aliquotContainers[i].aliquot.volume,
        intermediateContainer.aliquotContainers[i].aliquot.volumetricUnitCode,
        true
      );
    };
    let currentVolume =
      getCurrentIntermediateContainerVolume(intermediateAcIndex);

    const addTransfer = (aliquotContainer, plate) => {
      if (
        standardizedMicrobialTransferVolume.gt(currentVolume) ||
        currentVolume.eq(0)
      ) {
        currentVolume =
          getCurrentIntermediateContainerVolume(intermediateAcIndex);
        intermediateAcIndex += 1;
      }
      currentVolume = currentVolume.minus(standardizedMicrobialTransferVolume);
      microbialTransformationWorklist.worklistTransfers.push({
        volume: microbialTransferVolume,
        volumetricUnitCode: microbialTransferVolumeUnitCode,
        destinationPlate: plate,
        destinationAliquotContainer: {
          ...aliquotContainer,
          containerArrayType: plate.containerArrayType
        },
        destinationAliquotContainerId: aliquotContainer.id,
        sourceAliquotContainer:
          intermediateContainer.aliquotContainers[intermediateAcIndex],
        sourceAliquotContainerId: `&${intermediateContainer.aliquotContainers[intermediateAcIndex].cid}`
      });
    };
    containerArrays.forEach(plate => {
      plate.aliquotContainers.forEach(ac => {
        if (ac.aliquot) {
          addTransfer(ac, plate);
        }
      });
    });
    aliquotContainers.forEach(addTransfer);

    change("intermediateContainer", intermediateContainer);
    change("destinationPlateTypes", destinationPlateTypes);
    change("destinationPlates", toBeCreatedDestinationPlates);
    change("worklist", microbialTransformationWorklist);
    nextStep();
  };

  return (
    <>
      <MicrobialMaterialSelectSection
        microbialMaterial={microbialMaterial}
        header="Select Recipient Cells"
        helper="Select the recipient cells of microbial materials which may or may not contain plasmid DNA materials."
      />
      <div className="tg-step-form-section">
        <HeaderWithHelper
          header="Transfer"
          helper="Enter the volume of recipient cells to transfer to the selected donor cells' containers. Then enter a name and select a type for an intermediate container, which will hold the microbial material prior to transferring. Specify the dead volume expected for liquid handler and selected container type."
        />
        <div>
          <UnitInputField
            label="Microbe Transfer Volume"
            name="microbialTransferVolume"
            min={0}
            unitName="microbialTransferVolumeUnitCode"
            unitDefault="uL"
            unitType="volumetricUnit"
          />
          <hr className="tg-section-break" />
          <IntermediateContainerTypeFields formName={toolSchema.code} />
        </div>
      </div>
      <Footer
        {...footerProps}
        nextButton={
          <Button intent="primary" onClick={handleSubmit(generateWorklist)}>
            Next
          </Button>
        }
      />
    </>
  );
};

export default compose(stepFormValues("microbialMaterial"))(
  SelectRecipientCells
);
