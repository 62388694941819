/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { MenuItem } from "@blueprintjs/core";
import { showDialog } from "../../GlobalDialog";
import UpdateRecordExtendedPropertiesDialog from "../../UpdateRecordExtendedPropertiesDialog";

const EditExtendedPropertiesMenuItem = ({ records, libraryFragment }) => {
  const onClick = () => {
    showDialog({
      ModalComponent: UpdateRecordExtendedPropertiesDialog,
      modalProps: {
        model: records[0].__typename,
        recordIds: records.map(r => r.id),
        libraryFragment
      }
    });
  };

  return (
    <MenuItem
      onClick={onClick}
      icon="panel-stats"
      text="Edit Extended Properties"
    />
  );
};

export default EditExtendedPropertiesMenuItem;
