/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import {
  withDialog,
  DialogFooter,
  InputField,
  TextareaField,
  NumericInputField,
  SelectField
} from "@teselagen/ui";
import withQuery from "../../../../../../src-shared/withQuery";

import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { startCase, get } from "lodash";
import { arrayToIdOrCodeValuedOptions } from "../../../../../../src-shared/utils/formUtils";

import { safeUpsert } from "../../../../../../src-shared/apolloMethods";

import "./style.css";

const inPerMm = 0.0393701;
const dpmmOptions = [6, 8, 12, 24].map(dpmm => ({
  value: dpmm,
  label: `${dpmm} dpmm (${Math.round(dpmm / inPerMm)} dpi)`
}));

class LabelMediaDialog extends React.Component {
  onSubmit = async values => {
    const { initialValues, refetch, hideModal } = this.props;
    try {
      const id = get(initialValues, "id");

      await safeUpsert("labelMedia", {
        id,
        name: values.name,
        description: values.description,
        widthUnitCode: values.widthUnitCode,
        heightUnitCode: values.heightUnitCode,
        height: values.height,
        width: values.width
      });

      await refetch();

      hideModal();
    } catch (e) {
      console.error(e);
      window.toastr.error(
        `Erroring ${initialValues ? "editing" : "creating"} label media.`
      );
    }
  };

  renderDimension(dimension) {
    const {
      lengthUnits = [],
      [dimension]: length,
      [dimension + "UnitCode"]: unitCode,
      dpmm
    } = this.props;

    const dots = getDots(length, unitCode, dpmm);
    const dotsLabel = !(length > 0) ? "" : `${dots} dots`;

    return (
      <div className="input-with-unit-select" style={{ maxWidth: "unset" }}>
        <div>
          <NumericInputField
            label={startCase(dimension)}
            name={dimension}
            min={0}
          />
        </div>
        <SelectField
          className="tg-unit-select"
          label="none"
          isRequired
          name={dimension + "UnitCode"}
          defaultValue={unitCode ? unitCode : "mm"}
          options={arrayToIdOrCodeValuedOptions(lengthUnits, {
            labelKey: "code"
          })}
        />
        <div>
          <div className={Classes.LABEL}>{"\u00A0"}</div>
          <div className="label-media-dots">
            <i>{dotsLabel}</i>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { hideModal, submitting, handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          <InputField name="name" label="Name" isRequired />
          <TextareaField name="description" label="Description" />
          {this.renderDimension("width")}
          {this.renderDimension("height")}
          <SelectField
            name="dpmm"
            label="Preview Dot Density"
            options={dpmmOptions}
            defaultValue={dpmmOptions[0].value}
          />
        </div>
        <DialogFooter hideModal={hideModal} submitting={submitting} />
      </form>
    );
  }
}

export default compose(
  withDialog({}),
  reduxForm({
    form: "LabelMediaDialogForm",
    enableReinitialize: true,
    validate
  }),
  tgFormValues("dpmm", "width", "height", "widthUnitCode", "heightUnitCode"),
  withQuery(["lengthUnit", "code"], { isPlural: true })
)(LabelMediaDialog);

function validate(values) {
  const errors = {};
  if (!(values.height > 0)) errors.height = "Must be positive number.";
  if (!(values.width > 0)) errors.width = "Must be positive number.";
  return errors;
}

/**
 * Given a length in units of `unitCode`, convert that length to
 * units of dots, assuming `dpmm` dots per mm. Dots are rounded to
 * the nearest integer.
 *
 * If length is invalid, return `"N/A"`.
 *
 * @param {number} length
 * @param {string} unitCode
 * @param {number} dpmm
 */
function getDots(length, unitCode, dpmm) {
  if (!(length > 0)) return "N/A";
  if (unitCode === "in") {
    return Math.round((length / inPerMm) * dpmm);
  } else if (unitCode === "mm") {
    return Math.round(length * dpmm);
  } else if (unitCode === "cm") {
    return Math.round(length * 10 * dpmm);
  } else {
    throw new Error(`Invalid unitCode "${unitCode}".`);
  }
}
