/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export const sampleLibraryFragment = gql`
  fragment sampleLibraryFragment on sample {
    id
    name
    sampleStatus {
      code
      name
    }
    sampleTypeCode
    sampleType {
      code
      name
    }
    sampleFormulations {
      id
      materialCompositions {
        id
        material {
          id
          name
        }
      }
    }
    material {
      id
      name
    }
  }
`;
