/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { basename } from "path";
import React, { Component } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { keyBy, uniqBy, isEmpty } from "lodash";
import { Classes } from "@blueprintjs/core";
import {
  FileUploadField,
  DialogFooter,
  BlueprintError,
  showConfirmationDialog,
  wrapDialog
} from "@teselagen/ui";
import shortid from "shortid";
import {
  getReverseComplementSequenceString,
  getAminoAcidStringFromSequenceString,
  guessIfSequenceIsDnaAndNotProtein
} from "@teselagen/sequence-utils";

import { START_CODON } from "../../../utils/sequenceUtils";
import { taggedItems } from "../../../utils/plateUtils";

import TagField from "../../../../src-shared/TagField";
import { createTaggedItems } from "../../../../src-shared/utils/tagUtils";
import { startImportCollection } from "../../../../src-shared/utils/importCollection";
import processSequences from "../../../../../tg-iso-shared/src/sequence-import-utils/processSequences";
import { addTaggedItemsBeforeCreate } from "../../../../../tg-iso-shared/src/tag-utils";
import { throwFormError } from "../../../../src-shared/utils/formUtils";

import { safeUpsert, safeQuery } from "../../../../src-shared/apolloMethods";

import {
  allowedCsvFileTypes,
  isCsvOrExcelFile,
  extractZipFiles,
  validateCSVRow,
  parseCsvOrExcelFile
} from "../../../../../tg-iso-shared/src/utils/fileUtils";

import { extendedPropertyUploadFragment } from "../../../../src-shared/utils/extendedPropertyUtils";
import { getBoundExtendedPropertyUploadHelpers } from "../../../../../tg-iso-shared/src/utils/extendedPropertiesUtils";
import {
  aaSequenceJSONtoGraphQLInput,
  computeSequenceHash,
  parseSequenceFiles,
  sequenceJSONtoGraphQLInput
} from "../../../../../tg-iso-shared/src/sequence-import-utils/utils";
import {
  checkDuplicateSequences,
  checkDuplicateSequencesExtended
} from "../../../../../tg-iso-shared/src/sequence-import-utils/checkDuplicateSequences";
import showDuplicateInputSequencesConfirmation from "../../../../src-shared/showDuplicateInputSequencesConfirmation";
import upsertUniqueAliases from "../../../../../tg-iso-shared/src/sequence-import-utils/upsertUniqueAliases";
import { getSequence } from "../../../../../tg-iso-shared/src/utils/getSequence";
import { getDownloadTemplateFileHelpers } from "../../../../src-shared/components/DownloadTemplateFileButton";
import { caseInsensitiveFilter } from "../../../utils/queryUtils";

const requiredHeaders = ["CDS_FRAG_ID", "CDS"];

class UploadProteinDnaDialog extends Component {
  onSubmit = async values => {
    const { refetch, hideModal } = this.props;
    const { proteinDNAFile } = values;
    try {
      const allFiles = await extractZipFiles(proteinDNAFile);
      const sequenceFiles = allFiles.filter(
        file => file.name.split(".").pop() === "gb"
      );
      const csvFile = allFiles.find(isCsvOrExcelFile);
      if (!csvFile) {
        return window.toastr.error("No CSV file found in zip.");
      }
      const parsedCsv = await parseCsvOrExcelFile(csvFile);
      const { data: dirtyData, meta } = parsedCsv;
      const plasmidNames = [];
      const sequenceFileMap = {};
      const materialsToCreate = [];
      const existingPlasmidUpdates = [];
      const sequenceUpdates = [];
      const cdsHashToSequenceId = {};
      const sequencesToCreate = [];
      const sequenceCodingSequencesToCreate = [];
      const sequenceCodingSequenceUpdates = [];
      const cdsHashToJoinTableExtraFields = {};
      const materialFpusToCreate = [];
      const createdMaterialsForProteins = {};
      const proteinNames = [];
      const existingPlasmidMaterialsToTag = [];
      const cdsToCreate = [];
      const sequenceHashToInvItemId = {};
      const inventoryItemsToCreate = [];
      const createdExtPropsForHash = {};
      const sequenceAliases = [];

      const { getCsvRowExtProps, createUploadProperties } =
        await getBoundExtendedPropertyUploadHelpers(meta.fields);

      // NOTE: this is assuming that the order of parsedSequences is keeping the same order of sequenceFiles.
      const { sequences: parsedSequences } =
        await parseSequenceFiles(sequenceFiles);
      sequenceFiles.forEach((file, i) => {
        sequenceFileMap[basename(file.name, ".gb")] = parsedSequences[i];
      });

      const data = [];
      const seenHashes = [];
      const dupRows = [];
      for (const [index, row] of dirtyData.entries()) {
        const { CDS: cdsSequence } = row;
        if (!cdsSequence) {
          return window.toastr.error(
            `Row ${index + 1} did not provide a cds sequence.`
          );
        }
        const cdsHash = computeSequenceHash(cdsSequence, "CDS");
        if (seenHashes.includes(cdsHash)) {
          dupRows.push(index + 1);
        } else {
          data.push(row);
          seenHashes.push(cdsHash);
        }
      }
      if (dupRows.length) {
        const continueUpload = await showConfirmationDialog({
          text: `We found duplicate cds in the upload at these rows ${dupRows.join(
            ", "
          )}. Would you like to ignore these rows and continue?`,
          cancelButtonText: "No",
          confirmButtonText: "Yes"
        });
        if (!continueUpload) return;
      }

      const {
        allInputSequencesWithAttachedDuplicates,
        duplicateSequencesFound,
        duplicatesOfInputSequences
      } = await checkDuplicateSequencesExtended(parsedSequences, {
        fragment: `id
            name
            hash
            ${taggedItems}
            ${extendedPropertyUploadFragment}
            polynucleotideMaterialId
            polynucleotideMaterial {
              id
              polynucleotideMaterialMaterialFpus {
                id
                functionalProteinUnitId
              }
              ${taggedItems}
            }`
      });
      const continueUpload = await showDuplicateInputSequencesConfirmation(
        duplicatesOfInputSequences,
        sequenceAliases
      );
      if (!continueUpload) return;

      const keyedAllInputSequencesWithAttachedDuplicates = keyBy(
        allInputSequencesWithAttachedDuplicates,
        "cid"
      );
      // CDS sequences are processed here and their hashes are computed.
      const cdsHashes = [];
      for (const row of data) {
        const {
          CDS: cdsSequence,
          PLASMID_NAME: plasmidName,
          SEQUENCE_FILE: sequenceFileName,
          FPU_ID: proteinName
        } = row;
        if (cdsSequence) {
          cdsHashes.push(computeSequenceHash(cdsSequence, "CDS"));
        }
        if (!sequenceFileName && plasmidName) {
          plasmidNames.push(plasmidName);
        }
        if (proteinName) {
          proteinNames.push(proteinName);
        }
      }

      // 'duplicateCds' represents CDSs already in the database.
      const duplicateCds = await checkDuplicateSequences(cdsHashes, {
        fragment: `id
          name
          hash
          polynucleotideMaterialId
          aminoAcidSequenceId
          referenceCdsFragmentId
          codingDnaSequenceSequenceCodingSequences {
            id
            sequenceId
          }
          inventoryItems {
            id
          }
          ${taggedItems}
          ${extendedPropertyUploadFragment}
          `
      });
      const keyedDuplicateCds = keyBy(duplicateCds, "hash");

      const existingPlasmids = plasmidNames.length
        ? await safeQuery(
            [
              "sequence",
              `id
            name
            hash
            circular
            ${taggedItems}
            ${extendedPropertyUploadFragment}
            polynucleotideMaterialId
            polynucleotideMaterial {
              id
              ${taggedItems}
              polynucleotideMaterialMaterialFpus {
                id
                functionalProteinUnitId
              }
            }
            sequenceFragments {
              id
              cid
              index
              fragment
            }
            `
            ],
            {
              variables: {
                filter: {
                  name: caseInsensitiveFilter("sequence", "name", plasmidNames)
                }
              }
            }
          )
        : [];
      const plasmidMap = keyBy(existingPlasmids, "name");

      let proteinMap = {};
      let existingProteins = [];
      const existingProteinMaterials = [];
      if (proteinNames.length) {
        existingProteins = await safeQuery(
          [
            "functionalProteinUnit",
            `id name proteinMaterial { id ${taggedItems} } ${taggedItems}`
          ],
          {
            variables: {
              filter: {
                name: proteinNames
              }
            }
          }
        );
        proteinMap = keyBy(existingProteins, "name");
        existingProteins.forEach(protein => {
          if (protein.proteinMaterial) {
            existingProteinMaterials.push(protein.proteinMaterial);
          }
        });
      }

      for (const [index, row] of data.entries()) {
        const {
          CDS: cdsSequence,
          PLASMID_NAME: plasmidName,
          CDS_FRAG_ID: cdsName,
          FPU_ID: proteinName,
          SEQUENCE_FILE: sequenceFileName,
          EOU_DESCRIPTION: eouDescription,
          RBS_STRENGTH: ribosomeBindingStrength,
          REFERENCE_CDS_FRAG_ID: referenceCdsFragmentId
        } = row;

        let plasmidId, plasmid, plasmidHash;

        const requiredFieldError = validateCSVRow(row, requiredHeaders, index);
        if (requiredFieldError) {
          return window.toastr.error(requiredFieldError);
        }
        if (!guessIfSequenceIsDnaAndNotProtein(cdsSequence)) {
          return window.toastr.error(
            `Row ${index + 1} contains an invalid CDS sequence.`
          );
        }
        if (
          sequenceFileName &&
          !sequenceFileMap[basename(sequenceFileName).split(".")[0]]
        ) {
          return window.toastr.error(
            `Row ${
              index + 1
            } specifies the sequence file ${sequenceFileName} which was not found inside the zip folder.`
          );
        }
        if (!sequenceFileName && plasmidName && !plasmidMap[plasmidName]) {
          return window.toastr.error(
            `Row ${
              index + 1
            } specifies the plasmid ${plasmidName} which was not found in inventory.`
          );
        }
        if (proteinName && !proteinMap[proteinName]) {
          return window.toastr.error(
            `Row ${
              index + 1
            } specifies the protein ${proteinName} which was not found in inventory.`
          );
        }
        if (proteinName && !sequenceFileName && !plasmidName) {
          return window.toastr.error(
            `Row ${
              index + 1
            } specifies the protein ${proteinName} but didn't provide a relevant plasmid.`
          );
        }
        const newCds = sequenceJSONtoGraphQLInput({
          sequence: cdsSequence,
          name: cdsName,
          isCds: true,
          sequenceTypeCode: "LINEAR_DNA"
        });
        newCds.referenceCdsFragmentId = referenceCdsFragmentId;

        const protein = proteinMap[proteinName];

        if (
          protein &&
          !protein.proteinMaterial &&
          !createdMaterialsForProteins[protein.id]
        ) {
          createdMaterialsForProteins[protein.id] = true;
          materialsToCreate.push({
            name: protein.name,
            materialTypeCode: "PROTEIN",
            functionalProteinUnitId: protein.id
          });
        }

        let sequenceString;
        let isCircular;

        let dnaMaterialId;
        let dnaMaterial;
        const addNewMaterial = name => {
          const materialCid = shortid();
          dnaMaterialId = `&${materialCid}`;
          materialsToCreate.push({
            cid: materialCid,
            name,
            materialTypeCode: "DNA"
          });
        };

        const createdMaterialForSequenceMap = {};
        const handleMaterialForExistingSequence = existingSequence => {
          plasmidId = existingSequence.id;
          plasmidHash = existingSequence.hash;
          plasmid = existingSequence;

          if (existingSequence.polynucleotideMaterialId) {
            dnaMaterialId = existingSequence.polynucleotideMaterialId;
            dnaMaterial = existingSequence.polynucleotideMaterial;
            existingPlasmidMaterialsToTag.push(
              existingSequence.polynucleotideMaterial
            );
          } else if (
            !existingSequence.polynucleotideMaterialId &&
            !createdMaterialForSequenceMap[existingSequence.id]
          ) {
            addNewMaterial(existingSequence.name);
            createdMaterialForSequenceMap[existingSequence.id] = dnaMaterialId;
            existingPlasmidUpdates.push({
              id: existingSequence.id,
              polynucleotideMaterialId: dnaMaterialId
            });
          } else if (createdMaterialForSequenceMap[existingSequence.id]) {
            dnaMaterialId = createdMaterialForSequenceMap[existingSequence.id];
          }
        };

        const handleMaterialFpus = () => {
          if (protein) {
            const hasProtein =
              dnaMaterial?.polynucleotideMaterialMaterialFpus?.some(
                sfpu => sfpu.functionalProteinUnitId === protein.id
              );
            if (!hasProtein) {
              const alreadyMade = materialFpusToCreate.find(
                fpu =>
                  fpu.polynucleotideMaterialId === dnaMaterialId &&
                  fpu.functionalProteinUnitId === protein.id
              );
              if (!alreadyMade) {
                materialFpusToCreate.push({
                  polynucleotideMaterialId: dnaMaterialId,
                  functionalProteinUnitId: protein.id
                });
              }
            }
          }
        };

        if (sequenceFileName) {
          const sequence =
            sequenceFileMap[basename(sequenceFileName).split(".")[0]];
          sequenceString = getSequence(sequence);
          plasmidHash = sequence.hash;
          isCircular = sequence.circular;
          const existingSequence =
            keyedAllInputSequencesWithAttachedDuplicates[sequence.cid]
              .duplicateFound;

          if (existingSequence && existingSequence.id) {
            handleMaterialForExistingSequence(existingSequence);
            cdsHashToSequenceId[newCds.hash] = existingSequence.id;
            handleMaterialFpus();
          } else {
            if (!existingSequence) {
              sequence.cid = sequence.cid || shortid();
              plasmidId = `&${sequence.cid}`;
              addNewMaterial(sequence.name);
              sequence.polynucleotideMaterialId = dnaMaterialId;
              sequencesToCreate.push(sequence);
            }
            const sequenceCid = existingSequence
              ? existingSequence.cid
              : sequence.cid;
            const sequenceId = `&${sequenceCid}`;
            handleMaterialFpus();
            cdsHashToSequenceId[newCds.hash] = sequenceId;
          }
        } else if (plasmidName) {
          const existingSequence = plasmidMap[plasmidName];

          handleMaterialForExistingSequence(existingSequence);
          sequenceString = getSequence(existingSequence);
          isCircular = existingSequence.circular;
          handleMaterialFpus();
          cdsHashToSequenceId[newCds.hash] = existingSequence.id;
        }

        cdsHashToJoinTableExtraFields[newCds.hash] = {
          eouDescription,
          ribosomeBindingStrength
        };
        if (sequenceString) {
          if (isCircular) sequenceString += sequenceString;
          if (
            !sequenceString.toUpperCase().includes(cdsSequence.toUpperCase())
          ) {
            return window.toastr.error(
              `Row ${
                index + 1
              } cds sequence was not found on the provided plasmid.`
            );
          }
        }

        newCds.cid = shortid();
        const existingCds = keyedDuplicateCds[newCds.hash];
        const sequenceId = cdsHashToSequenceId[newCds.hash];

        if (sequenceId) {
          if (existingCds && !sequenceId.startsWith("&")) {
            const hasLink =
              existingCds.codingDnaSequenceSequenceCodingSequences.find(
                cs => cs.sequenceId === sequenceId
              );
            if (!hasLink) {
              sequenceCodingSequencesToCreate.push({
                sequenceId,
                codingDnaSequenceId: existingCds.id,
                ...cdsHashToJoinTableExtraFields[newCds.hash]
              });
            } else if (!isEmpty(cdsHashToJoinTableExtraFields[newCds.hash])) {
              sequenceCodingSequenceUpdates.push({
                id: hasLink.id,
                ...cdsHashToJoinTableExtraFields[newCds.hash]
              });
            }
          } else {
            // 'sequenceCodingSequencesToCreate' These are the CDSs parent sequence.
            sequenceCodingSequencesToCreate.push({
              sequenceId,
              codingDnaSequenceId: existingCds
                ? existingCds.id
                : `&${newCds.cid}`,
              ...cdsHashToJoinTableExtraFields[newCds.hash]
            });
          }
        }
        if (
          existingCds &&
          newCds.referenceCdsFragmentId &&
          existingCds.referenceCdsFragmentId !== newCds.referenceCdsFragmentId
        ) {
          sequenceUpdates.push({
            id: existingCds.id,
            referenceCdsFragmentId: newCds.referenceCdsFragmentId
          });
        }
        if (!existingCds) {
          cdsToCreate.push(newCds);
        }

        // make inventory items
        if (!sequenceHashToInvItemId[newCds.hash]) {
          if (existingCds) {
            if (existingCds.inventoryItems.length) {
              sequenceHashToInvItemId[newCds.hash] =
                existingCds.inventoryItems[0].id;
            } else {
              const cid = shortid();
              inventoryItemsToCreate.push({
                cid,
                inventoryItemTypeCode: "DNA_SEQUENCE",
                sequenceId: existingCds.id
              });
              sequenceHashToInvItemId[newCds.hash] = `&${cid}`;
            }
          } else {
            const cid = shortid();
            inventoryItemsToCreate.push({
              cid,
              inventoryItemTypeCode: "DNA_SEQUENCE",
              sequenceId: `&${newCds.cid}`
            });
            sequenceHashToInvItemId[newCds.hash] = `&${cid}`;
          }
        }

        if (!createdExtPropsForHash[newCds.hash]) {
          createdExtPropsForHash[newCds.hash] = true;
          getCsvRowExtProps({
            row,
            modelTypeCode: "DNA_SEQUENCE",
            typeFilter: "cds",
            recordId: existingCds ? existingCds.id : `&${newCds.cid}`,
            record: existingCds
          });
        }

        if (!createdExtPropsForHash[plasmidHash]) {
          createdExtPropsForHash[plasmidHash] = true;
          getCsvRowExtProps({
            row,
            modelTypeCode: "DNA_SEQUENCE",
            typeFilter: "plasmid",
            recordId: plasmidId,
            record: plasmid
          });
        }
      }

      let aminoAcidSequencesToCreate = [];
      const aliasesToCreate = [];
      const aminoHashToCds = {};
      cdsToCreate.forEach(cds => {
        let sequence = getSequence(cds);
        const firstThreeCharacters = sequence.slice(0, 3).toLowerCase();
        if (firstThreeCharacters !== START_CODON) {
          sequence = getReverseComplementSequenceString(sequence);
        }
        const aaSequence = getAminoAcidStringFromSequenceString(sequence);
        const aminoAcid = aaSequenceJSONtoGraphQLInput({
          name: cds.name,
          sequence: aaSequence
        });

        if (!aminoAcid.proteinSequence) {
          throw new Error(`CDS sequence ${cds.name} is invalid.`);
        }

        if (!aminoHashToCds[aminoAcid.hash]) {
          aminoHashToCds[aminoAcid.hash] = [];
          aminoAcidSequencesToCreate.push(aminoAcid);
        }
        aminoHashToCds[aminoAcid.hash].push(cds);
      });
      const existingAAs = await checkDuplicateSequences(
        aminoAcidSequencesToCreate,
        {
          fragment: `id name hash ${taggedItems} aliases { id name } inventoryItems { id  }`,
          isProtein: true
        }
      );
      const keyedExistingAAs = keyBy(existingAAs, "hash");
      aminoAcidSequencesToCreate = aminoAcidSequencesToCreate.filter(
        a => !keyedExistingAAs[a.hash]
      );

      existingAAs.forEach(aa => {
        aminoHashToCds[aa.hash].forEach(cds => {
          const cdsName = cds.name;
          cds.aminoAcidSequenceId = aa.id;
          if (cdsName) {
            if (aa.name !== cdsName) {
              const hasAlias = aa.aliases.some(alias => alias.name === cdsName);

              if (!hasAlias) {
                aliasesToCreate.push({
                  name: cdsName,
                  aminoAcidSequenceId: aa.id,
                  targetInventoryItemId: sequenceHashToInvItemId[cds.hash]
                });
              }
            }
          }
        });
      });
      aminoAcidSequencesToCreate.forEach(aaSequence => {
        aaSequence.cid = shortid();
        aminoHashToCds[aaSequence.hash].forEach(cds => {
          cds.aminoAcidSequenceId = `&${aaSequence.cid}`;
          if (cds.name !== aaSequence.name) {
            aliasesToCreate.push({
              name: cds.name,
              aminoAcidSequenceId: `&${aaSequence.cid}`,
              linked: true,
              targetInventoryItemId: sequenceHashToInvItemId[cds.hash]
            });
          }
        });
      });

      // don't make an empty import collection if everything already exists
      if (materialsToCreate.length || cdsToCreate.length) {
        await startImportCollection("Coding Sequences Upload");
      }

      await safeUpsert(
        "material",
        addTaggedItemsBeforeCreate(materialsToCreate, values.tags),
        {
          excludeResults: true
        }
      );
      const createdSequences = await safeUpsert(
        "sequence",
        addTaggedItemsBeforeCreate(sequencesToCreate, values.tags)
      );
      await safeUpsert(
        "aminoAcidSequence",
        addTaggedItemsBeforeCreate(aminoAcidSequencesToCreate, values.tags),
        { excludeResults: true }
      );
      await safeUpsert("sequence", existingPlasmidUpdates, {
        excludeResults: true
      });
      const cdsAAIds = [];
      duplicateCds.forEach(cds => {
        if (cds.aminoAcidSequenceId) {
          cdsAAIds.push(cds.aminoAcidSequenceId);
        }
      });
      const existingAAIds = existingAAs.map(a => a.id).concat(cdsAAIds);
      // need to requery because they might have had new inv items made for them
      const existingAminosWithTags = existingAAIds.length
        ? await safeQuery(["aminoAcidSequence", `id ${taggedItems}`], {
            variables: {
              filter: {
                id: existingAAIds
              }
            }
          })
        : [];

      const existingPlasmidsToTag = uniqBy(
        existingPlasmids.concat(duplicateSequencesFound),
        "id"
      );
      await safeUpsert(
        "sequence",
        addTaggedItemsBeforeCreate(cdsToCreate, values.tags),
        {
          excludeResults: true
        }
      );

      // this order matters, inv items need to be created before aliases
      await safeUpsert("inventoryItem", inventoryItemsToCreate, {
        excludeResults: true
      });
      await upsertUniqueAliases(aliasesToCreate);
      await safeUpsert(
        "sequenceCodingSequence",
        sequenceCodingSequencesToCreate,
        {
          excludeResults: true
        }
      );
      await safeUpsert(
        "sequenceCodingSequence",
        sequenceCodingSequenceUpdates,
        {
          excludeResults: true
        }
      );
      await safeUpsert("sequence", sequenceUpdates, {
        excludeResults: true
      });
      await safeUpsert("materialFpu", materialFpusToCreate, {
        excludeResults: true
      });
      await upsertUniqueAliases(sequenceAliases);
      await createUploadProperties();
      await processSequences(createdSequences.map(s => s.id));

      if (!isEmpty(values.tags)) {
        const itemsToTag = [
          existingAminosWithTags,
          duplicateCds,
          existingProteins,
          existingProteinMaterials,
          existingPlasmidsToTag,
          existingPlasmidMaterialsToTag
        ];
        for (const itemArray of itemsToTag) {
          await createTaggedItems({
            selectedTags: values.tags,
            records: itemArray
          });
        }
      }
      await refetch();
      hideModal();
      window.toastr.success("Successfully uploaded Protein Coding Sequences.");
    } catch (error) {
      console.error("error:", error);
      throwFormError(error || "Error uploading protein DNA.");
    }
  };

  render() {
    const { submitting, handleSubmit, hideModal, error } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <FileUploadField
            fileLimit={1}
            accept={getDownloadTemplateFileHelpers({
              type: allowedCsvFileTypes.concat([".zip"]),
              fileName: "Protein_Coding_Sequences.csv",
              headers: [
                "CDS_FRAG_ID",
                "CDS",
                "PLASMID_NAME",
                "SEQUENCE_FILE",
                "FPU_ID",
                "EOU_DESCRIPTION",
                "RBS_STRENGTH",
                "REFERENCE_CDS_FRAG_ID",
                "REFERENCE_CDS"
              ],
              extendedPropTypes: ["cds", "plasmid"],
              requiredHeaders
            })}
            name="proteinDNAFile"
            isRequired
          />
          <TagField />
          <BlueprintError error={error} />
        </div>
        <DialogFooter
          submitting={submitting}
          hideModal={hideModal}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

export default compose(
  wrapDialog({
    title: "Upload Coding Sequences"
  }),
  reduxForm({
    form: "uploadProteinCodingSequencesDialogForm"
  })
)(UploadProteinDnaDialog);
