/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback } from "react";
import { compose } from "redux";
import { reduxForm, FieldArray } from "redux-form";
import { DialogFooter, wrapDialog } from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import AddPlateFields from "./AddPlateFields";
import { platePrepPlateFragment } from "./fragments";
import { safeUpsert, safeQuery } from "../../../../src-shared/apolloMethods";
import { addBarcodesToRecords } from "../../../../../tg-iso-lims/src/utils/barcodeUtils";
import { getUploadAliquotContainers } from "../../../../../tg-iso-lims/src/utils/plateUtils";
import isValidPositiveInteger from "../../../../../tg-iso-shared/src/utils/isValidPositiveInteger";
import { useFormValue } from "../../../../src-shared/hooks/useFormValue";

const form = "createPlates";

const PlatePrepDialog = ({
  hideModal,
  afterCreate,
  submitting,
  handleSubmit,
  initialValues: { id } = {}
}) => {
  const plates = useFormValue(form, "plates");

  const onSubmit = useCallback(
    async values => {
      try {
        const { plates = [], generateBarcodes } = values;
        if (!plates.length) return;
        const platesOrSimpleRacksToCreate = [];
        const racksToCreateAndAddTubeBarcodes = [];
        plates.forEach(p => {
          const {
            name,
            barcode,
            containerArrayType,
            aliquotContainerType,
            shouldFillRack,
            numTubesToFillRack,
            generateTubeBarcodes
          } = p;

          const aliquotContainers = getUploadAliquotContainers({
            newAliquotContainers: [],
            containerArrayType,
            shouldFillRack,
            aliquotContainerType,
            numTubesToFillRack
          });

          const plateOrRackToCreate = {
            name: name,
            containerArrayTypeId: containerArrayType.id,
            barcode: !generateBarcodes
              ? {
                  barcodeString: barcode
                }
              : undefined,
            aliquotContainers
          };
          if (!containerArrayType.isPlate && generateTubeBarcodes) {
            racksToCreateAndAddTubeBarcodes.push(plateOrRackToCreate);
          } else {
            platesOrSimpleRacksToCreate.push(plateOrRackToCreate);
          }
        });

        const createdSimplePlates = await safeUpsert(
          "containerArray",
          platesOrSimpleRacksToCreate
        );
        const createdRacksWithTubesToBarcode = await safeUpsert(
          ["containerArray", "id aliquotContainers { id }"],
          racksToCreateAndAddTubeBarcodes
        );
        const allNewPlatesAndRacks = createdSimplePlates.concat(
          createdRacksWithTubesToBarcode
        );
        if (generateBarcodes) {
          await addBarcodesToRecords(allNewPlatesAndRacks);
        }
        if (createdRacksWithTubesToBarcode.length) {
          let aliquotContainersToBarcode = [];
          createdRacksWithTubesToBarcode.forEach(r => {
            aliquotContainersToBarcode = aliquotContainersToBarcode.concat(
              r.aliquotContainers
            );
          });
          await addBarcodesToRecords(aliquotContainersToBarcode);
        }
        const newPlatesWithBarcodes = await safeQuery(platePrepPlateFragment, {
          variables: {
            filter: {
              id: allNewPlatesAndRacks.map(({ id }) => id)
            }
          }
        });
        afterCreate(newPlatesWithBarcodes);
        hideModal();
      } catch (error) {
        console.error("error:", error);
        window.toastr.error("Error creating plates.");
      }
    },
    [afterCreate, hideModal]
  );

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <FieldArray
          name="plates"
          component={AddPlateFields}
          isEditing={!!id}
          handleSubmit={handleSubmit}
        />
      </div>
      <DialogFooter
        hideModal={hideModal}
        submitting={submitting}
        disabled={!plates?.length}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
};

const validate = values => {
  const { numPlates } = values;
  const errors = {};
  if (!isValidPositiveInteger(numPlates)) {
    errors.numPlates = "Please enter a valid number.";
  }
  return errors;
};

const PlatePrepWithDialog = compose(
  wrapDialog({ title: "Prep New Plates", style: { width: 650 } }),
  reduxForm({ form, validate })
)(PlatePrepDialog);

export default PlatePrepWithDialog;
