/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useMemo } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import {
  BlueprintError,
  DialogFooter,
  FileUploadField,
  wrapDialog
} from "@teselagen/ui";
import { groupBy } from "lodash";
import { safeUpsert, safeQuery } from "../../../src-shared/apolloMethods";
import {
  allowedCsvFileTypes,
  parseCsvOrExcelFile
} from "../../../../tg-iso-shared/src/utils/fileUtils";
import {
  getPlateLocationMap,
  getPositionFromAlphanumericLocation
} from "../../../../tg-iso-lims/src/utils/plateUtils";
import { getDownloadTemplateFileHelpers } from "../../../src-shared/components/DownloadTemplateFileButton";
import GenericSelect from "../../../src-shared/GenericSelect";
import { getAliquotContainerLocation } from "../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { download } from "../../../src-shared/utils/downloadTest";
import { throwFormError } from "../../../src-shared/utils/formUtils";
import getNameKeyedItems from "../../../../tg-iso-shared/src/utils/getNameKeyedItems";
import { useFormValue } from "../../../src-shared/hooks/useFormValue";

export const tubeRackBarcodeHeaders = [
  "Rack Name",
  "Rack Barcode",
  "New Rack Barcode",
  "Rack Position",
  "Tube Barcode"
];

export const uploadRackTubeBarcodesFragment = [
  "containerArray",
  /* GraphQL */ `
    {
      id
      name
      barcode {
        id
        barcodeString
      }
      containerArrayType {
        id
        containerFormat {
          code
          rowCount
          columnCount
          quadrantSize
          is2DLabeled
        }
      }
      aliquotContainers {
        id
        rowPosition
        columnPosition
        barcode {
          id
          barcodeString
        }
      }
    }
  `
];

export async function handleUpdateRackTubeBarcodes({
  csvData,
  racksToUse,
  hasHeaderRow
}) {
  const rackBarcodes = [];
  const rackNames = [];

  const makeError = (message, index) => {
    if (index) {
      message = `Row ${index + (hasHeaderRow ? 2 : 1)}: ${message}`;
    }
    const err = {
      validationError: true,
      message: message
    };
    throw err;
  };
  let groupedRacksByName;
  let groupedRacksByBarcode;
  for (const [index, row] of csvData.entries()) {
    const { "Rack Barcode": rackBarcode, "Rack Name": rackName } = row;
    if (!rackBarcode && !rackName) {
      return makeError(`did not specify a rack name or barcode.`, index);
    }
    if (rackBarcode) {
      rackBarcodes.push(rackBarcode);
    } else {
      rackNames.push(rackName);
    }
  }
  if (!rackBarcodes.length && !rackNames.length) {
    return makeError("File empty.");
  }
  if (racksToUse) {
    groupedRacksByBarcode = groupBy(racksToUse, "barcode.barcodeString");
    groupedRacksByName = groupBy(racksToUse, r => r.name.toLowerCase());
  } else {
    const racksByBarcode = rackBarcodes.length
      ? await safeQuery(uploadRackTubeBarcodesFragment, {
          variables: {
            filter: {
              "barcode.barcodeString": rackBarcodes
            }
          }
        })
      : [];
    groupedRacksByName = await getNameKeyedItems({
      names: rackNames,
      fragment: uploadRackTubeBarcodesFragment,
      group: true
    });
    groupedRacksByBarcode = groupBy(racksByBarcode, "barcode.barcodeString");
  }

  const duplicateRackBarcodes = [];
  for (const barcode of Object.keys(groupedRacksByBarcode)) {
    if (groupedRacksByBarcode[barcode].length > 1) {
      duplicateRackBarcodes.push(barcode);
    } else {
      const rack = groupedRacksByBarcode[barcode][0];
      groupedRacksByBarcode[barcode] = {
        rack,
        locationMap: getPlateLocationMap(rack)
      };
    }
  }
  const duplicateRackNames = [];
  for (const name of Object.keys(groupedRacksByName)) {
    if (groupedRacksByName[name].length > 1) {
      duplicateRackNames.push(name);
    } else {
      const rack = groupedRacksByName[name][0];
      groupedRacksByName[name] = {
        rack,
        locationMap: getPlateLocationMap(rack)
      };
    }
  }
  if (duplicateRackBarcodes.length) {
    return makeError(
      `Multiple racks were found with these barcodes: ${duplicateRackBarcodes.join(
        ", "
      )}. Could not determine which to update.`
    );
  }
  if (duplicateRackNames.length) {
    return makeError(
      `Multiple racks were found with these names: ${duplicateRackNames.join(
        ", "
      )}. Could not determine which to update.`
    );
  }

  const barcodeUpdates = [];
  const barcodeCreates = [];
  const rackBarcodeToNewBarcode = {};
  for (const [index, row] of csvData.entries()) {
    const {
      "Rack Name": rackName,
      "Rack Barcode": rackBarcode,
      "New Rack Barcode": newRackBarcode,
      "Rack Position": location,
      "Tube Barcode": tubeBarcode
    } = row;

    const rackHelper = rackBarcode
      ? groupedRacksByBarcode[rackBarcode]
      : groupedRacksByName[rackName.toLowerCase()];
    if (!rackHelper) {
      return makeError(
        `specifies the rack ${
          rackBarcode ? `barcode ${rackBarcode}` : `name ${rackName}`
        } which does not exist in inventory.`,
        index
      );
    }
    const rackKey = rackBarcode || rackName.toLowerCase();
    if (newRackBarcode) {
      if (
        rackBarcodeToNewBarcode[rackKey] &&
        newRackBarcode !== rackBarcodeToNewBarcode[rackKey]
      ) {
        return makeError(
          `specifies the new barcode ${newRackBarcode} which conflicts with another new barcode for the rack (${rackBarcodeToNewBarcode[rackKey]}).`,
          index
        );
      } else {
        rackBarcodeToNewBarcode[rackKey] = newRackBarcode;
      }
    }
    if (tubeBarcode) {
      if (!location) {
        return makeError(`did not specify a rack position.`, index);
      }
      const position = getPositionFromAlphanumericLocation(
        location,
        rackHelper.rack.containerArrayType.containerFormat
      );
      const location2d = getAliquotContainerLocation(position, {
        force2D: true,
        containerFormat: rackHelper.rack.containerArrayType.containerFormat
      });
      const ac = rackHelper.locationMap[location2d];
      if (!ac) {
        return makeError(
          `specifies the rack position ${location} which does not exist in the rack ${rackBarcode}.`,
          index
        );
      }
      if (ac.barcode?.barcodeString !== tubeBarcode) {
        if (ac.barcode) {
          barcodeUpdates.push({
            id: ac.barcode.id,
            barcodeString: tubeBarcode
          });
        } else {
          barcodeCreates.push({
            aliquotContainerId: ac.id,
            barcodeString: tubeBarcode
          });
        }
      }
    }
  }
  const rackBarcodeUpdates = [];
  const racksIdsWithUpdatedBarcodes = [];
  for (const [rackKey, newRackBarcode] of Object.entries(
    rackBarcodeToNewBarcode
  )) {
    const rack = (groupedRacksByBarcode[rackKey] || groupedRacksByName[rackKey])
      .rack;
    racksIdsWithUpdatedBarcodes.push(rack.id);
    if (rack.barcode) {
      rackBarcodeUpdates.push({
        id: rack.barcode.id,
        barcodeString: newRackBarcode
      });
    } else {
      barcodeCreates.push({
        containerArrayId: rack.id,
        barcodeString: newRackBarcode
      });
    }
  }
  await safeUpsert("barcode", barcodeUpdates.concat(rackBarcodeUpdates));
  await safeUpsert("barcode", barcodeCreates);

  return {
    racksIdsWithUpdatedBarcodes
  };
}

const racksSchema = ["name", "barcode.barcodeString"];
const racksAdditionalFilter = { "containerArrayType.isPlate": false };
const racksFragment = [
  "containerArray",
  "id name barcode { id barcodeString }"
];

const form = "uploadTubeBarcodes";

const UploadRackTubeBarcodes = ({
  hideModal,
  libraryFragment,
  handleSubmit,
  submitting,
  error
}) => {
  const racksForTemplate = useFormValue(form, "racksForTemplate");
  const onSubmit = useCallback(
    async values => {
      try {
        const { rackTubesFile } = values;
        const { data } = await parseCsvOrExcelFile(rackTubesFile);

        const { racksIdsWithUpdatedBarcodes } =
          await handleUpdateRackTubeBarcodes({
            csvData: data
          });
        if (racksIdsWithUpdatedBarcodes.length) {
          await safeQuery(libraryFragment, {
            variables: {
              filter: {
                id: racksIdsWithUpdatedBarcodes
              }
            }
          });
        }
        window.toastr.success("Tubes Updated!");
        hideModal();
      } catch (error) {
        if (error.validationError) {
          return throwFormError(error.message);
        }
        console.error("error:", error);
        window.toastr.error("Error uploading barcodes");
      }
    },
    [hideModal, libraryFragment]
  );

  const rackTubeAccept = useMemo(
    () =>
      getDownloadTemplateFileHelpers({
        type: allowedCsvFileTypes,
        fileName: "tube_barcodes",
        headers: tubeRackBarcodeHeaders,
        requiredHeaders: tubeRackBarcodeHeaders,
        headerMessages: {
          "New Rack Barcode":
            "Optional. Can be used to update the rack's barcode. Must be consistent for all rows."
        }
      }),
    []
  );

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <GenericSelect
          name="racksForTemplate"
          asReactSelect
          isMultiSelect
          label="Racks for Template Download (Optional)"
          schema={racksSchema}
          additionalFilter={racksAdditionalFilter}
          fragment={racksFragment}
          additionalDataFragment={uploadRackTubeBarcodesFragment}
        />
        {!!racksForTemplate?.length && (
          <a
            onClick={() => {
              let template = tubeRackBarcodeHeaders.join(",") + "\n";
              racksForTemplate.forEach(rack => {
                rack.aliquotContainers.forEach(ac => {
                  const position = getAliquotContainerLocation(ac, {
                    containerFormat: rack.containerArrayType.containerFormat
                  });
                  template += `${rack.name},${
                    rack.barcode?.barcodeString || ""
                  },,${position},${ac.barcode?.barcodeString || ""}\n`;
                });
              });

              download(template, "tube_barcodes_template.csv");
            }}
            style={{ marginBottom: 10 }}
          >
            Download Pre-filled Template
          </a>
        )}
        <FileUploadField
          isRequired
          accept={rackTubeAccept}
          name="rackTubesFile"
        />
        <BlueprintError error={error} />
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
};

export default compose(
  wrapDialog({ title: "Upload Tube Rack Barcodes" }),
  reduxForm({ form })
)(UploadRackTubeBarcodes);
