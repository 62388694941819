/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useEffect } from "react";
import { get } from "lodash";
import { NumericInputField } from "@teselagen/ui";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import IntermediateContainerTypeFields from "../../../IntermediateContainerTypeFields";
import {
  standardizeVolume,
  toDecimalPrecision
} from "../../../../../src-shared/utils/unitUtils";
import unitGlobals from "../../../../../../tg-iso-lims/src/unitGlobals";
import { defaultVolumetricUnitCode } from "../../../../../../tg-iso-lims/src/utils/unitUtils";
import usePrevious from "../../../../../src-shared/usePrevious";
import Big from "big.js";
import {
  getDiluentTransferVolumes,
  calculateMinimumNumberOfContainerArrays,
  calculateVolumePerDiluentAliquotContainer
} from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { change } from "redux-form";

const DiluentContainer = ({ toolSchema: { code } }) => {
  const {
    containerArrayToNormalize,
    desiredCellConcentration,
    desiredCellConcentrationUnitCode,
    desiredConcentration,
    desiredConcentrationUnitCode,
    desiredMolarity,
    desiredMolarityUnitCode,
    desiredTransferVolume,
    desiredTransferVolumetricUnitCode,
    intermediateContainerName,
    intermediateContainerType,
    normalizationType,
    numDiluentAliquotContainers,
    numberOfDiluentContainers,
    selectedWellsForPlates,
    transferSourcePlateToNormalizedPlate,
    uniformDiluentAliquotContainerVolume
  } = useSelector(state => state.form?.[code]?.values || {});
  const oldType = usePrevious(intermediateContainerType);

  const dispatch = useDispatch();
  const changeFieldValue = useCallback(
    (fieldName, value) => dispatch(change(code, fieldName, value)),
    [code, dispatch]
  );

  const doCalculations = useCallback(
    overrideNumContainers => {
      const { diluentTransferVolumes, transferMap } =
        getDiluentTransferVolumes({
          normalizationType,
          containerArrayToNormalize,
          selectedWellsForPlates,
          desiredConcentration,
          desiredConcentrationUnitCode,
          desiredCellConcentration,
          desiredCellConcentrationUnitCode,
          desiredMolarity,
          desiredMolarityUnitCode,
          intermediateContainerType,
          transferSourcePlateToNormalizedPlate,
          desiredTransferVolume,
          desiredTransferVolumetricUnitCode
        }) || {};
      if (!diluentTransferVolumes) return;
      const containerFormat = intermediateContainerType?.containerFormat;
      const quadrantSize =
        intermediateContainerType?.containerFormat?.quadrantSize;
      const diluentAliquotContainerType =
        intermediateContainerType?.aliquotContainerType;

      const {
        uniformDiluentAliquotContainerVolume: originalUniformVolume,
        userSpecifiedNumDiluentContainers,
        calculatedNumDiluentContainers
      } = calculateMinimumNumberOfContainerArrays({
        quadrantSize,
        overrideNumContainers,
        diluentTransferVolumes,
        intermediateContainerType,
        numberOfDiluentContainers
      });

      const filteredAndSortedDiluentTransferVolumes = [
        ...diluentTransferVolumes
      ]
        .filter(v => !!v && !new Big(v).eq(0))
        .sort((a, b) => new Big(a).minus(new Big(b)));
      const { diluentAliquotContainers, uniformDiluentAliquotContainerVolume } =
        calculateVolumePerDiluentAliquotContainer({
          uniformDiluentAliquotContainerVolume: originalUniformVolume,
          numDiluentAliquotContainers:
            userSpecifiedNumDiluentContainers || calculatedNumDiluentContainers,
          diluentAliquotContainerType,
          transferMap,
          containerFormat,
          diluentTransferVolumes: filteredAndSortedDiluentTransferVolumes,
          intermediateContainerName
        });

      changeFieldValue("diluentAliquotContainers", diluentAliquotContainers);
      changeFieldValue(
        "uniformDiluentAliquotContainerVolume",
        uniformDiluentAliquotContainerVolume
      );
      if (!userSpecifiedNumDiluentContainers) {
        changeFieldValue(
          "numDiluentAliquotContainers",
          calculatedNumDiluentContainers
        );
      }
      changeFieldValue("transferMap", transferMap);
    },
    [
      changeFieldValue,
      containerArrayToNormalize,
      desiredCellConcentration,
      desiredCellConcentrationUnitCode,
      desiredConcentration,
      desiredConcentrationUnitCode,
      desiredMolarity,
      desiredMolarityUnitCode,
      desiredTransferVolume,
      desiredTransferVolumetricUnitCode,
      intermediateContainerName,
      intermediateContainerType,
      normalizationType,
      numberOfDiluentContainers,
      selectedWellsForPlates,
      transferSourcePlateToNormalizedPlate
    ]
  );

  useEffect(() => {
    if (!oldType) {
      doCalculations();
    } else if (intermediateContainerType !== oldType) {
      doCalculations(true);
    }
  }, [doCalculations, intermediateContainerType, oldType]);

  const normalizeNumDiluentContainers = useCallback(
    value => {
      const num = parseInt(value, 10);
      if (isNaN(num) || num < (numDiluentAliquotContainers || 1))
        return numDiluentAliquotContainers || 1;
      return value;
    },
    [numDiluentAliquotContainers]
  );

  const diluentContainerUnitCode = get(
    intermediateContainerType,
    "aliquotContainerType.volumetricUnitCode"
  );
  const diluentContainerUnit =
    unitGlobals.volumetricUnits[diluentContainerUnitCode] ||
    unitGlobals.volumetricUnits[defaultVolumetricUnitCode];

  let error;
  if (intermediateContainerType) {
    const maxIntermediateContainerVolume =
      standardizeVolume(
        intermediateContainerType.aliquotContainerType.maxVolume,
        intermediateContainerType.aliquotContainerType.volumetricUnitCode
      ) -
      (intermediateContainerType.aliquotContainerType.deadVolume
        ? standardizeVolume(
            intermediateContainerType.aliquotContainerType.deadVolume || 0,
            intermediateContainerType.aliquotContainerType
              .deadVolumetricUnitCode
          )
        : 0);

    if (uniformDiluentAliquotContainerVolume > maxIntermediateContainerVolume) {
      error =
        "Minimum transfer volume would exceed diluent containers max volume. Please choose a larger diluent container.";
    }
  }

  return (
    <div>
      <div className="tg-step-form-section">
        <HeaderWithHelper
          header="Select Diluent Container Type"
          helper="Select the type of diluent container to be used for normalization."
        />
        <div style={{ width: "40%" }}>
          <IntermediateContainerTypeFields
            noBarcodeInfo
            label="Diluent Container"
            error={error}
            formName={code}
          />
        </div>
      </div>
      <div className="tg-step-form-section">
        <HeaderWithHelper
          header="Suggested Number of Diluent Containers"
          helper="Based on the selected diluent container
              type and the volume required to reach target
              concentration, we have calculated the minimum
              number of diluent containers required for
              normalization."
        />
        <div style={{ width: "40%" }}>
          <NumericInputField
            label="Number of Diluent Containers"
            name="numberOfDiluentContainers"
            defaultValue={
              numberOfDiluentContainers === undefined
                ? numDiluentAliquotContainers
                : numberOfDiluentContainers
            }
            normalize={normalizeNumDiluentContainers}
            enableReinitialize
          />
          {intermediateContainerType && (
            <div className="input-with-unit-select">
              Minimum Diluent Per Container:{" "}
              {toDecimalPrecision(
                uniformDiluentAliquotContainerVolume /
                  diluentContainerUnit.liters
              ) +
                " " +
                diluentContainerUnit.code}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DiluentContainer;
