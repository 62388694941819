/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import shortid from "shortid";
import stripFields from "../../../tg-iso-shared/src/utils/stripFields";
import { download } from "../../src-shared/utils/downloadTest";
import { isEmpty } from "lodash";
import { workflowToolFragment } from "../components/NewWorkflowRun/fragments/newWorkflowRunFragment.gql";
import {
  safeUpsert,
  safeQuery,
  safeDelete
} from "../../src-shared/apolloMethods";
import {
  getIoItemFromTaskIoType,
  importWorkflowDefinition
} from "../../../tg-iso-lims/src/utils/workflowUtils";
import { handleZipFiles } from "../../../tg-iso-shared/src/utils/fileUtils";
import { duplicateWorkflowDefinitionFragment } from "../graphql/fragments/duplicateWorkflowDefinitionFragment.gql";
import { DuplicateWorkflowDefinitionFragment } from "../graphql/fragments/duplicateWorkflowDefinitionFragment.gql.generated";
import { IOItem, ToolSchemaInner } from "../components/LimsTools/toolSchemas";
import { ValueOf } from "../../src-shared/typescriptHelpers";
import {
  dataItemTypeMap,
  inventoryItemTypeMap
} from "../../../tg-iso-shared/src/utils/inventoryUtils";

export function getIsStartTask(_task: {
  workflowToolDefinition: { toolCode: string };
}) {
  const task = _task.workflowToolDefinition || _task;
  return task.toolCode === "workflowStartTask";
}

function isLengthOne<T>(array: T | T[]): array is [T] {
  return Array.isArray(array) && array.length === 1;
}

export async function exportWorkflowDefinition(
  workflowDefinitionIdOrIds: string[] | string
) {
  try {
    const workflowDefinitions =
      (await safeQuery<DuplicateWorkflowDefinitionFragment>(
        duplicateWorkflowDefinitionFragment,
        {
          variables: {
            filter: {
              id: workflowDefinitionIdOrIds
            }
          }
        }
      )) as DuplicateWorkflowDefinitionFragment[];
    const strippedWDs = stripFields(workflowDefinitions, ["__typename"]);
    if (isLengthOne(strippedWDs)) {
      download(
        JSON.stringify(strippedWDs[0], null, 2),
        strippedWDs[0].name + ".json",
        "application/json"
      );
    } else {
      const files: { name: string; data: any }[] = [];
      strippedWDs.forEach(strippedWD => {
        files.push({
          name: `${strippedWD.name}.json`,
          data: JSON.stringify(strippedWD, null, 2)
        });
      });
      download(
        await handleZipFiles(files),
        "workflow-definitions.zip",
        "application/zip"
      );
    }
  } catch (error) {
    console.error("error:", error);
    window.toastr.error("Error exporting workflow definition");
  }
}

export async function duplicateWorkflowDefinition(
  workflowDefinitionId: string,
  options: { newName?: string } = {}
) {
  try {
    const workflowDefinition = (await safeQuery<
      DuplicateWorkflowDefinitionFragment,
      false
    >(duplicateWorkflowDefinitionFragment, {
      variables: {
        id: workflowDefinitionId
      }
    })) as DuplicateWorkflowDefinitionFragment;
    const strippedWD = stripFields(workflowDefinition, ["__typename"]);
    return await importWorkflowDefinition(strippedWD, {
      newName: options.newName || workflowDefinition.name + " copy"
    });
  } catch (error) {
    console.error("error:", error);
    window.toastr.error("Error duplicating workflow definition");
  }
}

type DataItemType = { code: ValueOf<typeof dataItemTypeMap> };
type InventoryItemType = { code: ValueOf<typeof inventoryItemTypeMap> };
type TaskIoType = {
  code: string;
  dataItemType?: DataItemType;
  inventoryItemType?: InventoryItemType;
};

export function convertToolSchemaInputToToolDefinition({
  toolSchema,
  taskIoTypes
}: {
  toolSchema: ToolSchemaInner;
  taskIoTypes: TaskIoType[];
}) {
  const { input = {}, output = {}, settings = {} } = toolSchema;
  const { taskIoTypesByDataItemTypeCode, taskIoTypesByInventoryItemTypeCode } =
    getTaskIoTypeMaps(taskIoTypes);
  const [workflowToolInputDefinitions, workflowToolOutputDefinitions] = [
    input,
    output
  ].map(({ ioItems = {} }: { ioItems?: { [itemCode: string]: IOItem } }) => {
    return Object.entries(ioItems).map(
      ([
        itemCode,
        { label, isList, dataItemTypeCode, inventoryItemTypeCode }
      ]) => {
        const taskIoType = dataItemTypeCode
          ? taskIoTypesByDataItemTypeCode[dataItemTypeCode!]
          : taskIoTypesByInventoryItemTypeCode[inventoryItemTypeCode!];
        return {
          label,
          itemCode,
          isList,
          taskIoTypeCode: taskIoType!.code
        };
      }
    );
  });
  const workflowToolSettingDefinitions = Object.entries(settings).map(
    ([
      name,
      { label, canAdjustPresetSettings, workflowToolSettingDefinitionTypeCode }
    ]) => {
      let nameToUse = name;
      if (
        workflowToolSettingDefinitionTypeCode === "MARKDOWN" ||
        workflowToolSettingDefinitionTypeCode === "INTEGRATION" ||
        workflowToolSettingDefinitionTypeCode === "CUSTOM_QUERY"
      ) {
        nameToUse = label;
      }
      return {
        name: nameToUse,
        isPresetValue: canAdjustPresetSettings,
        workflowToolSettingDefinitionTypeCode:
          workflowToolSettingDefinitionTypeCode || "GENERIC"
      };
    }
  );
  return {
    workflowToolInputDefinitions,
    workflowToolOutputDefinitions,
    workflowToolSettingDefinitions
  };
}

function getTaskIoTypeMaps(taskIoTypes: TaskIoType[]) {
  const taskIoTypesByDataItemTypeCode: {
    [key in ValueOf<typeof dataItemTypeMap>]?: TaskIoType;
  } = {};
  const taskIoTypesByInventoryItemTypeCode: {
    [key in ValueOf<typeof inventoryItemTypeMap>]?: TaskIoType;
  } = {};

  taskIoTypes.forEach(taskIoType => {
    const { dataItemType, inventoryItemType } = taskIoType;
    if (dataItemType) {
      taskIoTypesByDataItemTypeCode[dataItemType.code] = taskIoType;
    } else if (inventoryItemType) {
      taskIoTypesByInventoryItemTypeCode[inventoryItemType.code] = taskIoType;
    }
  });
  return {
    taskIoTypesByDataItemTypeCode,
    taskIoTypesByInventoryItemTypeCode
  };
}

export function computeTaskSettingDefDirtyState(
  dirtySettingDefs: { [settingDefId: string]: boolean },
  settingDef: { id: string },
  dirty: boolean
) {
  if (dirty && !dirtySettingDefs[settingDef.id]) {
    dirtySettingDefs[settingDef.id] = true;
  } else if (!dirty && dirtySettingDefs[settingDef.id]) {
    delete dirtySettingDefs[settingDef.id];
  }
  const newOverallDirty = !isEmpty(dirtySettingDefs);
  return newOverallDirty;
}

export async function createMissingIoItemsForTool(workflowTool: {
  id?: string;
  workflowToolInputs: {
    id: string;
    workflowToolInputIoItems: { id: string; ioItem?: any }[];
    workflowToolInputDefinition: { isList?: boolean; taskIoTypeCode: string };
  }[];
  workflowToolOutputs: {
    id?: string;
    ioItem?: any;
    workflowToolOutputDefinition: { isList?: boolean; taskIoTypeCode: string };
  }[];
}) {
  const workflowToolInputIoItemsToDelete: string[] = [];
  const workflowToolInputIoItemToCreate: ({
    wtiId: string;
    isList?: boolean;
  } & ReturnType<typeof getIoItemFromTaskIoType>)[] = [];
  const workflowToolOutputsToUpdate: { id?: string; ioItemId: string }[] = [];
  const ioItemsToCreate = [];
  workflowTool.workflowToolInputs.forEach(workflowToolInput => {
    const isList = workflowToolInput.workflowToolInputDefinition.isList;
    if (
      !workflowToolInput.workflowToolInputIoItems.length ||
      workflowToolInput.workflowToolInputIoItems.some(wti => !wti.ioItem)
    ) {
      workflowToolInput.workflowToolInputIoItems.forEach(inputIoItem => {
        workflowToolInputIoItemsToDelete.push(inputIoItem.id);
      });
      workflowToolInputIoItemToCreate.push({
        wtiId: workflowToolInput.id,
        isList,
        ...getIoItemFromTaskIoType(
          null,
          workflowToolInput.workflowToolInputDefinition.taskIoTypeCode
        )
      });
    }
  });
  workflowTool.workflowToolOutputs.forEach(workflowToolOutput => {
    const isList = workflowToolOutput.workflowToolOutputDefinition.isList;
    if (!workflowToolOutput.ioItem) {
      const cid = shortid();
      ioItemsToCreate.push({
        cid: cid,
        isList,
        ...getIoItemFromTaskIoType(
          null,
          workflowToolOutput.workflowToolOutputDefinition.taskIoTypeCode
        )
      });
      workflowToolOutputsToUpdate.push({
        id: workflowToolOutput.id,
        ioItemId: `&${cid}`
      });
    }
  });
  if (
    ioItemsToCreate.length ||
    workflowToolInputIoItemToCreate.length ||
    workflowToolInputIoItemsToDelete.length ||
    workflowToolOutputsToUpdate.length
  ) {
    await safeDelete(
      // @ts-ignore
      "workflowToolInputIoItem",
      workflowToolInputIoItemsToDelete
    );
    await safeUpsert(
      // @ts-ignore
      "workflowToolInputIoItem",
      workflowToolInputIoItemToCreate
    );
    await safeUpsert("ioItem", workflowToolInputIoItemToCreate);
    // @ts-ignore
    await safeUpsert("workflowToolOutput", workflowToolOutputsToUpdate);
    const updatedWorkflowTool = await safeQuery<any, false>(
      workflowToolFragment,
      {
        variables: {
          id: workflowTool.id
        }
      }
    );
    return updatedWorkflowTool;
  } else {
    return workflowTool;
  }
}
