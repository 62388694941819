/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { identity } from "lodash";
import StepForm from "../../../../src-shared/StepForm";
import UploadPlate from "./UploadPlate";
import { submitTwistPlate } from "../../Dialogs/UploadTwistPlateDialog";
import { submitIdtPlate } from "../../Dialogs/UploadIdtPlateDialog";
import { submitReagentPlate } from "../../Dialogs/UploadReagentPlateDialog";
import { submitProteinPlate } from "../../Dialogs/UploadProteinPlateDialog";
import { submitDnaPlate } from "../../Dialogs/UploadDnaPlateDialog";
import { showDialog } from "../../../../src-shared/GlobalDialog";
import "./style.css";
import { submitCellCulturePlate } from "../../Dialogs/UploadCellCulturePlateDialog";
import { submitMicrobialPlate } from "../../Dialogs/UploadMicrobialPlateDialog";
import { throwFormError } from "../../../../src-shared/utils/formUtils";

const onSubmit = async values => {
  const { shouldAssignToLocation, uploadForms } = values;
  let allContainerArrays = [];
  const errors = {
    uploadForms: []
  };
  let cancelled = false;
  const containerArrayTypes = [];
  for (const [index, formValues] of uploadForms.entries()) {
    let containerArrays;
    if (cancelled) break;

    // eslint-disable-next-line no-loop-func
    const throwErrorHelper = error => {
      errors.uploadForms[index] = { generalError: error };
      cancelled = true;
    };

    const options = {
      makeError: throwErrorHelper
    };

    switch (formValues.uploadType) {
      case "Twist":
        containerArrays = await submitTwistPlate(formValues, options);
        break;
      case "IDT":
        containerArrays = await submitIdtPlate(formValues, options);
        break;
      case "Reagent":
        containerArrays = await submitReagentPlate(formValues, options);
        break;
      case "Protein":
        containerArrays = await submitProteinPlate(formValues, options);
        break;
      case "Cell Culture":
        containerArrays = await submitCellCulturePlate(formValues, options);
        break;
      case "Microbial":
        containerArrays = await submitMicrobialPlate(formValues, options);
        break;
      case "DNA":
        containerArrays = await submitDnaPlate(formValues, options);
        break;
      default:
        console.error(("Invalid Upload Type: ", formValues.uploadType));
        break;
    }
    if (containerArrays) {
      const plates = containerArrays.filter(identity);
      if (plates.length && formValues.containerArrayType) {
        containerArrayTypes.push(formValues.containerArrayType);
      }
      allContainerArrays = allContainerArrays.concat(plates);
    }
  }
  if (errors.uploadForms.length) {
    if (!errors._error) errors._error = "Please review upload errors.";
    throwFormError(errors);
  }

  if (allContainerArrays.length) {
    if (shouldAssignToLocation) {
      showDialog({
        modalType: "ASSIGN_PLATE_PLACEMENT_STRATEGY",
        modalProps: {
          plateIds: allContainerArrays.map(p => p.id),
          containerArrayTypes
        }
      });
    }
    return {
      containerArrays: allContainerArrays
    };
  }
};

const steps = [
  {
    title: "Upload CSV",
    Component: UploadPlate,
    withCustomFooter: true
  }
];

const initialValues = { uploadForms: [{}] };

const CsvPlateRegistrationTool = ({ toolIntegrationProps, toolSchema }) => (
  <StepForm
    initialValues={initialValues}
    toolIntegrationProps={toolIntegrationProps}
    toolSchema={toolSchema}
    steps={steps}
    onSubmit={onSubmit}
  />
);

export default CsvPlateRegistrationTool;
