/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { Intent, KeyCombo } from "@blueprintjs/core";
import { keyBy } from "lodash";
import { map } from "lodash";
import React from "react";
import { Helmet } from "react-helmet";
import { showConfirmationDialog } from "@teselagen/ui";
import defaultValueConstants from "../../../tg-iso-shared/src/defaultValueConstants";
import defaultValueGeneratorFragment from "../../../tg-iso-shared/src/fragments/defaultValueGeneratorFragment";
import { safeDelete, useTgQuery } from "../apolloMethods";
import VerticalTable from "../components/VerticalTable";
import AdminCallout from "../SharedAppSettings/AdminCallout";
import { userColumns } from "../utils/libraryColumns";
import { getHelpArticleLink } from "../utils/generalUtils";

// import { BlueprintError } from "@teselagen/ui";
//As an admin, I would like to be able to deactivate/reactivate any user
//As an admin, I would like to see a table of all users and their login status, activation status

const schema = [
  {
    path: "name",
    type: "string",
    displayName: "Field",
    render: (v, r) => r.label || r.name
  },
  {
    path: "defaultTemplateString",
    type: "string",
    displayName: "Template"
  },
  { path: "fieldType", type: "string", displayName: "Type" },
  {
    path: "allowUserOverride",
    type: "string",
    displayName: "Is Overridable?"
  },
  { ...userColumns, hideIfEmpty: true }
  //   name
  // defaultTemplateString
  // fieldType
];

function DefaultValuesManagementPanel() {
  const {
    data: { defaultValueGenerators },
    ...rest
  } = useTgQuery(
    defaultValueGeneratorFragment
    // { variables: { id } }
  );
  if (useTgQuery.checkErrAndLoad(rest))
    return useTgQuery.handleErrAndLoad(rest);
  const dvgByCode = keyBy(defaultValueGenerators, "code");
  return (
    <div className="tg-default-val-settings tg-card">
      <Helmet title="Default Values" />
      <AdminCallout>
        As an admin you can edit default values by entering Assign Default Value
        mode via the "User Icon &gt; Assign Default Values" menu item or by
        pressing
        <KeyCombo className="inline-key-combo" combo="shift+d"></KeyCombo>{" "}
        Navigate the app while in Assign Default Mode to find fields that allow
        for default values. For more info{" "}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={getHelpArticleLink(
            "4714710-default-form-values-admin-configurable"
          )}
        >
          read the help docs here
        </a>
      </AdminCallout>
      <br></br>

      <br></br>
      <h2>Default Values</h2>
      <div style={{ width: "fit-content" }}>
        <VerticalTable
          withSearch
          schema={schema}
          entities={map(defaultValueConstants, dvConstant => {
            const dvGenerator = dvgByCode[dvConstant.code] || {};
            return {
              allowUserOverride:
                dvGenerator.allowUserOverride === true ? "Yes" : "No",
              ...dvConstant,
              user: dvGenerator.user,
              defaultTemplateString: dvGenerator.code
                ? dvGenerator.isUrl
                  ? dvGenerator.endpointUrlString
                  : dvGenerator.templateString
                : dvConstant.defaultTemplateString,
              deleteMessage: "Reset",
              deleteIcon: "reset",
              onDelete: dvGenerator.code
                ? async () => {
                    const confirm = await showConfirmationDialog({
                      text: `Are you sure you want to reset this to its default value?
                    You cannot undo this action.`,
                      intent: Intent.DANGER,
                      confirmButtonText: "Reset",
                      cancelButtonText: "Cancel",
                      canEscapeKeyCancel: true
                    });
                    if (confirm) {
                      await safeDelete(
                        "defaultValueGenerator",
                        dvGenerator.code,
                        {
                          isCode: true
                        }
                      );
                      rest.refetch();
                    }
                  }
                : undefined,
              onEdit: () => {
                window.__showAssignDefaultValueModal({
                  onFinish: () => {
                    rest.refetch();
                  },
                  generateDefaultValue: dvConstant
                });
              }
            };
          })}
        />
      </div>
    </div>
  );
}

export default DefaultValuesManagementPanel;
