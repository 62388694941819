/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { getFeatureTypes } from "@teselagen/sequence-utils";
import { Button, Classes, Intent } from "@blueprintjs/core";
import { DataTable, withTableParams, ReactSelectField } from "@teselagen/ui";
import { formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { keyBy } from "lodash";
import withQuery from "../../../withQuery";
import { safeQuery } from "../../../apolloMethods";
import { wrapDialog } from "@teselagen/ui";
import { addActiveProjectFilter } from "../../../utils/projectUtils";

class AutoAnnotateOligosDialog extends React.Component {
  onSubmit = async ({ annotationType }) => {
    const {
      selectedEntities: selectedOligos,
      modalProps: { sequenceIds, refetchSequences },
      hideModal
    } = this.props;

    const selectedOligoIds = selectedOligos.map(f => f.id);
    try {
      const sequences = await safeQuery(["sequence", "id name"], {
        isPlural: true,
        variables: { filter: { id: sequenceIds } }
      });
      const seqMap = keyBy(sequences, "id");

      const {
        data: { success, numberOfAddedFeaturesMap, tooSmallAnnotations, err }
      } = await window.serverApi.request({
        method: "POST",
        url: "/autoAnnotateOligos",
        data: {
          sequenceIds: Object.keys(seqMap),
          oligoIds: selectedOligoIds,
          userId: localStorage.getItem("userId"),
          annotationType
        }
      });
      if (!success) throw new Error(err);

      if (tooSmallAnnotations.length > 0) {
        window.toastr.warning(
          `These oligos: "${tooSmallAnnotations
            .map(a => a.name)
            .join(", ")}" were too small to be auto-annotated`
        );
      }

      const msg = Object.keys(numberOfAddedFeaturesMap)
        .map(
          seqId =>
            `${seqMap[seqId].name}: ${numberOfAddedFeaturesMap[seqId]} features added`
        )
        .join(",\n");

      hideModal();
      if (!msg) return;
      refetchSequences && (await refetchSequences());
      window.toastr.success(msg);
    } catch (e) {
      console.error(e);
      window.toastr.error("Error auto annotating features.");
    }
  };

  render() {
    const {
      tableParams,
      hideModal,
      loading,
      selectedEntities,
      handleSubmit,
      submitting
    } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          Choose which oligos you would like to annotate as features with the
          selected type.
          <DataTable
            {...tableParams}
            withCheckboxes
            compact
            maxHeight={500}
            doNotShowEmptyRows
            isLoading={loading}
          />
        </div>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions tg-flex justify-space-between">
              <ReactSelectField
                label="Annotation Type"
                name="annotationType"
                options={getFeatureTypes()}
                defaultValue="primer_bind"
              />
              <div>
                <Button minimal text="Cancel" onClick={hideModal} />
                <Button
                  intent={Intent.PRIMARY}
                  text="Add Annotations"
                  type="submit"
                  disabled={!selectedEntities.length}
                  loading={submitting}
                />
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

const selector = formValueSelector("autoAnnotateOligosDialog");

const mapStateToProps = state => ({
  selectedEntities: Object.values(
    selector(state, "reduxFormSelectedEntityIdMap") || {}
  )
    .map(v => v.entity)
    .filter(ent => !!ent)
});

export default compose(
  wrapDialog({
    title: "Select Oligos",
    style: { width: 850 }
  }),
  withTableParams({
    schema: {
      model: "sequence",
      fields: [
        {
          path: "id",
          type: "string",
          isHidden: true,
          searchDisabled: true
        },
        { path: "name", type: "string", displayName: "Name", width: 100 },
        {
          path: "fullSequence",
          type: "number",
          displayName: "Sequence",
          searchDisabled: true
        }
      ]
    },
    additionalFilter: (_, qb) => {
      addActiveProjectFilter(qb, {
        model: "sequence"
      });
      qb.whereAll({ sequenceTypeCode: "OLIGO", isInLibrary: true });
    },
    formName: "autoAnnotateOligosDialog"
  }),
  withQuery(["sequence", "id name fullSequence"], {
    isPlural: true
  }),
  reduxForm({
    form: "autoAnnotateOligosForm", // a unique name for this form
    enableReinitialize: true
  }),
  connect(mapStateToProps)
)(AutoAnnotateOligosDialog);
