/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useMemo } from "react";
import { compose } from "redux";
import { get, kebabCase, startCase } from "lodash";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { DialogFooter, InputField, wrapDialog } from "@teselagen/ui";
import { safeDelete, safeUpsert } from "../../../../src-shared/apolloMethods";
import GenericSelect from "../../../../src-shared/GenericSelect";
import { getInitiallySelectedTags } from "../../../../src-shared/utils/tagUtils";

const getTagRuleTagsByType = (
  { tagRules } = { tagRules: [] },
  existenceValue
) =>
  getInitiallySelectedTags([
    {
      taggedItems: tagRules.filter(tr => tr.existence === existenceValue)
    }
  ]);

// The prop `rule` will be passed if and only if we are editing an
// existing rule set.
const AddTagDesignRuleDialog = props => {
  const onSubmit = async values => {
    try {
      const { hideModal, refetchRuleSet, ruleSet, rule } = props;

      const {
        requiredTags = [],
        restrictedTags = [],
        atLeastOneOfTags = []
      } = values;

      const getTagId = tag => tag.id.split(":")[0];
      const getTagOptionId = tag => tag.id.split(":")[1] || null;
      const getRules = (tags, existence) => {
        return tags.map(tag => ({
          tagId: getTagId(tag),
          tagOptionId: getTagOptionId(tag),
          existence
        }));
      };
      const tagRules = getRules(requiredTags, "required")
        .concat(getRules(restrictedTags, "restricted"))
        .concat(getRules(atLeastOneOfTags, "at-least-one-of"));
      if (rule) {
        await safeUpsert("rule", {
          id: rule.id,
          name: values.name
        });
        await safeDelete(
          "tagRule",
          rule.tagRules.map(tr => tr.id)
        );
        await safeUpsert(
          "tagRule",
          tagRules.map(tagRule => {
            tagRule.ruleId = rule.id;
            return tagRule;
          })
        );
      } else {
        await safeUpsert("rule", {
          ruleSetId: ruleSet.id,
          name: values.name,
          tagRules
        });
      }
      await refetchRuleSet();
      hideModal();
    } catch (err) {
      console.error(err);
      window.toastr.error("Error adding rule.");
    }
  };
  const { hideModal, handleSubmit, rule } = props;
  const defaultValues = useMemo(
    () => ({
      required: getTagRuleTagsByType(rule, kebabCase("required")),
      restricted: getTagRuleTagsByType(rule, kebabCase("restricted")),
      atLeastOneOf: getTagRuleTagsByType(rule, kebabCase("atLeastOneOf"))
    }),
    [rule]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={Classes.DIALOG_BODY}>
        <InputField
          name="name"
          defaultValue={get(rule, "name")}
          label="Rule Name"
        />

        {["required", "restricted", "atLeastOneOf"].map(type => {
          return (
            <GenericSelect
              key={type}
              name={type + "Tags"}
              reactSelectProps={{
                isTagSelect: true
              }}
              asReactSelect
              isMultiSelect
              label={startCase(type) + " Tags"}
              tooltipInfo={tooltipForType[type]}
              defaultValue={defaultValues[type]}
              tableParamOptions={{
                defaults: {
                  order: ["name"]
                }
              }}
              fragment={["tagWithOptionView", "id name color"]}
            />
          );
        })}
      </div>
      <DialogFooter hideModal={hideModal} text="OK" />
    </form>
  );
};

const tooltipForType = {
  required: "Part must have these tags",
  restricted: "Part cannot have these tags",
  atLeastOneOf: "Part must have at least one of these tags"
};

const validate = values => {
  const errors = {};
  ["name"].forEach(key => {
    if (!values[key]) errors[key] = "Required";
  });
  return errors;
};

export default compose(
  wrapDialog(props => {
    return {
      title: props.rule ? "Edit Tag Rule" : "Add Tag Rule",
      style: { width: 550 }
    };
  }),
  reduxForm({
    form: "addTagDesignRuleDialogForm",
    validate,
    enableReinitialize: true
  })
)(AddTagDesignRuleDialog);
