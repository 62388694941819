/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { Icon, Tooltip } from "@blueprintjs/core";
import React, { useContext, useMemo } from "react";
import GenericSelect from "../GenericSelect";
import { popoverOverflowModifiers } from "../utils/generalUtils";
import CurrentUserContext from "../context/CurrentUserContext";

const reactSelectProps = {
  resetOnSelect: true
};

const queryOptions = {
  variables: {
    sort: ["isDeactivated", "username", "-updatedAt"]
  }
};

function UserSelect(props) {
  const { showEmail } = props;
  const { currentUser } = useContext(CurrentUserContext);
  const schema = useMemo(() => {
    const toReturn = [
      {
        path: "username",
        render: (v, rec) => {
          let inner = v;
          if (rec.isDeactivated) {
            inner = (
              <span style={{ display: "flex" }}>
                <Tooltip
                  content="User is deactivated"
                  modifiers={popoverOverflowModifiers}
                  position="left"
                >
                  <Icon
                    size={12}
                    style={{ marginRight: 5 }}
                    icon="warning-sign"
                    intent="danger"
                  />
                </Tooltip>
                {inner}
              </span>
            );
          }
          if (currentUser && currentUser.id === rec.id) {
            return (
              <span style={{ display: "flex", alignItems: "center" }}>
                {inner} <Icon style={{ marginLeft: 5 }} size={12} icon="user" />
              </span>
            );
          }
          return inner;
        }
      }
    ];
    if (showEmail) {
      toReturn.push({
        path: "email"
      });
    }
    return toReturn;
  }, [currentUser, showEmail]);

  const fragment = useMemo(
    () => ["user", `id username isDeactivated ${showEmail ? "email" : ""}`],
    [showEmail]
  );

  const firstItemsToShow = useMemo(() => [currentUser], [currentUser]);

  return (
    <GenericSelect
      schema={schema}
      reactSelectProps={reactSelectProps}
      firstItemsToShow={firstItemsToShow}
      asReactSelect
      queryOptions={queryOptions}
      fragment={fragment}
      {...props}
    />
  );
}

export default UserSelect;
