/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback } from "react";
import {
  molecularWeightColumn,
  sequenceFeaturesColumn,
  designMaterialAvailabilityColumn
} from "../../utils/libraryColumns";
import { Link } from "react-router-dom";
import { compose, withProps } from "recompose";
import gql from "graphql-tag";
import libraryEnhancer from "../../libraryEnhancer";
import { seqSearchAdditionalOrFilter } from "../../components/SequenceSearch";
import { nonDNASeqTypes } from "../../../../tg-iso-shared/src/sequence-import-utils/utils";
import modelNameToLink from "../../utils/modelNameToLink";
import { associatedDesigns } from "../../PartRecordView";
import { get } from "lodash";

const dnaSequenceSchema = {
  model: "sequence",
  fields: [
    {
      path: "name",
      type: "string",
      displayName: "Name"
    },
    {
      path: "size",
      type: "number",
      displayName: "Size"
    },
    {
      path: "sequenceType.name",
      type: "string",
      displayName: "Sequence Type"
    },
    {
      path: "polynucleotideMaterial.name",
      type: "string",
      displayName: "Material",
      render: (v, r) => {
        if (v) {
          return (
            <Link to={modelNameToLink(r.polynucleotideMaterial)}>{v}</Link>
          );
        }
      }
    },
    {
      path: "description",
      type: "string",
      displayName: "Description",
      isHidden: true
    },
    sequenceFeaturesColumn,
    {
      path: "parts.name",
      type: "string",
      displayName: "Parts",
      render: (items, record) => {
        return (
          <div>
            {record.parts.map((part, i) => {
              return (
                <span key={part.id}>
                  <Link to={`/parts?filters=id__equalTo__${part.id}`}>
                    {part.name}
                  </Link>
                  {i !== record.parts.length - 1 && ", "}
                </span>
              );
            })}
          </div>
        );
      }
    },
    associatedDesigns("parts."),
    molecularWeightColumn,
    designMaterialAvailabilityColumn,
    {
      displayName: "Induction Methods",
      render: (v, r) =>
        r.plasmidInductionMethodPlasmids
          .map(pim => pim.inductionMethod.name)
          .join(", ")
    },
    {
      displayName: "Replication Origin",
      render: (v, r) =>
        r.sequenceFeatures.length > 0 &&
        r.sequenceFeatures
          .filter(feature => feature.type === "rep_origin")
          .map(feature => feature.name)
          .join(", ")
    }
  ]
};

const sequenceTypeIndex = dnaSequenceSchema.fields.findIndex(
  f => f.path === "sequenceType.name"
);
// replace sequence type (which will always be RNA) with rna type

const rnaTypeColumn = {
  path: "rnaType.name",
  displayName: "RNA Type"
};

const rnaFields = [...dnaSequenceSchema.fields];
rnaFields[sequenceTypeIndex] = rnaTypeColumn;
const rnaSequenceSchema = {
  ...dnaSequenceSchema,
  fields: rnaFields
};

const fragment = gql`
  fragment sequenceLibraryFragment on sequence {
    id
    name
    size
    hash
    molecularWeight
    sequenceTypeCode
    polynucleotideMaterial {
      id
      name
    }
    sequenceType {
      code
      name
    }
    rnaType {
      id
      name
    }
    description
    circular
    plasmidInductionMethodPlasmids {
      id
      inductionMethod {
        id
        name
      }
    }
    parts {
      id
      name
      elements {
        id
        design {
          lockTypeCode
          id
          name
        }
      }
    }
    isJ5Sequence
    isInLibrary
    sequenceFeatures {
      id
      name
      type
    }
    designMaterialAvailabilityView {
      id
      isAvailable
    }
  }
`;

export function getLibWrapper({ isRNA } = {}) {
  return compose(
    withProps(props => {
      const search = get(props, "location.search", "");

      const additionalFilter = useCallback(
        (_, qb) => {
          if (props.isRNA || isRNA) {
            qb.whereAll({
              sequenceTypeCode: "RNA",
              isInLibrary: true
            });
          } else {
            qb.whereAll({
              sequenceTypeCode: qb.notInList(nonDNASeqTypes),
              isInLibrary: true
            });
          }
        },
        [props.isRNA]
      );
      const additionalOrFilter = useCallback(
        (_, qb) => seqSearchAdditionalOrFilter(search, qb),
        [search]
      );
      return { additionalFilter, additionalOrFilter };
    }),
    libraryEnhancer({
      withSelectedEntities: true,
      schema: isRNA ? rnaSequenceSchema : dnaSequenceSchema,
      fragment,
      integrationSubtype: "DNA_SEQUENCE"
    })
  );
}
