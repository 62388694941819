/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { Callout, Intent } from "@blueprintjs/core";
import { keyBy } from "lodash";
import { map } from "lodash";
import React from "react";
import { compose } from "recompose";
import {
  DialogFooter,
  showConfirmationDialog,
  wrapDialog
} from "@teselagen/ui";
import { reduxForm } from "redux-form";

import customHelpDocFragment from "../../../tg-iso-shared/src/fragments/customHelpDocFragment";
import { safeDelete, safeUpsert, useTgQuery } from "../apolloMethods";
import VerticalTable from "../components/VerticalTable";
import { showDialog } from "../GlobalDialog";
import { userColumns } from "../utils/libraryColumns";
import customHelpDocConstants from "./customHelpDocConstants";

import { CustomHelpDocWidgetInner } from "./CustomHelpDocWidget";
import RichHtmlEditor from "../RichHtmlEditor";
import appGlobals from "../appGlobals";
import AdminCallout from "../SharedAppSettings/AdminCallout";
import { Helmet } from "react-helmet";
import { getHelpArticleLink } from "../utils/generalUtils";

const AddEditCustomHelpDoc = compose(
  wrapDialog({
    useCmdEnter: true,
    title: "Edit Help Text"
  }),
  reduxForm({
    form: "editCustomHelpDoc",
    validate: () => {}
  })
)(({
  submitting,
  handleSubmit,
  onFinish,
  hideModal,
  where,
  code,
  initialValues = {}
}) => {
  // console.log(`initialValues.markdown:`, initialValues.markdown);
  // const deser = htmlSerializer.deserialize(initialValues.markdown || "");
  // console.log(`deser:`, deser);
  return (
    <div className="bp3-dialog-body">
      <Callout>Add custom help text to: {where}</Callout>
      <br></br>
      <RichHtmlEditor name="markdown" />
      <DialogFooter
        submitting={submitting}
        onClick={handleSubmit(async ({ markdown }) => {
          await safeUpsert(
            ["customHelpDoc", "code markdown"],
            {
              code,
              markdown: markdown,
              userId: appGlobals.currentUser.id
            },
            {
              idAs: "code",
              ...(initialValues && initialValues.code
                ? {
                    forceUpdate: true
                  }
                : {
                    forceCreate: true
                  })
            }
          );
          window.toastr.success("Custom Help Text Updated Successfully");
          onFinish && onFinish();
          hideModal();
        })}
      />
    </div>
  );
});

const schema = [
  { path: "where", type: "string", displayName: "Where" },
  {
    path: "markdown",
    type: "string",
    render: v => {
      return (
        <CustomHelpDocWidgetInner
          noMargin
          markdown={v}
        ></CustomHelpDocWidgetInner>
      );
    },
    displayName: "Text To Display"
  },
  { ...userColumns, hideIfEmpty: true }
];

function CustomHelpDocsManagementPanel() {
  const {
    data: { customHelpDocs },
    ...rest
  } = useTgQuery(customHelpDocFragment);
  if (useTgQuery.checkErrAndLoad(rest))
    return useTgQuery.handleErrAndLoad(rest);
  const chdByCode = keyBy(customHelpDocs, "code");
  return (
    <div className="tg-custom-help-doc-settings tg-card">
      <Helmet title="Custom Help Text" />
      <AdminCallout intent="primary">
        As an admin you can add or edit custom help text in specific locations
        around the application. &nbsp;
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={getHelpArticleLink(
            "4714710-default-form-values-admin-configurable"
          )}
        >
          Click to learn more
        </a>
      </AdminCallout>
      <h2>Custom Help Text</h2>
      <div style={{ width: "fit-content" }}>
        <VerticalTable
          withSearch
          schema={schema}
          entities={map(customHelpDocConstants, customHelpDocConstant => {
            const customHelpDoc = chdByCode[customHelpDocConstant.code] || {};
            return {
              ...customHelpDocConstant,
              markdown: customHelpDoc.markdown,
              onDelete: customHelpDoc.code
                ? async () => {
                    const confirm = await showConfirmationDialog({
                      text: `Are you sure you want to delete your Custom Help Doc?
                    You cannot undo this action.`,
                      intent: Intent.DANGER,
                      confirmButtonText: "Reset",
                      cancelButtonText: "Cancel",
                      canEscapeKeyCancel: true
                    });
                    if (confirm) {
                      await safeDelete("customHelpDoc", customHelpDoc.code, {
                        isCode: true
                      });
                      rest.refetch();
                    }
                  }
                : undefined,
              onEdit: () => {
                showDialog({
                  ModalComponent: AddEditCustomHelpDoc,
                  modalProps: {
                    code: customHelpDocConstant.code,
                    where: customHelpDocConstant.where,
                    initialValues: {
                      ...customHelpDoc,
                      markdown: customHelpDoc.markdown || ""
                    },
                    onFinish: () => {
                      rest.refetch();
                    }
                  }
                });
              }
            };
          })}
        />
      </div>
    </div>
  );
}

export default CustomHelpDocsManagementPanel;
