/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import { InputField, RadioGroupField } from "@teselagen/ui";
import GenericSelect from "../../GenericSelect";
import { COMMON_LAB_ID } from "@teselagen/auth-utils";
import QueryBuilder from "tg-client-query-builder";
type Props = {
  onlyExistingLabs?: boolean;
  allowCommonLab?: boolean;
  labOptionForUser?: "newLab" | "existingLab";
  tooltipInfo?: string;
};

const existingLabQueryOptions = {
  variables: { sort: ["name"] }
};

const existingLabFragment = ["lab", "id name"];
const LabForUserForm = (props: Props) => {
  const {
    onlyExistingLabs,
    labOptionForUser = "existingLab",
    allowCommonLab = false,
    tooltipInfo
  } = props;

  const existingLabTableParamOptions = useMemo(
    () =>
      allowCommonLab
        ? {}
        : {
            additionalFilter: (_: any, qb: QueryBuilder) => {
              qb.whereAll({
                id: qb.notInList([COMMON_LAB_ID])
              });
            }
          },
    [allowCommonLab]
  );

  return (
    <>
      {onlyExistingLabs ? (
        <h6>
          <b>
            <i>Lab</i>
          </b>
        </h6>
      ) : (
        <RadioGroupField
          tooltipInfo={tooltipInfo}
          defaultValue="existingLab"
          label="Lab"
          inline
          options={[
            {
              label: "Existing Lab",
              value: "existingLab"
            },
            {
              label: "Create New Lab",
              value: "newLab"
            }
          ]}
          name="labOptionForUser"
        />
      )}
      {labOptionForUser === "newLab" ? (
        <InputField
          tooltipInfo={tooltipInfo}
          name="labGroupName"
          isRequired
          label="Lab Group Name"
        />
      ) : (
        <GenericSelect
          tooltipInfo={tooltipInfo}
          schema={["name"]}
          name="existingLab"
          isRequired
          asReactSelect
          fragment={existingLabFragment}
          queryOptions={existingLabQueryOptions}
          tableParamOptions={existingLabTableParamOptions}
        />
      )}
    </>
  );
};

export default LabForUserForm;
