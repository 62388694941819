/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

export const ItemTypes = {
  Material: "material",
  AdditiveMaterial: "additiveMaterial",
  Lot: "lot",
  Sample: "sample",
  J5Report: "j5Report",
  ContainerArray: "containerArray",
  PlateMap: "plateMap",
  InventoryList: "Inventory List",
  ReactionMap: "reactionMap"
} as const;
