/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState, useRef, useCallback } from "react";
import { noop, startCase } from "lodash";
import { generateField } from "@teselagen/ui";
import { InputGroup, ButtonGroup, Button, Classes } from "@blueprintjs/core";

const MustacheTemplateField = ({
  input: { name, value, onChange: _onChange },
  onFieldSubmit = noop,
  input,
  submitOnChange,
  templateVariables = [],
  InputComponent = InputGroup,
  intent,
  inline,
  rows
}) => {
  const ref = useRef(null);
  const [cursorPos, setCursorPos] = useState(0);

  const handleTemplateCursor = useCallback(e => {
    e.persist && e.persist();
    // Defer this to get the updated (post-event) position
    setCursorPos(e.target.selectionStart);
  }, []);

  const handleTemplateButtonClick = useCallback(
    variable => () => {
      const oldValue = value || "";
      let _cursorPos = cursorPos;
      if (!_cursorPos) {
        _cursorPos = oldValue.length;
      }
      const newValue =
        oldValue.substr(0, _cursorPos) +
        `{{{${variable}}}}` +
        oldValue.substr(_cursorPos);
      _onChange(newValue);
      // Refocus and move cursor on input box
      const newPos = _cursorPos + variable.length + 6;

      onFieldSubmit(newValue);
      const el = ref.current.querySelector(`[name="${name}"]`);
      el.focus();
      el.selectionStart = el.selectionEnd = newPos;
      setCursorPos(newPos);
    },
    [_onChange, cursorPos, name, onFieldSubmit, value]
  );

  const onChange = useCallback(
    (e, ...rest) => {
      _onChange(e, ...rest);
      if (submitOnChange) {
        onFieldSubmit(e.target.value);
      }
    },
    [_onChange, onFieldSubmit, submitOnChange]
  );

  const style = {};
  if (inline) {
    style.display = "flex";
    style.position = "relative";
  }
  return (
    <div ref={ref} style={style}>
      <div
        style={{
          width: inline ? "70%" : undefined
        }}
      >
        <InputComponent
          {...input}
          onChange={onChange}
          rows={rows}
          className={Classes.FILL}
          intent={intent}
          onFocus={handleTemplateCursor}
          onClick={handleTemplateCursor}
          onKeyUp={handleTemplateCursor}
          onBlur={(e, val) => {
            input.onBlur(e, val);
            onFieldSubmit(e.target ? e.target.value : val, { blur: true }, e);
          }}
        />
      </div>
      <div
        style={{
          marginTop: inline ? 0 : 12,
          marginLeft: inline ? 20 : 0,
          width: inline ? "25%" : undefined,
          height: "100%"
        }}
      >
        <div
          style={{
            marginBottom: 5
          }}
        >
          Available Template Variables
        </div>
        <ButtonGroup style={{ marginBottom: 15 }} vertical={inline}>
          {templateVariables.map(templateVariable => {
            return (
              <Button
                key={templateVariable}
                text={startCase(templateVariable)}
                style={{
                  textAlign: "center"
                }}
                onClick={handleTemplateButtonClick(templateVariable)}
              />
            );
          })}
        </ButtonGroup>
      </div>
    </div>
  );
};

export default generateField(MustacheTemplateField);
