/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export const j5LogMessagesJ5ReportFragment = gql`
  fragment j5LogMessagesJ5ReportFragment on j5LogMessage {
    id
    message
    j5LogMessageTypeCode
    j5LogMessagePriorityCode
    j5LogMessageJoins {
      id
      specificMsg
      j5RunConstructId
      j5InputPartId
      j5AssemblyPieceId
    }
  }
`;
