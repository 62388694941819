/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState } from "react";
import { Button, Intent, Classes } from "@blueprintjs/core";
import { compose } from "recompose";
import { DialogFooter, wrapDialog } from "@teselagen/ui";
import { showDeleteAlert } from "../../../utils";
import { containerArrayRecordViewAliquotContainerFragment } from "../../../graphql/fragments/containerArrayRecordViewFragment.gql";
import { addAliquotsToAliquotContainers } from "../../../utils/updateAliquotsWithReagents";
import AliquotsTable from "./AliquotsTable";
import TubesTable from "./TubesTable";

import "./style.css";
import AdditivesTable from "./AdditivesTable";
import RecordInfoDisplay from "../../../../src-shared/RecordInfoDisplay";
import { aliquotUnitFields } from "../../Record/AliquotRecordView";
import {
  safeUpsert,
  safeQuery,
  safeDelete
} from "../../../../src-shared/apolloMethods";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { sortAliquotContainers } from "../../../../../tg-iso-lims/src/utils/plateUtils";
import { Link } from "react-router-dom";
import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";
import { renderMaterialsField } from "../../../utils/plateUtils";
import { containerArrayRecordFragment } from "../../../graphql/fragments/containerArrayRecordViewFragment.gql";

export async function handleAssignment(options) {
  const {
    aliquotContainers = [],
    orderToAssign,
    aliquotsToAssign,
    tubesToAssign,
    containerArrayId,
    hideModal
  } = options;
  let sortedAliquotContainers = aliquotContainers;
  if (orderToAssign) {
    sortedAliquotContainers = sortAliquotContainers(
      aliquotContainers,
      orderToAssign
    );
  }

  try {
    if (aliquotsToAssign) {
      await addAliquotsToAliquotContainers(
        sortedAliquotContainers,
        aliquotsToAssign
      );
    } else {
      const tubeUpdates = [];
      for (const [
        index,
        aliquotContainer
      ] of sortedAliquotContainers.entries()) {
        const tubeToAssignHere = tubesToAssign[index];
        if (!tubeToAssignHere) break;
        tubeUpdates.push({
          id: tubeToAssignHere.id,
          rowPosition: aliquotContainer.rowPosition,
          columnPosition: aliquotContainer.columnPosition,
          containerArrayId
        });
      }
      await safeUpsert("aliquotContainer", tubeUpdates);
      await safeQuery(containerArrayRecordFragment, {
        variables: {
          id: containerArrayId
        }
      });
    }
    hideModal();
    return true;
  } catch (error) {
    console.error("error:", error);
    window.toastr.error(error.message || "Error updating aliquot location");
    return false;
  }
}

const InspectAliquotContainerDialog = props => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const {
    aliquotContainer,
    containerArrayId,
    containerArrayType,
    hideModal,
    history,
    selectedAliquots,
    selectedTubes
  } = props;

  const makeSelection = async maybeRecord => {
    if (loading) return;
    const record = maybeRecord || selectedAliquots[0];

    setLoading(true);
    const finished = await handleAssignment({
      aliquotContainers: [aliquotContainer],
      aliquotsToAssign: [record],
      hideModal
    });
    if (!finished) {
      setLoading(false);
    }
  };

  const makeTubeSelection = async maybeTube => {
    if (loading) return;
    const tube = maybeTube || selectedTubes[0];

    setLoading(true);
    const finished = await handleAssignment({
      containerArrayId,
      aliquotContainers: [aliquotContainer],
      tubesToAssign: [tube],
      hideModal
    });
    if (!finished) {
      setLoading(false);
    }
  };

  const deleteAliquot = async () => {
    try {
      hideModal();
      const continueDelete = await showDeleteAlert({ model: "aliquot" });
      if (continueDelete) {
        await safeDelete("aliquot", aliquotContainer.aliquot.id);
        await safeQuery(containerArrayRecordViewAliquotContainerFragment, {
          variables: {
            filter: {
              id: aliquotContainer.id
            }
          }
        });
      }
    } catch (err) {
      console.error("err:", err);
      window.toastr.error("Aliquot deleted at " + aliquotContainer.location);
    }
  };

  const { aliquot, aliquotContainerType } = aliquotContainer;
  let content;

  if (page === 1) {
    if (aliquot) {
      const recordInfo = aliquotUnitFields(aliquot);
      recordInfo.push([
        "Sample",
        aliquot.sample ? (
          <Link to={modelNameToLink(aliquot.sample)}>
            {aliquot.sample.name}
          </Link>
        ) : (
          "Not found"
        )
      ]);
      recordInfo.push(["Material", renderMaterialsField(aliquot)]);
      content = (
        <>
          <h6>Aliquot Information</h6>
          <RecordInfoDisplay recordInfo={recordInfo} />
          <hr className="tg-section-break" />
          <AdditivesTable aliquotId={aliquot.id} />
        </>
      );
    } else if (!aliquotContainerType) {
      content = <div>There is no tube assigned to this rack.</div>;
    } else {
      const msg = `This ${
        containerArrayType.isPlate ? "plate well" : "tube"
      } does not have an aliquot.`;
      content = (
        <div>
          {msg}
          <AdditivesTable aliquotContainerId={aliquotContainer.id} />
        </div>
      );
    }
  } else if (page === 2) {
    if (aliquotContainerType) {
      content = <AliquotsTable onDoubleClick={makeSelection} />;
    } else {
      content = (
        <TubesTable
          onDoubleClick={makeTubeSelection}
          containerArrayType={containerArrayType}
        />
      );
    }
  }
  let footer;
  if (aliquot) {
    footer = (
      <DialogFooter
        hideModal={hideModal}
        additionalButtons={
          <Button
            intent={Intent.DANGER}
            onClick={deleteAliquot}
            text="Delete Aliquot"
          />
        }
        submitting={loading}
        intent="primary"
        onClick={() => {
          history.push("/aliquots/" + aliquot.id);
          hideModal();
        }}
        text="View Aliquot"
      />
    );
  } else if (page === 1) {
    footer = (
      <DialogFooter
        hideModal={hideModal}
        submitting={loading}
        intent={Intent.PRIMARY}
        onClick={() => setPage(page + 1)}
        text={
          !aliquotContainerType
            ? "Assign Tube to Rack"
            : containerArrayType.isPlate
              ? "Assign Aliquot to Plate"
              : "Assign Aliquot to Tube"
        }
      />
    );
  } else {
    footer = (
      <DialogFooter
        submitting={loading}
        secondaryAction={() => setPage(page - 1)}
        secondaryText="Back"
        disabled={
          aliquotContainerType
            ? !selectedAliquots.length
            : !selectedTubes.length
        }
        onClick={() =>
          aliquotContainerType ? makeSelection() : makeTubeSelection()
        }
      />
    );
  }
  return (
    <>
      <div className={Classes.DIALOG_BODY}>{content}</div>
      {footer}
    </>
  );
};

export default compose(
  wrapDialog({
    getDialogProps: props => {
      const { containerArrayType, aliquotContainer = {} } = props;
      let title;
      const location = getAliquotContainerLocation({
        ...aliquotContainer,
        containerArrayType
      });
      if (containerArrayType.isPlate) {
        title = `Inspect Well ${location}`;
      } else {
        title = `Inspect Position ${location}`;
      }
      return {
        title,
        style: {
          width: 800
        }
      };
    }
  })
)(InspectAliquotContainerDialog);
