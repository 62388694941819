/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import client from "./apolloClient";
import { showProgressToast } from "@teselagen/ui";
import {
  getApolloMethods,
  getModelNameFromFragment
} from "@teselagen/apollo-methods";
import modelNameToReadableName from "./utils/modelNameToReadableName";
import useTgQuery, { HandleErrAndLoad } from "./apolloUseTgQuery";

const addRestrictionsToUpsert = upsert => {
  return (...args) => {
    const model =
      typeof args[0] === "string" ? args[0] : getModelNameFromFragment(args[0]);
    if (model === "notification") {
      if (!args[1] || (Array.isArray(args[1]) && !args[1].length)) {
        return [];
      }
      if (![].concat(args[1])[0].id) {
        //we don't want to allow creation of new notifications here! so throw an error
        throw new Error(
          `Sorry, I just can't let you do that..
          Use createNotification() from /src-shared/utils/createNotification instead to create new notifications!`
        );
      }
    }
    return upsert(...args);
  };
};

const {
  upsert: _upsert,
  delete: del,
  deleteWithQuery,
  query,
  updateWithQuery,
  makeSafeQueryWithToast,
  safeUpsert: _safeUpsert,
  safeDelete
} = getApolloMethods(client, {
  safeQueryDefaultPageSize: window.frontEndConfig.SAFE_QUERY_PAGE_SIZE
});

const safeQuery = makeSafeQueryWithToast(
  showProgressToast,
  modelNameToReadableName
);

const safeUpsert = addRestrictionsToUpsert(_safeUpsert);
const upsert = addRestrictionsToUpsert(_upsert);

export {
  upsert,
  del,
  query,
  safeQuery,
  safeUpsert,
  safeDelete,
  deleteWithQuery,
  updateWithQuery,
  useTgQuery,
  HandleErrAndLoad
};
