/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, {
  Children,
  useEffect,
  useRef,
  useState,
  forwardRef
} from "react";
import useStyles from "substyle";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import isNumber from "lodash/isNumber";

import {
  iterateMentionsMarkup,
  mapPlainTextIndex,
  readConfigFromChildren
} from "./utils";

const defaultStyle = {
  position: "relative",
  width: "inherit",
  color: "transparent",

  overflow: "hidden",

  whiteSpace: "pre-wrap",
  wordWrap: "break-word",

  "&singleLine": {
    whiteSpace: "pre",
    wordWrap: null
  },

  substring: {
    visibility: "hidden"
  }
};

const _generateComponentKey = (usedKeys, id) => {
  if (!usedKeys.hasOwnProperty(id)) {
    usedKeys[id] = 0;
  } else {
    usedKeys[id]++;
  }
  return id + "_" + usedKeys[id];
};

const Highlighter = forwardRef(
  (
    {
      value = "",
      inputStyle = {},
      onCaretPositionChange,
      selection,
      style: _style,
      children,
      singleLine
    },
    ref
  ) => {
    const style = useStyles(
      defaultStyle,
      { style: _style },
      { "&singleLine": singleLine }
    );
    const [lastPosition, setLastPosition] = useState({});
    const caretRef = useRef(null);

    useEffect(() => {
      if (!caretRef.current) {
        return;
      }

      const position = {
        left: caretRef.current.offsetLeft,
        top: caretRef.current.offsetTop
      };

      if (isEqual(lastPosition, position)) {
        return;
      }

      setLastPosition(position);

      onCaretPositionChange(position);
    }, [lastPosition, onCaretPositionChange]);

    const renderSubstring = (string, key) => {
      // set substring span to hidden, so that Emojis are not shown double in Mobile Safari
      return (
        <span {...style("substring")} key={key}>
          {string}
        </span>
      );
    };

    // Returns a clone of the Mention child applicable for the specified type to be rendered inside the highlighter
    const getMentionComponentForMatch = (
      id,
      display,
      mentionChildIndex,
      key
    ) => {
      const child = Children.toArray(children)[mentionChildIndex];
      return React.cloneElement(child, { id, display, key });
    };

    // Renders an component to be inserted in the highlighter at the current caret position
    const renderHighlighterCaret = children => {
      return (
        <span {...style("caret")} ref={caretRef} key="caret">
          {children}
        </span>
      );
    };

    const config = readConfigFromChildren(children);

    // If there's a caret (i.e. no range selection), map the caret position into the marked up value
    let caretPositionInMarkup;
    if (selection.start === selection.end) {
      caretPositionInMarkup = mapPlainTextIndex(
        value,
        config,
        selection.start,
        "START"
      );
    }

    const resultComponents = [];
    const componentKeys = {};

    // start by appending directly to the resultComponents
    let components = resultComponents;
    let substringComponentKey = 0;

    const textIteratee = (substr, index) => {
      // check whether the caret element has to be inserted inside the current plain substring
      if (
        isNumber(caretPositionInMarkup) &&
        caretPositionInMarkup >= index &&
        caretPositionInMarkup <= index + substr.length
      ) {
        // if yes, split substr at the caret position and insert the caret component
        const splitIndex = caretPositionInMarkup - index;
        components.push(
          renderSubstring(
            substr.substring(0, splitIndex),
            substringComponentKey
          )
        );

        // add all following substrings and mention components as children of the caret component
        components = [
          renderSubstring(substr.substring(splitIndex), substringComponentKey)
        ];
      } else {
        // otherwise just push the plain text substring
        components.push(renderSubstring(substr, substringComponentKey));
      }

      substringComponentKey++;
    };

    const mentionIteratee = (
      markup,
      index,
      indexInPlainText,
      id,
      display,
      mentionChildIndex
      // lastMentionEndIndex
    ) => {
      // generate a component key based on the id
      const key = _generateComponentKey(componentKeys, id);
      components.push(
        getMentionComponentForMatch(id, display, mentionChildIndex, key)
      );
    };

    iterateMentionsMarkup(value, config, mentionIteratee, textIteratee);

    // append a span containing a space, to ensure the last text line has the correct height
    components.push(" ");

    if (components !== resultComponents) {
      // if a caret component is to be rendered, add all components that followed as its children
      resultComponents.push(renderHighlighterCaret(components));
    }

    return (
      <div
        ref={ref}
        {...style}
        style={{
          ...inputStyle,
          ...style.style
        }}
      >
        {resultComponents}
      </div>
    );
  }
);

Highlighter.propTypes = {
  selection: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number
  }).isRequired,
  value: PropTypes.string.isRequired,
  onCaretPositionChange: PropTypes.func.isRequired,
  inputStyle: PropTypes.object,

  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
};

export default Highlighter;
