/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { ReactSelectField } from "@teselagen/ui";
import { breakdownPatterns } from "../../../PlateReformatTool/utils";
import { Callout } from "@blueprintjs/core";
import { useSelector } from "react-redux";

interface Props {
  containerFormat: {
    rowCount: number;
    columnCount: number;
  };
}

interface ReduxState {
  form?: {
    chooseDistSimpleForm?: {
      values?: {
        distributionPattern: string;
      };
    };
  };
}

function DistributionAdvancedOptions({ containerFormat }: Props) {
  const distributionPattern = useSelector<ReduxState, string | undefined>(
    state => {
      return state.form?.chooseDistSimpleForm?.values?.distributionPattern;
    }
  );
  const isRect =
    (containerFormat.rowCount * containerFormat.columnCount) % 4 === 0;
  if (!isRect) return null;

  return (
    <div>
      {distributionPattern && (
        <Callout intent="warning" style={{ marginTop: 15 }}>
          Using a distribution pattern will override the fill direction and
          replace all items.
        </Callout>
      )}
      <div style={{ minWidth: 250, marginTop: 15 }}>
        <ReactSelectField
          name="distributionPattern"
          label="Distribution Pattern"
          secondaryLabel="(Optional)"
          options={breakdownPatterns(true)}
        />
      </div>
    </div>
  );
}

export default DistributionAdvancedOptions;
