/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import { reduxForm } from "redux-form";
import { compose } from "recompose";
import { keyBy } from "lodash";
import { Classes } from "@blueprintjs/core";
import {
  BlueprintError,
  DialogFooter,
  FileUploadField,
  wrapDialog
} from "@teselagen/ui";
import shortid from "shortid";

import TagField from "../../../../src-shared/TagField";
import { startImportCollection } from "../../../../src-shared/utils/importCollection";
import { addTaggedItemsBeforeCreate } from "../../../../../tg-iso-shared/src/tag-utils";
import { addToUniqArray } from "../../../../../tg-iso-shared/src/utils/generalUtils";
import { safeUpsert, safeQuery } from "../../../../src-shared/apolloMethods";

import {
  allowedCsvFileTypes,
  validateCSVRequiredHeaders,
  validateCSVRow,
  parseCsvOrExcelFile
} from "../../../../../tg-iso-shared/src/utils/fileUtils";

import caseInsensitiveFilter from "../../../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import { getBoundExtendedPropertyUploadHelpers } from "../../../../../tg-iso-shared/src/utils/extendedPropertiesUtils";
import isValidPositiveNumber from "../../../../../tg-iso-shared/src/utils/isValidPositiveNumber";
import unitGlobals from "../../../../../tg-iso-lims/src/unitGlobals";
import { getDownloadTemplateFileHelpers } from "../../../../src-shared/components/DownloadTemplateFileButton";
import { throwFormError } from "../../../../src-shared/utils/formUtils";

const fields = [
  "NAME",
  "REAGENT",
  "DESCRIPTION",
  "VOLUME",
  "VOLUMETRIC_UNIT",
  "CONCENTRATION",
  "CONCENTRATION_UNIT",
  "EXPIRATION_DATE"
];
const requiredFields = ["NAME", "REAGENT", "VOLUME", "VOLUMETRIC_UNIT"];

const UploadLotDialog = props => {
  const { hideModal, refetch, submitting, handleSubmit, error } = props;
  const onSubmit = async values => {
    let data, meta;

    try {
      const { name: filename } = values.lotFile[0];
      try {
        const parsedCsv = await parseCsvOrExcelFile(values.lotFile[0]);
        data = parsedCsv.data;
        meta = parsedCsv.meta;
      } catch (error) {
        console.error("error:", error);
        return window.toastr.error(error.message || "Error parsing CSV file.");
      }
      const maybeError = validateCSVRequiredHeaders(
        meta.fields,
        requiredFields
      );
      if (maybeError) {
        return window.toastr.error(maybeError);
      }

      const { getCsvRowExtProps, createUploadProperties } =
        await getBoundExtendedPropertyUploadHelpers(meta.fields);

      const additiveMaterialNames = [];
      for (const [index, row] of data.entries()) {
        const error = validateCSVRow(row, requiredFields, index);
        if (error) return window.toastr.error(error);
        addToUniqArray(additiveMaterialNames, row.REAGENT.toLowerCase());
      }

      const additiveMaterials = additiveMaterialNames.length
        ? await safeQuery(["additiveMaterial", "id name"], {
            variables: {
              filter: caseInsensitiveFilter(
                "additiveMaterial",
                "name",
                additiveMaterialNames
              )
            }
          })
        : [];

      const keyedAdditiveMaterials = keyBy(additiveMaterials, am => {
        return am.name.toLowerCase();
      });
      const newLots = [];
      for (const [index, row] of data.entries()) {
        const {
          NAME = "",
          REAGENT = "",
          DESCRIPTION,
          VOLUME,
          VOLUMETRIC_UNIT,
          CONCENTRATION,
          CONCENTRATION_UNIT,
          EXPIRATION_DATE
        } = row;
        const reagent = keyedAdditiveMaterials[REAGENT.toLowerCase()];
        if (!reagent) {
          return window.toastr.error(
            `Row ${
              index + 1
            } specifies the reagent ${REAGENT} which was not found.`
          );
        }

        const volume = Number(VOLUME);
        const concentration = CONCENTRATION && Number(CONCENTRATION);
        if (!isValidPositiveNumber(volume)) {
          return window.toastr.error(
            `Row ${index + 1} did not have a valid volume`
          );
        }
        if (concentration && !isValidPositiveNumber(concentration)) {
          return window.toastr.error(
            `Row ${index + 1} did not have a valid concentration`
          );
        }
        if (!unitGlobals.volumetricUnits[VOLUMETRIC_UNIT]) {
          return window.toastr.error(
            `Row ${index + 1} did not have a valid volumetric unit`
          );
        }
        if (
          CONCENTRATION_UNIT &&
          !unitGlobals.concentrationUnits[CONCENTRATION_UNIT]
        ) {
          return window.toastr.error(
            `Row ${index + 1} did not have a valid concentration unit`
          );
        }

        const recordCid = shortid();
        getCsvRowExtProps({
          row,
          recordId: `&${recordCid}`,
          modelTypeCode: "LOT"
        });
        const expirationDate = new Date(EXPIRATION_DATE);
        const isValidDate = expirationDate !== "Invalid Date";
        const lot = {
          cid: recordCid,
          name: NAME,
          description: DESCRIPTION,
          additiveMaterialId: reagent.id,
          volume: volume,
          volumetricUnitCode: VOLUMETRIC_UNIT,
          concentration: concentration || undefined,
          concentrationUnitCode: CONCENTRATION_UNIT || undefined,
          expirationDate: isValidDate ? expirationDate : null
        };
        newLots.push(lot);
      }
      if (newLots.length) {
        await startImportCollection(filename || "Lot Upload");
        await safeUpsert(
          "lot",
          addTaggedItemsBeforeCreate(newLots, values.tags),
          {
            excludeResults: true
          }
        );
        await createUploadProperties();
      }
      await refetch();
      hideModal();
    } catch (error) {
      throwFormError(error);
      window.toastr.error("Error registering new reagent lots.");
    }
  };

  const accept = useMemo(
    () =>
      getDownloadTemplateFileHelpers({
        type: allowedCsvFileTypes,
        fileName: "lots",
        headers: fields,
        extendedPropTypes: ["lot"],
        requiredHeaders: requiredFields
      }),
    []
  );

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <FileUploadField
          accept={accept}
          fileLimit={1}
          name="lotFile"
          isRequired
          label="Upload Lots"
        />
        <TagField />
        <BlueprintError error={error} />
      </div>
      <DialogFooter
        hideModal={hideModal}
        submitting={submitting}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
};

export default compose(
  wrapDialog({
    title: "Upload Reagent Lots"
  }),
  reduxForm({
    form: "uploadLots"
  })
)(UploadLotDialog);
