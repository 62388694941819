/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import { DataTable, convertSchema } from "@teselagen/ui";
import { reduxForm } from "redux-form";
import classNames from "classnames";
import { nanoid } from "nanoid";
import GenericSelect from "../GenericSelect";
import { Popover } from "@blueprintjs/core";
import { isEmpty } from "lodash";
import { isFunction } from "lodash";

export const TgEditableTable = reduxForm({ form: "TgEditableTable" })(({
  schema,
  tableProps
}) => {
  const { schemaToUse, entsToUse } = useMemo(() => {
    const _schemaToUse = convertSchema(schema);
    const entsToInitialize = [{}, {}, {}];

    const _entsToUse = entsToInitialize.map((row, i) => {
      const toRet = {
        _isClean: isEmpty(row) ? true : row._isClean
      };
      _schemaToUse.fields.forEach(({ path, defaultValue }) => {
        if (toRet[path] === undefined || toRet[path] === "") {
          if (defaultValue) {
            if (isFunction(defaultValue)) {
              toRet[path] = defaultValue(i, row);
            } else toRet[path] = defaultValue;
          } else {
            // const exampleToUse = isArray(example) //this means that the row was not added by a user
            //   ? example[i1]
            //   : i1 === 0 && example;
            // toRet[path]
            // if (useExampleData && exampleToUse) {
            //   toRet[path] = exampleToUse;
            //   delete toRet._isClean;
            // } else {
            // }
          }
        }
      });

      if (row.id === undefined) {
        toRet.id = nanoid();
      } else {
        toRet.id = row.id;
      }
      // console.log(`toRet:`, toRet);
      return toRet;
    });

    _schemaToUse.fields.forEach(f => {
      if (f.type === "genericSelect") {
        f.render = toSelect => {
          const toRet = toSelect
            ?.map?.(s =>
              f.schema
                .map(schemaVal => {
                  return s[schemaVal?.path || schemaVal];
                })
                .join(" - ")
            )
            ?.join(", ");
          return toRet;
        };
        f.validate = v => {
          if (f.isRequired && (!v || v.length === 0)) {
            return "Please choose one of the accepted values";
          }
        };
        f.GenericSelectComp = ({
          rowId,
          fullValue,
          finishEdit,
          dataTest,
          cancelEdit
        }) => {
          const containerClassName = classNames(
            "tg-dropdown-cell-edit-container",
            {
              "tg-dropdown-cell-edit-container-multi": f.isMultiSelect
            }
          );
          return (
            <div
              style={{ position: "relative", height: "100%", width: "100%" }}
            >
              <Popover //tnw - maybe we want this popover here or maybe we can do better..?
                minimal
                usePortal
                position="bottom-left"
                content={
                  <div style={{ maxWidth: 300 }}>
                    <GenericSelect
                      defaultValue={fullValue}
                      dataTest={dataTest}
                      handleOpenChange={isOpen => {
                        if (!isOpen) cancelEdit();
                      }}
                      onFieldSubmit={toSelect => {
                        finishEdit(toSelect, true);
                      }}
                      autoOpen
                      placeholder={" "}
                      noMarginBottom
                      asReactSelect
                      name={f.path + "__" + rowId}
                      {...f}
                      label={undefined}
                      containerClassName={containerClassName}
                    />
                  </div>
                }
                isOpen
              >
                <div />
              </Popover>
            </div>
          );
        };
      }
    });
    return { schemaToUse: _schemaToUse, entsToUse: _entsToUse };
  }, [schema]);

  return (
    <DataTable
      formName="editableCellTable"
      isSimple
      doNotValidateUntouchedRows
      isCellEditable
      entities={entsToUse}
      schema={schemaToUse}
      {...tableProps}
    />
  );
});
