/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { getDesign } from "../../../../../../tg-iso-design/selectors/designStateSelectors";
import {
  Button,
  ButtonGroup,
  Tooltip,
  Menu,
  MenuItem,
  Callout
} from "@blueprintjs/core";
import { DesignApprovalStatusIcon } from "../../../DesignApprovalStatusIcon";
import { statusToIconMap } from "../../../../../../tg-iso-design/constants/designStateConstants";
import CommentCard from "../../../../../src-shared/CommentCard";
import {
  useTgQuery,
  safeUpsert,
  safeQuery,
  upsert
} from "../../../../../src-shared/apolloMethods";
import actions from "../../../../../src-shared/redux/actions";
import { showDialog } from "../../../../../src-shared/GlobalDialog";
import { createNotification } from "../../../../../src-shared/utils/createNotification";
import appGlobals from "../../../../../src-shared/appGlobals";
import { DialogFooter, DropdownButton, TextareaField } from "@teselagen/ui";
import { filter, reverse } from "lodash";
import asPromiseDialog from "../../../../../src-shared/utils/asPromiseDialog";

function ReviewInspectorPanelContainer({ design, updateDesign }) {
  const {
    data: { stages = [] },
    ...r1
  } = useTgQuery(["stage", "id"], {
    isPlural: true
  });

  const {
    refetch,
    data: { design: designWithSubmission },
    ...r2
  } = useTgQuery(
    [
      "design",
      /* GraphQL */ `
        {
          id
          name
          activeSubmissionId
          isLocked
          lockMsgDescription
          lockTypeCode
          lockedMessage
          submissions {
            createdAt
            id
            userId
            user {
              id
              username
            }
            stage {
              id
              name
              description
              howManyApprovers
              color
            }
            submissionStateCode
            submissionStageApprovers {
              id
              submissionStageApproverStatusCode
              stageApprover {
                id
                userId
              }
            }
          }
        }
      `
    ],
    {
      variables: {
        id: design.id
      }
    }
  );
  if (useTgQuery.checkErrAndLoad(r1)) return useTgQuery.handleErrAndLoad(r1);
  if (useTgQuery.checkErrAndLoad(r2)) return useTgQuery.handleErrAndLoad(r2);
  if (!stages.length) {
    return (
      <div style={{ padding: 10 }}>
        No Review Stages have been set up yet. An admin will need to set up the
        design approval process before designs can be submitted for approval
      </div>
    );
  }
  let activeSubmission;
  const orderedSubmissions = [];
  const submitForReviewButton = ({ isRestartOfCancel, disabled } = {}) => (
    <Button
      disabled={disabled}
      onClick={() => {
        showDialog({
          modalType: "SubmitDesignForReviewDialog",
          modalProps: {
            design,
            refetch
          }
        });
      }}
      intent="primary"
      {...(isRestartOfCancel && {
        style: { marginTop: 10, marginLeft: 20 },

        icon: "refresh"
      })}
    >
      {isRestartOfCancel ? "Re-Submit" : "Submit For Review"}
    </Button>
  );

  reverse([...designWithSubmission.submissions]).forEach(s => {
    if (s.id === designWithSubmission.activeSubmissionId) {
      activeSubmission = s;
      orderedSubmissions.unshift(s);
    } else {
      orderedSubmissions.push(s);
    }
  });
  const orderedSubmissionsMinusCancelledOnes = filter(
    orderedSubmissions,
    s => s.submissionStateCode !== "CANCELLED"
  );

  return (
    <div className="bp3-card tgApprovalProcessInspector">
      <h5 className="inspector-panel-header">Design Review</h5>
      <div style={{ display: "flex" }}></div>
      {orderedSubmissionsMinusCancelledOnes.length !== stages.length &&
        activeSubmission &&
        activeSubmission.submissionStateCode === "ACCEPTED" && (
          <Button
            style={{ margin: "10px,0px" }}
            onClick={() => {
              showDialog({
                modalType: "SubmitDesignForReviewDialog",
                modalProps: {
                  design,
                  refetch
                }
              });
            }}
          >
            Start Next Review Stage
          </Button>
        )}

      {orderedSubmissions.length
        ? orderedSubmissions.map(submission => {
            let isReviewer = false;
            submission &&
              submission.submissionStageApprovers.forEach(s => {
                const { stageApprover } = s;
                if (
                  stageApprover.userId === window.localStorage.getItem("userId")
                ) {
                  isReviewer = s;
                }
              });

            const isActiveSubmission =
              submission.id === designWithSubmission.activeSubmissionId;
            const isCancelled = submission.submissionStateCode === "CANCELLED";
            return (
              <div key={submission.id}>
                <div
                  style={{
                    // ...(orderedSubmissions.length > 1 && {
                    //   borderTop: "1px solid lightgrey",
                    //   paddingTop: 10
                    // }),
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10
                  }}
                >
                  <React.Fragment>
                    <h5 style={{ marginBottom: 0 }}>{submission.stage.name}</h5>{" "}
                    &nbsp;
                  </React.Fragment>
                  <DesignApprovalStatusIcon
                    {...{
                      noNewApprovalsAllowed:
                        design.lockTypeCode === "LOCKED_FOR_ASSEMBLY",
                      id: design.id,
                      selectedSubmissionId: submission.id,
                      activeSubmission,
                      submissionStateCode: submission.submissionStateCode
                    }}
                  />
                  &nbsp;
                  {isActiveSubmission &&
                    submission.submissionStateCode !== "CANCELLED" && (
                      <Tooltip content="Add Additional Reviewers">
                        <Button
                          disabled={
                            design.lockTypeCode === "LOCKED_FOR_ASSEMBLY"
                          }
                          className="addAdditionalReviewersBtn"
                          onClick={() => {
                            showDialog({
                              modalType: "AddDesignReviewersDialog",
                              modalProps: {
                                designId: design.id,
                                refetch
                              }
                            });
                          }}
                          minimal
                          icon="add"
                        ></Button>
                      </Tooltip>
                    )}
                  {isActiveSubmission &&
                    submission.userId ===
                      window.localStorage.getItem("userId") &&
                    submission.submissionStateCode !== "CANCELLED" && (
                      <DropdownButton
                        className="moreReviewOptsBtn"
                        minimal
                        icon="more"
                        noRightIcon
                        menu={
                          <Menu>
                            <MenuItem
                              disabled={
                                design.lockTypeCode === "LOCKED_FOR_ASSEMBLY"
                              }
                              onClick={async () => {
                                const confirm = await cancelConfirmed({
                                  submissionId: submission.id,
                                  dialogProps: {
                                    title: "Cancel Submission?"
                                  }
                                });
                                if (confirm) {
                                  await safeUpsert("submission", {
                                    id: submission.id,
                                    submissionStateCode: "CANCELLED"
                                  });
                                  await updateDesign({
                                    isLocked: false,
                                    overrideLock: true,
                                    lockedMessage: null,
                                    lockTypeCode: null,
                                    doNotAddToUndoStack: true
                                  });
                                  await refetch();
                                }
                              }}
                              text="Cancel Review"
                            ></MenuItem>
                          </Menu>
                        }
                      ></DropdownButton>
                    )}
                  {isActiveSubmission &&
                    activeSubmission.submissionStateCode === "CANCELLED" &&
                    submitForReviewButton({
                      isRestartOfCancel: true,
                      disabled: design.lockTypeCode === "LOCKED_FOR_ASSEMBLY"
                    })}
                </div>

                <CommentCard
                  {...{
                    isDisabled:
                      !isActiveSubmission ||
                      isCancelled ||
                      design.lockTypeCode === "LOCKED_FOR_ASSEMBLY",
                    noAddComment: !isReviewer || !isActiveSubmission,
                    noCard: true,
                    disableDelete: true,
                    currentUser: appGlobals.currentUser,
                    // __typename it's needed in CommentCard module
                    record: {
                      id: submission.id,
                      __typename: "submission"
                    },
                    addCommentOverrides: {
                      label: "Add a Response"
                    },
                    buttonOverride: ({
                      handleSubmit,
                      submitting,
                      onSubmit
                    }) => {
                      // if (!isReviewer) {
                      //   return null
                      // }
                      return (
                        <ButtonGroup>
                          <Button
                            disabled={
                              design.lockTypeCode === "LOCKED_FOR_ASSEMBLY"
                            }
                            loading={submitting}
                            intent="danger"
                            onClick={handleSubmit(async vals => {
                              await onSubmit({
                                commentProps: {
                                  headerMsg: `Rejected`,
                                  icon: statusToIconMap.REJECTED.icon
                                },
                                beforeFinish: async () => {
                                  const designToUse = await safeQuery(
                                    [
                                      "design",
                                      /* GraphQL */ `
                                        {
                                          id
                                          name
                                          activeSubmissionId
                                          activeSubmission {
                                            createdAt
                                            id
                                            submissionStageApprovers {
                                              id
                                              submissionStageApproverStatusCode
                                              stageApprover {
                                                id
                                                userId
                                              }
                                            }
                                          }
                                        }
                                      `
                                    ],
                                    {
                                      variables: {
                                        id: design.id
                                      }
                                    }
                                  );
                                  let areThereOtherChangesRequested;
                                  designToUse.activeSubmission.submissionStageApprovers.forEach(
                                    ({
                                      submissionStageApproverStatusCode,
                                      stageApprover
                                    }) => {
                                      if (
                                        stageApprover.userId !==
                                        window.localStorage.getItem("userId")
                                      ) {
                                        if (
                                          submissionStageApproverStatusCode ===
                                          "CHANGES_REQUESTED"
                                        ) {
                                          areThereOtherChangesRequested = true;
                                        }
                                      }
                                    }
                                  );
                                  if (!areThereOtherChangesRequested) {
                                    //no other reviewers are requesting changes so go ahead and approve it
                                    await updateDesign({
                                      isLocked: true,
                                      overrideLock: true,
                                      doNotAddToUndoStack: true
                                    });
                                  }

                                  await safeUpsert("submissionStageApprover", {
                                    id: isReviewer.id,
                                    submissionStageApproverStatusCode:
                                      "REJECTED"
                                  });
                                  await safeUpsert(
                                    [
                                      "submission",
                                      "id submissionStateCode design {id }"
                                    ],
                                    {
                                      id: designWithSubmission.activeSubmissionId,
                                      submissionStateCode: "REJECTED"
                                    }
                                  );
                                  await createNotification({
                                    userId: activeSubmission.userId,
                                    message: `${window.localStorage.getItem(
                                      "username"
                                    )} rejected design ${design.name}`,
                                    notificationIntent: "primary",
                                    notificationTypeCode:
                                      "DESIGN_APPROVAL_REQUESTED",
                                    link: `/designs/${design.id}?panel=reviews&submissionId=${submission.id}`
                                  });
                                  window.refetchNotifications &&
                                    window.refetchNotifications();
                                }
                              })(vals);
                            })}
                          >
                            Reject
                          </Button>

                          <Button
                            loading={submitting}
                            intent="warning"
                            disabled={
                              design.lockTypeCode === "LOCKED_FOR_ASSEMBLY"
                            }
                            onClick={handleSubmit(async vals => {
                              await onSubmit({
                                commentProps: {
                                  headerMsg: `Requested Changes`,
                                  icon: statusToIconMap.CHANGES_REQUESTED.icon
                                },
                                beforeFinish: async () => {
                                  //unlock the design because we're requesting changes
                                  await updateDesign({
                                    isLocked: false,
                                    overrideLock: true,
                                    doNotAddToUndoStack: true
                                  });
                                  await safeUpsert("submissionStageApprover", {
                                    id: isReviewer.id,
                                    submissionStageApproverStatusCode:
                                      "CHANGES_REQUESTED"
                                  });
                                  await safeUpsert(
                                    [
                                      "submission",
                                      "id submissionStateCode design {id }"
                                    ],
                                    {
                                      id: designWithSubmission.activeSubmissionId,
                                      submissionStateCode: "CHANGES_REQUESTED"
                                    }
                                  );

                                  await createNotification({
                                    userId: activeSubmission.userId,
                                    message: `${window.localStorage.getItem(
                                      "username"
                                    )} requested changes on design ${
                                      design.name
                                    }`,
                                    notificationIntent: "primary",
                                    notificationTypeCode:
                                      "DESIGN_APPROVAL_REQUESTED",
                                    link: `/designs/${design.id}?panel=reviews&submissionId=${submission.id}`
                                  });
                                  window.refetchNotifications &&
                                    window.refetchNotifications();
                                }
                              })(vals);
                            })}
                          >
                            Request Changes
                          </Button>
                          <Button
                            disabled={
                              design.lockTypeCode === "LOCKED_FOR_ASSEMBLY"
                            }
                            loading={submitting}
                            intent="success"
                            onClick={handleSubmit(async vals => {
                              await onSubmit({
                                commentProps: {
                                  headerMsg: `Approved`,
                                  icon: statusToIconMap.ACCEPTED.icon
                                },
                                beforeFinish: async () => {
                                  const designToUse = await safeQuery(
                                    [
                                      "design",
                                      `id
                name
                activeSubmission {
                  createdAt
                  id
                  submissionStageApprovers {
                    id
                    submissionStageApproverStatusCode
                    stageApprover {
                      id
                      userId
                    }
                  }
                }`
                                    ],
                                    {
                                      variables: {
                                        id: design.id
                                      }
                                    }
                                  );
                                  //add approval logic here!
                                  let areThereOtherChangesRequested;
                                  designToUse.activeSubmission.submissionStageApprovers.forEach(
                                    ({
                                      submissionStageApproverStatusCode,
                                      stageApprover
                                    }) => {
                                      if (
                                        stageApprover.userId !==
                                        window.localStorage.getItem("userId")
                                      ) {
                                        if (
                                          submissionStageApproverStatusCode ===
                                          "CHANGES_REQUESTED"
                                        ) {
                                          areThereOtherChangesRequested = true;
                                        }
                                      }
                                    }
                                  );
                                  if (!areThereOtherChangesRequested) {
                                    //no other reviewers are requesting changes so go ahead and approve it
                                    await updateDesign({
                                      isLocked: true,
                                      overrideLock: true,
                                      doNotAddToUndoStack: true
                                    });
                                  }
                                  await safeUpsert("submissionStageApprover", {
                                    id: isReviewer.id,
                                    submissionStageApproverStatusCode:
                                      "ACCEPTED"
                                  });

                                  await createNotification({
                                    userId: activeSubmission.userId,
                                    message: `${window.localStorage.getItem(
                                      "username"
                                    )} accepted design ${design.name}`,
                                    notificationIntent: "primary",
                                    notificationTypeCode:
                                      "DESIGN_APPROVAL_REQUESTED",
                                    link: `/designs/${design.id}?panel=reviews&submissionId=${submission.id}`
                                  });
                                  window.refetchNotifications &&
                                    window.refetchNotifications();

                                  // let fullyApproved = true;
                                  let numberOrApprovals = 0;
                                  let changesRequestedOrRejected = false;

                                  //loop through all the submissionStageApprovers and check to see if the approval threshold has been met (and no changes requested/rejected)
                                  designToUse.activeSubmission.submissionStageApprovers.forEach(
                                    ({
                                      stageApprover,
                                      submissionStageApproverStatusCode
                                    }) => {
                                      if (
                                        stageApprover.userId ===
                                          isReviewer.stageApprover.userId ||
                                        submissionStageApproverStatusCode ===
                                          "ACCEPTED"
                                      ) {
                                        numberOrApprovals++;
                                      } else if (
                                        submissionStageApproverStatusCode ===
                                          "REJECTED" ||
                                        submissionStageApproverStatusCode ===
                                          "CHANGES_REQUESTED"
                                      ) {
                                        changesRequestedOrRejected = true;
                                      }
                                    }
                                  );

                                  if (
                                    numberOrApprovals >=
                                      submission.stage.howManyApprovers &&
                                    !changesRequestedOrRejected
                                  ) {
                                    await safeUpsert(
                                      [
                                        "submission",
                                        "id submissionStateCode design {id }"
                                      ],
                                      {
                                        id: designWithSubmission.activeSubmissionId,
                                        submissionStateCode: "ACCEPTED"
                                      }
                                    );
                                  }
                                }
                              })(vals);
                            })}
                          >
                            Approve
                          </Button>
                        </ButtonGroup>
                      );
                    }
                  }}
                />
              </div>
            );
          })
        : submitForReviewButton({
            disabled: design.lockTypeCode === "LOCKED_FOR_ASSEMBLY"
          })}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    design: getDesign(state)
  };
};

export default compose(
  connect(mapStateToProps, {
    updateDesign: actions.design.updateDesign
  }),
  reduxForm({ form: "reviewInspectorPanel" })
)(ReviewInspectorPanelContainer);

const cancelConfirmed = asPromiseDialog(
  reduxForm({ form: "CancelSubmissionDialog" })(
    function CancelSubmissionDialog({ resolve, handleSubmit, submissionId }) {
      return (
        <form
          onSubmit={handleSubmit(async ({ message }) => {
            // get the comment
            await upsert("comment", {
              submissionId,
              message,
              headerMsg: "Cancelled Review"
            });
            if (window.__tgReloadComments) {
              window.__tgReloadComments();
            }
            resolve(true);
          })}
        >
          <div className="bp3-dialog-body">
            <Callout intent="primary">
              Are you sure you want to cancel this review? Doing so will unlock
              the design. You cannot undo this action.
            </Callout>
            <br></br>
            <TextareaField
              label="Explanation"
              defaultValue="I am cancelling this request."
              required
              name="message"
            />
          </div>
          <DialogFooter
            text="Confirm Cancel"
            secondaryText="Back"
            secondaryAction={() => {
              resolve();
            }}
            intent="danger"
          ></DialogFooter>
        </form>
      );
    }
  )
);
