/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { Button } from "@blueprintjs/core";

const SimplePaging = ({
  page,
  numPages,
  zeroIndexed,
  setPage
}: {
  page: number;
  numPages: number;
  zeroIndexed?: boolean;
  setPage: (page: number) => void;
}) => (
  <div className="tg-flex align-center">
    <Button
      onClick={() => setPage(page - 1)}
      minimal
      icon="arrow-left"
      disabled={page === (zeroIndexed ? 0 : 1)}
    />
    {zeroIndexed ? page + 1 : page} of {numPages}
    <Button
      onClick={() => setPage(page + 1)}
      minimal
      icon="arrow-right"
      disabled={page === (zeroIndexed ? numPages - 1 : numPages)}
    />
  </div>
);

export default SimplePaging;
