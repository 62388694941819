/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { getRangeLength } from "@teselagen/range-utils";
import {
  volumeRender,
  massRender,
  concentrationRender,
  molarityRender,
  molecularWeightRender,
  materialConcentrationRender
} from "./unitUtils";
import { tagColumn } from "./tagColumn";
import { Colors, Icon } from "@blueprintjs/core";
import { isCommonLabLocked } from "./labUtils";
import { Classes } from "@blueprintjs/core";
import TagsRenderingWrapper from "../TagsRenderingWrapper";
import Tag from "../Tag";
import { Link } from "react-router-dom";
import modelNameToLink from "./modelNameToLink";
import { get, isEmpty, some } from "lodash";
import { convertDnaCaretPositionOrRangeToAA } from "@teselagen/sequence-utils";
import appGlobals from "../appGlobals";
import { getActiveLabId, COMMON_LAB_ID } from "@teselagen/auth-utils";
import dataTableSelectFilterMenu from "../dataTableSelectFilterMenu";
import { getUserLabs } from "./labUtils";
import { commonLockedTooltip } from "../constants/tooltips";
import { getLocationLink } from "../../src-build/graphql/enhancers/withLocationLink";

export { tagColumn };
/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
export const dateCreatedColumn = {
  path: "createdAt",
  type: "timestamp",
  displayName: "Created"
};

export const dateModifiedColumn = {
  path: "updatedAt",
  type: "timestamp",
  displayName: "Modified"
};

export const barcodeColumn = {
  displayName: "Barcode",
  path: "barcode.barcodeString"
};

export const barcodeViewColumn = {
  path: "barcodeString",
  displayName: "Barcode"
};

export const addedByColumn = {
  path: "user.username",
  displayName: "Added By",
  // NOTE: Just in case the user has no username.
  render: (value: string, record: { user?: { email: string } }) =>
    typeof value === "string" && isEmpty(value.trim())
      ? record.user?.email
      : value
};

export const updatedByColumn = {
  path: "updatedByUser.username",
  type: "string",
  displayName: "Updated By",
  // NOTE: Just in case the user has no username.
  render: (value: string, record: { user?: { email: string } }) =>
    typeof value === "string" && isEmpty(value.trim())
      ? record.user?.email
      : value
};

export const batchColumn = {
  path: "batch.name",
  type: "string",
  displayName: "Batch"
};

export const nameWithDeprecateColumn = {
  path: "name",
  render: (v: string, r: { isDeprecated: boolean }) => {
    if (r.isDeprecated) {
      return <span className={Classes.TEXT_MUTED}>{v} (deprecated)</span>;
    }
    return v;
  }
};

export const importCollectionColumn = {
  path: "importCollection.name",
  type: "string",
  displayName: "Import Collection",
  withLink: true
};

export const workflowCollectionColumn = {
  path: "workflowCollection.workflowRun.name",
  type: "string",
  displayName: "Workflow",
  withLink: true
};

type RenderProjectItemsProps = {
  projectItems: {
    id: string;
    project: {
      id: string;
      name: string;
      projectRoles: { id: string; userId: string }[];
    };
  }[];
};

export function RenderProjectItems(r: RenderProjectItemsProps) {
  const projectItems = (r?.projectItems || []).filter(
    projectItem =>
      // Hasura will set 'projectItem.project' to null, if the user is not a member of such project
      projectItem.project &&
      (projectItem.project.id === window.frontEndConfig.allProjectsTagId ||
        // When Hasura is not used, we should make sure we don't show foreign-to-the-current-user projects
        some(
          projectItem.project.projectRoles,
          projectRole => projectRole.userId === appGlobals.currentUser?.id
        ))
  );

  const sortedProjectItems = [...projectItems].sort(
    ({ project: projectA }, { project: projectB }) =>
      projectA.name.localeCompare(projectB.name)
  );
  return (
    <TagsRenderingWrapper>
      {sortedProjectItems.map(projectItem => {
        return (
          <Tag
            key={projectItem.id}
            {...projectItem.project}
            {...(projectItem.project?.id ===
              window.frontEndConfig.allProjectsTagId && {
              color: Colors.GOLD4
            })}
            style={{ marginRight: 5 }}
          />
        );
      })}
    </TagsRenderingWrapper>
  );
}

export const projectsColumn = {
  path: "projectItems.project.name",
  type: "string",
  displayName: "Projects",
  render: (v: any, r: RenderProjectItemsProps) => RenderProjectItems(r)
};

type NestedPartial<T> = {
  [K in keyof T]?: T[K] extends object ? NestedPartial<T[K]> : T[K];
};

export const projectColumnNested = (nestPath: string) => ({
  ...projectsColumn,
  path: nestPath + projectsColumn.path,
  render: (v: any, r: NestedPartial<RenderProjectItemsProps>) =>
    RenderProjectItems(get(r, nestPath))
});

export const aliasColumn = {
  type: "string",
  path: "aliases.name",
  displayName: "Aliases",
  render: (v: any, r: { aliases: { name: string }[] }) => {
    const aliases = r.aliases.map(a => a.name);
    return aliases.join(", ");
  }
};

export const volumeColumn = {
  path: "volume",
  type: "number",
  displayName: "Volume",
  render: volumeRender
};

export const massColumn = {
  path: "mass",
  type: "number",
  displayName: "Mass",
  render: massRender
};
export const concentrationColumn = {
  path: "concentration",
  type: "number",
  displayName: "Concentration",
  render: concentrationRender
};
export const molarityColumn = {
  path: "molarity",
  type: "number",
  displayName: "Molarity",
  render: molarityRender
};

export const molecularWeightColumn = {
  path: "molecularWeight",
  type: "number",
  displayName: "Molecular Weight",
  render: molecularWeightRender
};

export const materialConcentrationColumn = {
  path: "materialConcentration",
  type: "number",
  displayName: "Material Concentration",
  render: materialConcentrationRender
};

export const unitColumns = [
  volumeColumn,
  massColumn,
  concentrationColumn,
  molarityColumn
];

export const dateColumns = [dateCreatedColumn, dateModifiedColumn];
export const userColumns = [addedByColumn, updatedByColumn];

export const generalLibraryColumns = [...userColumns, ...dateColumns];

export const speciesColumn = {
  path: "specie.abbreviatedName",
  type: "string",
  displayName: "Species",
  render: (v: string) => <i>{v}</i>
};

type Specie = { name: string; genus: { name: string } };
type Strain = { specie: Specie };

function isStrainContainer(
  obj: { strain: Strain } | Strain
): obj is { strain: Strain } {
  return "strain" in obj && !!obj.strain;
}

export const genusAndSpeciesColumn = {
  displayName: "Genus and Species",
  render: (_: any, r: { strain: Strain } | Strain) => {
    const specie = isStrainContainer(r) ? r.strain.specie : r.specie;
    if (specie) {
      return `${specie.genus.name} ${specie.name}`;
    }
    return;
  }
};

export const targetOrganismGroupColumn = {
  path: "targetOrganismClass.name",
  type: "string",
  displayName: "Organism Group"
};

export const lockedToCommonColumn = {
  type: "action",
  width: 30,
  resizable: false,
  hideInMenu: true,
  render: (
    v: any,
    r: { id: string; lab: any; labId: string; lockId: string }
  ) => {
    if (isCommonLabLocked(r) || r.lockId) {
      return (
        <div
          data-tip={commonLockedTooltip}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Icon icon="lock" />
        </div>
      );
    }
    return null;
  }
};

export const internalAvailabilityColumn = {
  path: "internalAvailability",
  type: "boolean",
  displayName: "Internal Availability",
  render: (v: boolean) =>
    v ? (
      <Icon intent="success" icon="tick" />
    ) : (
      <Icon intent="danger" icon="cross" />
    )
};

export const externalAvailabilityColumn = {
  path: "externalAvailability",
  type: "boolean",
  displayName: "External Availability",
  render: (v: boolean) =>
    v ? (
      <Icon intent="success" icon="tick" />
    ) : (
      <Icon intent="danger" icon="cross" />
    )
};

export const buildMaterialAvailabilityColumns = [
  internalAvailabilityColumn,
  externalAvailabilityColumn
];

export const designMaterialAvailabilityColumn = {
  path: "designMaterialAvailabilityView.isAvailable",
  type: "boolean",
  displayName: "Available",
  render: (v: boolean) =>
    v ? (
      <Icon intent="success" icon="tick" />
    ) : (
      <Icon intent="danger" icon="cross" />
    )
};

export const sequenceFeaturesColumn = {
  path: "sequenceFeatures.name",
  type: "string",
  displayName: "Features",
  render: (
    v: any,
    r: { sequenceFeatures: { id: string; name: string; __typename: string }[] }
  ) => {
    return r.sequenceFeatures.map((f, i) => {
      return (
        <React.Fragment key={f.id}>
          <Link to={modelNameToLink(f)}>{f.name || "Unnamed feature"}</Link>
          {i !== r.sequenceFeatures.length - 1 && ", "}
        </React.Fragment>
      );
    });
  }
};
const zeroBaseNormalizeFilter = (value: number) => (value ? value - 1 : value);

type Annotation = { start: number; end: number; overlapsSelf: boolean };

export const sizeColumn = {
  displayName: "Size",
  render: (__: any, ann: Annotation) => {
    const { start, end } = ann;
    const srcSize = get(ann, "sequence.size", 0);
    let size = getRangeLength({ start, end }, srcSize);
    if (ann.overlapsSelf) {
      size += srcSize;
    }
    return `${size}  (${start + 1} - ${end + 1}) ${
      ann.overlapsSelf ? "(Overlaps Self)" : ""
    }`;
  }
};

export const sizeInfo = (ann: Annotation) => [
  sizeColumn.displayName,
  sizeColumn.render(null, ann)
];

export const annotationSizeStartEndColumns = [
  {
    path: "start",
    type: "number",
    displayName: "Start",
    render: (v: number) => {
      return v + 1;
    },
    normalizeFilter: zeroBaseNormalizeFilter,
    isHidden: true
  },
  {
    path: "end",
    type: "number",
    displayName: "End",
    render: (v: number) => {
      return v + 1;
    },
    normalizeFilter: zeroBaseNormalizeFilter,
    isHidden: true
  },
  sizeColumn
];

export const aaSizeColumn = {
  displayName: "Size",
  render: (__: any, ann: Annotation) => {
    const { start, end } = convertDnaCaretPositionOrRangeToAA(ann);
    const size = getRangeLength({ start, end });
    return `${size}  (${start + 1} - ${end + 1})`;
  }
};

export const aaSizeInfo = (ann: Annotation) => [
  aaSizeColumn.displayName,
  aaSizeColumn.render(null, ann)
];

export const aaAnnotationSizeStartEndColumns = [
  {
    type: "number",
    displayName: "Start",
    isHidden: true,
    render: (v: any, r: Annotation) =>
      convertDnaCaretPositionOrRangeToAA({ start: r.start, end: r.end }).start
  },
  {
    type: "number",
    displayName: "End",
    isHidden: true,
    render: (v: any, r: Annotation) =>
      convertDnaCaretPositionOrRangeToAA({ start: r.start, end: r.end }).end
  },
  aaSizeColumn
];

export const j5ReportAssemblyHierarchicalColumns = [
  {
    path: "isHierarchical",
    type: "boolean",
    render: (v: boolean) => (v ? "Yes" : "No")
  },
  {
    path: "outputCardName"
  },
  {
    path: "assemblyBatchId",
    type: "string",
    // Some j5Reports may be imported with a wrong, deleted or external assemblyBatchId
    // when this happens the j5-import function nullifies such field for FK constraint reasons
    // and stores the id value in the "idFromOriginalAssemblyBatch" field.
    render: (value: string, record: { idFromOriginalAssemblyBatch: string }) =>
      value || record["idFromOriginalAssemblyBatch"]
  },
  {
    path: "treePosition"
  }
];

export const userFirstNameWithIconColumn = {
  type: "string",
  displayName: "First Name",
  path: "firstName",
  render: (firstName: string, rec: { id: string }) => {
    if (appGlobals.currentUser && appGlobals.currentUser.id === rec.id) {
      return (
        <span style={{ display: "flex", alignItems: "center" }}>
          {firstName} &nbsp; <Icon icon="user" />
        </span>
      );
    }
    return firstName;
  }
};

export const linkedWorkflowsColumn = <T extends string>(viewTableName: T) => ({
  displayName: "Linked Workflows",
  path: `${viewTableName}.workflowNames`,
  render: (
    _: any,
    r: { [R in T]?: { workflowIds: string; workflowNames: string } }
  ) => {
    const workflowIds = r[viewTableName]?.workflowIds.split(", ") || [];
    return r[viewTableName]?.workflowNames.split(", ").map((name, i) => {
      return (
        <React.Fragment key={i}>
          <Link to={`/workflow-runs/${workflowIds[i]}`}>{name}</Link>
          {i !== workflowIds.length - 1 && ", "}
        </React.Fragment>
      );
    });
  }
});

export const labGroupColumn = {
  path: "lab.name",
  type: "string",
  displayName: "Lab Group",
  render: (v: any) => v || "Common",
  filterIsActive: (currentParams: { labFilter: any }) =>
    currentParams.labFilter,
  FilterMenu: dataTableSelectFilterMenu({
    getOptions: () => {
      const allLabs = getUserLabs(appGlobals.currentUser!);
      const activeLabId = getActiveLabId();
      const labOptions = allLabs
        .filter(l => l.id === COMMON_LAB_ID || l.id === activeLabId)
        .map(l => ({
          value: l.id,
          label: l.name
        }));
      return labOptions;
    },
    paramKey: "labFilter",
    label: "Lab"
  })
};

export const assignedPositionLocationColumn = {
  path: "fullPath",
  width: 300,
  type: "string",
  displayName: "Location",
  render: (v: any, record: any) => {
    if (v && record.assignedPositionId && record.equipmentId) {
      return (
        <div>
          {getLocationLink({
            locationPath: v,
            assignedPositionId: record.assignedPositionId,
            equipmentId: record.equipmentId
          })}
        </div>
      );
    }
    return v;
  }
};
