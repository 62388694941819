/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useEffect } from "react";
import { CollapsibleCard, DataTable } from "@teselagen/ui";

import TgSequenceEditor from "../../../../../src-shared/TgSequenceEditor";
import useTgQuery from "../../../../../src-shared/apolloUseTgQuery";
import { sequenceFragment } from "../../../../../../tg-iso-shared/src/fragments/sequenceFragment.gql";
import { updateSequenceFromProps } from "../../../../../src-shared/utils/sequenceUtils";
import { Link } from "react-router-dom";
import modelNameToLink from "../../../../../src-shared/utils/modelNameToLink";

function DnaVeCard({ material, ...otherProps }) {
  const {
    data: { sequences },
    ...rest
  } = useTgQuery(sequenceFragment, {
    variables: {
      filter: {
        polynucleotideMaterialId: material.id
      }
    }
  });

  const [selectedSequence, setSelectedSequence] = React.useState(null);

  const onSequenceSelect = sequence => {
    setSelectedSequence(sequence);
    updateSequenceFromProps([sequence]);
  };

  useEffect(() => {
    if (sequences?.length && !selectedSequence) {
      onSequenceSelect(sequences[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sequences]);

  if (useTgQuery.checkErrAndLoad(rest))
    return useTgQuery.handleErrAndLoad(rest);

  let inner;
  if (sequences.length) {
    if (selectedSequence) {
      inner = <TgSequenceEditor {...otherProps} />;
    } else {
      inner = (
        <div style={{ padding: 20 }}>
          <i>Select a sequence to view information.</i>
        </div>
      );
    }
  } else {
    inner = (
      <div style={{ padding: 20 }}>
        <i>There is no sequence associated with this material.</i>
      </div>
    );
  }
  return (
    <CollapsibleCard title="Sequence Map">
      {!!sequences.length && (
        <DataTable
          isSimple
          className="tg-no-min-height-table"
          formName="dnaVeCardSequences"
          entities={sequences}
          schema={[
            {
              path: "name",
              render: (v, r) => {
                return <Link to={modelNameToLink(r)}>{v}</Link>;
              }
            },
            "size"
          ]}
          onSingleRowSelect={onSequenceSelect}
        />
      )}
      <div style={{ marginBottom: 15 }} />
      {inner}
    </CollapsibleCard>
  );
}

export default DnaVeCard;
