/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { DataTable, InfoHelper } from "@teselagen/ui";
import {
  Button,
  Intent,
  Slider,
  Menu,
  MenuItem,
  Popover,
  Position
} from "@blueprintjs/core";
import stepFormValues from "../../../../../src-shared/stepFormValues";
import { groupBy, isEmpty, size, startCase } from "lodash";
import { showDialog } from "../../../../../src-shared/GlobalDialog";
import AddSourceDialog from "../dialogs/AddSourceDialog";
import { safeQuery, safeUpsert } from "../../../../../src-shared/apolloMethods";
import {
  BLASTQueryJobResult,
  DatabaseSource,
  MetadataEntry,
  Result
} from "../blastTypes";
import { InjectedStepFormValuesProps } from "../../../../../src-shared/InjectedStepFormValuesProps";
import { InjectedStepFormProps } from "../../../../../src-shared/StepForm";
import { FormData, SequenceAnnotation, SourceRecord, SourceDatabase } from "..";
import { flashTab } from "../../../../../src-shared/utils/flashTab";

// list of available sources to choose in the add button
const enabledSourceTypes = [
  "referenceDatabase",
  "featureList",
  "partList",
  "oligoList",
  "featuresFromSequence",
  "partsFromSequence",
  "featureGroup",
  "partGroup"
];

type InjectedProps = InjectedStepFormValuesProps<
  FormData,
  "targetSequences" | "sources" | "featureType" | "sequenceAnnotations"
> &
  InjectedStepFormProps<FormData>;

const SelectReferencesStep = (props: InjectedProps) => {
  const {
    Footer,
    footerProps,
    handleSubmit,
    nextStep,
    targetSequences,
    featureType,
    stepFormProps: { change },
    sources = [
      {
        id: "snapgene", // SnapGene is the default source
        name: "SnapGene",
        type: "referenceDatabase"
      }
    ],
    sequenceAnnotations
  } = props;

  const [identityLevel, setIdentityLevel] = useState(100);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      featureType != null &&
      typeof featureType === "object" &&
      featureType.userCreated
    ) {
      const featureName = featureType.label.replace(" ", "_");
      const featToSave = {
        name: featureName,
        color: "#ffffff" // default white color, maybe add a color picker
      };
      safeUpsert("featureTypeOverride", featToSave);
      change("featureType", featureName);
    }
  }, [featureType, change]);

  useEffect(() => {
    flashTab("New annotations found!");
  }, [sequenceAnnotations]);

  const removeSourceType = (record: SourceRecord) => {
    change(
      "sources",
      sources.filter(s => s.type !== record.sourceType)
    );
  };

  /** This adds new sources to the list of corresponding sourceType
   * if the source is already in the list it will not be added
   * if the sourceType is not in the table it will be added
   */
  const addSources = (newSources: SourceDatabase[]) => {
    if (isEmpty(newSources)) return; // maybe ask the user if they want to remove the sourceType

    const sourcesToAdd = newSources.filter(
      source => !sources.some(s => s.id === source.id && s.type === source.type)
    );

    return change("sources", [...sources, ...sourcesToAdd]);
  };

  /** This function can modify the list of sources of the selected sourceType */
  const updateSources = (updatedSources: SourceDatabase[]) => {
    if (isEmpty(updatedSources)) return;

    const existingSourceIds = sources
      .filter(source => source.type === updatedSources[0].type)
      .map(source => source.id);

    // if no existing source of sourceType is found, add the new sources
    if (!existingSourceIds) change("sources", [...sources, ...updatedSources]);

    // if exiting sources is greater than new sources then remove the difference
    if (size(existingSourceIds) > size(updatedSources)) {
      const idsToRemove = existingSourceIds.filter(
        id => !updatedSources.map(s => s.id).includes(id)
      );

      change("sources", [
        ...sources.filter(source => !idsToRemove.includes(source.id))
      ]);
    }

    // if exiting sources is less than new sources then add the difference
    if (size(existingSourceIds) < size(updatedSources)) {
      change("sources", [
        ...sources,
        ...updatedSources.filter(s => !existingSourceIds.includes(s.id))
      ]);
    }
  };

  const openDialog = ({
    sourceType,
    edit = false
  }: {
    sourceType: string;
    edit?: boolean;
  }) => {
    showDialog({
      ModalComponent: AddSourceDialog,
      modalProps: {
        edit,
        sourceType,
        addSource: edit ? updateSources : addSources,
        existingSources: sources
      }
    });
  };

  /**
   * Retrieves and formats metadata of subjects returned by BLAST,
   * applying the required schema to be used within the tool.
   *
   * @example
   * {
   *   subject_id: "42193bf6-be84-4c01-b190-adacbb704199",
   *   feature_name: "cat promoter",
   *   description: "Promoter of the E. coli cat gene",
   *   type: "promoter",
   *   db_type: "referenceDatabase",
   *   db_id: "snapgene"
   * }
   *
   */
  const getMetadata = async (blastResult: Result): Promise<MetadataEntry[]> => {
    const { records, subjects_metadata: metadata } = blastResult;
    const newMetadata: MetadataEntry[] = [];
    const metadataMap = new Map(metadata.map(m => [m.subject_id, m]));

    const queries: {
      id: string;
      type: string;
      db_id: string;
      db_name?: string;
    }[] = [];

    for (const record of records) {
      for (const alignment of record.alignments) {
        const { subject_id: subjectId, subject_db_id: subjectDBId } = alignment;
        const existingMetadata = metadataMap.get(subjectId);

        if (subjectDBId.includes("reference_database") && existingMetadata) {
          const [dbType, dbName] = subjectDBId.split("-");
          newMetadata.push({
            ...existingMetadata,
            db_id: dbType,
            db_name: dbName
          });
        } else if (subjectDBId.includes("feature_list")) {
          queries.push({
            type: "sequenceFeature",
            id: subjectId,
            db_id: "feature_list",
            db_name: subjectDBId
          });
        } else if (subjectDBId.includes("part_list")) {
          queries.push({
            type: "part",
            id: subjectId,
            db_id: "part_list",
            db_name: subjectDBId
          });
        } else if (subjectDBId.includes("oligo_list")) {
          queries.push({
            type: "sequence",
            id: subjectId,
            db_id: "oligo_list",
            db_name: subjectDBId
          });
        } else if (subjectDBId === "features_from_sequence") {
          queries.push({
            type: "sequenceFeature_sq",
            id: subjectId,
            db_id: subjectDBId
          });
        } else if (subjectDBId === "parts_from_sequence") {
          queries.push({
            type: "part_sq",
            id: subjectId,
            db_id: subjectDBId
          });
        } else if (subjectDBId.includes("group")) {
          const [groupType, groupName] = subjectDBId.split("-");
          queries.push({
            type: "registeredAnnotation",
            id: subjectId,
            db_id: groupType,
            db_name: groupName
          });
        }
      }
    }

    // Execute all queries in parallel
    const results = await Promise.all(
      queries.map(q => {
        const structure = q.type.includes("_sq")
          ? "id name type sequence { id name }"
          : "id name type";
        q.type = q.type.replace("_sq", "");
        return safeQuery([q.type, structure], {
          variables: { filter: { id: q.id } }
        });
      })
    );

    // Process results and populate newMetadata
    results.forEach((result, index) => {
      const [entry] = result;
      const query = queries[index];
      newMetadata.push({
        subject_id: entry.id,
        feature_name: entry.name,
        description: entry.description,
        type: entry.type,
        db_id: query.db_id,
        db_name: query.db_name || entry.sequence?.name
      });
    });

    return newMetadata;
  };

  const buildAnnotatedSequences = (
    records: Result["records"],
    metadata: MetadataEntry[]
  ) => {
    const annotations: SequenceAnnotation[] = [];

    if (records.every(record => !size(record.alignments))) {
      flashTab("No annotations found");
      throw new Error(
        "No annotations found, either select a different sequence or try again with a lower identity level"
      );
    }

    records?.forEach((record, idx) => {
      const targetSequence = targetSequences[idx];

      record.alignments.forEach(alignment => {
        const subjectId = alignment.subject_id;
        const subjectMetadata = metadata.find(m => m.subject_id === subjectId);

        const annotation = alignment.hsps.map(hsp => {
          const {
            align_length,
            gaps,
            query_start: start,
            query_end: end,
            identity_ratio: ratio,
            strands: { query: queryStrand, subject: subjectStrand } = {
              query: "Plus",
              subject: "Plus"
            }
          } = hsp;

          const strand = queryStrand === subjectStrand ? 1 : -1;
          const coverage = (ratio * align_length) / alignment.length;
          return {
            id: crypto.randomUUID(),
            sequenceId: targetSequence?.id,
            start,
            end,
            name: subjectMetadata?.feature_name || subjectId,
            description: subjectMetadata?.description,
            type: subjectMetadata?.type || featureType,
            matchLength: align_length,
            identity: ratio * 100,
            mismatchCount: gaps,
            referenceLength: alignment.length,
            referenceCoverage: coverage * 100,
            sourceType: subjectMetadata?.db_id,
            sourceDescription: subjectMetadata?.db_name,
            strand
          };
        });
        annotations.push(...annotation);
      });

      annotations && change("sequenceAnnotations", annotations);
    });
  };

  /**
   * Generates a list of database sources to be passed to the BLAST API.
   * This function processes the user's selected sources and organizes them
   * into the format required by the BLAST API, including grouping by type
   * and querying additional metadata as needed.
   *
   * @param {SourceDatabase[]} sources Array of sources selected by the user.
   * @returns {Promise<DatabaseSource[]>} A list of database sources to be passed to the BLAST API.
   *
   */
  const getDatabaseSources = async (
    sources: SourceDatabase[]
  ): Promise<DatabaseSource[]> => {
    const databaseSources: DatabaseSource[] = [];
    const groupedSources = groupBy(sources, "type");

    // Add reference databases
    if (groupedSources.referenceDatabase) {
      databaseSources.push(
        ...groupedSources.referenceDatabase.map(db => ({
          seededDBName: db.id,
          dbId: `reference_database-${db.id}`
        }))
      );
    }

    // Add feature lists, part lists, and oligo lists
    const listTypes: {
      type: string;
      idsType: "feature" | "part" | "sequence" | "featureGroup" | "partGroup";
      suffix: string;
    }[] = [
      { type: "featureList", idsType: "feature", suffix: "_feature_list" },
      { type: "partList", idsType: "part", suffix: "_part_list" },
      { type: "oligoList", idsType: "sequence", suffix: "_oligo_list" }
    ];

    listTypes.forEach(({ type, idsType, suffix }) => {
      if (groupedSources[type]) {
        databaseSources.push({
          ids: groupedSources[type].map(s => s.id),
          idsType,
          dbId: `${groupedSources[type].length}${suffix}`
        });
      }
    });

    // Fetch sequence features and parts from sequences in parallel
    const [sequenceFeatures, parts] = (await Promise.all([
      groupedSources.featuresFromSequence
        ? safeQuery(["sequenceFeature", "id"], {
            variables: {
              filter: {
                sequenceId: groupedSources.featuresFromSequence.map(
                  seq => seq.id
                )
              }
            }
          })
        : Promise.resolve([]),
      groupedSources.partsFromSequence
        ? safeQuery(["part", "id"], {
            variables: {
              filter: {
                sequenceId: groupedSources.partsFromSequence.map(seq => seq.id)
              }
            }
          })
        : Promise.resolve([])
    ])) as [{ id: string }[], { id: string }[]];

    // Add features and parts from sequences
    if (sequenceFeatures.length > 0) {
      databaseSources.push({
        ids: sequenceFeatures.map(feat => feat.id),
        idsType: "feature",
        dbId: "features_from_sequence"
      });
    }

    if (parts.length > 0) {
      databaseSources.push({
        ids: parts.map(part => part.id),
        idsType: "part",
        dbId: "parts_from_sequence"
      });
    }

    // Add feature and part groups
    const groupTypes: {
      type: string;
      idsType: "partGroup" | "featureGroup";
      prefix: string;
    }[] = [
      {
        type: "featureGroup",
        idsType: "featureGroup",
        prefix: "feature_group"
      },
      { type: "partGroup", idsType: "partGroup", prefix: "part_group" }
    ];

    groupTypes.forEach(({ type, idsType, prefix }) => {
      if (groupedSources[type]) {
        groupedSources[type].forEach(group => {
          databaseSources.push({
            ids: [group.id],
            idsType,
            dbId: `${prefix}-${group.name}`
          });
        });
      }
    });

    // Filter out empty sources
    return databaseSources.filter(
      source => source.ids?.length || source.seededDBName
    );
  };

  const executeBlastQuery = async (retry = false) => {
    setIsLoading(true);
    window.toastr.success(
      "This process might take a bit, please don't close this tab"
    );
    const data = {
      querySubjects: {
        sequences: targetSequences?.map(seq => seq.fullSequence)
      },
      databaseSources: await getDatabaseSources(sources),
      algorithmParams: {
        perc_identity: identityLevel
      },
      program: "blastn",
      timeout: retry // note: on PRs this can be slow so i'm increasing the timeout
        ? 360000 // 6 minutes (maybe add a way to change this)
        : 600000 // 10 minutes
    };

    try {
      const response = await window.tgApi<BLASTQueryJobResult>({
        method: "POST",
        url: "/blast",
        data
      });

      if (response.error) {
        console.error("Error executing blast query:", response.error);
        window.toastr.error("Error executing blast query", response.error);
        throw new Error("Error executing blast query");
      }
      if (!response.data) {
        console.error("Error executing blast query:");
        window.toastr.error("Error executing blast query");
        throw new Error("Error executing blast query");
      }
      if (response.data?.status === "completed-failed") {
        console.error("Error executing blast query:", response.data?.message);
        window.toastr.error("Error executing blast query");
        throw new Error("Error executing blast query");
      }

      const { data: blastResults } = response;

      if (["pending", "in-progress"].includes(blastResults?.status)) {
        // retry the query
        return await executeBlastQuery(true);
      }

      if (blastResults?.result != null && size(blastResults.result) > 0) {
        const metadata = await getMetadata(blastResults.result);
        buildAnnotatedSequences(blastResults.result?.records, metadata);
        nextStep();
      }
    } catch (error) {
      console.error(`Error trying to execute  blast query:`, error);
      window.toastr.error(
        error.message || "Error trying to execute blast query"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const renderSources = (
    <div className="tg-step-form-section column">
      <DataTable
        required={true}
        formName="sources"
        tableName="Select Sources"
        noSelect
        isSimple
        isInfinite
        schema={[
          { path: "sourceType", displayName: "Type", render: startCase },
          {
            path: "sources",
            displayName: "Sources",
            render: (sources: SourceRecord["sources"]) => {
              return sources.map(s => s.name).join(", ");
            }
          },
          {
            path: "Edit",
            displayName: "Edit",
            render: (_: any, record: SourceRecord) => (
              <div className="tg-flex justify-space-between">
                <Button
                  intent={Intent.NONE}
                  onClick={() =>
                    openDialog({ sourceType: record.sourceType, edit: true })
                  }
                  icon="edit"
                  disabled={isLoading}
                />
                <Button
                  minimal
                  intent={Intent.DANGER}
                  onClick={() => removeSourceType(record)}
                  icon="trash"
                  disabled={isLoading}
                />
              </div>
            )
          }
        ]}
        entities={Object.keys(groupBy(sources, "type")).map(sourceType => ({
          sourceType,
          sources: groupBy(sources, "type")[sourceType]
        }))}
        defaultRowHeight={30}
        withPagination={false}
      >
        <div className="tg-flex align-center">
          <h4 style={{ marginBottom: 2 }}>Select Sources</h4>
          {/* @ts-ignore */}
          <Popover
            minimal
            position={Position.BOTTOM_LEFT}
            content={
              <Menu>
                {enabledSourceTypes.map(sourceType => {
                  return (
                    <MenuItem
                      key={sourceType}
                      text={startCase(sourceType)}
                      onClick={() => openDialog({ sourceType })}
                    />
                  );
                })}
              </Menu>
            }
          >
            <Button
              minimal
              text="Add Source"
              intent={Intent.SUCCESS}
              icon="add"
              disabled={isLoading}
            />
          </Popover>
        </div>
      </DataTable>
    </div>
  );

  const renderAditionalParams = (
    <div className="tg-step-form-section column">
      <h4>Additional Parameters</h4>
      <h6>
        Minimum identity{" "}
        <InfoHelper
          content="Percentage of matched base pairs within the match range"
          size={12}
          isInline
        />
      </h6>
      <div className="tg-flex column" style={{ maxWidth: 450, gap: 24 }}>
        <Slider
          data-test="identityLevel"
          min={70}
          max={100}
          labelStepSize={10}
          value={identityLevel}
          onChange={setIdentityLevel}
          disabled={isLoading}
        />
      </div>
    </div>
  );

  return (
    <React.Fragment>
      {renderSources}
      {renderAditionalParams}
      <Footer
        {...footerProps}
        onNextClick={handleSubmit(executeBlastQuery as any)}
        lading={isLoading}
        disabled={isEmpty(sources)}
      />
    </React.Fragment>
  );
};

export default compose<any, any>(
  stepFormValues(
    "targetSequences",
    "sources",
    "featureType",
    "sequenceAnnotations"
  )
)(SelectReferencesStep);
