/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import { chunk, startCase } from "lodash";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import modelNameToLink from "../utils/modelNameToLink";
import { aliasModels } from "@teselagen/constants";
import {
  tagModels,
  extendedPropertyModels,
  externalReferenceKeys,
  expirationDateModels,
  labModels
} from "../../../tg-iso-shared/constants";
import RecordExtendedProperties from "./RecordExtendedProperties";
import RecordInfoTable from "./RecordInfoTable";
import RecordTags from "./RecordTags";
import { isCommonLabLocked } from "../utils/labUtils";
import { formatDateTime } from "../utils/dateUtils";
import RecordProjects from "./RecordProjects";
import RecordAliases from "./RecordAliases";
import "./style.css";
import { shouldShowProjects } from "../utils/projectUtils";
import renderExpirationDate from "../utils/renderExpirationDate";
import { isAdmin } from "../utils/generalUtils";
import { inDevelopment } from "../utils/inDevelopment";

const RecordInfoDisplay = ({
  afterTag,
  record = {},
  readOnly: _readOnly,
  recordInfo = [],
  columns,
  noFill
}) => {
  const readOnly = useMemo(() => {
    return _readOnly || isCommonLabLocked(record);
  }, [_readOnly, record]);

  /**
   * Get the name of model for the record we are displaying.
   */
  const model = useMemo(() => {
    return record?.__typename;
  }, [record?.__typename]);

  const sections = useMemo(() => {
    const recordInfoToUse = recordInfo.filter(info => !!info);
    let withProjects = false;
    if (record) {
      const {
        user,
        updatedByUser,
        importCollection,
        workflowCollection,
        createdAt,
        updatedAt
      } = record;

      withProjects = shouldShowProjects(model);

      importCollection &&
        recordInfoToUse.push([
          "Import Collection",
          <Link key="importCollection" to={modelNameToLink(importCollection)}>
            {importCollection.name}
          </Link>
        ]);
      if (workflowCollection && workflowCollection.workflowRun) {
        recordInfoToUse.push([
          "Workflow Run",
          <Link
            key="workflowRun"
            to={modelNameToLink(workflowCollection.workflowRun)}
          >
            {workflowCollection.workflowRun.name}
          </Link>
        ]);
        recordInfoToUse.push([
          "Workflow Collection",
          <Link
            key="workflowCollection"
            to={modelNameToLink(workflowCollection)}
          >
            {workflowCollection.workflowRun.name}
          </Link>
        ]);
      }
      if (labModels.includes(model) && (isAdmin() || inDevelopment)) {
        recordInfoToUse.push(["Lab", record.lab?.name || "Common Lab"]);
      }
      if (expirationDateModels.includes(model) && record.expirationDate) {
        recordInfoToUse.push([
          "Expiration",
          renderExpirationDate(record.expirationDate)
        ]);
      }

      externalReferenceKeys.forEach(key => {
        if (record[key]) {
          let title = startCase(key.replace("Reference", ""));
          let value = record[key];
          if (key === "externalReferenceId") {
            title = "External ID";
          }
          if (key === "externalReferenceUrl") {
            value = (
              <a
                key="extUrl"
                href={value}
                target="_blank"
                rel="noopener noreferrer"
              >
                {value}
              </a>
            );
          }
          recordInfoToUse.push([title, value]);
        }
      });
      withProjects &&
        recordInfoToUse.push([
          "Projects",
          <RecordProjects
            key="projects"
            readOnly={readOnly}
            recordId={record.id}
            model={model}
          />
        ]);
      user && recordInfoToUse.push(["Added By", user.username]);
      updatedByUser &&
        recordInfoToUse.push(["Updated By", updatedByUser.username]);
      createdAt && recordInfoToUse.push(["Created", formatDateTime(createdAt)]);
      updatedAt &&
        recordInfoToUse.push(["Modified", formatDateTime(updatedAt)]);

      if (aliasModels.includes(model)) {
        recordInfoToUse.push([
          "Aliases",
          <RecordAliases key="aliases" record={record} />
        ]);
      }
    }
    let sections = [recordInfoToUse];

    if (columns) {
      sections = chunk(
        recordInfoToUse,
        Math.ceil(recordInfoToUse.length / columns)
      );
    }
    return sections;
  }, [columns, model, readOnly, record, recordInfo]);

  return (
    <div className={classNames("record-info-container", { "no-fill": noFill })}>
      <RecordInfoTable sections={sections} />
      {tagModels.includes(model) && (
        <RecordTags
          recordId={record.id}
          model={model}
          readOnly={readOnly}
          afterTag={afterTag}
        />
      )}
      {extendedPropertyModels.includes(model) && (
        <RecordExtendedProperties recordId={record.id} model={model} />
      )}
    </div>
  );
};

RecordInfoDisplay.propTypes = {
  /**
   * Information to display about the record. Each item in the array is 2-tuple
   * with the first element being the label and the second element being the value.
   */
  recordInfo: PropTypes.array,

  /**
   * The record we displaying info of. Only required if either `withGeneralFields`
   * or `withExtendedProperties` is true.
   */
  record: PropTypes.object,

  /**
   * If supplied, the record info will be split evenly into the given number of columns.
   */
  columns: PropTypes.number
};

export default RecordInfoDisplay;
