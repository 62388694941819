/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { PlateWellContentTypes } from "./plateWellContentTypes";
import { ItemTypes } from "./itemTypes";
import {
  J5PcrReactionFragment,
  J5MaterialFragment,
  AdditiveMaterialFragment,
  LotFragment
} from "./fragments.gql.generated";
import { PlateMap } from "./types/PlateMap";
import { RecursiveRequired } from "../../../../../src-shared/typescriptHelpers";
import {
  SampleAdditionalFragment,
  MaterialAdditionalFragment,
  ContainerArrayAdditionalFragment
} from "./additionalFragments.gql.generated";

type ValueOf<T> = T[keyof T];

export const pluralizeItemType = {
  material: "materials",
  additiveMaterial: "additiveMaterials",
  lot: "lots",
  sample: "samples",
  j5Report: "j5Reports",
  containerArray: "containerArrays",
  plateMap: "plateMaps",
  "Inventory List": "Inventory Lists",
  reactionMap: "reactionMaps"
} as const;

const pluralizePlateWellContentType = {
  material: "materials",
  sample: "samples"
} as const;

// We need to improve this return type
export function getSelectedItems(values: {
  itemType: ValueOf<typeof ItemTypes>;
  breakIntoQuadrants: boolean;
  j5EntityRadio: "j5PcrReaction" | string;
  j5PcrReactions: RecursiveRequired<J5PcrReactionFragment[]>;
  j5Materials: RecursiveRequired<J5MaterialFragment[]>;
  samples: RecursiveRequired<SampleAdditionalFragment[]>;
  reactionEntityType: string;
  materials: RecursiveRequired<MaterialAdditionalFragment[]>;
  additiveMaterials: RecursiveRequired<AdditiveMaterialFragment[]>;
  plateWellContentType: ValueOf<typeof PlateWellContentTypes>;
  lots: RecursiveRequired<LotFragment[]>;
  containerArrays: RecursiveRequired<ContainerArrayAdditionalFragment[]>;
  plateMaps: RecursiveRequired<PlateMap[]>;
}) {
  if (!values.itemType) return [];
  let selectedItems;
  switch (values.itemType) {
    case "j5Report":
      if (!values.breakIntoQuadrants) {
        if (values.j5EntityRadio === "j5PcrReaction") {
          selectedItems = values.j5PcrReactions;
        } else {
          selectedItems = values.j5Materials;
        }
      }
      break;
    case "Inventory List":
      selectedItems = values.samples;
      break;
    case "reactionMap":
      if (values.reactionEntityType) {
        if (values.reactionEntityType.includes("Material")) {
          selectedItems = values.materials;
        } else {
          selectedItems = values.additiveMaterials;
        }
      }
      break;
    case "containerArray":
      if (values.plateWellContentType) {
        selectedItems =
          values[pluralizePlateWellContentType[values.plateWellContentType]];
      }
      break;
    default:
      selectedItems = values[pluralizeItemType[values.itemType]] || [];
  }
  // Add a default return statement
  return selectedItems || [];
}
