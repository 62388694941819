/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import GenericSelect from "../../../../src-shared/GenericSelect";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import platePreviewColumn from "../../../utils/platePreviewColumn";
import { plateLibraryFilter } from "../../../utils/plateUtils";
import stepFormValues from "../../../../src-shared/stepFormValues";
import { InputField } from "@teselagen/ui";
import defaultValueConstants from "../../../../../tg-iso-shared/src/defaultValueConstants";

export const plateFragment = [
  "containerArray",
  "id name barcode { id barcodeString }"
];

const customQueryFragment = [
  "customQuery",
  "id name description schema query idFieldName"
];

const SelectQuery = props => {
  const {
    toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} }
  } = props;

  return (
    <div>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select Plates"
          helper="Select one or more plates to query."
        />
        <div>
          <GenericSelect
            {...{
              name: "containerArrays",
              buttonProps: {
                loading: isLoadingMap.containerArrays,
                disabled: isDisabledMap.containerArrays
              },
              isMultiSelect: true,
              isRequired: true,
              tableParamOptions: {
                additionalFilter: plateLibraryFilter
              },
              fragment: plateFragment,
              postSelectDTProps: {
                formName: "runQueryToolSelectPlates",
                schema: [
                  platePreviewColumn(),
                  "name",
                  {
                    displayName: "Barcode",
                    path: "barcode.barcodeString"
                  },
                  dateModifiedColumn
                ]
              }
            }}
          />
        </div>
      </div>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select Custom Query"
          helper="Select a query to run on the selected items."
        />
        <div>
          <GenericSelect
            {...{
              name: "customQuery",
              buttonProps: {
                loading: isLoadingMap.customQuery,
                disabled: isDisabledMap.customQuery
              },
              isRequired: true,
              fragment: customQueryFragment,
              postSelectDTProps: {
                formName: "runQueryToolSelectQuery",
                schema: ["name", "description"]
              }
            }}
          />
        </div>
      </div>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Name Data Table"
          helper="Provide a name for the data table of query results."
        />
        <div className="tg-flex column" style={{ maxWidth: 315 }}>
          <InputField
            name="dataTableName"
            isRequired
            generateDefaultValue={{
              ...defaultValueConstants.QUERY_RESULTS_TABLE_NAME
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default stepFormValues(
  "containerArrays",
  "customQuery",
  "dataTableName"
)(SelectQuery);
