/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import PropTypes from "prop-types";

import download from "downloadjs";
import { Classes } from "@blueprintjs/core";
import { DialogFooter, InputField, wrapDialog } from "@teselagen/ui";

const form = "PromptedLabelValuesDialogForm";

class PromptedLabelValuesDialog extends React.Component {
  static propTypes = {
    /**
     * Are the `itemIds` container arrays or aliquot containers.
     */
    isContainerArray: PropTypes.bool.isRequired,

    /**
     * The ids of the items we want to generate labels for.
     */
    itemIds: PropTypes.arrayOf(PropTypes.string).isRequired,

    /**
     * The id of the label format we are using to generate the label.
     */
    labelFormatId: PropTypes.string.isRequired,

    /**
     * An array giving information about the prompted values we want
     * the user to fill out. Taking this to be the `labelTemplatePrompts` of
     * the label format should work.
     */
    prompts: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * The display name of the prompted value.
         */
        name: PropTypes.string.isRequired,
        /**
         * The value that can be used as a variable in the nunjucks template.
         * Should start with `promptedValues.`.
         */
        path: PropTypes.string.isRequired
      })
    ).isRequired,

    refetch: PropTypes.func
  };

  onSubmit = async ({ promptedValues = {} }) => {
    const { isContainerArray, itemIds, labelFormatId, hideModal, refetch } =
      this.props;
    try {
      const { data } = await window.serverApi.request({
        method: "POST",
        url: "/createZpl",
        data: {
          isContainerArray,
          itemIds,
          labelFormatId,
          promptedValues
        }
      });

      download(data, "label.zpl", "text/plain");
      if (refetch) await refetch();
      hideModal();
    } catch (e) {
      console.error(e);
      window.toastr.error("Error generating label.");
    }
  };

  render() {
    const { handleSubmit, submitting, hideModal, prompts } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className={Classes.DIALOG_BODY}>
          {prompts.map(p => (
            <InputField key={p.path} label={p.name} name={p.path} />
          ))}
        </div>
        <DialogFooter submitting={submitting} hideModal={hideModal} />
      </form>
    );
  }
}

export default compose(
  wrapDialog({ title: "Please enter the specified values" }),
  reduxForm({ form, enableReinitialize: true })
)(PromptedLabelValuesDialog);
