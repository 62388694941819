/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component, useCallback } from "react";
import { compose } from "redux";
import { Link } from "react-router-dom";
import AbstractLibrary from "../../src-shared/AbstractLibrary";
import libraryEnhancer from "../../src-shared/libraryEnhancer";
import gql from "graphql-tag";
import ChangeFeatureTypeMenuItem from "../../src-shared/ChangeFeatureTypeMenuItem";
import { getEnclosingFeatures } from "../../src-shared/utils/sequenceUtils";
import modelNameToLink from "../../src-shared/utils/modelNameToLink";
import PartRecordView, {
  associatedDesigns
} from "../../src-shared/PartRecordView";
import { annotationSizeStartEndColumns } from "../../src-shared/utils/libraryColumns";
import partLibraryFilter from "../utils/partLibraryFilter";
import { digestPartColumn } from "../../src-shared/utils/digestPartUtils";
import { digestPartFragment } from "../../../tg-iso-design/graphql/fragments/partFragment";
import { withProps } from "recompose";

const schema = {
  model: "part",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    { path: "type", type: "string", displayName: "Type" },
    ...annotationSizeStartEndColumns,
    {
      path: "sequence.name",
      type: "string",
      displayName: "Source Sequence",
      render: (name, val) => {
        return <Link to={`/sequences/${val.sequence.id}`}>{name}</Link>;
      }
    },
    {
      displayName: "Enclosing Features",
      render: (_, record) => {
        if (!record.sequence) return;
        const enclosingFeatures = getEnclosingFeatures(
          record,
          record.sequence,
          { isPart: true }
        );
        return enclosingFeatures.map((f, i) => {
          return (
            <React.Fragment key={f.id}>
              <Link to={modelNameToLink(f)}>{f.name}</Link>
              {i !== enclosingFeatures.length - 1 && ", "}
            </React.Fragment>
          );
        });
      }
    },
    associatedDesigns(),
    digestPartColumn
  ]
};

class PartLibrary extends Component {
  additionalContextMenu = selectedRecords => {
    const extraItems = [];

    extraItems.push(
      <ChangeFeatureTypeMenuItem key="editType" records={selectedRecords} />
    );
    return extraItems;
  };
  render() {
    const { isRNA } = this.props;
    return (
      <AbstractLibrary
        {...this.props}
        noNewItem
        libraryName={isRNA ? "rnaPart" : "dnaPart"}
        libraryTitle={isRNA ? "RNA Parts" : "DNA Parts"}
        generateExtraContextMenuItems={this.additionalContextMenu}
        RecordViewInspector={PartRecordView}
      />
    );
  }
}

const fragment = gql`
  fragment partLibraryFragment on part {
    id
    name
    type
    start
    end
    size
    overlapsSelf
    ...digestPartFragment
    sequence {
      id
      name
      size
      sequenceFeatures {
        id
        name
        start
        end
      }
    }
    elements {
      id
      design {
        id
        lockTypeCode
        name
      }
    }
    sequenceId
  }
  ${digestPartFragment}
`;

export default compose(
  withProps(props => {
    const additionalFilter = useCallback(
      (_, qb) => {
        partLibraryFilter({ isRNA: props.isRNA }, qb);
      },
      [props.isRNA]
    );
    return { additionalFilter };
  }),
  libraryEnhancer({
    fragment,
    schema,
    withSelectedEntities: true
  })
)(PartLibrary);
