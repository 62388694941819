/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useMemo, useState } from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { BlueprintError, Loading } from "@teselagen/ui";
import { difference, get, noop } from "lodash";
import {
  CheckboxField,
  InputField,
  NumericInputField,
  ReactSelectField,
  SelectField,
  TextareaField,
  DialogFooter,
  wrapDialog,
  throwFormError
} from "@teselagen/ui";
import { Button, Classes } from "@blueprintjs/core";
import AsyncValidateFieldSpinner from "../../../../src-shared/AsyncValidateFieldSpinner";
import GenericSelect from "../../../../src-shared/GenericSelect";
import "./style.css";
import { filterSequenceUploads } from "../../../../../tg-iso-shared/src/sequence-import-utils/utils";
import { arrayToIdOrCodeValuedOptions } from "../../../../src-shared/utils/formUtils";
import {
  safeUpsert,
  safeQuery,
  safeDelete,
  useTgQuery
} from "../../../../src-shared/apolloMethods";
import caseInsensitiveFilter from "../../../../../tg-iso-shared/src/utils/caseInsensitiveFilter";
import modelNameToReadableName from "../../../../src-shared/utils/modelNameToReadableName";
import { checkDuplicateSequencesExtended } from "../../../../../tg-iso-shared/src/sequence-import-utils/checkDuplicateSequences";
import SequenceFileUpload from "../../../../src-shared/SequenceFileUpload";
import { getMicrobialPlasmidsToCreate } from "../../../utils";
import { useFormValue } from "../../../../src-shared/hooks/useFormValue";

const genomeFragment = ["genome", "id name"];

const selectedSequencesSchema = [
  { path: "name" },
  { path: "sequenceTypeCode" }
];
const selectedSequencesFragment = [
  "sequence",
  "id name sequenceTypeCode hash size circular sequenceFragments { id index fragment}"
];
const selectedSequencesTableParamOptions = {
  additionalFilter: {
    sequenceTypeCode: "CIRCULAR_DNA"
  }
};

const speciesSchema = [{ path: "abbreviatedName" }];
const speciesFragment = ["specie", "id name abbreviatedName"];

const form = "createNewStrain";

const selectionMethodFragment = ["selectionMethod", "id name"];
const inductionMethodFragment = ["inductionMethod", "id name"];
// const growthConditionFragment = ["growthCondition", "id name"];
const targetOrganismClassFragment = ["targetOrganismClass", "id name"];
const biosafetyLevelFragment = ["biosafetyLevel", "code name"];
const lengthUnitFragment = ["lengthUnit", "code"];

export const useStrainUnits = () => {
  const { entities: biosafetyLevels, loading: biosafetyLevelsLoading } =
    useTgQuery(biosafetyLevelFragment, { isPlural: true });
  // const { entities, loading } = useTgQuery(growthConditionFragment, { isPlural: true });
  const { entities: inductionMethods, loading: inductionMethodsLoading } =
    useTgQuery(inductionMethodFragment, { isPlural: true });
  const { entities: lengthUnits, loading: lengthUnitsLoading } = useTgQuery(
    lengthUnitFragment,
    { isPlural: true }
  );
  const { entities: selectionMethods, loading: selectionMethodsLoading } =
    useTgQuery(selectionMethodFragment, { isPlural: true });
  const {
    entities: targetOrganismClasses,
    loading: targetOrganismClassesLoading
  } = useTgQuery(targetOrganismClassFragment, { isPlural: true });
  return {
    loading:
      biosafetyLevelsLoading ||
      inductionMethodsLoading ||
      lengthUnitsLoading ||
      selectionMethodsLoading ||
      targetOrganismClassesLoading,
    biosafetyLevels,
    inductionMethods,
    lengthUnits,
    selectionMethods,
    targetOrganismClasses
  };
};

const CreateNewStrainDialog = ({
  asyncValidating,
  error,
  handleSubmit,
  hideModal,
  history,
  initialValues = {},
  isUpdate,
  strainTypeCode,
  strainSelectionMethods,
  strainInductionMethods,
  submitting,
  refetch = noop
}) => {
  const [page, setPage] = useState(1);
  const withGrowthCondition = useFormValue(form, "withGrowthCondition");

  const {
    biosafetyLevels,
    inductionMethods,
    lengthUnits,
    loading,
    selectionMethods,
    targetOrganismClasses
  } = useStrainUnits();

  const nextPage = useCallback(async ({ plasmidUpload }) => {
    try {
      if (plasmidUpload)
        await filterSequenceUploads({
          allSequenceFiles: [...plasmidUpload]
        });
      setPage(2);
    } catch (error) {
      console.error(error);
      throwFormError(error.message || "Error creating Strain");
    }
  }, []);

  const onSubmit = useCallback(
    async ({
      biosafetyLevelCode,
      description,
      genome,
      genotype,
      growthCondition: _growthCondition,
      id: updateId,
      inductionMethodIds = [],
      name,
      plasmidUpload,
      selectedSequences = [],
      selectionMethodIds = [],
      specie,
      targetOrganismClassId,
      withGrowthCondition
    }) => {
      const maybeGrowthCondition = _growthCondition || {};
      const growthMediaId = get(maybeGrowthCondition, "growthMedia.id");
      const gasCompositionId = get(maybeGrowthCondition, "gasComposition.id");
      let growthCondition = {
        updatedAt: new Date()
      };
      let growthConditionId;
      try {
        let sequences;
        if (plasmidUpload) {
          sequences = await filterSequenceUploads({
            allSequenceFiles: [...plasmidUpload]
          });
        } else {
          sequences = [];
        }
        const { allInputSequencesWithAttachedDuplicates } =
          await checkDuplicateSequencesExtended(sequences);

        if (withGrowthCondition) {
          growthCondition = {
            ...maybeGrowthCondition,
            growthMediaId,
            gasCompositionId
          };
          delete growthCondition.growthMedia;
          delete growthCondition.gasComposition;
          delete growthCondition.__typename;
          const [{ id }] = await safeUpsert("growthCondition", growthCondition);
          growthConditionId = id;
        } else if (updateId && maybeGrowthCondition.id) {
          // if updating and trying to remove old growth condition
          safeDelete("growthCondition", maybeGrowthCondition.id);
        }

        const { microbialPlasmids, extraCreates } =
          await getMicrobialPlasmidsToCreate({
            selectedSequences,
            allInputSequencesWithAttachedDuplicates
          });

        const newStrain = {
          id: updateId,
          name,
          strainTypeCode,
          genotype,
          description,
          targetOrganismClassId,
          biosafetyLevelCode,
          growthConditionId,
          genomeId: genome && genome.id,
          specieId: specie && specie.id,
          strainPlasmids: microbialPlasmids
        };
        if (!targetOrganismClassId) newStrain.targetOrganismClassId = null;
        await extraCreates();
        const [{ id }] = await safeUpsert("strain", newStrain);
        let newSelectionMethodIds = selectionMethodIds;
        let newInductionMethodIds = inductionMethodIds;
        // SELECTION METHODS
        if (initialValues.selectionMethodIds) {
          newSelectionMethodIds = difference(
            selectionMethodIds,
            initialValues.selectionMethodIds
          );
          const removedSelectionMethodIds = difference(
            initialValues.selectionMethodIds,
            selectionMethodIds
          );
          const strainSelectionMethodsToDelete = (
            strainSelectionMethods || []
          ).reduce((acc, ssm) => {
            if (removedSelectionMethodIds.includes(ssm.selectionMethod.id)) {
              acc.push(ssm.id);
            }
            return acc;
          }, []);
          await safeDelete(
            "strainSelectionMethod",
            strainSelectionMethodsToDelete
          );
        }
        await safeUpsert(
          "strainSelectionMethod",
          newSelectionMethodIds.map(selectionMethodId => ({
            strainId: id,
            selectionMethodId
          }))
        );

        // INDUCTION METHODS
        if (initialValues.inductionMethodIds) {
          newInductionMethodIds = difference(
            inductionMethodIds,
            initialValues.inductionMethodIds
          );
          const removedInductionMethodIds = difference(
            initialValues.inductionMethodIds,
            inductionMethodIds
          );
          const strainInductionMethodsToDelete = (
            strainInductionMethods || []
          ).reduce((acc, sim) => {
            if (removedInductionMethodIds.includes(sim.inductionMethod.id)) {
              acc.push(sim.id);
            }
            return acc;
          }, []);
          await safeDelete(
            "inductionMethodStrain",
            strainInductionMethodsToDelete
          );
        }
        await safeUpsert(
          "inductionMethodStrain",
          newInductionMethodIds.map(inductionMethodId => ({
            strainId: id,
            inductionMethodId
          }))
        );
        await refetch();
        hideModal();
        strainTypeCode === "MICROBIAL_STRAIN"
          ? history.push(`/microbial-strains/${id}`)
          : history.push(`/cell-lines/${id}`);
      } catch (error) {
        console.error("error:", error);
        throwFormError(error.message || "Error creating Strain");
      }
    },
    [
      hideModal,
      history,
      initialValues.inductionMethodIds,
      initialValues.selectionMethodIds,
      refetch,
      strainInductionMethods,
      strainSelectionMethods,
      strainTypeCode
    ]
  );

  // We do this so we avoid rerendering the component on every render
  const sequenceFileUpload = useMemo(
    () => (
      <SequenceFileUpload
        label="Upload Additional Plasmids"
        name="plasmidUpload"
      />
    ),
    []
  );

  if (loading) return <Loading inDialog bounce />;

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        {page === 1 ? (
          <>
            <InputField
              label="Name"
              name="name"
              isRequired
              rightElement={
                <AsyncValidateFieldSpinner validating={asyncValidating} />
              }
            />
            <TextareaField label="Genotype" name="genotype" />
            {strainTypeCode === "MICROBIAL_STRAIN" && (
              <GenericSelect
                name="genome"
                asReactSelect
                label="Genome"
                fragment={genomeFragment}
              />
            )}
            <TextareaField
              label="Description"
              name="description"
              placeholder="Enter any additional information."
            />
            {!isUpdate && (
              <GenericSelect
                name="selectedSequences"
                asReactSelect
                isMultiSelect
                label="Select Existing Plasmids"
                schema={selectedSequencesSchema}
                fragment={selectedSequencesFragment}
                tableParamOptions={selectedSequencesTableParamOptions}
              />
            )}
            {sequenceFileUpload}
            {error && <BlueprintError error={error} />}
          </>
        ) : (
          <>
            <CheckboxField
              name="withGrowthCondition"
              label="Specify Growth Conditions?"
              defaultValue={initialValues.growthCondition}
            />
            {withGrowthCondition && (
              <div className="tg-form-border" style={{ marginBottom: 15 }}>
                <h6>Growth Conditions</h6>
                <GrowthConditionFields lengthUnits={lengthUnits} />
              </div>
            )}
            <GenericSelect
              name="specie"
              asReactSelect
              label="Species"
              schema={speciesSchema}
              fragment={speciesFragment}
            />
            <ReactSelectField
              label={modelNameToReadableName("targetOrganismClass", {
                upperCase: true
              })}
              name="targetOrganismClassId"
              options={arrayToIdOrCodeValuedOptions(targetOrganismClasses)}
            />
            <BiosafetySelect biosafetyLevels={biosafetyLevels} isRequired />
            <ReactSelectField
              label="Selection Methods"
              name="selectionMethodIds"
              options={arrayToIdOrCodeValuedOptions(selectionMethods)}
              multi
            />
            <ReactSelectField
              label="Induction Methods"
              name="inductionMethodIds"
              options={arrayToIdOrCodeValuedOptions(inductionMethods)}
              multi
              defaultValue={initialValues.inductionMethodIds}
            />
            {error && <BlueprintError error={error} />}
          </>
        )}
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        text={page > 1 ? "Submit" : "Next"}
        additionalButtons={
          page > 1 && <Button text="Previous" onClick={() => setPage(1)} />
        }
        onClick={handleSubmit(page === 1 ? nextPage : onSubmit)}
      />
    </>
  );
};

export const asyncValidateStrainName = async (
  { name },
  dispatch,
  { initialValues = {}, strainTypeCode }
) => {
  if (!name) return;
  if (initialValues.name && name === initialValues.name)
    return Promise.resolve();
  const [existingStrain] = await safeQuery(["strain", "id"], {
    variables: {
      pageSize: 1,
      filter: caseInsensitiveFilter("strain", "name", [name], {
        returnQb: true
      })
        .whereAll({
          strainTypeCode
        })
        .toJSON()
    }
  });
  if (existingStrain) {
    const error = {
      name: `A ${
        strainTypeCode === "MICROBIAL_STRAIN" ? "strain" : "cell line"
      } with this name already exists.`
    };
    throw error;
  }
};

export default compose(
  wrapDialog({
    getDialogProps: ({ strainTypeCode, isUpdate }) => ({
      title:
        strainTypeCode === "MICROBIAL_STRAIN"
          ? `${isUpdate ? "Update" : "Create New"} Strain`
          : `${isUpdate ? "Update" : "Create New"} Cell Line`
    })
  }),
  reduxForm({
    form,
    asyncBlurFields: ["name"],
    asyncValidate: asyncValidateStrainName
  })
)(CreateNewStrainDialog);

const growthConditionGrowthMediaSchema = [{ path: "name" }];
const growthConditionGrowthMediaFragment = ["additiveMaterial", "id name"];
const growthConditionGrowthMediaTableParamOptions = {
  additionalFilter: (_, qb) => {
    qb.whereAll({
      additiveTypeCode: "GROWTH_MEDIA"
    });
  }
};
const growthConditionGasCompositionSchema = [{ path: "name" }];
const growthConditionGasCompositionFragment = ["gasComposition", "id name"];

export const GrowthConditionFields = ({ lengthUnits }) => (
  <>
    <InputField label="Name" name="growthCondition.name" />
    <TextareaField label="Description" name="growthCondition.description" />
    <NumericInputField
      label="Shaker Speed (rpm)"
      name="growthCondition.shakerSpeed"
      min={0}
    />
    <div className="input-with-unit-select">
      <NumericInputField
        name="growthCondition.shakerThrow"
        label="Shaker Throw"
        min={0}
      />
      <SelectField
        name="growthCondition.lengthUnitCode"
        label="none"
        className="tg-unit-select"
        defaultValue="mm"
        options={arrayToIdOrCodeValuedOptions(lengthUnits, {
          labelKey: "code"
        })}
      />
    </div>
    <NumericInputField
      name="growthCondition.temperature"
      label="Temperature (°C)"
      min={0}
    />
    <NumericInputField
      name="growthCondition.humidity"
      label="Humidity (%)"
      min={0}
    />
    <GenericSelect
      name="growthCondition.growthMedia"
      asReactSelect
      label="Growth Medium"
      schema={growthConditionGrowthMediaSchema}
      fragment={growthConditionGrowthMediaFragment}
      tableParamOptions={growthConditionGrowthMediaTableParamOptions}
    />
    <GenericSelect
      name="growthCondition.gasComposition"
      asReactSelect
      label="Gas Composition"
      schema={growthConditionGasCompositionSchema}
      fragment={growthConditionGasCompositionFragment}
    />
  </>
);

export function BiosafetySelect({ biosafetyLevels, isRequired }) {
  return (
    <ReactSelectField
      label="Biosafety Level"
      name="biosafetyLevelCode"
      isRequired={isRequired}
      options={arrayToIdOrCodeValuedOptions(
        [...biosafetyLevels].sort(
          (a, b) => (Number(a.name) || 0) - (Number(b.name) || 0)
        )
      )}
    />
  );
}
