/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { DragLayer, XYCoord } from "react-dnd";
import { Icon } from "@blueprintjs/core";
import "./style.css";

interface InjectedProps {
  item: { selectedSourceMaterials: any } | null;
  currentOffset: XYCoord | null;
  isDragging: boolean;
  itemType: string | symbol | null;
}

const CustomMaterialDragLayer = ({
  item,
  isDragging,
  currentOffset,
  itemType
}: InjectedProps) => {
  if (!isDragging || !currentOffset || itemType !== "source-material" || !item)
    return null;

  return (
    <div className="source-material-drag-layer">
      <div
        style={{
          position: "absolute",
          top: currentOffset.y,
          left: currentOffset.x,
          fontSize: "16px"
        }}
      >
        <b>
          <Icon icon="symbol-cross" iconSize={20} />
          <sub style={{ marginLeft: -5 }}>
            ☓{item.selectedSourceMaterials.length}
          </sub>
        </b>
      </div>
    </div>
  );
};

export default DragLayer<{}, InjectedProps>(monitor => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging()
}))(CustomMaterialDragLayer);
