/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
// we no longer use the numDigits and incrementStart that is passed on the j5_output_naming_templates
//instead we parse those directly from the template itself
//increment_from_00012 becomes increment_from numDigits=5 incrementStart=12

import { toNumber } from "lodash-es";

// examples:
// `hey-{{{incrementing_number}}}`                            <----- input
// `hey-{{{increment_from_0001}}}`                            <----- intermediate
// `hey-{{{increment_from}}}` numDigits=4 incrementStart=1    <----- output

// `hey-{{{  increment_from_00000012}}`                       <----- input
// `hey-{{{  increment_from}}`numDigits=8 incrementStart=12   <----- output
export default function processTemplate(template) {
  if (!template) return;
  template.template = template.template.replace(
    /{{{\s*incrementing_number\s*}}}/gi,
    () => {
      return `{{{increment_from_0001}}}`;
    }
  );
  template.template = template.template.replace(
    /{{{\s*increment_from_[0-9]+\s*}}}/gi,
    match => {
      const num = match
        .replace(/{{{\s*increment_from_/gi, "")
        .replace(/\s*}}}/gi, "");
      template["numDigits"] = Math.max(num.length, 1);
      template["incrementStart"] = toNumber(num);
      return `{{{increment_from}}}`;
    }
  );
}
