/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback } from "react";
import { compose, withProps } from "recompose";
import { DataTable, withTableParams } from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";
import { worklistRecordTransfersFragment } from "../../../graphql/fragments/worklistRecordFragment";
import { worklistRecordTableSchema } from "./worklistRecordTableSchema";

function WorklistTransfersTable({ tableParams }) {
  return <DataTable {...tableParams} compact />;
}

export default compose(
  withProps(props => {
    // Added while #12943 is not ready
    const additionalFilter = useCallback(
      (_, qb) => {
        qb.whereAll({
          worklistId: props.worklistId
        });
      },
      [props.worklistId]
    );
    return { additionalFilter };
  }),
  withTableParams({
    formName: "worklistRecordTransfersTable",
    urlConnected: false,
    schema: worklistRecordTableSchema
  }),
  withQuery(worklistRecordTransfersFragment, {
    isPlural: true
  })
)(WorklistTransfersTable);
