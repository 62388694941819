/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { startCase } from "lodash";
import Helmet from "react-helmet";
import modelNameToLink from "../../../../../src-shared/utils/modelNameToLink";
import LabelSettingsContextMenu from "./LabelSettingsContextMenu";
import { pushHelper } from "../../../../../src-shared/utils/pushHelper";
import AbstractLibrary from "../../../../../src-shared/AbstractLibrary";

/**
 * Generic component to prevent code duplication in the label settings panel.
 *
 * Renders a card with data table showing all items of that type and a button
 * to create new items. Users can edit and delete items.
 */
const LabelPanel = ({
  Dialog,
  getDialogInitialValues = selectedRecord => selectedRecord,
  model,
  readOnly,
  ...props
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const editRecord = record => {
    if (Dialog) {
      setSelectedRecord(record);
      setIsDialogOpen(true);
    } else {
      props.history.push(modelNameToLink(model, record.id));
    }
  };

  const toggleModal = () => {
    setIsDialogOpen(prev => !prev);
    setSelectedRecord(null);
  };

  const handleContextMenu = ({ selectedRecords }) => (
    <LabelSettingsContextMenu
      model={model}
      refetch={props.refetch}
      selectedRecords={selectedRecords}
      editRecord={editRecord}
    />
  );

  const upperModel = startCase(model);
  return (
    <div className="tg-card">
      <Helmet title={upperModel} />
      {Dialog && isDialogOpen && (
        // eslint-disable-next-line local-eslint-plugin/no-direct-dialog
        <Dialog
          noTarget
          dialogProps={{
            isOpen: isDialogOpen,
            title: `${selectedRecord ? "Edit" : "Create New"} ${startCase(model)}`
          }}
          hideModal={toggleModal}
          refetch={props.refetch}
          initialValues={getDialogInitialValues(selectedRecord)}
        />
      )}
      <AbstractLibrary
        {...props}
        model={model}
        tableName={upperModel}
        hideSelectedCount
        onSingleRowSelect={record => setSelectedRecord(record)}
        onDeselect={() => setSelectedRecord(null)}
        contextMenu={!readOnly && handleContextMenu}
        onDoubleClick={!readOnly && editRecord}
        onNewItemClick={
          Dialog
            ? toggleModal
            : e => pushHelper(e, modelNameToLink(model) + "/new")
        }
      ></AbstractLibrary>
    </div>
  );
};

LabelPanel.propTypes = {
  /**
   * The name of the models that we intend to display.
   */
  model: PropTypes.string.isRequired,

  /**
   * A standard @teselagen/ui `tableParams` object.
   */
  tableParams: PropTypes.object.isRequired,

  /**
   * A React component comprising the dialog for creating/editing new items.
   * The component should be wrapped with `withDialog`. The dialog will be
   * rendered with the props: `hideModal`, `refetch`, `initialValues`.
   */
  Dialog: PropTypes.func,

  /**
   * Refetch the items in the data table.
   */
  refetch: PropTypes.func.isRequired,

  /**
   * Function that will get called on the selected record when generating
   * the initial values for the dialog.
   */
  getDialogInitialValues: PropTypes.func
};

export default LabelPanel;
