/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import { FileUploadField } from "@teselagen/ui";
import { getDownloadTemplateFileHelpers } from "../components/DownloadTemplateFileButton";
import {
  guideRNAFileTypesAndDescriptions,
  aminoAcidFileTypesAndDescriptions,
  rnaFileTypesAndDescriptions,
  oligoFileTypesAndDescriptions,
  dnaFileTypesAndDescriptions,
  dnaMaterialFileTypesAndDescriptions,
  rnaMaterialFileTypesAndDescriptions
} from "../../../tg-iso-shared/src/sequence-import-utils/utils";

function SequenceFileUpload({
  isMaterial,
  isOligo,
  isGuideRNA,
  isProtein,
  isRNA,
  ...rest
}) {
  const accept = useMemo(() => {
    return optionallyAddMaterialFieldsToCsvSchema(
      isMaterial,
      isOligo
        ? oligoFileTypesAndDescriptions
        : isGuideRNA
          ? guideRNAFileTypesAndDescriptions
          : isProtein
            ? aminoAcidFileTypesAndDescriptions
            : isRNA
              ? isMaterial
                ? rnaMaterialFileTypesAndDescriptions
                : rnaFileTypesAndDescriptions
              : isMaterial
                ? dnaMaterialFileTypesAndDescriptions
                : dnaFileTypesAndDescriptions
    ).map(f => {
      if (f.validateAgainstSchema) {
        return getDownloadTemplateFileHelpers(f);
      }
      return f;
    });
  }, [isMaterial, isOligo, isGuideRNA, isProtein, isRNA]);

  return <FileUploadField accept={accept} {...rest} />;
}

export default SequenceFileUpload;

function optionallyAddMaterialFieldsToCsvSchema(
  isMaterial,
  fileTypesAndDescriptions
) {
  if (isMaterial) {
    return fileTypesAndDescriptions.map(f => {
      if (f.validateAgainstSchema) {
        return {
          ...f,
          extendedPropTypes: [...f.extendedPropTypes, "material"],
          validateAgainstSchema: {
            ...f.validateAgainstSchema,
            fields: [
              {
                path: "MaterialName",
                description: "The name of the material",
                example: "Neurogen1"
              },
              {
                path: "ExternallyAvailable",
                type: "boolean",
                defaultValue: false
              },
              ...f.validateAgainstSchema.fields
            ]
          }
        };
      }
      return f;
    });
  }
  return fileTypesAndDescriptions;
}
