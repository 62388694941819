/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useMemo } from "react";
import StepForm from "../../../../src-shared/StepForm";
import { PrepPlates, SelectInputs, ReviewWorklist } from "./Steps";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import { singlePlatePrepPlateFragment } from "./Steps/SelectInputs";
import { standardizeVolume } from "../../../../src-shared/utils/unitUtils";
import { isEmpty, keyBy, pick } from "lodash";
import {
  getVolumeOfAliquotContainer,
  sortToLocationStrings
} from "../../../../../tg-iso-lims/src/utils/plateUtils";
import { safeDelete, safeUpsert } from "../../../../src-shared/apolloMethods";
import {
  isValidPositiveNumber,
  throwFormError
} from "../../../../src-shared/utils/formUtils";
import { executeServerWorklist } from "../utils";
import aliquotContainerTypeFragment from "../../../../../tg-iso-shared/src/fragments/aliquotContainerTypeFragment";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { allConcentrationTypeFields } from "../../../../../tg-iso-lims/src/utils/unitUtils";
import useTgQuery, {
  HandleErrAndLoad
} from "../../../../src-shared/apolloUseTgQuery";

const onSubmit = async ({
  sourceReagent,
  worklist,
  worklistName,
  inputPlates,
  transferVolume,
  transferVolumeUnitCode,
  mockTransfers,
  preppingReagent
}) => {
  let cleanupData;
  try {
    if (sourceReagent) {
      const [createdWorklist] = await safeUpsert("worklist", {
        name: worklistName,
        worklistTransfers: worklist.worklistTransfers.map(t => {
          return {
            volume: t.volume,
            volumetricUnitCode: t.volumetricUnitCode,
            sourceAliquotContainerId: t.sourceAliquotContainer.id,
            destinationAliquotContainerId: t.destinationAliquotContainer.id
          };
        })
      });
      return {
        worklist: createdWorklist
      };
    } else {
      // make a worklist and a reservoir. execute and delete it
      const totalVolumeNeededForTransfers = standardizeVolume(
        transferVolume,
        transferVolumeUnitCode,
        true
      )
        // to be safe
        .times(2 * mockTransfers.length)
        .toString();

      const newTube = {
        aliquotContainerTypeCode: "TUBE"
      };
      if (preppingReagent.__typename === "material") {
        newTube.aliquot = {
          aliquotType: "sample-aliquot",
          volume: totalVolumeNeededForTransfers,
          volumetricUnitCode: "L",
          sample: {
            sampleTypeCode: "REGISTERED_SAMPLE",
            materialId: preppingReagent.id
          }
        };
      } else if (preppingReagent.__typename === "additiveMaterial") {
        newTube.additives = [
          {
            additiveMaterialId: preppingReagent.id,
            volume: totalVolumeNeededForTransfers,
            volumetricUnitCode: "L"
          }
        ];
      } else {
        newTube.additives = [
          {
            lotId: preppingReagent.id,
            volume: totalVolumeNeededForTransfers,
            volumetricUnitCode: "L",
            ...pick(preppingReagent, allConcentrationTypeFields)
          }
        ];
      }
      const [sourceTube] = await safeUpsert("aliquotContainer", newTube);
      const [worklist] = await safeUpsert("worklist", {
        worklistTransfers: mockTransfers.map(t => {
          return {
            destinationAliquotContainerId: t.destinationAliquotContainer.id,
            sourceAliquotContainerId: sourceTube.id,
            volume: transferVolume,
            volumetricUnitCode: transferVolumeUnitCode
          };
        })
      });

      // we will create a tube type and worklist to transfer from because
      // execute worklist handles all the complex logic for us.
      // cleanup this temporary data after
      cleanupData = async () => {
        await safeDelete("worklist", worklist.id);
        await safeDelete("aliquotContainer", sourceTube.id);
      };

      await executeServerWorklist(worklist.id);

      await cleanupData();

      return {
        containerArrays: inputPlates
      };
    }
  } catch (error) {
    console.error(`error:`, error);
    throwFormError(
      `Error ${sourceReagent ? "generating worklist" : "handling transfers"}`
    );
    try {
      cleanupData && (await cleanupData());
    } catch (error) {
      console.error(`error:`, error);
    }
  }
};

const SinglePlatePrep = ({
  initialValues,
  toolSchema,
  aliquotContainerTypes = [],
  toolIntegrationProps,
  isToolIntegrated
}) => {
  const validate = useCallback(
    ({
      transferVolume,
      transferVolumeUnitCode,
      inputPlates = [],
      selectedLocationsForPlates
    }) => {
      const errors = {};

      if (!isValidPositiveNumber(transferVolume)) {
        errors.transferVolume = "Must be valid number.";
      } else if (transferVolume && !isEmpty(selectedLocationsForPlates)) {
        const standardizedTransferVolume = standardizeVolume(
          transferVolume,
          transferVolumeUnitCode,
          true
        );
        const plateToAcErrors = {};
        const keyedAliquotContainerTypes = keyBy(aliquotContainerTypes, "code");
        let transferVolumeError = `Transfer volume would exceed max well volume at the following locations: `;
        Object.keys(selectedLocationsForPlates).forEach(plateId => {
          const currentPlate = inputPlates.find(
            inputPlate => inputPlate.id === plateId
          );
          if (
            selectedLocationsForPlates[plateId] &&
            selectedLocationsForPlates[plateId].length
          ) {
            currentPlate.aliquotContainers.forEach(ac => {
              if (
                selectedLocationsForPlates[plateId].includes(
                  getAliquotContainerLocation(ac, { force2D: true })
                )
              ) {
                const acVolume = getVolumeOfAliquotContainer(ac, true);
                const acType =
                  keyedAliquotContainerTypes[ac.aliquotContainerTypeCode];
                if (
                  acVolume
                    .plus(standardizedTransferVolume)
                    .gt(
                      standardizeVolume(
                        acType.maxVolume,
                        acType.volumetricUnitCode,
                        true
                      )
                    )
                ) {
                  if (!plateToAcErrors[currentPlate.name])
                    plateToAcErrors[currentPlate.name] = [];
                  plateToAcErrors[currentPlate.name].push({
                    ...ac,
                    containerArray: currentPlate
                  });
                }
              }
            });
          }
        });
        Object.keys(plateToAcErrors).forEach(plateName => {
          transferVolumeError += `\n${plateName} at ${sortToLocationStrings(
            plateToAcErrors[plateName]
          )}`;
        });
        if (!isEmpty(plateToAcErrors)) {
          errors.transferVolume = transferVolumeError;
        }
      }
      return errors;
    },
    [aliquotContainerTypes]
  );

  const steps = useMemo(
    () => [
      {
        title: "Select Plates",
        Component: SelectInputs,
        withCustomFooter: true
      },
      {
        title: "Prep Plates",
        Component: PrepPlates,
        withCustomFooter: true,
        props: {
          aliquotContainerTypes
        }
      },
      {
        title: "Review Worklist",
        Component: ReviewWorklist,
        withCustomFooter: true,
        props: {
          aliquotContainerTypes
        }
      }
    ],
    [aliquotContainerTypes]
  );

  return (
    <StepForm
      steps={steps}
      toolIntegrationProps={toolIntegrationProps}
      toolSchema={toolSchema}
      onSubmit={onSubmit}
      validate={validate}
      enableReinitialize={isToolIntegrated}
      initialValues={initialValues}
    />
  );
};

const useTgQueryOptions = {
  isPlural: true,
  showLoading: true,
  inDialog: true,
  variables: {
    pageSize: 20000
  }
};

const SinglePlatePrepWrapper = props => {
  const { entities, loading, error } = useTgQuery(
    aliquotContainerTypeFragment,
    useTgQueryOptions
  );
  if (loading || error)
    return <HandleErrAndLoad loading={loading} error={error} />;
  return <SinglePlatePrep {...props} aliquotContainerTypes={entities} />;
};

export default withWorkflowInputs(singlePlatePrepPlateFragment)(
  SinglePlatePrepWrapper
);
