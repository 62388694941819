/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import StepForm from "../../../../src-shared/StepForm";
import { isPlainObject } from "lodash";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import { compose } from "recompose";
import SelectWorklists, {
  minimalConcatenateWorklistFragment
} from "./Steps/SelectWorklists";
import { concatenateWorklistFragment } from "./fragments";
import { safeUpsert } from "../../../../src-shared/apolloMethods";
import safeQueryIgnoreBatch from "../../../utils/safeQueryIgnoreBatch";
import { convertVolume } from "../../../../src-shared/utils/unitUtils";
import { uniq } from "lodash";

const steps = [
  {
    title: "Select Worklist(s)",
    Component: SelectWorklists
  }
];

const onSubmit = async values => {
  try {
    const {
      worklists = [],
      worklistName,
      consolidate,
      consolidateMethod
    } = values;
    const concatenatedWorklist = {
      name: worklistName,
      worklistContainerArrays: [],
      worklistTransfers: [],
      worklistReactionMaps: []
    };

    const worklistIdsInOrder = worklists.map(w => w.id);
    const fullWorklists = await safeQueryIgnoreBatch(
      concatenateWorklistFragment,
      {
        variables: {
          filter: {
            id: worklistIdsInOrder
          }
        }
      }
    );

    const cleanRecord = r => {
      const newR = { ...r };
      delete newR.id;
      delete newR.__typename;
      return newR;
    };

    const deepCleanRecord = r => {
      const newR = cleanRecord(r);
      Object.values(newR).forEach(val => {
        if (isPlainObject(val)) {
          deepCleanRecord(val);
        }
        if (Array.isArray(val)) {
          val.forEach(deepCleanRecord);
        }
      });
      return newR;
    };

    const sortedFullWorklists = fullWorklists.sort(
      (a, b) =>
        worklistIdsInOrder.indexOf(a.id) - worklistIdsInOrder.indexOf(b.id)
    );

    const reactionMapIds = [];
    sortedFullWorklists.forEach(worklist => {
      concatenatedWorklist.worklistContainerArrays =
        concatenatedWorklist.worklistContainerArrays.concat(
          worklist.worklistContainerArrays.map(deepCleanRecord)
        );

      concatenatedWorklist.worklistTransfers =
        concatenatedWorklist.worklistTransfers.concat(
          worklist.worklistTransfers.map(deepCleanRecord)
        );

      worklist.worklistReactionMaps.forEach(wrm => {
        reactionMapIds.push(wrm.reactionMapId);
      });
    });

    concatenatedWorklist.worklistReactionMaps = uniq(reactionMapIds).map(
      reactionMapId => ({ reactionMapId })
    );

    if (consolidate) {
      const sourceDestMap = {};
      concatenatedWorklist.worklistTransfers =
        concatenatedWorklist.worklistTransfers.filter(transfer => {
          const src = transfer.sourceAliquotContainerId;
          const dst = transfer.destinationAliquotContainerId;
          const key = `${src}:${dst}`;
          if (sourceDestMap[key]) {
            if (consolidateMethod === "combineVolume") {
              const transferToUpdate = sourceDestMap[key];
              transferToUpdate.volume =
                transferToUpdate.volume +
                convertVolume(
                  transfer.volume,
                  transfer.volumetricUnitCode,
                  transferToUpdate.volumetricUnitCode
                );
            }
            return false;
          } else {
            sourceDestMap[key] = transfer;
            return true;
          }
        });
    }

    const [newWorklist] = await safeUpsert("worklist", concatenatedWorklist);

    return {
      worklist: newWorklist
    };
  } catch (e) {
    console.error(e);
    window.toastr.error("Error concatenating worklists");
  }
};

const ConcatenateWorklists = ({
  toolSchema,
  initialValues,
  isToolIntegrated,
  toolIntegrationProps
}) => (
  <StepForm
    steps={steps}
    toolSchema={toolSchema}
    initialValues={initialValues}
    enableReinitialize={isToolIntegrated}
    toolIntegrationProps={toolIntegrationProps}
    onSubmit={onSubmit}
  />
);

export default compose(withWorkflowInputs(minimalConcatenateWorklistFragment))(
  ConcatenateWorklists
);
