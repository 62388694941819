/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback } from "react";
import { SelectField } from "@teselagen/ui";

const options = ["Show All", "In Inventory", "Not in Inventory"];

function InInventorySelectFilter({ name, setNewParams, selectTableRecords }) {
  const inventoryFilterChange = useCallback(
    inventoryFilter => {
      // This works only if tableParams are not urlConnected
      setNewParams(prev => ({ ...prev, inventoryFilter }));
      selectTableRecords([]);
    },
    [selectTableRecords, setNewParams]
  );

  return (
    <SelectField
      className="tg-no-form-group-margin"
      name={name}
      defaultValue="Show All"
      options={options}
      onFieldSubmit={inventoryFilterChange}
    />
  );
}

export default InInventorySelectFilter;
