/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useMemo, useState } from "react";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import GenericSelect from "../../../../src-shared/GenericSelect";
import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import {
  worklistPlanningDataTableAliquotContainerFragment,
  worklistPlanningDataTableFragment,
  worklistPlanningPlateFragment,
  worklistPlanningTubeFragment
} from "./fragments";
import platePreviewColumn from "../../../utils/platePreviewColumn";
import { forEach, get, keyBy, isEmpty } from "lodash";
import { standardizeVolume } from "../../../../src-shared/utils/unitUtils";
import { Callout } from "@blueprintjs/core";
import { validateTransfers } from "../../../utils";
import { isValidPositiveNumber } from "../../../../src-shared/utils/formUtils";
import {
  safeQuery,
  useTgQuery,
  HandleErrAndLoad
} from "../../../../src-shared/apolloMethods";
import { getAliquotContainerLocation } from "../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import {
  getKeyAdditivesByReagentIdFromAliquotContainer,
  getMaterialIdsFromAliquotContainer,
  getReagentIdsFromAliquotContainer,
  getUsableVolumeOfAliquotContainer
} from "../../../../../tg-iso-lims/src/utils/plateUtils";
import aliquotContainerTypeFragment from "../../../../../tg-iso-shared/src/fragments/aliquotContainerTypeFragment";
import {
  defaultMassUnitCode,
  defaultVolumetricUnitCode
} from "../../../../../tg-iso-lims/src/utils/unitUtils";
import { getAliquotTransferVolumeFromMass } from "../../../utils/plateUtils";
import { TransferInfoSection } from "./TransferInfoSection";
import { change as _change } from "redux-form";
import { useDispatch } from "react-redux";
import { useFormValue } from "../../../../src-shared/hooks/useFormValue";

const sourcePlateSchema = [
  "name",
  { displayName: "Barcode", path: "barcode.barcodeString" },
  dateModifiedColumn
];

const sourcePlateFragment = [
  "containerArray",
  "id name barcode { id barcodeString } updatedAt"
];

const sourcePlatePostSelectDTProps = {
  formName: "worklistPlanningSelectPlatesTable",
  schema: [
    platePreviewColumn(),
    "name",
    { displayName: "Barcode", path: "barcode.barcodeString" },
    dateModifiedColumn
  ]
};

const sourceTubeSchema = [
  "name",
  { displayName: "Barcode", path: "barcode.barcodeString" },
  dateModifiedColumn
];

const sourceTubeFragment = [
  "aliquotContainer",
  "id name barcode { id barcodeString } updatedAt"
];

const sourceTubeTableParamsOptions = {
  additionalFilter: {
    "aliquotContainerType.isTube": true
  }
};

const sourceTubePostSelectDTProps = {
  formName: "worklistPlanningSelectTubesTable",
  schema: [
    "name",
    { displayName: "Barcode", path: "barcode.barcodeString" },
    dateModifiedColumn
  ]
};

const sourceDataTablesSchema = ["name", dateModifiedColumn];
const sourceDataTablesFragment = ["dataTable", "id name updatedAt"];
const sourceDataTablesTableParamOptions = {
  additionalFilter: {
    dataTableTypeCode: [
      "PCR_INVENTORY_MATERIALS",
      "VALID_SAMPLE_QC_INVENTORY_LIST",
      "INVALID_SAMPLE_QC_INVENTORY_LIST"
    ]
  }
};

const sourceDataTablesPostSelectDTProps = {
  formName: "worklistPlanningSelectDataTablesTable",
  schema: ["name", dateModifiedColumn]
};

const SourceMaterials = ({
  aliquotContainerTypes = [],
  defaultSourceDataTables,
  defaultSourcePlates,
  defaultSourceTubes,
  Footer,
  footerProps,
  handleSubmit,
  nextStep,
  toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
  toolSchema: { code }
}) => {
  const [loadingTableAliquots, setLoadingTableAliquots] = useState(false);

  const destinationPlates = useFormValue(code, "destinationPlates");
  const plateToReactionMap = useFormValue(code, "plateToReactionMap");
  const reactionMaps = useFormValue(code, "reactionMaps");
  const sourcePlates = useFormValue(code, "sourcePlates");
  const sourceTubes = useFormValue(code, "sourceTubes");
  const tableAliquotContainers = useFormValue(code, "tableAliquotContainers");
  const reagentTransferSettings = useFormValue(code, "reagentTransferSettings");
  const sourceDataTables = useFormValue(code, "sourceDataTables");
  const materialTransferSettings = useFormValue(
    code,
    "materialTransferSettings"
  );

  const dispatch = useDispatch();
  const change = useCallback(
    (field, value) => dispatch(_change(code, field, value)),
    [dispatch, code]
  );

  const { inputMaterials, inputReagents } = useMemo(() => {
    const inputMaterials = {};
    const inputReagents = {};
    reactionMaps?.forEach(rm => {
      rm.reactions.forEach(r => {
        r.reactionInputs.forEach(input => {
          const materialId = get(input, "inputMaterial.id");
          const reagentId = get(input, "inputAdditiveMaterial.id");
          if (materialId && !inputMaterials.hasOwnProperty(materialId)) {
            inputMaterials[materialId] = input.inputMaterial;
          }
          if (reagentId && !inputReagents.hasOwnProperty(reagentId)) {
            inputReagents[reagentId] = input.inputAdditiveMaterial;
          }
        });
      });
    });
    return {
      inputMaterials,
      inputReagents
    };
  }, [reactionMaps]);

  const {
    materialIdToDestACs,
    reagentIdToDestACs,
    neededInputMaterialIds,
    neededInputMaterials,
    neededInputReagentIds,
    neededInputReagents,
    matIdsForDestAcTracker,
    reagentIdsForDestAcTracker,
    reactionToNeededInputs
  } = useMemo(() => {
    // keep track of how many transfers we need for each source material
    // this will store an array of destination aliquot containers for each
    // source material id
    const materialIdToDestACs = {};
    const reagentIdToDestACs = {};
    const neededInputMaterialIds = [];
    const neededInputMaterials = [];
    const neededInputReagentIds = [];
    const neededInputReagents = [];
    const matIdsForDestAcTracker = {};
    const reagentIdsForDestAcTracker = {};
    const reactionToNeededInputs = {};

    let keyedReactions = {};
    reactionMaps?.forEach(
      rm =>
        (keyedReactions = {
          ...keyedReactions,
          ...keyBy(get(rm, "reactions"), "id")
        })
    );

    const platesAcMap = {};
    const plateIdToName = {};
    destinationPlates?.forEach(p => {
      plateIdToName[p.id] = p.name;
      platesAcMap[p.id] = {};
      p.aliquotContainers.forEach(ac => {
        platesAcMap[p.id][getAliquotContainerLocation(ac)] = ac;
      });
    });

    forEach(plateToReactionMap || {}, (locationMap, plateId) => {
      forEach(locationMap, (reactionIds, location) => {
        const materialIdsForLocation = [];
        const reagentIdsForLocation = [];
        reactionIds.forEach(reactionId => {
          const reactionInfoHelper = {
            materialIds: [],
            reagentIds: [],
            destinationAcIds: []
          };
          reactionToNeededInputs[reactionId] = reactionInfoHelper;
          keyedReactions[reactionId].reactionInputs.forEach(input => {
            const material = get(input, "inputMaterial");
            const reagent = get(input, "inputAdditiveMaterial");
            const ac = platesAcMap[plateId][location];

            if (!reactionInfoHelper.destinationAcIds.includes(ac.id)) {
              reactionInfoHelper.destinationAcIds.push(ac.id);
            }
            if (material) {
              const materialId = material.id;
              reactionToNeededInputs[reactionId].materialIds.push(materialId);
              if (!materialIdToDestACs[materialId]) {
                materialIdToDestACs[materialId] = [];
              }
              if (!matIdsForDestAcTracker[ac.id]) {
                matIdsForDestAcTracker[ac.id] =
                  getMaterialIdsFromAliquotContainer(ac);
              }
              const materialIdArray = matIdsForDestAcTracker[ac.id];
              // the needed material might already be at the destination
              if (!materialIdArray.includes(materialId)) {
                materialIdsForLocation.push(materialId);
                if (!neededInputMaterialIds.includes(materialId)) {
                  neededInputMaterials.push(material);
                  neededInputMaterialIds.push(materialId);
                }
                materialIdToDestACs[materialId].push({
                  ...platesAcMap[plateId][location],
                  plateName: plateIdToName[plateId]
                });
              }
            }
            if (reagent) {
              const reagentId = reagent.id;
              reactionToNeededInputs[reactionId].reagentIds.push(reagentId);
              if (!reagentIdToDestACs[reagentId]) {
                reagentIdToDestACs[reagentId] = [];
              }
              if (!reagentIdsForDestAcTracker[ac.id]) {
                reagentIdsForDestAcTracker[ac.id] =
                  getReagentIdsFromAliquotContainer(ac);
              }
              const reagentIdArray = reagentIdsForDestAcTracker[ac.id];
              // the needed material might already be at the destination
              if (!reagentIdArray.includes(reagentId)) {
                reagentIdsForLocation.push(reagentId);
                if (!neededInputReagentIds.includes(reagentId)) {
                  neededInputReagents.push(reagent);
                  neededInputReagentIds.push(reagentId);
                }
                reagentIdToDestACs[reagentId].push({
                  ...platesAcMap[plateId][location],
                  plateName: plateIdToName[plateId]
                });
              }
            }
          });
        });
      });
    });
    return {
      materialIdToDestACs,
      reagentIdToDestACs,
      neededInputMaterialIds,
      neededInputMaterials,
      neededInputReagentIds,
      neededInputReagents,
      matIdsForDestAcTracker,
      reagentIdsForDestAcTracker,
      reactionToNeededInputs
    };
  }, [destinationPlates, plateToReactionMap, reactionMaps]);

  const fetchMaterialsForTables = useCallback(
    async tables => {
      try {
        setLoadingTableAliquots(true);
        change("tableAliquotContainers", []);
        const aliquotIds = [];
        tables.forEach(table => {
          table.dataRows.forEach(row => {
            const aliquotId = row.rowValues?.aliquotId;
            if (aliquotId) {
              aliquotIds.push(aliquotId);
            }
          });
        });

        if (!aliquotIds.length) {
          return window.toastr.warning("No aliquots found on tables.");
        }

        const aliquotContainers = await safeQuery(
          worklistPlanningDataTableAliquotContainerFragment,
          {
            variables: {
              filter: {
                aliquotId: aliquotIds
              }
            }
          }
        );
        if (!aliquotContainers.length) {
          return window.toastr.error(
            "Table aliquots were not linked to plates or tubes."
          );
        }
        change("tableAliquotContainers", aliquotContainers);
      } catch (error) {
        console.error(`error:`, error);
        window.toastr.error("Error fetching table aliquots");
      }
      setLoadingTableAliquots(false);
    },
    [change]
  );

  const keyedAliquotContainerTypes = keyBy(aliquotContainerTypes, "code");

  const destinationPlateIds = useMemo(() => {
    const destinationPlateIds = [];
    destinationPlates?.forEach(destP => {
      if (!destP.id.includes("__")) {
        destinationPlateIds.push(destP.id);
      }
    });
    return destinationPlateIds;
  }, [destinationPlates]);

  let allSourceAliquotContainers = (sourceTubes || []).concat(
    tableAliquotContainers || []
  );
  sourcePlates?.forEach(p => {
    allSourceAliquotContainers = allSourceAliquotContainers.concat(
      p.aliquotContainers.map(ac => ({
        ...ac,
        containerArray: {
          id: p.id,
          name: p.name
        }
      }))
    );
  });

  const { materialToSourceACTracker, reagentToSourceACTracker } =
    useMemo(() => {
      const materialToSourceACTracker = {};
      const reagentToSourceACTracker = {};

      allSourceAliquotContainers.forEach(sourceAc => {
        const materialId = get(sourceAc, "aliquot.sample.materialId");
        const reagentIds = getReagentIdsFromAliquotContainer(sourceAc);
        if (materialId && inputMaterials.hasOwnProperty(materialId)) {
          materialToSourceACTracker[materialId] =
            materialToSourceACTracker[materialId] || [];
          materialToSourceACTracker[materialId].push(sourceAc);
        }
        reagentIds.forEach(reagentId => {
          if (inputReagents.hasOwnProperty(reagentId)) {
            reagentToSourceACTracker[reagentId] =
              reagentToSourceACTracker[reagentId] || [];
            reagentToSourceACTracker[reagentId].push(sourceAc);
          }
        });
      });
      // go through all reactions they want to perform (could be multiple of the same reaction)
      // make sure there is enough of each source material for all reactions, including dead volume of source containers

      // sort the source materials by largest volume first
      forEach(materialToSourceACTracker, (sourceAcs, key) => {
        materialToSourceACTracker[key] = sourceAcs.sort(
          (a, b) =>
            getUsableVolumeOfAliquotContainer(b, keyedAliquotContainerTypes) -
            getUsableVolumeOfAliquotContainer(a, keyedAliquotContainerTypes)
        );
      });
      forEach(reagentToSourceACTracker, (sourceAcs, key) => {
        reagentToSourceACTracker[key] = sourceAcs.sort(
          (a, b) =>
            getUsableVolumeOfAliquotContainer(b, keyedAliquotContainerTypes) -
            getUsableVolumeOfAliquotContainer(a, keyedAliquotContainerTypes)
        );
      });
      return { materialToSourceACTracker, reagentToSourceACTracker };
    }, [
      allSourceAliquotContainers,
      inputMaterials,
      inputReagents,
      keyedAliquotContainerTypes
    ]);

  // first make sure there is an aliquot for all needed input materials
  // needed input materials are ones that are not already in the destination wells
  const { missingMaterialMessage, missingMaterialIds } = useMemo(() => {
    const missingMaterialIds = [];
    let missingMaterialMessage = "";
    neededInputMaterialIds.forEach(id => {
      if (!materialToSourceACTracker[id]) {
        if (!missingMaterialMessage)
          missingMaterialMessage +=
            "The following input materials are missing from selected inventory:\n";
        missingMaterialIds.push(id);
        missingMaterialMessage += `${inputMaterials[id].name}\n`;
      }
    });
    return { missingMaterialIds, missingMaterialMessage };
  }, [inputMaterials, materialToSourceACTracker, neededInputMaterialIds]);

  let missingReagentMessage = "";
  const missingReagentIds = [];

  // if all reagent ids are missing then hide the reagent transfer volume field
  neededInputReagentIds.forEach(id => {
    if (!reagentToSourceACTracker[id]) {
      if (!missingReagentMessage)
        missingReagentMessage +=
          "The following input reagents are missing from selected inventory:\n";
      missingReagentIds.push(id);
      missingReagentMessage += `${inputReagents[id].name}\n`;
    }
  });

  const worklistTransfers = [];

  const allMaterialsMissing =
    neededInputMaterialIds.length &&
    missingMaterialIds.length === neededInputMaterialIds.length;
  const allReagentsMissing =
    neededInputReagentIds.length &&
    missingReagentIds.length === neededInputReagentIds.length;

  let errorMessage = "";
  // next make sure that there is enough volume for all input reactions
  if (!allMaterialsMissing || !allReagentsMissing) {
    const acToAvailableVolume = {};

    const tryToAddTransfer = ({ reagentId, materialId, destACs }) => {
      const transferSettings = reagentId
        ? reagentTransferSettings || {}
        : materialTransferSettings || {};
      const {
        transferType,
        transferVolume,
        transferVolumetricUnitCode,
        transferInfo = {},
        applyUniversalTransfer,
        transferMass,
        transferMassUnitCode
      } = transferSettings;
      const standardizedTransferVolume = standardizeVolume(
        isValidPositiveNumber(transferVolume) ? transferVolume : 0,
        transferVolumetricUnitCode || "uL",
        true
      );

      let standardizedTransferVolumeToUse,
        transferVolumeToUse,
        transferVolumetricUnitCodeToUse;
      const itemId = materialId || reagentId;
      const transferInfoForItem = transferInfo[itemId] || {};

      if (transferType === "mass") {
        // this will be handled individually per aliquot below
      } else {
        if (applyUniversalTransfer) {
          standardizedTransferVolumeToUse = standardizedTransferVolume;
          transferVolumeToUse = isValidPositiveNumber(transferVolume)
            ? transferVolume
            : 0;
          transferVolumetricUnitCodeToUse = transferVolumetricUnitCode;
        } else {
          transferVolumeToUse = isValidPositiveNumber(
            transferInfoForItem.volume
          )
            ? transferInfoForItem.volume
            : 0;
          standardizedTransferVolumeToUse = standardizeVolume(
            transferVolumeToUse,
            transferInfoForItem.volumetricUnitCode || defaultVolumetricUnitCode,
            true
          );
          transferVolumetricUnitCodeToUse =
            transferInfoForItem.volumetricUnitCode || defaultVolumetricUnitCode;
        }
      }
      let sourceAcTracker, inputItems;
      if (materialId) {
        sourceAcTracker = materialToSourceACTracker;
        inputItems = inputMaterials;
      } else {
        sourceAcTracker = reagentToSourceACTracker;
        inputItems = inputReagents;
      }
      const acIdToKeyReagents = {};
      // a small cache helper
      const sourceItemIdToTransferVolume = {};
      if (sourceAcTracker[itemId]) {
        const matErr = `Not enough source volume for ${
          materialId ? "material" : "reagent"
        } ${inputItems[itemId].name}.\n`;
        const isAliquotContainerValidForTransfer = ac => {
          if (!materialId) {
            if (!acIdToKeyReagents[ac.id]) {
              acIdToKeyReagents[ac.id] =
                getKeyAdditivesByReagentIdFromAliquotContainer(ac);
            }
          }
          if (acToAvailableVolume[ac.id] === undefined) {
            acToAvailableVolume[ac.id] = getUsableVolumeOfAliquotContainer(
              ac,
              keyedAliquotContainerTypes
            );
          }
          if (transferType === "mass") {
            // if transfer type is mass then we calculate transfer volume
            // from concentration. This means it needs to be calculated
            // for each item individually
            const sourceItem = materialId
              ? ac.aliquot
              : acIdToKeyReagents[ac.id][itemId];
            if (!sourceItem || !sourceItem.concentration || !sourceItem.volume)
              return false;
            transferVolumetricUnitCodeToUse = sourceItem.volumetricUnitCode;
            transferVolumeToUse = sourceItemIdToTransferVolume[sourceItem.id]
              ? sourceItemIdToTransferVolume[sourceItem.id]
              : getAliquotTransferVolumeFromMass(
                  sourceItem,
                  (applyUniversalTransfer
                    ? transferMass
                    : transferInfoForItem.mass) || 0,
                  (applyUniversalTransfer
                    ? transferMassUnitCode
                    : transferInfoForItem.massUnitCode) || defaultMassUnitCode
                );
            sourceItemIdToTransferVolume[sourceItem.id] = transferVolumeToUse;
            standardizedTransferVolumeToUse = standardizeVolume(
              transferVolumeToUse,
              sourceItem.volumetricUnitCode || "uL",
              true
            );
          }
          return acToAvailableVolume[ac.id].gte(
            standardizedTransferVolumeToUse
          );
        };
        for (const destAliquotContainer of destACs) {
          const acToUse = sourceAcTracker[itemId].find(
            isAliquotContainerValidForTransfer
          );
          // no source aliquot containers have enough volume for transfer
          if (!acToUse) {
            errorMessage += matErr;
            break;
          } else {
            acToAvailableVolume[acToUse.id] = acToAvailableVolume[
              acToUse.id
            ].minus(standardizedTransferVolumeToUse);
            worklistTransfers.push({
              sourceAliquotContainerId: acToUse.id,
              sourceAliquotContainer: acToUse,
              destinationAliquotContainer: destAliquotContainer,
              destinationAliquotContainerId:
                destAliquotContainer.id || `&${destAliquotContainer.cid}`,
              destinationPlateName: destAliquotContainer.plateName,
              volume: transferVolumeToUse,
              volumetricUnitCode: transferVolumetricUnitCodeToUse
            });
          }
        }
      }
    };

    forEach(materialIdToDestACs, (destACs, materialId) => {
      tryToAddTransfer({
        materialId,
        destACs
      });
    });
    forEach(reagentIdToDestACs, (destACs, reagentId) => {
      tryToAddTransfer({
        reagentId,
        destACs
      });
    });
  }

  const materialTransferVolumeWasEntered = neededInputMaterialIds.length
    ? materialTransferSettings?.transferType === "mass"
      ? materialTransferSettings?.transferMass
      : materialTransferSettings?.transferVolume
    : true;
  const reagentTransferVolumeWasEntered = neededInputReagentIds.length
    ? reagentTransferSettings?.transferType === "mass"
      ? reagentTransferSettings?.transferMass
      : reagentTransferSettings?.transferVolume
    : true;
  const transferVolumesEntered =
    materialTransferVolumeWasEntered && reagentTransferVolumeWasEntered;
  let worklistError;
  if (worklistTransfers.length && transferVolumesEntered) {
    worklistError = validateTransfers(
      { worklistTransfers },
      aliquotContainerTypes
    );
  } else if (
    transferVolumesEntered &&
    !allMaterialsMissing &&
    !allReagentsMissing &&
    !errorMessage &&
    !isEmpty(materialToSourceACTracker) &&
    !isEmpty(reagentToSourceACTracker)
  ) {
    worklistError = "Error setting up transfers.";
  }

  const allSourcesMissing = allReagentsMissing && allMaterialsMissing;

  const sourcePlateTableParamsOptions = useMemo(
    () => ({
      additionalFilter: (_, qb) => {
        qb.whereAll({
          id: qb.notInList(destinationPlateIds)
        });
      }
    }),
    [destinationPlateIds]
  );

  const sourcePlatesButtonProps = useMemo(
    () => ({
      loading: isLoadingMap.containerArrays,
      disabled: isDisabledMap.containerArrays
    }),
    [isDisabledMap.containerArrays, isLoadingMap.containerArrays]
  );

  const sourceTubeButtonProps = useMemo(
    () => ({
      loading: isLoadingMap.aliquotContainers,
      disabled: isDisabledMap.aliquotContainers
    }),
    [isDisabledMap.aliquotContainers, isLoadingMap.aliquotContainers]
  );

  const sourceDataTablesButtonProps = useMemo(
    () => ({
      loading: isLoadingMap.dataTables,
      disabled: isDisabledMap.dataTables
    }),
    [isDisabledMap.dataTables, isLoadingMap.dataTables]
  );

  return (
    <>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select Source Plates"
          helper="Select plates with input materials or reagents for reactions."
        />
        <GenericSelect
          name="sourcePlates"
          schema={sourcePlateSchema}
          isMultiSelect
          fragment={sourcePlateFragment}
          additionalDataFragment={worklistPlanningPlateFragment}
          tableParamOptions={sourcePlateTableParamsOptions}
          postSelectDTProps={sourcePlatePostSelectDTProps}
          buttonProps={sourcePlatesButtonProps}
          defaultValue={sourcePlates || defaultSourcePlates}
        />
        <HeaderWithHelper
          header="Select Source Tubes"
          helper="Select tubes with input materials or reagents for reactions."
        />
        <GenericSelect
          name="sourceTubes"
          schema={sourceTubeSchema}
          isMultiSelect
          fragment={sourceTubeFragment}
          tableParamOptions={sourceTubeTableParamsOptions}
          additionalDataFragment={worklistPlanningTubeFragment}
          postSelectDTProps={sourceTubePostSelectDTProps}
          buttonProps={sourceTubeButtonProps}
          defaultValue={sourceTubes || defaultSourceTubes}
        />
        <HeaderWithHelper
          header="Select Data Tables"
          helper="Select data tables containing input materials for reactions."
        />
        <GenericSelect
          name="sourceDataTables"
          schema={sourceDataTablesSchema}
          isMultiSelect
          fragment={sourceDataTablesFragment}
          tableParamOptions={sourceDataTablesTableParamOptions}
          additionalDataFragment={worklistPlanningDataTableFragment}
          onSelect={fetchMaterialsForTables}
          postSelectDTProps={sourceDataTablesPostSelectDTProps}
          buttonProps={sourceDataTablesButtonProps}
          defaultValue={sourceDataTables || defaultSourceDataTables}
        />
        {loadingTableAliquots && (
          <div style={{ margin: "10px 0" }}>
            Loading source materials from selected data tables...
          </div>
        )}
        {!!allSourceAliquotContainers.length && missingMaterialMessage && (
          <Callout
            intent={allMaterialsMissing ? "danger" : "warning"}
            className="preserve-newline"
            style={{ marginBottom: 5 }}
          >
            {missingMaterialMessage}
          </Callout>
        )}
        {!!allSourceAliquotContainers.length && missingReagentMessage && (
          <Callout
            intent={allReagentsMissing ? "danger" : "warning"}
            className="preserve-newline"
            style={{ marginBottom: 5 }}
          >
            {missingReagentMessage}
          </Callout>
        )}
        {errorMessage && (
          <Callout
            intent="warning"
            className="preserve-newline"
            style={{ marginBottom: 5 }}
          >
            {errorMessage}
          </Callout>
        )}
      </div>
      <TransferInfoSection
        type="material"
        neededInputMaterials={neededInputMaterials}
        materialTransferSettings={materialTransferSettings}
        neededInputReagents={neededInputReagents}
        reagentTransferSettings={reagentTransferSettings}
        allReagentsMissing={allReagentsMissing}
        formName={code}
      />
      <TransferInfoSection
        type="reagent"
        neededInputMaterials={neededInputMaterials}
        materialTransferSettings={materialTransferSettings}
        neededInputReagents={neededInputReagents}
        reagentTransferSettings={reagentTransferSettings}
        allReagentsMissing={allReagentsMissing}
        formName={code}
      />
      <Footer
        {...footerProps}
        nextDisabled={
          allSourcesMissing ||
          errorMessage ||
          worklistError ||
          loadingTableAliquots
        }
        errorMessage={worklistError}
        onNextClick={handleSubmit(values => {
          const { reactionMaps = [] } = values;
          change("worklist", { worklistTransfers });
          change("missingMaterialIds", missingMaterialIds);
          change("missingReagentIds", missingReagentIds);
          const completedReactionIds = [];
          Object.keys(reactionToNeededInputs || {}).forEach(reactionId => {
            const {
              materialIds,
              reagentIds,
              destinationAcIds = []
            } = reactionToNeededInputs[reactionId];
            const missingSourceMaterialIdsForReaction = materialIds.filter(
              id => !materialToSourceACTracker[id]
            );
            const missingSourceReagentIdsReaction = reagentIds.filter(
              id => !reagentToSourceACTracker[id]
            );
            // if all of the materials and reagents for this reaction are in the chosen sources
            // then the reaction is going to run
            // the reaction will also run if a destination for the reaction already
            // has the missing inputs needed
            if (
              !missingSourceMaterialIdsForReaction.length &&
              !missingSourceReagentIdsReaction.length
            ) {
              completedReactionIds.push(reactionId);
            } else {
              const someDestinationAlreadyHasMissingInputs =
                destinationAcIds.some(acId => {
                  const hasMissingMaterials =
                    missingSourceMaterialIdsForReaction.length
                      ? missingSourceMaterialIdsForReaction.every(id =>
                          matIdsForDestAcTracker[acId].includes(id)
                        )
                      : true;
                  const hasMissingReagents =
                    missingSourceReagentIdsReaction.length
                      ? missingSourceReagentIdsReaction.every(id =>
                          reagentIdsForDestAcTracker[acId].includes(id)
                        )
                      : true;
                  return hasMissingMaterials && hasMissingReagents;
                });
              if (someDestinationAlreadyHasMissingInputs) {
                completedReactionIds.push(reactionId);
              }
            }
          });
          const usedReactionMapIds = [];
          if (completedReactionIds.length) {
            reactionMaps.forEach(rm => {
              const ranAReaction = rm.reactions.some(r =>
                completedReactionIds.includes(r.id)
              );
              if (ranAReaction) {
                usedReactionMapIds.push(rm.id);
              }
            });
          }
          change("usedReactionMapIds", usedReactionMapIds);
          nextStep();
        })}
      />
    </>
  );
};

const SourceMaterialsWrapper = props => {
  const { entities, loading, error } = useTgQuery(
    aliquotContainerTypeFragment,
    {
      isPlural: true,
      variables: {
        pageSize: 20000
      }
    }
  );
  if (loading || error)
    return <HandleErrAndLoad loading={loading} error={error} />;
  return <SourceMaterials {...props} aliquotContainerTypes={entities} />;
};

export default SourceMaterialsWrapper;
