/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export const distByAssemblyReportSequenceFragment = gql`
  fragment distByAssemblyReportSequenceFragment on sequence {
    id
    sequenceJ5ItemViews {
      id
      j5ReportName
      j5ReportId
    }
  }
`;
