/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import Helmet from "react-helmet";
import { MenuItem } from "@blueprintjs/core";
import { withTableParams } from "@teselagen/ui";
import withQuery from "../../../src-shared/withQuery";
import { pick, get } from "lodash";
import AbstractLibrary from "../../../src-shared/AbstractLibrary";
import { dateModifiedColumn } from "../../../src-shared/utils/libraryColumns";
import { showDialog } from "../../../src-shared/GlobalDialog";
import gql from "graphql-tag";
import UploadSelectionMethodsDialog from "./UploadSelectionMethodsDialog";
import modelNameToReadableName from "../../../src-shared/utils/modelNameToReadableName";

const schema = {
  model: "selectionMethod",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    { path: "cofactor", type: "string", displayName: "Cofactor" },
    {
      displayName: modelNameToReadableName("targetOrganismClass", {
        upperCase: true,
        plural: true
      }),
      path: "selectionMethodTargetOrganismClasses.targetOrganismClass.name",
      render: (v, r) =>
        r.selectionMethodTargetOrganismClasses
          .map(c => c.targetOrganismClass.name)
          .join(", ")
    },
    dateModifiedColumn
  ]
};

class SelectionMethodPanel extends Component {
  openEditWindow = record => {
    const { refetchSelectionMethods } = this.props;
    const selectionMethodTargetOrganismClasses = get(
      record,
      "selectionMethodTargetOrganismClasses"
    );
    const targetOrganismClasses = [];

    if (
      selectionMethodTargetOrganismClasses &&
      selectionMethodTargetOrganismClasses.length > 0
    ) {
      selectionMethodTargetOrganismClasses.forEach(
        selectionMethodTargetOrganismClass => {
          targetOrganismClasses.push(
            selectionMethodTargetOrganismClass.targetOrganismClass
          );
        }
      );
    }
    showDialog({
      modalType: "CREATE_NEW_SELECTION_METHOD",
      modalProps: {
        refetch: refetchSelectionMethods,
        selectionMethodTargetOrganismClasses,
        initialValues: {
          ...pick(record, ["id", "name", "cofactor", "description"]),
          targetOrganismClasses
        }
      }
    });
  };

  contextMenu = ({ deleteMenuItem, selectedRecords }) => {
    const menuItems = [];
    if (selectedRecords.length === 1) {
      const record = selectedRecords[0];
      menuItems.push(
        <MenuItem
          key="edit"
          icon="edit"
          onClick={() => this.openEditWindow(record)}
          text="Edit"
        />
      );
    }
    menuItems.push(deleteMenuItem);
    return menuItems;
  };

  onUpload = () => {
    const { refetchSelectionMethods } = this.props;
    showDialog({
      ModalComponent: UploadSelectionMethodsDialog,
      modalProps: {
        refetch: refetchSelectionMethods
      }
    });
  };

  render() {
    return (
      <div className="tg-card">
        <Helmet title="Selection Methods" />
        <AbstractLibrary
          {...this.props}
          contextMenu={this.contextMenu}
          onDoubleClick={this.openEditWindow}
          libraryName="selectionMethod"
          showUploadFunction={this.onUpload}
          onNewItemClick={this.openEditWindow}
        />
      </div>
    );
  }
}

const selectionMethodLibraryFragment = gql`
  fragment selectionMethodLibraryFragment on selectionMethod {
    id
    name
    cofactor
    description
    createdAt
    updatedAt
    selectionMethodTargetOrganismClasses {
      id
      targetOrganismClass {
        id
        name
      }
    }
  }
`;

export default compose(
  withTableParams({
    urlConnected: false,
    schema,
    formName: "selectionMethodsLibrary",
    withDisplayOptions: true,
    tableParams: {
      showEmptyColumnsByDefault: true
    }
  }),
  withQuery(selectionMethodLibraryFragment, {
    isPlural: true
  })
)(SelectionMethodPanel);
