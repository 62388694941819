/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

export function getWorkflowToolCode({
  toolCode,
  index
}: {
  toolCode: string;
  index: number;
}) {
  return toolCode + "-" + index;
}
