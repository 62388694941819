/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import gql from "graphql-tag";
import { sequencePartialFragment } from "./sequencePartialFragment.gql";

export const sequenceFragment = gql`
  fragment sequenceFragment on sequence {
    externalReferenceSystem
    externalReferenceId
    externalReferenceType
    externalReferenceUrl
    ...sequencePartialFragment
    plasmidInductionMethodPlasmids {
      id
      inductionMethod {
        id
        name
      }
    }
    isJ5Sequence
    polynucleotideMaterialId
    sequenceJ5ItemViews {
      id
      j5Report {
        id
        name
        designId
      }
    }
    referenceCdsFragmentId
    molecularWeight
    sequenceFragments {
      id
      cid
      index
      fragment
    }
  }
  ${sequencePartialFragment}
`;
