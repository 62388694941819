/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useMemo } from "react";
import Helmet from "react-helmet";
import { noop } from "lodash";
import { MenuItem, Callout, Tooltip } from "@blueprintjs/core";
import { compose } from "recompose";
import { showConfirmationDialog } from "@teselagen/ui";
import AbstractLibrary from "../AbstractLibrary";
import {
  getLabAdmins,
  getLabMembers,
  removeDefaultLabIdIfNecessary
} from "../utils/labUtils";
import { showDialog } from "../GlobalDialog";
import { isAdmin } from "../utils/generalUtils";
import { ADMIN_SINGLE_LAB_CID } from "../../../tg-iso-shared/src/utils/defaultAdminLabCid";
import { safeDelete } from "../apolloMethods";
import { removeLabMembers } from "../utils/labUtils";
import CreateOrEditNewLabGroup from "../CreateOrEditNewLabGroup";
import AdminCallout from "../SharedAppSettings/AdminCallout";
import libraryEnhancer from "../libraryEnhancer";
import gql from "graphql-tag";
import { COMMON_LAB_ID } from "@teselagen/auth-utils";

const schema = {
  model: "lab",
  fields: [
    { path: "name", type: "string", displayName: "Lab Name" },
    {
      path: "labAdmins",
      type: "array",
      filterDisabled: true,
      sortDisabled: true,
      displayName: "Lab Admins",
      render: (v, record) => {
        try {
          return getLabAdmins(record)
            .map(({ user }) => user.username)
            .join(", ");
        } catch (e) {
          console.error(e);
          return "Unknown";
        }
      }
    },
    {
      path: "labRoles.user.username",
      displayName: "Lab Members",
      render: (v, record) => {
        try {
          return getLabMembers(record)
            .map(({ user }) => user.username)
            .join(", ");
        } catch (e) {
          console.error(e);
          return "Unknown";
        }
      }
    },
    {
      path: "color",
      type: "string",
      sortDisabled: true,
      filterDisabled: true,
      render: v => {
        return (
          v && (
            <div
              style={{
                height: 20,
                width: 40,
                background: v,
                border: "1px solid #182026",
                borderRadius: 5
              }}
            />
          )
        );
      }
    },
    {
      path: "emailDomain",
      type: "string",
      displayName: "Allowed Email Domain",
      render: v => {
        if (v) return v;
        return "Any";
      }
    }
  ]
};

/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

//As an admin, I would like to be able to deactivate/reactivate any user
//As an admin, I would like to see a table of all users and their login status, activation status
const LabManagementPanel = props => {
  const { refetchLabs } = props;

  const onNewItemClick = useCallback(() => {
    showDialog({
      ModalComponent: CreateOrEditNewLabGroup,
      modalProps: {
        refetchLabs
      }
    });
  }, [refetchLabs]);

  const editLab = useCallback(
    record => {
      if (!isAdmin()) return;
      showDialog({
        ModalComponent: CreateOrEditNewLabGroup,
        modalProps: {
          refetchLabs,
          dialogProps: {
            title: "Edit Lab"
          },
          recordId: record.id
        }
      });
    },
    [refetchLabs]
  );

  const contextMenuItems = useCallback(
    ({ selectedRecords }) => {
      const menuItems = [];
      if (!isAdmin()) return menuItems;
      const selectedAdminLab = selectedRecords.some(
        r => r.cid === ADMIN_SINGLE_LAB_CID
      );
      const wrapWithAdminTooltip = menuItem => {
        const adminLabTooltip = "Cannot edit admin lab.";
        if (selectedAdminLab) {
          return (
            <Tooltip className="full-width-tooltip" content={adminLabTooltip}>
              {menuItem}
            </Tooltip>
          );
        } else {
          return menuItem;
        }
      };
      if (selectedRecords.length === 1) {
        const labToEdit = selectedRecords[0];

        menuItems.push(
          wrapWithAdminTooltip(
            <MenuItem
              key="editLab"
              disabled={selectedAdminLab}
              icon="edit"
              text="Edit"
              onClick={() => editLab(labToEdit)}
            />
          )
        );
      }
      menuItems.push(
        wrapWithAdminTooltip(
          <MenuItem
            key="removeMembers"
            icon="eraser"
            text="Remove all members"
            disabled={selectedAdminLab}
            onClick={async () => {
              const confirmation = await showConfirmationDialog({
                text: `Are you sure you want to remove all Members from this lab?`,
                intent: "danger",
                icon: "warning-sign",
                confirmButtonText: "Remove all"
              });
              if (confirmation) {
                await removeLabMembers(selectedRecords, safeDelete);
                for (let i = 0; i < selectedRecords.length; i++) {
                  const record = selectedRecords[i];
                  await removeDefaultLabIdIfNecessary({
                    originalLabBeingEdited: record,
                    labGroupAdmins: getLabAdmins(record),
                    labGroupMembers: getLabMembers(record)
                  });
                }
              }
              refetchLabs();
            }}
          />
        )
      );
      return menuItems;
    },
    [editLab, refetchLabs]
  );

  const onDoubleClick = useMemo(() => {
    if (isAdmin()) {
      return editLab;
    }
    return noop;
  }, [editLab]);

  return (
    <div className="tg-card">
      <Helmet title="Labs" />
      {isAdmin() ? (
        <div>
          <AdminCallout>
            As an admin you can add, delete and edit lab groups.
          </AdminCallout>
        </div>
      ) : (
        <Callout intent="primary" title="You are not an admin">
          Only an admin can add, delete and edit lab groups.
        </Callout>
      )}
      <br />
      <AbstractLibrary
        {...props}
        // noRoute
        noNewItem={!isAdmin()}
        onNewItemClick={onNewItemClick}
        contextMenu={contextMenuItems}
        // generateExtraContextMenuItems={this.contextMenuItems}
        libraryName="labGroup"
        onDoubleClick={onDoubleClick}
      />
    </div>
  );
};

const fragment = gql`
  fragment labManagementFragment on lab {
    id
    cid
    name
    color
    emailDomain
    labRoles {
      id
      roleCode
      userId
      user {
        id
        username
        email
      }
    }
  }
`;

export default compose(
  libraryEnhancer({
    schema,
    fragment,
    noAddedBy: true,
    additionalFilter: (_, qb) => {
      qb.whereAll({
        id: qb.notEquals(COMMON_LAB_ID)
      });
    }
  })
)(LabManagementPanel);
