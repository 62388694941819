/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import { compose } from "recompose";
import { InputField, DialogFooter, wrapDialog } from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { noop, get } from "lodash";
import { safeUpsert } from "../apolloMethods";
import axios from "axios";
import { validateEmail } from "../auth/utils/validations";

class CreatePublicationTargetDialog extends Component {
  onSubmit = async values => {
    const { hideModal, refetch = noop } = this.props;
    const { id: updatedId, name, authKey, authToken } = values;
    try {
      const baseUrl = authKey.split(`-serviceaccount@`)[1];
      if (!baseUrl) {
        throw new Error("Invalid URL provided");
      }
      // baseUrl might looks something like: example.com
      // check that the baseURL is indeed a URL (this will throw if it is not)
      new URL(`http://` + baseUrl);

      // check account validity by getting a jwt token if same domain
      if (window.location.href.startsWith(baseUrl)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = await axios({
          method: "PUT",
          url: `${baseUrl}/tg-api/public/auth`, // this will move to /api/public/auth
          data: JSON.stringify({
            username: authKey,
            password: authToken,
            expiresIn: "1m"
          }),
          headers: {
            "Content-Type": "application/json"
          }
        });
      }

      try {
        await safeUpsert(
          ["publicationTarget", "id name, baseUrl authKey authToken"],
          {
            id: updatedId,
            name,
            baseUrl,
            authKey,
            authToken
          }
        );
        await refetch();
        hideModal();
      } catch (error) {
        console.error("error:", error);
        window.toastr.error(
          "Error creating publication target. See console for more details"
        );
      }
    } catch (err) {
      console.error(`err:`, err);
      if (err?.response?.status === 401) {
        window.toastr.error(
          `Invalid service account or auth token provided  see console for more details`
        );
      } else {
        window.toastr.error(
          `Invalid URL provided in the service account email - see console for more details`
        );
      }
    }
  };

  render() {
    const { hideModal, submitting, handleSubmit } = this.props;
    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <InputField
            name="name"
            label="Name"
            isRequired
            tooltipInfo="The name of what is being published/where it is being published. Users will see this from Sequence Library > Right Click > Publish > Publish Sequence/Design to Instance/Lab."
            placeholder="Publish Sequence/Design to Instance"
          />
          <InputField
            name="authKey"
            label="Service Account Email"
            validate={validateServiceAccountEmail}
            tooltipInfo={
              <>
                The service account email of the target instance you're wanting
                to publish to. e.g.
                test-serviceaccount@some-other-instance.teselagen.com
                <br></br>
                <br></br>
                Note: It is also possible to target THIS instance by using a
                service account associated with THIS instance. e.g.
                test-serviceaccount@{window.location.origin}
              </>
            }
            placeholder="test-serviceaccount@some-other-instance.teselagen.com"
          />
          <InputField
            name="authToken"
            label="Service Account Token"
            type="password"
            isRequired
            placeholder="your-secret-token-here"
          />
        </div>
        <DialogFooter
          hideModal={hideModal}
          submitting={submitting}
          onClick={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

const CreatePublicationTargetWithDialog = compose(
  wrapDialog({
    getDialogProps: props => {
      return {
        title: get(props, "initialValues.id")
          ? "Edit Publication Target"
          : "Create Publication Target"
      };
    }
  }),
  reduxForm({
    form: "createPublicationTarget"
  })
)(CreatePublicationTargetDialog);

export default CreatePublicationTargetWithDialog;

function validateServiceAccountEmail(value) {
  if (!value) {
    return "Required";
  }
  if (!value.includes("-serviceaccount@")) {
    return "Invalid service account email (must contain -serviceaccount@). Please generate a new service account if your service account email is using the old format (e.g. somename@somecompany.teselagen-serviceaccount.com)";
  }
  const err = validateEmail(value);
  if (err) {
    return err;
  }
  return undefined;
}
