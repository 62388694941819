/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useCallback } from "react";
import { compose, withProps } from "recompose";
import { DataTable, withTableParams, CheckboxField } from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";

import { dateModifiedColumn } from "../../../../src-shared/utils/libraryColumns";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";

const schema = {
  model: "aliquotContainer",
  fields: [
    {
      path: "name"
    },
    {
      displayName: "Barcode",
      path: "barcode.barcodeString"
    },
    {
      displayName: "Tube Type",
      path: "aliquotContainerType.name"
    },
    dateModifiedColumn
  ]
};

function TubesTable({ tableParams, onDoubleClick, multi }) {
  const showAssignedTubes = val => {
    tableParams.setNewParams({
      ...tableParams.currentParams,
      showAssigned: val
    });
  };
  return (
    <React.Fragment>
      <CheckboxField
        name="showAssigned"
        label="Show Assigned Tubes"
        onFieldSubmit={showAssignedTubes}
      />
      <DataTable
        {...tableParams}
        onDoubleClick={onDoubleClick}
        maxHeight={450}
        noPadding
        withCheckboxes={multi}
        isSingleSelect={!multi}
      />
    </React.Fragment>
  );
}

const fragment = `
  id
  name
  barcode {
    id
    barcodeString
  }
  aliquotContainerType {
    code
    name
  }
  updatedAt
`;

export default compose(
  reduxForm({
    form: "inspectTubeDisplayTableOptions"
  }),
  tgFormValues("showAssigned"),
  withProps(props => {
    // Added while #12943 is not ready
    const additionalFilter = useCallback(
      (_, qb, currentParams) => {
        // needs to be assignable
        qb.whereAll({
          "aliquotContainerType.isTube": true,
          aliquotContainerTypeCode:
            props.containerArrayType.nestableTubeTypes.map(
              tubeType => tubeType.aliquotContainerType.code
            )
        });
        if (!currentParams.showAssigned) {
          qb.whereAll({
            containerArrayId: qb.isNull()
          });
        }
      },
      [props.containerArrayType.nestableTubeTypes]
    );
    return { additionalFilter };
  }),
  withTableParams({
    urlConnected: false,
    formName: "tubesDisplayTable",
    schema,
    defaults: {
      order: ["-modified"]
    }
  }),
  withQuery(["aliquotContainer", fragment], { isPlural: true })
)(TubesTable);
