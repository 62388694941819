/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import gql from "graphql-tag";
import Url from "url";
import { DataTable, CollapsibleCard } from "@teselagen/ui";
import { get, set, size, groupBy, partition, find, omit } from "lodash";
import queryString from "query-string";
import { Intent, Icon } from "@blueprintjs/core";
import LabelWithTooltip from "../../LabelWithTooltip";
import { getRequestHeaderKeys } from "@teselagen/auth-utils";
import { dateCreatedColumn, dateModifiedColumn } from "../libraryColumns";
import withQuery from "../../withQuery";
import QueryBuilder from "tg-client-query-builder";
import { rowIndexToLetter } from "../../../../tg-iso-lims/src/utils/rowIndexToLetter";
import { handleZipFiles } from "../../../../tg-iso-shared/src/utils/fileUtils";
import { download } from "../downloadTest";
import { unparse } from "papaparse";
import { safeQuery } from "../../apolloMethods";

export const dataGridAliquotFragment = gql`
  fragment dataGridAliquotFragment on aliquot {
    id
    sample {
      id
      name
      material {
        id
        name
      }
    }
    aliquotContainer {
      id
      rowPosition
      columnPosition
      containerArray {
        id
        name
        barcode {
          id
          barcodeString
        }
      }
      aliquotContainerTypeCode
    }
  }
`;

// Not sure if it's a good idea to import from the server's seed data.
// So just going to create codes variables.
// import importFileSetStepCodes from "../../../server/src/seedData/build/dictionaries/importFileSetStep.json"
// NOTE: these codes are directly linked to the importFileSet (not the dataGrid) entity.
// But for any practical purposes of importing data from a data grid, thse codes are the ones that best describe
// the state of such an import and best align with the users perspective.
export const NOASSAY = "NOASSAY";
export const FINISHED = "FINISHED";
export const ERROR = "ERROR";
export const INPROGRESS = "INPROGRESS";

// Context display names for context information on data grid's aliquots
export const PLATE_NAME_FIELD = "Plate Name";
export const PLATE_BARCODE_FIELD = "Plate Barcode";
export const ALIQUOT_ID_FIELD = "Aliquot ID";
export const ALIQUOT_WELL_FIELD = "Aliquot Well";
export const SAMPLE_NAME_FIELD = "Sample";
export const MATERIAL_NAME_FIELD = "Material";

export const ALIQUOT_CONTEXT_SCHEMA = [
  {
    path: "aliquot.id",
    displayName: ALIQUOT_ID_FIELD,
    render: aliquotId => {
      return aliquotId ? (
        <a href={`/client/aliquots/${aliquotId}`}>{aliquotId}</a>
      ) : (
        "N/A"
      );
    }
  },
  {
    path: "aliquot.aliquotContainer.containerArray.name",
    displayName: PLATE_NAME_FIELD,
    render: (value, record) => (
      <a
        href={`/client/plates/${get(
          record,
          "aliquot.aliquotContainer.containerArray.id"
        )}`}
      >
        {value}
      </a>
    )
  },
  {
    path: "aliquot.aliquotContainer.containerArray.barcode.barcodeString",
    displayName: PLATE_BARCODE_FIELD
  },
  {
    path: "aliquot.aliquotContainer",
    displayName: ALIQUOT_WELL_FIELD,
    render: (
      record // record is posibly undefined
    ) => rowIndexToLetter(record?.rowPosition) + (record?.columnPosition + 1)
  },
  {
    path: "aliquot.sample.name",
    displayName: SAMPLE_NAME_FIELD
  },
  {
    path: "aliquot.sample.material.name",
    displayName: MATERIAL_NAME_FIELD
  }
];

// importFileSet codes that reflect that the import file set is imported.
export const IMPORTED_CODES = [
  FINISHED
  // "FINISHED-DISCARDED", // Maybe discarded imports should never show up in the dialog.
];

export const getDataGridImportStatus = dataGrid => {
  const importFileSetStepCode = get(
    dataGrid,
    "importFileSet.importFileSetStepCode"
  );
  const dataGridStatus = {};
  switch (importFileSetStepCode) {
    case FINISHED:
      dataGridStatus["code"] = FINISHED;
      dataGridStatus["message"] = null;
      break;
    case ERROR:
      dataGridStatus["code"] = ERROR;
      dataGridStatus["message"] = get(dataGrid, "importFileSet.description");
      break;
    default:
      dataGridStatus["code"] = importFileSetStepCode;
      dataGridStatus["message"] =
        "This Data Grid hasn't been imported into an Assay yet.";
      break;
  }
  return dataGridStatus;
};

const getDataGridImportStatusComponent = dataGrid => {
  const importStatus = getDataGridImportStatus(dataGrid);

  const icon =
    importStatus.code !== FINISHED
      ? importStatus.code === ERROR
        ? "warning-sign"
        : "info-sign"
      : null;

  const tooltipMessage = importStatus.message
    ? importStatus.message || "Error importing data."
    : undefined;

  const tooltip = (
    <span>
      {tooltipMessage}
      {importStatus.code === ERROR && (
        <span>
          <br /> <br /> Make sure the Data Mapping is correct and/or try
          importing again.
        </span>
      )}
    </span>
  );

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <React.Fragment>
        <LabelWithTooltip
          icon={icon}
          intent={importStatus.code === ERROR ? Intent.WARNING : Intent.NONE}
          label={importStatus.code === FINISHED ? "" : "no assay"}
          tooltip={tooltip}
        />
      </React.Fragment>
    </div>
  );
};

const getDataGridAssayLink = dataGrid => {
  const assay = get(dataGrid, "importFileSet.assay");
  return (
    <>
      <a href={`/client/assays/${get(assay, "id")}`}>{get(assay, "name")}</a>
      <React.Fragment>
        <Icon
          intent={Intent.SUCCESS}
          icon="symbol-circle"
          style={{ paddingLeft: 5 }}
        />
      </React.Fragment>
    </>
  );
};

export const getDataGridAssayStatus = dataGrid => {
  const importStatus = getDataGridImportStatus(dataGrid);
  return (
    <>
      {importStatus.code === FINISHED
        ? getDataGridAssayLink(dataGrid)
        : getDataGridImportStatusComponent(dataGrid)}
    </>
  );
};

export const getInfoSchema = (options = {}) => {
  const { withDates = false, withDescription = false } = options;
  const schema = [
    {
      path: "name",
      displayName: "Name"
    },
    ...(withDescription
      ? [
          {
            path: "description",
            displayName: "Description"
          }
        ]
      : []),
    {
      path: "importFileSet.assay",
      displayName: "Assay",
      render: (_, record) => {
        return getDataGridAssayStatus(record);
      }
    },
    ...(withDates ? [dateModifiedColumn] : [])
  ];

  return schema;
};

export const exportDataGridImportFileAsCsv = async dataGrid => {
  const importFileSetId = get(dataGrid, "importFileSet.id");
  const results = await window.serverApi.get(
    Url.resolve("/test-routes/files/", importFileSetId)
  );
  if (results.status === 200) {
    const encodedUri = encodeURI("data:text/csv;charset=utf-8," + results.data);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${dataGrid.name}.csv`);
    document.body.appendChild(link); // Required for FF
    link.click();
  } else {
    window.toastr.error("we can't download the file");
  }
};

export const dataCellsToCsv = dataCells => {
  const headers = dataCells.filter(cell => cell.rowPosition === 0);
  const dataRows = dataCells.filter(cell => cell.rowPosition > 0);
  const csv = [];

  csv.push(headers.map(header => header.value));
  const groupedRows = groupBy(dataRows, "rowPosition");
  Object.values(groupedRows).forEach(rowGroup => {
    const row = headers.map(header => {
      const cell = find(rowGroup, { columnPosition: header.columnPosition });
      return cell.value || "N/A";
    });
    csv.push(row);
  });

  return csv;
};

export const exportDataGridsFromDataCells = async dataGridIds => {
  const files = [];
  const dataGrids = await safeQuery(
    ["dataGrid", "id name dataCells { id value rowPosition columnPosition }"],
    {
      variables: { filter: { id: dataGridIds } }
    }
  );

  for (const dataGrid of dataGrids) {
    const nameToUse = `${get(dataGrid, "name")}.csv`;
    const dataCells = get(dataGrid, "dataCells");
    const csvData = dataCellsToCsv(dataCells);

    if (dataGrids.length > 1) {
      files.push({ name: nameToUse, data: unparse(csvData) });
    } else {
      return download(unparse(csvData), nameToUse, "text/csv");
    }
  }

  const zipFile = await handleZipFiles(files);
  download(zipFile, "DataGrids.zip", "application/zip");
};

/** Helpers for working with the react-spreadsheet library used for certain Data Grid views */

// This returns a schema and an entities object ready for TG's DataTable component.
export function parseDataForDataGridTable(dataCells, options = {}) {
  const { headerCells: _headerCells, withAliquotData = false } = options;
  let headerCells = [];
  let rowCells = [];

  if (!_headerCells?.length) {
    [headerCells, rowCells] = partition(
      dataCells,
      cell => cell.rowPosition === 0
    );
  } else {
    headerCells = _headerCells;
    rowCells = dataCells;
  }

  let withAliquot = false;
  const dataRows = groupBy(rowCells, "rowPosition");
  const entities = Object.values(dataRows).map(row => {
    const entity = {};

    headerCells.forEach(headerCell => {
      const rowKey = find(
        row,
        rowCell => rowCell.columnPosition === headerCell.columnPosition
      );
      let value = get(rowKey, "value");
      if (!isNaN(value)) value = parseFloat(value);
      if (typeof value === "number" && isNaN(value)) value = null;
      entity[headerCell.value] = value ?? "N/A";
      /**
       * Data cells may be linked to aliquots, if that's the case
       * we extend the data grid record view so that each row
       * has a reference to its aliquot.
       */
      if (rowKey?.aliquot) {
        withAliquot = true;
        ALIQUOT_CONTEXT_SCHEMA.forEach(column =>
          set(entity, column.path, get(rowKey, column.path))
        );
      }
      if (withAliquotData) set(entity, "aliquot", get(rowKey, "aliquot"));
    });

    return entity;
  });

  const schema = headerCells.map(cell => {
    const columnValues = entities.map(entity => entity[cell.value]);
    const type = columnValues.every(value => !isNaN(value))
      ? "number"
      : "string";
    return {
      path: cell.value,
      displayName: cell.value,
      type,
      render: (_, record) => get(record, cell.value)
    };
  });

  if (withAliquot) {
    schema.unshift(...ALIQUOT_CONTEXT_SCHEMA);
  }

  return {
    schema,
    entities
  };
}

export const getDataGrid = async (dataGridId, options = {}) => {
  const { rowPagination } = options;
  const searchParams = queryString.stringify({
    rowPagination: JSON.stringify(rowPagination)
  });
  try {
    const dataGrids = await window.serverApi.request({
      method: "GET",
      headers: getRequestHeaderKeys(),
      withCredentials: true,
      url: `/test-routes/data-grids/${dataGridId}?${searchParams}`
    });
    return dataGrids.data;
  } catch (error) {
    console.error(error);
  }
};

export const getDataCellsFromCsvData = (data, options = {}) => {
  const { nonEmpty = true, excludeColumns } = options;

  const headers = Object.keys(omit(data[0], [...excludeColumns, "aliquot"]));

  const dataCells = headers.map((header, idx) => ({
    value: header,
    header: header,
    rowPosition: 0,
    columnPosition: idx
  }));

  data.forEach((row, row_idx) => {
    const dataRow = omit(row, [...excludeColumns, "aliquot"]);
    Object.entries(dataRow).forEach(([header, value], col_idx) => {
      // csv data should always come with string values, so empty cells
      // will be empty strings
      if (nonEmpty && value !== "") {
        dataCells.push({
          aliquotId: row.aliquot?.id,
          value,
          header,
          rowPosition: row_idx + 1,
          columnPosition: col_idx
        });
      }
    });
  });

  return dataCells;
};

/**
 * This function is originally designed for inventory record views,
 * to include data grid rows filtered by the inventory record ID.
 *
 * The ID is obtained from the URL params, but can also be passed as prop.
 *
 * The filter is made on the data grid's data cells "aliquotId" field.
 *
 * @returns Data Cell Records
 */
export const withDataCells = (aliquotId = null) => {
  return withQuery(
    [
      "dataCell",
      "id createdAt header value rowPosition columnPosition dataGrid { id name dataCells(filter: {rowPosition: 0}) { id value } }"
    ],
    {
      isPlural: true,
      showLoading: true,
      options: props => {
        const id = get(props, "match.params.id");
        const qb = new QueryBuilder("dataCell");
        const filter = qb.whereAll({ aliquotId: id || aliquotId });
        return {
          variables: { filter }
        };
      }
    }
  );
};

export const dataGridRowsCard = dataCells => {
  if (size(dataCells) > 0) {
    const dataByRows = groupBy(dataCells, "rowPosition");

    const headers = dataCells[0].dataGrid.dataCells.map(
      dataCell => dataCell.value
    );

    const dataRows = Object.values(dataByRows).map(rowCells => {
      return rowCells.reduce((acc, item) => {
        const { header, value, createdAt, dataGrid } = item;
        acc[header] = value;
        acc["createdAt"] = createdAt;
        acc["dataGrid"] = dataGrid;
        return acc;
      }, {});
    });

    const schema = [
      {
        /**
         * NOTE: Not sure if we are ready to let the user know that this data comes from a Data Grid.
         * In fact, the data grids linked to aliquot are in an experimental phase and won't be shown in the
         * Data Grid Library either.
         */
        displayName: "Data Grid",
        render: (_, record) => {
          return (
            <a href={`/client/data-grids/${get(record, "dataGrid.id")}`}>
              {get(record, "dataGrid.name")}
            </a>
          );
        }
      }
    ];

    schema.push(
      ...headers.map(header => ({
        displayName: header,
        render: (_, record) => get(record, header)
      }))
    );

    schema.push(dateCreatedColumn);

    return (
      <CollapsibleCard key="dataGridRows" title="Associated Data">
        <i style={{ marginBottom: 20 }}>
          These are data points associated with the aliquot
        </i>
        <div style={{ paddingTop: 30 }}>
          <DataTable
            schema={schema}
            style={{ marginBottom: 20 }}
            formName="assaySubjectsTable"
            entities={dataRows}
            isSimple
          />
        </div>
      </CollapsibleCard>
    );
  }
  return null;
};
