/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import { get, isNumber, keyBy, times } from "lodash";
import { safeQuery } from "../../src-shared/apolloMethods";
import {
  containerArrayExportFragment,
  aliquotContainerFragment,
  aliquotFragment
} from "../graphql/fragments/containerArrayExportFragment.gql";
import type {
  ContainerArrayExportFragment,
  AliquotContainerPlateExportFragment as AliquotContainerFragment,
  AliquotPlateExportFragment as AliquotFragment
} from "../graphql/fragments/containerArrayExportFragment.gql.generated";
import { showProgressToast } from "@teselagen/ui";
import { toDecimalPrecision } from "../../src-shared/utils/unitUtils";
import { defaultReagentHeaders } from "./getReagentsFromCsv";
import { unparse } from "papaparse";
import {
  getSequenceInFileType,
  sequenceFileTypeToExt
} from "../../../tg-iso-shared/src/sequence-import-utils/utils";
import { handleZipFiles } from "../../../tg-iso-shared/src/utils/fileUtils";
import { download } from "../../src-shared/utils/downloadTest";
import { sortAliquotContainers } from "../../../tg-iso-lims/src/utils/plateUtils";
import { getAliquotContainerLocation } from "../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { RecursiveRequired } from "../../src-shared/typescriptHelpers";

type AliquotContainerWithAliquot = AliquotContainerFragment & {
  aliquot?: AliquotFragment;
};

type PolynucleotideMaterialSequence =
  RecursiveRequired<AliquotFragment>["sample"]["material"]["polynucleotideMaterialSequence"];

type Additive = RecursiveRequired<AliquotFragment>["additives"][number];

const ifNumCleanNum = (n: string | number) => {
  if (isNumber(n)) return toDecimalPrecision(n);
  else return "";
};

const addAdditiveToRow = (additive: Additive, row: any[]) => {
  const additiveMaterial = additive.lot
    ? get(additive, "lot.additiveMaterial")
    : additive.additiveMaterial;
  row.push(additiveMaterial && additiveMaterial.name);
  row.push(additive.lot && additive.lot.name);
  row.push(ifNumCleanNum(additive.volume));
  row.push(additive.volumetricUnitCode);
  row.push(ifNumCleanNum(additive.concentration));
  row.push(additive.concentrationUnitCode);
  row.push(ifNumCleanNum(additive.mass));
  row.push(additive.massUnitCode);
};

export async function platesOrTubesToCSV(
  containersIds: string[],
  {
    sorting = "rowFirst",
    sequenceFileType,
    isTube
  }: {
    sorting?: "rowFirst" | "columnFirst";
    sequenceFileType: "genbank" | "fasta" | "json";
    isTube?: boolean;
  }
) {
  let aliquotContainers = (await safeQuery(aliquotContainerFragment, {
    ...(!isTube && { nameOverride: "Plate Wells" }),
    variables: {
      filter: {
        ...(!isTube && { containerArrayId: containersIds }),
        ...(isTube && { id: containersIds })
      }
    }
  })) as AliquotContainerWithAliquot[];

  const aliquotIds: string[] = [];
  aliquotContainers.forEach(ac => {
    if (ac.aliquotId && !aliquotIds.includes(ac.aliquotId)) {
      aliquotIds.push(ac.aliquotId);
    }
  });

  if (aliquotIds.length) {
    const aliquots = await safeQuery<AliquotFragment>(aliquotFragment, {
      variables: {
        filter: {
          id: aliquotIds
        }
      }
    });
    const keyedAliquots = keyBy(aliquots, "id");
    aliquotContainers = aliquotContainers.map(ac => {
      if (ac.aliquotId) {
        return {
          ...ac,
          aliquot: keyedAliquots[ac.aliquotId]
        };
      }
      return ac;
    });
  }

  const dnaCsvRows: any[] = [];
  const microbialCsvRows: any[] = [];
  const tubeCsvRows: any[] = [];
  const microbialTubeCsvRows: any[] = [];
  const sequenceMap: {
    [sequenceHash: string]: PolynucleotideMaterialSequence;
  } = {};
  const dnaCsvHeaders = [
    "PLATE_NAME",
    "PLATE_BARCODE",
    "WELL_LOCATION",
    "TUBE_BARCODE",
    "SAMPLE_NAME",
    "MATERIAL_NAME",
    "SEQUENCE_FILE",
    "SEQUENCE_SIZE",
    "VOLUME",
    "VOLUMETRIC_UNIT",
    "CONCENTRATION",
    "CONCENTRATION_UNIT",
    "MASS",
    "MASS_UNIT",
    "ALIQUOT_ID",
    "SAMPLE_ID",
    "MATERIAL_ID"
  ];
  const microbialCsvHeaders = [
    "PLATE_NAME",
    "PLATE_BARCODE",
    "TUBE_BARCODE",
    "WELL_LOCATION",
    "MATERIAL_NAME",
    "SAMPLE_NAME",
    "STRAIN_NAME",
    "PLASMID_NAME",
    "GB_FILE",
    "TOTAL_VOLUME",
    "TOTAL_VOLUMETRIC_UNIT",
    "MASS",
    "MASS_UNIT"
  ];
  const tubeCsvHeaders = [
    "TUBE_NAME",
    "TUBE_BARCODE",
    "TUBE_LOCATION",
    "SAMPLE_NAME",
    "MATERIAL_NAME",
    "SEQUENCE_FILE",
    "SEQUENCE_SIZE",
    "VOLUME",
    "VOLUMETRIC_UNIT",
    "CONCENTRATION",
    "CONCENTRATION_UNIT",
    "MASS",
    "MASS_UNIT",
    "ALIQUOT_ID",
    "SAMPLE_ID",
    "MATERIAL_ID"
  ];
  const tubeMicrobialCsvHeaders = [
    "TUBE_NAME",
    "TUBE_BARCODE",
    "TUBE_LOCATION",
    "MATERIAL_NAME",
    "SAMPLE_NAME",
    "STRAIN_NAME",
    "PLASMID_NAME",
    "GB_FILE",
    "TOTAL_VOLUME",
    "TOTAL_VOLUMETRIC_UNIT",
    "MASS",
    "MASS_UNIT"
  ];

  let maxNumAdditives = 0;
  let longestRow = 0;
  // so that we have unique names in case two sequences have the same name
  const sequenceNameCounter: { [sequenceName: string]: number } = {};
  let tubeHasMicrobial = false;
  let someDnaPlates = false;
  let someMicrobialPlates = false;
  const dnaPlates: ContainerArrayExportFragment[] = [];
  const microbialPlates: ContainerArrayExportFragment[] = [];

  const addSequenceToMap = (sequence: PolynucleotideMaterialSequence) => {
    if (!sequenceMap[sequence.hash]) {
      const originalName = sequence.name || "Untitled Sequence";
      let newName = originalName;
      sequenceNameCounter[originalName] =
        sequenceNameCounter[originalName] || 0;
      if (sequenceNameCounter[originalName]) {
        // only append a number if greater than 0 (seen before)
        newName += ` ${sequenceNameCounter[originalName]}`;
      }
      sequenceNameCounter[originalName]++;
      sequenceMap[sequence.hash] = { ...sequence, name: newName };
    }
  };

  const addContainersData = ({
    aliquotContainers,
    plate
  }: {
    aliquotContainers: AliquotContainerWithAliquot[];
    plate?: ContainerArrayExportFragment;
  }) => {
    let isDnaPlate = false;
    let isMicrobialPlate = false;
    aliquotContainers.forEach(aliquotContainer => {
      const matType =
        aliquotContainer.aliquot?.sample?.material?.materialTypeCode;
      if (matType === "MICROBIAL") {
        if (!isTube) {
          isMicrobialPlate = true;
        }
      } else if (matType === "DNA") {
        if (!isTube) {
          isDnaPlate = true;
        }
      }
    });
    aliquotContainers.forEach(aliquotContainer => {
      const aliquot = aliquotContainer.aliquot;
      const sample = aliquot?.sample;
      if (!sample?.id && !aliquotContainer.additives?.length) return;
      const sequence = get(
        sample,
        "material.polynucleotideMaterialSequence"
      ) as PolynucleotideMaterialSequence;
      let sequenceNameToUse = "";
      let sequenceSize: string | number = "";

      if (sequence) {
        addSequenceToMap(sequence);
        const seq = sequenceMap[sequence.hash];
        sequenceNameToUse = seq.name;
        sequenceSize = seq.size;
      }
      const plasmidNames: string[] = [];
      if (sample?.material?.materialTypeCode === "MICROBIAL") {
        sample.material.microbialMaterialMicrobialMaterialPlasmids?.forEach(
          mmp => {
            const plasmid = mmp.polynucleotideMaterial
              ?.polynucleotideMaterialSequence as PolynucleotideMaterialSequence;
            if (plasmid) {
              addSequenceToMap(plasmid);
              const seq = sequenceMap[plasmid.hash];
              plasmidNames.push(seq.name);
            }
          }
        );
      }
      let materialName;
      const materialIds: string[] = [];
      if (!sample?.material && sample?.sampleTypeCode === "FORMULATED_SAMPLE") {
        const materialNames: string[] = [];
        aliquot?.sample?.sampleFormulations?.forEach(sf => {
          sf.materialCompositions?.forEach(mc => {
            if (mc.material && !materialIds.includes(mc.material.id)) {
              materialIds.push(mc.material.id);
              materialNames.push(mc.material.name ?? "");
            }
            const sequence = get(
              mc,
              "material.polynucleotideMaterialSequence"
            ) as PolynucleotideMaterialSequence;
            if (sequence) {
              if (sequenceNameToUse) sequenceNameToUse += ",";
              if (!sequenceMap[sequence.hash!]) {
                sequenceMap[sequence.hash!] = sequence;
              }
              sequenceNameToUse += sequenceMap[sequence.hash!].name;
            }
          });
        });
        materialName = materialNames.join(", ");
      } else {
        if (sample?.material) {
          if (sample.material.materialTypeCode === "DNA") {
            if (!isTube) {
              someDnaPlates = true;
            }
          } else if (sample.material.materialTypeCode === "MICROBIAL") {
            if (isTube) {
              tubeHasMicrobial = true;
            } else {
              someMicrobialPlates = true;
            }
          }
          materialName = sample.material.name;
          materialIds.push(sample.material.id);
        }
      }

      let dataRow;
      const materialTypeCode = sample?.material?.materialTypeCode;
      if (isTube) {
        if (materialTypeCode === "MICROBIAL") {
          // "TUBE_NAME",
          // "TUBE_BARCODE",
          // "TUBE_LOCATION",
          // "MATERIAL_NAME",
          // "SAMPLE_NAME",
          // "STRAIN_NAME",
          // "PLASMID_NAME",
          // "GB_FILE",
          // "TOTAL_VOLUME",
          // "TOTAL_VOLUMETRIC_UNIT",
          // "MASS",
          // "MASS_UNIT"
          dataRow = [
            aliquotContainer.name,
            get(aliquotContainer, "barcode.barcodeString"),
            get(aliquotContainer, "aliquotContainerPathView.fullPath"),
            materialName,
            sample?.name,
            sample?.material?.strain?.name,
            plasmidNames.join(", "),
            plasmidNames.join(", "),
            // don't give volume if dry
            !aliquot?.isDry ? ifNumCleanNum(aliquot?.volume!) : "",
            aliquot?.volumetricUnitCode,
            aliquot?.isDry ? ifNumCleanNum(aliquot?.mass!) : "",
            aliquot?.massUnitCode
          ];
        } else {
          // "TUBE_NAME",
          // "TUBE_BARCODE",
          // "TUBE_LOCATION",
          // "SAMPLE_NAME",
          // "MATERIAL_NAME",
          // "SEQUENCE_FILE",
          // "SEQUENCE_SIZE",
          // "VOLUME",
          // "VOLUMETRIC_UNIT",
          // "CONCENTRATION",
          // "CONCENTRATION_UNIT",
          // "MASS",
          // "MASS_UNIT",
          // "ALIQUOT_ID",
          // "SAMPLE_ID",
          // "MATERIAL_ID"
          dataRow = [
            aliquotContainer.name,
            get(aliquotContainer, "barcode.barcodeString"),
            get(aliquotContainer, "aliquotContainerPathView.fullPath"),
            sample?.name,
            materialName,
            sequenceNameToUse,
            sequenceSize,
            // don't give volume if dry
            !aliquot?.isDry ? ifNumCleanNum(aliquot?.volume!) : "",
            aliquot?.volumetricUnitCode,
            !aliquot?.isDry ? ifNumCleanNum(aliquot?.concentration!) : "",
            aliquot?.concentrationUnitCode,
            aliquot?.isDry ? ifNumCleanNum(aliquot?.mass!) : "",
            aliquot?.massUnitCode,
            aliquot?.id,
            sample?.id,
            materialIds.join(",")
          ];
        }
      } else {
        if (isMicrobialPlate) {
          // const microbialCsvHeaders = [
          //   "PLATE_NAME",
          //   "PLATE_BARCODE",
          //   "TUBE_BARCODE",
          //   "WELL_LOCATION",
          //   "MATERIAL_NAME",
          //   "SAMPLE_NAME",
          //   "STRAIN_NAME",
          //   "PLASMID_NAME",
          //   "GB_FILE",
          //   "TOTAL_VOLUME",
          //   "TOTAL_VOLUMETRIC_UNIT",
          //   "MASS",
          //   "MASS_UNIT"
          // ];
          dataRow = [
            plate?.name,
            get(plate, "barcode.barcodeString"),
            get(aliquotContainer, "barcode.barcodeString"),
            getAliquotContainerLocation(aliquotContainer as any),
            materialName,
            sample?.name,
            sample?.material?.strain?.name,
            plasmidNames.join(", "),
            plasmidNames.join(", "),
            // don't give volume if dry
            !aliquot?.isDry ? ifNumCleanNum(aliquot?.volume!) : "",
            aliquot?.volumetricUnitCode,
            aliquot?.isDry ? ifNumCleanNum(aliquot?.mass!) : "",
            aliquot?.massUnitCode
          ];
        } else {
          // "PLATE_NAME",
          // "PLATE_BARCODE",
          // "WELL",
          // "TUBE_BARCODE",
          // "SAMPLE_NAME",
          // "MATERIAL_NAME",
          // "SEQUENCE_FILE",
          // "SEQUENCE_SIZE",
          // "VOLUME",
          // "VOLUMETRIC_UNIT",
          // "CONCENTRATION",
          // "CONCENTRATION_UNIT"
          // "MASS",
          // "MASS_UNIT",
          // "ALIQUOT_ID",
          // "SAMPLE_ID",
          // "MATERIAL_ID"
          dataRow = [
            plate?.name,
            get(plate, "barcode.barcodeString"),
            getAliquotContainerLocation(aliquotContainer as any),
            get(aliquotContainer, "barcode.barcodeString"),
            sample?.name,
            materialName,
            sequenceNameToUse,
            sequenceSize,
            // don't give volume if dry
            !aliquot?.isDry ? ifNumCleanNum(aliquot?.volume!) : "",
            aliquot?.volumetricUnitCode,
            !aliquot?.isDry ? ifNumCleanNum(aliquot?.concentration!) : "",
            aliquot?.concentrationUnitCode,
            aliquot?.isDry ? ifNumCleanNum(aliquot?.mass!) : "",
            aliquot?.massUnitCode,
            aliquot?.id,
            sample?.id,
            materialIds.join(",")
          ];
        }
      }

      if (aliquot?.id) {
        aliquot.additives?.forEach(additive =>
          addAdditiveToRow(additive as Additive, dataRow)
        );
      } else {
        aliquotContainer?.additives?.forEach(additive =>
          addAdditiveToRow(additive as Additive, dataRow)
        );
      }
      const numAdditives = aliquot?.id
        ? (aliquot.additives?.length as number)
        : (aliquotContainer?.additives?.length as number);
      if (numAdditives > maxNumAdditives) maxNumAdditives = numAdditives;
      if (dataRow.length > longestRow) longestRow = dataRow.length;
      if (isTube) {
        tubeHasMicrobial
          ? microbialTubeCsvRows.push(dataRow)
          : tubeCsvRows.push(dataRow);
      } else {
        isMicrobialPlate
          ? microbialCsvRows.push(dataRow)
          : dnaCsvRows.push(dataRow);
      }
    });
    if (isDnaPlate && isMicrobialPlate) {
      throw new Error(
        `Plate ${plate?.name} (${
          plate?.barcode?.barcodeString || "empty barcode"
        }) has both DNA and Microbial materials. Mixed plates can not be exported.`
      );
    }
    if (isMicrobialPlate) {
      microbialPlates.push(plate!);
    } else if (!isTube) {
      // default for plates
      dnaPlates.push(plate!);
    }
  };

  let fileNameToUse;
  if (isTube) {
    addContainersData({ aliquotContainers });
    fileNameToUse =
      aliquotContainers.length === 1 ? aliquotContainers[0].name : "tubes";
  } else {
    const partialPlates = await safeQuery(containerArrayExportFragment, {
      variables: {
        filter: {
          id: containersIds
        }
      }
    });
    const groupedAliquotContainers = aliquotContainers.reduce(
      (acc, ac) => {
        if (!acc[ac.containerArrayId!]) acc[ac.containerArrayId!] = [];
        acc[ac.containerArrayId!].push(ac);
        return acc;
      },
      {} as { [key: string]: AliquotContainerWithAliquot[] }
    );
    const plates = partialPlates.map(plate => ({
      ...plate,
      aliquotContainers: groupedAliquotContainers[plate.id] || []
    }));
    plates.forEach(plate => {
      const sortedAliquotContainers = sortAliquotContainers(
        plate.aliquotContainers,
        sorting
      );
      addContainersData({
        aliquotContainers: sortedAliquotContainers,
        plate
      });
    });
    fileNameToUse = plates.length === 1 ? plates[0].name : "plates";
  }

  const files = [];

  const padRowsAndAddReagentHeaders = (rows: any[], headers: string[]) => {
    const csvRows = isTube ? tubeCsvRows : dnaCsvRows;
    csvRows.forEach(row => {
      const extra = longestRow - row.length;
      if (extra > 0) {
        times(extra, () => {
          row.push("");
        });
      }
    });

    let allHeaders = headers;
    // because some rows may have more additives than others we need to pad rows to keep row length the same
    if (maxNumAdditives > 0) {
      times(maxNumAdditives, () => {
        allHeaders = allHeaders.concat(defaultReagentHeaders);
      });
    }
    return allHeaders;
  };

  if (dnaPlates.length) {
    const allHeaders = padRowsAndAddReagentHeaders(dnaCsvRows, dnaCsvHeaders);
    dnaCsvRows.unshift(allHeaders);
    const csvData = unparse(dnaCsvRows);

    const dnaPlateFileName =
      dnaPlates.length === 1 ? dnaPlates[0].name : "dna plates";
    files.push({
      name: `${dnaPlateFileName}.csv`,
      data: csvData
    });
  }
  if (microbialPlates.length) {
    const allHeaders = padRowsAndAddReagentHeaders(
      microbialCsvRows,
      microbialCsvHeaders
    );

    microbialCsvRows.unshift(allHeaders);
    const csvData = unparse(microbialCsvRows);

    const microbialPlateFileName =
      microbialPlates.length === 1
        ? microbialPlates[0].name
        : "microbial plates";
    files.push({
      name: `${microbialPlateFileName}.csv`,
      data: csvData
    });
  }
  if (isTube) {
    let csvData;
    if (tubeHasMicrobial) {
      const allHeaders = padRowsAndAddReagentHeaders(
        microbialTubeCsvRows,
        tubeMicrobialCsvHeaders
      );
      microbialTubeCsvRows.unshift(allHeaders);
      csvData = unparse(microbialTubeCsvRows);
    } else {
      const allHeaders = padRowsAndAddReagentHeaders(
        tubeCsvRows,
        tubeCsvHeaders
      );
      tubeCsvRows.unshift(allHeaders);
      csvData = unparse(tubeCsvRows);
    }

    const tubesFileName =
      aliquotContainers.length === 1 ? aliquotContainers[0].name : "tubes";
    files.push({
      name: `${tubesFileName}.csv`,
      data: csvData
    });
  }
  Object.values(sequenceMap).forEach(sequence => {
    const sequenceFileContents = getSequenceInFileType(
      sequence,
      sequenceFileType
    );
    files.push({
      name: `${sequence.name}.${sequenceFileTypeToExt(sequenceFileType)}`,
      data: sequenceFileContents
    });
  });

  return { files, fileNameToUse, someDnaPlates, someMicrobialPlates };
}

export async function exportPlatesOrTubes(
  containersToExportOrIds:
    | { id: string }
    | { id: string }[]
    | string
    | string[],
  {
    sorting = "rowFirst",
    sequenceFileType,
    isTube
  }: {
    sorting?: "rowFirst" | "columnFirst";
    sequenceFileType: "genbank" | "fasta" | "json";
    isTube?: boolean;
  }
) {
  const clearProgressToast = showProgressToast(
    isTube ? "Exporting Tubes..." : "Exporting Plates..."
  );

  let containersIds: string[] = [];

  if (!Array.isArray(containersToExportOrIds)) {
    containersIds = [
      typeof containersToExportOrIds === "string"
        ? containersToExportOrIds
        : containersToExportOrIds.id
    ];
  } else {
    containersIds = containersToExportOrIds.map(p => {
      return typeof p === "string" ? p : p.id;
    });
  }

  try {
    const { files, someDnaPlates, someMicrobialPlates, fileNameToUse } =
      await platesOrTubesToCSV(containersIds, {
        sorting,
        sequenceFileType,
        isTube
      });

    if (someDnaPlates && someMicrobialPlates) {
      window.toastr.warning(
        "There were both microbial and dna plates exported. These will need to be imported separately through respective uploads."
      );
    }
    const zipFile = await handleZipFiles(files);
    download(zipFile, `${fileNameToUse}.zip`, "application/zip");
  } catch (error) {
    throw error;
  } finally {
    clearProgressToast();
  }
}
