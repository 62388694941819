/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import HeaderWithHelper from "../../../../src-shared/HeaderWithHelper";
import PrepFields from "./PrepFields";

function PrepFieldsHelper({
  items,
  prepNewPlateButton,
  isTube,
  platesToPrep,
  toolIntegrationProps,
  addPlateFilter,
  selectedWellsForPlate,
  universalTransfers,
  renderWells,
  containerArrayTypes,
  generateBarcode
}) {
  let headerMessage, helperMessage;
  const isLot = items[0].__typename === "lot";

  if (isLot) {
    headerMessage = "Transfer from Reagent Lots";
    helperMessage = `Map the contents of each reagent lot to one or more new
    or existing plates. Choose the name, plate type and barcode for the
    intermediate container that will be used to transfer the lot's contents.`;
  } else if (isTube) {
    headerMessage = "Transfer Reagents from Tubes";
    helperMessage =
      "Map the contents of each tube to one or more new or existing plates.";
  } else {
    headerMessage = "Transfer Reagents from Plates or Racks";
    helperMessage =
      "Map the contents of each tube or plate well to one or more new or existing plates.";
  }

  return (
    <div className="tg-step-form-section column">
      <div className="tg-flex justify-space-between">
        <HeaderWithHelper header={headerMessage} helper={helperMessage} />
        {prepNewPlateButton}
      </div>
      {items.map((item, i) => {
        return (
          <PrepFields
            key={i}
            aliquotContainer={!isLot ? item : undefined}
            lot={isLot ? item : undefined}
            isLast={i === items.length - 1}
            isTube={isTube}
            platesToPrep={platesToPrep}
            toolIntegrationProps={toolIntegrationProps}
            addPlateFilter={addPlateFilter}
            selectedWellsForPlate={selectedWellsForPlate}
            universalTransfers={universalTransfers}
            renderWells={renderWells}
            containerArrayTypes={containerArrayTypes}
            generateBarcode={generateBarcode}
          />
        );
      })}
    </div>
  );
}

export default PrepFieldsHelper;
