/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { Children, useRef, useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import useStyle from "substyle";

import { countSuggestions } from "./utils";
import Suggestion from "./Suggestion";
import LoadingIndicator from "./LoadingIndicator";

const defaultStyle = {
  position: "absolute",
  zIndex: 1,
  backgroundColor: "white",
  marginTop: 14,
  minWidth: 100,
  list: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  }
};

const getID = suggestion => {
  if (suggestion instanceof String) {
    return suggestion;
  }

  return suggestion.id;
};

const SuggestionsOverlay = forwardRef(
  (
    {
      ignoreAccents,
      suggestions = {},
      onSelect = () => null,
      children,
      style: _style,
      focusIndex,
      scrollFocusedIntoView,
      isLoading,
      onMouseEnter,
      onMouseDown,
      position
    },
    ref
  ) => {
    const style = useStyle(defaultStyle, { style: _style }, { ...position });
    const suggestionsRef = useRef(null);

    useEffect(() => {
      if (
        !suggestionsRef.current ||
        suggestionsRef.current.offsetHeight >=
          suggestionsRef.current.scrollHeight ||
        !scrollFocusedIntoView
      ) {
        return;
      }

      const scrollTop = suggestionsRef.current.scrollTop;
      let { top, bottom } =
        suggestionsRef.current.children[focusIndex].getBoundingClientRect();
      const { top: topContainer } =
        suggestionsRef.current.getBoundingClientRect();
      top = top - topContainer + scrollTop;
      bottom = bottom - topContainer + scrollTop;

      if (top < scrollTop) {
        suggestionsRef.current.scrollTop = top;
      } else if (bottom > suggestionsRef.current.offsetHeight) {
        suggestionsRef.current.scrollTop =
          bottom - suggestionsRef.current.offsetHeight;
      }
    });

    const renderSuggestion = (result, queryInfo, index) => {
      const id = getID(result);
      const isFocused = index === focusIndex;
      const { childIndex, query } = queryInfo;
      const { renderSuggestion } = Children.toArray(children)[childIndex].props;

      return (
        <Suggestion
          style={style("item")}
          key={`${childIndex}-${id}`}
          id={id}
          query={query}
          index={index}
          ignoreAccents={ignoreAccents}
          renderSuggestion={renderSuggestion}
          suggestion={result}
          focused={isFocused}
          onClick={() => onSelect(result, queryInfo)}
          onMouseEnter={() => onMouseEnter && onMouseEnter(index)}
        />
      );
    };

    const renderSuggestions = () =>
      Object.values(suggestions).reduce(
        (accResults, { results, queryInfo }) => [
          ...accResults,
          ...results.map((result, index) =>
            renderSuggestion(result, queryInfo, accResults.length + index)
          )
        ],
        []
      );

    const renderLoadingIndicator = () => {
      if (!isLoading) {
        return;
      }

      return <LoadingIndicator style={style("loadingIndicator")} />;
    };

    // do not show suggestions until there is some data
    if (countSuggestions(suggestions) === 0 && !isLoading) {
      return null;
    }

    return (
      <div ref={ref} {...style} onMouseDown={onMouseDown}>
        <ul ref={suggestionsRef} {...style("list")}>
          {renderSuggestions()}
        </ul>

        {renderLoadingIndicator()}
      </div>
    );
  }
);

SuggestionsOverlay.propTypes = {
  suggestions: PropTypes.object.isRequired,
  focusIndex: PropTypes.number,
  scrollFocusedIntoView: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSelect: PropTypes.func,
  ignoreAccents: PropTypes.bool,

  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
};

export default SuggestionsOverlay;
