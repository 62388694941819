/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { useCallback } from "react";
import { reduxForm } from "redux-form";
import { uniqBy } from "lodash";
import { Classes, Intent, Button } from "@blueprintjs/core";
import { DataTable, DialogFooter, wrapDialog } from "@teselagen/ui";
import { CheckboxField } from "@teselagen/ui";
import withLibraryExtendedPropertyColumns from "../../../../src-shared/enhancers/withLibraryExtendedPropertyColumns";

import "./style.css";
import { withTableParams } from "@teselagen/ui";
import withQuery from "../../../../src-shared/withQuery";
import { compose } from "redux";
import { connect } from "react-redux";
import { uniq } from "lodash";
import { formValueSelector } from "redux-form";
import {
  getSetsValidatingSet,
  getRegexOfValidatorGeneric
} from "../../../../src-shared/selectors/validationSetSelectors";
import {
  toJsRegexStr,
  toRevCompJsRegexStr
} from "../../../../../tg-iso-shared/src/utils/validationSetUtils";
import withGetPartIdsByRegex from "../../../graphql/enhancers/withGetPartIdsByRegex";
import {
  getItemOfType,
  getReferencedValue
} from "../../../../../tg-iso-design/selectors/designStateSelectors";
import actions from "../../../../src-shared/redux/actions";
import {
  getArfJunctionBps,
  getJunctionBpsOfBin,
  doesAdjacentBinHaveArf,
  getShowCompatiblePartsFilter
} from "../../../utils/stateUtils";
import { tagColumnWithRender } from "../../../../src-shared/utils/tagColumn";
import gql from "graphql-tag";
import { addTagFilterToQuery } from "../../../../src-shared/utils/tagUtils";
import {
  annotationSizeStartEndColumns,
  dateModifiedColumn
} from "../../../../src-shared/utils/libraryColumns";
import { addActiveProjectFilter } from "../../../../src-shared/utils/projectUtils";
import { getTagRules } from "../../../../../tg-iso-design/utils/doesSequenceStringPassRule";
import { getTagKey } from "../../../../../tg-iso-shared/src/tag-utils";
import { libraryExtendedStringValues } from "../../../../src-shared/libraryEnhancer";
import { withProps } from "recompose";
import { taggedItemFragment } from "../../../../../tg-iso-shared/src/fragments/taggedItemFragment";
import { combineGqlFragments } from "../../../../../tg-iso-shared/utils/gqlUtils";
import { showInsertBasePairLiteralDialog } from "../InsertBasePairLiteralDialog";

let debugARFLog = () => {};
if (window.frontEndConfig.logDesignARFActions) {
  debugARFLog = console.info;
}

class PartLibraryDialog extends React.Component {
  state = {
    parts: [],
    submitting: false
  };

  handleSelect = parts => {
    let sortedParts = parts;
    const { tableParams } = this.props;
    if (Array.isArray(sortedParts) && tableParams?.entities) {
      const entityIds = tableParams.entities.map(e => e.id);
      sortedParts = [...sortedParts].sort((a, b) => {
        if (entityIds.includes(a.id)) {
          return entityIds.indexOf(a.id) - entityIds.indexOf(b.id);
        } else {
          return -1;
        }
      });
    }
    this.setState({
      parts: uniqBy(this.state.parts.concat(sortedParts), "id")
    });
  };

  handleDoubleClick = async part => {
    const { validateCompatibleParts, adjacentBinHasArf } = this.props;
    this.setState({ submitting: true });
    await this.props.onSubmit([part], {
      validateCompatibleParts,
      adjacentBinHasArf
    });
    this.props.hideModal();
  };

  render() {
    const {
      tableParams,
      hideModal,
      partsCount,
      onSubmit,
      isSingleSelect,
      withCheckboxes = true,
      canValidationFilter,
      tagRules,
      basePairLiteralOption,
      cardId,
      binId,
      toggleCompatiblePartsValidation,
      validateCompatibleParts,
      fivePrimeJunctionBps = "",
      threePrimeJunctionBps = "",
      showCompatiblePartsFilter,
      adjacentBinHasArf,
      selectedEntities
    } = this.props;
    const { parts, submitting } = this.state;

    let entitiesToUse = tableParams.entities || [];
    const { currentParams, setNewParams } = tableParams;

    let invalidCompatiblePartsCount = 0;
    if (validateCompatibleParts) {
      entitiesToUse = entitiesToUse.filter(entity => {
        if (
          fivePrimeJunctionBps &&
          threePrimeJunctionBps &&
          !adjacentBinHasArf
        ) {
          // the part's sequence's size must be equal to the part length + the overlaps
          const fivePrimeJunctionLength = fivePrimeJunctionBps
            ? fivePrimeJunctionBps.length
            : 0;
          const threePrimeJunctionLength = threePrimeJunctionBps
            ? threePrimeJunctionBps.length
            : 0;

          if (
            entity.sequence.size !==
            fivePrimeJunctionLength + entity.size + threePrimeJunctionLength
          ) {
            debugARFLog(
              `${entity.name}'s sequence size (${
                entity.sequence.size
              }) didn't match part length + overhang lengths (${
                fivePrimeJunctionLength + entity.size + threePrimeJunctionLength
              })`
            );
            invalidCompatiblePartsCount++;
            return false;
          }
        }

        return true;
      });
    }

    return (
      <React.Fragment>
        <div className={Classes.DIALOG_BODY}>
          <DataTable
            formName="PartLibraryDialog"
            {...tableParams}
            entities={entitiesToUse}
            isSingleSelect={isSingleSelect}
            entityCount={partsCount - invalidCompatiblePartsCount}
            withSearch
            withPaging
            withDisplayOptions
            canOutsideClickClose={false}
            isInfinite={false}
            onSingleRowSelect={this.handleSelect}
            onMultiRowSelect={this.handleSelect}
            onDoubleClick={this.handleDoubleClick}
            withCheckboxes={withCheckboxes}
          >
            {!!canValidationFilter && (
              <CheckboxField
                label="Apply validation group filter"
                name="applyValidationFilter"
              />
            )}
            {tagRules && (
              <CheckboxField
                style={{ marginLeft: 12 }}
                label="Apply tag rules filter"
                name="applyTagRulesFilter"
                defaultValue={!currentParams.ignoreTagRules}
                onFieldSubmit={val => {
                  setNewParams({
                    ...currentParams,
                    ignoreTagRules: !val
                  });
                }}
              />
            )}
          </DataTable>
        </div>
        <div className="bp3-dialog-footer">
          <div
            className="bp3-dialog-footer-actions tg-flex justify-space-between"
            style={{ position: "relative" }}
          >
            {!!basePairLiteralOption && (
              <Button
                minimal
                intent={Intent.PRIMARY}
                text="Insert Base Pairs"
                onClick={showInsertBasePairLiteralDialog}
              />
            )}
            <CheckboxField
              label="Only show available parts"
              name="filterMaterialAvailability"
            />
            {cardId && binId && showCompatiblePartsFilter && (
              <CheckboxField
                label="Filter for already-compatible parts"
                name="filterAlreadyCompatibleParts"
                onChange={toggleCompatiblePartsValidation}
                defaultValue={validateCompatibleParts}
              />
            )}
            <DialogFooter
              hideModal={hideModal}
              text="OK"
              disabled={!parts.length}
              loading={submitting}
              onClick={async () => {
                this.setState({ submitting: true });
                const stillSelectedPartIds = selectedEntities.map(
                  ent => ent.id
                );
                debugARFLog("kc_adjacentBinHasArf", adjacentBinHasArf);

                await onSubmit(
                  parts.filter(
                    part => stillSelectedPartIds.indexOf(part.id) > -1
                  ),
                  {
                    validateCompatibleParts,
                    adjacentBinHasArf
                  }
                );

                hideModal();
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const schema = {
  model: "part",
  fields: [
    { path: "id", type: "string", displayName: "Id", isHidden: true },
    { path: "name", type: "string", displayName: "Name" },
    tagColumnWithRender,
    ...annotationSizeStartEndColumns,
    { path: "sequence.name", type: "string", displayName: "Source" },
    dateModifiedColumn
  ]
};

const mapDispatchToProps = {
  toggleCompatiblePartsValidation:
    actions.ui.designEditor.inspector.toggleCompatiblePartsValidation
};

const mapStateToProps = (
  state,
  {
    binId,
    cardId,
    setId,
    firstPreviousPart,
    firstNextPart,
    previousArfPart,
    nextArfPart
  }
) => {
  const junctionBps = getJunctionBpsOfBin({ state, binId });

  let tagRules;
  if (binId) {
    const binRuleSets = getReferencedValue(state, "bin", binId, "binRuleSets");
    tagRules = { required: [], restricted: [], atLeastOneOf: [] };
    binRuleSets.forEach(({ ruleSetId }) => {
      const rules = getReferencedValue(state, "ruleSet", ruleSetId, "rules");
      rules.forEach(rule => {
        if (!rule.regex) {
          const { required, restricted, atLeastOneOf } = getTagRules(
            state,
            rule.id
          );
          tagRules.required.push(...required.map(getTagKey));
          tagRules.restricted.push(...restricted.map(getTagKey));
          tagRules.atLeastOneOf.push(...atLeastOneOf.map(getTagKey));
        }
      });
    });
    // if no tag rules unset
    if (Object.keys(tagRules).every(key => !tagRules[key].length)) {
      tagRules = undefined;
    }
  }

  const injections = {
    filterMaterialAvailability: !!selector(state, "filterMaterialAvailability"),
    validateCompatibleParts:
      state.ui.designEditor.inspector.validateCompatibleParts,
    fivePrimeArfJunctionBps: getArfJunctionBps({
      state,
      binId,
      cardId,
      isFivePrimeJunction: true,
      neighborPart: firstPreviousPart,
      neighborArfPart: previousArfPart
    }),
    threePrimeArfJunctionBps: getArfJunctionBps({
      state,
      binId,
      cardId,
      isFivePrimeJunction: false,
      neighborPart: firstNextPart,
      neighborArfPart: nextArfPart
    }),
    fivePrimeJunctionBps: junctionBps.fivePrimeBps,
    threePrimeJunctionBps: junctionBps.threePrimeBps,
    adjacentBinHasArf: doesAdjacentBinHaveArf(state, binId, cardId),
    showCompatiblePartsFilter: getShowCompatiblePartsFilter(
      state,
      binId,
      cardId
    ),
    tagRules
  };

  if (setId) {
    const set = getItemOfType(state, "set", setId);
    const validators = getSetsValidatingSet(state, setId);

    let startRegexes = [];
    let endRegexes = [];

    for (const validator of validators) {
      startRegexes.push(getRegexOfValidatorGeneric(state, validator.id, true));
      endRegexes.push(getRegexOfValidatorGeneric(state, validator.id, false));
    }
    const toRegex = set.direction ? toJsRegexStr : toRevCompJsRegexStr;
    startRegexes = uniq(startRegexes.filter(x => x).map(r => toRegex(r)));
    endRegexes = uniq(endRegexes.filter(x => x).map(r => toRegex(r)));

    Object.assign(injections, {
      startRegexes: set.direction ? startRegexes : endRegexes,
      endRegexes: set.direction ? endRegexes : startRegexes,
      canValidationFilter: startRegexes.length || endRegexes.length,
      applyValidationFilter: !!selector(state, "applyValidationFilter")
    });
  }

  return injections;
};

const selector = formValueSelector("partLibraryDialog");

export let partLibraryDialogFragment = gql`
  fragment partLibraryDialogFragment on part {
    id
    name
    createdAt
    updatedAt
    start
    end
    size
    lab {
      id
      name
    }
    taggedItems {
      ...taggedItemFragment
    }
    sequence {
      id
      size
      name
      designMaterialAvailabilityView {
        id
        isAvailable
      }
    }
    bps5Prime
    bps3Prime
    bpsUpStream
    bpsDownStream
  }
  ${taggedItemFragment}
`;

partLibraryDialogFragment = combineGqlFragments([
  partLibraryDialogFragment,
  libraryExtendedStringValues
]);

export default compose(
  withProps(({ hideModal, resolve }) => {
    return {
      hideModal: () => {
        hideModal();
        if (resolve) resolve();
      }
    };
  }),
  wrapDialog(props => {
    return {
      title: props.title || "Choose Part(s)",
      style: { width: 950 },
      className: "compact-lib-dialog"
    };
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withGetPartIdsByRegex({ partIdsKey: "matchingPartIds" }),
  withLibraryExtendedPropertyColumns({
    schema,
    model: "part",
    inDialog: true
  }),
  withProps(props => {
    const {
      tagRules,
      applyValidationFilter,
      matchingPartIds,
      filterMaterialAvailability,
      validateCompatibleParts,
      fivePrimeArfJunctionBps,
      fivePrimeJunctionBps,
      threePrimeArfJunctionBps,
      threePrimeJunctionBps,
      adjacentBinHasArf
    } = props;
    // Added while #12943 is not ready
    const additionalFilter = useCallback(
      (_, qb, currentParams) => {
        addTagFilterToQuery(currentParams.tags, qb);
        addActiveProjectFilter(qb, {
          model: "part"
        });
        qb.whereAll(
          applyValidationFilter ? { id: matchingPartIds } : "",
          filterMaterialAvailability
            ? { "sequence.designMaterialAvailabilityView.isAvailable": true }
            : ""
        );
        qb.whereAny({
          "sequence.isInLibrary": true
        });

        // add tag design ruleset filter
        if (tagRules && !currentParams.ignoreTagRules) {
          if (tagRules.restricted.length) {
            addTagFilterToQuery(tagRules.restricted, qb, {
              not: true,
              model: "part"
            });
          }
          if (tagRules.required.length) {
            addTagFilterToQuery(tagRules.required, qb);
          }
          if (tagRules.atLeastOneOf.length) {
            addTagFilterToQuery(tagRules.atLeastOneOf, qb, {
              atLeastOneOf: true
            });
          }
        }

        debugARFLog("validateCompatibleParts", validateCompatibleParts);
        debugARFLog("adjacentBinHasArf", adjacentBinHasArf);
        debugARFLog("fivePrimeArfJunctionBps", fivePrimeArfJunctionBps);
        debugARFLog("threePrimeArfJunctionBps", threePrimeArfJunctionBps);

        if (validateCompatibleParts) {
          if (adjacentBinHasArf) {
            if (fivePrimeArfJunctionBps && threePrimeArfJunctionBps) {
              qb.whereAll({
                bps5Prime: qb.startsWith(fivePrimeArfJunctionBps),
                bps3Prime: qb.endsWith(threePrimeArfJunctionBps)
              });
            } else if (fivePrimeArfJunctionBps) {
              qb.whereAll({
                bps5Prime: qb.startsWith(fivePrimeArfJunctionBps)
              });
            } else if (threePrimeArfJunctionBps) {
              qb.whereAll({
                bps3Prime: qb.endsWith(threePrimeArfJunctionBps)
              });
            }
          } else {
            if (fivePrimeArfJunctionBps && threePrimeArfJunctionBps) {
              qb.whereAll({
                bpsUpStream: qb.endsWith(fivePrimeArfJunctionBps),
                bpsDownStream: qb.startsWith(threePrimeArfJunctionBps),
                "sequence.circular": false
              });
            } else if (fivePrimeArfJunctionBps) {
              qb.whereAll({
                bpsUpStream: qb.endsWith(fivePrimeArfJunctionBps),
                "sequence.circular": false
              });
            } else if (threePrimeArfJunctionBps) {
              qb.whereAll({
                bpsDownStream: qb.startsWith(threePrimeArfJunctionBps),
                "sequence.circular": false
              });
            } // below here is for non-ARF filtering
            else if (fivePrimeJunctionBps && threePrimeJunctionBps) {
              qb.whereAll({
                bps5Prime: qb.startsWith(fivePrimeJunctionBps),
                bps3Prime: qb.endsWith(threePrimeJunctionBps)
              });
            } else if (fivePrimeJunctionBps) {
              qb.whereAll({
                bps5Prime: qb.startsWith(fivePrimeJunctionBps)
              });
            } else if (threePrimeJunctionBps) {
              qb.whereAll({
                bps3Prime: qb.endsWith(threePrimeJunctionBps)
              });
            }
          }
        }
      },
      [
        adjacentBinHasArf,
        applyValidationFilter,
        filterMaterialAvailability,
        fivePrimeArfJunctionBps,
        fivePrimeJunctionBps,
        matchingPartIds,
        tagRules,
        threePrimeArfJunctionBps,
        threePrimeJunctionBps,
        validateCompatibleParts
      ]
    );
    return {
      additionalFilter
    };
  }),
  withTableParams({
    urlConnected: false,
    schema: undefined,
    formName: "partLibraryDialog",
    withSelectedEntities: true,
    tableParams: {
      cellRenderer: {
        start: index => index + 1,
        end: index => index + 1
      }
    },
    withDisplayOptions: true
  }),
  withQuery(partLibraryDialogFragment, {
    isPlural: true
  }),
  withProps(props => {
    return {
      tableParams: {
        ...props.tableParams,
        isLoading:
          props.tableParams.isLoading || props.extendedPropertiesLoading
      }
    };
  }),
  reduxForm({
    form: "partLibraryDialog",
    enableReinitialize: true
  })
)(PartLibraryDialog);
