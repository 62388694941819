/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import PcrBlock from "./structs/PcrBlock";

interface PCRReaction {
  optimal_annealing_temperature: number;
}

/**
 * Identify which block and zone to place the next pcr reaction in.
 */
export const identifyBlockAndZoneForNextPcrReaction = (
  blocks: PcrBlock[],
  pcrReaction: PCRReaction
) => {
  // Set the selected block to undefined to indicate that we haven't assigned a
  // block/zone to the pcr reaction yet
  let selectedBlock: PcrBlock | undefined;
  let selectedZone: number | undefined;
  let minAbsDeltaTemp: number | undefined;

  blocks.forEach(block => {
    // If this block isn't already full.
    if (block.full) return;

    for (let j = 0, jj = block.n_zones; j < jj; j++) {
      // If there is an available well in this zone.
      if (
        !block.zone_reaction_list[j] ||
        block.zone_reaction_list[j].length < block.wells_per_zone
      ) {
        const deltaTemp =
          block.zone_temperature[j] - pcrReaction.optimal_annealing_temperature;

        // Check if either no zone has already been selected for this pcr reaction, or
        // if this zone is the best fit so far for this pcr reaction.
        if (
          !selectedBlock ||
          minAbsDeltaTemp === undefined ||
          Math.abs(deltaTemp) < minAbsDeltaTemp
        ) {
          selectedBlock = block;
          selectedZone = j;
          minAbsDeltaTemp = Math.abs(deltaTemp);
        }
      }
    }
  });

  return { selectedBlock, selectedZone };
};

/**
 * Preserve the pcr reaction distribution (filled wells) across the blocks and re-order the reactions
 * to keep the target temperatures monotonically arranged
 */
export const reorderPcrReactionsMonotonicallyInPlace = (
  blocks: PcrBlock[],
  pcrReactions: PCRReaction[]
) => {
  let currentPcrReactionIndex = 0;

  blocks.forEach(block => {
    block.zone_reaction_list.forEach(zoneReactions => {
      for (let k = 0, kk = zoneReactions.length; k < kk; k++) {
        zoneReactions[k] = pcrReactions[currentPcrReactionIndex++];
      }
    });
  });
};

/**
 * Randomly select block, zone(s), and temperature to adjust.
 */
export const selectBlockZonesAndTemperatureToAdjust = (
  blocks: PcrBlock[],
  minOptTemp: number,
  maxOptTemp: number,
  downstreamAutomationParameters: { trial_delta_temperature: number }
): {
  trialBlock: PcrBlock;
  trialZones: number[];
  trialTempChange: number;
} => {
  const { trial_delta_temperature } = downstreamAutomationParameters;

  // Choose a block at random.
  const trialBlock = blocks[Math.floor(Math.random() * blocks.length)];

  // Choose a zone at random.
  const trialZones = [Math.floor(Math.random() * trialBlock.n_zones)];

  // Choose a temperature change of either -0.1 or 0.1 at random.
  let trialTempChange;
  if (
    (Math.random() < 0.5 &&
      trialBlock.zone_temperature[trialZones[0]] > minOptTemp) ||
    trialBlock.zone_temperature[trialZones[0]] >= maxOptTemp
  ) {
    trialTempChange = -trial_delta_temperature;
  } else {
    trialTempChange = trial_delta_temperature;
  }

  // Check to see if changing this zone by the trial temperature change will demand changing the temperature of
  // the neighboring zone(s) as well.

  // Check previous zones.
  let currentZone = trialZones[0];

  // While the current zone is not the first zone
  while (currentZone !== 0) {
    // Check to see if changing this zone will result in exceeding the delta temperature between adjacent zones
    // or will result in a temperature lower than the preceeding zone.
    const currentZoneTemp = trialBlock.zone_temperature[currentZone];
    const prevZoneTemp = trialBlock.zone_temperature[currentZone - 1];
    if (
      currentZoneTemp + trialTempChange - prevZoneTemp >
        trialBlock.max_delta_temperature_adjacent_zones +
          Math.abs(trial_delta_temperature) / 2.0 ||
      currentZoneTemp + trialTempChange < prevZoneTemp
    ) {
      trialZones.push(--currentZone);
    } else {
      break;
    }
  }

  // Check following zones.

  // While the current zone is not the last zone.
  while (currentZone !== trialBlock.n_zones - 1) {
    // Check to see if changing this zone will result in exceeding the delta temperature between adjacent zones
    // or will result in a temperature greater than the next zone.
    const currentZoneTemp = trialBlock.zone_temperature[currentZone];
    const nextZoneTemp = trialBlock.zone_temperature[currentZone + 1];
    if (
      nextZoneTemp - (currentZoneTemp + trialTempChange) >
        trialBlock.max_delta_temperature_adjacent_zones +
          Math.abs(trial_delta_temperature) / 2.0 ||
      currentZoneTemp + trialTempChange > nextZoneTemp
    ) {
      trialZones.push(++currentZone);
    } else {
      break;
    }
  }

  return { trialBlock, trialZones, trialTempChange };
};

/**
 * Perform a monte carlo move.
 */
export const doMonteCarloMove = (
  blocks: PcrBlock[],
  pcrReactions: PCRReaction[],
  trialBlock: PcrBlock,
  trialZones: number[],
  trialTempChange: number
): void => {
  // Adjust the temperature of the select block zone(s).
  trialZones.forEach(trialZone => {
    trialBlock.zone_temperature[trialZone] += trialTempChange;
  });

  // Fill in the blocks with the pcr reactions.

  // First empty the blocks.
  blocks.forEach(block => block.emptyPcrReactions());

  // Fill in the blocks with the pcr reactions.
  pcrReactions.forEach(pcrReaction => {
    const { selectedBlock, selectedZone } =
      identifyBlockAndZoneForNextPcrReaction(blocks, pcrReaction);
    if (selectedBlock && selectedZone !== undefined)
      selectedBlock.addPcrReactionToZone(pcrReaction, selectedZone);
  });

  reorderPcrReactionsMonotonicallyInPlace(blocks, pcrReactions);
};
