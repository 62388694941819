/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback } from "react";
import { CheckboxField, InputField, RadioGroupField } from "@teselagen/ui";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import UnitInputField from "../../../UnitInputField";
import PlateUploadFields from "../../../PlateUploadFields";
import PlatePreview from "./PlatePreview";
import defaultValueConstants from "../../../../../../tg-iso-shared/src/defaultValueConstants";
import PlateMapPlate from "../../../PlateMapPlate";
import { pick } from "lodash";
import { generateContainerArray } from "../../../../../../tg-iso-lims/src/utils/plateUtils";
import { useDispatch } from "react-redux";
import { change as _change } from "redux-form";
import { useFormValue } from "../../../../../src-shared/hooks/useFormValue";

const destinationTransferOrderOptions = [
  { label: "Row First", value: "rowFirst" },
  { label: "Column First", value: "columnFirst" }
];

const sourceTransferOrderOptions = [
  { label: "Row First", value: "rowFirst" },
  { label: "Column First", value: "columnFirst" }
];

const NormalizationParameters = ({ toolSchema: { code } }) => {
  const dispatch = useDispatch();
  const change = useCallback(
    (field, value) => dispatch(_change(code, field, value)),
    [code, dispatch]
  );

  const generateBarcodes = useFormValue(code, "generateBarcodes");
  const normalizationType = useFormValue(code, "normalizationType");
  const selectedWellsForPlates =
    useFormValue(code, "selectedWellsForPlates") || {};
  const containerArrayType = useFormValue(code, "containerArrayType");
  const sourceTransferOrder = useFormValue(code, "sourceTransferOrder");
  const destinationTransferOrder = useFormValue(
    code,
    "destinationTransferOrder"
  );
  const transferSourcePlateToNormalizedPlate = useFormValue(
    code,
    "transferSourcePlateToNormalizedPlate"
  );
  const containerArrayToNormalize = useFormValue(
    code,
    "containerArrayToNormalize"
  );

  const renderSourcePlateToNormalizationPlateTransferVolume = isDry => {
    const shouldShow = transferSourcePlateToNormalizedPlate && !isDry;
    if (shouldShow)
      return (
        <div>
          <div className="tg-step-form-section">
            <HeaderWithHelper
              header="Desired Transfer Volume"
              helper="Enter the  desired transfer volume of materials from the selected plate/rack to a new destination plate/rack for normalization."
            />
            <UnitInputField
              name="desiredTransferVolume"
              isRequired
              label="Volume"
              unitName="desiredTransferVolumetricUnitCode"
              unitDefault="uL"
              unitType="volumetricUnit"
            />
          </div>
          <div className="tg-step-form-section">
            <HeaderWithHelper
              header="Destination Plate Info"
              helper="Enter a name for the new destination plate or rack. Check the box below if you would like to automatically generate a barcode."
            />
            <div style={{ width: "30%" }}>
              <InputField
                name="destinationContainerArrayName"
                isRequired
                label="Plate Name"
                placeholder="Enter destination plate name..."
              />
              <CheckboxField
                name="generateBarcodes"
                label="Generate Barcodes"
                defaultValue={
                  generateBarcodes === undefined ? true : generateBarcodes
                }
              />
              {!generateBarcodes && (
                <InputField
                  name="destinationContainerArrayBarcode"
                  label="Barcode"
                />
              )}
              <PlateUploadFields
                inTool
                noFileUpload
                noNumTubes
                genericSelectOptions={{
                  isRequired: true
                }}
              />
              {containerArrayType &&
                containerArrayType.containerFormat.code !==
                  containerArrayToNormalize.containerArrayType.containerFormat
                    .code && (
                  <>
                    <RadioGroupField
                      name="sourceTransferOrder"
                      label="Source Transfer Order"
                      tooltipInfo="This is the order the transfers will be picked off the source plate"
                      defaultValue={
                        sourceTransferOrder === undefined
                          ? "rowFirst"
                          : sourceTransferOrder
                      }
                      options={sourceTransferOrderOptions}
                    />
                    <RadioGroupField
                      name="destinationTransferOrder"
                      tooltipInfo="This is the order the transfers will be placed into the destination plate"
                      label="Destination Transfer Order"
                      defaultValue={
                        destinationTransferOrder === undefined
                          ? "rowFirst"
                          : destinationTransferOrder
                      }
                      options={destinationTransferOrderOptions}
                    />
                  </>
                )}
            </div>
          </div>
        </div>
      );
  };

  const updateLocations = locations => {
    change("selectedWellsForPlates", {
      ...selectedWellsForPlates,
      [containerArrayToNormalize.id]: locations
    });
  };

  const isDry = containerArrayToNormalize.aliquotContainers.every(container => {
    if (container.aliquot && !container.aliquot.isDry) {
      return false;
    } else {
      return true;
    }
  });

  return (
    <>
      <div className="tg-step-form-section column">
        <div style={{ justifyContent: "space-between" }}>
          <HeaderWithHelper
            header={`Normalize ` + containerArrayToNormalize.name}
            helper="By default normalization will be applied to all wells with aliquots. You can narrow selection by selecting wells below."
          />
          <div className="tg-flex">
            <PlateMapPlate
              {...pick(containerArrayToNormalize, ["containerArrayType"])}
              aliquotContainers={generateContainerArray(
                containerArrayToNormalize.aliquotContainers,
                containerArrayToNormalize.containerArrayType.containerFormat
              )}
              withDragSelect
              onWellsSelected={updateLocations}
              selectedAliquotContainerLocations={
                selectedWellsForPlates[containerArrayToNormalize.id] || []
              }
            />
            <div className="tg-flex-separator" />
            <PlatePreview
              containerArray={containerArrayToNormalize}
              tableOnly
              noTitle
            />
          </div>
        </div>
      </div>
      <div className="tg-step-form-section">
        <HeaderWithHelper
          header="Target Concentration"
          helper="Enter the target concentration
              for aliquots of the selected plate
              or rack. Check the box below  if you
              would like your input materials transferred
              to a new plate or rack before normalization."
        />
        <div className="tg-flex column">
          <RadioGroupField
            name="normalizationType"
            inline
            showErrorIfUntouched
            generateDefaultValue={
              defaultValueConstants.CONC_OR_MOL_NORMALIZATION
            }
            options={defaultValueConstants.CONC_OR_MOL_NORMALIZATION.options}
          />
          <div style={{ marginRight: -13 }} className="input-with-unit-select">
            {normalizationType === "molarity" ? (
              <UnitInputField
                name="desiredMolarity"
                label="Molarity"
                isRequired
                unitName="desiredMolarityUnitCode"
                unitDefault="nM"
                unitType="molarityUnit"
              />
            ) : normalizationType === "concentration" ? (
              <UnitInputField
                name="desiredConcentration"
                isRequired
                label="Concentration"
                unitName="desiredConcentrationUnitCode"
                unitDefault="ng/uL"
                unitType="concentrationUnit"
              />
            ) : (
              <UnitInputField
                name="desiredCellConcentration"
                isRequired
                label="Cell Concentration"
                unitName="desiredCellConcentrationUnitCode"
                unitDefault="cells/uL"
                unitType="cellConcentrationUnit"
              />
            )}
          </div>
          {!isDry && (
            <CheckboxField
              label="Transfer Before Normalization"
              name="transferSourcePlateToNormalizedPlate"
            />
          )}
        </div>
      </div>
      {renderSourcePlateToNormalizationPlateTransferVolume(isDry)}
    </>
  );
};

export default NormalizationParameters;
