/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
import { reduxForm } from "redux-form";
import { tgFormValues } from "@teselagen/ui";
import { compose } from "recompose";
import { Classes } from "@blueprintjs/core";
import { DialogFooter, wrapDialog, RadioGroupField } from "@teselagen/ui";
import PropTypes from "prop-types";
import { hideStackedDialog, showStackedDialog } from "../StackedDialog";
import EditValueExtendedProperty from "./EditValueExtendedProperty";
import EditCategoryExtendedProperty from "./EditCategoryExtendedProperty";
import EditMeasurementExtendedProperty from "./EditMeasurementExtendedProperty";
import EditLinkExtendedProperty from "./EditLinkExtendedProperty";
import { startCase } from "lodash";

const extendedPropertyClassOptions = [
  { label: "Basic - text, true/false, number, or date", value: "VALUE" },
  {
    label: `Categorical - a set of pre-defined options: Ex. "Color: red, blue, or orange"`,
    value: "CATEGORY"
  },
  {
    label: `Measurement - a numeric value with custom measurement units. Ex. "23 stones"`,
    value: "MEASUREMENT"
  },
  {
    label: `Link - a link to another entity in the system. Ex. "Reference Sequence"`,
    value: "LINK"
  }
];

extendedPropertyClassOptions.push({
  label:
    "Worklist Counter - a number which increments whenever the item is executed inside of a worklist.",
  value: "WORKLIST_COUNTER"
});

const AddExtendedProperty = ({
  handleSubmit,
  submitting,
  initialValues,
  hideModal,
  stacked,
  refetch,
  initialItemType
}) => {
  const onSubmit = ({ extendedPropertyClassCode }) => {
    const DialogComps = {
      EditValueExtendedProperty,
      EditCategoryExtendedProperty,
      EditMeasurementExtendedProperty,
      EditLinkExtendedProperty
    };
    let DialogComp =
      DialogComps[
        `Edit${startCase(extendedPropertyClassCode.toLowerCase())}ExtendedProperty`
      ];
    if (extendedPropertyClassCode === "WORKLIST_COUNTER") {
      DialogComp = EditValueExtendedProperty;
    }
    if (stacked) {
      hideStackedDialog();
    } else {
      hideModal();
    }
    showStackedDialog({
      ModalComponent: DialogComp,
      modalProps: {
        refetch,
        initialValues,
        initialItemType,
        isWorklistCounter: extendedPropertyClassCode === "WORKLIST_COUNTER"
      }
    });
  };

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <RadioGroupField
          defaultValue="VALUE"
          isRequired
          name="extendedPropertyClassCode"
          options={extendedPropertyClassOptions}
        />
      </div>
      <DialogFooter
        text="Next"
        submitting={submitting}
        onClick={handleSubmit(onSubmit)}
        hideModal={hideModal}
      />
    </>
  );
};

AddExtendedProperty.propTypes = {
  refetch: PropTypes.func.isRequired
};

export default compose(
  wrapDialog({
    title: "Choose Extended Property Type"
  }),
  reduxForm({
    form: "addExtendedProperty"
  }),
  tgFormValues("extendedPropertyClassCode")
)(AddExtendedProperty);
