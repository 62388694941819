/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "recompose";
import { reduxForm } from "redux-form";
import { Classes } from "@blueprintjs/core";
import {
  DialogFooter,
  wrapDialog,
  InputField,
  BlueprintError,
  throwFormError
} from "@teselagen/ui";
import {
  deleteWithQuery,
  safeQuery,
  safeUpsert
} from "../../../../src-shared/apolloMethods";
import modelNameToLink from "../../../../src-shared/utils/modelNameToLink";
import { identity, uniq } from "lodash";
import {
  generateContainerArray,
  sortAliquotContainers
} from "../../../../../tg-iso-lims/src/utils/plateUtils";

function MoveBarcodedItemDialog(props) {
  const { hideModal, handleSubmit, submitting, history, error } = props;
  async function onSubmit({ barcode, destinationBarcode }) {
    try {
      let allBarcodes = [barcode];
      if (barcode.includes(" ") || barcode.includes(",")) {
        allBarcodes = barcode.split(/[\s,]+/).filter(identity);
        allBarcodes = uniq(allBarcodes);
      }
      const barcodeWithItemIds = await safeQuery(
        [
          "barcode",
          /* GraphQL */ `
            {
              id
              containerArrayId
              aliquotContainerId
            }
          `
        ],
        {
          variables: {
            filter: {
              barcodeString: allBarcodes
            }
          }
        }
      );
      const throwValidationError = err => {
        const error = {
          validationError: err
        };
        throw error;
      };
      if (!barcodeWithItemIds.length) {
        throwValidationError("No item with barcode found");
      }
      const getModelAndRecord = barcodeWithItemId => {
        for (const key of Object.keys(barcodeWithItemId)) {
          const recordId = barcodeWithItemId[key];
          if (key.includes("Id") && recordId) {
            const model = key.replace("Id", "");
            return {
              id: recordId,
              __typename: model
            };
          }
        }
      };
      const items = barcodeWithItemIds.map(getModelAndRecord).filter(identity);
      if (items.length > 1) {
        const allSameModel = items.every(
          item => item.__typename && item.__typename === items[0].__typename
        );
        if (!allSameModel) {
          throwValidationError(
            "Cannot move multiple items of different types."
          );
        }
      }
      const destinationContainers = await safeQuery(
        [
          "container",
          /* GraphQL */ `
            {
              id
              containerPositions {
                id
                index
              }
            }
          `
        ],
        {
          variables: {
            filter: {
              "barcode.barcodeString": destinationBarcode
            }
          }
        }
      );
      if (destinationContainers.length > 1) {
        throwValidationError("Multiple containers with barcode found");
      }
      const clearAssignedPositions = async () => {
        await deleteWithQuery("assignedPosition", {
          [items[0].__typename + "Id"]: items.map(item => item.id)
        });
      };
      if (
        !destinationContainers[0] &&
        items[0].__typename === "aliquotContainer"
      ) {
        // tubes can be placed into racks
        const racks = await safeQuery(
          [
            "containerArray",
            /* GraphQL */ `
              {
                id
                containerArrayType {
                  id
                  isPlate
                  containerFormat {
                    code
                    columnCount
                    rowCount
                  }
                  nestableTubeTypes {
                    id
                    aliquotContainerTypeCode
                  }
                }
                aliquotContainers {
                  id
                  rowPosition
                  columnPosition
                }
              }
            `
          ],
          {
            variables: {
              filter: {
                "barcode.barcodeString": destinationBarcode
              }
            }
          }
        );
        if (!racks.length) {
          throwValidationError("No rack with barcode found");
        } else if (racks.length > 1) {
          throwValidationError("Multiple racks with barcode found");
        }
        const rackToPlaceInto = racks[0];
        const tubesWithInfo = await safeQuery(
          ["aliquotContainer", "id aliquotContainerTypeCode"],
          {
            variables: {
              filter: {
                id: items.map(item => item.id)
              }
            }
          }
        );
        if (rackToPlaceInto.containerArrayType.isPlate) {
          throwValidationError("Destination is a plate not a rack.");
        }
        const acceptableTubeTypes =
          rackToPlaceInto.containerArrayType.nestableTubeTypes.map(
            t => t.aliquotContainerTypeCode
          );
        if (
          tubesWithInfo.some(
            t => !acceptableTubeTypes.includes(t.aliquotContainerTypeCode)
          )
        ) {
          throwValidationError(
            "Destination rack does not accept this tube type."
          );
        }
        const emptyPositions = sortAliquotContainers(
          generateContainerArray(
            rackToPlaceInto.aliquotContainers,
            rackToPlaceInto.containerArrayType.containerFormat
          ),
          "rowFirst"
        ).filter(ac => !ac.id);
        if (emptyPositions.length < items.length) {
          throwValidationError(
            `Rack does not have enough space for tube${
              items.length > 1 ? "s" : ""
            }.`
          );
        }
        await clearAssignedPositions();
        await safeUpsert(
          "aliquotContainer",
          items.map(tube => {
            const position = emptyPositions.shift();
            return {
              id: tube.id,
              containerArrayId: rackToPlaceInto.id,
              ...position
            };
          })
        );
      } else {
        if (!destinationContainers[0]) {
          throwValidationError("No container with barcode found");
        }

        const container = destinationContainers[0];
        let assignedPositions;
        if (container.containerPositions.length) {
          const position = [...container.containerPositions].sort(
            (a, b) => a.index - b.index
          )[0];
          assignedPositions = items.map(item => {
            return {
              positionTypeCode: "CONTAINER_POSITION",
              [items[0].__typename + "Id"]: item.id,
              containerPositionId: position.id
            };
          });
        } else {
          assignedPositions = items.map(item => {
            return {
              positionTypeCode: "CONTAINER",
              [items[0].__typename + "Id"]: item.id,
              containerId: container.id
            };
          });
        }
        await clearAssignedPositions();
        await safeUpsert("assignedPosition", assignedPositions);
      }

      const ids = items.map(item => item.id);
      history.push(
        modelNameToLink(items[0].__typename) +
          `?filters=id__inList__${ids.join(";")}`
      );

      hideModal();
    } catch (error) {
      console.error(`error:`, error);
      if (error.validationError) {
        throwFormError(error.validationError);
      }
      window.toastr.error("Unable to move item");
    }
  }

  return (
    <React.Fragment>
      <div className={Classes.DIALOG_BODY}>
        <InputField autoFocus isRequired label="Item Barcode" name="barcode" />
        <InputField
          isRequired
          label="Destination Barcode"
          name="destinationBarcode"
        />
        <BlueprintError error={error} />
      </div>
      <DialogFooter
        submitting={submitting}
        hideModal={hideModal}
        onClick={handleSubmit(onSubmit)}
      />
    </React.Fragment>
  );
}

export default compose(
  wrapDialog({
    title: "Move Barcoded Item"
  }),
  reduxForm({
    form: "MoveBarcodedItemDialog"
  })
)(MoveBarcodedItemDialog);
