/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

export function getParsedSequenceMatch(
  seqNameMap,
  seqFileMap,
  sequenceName = ""
) {
  const seqName = sequenceName.toLowerCase();
  const byName = seqNameMap && seqNameMap[seqName] && seqNameMap[seqName][0];
  const byFile = seqFileMap && seqFileMap[seqName] && seqFileMap[seqName][0];
  return byName || byFile;
}
