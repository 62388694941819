/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useMemo } from "react";
import {
  FileUploadField,
  BlueprintError,
  wrapDialog,
  DialogFooter
} from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import { reduxForm } from "redux-form";
import { compose } from "recompose";

import TagField from "../../../../src-shared/TagField";

import { throwFormError } from "../../../../src-shared/utils/formUtils";
import { allowedCsvFileTypes } from "../../../../../tg-iso-shared/src/utils/fileUtils";
import { getDownloadTemplateFileHelpers } from "../../../../src-shared/components/DownloadTemplateFileButton";
import useTgQuery from "../../../../src-shared/apolloUseTgQuery";
import handleStrainImport from "../../../../../tg-iso-lims/src/handleStrainImport";

const fragment = ["biosafetyLevel", "code name"];
const options = {
  variables: { pageSize: 999 }
};

const UploadStrainsDialog = ({
  hideModal,
  submitting,
  handleSubmit,
  error,
  refetch,
  isCellLine
}) => {
  const {
    data: { biosafetyLevels }
  } = useTgQuery(fragment, options);

  const fields = useMemo(() => {
    const nameToUse = isCellLine ? "cell line" : "strain";
    let fields = [
      {
        path: "NAME",
        description: `Required. The name of the ${nameToUse}. This name will be referenced in other CSV upload files as STRAIN_NAME.`,
        example: "Strain_ABC",
        isRequired: true
      },
      {
        path: "ALIAS",
        description: `Optional. An alternative name or nickname for the ${nameToUse}.`,
        example: "ABC123"
      },
      {
        path: "BIOSAFETY_LEVEL",
        description: `Required. The biosafety level assigned to the ${nameToUse}.`,
        example: "2",
        isRequired: true,
        ...(biosafetyLevels?.length && {
          type: "dropdown",
          values: biosafetyLevels.map(({ name }) => name)
        })
      },
      {
        path: "GENUS",
        description: `Optional. The genus of the ${nameToUse}. Requires setting the genus and species in 'Settings > Genera and Species'.`,
        example: "Escherichia"
      },
      {
        path: "SPECIES",
        description: `Optional. The species of the ${nameToUse}. Requires setting the genus and species in 'Settings > Genera and Species'.`,
        example: "Coli"
      },
      {
        path: "GENOTYPE",
        description: `Optional. The sub-type or genetic variant of the ${nameToUse}.`,
        example: "K12"
      },
      {
        path: "DESCRIPTION",
        description: `Optional. Additional description or notes about the ${nameToUse}.`,
        example: `This ${nameToUse} has antibiotic resistance.`
      },
      {
        path: "PLASMID_NAME",
        description: `Optional. The name of the plasmid associated with the ${nameToUse}. Requires specifying the plasmid file in the 'GB_FILE' column.`,
        example: "pUC19"
      },
      {
        path: "GENOME_NAME",
        description: `Optional. The name of the genome associated with the ${nameToUse}. Requires the genome to be available in 'Settings > Genomes'.`,
        example: "Genome_ABC"
      },
      {
        path: "GB_FILE",
        description: `Optional. The GenBank or FASTA file(s) associated with the ${nameToUse}. Upload sequence files along with the CSV. The 'GB_FILE' column of the CSV must match the sequence file name.`,
        example: "sequence1"
      },
      {
        path: "SELECTION_METHOD",
        description: `Optional. The method used for selecting the ${nameToUse}. Requires the selection method to be set in 'Settings > Selection Methods'.`,
        example: "Antibiotic selection"
      },
      {
        path: "INDUCTION_METHOD",
        description: `Optional. The method used for inducing gene expression in the ${nameToUse}. Requires the induction method to be set in 'Settings > Induction Methods'.`,
        example: "IPTG induction"
      },
      {
        path: "GAS_COMPOSITION",
        description: `Optional. The composition of the gas used for culturing the ${nameToUse}. Requires the gas composition to be set in 'Settings > Gas Compositions'.`,
        example: "Air"
      },
      {
        path: "ORGANISM_GROUP",
        description:
          "Optional. The class or group to which the organism belongs. Requires the group name to be set in 'Settings > Target Organism Group'.",
        example: "Bacteria"
      },
      {
        path: "GROWTH_CONDITION_NAME",
        description:
          "Optional. The name or identifier of the growth condition.",
        example: "Condition_ABC"
      },
      {
        path: "GROWTH_CONDITION_DESCRIPTION",
        description:
          "Optional. Additional description or notes about the growth condition.",
        example: "Cells were grown in LB medium."
      },
      {
        path: "GROWTH_MEDIUM",
        description: `Optional. The name of the growth medium used for culturing the ${nameToUse}. Requires the medium name to be set in 'Materials > Reagents > New Reagent'.`,
        example: "LB agar"
      },
      {
        path: "TEMPERATURE",
        type: "number",
        description: `Optional. The temperature at which the ${nameToUse} was cultured.`,
        example: "37"
      },
      {
        path: "SHAKER_SPEED",
        description: `Optional. The speed of the shaker used for culturing the ${nameToUse}.`,
        example: "200"
      },
      {
        path: "SHAKER_THROW",
        description: `Optional. The throw or amplitude of the shaker used for culturing the ${nameToUse}.`,
        example: "2"
      },
      {
        path: "LENGTH_UNIT_CODE",
        description:
          "Optional. The unit of measurement for shaker throw, required if shaker throw is specified.",
        example: "cm"
      }
    ];
    if (isCellLine) {
      fields = fields.filter(f => f.path !== "GB_FILE");
    }
    return fields;
  }, [isCellLine, biosafetyLevels]);

  const accept = useMemo(
    () =>
      getDownloadTemplateFileHelpers({
        type: allowedCsvFileTypes,
        fileName: isCellLine ? "cell_lines" : "strains",
        validateAgainstSchema: {
          fields
        },
        extendedPropTypes: ["strain"],
        additionalFileTypes: isCellLine ? [] : [".gb", ".fasta", ".fa"],
        helper: isCellLine
          ? null
          : "To associate plasmid files, upload genbank (.gb) or fasta files along with the csv. (Note: The 'GB_FILE' column of the csv must match the sequence file's name)"
      }),
    [fields, isCellLine]
  );

  const fileUploadFieldText = useMemo(
    () => (isCellLine ? "Upload Cell Lines" : "Upload Strains"),
    [isCellLine]
  );

  const onSubmit = useCallback(
    async values => {
      try {
        await handleStrainImport({
          ...values,
          isCellLine
        });

        await refetch();
        hideModal();
      } catch (error) {
        console.error("error:", error);
        throwFormError(
          error.message ||
            `Error registering new ${isCellLine ? "cell lines" : "strains"}`
        );
      }
    },
    [hideModal, isCellLine, refetch]
  );

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <FileUploadField
          accept={accept}
          isRequired
          name="strainFile"
          text={fileUploadFieldText}
        />
        <TagField />
        <BlueprintError error={error} />
      </div>
      <DialogFooter
        hideModal={hideModal}
        submitting={submitting}
        onClick={handleSubmit(onSubmit)}
      />
    </>
  );
};

export default compose(
  wrapDialog(({ isCellLine }) => ({
    title: isCellLine ? "Upload Cell Lines" : "Upload Strains"
  })),
  reduxForm({
    form: "uploadStrains"
  })
)(UploadStrainsDialog);
