/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useMemo } from "react";
import { useTableEntities } from "@teselagen/ui";
import SelectJ5Report from "./SelectJ5Report";
import Constructs from "./Constructs";
import AssemblyPieces from "./AssemblyPieces";
import OutputNames from "./OutputNames";
import { constructsTableName } from "../constants";
import { j5ReportsFullyLinked } from "../utils";
import { useFormValue } from "../../../../../src-shared/hooks/useFormValue";

const ChooseAssemblyPiecesAndConstructs = ({
  Footer,
  footerProps,
  toolSchema,
  toolIntegrationProps
}) => {
  const { selectedEntities } = useTableEntities(constructsTableName);
  const j5Reports = useFormValue(toolSchema.code, "j5Reports");
  const fullyLinked = useMemo(
    () => j5ReportsFullyLinked(j5Reports || []),
    [j5Reports]
  );

  return (
    <div>
      <SelectJ5Report
        toolSchema={toolSchema}
        toolIntegrationProps={toolIntegrationProps}
      />
      {j5Reports?.length > 0 && fullyLinked && (
        <>
          <AssemblyPieces toolSchema={toolSchema} />
          <Constructs toolSchema={toolSchema} />
          <OutputNames toolSchema={toolSchema} />
        </>
      )}
      <Footer
        {...footerProps}
        errorMessage={
          j5Reports?.length > 0 &&
          fullyLinked &&
          !Object.keys(selectedEntities || {}).length &&
          "Please select at least one construct to build."
        }
        nextDisabled={!Object.keys(selectedEntities || {}).length}
      />
    </div>
  );
};

export default ChooseAssemblyPiecesAndConstructs;
