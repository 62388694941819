/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { withTableParams } from "@teselagen/ui";
import withQuery from "../../src-shared/withQuery";

import { compose } from "redux";
import InsertAssemblyPieceDialog from "../components/Dialogs/InsertAssemblyPieceDialog";
import {
  designMaterialAvailabilityColumn,
  dateModifiedColumn
} from "../../src-shared/utils/libraryColumns";
import { tagColumnWithRender } from "../../src-shared/utils/tagColumn";
import { addTagFilterToQuery } from "../../src-shared/utils/tagUtils";
import gql from "graphql-tag";
import { taggedItemFragment } from "../../../tg-iso-shared/src/fragments/taggedItemFragment";

const schema = {
  model: "sequence",
  fields: [
    { path: "name", type: "string", displayName: "Name" },
    { path: "size", type: "number", displayName: "Size" },
    designMaterialAvailabilityColumn,
    {
      path: "user.username",
      type: "string",
      displayName: "Added By",
      searchDisabled: true
    },
    tagColumnWithRender,
    dateModifiedColumn
  ]
};

const fragment = gql`
  fragment insertAssemblyPieceDialogFragment on sequence {
    id
    name
    description
    size
    circular
    parts {
      id
      name
    }
    designMaterialAvailabilityView {
      id
      isAvailable
    }
    sequenceFeatures {
      id
      name
    }
    user {
      id
      username
      firstName
      lastName
    }
    isJ5Sequence
    isInLibrary
    lab {
      id
      name
    }
    taggedItems {
      ...taggedItemFragment
    }
    createdAt
    updatedAt
  }
  ${taggedItemFragment}
`;
const InsertAssemblyPieceDialogContainer = compose(
  withTableParams({
    urlConnected: true,
    schema,
    formName: "insertAssemblyPieceDialog",
    withDisplayOptions: true,
    additionalFilter: (_, qb, currentParams) => {
      addTagFilterToQuery(currentParams.tags, qb);
      qb.whereAny({
        isInLibrary: true
      });
    }
  }),
  withQuery(fragment, {
    isPlural: true
  })
)(InsertAssemblyPieceDialog);

export default InsertAssemblyPieceDialogContainer;
