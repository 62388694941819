/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useMemo, useState } from "react";
import gql from "graphql-tag";
import { get, uniq, identity, isEmpty } from "lodash";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import GenericSelect from "../../../../../src-shared/GenericSelect";
import { dateModifiedColumn } from "../../../../../src-shared/utils/libraryColumns";
import platePreviewColumn from "../../../../utils/platePreviewColumn";
import {
  BlueprintError,
  CheckboxField,
  DataTable,
  ReactSelectField,
  flaskIcon,
  InfoHelper,
  useTableEntities
} from "@teselagen/ui";
import {
  convertVolume,
  standardizeVolume,
  toDecimalPrecision,
  volumeRender
} from "../../../../../src-shared/utils/unitUtils";
import { Button, Icon, Intent } from "@blueprintjs/core";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import { sortAliquotContainers } from "../../../../../../tg-iso-lims/src/utils/plateUtils";
import { allConcentrationTypeFields } from "../../../../../../tg-iso-lims/src/utils/unitUtils";
import { change as _change } from "redux-form";
import { useDispatch } from "react-redux";
import { useFormValue } from "../../../../../src-shared/hooks/useFormValue";

const modelNameToSchema = {
  material: [
    {
      path: "name"
    },
    {
      path: "materialType.name",
      displayName: "Type"
    },
    dateModifiedColumn
  ],
  additiveMaterial: [
    {
      path: "name"
    },
    {
      path: "additiveType.name",
      displayName: "Type"
    },
    dateModifiedColumn
  ],
  lot: [
    {
      path: "name"
    },
    {
      path: "additiveMaterial.name",
      displayName: "Reagent Name"
    },
    {
      path: "additiveMaterial.additiveType.name",
      displayName: "Reagent Type"
    },
    {
      displayName: "Volume",
      render: volumeRender
    },
    dateModifiedColumn
  ]
};

const modelNameToFragment = {
  material: [
    "material",
    "id name materialTypeCode materialType { code name } createdAt updatedAt"
  ],
  additiveMaterial: [
    "additiveMaterial",
    "id name additiveType { code name } createdAt updatedAt"
  ],
  lot: [
    "lot",
    `id name additiveMaterial { id name additiveType { code name} } volume volumetricUnitCode ${allConcentrationTypeFields.join(
      " "
    )} createdAt updatedAt`
  ],
  materialAC: [
    "aliquotContainer",
    "id containerArrayId aliquot { id sample { id material { id } } }"
  ],
  additiveMaterialAC: [
    "aliquotContainer",
    "id containerArrayId additives { id additiveMaterialId }"
  ],
  lotAC: ["aliquotContainer", "id containerArrayId additives { id lotId }"]
};

const singlePlatePrepAliquotContainerFragment = gql`
  fragment singlePlatePrepAliquotContainerFragment on aliquotContainer {
    id
    rowPosition
    columnPosition
    aliquotContainerTypeCode
    aliquotContainerType {
      code
      deadVolume
      deadVolumetricUnitCode
    }
    name
    createdAt
    updatedAt
    barcode {
      id
      barcodeString
    }
    additives {
      id
      volume
      volumetricUnitCode
      additiveMaterialId
      lotId
      additiveMaterial {
        id
        name
      }
    }
    aliquot {
      id
      isDry
      volume
      volumetricUnitCode
      sample {
        id
        name
        materialId
        sampleTypeCode
        sampleFormulations {
          id
          materialCompositions {
            id
            material {
              id
              name
            }
          }
        }
        material {
          id
          name
          materialTypeCode
        }
      }
      additives {
        id
      }
    }
  }
`;

export const singlePlatePrepPlateFragment = gql`
  fragment singlePlatePrepPlateFragment on containerArray {
    id
    name
    createdAt
    updatedAt
    barcode {
      id
      barcodeString
    }
    containerArrayType {
      id
      name
      isPlate
      containerFormat {
        code
        rowCount
        columnCount
        is2DLabeled
      }
    }
    aliquotContainers {
      ...singlePlatePrepAliquotContainerFragment
    }
  }
  ${singlePlatePrepAliquotContainerFragment}
`;

const originalPlatePreviewColumn = platePreviewColumn();
const customPlatePreviewColumn = {
  ...originalPlatePreviewColumn,
  render: (...args) => {
    const record = args[1];
    if (record.__typename === "aliquotContainer") {
      return (
        <div style={{ float: "right", marginRight: 8 }}>
          <Icon icon={flaskIcon} />
        </div>
      );
    } else {
      return originalPlatePreviewColumn.render(...args);
    }
  }
};

function PreppingReagent({ additiveMaterial, lot, material, reagentType }) {
  let name = "";
  if (reagentType === "material") {
    name = material?.name;
  } else if (reagentType === "additiveMaterial") {
    name = additiveMaterial?.name;
  } else if (reagentType === "lot") {
    name = lot?.name;
  }
  return (
    <div className="tg-flex-column">
      <h6>Selected Prepping Reagent: {name}</h6>
    </div>
  );
}

const inputPlateSchema = [
  "name",
  { displayName: "Barcode", path: "barcode.barcodeString" },
  dateModifiedColumn
];

const inputPlatePostSelectDTProps = {
  formName: "singlePlatePrepSelectedPlates",
  schema: [
    platePreviewColumn({ withSampleStatus: true }),
    "name",
    {
      displayName: "Plate Type",
      path: "containerArrayType.name"
    },
    {
      displayName: "Barcode",
      path: "barcode.barcodeString"
    }
  ]
};

const inputPlateFragment = [
  "containerArray",
  "id name barcode { id barcodeString } containerArrayType { id name } updatedAt"
];

const reagentTypeOptions = [
  { label: "Reagent", value: "additiveMaterial" },
  { label: "Reagent Lot", value: "lot" },
  { label: "Material", value: "material" }
];

const reagentPlatesTableFormName = "reagentPlatesTable";

function SelectInputs({
  Footer,
  footerProps,
  handleSubmit,
  nextStep,
  toolIntegrationProps: { isDisabledMap = {}, isLoadingMap = {} },
  toolSchema: { code }
}) {
  const additiveMaterial = useFormValue(code, "additiveMaterial");
  const inputPlates = useFormValue(code, "inputPlates");
  const lot = useFormValue(code, "lot");
  const material = useFormValue(code, "material");
  const plateToAcs = useFormValue(code, "plateToAcs");
  const reagentType = useFormValue(code, "reagentType");
  const sourcePlates = useFormValue(code, "sourcePlates");
  const sourceReagent = useFormValue(code, "sourceReagent");
  const sourceTubes = useFormValue(code, "sourceTubes");

  const { selectedEntities } = useTableEntities(reagentPlatesTableFormName);
  const dispatch = useDispatch();
  const change = useCallback(
    (field, value) => dispatch(_change(code, field, value)),
    [dispatch, code]
  );

  const reagentTypeOnFieldSubmit = useCallback(
    value => {
      const types = ["additiveMaterial", "lot", "material"];
      const otherTypes = types.filter(type => type !== value);
      otherTypes.forEach(type => {
        change(type, null);
      });
    },
    [change]
  );

  const isEntityDisabled = useCallback(
    r => {
      const { totalVolumeWithDead } = getTotalVolume({
        reagentType,
        plateToAcs: plateToAcs || {},
        record: r
      });
      return totalVolumeWithDead <= 0;
    },
    [plateToAcs, reagentType]
  );

  const [loadingInventory, setLoadingInventory] = useState(false);

  const loadInventory = useCallback(async () => {
    setLoadingInventory(true);
    try {
      const fragment = modelNameToFragment[reagentType + "AC"];

      const modelNameToFilter = {
        material: { "aliquot.sample.material.id": material?.id },
        additiveMaterial: {
          "additives.additiveMaterialId": additiveMaterial?.id
        },
        lot: { "additives.lotId": lot?.id }
      };
      const filter = modelNameToFilter[reagentType];

      const matchingAliquotContainers = await safeQuery(fragment, {
        variables: {
          filter
        }
      });

      let filteredAliquotContainers;

      if (reagentType === "lot" || reagentType === "additiveMaterial") {
        filteredAliquotContainers = matchingAliquotContainers.filter(
          ac => ac.additives.length === 1
        );
      } else {
        filteredAliquotContainers = matchingAliquotContainers;
      }

      const plateIds = uniq(
        filteredAliquotContainers.map(ac => ac.containerArrayId)
      ).filter(identity); // removes nil plateIds
      const tubeIds = matchingAliquotContainers
        .filter(ac => !ac.containerArrayId)
        .map(ac => ac.id);
      const inputPlateIds = inputPlates?.map(plate => plate.id) || [];
      const filteredPlateIds = plateIds.filter(
        id => !inputPlateIds.includes(id)
      );

      const reagentSourcePlates = filteredPlateIds.length
        ? await safeQuery(singlePlatePrepPlateFragment, {
            variables: { filter: { id: filteredPlateIds } }
          })
        : [];
      const reagentSourceTubes = tubeIds.length
        ? await safeQuery(singlePlatePrepAliquotContainerFragment, {
            variables: {
              filter: {
                id: tubeIds
              }
            }
          })
        : [];

      if (reagentSourcePlates.length < 1 && reagentSourceTubes.length < 1) {
        window.toastr.warning(
          "Prepping reagent not found in inventory, make sure you have a plate in inventory containing the desired reagent."
        );
      }
      const preppingReagent = material || additiveMaterial || lot;
      const reagentIdPath =
        reagentType === "material"
          ? "aliquot.sample.materialId"
          : reagentType === "additiveMaterial"
            ? "additives[0].additiveMaterialId"
            : "additives[0].lotId";
      const plateToAcs = {};
      reagentSourcePlates.forEach(plate => {
        plateToAcs[plate.id] = plate.aliquotContainers
          .filter(ac => {
            if (get(ac, reagentIdPath) === preppingReagent.id) {
              if (reagentType === "material") {
                return !ac.aliquot.isDry;
              } else {
                return true;
              }
            } else {
              return false;
            }
          })
          .map(ac => {
            return {
              ...ac,
              containerArray: plate
            };
          });
        plateToAcs[plate.id] = sortAliquotContainers(
          plateToAcs[plate.id],
          "rowFirst"
        );
      });
      change("sourcePlates", reagentSourcePlates);
      change("sourceTubes", reagentSourceTubes);
      change("plateToAcs", plateToAcs);
    } catch (error) {
      console.error(`error:`, error);
      window.toastr.error("Error Loading Inventory");
    }
    setLoadingInventory(false);
  }, [
    change,
    setLoadingInventory,
    material,
    additiveMaterial,
    lot,
    reagentType,
    inputPlates
  ]);

  const clearSourcePlates = useCallback(() => {
    change("sourcePlates", []);
    change("sourceTubes", []);
    change("plateToAcs", {});
  }, [change]);

  const beforeNextStep = useCallback(() => {
    let sourcedReagentPlatesAndTubes = [];
    if (sourceReagent) {
      sourcedReagentPlatesAndTubes = Object.values(selectedEntities || {}).map(
        x => x.entity
      );
    }
    change("preppingReagent", material || additiveMaterial || lot);
    change("sourcedReagentPlatesAndTubes", sourcedReagentPlatesAndTubes);
    nextStep();
  }, [
    additiveMaterial,
    change,
    lot,
    material,
    nextStep,
    selectedEntities,
    sourceReagent
  ]);

  const hasSelectedReagent = material || additiveMaterial || lot;

  const matchingPlateError = useMemo(() => {
    const inputPlateIds = inputPlates?.map(plate => plate.id) || [];
    const selected = Object.values(selectedEntities || {}).map(x => x.entity);
    if (
      selected.length &&
      inputPlates?.length &&
      selected.some(plate => inputPlateIds.includes(plate.id))
    ) {
      return "Cannot select an input plate already selected as prepping reagent source plate.";
    }
  }, [inputPlates, selectedEntities]);

  let nextDisabled = false;
  if (matchingPlateError) {
    nextDisabled = true;
  } else if (sourceReagent) {
    nextDisabled = !inputPlates?.length || isEmpty(selectedEntities);
  } else {
    nextDisabled = !inputPlates?.length;
  }

  const reagentsPlateTableSchema = useMemo(
    () => [
      customPlatePreviewColumn,
      "name",
      {
        displayName: "Barcode",
        path: "barcode.barcodeString"
      },
      {
        displayName: "Total Reagent Volume",
        render: (v, r) => {
          const { totalVolume, originalUnitCode, totalVolumeWithDead } =
            getTotalVolume({
              reagentType,
              plateToAcs: plateToAcs || {},
              record: r
            });
          const convertedTotalVolume = convertVolume(
            totalVolume,
            "L",
            originalUnitCode
          );
          let toRet =
            toDecimalPrecision(convertedTotalVolume) + ` ${originalUnitCode}`;

          if (totalVolume !== totalVolumeWithDead) {
            const convertedTotalVolumeWithDead = convertVolume(
              totalVolumeWithDead,
              "L",
              originalUnitCode
            );
            let withDeadNum = toDecimalPrecision(convertedTotalVolumeWithDead);
            if (withDeadNum < 0) withDeadNum = 0;
            const withDeadVal = withDeadNum + ` ${originalUnitCode}`;
            toRet = (
              <div style={{ display: "flex" }}>
                <InfoHelper
                  style={{ marginRight: 5 }}
                  content={`Total volume is ${toRet} but there is dead volume in this container type that is making some of that volume unusable.`}
                />
                {withDeadVal}
              </div>
            );
          }
          return toRet;
        }
      },
      dateModifiedColumn
    ],
    [plateToAcs, reagentType]
  );

  const inputPlatesButtonProps = useMemo(
    () => ({
      disabled: isDisabledMap.containerArrays,
      loading: isLoadingMap.containerArrays
    }),
    [isDisabledMap.containerArrays, isLoadingMap.containerArrays]
  );

  return (
    <>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Select Destination Plates"
          helper={`Select one or more destination plates
          to prep. The prepping reagent selected below
          will be transfered to these plates.`}
        />
        <GenericSelect
          name="inputPlates"
          schema={inputPlateSchema}
          isMultiSelect
          buttonProps={inputPlatesButtonProps}
          fragment={inputPlateFragment}
          additionalDataFragment={singlePlatePrepPlateFragment}
          postSelectDTProps={inputPlatePostSelectDTProps}
        />
        <BlueprintError error={matchingPlateError} />
      </div>
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          header="Prepping Reagent"
          width="100%"
          helper={`Choose a prepping reagent type from the dropdown
          below (reagent, reagent lot, material). After selecting a reagent of that type,
          check the box labeled "Source Prepping Reagent" to select a reagent from inventory.
          Doing so will create a worklist upon completing the tool. If you do not source the
          prepping reagent, the plate will be updated automatically upon completing the tool.`}
        />
        <div style={{ width: "50%" }}>
          <ReactSelectField
            name="reagentType"
            label="Prepping Reagent Type"
            onFieldSubmit={reagentTypeOnFieldSubmit}
            options={reagentTypeOptions}
          />
        </div>
        {reagentType && (
          <div className="tg-flex column">
            <GenericSelect
              key={reagentType}
              // add validation to prevent users from selecting dry reagents/reagent lots
              name={reagentType}
              schema={modelNameToSchema[reagentType]}
              fragment={modelNameToFragment[reagentType]}
              onClear={clearSourcePlates} // add onClear here to reset table
            />
            {hasSelectedReagent && (
              <PreppingReagent
                additiveMaterial={additiveMaterial}
                lot={lot}
                material={material}
                reagentType={reagentType}
              />
            )}
            <div className="tg-flex">
              {hasSelectedReagent && (
                <CheckboxField
                  name="sourceReagent"
                  label="Source Prepping Reagent"
                  onFieldSubmit={clearSourcePlates}
                />
              )}
              <div style={{ marginLeft: 20 }}>
                {sourceReagent && hasSelectedReagent && (
                  <Button
                    text="Search Inventory"
                    loading={loadingInventory}
                    intent={Intent.PRIMARY}
                    onClick={loadInventory}
                  />
                )}
              </div>
            </div>
            {(sourcePlates?.length || sourceTubes?.length) && (
              <DataTable
                keepDirtyOnReinitialize
                destroyOnUnmount={false}
                formName={reagentPlatesTableFormName}
                entities={(sourcePlates || []).concat(sourceTubes || [])}
                withCheckboxes
                isEntityDisabled={isEntityDisabled}
                schema={reagentsPlateTableSchema}
              />
            )}
          </div>
        )}
      </div>
      <Footer
        {...footerProps}
        nextDisabled={nextDisabled}
        onNextClick={handleSubmit(beforeNextStep)}
      />
    </>
  );
}

export default SelectInputs;

function getTotalVolume({ reagentType, plateToAcs, record }) {
  const volumePath = reagentType === "material" ? "aliquot" : "additives[0]";
  let filteredAcs = [];
  if (record.__typename === "containerArray") {
    filteredAcs = plateToAcs[record.id] || [];
  } else {
    filteredAcs = [record];
  }
  let originalUnitCode = "uL";
  const getTotalVolume = withDead => {
    return filteredAcs.reduce((acc, ac) => {
      const volumeItem = get(ac, volumePath);
      originalUnitCode = volumeItem?.volumetricUnitCode;
      let newVol =
        acc +
        standardizeVolume(
          volumeItem?.volume || 0,
          volumeItem?.volumetricUnitCode || "uL"
        );
      if (withDead) {
        newVol -= standardizeVolume(
          ac.aliquotContainerType.deadVolume || 0,
          ac.aliquotContainerType.deadVolumetricUnitCode || "uL"
        );
      }
      return newVol;
    }, 0);
  };
  const totalVolume = getTotalVolume();
  const totalVolumeWithDead = getTotalVolume(true);
  return {
    totalVolume,
    totalVolumeWithDead,
    originalUnitCode
  };
}
