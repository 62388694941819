/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useEffect, useState } from "react";
import { SwitchField, useDeepEqualMemo } from "@teselagen/ui";
import withQuery from "../../../../../src-shared/withQuery";

import { Button, Intent } from "@blueprintjs/core";
import { get, forEach, keyBy, pick } from "lodash";
import QueryBuilder from "tg-client-query-builder";
import shortid from "shortid";
import Big from "big.js";
import HeaderWithHelper from "../../../../../src-shared/HeaderWithHelper";
import UniversalTransfer from "../../PlateReformatTool/SharedFieldComponents/UniversalTransfer";
import PrepFieldsHelper from "../PrepFieldsHelper";
import {
  standardizeVolume,
  convertVolume
} from "../../../../../src-shared/utils/unitUtils";
import { showDialog } from "../../../../../src-shared/GlobalDialog";
import {
  getIntermediateContainerAdjustedMaxVolume,
  getPlatePrepKey
} from "../utils";
import { safeQuery } from "../../../../../src-shared/apolloMethods";
import {
  collectExtendedProperties,
  recordExtendedValuesString
} from "../../../../../src-shared/utils/extendedPropertyUtils";
import { getAliquotContainerLocation } from "../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { generateEmptyWells } from "../../../../../../tg-iso-lims/src/utils/plateUtils";
import { allConcentrationTypeFields } from "../../../../../../tg-iso-lims/src/utils/unitUtils";
import containerArrayTypeFragment from "../../../../../../tg-iso-shared/src/fragments/containerArrayTypeFragment";
import ChooseAndClearWellsForPlate from "../../../ChooseAndClearWellsForPlate";
import { useDispatch, useSelector } from "react-redux";
import { formValueSelector, change } from "redux-form";
import PlatePrepDialog from "../PlatePrepDialog";

const containerArrayFragment = [
  "containerArray",
  `
    id
    name
    containerArrayType {
      id
      containerFormat {
        code
        is2DLabeled
        columnCount
      }
    }
    ${recordExtendedValuesString}
    aliquotContainers {
      aliquotContainerTypeCode
      id
      rowPosition
      columnPosition
      aliquot {
        id
        volume
        volumetricUnitCode
        ${recordExtendedValuesString}
      }
    }
  `
];

const MapToDestinationPlates = props => {
  const {
    containerArrayTypes = [],
    Footer,
    footerProps,
    handleSubmit,
    nextStep,
    stepFormProps: {
      initialValues: {
        destinationContainerArrays: _destinationContainerArrays = []
      } = {}
    },
    toolIntegrationProps,
    toolIntegrationProps: { isIntegratedMap: _isIntegratedMap = {} },
    toolSchema
  } = props;

  const dispatch = useDispatch();
  const {
    combineWorklists,
    generateBarcode = {},
    intermediateContainers = {},
    platesToPrep: _platesToPrep = [],
    prepAliquotContainers: _prepAliquotContainers = [],
    prepEmptyWells = {},
    prepLots: _prepLots = [],
    prepMaterialPlates = [],
    prepTubes: _prepTubes = [],
    selectedWellsForPlate = {},
    transferVolume = {},
    transferVolumetricUnitCode = {},
    universalTransfers,
    universalTransferVolume,
    universalTransferVolumeUnitCode
  } = useSelector(state =>
    formValueSelector(toolSchema.code)(
      state,
      "prepTubes",
      "prepMaterialPlates",
      "prepLots",
      "prepAliquotContainers",
      "platesToPrep",
      "combineWorklists",
      "transferVolume",
      "transferVolumetricUnitCode",
      "universalTransfers",
      "universalTransferVolume",
      "universalTransferVolumeUnitCode",
      "generateBarcode",
      "intermediateContainers",
      "selectedWellsForPlate",
      "prepEmptyWells"
    )
  );

  const destinationContainerArrays = useDeepEqualMemo(
    _destinationContainerArrays
  );
  const platesToPrep = useDeepEqualMemo(_platesToPrep);
  const prepLots = useDeepEqualMemo(_prepLots);
  const prepTubes = useDeepEqualMemo(_prepTubes);
  const prepAliquotContainers = useDeepEqualMemo(_prepAliquotContainers);
  const isIntegratedMap = useDeepEqualMemo(_isIntegratedMap);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const allPrepItems = prepTubes
      .concat(prepLots)
      .concat(prepAliquotContainers);
    if (isIntegratedMap.containerArrays && destinationContainerArrays.length) {
      const newPlatesToPrep = {};
      allPrepItems.forEach(aliquotContainer => {
        newPlatesToPrep[getPlatePrepKey(aliquotContainer)] =
          destinationContainerArrays;
      });
      dispatch(change(toolSchema.code, "platesToPrep", newPlatesToPrep));
    } else {
      const keys = allPrepItems.map(aliquotContainer => {
        return getPlatePrepKey(aliquotContainer);
      });
      const newPlatesToPrep = { ...platesToPrep };
      forEach(platesToPrep, (val, key) => {
        if (!keys.includes(key)) {
          delete newPlatesToPrep[key];
        }
      });
      if (
        Object.keys(newPlatesToPrep).length !== Object.keys(platesToPrep).length
      ) {
        dispatch(change(toolSchema.code, "platesToPrep", newPlatesToPrep));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const savePlatesToForm = plates => {
    const aliquotContainersAndLots = prepAliquotContainers.concat(
      prepTubes,
      prepLots
    );
    aliquotContainersAndLots.forEach(container => {
      const key = getPlatePrepKey(container);
      const newPlatesToPrep = (platesToPrep[key] || []).concat(plates);
      dispatch(change(toolSchema.code, `platesToPrep.${key}`, newPlatesToPrep));
    });
  };

  const createPlates = () => {
    showDialog({
      ModalComponent: PlatePrepDialog,
      modalProps: {
        afterCreate: savePlatesToForm
      }
    });
  };

  const generateWorklist = async () => {
    setLoading(true);
    try {
      const destinationAliquotExtendedProperties = {};
      const destinationContainerArrayExtendedProperties = {};

      const allDestPlateIds = [];

      forEach(platesToPrep, plates => {
        plates.forEach(plate => {
          if (!allDestPlateIds.includes(plate.id))
            allDestPlateIds.push(plate.id);
        });
      });

      const allDestinationPlates = await safeQuery(containerArrayFragment, {
        variables: {
          filter: {
            id: allDestPlateIds
          }
        }
      });
      const keyedDestinationPlates = keyBy(allDestinationPlates, "id");

      const keyedAliquotContainers = keyBy(
        prepAliquotContainers.concat(prepTubes),
        "id"
      );
      const worklists = [];
      const intermediateContainersToUpsert = [];
      const lotVolumeToUpsert = [];
      let activeWorklist = {
        worklistTransfers: []
      };
      forEach(platesToPrep, (plates, platePrepKey) => {
        let sourceAliquotContainerId;
        let sourceLotId;
        let lotAliquotContainers;
        let activeAliquotContainerIndex = 0;
        const volumeToUse = universalTransfers
          ? universalTransferVolume
          : transferVolume[platePrepKey];
        const volumeUnitCodeToUse = universalTransfers
          ? universalTransferVolumeUnitCode
          : transferVolumetricUnitCode[platePrepKey];
        const standardizedTransferVolume = standardizeVolume(
          volumeToUse,
          volumeUnitCodeToUse,
          true
        );
        const isLotTransfer = platePrepKey.includes("lot");
        const shouldPrepEmptyWells = prepEmptyWells[platePrepKey];
        if (platePrepKey.includes("aliquotContainer")) {
          sourceAliquotContainerId = platePrepKey.replace(
            "aliquotContainer",
            ""
          );
        } else if (isLotTransfer) {
          sourceLotId = platePrepKey.replace("lot", "");

          let numDestinationAliquotContainers = 0;
          plates.forEach(plate => {
            const plateWellsKey = platePrepKey + `-${plate.id}`;
            const plateWithACs = keyedDestinationPlates[plate.id];
            const wells = selectedWellsForPlate[plateWellsKey];
            if (wells && wells.length) {
              // if we are not prepping empty wells filter out selected wells that don't have aliquots
              if (!shouldPrepEmptyWells) {
                numDestinationAliquotContainers +=
                  plateWithACs.aliquotContainers.filter(
                    ac =>
                      ac.aliquot &&
                      wells.includes(getAliquotContainerLocation(ac))
                  ).length;
              } else {
                numDestinationAliquotContainers += wells.length;
              }
            } else {
              // if we are not prepping empty wells then we need to count up the wells with aliquots
              // else we just count every aliquot container
              if (!shouldPrepEmptyWells) {
                numDestinationAliquotContainers +=
                  plateWithACs.aliquotContainers.filter(
                    ac => ac.aliquot
                  ).length;
              } else {
                numDestinationAliquotContainers +=
                  plate.containerArrayType.containerFormat.quadrantSize;
              }
            }
          });

          const totalStandardizedDestinationPlateVolume = standardizeVolume(
            volumeToUse * numDestinationAliquotContainers,
            volumeUnitCodeToUse,
            true
          );

          const {
            name,
            type: intermediateContainerType,
            barcode
          } = intermediateContainers[platePrepKey];
          const {
            aliquotContainerTypeCode,
            aliquotContainerType: intermediateWellType,
            containerFormat
          } = intermediateContainerType;

          const maxWellVolumeAdjusted =
            getIntermediateContainerAdjustedMaxVolume({
              intermediateContainerType,
              standardizedTransferVolume
            });
          const { volumetricUnitCode, deadVolume, deadVolumetricUnitCode } =
            intermediateWellType;
          const lotInfo = prepLots.find(lot => lot.id === sourceLotId);
          let volumeNeeded = totalStandardizedDestinationPlateVolume;

          let totalVolumeToTakeFromLot =
            totalStandardizedDestinationPlateVolume;
          const standardizedDeadVolume = standardizeVolume(
            deadVolume || 0,
            deadVolumetricUnitCode || "uL",
            true
          );
          lotAliquotContainers = generateEmptyWells(containerFormat, {
            aliquotContainerTypeCode
          }).map(ac => {
            let volume = maxWellVolumeAdjusted;
            if (volumeNeeded.minus(maxWellVolumeAdjusted).lt(0)) {
              volume = volumeNeeded;
              volumeNeeded = new Big(0);
            } else {
              volumeNeeded = volumeNeeded.minus(maxWellVolumeAdjusted);
            }
            const additives = [];
            let adjustedVolume;
            if (!volume.eq(0)) {
              adjustedVolume = volume;
              volume = volume.plus(standardizedDeadVolume);
              totalVolumeToTakeFromLot = totalVolumeToTakeFromLot.plus(
                standardizedDeadVolume
              );
              additives.push({
                lotId: sourceLotId,
                volume: Number(
                  convertVolume(
                    volume,
                    "L",
                    volumetricUnitCode,
                    true
                  ).toString()
                ),
                volumetricUnitCode,
                ...pick(lotInfo, allConcentrationTypeFields)
              });
            }
            return { ...ac, additives, adjustedVolume, cid: shortid() };
          });
          const containerArrayType =
            intermediateContainers[platePrepKey] &&
            intermediateContainers[platePrepKey].type;
          const intermediateContainer = {
            name,
            displayFilter:
              containerArrayType.name === "Reservoir" ? "RESERVOIR" : null,
            containerArrayTypeId: containerArrayType.id,
            ...(!generateBarcode[platePrepKey] && {
              barcode: {
                barcodeString: barcode
              }
            }),
            aliquotContainers: lotAliquotContainers.map(lac => {
              const cleaned = { ...lac };
              delete cleaned.adjustedVolume;
              return cleaned;
            })
          };
          intermediateContainersToUpsert.push(intermediateContainer);

          const standardizedLotVolume = standardizeVolume(
            lotInfo.volume,
            lotInfo.volumetricUnitCode,
            true
          );
          const remainingLotVolume = convertVolume(
            standardizedLotVolume.minus(totalVolumeToTakeFromLot),
            "L",
            lotInfo.volumetricUnitCode,
            true
          );
          const lotVolume = {
            id: sourceLotId,
            volume: remainingLotVolume
          };
          lotVolumeToUpsert.push(lotVolume);
        }

        let activeSourceAdditiveVolume;
        if (isLotTransfer) {
          activeSourceAdditiveVolume =
            lotAliquotContainers[activeAliquotContainerIndex].adjustedVolume;
        }

        plates.forEach(plate => {
          const plateWithACs = keyedDestinationPlates[plate.id];
          const selectedWells =
            selectedWellsForPlate[platePrepKey + "-" + plate.id];
          collectExtendedProperties(
            plateWithACs,
            destinationContainerArrayExtendedProperties
          );
          plateWithACs.aliquotContainers.forEach(destAc => {
            // if they have chosen specific wells to transfer into
            if (
              selectedWells &&
              selectedWells.length &&
              !selectedWells.includes(getAliquotContainerLocation(destAc))
            )
              return;

            // skip empty wells
            if (!shouldPrepEmptyWells && !destAc.aliquot) return;

            if (destAc.aliquot) {
              collectExtendedProperties(
                destAc.aliquot,
                destinationAliquotExtendedProperties
              );
            }

            if (isLotTransfer) {
              if (
                activeSourceAdditiveVolume
                  .minus(standardizedTransferVolume)
                  .lt(0)
              ) {
                activeAliquotContainerIndex++;
                activeSourceAdditiveVolume =
                  lotAliquotContainers[activeAliquotContainerIndex]
                    .adjustedVolume;
              }
              activeSourceAdditiveVolume = activeSourceAdditiveVolume.minus(
                standardizedTransferVolume
              );
              sourceAliquotContainerId =
                "&" + lotAliquotContainers[activeAliquotContainerIndex].cid;
            }
            let sourceAliquotContainer =
              keyedAliquotContainers[sourceAliquotContainerId];
            if (isLotTransfer) {
              sourceAliquotContainer =
                lotAliquotContainers[activeAliquotContainerIndex];
            }
            let sourcePlateName = get(
              keyedAliquotContainers[sourceAliquotContainerId],
              "containerArray.name"
            );
            if (isLotTransfer) {
              sourcePlateName = intermediateContainers[platePrepKey].name;
            }
            activeWorklist.worklistTransfers.push({
              volume: volumeToUse,
              volumetricUnitCode: volumeUnitCodeToUse,
              sourceAliquotContainerId,
              sourceAliquotContainer,
              destinationAliquotContainerId: destAc.id,
              destinationAliquotContainer: {
                ...destAc,
                // will be needed for showing box positions
                containerArray: {
                  id: plateWithACs.id,
                  __typename: plateWithACs.__typename,
                  containerArrayType: plateWithACs.containerArrayType
                }
              },
              destinationPlateId: plate.id,
              sourcePlateName,
              destinationPlateName: plateWithACs.name
            });
          });
        });
        if (!combineWorklists) {
          worklists.push(activeWorklist);
          activeWorklist = {
            worklistTransfers: []
          };
        }
      });
      if (combineWorklists) {
        worklists.push(activeWorklist);
      }

      dispatch(
        change(
          toolSchema.code,
          "destinationAliquotExtendedProperties",
          Object.values(destinationAliquotExtendedProperties)
        )
      );

      dispatch(
        change(
          toolSchema.code,
          "destinationContainerArrayExtendedProperties",
          Object.values(destinationContainerArrayExtendedProperties)
        )
      );
      dispatch(
        change(toolSchema.code, "allDestinationPlates", keyedDestinationPlates)
      );
      dispatch(
        change(
          toolSchema.code,
          "intermediateContainersToUpsert",
          intermediateContainersToUpsert
        )
      );
      dispatch(change(toolSchema.code, "worklists", worklists));
      dispatch(change(toolSchema.code, "lotVolumeToUpsert", lotVolumeToUpsert));
      nextStep();
    } catch (error) {
      console.error("error:", error);
      window.toastr.error("Error generating worklists");
      setLoading(false);
    }
  };

  const chooseWellsForPlate = (wellLocations, chooseWellsKey) => {
    dispatch(
      change(toolSchema.code, "selectedWellsForPlate", {
        ...selectedWellsForPlate,
        [chooseWellsKey]: wellLocations
      })
    );
  };

  const clearSelectedWellsForPlate = key => {
    dispatch(
      change(toolSchema.code, "selectedWellsForPlate", {
        ...selectedWellsForPlate,
        [key]: undefined
      })
    );
  };

  const renderWells = platePrepKey => (v, plate) => {
    const chooseWellsKey = platePrepKey + "-" + plate.id;
    let selectedWells = selectedWellsForPlate[chooseWellsKey];
    if (selectedWells && !selectedWells.length) {
      selectedWells = undefined;
    }

    return (
      <ChooseAndClearWellsForPlate
        containerArray={plate}
        selectedWells={selectedWells}
        clearWells={() => clearSelectedWellsForPlate(chooseWellsKey)}
        chooseWells={wellLocations =>
          chooseWellsForPlate(wellLocations, chooseWellsKey)
        }
      />
    );
  };

  const { isDisabledMap = {} } = toolIntegrationProps;

  const selectedPlateIds = prepMaterialPlates.map(plate => plate.id);
  const addPlateFilter = qb =>
    qb.whereAll({
      id: qb.notInList(selectedPlateIds)
    });

  const prepNewPlateButton = (
    <div>
      <Button
        intent={Intent.SUCCESS}
        text="Prep New Plates"
        disabled={isDisabledMap.containerArrays}
        onClick={createPlates}
      />
    </div>
  );

  return (
    <>
      <UniversalTransfer toolSchema={toolSchema} />
      {[prepAliquotContainers, prepTubes, prepLots].map((itemGroup, i) => {
        return (
          itemGroup.length > 0 && (
            <PrepFieldsHelper
              key={i}
              items={itemGroup}
              isTube={i === 1}
              prepNewPlateButton={prepNewPlateButton}
              platesToPrep={platesToPrep}
              toolIntegrationProps={toolIntegrationProps}
              addPlateFilter={addPlateFilter}
              selectedWellsForPlate={selectedWellsForPlate}
              universalTransfers={universalTransfers}
              renderWells={renderWells}
              containerArrayTypes={containerArrayTypes}
              generateBarcode={generateBarcode}
            />
          )
        );
      })}
      <div className="tg-step-form-section column">
        <HeaderWithHelper
          width="100%"
          header="Format Worklists"
          helper="Choose whether you would like to create one worklist per reagent, or one master worklist."
        />
        <SwitchField name="combineWorklists" label="Combine Worklists" />
      </div>
      <Footer
        {...footerProps}
        loading={loading}
        onClick={handleSubmit(generateWorklist)}
      />
    </>
  );
};

const qb = new QueryBuilder("containerArrayType");
const filter = qb
  .whereAll({ "aliquotContainerType.maxVolume": qb.notNull(), isPlate: true })
  .toJSON();

export default withQuery(containerArrayTypeFragment, {
  isPlural: true,
  showLoading: true,
  options: { variables: { filter } }
})(MapToDestinationPlates);
