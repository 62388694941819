/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React from "react";
// import classNames from "classnames";
import { MenuItem } from "@blueprintjs/core";
import { startCase } from "lodash";
import pluralize from "pluralize";
import { hideDialog, showDialog } from "../GlobalDialog";
import { AutoAnnotateDialog } from "./AutoAnnotateDialog";
import sIfPlural from "../utils/sIfPlural";

const types = ["feature", "part"];
export const AutoAnnotateMenu = ({ selectedRecords, refetch }) => (
  <MenuItem
    icon="add-row-top"
    key="autoAnnotateFeaturesMenuItem"
    text="Auto Annotate"
  >
    {types.map(type => (
      <MenuItem
        key={type}
        onClick={() => {
          if (!selectedRecords.reduce((acc, r) => acc + r.size, 0)) {
            return window.toastr.warning(`The selected sequence${sIfPlural(
              selectedRecords
            )}
        contain${selectedRecords.length > 1 ? "" : "s"} no base pairs.`);
          }
          showDialog({
            ModalComponent: AutoAnnotateDialog,
            modalProps: {
              annotationType: type,
              sequenceIds: selectedRecords.map(r => r.id),
              sequenceName: selectedRecords.map(r => r.name),
              refetch
            }
          });
        }}
        text={`Auto Annotate ${pluralize(startCase(type))}`}
      />
    ))}
  </MenuItem>
);

const oveAutoAnnotateHandlers = {};
types.forEach(type => {
  oveAutoAnnotateHandlers[`autoAnnotate${pluralize(startCase(type))}`] =
    props =>
      new Promise(resolve => {
        showDialog({
          ModalComponent: AutoAnnotateDialog,
          modalProps: {
            annotationType: type,
            justPassResultsCallback: results => {
              resolve(results);
              hideDialog();
            },
            doNotShowConfirmation: true,
            sequenceIds: [props.sequenceData.id],
            sequenceName: [props.sequenceData.name]
          }
        });
      });
});

export { oveAutoAnnotateHandlers };
