/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import { each } from "lodash";

export const makeItemToWellMapForPlateMap = (plateMap: {
  [key: string]: { item: { id: string } };
}) => {
  const itemToWellMap: { [itemId: string]: string[] } = {};

  each(plateMap, ({ item }, well) => {
    if (well.startsWith("__")) return;
    const id = item.id;
    if (!itemToWellMap[id]) itemToWellMap[id] = [];
    itemToWellMap[id].push(well);
  });

  return itemToWellMap;
};
