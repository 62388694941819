/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */

import React, { Component } from "react";
import gql from "graphql-tag";
import libraryEnhancer from "../../../src-shared/libraryEnhancer";
import AbstractLibrary from "../../../src-shared/AbstractLibrary";
import { compose } from "recompose";
import { showDialog } from "../../../src-shared/GlobalDialog";

class DesignLibrary extends Component {
  render() {
    return (
      <AbstractLibrary
        {...this.props}
        onNewItemClick={() => {
          showDialog({
            modalType: "CREATE_RULE_SET"
          });
        }}
        withExport
        isLibraryTable
        model="ruleSet"
      />
    );
  }
}

const schema = {
  model: "ruleSet",
  fields: [
    {
      path: "name",
      type: "string",
      displayName: "Name"
    }
  ]
};

const fragment = gql`
  fragment designRulesetLibraryFragment on ruleSet {
    id
    name
  }
`;

export default compose(
  libraryEnhancer({
    schema,
    fragment,
    withSelectedEntities: true,
    noAddedBy: true
  })
)(DesignLibrary);
