/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { MouseEvent } from "react";
import { Button, Classes, Intent } from "@blueprintjs/core";
import { FieldArrayFieldsProps } from "redux-form";

export default function AddButton({
  fields,
  label,
  text,
  add: _add,
  noMargin,
  ...rest
}: {
  fields: FieldArrayFieldsProps<any>;
  label?: string;
  text?: string;
  add?: (event: MouseEvent) => void;
  noMargin?: boolean;
  [key: string]: any;
}) {
  function add() {
    fields.push({});
  }

  return (
    <Button
      style={{ marginTop: noMargin ? 0 : 10 }}
      icon="add"
      className={Classes.MINIMAL}
      intent={Intent.SUCCESS}
      onClick={_add || add}
      text={label || text}
      {...rest}
    />
  );
}
