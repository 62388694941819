/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useCallback, useEffect, useMemo } from "react";
import { InputField, useTableEntities } from "@teselagen/ui";
import type { InjectedWithSelectTableRecords } from "@teselagen/ui";
import { useSelector } from "react-redux";
import HeaderWithHelper from "../../../../../../src-shared/HeaderWithHelper";
import {
  generatePlateMapGroup,
  pluralizeItemType,
  PlateWellContentTypes
} from "../../utils";
import { PlateToCreateType } from "../../utils/generatePlateMapGroup";
import PlateMapView from "../../../../../components/PlateMapView";
import { volumeColumn } from "../../../../../../src-shared/utils/libraryColumns";
import modelNameToReadableName from "../../../../../../src-shared/utils/modelNameToReadableName";
import { tagModels } from "../../../../../../../tg-iso-shared/constants";
import { tagColumnWithRender } from "../../../../../../src-shared/utils/tagColumn";
import defaultValueConstants from "../../../../../../../tg-iso-shared/src/defaultValueConstants";
import { getAliquotContainerLocation } from "../../../../../../../tg-iso-lims/src/utils/getAliquotContainerLocation";
import { BreakdownPatterns } from "../../../utils/blockToAliquotArray";
import { ItemTypes } from "../../utils/itemTypes";
import { formValueSelector } from "redux-form";
import { Directions } from "../../../../../utils/plateUtils/getWellPositionsGivenWellCountAndDirection";
import { J5EntityType } from "../../utils/types/J5EntityType";
import type { Item } from "../../utils/types/Item";
import type { RecursiveRequired } from "../../../../../../src-shared/typescriptHelpers";
import type { PlateMap, PlateMapItem } from "../../utils/types/PlateMap";
import { PlateConfiguration } from "./PlateConfiguration";

const reduxValues = {
  UploadingVolumeInfo: "uploadingVolumeInfo",
  BreakdownPattern: "breakdownPattern",
  ItemType: "itemType",
  J5EntityType: "j5EntityType",
  J5EntityRadio: "j5EntityRadio",
  SelectedContainerFormat: "selectedContainerFormat",
  PlateMapGroupName: "plateMapGroupName",
  PlateMapType: "plateMapType",
  PlatesToCreate: "platesToCreate",
  PlateWellContentType: "plateWellContentType",
  FixedQuadrants: "fixedQuadrants",
  ReactionEntityType: "reactionEntityType"
} as const;

interface InjectedPlateMapConfigurationProps {
  fillDirection: Directions;
  stepFormProps: any;
  toolSchema: { code: string };
}

const getPlateMapItem = (item: PlateMapItem) => {
  switch (item.plateMapItemTypeCode) {
    case "INVENTORY_ITEM":
      return item.inventoryItem;
    case "J5_ITEM":
      return item.j5Item;
  }
  throw new Error("There is no item");
};

type ValueOf<T> = T[keyof T];

type useSelectorResponse = {
  breakdownPattern: BreakdownPatterns;
  fixedQuadrants: string;
  itemType: ValueOf<typeof ItemTypes>;
  j5EntityRadio: "material" | "j5PcrReaction";
  j5EntityType: ValueOf<typeof J5EntityType>;
  plateMapGroupName: string;
  plateMaps: RecursiveRequired<PlateMap[]>;
  plateMapType: ValueOf<typeof PlateWellContentTypes>;
  platesToCreate: RecursiveRequired<PlateToCreateType[]>;
  plateWellContentType: ValueOf<typeof PlateWellContentTypes>;
  reactionEntityType: string;
  selectedContainerFormat: {
    quadrantSize: number;
    rowCount: number;
    columnCount: number;
    code: string;
  };
  uploadingVolumeInfo: boolean;
};

interface ReduxState {
  form?: {
    [formName: string]: {
      values?: useSelectorResponse;
    };
  };
}

const PlateMapConfiguration = (
  props: InjectedWithSelectTableRecords & InjectedPlateMapConfigurationProps
) => {
  const {
    fillDirection = "right",
    stepFormProps: { change },
    toolSchema
  } = props;

  const { selectTableEntities } = useTableEntities<Item>(
    "selectedSourceMaterialsTable"
  );

  const {
    breakdownPattern,
    fixedQuadrants,
    itemType,
    j5EntityType,
    j5EntityRadio,
    plateMapGroupName,
    plateMaps,
    plateMapType,
    platesToCreate = [],
    plateWellContentType,
    reactionEntityType,
    selectedContainerFormat,
    uploadingVolumeInfo
  } = useSelector<ReduxState, useSelectorResponse>(state =>
    formValueSelector(toolSchema.code)(
      state,
      ...Object.values(ItemTypes).map(model => pluralizeItemType[model]),
      ...Object.values(reduxValues)
    )
  );

  useEffect(() => {
    if (itemType === "plateMap" && !uploadingVolumeInfo && !fixedQuadrants) {
      const newPlates: { [location: string]: {} }[] = [];
      plateMaps.forEach((plateMap, i) => {
        newPlates.push({});
        plateMap.plateMapItems.forEach(plateMapItem => {
          const item = getPlateMapItem(plateMapItem);
          newPlates[i][getAliquotContainerLocation(plateMapItem)] = { item };
        });
      });
      change("platesToCreate", newPlates);
    }
  }, [
    change,
    fixedQuadrants,
    itemType,
    plateMapType,
    plateMaps,
    uploadingVolumeInfo
  ]);

  const usableItemType = useMemo(() => {
    let _itemType:
      | ValueOf<typeof ItemTypes>
      | ValueOf<typeof PlateWellContentTypes>
      | "reaction"
      | ValueOf<typeof J5EntityType> = itemType;
    if (_itemType === "Inventory List") {
      _itemType = "sample";
    } else if (_itemType === "containerArray") {
      _itemType = plateWellContentType;
    } else if (_itemType === "plateMap") {
      _itemType = plateMapType;
    } else if (_itemType === "j5Report") {
      if (j5EntityRadio === "j5PcrReaction") {
        _itemType = "reaction";
      } else {
        _itemType = j5EntityType || j5EntityRadio;
      }
    } else if (_itemType === "reactionMap") {
      _itemType = reactionEntityType.includes("Material")
        ? "material"
        : "additiveMaterial";
    }
    return _itemType;
  }, [
    itemType,
    j5EntityRadio,
    j5EntityType,
    plateMapType,
    plateWellContentType,
    reactionEntityType
  ]);

  const readableItemType = useCallback(
    ({ upperCase, plural }: { upperCase?: boolean; plural?: boolean }) => {
      return modelNameToReadableName(usableItemType, {
        plural,
        upperCase
      });
    },
    [usableItemType]
  );

  const plateMapViewSchema = useMemo(() => {
    let model: string = itemType;
    if (itemType === "plateMap") {
      model = plateMapType;
    } else if (itemType === "j5Report") {
      model = j5EntityType;
    }

    const _schema: {
      path: string;
      displayName?: string;
      sortFn?: string[];
      filterDisabled?: boolean;
      sortDisabled?: boolean;
    }[] = [
      { path: "location", sortFn: ["rowPosition", "columnPosition"] },
      {
        displayName: readableItemType({ upperCase: true }) + " Name",
        path: "name"
      }
    ];

    if (uploadingVolumeInfo) {
      _schema.push(volumeColumn);
    }

    if (tagModels.includes(model)) {
      _schema.push({
        ...tagColumnWithRender,
        filterDisabled: true,
        sortDisabled: true
      });
    }
    return _schema;
  }, [
    itemType,
    j5EntityType,
    plateMapType,
    readableItemType,
    uploadingVolumeInfo
  ]);

  const renderPlatePreview = useCallback(() => {
    const plateMapGroup: ReturnType<typeof generatePlateMapGroup> & {
      containerFormat?: {
        quadrantSize: number;
        rowCount: number;
        columnCount: number;
      };
    } = generatePlateMapGroup({
      itemType,
      plateWellContentType,
      plateMapType,
      j5EntityRadio,
      reactionEntityType,
      plateMapGroupName,
      platesToCreate,
      selectedContainerFormat,
      uploadingVolumeInfo,
      addFakeIds: true
    });
    plateMapGroup.containerFormat = selectedContainerFormat;
    return (
      <div className="tg-step-form-section column">
        <PlateMapView
          // We need to fix PlateMapView Types to work with PlateMapConfiguration Types
          selectTableRecords={selectTableEntities as (items: any[]) => void}
          plateMapGroup={plateMapGroup}
          tableSchema={plateMapViewSchema}
          withQuadrantFilter
          breakdownPattern={breakdownPattern}
        />
      </div>
    );
  }, [
    breakdownPattern,
    itemType,
    j5EntityRadio,
    plateMapGroupName,
    plateMapType,
    plateWellContentType,
    platesToCreate,
    reactionEntityType,
    selectTableEntities,
    selectedContainerFormat,
    plateMapViewSchema,
    uploadingVolumeInfo
  ]);

  return (
    <div>
      <div className="tg-step-form-section column">
        <div className="tg-flex justify-space-between">
          <HeaderWithHelper
            header="Name Plate Map"
            helper="Enter a name for the output plate map."
          />
          <div style={{ width: "30%" }}>
            <InputField
              isRequired
              placeholder="Enter output plate map name..."
              name="plateMapGroupName"
              generateDefaultValue={{
                ...defaultValueConstants.PLATE_MAP_GROUP_NAME
              }}
            />
          </div>
        </div>
      </div>
      {uploadingVolumeInfo || fixedQuadrants ? (
        renderPlatePreview()
      ) : (
        <PlateConfiguration
          fillDirection={fillDirection}
          formName={toolSchema.code}
          readableItemType={readableItemType}
        />
      )}
    </div>
  );
};

export default PlateMapConfiguration;
