/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export const extendedPropertyFragment = gql`
  fragment extendedPropertyFragment on extendedProperty {
    id
    name
    modelTypeCode
    targetModel
    modelType {
      code
      name
    }
    extendedTypeCode
    extendedType {
      code
      name
    }
    extendedPropertyClassCode
    extendedPropertyClass {
      code
      name
    }
    extendedCategoryClass {
      id
      name
      extendedCategories {
        id
        name
      }
    }
    extendedMeasurementUnitClass {
      id
      name
      baseMeasurementUnitId
      baseMeasurementUnit {
        id
        name
        abbreviation
        conversionFactor
      }
      measurementUnits {
        id
        name
        abbreviation
        conversionFactor
      }
    }
  }
`;
