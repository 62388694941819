/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose } from "redux";
import { get, forEach, isEmpty } from "lodash";
import { Button, Intent } from "@blueprintjs/core";
import Big from "big.js";
import StepForm from "../../../../src-shared/StepForm";
import "./style.css";
import withWorkflowInputs from "../../../graphql/enhancers/withWorkflowInputs";
import plateMapGroupRegistrationFragment from "../../../graphql/fragments/plateMapGroupRegistrationFragment";
import {
  RegisterPlateBarcodes,
  SelectPlateSetMaps,
  SelectPlateTypeAndContents
} from "./Steps";
import {
  preparePlatesInput,
  getInvalidPlateMaps,
  getPlateMapItemContent,
  getItemHeader
} from "./utils";
import {
  standardizeVolume,
  convertVolume
} from "../../../../src-shared/utils/unitUtils";
import {
  REQUIRED_ERROR,
  throwFormError
} from "../../../../src-shared/utils/formUtils";

import { safeUpsert } from "../../../../src-shared/apolloMethods";
import { addBarcodesToRecords } from "../../../../../tg-iso-lims/src/utils/barcodeUtils";
import { validateCSVRequiredHeaders } from "../../../../../tg-iso-shared/src/utils/fileUtils";

const steps = [
  {
    title: "Select Plate Map",
    Component: SelectPlateSetMaps,
    withCustomFooter: true
  },
  {
    title: "Select Plate Format",
    Component: SelectPlateTypeAndContents
  },
  {
    title: "Register Plate Barcodes",
    nextButton: (
      <Button type="submit" text="Register Plates" intent={Intent.SUCCESS} />
    ),
    Component: RegisterPlateBarcodes
  }
];

const onSubmit = async values => {
  const { plateMaps = [], generateBarcodes, plateMapBarcodes } = values;

  try {
    const containerArrays = preparePlatesInput({
      ...values,
      plateMaps,
      plateMapBarcodes
    });
    const createdContainerArrays = await safeUpsert(
      "containerArray",
      containerArrays
    );
    if (generateBarcodes) {
      await addBarcodesToRecords(createdContainerArrays);
    }
    return {
      containerArrays: createdContainerArrays
    };
  } catch (error) {
    console.error("err:", error);
    throwFormError(error.message || "Error creating plates.");
  }
};

const validate = values => {
  const {
    selectedPlateMapGroups = [],
    selectedContainerArrayTypes = {},
    selectedTubeTypes = [],
    volumeAndConcentration,
    wellContentsInfoUpload,
    volume,
    mass,
    plateBarcodeTubesUpload,
    tubeBarcodes,
    volumetricUnitCode,
    concentrationType
  } = values;
  const errors = {};
  const plateMaps = [];
  selectedPlateMapGroups.forEach(pmg => {
    plateMaps.push(...pmg.plateMaps);
  });

  if (selectedPlateMapGroups.length < 1) {
    errors.selectedPlateMapGroups = "Must select at least one plate map.";
  }

  if (tubeBarcodes && !plateBarcodeTubesUpload)
    errors.plateBarcodeTubesUpload = REQUIRED_ERROR;
  const groupsMissingMaps = [];
  selectedPlateMapGroups.forEach(plateMapGroup => {
    if (plateMapGroup.plateMaps.length < 1) {
      groupsMissingMaps.push(plateMapGroup.name);
    }
  });

  if (groupsMissingMaps.length > 0) {
    errors.selectedPlateMapGroups = `The following plate maps do not have associated plate maps: ${groupsMissingMaps.join(
      ", "
    )}`;
  }

  if (!errors.selectedPlateMapGroups) {
    const invalidPlateMaps = getInvalidPlateMaps(selectedPlateMapGroups);
    if (invalidPlateMaps.length) {
      errors.selectedPlateMapGroups = `The following plate maps are not linked to materials appropriately: ${invalidPlateMaps.join(
        ", "
      )}. This tool only accepts plate maps created using the Create Plate Map tool.`;
    }
  }

  let dryReagents = false,
    wetReagents = false;
  const keyedPlateMapItems = {};
  plateMaps.some(pm => {
    return pm.plateMapItems.some(pmi => {
      const additiveMaterial = get(pmi, "inventoryItem.additiveMaterial");
      if (additiveMaterial) {
        dryReagents = additiveMaterial.isDry;
        wetReagents = !additiveMaterial.isDry;
      }
      keyedPlateMapItems[pmi.id] = { ...pmi, plateMap: pm };
      return dryReagents && wetReagents;
    });
  });
  const missingMolecularWeight = plateMaps.some(pm => {
    return pm.plateMapItems.some(pmi => {
      const molecularWeight = get(
        pmi,
        "inventoryItem.material.polynucleotideMaterialSequence.molecularWeight"
      );
      return !molecularWeight;
    });
  });

  // can only specify molarity if no reagent plate maps and all materials have a molecular weight
  if (concentrationType === "molarity") {
    if (missingMolecularWeight) {
      errors.molarity =
        "Not all materials have a molecular weight. Molarity cannot be calculated.";
    }
  }

  if (volumeAndConcentration === "plateWideVolume") {
    if (!volume) errors.volume = REQUIRED_ERROR;
    else {
      if (dryReagents) {
        errors.volume = "There are dry reagents on these plate maps.";
      }
      const standardizedAliquotVolume = standardizeVolume(
        volume,
        volumetricUnitCode,
        true
      );

      let minSelectedContainerArrayTypeMaxVolume;

      forEach(selectedContainerArrayTypes, (containerArrayType, key) => {
        let standardizedMaxVolume;
        if (containerArrayType.isPlate) {
          standardizedMaxVolume = standardizeVolume(
            containerArrayType.aliquotContainerType.maxVolume,
            containerArrayType.aliquotContainerType.volumetricUnitCode,
            true
          );
        } else if (selectedTubeTypes[key]) {
          standardizedMaxVolume = standardizeVolume(
            selectedTubeTypes[key].aliquotContainerType.maxVolume,
            selectedTubeTypes[key].aliquotContainerType.volumetricUnitCode,
            true
          );
        } else {
          // Resetting standardized max volume to zero if selected tube type default value is erased from form
          standardizedMaxVolume = new Big(0);
        }

        if (
          !minSelectedContainerArrayTypeMaxVolume ||
          standardizedMaxVolume.lt(minSelectedContainerArrayTypeMaxVolume)
        ) {
          minSelectedContainerArrayTypeMaxVolume = standardizedMaxVolume;
        }
      });

      const convertedVolume = convertVolume(
        minSelectedContainerArrayTypeMaxVolume,
        "L",
        volumetricUnitCode,
        true
      );
      if (standardizedAliquotVolume.gt(minSelectedContainerArrayTypeMaxVolume))
        errors.volume = `Exceeds max well volume of ${convertedVolume} ${volumetricUnitCode}.`;
    }
  } else if (values.volumeAndConcentration === "plateWideMass") {
    if (!mass) errors.mass = REQUIRED_ERROR;
    if (wetReagents) {
      errors.mass = "There are wet reagents on these plate maps.";
    }
  } else if (volumeAndConcentration === "upload") {
    if (isEmpty(wellContentsInfoUpload)) {
      errors.wellContentsInfoUpload = REQUIRED_ERROR;
    } else {
      if (!wellContentsInfoUpload[0].loading) {
        errors.wellContentsInfoUpload = validateWellContentsInfoUpload(
          wellContentsInfoUpload,
          keyedPlateMapItems
        );
      }
    }
  }
  return errors;
};

const PlateRegistrationTool = ({
  initialValues,
  toolSchema,
  toolIntegrationProps,
  isToolIntegrated
}) => (
  <StepForm
    steps={steps}
    toolIntegrationProps={toolIntegrationProps}
    toolSchema={toolSchema}
    onSubmit={onSubmit}
    validate={validate}
    enableReinitialize={isToolIntegrated}
    initialValues={initialValues}
  />
);

export default compose(
  withWorkflowInputs(plateMapGroupRegistrationFragment, {
    initialValueName: "selectedPlateMapGroups"
  })
)(PlateRegistrationTool);

const validateWellContentsInfoUpload = (
  wellContentsInfoUpload,
  keyedPlateMapItems = {}
) => {
  const parsedCsv = get(wellContentsInfoUpload, "[0].parsedCsv");
  if (!parsedCsv) return "No CSV uploaded.";
  const { meta } = parsedCsv;

  const headerError = validateCSVRequiredHeaders(meta.fields, [
    "plate map item id"
  ]);
  if (headerError) {
    return headerError;
  } else {
    const wellContentsCsvData = get(
      wellContentsInfoUpload,
      "[0].parsedCsv.data",
      []
    );
    if (wellContentsCsvData) {
      let error = "";
      for (const [index, row] of wellContentsCsvData.entries()) {
        const plateMapItem = keyedPlateMapItems[row["plate map item id"]];

        const additiveMaterial = get(
          plateMapItem,
          "inventoryItem.additiveMaterial"
        );
        if (!row["volume"] && !row["mass"]) {
          error += `Row ${index + 1} did not specify a volume or mass.\n`;
        }
        if (!plateMapItem) {
          error += `Row ${
            index + 1
          } did not specify a valid plate map item id.\n`;
        } else {
          const item = getPlateMapItemContent(
            plateMapItem.plateMap,
            plateMapItem
          );
          if (item) {
            const header = getItemHeader(item);
            const rowItemName = row[header];
            if (header && !rowItemName) {
              error += `Row ${index + 1} did not specify a ${header}.\n`;
            } else if (rowItemName && rowItemName !== item.name) {
              error += `Row ${
                index + 1
              } specified a different ${header} than the existing one at that location.\n`;
            }
          }
          if (additiveMaterial) {
            if (additiveMaterial.isDry && row["volume"]) {
              error += `Row ${
                index + 1
              } specifies a volume but the additive material for this plate map row is dry.\n`;
            } else if (!additiveMaterial.isDry && row["mass"]) {
              error += `Row ${
                index + 1
              } specifies a mass but the additive material for this plate map row is not dry.\n`;
            }
          }
        }
      }
      return error;
    } else {
      return "No data in CSV.";
    }
  }
};
