/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import gql from "graphql-tag";

export const aliquotLibraryFragment = gql`
  fragment aliquotLibraryFragment on aliquotPathView {
    id
    nid
    concentration
    concentrationUnitCode
    molarity
    molarityUnitCode
    volume
    volumetricUnitCode
    mass
    massUnitCode
    fullPath
    sample {
      id
      name
      sampleTypeCode
      sampleFormulations {
        id
        materialCompositions {
          id
          material {
            id
            name
          }
        }
      }
      material {
        id
        name
      }
    }
    aliquotType
  }
`;
