/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React, { useState, useMemo, useCallback } from "react";
import {
  DialogFooter,
  DataTable,
  TgSelect,
  showConfirmationDialog
} from "@teselagen/ui";
import { Classes } from "@blueprintjs/core";
import { get, pick, debounce, keyBy, noop, isEqual } from "lodash";
import pluralize from "pluralize";
import { flatMap } from "lodash";
import { compose, branch, withProps } from "recompose";
import { formatDateTime } from "../utils/dateUtils";
import { safeQuery } from "../apolloMethods";
import { isEmpty } from "lodash";
import { expirationDateModels } from "../../../tg-iso-shared/constants";
import modelNameToReadableName from "../../../tg-iso-shared/src/utils/modelNameToReadableName";
import { withDialog } from "@teselagen/ui";
import genericSelectTableWrapper from "./genericSelectTableWrapper";

const InnerComp = compose(
  branch(
    ({ noDialog }) => !noDialog,
    withDialog({
      enforceFocus: false,
      canOutsideClickClose: false
    })
  ),
  withProps(({ currentValue, asReactSelect, idAs }) => {
    if (!asReactSelect && Array.isArray(currentValue) && currentValue.length) {
      // preserve old selection in table
      return {
        initialValues: {
          reduxFormSelectedEntityIdMap: currentValue.reduce((acc, entity) => {
            acc[entity[idAs]] = { entity };
            return acc;
          }, {})
        }
      };
    }
  }),
  genericSelectTableWrapper
)(({
  additionalOptions = [],
  additionalDataFragment,
  additionalFilter,
  additionalTableProps,
  asReactSelect,
  autoOpen,
  buttonText,
  buttonProps,
  code,
  currentValue,
  defaultTemplateString,
  defaultValue,
  defaultValueByIdOverride,
  destinationPlateFormat,
  dialogFooterProps,
  dialogInfoMessage = null,
  dialogProps,
  disabled,
  doNotGenerateField,
  enableReinitialize,
  fragment,
  fieldType,
  firstItemsToShow = [],
  getButton,
  getButtonText,
  getExtraOptionFields = noop,
  handleOpenChange,
  handlersObj,
  handleSelection,
  hideModal,
  idAs,
  initialValues,
  isMultiSelect,
  isRequired,
  key,
  label,
  meta,
  maxSelected,
  minSelected,
  mustSelect,
  name,
  nameOverride,
  noDialog,
  noFill,
  noForm,
  noMarginBottom,
  noRemoveButton,
  noResultsText,
  onFieldSubmit,
  onSelect,
  params,
  passedName,
  placeholder,
  postSelectDTProps,
  postSelectFormName,
  preserveValue,
  queryOptions,
  reactSelectProps = {},
  reagent,
  readableName,
  schema,
  secondaryLabel,
  selectedEntities,
  style,
  tableParamOptions,
  tableParams = {},
  transformEntities: customTransformEntities,
  tooltipInfo,
  validate,
  setReactSelectQueryString,
  withSelectAll,
  withSelectedTitle,
  selectedPlateIds
}) => {
  const rest = {
    additionalDataFragment,
    additionalFilter,
    buttonProps,
    code,
    currentValue,
    defaultTemplateString,
    defaultValue,
    defaultValueByIdOverride,
    destinationPlateFormat,
    dialogProps,
    disabled,
    doNotGenerateField,
    enableReinitialize,
    fragment,
    fieldType,
    getButton,
    getButtonText,
    initialValues,
    isRequired,
    key,
    label,
    meta,
    name,
    nameOverride,
    noDialog,
    noFill,
    noForm,
    noRemoveButton,
    onFieldSubmit,
    onSelect,
    params,
    placeholder,
    postSelectDTProps,
    postSelectFormName,
    preserveValue,
    queryOptions,
    reagent,
    schema,
    secondaryLabel,
    style,
    tableParamOptions,
    tooltipInfo,
    validate,
    withSelectedTitle,
    noMarginBottom,
    selectedPlateIds,
    autoOpen,
    handleOpenChange
  };
  const [reactSelectLoading, setReactSelectLoading] = useState(false);
  const [finishingSelection, setFinishingSelection] = useState(false);
  const reactSelectLoadingState = useMemo(() => {
    return (
      tableParams.isLoading || reactSelectProps.isLoading || reactSelectLoading
    );
  }, [tableParams.isLoading, reactSelectProps.isLoading, reactSelectLoading]);

  const transformEntities = useCallback(
    (entities, addFirstItems) => {
      if (customTransformEntities) {
        return customTransformEntities(entities);
      } else if (reactSelectProps.isTagSelect) {
        return flatMap(entities, tag => {
          if (tag.tagOptions && tag.tagOptions.length) {
            return tag.tagOptions.map(tagOption => {
              const label = `${tag.name}: ${tagOption.name}`;
              return {
                ...tag,
                id: `${tag.id}:${tagOption.id}`,
                name: label,
                label,
                color: tagOption.color
              };
            });
          } else {
            return { ...tag, label: tag.name };
          }
        });
      } else {
        if (
          addFirstItems &&
          firstItemsToShow &&
          (isEmpty(tableParams.currentParams) ||
            isEqual(tableParams.currentParams, { searchTerm: "" }))
        ) {
          const firstItemIds = firstItemsToShow.map(i => i.id);
          const concatted = firstItemsToShow.concat(
            entities.filter(e => !firstItemIds.includes(e.id))
          );
          return concatted;
        } else {
          return entities;
        }
      }
    },
    [
      customTransformEntities,
      firstItemsToShow,
      reactSelectProps.isTagSelect,
      tableParams.currentParams
    ]
  );

  const finishSelection = useCallback(
    async records => {
      const stateLoadingFunction = asReactSelect
        ? setReactSelectLoading
        : setFinishingSelection;

      const model = records?.[0]?.__typename;
      if (expirationDateModels.includes(model)) {
        const expiredRecords = records.filter(
          r => r.expirationDate && new Date(r.expirationDate) < new Date()
        );
        if (expiredRecords.length) {
          const continueSelection = await showConfirmationDialog({
            text: `These ${modelNameToReadableName(model, {
              plural: true
            })} are expired: ${expiredRecords
              .map(r => r.name)
              .join(", ")}. Would you like to continue with this selection?`,
            confirmButtonText: "Yes",
            cancelButtonText: "No"
          });
          if (!continueSelection) {
            return;
          }
        }
      }
      stateLoadingFunction(true);
      await handleSelection(records);
      if (asReactSelect) {
        stateLoadingFunction(false);
      }
      if (!noDialog) {
        hideModal && hideModal();
      }
    },
    [asReactSelect, handleSelection, hideModal, noDialog]
  );

  const onDoubleClick = useCallback(
    record => {
      if (isMultiSelect) return;
      finishSelection([record]);
    },
    [finishSelection, isMultiSelect]
  );

  const getReactSelectOptions = () => {
    const { entityCount, schema, setPage, page, pageSize } = tableParams;

    const inputIds = [];
    const inputEntities = [];
    if (currentValue) {
      (Array.isArray(currentValue) ? currentValue : [currentValue]).forEach(
        ent => {
          inputIds.push(ent[idAs]);
          inputEntities.push(ent);
        }
      );
    }

    const inputEntityOptions = inputEntities.map(ent => ({
      ...ent,
      __isInputEnt: true
    }));

    const fullyTransformEntities = entities => {
      return transformEntities(entities, true).map(entity => {
        return {
          ...pick(entity, ["__isInputEnt", "userCreated"]),
          ...getExtraOptionFields(entity),
          clearableValue: entity.clearableValue,
          record: entity,
          value: entity[idAs],
          ...(reactSelectProps.isTagSelect && entity),
          ...(!reactSelectProps.isTagSelect && {
            label: (
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                {schema.fields.reduce((acc, field, i) => {
                  if (field.isHidden) return acc;
                  const label = field.displayName ? (
                    <div style={{ fontSize: 10, color: "#aaa" }}>
                      {field.displayName}:{" "}
                    </div>
                  ) : null;
                  let val = get(entity, field.path || field);
                  if (field.render) {
                    val = field.render(val, entity);
                  } else if (field.type === "timestamp") {
                    val = val ? formatDateTime(val) : "";
                  } else if (field.type === "boolean") {
                    val = val ? "True" : "False";
                  }

                  let style;
                  if (i > 0) {
                    style = {
                      marginLeft: 8,
                      fontSize: 9
                    };
                  }
                  acc.push(
                    <div
                      key={i}
                      style={{
                        ...style,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      {label}
                      <div style={{ marginLeft: 4 }}>{val}</div>
                    </div>
                  );
                  return acc;
                }, [])}
              </span>
            )
          })
        };
      });
    };

    if (reactSelectLoadingState) {
      // don't show tableParams.entities because they might be out of sync
      return fullyTransformEntities(inputEntityOptions).concat({
        value: "__LOADING",
        noTagStyle: true,
        disabled: true,
        label: (
          <span className={Classes.TEXT_MUTED} style={{ fontStyle: "italic" }}>
            Loading...
          </span>
        )
      });
    }
    //here we need to append "inputEntities" to our regular list of entities
    //input entities can be initialValues
    //it is important that we spread inputEntities second as the initialValues might not yet be loaded by the default table query
    const entities = [
      ...(tableParams.entities || []).filter(
        ent => !inputIds.includes(ent[idAs])
      ),
      ...inputEntityOptions
    ];
    if (!entities.length) return [];
    const lastItem = [];
    const firstItem = [];
    const backEnabled = page - 1 > 0;
    const forwardEnabled = page * pageSize < entityCount;
    const lastPage = Math.ceil(entityCount / pageSize);
    if (backEnabled) {
      firstItem.push({
        value: "__PREV_PAGE",
        noTagStyle: true,
        onClick: () => {
          setPage(parseInt(page, 10) - 1);
        },
        label: (
          <span className={Classes.TEXT_MUTED} style={{ fontStyle: "italic" }}>
            Previous Page (On page {page} of {lastPage})
          </span>
        )
      });
    }
    if (forwardEnabled) {
      lastItem.push({
        value: "__NEXT_PAGE",
        noTagStyle: true,
        onClick: () => {
          setPage(parseInt(page, 10) + 1);
        },
        label: (
          <span className={Classes.TEXT_MUTED} style={{ fontStyle: "italic" }}>
            Next Page (On page {page} of {lastPage})
          </span>
        )
      });
    }
    const entityOptions = fullyTransformEntities(entities);
    return [
      ...firstItem,
      ...transformEntities(additionalOptions),
      ...entityOptions,
      ...lastItem
    ];
  };

  const handleReactSelectSearchDebounced = useMemo(() => {
    return debounce(val => {
      // stop local loading state because tableParams will provide it
      setReactSelectLoading(false);
      tableParams.setSearchTerm(val);
    }, 250);
  }, [tableParams]);

  const handleReactSelectSearch = useCallback(
    val => {
      setReactSelectQueryString(val);
      // show loading spinner immediately, because debounced query will not be fired
      setReactSelectLoading(!!val);
      handleReactSelectSearchDebounced(val);
      return val; //return val for react-select to work properly
    },
    [handleReactSelectSearchDebounced, setReactSelectQueryString]
  );

  const handleReactSelectFieldSubmit = useCallback(
    valOrVals => {
      if (!valOrVals || valOrVals.length === 0) {
        return finishSelection([]);
      }
      //we want to save the entity/entity array itself to the redux form value, not the {label,value} that is passed here
      let entitiesById = keyBy(
        transformEntities([
          ...firstItemsToShow,
          ...(tableParams.entities || []),
          ...additionalOptions
        ]),
        idAs
      );
      if (currentValue) {
        if (Array.isArray(currentValue)) {
          entitiesById = {
            ...entitiesById,
            ...keyBy(currentValue, idAs)
          };
        } else {
          entitiesById[currentValue[idAs]] = currentValue;
        }
      }
      try {
        let records = Array.isArray(valOrVals) ? valOrVals : [valOrVals];
        records = records
          .filter(r => !r.disabled)
          .map(val => {
            const { value, userCreated } = val;
            if (userCreated) {
              return val;
              // custom components will want to manipulate the input values themselves
            } else if (val.__useVal) {
              const newV = { ...val };
              delete val.__useVal;
              return newV;
            } else {
              return entitiesById[value];
            }
          });
        finishSelection(records);
      } catch (error) {
        console.error(`error:`, error);
      }
    },
    [
      additionalOptions,
      currentValue,
      finishSelection,
      firstItemsToShow,
      idAs,
      tableParams.entities,
      transformEntities
    ]
  );

  if (handlersObj) {
    handlersObj.refetch = tableParams.onRefresh;
  }

  const {
    minSelectMessage,
    maxSelectMessage,
    mustSelectMessage,
    disableButton
  } = useMemo(() => {
    const getName = num =>
      num !== 1
        ? pluralize(readableName.toLowerCase())
        : readableName.toLowerCase();

    let disableButton = !selectedEntities.length;
    let minSelectMessage;
    let maxSelectMessage;
    let mustSelectMessage;
    if (minSelected && selectedEntities.length < minSelected) {
      minSelectMessage = `Please select at least ${minSelected} ${getName(
        minSelected
      )}`;
      disableButton = true;
    }
    if (maxSelected && selectedEntities.length > maxSelected) {
      maxSelectMessage = `Please select up to ${maxSelected} ${getName(
        maxSelected
      )}`;
      disableButton = true;
    }
    if (mustSelect && selectedEntities.length !== mustSelect) {
      mustSelectMessage = `Please select ${mustSelect} ${getName(mustSelect)}`;
      disableButton = true;
    }
    return {
      minSelectMessage,
      maxSelectMessage,
      mustSelectMessage,
      disableButton
    };
  }, [
    maxSelected,
    minSelected,
    mustSelect,
    readableName,
    selectedEntities.length
  ]);

  if (asReactSelect) {
    const addValueToEntity = entity => {
      //we need to add a .value field to every entity based on the entities id/code
      return {
        ...entity,
        value: entity[idAs]
      };
    };
    const value = isMultiSelect
      ? !currentValue || !currentValue.length
        ? []
        : currentValue.map(addValueToEntity)
      : !currentValue
        ? ""
        : addValueToEntity(currentValue);

    const sharedProps = {
      value,
      noResultsText,
      isLoading: reactSelectLoadingState,
      name: passedName,
      onChange: handleReactSelectFieldSubmit,
      options: getReactSelectOptions(),
      multi: isMultiSelect,
      onInputChange: handleReactSelectSearch
    };

    return (
      <TgSelect
        itemListPredicate={(queryString, items) => {
          const currentValuesByKey = keyBy(value, "value");
          return items.filter(item => {
            const { value, __isInputEnt, userCreated } = item;
            if (userCreated || __isInputEnt) return false; //don't show user created option as option to select
            // if (__isInputEnt) {
            //we need to filter it out manually
            // return singleItemPredicate(queryString, item);
            // }
            return !currentValuesByKey[value];
          });
        }}
        {...sharedProps}
        {...reactSelectProps}
        {...rest}
      />
    );
  }

  let enhancedChildren = additionalTableProps?.children;
  // the enhanced children will get overwritten if passing children to additionalTableProps
  if (additionalTableProps && additionalTableProps.enhancedChildren) {
    enhancedChildren = additionalTableProps.enhancedChildren({ tableParams });
  }

  const entities = tableParams.entities;

  return (
    <div>
      <div className={Classes.DIALOG_BODY}>
        {dialogInfoMessage}
        <div style={{ marginBottom: 10 }}>
          {minSelectMessage}
          {mustSelectMessage}
          {maxSelectMessage}
        </div>
        <DataTable
          noPadding
          autoFocusSearch
          withSearch
          withPaging
          doNotShowEmptyRows
          withSelectAll={isMultiSelect ? withSelectAll : false}
          safeQuery={safeQuery}
          onDoubleClick={onDoubleClick}
          withCheckboxes={isMultiSelect}
          isSingleSelect={!isMultiSelect}
          maxHeight={400}
          {...tableParams}
          {...(entities?.length && {
            entities: transformEntities(entities)
          })}
          {...additionalTableProps}
          // destroyOnUnmount={false}
          // keepDirtyOnReinitialize
          // enableReinitialize={true}
          // updateUnregisteredFields
        >
          {enhancedChildren}
        </DataTable>
      </div>
      <DialogFooter
        hideModal={hideModal}
        disabled={disableButton}
        onClick={() => finishSelection(selectedEntities)}
        loading={finishingSelection}
        text={buttonText || "Select " + readableName}
        {...dialogFooterProps}
      />
    </div>
  );
});

export default InnerComp;
