/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import JSZip from "jszip";
import { isBrowser } from "browser-or-node";
import { Readable } from "stream";
import shortid from "shortid";
import { extractZipFiles, isCsvFile } from "@teselagen/file-utils";

export {
  allowedCsvFileTypes,
  isZipFile,
  getExt,
  isExcelFile,
  isCsvFile,
  isTextFile,
  isCsvOrExcelFile,
  extractZipFiles,
  setupCsvParserOptions,
  normalizeCsvHeader,
  parseCsvFile,
  jsonToCsv,
  parseCsvString,
  parseCsvOrExcelFile,
  validateCSVRequiredHeaders,
  validateCSVRow,
  cleanCommaSeparatedCell,
  cleanCsvExport,
  filterFilesInZip,
  removeExt,
  encodeFilesForRequest
} from "@teselagen/file-utils";

export const stripZippedPrefix = n => {
  return n.replace(/^.*_&&_/, "");
};

export async function parseExcelToCsv(file) {
  try {
    if (isBrowser) {
      const data = new FormData();
      data.append("file", file.originalFileObj || file);
      const res = await window.serverApi.request({
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        url: "/parseExcelToZippedCsv",
        responseType: "blob",
        data
      });
      const files = await extractZipFiles([res.data]);
      if (!files[0] || !isCsvFile(files[0])) {
        throw new Error("No CSV file found");
      }
      files[0].name = stripZippedPrefix(files[0].name);
      return files[0];
    } else {
      throw new Error("Should not be hitting this function from the server");
    }
  } catch (error) {
    console.error("error:", error);
    return { error: "Error parsing excel file." };
  }
}

// if window isn't undefined, add
if (isBrowser) {
  window.parseExcelToCsv = parseExcelToCsv;
}

export async function uploadAndProcessFilesClient(files = []) {
  if (!files.length) return null;

  const formData = new FormData();
  files.forEach(({ originFileObj }) => formData.append("file", originFileObj));

  const response = await window.serverApi.post("/user_uploads/", formData);

  return response.data.map(d => ({
    encoding: d.encoding,
    mimetype: d.mimetype,
    originalname: d.originalname,
    path: d.path,
    size: d.size,
    href: d.href
  }));
}

export async function uploadAndProcessFilesServer(files = [], ctx) {
  const uploadedFiles = [];
  for (const file of files) {
    uploadedFiles.push(await uploadFileToBucket(file, ctx));
  }
  return uploadedFiles;
}

export async function uploadFileToBucket(file, ctx) {
  const { s3Uploader } = ctx;
  const filename = file.originalname || "untitled";
  const readStream = new Readable();
  readStream.push(file.buffer);
  readStream.push(null);

  let filePath = filename;
  // This name processing could be optional.
  filePath = processFilename(filename);

  const result = await s3Uploader.uploadFileFromStream(
    "user_uploads",
    filePath,
    readStream
  );

  return {
    encoding: file.encoding,
    mimetype: file.mimetype,
    originalname: filename,
    path: result,
    size: file.size
  };
}

function processFilename(filename) {
  let newFilename = `${filename.replace(/\s/g, "")}_${shortid()}`;
  // if the filename comes with an extension, handle this so that the extension is after the unique ID suffix.
  if (filename.indexOf(".") > -1) {
    const fileNameWithoutExtension = filename.slice(
      0,
      filename.lastIndexOf(".")
    );
    const fileExtension = filename.slice(filename.lastIndexOf(".") + 1);

    // removes any white-spaces in the filename string and appends a unique ID suffix.
    newFilename = `${fileNameWithoutExtension.replace(
      /\s/g,
      ""
    )}_${shortid()}.${fileExtension}`;
  }
  return newFilename;
}

export async function handleZipFiles(files) {
  const zip = new JSZip();
  const fileNames = [];

  files.forEach(file => {
    let fileName = file.name;
    let count = 1;
    while (fileNames.includes(fileName)) {
      fileName = file.name + " (" + count + ")";
      count++;
    }
    fileNames.push(fileName);
    zip.file(fileName, file.data);
  });

  return await zip.generateAsync({ type: "blob" });
}
