/* Copyright (C) 2018 TeselaGen Biotechnology, Inc. */
import React from "react";
import { compose, withProps } from "recompose";
import withQuery from "../../../src-shared/withQuery";
import { Link } from "react-router-dom";
import modelNameToLink from "../../../src-shared/utils/modelNameToLink";
import { stringify } from "qs";

export default compose(
  withQuery(
    ["aliquotContainerPathView", "id fullPath assignedPositionId equipmentId"],
    {
      isPlural: true,
      skip: props => !props.aliquotContainerId,
      options: props => {
        return {
          variables: {
            filter: {
              id: props.aliquotContainerId
            }
          }
        };
      }
    }
  ),
  withQuery(
    ["containerArrayPathView", "id fullPath assignedPositionId equipmentId"],
    {
      isPlural: true,
      skip: props => !props.containerArrayId,
      options: props => {
        return {
          variables: {
            filter: {
              id: props.containerArrayId
            }
          }
        };
      }
    }
  ),
  withQuery(["containerPathView", "id fullPath equipmentId"], {
    isPlural: true,
    skip: props => !props.containerId,
    options: props => {
      return {
        variables: {
          filter: {
            id: props.containerId
          }
        }
      };
    }
  }),
  withProps(props => {
    return {
      loadingLocationLink: props.data.loading,
      refetchLocationLink: props.data.refetch
    };
  }),
  withProps(props => {
    const {
      loadingLocationLink,
      containerArrayPathViews = [],
      aliquotContainerPathViews = [],
      containerPathViews = []
    } = props;

    let assignedPositionId;
    let locationPath;
    let equipmentId;

    if (!loadingLocationLink) {
      let pathView;
      if (props.aliquotContainerId) {
        pathView = aliquotContainerPathViews[0];
      } else if (props.containerArrayId) {
        pathView = containerArrayPathViews[0];
      } else if (props.containerId) {
        pathView = containerPathViews[0];
      }
      if (pathView) {
        locationPath = pathView.fullPath;
        assignedPositionId = pathView.assignedPositionId;
        equipmentId = pathView.equipmentId;
      }
    }

    let locationLink;
    if (equipmentId) {
      locationLink = getLocationLink({
        locationPath,
        equipmentId,
        assignedPositionId
      });
    } else {
      // so we can have the class for testing
      locationLink = <span className="location-link">{locationPath}</span>;
    }

    return {
      assignedPositionId,
      locationLink,
      loadingLocationLink: loadingLocationLink
    };
  })
);

export function getLocationLink({
  locationPath,
  equipmentId,
  assignedPositionId
}) {
  let hash;
  if (assignedPositionId) {
    hash = stringify({
      assignedPositionId
    });
  }

  return (
    <Link
      className="location-link"
      to={{ pathname: modelNameToLink("equipmentItem", equipmentId), hash }}
    >
      {locationPath}
    </Link>
  );
}
